import React, { useState, useEffect } from "react";
import DashboardSessionCalender from "../pages/DashboardSessionCalender";

function ViewSessionCalendar(props) {
  useEffect(() => {
    // adminService.getAll(`languages?size=1000`).then(
    //     res => {
    //         setLanguage(res.data.content);
    //         if (interpreterForm.hasOwnProperty('interpreterLanguageForms'))
    //             setLanguageIds(interpreterForm.interpreterLanguageForms)
    //     },
    //     error => {
    //         console.log(error)
    //     }
    // );
  }, []);

  return (
    <>
      <form>
        <div className="col-md-2 custom-modal">
          <div
            className="modal fade add-new-prop-modal show "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="w-100 text-center">
                    <h5 className="modal-title name">Therapy Sessions</h5>
                  </div>
                  {
                    <button
                      type="button"
                      onClick={() => props.closeModal(false)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <div className="close-background">
                        <p aria-hidden="true" className="cross">
                          &times;
                        </p>
                      </div>
                    </button>
                  }
                </div>
                <div className="modal-body calenda-modal-view">
                  <DashboardSessionCalender
                    userrole={props.userrole}
                    isCal={true}
                    userid={props.userid}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ViewSessionCalendar;
