import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { adminService } from "../../../services/adminService";
import allActions from "../../../redux/actions";
import { toast } from "react-toastify";
import { api, monthJson } from "../../../config/Config";
import { useForm } from "react-hook-form";
import DatePicker from "react-date-picker";
import moment from "moment-timezone";
import { ReactSVG } from "react-svg";
import appAction from "../../../redux/actions/appAction";
import { nameRegex } from "../../../utils/regex";

function FamilyAssignTherapy(props) {
  // TODO add app loading on this page
  const { register, formState: { errors, }, handleSubmit } = useForm();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loadings, setloadings] = useState(false);
  const [attachmentLoadings, setAttachmentLoadings] = useState(false);
  const [refDateValue, handleChangeDate] = useState(new Date());
  let [attachments, setAttachments] = useState([]);
  let [therapies, setTherapies] = useState([]);
  let [therapist, setTherapist] = useState([]);
  let [interpreter, setInterpreter] = useState("");
  let [diagnoses, setDiagnoses] = useState("");
  let familyForm = useSelector((state) => state.adminUser.familyForm);
  let [tempAssignTherapy, setTempAssignTherapy] = useState([]);
  const [familyMembers, setFamilyMembers] = useState(
    familyForm.familyMemberForms
  );
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
  const [inputs, setInputs] = useState({
    allergies: "",
    attachmentKeys: [],
    diagnosisIds: [],
    tempDiagnosisIds: [],
    medications: "",
    physicianFirstName: "",
    physicianLastName: "",
    physicianNpiNumber: "",
    precautions: "",
    referralDate: new Date(),
    therapyId: "",
    therapistId: "",
  });

  const [assignServices, setAssignServices] = useState({
    referralDate: new Date(),
    therapyId: "",
    therapistId: "",
  });
  const onSubmit = () => {
    console.log("errors->>>>>", errors);
    let isValidated = true;
    familyMembers.map((item) => {
      if (
        item.familyMemberTherapyForms &&
        item.familyMemberTherapyForms.length === 0
      ) {
        isValidated = false;
        toast.warn("Please assign therapy for " + item.firstName);
        return;
      }
      if (item.interpreterId == "") {
        toast.warn("Please select interpreter for " + item.firstName);
        isValidated = false;
        return;
      }
    });
    if (isValidated) {
      setloadings(true);
      let formData = new FormData();
      let familyFormObj = familyForm;

      // for diagnosisIds into request payload for therapy member
      familyFormObj.familyMemberForms.map((item) => {
        let familyMemberTherapyFormsObj = [];
        item.familyMemberTherapyForms.map((item1) => {
          let diagnosisIds = [];
          item1.diagnosisIds.map((item2) => {
            if (!Number.isInteger(item2)) {
              let splitArray = item2 && item2.split("$&");
              diagnosisIds.push(parseInt(splitArray[0]));
            }
          });
          if (diagnosisIds.length > 0) item1.diagnosisIds = diagnosisIds;
          familyMemberTherapyFormsObj.push(item1);
        });

        // for attachments
        let attachmentKeys = [];

        item.attachmentKeys.map((item1) => {
          item1.attachmentKeys && attachmentKeys.push(item1.attachmentKeys);
        });
        if (attachmentKeys.length > 0) item.attachmentKeys = attachmentKeys;
        // attachment end

        familyMemberTherapyFormsObj.push(item);
      });

      familyForm.userProfileForm.phone =
        familyForm.userProfileForm.phone.replace(/[^\d]/g, "");
      formData.append("profileImage", familyForm.userProfileForm.profileImage);
      //delete familyForm.userProfileForm.profileImage;
      if (props?.familyData) {
        onSubmitSaveAndBack();
      } else {
        formData.append(
          "familyForm",
          new Blob([JSON.stringify(familyFormObj)], {
            type: "application/json",
          })
        );

        adminService.onboardUser(`families`, formData).then(
          (res) => {
            setloadings(false);
            if (res.status === "Success") {
              //props.handleNext();
              //dispatch(allActions.adminAction.clearOnboardData());
              toast.success(res.message);
              history("/family");
            } else {
              toast.error(res.message);
              setloadings(false);
            }
          },
          (error) => {
            console.log("Error >>>>>>>", error);
          }
        );
      }
    }
  };

  const onSubmitSaveAndBack = () => {
    const childList = [];
    const familyMemberUpdateForms = [];
    let formData = new FormData();
    // formData.append("profileImage", familyForm.userProfileForm.profileImage);
    const userBasicInfo = {
      firstName: familyForm?.userProfileForm?.firstName,
      lastName: familyForm?.userProfileForm?.lastName,
      email: familyForm?.userProfileForm?.email,
      phone: familyForm?.userProfileForm?.phone,
      about: "",
      gender: familyForm?.userProfileForm?.gender,
    };
    familyForm?.familyMemberForms.length > 0 &&
      familyForm?.familyMemberForms.map((member) => {
        const data = {
          id: member?.id || null,
          firstName: member.firstName,
          lastName: member.lastName,
          dob: member.dob,
          gender: member.gender,
          about: member.about,
          profilePicKey: member.profilePicKey,
          // insuranceDetailForms: member.insuranceDetailForms,
        };
        if (member?.id) {
          console.log("family update",familyMembers,member);
          const newIns = [];
          const haveIns = [];
          // data.newInsuranceDetailForm = member.insuranceDetailForms;
          if (member?.insuranceDetailForms) {
            member.insuranceDetailForms.map((ins) => {
              ins?.id ? haveIns.push(ins) : newIns.push(ins);
            });
          }
          data.interpreterId = member.interpreterId;
          data.newInsuranceDetailForm = newIns;
          data.insuranceDetailUpdateForms = haveIns;
          data.newFamilyMemberTherapyForms = member.familyMemberTherapyForms;
          familyMemberUpdateForms.push(data);
        } else {
          console.log("family update",familyMembers,member);
          data.interpreterId = member.interpreterId;
          data.insuranceDetailForms = member.insuranceDetailForms;
          data.familyMemberTherapyForms = member.familyMemberTherapyForms;
          childList.push(data);
        }
      });
    if (props?.familyData) {
      console.log("familyupdateonboard",familyMemberUpdateForms);
      formData.append(
        "familyUpdateForm",
        new Blob(
          [
            JSON.stringify({
              languageIds: familyForm?.languageIds,
              relationship: familyForm?.relationship,
              userProfileUpdateForm: userBasicInfo,
              pendingOnboarding: false,
              newFamilyMemberForms: childList,
              familyMemberUpdateForms,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    } else {
      formData.append(
        "familyForm",
        new Blob(
          [
            JSON.stringify({
              languageIds: familyForm?.languageIds,
              relationship: familyForm?.relationship,
              userProfileForm: userBasicInfo,
              familyMemberForms: childList,
              pendingOnboarding: false,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }
    // dispatch(appAction.setAppLoading(true));
    if (!props?.familyData) {
      adminService
        .onboardUser(`families`, formData)
        .then(
          (res) => {
            console.log("object", res);
            if (res?.data?.id) {
              history("/pending-users-onboarding");
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    } else {
      adminService
        .update(`/families/` + props?.familyData.id, formData)
        .then(
          (res) => {
            if (res?.status === "Success") {
              history("/pending-users-onboarding");
            } else {
              toast.error(res.message);
              setloadings(false);
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    }
  };

  const assignInterpreter = (val, fname, lname) => {
    let familyMembersObj = [...familyMembers];
    let fileindex1 = familyMembersObj.findIndex(
      (obj) => obj.firstName == fname && obj.lastName == lname
    );
    familyMembersObj[fileindex1].interpreterId =
      parseInt(val) > 0 ? parseInt(val) : null;
    setFamilyMembers(familyMembersObj);
    dispatch(
      allActions.adminAction.setFamilyFormData(
        familyMembersObj,
        "familyMemberForms"
      )
    );
  };

  const assignTherapyTherapist = (fname, lname) => {
    if (typeof fname === "string" || fname instanceof String) {
      if (
        inputs.therapistId &&
        inputs.therapyId != 0 &&
        inputs.diagnosisIds.length > 0
      ) {
        let familyMembersObj = [...familyMembers];
        let fileindex1 = familyMembersObj.findIndex(
          (obj) => obj.firstName == fname && obj.lastName == lname
        );
        inputs.referralDate = moment(refDateValue)
          .format("YYYY-MM-DD")
          .toString();

        //return;
        // to check wheater assigned and only adding the services to the child
        if (
          familyMembersObj?.[fileindex1]?.["familyMemberTherapyForms"].length
        ) {
          let findTherapyIndex = familyMembersObj[fileindex1][
            "familyMemberTherapyForms"
          ].findIndex((obj) => obj.therapyId == inputs.therapyId);
          if (findTherapyIndex !== -1) {
            toast.warn("Service already added, please add another service");
            return;
          } else {
            inputs.therapisName =
              therapist.length > 0
                ? therapist.filter((item) => item.id === inputs.therapistId)[0]
                    .userProfile.firstName +
                  " " +
                  therapist.filter((item) => item.id === inputs.therapistId)[0]
                    .userProfile.lastName
                : "";
            familyMembersObj[fileindex1]["familyMemberTherapyForms"].push(
              inputs
            );
            let findTherapyIndex = familyMembersObj[fileindex1][
              "familyMemberTherapyForms"
            ].findIndex((obj) => obj.therapyId == inputs.therapyId);
            familyMembersObj[fileindex1]["familyMemberTherapyForms"][
              findTherapyIndex
            ].diagnosisIds = inputs.diagnosisIds;
            setFamilyMembers(familyMembersObj);
            dispatch(
              allActions.adminAction.setFamilyFormData(
                familyMembersObj,
                "familyMemberForms"
              )
            );
            return;
          }
        }

        // when first time assigned therapy here only will come to set the details of form
        // let attachmentKeys = [];
        // attachments.map((item) => {
        //     attachmentKeys.push(item.attachmentKeys)
        // });

        inputs.therapisName =
          therapist.length > 0
            ? therapist.filter((item) => item.id === inputs.therapistId)[0]
                .userProfile.firstName +
              " " +
              therapist.filter((item) => item.id === inputs.therapistId)[0]
                .userProfile.lastName
            : "";
        let assignarray = [
          ...familyMembersObj[fileindex1]["familyMemberTherapyForms"],
          inputs,
        ];

        // for child single data changes on 16 march 2021
        familyMembersObj[fileindex1]["familyMemberTherapyForms"] = assignarray;
        familyMembersObj[fileindex1]["familyMemberTherapyForms"].diagnosisIds =
          inputs.diagnosisIds;
        familyMembersObj[fileindex1][
          "familyMemberTherapyForms"
        ].tempDiagnosisIds = inputs.diagnosisIds;
        familyMembersObj[fileindex1].allergies = inputs.allergies;
        familyMembersObj[fileindex1].tempAttachmentKeys = attachments;
        familyMembersObj[fileindex1].attachmentKeys = attachments;
        familyMembersObj[fileindex1].medications = inputs.medications;
        familyMembersObj[fileindex1].physicianFirstName =
          inputs.physicianFirstName;
        familyMembersObj[fileindex1].physicianLastName =
          inputs.physicianLastName;
        familyMembersObj[fileindex1].physicianNpiNumber =
          inputs.physicianNpiNumber;
        familyMembersObj[fileindex1].precautions = inputs.precautions;

        setFamilyMembers(familyMembersObj);
        dispatch(
          allActions.adminAction.setFamilyFormData(
            familyMembersObj,
            "familyMemberForms"
          )
        );
      } else {
        toast.warn("Please fill all the required fields");
      }
    }
    setAssignServices({
      referralDate: new Date(),
      therapyId: "",
      therapistId: "",
    });
    setDiagnoses("");
    handleChangeDate(new Date());
  };

  const removeTempAssignTherapy = (id, fname, lname) => {
    let familyMembersObj = [...familyMembers];
    let fileindex1 = familyMembersObj.findIndex(
      (obj) => obj.firstName == fname && obj.lastName == lname
    );
    let familyMemberTherapyForms = familyMembersObj[
      fileindex1
    ].familyMemberTherapyForms.filter((item) => item.therapyId !== id);
    familyMembersObj[fileindex1]["familyMemberTherapyForms"] =
      familyMemberTherapyForms;
    setFamilyMembers(familyMembersObj);
    dispatch(
      allActions.adminAction.setFamilyFormData(
        familyMembersObj,
        "familyMemberForms"
      )
    );
    setTempAssignTherapy(
      tempAssignTherapy.filter((item) => item.therapyId !== id)
    );
  };

  function handleChange(e) {
    const { name, value, selectedOptions } = e.target;
    if (name === "therapyId") {
      adminService
        .getAll(`therapists/filter?therapyId=${parseInt(value)}&sort=id,DESC`)
        .then(
          (res) => {
            if (res.data.content.length > 0) {
              setTherapist(res.data.content);
              setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
            } else {
              toast.warn("Therapist not available for selected therapy");
              setTherapist(res.data.content);
            }
          },
          (error) => {
            console.log(error);
          }
        );

      adminService.getAll(`diagnoses/${parseInt(value)}`).then(
        (res) => {
          if (res.data.length > 0) {
            setInputs((inputs) => ({ ...inputs, ["diagnosisIds"]: [] }));
            setDiagnoses(res.data);
          } else {
            toast.warn("ICD 10 codes is not available for selected therapy");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (name === "diagnosisIds") {
      let valueIds = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setInputs((inputs) => ({ ...inputs, [name]: valueIds }));
      setInputs((inputs) => ({ ...inputs, ["tempDiagnosisIds"]: valueIds }));
    } else if (name == "therapistId")
      setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
    else setInputs((inputs) => ({ ...inputs, [name]: value }));
    setAssignServices((assignServices) => ({
      ...assignServices,
      [name]: value,
    }));
  }

  function getTherapyName(id) {
    return therapies.length > 0
      ? therapies.filter((item) => item.id === id)[0].description
      : "";
  }

  // For upload attachments
  const handleImageChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const { name } = e.target;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let FileSize = e.target.files[0].size / 1024 / 1024; // in MB
    dispatch(appAction.setAppLoading(true));
    if (file.name.match(/.(jpg|jpeg|jpeg2000|png|pdf|doc|docx)$/i)) {
      if (FileSize > 5) {
        toast.warn("Please upload file having size MAX 5MB");
        dispatch(appAction.setAppLoading(false));
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          file.url = reader.result;
          let formData = new FormData();
          formData.append("imageFile", file);
          adminService
            .POSTUploadImage(`upload/image`, formData)
            .then(
              (res) => {
                if (res.status === "Success") {
                  let attachment_json = {
                    file: file,
                    attachmentKeys: res.data.fileKey,
                  };
                  setAttachments([...attachments, attachment_json]);
                  // setAttachmentLoadings(false);
                } else {
                  toast.error(res.message);
                }
              },
              (error) => {
                console.log("Error >>>>>>>", error);
              }
            )
            .finally(() => {
              dispatch(appAction.setAppLoading(false));
              target.value = "";
            });
        };
      }
    } else {
      toast.warn(
        "Please upload file having extensions - JPEG,PNG,PDF,DOC,DOCX"
      );
      dispatch(appAction.setAppLoading(false));
    }
  };

  const removeAttachments = (key) => {
    setAttachments(attachments.filter((item) => item.attachmentKeys !== key));
  };
  //End for attachment images

  useEffect(() => {
    adminService.getAll(`therapies`).then(
      (res) => {
        setTherapies(res.data.content);
      },
      (error) => {
        console.log(error);
      }
    );

    adminService
      .getAll(
        `interpreters/filter?languageIds=` + familyForm.languageIds.join()
      )
      .then(
        (res) => {
          setInterpreter(res.data.content);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  return (
    <>
      {" "}
      {loadings ? (
        <div className="loader"></div>
      ) : (
        <>
          <form onSubmit={handleSubmit(assignTherapyTherapist)}>
            <div className="addtheripist">
              <label className="conetent-title">Assign Therapy</label>
              <div className="row">
                <div className="col-md-12">
                  <table className="table custom-table">
                    <tbody>
                      {familyMembers &&
                        familyMembers.map((info, index) => (
                          <tr>
                            <td className="p-0">
                              <div className="col-auto p-0 mt-2 mb-2">
                                <div className="user-info">
                                  <span className="admin-name justify-content-start">
                                    <img
                                      src={
                                        info?.profileImage?.url
                                          ? info.profileImage.url
                                          : info?.profileUrl
                                          ? info?.profileUrl
                                          : "/resources/assets/defaultprofile.png"
                                      }
                                      alt=""
                                      className="user-info-img"
                                    />

                                    <div className="content ml-3">
                                      <span className="info-name">
                                        {info.firstName} {info.lastName}
                                      </span>
                                      <sapn className="ml-5 info-dob">
                                        Gender -{" "}
                                        {info.gender === "NOT_DISCLOSED"
                                          ? "Other"
                                          : info.gender}
                                      </sapn>
                                      <span className="ml-5 info-dob">
                                        Date of Birth -{" "}
                                        {
                                          monthJson[
                                            parseInt(info.dob.split("-")[1]) - 1
                                          ].name
                                        }{" "}
                                        {info.dob.split("-")[2]}{" "}
                                        {info.dob.split("-")[0]}
                                      </span>
                                      <small>
                                        <br />
                                        {info.about}
                                      </small>
                                    </div>
                                  </span>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Physician NPI Number</label>
                                    {/* (<span className="required-span">*</span>) */}
                                    {/* ${
                                        errors.physicianNpiNumber &&
                                        "is-invalid"
                                      } */}
                                    <input
                                      // value={inputs.physicianNpiNumber}
                                      defaultValue={
                                        info.physicianNpiNumber
                                          ? info.physicianNpiNumber
                                          : inputs.physicianNpiNumber
                                      }
                                      name="physicianNpiNumber"
                                      onChange={handleChange}
                                      type="text"
                                      className={`form-control`}
                                      placeholder="Enter Physician NPI Number"
                                      // ref={register({ required: true, maxLength: 45 })}
                                    />
                                    {/* {errors.physicianNpiNumber &&
                                      errors.physicianNpiNumber.type ===
                                        "required" && (
                                        <div className="invalid-feedback-custom">
                                          Physician NPI number is required
                                        </div>
                                      )} */}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Physician First Name</label>{" "}
                                    {/*(<span className="required-span">*</span>) */}
                                    <input
                                      defaultValue={
                                        info.physicianFirstName
                                          ? info.physicianFirstName
                                          : inputs.physicianFirstName
                                      }
                                      name="physicianFirstName"
                                      onChange={handleChange}
                                      type="text"
                                      className={`form-control ${
                                        errors.physicianFirstName &&
                                        "is-invalid"
                                      }`}
                                      placeholder="Enter Physician First Name"
                                      //ref={register({ required: true, maxLength: 20, pattern: nameRegex })}
                                    />
                                    {/* {errors.physicianFirstName &&
                                      errors.physicianFirstName.type ===
                                        "required" && (
                                        <div className="invalid-feedback-custom">
                                          Physician first name is required
                                        </div>
                                      )} */}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Physician Last Name</label>{" "}
                                    {/*(<span className="required-span">*</span>) */}
                                    <input
                                      defaultValue={
                                        info.physicianLastName
                                          ? info.physicianLastName
                                          : inputs.physicianLastName
                                      }
                                      name="physicianLastName"
                                      onChange={handleChange}
                                      type="text"
                                      className={`form-control ${
                                        errors.physicianLastName && "is-invalid"
                                      }`}
                                      placeholder="Enter Physician Last Name"
                                      // ref={register({ required: true, maxLength: 20, pattern: nameRegex })}
                                    />
                                    {/* {errors.physicianLastName &&
                                      errors.physicianLastName.type ===
                                        "required" && (
                                        <div className="invalid-feedback-custom">
                                          Physician last name is required
                                        </div>
                                      )} */}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Allergies</label>
                                    <textarea
                                      maxlength="500"
                                      defaultValue={
                                        info.allergies
                                          ? info.allergies
                                          : inputs.allergies
                                      }
                                      name="allergies"
                                      rows="3"
                                      onChange={handleChange}
                                      className={`form-control h-100 ${
                                        errors.allergies && "is-invalid"
                                      }`}
                                      placeholder="Enter Allergies"
                                    ></textarea>
                                    {/*<div className="text-info-div mt-1">
                                                                    <img src='../resources/assets/material-info-outline.svg' alt="Info icon for Precautions" /><span className="text-info-tips">&nbsp;Lorem ipsum dolor sit amet,</span>
                                                                </div>*/}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Precautions</label>
                                    <textarea
                                      maxlength="500"
                                      defaultValue={
                                        info.precautions
                                          ? info.precautions
                                          : inputs.precautions
                                      }
                                      name="precautions"
                                      rows="3"
                                      onChange={handleChange}
                                      className={`form-control h-100 ${
                                        errors.precautions && "is-invalid"
                                      }`}
                                      placeholder="Enter Precautions"
                                    ></textarea>
                                    {/* <div className="text-info-div mt-1">
                                                                    <img src='../resources/assets/material-info-outline.svg' alt="Info icon for Precautions" /><span className="text-info-tips">&nbsp;Lorem ipsum dolor sit amet,</span>
                                                            </div> */}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Medications</label>
                                    <textarea
                                      maxlength="500"
                                      defaultValue={
                                        info.medications
                                          ? info.medications
                                          : inputs.medications
                                      }
                                      name="medications"
                                      rows="3"
                                      onChange={handleChange}
                                      className={`form-control h-100 ${
                                        errors.medications && "is-invalid"
                                      }`}
                                      placeholder="Enter Medications"
                                    ></textarea>
                                    {/* <div className="text-info-div mt-1">
                                                                    <img src='../resources/assets/material-info-outline.svg' alt="Info icon for Precautions" /><span className="text-info-tips">&nbsp;Lorem ipsum dolor sit amet,</span>
                                                        </div>*/}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  {/* <label className="conetent-title">Assign Interpreter</label> */}
                                  <div className="form-group mb-4">
                                    <label>Interpreter</label> (
                                    <span className="required-span">*</span>)
                                    <div className="d-flex">
                                      <img
                                        className="pr-3"
                                        src="../../resources/assets/user-icon-blue.svg"
                                      />
                                      <div className="input-group">
                                        <select
                                          className="custom-select"
                                          onChange={(e) =>
                                            assignInterpreter(
                                              e.target.value,
                                              info.firstName,
                                              info.lastName
                                            )
                                          }
                                          value={info.interpreterId}
                                          name="therapistId"
                                          required
                                        >
                                          <option value="">
                                            Select Interpreter
                                          </option>
                                          <option value="null">
                                            None Needed
                                          </option>
                                          {tenantPemission?.interpreter &&
                                            interpreter &&
                                            interpreter.map((item, index) => (
                                              <option value={item.id}>
                                                {item.userProfile.firstName}{" "}
                                                {item.userProfile.lastName}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="form-group mb-4">
                                    <label>Attachments</label>

                                    <div className="file-input-wrapper">
                                      <button
                                        className="btn-file-input attachment-icon cursor-pointer"
                                        type="button"
                                      >
                                        <img
                                          src="../resources/assets/attach-file.svg"
                                          alt="attach"
                                        />
                                      </button>
                                      <input
                                        type="file"
                                        id="image"
                                        alt="Click to upload attachment"
                                        name="profilePicKey"
                                        onChange={(e) => handleImageChange(e)}
                                      />
                                      <span className="text-info-tips">
                                        &nbsp; Attach format - pdf, jpeg, png,
                                        doc, docx{" "}
                                      </span>
                                    </div>

                                    <div className="attahment-section mt-3">
                                      <span>Attached Files</span>
                                      <div className="row attachment-images-section-view mt-2">
                                        {attachments &&
                                          attachments.map((item, index) => (
                                            // <div className="column col-1">
                                            //     <button type="button" onClick={(e) => removeAttachments(item.attachmentKeys)}>
                                            //         <img src="../resources/assets/close-fill-blue.svg" />
                                            //     </button>
                                            //     <img src={item.file.type === 'image/png' ? item.file.url :
                                            //         item.file.type === 'image/jpeg' ? item.file.url :
                                            //             item.file.type === 'image/svg+xml' ? item.file.url :
                                            //                 "/resources/assets/Icon awesome-file-pdf.svg"} />
                                            // </div>
                                            <div className="column col-auto">
                                              <button
                                                type="button"
                                                className="mr-3"
                                                onClick={(e) =>
                                                  removeAttachments(
                                                    item.attachmentKeys
                                                  )
                                                }
                                              >
                                                <img src="../resources/assets/close-fill-blue.svg" />
                                              </button>
                                              <div
                                                title={item.file.name}
                                                className={
                                                  (`c-attachments__file c-attachments__file--${item.file.type}`,
                                                  "c-attachments__file")
                                                }
                                              >
                                                <div className="c-attachments__file-inner">
                                                  <a
                                                    href={item.file.url}
                                                    className=""
                                                    download
                                                    target={"_blank"}
                                                  >
                                                    {item.file.type ===
                                                      "image" ||
                                                    item.file.type ===
                                                      "image/jpeg" ||
                                                    item.file.type ===
                                                      "image/png" ||
                                                    item.file.type ===
                                                      "image/svg+xml" ? (
                                                      <img
                                                        className="attachment-img"
                                                        src={item.file.url}
                                                        alt="attachement"
                                                      />
                                                    ) : (
                                                      <ReactSVG
                                                        src={
                                                          item.file.type ===
                                                            "video/mp4" ||
                                                          item.file.type ===
                                                            "video"
                                                            ? "/resources/assets/Icon awesome-video.svg"
                                                            : item.file.type ===
                                                              "application/msword"
                                                            ? "/resources/assets/docs.svg"
                                                            : item.file.type ===
                                                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            ? "/resources/assets/docs.svg"
                                                            : item.file.type ===
                                                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                            ? "/resources/assets/excels.svg"
                                                            : "/resources/assets/Icon awesome-file-pdf.svg"
                                                        }
                                                      />
                                                    )}
                                                  </a>
                                                </div>
                                                <span className="c-attachments__file-name d-block">
                                                  {item.file.name}
                                                </span>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="">
                                    <label className="conetent-title p-0">
                                      Service Details
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12 border-sestion-full mb-3"></div>

                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Service Type</label> (
                                    <span className="required-span">*</span>)
                                    <div className="d-flex">
                                      <img
                                        className="pr-3"
                                        src="../../resources/assets/user-icon-blue.svg"
                                      />
                                      <div className="input-group">
                                        <select
                                          className="custom-select"
                                          onChange={handleChange}
                                          value={assignServices.therapyId}
                                          name="therapyId"
                                          required
                                        >
                                          <option value="0">
                                            Select Service Type
                                          </option>
                                          {therapies &&
                                            therapies.map((item, index) => (
                                              <option value={item.id}>
                                                {item.description}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Referral Date</label> (
                                    <span className="required-span">*</span>)
                                    <div className="d-flex">
                                      <div className="input-group">
                                        <DatePicker
                                          onChange={handleChangeDate}
                                          value={refDateValue}
                                          name="referralDate"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <label>Therapist</label> (
                                    <span className="required-span">*</span>)
                                    <div className="d-flex">
                                      <img
                                        className="pr-3"
                                        src="../../resources/assets/user-icon-blue.svg"
                                      />
                                      <div className="input-group">
                                        <select
                                          className="custom-select"
                                          onChange={handleChange}
                                          value={assignServices.therapistId}
                                          name="therapistId"
                                          required
                                        >
                                          <option selected>
                                            Select Therapist
                                          </option>
                                          {therapist &&
                                            therapist.map((item, index) => (
                                              <option value={item.id}>
                                                {item.userProfile.firstName}{" "}
                                                {item.userProfile.lastName}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-8">
                                  <div className="form-group mb-4">
                                    <label>ICD 10 Codes</label> (
                                    <span className="required-span">*</span>)
                                    <div className="d-flex">
                                      <div className="input-group">
                                        <select
                                          className="custom-select"
                                          value={inputs.diagnosisIds}
                                          onChange={handleChange}
                                          multiple
                                          name="diagnosisIds"
                                          size="5"
                                          style={{ overflowX: "auto" }}
                                        >
                                          <option selected>Select Code</option>
                                          {diagnoses &&
                                            diagnoses.map((item, index) => (
                                              <option
                                                value={
                                                  item.id +
                                                  "$&" +
                                                  item.code +
                                                  "$&" +
                                                  item.description
                                                }
                                              >
                                                {item.code} - {item.description}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="col-md-5">
                                                        <div className="form-group mb-4">
                                                            <label>ICD 10 Codes Description</label>
                                                            <div className="d-flex icd-h-desc">
                                                                <table className="table custom-table">

                                                                    <tr className="bg-common-gray">
                                                                        <td><strong>Code</strong></td>
                                                                        <td><strong>Description</strong></td>
                                                                    </tr>

                                                                    {inputs.diagnosisIds && inputs.diagnosisIds.map(item =>
                                                                        <tr className="bg-common-gray">
                                                                            <td>{item.split('$&')[1]}</td>
                                                                            <td>{item.split('$&')[2]}</td>
                                                                        </tr>
                                                                    )}

                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div> */}
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <button
                                      type="submit"
                                      onClick={() =>
                                        assignTherapyTherapist(
                                          info.firstName,
                                          info.lastName
                                        )
                                      }
                                      className="assigntherapist cursor-pointer btn btn-primary custom-btn-green custom-btn-green-auto"
                                    >
                                      <img
                                        className="pr-2"
                                        src="../resources/assets/plus-icon-button.svg"
                                        alt="Click to assign therapy"
                                      />{" "}
                                      Add Service
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* Inner row END */}

                              {/* <div className="col-md-4 align-self-end">
                                                    <div className="form-group mb-4">

                                                        <button type="submit" onClick={() => assignTherapyTherapist(info.firstName, info.lastName)} className="cursor-pointer btn btn-primary custom-btn-green custom-btn-green-auto">
                                                            <img className="pr-2" src="../resources/assets/plus-icon-button.svg" alt="Click to assign therapy" /> Assign
                                                        </button>

                                                    </div>
                                                </div> */}

                              {/* view for assigned therapy */}
                              <label className="conetent-title p-0">
                                Assigned Services
                              </label>
                              <div className="col-md-12">
                                <div className="form-group mb-4">
                                  <table className="table custom-table assigned-therapy">
                                    <tbody>
                                      {info.familyMemberTherapyForms &&
                                        info.familyMemberTherapyForms.map(
                                          (item, index) => (
                                            <>
                                              {/* <tr className="">
                                                                            <td className="border-top-0">Physician NPI Number <br /> <span>{item.physicianNpiNumber}</span> </td>
                                                                            <td className="border-top-0 lang">Physician First Name<br /> <span>{item.physicianFirstName}</span></td>
                                                                            <td className="border-top-0 lang">Physician Last Name<br /><span>{item.physicianLastName}</span></td>
                                                                            
                                                                        </tr>
                                                                        <tr className="">
                                                                            <td className="border-top-0">Allergies<br /> <span>{item.allergies}</span></td>
                                                                            <td className="border-top-0 lang">Precautions<br /><span>{item.precautions}</span></td>
                                                                            <td className="border-top-0 lang">Medications<br /><span>{item.medications}</span></td>
                                                                        </tr> */}
                                              {/* <tr className="">
                                                                            <td className="border-top-0">Interpreter<br /> <span>--</span></td>
                                                                            <td className="border-top-0"><br /> </td>
                                                                            <td className="border-top-0"><br /> </td>
                                                                        </tr> */}
                                              {/* <tr className="">
                                                                            <td className="border-top-0">Attachements<br /> 
                                                                                <div className="attachment-images-section-view mt-2 d-flex">
                                                                                    {attachments && attachments.map((item, index) =>
                                                                                        <div className="images">
                                                                                            <button type="button" onClick={(e) => removeAttachments(item.attachmentKeys)}>
                                                                                                <img className="close" src="../resources/assets/close-fill-blue.svg" />
                                                                                            </button>
                                                                                            <img className="img" src={item.file.url} />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                            <td className="border-top-0"><br /> </td>
                                                                            <td className="border-top-0"><br /> </td>
                                                                        </tr> */}
                                              <tr className="">
                                                <td className="border-top-0">
                                                  Service Type <br />
                                                  <span>
                                                    {getTherapyName(
                                                      item.therapyId
                                                    )}
                                                  </span>{" "}
                                                </td>
                                                <td className="border-top-0">
                                                  Referral Date <br />
                                                  <span>
                                                    {item.referralDate}
                                                  </span>{" "}
                                                </td>
                                                <td className="border-top-0">
                                                  Therapist
                                                  <br />
                                                  <span>
                                                    {item.therapisName}
                                                  </span>{" "}
                                                </td>

                                                <td
                                                  className="border-top-0 cursor-pointer text-right"
                                                  onClick={() =>
                                                    removeTempAssignTherapy(
                                                      item.therapyId,
                                                      info.firstName,
                                                      info.lastName
                                                    )
                                                  }
                                                >
                                                  <img src="../resources/assets/action-icon-delete.svg" />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  colSpan="4"
                                                  className="border-top-0 w-50"
                                                >
                                                  ICD 10 Code <br />
                                                  <table className="table custom-table">
                                                    {item.tempDiagnosisIds &&
                                                      item.tempDiagnosisIds.map(
                                                        (item) => (
                                                          <tr className="bg-common-gray">
                                                            <td className="w-0">
                                                              {Number.isInteger(
                                                                item
                                                              )
                                                                ? ""
                                                                : item.split(
                                                                    "$&"
                                                                  )[1] +
                                                                  " - " +
                                                                  item.split(
                                                                    "$&"
                                                                  )[2]}
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr className="border-sestion-full h-2 bg-white">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                              </tr>
                                            </>
                                          )
                                        )}
                                      {info.familyMemberTherapyForms &&
                                        info.familyMemberTherapyForms.length ==
                                          0 && (
                                          <tr className="text-center">
                                            <td colSpan="4">
                                              <strong>
                                                No service found, please add
                                                service from service details
                                                section
                                              </strong>
                                            </td>
                                          </tr>
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      {/* {familyMembers.length == 0 && (
                                        <tr className="text-center"><td colSpan="2"><strong>No data found</strong></td></tr>
                                    )} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
          <div className="row addtheripist">
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12 d-flex">
              <button
                className="btn  btn-primary d-block stepper-button-back"
                onClick={(e) => props.handleBack()}
              >
                Back
              </button>
              <button
                className="btn  btn-primary d-block stepper-button-next"
                type="button"
                onClick={(e) => onSubmit()}
              >
                Finish
              </button>
              {loadings ? <div className="loader-small"></div> : ""}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FamilyAssignTherapy;
