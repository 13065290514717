import React, { Component, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { adminService } from "../../../services/adminService";
import allActions from "../../../redux/actions";
import { useForm } from "react-hook-form";
import { getTherapisttype } from "../../../services/userServices";
import { toast } from "react-toastify";
import appAction from "../../../redux/actions/appAction";
import { alphanumericRegx } from "../../../components/Utils/Regex";

function TherapistServiceInformation(props) {
  const saveNextRef = useRef(null);
  const { register, formState: { errors }, handleSubmit } = useForm();
  let params = useParams();
  const history = useNavigate();
  const [discipline, setDiscipline] = useState([]);
  const [selected, setSelected] = useState(-1);
  const value = selected !== -1 && discipline[selected];

  let loading = false;
  const dispatch = useDispatch();
  let therapistForm = useSelector((state) => state.adminUser.therapistForm);
  let jsonFound = therapistForm.hasOwnProperty("userProfileForm");

  const [therapistServices, setTherapistServices] = useState(
    therapistForm?.userProfileForm?.therapistServices || []
  );
  const [therapies, setTherapies] = useState("");
  const [inputs, setInputs] = useState({
    serviceDetail: "",
    therapyId: "",
    clinicNpiNumber: "",
    clinicianNpiNumber: "",
    credentials: therapistForm?.otherFields?.credentials,
    disciplines_id: therapistForm?.otherFields?.disciplines_id,
    licenseNumberState: therapistForm?.otherFields?.licenseNumberState,
    hourlyRate: therapistForm?.otherFields?.hourlyRate,
    medicaidNumber: therapistForm?.otherFields?.medicaidNumber,
    taxonomy: therapistForm?.otherFields?.taxonomy,
    otherDiscipline: therapistForm?.otherFields?.otherDiscipline,
  });
  const [otherInput, setOtherInput] = useState(
    therapistForm?.otherFields?.otherDiscipline
  );

  const [isSaveBack, setIsSaveBack] = useState(false);
  const onSubmit = () => {
    // onSubmitSaveAndBack(true)
    if (isSaveBack) {
      createTherapist();
    } else {
      if (therapistServices && therapistServices?.length > 0) {
        dispatch(
          allActions.adminAction.setTherapistFormData(
            {
              credentials: inputs?.credentials,
              disciplines_id: inputs?.disciplines_id,
              hourlyRate: inputs?.hourlyRate,
              licenseNumberState: inputs?.licenseNumberState,
              medicaidNumber: inputs?.medicaidNumber,
              taxonomy: inputs?.taxonomy,
              otherDiscipline: inputs?.otherDiscipline,
            },
            "otherFields"
          )
        );
        dispatch(
          allActions.adminAction.setTherapistFormData(
            therapistServices,
            "therapistServiceForms"
          )
        );
        props.handleNext();
      } else {
        toast.warn("Please add atleast one Discipline & Service Information");
      }
    }
  };

  const createTherapist = (isnext = "") => {
    setIsSaveBack(false);
    dispatch(appAction.setAppLoading(true));
    let formData = new FormData();
    formData.append(
      "profileImage",
      jsonFound && therapistForm.userProfileForm.profileImage
    );
    const basicInfo = {
      firstName: jsonFound ? therapistForm.userProfileForm.firstName : "",
      lastName: jsonFound ? therapistForm.userProfileForm.lastName : "",
      email: jsonFound ? therapistForm.userProfileForm.email : "",
      phone: jsonFound ? therapistForm.userProfileForm.phone : "",
      about: jsonFound ? therapistForm.userProfileForm.about : "",
      gender: jsonFound ? therapistForm.userProfileForm.gender : "",
      active: jsonFound ? therapistForm.userProfileForm.active : false,
    };
    const otherInfo = {
      pendingOnboarding: inputs.pendingOnboarding,
      onboardPermission: inputs.onboardPermission,
      updatePermission: inputs.updatePermission,
    };

    const therapistServiceForms = therapistServices;
    if (props?.therapistData) {
      let formDataPut = {
        userProfileUpdateForm: basicInfo,
        therapistServiceUpdateForms: therapistServiceForms,
        pendingOnboarding: true,
        onboardPermission: false,
        updatePermission: false,
        credentials: inputs?.credentials,
        disciplines_id: inputs?.disciplines_id,
        hourlyRate: inputs?.hourlyRate,
        licenseNumberState: inputs?.licenseNumberState,
        medicaidNumber: inputs?.medicaidNumber,
        taxonomy: inputs?.taxonomy,
        otherDiscipline: inputs?.otherDiscipline,
      };
      formData.append(
        "therapistUpdateForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );
    } else {
      let formDataPut = {
        userProfileForm: basicInfo,
        therapistServiceForms: therapistServiceForms,
        pendingOnboarding: true,
        onboardPermission: false,
        updatePermission: false,
        credentials: inputs?.credentials,
        disciplines_id: inputs?.disciplines_id,
        hourlyRate: inputs?.hourlyRate,
        licenseNumberState: inputs?.licenseNumberState,
        medicaidNumber: inputs?.medicaidNumber,
        taxonomy: inputs?.taxonomy,
        otherDiscipline: inputs?.otherDiscipline,
      };
      formData.append(
        "therapistForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );
    }

    if (props?.therapistData) {
      updateOnboardTherapist(props?.therapistData.id, formData, isnext);
    } else {
      onboardTherapist(formData, isnext);
    }
  };

  const onboardTherapist = (formData, isnext) => {
    adminService
      .onboardUser(`therapists`, formData)
      .then(
        (res) => {
          dispatch(appAction.setAppLoading(false));
          if (res?.status === "Success") {
            dispatch(
              allActions.adminAction.setTherapistFormData(
                inputs,
                "userProfileForm"
              )
            );
            if (isnext) {
              props.handleNext();
            } else {
              // dispatch(allActions.adminAction.clearOnboardData());
              toast.success(res.message);
              setTimeout(() => {
                history("/pending-users-onboarding?tab=therapist");
              }, 2000);
            }
          } else {
            toast.error(res?.message || "Somthing went wrong.");
          }
        },
        (error) => {
          console.log("Error >>>>>>>", error);
        }
      )
      .finally(() => {
        dispatch(appAction.setAppLoading(false));
      });
  };

  const updateOnboardTherapist = (id, formData, isnext) => {
    adminService
      .update(`/therapists/${id}`, formData)
      .then(
        (res) => {
          dispatch(appAction.setAppLoading(false));
          if (res?.data?.id) {
            if (isnext) {
              // props?.handleNext();
              dispatch(
                allActions.adminAction.setTherapistFormData(
                  inputs,
                  "userProfileForm"
                )
              );
              props.handleNext();
            } else {
              history("/pending-users-onboarding?tab=therapist");
            }
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        dispatch(appAction.setAppLoading(false));
      });
  };

  const onSubmitSaveAndBack = () => {
    setIsSaveBack(true);
    setTimeout(() => {
      setIsSaveBack(true);
      saveNextRef.current.click();
    }, 1000);
  };

  const addService = () => {
    let fileindex = therapistServices.findIndex(
      (obj) => obj?.therapyId == inputs.therapyId
    );
    if (fileindex === -1) {
      if (inputs.therapyId !== 0 && inputs.therapyId !== "") {
        inputs.clinicNpiNumber = inputs.clinicNpiNumber
          ? inputs.clinicNpiNumber
          : 0;
        inputs.serviceDetail = inputs.serviceDetail ? inputs.serviceDetail : 0;
        inputs.clinicianNpiNumber = inputs.clinicianNpiNumber
          ? inputs.clinicianNpiNumber
          : 0;
        setTherapistServices([
          ...therapistServices,
          {
            serviceDetail: inputs?.serviceDetail,
            therapyId: inputs?.therapyId,
            clinicNpiNumber: inputs?.clinicNpiNumber,
            clinicianNpiNumber: inputs?.clinicianNpiNumber,
          },
        ]);
        setInputs({
          ...inputs,
          serviceDetail: "",
          therapyId: "0",
          clinicNpiNumber: "",
          clinicianNpiNumber: "",
        });
      } else toast.warn("Please fill the service information");
    } else {
      toast.warn("Service already added, please add other services");
      setInputs({
        ...inputs,
        serviceDetail: "",
        therapyId: "0",
        clinicNpiNumber: "",
        clinicianNpiNumber: "",
      });
    }
  };

  const removeService = (id) => {
    setTherapistServices(
      therapistServices.filter((item) => item?.therapyId !== id)
    );
  };

  function handleChange(e) {
    const { name, value } = e.target;
    console.log("disciplines_id",e.target.name,e.target.value)
    if (name === "disciplines_id") {
      if (value === "0") {
        setOtherInput(true);
      } else {
        setOtherInput(false);
      }
      setInputs((inputs) => ({
        ...inputs,
        [name]: name === "disciplines_id" ? parseInt(value) : value,
      }));
    } else if (name === "therapyId") {
      setInputs((inputs) => ({
        ...inputs,
        [name]: name === "therapyId" ? parseInt(value) : value,
      }));
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value ? value : "" }));
    }
  }
  function getServiceName(id) {
    return therapies.filter((item) => item.id === id)[0].description;
  }

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  useEffect(() => {
    dispatch(appAction.setAppLoading(true));
    adminService
      .getAll(`therapies`)
      .then(
        (res) => {
          setTherapies(res.data.content);
          if (therapistForm.hasOwnProperty("therapistServiceForms"))
            setTherapistServices(therapistForm.therapistServiceForms);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        dispatch(appAction.setAppLoading(false));
      });
  }, []);

  useEffect(() => {
    adminService
      .getAll(`/disciplines`)
      .then((res) => {
        setDiscipline(res.data);
        // resolveWithData(resolve, rej, res);
        console.log("displines",res.data);
      })
      .catch((err) => {
        // rej(err);
      });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="addtheripist mw-100 pb-0">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Discipline</label> (
                <span className="required-span">*</span>)
                <div className="input-group ">
                  <select
                    className="custom-select"
                    {...register('disciplines_id',{onChange:(e)=>{
                      setInputs({ ...inputs, otherDiscipline: null });
                      handleChange(e);
                    },required:true})}
                    // onChange={(e) => {
                    //   setInputs({ ...inputs, otherDiscipline: null });
                    //   handleChange(e);
                    // }}
                    value={inputs.disciplines_id}
                    name="disciplines_id"
                  >
                    <option value="-1">Select Discipline </option>
                    {discipline &&
                      discipline.map((disc, index) => (
                        <option key={index} value={disc.id}>
                          {disc.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Credentials</label>{" "}
                <input
                  defaultValue={inputs.credentials}
                  disabled={params.uid ? true : false}
                  name="credentials"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  className={`form-control`}
                  placeholder="Credentials"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Hourly Rate</label>{" "}
                <input
                  value={inputs.hourlyRate}
                  disabled={params.uid ? true : false}
                  name="hourlyRate"
                  type="number"
                  onChange={(e) => handleChange(e)}
                  className={`form-control`}
                  placeholder="Hourly Rate"
                />
              </div>
            </div>
            <div className="col-md-12">
              {otherInput && (
                <div className="form-group">
                  <label>Other</label>{" "}
                  <input
                    defaultValue={inputs.otherDiscipline}
                    disabled={params.uid ? true : false}
                    {...register('otherDiscipline',{ onChange: (e) => handleChange(e),required:true })}
                    name="otherDiscipline"
                    type="text"
                    // onChange={(e) => {
                    //   handleChange(e);
                    // }}
                    className={`form-control`}
                    placeholder="other"
                  />
                  {errors.otherDiscipline && errors.otherDiscipline.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Field is required
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>License Number and State </label>{" "}
                <input
                  defaultValue={inputs.licenseNumberState}
                  disabled={params.uid ? true : false}
                  {...register('licenseNumberState',{ onChange: (e) => handleChange(e),pattern: alphanumericRegx })}
                  name="licenseNumberState"
                  //onChange={(e) => handleChange(e)}
                  className={`form-control`}
                  placeholder="License Number and State"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Medicaid Number</label>{" "}
                <input
                  defaultValue={inputs.medicaidNumber}
                  disabled={params.uid ? true : false}
                  name="medicaidNumber"
                  type="number"
                  onChange={(e) => handleChange(e)}
                  className={`form-control`}
                  placeholder="Medicaid Number"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Taxonomy</label>{" "}
                <input
                  value={inputs.taxonomy}
                  disabled={params.uid ? true : false}
                  name="taxonomy"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  className={`form-control`}
                  placeholder="Taxonomy"
                />
              </div>
            </div>

            <div className="col-12">
              <label className="conetent-title">Service Information</label>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Provider Type</label> (
                <span className="required-span">*</span>)
                <div className="input-group ">
                  <select
                    className="custom-select"
                    {...register('therapyId',{onChange: (e) => handleChange(e),required: true })}
                    //onChange={handleChange}
                    value={inputs.therapyId}
                    name="therapyId"
                  >
                    <option value="0">Select Service Name</option>
                    {therapies &&
                      therapies.map((item, index) => (
                        <option value={item.id}>{item.description}</option>
                      ))}
                  </select>
                  {errors.therapyId && errors.therapyId.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Field is required
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>Clinician NPI Number</label>{" "}
                {/*(<span className="required-span">*</span>)*/}
                <input
                  value={inputs.clinicianNpiNumber}
                  disabled={params.uid ? true : false}
                  name="clinicianNpiNumber"
                  type="text"
                  onChange={handleChange}
                  className={`form-control`}
                  placeholder="Enter Clinician NPI Number"
                  maxLength="20"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Clinic NPI Number</label>{" "}
                {/*(<span className="required-span">*</span>)*/}
                <input
                  value={inputs.clinicNpiNumber}
                  disabled={params.uid ? true : false}
                  name="clinicNpiNumber"
                  type="text"
                  onChange={handleChange}
                  className={`form-control`}
                  placeholder="Enter Clinic NPI Number"
                  maxLength="20"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row ml-1">
          <div className="col-md-12 form-group">
            <button
              type="button"
              onClick={() =>
                inputs?.therapyId == "0"
                  ? toast.warn("Please fill the service information")
                  : addService()
              }
              className="mb-2 btn btn-primary custom-btn-green custom-btn-green-auto cursor-pointer"
              data-toggle="button"
              aria-pressed="false"
              autocomplete="off"
            >
              <img
                className="pr-2"
                src="../resources/assets/plus-icon-button.svg"
                alt=""
              />{" "}
              Add Service
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <label className="conetent-title font-bold-500">Added Services</label>
          <table className="table therapist-service-table">
            <thead>
              <th className="thead" style={{ maxWidth: 40, minWidth: 40 }}>
                S No.
              </th>
              <th className="thead" style={{ maxWidth: 100, minWidth: 100 }}>
                Provider Type
              </th>
              {/*<th className="thead" style={{ maxWidth: 100, minWidth: 100 }}>Service Details</th>*/}
              <th className="thead" style={{ maxWidth: 120, minWidth: 120 }}>
                Clinician NPI Number
              </th>
              <th className="thead" style={{ maxWidth: 120, minWidth: 120 }}>
                Clinic NPI Number
              </th>
              {/* <th className="thead" style={{ maxWidth: 120, minWidth: 120 }}>
              Hourly Rate
            </th>
            <th className="thead" style={{ maxWidth: 120, minWidth: 120 }}>
              Discipline
            </th> */}
              <th className="thead" style={{ maxWidth: 80, minWidth: 80 }}>
                Actions
              </th>
            </thead>
            <tbody>
              {therapistServices &&
                therapistServices.map((item, index) => {
                  item.therapyId = item?.therapyId || item?.therapy.id;
                  // console.log("item", item);
                  const currentTherapyId = item?.therapyId || item?.therapy.id;
                  return (
                    <tr>
                      <td style={{ maxWidth: 40, minWidth: 40 }}>
                        <p className="preview">{index + 1}</p>
                      </td>
                      <td style={{ maxWidth: 100, minWidth: 100 }}>
                        {" "}
                        <p className="preview">
                          {item?.therapy?.name
                            ? item?.therapy?.name
                            : getServiceName(currentTherapyId)}
                        </p>
                      </td>
                      {/*<td style={{ maxWidth: 100, minWidth: 100 }}> <p className="preview">{item.serviceDetail}</p></td>*/}
                      <td style={{ maxWidth: 120, minWidth: 120 }}>
                        {" "}
                        <p className="preview">
                          {item.clinicianNpiNumber !== 0
                            ? item.clinicianNpiNumber
                            : ""}
                        </p>
                      </td>
                      <td style={{ maxWidth: 120, minWidth: 120 }}>
                        {" "}
                        <p className="preview">
                          {item.clinicNpiNumber !== 0
                            ? item.clinicNpiNumber
                            : ""}
                        </p>
                      </td>
                      {/* <td>
                      <strong>${item.hourlyRate}/Hr</strong>
                    </td>
                    <td>
                      <strong>{item.disciplines_id}</strong>
                    </td> */}
                      <td style={{ maxWidth: 80, minWidth: 80 }}>
                        <button
                          type="button"
                          onClick={() => removeService(currentTherapyId)}
                          className="btn-rounded-black cursor-pointer"
                        >
                          <img src="../resources/assets/ionic-md-close.svg" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {therapistServices && therapistServices?.length == 0 && (
                <tr className="text-center">
                  <td colSpan="5">
                    <strong>No data found</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="row addtheripist">
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-12 d-flex">
            <button
              type="button"
              className="btn  btn-primary d-block stepper-button-back"
              onClick={(e) => props.handleBack()}
            >
              Back
            </button>
            {!params.id && (
              <button
                className="btn  btn-primary d-block stepper-button-next"
                id="save-and-back"
                type="button"
                onClick={(e) => onSubmitSaveAndBack(false)}
              >
                Save & Return
              </button>
            )}
            <button
              className="btn  btn-primary d-block stepper-button-next"
              type="submit"
              ref={saveNextRef}
            >
              Save & Continue
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default TherapistServiceInformation;
