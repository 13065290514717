import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getUTCTimeToLocalByDateWithMeridium,
  trimName,
} from "../../utils/commonFunctions";
import SessionsDetail from "../../components/SessionsDetail";
import { useSelector } from "react-redux";

function Dashboard(props) {
  const [dasboard, setDashboard] = useState([]);
  const [billingInfo, setBillingInfo] = useState({});
  const [loading, setloading] = useState(false);
  const [archive, setArchive] = useState(false);
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);

  useEffect(() => {
    setloading(true);
    adminService.getAll(`dashboard`).then(
      (res) => {
        if (res?.status === "Success") {
          setDashboard(res.data);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    adminService.getAll(`session-billings/dashboard`).then(
      (res) => {
        if (res?.status === "Success") {
          setBillingInfo(res.data);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  
  const archiveDashboard = (e) => {
    setArchive(!archive);
    console.log("archive", archive);
    if (archive === false) {
      adminService.getAll(`archive/dashboard/rce`).then(
        (res) => {
          if (res?.status === "Success") {
            setBillingInfo(res.data);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
    }else{
      adminService.getAll(`session-billings/dashboard`).then(
        (res) => {
          if (res?.status === "Success") {
            setBillingInfo(res.data);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  const [therapysessions, setTherapySessions] = useState([]);
  const [recentSessions, setRecentSessions] = useState([]);
  function currencyFormat(currency) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency);
  }

  useEffect(() => {
    setloading(true);
    let startFromDateTime = moment().format("YYYY-MM-DD") + "T00:00:00";
    let startToDateTime = moment().format("YYYY-MM-DD") + "T23:59:59";
    adminService
      .getAll(
        `sessions/filter/formatted/?page=0&size=500&sort=startDateTime,DESC&startFromDateTime=${startFromDateTime}&startToDateTime=${startToDateTime}&timeZone=${moment.tz.guess()}`
      )
      .then(
        (res) => {
          if (res?.status === "Success") {
            setTherapySessions(res.data.content);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
    adminService
      .getAll(
        `sessions/filter/formatted/?status=COMPLETED&page=0&size=500&sort=startDateTime,DESC&startFromDateTime=${startFromDateTime}&startToDateTime=${startToDateTime}&timeZone=${moment.tz.guess()}`
      )
      .then(
        (res) => {
          if (res?.status === "Success") {
            setRecentSessions(res.data.content);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  if (loading) {
    return <div className="loader"></div>;
  }

  return (
    <>
      <div className="col-md-6 no-padding">
        <div className="dashboard-count">
          <div className="row">
            <div className="col-lg-5 col-sm-4 no padding">
              <div className="item">
                <img src="../resources/assets/Group 154.png" alt="" />
                <div className="content mr-3 dash-blue-div">
                  <div className="count">{dasboard.todaySessionCount}</div>
                  <div className="title">Today's Sessions</div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 dash-blue col-sm-4 no padding">
              <div className="item">
                <div className="content">
                  <div className="count">{dasboard.upcomingSessionCount}</div>
                  <div className="title">Upcoming Sessions</div>
                </div>
                <Link to={`/therapy-sessions`}>
                  <button className="btn btn-primary custom-btn-green custom-btn-green-sm dash-blue-div">
                    View All
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row dasboard-count-user">
          <div className="col-sm-4">
            <div className="card">
              <div className="card-body text-center">
                <Link to="/therapist" className="view-all-link">
                  View All
                </Link>
                <img
                  src="../resources/assets/filter-icon-user.svg"
                  alt=""
                  className="card-icon"
                />
                <h5 className="card-title">{dasboard.activeTherapistCount}</h5>
                <p className="card-text">Therapist</p>
                <Link to={`/add-therapist`}>
                  <button
                    type="button"
                    className="btn btn-primary custom-btn-green for-ipad-green-btn"
                  >
                    Add Therapist
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="card">
              <div className="card-body text-center">
                <Link to="/family" className="view-all-link">
                  View All
                </Link>
                <img
                  src="../resources/assets/Icon feather-users.svg"
                  alt=""
                  className="card-icon"
                />
                <h5 className="card-title">{dasboard.activeFamilyCount}</h5>
                <p className="card-text">Client</p>
                <Link to={`/add-family`}>
                  <button
                    type="button"
                    className="btn btn-primary custom-btn-green for-ipad-green-btn"
                  >
                    Add Client
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            {tenantPemission?.interpreter && (
              <div className="card">
                <div className="card-body text-center">
                  <Link to="/interpreter" className="view-all-link">
                    View All
                  </Link>
                  <img
                    src="../resources/assets/translation.svg"
                    alt=""
                    className="card-icon"
                  />
                  <h5 className="card-title">
                    {dasboard.activeInterpreterCount}
                  </h5>
                  <p className="card-text">Interpreter</p>
                  <Link to={`/add-interpreter`}>
                    <button
                      type="button"
                      className="btn btn-primary custom-btn-green for-ipad-green-btn"
                    >
                      Add Interpreter
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {tenantPemission?.billing && (
          <div className="no-padding dash-billing-info">
            <div className="custom-table-view">
              <div className="content-header">
                <div className="text-left header-title">
                  Billing Information
                </div>
                <div className="text-right header-btn">
                  <div class="form-check form-switch" style={{ display: "inline-flex", marginRight: '10px' }}>
                    <input onChange={(e) => archiveDashboard(e)} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                    <label class="form-check-label" for="flexSwitchCheckDefault">Archive</label>
                  </div>
                  <Link to={`/client-billing`}>
                    <button className="btn btn-primary custom-btn-yellow">
                      View All
                    </button>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="billing-item col-md-4 no-padding text-center">
                  <div className="">
                    <div className="bill-icon">
                      <img src="../resources/assets/send.svg" alt="" />
                    </div>
                    <h5 className="bill-title">
                      Approval Pending
                    </h5>
                    <p className="bill-text mb-0">
                      {
                        billingInfo.pendingApprovalCount
                          ? billingInfo.pendingApprovalCount
                          : 0
                      }
                    </p>
                  </div>
                </div>
                <div className="billing-item col-md-4 no-padding text-center">
                  <div className="">
                    <div className="bill-icon">
                      <img src="../resources/assets/Group 178.svg" alt="" />
                    </div>
                    <h5 className="bill-title">Payment Done</h5>
                    <p className="bill-text mb-0">
                      {currencyFormat(
                        billingInfo.paymentDone ? billingInfo.paymentDone : 0
                      )}
                    </p>
                  </div>
                </div>
                <div className="billing-item border-right-0 col-md-4 no-padding text-center">
                  <div className=" ">
                    <div className="bill-icon">
                      <img src="../resources/assets/Group 179.svg" alt="" />
                    </div>
                    <h5 className="bill-title">Payment Pending</h5>
                    <p className="bill-text mb-0">
                      {currencyFormat(
                        billingInfo.paymentPending
                          ? billingInfo.paymentPending
                          : 0
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="col-md-4 no-padding c-recent-sessions">
        <div className="custom-table-view mb-3">
          <div className="content-header">
            <div className="text-left header-title">Recent Sessions</div>
            <div className="text-right header-btn">
              <Link
                to={`/therapy-sessions`}
                className="btn btn-primary custom-btn-yellow custom-btn-yellow-sm"
              >
                View All
              </Link>
            </div>
          </div>

          <table className="table table-responsive">
            {recentSessions.length > 0 ? (
              recentSessions &&
              recentSessions.map((item) => (
                <>
                  {item &&
                    item.map((info, i) => (
                      <>
                        {info.therapySession &&
                          info.therapySession.map((subinfo) => {
                            console.log("subinfo", subinfo);
                            return (
                              <tr>
                                <td className={i === 0 && "border-top-0"}>
                                  <img
                                    src={
                                      subinfo?.groupSession
                                        ? "/resources/assets/gUsers.jpg"
                                        : typeof subinfo?.familyMemberDtoSet[0]
                                          ?.profileUrl === "string"
                                          ? subinfo?.familyMemberDtoSet[0]
                                            ?.profileUrl
                                          : "/resources/assets/defaultprofile.png"
                                    }
                                    className="image"
                                    alt=""
                                  />
                                  <img
                                    src="../resources/assets/Icon-clock.svg"
                                    alt=""
                                    className="ml-10px"
                                  />{" "}
                                  {getUTCTimeToLocalByDateWithMeridium(
                                    subinfo.startDateTime
                                  )}{" "}
                                  -{" "}
                                  {getUTCTimeToLocalByDateWithMeridium(
                                    subinfo.endDateTime
                                  )}
                                </td>
                                <td
                                  className={
                                    i === 0 ? "border-top-0 pr-0" : "pr-0"
                                  }
                                >
                                  <Link
                                    to={`/therapy-sessions-details/${subinfo.id}`}
                                  >
                                    Details
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    ))}
                </>
              ))
            ) : (
              <div
                className={`session-detail-section mt-3 ${props.bgColor === "bg-color1" ? "bg-white" : ""
                  }`}
              >
                <div className="text-center">
                  <p className="name mt-2">No Session Available</p>
                </div>
              </div>
            )}
          </table>
        </div>
        <div
          style={{
            height: 1,
            marginTop: -1,
          }}
        />
        <SessionsDetail
          allLink="/therapy-sessions"
          bgColor="ml-0 mt-3"
          therapysessions={therapysessions}
        />
      </div>
    </>
  );
}

export default Dashboard;
