import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { numRegex } from "../../components/Utils/Regex";
export default function AdditionalForm(props) {
    //console.log("invoice id",props);
    const {
        register,
        formState: { errors },
        control,
        watch,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm({
        defaultValues: {
            test: [{ description: "", amount: "" }]
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "test"
    });
    //const [addAdditionalField, setAdditionalField] = useState([{}]);
    const SubmitAdditionalFeild = (data) => {
        console.log("test data", data.test);
        const postData = {
            "invoiceDate": moment().format('YYYY-MM-DD'),
            "invoiceDetailFormList": [...data.test],
            "invoiceNo": props?.invoiceId
        }
        console.log("submit addtional data", postData);
        adminService.PUT(`payments/edit`, JSON.stringify(postData))
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("addtional data", res.data);
                    //setAdditionalField([{ amount: "", description: "" }]);
                    props.setShowAdditional(false);
                    //handleAdditionalClose(false);
                    toast.success("Additional Data adedd successfully");
                } else {
                    toast.error(res.message);
                }

            })
    }
    // const addAdditionalFieldBtn = () => {
    //     setAdditionalField([...addAdditionalField, { amount: "", description: "" }]);
    // };
    // const removePrevLongGoals = (i) => {
    //     const arr1 = [...addAdditionalField];
    //     document.getElementById("amount" + i).value = '';
    //     document.getElementById("dec" + i).value = '';
    //     arr1.splice(i, 1);
    //     setAdditionalField(arr1);
    // };
    // const handleAdditionalField = (e, i) => {
    //     var inputAmount = document.getElementById("amount" + i);
    //     inputAmount.addEventListener("mousewheel",
    //         function (event) {
    //             event.target.blur()
    //         });
    //     const { name, value } = e.target;
    //     console.log("name value", name, value)
    //     let newArr = [...addAdditionalField];
    //     newArr[i] = {
    //         ...newArr[i],
    //         [name]: value,
    //     };
    //     setAdditionalField(newArr);
    //     console.log("additional field", newArr);
    // };
    return (
        <>
            <form onSubmit={handleSubmit(SubmitAdditionalFeild)}>
                {fields.map((item, index) => (
                    <>
                        <p style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>{index + 1}.  Additional Data</span>

                            <button
                                type="button"
                                className="btn btn-primary "
                                style={{
                                    width: "29px",
                                    height: "28px",
                                    padding: "0px",
                                    marginBottom: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: "transparent",
                                    borderColor: "red",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "red"
                                }}
                                onClick={() => remove(index)}
                            >
                                <b style={{ fontSize: "25px" }}>-</b>
                            </button>
                        </p>
                        <hr />

                        <div key={item.id}>
                            <lable className="mb-2">Description ( <span class="required-span">*</span> )</lable>
                            <input type="text" className="form-control mb-3" {...register(`test.${index}.description`, { required: true })} />
                            {errors.test?.[index]?.description && (
                                <p className="fieldError">This field is required.</p>
                            )}
                            <lable className="mb-2">Amount ( <span class="required-span">*</span> )</lable>
                            <input
                                className="form-control mb-3"
                                type="number"
                                {...register(`test.${index}.amount`, { required: true, pattern: numRegex })}
                            />
                            {errors.test?.[index]?.amount && (
                                <p className="fieldError mb-0">This field is required.</p>
                            )}
                            <hr />
                        </div>
                    </>
                ))}
                <button
                    type="button"
                    className="btn btn-success "
                    style={{
                        background: "transparent",
                        color: "#387af6",
                        borderColor: "#387af6",
                        padding: "6px 10px",
                        marginBottom: "10px",
                        borderRadius: "10px"
                    }}
                    onClick={() => append({ description: "", amount: "" })}
                >
                    + Add More Detail
                </button><br />
                <input className="btn btn-success" type="submit" />
            </form>
            {/* <form onSubmit={handleSubmit(SubmitAdditionalFeild)}>
                {addAdditionalField.map((item, index) => (
                    <>

                        <p style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>{index + 1}.  Additional Data</span>

                            <button
                                type="button"
                                className="btn btn-primary "
                                style={{
                                    width: "29px",
                                    height: "28px",
                                    padding: "0px",
                                    marginBottom: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: "transparent",
                                    borderColor: "red",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "red"
                                }}
                                onClick={(e) => removePrevLongGoals(index, e)}
                            >
                                <b style={{ fontSize: "25px" }}>-</b>
                            </button>
                        </p>
                        <hr />
                        <lable className="mb-2">Description ( <span class="required-span">*</span> )</lable>
                        <input {...register('description', { onChange: (e) => handleAdditionalField(e, index), required: true })} type="text" id={"dec" + index} className="form-control mb-3" name="description" defaultValue={item?.description} />
                        {errors.description && (
                            <p className="fieldError">This field is required.</p>
                        )}
                        <lable className="mb-2">Amount ( <span class="required-span">*</span> )</lable>
                        <input {...register('amount', { onChange: (e) => handleAdditionalField(e, index), required: true, pattern: numRegex })} type="number" id={"amount" + index} className="form-control mb-3" name="amount" defaultValue={item?.amount} />
                        {errors.amount && (
                            <p className="fieldError mb-0">This field is required.</p>
                        )}
                        <hr />

                    </>
                ))}
                <button
                    type="button"
                    className="btn btn-success "
                    style={{
                        background: "transparent",
                        color: "#387af6",
                        borderColor: "#387af6",
                        padding: "6px 10px",
                        marginBottom: "10px",
                        borderRadius: "10px"
                    }}
                    onClick={() => addAdditionalFieldBtn()}
                >
                    + Add More Detail
                </button><br />
                <button className="btn btn-success" type="submit">Submit</button>
            </form> */}
        </>
    )
}
