import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { adminService } from "../../services/adminService";
import moment from "moment-timezone";
import { daysJson, monthJson } from "../../config/Config";
import {
  getUTCTimeToLocalByDateWithMeridium,
  calculateDuration,
  maskPhoneNumber,
} from "../../utils/commonFunctions";
import DeleteSession from "../../pages/Representative/DeleteSession";
import EditSessionRequest from "../../pages/Representative/EditSessionRequest";
import DashboardSessionCalender from "../../pages/DashboardSessionCalender";
import RecentMessages from "../../components/messages/RecentMessages";

function FamilyDashboard(props) {
  const [teamdetails, setTeamDetails] = useState([]);
  const [recentQuery, setRecentQuery] = useState([]);
  const [therapysessions, setTherapySessions] = useState([]);
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [modalOpenClose1, setModalOpenClose1] = useState(false);
  const [loading, setloading] = useState(false);
  const [iscancelreq, setIsCancelReq] = useState(false);
  const [deletesessiondetail, setdeletesessiondetail] = useState({
    info: "",
  });

  useEffect(() => {
    adminService.getAll(`queries/recent`).then(
      (res) => {
        if (res?.status === "Success") {
          setRecentQuery(res.data.sort((a, b) => (a.id > b.id ? -1 : 1)));
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );

    adminService.getAll(`families/my-team/web`).then(
      (res) => {
        if (res?.status === "Success") {
          setTeamDetails(res.data);
        } else {
          res?.message && toast.error(res?.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    adminService
      .getAll(
        `families/filter/formatted?status=UP_COMING&page=0&size=2000&sort=startDateTime,DESC`
      )
      .then(
        (res) => {
          if (res?.status === "Success") {
            setTherapySessions(res.data.content);
          } else {
            res?.message && toast.error(res?.message);
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const cancelSessionReq = (id, name) => {
    let data = {
      sessionid: id,
      therapyname: name,
    };
    setdeletesessiondetail((deletesessiondetail) => ({
      ...deletesessiondetail,
      ["info"]: data,
    }));
    setModalOpenClose1(true);
    setIsCancelReq(true);
  };

  const Editsession = (id, name) => {
    let data = {
      sessionid: id,
      therapyname: name,
    };
    setdeletesessiondetail((deletesessiondetail) => ({
      ...deletesessiondetail,
      ["info"]: data,
    }));
    setModalOpenClose1(true);
    setIsCancelReq(false);
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <DashboardSessionCalender />

        <div className="dashboard-count">
          <div className="row">
            <div className="col-md-5">
              <div className="font-content1 ml-1">
                <span>My Team</span>
              </div>
            </div>
            <div className="col-md-7">
              <div className="text-right">
                <button className="btn btn-primary mr-1 custom-btn-yellow custom-btn-yellow-sm">
                  <Link to={`/my-team`}>View All</Link>
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            {teamdetails.therapists &&
              teamdetails.therapists.map((item, i) => {
                const {
                  profilePicUrl,
                  firstName,
                  lastName,
                  about,
                  email,
                  phone,
                } = item.userProfile;
                return (
                  <div className="col-lg-4" key={i}>
                    <DashboardTeamMember
                      image={profilePicUrl}
                      firstName={firstName}
                      lastName={lastName}
                      about={about}
                      email={email}
                      phone={phone}
                    />
                  </div>
                );
              })}
          </div>
        </div>

        <div className="no-padding dash-billing-info">
          <div className="custom-table-view">
            <div className="row">
              <div className="col-lg-6">
                <div className="content-header border-0">
                  <div className="text-left header-title">Recent Queries</div>
                  {/* <div className="text-right header-btn"><button className="btn btn-primary custom-btn-yellow custom-btn-yellow-sm">
                                        <Link to={`/therapy-sessions`} >
                                            View All
                                        </Link>
                                    </button></div> */}
                </div>
                <table className="table">
                  <tbody>
                    {recentQuery &&
                      recentQuery.map((item, index) =>
                        index > 2 ? null : (
                          <tr>
                            <td>
                              <div className="col-auto">
                                <div className="user-info">
                                  <span className="admin-name justify-content-start">
                                    <img
                                      src={
                                        item.creatorProfilePic
                                          ? item.creatorProfilePic
                                          : "/resources/assets/defaultprofile.png"
                                      }
                                      alt=""
                                      className="image"
                                    />
                                    <div className="content ml-2">
                                      <span>{item.childName}</span>
                                      <small>
                                        <br />
                                        {item.detail}
                                      </small>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td>
                              <Link to={`/case-notes/` + item.caseNoteId}>
                                <img
                                  className="pl-2"
                                  src="../resources/assets/arrow.svg"
                                  alt=""
                                />
                              </Link>
                            </td>
                          </tr>
                        )
                      )}
                    {recentQuery && recentQuery.length === 0 ? (
                      <tr>
                        <td colSpan="2">
                          <div className="text-center">
                            <h5 className=" mt-2">No Queries Found</h5>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>

              <div className="col-md-6 no-padding">
                <RecentMessages />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpenClose && (
        <DeleteSession
          //successSub={() => successSub()}
          closeModal={() => setModalOpenClose(false)}
          sessioninfo={deletesessiondetail}
        />
      )}
      {modalOpenClose1 && (
        <EditSessionRequest
          isdelete={iscancelreq}
          // successSub={() => successSub()}
          closeModal={() => setModalOpenClose1(false)}
          sessioninfo={deletesessiondetail}
        />
      )}
    </>
  );
}

export default FamilyDashboard;

const DashboardTeamMember = ({
  image,
  firstName,
  lastName,
  about,
  email,
  phone,
}) => {
  return (
    <div className="c-dashboard-team-member text-white">
      <div className="c-dashboard-team-member__img-wrapper">
        <img
          src={image}
          className="c-dashboard-team-member__image image"
          alt=""
        />
      </div>
      <div className="content c-dashboard-team-member__content">
        <span className="c-dashboard-team-member__name">
          {firstName} {lastName}
        </span>
        {/* <span className="c-dashboard-team-member__info"><br />{about}</span> */}
        <span className="c-dashboard-team-member__info">
          <br />
          <img
            src="../resources/assets/Group 7396.svg"
            alt=""
            className="mr-2"
          />
          {email}
        </span>
        <span className="c-dashboard-team-member__info">
          <br />
          <img
            src="../resources/assets/Icon feather-phone.svg"
            className="mb-1"
            alt=""
          />
          <span className="ml-2">{maskPhoneNumber(phone)}</span>
        </span>
      </div>
    </div>
  );
};
