import React from "react";

function NameSignDate(props) {
  const {
    date,
    familyImageURL,
    isSubmit,
    setOpen,
    signTitle,
    errors,
    familyInfo,
    updateInfo,
    inputName,
    register,
  } = props;
  return (
    <>
      <div
        className="row mt-30"
        style={{ marginLeft: 0, width: "100%", display: "flex" }}
      >
        <div style={{ display: "block" }}></div>
        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-flex",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}>
            <b>{signTitle}   Name</b>
          </div>
        </div>
        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-flex",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}>
            <b>Signature</b>
          </div>
        </div>
        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-flex",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}>
            <b>Date</b>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{ marginLeft: 0, width: "100%", display: "flex" }}
      >
        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-flex",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          {isSubmit ? (
            inputName
          ) : (
            <input
              //maxlength="35"
              type="text"
              name={inputName}
              className={`form-control ${errors[inputName] && "is-invalid"}`}
              defaultValue={inputName}
              //onChange={(e) => updateInfo(e)}
              {...register(`${inputName}`,{ onChange:(e) => updateInfo(e),required: true })}
            />
          )}
        </div>
        <div
          className="col-md-4"
          style={{ width: "33%", display: "inline-flex" }}
        >
          <div className="form-row1" style={{ width: "90%",marginLeft: "-15px" }}>
            <div className="col-md-12">
              {familyImageURL ? (
                <img
                  src={
                    props?.isLoading
                      ? props?.userType == "therapist"
                        ? "familyInfo?.therapistSign"
                        : "familyInfo?.familySign"
                      : familyImageURL
                  }
                  alt="my signature"
                  className="sign-img"
                  style={{ maxwidth: "160px", maxHeight: "80px" }}
                />
              ) : familyInfo?.familySign ? (
                <img
                  src={familyInfo?.familySign}
                  alt="my signature"
                  className="sign-img"
                  style={{ maxwidth: "160px", maxHeight: "80px" }}
                />
              ) : familyInfo?.therapistSign ? (
                <img
                  src={familyInfo?.therapistSign}
                  alt="my signature"
                  className="sign-img"
                  style={{ maxwidth: "160px", maxHeight: "80px" }}
                />
              ) : null}
            </div>
            <div className="col-md-12 pl-0">
              {!isSubmit && (
                <button
                style={{ marginTop:"7px" }}
                  type="button"
                  className="btn btn-success create-sign-btn"
                  onClick={() => setOpen(true)}
                >
                  {familyImageURL ? "Update" : "Create"} Your Signature
                </button>
              )}
            </div>
          </div>
        </div>

        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-flex",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}>
            {date}
          </div>
        </div>
      </div>
    </>
  );
}

export default NameSignDate;