import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { adminService } from "../../services/adminService";

function AccountDetails() {
  const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
  const [accountDetail, setAccountDetail] = useState({});
  const [accountComplete, setAccountComplete] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    adminService
      .getAll(`account/account-details`)
      .then((res) => {
        console.log("account detail customer", res.data);
        setAccountDetail(res.data);
        //setBillingList(res?.data?.content);
      })
    getAccountId();
  }, [])
  const getApi = () => {
    setLoader(true);
    adminService
      .getAll(`account/refresh-url`)
      .then((res) => {
        console.log("stripe customer", res.data);
        setLoader(false);
        window.location.href = res.data;

        //setCustomerId(res?.data?.stripeCustomerId);
      })
  }
  const getAccountId = async () => {
    await adminService.getAll(`misc/billing-company-detail?tenantId=${tenantId}`).then(
      (res) => {
        if (res.status === "Success") {
          //setDashboard(res.data);
          console.log("connect account", res.data.stripeAccountCompleted);
          setAccountComplete(res?.data?.stripeAccountCompleted);
        } else {
          //toast.error(res.message);
        }
        //setloadings(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  return (
    <div className="col-md-10 no-padding d-flex flex-column">
      <div className="no-padding dash-billing-info mr-0 flex-grow-1">
        <div className="custom-table-view min-h-100">
          {loader && (
            <div className="loader-overlay">
            <div className="loader" />
            </div>
          )}
          <div className="content-header">
            <div className="text-left header-title">Account Details</div>
            <div className="text-right">
              {accountComplete ? '' : <p><b>Note:</b> Please <a onClick={getApi} type="button"><b style={{ textDecoration: "underline", color: "blue", fontWeight: "normal" }}>Click Here</b></a> to complete your account details.</p>}
            </div>
          </div>
          <div className="p-3">
            <div className="row">
              <div className="col-md-4 mb-2">
                <p><b>MCC</b></p>
                <p>{accountDetail?.mcc}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>Name</b></p>
                <p>{accountDetail?.name}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>Support Email</b></p>
                <p>{accountDetail?.supportEmail}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-2">
                <p><b>Support Phone</b></p>
                <p>{accountDetail?.supportPhone}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>Support Url</b></p>
                <p>{accountDetail?.supportUrl}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>Email</b></p>
                <p>{accountDetail?.email}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-2">
                <p><b>City</b></p>
                <p>{accountDetail?.supportAddressDto?.city}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>Country</b></p>
                <p>{accountDetail?.supportAddressDto?.country}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>Address line1</b></p>
                <p>{accountDetail?.supportAddressDto?.line1}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-2">
                <p><b>Address line2</b></p>
                <p>{accountDetail?.supportAddressDto?.line2}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>Postal Code</b></p>
                <p>{accountDetail?.supportAddressDto?.postal_code}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>State</b></p>
                <p>{accountDetail?.supportAddressDto?.state}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-2">
                <p><b>Card Payments</b></p>
                <p>{accountDetail?.cardPayments}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b>Default Currency</b></p>
                <p>{accountDetail?.defaultCurrency}</p>
              </div>
              <div className="col-md-4 mb-2">
                <p><b></b></p>
                <p></p>
              </div>
            </div>
            <h5 className="mt-2">External Accounts</h5>
            <hr />

            {accountDetail?.externalAccountsDtos?.map((item, i) => (
              <div className="row">
                <div className="col-md-4 mb-2">
                  <p><b>Account Holder Name</b></p>
                  <p>{item?.accountHolderName}</p>
                </div>
                <div className="col-md-4 mb-2">
                  <p><b>Account Holder Type</b></p>
                  <p>{item?.accountHolderType}</p>
                </div>
                <div className="col-md-4 mb-2">
                  <p><b>Account Type</b></p>
                  <p>{item?.accountType}</p>
                </div>
                <div className="col-md-4 mb-2">
                  <p><b>Available Payout Methods</b></p>
                  <p>{item?.availablePayoutMethods?.[0]?.standard}</p>
                </div>
                <div className="col-md-4 mb-2">
                  <p><b>Bank Name</b></p>
                  <p>{item?.bankName}</p>
                </div>
                <div className="col-md-4 mb-2">
                  <p><b>Country</b></p>
                  <p>{item?.country}</p>
                </div>
                <div className="col-md-4 mb-2">
                  <p><b>Currency</b></p>
                  <p>{item?.currency}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AccountDetails;