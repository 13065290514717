import React from "react";
import ViewSessionCalendar from "../ViewSessionCalendar";
import { useSelector } from "react-redux";

function SelectInterpreter(props) {
  const {
    params,
    interpreter,
    errors,
    handleChange,
    inputs,
    currentRole,
    setModalOpenClose1,
    modalOpenClose1,
  } = props;
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
  return (
    <>
      <div className="col-md-12">
        <div className="form-group">
          <label>Interpreter</label>
          <div className="row ml-2">
            <img
              src="../resources/assets/interpreter.svg"
              className="img-fluid"
              alt=""
            />
            <div className="col-md-10">
              <div className="input-group">
                {
                  !params.id && currentRole === "ROLE_REGIONAL_EARLY_STEPS" ? (
                    <select
                      className={`custom-select ${
                        errors.interpreterId && "is-invalid"
                      }`}
                      onChange={handleChange}
                      value={inputs.interpreterId}
                      defaultValue={inputs.interpreterId}
                      name="interpreterId"
                      disabled={params.id ? "disabled" : ""}
                    >
                      <option value="">None Needed</option>
                      {tenantPemission?.interpreter &&
                        interpreter &&
                        interpreter.map((info, index) => (
                          <>
                          {info.userProfile.active ? 
                            <option value={info.id}>
                              {info.userProfile.firstName}{" "}
                              {info.userProfile.lastName}
                            </option>
                            :
                            <></>
                          }
                          </>
                        ))}
                    </select>
                  ) : (
                    <>
                      <select
                        className={`custom-select ${
                          errors.interpreterId && "is-invalid"
                        }`}
                        onChange={handleChange}
                        value={inputs.interpreterId}
                        defaultValue={inputs.interpreterId}
                        name="interpreterId"
                        disabled={params.id ? "disabled" : ""}
                      >
                        <option value="" selected>
                          None Needed
                        </option>
                        {interpreter && interpreter.userProfile && interpreter.userProfile.active && (
                          <option
                            value={
                              interpreter &&
                              interpreter.userProfile &&
                              interpreter.id
                            }
                          >
                            {interpreter &&
                              interpreter.userProfile &&
                              interpreter.userProfile.firstName}{" "}
                            {interpreter &&
                              interpreter.userProfile &&
                              interpreter.userProfile.lastName}
                          </option>
                        )}
                      </select>
                      <div className="input-group-prepend">
                        <img
                          className="m-auto"
                          src="../resources/assets/Polygon 1.svg"
                          alt=""
                        />
                      </div>
                    </>
                  )
                  // interpreter && interpreter.userProfile && interpreter.userProfile.firstName} {interpreter && interpreter.userProfile && interpreter.userProfile.lastName
                }
              </div>
              {inputs.interpreterId ? (
                <>
                  <a
                    href="javascript:void()"
                    onClick={() => setModalOpenClose1(true)}
                  >
                    View Calendar
                  </a>
                  {modalOpenClose1 && (
                    <ViewSessionCalendar
                      closeModal={() => setModalOpenClose1(false)}
                      userrole="ROLE_INTERPRETER"
                      userid={inputs.interpreterId}
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectInterpreter;
