import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminService } from "../../services/adminService";
import { useSelector } from "react-redux";
import PendingFamilyList from "./FamilyTabs/PendingFamilyList";
import PendingTherapistList from "./FamilyTabs/PendingTherapistList";
import PendingInterpreterList from "./FamilyTabs/PendingInterpreterList";

function PendingUsersOnboarding(props) {
  let location = useLocation();
  let history = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const paramtab = urlParams.get("tab");
  const [marketingReg, setMarketingReg] = useState([]);
  const [loading, setloading] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [families, setFamilies] = useState([]);
  const profile = useSelector((state) => state.adminUser.profile);
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
  const [currentTab, setCurrentTab] = useState(
    paramtab == "family"
      ? "family"
      : paramtab == "therapist"
      ? "therapist"
      : paramtab == "interpreter"
      ? "interpreter"
      : "family"
  );

  useEffect(() => {
    const id = profile?.therapistId;
    if (id) {
      adminService
        .getAll(`therapists/${id}`)
        .then((res) => {
          // setTherapistInfo(res.data);
          if (res?.data) {
            profile?.userType == "ROLE_THERAPIST" &&
              res?.data?.onboardPermission == "false" &&
              history("/");
          }
        })
        .catch((err) => console.log(err));
    }
  }, [profile?.therapistId]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setCurrentTab(
  //       paramtab == "family"
  //         ? "family"
  //         : paramtab == "therapist"
  //         ? "therapist"
  //         : "family"
  //     );
  //   }, 1000);
  // }, [paramtab]);
  // useEffect(() => {
  //   setloading(true);
  //   adminService
  //     .getAll(
  //       `marketing-registrations/all?onboardedToPlatform=false&userType=${status}&name=${search}&page=0&size=10&sort=id,DESC`
  //     )
  //     .then(
  //       (res) => {
  //         if (res.status === "Success") {
  //           setMarketingReg(res.data.content);
  //         } else {
  //           toast.error(res.message);
  //         }
  //         setloading(false);
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }, [search, status]);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0 mb-3">
          <div className="card border-0 shadow-sm">
            <div className="p-case-notes__header p-3">
              <div className="text-left header-title">Pending Onboardings</div>
            </div>
          </div>
        </div>

        <div className="card border-0 shadow-sm">
          <div className="p-case-notes__main-box">
            <div className="p-case-notes__table p-case-notes__table--full">
              <div className="p-case-notes__table-body soap-note-tabs">
                {profile?.userType != "ROLE_THERAPIST" && (
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() => setCurrentTab("family")}
                    >
                      <a
                        className={`nav-link ${
                          currentTab == "family" ? "active" : ""
                        }`}
                        id="family-tab"
                        data-toggle="tab"
                        href="#family"
                        role="tab"
                        aria-controls="family"
                        aria-selected="true"
                      >
                        Client
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setCurrentTab("therapist")}
                    >
                      <a
                        className={`nav-link ${
                          currentTab == "therapist" ? "active" : ""
                        }`}
                        id="therapist-tab"
                        data-toggle="tab"
                        href="#therapist"
                        role="tab"
                        aria-controls="therapist"
                        aria-selected="false"
                      >
                        Therapist
                      </a>
                    </li>
                    {tenantPemission?.interpreter == false ? <></> :
                    <li
                      className="nav-item"
                      onClick={() => setCurrentTab("interpreter")}
                    >
                      <a
                        className={`nav-link ${
                          currentTab == "interpreter" ? "active" : ""
                        }`}
                        id="interpreter-tab"
                        data-toggle="tab"
                        href="#interpreter"
                        role="tab"
                        aria-controls="interpreter"
                        aria-selected="false"
                      >
                        Interpreter
                      </a>
                    </li>}
                  </ul>
                )}
                <div
                  className="d-block tab-content"
                  id="myTabContent"
                  style={{ paddingBottom: 20 }}
                >
                  <div
                    className={`tab-pane fade ${
                      currentTab == "family" ? "active show" : ""
                    }`}
                    id="family"
                    role="tabpanel"
                    aria-labelledby="family-tab"
                  >
                    <PendingFamilyList />
                  </div>
                  <div
                    className={`tab-pane fade ${
                      currentTab == "therapist" ? "active show" : ""
                    }`}
                    id="therapist"
                    role="tabpanel"
                    aria-labelledby="therapist-tab"
                  >
                    <PendingTherapistList />
                  </div>
                  <div
                    className={`tab-pane fade ${
                      currentTab == "interpreter" ? "active show" : ""
                    }`}
                    id="interpreter"
                    role="tabpanel"
                    aria-labelledby="interpreter-tab"
                  >
                    <PendingInterpreterList />
                  </div>
                </div>
              </div>
              {/* 
            <form>
              <div className="row">
                <div className="col-md-6 justify-content-between align-items-center mt-4 mb-4 d-flex">
                  <div className="col-auto w-75">
                    <input
                      name="text"
                      className="form-control search"
                      placeholder="Search by Name"
                      onChange={(e) => setSearch(e.target.value)}
                      autoFocus
                    />
                  </div>

                  <div className="col-auto">
                    <select
                      className="form-control time h-46"
                      onClick={(e) => setStatus(e.target.value)}
                      id="exampleFormControlSelect1"
                    >
                      <option className="form-control time" value="">
                        All
                      </option>
                      <option
                        className="form-control time"
                        value="ROLE_THERAPIST"
                      >
                        Therapist
                      </option>
                      <option
                        className="form-control time"
                        value="ROLE_INTERPRETER"
                      >
                        Interpreter
                      </option>
                      <option className="form-control time" value="ROLE_FAMILY">
                        Family
                      </option>
                    </select>
                  </div>

                  <div className="col-auto">
                    <button className="btn btn-theme-green">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="32"
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-2"></div>
                <div className="col-md-2"></div>
              </div>
            </form> */}
              {/* 
            <div className="row no-gutters">
              <div className="col-md-12 therapist p-0">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">User Type</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <div className="loader mt-5"></div>
                      </tbody>
                    ) : (
                      <>
                        {marketingReg.length > 0 ? (
                          marketingReg &&
                          marketingReg.map((item, index) => (
                            <tbody>
                              <tr className="cursor-pointer" key={index}>
                                <th>
                                  <div className="user-info">
                                    <span className="admin-name">
                                      <div className="content">
                                        <span>
                                          {item.firstName} {item.lastName}
                                        </span>
                                      </div>
                                    </span>
                                  </div>
                                </th>
                                <td>
                                  <span className="text-capitalize">
                                    {(item.userType || "")
                                      .toLowerCase()
                                      .replace("role_", "")}
                                  </span>
                                </td>
                                <td>{item.phone}</td>
                                <td>
                                  {item.userType === "ROLE_THERAPIST" ? (
                                    <Link
                                      to={"/add-therapist/" + item.id}
                                      className="view-all-link"
                                    >
                                      Complete Onboarding
                                      <img
                                        className="pl-2"
                                        src="../resources/assets/arrow.svg"
                                        alt=""
                                      />
                                    </Link>
                                  ) : item.userType === "ROLE_FAMILY" ? (
                                    <Link
                                      to={"/add-family/" + item.id}
                                      className="view-all-link"
                                    >
                                      Complete Onboarding
                                      <img
                                        className="pl-2"
                                        src="../resources/assets/arrow.svg"
                                        alt=""
                                      />
                                    </Link>
                                  ) : (
                                    <Link
                                      to={"/add-interpreter/" + item.id}
                                      className="view-all-link"
                                    >
                                      Complete Onboarding
                                      <img
                                        className="pl-2"
                                        src="../resources/assets/arrow.svg"
                                        alt=""
                                      />
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>
                                <div className="mt-5">
                                  <h5>No Data Available</h5>
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PendingUsersOnboarding;
