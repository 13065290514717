import { useState } from "react";

const usePageState = () => {
  const [pageState, setPageState] = useState({
    error: false,
    data: "",
    loading: false,
  });
  return {
    pageState,
    setPageState,
  };
};

export default usePageState;
