import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { adminService } from "../../services/adminService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import adminAction from "../../redux/actions/adminAction";
import {
  disableNotifications,
  enableNotifications,
  getDisabledNotifications,
  getListOfNotifictionsLabels,
} from "../../services/userServices";
import {
  bodyLoader,
  _toastHandleErr,
  getUTCTimeToLocalByDateWithMeridium24,
} from "../../utils/commonFunctions";
import appAction from "../../redux/actions/appAction";
import InputFileBtn from "../InputFileBtn";
import EditChildBioModal from "../Family/EditChildBioModal";
import AppModal from "../../components/AppModal";
import AddService from "../Representative/AddService";

function MySetting(props) {
  localStorage.setItem("userUniqueId", props?.userUniqueId);
  let isUserUniqueId = localStorage.getItem("userUniqueId");

  localStorage.setItem("roles", props?.roles);
  let roles = localStorage.getItem("roles");
  console.log("props", props);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [isImageTouched, setIsImageTouched] = useState(false);
  let user = useSelector((state) => state.adminUser.user);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  // const [profile, setProfile] = useState([]);
  const [childList, setChildList] = useState([]);
  const [inputs, setInputs] = useState({
    password: "",
    confimPassword: "",
    userUniqueId: user.accessToken && user.userDetails.userUniqueId,
    profileImage: profileImage,
  });
  const [childProfiles, setChildProfiles] = useState([]);
  const [therapistdetails, setTherapistDetails] = useState({});
  const [isNotifications, setIsNotifications] = useState(null);
  const [childBioModal, setChildBioModal] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [isdelete, setIsDelete] = useState(false);
  const [editservicedetail, setEditServiceDetail] = useState({
    info: "",
  });
  const [userUniqeId, setUserUniqeId] = useState('');
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const tab = urlParams.get("tab");
  const [currentTab, setCurrentTab] = useState(tab ? tab : "own");
  const profile = useSelector((state) => state.adminUser.profile);
  const userType = useSelector((state) => state?.adminUser?.profile?.userType);
  const userRoles = user?.userDetails?.roles;
  const enableSign =
    userRoles?.includes("ROLE_THERAPIST") || userRoles?.includes("ROLE_FAMILY") || userRoles?.includes("ROLE_RCE");

  const [loadingChildList, setLoadingChildList] = useState(false);
  const [notificationList, setIsNotificationList] = useState({});
  const [about, setAbout] = useState("");
  useEffect(() => {
    setLoading(true);
    profile?.userType == "ROLE_FAMILY" && getChildList();
    console.log("profile", profile?.userType, roles);
    getUserDetail();
    getNotification();
    return () => {
      dispatch(appAction.setAppLoading(false));
    };

  }, []);

  const getUserDetail = () => {
    adminService
      .getAll(`user-profiles/user-detail`)
      .then(
        (res) => {
          if (res?.status === "Success") {
            setUserUniqeId(res.data.userUniqeId);
            console.log("familyRes", res.data);
            // setProfile(res.data);
            dispatch(adminAction.setProfileData(res.data));
            setAbout(res.data.about);
            getDisabledNotifications(
              res.data.userType == "ROLE_RCE" ? "admin" : res.data.userUniqeId
            ).then((res) => {
              setIsNotifications(!(res.length > 0));
              setIsNotification(!(res.length > 0));
            });
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    profile?.userType == "ROLE_FAMILY" && getChildList();
    console.log("second useEffext");
  }, [profile?.userType]);
  const getNotification = () => {
    console.log("isUserUniqueId", isUserUniqueId, roles);
    if (roles == "ROLE_RCE") {
      let apiLink = "control-notification/admin";
      console.log("userLink admin", apiLink);
      setTimeout(() => {
        adminService
          .getAllNew(apiLink)
          .then(
            (res) => {
              if (res?.status === "Success") {
                console.log("notificationRes", res.data);
                setIsNotificationList(res.data);
              } else {
                res?.message
                  ? toast.error(res?.message)
                  : console.log("Somthing went wrong");
              }
              setLoading(false);
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            setLoading(false);
          });
      }, 2500)
    }
    if (roles == "ROLE_THERAPIST") {
      let apiLink = "control-notification/" + isUserUniqueId;
      console.log("userLink therapist", apiLink);
      setTimeout(() => {
        adminService
          .getAllNew(apiLink)
          .then(
            (res) => {
              if (res?.status === "Success") {
                console.log("notificationRes", res.data);
                setIsNotificationList(res.data);
              } else {
                res?.message
                  ? toast.error(res?.message)
                  : console.log("Somthing went wrong");
              }
              setLoading(false);
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            setLoading(false);
          });
      }, 2500)
    }
    if (roles == "ROLE_INTERPRETER") {
      let apiLink = "control-notification/" + isUserUniqueId;
      console.log("userLink interpreter", apiLink);
      setTimeout(() => {
        adminService
          .getAllNew(apiLink)
          .then(
            (res) => {
              if (res?.status === "Success") {
                console.log("notificationRes", res.data);
                setIsNotificationList(res.data);
              } else {
                res?.message
                  ? toast.error(res?.message)
                  : console.log("Somthing went wrong");
              }
              setLoading(false);
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            setLoading(false);
          });
      }, 2500)
    }
    if (roles == "ROLE_FAMILY") {
      let apiLink = "control-notification/" + isUserUniqueId;
      console.log("userLink family", apiLink);
      setTimeout(() => {
        adminService
          .getAllNew(apiLink)
          .then(
            (res) => {
              if (res?.status === "Success") {
                console.log("notificationRes", res.data);
                setIsNotificationList(res.data);
              } else {
                res?.message
                  ? toast.error(res?.message)
                  : console.log("Somthing went wrong");
              }
              setLoading(false);
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            setLoading(false);
          });
      }, 2500)
    }
    // let apiLink = profile?.userType == "ROLE_RCE" ? "control-notification/admin" : profile?.userType == 'ROLE_THERAPIST' ? "control-notification/" + isUserUniqueId : profile?.userType == 'ROLE_FAMILY' ? "control-notification/" + isUserUniqueId : '';
    // console.log("userLink", apiLink);
    // setTimeout(()=>{
    // adminService
    //   .getAllNew(apiLink)
    //   .then(
    //     (res) => {
    //       if (res?.status === "Success") {
    //         console.log("notificationRes", res.data);
    //         setIsNotificationList(res.data);
    //       } else {
    //         res?.message
    //           ? toast.error(res?.message)
    //           : console.log("Somthing went wrong");
    //       }
    //       setLoading(false);
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   )
    //   .finally(() => {
    //     setLoading(false);
    //   });
    // },3000)
  }
  useEffect(() => {
    profile?.therapistId && getTherapistInfo(profile.therapistId);
  }, [profile.therapistId]);

  const getTherapistInfo = (id) => {
    adminService.getAll(`therapists/` + id).then(
      (res) => {
        if (res?.data) {
          setTherapistDetails(res?.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getChildList = () => {
    adminService.getAll(`families/get-family-member`).then(
      (res) => {
        setLoadingChildList(false);
        setChildList(res.data.content);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const onSubmit = async () => {
    if (isNotifications != isNotification) {
      const fn = isNotifications ? enableNotifications : disableNotifications;
      // page-loader
      let data = {
        topic: user?.userDetails?.userUniqueId,
        type: [],
      };
      // type: isNotifications ? await getListOfNotifictionsLabels() :
      !isNotifications && (data.userRole = profile?.userType);

      dispatch(appAction.setAppLoading(true));
      fn(JSON.stringify(data))
        .then((res) => {
          // toast.success("Notification Updated");
          if (inputs.password && inputs.confimPassword) {
            updatePassword();
          } else {
            const uniqId =
              profile?.userType == "ROLE_RCE" ? "admin" : profile?.userUniqeId;
            getDisabledNotifications(uniqId).then((res) => {
              setIsNotifications(!(res.length > 0));
              setIsNotification(!(res.length > 0));
            });
            toast.success(
              `Notification ${isNotifications ? "enabled" : "disabled"
              } successfully!`
            );
          }
          setIsNotifications(!isNotifications);
        })
        .catch(_toastHandleErr)
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    } else {
      if (inputs.password && inputs.confimPassword) {
        updatePassword();
      } else {
        // toast.warning(`No data to update!`);
      }
    }
    //let apiLink = profile?.userType == "ROLE_RCE" ? "control-notification/admin" : profile?.userType == 'ROLE_THERAPIST' ? "control-notification/" + props?.userUniqueId : profile?.userType == 'ROLE_FAMILY' ? "control-notification/" + props?.userUniqueId : '';
    if (profile?.userType && roles == "ROLE_RCE") {
      let apiLink = "control-notification/admin";
      console.log("userLink admin", apiLink);
      const sdf = adminService.putNew(apiLink, JSON.stringify(notificationList));

      sdf.then((res) => {
        //setPageState((item) => ({ ...item, loading: false }));
        if (res.status == "Success") {
          setIsNotificationList(res?.data);
          console.log("res?.data setIsNotificationList", res?.data);
          toast.success(res?.message);
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      }).catch((err) => {
        toast.error(err);
        //setPageState((item) => ({ ...item, loading: false }));
      });
    }
    if (roles == "ROLE_THERAPIST") {
      let apiLink = "control-notification/" + isUserUniqueId;
      console.log("userLink therapist", apiLink);
      const sdf = adminService.putNew(apiLink, JSON.stringify(notificationList));

      sdf.then((res) => {
        //setPageState((item) => ({ ...item, loading: false }));
        if (res.status == "Success") {
          setIsNotificationList(res?.data);
          console.log("res?.data setIsNotificationList", res?.data);
          toast.success(res?.message);
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      }).catch((err) => {
        toast.error(err);
        //setPageState((item) => ({ ...item, loading: false }));
      });
    }
    if (roles == "ROLE_INTERPRETER") {
      let apiLink = "control-notification/" + isUserUniqueId;
      console.log("userLink interpreter", apiLink);
      const sdf = adminService.putNew(apiLink, JSON.stringify(notificationList));

      sdf.then((res) => {
        //setPageState((item) => ({ ...item, loading: false }));
        if (res.status == "Success") {
          setIsNotificationList(res?.data);
          console.log("res?.data setIsNotificationList", res?.data);
          toast.success(res?.message);
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      }).catch((err) => {
        toast.error(err);
        //setPageState((item) => ({ ...item, loading: false }));
      });
    }
    if (roles == "ROLE_FAMILY") {
      let apiLink = "control-notification/" + isUserUniqueId;
      console.log("userLink family", apiLink);
      const sdf = adminService.putNew(apiLink, JSON.stringify(notificationList));

      sdf.then((res) => {
        //setPageState((item) => ({ ...item, loading: false }));
        if (res.status == "Success") {
          setIsNotificationList(res?.data);
          console.log("res?.data setIsNotificationList", res?.data);
          toast.success(res?.message);
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      }).catch((err) => {
        toast.error(err);
        //setPageState((item) => ({ ...item, loading: false }));
      });
    }
    // if(isImageTouched){
    //     let formData = new FormData();
    //     formData.append('profileImage', inputs.profileImage)
    //     formData.append('signature', inputs.signature)
    //     adminService.update(`/user-profiles`, formData).then(
    //         res => {

    //             if (res.status == "Success") {
    //                 dispatch(adminAction.setProfileData(res.data))
    //                 toast.success("Profile updated Successfully!");
    //             } else {
    //                 toast.error(res.message);
    //             }
    //         },
    //         error => {
    //             console.log("Error >>>>>>>", error);
    //         }
    //     ).finally(() => {
    //         setIsImageTouched(false)
    //     });
    // }
  };

  const updatePassword = () => {
    if (inputs.password !== inputs.confimPassword) {
      toast.warning("Please enter same password");
    } else if (inputs.password && inputs.confimPassword) {
      try {
        adminService.resetPassword(inputs).then(
          (res) => {
            if (res.status === "Success") {
              toast.success("Basic info updated successfully!");
              setTimeout(() => {
                //   history('/dashboard/')
              }, 2000);
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log("Error >>>>>>>", error);
          }
        );
      } catch (error) { }
    }
  };

  const updateImg = (formData) => {
    adminService
      .update(`/user-profiles`, formData)
      .then(
        (res) => {
          if (res?.status == "Success") {
            dispatch(adminAction.setProfileData(res.data));
            toast.success(res.message);
          } else {
            res?.message && toast.error(res.message);
          }
        },
        (error) => {
          console.log("Error >>>>>>>", error);
        }
      )
      .finally(() => {
        setIsImageTouched(false);
      });
  };
  const updateChildImg = (formData, id) => {
    adminService
      .update(`/families/update-family-member/${id}`, formData)
      .then(
        (res) => {
          if (res.status == "Success") {
            // dispatch(adminAction.setProfileData(res.data));
            getChildList();
            toast.success("Client Profile updated Successfully!");
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log("Error >>>>>>>", error);
        }
      )
      .finally(() => {
        setIsImageTouched(false);
      });
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const handleImageChange = (e, imgFor, id = 0, index = "na") => {
    e.preventDefault();
    const { name, value } = e.target;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FileSize = e.target.files[0].size / 1024 / 1024; // in MB
    if (file.name.match(/.(jpg|jpeg|jpeg2000|png|svg)$/i)) {
      if (FileSize > 2) {
        toast.warn("Please upload file having size MAX 2MB");
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          file.url = reader.result;
          file.isnew = true;
          setProfileImage(file);
          setIsImageTouched(true);
          let formData = new FormData();
          if (imgFor == "profileImage") {
            formData.append("profileImage", file);
          } else if (imgFor == "signature") {
            formData.append("signature", file);
          } else {
            formData.append("profileImage", file);
          }
          if (imgFor != "childProfileImage") {
            setInputs((inputs) => ({ ...inputs, [name]: file }));
            updateImg(formData);
          } else {
            const list = [...childList];

            list[index]["profileUrl"] = file.url;
            setChildList(list);
            updateChildImg(formData, id);
          }
        };
      }
    } else {
      toast.warn("Please upload file having extensions - JPG,JPEG,PNG,SVG");
    }
  };

  const onNotificationChange = (e) => {
    //console.log("notificationList",e.target.value,e.target.name,e.target.checked);
    const { name, checked } = e.target;
    let arr = { ...notificationList };
    arr = {
      ...arr,
      [name]: e.target.checked,
    };
    console.log("notificationList", arr)
    setIsNotificationList(arr);
    // setIsNotification(checked)
    // const fn = checked ? enableNotifications : disableNotifications;
    // // page-loader
    // const data = {
    //   topic: user?.userDetails?.userUniqueId,
    //   type: await getListOfNotifictionsLabels(),
    // };

    // dispatch(appAction.setAppLoading(true));
    // fn(JSON.stringify(data))
    //   .then((res) => {
    //     // toast.success("Notification Updated");
    //   })
    //   .catch(_toastHandleErr)
    //   .finally(() => {
    //     dispatch(appAction.setAppLoading(false));
    //   });
  };

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  const deleteService = (
    rowid,
    id,
    name,
    clinicNpiNumber,
    clinicianNpiNumber,
    desc
  ) => {
    if (therapistdetails.therapistServices.length == 1) {
      toast.warning("Can't delete the service");
      return;
    }
    let data = {
      serviceDetail: desc,
      clinicNpiNumber: clinicNpiNumber,
      clinicianNpiNumber: clinicianNpiNumber,
      therapyId: id,
      therapistServiceId: rowid,
      name: name,
    };
    setEditServiceDetail((editservicedetail) => ({
      ...editservicedetail,
      ["info"]: data,
    }));
    setIsDelete(true);
    setModalOpenClose(true);
  };

  const addService = () => {
    setEditServiceDetail((editservicedetail) => ({
      ...editservicedetail,
      ["info"]: {},
    }));
    setIsDelete(false);
    setModalOpenClose(true);
  };

  const editService = (
    rowid,
    id,
    clinicNpiNumber,
    clinicianNpiNumber,
    desc
  ) => {
    let data = {
      serviceDetail: desc,
      clinicNpiNumber: clinicNpiNumber,
      clinicianNpiNumber: clinicianNpiNumber,
      therapyId: id,
      therapistServiceId: rowid,
    };
    setEditServiceDetail((editservicedetail) => ({
      ...editservicedetail,
      ["info"]: data,
    }));
    setIsDelete(false);
    setModalOpenClose(true);
  };

  const handleUpdateAbout = () => {
    if (about) {
      const formData = new FormData();
      let formDataPut = { userProfileUpdateForm: { about: about } };
      formData.append(
        "therapistUpdateForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );
      adminService
        .update(`/therapists/${profile.therapistId}`, formData)
        .then(
          (res) => {
            if (res?.status == "Success") {
              toast.success(res?.message);
              getUserDetail();
              getTherapistInfo(profile.therapistId);
            } else {
              toast.error(res?.message);
            }
            setIsModal(false);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {
          setIsModal(false);
        });
    }
  };

  const addFun = () => {
    setModalOpenClose(false);
    getTherapistInfo(profile.therapistId);
  };

  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            {/* <div className="content-header">
              <div className="text-left header-title">Settings</div>
            </div> */}

            <div
              className="d-block tab-content"
              id="myTabContent"
              style={{ paddingBottom: 20 }}
            >
              <div
                // className="tab-pane fade active show"
                className={`tab-pane fade ${profile?.userType == "ROLE_THERAPIST"
                  ? currentTab == "own" && "active show"
                  : "active show"
                  }`}
                id="own"
                role="tabpanel"
                aria-labelledby="own-tab"
              >
                {loading ? (
                  <div className="loader"></div>
                ) : (
                  <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row border-bottom">
                        <div className="form-group col-md-6 ml-2 mb-10 mt-5">
                          <label className="h5 mb-3 font-weight-bold text-left">
                            {/* Parent's Profile Picture */}
                            {userRoles == "ROLE_RCE" && "Admin's "}
                            {userRoles == "ROLE_THERAPIST" && "Therapist's "}
                            {/* {userRoles == "ROLE_RCE" && "Admin's"} */}
                            Profile Picture
                          </label>
                          <br />
                          <span>
                            <div className="row align-items-center">
                              <div className="col-md-12 my-auto">
                                <div className="setting-img-container">
                                  <img
                                    src={
                                      inputs.profileImage
                                        ? inputs.profileImage.url
                                        : profile && profile.profilePicUrl
                                          ? profile && profile.profilePicUrl
                                          : "../resources/assets/defaultprofile.png"
                                    }
                                    alt=""
                                    className="settingsimg"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 my-auto">
                                <InputFileBtn
                                  className="my-auto settings-change-button"
                                  id="fileuplaod"
                                  name="profileImage"
                                  onChange={(e) =>
                                    handleImageChange(e, "profileImage")
                                  }
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                        {enableSign && (
                          <div className="form-group col-md-5 ml-2 mb-10 mt-5 w-50">
                            <label className="h5 mb-3 font-weight-bold text-left">
                              Signature
                            </label>
                            <br />
                            <span>
                              <div className="row align-items-center">
                                <div className="col-md-12 my-auto">
                                  <div className="setting-img-container">
                                    <img
                                      src={
                                        inputs.signature
                                          ? inputs.signature.url
                                          : profile && profile.signatureUrl
                                            ? profile && profile.signatureUrl
                                            : "../resources/assets/defaultprofile.png"
                                      }
                                      alt=""
                                      className="settingsSignimg sign"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 my-auto">
                                  <InputFileBtn
                                    className="my-auto  settings-change-button"
                                    id="fileuplaod2"
                                    name="signature"
                                    onChange={(e) =>
                                      handleImageChange(e, "signature")
                                    }
                                  />
                                </div>
                              </div>
                            </span>
                          </div>
                        )}
                      </div>
                      {childList.length > 0 && (
                        <div className="row border-bottom m-0">
                          <div className="form-group col-md-12 mb-0">
                            <label className="h5 mb-4 font-weight-bold text-left mt-10">
                              Client's Profile Picture
                            </label>
                          </div>
                          {loadingChildList ? (
                            <>
                              <div className="loader"></div>
                            </>
                          ) : (
                            <>
                              {childList.map((child, i) => {
                                return (
                                  <div className="col-md-3 mb-10 m-5px">
                                    <div className="row align-items-center text-center card-1">
                                      <div className="col-md-12 my-auto">
                                        <img
                                          className="cursor-pointer pr-2 child-edit-icon"
                                          alt=""
                                          src="../resources/assets/action-icon-edit.svg"
                                          onClick={() =>
                                            setChildBioModal(child)
                                          }
                                        />
                                        <img
                                          src={
                                            childProfiles.length > 0 &&
                                              childProfiles[i]?.url
                                              ? childProfiles[i]?.url
                                              : child?.profileUrl
                                                ? child?.profileUrl
                                                : "../resources/assets/defaultprofile.png"
                                          }
                                          alt=""
                                          className="settingsChildimg sign"
                                        />
                                      </div>
                                      <div className="col-md-12 my-auto">
                                        <p>
                                          {child.firstName +
                                            " " +
                                            child.lastName}
                                        </p>
                                      </div>
                                      <div className="col-md-12 my-auto">
                                        <InputFileBtn
                                          className="my-auto  settings-change-button"
                                          id="fileuplaod2"
                                          name="childProfileImage"
                                          onChange={(e) =>
                                            handleImageChange(
                                              e,
                                              "childProfileImage",
                                              child.id,
                                              i
                                            )
                                          }
                                        />
                                        {/* <InputFileBtn
                                      className="my-auto  settings-change-button"
                                      id="fileuplaod3"
                                      name="childProfileImage"
                                      onChange={(e) =>
                                        handleImageChange(
                                          e,
                                          "childProfileImage",
                                          child.id,
                                          i
                                        )
                                      }
                                    /> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      )}

                      {childBioModal && (
                        <EditChildBioModal
                          setLoadingChildList={setLoadingChildList}
                          childBioModal={childBioModal}
                          setChildBioModal={setChildBioModal}
                          getChildList={getChildList}
                        />
                      )}

                      {profile?.userType == "ROLE_THERAPIST" && (
                        <>
                          <div className="row mt-5">
                            <div className="col-md-6">
                              <label className="h5 mb-4 font-weight-bold text-left">
                                About
                              </label>
                              {/* <h4 className="name">About</h4> */}
                            </div>

                            <div className="col-md-6 text-right">
                              <Link>
                                <img
                                  onClick={() => setIsModal(true)}
                                  src="/resources/assets/Icon feather-edit-3.svg"
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <p className="preview">
                                {profile?.about &&
                                  profile?.about.replace("↵", "<br />")}
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div className="row mt-20 border-bottom">
                            <div className="col-md-8">
                              <label className="h5 mb-4 font-weight-bold text-left">
                                Working Days and Time
                              </label>
                            </div>
                            <div className="col-md-4 text-right">
                              <Link
                                to={
                                  "/edit-therapist-availability/" +
                                  therapistdetails?.id
                                }
                              >
                                <img
                                  src="../resources/assets/Icon feather-edit-3.svg"
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="col-md-12">
                              <table className="table table-hover mt-4">
                                <thead>
                                  <th>Day</th>
                                  <th>Start Time</th>
                                  <th>End Time</th>
                                  <th>Timezone</th>
                                </thead>
                                <tbody>
                                  {therapistdetails?.therapistAvailability &&
                                    therapistdetails.therapistAvailability.map(
                                      (item, index) => (
                                        <tr>
                                          <td>{item.availableDay}</td>
                                          <td>
                                            {getUTCTimeToLocalByDateWithMeridium24(
                                              item.startTime
                                            )}
                                          </td>
                                          <td>
                                            {getUTCTimeToLocalByDateWithMeridium24(
                                              item.endTime
                                            )}
                                          </td>
                                          <td>{`${therapistdetails.timezone.code
                                            }(${therapistdetails.timezone.timezone.replaceAll(
                                              "_",
                                              " "
                                            )})`}</td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                              <hr />
                            </div>
                          </div>

                          <div className="row mt-5 justify-content-between">
                            <div className="col-auto">
                              <h5 className="name">Services Details</h5>
                            </div>
                            <div className="col-auto interpreter-detail-btn">
                              <button
                                type="button"
                                onClick={addService}
                                className="btn btn-primary custom-btn-green"
                              >
                                <img
                                  className="pr-2"
                                  src="../resources/assets/plus-icon-button.svg"
                                  alt=""
                                />
                                Add Service
                              </button>

                              {modalOpenClose && (
                                <AddService
                                  isdelete={isdelete}
                                  addFun={() => addFun()}
                                  closeModal={() => setModalOpenClose(false)}
                                  serviceinfo={editservicedetail}
                                  id={profile?.therapistId}
                                  servicedetail={
                                    therapistdetails.therapistServices &&
                                    therapistdetails.therapistServices
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="row justify-content-between border-bottom">
                            <div className="col-md-12 m-auto">
                              <table className="table table-hover mt-4">
                                <thead>
                                  <th
                                    className="thead"
                                    style={{ maxWidth: 40, minWidth: 40 }}
                                  >
                                    S No.
                                  </th>
                                  <th
                                    className="thead"
                                    style={{ maxWidth: 100, minWidth: 100 }}
                                  >
                                    Provider Type
                                  </th>
                                  <th
                                    className="thead"
                                    style={{ maxWidth: 120, minWidth: 120 }}
                                  >
                                    Clinic NPI Number
                                  </th>
                                  <th
                                    className="thead"
                                    style={{ maxWidth: 120, minWidth: 120 }}
                                  >
                                    Clinician NPI Number
                                  </th>
                                  <th
                                    className="thead"
                                    style={{ maxWidth: 80, minWidth: 80 }}
                                  >
                                    Actions
                                  </th>
                                </thead>
                                <tbody>
                                  {therapistdetails.therapistServices &&
                                    therapistdetails.therapistServices.map(
                                      (item, index) => (
                                        <tr>
                                          <td
                                            style={{
                                              maxWidth: 40,
                                              minWidth: 40,
                                            }}
                                          >
                                            <p className="preview">
                                              {index + 1}
                                            </p>
                                          </td>
                                          <td
                                            style={{
                                              maxWidth: 100,
                                              minWidth: 100,
                                            }}
                                          >
                                            <p className="preview">
                                              {item.therapy &&
                                                item.therapy.name}
                                            </p>
                                          </td>
                                          <td
                                            style={{
                                              maxWidth: 120,
                                              minWidth: 120,
                                            }}
                                          >
                                            <p className="preview">
                                              {item.clinicNpiNumber != 0
                                                ? item.clinicNpiNumber
                                                : ""}
                                            </p>
                                          </td>
                                          <td
                                            style={{
                                              maxWidth: 120,
                                              minWidth: 120,
                                            }}
                                          >
                                            <p className="preview">
                                              {item.clinicianNpiNumber != 0
                                                ? item.clinicianNpiNumber
                                                : ""}
                                            </p>
                                          </td>
                                          <td
                                            style={{
                                              maxWidth: 80,
                                              minWidth: 80,
                                            }}
                                          >
                                            <Link>
                                              <img
                                                className="pr-4"
                                                src="../resources/assets/action-icon-edit.svg"
                                                alt=""
                                                onClick={() =>
                                                  editService(
                                                    item.id,
                                                    item.therapy.id,
                                                    item.clinicNpiNumber,
                                                    item.clinicianNpiNumber,
                                                    item.serviceDetail
                                                  )
                                                }
                                              />
                                            </Link>
                                            <Link>
                                              <img
                                                src="../resources/assets/action-icon-delete.svg"
                                                alt=""
                                                onClick={() =>
                                                  deleteService(
                                                    item.id,
                                                    item.therapy.id,
                                                    item.therapy.name,
                                                    item.clinicNpiNumber,
                                                    item.clinicianNpiNumber,
                                                    item.serviceDetail
                                                  )
                                                }
                                              />
                                            </Link>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>

                              <hr />
                            </div>
                          </div>

                          <AppModal
                            title=""
                            isOpen={isModal}
                            onClose={() => setIsModal(false)}
                            modalSize="lg"
                          >
                            <div className="col-md-10">
                              <div className="form-group">
                                <label>Description</label> (
                                <span className="required-span">*</span>)
                                <textarea
                                  value={about}
                                  onChange={(e) => setAbout(e.target.value)}
                                  name="description"
                                  rows="5"
                                  className={`form-control h-100 ${!about && "is-invalid"
                                    }`}
                                  placeholder="Add your description"
                                  autofocus
                                ></textarea>
                                {!about === "required" && (
                                  <div className="invalid-feedback-custom">
                                    About is required
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 d-flex1 pl-511">
                                <div className="d-flex">
                                  <button
                                    className="btn btn btn-primary d-block stepper-button-next"
                                    type="button"
                                    disabled={about ? false : true}
                                    onClick={() => handleUpdateAbout()}
                                  >
                                    Submit
                                  </button>

                                  <button
                                    type="button"
                                    onClick={() => setIsModal(false)}
                                    className="btn  btn-primary d-block stepper-button-back"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </AppModal>
                        </>
                      )}

                      <div className="row border-bottom">
                        <div className="form-group col-md-12 ml-2 w-50 mt-10 mb-10 notificationCheck">
                          <label className="h5 mb-4 font-weight-bold text-left mt-10">
                            Notifications
                          </label>
                          {notificationList ?
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={notificationList?.email}
                                  onChange={(e) => onNotificationChange(e)}
                                  className="form-check-inline"
                                  name="email"
                                  value={true}
                                />
                                Email
                              </label>{" "}
                              <label>
                                <input
                                  type="checkbox"
                                  checked={notificationList?.platformNotification}
                                  onChange={(e) => onNotificationChange(e)}
                                  className="form-check-inline"
                                  name="platformNotification"
                                />
                                Platform Notification
                              </label>{" "}
                              {profile?.userType === "ROLE_RCE" ? <></> : 
                              <label>
                                <input
                                  type="checkbox"
                                  checked={notificationList?.remainder}
                                  onChange={(e) => onNotificationChange(e)}
                                  className="form-check-inline"
                                  name="remainder"
                                />
                                Reminder {" "}
                              </label>}
                              <label>
                                <input
                                  type="checkbox"
                                  checked={notificationList?.sms}
                                  onChange={(e) => onNotificationChange(e)}
                                  className="form-check-inline"
                                  name="sms"
                                />
                                SMS
                              </label>

                              {/* {isNotifications !== null && (
                              <label>
                                <input
                                  type="checkbox"
                                  defaultChecked={isNotifications}
                                  onChange={onNotificationChange}
                                  className="form-check-inline"
                                  name="notifications"
                                />
                                Enabled
                              </label>
                            )} */}
                            </div>
                            :
                            ''
                          }
                        </div>
                        <div className="form-group col-md-4 ml-2 w-50">
                          <label className="h5 mb-4 font-weight-bold text-left">
                            Change Password
                          </label>
                          <input
                            name="password"
                            onChange={handleChange}
                            type="password"
                            id="password"
                            placeholder="Enter New Password"
                            className={`form-control settingsinput ${errors.password && "is-invalid"
                              }`}
                            autocomplete="off"
                          />
                          {errors.password &&
                            errors.password.type === "required" && (
                              <div className="invalid-feedback-custom">
                                Field is required
                              </div>
                            )}
                          <br />
                          <input
                            name="confimPassword"
                            onChange={handleChange}
                            type="password"
                            id="confimPassword"
                            placeholder="Confirm New Password"
                            className={`form-control settingsinput ${errors.confimPassword && "is-invalid"
                              }`}
                            autocomplete="off"
                          />
                          {errors.confimPassword &&
                            errors.confimPassword.type === "required" && (
                              <div className="invalid-feedback-custom">
                                Field is required
                              </div>
                            )}
                          <br />

                          <button
                            type="submit"
                            className="btn btn-default green-submit-button"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MySetting;
