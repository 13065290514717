import AgoraRTC from "agora-rtc-sdk";
// import AgoraRTC from "./AgoraRTCSDK-3.4.0";
import EventEmitter from "events";
// import { toast } from "react-toastify";
import { agoraAppId } from "../../config/Config";
const appID = agoraAppId;
console.log(
  "agora sdk version: " +
    AgoraRTC.VERSION +
    " compatible: " +
    AgoraRTC.checkSystemRequirements()
);
export default class RTCClient {
  constructor() {
    this._client = null;
    this._joined = false;
    this._localStream = null;
    this._params = {};
    this._uid = 0;
    this._eventBus = new EventEmitter();
    this._showProfile = false;
    this._subscribed = false;
    this._created = false;
    this._devices = [];
    AgoraRTC.getDevices(
      (deviceList) => {
        this._devices = deviceList;
        console.log("getDevice", {
          deviceList,
          isAud: deviceList.some((item) => item.kind === "audioinput"),
          isVid: deviceList.some((item) => item.kind === "videoinput"),
        });
      },
      (err) => {
        console.log("device err", err);
      }
    );
  }

  createClient(data) {
    this._client = AgoraRTC.createClient({
      mode: data.mode,
      codec: data.codec,
    });
    console.log({ clent: this._client });
    return this._client;
  }

  destroy() {
    this._created = false;
    this._client = null;
  }

  on(evt, callback) {
    const customEvents = [
      "localStream-added",
      "stopScreenSharing",
      "userShareScreen",
    ];
    if (customEvents.indexOf(evt) !== -1) {
      this._eventBus.on(evt, callback);
      return;
    }

    this._client && this._client.on(evt, callback);
  }

  setClientRole(role) {
    return new Promise((resolve, reject) => {
      this._client.setClientRole(role, (err) => {
        if (err) {
          return reject(err);
        }
        resolve();
      });
    });
  }

  // sendEvent = (name, data) =>{
  //   console.log("send evt")
  //   this._eventBus.emit(name, {
  //     stream: this._localStream,
  //     data
  //   });
  // }

  createDemoRTCStream(data) {
    return new Promise((resolve, reject) => {
      const localStream = AgoraRTC.createStream({
        streamID: this._uid,
        audio: data.audio,
        video: data.video,
      });
      localStream.setVideoProfile("1080p");

      // Initialize the local stream
      localStream.init(
        () => {
          // Play the local stream
          localStream.play("me");
          // Publish the local stream
          this._client.publish(localStream, this.handleError);

          this._localStream = localStream;

          this._client.on("stream-added", function (evt) {
            this._client.subscribe(evt.stream, this.handleError);
          });
          // Play the remote stream when it is subsribed
          this._client.on("stream-subscribed", function (evt) {
            console.log("evt.stream, ", evt.stream);
            let stream = evt.stream;
            let streamId = String(stream.getId());
            data.addVideoStream(streamId);
            stream.play(streamId);
          });
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });

    // localStream.init(()=>{

    // }, this.handleError);
  }

  handleError = function (err) {
    console.log("Error: ", err);
  };

  createRTCStream(data) {
    // alert("sjdsgb");
    return new Promise((resolve, reject) => {
      this._uid = this._localStream ? this._localStream.getId() : data.uid;
      let devices = this._devices;
      AgoraRTC.getDevices(
        (deviceList) => {
          devices = deviceList;
          console.log(
            "getDevice",
            {
              devices,
              isAud: devices.some((item) => item.kind === "audioinput"),
              isVid: devices.some((item) => item.kind === "videoinput"),
            },
            data
          );
        },
        (err) => {
          console.log("device err", err);
        }
      );
      if (this._localStream) {
        this.unpublish();
        if (this._localStream.isPlaying()) {
          this._localStream.stop();
        }
        this._localStream.close();
      }
      // create rtc stream
      const rtcStream = AgoraRTC.createStream({
        streamID: this._uid,
        // audio: data.muteAudio,
        // video: data.muteVideo,
        audio: devices.some((item) => item.kind === "audioinput")
          ? true
          : false,
        video: devices.some((item) => item.kind === "videoinput")
          ? true
          : false, //true,
        screen: false,
        microphoneId: data.microphoneId,
        cameraId: data.cameraId,
      });

      if (data.resolution && data.resolution !== "default") {
        rtcStream.setVideoProfile(data.resolution);
      }

      // init local stream
      // rtcStream.init(
      //   () => {
      //     this._localStream = rtcStream;
      //     this._eventBus.emit("localStream-added", {
      //       stream: this._localStream,
      //     });
      //     if (data.muteVideo === false) {
      //       this._localStream.muteVideo();
      //     }
      //     if (data.muteAudio === false) {
      //       this._localStream.muteAudio();
      //     }
      //     console.log("___local")
      //     resolve();
      //   },
      //   (err) => {
      //     console.error("init local stream failed ", err);
      //     reject(err);
      //     // Toast.error("stream init failed, please open console see more detail");
      //   }
      // );

      rtcStream.on("stopScreenSharing", (evt) => {
        this._eventBus.emit("stopScreenSharing", evt);
      });
      // var elements = document.getElementsByClassName("avc__video-container");
      // elements.length > 0 &&
      //   elements?.classList?.remove("avc__video-container--has-pinned");
      // console.log("elements123", elements);
      // elements.length > 0 &&
      //   elements.map((element) => {
      //     element.classList.remove("avc__video-container--has-pinned");
      //   });

      // init local stream
      rtcStream.init(
        () => {
          // stop current local for screen share

          if (this._localStream) {
            this._uid = this._localStream.getId();
            this.publish();
            if (this._localStream.isPlaying()) {
              this._localStream.stop();
            }
            this._localStream.close();
          }

          this._localStream = rtcStream;

          // run callback
          this._eventBus.emit("localStream-added", {
            stream: this._localStream,
          });

          if (data.muteVideo === false) {
            this._localStream.muteVideo();
          }
          if (data.muteAudio === false) {
            this._localStream.muteAudio();
          }

          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  createScreenSharingStream(data) {
    return new Promise((resolve, reject) => {
      // create screen sharing stream
      this._uid = this._localStream ? this._localStream.getId() : data.uid;

      // comment code to set this after success

      // if (this._localStream) {
      //   this._uid = this._localStream.getId();
      //   this.unpublish();
      //   if (this._localStream.isPlaying()) {
      //     this._localStream.stop();
      //   }
      //   this._localStream.close();
      // }

      const screenSharingStream = AgoraRTC.createStream({
        streamID: this._uid,
        audio: true, //!this._params.muteAudio,
        video: false,
        screen: true,
        microphoneId: data.microphoneId,
        cameraId: data.cameraId,
      });

      if (data.resolution && data.resolution !== "default") {
        screenSharingStream.setScreenProfile(`${data.resolution}_1`);
      }

      screenSharingStream.on("stopScreenSharing", (evt) => {
        this._eventBus.emit("stopScreenSharing", evt);
      });

      // init local stream
      screenSharingStream.init(
        () => {
          // stop current local for screen share

          if (this._localStream) {
            this._uid = this._localStream.getId();
            this.unpublish();
            if (this._localStream.isPlaying()) {
              this._localStream.stop();
            }
            this._localStream.close();
          }

          this._localStream = screenSharingStream;

          // run callback
          this._eventBus.emit("localStream-added", {
            stream: this._localStream,
          });

          if (data.muteAudio === false) {
            this._localStream.muteAudio();
          }

          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  setStreamFallbackOption(stream, type) {
    this._client.setStreamFallbackOption(stream, type);
  }

  subscribe(stream, callback) {
    this._client.subscribe(stream, callback);
  }

  _createTmpStream() {
    this._uid = 0;
    return new Promise((resolve, reject) => {
      if (this._localStream) {
        this._localStream.close();
      }
      // create rtc stream
      const _tmpStream = AgoraRTC.createStream({
        streamID: this._uid,
        audio: true,
        video: true,
        screen: false,
      });

      // init local stream
      _tmpStream.init(
        () => {
          this._localStream = _tmpStream;
          resolve();
        },
        (err) => {
          reject(err);
          // Toast.error("stream init failed, please open console see more detail");
          console.error("init local stream failed ", err);
        }
      );
    });
  }

  getDevices() {
    return new Promise((resolve, reject) => {
      if (!this._client) {
        this.createClient({ codec: "h264", mode: "live" });
      }
      this._createTmpStream().then(() => {
        AgoraRTC.getDevices((devices) => {
          this._localStream.close();
          resolve(devices);
        });
      });
    });
  }

  enableDualStream() {
    return new Promise((resolve, reject) => {
      this._client.enableDualStream(resolve, reject);
    });
  }

  setRemoteVideoStreamType(stream, streamType) {
    this._client.setRemoteVideoStreamType(stream, streamType);
  }

  join(data) {
    console.log("join12345'");
    this._joined = "pending";
    return new Promise((resolve, reject) => {
      /**
       * A class defining the properties of the config parameter in the createClient method.
       * Note:
       *    Ensure that you do not leave mode and codec as empty.
       *    Ensure that you set these properties before calling Client.join.
       *  You could find more detail here. https://docs.agora.io/en/Video/API%20Reference/web/interfaces/agorartc.clientconfig.html
       **/

      this._params = data;

      // handle AgoraRTC client event
      // this.handleEvents();

      // init client
      this._client.init(
        appID,
        () => {
          /**
           * Joins an AgoraRTC Channel
           * This method joins an AgoraRTC channel.
           * Parameters
           * tokenOrKey: string | null
           *    Low security requirements: Pass null as the parameter value.
           *    High security requirements: Pass the string of the Token or Channel Key as the parameter value. See Use Security Keys for details.
           *  channel: string
           *    A string that provides a unique channel name for the Agora session. The length must be within 64 bytes. Supported character scopes:
           *    26 lowercase English letters a-z
           *    26 uppercase English letters A-Z
           *    10 numbers 0-9
           *    Space
           *    "!", "#", "$", "%", "&", "(", ")", "+", "-", ":", ";", "<", "=", ".", ">", "?", "@", "[", "]", "^", "_", "{", "}", "|", "~", ","
           *  uid: number | null
           *    The user ID, an integer. Ensure this ID is unique. If you set the uid to null, the server assigns one and returns it in the onSuccess callback.
           *   Note:
           *      All users in the same channel should have the same type (number) of uid.
           *      If you use a number as the user ID, it should be a 32-bit unsigned integer with a value ranging from 0 to (232-1).
           **/
          console.log(
            "join channel: ===" + data.channel + " success, uid: " + data.uid
          );
          this._client.join(
            data.token ? data.token : null,
            data.channel,
            data.uid ? +data.uid : null,
            (uid) => {
              this._uid = uid;
              // Toast.notice("join channel: " + data.channel + " success, uid: " + uid);
              console.log(
                "join channel: " + data.channel + " success, uid: " + uid
              );

              data.uid = uid;

              this.enableDualStream()
                .then(() => {
                  if (data.host) {
                    if (data?.test) {
                      this.createDemoRTCStream(data)
                        .then(() => {
                          // alert("hello")
                          this._joined = true;
                          this.setRemoteVideoStreamType(this._localStream, 0);
                          data.addVideoStream(data.uid, this._localStream);
                          resolve();
                        })
                        .catch((err) => {
                          // alert("no")
                          this._joined = false;
                          console.log("aaaaassss");
                          console.log({ err });
                          reject(err);
                        });
                    } else {
                      this.createRTCStream(data)
                        .then(() => {
                          this._joined = true;
                          console.log("aaaaassss");
                          this.setRemoteVideoStreamType(this._localStream, 0);
                          resolve();
                        })
                        .catch((err) => {
                          this._joined = false;
                          console.log("aaaaassss");
                          console.log({ err });
                          reject(err);
                        });
                    }
                    return;
                  }
                  resolve();
                })
                .catch((err) => {
                  reject(err);
                });
            },
            (err) => {
              this._joined = false;
              reject(err);
              console.error("client join failed", { err, uid: data.uid });
            }
          );
        },
        (err) => {
          this._joined = false;
          reject(err);
          console.error(err);
        }
      );
    });
  }

  publish() {
    // publish localStream
    console.log("____", this._localStream);
    this._client.publish(this._localStream, (err) => {
      console.error(err, this._localStream);
    });
  }

  unpublish() {
    if (!this._client) {
      return;
    }
    this._client.unpublish(this._localStream, (err) => {
      console.error(err);
    });
  }

  leave() {
    return new Promise((resolve) => {
      if (!this._client) return resolve();
      // leave channel
      this._client.leave(
        () => {
          this._joined = false;
          this.destroy();
          resolve();
        },
        (err) => {
          console.log("channel leave failed");
          console.error(err);
        }
      );
    });
  }
}
