import React, { useEffect, useState } from "react";
import { getFamilyUserDetail } from "../../services/userServices";
import ChildFormList from "./ChildFormList";

const FamilyForms = () => {
  // const state = useSelector(state => state)
  const [state, setState] = useState({
    family: null,
  });

  const [inputs, setInputs] = useState({
    familyMemberId: "",
    sortBy: "dateDesc",
  });

  useEffect(() => {
    getFamilyUserDetail().then((res) => {
      setState((state) => ({
        ...state,
        family: res,
      }));
    });
  }, []);

  useEffect(() => {
    if (state?.family?.familyMembers?.length > 0) {
      setInputs((input) => ({
        ...input,
        familyMemberId: state?.family?.familyMembers[0]?.id,
      }));
    }
  }, [state?.family?.familyMembers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "familyMemberId") {
      setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
    } else if (name == "sortBy") {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
  };
  return (
    <div
      onClick={() => {
        console.log({ state });
      }}
      className="c-forms c-forms--family"
    >
      <div className="row">
        <div className="col-md-6 mb-3">
          <select
            className={`custom-select`}
            onChange={handleChange}
            value={inputs.familyMemberId}
            defaultValue={inputs.familyMemberId}
            name="familyMemberId"
          >
            <option disabled selected>
              Select Child
            </option>
            {state?.family?.familyMembers?.map((item, _index) => (
              <option key={item.id} value={item.id}>
                {item.firstName + " " + item.lastName}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 mb-3">
          <select
            className={`custom-select`}
            onChange={handleChange}
            value={inputs.sortBy}
            defaultValue={inputs.sortBy}
            name="sortBy"
          >
            <option disabled selected>
              Sort by
            </option>
            <option value="dateAsc">Date in ASC</option>
            <option value="dateDesc">Date in DESC</option>
            <option value="titleAsc">Title in ASC</option>
            <option value="titleDesc">Title in DESC</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ChildFormList id={inputs.familyMemberId} sortBy={inputs.sortBy} />
        </div>
      </div>
    </div>
  );
};

export default FamilyForms;
