import React from "react";
//import { adminService } from "../../services/adminService";
import { adminService } from "../../services/adminService";

const DownLoadInvoiceBtn = ({
  targetRef,
  downloadName,
  onComplete,
  beforeStart,
}) => {
  const onDownload = () => {
    console.log(targetRef?.current?.outerHTML);
    if (typeof beforeStart === "function") {
      beforeStart(startDownload);
    } else {
      startDownload();
    }

    console.log(targetRef, targetRef?.current?.outerHTML);
  };
  const startDownload = () => {
    const html = `
                    <html>
                    <head>
                      <style>
                        .p-case-note-detail__info-label {
                            font-weight: bold;
                            margin-top: 0px;
                        }
                        .p-case-note-detail__info-value {
                            margin-top:-5px;
                        }
                        .sign-img {
                            margin-top:15px;
                            // margin-bottom: 15px;
                            // width: 150px !important;
                            width: 100% !important;
                            // height: 50px !important;
                            object-fit: contain;
                            // border: 1px solid rgba(0, 0, 0, 0.16);
                            border-radius: 5px;
                            padding-top: 5px;
                        }
                        .procedureTable td{
                          padding: "10px";
                          display: "table-cell";
                          border-spacing: "0";
                          border: 1px solid #dee2e6;
                          padding:8px;
                          text-align:center;
                          font-size:12px;
                        } 
                        .longGoalTable td:first-child {
                          width: 10%;
                      }
                      .procedureGoals td:first-child {
                        width: 20%;
                    }
                    .procedureGoals td:nth-child(2) {
                      width: 20%;
                  }
                        .procedureTable th
                        {
                          font-size:14px;
                        }
                        .p-case-note-detail__user img {
                            width: 60px;
                            height: 60px;
                            object-fit: cover;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                        p {
                            word-wrap: break-word;
                            width: 200px;
                        }
                        tr {
                            margin-bottom: 15px;
                        }
                        td{
                            vertical-align: top;
                            width: 33%;
                            padding-left: 5px;
                            padding-right: 5px;                            
                          }
                        .c-casenote-billing-info__image {
                            width: 250px;
                            height: 70px;
                            object-fit: contain;
                        }
                        .p-case-note-detail__info-value{
                            width:100%;
                        }
                          footer {page-break-after: always;}
                          table {
                            border-collapse: collapse;
                        }
                          tr {
                            display: table-row;
                            vertical-align: inherit;
                            border-color: inherit;
                        }
                        tbody {
                          display: table-row-group;
                          vertical-align: middle;
                          border-color: inherit;
                      }
                      .table-bordered th, .table-bordered td {
                        border: 1px solid #dee2e6;
                        vertical-align: middle;
                        padding: 10px;
                        display: table-cell;
                    }
                      </style>                        
                    </head>
                        <body>
                            ${targetRef?.current?.outerHTML}
                        <body>
                    </html>
            `;
    console.log("html", html);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: downloadName }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          onComplete();
        }
      });
  };
  return (
    <div className="">
      <button onClick={onDownload} className="btn btn-theme-green">
        Download Invoice
      </button>
    </div>
  );
};

export default DownLoadInvoiceBtn;

DownLoadInvoiceBtn.defaultProps = {
  downloadName: "soapnote",
};
