// import config from 'config';
import {
  authHeader,
  authHeaderMultiPartUpload,
  authHeaderMultiPartUploadJSONContent,
} from "../utils/Helper";
import { apiUrl, apiUrlAuth, api } from "../config/Config";
import { toast } from "react-toastify";

function handleResponse(response) {
  return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text);
      if (!response?.ok) {
        // if any error like validation check or data not found, etc...
        if (response?.status === 400) {
          return data;
        }
        if (response?.status === 401 || response?.status === 403) {
          // auto logout if 401 response returned from api
          //   logout();
        }
        const error = (data && data.message) || response.statusText;
        //return Promise.reject(error);
        return data;
      }

      return data;
    })
    .catch((err) => console.log("object----------", err));
}

const headers = {
  "X-TenantID": "MEPg3pOj",
  "Content-Type": "application/json",
  Accept: "application/json",
};

function generateTokenForMarketingUserID(endpoint, dataobj) {
  let options = {
    method: "POST",
    headers: headers,
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) =>
      toast.error(
        "Session has been expired, please try again, please logout and login again"
      )
    );
}

export const marketingService = {
  generateTokenForMarketingUserID,
};
