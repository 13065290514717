import React, { Component, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../redux/actions";
import { adminActions } from "../actions";
import { adminService } from "../services/adminService";
import { showError, showWarning } from "../utils/commonFunctions";
import { apiUrl } from "../config/Config";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
//import allActions from "../redux/actions";

function AdminNormalAccount() {

  let params = useParams();


  return (
    <>
      <div className="col">
        <div className="container p-0">
          <div className="row align-items-center login-page">
            <div className="col-md-6 no-padding p-0">
              <div className="log-wrapper-left">
                <img alt=""
                  src={ 
                    params.tempToken
                      ? "/resources/assets/Mask Group 2.png"
                      : "../resources/assets/Mask Group 2.png"
                  }
                  className="mask-login-left"
                />
                <img alt=""
                  src={
                    params.tempToken
                      ? "/resources/assets/MIS-1.png"
                      : "../resources/assets/MIS-1.png"
                  }
                  className="login-logo"
                />
              </div>
              <img alt=""
                src={
                  params.tempToken
                    ? "/resources/assets/pass-left.png"
                    : "../resources/assets/pass-left.png"
                }
                className="left-img img-fluid"
              />
              <img alt=""
                src={
                  params.tempToken
                    ? "/resources/assets/MIS-1.png"
                    : "../resources/assets/MIS-1.png"
                }
                className="copyright-image "
              />
              <span className="copyright-text">Copyright @RCE 2021</span>
            </div>
            <div className="col-md-6 no-padding">
              <div className="row valign_wrapper padd-b-60-all ">
                <div className="custom-table-view-login">
                  <div className="col-lg-12 min-hei-250-all">
                    <div className="text-left login">
                      <div className="">
                        <h5><b>Please contact Primary Tenant Admin to complete the account details.</b></h5>
                        <p style={{ textAlign: 'left' }}><Link to="/login" style={{ textDecoration: "underline", color: "blue", fontSize: "16px", cursor: "pointer" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                        </svg> Back to Login</Link></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminNormalAccount;
