import React, { useEffect, useState } from 'react';
import { adminService } from '../../services/adminService';
import moment from "moment-timezone";
import { getHoursFromSecond } from '../../utils/commonFunctions';
import { Link } from "react-router-dom";
import { paginationDisplayCount } from '../../config/Config';
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";

function BillingList(props) {
    var d = new Date();
    let c = d.setMonth(d.getMonth() - 1);
    const [billingList, setBillingList] = useState([]);
    const [loadings, setloadings] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalpages, setTotalPages] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [status, setStatus] = useState("");
    const [startDate, setStartDate] = useState(new Date(c));
    const [endDate, setEndDate] = useState(new Date());
    const [loader, setLoader] = useState(false);
    //pagination
    const pageNumbers = [];

    for (let i = 0; i <= totalpages - 1; i++) {
        pageNumbers.push(i);
    }

    const paginate = (number) => {
        setCurrentPage(number);
    };
    const paginatePre = () => {
        setCurrentPage(currentPage - 1);
    };
    const paginateNext = () => {
        setCurrentPage(currentPage + 1);
    };
    //end
    useEffect(() => {
        (async () => {
            setLoader(true);
            setloadings(true);
            let sessionFromDateTime =
                moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
            let sessionToDateTime = moment(endDate).format("YYYY-MM-DD") + "T23:59:59";
            let qry_param_filter = `name=${searchName}&billingStatus=${status}&sessionFromDateTime=${sessionFromDateTime}&sessionToDateTime=${sessionToDateTime}&page=${currentPage}&size=${paginationDisplayCount}&sort=createdDate,DESC`;
            let api = `payments/get-all-client-bills/family?${qry_param_filter}`;
            const stripeCustomer = await adminService
                .getAll(api)
                .then((res) => {
                    console.log("stripe customer", res.data.content);
                    setBillingList(res?.data?.content);
                    setLoader(false);
                    setTotalPages(res?.data?.totalPages);
                    //setCustomerId(res?.data?.stripeCustomerId);
                    setloadings(false);

                })
        })();
    }, [currentPage, paginationDisplayCount])
    function currencyFormat(currency) {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(currency);
    }
    const search = () => {
        let sessionFromDateTime =
            moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
        let sessionToDateTime = moment(endDate).format("YYYY-MM-DD") + "T23:59:59";
        let qry_param_filter = `name=${searchName}&billingStatus=${status}&sessionFromDateTime=${sessionFromDateTime}&sessionToDateTime=${sessionToDateTime}&page=${currentPage}&size=${paginationDisplayCount}&sort=createdDate,DESC`;
        setloadings(true);

        let api = `payments/get-all-client-bills/family?${qry_param_filter}`;

        adminService.getAll(api).then(
            (res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    setBillingList(res.data.content);
                    setTotalPages(res.data.totalPages);
                } else {
                    toast.error(res.message);
                }
                setloadings(false);
            },
            (error) => {
                console.log(error);
            }
        );
    };
    return (
        <div className="col-md-10 no-padding">
            <div className="no-padding dash-billing-info">
                <div className="custom-table-view">
                    {loader && (
                        <div className="loader-overlay">
                            <div className="loader" />
                        </div>
                    )}
                    <div class="row mb-10">
                        <div class="col-md-6">
                            <div class="text-left header-title">Billing Info</div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-3 padding-right-0">
                            <input
                                name="search"
                                className="billing-info-search form-control h-46"
                                onChange={(e) => setSearchName(e.target.value)}
                                placeholder="Search by Client Name"
                                autofocus
                            />
                        </div>
                        <div className="col-md-3 padding-right-0">
                            <select
                                className="form-control billing-dropdown"
                                onClick={(e) => setStatus(e.target.value)}
                                id="exampleFormControlSelect1"
                            >
                                <option
                                    className="form-control"
                                    value=""
                                >
                                    All
                                </option>
                                <option
                                    className="form-control"
                                    value="PAYMENT_PENDING"
                                >
                                    Payment Pending
                                </option>
                                <option className="form-control" value="PAID">
                                    Paid
                                </option>
                                <option className="form-control" value="PAYMENT_IN_PROGRESS">Auto Pay in Progress</option>
                                <option className="form-control" value="PAYMENT_FAILED">Auto Pay Failed</option>
                                <option className="form-control" value="VOID">
                                    Void
                                </option>
                                <option className="form-control" value="PAST_DUE">
                                    Past Due
                                </option>
                                <option className="form-control" value="UNCOLLECTIBLE">Uncollectible</option>
                            </select>
                        </div>
                        <div className="col-auto padding-right-0">
                            {/* <label>Start Date</label> */}
                            <div className="d-flex ">
                                <div className="input-group">
                                    <DatePicker
                                        onChange={setStartDate}
                                        value={startDate}
                                        name="startDate"
                                        className="datepickercal"
                                    />
                                </div>
                            </div>
                            <div className="time ml-2">Start Date</div>
                        </div>
                        <div className="col-auto padding-right-0">
                            {/* <label>End Date</label> */}
                            <div className="d-flex">
                                <div className="input-group">
                                    <DatePicker
                                        onChange={setEndDate}
                                        value={endDate}
                                        name="endDate"
                                        className="datepickercal"
                                    />
                                </div>
                            </div>
                            <div className="time ml-2">End Date</div>
                        </div>

                        <div className="col-auto pl-1 pr-0">
                            {/* <button className="btn custom-btn-green" style={{width:130,height:50}} type="submit" onClick={search}> Search</button> */}
                            <button
                                className="btn btn-theme-green mr-0"
                                style={{ padding: "7px 10px" }}
                                type="submit"
                                onClick={search}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-search"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <hr />
                    <div className='table-responsive'>
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th>Session Date</th>
                                    <th>Client</th>
                                    <th>Therapist</th>
                                    <th>Therapy</th>
                                    <th>Total</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {billingList.length ? billingList.map((item, index) => (
                                    <tr key={index}>
                                        <td><span className="name-table name">
                                            {moment.utc(item.sessionDateTime).utc().local().format("DD MMMM, yyyy")}<br />
                                            {moment.utc(item.sessionDateTime).utc().local().format("hh:mm A")}
                                        </span></td>
                                        <td><span className="name-table">
                                            {item?.familyMemberName}
                                        </span></td>
                                        <td><span className="name-table">
                                            {item?.therapistName}
                                        </span></td>
                                        <td><span className="name-table">
                                            {item?.therapyDto?.name}
                                        </span></td>
                                        <td>
                                            <span className="name-table name">
                                                {currencyFormat(item?.totalAmount)}
                                            </span>
                                        </td>
                                        <td><span className="name-table name">
                                            {" "}
                                            {item?.clientInvoiceDto?.overDueDate ? moment(item?.clientInvoiceDto?.overDueDate).format(
                                                "DD MMMM, yyyy"
                                            ) : '-'}
                                        </span></td>
                                        <td className="fd-flex">
                                            {item?.clientInvoiceDto?.billingStatus === "PAID" && (
                                                <span className="badge badge-primary session-status s-completePay ">
                                                    <img
                                                        className=""
                                                        src="../resources/assets/approve-icon-button.svg"
                                                    />{" "}
                                                    Paid
                                                </span>
                                            )}
                                            {/* {var a = moment(item?.clientInvoiceDto?.overDueDate);
                                                        var b = moment().format("YYYY-MM-DD");
                                                        moment(item?.clientInvoiceDto?.overDueDate).diff(moment().format("YYYY-MM-DD"), 'days')} */}
                                            {item?.clientInvoiceDto?.billingStatus ===
                                                "PAYMENT_PENDING" && (
                                                    <span className={moment(item?.clientInvoiceDto?.overDueDate).diff(moment().format("YYYY-MM-DD"), 'days') < 0 ? "badge badge-primary session-status s-cancelled " : "badge badge-primary session-status s-missed "}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.042" height="14.042" viewBox="0 0 14.042 14.042">
                                                            <g>
                                                                <g>
                                                                    <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" d="M16.042 9.521A6.521 6.521 0 1 1 9.521 3a6.521 6.521 0 0 1 6.521 6.521z" transform="translate(.5 .5) translate(-3 -3)" />
                                                                </g>
                                                            </g>

                                                            <path fill="currentcolor" d="M17.011 7.662a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.912zm0 5.47a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.912zm0-2.735a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.909z" transform="translate(-9.187 -3.75)" />
                                                        </svg>{" "}
                                                        Payment Pending
                                                    </span>
                                                )}
                                            {item?.clientInvoiceDto?.billingStatus ===
                                                "VOID" && (
                                                    <span className="badge badge-primary session-status s-hide ">
                                                        <img
                                                            className=""
                                                            src="../resources/assets/cancel.svg"
                                                        />{" "}
                                                        Void
                                                    </span>
                                                )}
                                            {item?.clientInvoiceDto?.billingStatus ===
                                                "PAST_DUE" && (
                                                    <span className="badge badge-primary session-status s-cancelled ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                        </svg>{" "}
                                                        Past Due
                                                    </span>
                                                )}
                                            {item?.clientInvoiceDto?.billingStatus ===
                                                "WAIVED_OFF" && (
                                                    <span className="badge badge-primary session-status s-hide ">
                                                        <img
                                                            className=""
                                                            src="../resources/assets/cancel.svg"
                                                        />{" "}
                                                        Waived Off
                                                    </span>
                                                )}
                                            {item?.clientInvoiceDto?.billingStatus ===
                                                "PAYMENT_IN_PROGRESS" && (
                                                    <span className="badge badge-primary session-status s-missed ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                        </svg>{" "}
                                                        Auto Pay in Progress
                                                    </span>
                                                )}
                                            {item?.clientInvoiceDto?.billingStatus ===
                                                "PAYMENT_FAILED" && (
                                                    <span className="badge badge-primary session-status s-cancelled ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                                                        </svg>{" "}
                                                        Auto Pay Failed
                                                    </span>
                                                )}
                                            {item?.clientInvoiceDto?.billingStatus ===
                                                "UNCOLLECTIBLE" && (
                                                    <span className="badge badge-primary session-status s-hide ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                                                        </svg>{" "}
                                                        Uncollectible
                                                    </span>
                                                )}
                                        </td>
                                        <td>
                                            <Link to={"/billing-detail/" + item?.invoiceNo}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M255.8 112c-80.4 0-143.8 50.6-219.6 133.3-5.5 6.1-5.6 15.2-.1 21.3C101 338.3 158.2 400 255.8 400c96.4 0 168.7-77.7 220.1-134 5.3-5.8 5.6-14.6.5-20.7C424 181.8 351.5 112 255.8 112zm4.4 233.9c-53 2.4-96.6-41.2-94.1-94.1 2.1-46.2 39.5-83.6 85.7-85.7 53-2.4 96.6 41.2 94.1 94.1-2.1 46.2-39.5 83.6-85.7 85.7z"></path><path d="M256 209c0-6 1.1-11.7 3.1-16.9-1 0-2-.1-3.1-.1-36.9 0-66.6 31.4-63.8 68.9 2.4 31.3 27.6 56.5 58.9 58.9 37.5 2.8 68.9-26.9 68.9-63.8 0-1.3-.1-2.6-.1-3.9-5.6 2.5-11.7 3.9-18.2 3.9-25.2 0-45.7-21.1-45.7-47z"></path></svg></Link>
                                        </td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan="7">
                                            <h5 className="text-center p-3">No detail available</h5>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {loadings ? (
                        <></>
                    ) : (
                        <>
                            {/* Pagination */}
                            <nav>
                                <ul className="pagination justify-content-center">
                                    <li onClick={() => paginatePre()} className="page-item">
                                        {currentPage <= 0 ? (
                                            <></>
                                        ) : (
                                            <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                                                <img
                                                    className="pr-2"
                                                    src="../resources/assets/arrow-left.svg"
                                                />
                                            </div>
                                        )}
                                    </li>
                                    {pageNumbers.map((number) => (
                                        <li
                                            key={number}
                                            onClick={() => paginate(number)}
                                            className="page-item"
                                        >
                                            <div
                                                className={`${currentPage === number
                                                    ? "pagination-active page-link pagination"
                                                    : "page-link pagination cursor-pointer"
                                                    }`}
                                            >
                                                {number + 1}
                                            </div>
                                        </li>
                                    ))}
                                    <li onClick={() => paginateNext()} className="page-item">
                                        {currentPage >= totalpages - 1 ? (
                                            <></>
                                        ) : (
                                            <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                                                <img
                                                    className="pr-2"
                                                    src="../resources/assets/arrow-right.svg"
                                                />
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </nav>
                            {/* End */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BillingList;