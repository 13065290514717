import moment from "moment";
import { toast } from "react-toastify";
import { messages } from "../config/Config";
import langList from "./lang.json";

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

Date.prototype.getNextWeekDay = function (wk) {
  if (true) {
    var next = this;
    next.setDate(this.getDate() - this.getDay() + wk + 1);
    return next;
  }
};

export function getHoursFromSecond(sec) {
  // Hours, minutes and seconds
  var hrs = ~~(sec / 3600);
  var mins = ~~((sec % 3600) / 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + " hr ";
  }
  if (mins > 0) {
    ret += "" + mins + " min";
  }
  return ret;
}

function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function showSuccess(msg) {
  toast.success(msg);
}

export function showError(errorMessages) {
  errorMessages.map((info) => {
    toast.error(info);
  });
}

export function showWarning(key) {
  toast.warning(
    messages.filter((info) => info.key == key).length > 0 &&
      messages.filter((info) => info.key == key)[0].message
  );
}

export function getWeeks(weekcount, bymonth = false) {
  let curr = new Date();

  if (bymonth) {
    let months = curr.getMonth();
    let year = curr.getFullYear();
    let allMonthsDate = getDaysInMonth(months + weekcount, year);
    let month = [];
    for (let i = 0; i <= allMonthsDate.length - 1; i++) {
      let month_date = allMonthsDate[i];
      let first = month_date.getDate() + 1;
      let day = new Date(month_date.setDate(first)).toISOString().slice(0, 10);
      month.push(day);
    }
    return month;
  } else {
    let curr = new Date();
    let first_date = curr.getNextWeekDay(weekcount * 7);
    let week = [];
    for (let i = 1; i <= 7; i++) {
      let first = first_date.getDate() - first_date.getDay() + i;
      let day = new Date(first_date.setDate(first)).toISOString().slice(0, 10);
      week.push(day);
    }

    return week;
  }
}

// TODO use it instead of above
export const getListOfDays = (count = 0, isMonth = false) => {
  const thisDate = moment().add(count, !isMonth ? "weeks" : "months");
  if (!isMonth) {
    //const firstDay = thisDate.weekday(0);
    const firstDay = thisDate.weekday(0);
    const list = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ].map((_d, i) => {
      const date = firstDay.clone().add(i, "days").format("YYYY-MM-DD");

      return date;
    });
    return list;
  } else {
    const firstDay = thisDate.startOf("month");
    const list = Array(firstDay.daysInMonth())
      .fill("")
      .map((_d, i) => {
        const date = firstDay.clone().add(i, "days").format("YYYY-MM-DD");
        return date;
      });
    return list;
  }
};
//
export const calcWeekCount = (date) => {
  const n1 = moment().weekday(0).week();
  const n2 = moment(date).weekday(0).week();

  return n2 - n1;
};
export const calcMonthCount = (date) => {
  const other = moment(date);
  const diff = moment().diff(other, "months");
  return diff * (moment().isBefore(other, "day") ? 1 : -1);
};

export function getTimeInUTC(hhmm) {
  // 16:10
  let date = new Date();
  let hhmm_arr = hhmm.split(":");
  date.setHours(hhmm_arr[0]);
  date.setMinutes(hhmm_arr[1]);
  let hhmmutc = moment.utc(date).format("HH:mm");

  return hhmmutc + ":00.000";
}

export function getUTCTimeToLocalByDateWithMeridium(date) {
  let utcDte = moment.utc(date).local(); // here date formate is startDateTime: "2021-02-05T13:30:00"
  let dateTime = moment(utcDte).format("YYYY-MM-DD" + "T" + "HH:mm:ss");
  let dateFinal = new Date(dateTime);
  return moment(dateFinal).format("hh:mm a");
}

export function getUTCDateToLocalByDateWithMeridium(date) {
  let utcDte = moment.utc(date).local(); // here date formate is startDateTime: "2021-02-05T13:30:00"
  let dateTime = moment(utcDte).format("YYYY-MM-DD" + "T" + "HH:mm:ss");
  let dateFinal = new Date(dateTime);
  return moment(dateFinal).format("dddd DD MMMM, YYYY");
}

export function getUTCDateTimeToLocalByDateWithMeridium24(date) {
  let utcDte = moment.utc(date).local(); // here date formate is startDateTime: "2021-02-05T13:30:00"
  let dateTime = moment(utcDte).format("YYYY-MM-DD" + "T" + "HH:mm:ss");
  let dateFinal = new Date(dateTime);
  return moment(dateFinal).format("YYYY-MM-DD HH:mm:ss");
}

export function getUTCTimeToLocalByDateWithMeridium24(time) {
  let utcDte = moment.utc(moment.utc().format("YYYY-MM-DD") + "T" + time); // here time formate is 13:30:00"
  let dateTime = moment(utcDte).format("YYYY-MM-DD" + "T" + "HH:mm:ss");
  let dateFinal = new Date(dateTime);
  return moment(dateFinal).format("hh:mm a");
}

export function getTime24(time) {
  let utcDte = moment.utc(moment.utc().format("YYYY-MM-DD") + "T" + time); // here time formate is 13:30:00"

  let dateTime = moment(utcDte).format("YYYY-MM-DD" + "T" + "HH:mm:ss");

  let dateFinal = new Date(dateTime);
  return moment(dateFinal).format("hh:mm");
}

export function getMeridium24(time) {
  // Before this was in UTC same for getTime24 function let utcDte = moment.utc(moment.utc().format('YYYY-MM-DD') + 'T' + time).local(); // here time formate is 13:30:00"
  let utcDte = moment.utc(moment.utc().format("YYYY-MM-DD") + "T" + time); // here time formate is 13:30:00"
  let dateTime = moment(utcDte).format("YYYY-MM-DD" + "T" + "HH:mm:ss");
  let dateFinal = new Date(dateTime);
  let hour = dateFinal.getHours();

  if (hour <= 11) {
    return "am";
  } else {
    return "pm";
  }
}

export function calculateDuration(startDate, endDate) {
  let sessionStartDate = new Date(startDate);
  let sessionEndDate = new Date(endDate);

  let hour = moment(sessionEndDate).diff(moment(sessionStartDate), "hours");
  let minutes = moment(sessionEndDate).diff(
    moment(sessionStartDate),
    "minutes"
  );

  let ms = moment(sessionEndDate).diff(moment(sessionStartDate));
  var d = moment.duration(ms);
  var returnText = "";

  if (hour < 1) {
    returnText = minutes + " min";
  } else {
    if (d.minutes() == 0) {
      returnText = d.hours() + " hr";
    } else {
      returnText = d.hours() + " hr " + d.minutes() + " min";
    }
  }
  return returnText;
}
// export function diff_hours(dt2, dt1){
//   var now = moment(dt2); //todays date
//   var end = moment(dt1); // another date
//   var duration = moment.duration(now.diff(end));
//   var hours = duration.asHours();
//   let minutes = duration.asMinutes();
//   return hours.toFixed(2);
// }

export function getUTCTimeToLocalByDate(date) {
  let utcDte = moment.utc(date).local(); // here date formate is startDateTime: "2021-02-05T13:30:00"
  let dateTime = moment(utcDte).format("YYYY-MM-DD" + "T" + "HH:mm:ss");
  let dateFinal = new Date(dateTime);
  return moment(dateFinal).format("hh:mm");
}

// get AM/PM from date formate is startDateTime: "2021-02-05T13:30:00" => this is in UTC

export function getMeridiumByDate(date) {
  let utcDte = moment.utc(date).local(); // here date formate is startDateTime: "2021-02-05T13:30:00"
  let dateTime = moment(utcDte).format("YYYY-MM-DD" + "T" + "HH:mm:ss");
  let dateFinal = new Date(dateTime);
  let hour = dateFinal.getHours();

  if (hour <= 11) {
    return "am";
  } else {
    return "pm";
  }
}

export const getFileType = (fileName) => {
  const imgRegex = /.(jpg|jpeg|png|gif|svg)$/;
  const videoRegex = /.(mp4|mov|mkv|wmv|flv)$/;
  switch (true) {
    case imgRegex.test(fileName):
      // case fileName.match(/.(jpg|jpeg|png|gif|svg)$/i):
      return "image";
    case videoRegex.test(fileName):
      // case fileName.match(/.(mp4|mov|mkv|wmv|flv)$/i):
      return "video";
    default:
      return "document";
  }
};

export const isRtl = (lang = "") => {
  const mylng = (lang || "").toLowerCase();
  const foundLang = langList.find(
    (lng) =>
      lng.code.toLowerCase() === mylng || lng.name.toLowerCase() === mylng
  );
  return foundLang?.dir === "rtl";
};

export const _toastHandleErr = (err) => {
  toast.error(err?.info || err?.msg || err?.message);
};
export const _toasthandleRes = (res, msg) => {
  if (res?.status?.toLowerCase() === "success") {
    toast.success(msg);
  } else {
    toast.error(res?.message);
  }
};

export const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

export const downloadFile = (file, name = null, isBlob = false) => {
  if (window && file) {
    const url = !isBlob ? file : window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  }
};

export const bodyLoader = (enable = false) => {
  if (enable) {
    if (document && document.body) {
      document.body.classList.add("page-loader");
    }
  } else {
    if (document && document.body) {
      document.body.classList.remove("page-loader");
    }
  }
};
export const setBodyScroll = (enable = true) => {
  if (enable) {
    if (document && document.body) {
      document.body.classList.remove("overflow-hidden");
    }
  } else {
    if (document && document.body) {
      document.body.classList.add("overflow-hidden");
    }
  }
};

export const maskPhoneNumber = (string) => {
  return string.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

export const getNumberFromMask = (str) => {
  // return str.split('').map(item => !isNaN(item) && +item).filter(Boolean).join("")
  const number1 = str.split("").map((item) => {
    if (!isNaN(item)) {
      return item.trim();
    }
    return null;
  });
  const number2 = number1.filter(Boolean); //.join("")

  const number3 = number2.join("");
  return number3;
};

export const sortLanguage = (langArr) => {
  const rest = (langArr || [])
    .filter((lg) => (lg.languageName || "").trim()?.toLowerCase() !== "english")
    .sort((a, b) => {
      const textA = a?.languageName?.toLowerCase();
      const textB = b?.languageName?.toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  const topLang = (langArr || []).find(
    (lg) => (lg.languageName || "").trim()?.toLowerCase() === "english"
  );
  return [topLang, ...rest].filter(Boolean);
};

export const trimName = (str, length = 10) => {
  if (str.length > length) {
    return `${str.slice(0, length)}...`;
  }
  return str;
};
export const capitalString = (str) =>
  str.replace(/\b\w/g, (l) => l.toUpperCase());
export const displayGender = (gender = "") => {
  return gender === "NOT_DISCLOSED"
    ? "Other"
    : capitalString(gender.toLowerCase());
};
export const getFullName = (...nameList) => {
  return nameList.filter(Boolean).join(" ");
};

export const appStatusList = [
  {
    name: "ACTION_NEEDED",
    img: "notcompleted.svg",
    className: "warning not-complete",
    label: "Action Needed",
  },
  {
    name: "ACTION_NEEDED_PENDING",
    img: "notcompleted.svg",
    className: "warning not-complete",
    label: "Action Pending",
  },
  {
    name: "NOT_COMPLETED",
    img: "notcompleted.svg",
    className: "pending not-complete",
    label: "Not Completed",
  },
  {
    name: "IN_PROGRESS",
    img: "pending-icon-button.svg",
    className: "pending",
    label: "In Progress",
  },
  {
    name: "COMPLETED",
    img: "complete-icon-button.svg",
    className: "complete",
    label: "Completed",
  },
  {
    name: "UP_COMING",
    img: "approve-icon-button.svg",
    className: "approve",
    label: "Upcoming",
  },
  {
    name: "MISSED",
    img: "Missed.png",
    className: "missed",
    label: "Missed",
  },
  {
    name: "CANCELLED",
    img: "cancel.svg",
    className: "cancelled",
    label: "Cancelled",
  },
];

export const getStatusClassName = (status) => {
  return appStatusList.find((st) => status === st.name)?.className || "";
};

export const getStatusImage = (status) => {
  return appStatusList.find((st) => status === st.name)?.img || "";
};

export const getShortInsurance = {
  PRIMARY: 1,
  SECONDARY: 2,
  OTHER: 3,
};
export const getStatusLabel = (status) => {
  return appStatusList.find((st) => status === st.name)?.label || "";
};

export const calculateMinsLeft = (startDate) => {
  let startDateStr = startDate + "Z";
  let sessionStartDate = new Date();
  let sessionEndDate = new Date(startDateStr);
  let returnText = moment(sessionEndDate).diff(
    moment(sessionStartDate),
    "minutes"
  );
  return returnText;
};
