import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { adminService } from "../../services/adminService";
import { Link } from "react-router-dom";
import AddyourQuestionFAQ from "./AddYourQuestionFAQ";
import { useDispatch, useSelector } from "react-redux";

function FAQ(props) {
  let profile = useSelector((state) => state.adminUser.profile);
  const [FAQDetails, setFAQDetails] = useState([]);
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [accordianOpenClose, setAccordianOpenClose] = useState(false);
  const [loading, setloading] = useState(false);
  const [search, setSearch] = useState("");
  const [faqData, setFaqData] = useState({
    question: "",
    answer: "",
  });
  useEffect(() => {
    setloading(true);
    searchfaq();
  }, []);

  const getFaqList = () => {
    adminService.getAll(`faqs`).then(
      (res) => {
        if (res.status === "Success") {
          const list = res.data.content.sort((a, b) => (a.id > b.id ? -1 : 1));
          setFAQDetails(list);
          setloading(false);
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const AddYourQuestion = () => {
    setFaqData({ id: null, question: null, answer: null });
    setModalOpenClose(true);
  };

  const accordian = () => {
    setAccordianOpenClose(true);
  };

  useEffect(() => {
    searchfaq();
  }, [search]);

  const searchfaq = () => {
    setloading(true);
    adminService.getAll(`faqs?filterKeyWord=${search}`).then(
      (res) => {
        if (res?.status === "Success") {
          setFAQDetails(res.data.content);
          setloading(false);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openEditModal = (data) => {
    setFaqData(data);
    setModalOpenClose(true);
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">FAQ's</div>
            </div>
            <div className="row">
              <div className="col-md-8 justify-content-between align-items-center mt-4 mb-4 d-flex">
                <div className="col mt-2">
                  <input
                    type="search"
                    className="search form-control"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    autofocus
                  />
                </div>
                <div className="col-auto mt-2">
                  <button
                    className="btn btn-theme-green"
                    type="submit"
                    onClick={searchfaq}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="32"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <>
                {/* <div className="ml-4 mr-4 mt-5 row addyourque">
                  <div className="col-sm-3 col-md-3 col-lg-3"></div>
                  <div className="col-sm-7 col-md-7 col-lg-7">
                    {FAQDetails.length == 0 && (
                      <p className="Could-Not-Find-Your-Question">
                        Couldn't find your question
                      </p>
                    )}
                    <div>
                      <Link onClick={() => AddYourQuestion()}>
                        <button
                          type="submit"
                          className="btn btn-default add-your-ques"
                        >
                          Add Your Question
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-1 col-md-1 col-lg-1"></div>
                </div> */}
                <div id="accordion" className="col-md-12">
                  {FAQDetails &&
                    FAQDetails.map((item, i) => (
                      <div className="card">
                        <div className="card-header" id={`heading-${i}`}>
                          <h5 className="mb-0">
                            <button
                              className="btn btn-header-link custom-collapse-btn w-100per"
                              data-toggle="collapse"
                              data-target={`#collapse-${i}`}
                              aria-expanded="true"
                              aria-controls={`collapse-${i}`}
                            >
                              {item.categoryName}{" "}
                              <sapn className="arrow">
                                <img
                                  src="../resources/assets/Path 4.svg"
                                  width={20}
                                />
                              </sapn>
                            </button>
                            {profile.userType == "ROLE_RCE" && (
                              <img
                                onClick={() => openEditModal(item)}
                                className="pr-4"
                                src="../resources/assets/action-icon-edit.svg"
                                alt=""
                              />
                            )}
                          </h5>
                        </div>

                        <div
                          id={`collapse-${i}`}
                          className="collapse"
                          aria-labelledby={`heading-${i}`}
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.answer,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
            <div className="col-md-12">
              <p>
                <b>Help :</b> Didn't find what you were looking for? Please
                contact{" "}
                <a href="mailto: support@mis-live.com">support@mis-live.com</a>{" "}
                and we will get back to you as soon as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* {modalOpenClose && (
        <AddyourQuestionFAQ
          closeModal={() => setModalOpenClose(false)}
          data={faqData}
          role={profile.userType}
          getFaqList={getFaqList()}
        />
      )} */}
    </>
  );
}
export default FAQ;
