import React from "react";
import { IoIosSearch } from "react-icons/io";
import { useSelector } from "react-redux";
const ArchieGoalsHeader = (props) => {
  const userInfo = useSelector((state) => state.adminUser?.user);
  return (
    <div className="p-case-notes__header p-3">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-3">
          <h2 className="header-title">{props.title}</h2>
        </div>
        <div className="col-md-4 d-flex">
          <div className="input-group">
            <select
              className={`custom-select`}
              onChange={(e) => props?.setfilter(e.target.value)}
              name="familyMemberId"
            >
              <option value="">All Status</option>
              {userInfo?.userDetails &&
                userInfo?.userDetails?.roles[0] !== "ROLE_FAMILY" && (
                  <option value="drafted">Drafted</option>
                )}
              <option value="submitted">Submitted</option>
              <option value="completed">Completed</option>
            </select>
          </div>
        </div>
        <div className="col-md-5 d-flex">
          <input
            type="text"
            placeholder="Search by Client Name"
            value={props.search}
            onChange={(e) => props.onChange(e.target.value)}
            className="form-control mr-3"
          />
          <button
            onClick={() => props.onSearch()}
            className="btn btn-theme-green"
          >
            <IoIosSearch />
          </button>
        </div>
      </div>
    </div>
  );
};
export default ArchieGoalsHeader;
