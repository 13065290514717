import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/adminService';
import { useParams } from "react-router-dom";
import TherapistPersonalInformation from './OnBoardingUser/TherapistPersonalInformation';

function EditTherapistDetails(props) {
    let params = useParams();
    const [therapist, setTherapist] = useState([])
    const [loading, setloading] = useState(false);
   
    useEffect(() => {
        setloading(true);
        adminService.getById(`therapists/` + params.id).then(
            res => {
                setTherapist(res.data);
                setloading(false);
            },
            error => {
                console.log(error)
            }
        );        
    }, [params.id])

    return (
        <>
            <div className="col-md-10 no-padding">
                <div className="no-padding dash-billing-info mr-0">
                    <div className="custom-table-view">                       
                        <TherapistPersonalInformation/>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default EditTherapistDetails;