import React from 'react';
import classNames from 'classnames'
import { getFullName, maskPhoneNumber } from '../utils/commonFunctions';
import { Link } from 'react-router-dom';

const TeamCard = ({classname, userImg, firstName, lastName, phone, email, editLink}) => {
    const name = getFullName(firstName, lastName)
    return (
        <div className={classNames(`c-team-card session-detail-section`, classname)}>
            <div className="row justify-content-between">
                <div className="col-auto">
                    <div className="user-info">
                        <span className="admin-name justify-content-start">
                            <img src={userImg !== '' ? userImg : '/resources/assets/defaultprofile.png'} alt="" className="image" />
                            <div className="content ml-2">
                                <large>{name}</large>
                                {/* <small className="preview"><br />{item.userProfile.about}</small> */}
                                {phone && <small><br />{maskPhoneNumber(phone)}</small>}
                                {email && <small><br />{email}</small>}
                            </div>
                        </span>

                    </div>
                </div>
                {editLink &&
                    <div className="col-auto">
                        <Link to={editLink}>
                            <img className="pr-4" src='../resources/assets/action-icon-edit.svg' alt="" />
                        </Link>
                    {/* <Link>
                        <img src='../resources/assets/action-icon-delete.svg' alt="" />
                    </Link> */}
                </div>
                }
            </div>
        </div>
    )
}

export default TeamCard
