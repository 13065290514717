import React from "react";
import moment from "moment";
import MsgItem from './MsgItem'

const MsgList = ({ messageList, localUser, otherUser, myKey }) => {
  console.log("sender firbase",localUser);
  if(messageList.length === 0){
    return <div className="d-flex justify-content-center py-5">
      No messages
    </div>
  }
  return messageList.map(({ message, time, from }, index) => {
      let sender = {};
    
      const isLocal = `${from}`.trim() === `${myKey}`.trim();
      const isFromLast =
        messageList[index - 1] && messageList[index - 1].from === from;
      if (isLocal) {
        sender = localUser;
        sender.name = localUser.userName;
      } else {
        sender = otherUser;
        sender.name =
          (otherUser?.firstName || "") +
          " " +
          (otherUser?.lastName || "");
      }
      const timeText = moment(time).format("hh:mm A");
      const date = moment(time).format("dddd DD MMMM, YYYY");
      const lastTimeText =
        messageList[index - 1] &&
        moment(messageList[index - 1].time).format("hh:mm");

      const lastDate =
        messageList[index - 1] &&
        moment(messageList[index - 1].time).format("dddd DD MMMM, YYYY");

      const showFrom = isFromLast && timeText === lastTimeText;
      const showDate = lastDate !== date;

      const itemProps = {
        date,
        showDate,
        from,
        showFrom,
        sender,
        timeText,
        message,
        time,
        key: time + from,
        isLocal
      };
      return <MsgItem {...itemProps} />;
    });
};


export default MsgList