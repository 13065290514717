import React, { useEffect } from "react";
import "./style.scss";
import {
  Route,
  Switch,
  useLocation,
  Navigate,
  useNavigate,
  useParams,
  BrowserRouter, Routes
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Represtative Routes
import CalenderDemo from "./pages/CalenderDemo";
import Login from "./pages/Login";
import AddAccount from "./pages/AddAccount";
import AdminNormalAccount from "./pages/AdminNormalAccount";
import createPassword from "./pages/createPassword";
import Dashboard from "./pages/Representative/Dashboard";
import TherapySession from "./pages/Representative/TherapySession";
import Therapist from "./pages/Representative/Therapist";
import Interpreter from "./pages/Representative/Interpreter";
import Family from "./pages/Representative/Family";
import BillingInfo from "./pages/Billings/BillingInfo";
import EditBillingInfo from "./pages/Billings/EditBillingInfo";
import ViewBillingInfo from "./pages/Billings/ViewBilling";
import Settings from "./pages/Settings";
import AddTherapist from "./pages/Representative/AddTherapist";
import AddFamily from "./pages/Representative/AddFamily";
import AddInterpreter from "./pages/Representative/AddInterpreter";
import AddNewSession from "./pages/AddNewSession";
import CasesNotesAndQueries from "./pages/Representative/CasesNotesAndQueries";
import Notifications from "./pages/Notifications";
import CasesDetails from "./pages/Representative/CasesDetails";
import InterpreterDetail from "./pages/Representative/InterpreterDetail";
import EditInterpreterDetail from "./pages/Representative/EditInterpreterDetail";
import EditInterpreterAvailability from "./pages/Representative/EditInterpreterAvailability";
import FamilyDetails from "./pages/Representative/FamilyDetails";
import TherapistDetails from "./pages/Representative/TherapistDetails";
import EditTherapistDetails from "./pages/Representative/EditTherapistDetails";
import EditTherapistAvailability from "./pages/Representative/EditTherapistAvailability";
import ChildrenInformation from "./pages/Representative/ChildrenInformation";
import EditFamilyParentInformation from "./pages/Representative/EditFamilyParentInformation";
import ViewClientBill from "./pages/Billings/ViewClientBill";
import AccountDetails from "./pages/Billings/AccountDetails";
import ClientBillingArchive from "./pages/Billings/ClientBillingArchive";
import ViewArchiveClientBill from "./pages/Billings/ViewArchiveClientBill";
import ArchiveTherapistBilling from "./pages/Billings/ArchiveTherapistBilling";
import ArchiveBillingInfo from "./pages/Billings/ArchiveBillingInfo";

//TherapistRoutes
import TherapistDashboard from "./pages/Therapist/TherapistDashboard";
import TherapistBillingInfo from "./pages/Therapist/TherapistBillingInfo";
import VideoTherapy from "./pages/Therapist/VideoTherapy";

//FamilyRoutes
import FamilyDashboard from "./pages/Family/FamilyDashboard";
import MyTeam from "./pages/Family/MyTeam";
import FAQ from "./pages/Family/FAQ's";
import MyResources from "./pages/Family/MyResources";
import Card from "./components/payment/Card";
import CardList from "./components/payment/CardList";
import BillingPage from "./components/payment/BillingPage";
import BillingDetail from "./components/payment/BillingDetail";
import BillingList from "./components/payment/BillingList";
//End

import Sidebar from "./pages/Sidebar";
import Header from "./pages/Header";

// Hooks
import CardLogin from "./components/payment/CardLogin";
import AddCard from "./pages/AddCard";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/forgotPassword";
//import forgotPassword from "./pages/forgotPassword";
import ViewTherapySessionDetails from "./pages/Representative/ViewTherapySessionDetails";
import PendingUsersOnboarding from "./pages/Representative/PendingUsersOnboarding";
import CaseNotes from "./pages/CaseNotes";
import CaseNoteDetail from "./pages/CaseNoteDetail";
import AllMessages from "./components/messages/AllMessages";
import AppLoader from "./components/loader/AppLoader";
import { adminService } from "./services/adminService";
import adminAction from "./redux/actions/adminAction";
import { getSoapNoteBillingData } from "./services/caseNoteServices";
import appActions from "./redux/actions/appAction";
import ErrorPage from "./pages/ErrorPage";
import TestPage from "./pages/TestPage";
import WhiteBoard from "./components/agora/whiteboard";
import MarketingWhiteBoard from "./pages/Marketing/MarketingWhiteBoard";
import MediaReleaseForm from "./components/forms/admin/MediaReleaseForm";
import AdminFormList from "./components/forms/admin/AdminFormList";
import FamilyFormList from "./components/forms/admin/FamilyFormList";
import HippaNoticeForm from "./components/forms/admin/HippaNoticeForm";
import MedicalInsuranceForm from "./components/forms/admin/MedicalInsuranceForm";
import CancellationForm from "./components/forms/admin/CancellationForm";
import ChangeInCoverage from "./components/forms/other/ChangeInCoverage";
import ParentProvider from "./components/forms/other/ParentProvider";
import ClientContactRegister from "./components/forms/other/ClientContactRegister";
import Acknowledgement from "./components/forms/other/Acknowledgement";
import AuthToDiscoloseInfo from "./components/forms/other/AuthToDiscoloseInfo";
import EditFamily from "./pages/Representative/EditFamily";
import EditTherapist from "./pages/Representative/EditTherapist";
import EditInterpreter from "./pages/Representative/EditInterpreter";
import DischargeSummery from "./components/forms/therapist/DischargeSummery";
import TherapistFormList from "./components/forms/therapist/TherapistFormList";
import Impersonation from "./pages/Impersonation/Impersonation";
import CreateForm from "./pages/Forms/CreateForm";
import FormSetups from "./pages/Forms/FormSetups";
import MisGoals from "./pages/Therapist/MisGoals";
import Goals from "./pages/Goals/Goals";
import TherapyTypeList from "./pages/TherapistLicense/TherapyType/TherapyTypesList";
import CptCodeList from "./pages/TherapistLicense/CPT-Code/CptCodeList";
import EditCptCode from "./pages/TherapistLicense/CPT-Code/EditCptCode";
import IcdCodeList from "./pages/TherapistLicense/ICD-Code/IcdCodeList";
import AddIcdCode from "./pages/TherapistLicense/ICD-Code/AddIcdCode";
import AddCptCode from "./pages/TherapistLicense/CPT-Code/AddCptCode";
import AddTherapyType from "./pages/TherapistLicense/TherapyType/AddTherapyType";
import ViewCptCode from "./pages/TherapistLicense/CPT-Code/ViewCptCode";
import ViewIcdCode from "./pages/TherapistLicense/ICD-Code/ViewIcdCode";
import ViewTherapyType from "./pages/TherapistLicense/TherapyType/ViewTherapyType";
import AddEditTenantAdmin from "./pages/Tenant/AddEditTenantAdmin";
import ViewTenentAdmin from "./pages/Tenant/ViewTenentAdmin";
import ArchivesGoals from "./pages/ArchiveGoals/ArchieGoals";
import ViewArchiveGoal from "./pages/ArchiveGoals/ViewArchiveGoal";
import ArchiveCaseNotes from "./pages/ArchiveCaseNote/ArchiveCaseNotes";
import ViewArchiveCaseNotes from "./pages/ArchiveCaseNote/ViewArchiveCaseNotes";
import ArchiveTherapySessionTable from "./components/ArchiveTherapySessionTable";
import ArchiveTherapistFormList from "./components/forms/therapist/ArchiveTherapistFormList";
import ViewArchiveTherapySession from "./components/ViewArchiveTherapySession";
import TherapistBilling from "./pages/Billings/TherapistBilling";
import ClientBilling from "./pages/Billings/ClientBilling";
import OtherUser from "./pages/Marketing/OtherUser";
import AddEditOtherUser from "./pages/Marketing/AddEdit/AddEditOtherUser";
import OtherUserDetail from "./pages/Marketing/OtherUserDetail";
import MyFamilyInterpreter from "./pages/Representative/MyFamilyInterpreter";
//Marketing Routes
import MarketingSessionFirstScreen from "./pages/Marketing/MarketingSessionFirstScreen";
import MarketingSession from "./pages/Marketing/MarketingSession";
import ViewMarketingSessionDetails from "./pages/Marketing/ViewMarketingSessionDetails";
import AddNewMarketingSession from "./pages/Marketing/AddNewMarketingSession";
import MarketingVideoTherapy from "./pages/Marketing/MarketingVideoTherapy";
import FamilyInterpreterDetail from "./pages/Representative/FamilyInterpreterDetail";

const RegnalEarlyStepsUserRoutes = (authToken) => {
  return authToken ? (
    <>
      <>
        <Routes>
          <Route index path="/" element={() => <Navigate to="/dashboard/" />} />
          <Route path="/login" element={() => <Navigate to="/dashboard/" />} />
          <Route path="/dashboard/" element={<TherapistDashboard />} />

          <Route path="/therapy-sessions/" element={<TherapySession />} />
          <Route path="/add-therapy-session/" element={<AddNewSession />} />
          <Route

            path="/edit-therapy-session/:id"
            element={<AddNewSession />}
          />
          {/* <Route path="/view-therapysession-details/:id" element={ViewTherapySessionDetails} /> */}
          <Route

            path="/therapy-sessions-details/:id"
            element={<ViewTherapySessionDetails />}
          />
          <Route path="/notifications/" element={<Notifications />} />

          <Route path="/family/" element={<Family />} />
          <Route path="/add-family/" element={<AddFamily />} />
          <Route path="/edit-family/:familyId" element={<EditFamily />} />
          <Route

            path="/child-detail/:id/:childid"
            element={<FamilyDetails />}
          />
          <Route

            path="/edit-child-detail/:id/:childid"
            element={<ChildrenInformation />}
          />
          <Route

            path="/edit-parent-detail/:id"
            element={<EditFamilyParentInformation />}
          />
          <Route path="/test/" element={<TestPage />} />
          <Route path="/form-test/" element={<TestPage />} />

          <Route path="/settings/" element={<Settings />} />
          <Route path="" element={<ErrorPage />} />
        </Routes>
      </>
    </>
  ) : (
    <>{AuthRoutes()}</>
  );
};

const RepresentativeRoute = (authToken) => {
  return authToken ? (
    <>
      <>
        <Routes>
          <Route

            path="/calenderDemo"
            element={<CalenderDemo />}
          />
          {/* <Route path="/calenderDemo" element={<createPassword />} /> */}
          <Route

            path="/marketing/whiteboard/:sessionid"
            element={<MarketingWhiteBoard />}
          />
          <Route index path="/" element={() => <Navigate to="/dashboard/" />} />
          <Route path="/impersonation/:id" element={<Impersonation />} />
          <Route

            path="/login"
            element={() => <Navigate to="/dashboard/" />}
          />
          <Route path="/dashboard/" element={<Dashboard />} />
          <Route path="/form-list/" element={<AdminFormList />} />

          <Route

            path="/pending-users-onboarding/"
            element={<PendingUsersOnboarding />}
          />

          <Route path="/therapy-sessions/" element={<TherapySession />} />
          <Route

            path="/therapy-sessions/?filter=id"
            element={<TherapySession />}
          />

          <Route path="/marketing-sessions/" element={<MarketingSession />} />
          <Route

            path="/marketing-sessions/?filter=id"
            element={<MarketingSession />}
          />
          <Route

            path="/marketing-user"
            element={<OtherUser />}
          />
          <Route

            path="/marketing-user/:id"
            element={<OtherUserDetail />}
          />
          <Route path="/add-marketing-user" element={<AddEditOtherUser />} />
          <Route

            path="/edit-marketing-user/:id"
            element={<AddEditOtherUser />}
          />
          <Route path="/add-therapy-session/" element={<AddNewSession />} />
          <Route

            path="/add-marketing-session/"
            element={<AddNewMarketingSession />}
          />

          <Route

            path="/edit-marketing-session/:id"
            element={<AddNewMarketingSession />}
          />

          <Route

            path="/marketing-video-therapy/:id"
            element={<MarketingVideoTherapy />}
          />

          <Route

            path="/edit-therapy-session/:id"
            element={<AddNewSession />}
          />
          <Route

            path="/therapy-sessions-details/:id"
            element={<ViewTherapySessionDetails />}
          />
          <Route

            path="/marketing-sessions-details/:id"
            element={<ViewMarketingSessionDetails />}
          />
          <Route path="/case-notes" element={<CaseNotes />} />
          <Route path="/case-notes/:id" element={<CaseNoteDetail />} />
          <Route path="/archive-case-notes" element={<ArchiveCaseNotes />} />
          <Route

            path="/view-archive-case-notes/:id"
            element={<ViewArchiveCaseNotes />}
          />
          <Route path="/therapy-types" element={<TherapyTypeList />} />
          <Route path="/cpt-code" element={<CptCodeList />} />
          <Route path="/icd-code" element={<IcdCodeList />} />
          <Route path="/add-icd-code" element={<AddIcdCode />} />
          <Route path="/add-cpt-code" element={<AddCptCode />} />
          <Route path="/add-therapy-type" element={<AddTherapyType />} />
          <Route path="/view-cpt-code/:id" element={<ViewCptCode />} />
          <Route path="/edit-cpt-code/:id" element={<EditCptCode />} />
          <Route path="/view-icd-code/:id" element={<ViewIcdCode />} />
          <Route

            path="/view-therapy-type/:id"
            element={<ViewTherapyType />}
          />

          <Route path="/therapist/" element={<Therapist />} />
          {/* <Route path="/misgoals" element={MisGoals} /> */}
          <Route path="/add-therapist/" element={<AddTherapist />} />
          <Route path="/add-therapist/:uid" element={<AddTherapist />} />
          <Route

            path="/edit-therapist/:therapistId"
            element={<EditTherapist />}
          />
          <Route

            path="/edit-pending-therapist/:therapistId"
            element={<EditTherapist />}
          />
          <Route

            path="/edit-pending-interpreter/:interpreterId"
            element={<EditInterpreter />}
          />
          <Route

            path="/therapist-details/:id"
            element={<TherapistDetails />}
          />
          <Route

            path="/edit-therapist-details/:id"
            element={<EditTherapistDetails />}
          />
          <Route

            path="/edit-therapist-availability/:id"
            element={<EditTherapistAvailability />}
          />

          <Route path="/interpreter/" element={<Interpreter />} />
          <Route path="/add-interpreter/" element={<AddInterpreter />} />
          <Route path="/add-interpreter/:uid" element={<AddInterpreter />} />
          <Route

            path="/interpreter-details/:id"
            element={<InterpreterDetail />}
          />
          <Route

            path="/edit-interpreter-details/:id"
            element={<EditInterpreterDetail />}
          />
          <Route

            path="/edit-interpreter-availability/:id"
            element={<EditInterpreterAvailability />}
          />
          <Route path="/test/" element={<TestPage />} />

          <Route path="/family/" element={<Family />} />
          <Route path="/add-family/:uid" element={<AddFamily />} />
          <Route path="/add-family/" element={<AddFamily />} />
          <Route path="/edit-family/:familyId" element={<EditFamily />} />
          <Route

            path="/child-detail/:id/:childid"
            element={<FamilyDetails />}
          />
          <Route

            path="/edit-child-detail/:id/:childid"
            element={<ChildrenInformation />}
          />
          <Route

            path="/edit-parent-detail/:id"
            element={<EditFamilyParentInformation />}
          />

          <Route path="/billing-info/" element={<BillingInfo />} />
          <Route path="/therapist-interpreter-billing/" element={<TherapistBilling />} />
          <Route path="/archive-therapist-interpreter-billing/" element={<ArchiveTherapistBilling />} />
          <Route path="/client-billing/" element={<ClientBilling />} />
          <Route path="/client-billing-archive/" element={<ClientBillingArchive />} />
          <Route path="/account-detail/" element={<AccountDetails />} />
          <Route path="/view-client-bill/:id" element={<ViewClientBill />} />
          <Route path="/view-archive-client-bill/:id" element={<ViewArchiveClientBill />} />
          <Route path="/billing-info/:id" element={<ViewBillingInfo />} />
          <Route
            path="/edit-billing-info/:id"
            element={<EditBillingInfo />}
          />
          <Route path="/settings/" element={<Settings />} />

          <Route
            path="/case-notes-and-queries/"
            element={<CasesNotesAndQueries />}
          />
          <Route path="/notifications/" element={<Notifications />} />
          <Route path="/cases/" element={<CasesDetails />} />
          <Route path="/case-notes/:id" element={<CaseNoteDetail />} />
          <Route path="/faq/" element={<FAQ />} />
          <Route path="/misgoals/:id" element={<MisGoals />} />
          <Route path="/goals/" element={<Goals />} />
          <Route path="/archiveGoals/" element={<ArchivesGoals />} />
          <Route path="/viewArchiveGoal/:id" element={<ViewArchiveGoal />} />

          <Route path="/create-form" element={<CreateForm />} />
          <Route path="/form-setups" element={<FormSetups />} />
          <Route path="/form-details/:id" element={<FormSetups />} />

          <Route path="/add-tenant/" element={<AddEditTenantAdmin />} />
          <Route path="/edit-tenant/:id" element={<AddEditTenantAdmin />} />
          <Route path="/view-tenant/:id" element={<ViewTenentAdmin />} />
          <Route path="/archive-admin-form-list/" element={<ArchiveTherapistFormList />} />
          <Route path="/archive-therapy-session" element={<ArchiveTherapySessionTable />} />
          <Route path="/archive-therapy-session/:id" element={<ViewArchiveTherapySession />} />
          <Route path="" element={<ErrorPage />} />
        </Routes>
      </>
    </>
  ) : (
    <>{AuthRoutes()}</>
  );
};

const FamilyRoutes = (authToken) => {
  return authToken ? (
    <>
      <>
        <Routes>
          <Route

            path="/marketing-video-therapy/:id"
            element={<MarketingVideoTherapy />}
          />
          <Route index path="/" element={() => <Navigate to="/dashboard/" />} />
          <Route path="/impersonation/:id" element={<Impersonation />} />
          <Route

            path="/login"
            element={() => <Navigate to="/dashboard/" />}
          />
          <Route path="/dashboard/" element={<FamilyDashboard />} />
          <Route path="/settings/" element={<Settings />} />
          {/* <Route path="/billing-info/" element={TherapistBillingInfo} /> */}
          {/* <Route path="/billing-info/" element={BillingInfo} /> */}
          <Route path="/billing-info/:id" element={<ViewBillingInfo />} />
          <Route

            path="/edit-billing-info/:id"
            element={<EditBillingInfo />}
          />
          <Route path="/therapy-sessions/" element={<TherapySession />} />
          <Route

            path="/therapy-sessions-details/:id"
            element={<ViewTherapySessionDetails />}
          />
          <Route path="/notifications/" element={<Notifications />} />
          <Route path="/my-team/" element={<MyTeam />} />
          <Route path="/faq/" element={<FAQ />} />
          <Route path="/video-therapy/:id" element={<VideoTherapy />} />
          <Route path="/whiteboard/:sessionid" element={<WhiteBoard />} />
          <Route

            path="/marketing/whiteboard/:sessionid"
            element={<MarketingWhiteBoard />}
          />
          <Route path="/family-form-list/" element={<FamilyFormList />} />
          <Route path="/my-resources/" element={<MyResources />} />
          <Route path="/case-notes" element={<CaseNotes />} />
          <Route path="/case-notes/:id" element={<CaseNoteDetail />} />
          <Route path="/media-release/" element={<MediaReleaseForm />} />
          <Route path="/misgoals/:id" element={<MisGoals />} />

          <Route

            path="/medical-insurance/"
            element={<MedicalInsuranceForm />}
          />
          <Route path="/test/" element={<TestPage />} />
          <Route path="/coverage-in-change" element={<ChangeInCoverage />} />
          <Route path="/parent-provider" element={<ParentProvider />} />
          <Route path="/acknowledgement" element={<Acknowledgement />} />
          <Route

            path="/auth-to-disclose-info"
            element={<AuthToDiscoloseInfo />}
          />
          <Route

            path="/client-contact-register"
            element={<ClientContactRegister />}
          />
          <Route

            path="/cancellation-policy/"
            element={<CancellationForm />}
          />
          <Route path="/discharge-summery/" element={<DischargeSummery />} />
          <Route path="/hippa-notice/" element={<HippaNoticeForm />} />
          <Route path="/goals/" element={<Goals />} />
          <Route path="/Card" element={<Card />} />
          <Route path="/card-list" element={<CardList />} />
          <Route path="/billing-info" element={<BillingList />} />
          <Route path="/billing-detail/:id" element={<BillingDetail />} />
          <Route path="" element={<ErrorPage />} />
        </Routes>
      </>
    </>
  ) : (
    <>{AuthRoutes()}</>
  );
};

const TherapistRoute = (user) => {
  return user && !user.userDetails.newUser ? (
    <>
      <>
        <Routes>
          <Route
            path="/marketing-video-therapy/:id"
            element={<MarketingVideoTherapy />}
          />
          <Route
            path="/marketing/whiteboard/:sessionid"
            element={<MarketingWhiteBoard />}
          />
          <Route index path="/" element={() => <Navigate to="/dashboard/" />} />
          <Route path="/impersonation/:id" element={<Impersonation />} />

          <Route

            path="/login"
            element={() => <Navigate to="/dashboard/" />}
          />
          <Route path="/dashboard/" element={<TherapistDashboard />} />

          {/* <Route
        
          path="/pending-users-onboarding/"
          element={PendingUsersOnboarding}
        /> */}
          <Route path="/therapy-sessions/" element={<TherapySession />} />
          <Route path="/family/" element={<Family />} />
          <Route path="/add-family/" element={<AddFamily />} />
          <Route path="/edit-family/:familyId" element={<EditFamily />} />
          <Route path="/faq/" element={<FAQ />} />
          <Route path="/misgoals/:id" element={<MisGoals />} />
          <Route

            path="/child-detail/:id/:childid"
            element={<FamilyDetails />}
          />
          <Route

            path="/edit-child-detail/:id/:childid"
            element={<ChildrenInformation />}
          />
          <Route

            path="/edit-parent-detail/:id"
            element={<EditFamilyParentInformation />}
          />
          <Route

            path="/child-detail/:id/:childid"
            element={<FamilyDetails />}
          />
          <Route path="/add-therapy-session/" element={<AddNewSession />} />
          <Route

            path="/add-marketing-session/"
            element={<AddNewMarketingSession />}
          />
          <Route

            path="/edit-therapy-session/:id"
            element={<AddNewSession />}
          />
          <Route

            path="/therapy-sessions-details/:id"
            element={<ViewTherapySessionDetails />}
          />
          <Route

            path="/pending-users-onboarding/"
            element={<PendingUsersOnboarding />}
          />
          <Route path="/settings/" element={<Settings />} />
          {/* <Route path="/billing-info/" element={TherapistBillingInfo} /> */}
          <Route path="/billing-info/" element={<BillingInfo />} />
          <Route path="/archive-billing-info/" element={<ArchiveBillingInfo />} />
          <Route path="/video-therapy/:id" element={<VideoTherapy />} />
          <Route path="/whiteboard/:id" element={<WhiteBoard />} />
          <Route

            path="/edit-therapist-availability/:id"
            element={<EditTherapistAvailability />}
          />
          <Route path="/test/" element={<TestPage />} />
          <Route path="/notifications/" element={<Notifications />} />
          <Route path="/case-notes" element={<CaseNotes />} />
          <Route path="/case-notes/:id" element={<CaseNoteDetail />} />
          <Route

            path="/therapist-form-list/"
            element={<TherapistFormList />}
          />
          <Route path="/archive-case-notes" element={<ArchiveCaseNotes />} />
          <Route

            path="/view-archive-case-notes/:id"
            element={<ViewArchiveCaseNotes />}
          />
          <Route

            path={"/archive-therapy-session"}
            element={<ArchiveTherapySessionTable />}
          />
          <Route

            path="/archive-therapy-session/:id"
            element={<ViewArchiveTherapySession />}
          />
          {/* <Route path="/create-form" element={CreateForm} /> */}
          <Route path="/discharge-summery/" element={<DischargeSummery />} />
          <Route path="/goals/" element={<Goals />} />
          <Route path="/archiveGoals/" element={<ArchivesGoals />} />
          <Route path="/viewArchiveGoal/:id" element={<ViewArchiveGoal />} />
          <Route

            path="/archive-therapy-form-list/"
            element={<ArchiveTherapistFormList />}
          />
          <Route path="" element={<ErrorPage />} />
        </Routes>
      </>
    </>
  ) : (
    <>{AuthRoutes()}</>
  );
};

const InterpreterRoutes = (authToken) => {
  return authToken ? (
    <>
      <>
        <Routes>
          <Route index path="/" element={() => <Navigate to="/dashboard/" />} />
          <Route path="/impersonation/:id" element={<Impersonation />} />
          <Route

            path="/login"
            element={() => <Navigate to="/dashboard/" />}
          />
          <Route path="/dashboard/" element={<TherapistDashboard />} />
          <Route path="/settings/" element={<Settings />} />
          <Route path="/billing-info/" element={<BillingInfo />} />
          <Route path="/archive-billing-info/" element={<ArchiveBillingInfo />} />
          <Route path="/billing-info/:id" element={<ViewBillingInfo />} />
          <Route

            path="/edit-billing-info/:id"
            element={<EditBillingInfo />}
          />
          {/* <Route path="/billing-info/" element={TherapistBillingInfo} /> */}
          <Route path="/therapy-sessions/" element={<TherapySession />} />
          <Route

            path="/therapy-sessions-details/:id"
            element={<ViewTherapySessionDetails />}
          />
          <Route path="/test/" element={<TestPage />} />
          <Route path="/video-therapy/:id" element={<VideoTherapy />} />
          <Route path="/whiteboard/:id" element={<WhiteBoard />} />
          <Route path="/notifications/" element={<Notifications />} />
          <Route path="/case-notes" element={<CaseNotes />} />
          <Route path="/case-notes/:id" element={<CaseNoteDetail />} />
          <Route path="/family/" element={<MyFamilyInterpreter />} />
          <Route

            path="/child-detail/:id/:childid"
            element={<FamilyInterpreterDetail />}
          />
          <Route path="" element={<ErrorPage />} />
        </Routes>
      </>
    </>
  ) : (
    <>{AuthRoutes()}</>
  );
};

const AuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/:sessionId/:id" element={<Login />} />
        <Route

          path="/:email/sessionId/:id"
          element={<MarketingSessionFirstScreen />}
        />

        <Route path="/login/:sessionexpired" element={<Login />} />
        <Route path="/reset-password/" element={<ResetPassword />} />
        <Route path="/add-card/" element={<AddCard />} />
        <Route path="/card-login/" element={<CardLogin />} />
        <Route path="/add-account/" element={<AddAccount />} />
        <Route path="/admin-normal-account/" element={<AdminNormalAccount />} />
        <Route

          path="/reset-password/:tempToken/:userUniqueId"
          element={<ResetPassword />}
        />
        <Route path="/forgot-password/" element={<ForgotPassword />} />
        <Route path="/test/" element={<TestPage />} />
        <Route path="/impersonation/:id" element={<Impersonation />} />
        <Route path="/misgoals/:id" element={<MisGoals />} />
        <Route
          path="/marketing-video-session/:id"
          element={<MarketingVideoTherapy />}
        />
        <Route
          path="/marketing/whiteboard/:sessionid"
          element={<MarketingWhiteBoard />}
        />
        {/* <Route path="/*" element={Login} /> */}
        <Route path="" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

function Router(props) {
  const history = useNavigate();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const sessionId = urlParams.get("sessionId");
  let accoutType = location.pathname.includes("/admin");
  let user = useSelector((state) => state.adminUser.user);
  const dispatch = useDispatch();
  let tenantInfo = useSelector((state) => state.adminUser.tenantInfo);

  const id = window.location.pathname.split("/")[3];
  useEffect(() => {
    let tenantUrl =
      window.location.host === "meisqa.techment.com"
        ? "meisqa.techment.com"
        : window.location.host === "localhost:3000" ||
          window.location.host === "meisdev.techment.com"
          ? "dev-tenant-1.techment.com"
          : window.location.host;
    adminService.getAllAuth(`tenants?tenantUrl=${tenantUrl}`).then(
      (res) => {
        const data = res?.data && res.data[0];
        if (data) {
          dispatch(adminAction.setTenantInfo(data));
        }
      },
      (error) => {
        console.log({ error });
      }
    );
  }, []);

  useEffect(() => {
    if (user?.accessToken) {
      getSoapNoteBillingData(tenantInfo?.tenantId).then((res) => {
        dispatch(appActions.setSoapNoteBillingInfo(res));
      });
    }
  }, [dispatch, user?.accessToken]);

  useEffect(() => {
    console.log("hiiiiiiiiiiiiiiiiiiiiiii")
    var OSName = null;
    if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
    if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
    if (navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
    if (navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
    if (OSName == "Android" || OSName == "IOS") {
      window.location.href = `meis://sessionId/${id}`;
    } else {
      if (
        id &&
        user?.accessToken &&
        (window.location.pathname.includes("login") ||
          window.location.pathname.includes("video-therapy"))
      ) {
        history(`/video-therapy/${id}`);
      }
    }
    if (OSName == "Android" || OSName == "IOS") {
      window.location.href = `meis://sessionId/${id}`;
    } else {
      if (
        id &&
        user?.accessToken &&
        (window.location.pathname.includes("login") ||
          window.location.pathname.includes("marketing-video-therapy"))
      ) {
        history(`/marketing-video-therapy/${id}`);
      }
    }
  }, []);

  return (
    <>
      {user.accessToken && user && !user.userDetails.newUser ? (
        <>
          <Header />
          <div className="d-flex flex-column flex-grow-1">
            <div className="container-fluid d-flex flex-column flex-grow-1">
              <div className="row flex-grow-1">
                {/* {!location.pathname.includes("/marketing-video-therapy")&&
                  !location.pathname.includes("/marketing/whiteboard")} */}
                {!location.pathname.includes("/marketing-video-therapy") && !location.pathname.includes("/video-therapy") &&
                  !location.pathname.includes("/whiteboard") && <Sidebar />}
                {user.userDetails.roles[0] === "ROLE_RCE" &&
                  RepresentativeRoute(user)}
                {user.userDetails.roles[0] === "ROLE_THERAPIST" &&
                  TherapistRoute(user)}
                {user.userDetails.roles[0] === "ROLE_FAMILY" &&
                  FamilyRoutes(user)}
                {user.userDetails.roles[0] === "ROLE_INTERPRETER" &&
                  InterpreterRoutes(user)}
                {user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS" &&
                  RegnalEarlyStepsUserRoutes(user)}
              </div>
            </div>
          </div>
          <AllMessages />
          <AppLoader />
          {console.log("after login")}
        </>
      ) : (
        <>
          {console.log("before login")}
          {AuthRoutes()}
          <AppLoader />
        </>
      )}
    </>
  );
}

export default Router;
