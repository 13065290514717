import React, { useEffect, useState } from "react";
import AppModal from "../../../components/AppModal";
function GoalsModal(props) {
  const initialState = {
    concern: "",
    description: "",
    achievedPercentage: "",
  };
  const [val, setVal] = useState(initialState);
  const [err, setErr] = useState({
    concern: null,
    description: null,
    achievedPercentage: null,
  });
  useEffect(() => {
    if (props?.editGoalData?.concern) {
      setVal({
        concern: props?.editGoalData?.concern,
        description: props?.editGoalData?.description,
        achievedPercentage: props?.editGoalData?.achievedPercentage,
      });
    }
  }, [props?.editGoalData]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      val?.concern.trim() &&
      val?.description.trim() &&
      val?.achievedPercentage &&
      val?.achievedPercentage > 0 &&
      val?.achievedPercentage <= 100
    ) {
      const id = props?.editGoalData?.id || 0;
      if (props?.addLongGoals == "Long") {
        const lst = [...props?.formData?.longTermGoals];
        if (id == 0) {
          val.id = props?.formData?.longTermGoals.length + 1;
          val.editable = true;
          val.isNewItem = true;
          lst.push(val);
        } else {
          const idx = lst.findIndex((obj) => obj.id == id);
          lst[idx]["concern"] = val?.concern;
          lst[idx]["description"] = val?.description;
          lst[idx]["achievedPercentage"] = val?.achievedPercentage;
        }
        props?.setFormData({ ...props?.formData, longTermGoals: lst });
      } else {
        const lst = [...props?.formData?.shortTermGoals];
        if (id == 0) {
          val.id = props?.formData?.shortTermGoals.length + 1;
          val.editable = true;
          val.isNewItem = true;
          lst.push(val);
        } else {
          const idx = lst.findIndex((obj) => obj.id == id);
          lst[idx]["concern"] = val?.concern;
          lst[idx]["description"] = val?.description;
          lst[idx]["achievedPercentage"] = val?.achievedPercentage;
        }
        props?.setFormData({ ...props?.formData, shortTermGoals: lst });
      }
      setVal(initialState);
      props?.setEditGoalData({});
      props?.setAddLongGoals(false);
    } else {
      setErr({
        concern: val.concern.trim() ? false : "This field is required",
        description: val.description.trim() ? false : "This field is required",
        achievedPercentage: val.achievedPercentage
          ? val.achievedPercentage >= 0 && val.achievedPercentage <= 100
            ? false
            : "This field is required"
          : "This field is required",
      });
    }
  };

  return (
    <>
      <AppModal
        title={`${props?.editGoalData?.id ? "Update" : "Add"} ${
          props?.addLongGoals
        } Term Goals`}
        isOpen={props?.addLongGoals}
        onClose={() => {
          props?.setAddLongGoals(false);
          props?.setEditGoalData({});
        }}
      >
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="row">
            <div className="col-12">
              <label className="font-weight-bold">
                <strong> Area of Concern</strong>
                <span className="text-danger">&nbsp;*</span>
              </label>
              <input
                type="text"
                className={`form-control ${err?.concern && "is-invalid"}`}
                id="concern"
                name="concern"
                placeholder="Area of Concern"
                value={val?.concern}
                onChange={(e) =>
                  props?.goalData?.drafted === true &&
                  props?.editGoalData?.editable === false
                    ? console.log("")
                    : setVal({ ...val, concern: e.target.value })
                }
                disabled={
                  props?.goalData?.drafted === true &&
                  props?.editGoalData?.editable === false
                }
              />
              <span className="text-red">
                <small>{err?.concern}</small>
              </span>
            </div>
            <div className="mt-3 col-12">
              <label className="font-weight-bold">
                <strong> {props?.addLongGoals} Term Description</strong>
                <span className="text-danger">&nbsp;*</span>
              </label>
              <input
                type="text"
                className={`form-control ${err.description && "is-invalid"}`}
                name="description"
                id="description"
                placeholder={`${props?.addLongGoals} Term Description`}
                value={val?.description}
                onChange={(e) =>
                  props?.goalData?.drafted === true &&
                  props?.editGoalData?.editable === false
                    ? console.log("")
                    : setVal({ ...val, description: e.target.value })
                }
                disabled={
                  props?.goalData?.drafted === true &&
                  props?.editGoalData?.editable === false
                }
              />
              <span className="text-red">
                <small>{err?.description}</small>
              </span>
            </div>
            <div className="mt-3 col-12">
              <label className="font-weight-bold">
                <strong> Achieved ( % )</strong>
                <span className="text-danger">&nbsp;*</span>
              </label>
              {/* <input
                type="number"
                min={1}
                max={100}
                className={`form-control ${
                  err.achievedPercentage && "is-invalid"
                }`}
                name="achievedPercentage"
                id="achievedPercentage"
                value={val?.achievedPercentage}
                onChange={(e) =>
                  setVal({ ...val, achievedPercentage: e.target.value })
                }
              />
              <span className="text-red">
                <small>{err?.achievedPercentage}</small>
              </span>

              <label for="customRange3" class="form-label">
                Example range
              </label> */}
              <div className="row">
                <div className="col-md-11">
                  <input
                    type="range"
                    class="slider"
                    min="0"
                    max="100"
                    step="1"
                    value={val?.achievedPercentage || 0}
                    onChange={(e) =>
                      setVal({ ...val, achievedPercentage: e.target.value })
                    }
                    id="customRange3"
                  />
                </div>
                <div className="col-md-1 text-left p-0">
                  {val?.achievedPercentage || 0}%
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 d-flex justify-content-center">
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-theme-green">
                {props?.editGoalData?.id ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </AppModal>
    </>
  );
}

export default GoalsModal;
