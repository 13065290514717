import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllMsgLink } from './msgUtil'
import RecentMsgItem from './RecentMsgItem'

const RecentMessages = ({maxList}) => {
    const profile = useSelector(state => state.adminUser.profile);
    const allList = useSelector(state => state.messages?.profiles);
    const otherUsers = useSelector((state) => state.messages?.otherUsers || []);
    const [state, setState] = useState({
        recentMsg: []
    })

    const {
        userType
    } = profile

    useEffect(() => {
        const profileKeys = Object.keys(allList);
        const recentMsg = Object.values(allList || {})?.map(item => {
            return Object.keys(item)?.map(itx => {
                const list = Object.values(item[itx]);
                const listFormOther = list.filter(i => !profileKeys.includes(i.from))?.reverse();
                const topMsg = list[list.length - 1]
                return {
                    key: itx,
                    topMsg,
                    topReceivedMsg: listFormOther[0] || null,
                }
                
            })
        })?.flat()
        setState(state => ({
            ...state, 
            recentMsg
        }))
    }, [allList])

    return (
        <div className="w-100">
            <div className="content-header border-0">
                <div className="text-left header-title">Recent Messages</div>
                <div className="text-right header-btn"><button className="btn btn-primary custom-btn-yellow custom-btn-yellow-sm">
                    <Link to={getAllMsgLink(userType)} >
                        View All
                    </Link>
                </button></div>
            </div>
            <div>
                <div className="recent-messages">
                    {
                        state.recentMsg.length > 0 ? state.recentMsg.map((msg, index) => {
                            if(index < maxList){
                                const fUser = otherUsers.find(usr => usr.key === msg.key)
                                return <RecentMsgItem 
                                    key={index}
                                    msgKey={msg.key}
                                    image={fUser?.image}
                                    name={fUser?.name}
                                    msg={msg.topMsg?.message}
                                />
                            }
                            return null
                        }) : (
                            <div className="text-center border-top pt-2">
                                <h5 className=" mt-2">No Messages Found</h5>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default RecentMessages

RecentMessages.defaultProps = {
    maxList: 3
}