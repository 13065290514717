import React from 'react'
import QueryItem from './QueryItem'

const QueryReplyList = ({ data, translateForm, familyLanguages, languageDto, onSuccess, interpreterName }) => {
    const getName = (item) => {
      if(item?.postedBy?.userType === "ROLE_FAMILY"){
        return item?.childName
      }
      return ((item?.postedBy?.firstName || "") + " " + (item?.postedBy?.lastName || "")).trim()
    }
    const list = (data || []).map(item => ({
      attachmentsList: item.attachments || [],
      date: item.createdDate || null,
      detail: item.reply,
      title: null,
      translatedDetail: item.translatedReply,
      profilePicKey: item?.postedBy?.userType === "ROLE_FAMILY" ? item.childProfilePic : item.postedBy?.profilePicUrl,
      name: getName(item),
      queryId: item.queryId,
      id: item.id,
      isReply: true,
      familyLanguages, languageDto, translateForm,
      onSuccess,
      interpreterName,
      key: item.id
    }))
    return list.map(item => <QueryItem {...item} />)
}

export default QueryReplyList
