import React, { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import InjectCheckOut from './CheckoutForm';
import { adminService } from '../../services/adminService';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

//const stripePromise = loadStripe("pk_test_51L93VpSIqiHQiTzcCQtM3cCfikd7d53y6VK1PPjhdw1RdX2xnp8L1kvoe7asyYDWpXvYe4oJJluH0B9vPFsx39wE00zP8OSvpe");
const successMessage = () => {
  return (
    <div className="success-msg">
      <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
      </svg>
      <div className="title">Payment Successful</div>
    </div>
  )
}
function Card(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  let accountId = useSelector((state) => state?.adminUser?.accountData);

  const getAccount = () => {
    setStripePromise(loadStripe("pk_test_51L93VpSIqiHQiTzcCQtM3cCfikd7d53y6VK1PPjhdw1RdX2xnp8L1kvoe7asyYDWpXvYe4oJJluH0B9vPFsx39wE00zP8OSvpe", {
      stripeAccount: accountId,
    }))
  }
  useEffect(() => {
    getAccount();
  }, [stripePromise])
  console.log("stripePromise", stripePromise);
  return (
    <div className='col-md-10 no-padding'>
      <div className='no-padding dash-billing-info'>
        <div className='custom-table-view'>
          {paymentCompleted ? successMessage() : <React.Fragment>
            <div className="order-md-1">
              <Elements stripe={stripePromise}>
                <InjectCheckOut amount={2000} setPaymentCompleted={setPaymentCompleted} />
              </Elements>
            </div>
          </React.Fragment>}
        </div>
      </div>
    </div>
  );
}

export default Card;