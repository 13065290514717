import React from "react";
import classNames from "classnames";
import { CaseNoteInfoCol } from "../../components/case-note-detail";
import moment from "moment-timezone";

const InvoicePrint = React.forwardRef((props, ref) => {
    let propsData = props?.billingData;
    let formateData = JSON.stringify({ propsData })
    const relValue =
        typeof props?.data?.reply === "undefined"
            ? ""
            : JSON.parse(formateData);

    console.log("relValue casenote", formateData, propsData, props);
    // return (

    // )
    return (
        <>
            <div ref={ref} className="col-md-12">
                <>
                    <div style={{ width: "100%" }}>
                        <div class="row mb-10" style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                            <div class="col-md-6" style={{ width: "50%" }}>
                                <div className='addressInvoice' style={{ width: "100%", display: "flex", flexDirection: "row" }}><div style={{ width: "15%" }}>From, </div><div style={{ width: "85%" }}>{propsData?.billingCompany?.name}<br />{propsData?.billingCompany?.address1}</div></div>
                            </div>
                            <div className='col-md-6 text-right' style={{ width: "50%", textAlign: "right" }}>
                                <img style={{
                                    maxWidth: "250px",
                                    maxHeight: "100px",
                                    objectFit: "contain"
                                }} src={propsData?.billingCompany?.logoUrl} />
                            </div>
                        </div>
                        <h3 className='text-center' style={{ width: "100%", textAlign: "center" }}>{propsData?.superBill ? "Statement for Insurance Reimbursement" : "Invoice"}</h3>
                        <div className='row' style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: "0px" }}>
                            <div className='col-md-6' style={{ width: "50%" }}>
                                <p className='addressInvoice'><span>To, </span><span style={{ marginLeft: "10px", marginBottom: "0" }}>{propsData?.familyMemberDto?.firstName + " " + propsData?.familyMemberDto?.lastName}</span></p>
                            </div>
                            <div className='col-md-6' style={{ width: "50%", textAlign: "left" }}>
                                <div className='text-right' style={{ width: "100%", textAlign: "left" }}>
                                    <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%", paddingLeft: "150px" }}><span>Invoice </span>|<span> {propsData?.invoiceNo}</span></p>
                                    <p style={{ width: "100%", marginTop: "0", marginBottom: "0", paddingLeft: "150px" }}><span>Issue Date </span>|<span> {propsData?.invoiceDate ? moment(propsData?.invoiceDate).format("MM/DD/YYYY") : moment().format('YYYY-MM-DD')}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='row' style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "0px" }}>
                            <div className='col-md-6' style={{ width: "50%" }}>
                                <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%" }}>Client,</p>
                                <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%" }}>{propsData?.familyMemberDto?.firstName + " " + propsData?.familyMemberDto?.lastName}</p>
                                <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%" }}>{propsData?.familyMemberDto?.phone}</p>
                                <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%" }}>{propsData?.familyMemberDto?.email}</p>
                                {propsData?.superBill ? <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%" }}>DOB : {propsData?.familyMemberDto?.dob ? moment(propsData?.familyMemberDto?.dob).format("MM/DD/YYYY") : ''}</p> : ''}
                            </div>
                            <div className='col-md-6' style={{ width: "50%", textAlign: "left" }}>
                                <div className='text-right' style={{ width: "100%", textAlign: "left" }}>
                                    <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%", paddingLeft: "150px" }}>Provider,</p>
                                    <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%", paddingLeft: "150px" }}>{propsData?.provider?.firstName + " " + propsData?.provider?.lastName}</p>
                                    {propsData?.superBill ? '' : <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%", paddingLeft: "150px" }}>Tax ID : {propsData?.therapistDto?.taxonomy}</p>}
                                    {propsData?.superBill ? <></> : <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%", paddingLeft: "150px" }}>NPI : {propsData?.billingCompany?.groupNpiNumber}</p>}
                                    <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%", paddingLeft: "150px" }}>{propsData?.provider?.phone}</p>
                                    <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%", paddingLeft: "150px" }}>{propsData?.provider?.email}</p>
                                    {propsData?.superBill ? <p className='mb-0' style={{ marginBottom: "0", marginTop: "0", width: "100%", paddingLeft: "150px" }}>NPI : {propsData?.billingCompany?.groupNpiNumber}</p> : <></>}
                                    {propsData?.superBill ? <p className='mb-0' style={{ marginBottom: "0", width: "100%", textAlign: "left", marginTop: "0", paddingLeft: "150px" }}>Licenses : {propsData?.therapistDto?.licenseNumberState}</p> : ''}
                                </div>
                            </div>
                        </div>
                        <div className='row' style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "0px" }}>
                            <div className='col-md-6' style={{ width: "50%" }}></div>
                            <div className='col-md-6' style={{ width: "50%", textAlign: "left" }}>
                                <div className='text-right' style={{ width: "100%", textAlign: "left" }}>
                                    {propsData?.superBill ? <p className='mb-0' style={{ width: "100%", marginBottom: "0", marginTop: "0", paddingLeft: "150px" }}><span>Practice </span>|<span> Tax ID : {propsData?.therapistDto?.taxonomy}<br />NPI : {props?.npiName}</span></p>
                                        : ''}
                                </div>
                            </div>
                        </div>
                        {propsData?.superBill ?
                            <>
                                <div className='table-responsive mt-3' style={{ width: "100%", marginTop: "10px" }}>
                                    <table className='table table-bordered' style={{ width: "100%" }}>
                                        <tbody style={{ width: "100%" }}>
                                            <tr>
                                                <td style={{ width: "5%" }}><b>DX</b></td>
                                                <td><b>Diagnosis Code</b></td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "5%" }}>1</td>
                                                <td>({propsData?.diagnosisDTO?.code}){propsData?.diagnosisDTO?.description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='table-responsive mt-3' style={{ width: "100%", marginTop: "10px" }}>
                                    <table className='table table-bordered' style={{ width: "100%" }}>
                                        <tbody style={{ width: "100%" }}>
                                            <tr>
                                                <td style={{ width: "15%" }}><b>Date</b></td>
                                                <td style={{ width: "12%" }}><b>Service</b></td>
                                                <td style={{ width: "10%" }}><b>DX</b></td>
                                                <td><b>Description</b></td>
                                                <td style={{ width: "8%", textAlign: 'right' }}><b>Units</b></td>
                                                <td style={{ width: "12%", textAlign: 'right' }}><b>Fee</b></td>
                                                <td style={{ width: "12%", textAlign: 'right' }}><b>Paid</b></td>
                                            </tr>
                                            {propsData?.invoiceDetails?.length ? propsData?.invoiceDetails.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ width: "15%" }}>{item?.invoiceDate ? moment(item?.invoiceDate).format("MM/DD/YYYY") : moment().format('YYYY-MM-DD')}</td>
                                                    <td style={{ width: "12%" }}>{item?.sessionBillingId ? propsData?.diagnosisDTO?.therapyDto?.code : ''}</td>
                                                    <td style={{ width: "10%" }}>1</td>
                                                    <td>{item?.description}</td>
                                                    <td style={{ width: "8%", textAlign: 'right' }}>{item?.sessionBillingId ? propsData?.caseNoteUnits : ''}</td>
                                                    <td style={{ width: "12%", textAlign: 'right' }}><b>$ {item?.amount}</b></td>
                                                    <td style={{ width: "12%", textAlign: 'right' }}><b>$ {item?.amount}</b></td>
                                                </tr>
                                            ))
                                                :
                                                <tr><td>No Data Available</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            :
                            <div className='table-responsive mt-3' style={{ width: "100%", marginTop: "10px" }}>
                                <table className='table table-bordered' style={{ width: "100%" }}>
                                    <tbody style={{ width: "100%" }}>
                                        <tr>
                                            <td style={{ width: "15%" }}><b>Date</b></td>
                                            <td><b>Description</b></td>
                                            <td style={{ width: "15%", textAlign: 'right' }}><b>Amount</b></td>
                                        </tr>
                                        {propsData?.invoiceDetails?.length ? propsData?.invoiceDetails.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ width: "15%" }}>{item?.invoiceDate ? moment(item?.invoiceDate).format("MM/DD/YYYY") : moment().format('YYYY-MM-DD')}</td>
                                                <td>{item?.description}</td>
                                                <td style={{ width: "15%", textAlign: 'right' }}><b>$ {item?.amount}</b></td>
                                            </tr>
                                        ))
                                            :
                                            <tr><td>No Data Available</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className='row mt-3' style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "150px" }}>
                            {propsData?.superBill ?
                                <>
                                    <div className='col-md-2' style={{ width: "16%", marginTop: 'auto', position: 'relative' }}>
                                        <div style={{ width: "100%", position: "absolute", bottom: "0px" }}>
                                            <p style={{ width: "100%" }}>{moment(propsData?.provider?.signatureDateTime).format("MM/DD/YYYY")}</p>
                                            <p style={{ width: "100%" }}><b>Date</b></p>
                                        </div>
                                    </div>
                                    <div className='col-md-2' style={{ width: "16%", marginTop: 'auto', position: 'relative' }}>
                                        <div style={{ width: "100%", position: "absolute", bottom: "0px" }}>
                                            <p style={{ width: "100%" }}><img style={{ width: "100%" }} src={propsData?.provider?.signatureUrl} /></p>
                                            <p style={{ width: "100%" }}><b>Signature</b></p>
                                        </div>
                                    </div>
                                    <div className='col-md-4' style={{ width: "33%" }}></div></>
                                :
                                <div className='col-md-8' style={{ width: "65%" }}></div>
                            }
                            <div className='col-md-4' style={{ width: "35%", textAlign: "right", marginTop: 'auto', position: 'relative' }}>
                                <div style={{ width: "100%", position: "absolute", bottom: "0px" }}>
                                    {propsData?.superBill ?
                                        <>
                                            <p style={{ width: "100%", display: "inline-block", marginBottom: "0" }}>
                                                <span style={{ float: "left" }}><b>Total Charges</b></span>
                                                <span className='text-right' style={{ float: "right" }}>$ {propsData?.totalAmount}</span>
                                            </p>
                                            <br />
                                            <p style={{ width: "100%", display: "inline-block", marginBottom: "0" }}>
                                                <span style={{ float: "left" }}><b>Total Paid</b></span>
                                                <span className='text-right' style={{ float: "right" }}>$ {propsData?.totalAmount}</span>
                                            </p></>
                                        : <p style={{ width: "100%", display: "inline-block", marginBottom: "0" }}>
                                            <span style={{ float: "left" }}><b>Total</b></span>
                                            <span className='text-right' style={{ float: "right" }}>$ {propsData?.totalAmount}</span>
                                        </p>
                                    }

                                    <br />
                                    <p style={{ width: "100%", display: "inline-block" }}>
                                        <span style={{ float: "left" }}>Make payments to : </span>
                                        <span className='text-right' style={{ float: "right" }}>{propsData?.provider?.firstName + " " + propsData?.provider?.lastName}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {propsData?.billingStatus === 'WAIVED_OFF' ? <></> : <p style={{ width: "100%" }}><b>Note:</b> Please pay your invoice within {moment(propsData?.overDueDate).format(
                        "DD MMMM YYYY"
                    )}, otherwise {propsData?.lateFees ? '$' + propsData?.lateFees : ''} late fee applies</p>}
                    {/* {propsData?.lateFees !== null ? <p style={{ width: "100%" }}><b>Note:</b> Please pay your invoice within {moment(propsData?.overDueDate).format(
                        "MMMM DD, YYYY"
                    )} days of receipt, otherwise ${propsData?.lateFees} late fee applies</p> : ''} */}
                    <p style={{ pageBreakBefore: "always" }}>&nbsp;</p>
                    <footer></footer>
                </>
            </div>
        </>

    );
}
);

export default InvoicePrint;
