import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { adminService } from "../../services/adminService";
import { useForm } from "react-hook-form";
import allActions from "../../redux/actions";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import {
  showError,
  showWarning,
  sortLanguage,
} from "../../utils/commonFunctions";
import validationRules from "../../utils/validationRules";
import CurrencyInput from "../../components/CurrencyInput";

function AddLanguage(props) {
  const dispatch = useDispatch();
  let interpreterForm = useSelector((state) => state.adminUser.interpreterForm);
  const [language, setLanguage] = useState("");
  const [interpreterLanguageId, setinterpreterLanguageId] = useState(
    props.serviceinfo.info.interpreterLanguageId
      ? props.serviceinfo.info.interpreterLanguageId
      : []
  );
  const [languageIds, setLanguageIds] = useState([]);
  const [inputs, setInputs] = useState({
    languageId: props.serviceinfo.info.languageId
      ? props.serviceinfo.info.languageId
      : 0,
    hourlyRate: props.serviceinfo.info.hourlyRate
      ? props.serviceinfo.info.hourlyRate
      : "",
    interpreterLanguageId: props.serviceinfo.info.interpreterLanguageId
      ? props.serviceinfo.info.interpreterLanguageId
      : "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    trigger,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      hourlyRate: props.serviceinfo.info.hourlyRate
        ? props.serviceinfo.info.hourlyRate
        : "",
      languageId: props.serviceinfo.info.languageId
        ? props.serviceinfo.info.languageId
        : "",
    },
  });

  useEffect(() => {
    !props.isdelete &&
      register &&
      register("hourlyRate", validationRules.interpreterHourlyRate);
      register("languageId", validationRules.interpreterLanguage);
  }, [register, props.isdelete]);

  const onSubmit = () => {
    if (languageIds.length > 0) {
      dispatch(
        allActions.adminAction.setInterpreterFormData(
          languageIds,
          "interpreterLanguageForms"
        )
      );
    } else {
      alert("Please add atleast 1 Interpreting Detail");
    }
  };

  let params = useParams();
  const addLanguage = () => {
    if (
      !props.serviceinfo.info.interpreterLanguageId &&
      props.servicedetail.filter(
        (item) => item.language.id == inputs.languageId
      ).length > 0
    ) {
      showWarning("language_added");
      return;
    }

    if (inputs.languageId != 0 && inputs.hourlyRate != "") {
      if (inputs.hourlyRate > 0 && inputs.hourlyRate <= 999) {
        let formData = new FormData();

        let forkey = props.isdelete
          ? "deletedInterpreterLanguageIds"
          : props.serviceinfo.info.interpreterLanguageId
            ? "interpreterLanguageUpdateForms"
            : "newInterpreterLanguageForms";
        let newform = {
          [forkey]: [props.isdelete ? interpreterLanguageId : inputs],
        };

        formData.append(
          "interpreterUpdateForm",
          new Blob([JSON.stringify(newform)], {
            type: "application/json",
          })
        );
        setIsLoading(true);
        adminService
          .update(`/interpreters/` + params.id, formData)
          .then(
            (res) => {
              console.log(res);
              if (res.status === "Success") {
                if (
                  props.isdelete
                    ? toast.success("Language deleted successfully!")
                    : toast.success(res.message)
                )
                  props.addFun();
                setTimeout(() => {
                  //  history('/interpreter/' + params.id)
                }, 2000);
              } else {
                toast.error(res.message);
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        toast.warn("Hourly rate must be $1-$999");
      }
    } else {
      toast.warn("Please fill all the required information");
    }
  };

  const removeLanguage = (id) => {
    setLanguageIds(languageIds.filter((item) => item.languageId !== id));
  };

  function getLanguageName(id) {
    return language.filter((lang) => lang.id === id)[0].languageName;
  }

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "hourlyRate") {
      setInputs((inputs) => ({ ...inputs, [name]: value ? value : "" }));
    } else
      setInputs((inputs) => ({ ...inputs, [name]: value && parseInt(value) }));
  }

  useEffect(() => {
    setInputs(inputs);
    console.log(inputs);
  }, [inputs]);

  useEffect(() => {
    setIsLoading(true);
    adminService
      .getAll(`languages?size=1000`)
      .then(
        (res) => {
          setLanguage(sortLanguage(res.data.content || []));
          if (interpreterForm.hasOwnProperty("interpreterLanguageForms"))
            setLanguageIds(interpreterForm.interpreterLanguageForms);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.closeModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(addLanguage)}>
        <div className="col-md-2 custom-modal">
          <div
            className="modal fade add-new-prop-modal show "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="w-100 text-center">
                    <h5 className="modal-title name">
                      {props.isdelete
                        ? "Delete Language"
                        : props.serviceinfo.info.languageId
                          ? "Edit Language"
                          : "Add Language"}
                    </h5>
                  </div>
                  {props.isdelete ? (
                    <></>
                  ) : (
                    <button
                      type="button"
                      onClick={() => props.closeModal(false)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <div className="close-background">
                        <p aria-hidden="true" className="cross">
                          &times;
                        </p>
                      </div>
                    </button>
                  )}
                </div>
                <div className="modal-body">
                  {props.isdelete ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <h4>
                            <img
                              src="../resources/assets/Icon feather-trash-2.svg"
                              className="deleteicon"
                              alt=""
                            />
                            <span className="ml-3">Are You Sure?</span>
                          </h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <p>
                            Do you really want to delete{" "}
                            {props.serviceinfo.info.languageName} $
                            {parseFloat(
                              props.serviceinfo.info.hourlyRate
                            ).toFixed(2)}
                            /Hr
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row">
                        <div className="col-md-9">
                          <p className="name">Language</p>
                          <div className="input-group">
                            <select
                              className="custom-select"
                              onChange={(e)=>{handleChange(e);
                                setValue("languageId", e.target.value);
                                if (isDirty) {
                                  trigger("languageId");
                                }}}
                              disabled={
                                props.serviceinfo.info.languageId ? true : false
                              }
                              value={
                                props.serviceinfo.info.languageId &&
                                props.serviceinfo.info.languageId
                              }
                              name="languageId"
                            // ref={register(
                            //   validationRules.interpreterLanguage
                            // )}
                            >
                              <option value="">Select Language</option>
                              {language &&
                                language.map((item, index) => (
                                  <option
                                    key={`${item.id}${index}`}
                                    value={item.id}
                                  >
                                    {item.languageName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <p className="name">Hourly rate</p>
                          <div className="input-group">
                            <CurrencyInput
                              onChange={(e) => {
                                handleChange(e);
                                setValue("hourlyRate", e.target.value);
                                if (isDirty) {
                                  trigger("hourlyRate");
                                }
                              }}
                              defaultValue={inputs.hourlyRate}
                              name="hourlyRate"
                              className="form-control"
                              placeholder="0.0"
                            />
                            {/* <input 
                                                            type="number" 
                                                            onChange={handleChange} 
                                                            defaultValue={inputs.hourlyRate} 
                                                            name="hourlyRate" 
                                                            className="form-control" 
                                                            placeholder="0.0" 
                                                            min="0.00" max="999.00" step="0.01"
                                                            onWheel={event => { 
                                                                event.preventDefault(); 
                                                                if(typeof event.target.blur === 'function' ){
                                                                    event.target.blur()
                                                                }
                                                            }}
                                                            // required 
                                                            ref={register(validationRules.interpreterHourlyRate)}
                                                        /> */}
                          </div>
                        </div>
                      <div className="col-12">
                        <div className="col-12">
                          <ErrorBlock errors={errors} />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="text-center mt-4 mb-4">
                    <hr />
                    {props.isdelete ? (
                      <>
                        <button
                          disabled={isLoading}
                          type="button"
                          className="btn btn-primary noModalbutton mr-4"
                          onClick={() => props.closeModal(false)}
                        >
                          No
                        </button>
                        <button
                          disabled={isLoading}
                          type="submit"
                          // onClick={() => addLanguage()}
                          className="btn btn-primary custom-btn-green ml-4"
                        >
                          Yes
                        </button>
                      </>
                    ) : props.serviceinfo.info.languageId ? (
                      <button
                        disabled={isLoading}
                        type="submit"
                        // onClick={() => addLanguage()}
                        className="btn btn-primary custom-btn-green"
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        disabled={isLoading}
                        type="submit"
                        // onClick={() => addLanguage()}
                        className="btn btn-primary custom-btn-green"
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddLanguage;

const ErrorBlock = ({ errors }) => {
  const errorList = Object.values(errors || {});
  if (!errorList.length > 0) {
    return null;
  }
  return (
    <div className="mt-4 c-error-block border-danger border p-3">
      {errorList.map((item, i) => (
        <p className={`text-danger ${i + 1 === errorList.length && "mb-0"}`}>
          {i + 1}. {item.message}
        </p>
      ))}
    </div>
  );
};
