import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import InputFileBtn from "../InputFileBtn";
import { nameRegex } from "../../utils/regex";
import usePageState from "../../hooks/InitialState";

function TenantForm(props) {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const saveNextRef = useRef(null);
  const history = useNavigate();
  const params = useParams();
  const [inputs, setInputs] = useState();
  const { pageState, setPageState } = usePageState();
  const [errorPhone, setErrorPhone] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [loadings, setLoadings] = useState(false);

  const onSubmit = (e) => {
    console.log("data", e, inputs);

    if (inputs?.profilePicKey) {
      e["profilePicKey"] = inputs?.profilePicKey;
    }

    //edit if get user id or add new
    if (params?.id) {
      adminService
        .PUT(`tenantadmin/` + params?.id, JSON.stringify(e))
        .then((res) => {
          console.log(res);
          if (res?.status === "Success") {
            console.log("success");
            toast.success(res?.message || "Something went wrong");
            history("/settings");
            // setOpenModal(callFrom != "saveasdraft" ? "submit" : callFrom);
          } else {
            console.log("other..");
            toast.error(res?.message || "Something went wrong");
          }
        })
        .catch((err) => {});
    } else {
      adminService
        .POST(`tenantadmin`, JSON.stringify(e))
        .then((res) => {
          console.log(res);
          if (res?.status === "Success") {
            console.log("success");
            toast.success(res?.message || "Something went wrong");
            history("/settings");
            // setOpenModal(callFrom != "saveasdraft" ? "submit" : callFrom);
          } else {
            console.log("other..");
            toast.error(res?.message || "Something went wrong");
          }
        })
        .catch((err) => {});
    }
  };

  const handleImageChange = (e) => {
    console.log(e);
    e.preventDefault();
    const { name, value } = e.target;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FileSize = e.target.files[0].size / 1024 / 1024; // in MB
    if (file.name.match(/.(jpg|jpeg|jpeg2000|png|svg)$/i)) {
      if (FileSize > 2) {
        toast.warn("Please upload file having size MAX 2MB");
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          file.url = reader.result;
          file.isnew = true;
          //setProfileImage(file);
          setInputs((inputs) => ({
            ...inputs,
            [name]: URL.createObjectURL(e.target.files[0]),
          }));

          let formData = new FormData();
          formData.append("imageFile", file);
          adminService
            .POSTUploadImage(`upload/image`, formData)
            .then(
              (res) => {
                if (res.status === "Success") {
                  console.log(res);
                  let attachment_json = {
                    file: file,
                    attachmentKeys: res.data.fileKey,
                  };
                  //setAttachments([...attachments, attachment_json]);
                  // setValue("profilePicKey", res.data.fileKey);
                  setInputs((inputs) => ({
                    ...inputs,
                    profilePicKey: res.data.fileKey,
                  }));
                  // setValue("profilePicKey", res.data.fileKey);
                  // setAttachmentLoadings(false);
                } else {
                  toast.error(res.message);
                }
              },
              (error) => {
                console.log("Error >>>>>>>", error);
              }
            )
            .finally(() => {
              //dispatch(appAction.setAppLoading(false));
              //target.value = "";
            });
        };
        console.log("ok");
      }
    } else {
      toast.warn("Please upload file having extensions - JPG,JPEG,PNG,SVG");
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "phone") {
      const currentValue = value.replace(/[^\d]/g, "");
      if (currentValue.length === 10) setErrorPhone("");
      // setInputs(inputs => ({ ...inputs, [name]: normalizeInput(value) }));
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const allowNumbersOnly = (event) => {
    if (event.keyCode >= 65 && event.keyCode <= 90) {
      event.preventDefault();
      return;
    }
  };

  //set default value
  useEffect(() => {
    if (props?.getInfo) {
      setInputs(props?.getInfo);
      //console.log("form page", props?.getInfo);
      setValue("firstName", props?.getInfo?.firstName);
      setValue("lastName", props?.getInfo?.lastName);
      setValue("email", props?.getInfo?.email);
      setValue("phone", props?.getInfo?.phone);
      setValue("gender", props?.getInfo?.gender);
      setValue("about", props?.getInfo?.about);
      setValue("profilePicKey", props?.getInfo?.mobileProfilePicKey);
    }
  }, [props?.getInfo]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="addtheripist">
          <div className="row gutter-33">
            <div className="col-12">
              <div className="mb-4">
                <label>
                  <b>Profile Picture</b>
                </label>{" "}
                {params.id ? (
                  <span>
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          src={
                            inputs?.profileImage
                              ? inputs?.profileImage
                              : inputs?.profilePicUrl
                              ? inputs?.profilePicUrl
                              : "/resources/assets/defaultprofile.png"
                          }
                          alt=""
                          className="settingsimg"
                        />
                      </div>
                      <InputFileBtn
                        id="fileuplaod"
                        name="profileImage"
                        onChange={(e) => handleImageChange(e)}
                      />
                    </div>
                    <br />
                  </span>
                ) : (
                  <label for="fileuplaod" className="fileuplaod">
                    <div className="content-box">
                      <img
                        src={
                          inputs?.profileImage
                            ? inputs?.profileImage
                            : inputs?.profilePicUrl
                            ? inputs?.profilePicUrl
                            : "../resources/assets/file-upload-icon.svg"
                        }
                        alt="Image"
                      />
                      <div className="file-title">
                        Drop your image here, or <strong>browse</strong>{" "}
                      </div>
                      <div className="file-name">Support- JPG,JPEG,PNG,SVG</div>
                    </div>
                    <input
                      type="file"
                      id="fileuplaod"
                      name="profileImage"
                      onChange={(e) => handleImageChange(e)}
                      //{...register({ required: inputs.profileImage ? false : true })}
                    />
                  </label>
                )}
                {errors.profileImage &&
                  errors.profileImage.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Please upload profile picture
                    </div>
                  )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-4">
                <label>
                  <b>First Name</b>
                </label>{" "}
                (<span className="required-span">*</span>)
                <input
                  // value={inputs.firstName}
                  defaultValue={inputs?.firstName}
                  //disabled={params.uid ? true : false}
                  name="firstName"
                  //onChange={handleChange}
                  type="text"
                  className={`form-control ${errors.firstName && "is-invalid"}`}
                  placeholder="Enter First Name"
                  {...register('firstName',{
                    onChange:(e)=>handleChange(e),
                    required: true,
                    maxLength: 30,
                    pattern: nameRegex,
                  })}
                  autoComplete="off"
                />
                {errors.firstName && errors.firstName.type === "required" && (
                  <div className="invalid-feedback-custom">
                    First name is required
                  </div>
                )}
                {errors.firstName && errors.firstName.type === "pattern" && (
                  <div className="invalid-feedback-custom">
                    Alphabetical characters only
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-4">
                <label>
                  <b>Last Name</b>
                </label>{" "}
                (<span className="required-span">*</span>)
                <input
                  {...register('lastName',{
                    onChange:(e)=>handleChange(e),
                    required: true,
                    maxLength: 30,
                    pattern: nameRegex,
                  })}
                  // value={inputs.lastName}
                  defaultValue={inputs?.lastName}
                  //disabled={params.uid ? true : false}
                  name="lastName"
                  type="text"
                  //onChange={handleChange}
                  className={`form-control ${errors.lastName && "is-invalid"}`}
                  placeholder="Enter Last Name"
                />
                {errors.lastName && errors.lastName.type === "required" && (
                  <div className="invalid-feedback-custom">
                    Last name is required
                  </div>
                )}
                {errors.lastName && errors.lastName.type === "pattern" && (
                  <div className="invalid-feedback-custom">
                    Alphabetical characters only
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-4">
                <label>
                  <b>Email Address</b>
                </label>{" "}
                (<span className="required-span">*</span>)
                <input
                  {...register('email',{
                    onChange:(e)=>handleChange(e),
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  // value={inputs.email}
                  defaultValue={inputs?.email}
                  //disabled={params.id ? true : false}
                  name="email"
                  type="email"
                  //onChange={handleChange}
                  className={`form-control ${errors.email && "is-invalid"}`}
                  placeholder="Enter Email Address"
                />
                {errors.email?.type === "required" && (
                  <div className="invalid-feedback-custom">
                    Email address is required
                  </div>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <div className="invalid-feedback-custom">
                    Please enter valid email address
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-4">
                <label>
                  <b>Phone Number</b>
                </label>{" "}
                (<span className="required-span">*</span>)
                <input
                  {...register('phone',{ onChange:(e)=>handleChange(e),required: true, pattern: /^\d{9,10}$/ })}
                  //value={inputs.phone}
                  defaultValue={inputs?.phone}
                  //disabled={params.id ? true : false}
                  name="phone"
                  type="text"
                  //onChange={handleChange}
                  onKeyDown={(e) => allowNumbersOnly(e)}
                  className={`form-control ${errors.phone && "is-invalid"}`}
                  placeholder="Enter Phone Number"
                  maxlength="10"
                />
                {errors.phone?.type === "required" && (
                  <div className="invalid-feedback-custom">
                    Phone number is required
                  </div>
                )}
                {errorPhone ? (
                  <div className="invalid-feedback-custom">
                    Please enter 10 digit phone number
                  </div>
                ) : (
                  ""
                )}
                {errors.phone && errors.phone.type === "pattern" && (
                  <div className="invalid-feedback-custom">
                    Please enter valid phone number
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-4">
                <label>
                  <b>Gender</b>
                </label>{" "}
                (<span className="required-span">*</span>)
                <br />
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                    //onChange={handleChange}
                    type="radio"
                    id="customRadio1"
                    value="MALE"
                    checked={inputs?.gender === "MALE" ? "checked" : ""}
                    name="gender"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" for="customRadio1">
                    Male
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                    //onChange={handleChange}
                    type="radio"
                    id="customRadio2"
                    value="FEMALE"
                    checked={inputs?.gender === "FEMALE" ? "checked" : ""}
                    name="gender"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" for="customRadio2">
                    Female
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                    //onChange={handleChange}
                    type="radio"
                    id="customRadio3"
                    value="NOT_DISCLOSED"
                    checked={inputs?.gender === "NOT_DISCLOSED" ? "checked" : ""}
                    name="gender"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" for="customRadio3">
                    Other
                  </label>
                </div>
                {errors.gender && errors.gender.type === "required" && (
                  <div className="invalid-feedback-custom">
                    Gender is required
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb-4">
                <label>
                  <b>About</b>
                </label>{" "}
                (<span className="required-span">*</span>)
                <textarea
                  {...register('about',{
                    onChange:(e)=>handleChange(e),
                    required: true,
                  })}
                  // value={inputs.email}
                  defaultValue={inputs?.about}
                  //disabled={params.uid ? true : params.id ? true : false}
                  name="about"
                  type="about"
                  //onChange={handleChange}
                  className={`form-control ${errors.about && "is-invalid"}`}
                  placeholder="Enter Detail"
                />
                {errors.about?.type === "required" && (
                  <div className="invalid-feedback-custom">
                    Tenant Detail
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row addtheripist" style={{ marginTop: -40 }}>
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-12 d-flex">
            {params.id ? (
              <>
                <button
                  className="btn  btn-secondary d-block stepper-button-next"
                  id="save-and-back"
                  style={{ background: "#387af6" }}
                  type="button"
                  onClick={() => history("/settings")}
                >
                  Back
                </button>{" "}
                <button
                  className="btn  btn-primary d-block stepper-button-next"
                  id="save-and-back"
                  // onClick={(e) => onSubmitSaveAndBack(e)}
                >
                  Save
                </button>
              </>
            ) : (
              <button
                className="btn  btn-primary d-block stepper-button-next"
                type="submit"
                ref={saveNextRef}
              >
                Submit
              </button>
            )}

            {loadings ? <div className="loader-small"></div> : ""}
          </div>
        </div>
      </form>
    </>
  );
}

export default TenantForm;
