import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { numRegex } from "../../components/Utils/Regex";
export default function OverDueForm(props) {
    const {
        register,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm();
    const [loader, setLoader] = useState(false);
    const [paymentOverDue, setPaymentOverDue] = useState({ lateFees: '', paymentOverDueDays: '' })
    const overDueSubmit = (i) => {
        setLoader(true);
        //console.log("invoice number", i);
        adminService.PUT(`payments/apply-late-fees/${props.invoiceId}`, JSON.stringify(paymentOverDue))
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("sdf customerResponse", res.data);
                    setPaymentOverDue({ lateFees: '', paymentOverDueDays: '' })
                    props.setShow(false);
                    setLoader(false);
                    window.location.reload();
                    toast.success("Late fee adedd successfully");
                } else {
                    toast.error(res.message);
                    setLoader(false);
                    props.setShow(false);
                }

            })
    }
    const duePaymentHandle = (e) => {
        var inputAmount = document.getElementById("pasAmount");
        inputAmount.addEventListener("mousewheel",
            function (event) {
                event.target.blur()
            });
        const { name, value } = e.target;
        console.log("name", name);
        setPaymentOverDue((paymentOverDue) => ({ ...paymentOverDue, [name]: parseInt(value) }))
    }
    return (
        <>
            <form onSubmit={handleSubmit(overDueSubmit)}>
                <lable className="mb-2">Late Fees Amount ( <span class="required-span">*</span> )</lable>
                <input id="pasAmount" {...register('lateFees', { onChange: (e) => duePaymentHandle(e), required: true, pattern: numRegex })} className="form-control mb-3" type="number" name="lateFees" defaultValue={paymentOverDue?.lateFees} />
                {errors.lateFees && (
                    <p className="fieldError mb-0">This field is required.</p>
                )}
                <button className="btn button-withicon-green" type="submit">Submit</button>
                {loader ? <div className="loader"></div> : ""}
            </form>
        </>
    )
}
