import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { daysJson } from "../../config/Config";
import { getUTCTimeToLocalByDateWithMeridium24 } from "../../utils/commonFunctions";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";
import { toast } from "react-toastify";
import AddLanguage from "./AddLanguage";
import Thumbnail from "../../components/Thumbnail";
import BasicUserdetail from "../../components/BasicUserdetail";

function InterpreterDetail(props) {
  let params = useParams();
  const [interpreterdetails, setInterpreterDetails] = useState([]);
  const [loading, setloading] = useState(false);
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [isdelete, setIsDelete] = useState(false);
  const [editlanguagedetail, setEditLanguageDetail] = useState({
    info: "",
  });
  let profile = useSelector((state) => state.adminUser.profile);

  const addFun = () => {
    adminService.getAll(`interpreters/` + params.id).then(
      (res) => {
        setInterpreterDetails(res.data);
        setModalOpenClose(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const deleteLanguage = (rowid, id, name, hourlyRate) => {
    if (interpreterdetails?.interpreterLanguages.length === 1) {
      toast.warning("Can't delete the language");
      return;
    }
    let data = {
      hourlyRate: hourlyRate,
      languageId: id,
      interpreterLanguageId: rowid,
      languageName: name,
    };
    setEditLanguageDetail((editlanguagedetail) => ({
      ...editlanguagedetail,
      ["info"]: data,
    }));
    setIsDelete(true);
    setModalOpenClose(true);
  };

  const editLanguage = (rowid, id, hourlyRate) => {
    let data = {
      hourlyRate: hourlyRate,
      languageId: id,
      interpreterLanguageId: rowid,
    };
    setEditLanguageDetail((editlanguagedetail) => ({
      ...editlanguagedetail,
      ["info"]: data,
    }));
    setIsDelete(false);
    setModalOpenClose(true);
  };

  const addLanguage = () => {
    setEditLanguageDetail((editlanguagedetail) => ({
      ...editlanguagedetail,
      ["info"]: {},
    }));
    setModalOpenClose(true);
    setIsDelete(false);
  };

  useEffect(() => {
    setloading(true);
    adminService.getById(`interpreters/` + params.id).then(
      (res) => {
        setInterpreterDetails(res.data);
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [params.id]);

  useEffect(() => {
    setEditLanguageDetail(editlanguagedetail);
  }, [editlanguagedetail]);
  const basicInfo = {
    image: interpreterdetails?.userProfile?.profilePicUrl,
    firstName: interpreterdetails?.userProfile?.firstName,
    lastName: interpreterdetails?.userProfile?.lastName,
    status:
      interpreterdetails?.userProfile?.active === true ? "Active" : "InActive",
    appointments: interpreterdetails?.appointments,
    gender: interpreterdetails?.userProfile?.gender,
    phone: interpreterdetails?.userProfile?.phone,
    email: interpreterdetails?.userProfile?.email,
    editLink: "/edit-interpreter-details/" + interpreterdetails?.id,
    about: interpreterdetails?.userProfile?.about,
    userType: interpreterdetails?.userProfile?.userType,
    currentUserType: profile?.userType,
  };
  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Interpreter Details</div>
            </div>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <>
                <BasicUserdetail {...basicInfo} />
                <hr />
                <div className="row mt-5">
                  <div className="col-md-9">
                    <h5 className="name">Languages Details</h5>
                  </div>

                  <div className="col-md-3 d-flex justify-content-end">
                    <button
                      type="button"
                      style={{ width: 180 }}
                      onClick={addLanguage}
                      className="btn btn-primary custom-btn-green mb-2"
                    >
                      <img
                        className="pr-2"
                        src="../resources/assets/plus-icon-button.svg"
                        alt=""
                      />
                      Add Language
                    </button>
                    {modalOpenClose && (
                      <AddLanguage
                        isdelete={isdelete}
                        addFun={() => addFun()}
                        closeModal={() => setModalOpenClose(false)}
                        serviceinfo={editlanguagedetail}
                        servicedetail={
                          interpreterdetails?.interpreterLanguages &&
                          interpreterdetails?.interpreterLanguages
                        }
                      />
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="">
                      <table className="table table-hover mt-3">
                        <thead>
                          <th>S No.</th>
                          <th>Language Name</th>
                          <th>Rate</th>
                          <th>Actions</th>
                        </thead>
                        <tbody>
                          {interpreterdetails?.interpreterLanguages &&
                            interpreterdetails?.interpreterLanguages.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.language.languageName}</td>
                                  <td>
                                    ${parseFloat(item.hourlyRate).toFixed(2)}/Hr
                                  </td>
                                  <td>
                                    {" "}
                                    <div className="col-auto">
                                      <Link>
                                        <img
                                          className="pr-4"
                                          src="../resources/assets/action-icon-edit.svg"
                                          onClick={() =>
                                            editLanguage(
                                              item.id,
                                              item.language.id,
                                              item.hourlyRate
                                            )
                                          }
                                        />
                                      </Link>

                                      <Link>
                                        <img
                                          src="../resources/assets/action-icon-delete.svg"
                                          onClick={() =>
                                            deleteLanguage(
                                              item.id,
                                              item.language.id,
                                              item.language.languageName,
                                              item.hourlyRate
                                            )
                                          }
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="row mt-5">
                  <div className="col-md-8">
                    <h5 className="name">Working Days and Time</h5>

                    {/* <p className="mt-4">
                                            {daysJson && daysJson.map((item, index) =>
                                                interpreterdetails?.interpreterAvailability && interpreterdetails?.interpreterAvailability[item.day] ? item.name + ' ' : ""
                                            )}
                                        </p> */}
                  </div>
                  <div className="col-md-3 ml-5 text-right">
                    <Link
                      to={`/edit-interpreter-availability/${interpreterdetails?.id}`}
                    >
                      <img
                        src="../resources/assets/Icon feather-edit-3.svg"
                        alt=""
                      />
                    </Link>
                  </div>

                  <div className="col-md-12">
                    <table className="table table-hover mt-4">
                      <thead>
                        <th>Day</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Timezone</th>
                      </thead>
                      <tbody>
                        {interpreterdetails?.interpreterAvailability &&
                          interpreterdetails?.interpreterAvailability.map(
                            (item, index) => (
                              <tr>
                                <td>{item.availableDay}</td>
                                <td>
                                  {getUTCTimeToLocalByDateWithMeridium24(
                                    item.startTime
                                  )}
                                </td>
                                <td>
                                  {getUTCTimeToLocalByDateWithMeridium24(
                                    item.endTime
                                  )}
                                </td>
                                <td>{`${interpreterdetails?.timezone.code}(${(
                                  interpreterdetails?.timezone.timezone || ""
                                ).replaceAll("_", " ")})`}</td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="row ml-3 mt-5">
                                    <div className="col-md-10">
                                        <h5 className="name">Working Time</h5>
                                        <p className="mt-4">Start Time : {getUTCTimeToLocalByDateWithMeridium24(interpreterdetails?.interpreterAvailability && interpreterdetails?.interpreterAvailability.startTime)}
                                   &nbsp; &nbsp; &nbsp; &nbsp;  End Time : {getUTCTimeToLocalByDateWithMeridium24(interpreterdetails?.interpreterAvailability && interpreterdetails?.interpreterAvailability.endTime)} </p>
                                    </div>
                                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InterpreterDetail;
