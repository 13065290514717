import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch, connectAdvanced } from "react-redux";
import { adminService } from "../../../services/adminService";
import allActions from "../../../redux/actions";
import {
  daysJson,
  daySorter,
  getTimeFormatPropsInputComp,
} from "../../../config/Config";
import { toast } from "react-toastify";
import { getTimeInUTC } from "../../../utils/commonFunctions";
import { getTime24, getMeridium24 } from "../../../utils/commonFunctions";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import appAction from "../../../redux/actions/appAction";
import TimeInput from "../../../components/TimeInput";

function InterpreterSetAvailability(props) {
  let today_date = new Date();
  let params = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  let [timezones, setTimezones] = useState([]);
  let interpreterForm = useSelector((state) => state.adminUser.interpreterForm);
  let user = useSelector((state) => state.adminUser.user);
  let jsonFound = interpreterForm.hasOwnProperty("interpreterAvailabilityForm");
  const [availabilityJson, setAvailabilityJson] = useState([]);
  let currentRole = user.userDetails.roles[0];
  console.log("interpreterAvailabilityForm", interpreterForm)
  //   useEffect(() => {
  //     if (params.interpreterId) {
  //       //setloading(true);
  //       adminService.getById(`interpreters/` + params.interpreterId).then(
  //         (res) => {
  //           if (res.status === "Success") {
  //             //setLanguageIds(res.data.interpreterLanguages);
  //             console.log("interpreter lang",res.data.interpreterLanguages)
  //             dispatch(
  //               allActions.adminAction.setInterpreterFormData(
  //                 res.data.userProfile,
  //                 "userProfileForm"
  //               )
  //             );
  //             dispatch(allActions.adminAction.setInterpreterFormData(res.data.interpreterLanguages, 'interpreterLanguageForms'))
  //             //let phone = res.data.userProfile && res.data.userProfile.phone;
  //             //res.data.userProfile.phone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;

  //             //setloading(false);
  //           } else {
  //             toast.error(res.message);
  //           }
  //         },
  //         (error) => {
  //           console.log(error);
  //         }
  //       );
  //     }
  // },[]);
  const [inputs, setInputs] = useState({
    sun: jsonFound ? interpreterForm.interpreterAvailabilityForm.sun : false,
    mon: jsonFound ? interpreterForm.interpreterAvailabilityForm.mon : false,
    tue: jsonFound ? interpreterForm.interpreterAvailabilityForm.tue : false,
    wed: jsonFound ? interpreterForm.interpreterAvailabilityForm.wed : false,
    thu: jsonFound ? interpreterForm.interpreterAvailabilityForm.thu : false,
    fri: jsonFound ? interpreterForm.interpreterAvailabilityForm.fri : false,
    sat: jsonFound ? interpreterForm.interpreterAvailabilityForm.sat : false,
    endTime: jsonFound
      ? interpreterForm.interpreterAvailabilityForm.endTime
      : "",
    startTime: jsonFound
      ? interpreterForm.interpreterAvailabilityForm.startTime
      : "",
    selectedStartTime: jsonFound
      ? interpreterForm.interpreterAvailabilityForm
      : "00:00",
    selectedEndTime: jsonFound
      ? interpreterForm.interpreterAvailabilityForm
      : "00:00",
    interpreterAvailabilityId: 0,
    timezoneId: 1, //for EST default
    pendingOnboarding: false,
  });

  const [timeInputs, setTimeInputs] = useState({
    startTime: moment(today_date).format("hh:mm"),
    endTime: moment(today_date).format("hh:mm"),
    startMeridium: "am",
    endMeridium: "pm",
  });

  function getTimeForSetTimeUTC(time, meridium) {
    let time24 = moment(time + " " + meridium, ["h:mm A"]).format("HH:mm:ss");
    return getTimeInUTC(time24);
  }

  const onSubmit = () => {
    if (
      !inputs.sun &&
      !inputs.mon &&
      !inputs.tue &&
      !inputs.wed &&
      !inputs.thu &&
      !inputs.fri &&
      !inputs.sat
    ) {
      toast.warn("Please select working days");
      return;
    }

    if (inputs.selectedStartTime >= inputs.selectedEndTime) {
      toast.warn("Start Time can not be less from End Time");
      return;
    }

    if (!inputs.timezoneId) {
      toast.warn("Please select timezone");
      return;
    }

    // for edit availability call when submit
    if (params.id) {
      let availabilityJsonOBJ = [];
      availabilityJson.map((item, index) => {
        let startTime24 = moment(item.startTime + " " + item.startMeridium, [
          "h:mm A",
        ]).format("HH:mm:ss");
        let endTime24 = moment(item.endTime + " " + item.endMeridium, [
          "h:mm A",
        ]).format("HH:mm:ss");

        availabilityJsonOBJ.push({
          interpreterAvailabilityId: item.interpreterAvailabilityId,
          availableDay: item.availableDay,
          startTime: item.differentTime
            ? startTime24
            : inputs.selectedStartTime,
          endTime: item.differentTime ? endTime24 : inputs.selectedEndTime,
          differentTime: item.differentTime,
        });
      });

      let formDataPut = {
        interpreterAvailabilityUpdateForm: availabilityJsonOBJ,
        timezoneId: inputs.timezoneId,
      };
      let formData = new FormData();
      formData.append(
        "interpreterUpdateForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );

      dispatch(appAction.setAppLoading(true));
      adminService
        .update(`/interpreters/` + params.id, formData)
        .then(
          (res) => {
            if (res.status == "Success") {
              toast.success(res.message);
              setTimeout(() => {
                history("/interpreter-details/" + params.id);
              }, 2000);
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
      return;
    } // edit form end

    // Set time loop
    let availabilityJsonOBJ = [];
    availabilityJson.map((item, index) => {
      let startTime24 = moment(item.startTime + " " + item.startMeridium, [
        "h:mm A",
      ]).format("HH:mm:ss");
      let endTime24 = moment(item.endTime + " " + item.endMeridium, [
        "h:mm A",
      ]).format("HH:mm:ss");

      availabilityJsonOBJ.push({
        availableDay: item.availableDay,
        // startTime: item.differentTime ? getTimeForSetTimeUTC(item.startTime, item.startMeridium) : inputs.startTime,
        // endTime: item.differentTime ? getTimeForSetTimeUTC(item.endTime, item.endMeridium) : inputs.endTime,
        startTime: item.differentTime ? startTime24 : inputs.selectedStartTime,
        endTime: item.differentTime ? endTime24 : inputs.selectedEndTime,
        differentTime: item.differentTime,
      });
    });

    dispatch(
      allActions.adminAction.setInterpreterFormData(
        availabilityJsonOBJ,
        "interpreterAvailabilityForm"
      )
    );

    let formData = new FormData();
    interpreterForm.timezoneId = inputs.timezoneId;
    interpreterForm.userProfileForm.phone = interpreterForm?.userProfileForm?.phone?.replace(/[^\d]/g, "");
    formData.append(
      "profileImage",
      interpreterForm.userProfileForm.profileImage
    );
    delete interpreterForm.userProfileForm.profileImage;
    let basicInfo = {
      firstName: interpreterForm?.userProfileForm?.firstName,
      lastName: interpreterForm?.userProfileForm?.lastName,
      email: interpreterForm?.userProfileForm?.email,
      phone: interpreterForm?.userProfileForm?.phone,
      about: interpreterForm?.userProfileForm?.about,
      gender: interpreterForm?.userProfileForm?.gender,
      active: interpreterForm?.userProfileForm?.active,
    }
    if (props?.therapistData) {
      let formDataPut = {
        userProfileUpdateForm: basicInfo,
        interpreterLanguageForms: interpreterForm?.interpreterLanguageForms,
        interpreterAvailabilityUpdateForm: interpreterForm?.interpreterAvailabilityForm,
        timezoneId: inputs.timezoneId,
        pendingOnboarding: false
      };
      formData.append(
        "interpreterUpdateForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );
      adminService
        .update(`/interpreters/${props?.therapistData.id}`, formData)
        .then(
          (res) => {
            dispatch(appAction.setAppLoading(false));
            console.log("object", res);
            if (res?.data?.id) {
              history("/interpreter");
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    } else {
      let formDataPut = {
        userProfileForm: basicInfo,
        interpreterLanguageForms: interpreterForm?.interpreterLanguageForms,
        interpreterAvailabilityForm: interpreterForm?.interpreterAvailabilityForm,
        timezoneId: inputs.timezoneId,
        pendingOnboarding: false
      }
      formData.append(
        "interpreterForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );

      dispatch(appAction.setAppLoading(true));
      adminService
        .onboardUser(`interpreters`, formData)
        .then(
          (res) => {
            if (res.status == "Success") {
              dispatch(allActions.adminAction.clearOnboardData());
              toast.success(res.message);
              setTimeout(() => {
                history("/interpreter");
              }, 2000);
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log("Error >>>>>>>", error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    }
  };

  function handleChangeTimezone(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value ? parseInt(value) : "",
    }));
  }
  function handleChange(e) {
    const { name, value, checked } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: checked }));
    // add aval to json data
    if (checked) {
      let avaljson = {
        availableDay: value,
        startTime: moment(today_date).format("hh:mm"),
        endTime: moment(today_date).format("hh:mm"),
        startMeridium: "am",
        endMeridium: "pm",
        differentTime: false,
      };
      setAvailabilityJson([...availabilityJson, avaljson]);
    } else {
      setAvailabilityJson(
        availabilityJson.filter((item) => item.availableDay !== value)
      );
    }
    // add ends
  }

  function handleChangeSetTime(e) {
    const { name, value, checked } = e.target;

    let fileindex = availabilityJson.findIndex(
      (obj) => obj.availableDay === value
    );
    let tempJson = availabilityJson;
    tempJson[fileindex].differentTime = checked;
    setAvailabilityJson(tempJson);
    setAvailabilityJson([...availabilityJson, { availableDay: "" }]); // for render jsx
    setAvailabilityJson(
      availabilityJson.filter((item) => item.availableDay !== "")
    ); // for remove blank object
  }

  // for Set Time

  const handleChangeDiffTime = (e, name, availableDay) => {
    let fileindex = availabilityJson.findIndex(
      (obj) => obj.availableDay == availableDay
    );
    let avaljson = {
      availableDay: availableDay,
      startTime:
        name == "startTime"
          ? moment(e._d).format("hh:mm")
          : availabilityJson[fileindex].startTime,
      endTime:
        name == "endTime"
          ? moment(e._d).format("hh:mm")
          : availabilityJson[fileindex].endTime,
      startMeridium: availabilityJson[fileindex].startMeridium,
      endMeridium: availabilityJson[fileindex].endMeridium,
      differentTime: true,
      interpreterAvailabilityId: params.id
        ? availabilityJson[fileindex].interpreterAvailabilityId
        : "",
    };
    let tempJson = availabilityJson;
    tempJson[fileindex] = avaljson;
    setAvailabilityJson(tempJson);
    setAvailabilityJson([...availabilityJson, { availableDay: "" }]); // for render jsx
    setAvailabilityJson(
      availabilityJson.filter((item) => item.availableDay !== "")
    ); // for remove blank object
  };

  const handleChangeDiffMeridium = (name, value, availableDay) => {
    let fileindex = availabilityJson.findIndex(
      (obj) => obj.availableDay == availableDay
    );
    let avaljson = {
      availableDay: availableDay,
      startTime: availabilityJson[fileindex].startTime,
      endTime: availabilityJson[fileindex].endTime,
      startMeridium:
        name === "startMeridium"
          ? value
          : availabilityJson[fileindex].startMeridium,
      endMeridium:
        name === "endMeridium"
          ? value
          : availabilityJson[fileindex].endMeridium,
      differentTime: true,
      interpreterAvailabilityId: params.id
        ? availabilityJson[fileindex].interpreterAvailabilityId
        : "",
    };
    let tempJson = availabilityJson;
    tempJson[fileindex] = avaljson;
    setAvailabilityJson(tempJson);
    setAvailabilityJson([...availabilityJson, { availableDay: "" }]); // for render jsx
    setAvailabilityJson(
      availabilityJson.filter((item) => item.availableDay !== "")
    ); // for remove blank object
  };

  const renderInput = (props, openCalendar) => {
    return (
      <div className="input-group">
        <img src="../resources/assets/clock.svg" onClick={openCalendar} />
        <input {...props} />
      </div>
    );
  };

  const handleChangeTime = (e, name) => {
    setTimeInputs((timeInputs) => ({
      ...timeInputs,
      [name]: moment(e._d).format("hh:mm"),
    })); // HH:mm user for validation purpose
  };

  const handleChangeMeridium = (name, value) => {
    setTimeInputs((timeInputs) => ({ ...timeInputs, [name]: value }));
  };

  useEffect(() => {
    if (params.id) {
      setloading(true);
      adminService.getById(`interpreters/` + params.id).then(
        (res) => {
          if (res.status == "Success") {
            let interpreterAvailability = res.data.interpreterAvailability;
            //setInputs(interpreterAvailability);

            let input_data = {
              sun:
                interpreterAvailability.filter(
                  (info) => info.availableDay === "SUNDAY"
                ).length > 0
                  ? true
                  : false,
              mon:
                interpreterAvailability.filter(
                  (info) => info.availableDay === "MONDAY"
                ).length > 0
                  ? true
                  : false,
              tue:
                interpreterAvailability.filter(
                  (info) => info.availableDay === "TUESDAY"
                ).length > 0
                  ? true
                  : false,
              wed:
                interpreterAvailability.filter(
                  (info) => info.availableDay === "WEDNESDAY"
                ).length > 0
                  ? true
                  : false,
              thu:
                interpreterAvailability.filter(
                  (info) => info.availableDay === "THURSDAY"
                ).length > 0
                  ? true
                  : false,
              fri:
                interpreterAvailability.filter(
                  (info) => info.availableDay === "FRIDAY"
                ).length > 0
                  ? true
                  : false,
              sat:
                interpreterAvailability.filter(
                  (info) => info.availableDay === "SATURDAY"
                ).length > 0
                  ? true
                  : false,
              timezoneId: res.data.timezone.id,
            };
            setInputs(input_data);

            let availabilityJsonOBJ = [];
            interpreterAvailability.map((item, index) => {
              availabilityJsonOBJ.push({
                interpreterAvailabilityId: item.id,
                availableDay: item.availableDay,
                startTime: getTime24(item.startTime),
                endTime: getTime24(item.endTime),
                differentTime: item.differentTime,
                startMeridium: getMeridium24(item.startTime),
                endMeridium: getMeridium24(item.endTime),
              });
            });
            setAvailabilityJson(availabilityJsonOBJ);

            let all_day_json = interpreterAvailability.filter(
              (item) => item.differentTime == false
            );
            if (all_day_json.length > 0) {
              setTimeInputs((timeInputs) => ({
                ...timeInputs,
                startTime: getTime24(all_day_json[0].startTime),
                endTime: getTime24(all_day_json[0].endTime),
                startMeridium: getMeridium24(all_day_json[0].startTime),
                endMeridium: getMeridium24(all_day_json[0].endTime),
              }));
            } else {
              // When We have not normal time all is set time selected when edit
              setTimeInputs((timeInputs) => ({
                ...timeInputs,
                startTime: timeInputs.startTime,
                endTime: timeInputs.endTime,
                startMeridium: "am",
                endMeridium: "pm",
              }));
            }

            // setTimeInputs(timeInputs => ({
            //     ...timeInputs,
            //     startTime: getTime24(interpreterAvailability.startTime),
            //     endTime: getTime24(interpreterAvailability.endTime),
            //     startMeridium: getMeridium24(interpreterAvailability.startTime),
            //     endMeridium: getMeridium24(interpreterAvailability.endTime)
            // }))
            //setInputs(inputs => ({ ...inputs, ["interpreterAvailabilityId"]: interpreterAvailability.id }));
            setloading(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }

    let apiurl_timezone =
      currentRole === "ROLE_RCE" ||
        currentRole === "ROLE_THERAPIST" ||
        currentRole === "ROLE_REGIONAL_EARLY_STEPS"
        ? "misc/timezones"
        : "misc/timezones";
    adminService.getAll(apiurl_timezone).then(
      (res) => {
        if (res.status === "Success") {
          setTimezones(res.data);
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  }, []);

  useEffect(() => {
    setInputs(inputs);
    dispatch(
      allActions.adminAction.setInterpreterFormData(
        inputs,
        "interpreterAvailabilityForm"
      )
    );
  }, [inputs]);

  useEffect(() => {
    setAvailabilityJson(availabilityJson);
    dispatch(
      allActions.adminAction.setInterpreterFormData(
        availabilityJson,
        "interpreterAvailabilityForm"
      )
    );
  }, [availabilityJson]);

  useEffect(() => {
    setTimeInputs(timeInputs);
    let startTime24 = moment(
      timeInputs.startTime + " " + timeInputs.startMeridium,
      ["h:mm A"]
    ).format("HH:mm:ss");
    let endTime24 = moment(timeInputs.endTime + " " + timeInputs.endMeridium, [
      "h:mm A",
    ]).format("HH:mm:ss");
    setInputs((inputs) => ({ ...inputs, ["selectedStartTime"]: startTime24 }));
    setInputs((inputs) => ({ ...inputs, ["selectedEndTime"]: endTime24 }));
    setInputs((inputs) => ({
      ...inputs,
      ["startTime"]: getTimeInUTC(startTime24),
    }));
    setInputs((inputs) => ({
      ...inputs,
      ["endTime"]: getTimeInUTC(endTime24),
    }));
  }, [timeInputs]);

  // For sorting the day
  availabilityJson.sort(function sortByDay(a, b) {
    let day1 = a.availableDay.toLowerCase();
    let day2 = b.availableDay.toLowerCase();
    return daySorter[day1] - daySorter[day2];
  });
  console.log("interpreterAvailabilityForm",availabilityJson);
  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div className="addtheripist">
            <label className="conetent-title">
              {params.id ? "Edit" : "Set"} Availability
            </label>
            <div className="row gutter-33">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label className="mb-3">Working Days</label> (
                  <span className="required-span">*</span>)
                  <br />
                  <label>Click to select working day(s)</label>
                  <div className="working-days">
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.mon ? "checked" : ""}
                          id="checkbox"
                          value="MONDAY"
                          name="mon"
                          onChange={handleChange}
                        />
                        <div className="days"> Mon </div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "MONDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-1"
                          type="checkbox"
                          value="MONDAY"
                          disabled={!inputs.mon && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-1">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.tue ? "checked" : ""}
                          id="checkbox"
                          value="TUESDAY"
                          name="tue"
                          onChange={handleChange}
                        />
                        <div className="days"> Tue</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "TUESDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-2"
                          type="checkbox"
                          value="TUESDAY"
                          disabled={!inputs.tue && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-2">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.wed ? "checked" : ""}
                          id="checkbox"
                          value="WEDNESDAY"
                          name="wed"
                          onChange={handleChange}
                        />
                        <div className="days"> Wed</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "WEDNESDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-3"
                          type="checkbox"
                          value="WEDNESDAY"
                          disabled={!inputs.wed && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-3">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.thu ? "checked" : ""}
                          id="checkbox"
                          value="THURSDAY"
                          name="thu"
                          onChange={handleChange}
                        />
                        <div className="days"> Thu</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "THURSDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-4"
                          type="checkbox"
                          value="THURSDAY"
                          disabled={!inputs.thu && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-4">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.fri ? "checked" : ""}
                          id="checkbox"
                          value="FRIDAY"
                          name="fri"
                          onChange={handleChange}
                        />
                        <div className="days"> Fri</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "FRIDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-5"
                          type="checkbox"
                          value="FRIDAY"
                          disabled={!inputs.fri && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-5">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.sat ? "checked" : ""}
                          id="checkbox"
                          value="SATURDAY"
                          name="sat"
                          onChange={handleChange}
                        />
                        <div className="days"> Sat</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "SATURDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-6"
                          type="checkbox"
                          value="SATURDAY"
                          disabled={!inputs.sat && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-6">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.sun ? "checked" : ""}
                          id="checkbox"
                          value="SUNDAY"
                          name="sun"
                          onChange={handleChange}
                        />
                        <div className="days"> Sun</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "SUNDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-7"
                          type="checkbox"
                          value="SUNDAY"
                          disabled={!inputs.sun && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-7">Set Time</label>
                      </div>
                    </div>
                  </div>
                  <div className="text-info-div mt-1 mb-2">
                    <img
                      src="../resources/assets/material-info-outline.svg"
                      alt="Info icon for Precautions"
                    />
                    <span className="text-info-tips">
                      &nbsp;Check to set different time for selected day(s)
                    </span>
                  </div>
                  <span className="selectd-date-text">
                    (You have selected: &nbsp;
                    {daysJson &&
                      daysJson.map((item, index) =>
                        inputs[item.day] ? item.name + " " : ""
                      )}
                  </span>
                  )
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="mb-3">Working Time</label> (
                  <span className="required-span">*</span>)
                  <div className="row gutter-33">
                    <div className="col-md-3">
                      <h6 className="mb-3">Selected Day</h6>
                    </div>
                    <div className="col-md-4">
                      <h6 className="">Start time</h6>
                    </div>

                    <div className="col-md-4">
                      <h6 className="">End time</h6>
                    </div>
                  </div>
                  {availabilityJson &&
                    availabilityJson.map(
                      (item, index) =>
                        item.availableDay !== "" &&
                        item.differentTime === true && (
                          <>
                            <div className="row gutter-33">
                              <div className="col-md-3">
                                <strong>
                                  <span>{item.availableDay}</span>
                                </strong>
                              </div>
                              <div className="col-md-4">
                                <div className="working-time d-flex">
                                  <TimeInput
                                    onChange={(e) =>
                                      handleChangeDiffTime(
                                        e,
                                        "startTime",
                                        item.availableDay
                                      )
                                    }
                                    initialValue={item.startTime}
                                    value={item.startTime}
                                  />
                                  {/* <Datetime
                                                            inputProps={getTimeFormatPropsInputComp}
                                                            className="form-control-time"
                                                            renderInput={renderInput}
                                                            initialViewMode="time"
                                                            updateOnView="time"
                                                            timeFormat="hh:mm"
                                                            timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                            value={item.startTime}
                                                            dateFormat={false}
                                                            onChange={(e) => handleChangeDiffTime(e, "startTime", item.availableDay)}
                                                        /> */}

                                  <div
                                    className="btn-group btn-group-toggle set-time-section"
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={`btn ${item.startMeridium === "am" && "active"
                                        }`}
                                      onClick={() =>
                                        handleChangeDiffMeridium(
                                          "startMeridium",
                                          "am",
                                          item.availableDay
                                        )
                                      }
                                    >
                                      <input
                                        type="radio"
                                        value="am"
                                        name="startMeridium"
                                        autocomplete="off"
                                      />{" "}
                                      AM
                                    </label>

                                    <label
                                      className={`btn ${item.startMeridium === "pm" && "active"
                                        }`}
                                      onClick={() =>
                                        handleChangeDiffMeridium(
                                          "startMeridium",
                                          "pm",
                                          item.availableDay
                                        )
                                      }
                                    >
                                      <input
                                        type="radio"
                                        value="pm"
                                        name="startMeridium"
                                        autocomplete="off"
                                      />{" "}
                                      PM
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="working-time d-flex">
                                  <div className="input-group">
                                    <TimeInput
                                      onChange={(e) =>
                                        handleChangeDiffTime(
                                          e,
                                          "endTime",
                                          item.availableDay
                                        )
                                      }
                                      initialValue={item.endTime}
                                      value={item.endTime}
                                    />
                                    {/* <Datetime
                                                                inputProps={getTimeFormatPropsInputComp}
                                                                className="form-control-time"
                                                                renderInput={renderInput}
                                                                initialViewMode="time"
                                                                updateOnView="time"
                                                                timeFormat="hh:mm"
                                                                timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                                value={item.endTime}
                                                                dateFormat={false}
                                                                // inputProps={{name:"endTime"}}
                                                                onChange={(e) => handleChangeDiffTime(e, "endTime", item.availableDay)}
                                                            /> */}
                                  </div>
                                  <div
                                    className="btn-group btn-group-toggle set-time-section"
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={`btn ${item.endMeridium === "am" && "active"
                                        }`}
                                      onClick={() =>
                                        handleChangeDiffMeridium(
                                          "endMeridium",
                                          "am",
                                          item.availableDay
                                        )
                                      }
                                    >
                                      <input
                                        type="radio"
                                        value="am"
                                        name="endMeridium"
                                        autocomplete="off"
                                      />{" "}
                                      AM
                                    </label>

                                    <label
                                      className={`btn ${item.endMeridium === "pm" && "active"
                                        }`}
                                      onClick={() =>
                                        handleChangeDiffMeridium(
                                          "endMeridium",
                                          "pm",
                                          item.availableDay
                                        )
                                      }
                                    >
                                      <input
                                        type="radio"
                                        value="pm"
                                        name="endMeridium"
                                        autocomplete="off"
                                      />{" "}
                                      PM
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-md-10 border-top mt-4 mb-4"
                                key={index}
                              ></div>
                            </div>
                          </>
                        )
                    )}
                  <div className="row gutter-33">
                    <div className="col-md-3">
                      {availabilityJson.filter(
                        (item) => item.differentTime === false
                      ).length > 0 && (
                          <strong>
                            <span>
                              {availabilityJson.filter(
                                (item) => item.differentTime === true
                              ).length == 0
                                ? "All"
                                : "Other"}{" "}
                              Selected Day(s)
                            </span>
                          </strong>
                        )}
                    </div>
                    <div className="col-md-4">
                      {availabilityJson.filter(
                        (item) => item.differentTime === false
                      ).length > 0 && (
                          <div className="working-time d-flex">
                            <TimeInput
                              onChange={(e) => handleChangeTime(e, "startTime")}
                              initialValue={timeInputs.startTime}
                              value={timeInputs.startTime}
                            />
                            {/* <Datetime
                                                inputProps={getTimeFormatPropsInputComp}
                                                className="form-control-time"
                                                renderInput={renderInput}
                                                initialViewMode="time"
                                                updateOnView="time"
                                                timeFormat="hh:mm"
                                                timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                value={timeInputs.startTime}
                                                dateFormat={false}
                                                onChange={(e) => handleChangeTime(e, "startTime")}
                                            /> */}

                            <div
                              className="btn-group btn-group-toggle set-time-section"
                              data-toggle="buttons"
                            >
                              <label
                                className={`btn ${timeInputs.startMeridium === "am" && "active"
                                  }`}
                                onClick={() =>
                                  handleChangeMeridium("startMeridium", "am")
                                }
                              >
                                <input
                                  type="radio"
                                  value="am"
                                  name="startMeridium"
                                  autocomplete="off"
                                />{" "}
                                AM
                              </label>

                              <label
                                className={`btn ${timeInputs.startMeridium === "pm" && "active"
                                  }`}
                                onClick={() =>
                                  handleChangeMeridium("startMeridium", "pm")
                                }
                              >
                                <input
                                  type="radio"
                                  value="pm"
                                  name="startMeridium"
                                  autocomplete="off"
                                />{" "}
                                PM
                              </label>
                            </div>
                          </div>
                        )}
                    </div>

                    <div className="col-md-4">
                      {availabilityJson.filter(
                        (item) => item.differentTime === false
                      ).length > 0 && (
                          <div className="working-time d-flex">
                            <div className="input-group">
                              <TimeInput
                                onChange={(e) => handleChangeTime(e, "endTime")}
                                initialValue={timeInputs.endTime}
                                value={timeInputs.endTime}
                              />
                              {/* <Datetime
                                                    inputProps={getTimeFormatPropsInputComp}
                                                    className="form-control-time"
                                                    renderInput={renderInput}
                                                    initialViewMode="time"
                                                    updateOnView="time"
                                                    timeFormat="hh:mm"
                                                    timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                    value={timeInputs.endTime}
                                                    dateFormat={false}
                                                    // inputProps={{name:"endTime"}}
                                                    onChange={(e) => handleChangeTime(e, "endTime")}
                                                /> */}
                              {/* <input type="time" value={dateInputs.endTime} onChange={handleChange} name="endTime" aria-label="Hours" className="form-control" /> */}
                              {/* <input type="text" aria-label="Minutes" className="form-control" /> */}
                            </div>
                            <div
                              className="btn-group btn-group-toggle set-time-section"
                              data-toggle="buttons"
                            >
                              <label
                                className={`btn ${timeInputs.endMeridium === "am" && "active"
                                  }`}
                                onClick={() =>
                                  handleChangeMeridium("endMeridium", "am")
                                }
                              >
                                <input
                                  type="radio"
                                  value="am"
                                  name="endMeridium"
                                  autocomplete="off"
                                />{" "}
                                AM
                              </label>

                              <label
                                className={`btn ${timeInputs.endMeridium === "pm" && "active"
                                  }`}
                                onClick={() =>
                                  handleChangeMeridium("endMeridium", "pm")
                                }
                              >
                                <input
                                  type="radio"
                                  value="pm"
                                  name="endMeridium"
                                  autocomplete="off"
                                />{" "}
                                PM
                              </label>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="mb-3">Select Timezone</label> (
                  <span className="required-span">*</span>)
                  <div className="col-md-3 m-0 p-0">
                    {" "}
                    <select
                      className={`custom-select`}
                      onChange={handleChangeTimezone}
                      value={inputs.timezoneId}
                      defaultValue={inputs.timezoneId}
                      name="timezoneId"
                    >
                      <option value="">Select Timezone</option>
                      {timezones &&
                        timezones.map((info, index) => (
                          <>
                            <option value={info.id}>{`${info.code} -  ${(
                              info.timezone || ""
                            ).replaceAll("_", " ")}`}</option>
                          </>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row addtheripist">
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12 d-flex">
              {!params.id && (
                <button
                  className="btn  btn-primary d-block stepper-button-back"
                  onClick={(e) => props.handleBack()}
                >
                  Back
                </button>
              )}
              <button
                className="btn  btn-primary d-block stepper-button-next"
                type="submit"
              >
                Finish
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default InterpreterSetAvailability;
