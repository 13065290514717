import React, { useEffect, useState } from 'react';
import CptCodeTable from './CptCodeTable';
import { useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { adminService } from "../../../services/adminService";
import { toast } from "react-toastify";
import Pagination from "react-responsive-pagination";
import TherapyList from "../../../components/TherapyList/TherapyList";
import Search from "../../../components/Admin/Search/";
import usePageState from "../../../hooks/InitialState";

function CptCodeList(props) {
    const { pageState, setPageState } = usePageState();

    let history = useNavigate();
    const [status, setStatus] = useState("");
    const [cptList, setCptList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [therapy, setTherapy] = useState("");
    const [search, setSearch] = useState("");
    //const [confirmDeletion, setConfirmDeletion] = useState(false);

    const onSearch = () => {
        getCptList();
    };

    useEffect(() => {
        getCptList();
    }, [search, page, therapy, status]);

    const getCptList = (id) => {
        setPageState((item) => ({ ...item, loading: true }));
        adminService.getAll(
            `cpts?search=${search}&page=${page}&therapyId=${therapy}&active=${status}&size=10&sort=id,desc`
        )
            .then((res) => {
                setPageState((item) => ({ ...item, loading: false }));
                if (res.status == "Success") {
                    setCptList(res?.data.content);
                    setTotalPages(res?.data?.totalPages);
                } else {
                    toast.error(res?.message || "Something went wrong");
                }
            })
            .catch((err) => {
                toast.error(err);
                setPageState((item) => ({ ...item, loading: false }));
            });
    };
    // const deleteData = (id) => {
    //     adminService.DELETE(`cpts/${id}`)
    //         .then((res) => {
    //             if (res.status == "Success") {
    //                 setConfirmDeletion(false);
    //                 toast.success(res?.message);
    //                 getCptList();
    //             } else {
    //                 toast.error(res?.message || "Something went wrong");
    //             }
    //         })
    //         .catch((err) => toast.error(err));
    // };
    return (
        <div className="col-md-10 no-padding ContentArea theropySession">
            <div className="no-padding dash-billing-info mr-0 flex-grow-1">
                <div className="custom-table-view min-h-100">
                    <div class="content-header">
                        <div class="text-left header-title pt-7px">CPT Code</div>
                        <div class="text-right header-btn">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li><a>
                                    <button class="btn btn-primary custom-btn-green custom-btn-green-auto" onClick={() => history("/add-cpt-code")}>
                                        + CPT Code
                                    </button>
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12 mt-10 p-0">
                        <div className="row">
                            <div className="col-4">
                                <Search
                                    search={search}
                                    setSearch={setSearch}
                                    onSearch={onSearch}
                                    placeholder="Therapy Name/CPT Code"
                                />
                            </div>
                            <div className="col-4">
                                <TherapyList defaultValue="" onChange={setTherapy} />
                            </div>
                            {/* <div className="col-4">
                            <div className="therapyListWrapper" style={{ width: 110 }}>
                                <select
                                    onChange={(e) => setStatus(e.target.value)}
                                    defaultValue={status}
                                    value={status}
                                    name="status"
                                    className="custom-select form-control"
                                >
                                    <option value="">All</option>
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                </select>
                                <div className="input-group-prepend">
                                    <img
                                        className="m-auto"
                                        src="../resources/assets/Polygon 1.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>

                    {pageState.loading ? (
                        <>
                            <div className="p-5 mt-10em">
                                <span className="loader"></span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-md-12 mt-10 p-0">
                                <CptCodeTable list={cptList || []}
                                // onDelete={deleteData}
                                // confirmDeletion={confirmDeletion}
                                // setConfirmDeletion={setConfirmDeletion}
                                />
                            </div>
                            {cptList.length == 0 && (
                                <div className="col-md-12 mt-10">
                                    <p className="text-center">
                                        <b>{search != "" ? "No match found" : "Data Not Found"}</b>
                                    </p>
                                </div>
                            )}
                            <div className="col-md-12 mt-10">
                                <Pagination
                                    total={totalPages}
                                    current={page + 1}
                                    onPageChange={(page) => setPage(page - 1)}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CptCodeList;