import React from "react";
import classNames from "classnames";
import { getFullName, getStatusClassName } from "../utils/commonFunctions";
import { Link } from "react-router-dom";
import StatusBadge from "./StatusBadge";

const SessionCard = ({
  className,
  therapyName,
  status,
  userImg,
  firstName,
  lastName,
  startTime,
  endTime,
  duration,
  editLink,
  onCancel,
}) => {
  const name = getFullName(firstName, lastName);
  return (
    <div
      className={classNames(
        `c-session-card session-detail-section`,
        className,
        {
          [`c-session-card--${getStatusClassName(status)}`]:
            getStatusClassName(status),
        }
      )}
    >
      <StatusBadge status={status} />
      <div className="row justify-content-between pr-5">
        <div className="col-auto">
          <div className="text-left">{therapyName}</div>
        </div>
      </div>
      <div className="row">
        <div className="user-info col-auto">
          <span className="admin-name">
            <img
              src={
                userImg !== ""
                  ? userImg
                  : "/resources/assets/defaultprofile.png"
              }
              className="image"
              alt=""
            />
            <div className="content text-left ml-2">
              <span>{name}</span>
            </div>
          </span>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-auto d-flex flex-wrap">
          <span className={`ts-time-interval p-0`}>
            <img
              className="pr-1"
              src={`/resources/assets/Icon-clock.svg`}
              alt=""
            />{" "}
            {startTime} - {endTime} | {duration}
          </span>
        </div>

        <div className="col-auto d-flex">
          {editLink && (
            <Link to={editLink}>
              <img
                className="pr-4"
                src={`/resources/assets/action-icon-edit.svg`}
                alt=""
              />
            </Link>
          )}
          {onCancel && (
            <Link onClick={onCancel}>
              <img
                src={`/resources/assets/cancel.png`}
                className="cancelled-icon"
                alt=""
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionCard;
