import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import allActions from "../../redux/actions";

import { marketingService } from "../../services/marketingService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import firebase from "firebase";
import { firebaseCofig } from "../../config/Config";
import { apiUrl } from "../../config/Config";
import { adminService } from "../../services/adminService";

function MarketingSessionFirstScreen() {
  const [inactive, setInactive] = useState(false);
  let params = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const [email, setEmail] = useState(Buffer.from(params.email, "base64"));
  const sessionId = params.id;

  const [tenantinfo, setTenantInfo] = useState({
    largeLogoUrl: "",
    primaryColor: "",
    secondaryColor: "",
    smallLogoUrl: "",
    tenantId: "",
    tenantName: "",
    tenantUrl: "",
  });
  const [tenantId,setTenantId]=useState("");
  useEffect(() => {
    let tenantUrl =
      window.location.host === "meisqa.techment.com"
        ? "meisqa.techment.com"
        : window.location.host === "localhost:3000" ||
          window.location.host === "meisdev.techment.com"
        ? "qa-tenant-1.techment.com"
        : window.location.host;
    adminService.getAllAuth(`tenants?tenantUrl=${tenantUrl}`).then(
      (res) => {
        console.log("tenantId",res.data[0].tenantId);
        const data = res?.data && res.data[0];
        setTenantId(res.data[0].tenantId);
        // if (data) {
        //   dispatch(adminAction.setTenantInfo(data));
        // }
      },
      (error) => {
        console.log({ error });
      }
    );
  }, []);
  const handleResponse = (response) => {
    return response
      .text()
      .then((text) => {
        const data = text && JSON.parse(text);
        if (!response?.ok) {
          // if any error like validation check or data not found, etc...
          if (response?.status === 400) {
            return data;
          }
          if (response?.status === 401 || response?.status === 403) {
            // auto logout if 401 response returned from api
            //   logout();
          }
          const error = (data && data.message) || response.statusText;
          //return Promise.reject(error);
          return data;
        }
  
        return data;
      })
      .catch((err) => console.log("object----------", err));
  }
  const headers = {
    "X-TenantID": tenantId,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
function generateTokenForMarketingUserID(endpoint, dataobj) {
  let options = {
    method: "POST",
    headers: headers,
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) =>
      toast.error(
        "Session has been expired, please try again, please logout and login again"
      )
    );
}
  const onSubmit = (props) => {
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    console.log("email",email.toString("ascii"))
    if (validateEmail(email) && sessionId) {
      try {
        const url = `marketing/agora-tokens/${sessionId}`;
        const data = {
          email: email.toString("ascii"),
        };
        let emails = email.toString("ascii");
        generateTokenForMarketingUserID(url, JSON.stringify(data))
          .then(
            (res) => {
              if (res?.status === "Success") {
                history("/marketing-video-session/" + sessionId,{state: { email: emails }});
              } else {
                res?.message && toast.error(res?.message);
              }
            },
            (error) => {}
          );
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.error("Email or Session Id is Invalid");
    }
  };

  //   function handleChange(e) {
  //     const { name, value } = e.target;
  //     setInputs((inputs) => ({ ...inputs, [name]: value }));
  //   }

  return (
    <>
      <div className="col">
        <div className="container p-0">
          <div className="row align-items-center login-page">
            <div className="col-md-6 no-padding p-0">
              <div className="log-wrapper-left">
                <img
                  src={`${
                    sessionId ? "../" : ""
                  }../resources/assets/Mask Group 2.png`}
                  className="mask-login-left"
                />
                {
                  <img
                    src={`${
                      sessionId ? "../" : ""
                    }../resources/assets/MIS-1.png`}
                    className="login-logo"
                  />
                }
                {/*window.location.host !== "cfdx.mis-live.com" ? <img  className="login-logo"  src={'../resources/assets/MIS-1.png'} alt="" />: <img  className="login-logo"  src={'https://meid-dev-public.s3.us-east-2.amazonaws.com/billinglogo.png'} alt="" /> */}
              </div>
              <img
                src={`${
                  sessionId ? "../" : ""
                }../resources/assets/left-login-img.jpg`}
                className="left-img img-fluid"
              />
              <img
                src={`${sessionId ? "../" : ""}../resources/assets/MIS-1.png`}
                className="copyright-image "
              />
              <span className="copyright-text">Copyright @RCE 2021</span>
            </div>

            <div className="col-md-6 no-padding">
              <div className="row valign_wrapper padd-b-60-all ">
                <div className="custom-table-view-login">
                  <div className="col-lg-12 min-hei-250-all">
                    <div className="text-center login">
                      {/* <img className="mb-3 mb-sm-5 tenant-logo-img" src={tenantinfo && tenantinfo.largeLogoUrl} alt="" />*/}

                      {
                        tenantinfo && tenantinfo?.largeLogoUrl && (
                          <img
                            className="mb-3 mb-sm-5 tenant-logo-img"
                            src={tenantinfo && tenantinfo.largeLogoUrl}
                            alt=""
                          />
                        )
                        // : (
                        //   <img
                        //     className="mb-3 mb-sm-5 tenant-logo-img"
                        //     src="https://meisdevpublic.s3.us-east-2.amazonaws.com/meis-logo.png"
                        //     alt=""
                        //   />
                        // )
                      }
                      <div className="form-signin-wrapper">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autocomplete="off"
                          className="custom-form form-custom-elements floating-form"
                        >
                          <h1 className="h2 mb-3 font-weight-normal text-left">
                            Please Join the Meeting
                          </h1>
                          <div className="form-group">
                            <input
                              name="email"
                              defaultValue={email}
                              type="text"
                              id="inputEmail"
                              className={"form-control"}
                              required
                              disabled
                            />
                          </div>

                          <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                              <button
                                className="btn custom-btn-green"
                                type="submit"
                                //disabled={inactive}
                                block={true}
                              >
                                Join Now
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketingSessionFirstScreen;
