import React, { useEffect, useState } from "react";
import DeleteSession from "../pages/Representative/DeleteSession";
import EditSessionRequest from "../pages/Representative/EditSessionRequest";
import moment from "moment";
import { adminService } from "../services/adminService";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { daysJson, monthJson } from "../config/Config";
import {
  calcMonthCount,
  calcWeekCount,
  getUTCTimeToLocalByDateWithMeridium,
  getUTCDateTimeToLocalByDateWithMeridium24,
  calculateMinsLeft,
} from "../utils/commonFunctions";
import { getListOfDays } from "../utils/commonFunctions";
import StatusBadge from "../components/StatusBadge";
import sortSessionByTime from "../utils/sessionSorting";
import SweetAlert from "react-bootstrap-sweetalert";

function DashboardSessionList() {
  let user = useSelector((state) => state.adminUser.user);
  const [loading, setloading] = useState(false);
  const [therapysessions, setTherapySessions] = useState([]);
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [modalOpenClose1, setModalOpenClose1] = useState(false);
  const [actionNeededModalOpen, setActionNeededModalOpen] = useState(false);
  const [iscancelreq, setIsCancelReq] = useState(false);
  let [startFromDateTime, setStartFromDateTime] = useState("");
  let [endToDateTime, setEndToDateTime] = useState("");
  let [weekcount, setWeekCount] = useState(0);
  let [weeks, setWeeks] = useState(getListOfDays(weekcount, false));
  let [ismonth, setIsMonth] = useState(false);
  let [searchname, setSearchName] = useState("");
  const [updatedStatus, setupdatedStatus] = useState("");
 
  const [deletesessiondetail, setdeletesessiondetail] = useState({
    info: "",
  });

  const [childList, setChildList] = useState([]);
  const [commonChildList, setCommonChildList] = useState([]);

  const getNextWeeK = () => {
    setWeeks(getListOfDays(weekcount + 1, ismonth));
    setWeekCount(weekcount + 1);
  };
  const hideAlert = () =>{
    setActionNeededModalOpen(false);
  }
  const getPrevWeeK = () => {
    setWeeks(getListOfDays(weekcount - 1, ismonth));
    setWeekCount(weekcount - 1);
  };

  const setWeekMonth = (val) => {
    if (val === "week") {
      if (ismonth) {
        setWeekCount(0);
        setIsMonth(false);
        setWeeks(getListOfDays(0, false));
      }
    } else {
      if (!ismonth) {
        setWeekCount(0);
        setIsMonth(true);
        setWeeks(getListOfDays(0, true));
      }
    }
  };

  useEffect(() => {
    setloading(true);
    let startFromDateTime =
      moment(weeks.length > 0 && weeks[0]).format("YYYY-MM-DD") + "T00:00:00";
    setStartFromDateTime(startFromDateTime);
    let endToDateTime =
      moment(weeks.length > 0 && weeks[weeks.length - 1]).format("YYYY-MM-DD") +
      "T23:59:59";
    setEndToDateTime(endToDateTime);
    getList(startFromDateTime, endToDateTime);
  }, [startFromDateTime, endToDateTime, weeks]);

  const getList = (startFromDateTime, endToDateTime) => {
    let user_role = user.userDetails.roles[0];
    let api =
      user_role === "ROLE_FAMILY"
        ? `families/filter/formatted/?page=0&size=20&sort=startDateTime,DESC&startFromDateTime=${startFromDateTime}&startToDateTime=${endToDateTime}&timeZone=${moment.tz.guess()}`
        : user_role === "ROLE_THERAPIST"
        ? `therapist/sessions/filter/formatted/?page=0&size=20&sort=startDateTime,DESC&startFromDateTime=${startFromDateTime}&startToDateTime=${endToDateTime}&timeZone=${moment.tz.guess()}`
        : user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS"
        ? `sessions/filter/formatted/?page=0&size=20&sort=startDateTime,DESC&startFromDateTime=${startFromDateTime}&startToDateTime=${endToDateTime}&timeZone=${moment.tz.guess()}`
        : `interpreter/sessions/filter/formatted/?page=0&size=20&sort=startDateTime,DESC&startFromDateTime=${startFromDateTime}&startToDateTime=${endToDateTime}&timeZone=${moment.tz.guess()}`;

    adminService.getAll(api).then(
      (res) => {
        if (res?.status === "Success") {
          setTherapySessions(sortSessionByTime(res));
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const cancelSessionReq = (id, name) => {
    let data = {
      sessionid: id,
      therapyname: name,
    };
    setdeletesessiondetail((deletesessiondetail) => ({
      ...deletesessiondetail,
      ["info"]: data,
    }));
    setModalOpenClose1(true);
    setIsCancelReq(true);
  };

  const Editsession = (id, name) => {
    let data = {
      sessionid: id,
      therapyname: name,
    };
    setdeletesessiondetail((deletesessiondetail) => ({
      ...deletesessiondetail,
      ["info"]: data,
    }));
    setModalOpenClose1(true);
    setIsCancelReq(false);
  };
  const Deletesession = (id, name, sessionInfo) => {
    let data = {
      sessionid: id,
      therapyname: name,
    };
    setdeletesessiondetail((deletesessiondetail) => ({
      ...deletesessiondetail,
      ["info"]: data,
      sessionInfo,
    }));
    setModalOpenClose(true);
  };

  useEffect(() => {
    user.userDetails.roles[0] === "ROLE_FAMILY" && getChildList();
  }, [therapysessions]);

  const getChildList = () => {
    adminService.getAll(`families/get-family-member`).then(
      (res) => {
        if (res?.status === "Success") {
          setChildList(res.data.content);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // useEffect(() => {
  //   let arr = [];
  //   if (
  //     user.userDetails.roles[0] === "ROLE_FAMILY" &&
  //     subinfo?.familyMemberDtoSet
  //   ) {
  //     arr = childList.filter((o1) =>
  //       subinfo?.familyMemberDtoSet.some((o2) => o1.id === o2.id)
  //     );
  //   }
  //   setCommonChildList(arr);
  // }, [childList]);

  const today = new Date();

  // Mark as Completed
  const markCompletedHandler = () => {
    let sessionId = actionNeededModalOpen.id;
    var data = {
      description: "",
      sessionId: sessionId,
      attachmentsList: [],
      drafted: true,
    };

    setloading(true);
    adminService
      .POST(`case-notes`, JSON.stringify(data))
      .then((res) => {
        if (res?.data) {
          getList(startFromDateTime, endToDateTime);
          setupdatedStatus("COMPLETED");
          setActionNeededModalOpen(false);
        }
      })
      .catch((e) => {
        setActionNeededModalOpen(false);
      });
  };
  // Mark as Missed
  const missHandler = () => {
    setloading(true);
    setActionNeededModalOpen(false);
    let sessionId = actionNeededModalOpen.id;

    adminService
      .PUT(
        `therapist/sessions/change-status/${sessionId}/MISSED`,
        JSON.stringify({})
        // status: "MISSED"
      )
      .then((res) => {
        if (res?.data) {
          getList(startFromDateTime, endToDateTime);
          setupdatedStatus(res.data.status);
          setActionNeededModalOpen(false);
        }
      })
      .catch((e) => {
        setActionNeededModalOpen(false);
      });
  };
  return (
    <>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="input-group">
            <div className="col-auto">
              <select
                className="form-control time"
                onChange={(e) => setWeekMonth(e.target.value)}
                id="exampleFormControlSelect1"
              >
                <option className="form-control time" value="week">
                  Week
                </option>
                <option className="form-control time" value="Month">
                  Month
                </option>
              </select>
            </div>
            <div className="col-md-7 d-flex align-items-center">
              <span className="font-weight-bold">
                {weeks.length > 0 &&
                  weeks.map((day, index) =>
                    index === 0
                      ? moment(day).format("DD") +
                        " " +
                        monthJson[parseInt(day.split("-")[1]) - 1].name
                      : index === weeks.length - 1
                      ? " - " +
                        moment(day).format("DD") +
                        " " +
                        monthJson[parseInt(day.split("-")[1]) - 1].name +
                        ", " +
                        day.split("-")[0]
                      : ""
                  )}
              </span>
            </div>
            <div className="col-md-2">
              <div
                className="btn-group filter-next-prev dashboard-session-list"
                role="group"
              >
                <button
                  onClick={() => getPrevWeeK()}
                  className="btn next"
                  type="button"
                >
                  <img
                    className="pr-2"
                    src="../resources/assets/arrow-left.svg"
                  />
                </button>
                <div className="action-devider-button"></div>
                <button
                  onClick={() => getNextWeeK()}
                  className="btn prev"
                  type="button"
                >
                  <img
                    className="pl-2"
                    src="../resources/assets/arrow-right.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {therapysessions.length > 0 ? (
        therapysessions &&
        therapysessions.map((item) => (
          <>
            {item &&
              item.map((info) => (
                <>
                  <div className="col-md-12 mt-4">
                    <div className="back">
                      <h6>
                        {" "}
                        {
                          daysJson[
                            parseInt(
                              moment(info.date).isoWeekday() === 0
                                ? 6
                                : moment(info.date).isoWeekday() - 1
                            )
                          ].name
                        }{" "}
                        , {moment(info.date).format("DD")}{" "}
                        {
                          monthJson[
                            parseInt(moment(info.date).format("MM")) - 1
                          ].name
                        }{" "}
                        {moment(info.date).format("YYYY")}
                      </h6>
                    </div>
                  </div>
                  {info.therapySession &&
                    info.therapySession.map((subinfo, index) => {
                      let arr = [];
                      if (
                        user.userDetails.roles[0] === "ROLE_FAMILY" &&
                        subinfo?.familyMemberDtoSet
                      ) {
                        arr = childList.filter((o1) =>
                          subinfo?.familyMemberDtoSet.some(
                            (o2) => o1.id === o2.id
                          )
                        );
                      }

                      const time = calculateMinsLeft(subinfo?.startDateTime);
                      const endTime = calculateMinsLeft(subinfo?.endDateTime);
                      if (
                        time < 5 &&
                        endTime >= 1 &&
                        subinfo?.status != "COMPLETED" &&
                        subinfo?.status != "CANCELLED"
                      ) {
                        subinfo.isSessionReady = true;
                      } else {
                        subinfo.isSessionReady = false;
                      }
                      return (
                        <div className="col-auto mt-3">
                          <div className="user-info">
                            <span className="admin-name justify-content-start">
                              {subinfo?.familyMemberDtoSet.length == 1 ? (
                                <img
                                  src={
                                    typeof subinfo?.familyMemberDtoSet[0]
                                      ?.profileUrl === "string"
                                      ? subinfo?.familyMemberDtoSet[0]
                                          ?.profileUrl
                                      : "/resources/assets/defaultprofile.png"
                                  }
                                  className="image"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={"/resources/assets/gUsers.jpg"}
                                  className="image"
                                  alt=""
                                />
                              )}
                              <div className="col-7 content">
                                {user.userDetails.roles[0] == "ROLE_FAMILY" &&
                                arr.length > 0 ? (
                                  <>
                                    {arr[0]?.firstName + " " + arr[0]?.lastName}
                                  </>
                                ) : subinfo?.familyMemberDtoSet &&
                                  subinfo?.familyMemberDtoSet.length > 0 ? (
                                  <>
                                    {subinfo?.familyMemberDtoSet[0]?.firstName +
                                      " " +
                                      subinfo?.familyMemberDtoSet[0]?.lastName}
                                  </>
                                ) : (
                                  <>No Client Present</>
                                )}

                                {subinfo.familyMemberDtoSet.length > 1 &&
                                  ` +${
                                    subinfo.familyMemberDtoSet.length - 1
                                  } more`}
                                {/* {subinfo?.familyMemberDtoSet.length == 1 && (
                                <sapn>
                                  {subinfo?.familyMemberDtoSet[0]?.firstName}{" "}
                                  {subinfo?.familyMemberDtoSet[0]?.lastName}
                                </sapn>
                              )} */}
                                <span className="m-0">
                                  {" "}
                                  ({subinfo.therapy.name})
                                </span>
                                <small className="time">
                                  <br />
                                  {getUTCTimeToLocalByDateWithMeridium(
                                    subinfo.startDateTime
                                  )}{" "}
                                  -{" "}
                                  {getUTCTimeToLocalByDateWithMeridium(
                                    subinfo.endDateTime
                                  )}
                                </small>
                              </div>
                              <div className="col-4 text-right">
                                <div>
                                  {subinfo?.status === "ACTION_NEEDED" ? (
                                    <>
                                      {user.userDetails.roles[0] ===
                                        "ROLE_THERAPIST" &&
                                        subinfo.locationOfTreatment !=
                                          "Teletherapy" && (
                                          <>
                                            {
                                              <button
                                                onClick={() =>
                                                  setActionNeededModalOpen(
                                                    subinfo
                                                  )
                                                }
                                                className="btn btn-theme-green"
                                              >
                                                Action Needed
                                              </button>
                                            }
                                          </>
                                        )}
                                      <StatusBadge
                                        style={{ marginLeft: 10 }}
                                        status={
                                          subinfo.status == "ACTION_NEEDED" &&
                                          user.userDetails.roles[0] !=
                                            "ROLE_THERAPIST"
                                            ? "ACTION_NEEDED_PENDING"
                                            : subinfo.status
                                        }
                                      />
                                    </>
                                  ) : subinfo.status === "UP_COMING" ? (
                                    <>
                                      {subinfo?.locationOfTreatment ===
                                      "Teletherapy" ? (
                                        <>
                                          {" "}
                                          {subinfo?.isSessionReady ? (
                                            <a
                                              href={`/video-therapy/${subinfo.id}`}
                                              className="btn btn-theme-green"
                                            >
                                              Join Now
                                            </a>
                                          ):<StatusBadge
                                          style={{ marginLeft: 10 }}
                                          status={subinfo.status}
                                        />}
                                        </>
                                      ) : (
                                        <StatusBadge
                                          style={{ marginLeft: 10 }}
                                          status={subinfo.status}
                                        />
                                      )}
                                    </>
                                  ) : subinfo.status === "MISSED" ? (
                                    <>
                                      {user.userDetails.roles[0] ===
                                      "ROLE_THERAPIST" ? (
                                        <>
                                          <StatusBadge
                                            style={{ marginLeft: 10 }}
                                            status={subinfo.status}
                                          />
                                          <Link
                                            to={
                                              "/edit-therapy-session/" +
                                              subinfo.id
                                            }
                                          >
                                            <img
                                              style={{ marginLeft: 10 }}
                                              src="../resources/assets/action-icon-edit.svg"
                                              alt=""
                                            />
                                          </Link>
                                          {/* <Link onClick={() => Deletesession(subinfo.id, subinfo.therapy.name)}><img src='../resources/assets/action-icon-delete.svg' alt="" /></Link> */}
                                        </>
                                      ) : (
                                        <>
                                          <StatusBadge
                                            style={{ marginLeft: 5 }}
                                            status={subinfo.status}
                                          />
                                          <Link
                                            onClick={() =>
                                              Editsession(
                                                subinfo.id,
                                                subinfo.therapy.name
                                              )
                                            }
                                          >
                                            <img
                                              style={{ marginLeft: 10 }}
                                              src="../resources/assets/action-icon-edit.svg"
                                              alt=""
                                            />
                                          </Link>
                                          {/* <Link onClick={() => cancelSessionReq(subinfo.id, subinfo.therapy.name)}><img src='../resources/assets/action-icon-delete.svg' alt="" /></Link> */}
                                        </>
                                      )}
                                    </>
                                  ) : subinfo.status === "IN_PROGRESS" &&
                                    getUTCDateTimeToLocalByDateWithMeridium24(
                                      subinfo.endDateTime
                                    ) >=
                                      getUTCDateTimeToLocalByDateWithMeridium24(
                                        new Date()
                                      ) ? (
                                    <>
                                      <a
                                        href={`/video-therapy/${subinfo.id}`}
                                        className="btn btn-theme-green"
                                      >
                                        Join Now
                                      </a>
                                      <StatusBadge
                                        style={{ margin: 10, marginRight: 0 }}
                                        status={subinfo.status}
                                      />
                                    </>
                                  ) : subinfo.status === "IN_PROGRESS" ? (
                                    <>
                                      <StatusBadge
                                        style={{ marginLeft: 10 }}
                                        status="COMPLETED"
                                      />
                                    </>
                                  ) : (
                                    <StatusBadge status={subinfo.status} />
                                  )}
                                </div>
                              </div>
                            </span>
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                </>
              ))}
          </>
        ))
      ) : (
        <h6 className="text-center mt-5">No Data Found</h6>
      )}

      {actionNeededModalOpen && (
        <SweetAlert
          confirmBtnBsStyle="info"
          title={
            <h4 className="font-size-1-55-rem">
              Is the session "Completed" or "Missed"?
            </h4>
          }
         
          focusCancelBtn
          showCancel={true}
          showConfirm={false}
          onCancel={hideAlert}
          customClass="action-needed-popup"
        >
          <div className="d-flex justify-content-center">
            <button
              onClick={() => markCompletedHandler()}
              className="btn btn-success font-size-16"
            >
              Mark as completed
            </button>

            <button
              onClick={() => missHandler()}
              className="btn btn-danger font-size-16"
            >
              Mark as missed
            </button>
          </div>
        </SweetAlert>
      )}
      {modalOpenClose && (
        <DeleteSession
          //successSub={() => successSub()}
          closeModal={() => setModalOpenClose(false)}
          sessioninfo={deletesessiondetail}
        />
      )}
      {modalOpenClose1 && (
        <EditSessionRequest
          isdelete={iscancelreq}
          // successSub={() => successSub()}
          closeModal={() => setModalOpenClose1(false)}
          sessioninfo={deletesessiondetail}
        />
      )}
    </>
  );
}

export default DashboardSessionList;
