import React, { useState } from "react";

function CreateForm(props) {
  const {
    handleSubmit,
    onSubmit,
    register,
    handleChange,
    familyList,
    formNameList,
    setInputs,
    inputs,
    errors,
    setIsFormOpen,
    isFormOpen,
  } = props;
  const handleNext = () => {
    inputs.familyMemberId && inputs.formId
      ? setIsFormOpen(true)
      : setIsFormOpen(false);
  };
 
  return (
    <>
      <form
        className="add-session-form form-list"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!isFormOpen && (
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-10">
                <label>Caretaker & Client</label> (
                <span className="required-span">*</span>)
                <div className="input-group">
                  <select
                    {...register('familyMemberId',{ required: true,onChange:(e)=>handleChange(e) })}
                    className={`custom-select ${
                      errors.familyMemberId && "is-invalid"
                    }`}
                    //onChange={handleChange}
                    name="familyMemberId"
                  >
                    <option value="">Select Caretaker & Client</option>
                    {familyList &&
                      familyList.map((info, index) => (
                        <>
                          {info.familyMembers &&
                            info.familyMembers.map((minfo, index) => (
                              <>
                                <input
                                  type="hidden"
                                  id={minfo.id}
                                  value={info.id}
                                  index={index}
                                />

                                <option value={minfo.id}>
                                  {info.userProfile.firstName +
                                    " " +
                                    info.userProfile.lastName}
                                  /{minfo.firstName + " " + minfo.lastName}
                                </option>
                              </>
                            ))}
                        </>
                      ))}
                  </select>
                  <div className="input-group-prepend">
                    <img
                      className="m-auto"
                      src="../resources/assets/Polygon 1.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-10">
                <label>Form</label> (<span className="required-span">*</span>)
                <div className="input-group">
                  <select
                    {...register('formId',{ required: true,onChange:(e)=>handleChange(e) })}
                    className={`custom-select ${errors.formId && "is-invalid"}`}
                    //onChange={handleChange}
                    name="formId"
                  >
                    <option value="">Select Form</option>
                    {formNameList &&
                      formNameList.map((info, index) => (
                        <>
                          <option value={info.id}>{info.name}</option>
                        </>
                      ))}
                  </select>
                  <div className="input-group-prepend">
                    <img
                      className="m-auto"
                      src="../resources/assets/Polygon 1.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            {inputs.formId &&
              inputs.formId != 3 &&
              inputs.formId != 7 &&
              inputs.formId != 8 &&
              inputs.formId != 9 &&
              inputs.formId != 17 &&
              inputs.formId != 18 &&
              inputs.formId != 10 &&
              inputs.formId != 11 &&
              inputs.formId != 12 &&
              inputs.formId != 13 &&
              inputs.formId != 14 &&
              inputs.formId != 15 &&
              inputs.formId != 16 &&
              inputs.formId != 19 &&
              inputs.formId != 20 && (
                <div className="col-md-12 mb-10">
                  <input
                    type="checkbox"
                    name="replyNeeded"
                    // {...register({ required: true })}
                    className={`${errors.replyNeeded && "is-invalid"}`}
                    defaultValue={inputs.replyNeeded}
                    onChange={() =>
                      setInputs((inputs) => ({
                        ...inputs,
                        replyNeeded: !inputs.replyNeeded,
                      }))
                    }
                  />{" "}
                  Need Reply?
                </div>
              )}
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            {isFormOpen &&
            inputs.formId != 1 &&
            inputs.formId != 2 &&
            inputs.formId != 3 &&
            inputs.formId != 4 &&
            inputs.formId != 5 &&
            inputs.formId != 6 &&
            inputs.formId != 7 &&
            inputs.formId != 8 &&
            inputs.formId != 9 &&
            inputs.formId != 10 &&
            inputs.formId != 11 &&
            inputs.formId != 12 &&
            inputs.formId != 16 &&
            inputs.formId != 19 &&
            inputs.formId != 13 &&
            inputs.formId != 14 &&
            inputs.formId != 15 &&
            inputs.formId != 16 && 
            inputs.formId != 17 && 
            inputs.formId != 18 && 
            inputs.formId != 19 && 
            inputs.formId != 20 ? (
              <>
                <button
                  className="btn btn-theme-green mb-10"
                  onClick={() => props?.handleBack()}
                  type="button"
                >
                  Back
                </button>
                <button type="submit" className="btn btn-theme-green mb-10">
                  Submit
                </button>
              </>
            ) : (
              <>
                {/* // <button
              //   disabled={inputs.familyMemberId && inputs.formId ? false : true}
              //   // type="button"
              //   onClick={() => handleNext()}
              //   className="btn btn-success mb-10"
              // >
              //   Next
              // </button> */}
              </>
            )}
          </div>
        </div>
      </form>

      <div className="row">
        <div className="col-md-12">
          {isFormOpen ? (
            <></>
          ) : (
            <>
              <button
                type="button"
                onClick={() => props?.handleBack()}
                className="btn btn-theme-green mb-10"
              >
                Back
              </button>
              <button
                disabled={inputs.familyMemberId && inputs.formId ? false : true}
                // type="button"
                onClick={() => handleNext()}
                className="btn btn-theme-green mb-10"
              >
                Next
              </button>
            </>
          )}
        </div>
        {console.log("inputs", inputs,inputs.familyMemberId && inputs.formId ? false : true)}
      </div>
    </>
  );
}

export default CreateForm;
