import React from "react";
import moment from "moment";

function SignDate(props) {
  const { date, familyImageURL, isSubmit, setOpen, signTitle, familyInfo } =
    props;
  return (
    <>
      <div
        className={`${props?.common || "row mt-30"}`}
        style={{ width: "100%", display: "block" }}
      >
        <div
          className={`${props?.common || "col-md-6"}`}
          style={{
            width: props?.common ? "60%" : "50%",
            display: "inline-block",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}>
            <label style={{ fontWeight: props?.common || "bold" }}>
              {props?.guardian ? props.guardian : signTitle} Signature :
            </label>
          </div>
        </div>
        <div
          className={`${props?.common || "col-md-6"}`}
          style={{
            width: props?.common ? "40%" : "50%",
            display: "inline-block",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}>
            <label
              style={{ width: "auto", fontWeight: props?.common || "bold" }}
            >
              Date :
            </label>
            &nbsp;&nbsp;
            {date}
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%", display: "block" }}>
        <div
          className="col-md-6"
          style={{ width: "50%", display: "inline-block" }}
        >
          <div className="form-row1 row" style={{ width: "90%" }}>
            <div className="col-md-12">
              <div
                className="sign-img"
                style={{
                  width: "160px",
                  height: "60px",
                  border: 0,
                  marginBottom: "10px",
                }}
              >
                {familyImageURL ? (
                  <img
                    src={
                      props?.isLoading
                        ? props?.userType == "therapist"
                          ? "familyInfo?.therapistSign"
                          : "familyInfo?.familySign"
                        : familyImageURL
                    }
                    alt="my signature"
                    className="sign-img1"
                    style={{ width: "160px", height: "100%" }}
                  />
                ) : familyInfo?.familySign ? (
                  <img
                    src={familyInfo?.familySign}
                    alt="my signature"
                    className="sign-img1"
                    style={{ width: "160px", height: "100%" }}
                  />
                ) : familyInfo?.therapistSign ? (
                  <img
                    src={familyInfo?.therapistSign}
                    alt="my signature"
                    className="sign-img1"
                    style={{ width: "160px", height: "100%" }}
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-12 pl-0">
              {!isSubmit && (
                <button
                  type="button"
                  className="btn btn-success create-sign-btn"
                  onClick={() => setOpen(true)}
                >
                  {familyImageURL ? "Update" : "Create"} Your Signature
                </button>
              )}
            </div>
          </div>
        </div>

        <div
          className="col-md-6"
          style={{
            width: "50%",
            display: "inline-block",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}></div>
        </div>
      </div>
    </>
  );
}

export default SignDate;
