import React from "react";
import { IoIosEye } from "react-icons/io";
import { getUTCDateTimeToLocalByDateWithMeridium24 } from "../../utils/commonFunctions";
import { useParams, useNavigate } from "react-router-dom";

function GoalList(props) {
  const history = useNavigate();
  return (
    <>
      <tbody>
        {props?.list &&
          props?.list.map((data, i) => {
            return (
              <tr key={i}>
                <td>{data?.childName}</td>
                <td>
                  {getUTCDateTimeToLocalByDateWithMeridium24(data?.sessionDate)}
                </td>
                <td>{data?.goalsDto?.therapyType}</td>
                <td>{data?.therapist}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-lg btn-info mb-10 form-view-button"
                    // onClick={(e) =>
                    //     history(`misgoals/${}`)
                    // }
                  >
                    <IoIosEye />
                  </button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </>
  );
}

export default GoalList;
