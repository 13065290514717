import React from "react";

function SelectTherapy(props) {
  const {
    params,
    register,
    errors,
    handleChange,
    inputs,
    therapies,
    currentRole,
    therapistUserId,
    therapyFor,
  } = props;
  return (
    <>
      <div className="col-md-12">
        <div className="form-group">
          <label>Therapy</label> (<span className="required-span">*</span>)
          <div className="row ml-2">
            <img
              src="../resources/assets/therapy-web.svg"
              className="img-fluid"
              alt=""
            />
            <div className="col-md-10">
              <div className="input-group" style={{ marginLeft: 6 }}>
                {!params.id && currentRole === "ROLE_REGIONAL_EARLY_STEPS" ? (
                  <select
                    {...register('therapyId',{ onChange:(e)=>handleChange(e),required: true })}
                    className={`custom-select ${
                      errors.therapyId && "is-invalid"
                    }`}
                    //onChange={handleChange}
                    value={inputs?.therapyId}
                    // defaultValue={inputs?.therapyId}
                    name="therapyId"
                    disabled={params.id ? "disabled" : ""}
                  >
                    <option value="">Select Therapy</option>
                    {!params.id &&
                      therapies &&
                      therapies.map((info, index) => (
                        <>
                          <option value={info.id}>{info.name}</option>
                        </>
                      ))}
                    {params.id && therapies && (
                      <>
                        <option value={therapies.id}>{therapies.name}</option>
                      </>
                    )}
                  </select>
                ) : (
                  <select
                    {...register('therapyId',{ onChange:(e)=>handleChange(e),required: true })}
                    className={`custom-select ${
                      errors.therapyId && "is-invalid"
                    }`}
                    //onChange={handleChange}
                    value={inputs.therapyId}
                    defaultValue={inputs.therapyId}
                    name="therapyId"
                    disabled={params.id ? "disabled" : ""}
                  >
                    <option value="">Select Therapy</option>
                    {!params.id &&
                      therapies.length > 0 &&
                      therapies.map((info, index) => (
                        <>
                          {info?.therapist &&
                          info?.therapist.id === therapistUserId &&
                          currentRole === "ROLE_THERAPIST" ? (
                            <option value={info.therapy.id}>
                              {info.therapy.name}
                            </option>
                          ) : currentRole !== "ROLE_THERAPIST" ? (
                            <option value={info.therapy.id}>
                              {info.therapy.name}
                            </option>
                          ) : (
                            therapyFor === "group" && (
                              <option value={info.therapy.id}>
                                {info.therapy.name}
                              </option>
                            )
                          )}
                        </>
                      ))}
                    {params.id && therapies && (
                      <>
                        <option value={therapies.id}>{therapies.name}</option>
                      </>
                    )}
                  </select>
                )}

                <div className="input-group-prepend">
                  <img
                    className="m-auto"
                    src="../resources/assets/Polygon 1.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectTherapy;
