import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { adminService } from "../../../services/adminService";
import allActions from "../../../redux/actions";
import {
  daysJson,
  daySorter,
  getTimeFormatPropsInputComp,
} from "../../../config/Config";
import { sortLanguage, getTimeInUTC } from "../../../utils/commonFunctions";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { getTime24, getMeridium24 } from "../../../utils/commonFunctions";
import appAction from "../../../redux/actions/appAction";
import TimeInput from "../../../components/TimeInput";

function TherapistSetAvailability(props) {
  const { register, formState: { errors }, handleSubmit } = useForm();
  let params = useParams();
  let today_date = new Date();
  const history = useNavigate();
  const dispatch = useDispatch();
  let [timezones, setTimezones] = useState([]);
  let therapistForm = useSelector((state) => state.adminUser.therapistForm);
  let user = useSelector((state) => state.adminUser.user);
  let jsonFound = therapistForm.hasOwnProperty("therapistAvailabilityForm");
  const [language, setLanguage] = useState("");
  const [loading, setloading] = useState(false);
  const [languageIds, setLanguageIds] = useState([]);
  const [availabilityJson, setAvailabilityJson] = useState([]);
  let currentRole = user.userDetails.roles[0];

  const [inputs, setInputs] = useState({
    pendingOnboarding: false,
    sun: jsonFound ? therapistForm.therapistAvailabilityForm.sun : false,
    mon: jsonFound ? therapistForm.therapistAvailabilityForm.mon : false,
    tue: jsonFound ? therapistForm.therapistAvailabilityForm.tue : false,
    wed: jsonFound ? therapistForm.therapistAvailabilityForm.wed : false,
    thu: jsonFound ? therapistForm.therapistAvailabilityForm.thu : false,
    fri: jsonFound ? therapistForm.therapistAvailabilityForm.fri : false,
    sat: jsonFound ? therapistForm.therapistAvailabilityForm.sat : false,
    endTime: jsonFound ? therapistForm.therapistAvailabilityForm.endTime : "",
    startTime: jsonFound
      ? therapistForm.therapistAvailabilityForm.startTime
      : "",
    selectedStartTime: jsonFound
      ? therapistForm.therapistAvailabilityForm.selectedStartTime
      : "00:00",
    selectedEndTime: jsonFound
      ? therapistForm.therapistAvailabilityForm.selectedEndTime
      : "00:00",
    tempselectLangId: 0,
    therapistAvailabilityId: 0,
    timezoneId: 1, // 1 for EST default
    onboardPermission: false,
    updatePermission: false,
  });
  const [langClear, setLangClear] = useState({
    tempselectLangId: 0,
  });

  const [timeInputs, setTimeInputs] = useState({
    startTime: moment(today_date).format("hh:mm"),
    endTime: moment(today_date).format("hh:mm"),
    startMeridium: "am",
    endMeridium: "pm",
  });

  function getTimeForSetTimeUTC(time, meridium) {
    let time24 = moment(time + " " + meridium, ["h:mm A"]).format("HH:mm:ss");
    return getTimeInUTC(time24);
  }
  // console.log("therapistServiceForms", therapistForm.therapistServiceForms);
  const onSubmit = () => {
    if (
      !inputs.sun &&
      !inputs.mon &&
      !inputs.tue &&
      !inputs.wed &&
      !inputs.thu &&
      !inputs.fri &&
      !inputs.sat
    ) {
      toast.warn("Please select working day!");
      return;
    }

    if (inputs.selectedStartTime >= inputs.selectedEndTime) {
      toast.warn("Start time can not be less from end time!");
      return;
    }
    if (languageIds.length === 0) {
      toast.warn("Please add atleast 1 language!");
      return;
    }

    if (!inputs.timezoneId) {
      toast.warn("Please select timezone!");
      return;
    }

    if (params.id) {
      let availabilityJsonOBJ = [];
      availabilityJson.map((item) => {
        let startTime24 = moment(item.startTime + " " + item.startMeridium, [
          "h:mm A",
        ]).format("HH:mm:ss");
        let endTime24 = moment(item.endTime + " " + item.endMeridium, [
          "h:mm A",
        ]).format("HH:mm:ss");

        availabilityJsonOBJ.push({
          therapistAvailabilityId: item.therapistAvailabilityId,
          availableDay: item.availableDay,
          startTime: item.differentTime
            ? startTime24
            : inputs.selectedStartTime,
          endTime: item.differentTime ? endTime24 : inputs.selectedEndTime,
          differentTime: item.differentTime,
        });
      });
      let formDataPut = {
        therapistAvailabilityUpdateForm: availabilityJsonOBJ,
        languageIds: languageIds,
        timezoneId: inputs.timezoneId,
      };
      let formData = new FormData();
      formData.append(
        "therapistUpdateForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );

      dispatch(appAction.setAppLoading(true));
      adminService
        .update(`/therapists/` + params.id, formData)
        .then(
          (res) => {
            if (res.status === "Success") {
              toast.success(res.message);
              setTimeout(() => {
                currentRole === "ROLE_THERAPIST"
                  ? history("/settings")
                  : history("/therapist-details/" + params.id);
              }, 2000);
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
      return; // for edit return from this
    }

    // Set time loop
    let availabilityJsonOBJ = [];
    availabilityJson.map((item, index) => {
      let startTime24 = moment(item.startTime + " " + item.startMeridium, [
        "h:mm A",
      ]).format("HH:mm:ss");
      let endTime24 = moment(item.endTime + " " + item.endMeridium, [
        "h:mm A",
      ]).format("HH:mm:ss");

      availabilityJsonOBJ.push({
        availableDay: item.availableDay,
        startTime: item.differentTime ? startTime24 : inputs.selectedStartTime,
        endTime: item.differentTime ? endTime24 : inputs.selectedEndTime,
        differentTime: item.differentTime,
      });

      // availabilityJsonOBJ.push({
      //     availableDay: item.availableDay,
      //     startTime: item.differentTime ? getTimeForSetTimeUTC(item.startTime, item.startMeridium) : inputs.selectedStartTime,
      //     endTime: item.differentTime ? getTimeForSetTimeUTC(item.endTime, item.endMeridium) : inputs.selectedEndTime,
      //     differentTime: item.differentTime
      // })
    });

    //return;
    dispatch(
      allActions.adminAction.setTherapistFormData(
        availabilityJsonOBJ,
        "therapistAvailabilityForm"
      )
    );
    dispatch(
      allActions.adminAction.setTherapistFormData(languageIds, "languageIds")
    );
    let formData = new FormData();
    therapistForm.timezoneId = inputs.timezoneId;
    therapistForm.userProfileForm.phone =
      therapistForm.userProfileForm.phone.replace(/[^\d]/g, "");

    therapistForm.pendingOnboarding = "false";
    therapistForm.onboardPermission = false;
    therapistForm.updatePermission = false;
    dispatch(appAction.setAppLoading(true));
    formData.append("profileImage", therapistForm.userProfileForm.profileImage);
    const basicInfo = {
      firstName: therapistForm?.userProfileForm?.firstName,
      lastName: therapistForm?.userProfileForm?.lastName,
      email: therapistForm?.userProfileForm?.email,
      phone: therapistForm?.userProfileForm?.phone,
      about: therapistForm?.userProfileForm?.about,
      gender: therapistForm?.userProfileForm?.gender,
      active: therapistForm?.userProfileForm?.active,
    };
    if (props?.therapistData) {
      let formDataPut = {
        userProfileUpdateForm: basicInfo,
        therapistServiceUpdateForms: therapistForm.therapistServiceForms,
        pendingOnboarding: false,
        onboardPermission: false,
        updatePermission: false,
        therapistAvailabilityUpdateForm: availabilityJsonOBJ,
        timezoneId: inputs.timezoneId,
        languageIds: languageIds,

        credentials: therapistForm?.otherFields?.credentials,
        disciplines_id: therapistForm?.otherFields?.disciplines_id,
        licenseNumberState: therapistForm?.otherFields?.licenseNumberState,
        hourlyRate: therapistForm?.otherFields?.hourlyRate,
        medicaidNumber: therapistForm?.otherFields?.medicaidNumber,
        taxonomy: therapistForm?.otherFields?.taxonomy,
        otherDiscipline: therapistForm?.otherFields?.otherDiscipline,
      };
      formData.append(
        "therapistUpdateForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );
      adminService
        .update(`/therapists/${props?.therapistData.id}`, formData)
        .then(
          (res) => {
            dispatch(appAction.setAppLoading(false));
            console.log("object", res);
            if (res?.data?.id) {
              history("/therapist");
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    } else {
      // formData.append(
      //   "therapistForm",
      //   new Blob([JSON.stringify(therapistForm)], {
      //     type: "application/json",
      //   })
      // );
      let formDataPut = {
        userProfileForm: basicInfo,
        therapistServiceForms: therapistForm.therapistServiceForms,
        pendingOnboarding: false,
        onboardPermission: false,
        updatePermission: false,
        therapistAvailabilityForm: availabilityJsonOBJ,
        timezoneId: inputs.timezoneId,
        languageIds: languageIds,
        credentials: therapistForm?.otherFields?.credentials,
        disciplines_id: therapistForm?.otherFields?.disciplines_id,
        licenseNumberState: therapistForm?.otherFields?.licenseNumberState,
        hourlyRate: therapistForm?.otherFields?.hourlyRate,
        medicaidNumber: therapistForm?.otherFields?.medicaidNumber,
        taxonomy: therapistForm?.otherFields?.taxonomy,
        otherDiscipline: therapistForm?.otherFields?.otherDiscipline,
      };
      formData.append(
        "therapistForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );

      adminService
        .onboardUser(`therapists`, formData)
        .then(
          (res) => {
            if (res?.status === "Success") {
              //props.handleNext();
              dispatch(allActions.adminAction.clearOnboardData());
              toast.success(res.message);
              setTimeout(() => {
                history("/therapist");
              }, 2000);
            } else {
              toast.error(res?.message || "Somthing went wrong.");
            }
          },
          (error) => {
            console.log("Error >>>>>>>", error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    }
  };

  const addLanguage = () => {
    if (inputs.tempselectLangId != 0) {
      let fileindex = languageIds.findIndex(
        (obj) => obj == inputs.tempselectLangId
      );
      if (fileindex == -1) {
        setLanguageIds([...languageIds, parseInt(inputs.tempselectLangId)]);
        dispatch(
          allActions.adminAction.setTherapistFormData(
            languageIds,
            "languageIds"
          )
        );
      } else {
        toast.warning(
          "Language is already added, please add another language!"
        );
      }
    } else {
      toast.warning("Please select a language");
    }
    setLangClear({
      tempselectLangId: 0,
    });
  };

  const removeLanguage = (id) => {
    setLanguageIds(languageIds.filter((item) => item !== id));
  };

  function handleChangeTimezone(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value ? parseInt(value) : "",
    }));
  }

  function handleChange(e) {
    const { name, value, checked } = e.target;
    if (name === "tempselectLangId") {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
      setLangClear((langClear) => ({ ...langClear, [name]: value }));
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: checked }));
      // add aval to json data
      if (checked) {
        let avaljson = {
          availableDay: value,
          startTime: moment(today_date).format("hh:mm"),
          endTime: moment(today_date).format("hh:mm"),
          startMeridium: "am",
          endMeridium: "pm",
          differentTime: false,
        };
        setAvailabilityJson([...availabilityJson, avaljson]);
      } else {
        setAvailabilityJson(
          availabilityJson.filter((item) => item.availableDay !== value)
        );
      }
      // add ends
    }
  }

  function handleChangeSetTime(e) {
    const { name, value, checked } = e.target;

    let fileindex = availabilityJson.findIndex(
      (obj) => obj.availableDay === value
    );
    let tempJson = availabilityJson;
    tempJson[fileindex].differentTime = checked;
    setAvailabilityJson(tempJson);
    setAvailabilityJson([...availabilityJson, { availableDay: "" }]); // for render jsx
    setAvailabilityJson(
      availabilityJson.filter((item) => item.availableDay !== "")
    ); // for remove blank object
  }

  function getLanguageName(id) {
    console.log("getlanguage",language);
    return (
      language.length > 0 &&
      language.filter((lang) => lang.id === id)?.[0]?.languageName
    );
  }

  const renderInput = (props, openCalendar) => {
    return (
      <div className="input-group">
        <img src="../resources/assets/clock.svg" onClick={openCalendar} />
        <input {...props} />
      </div>
    );
  };

  const handleChangeTime = (e, name) => {
    setTimeInputs((timeInputs) => ({
      ...timeInputs,
      [name]: moment(e._d).format("hh:mm"),
    }));
  };

  const handleChangeMeridium = (name, value) => {
    setTimeInputs((timeInputs) => ({ ...timeInputs, [name]: value }));
  };

  // for Set Time

  const handleChangeDiffTime = (e, name, availableDay) => {
    let fileindex = availabilityJson.findIndex(
      (obj) => obj.availableDay == availableDay
    );
    let avaljson = {
      availableDay: availableDay,
      startTime:
        name == "startTime"
          ? moment(e._d).format("hh:mm")
          : availabilityJson[fileindex].startTime, // remove e._d to edit the input elements and hh:mm to HH:mm
      endTime:
        name == "endTime"
          ? moment(e._d).format("hh:mm")
          : availabilityJson[fileindex].endTime, // remove e._d to edit the input elements
      startMeridium: availabilityJson[fileindex].startMeridium,
      endMeridium: availabilityJson[fileindex].endMeridium,
      differentTime: true,
      therapistAvailabilityId: params.id
        ? availabilityJson[fileindex].therapistAvailabilityId
        : "",
    };
    let tempJson = availabilityJson;
    tempJson[fileindex] = avaljson;
    setAvailabilityJson(tempJson);
    setAvailabilityJson([...availabilityJson, { availableDay: "" }]); // for render jsx
    setAvailabilityJson(
      availabilityJson.filter((item) => item.availableDay !== "")
    ); // for remove blank object
  };

  const handleChangeDiffMeridium = (name, value, availableDay) => {
    let fileindex = availabilityJson.findIndex(
      (obj) => obj.availableDay == availableDay
    );
    let avaljson = {
      availableDay: availableDay,
      startTime: availabilityJson[fileindex].startTime,
      endTime: availabilityJson[fileindex].endTime,
      startMeridium:
        name == "startMeridium"
          ? value
          : availabilityJson[fileindex].startMeridium,
      endMeridium:
        name == "endMeridium" ? value : availabilityJson[fileindex].endMeridium,
      differentTime: true,
      therapistAvailabilityId: params.id
        ? availabilityJson[fileindex].therapistAvailabilityId
        : "",
    };
    let tempJson = availabilityJson;
    tempJson[fileindex] = avaljson;
    setAvailabilityJson(tempJson);
    setAvailabilityJson([...availabilityJson, { availableDay: "" }]); // for render jsx
    setAvailabilityJson(
      availabilityJson.filter((item) => item.availableDay !== "")
    ); // for remove blank object
  };

  useEffect(() => {
    if (params.id) {
      setloading(true);
      adminService.getById(`therapists/` + params.id).then(
        (res) => {
          if (res.status === "Success") {
            let therapistAvailability = res.data.therapistAvailability;
            //setInputs(therapistAvailability)

            let input_data = {
              sun:
                therapistAvailability.filter(
                  (info) => info.availableDay === "SUNDAY"
                ).length > 0
                  ? true
                  : false,
              mon:
                therapistAvailability.filter(
                  (info) => info.availableDay === "MONDAY"
                ).length > 0
                  ? true
                  : false,
              tue:
                therapistAvailability.filter(
                  (info) => info.availableDay === "TUESDAY"
                ).length > 0
                  ? true
                  : false,
              wed:
                therapistAvailability.filter(
                  (info) => info.availableDay === "WEDNESDAY"
                ).length > 0
                  ? true
                  : false,
              thu:
                therapistAvailability.filter(
                  (info) => info.availableDay === "THURSDAY"
                ).length > 0
                  ? true
                  : false,
              fri:
                therapistAvailability.filter(
                  (info) => info.availableDay === "FRIDAY"
                ).length > 0
                  ? true
                  : false,
              sat:
                therapistAvailability.filter(
                  (info) => info.availableDay === "SATURDAY"
                ).length > 0
                  ? true
                  : false,
              timezoneId: res.data.timezone.id,
            };
            setInputs(input_data);

            let availabilityJsonOBJ = [];
            therapistAvailability.map((item, index) => {
              availabilityJsonOBJ.push({
                therapistAvailabilityId: item.id,
                availableDay: item.availableDay,
                startTime: getTime24(item.startTime),
                endTime: getTime24(item.endTime),
                differentTime: item.differentTime,
                startMeridium: getMeridium24(item.startTime),
                endMeridium: getMeridium24(item.endTime),
              });
            });

            setAvailabilityJson(availabilityJsonOBJ);
            //dispatch(allActions.adminAction.setTherapistFormData(availabilityJsonOBJ, 'therapistAvailabilityForm'));

            let all_day_json = therapistAvailability.filter(
              (item) => item.differentTime == false
            );
            if (all_day_json.length > 0) {
              // When atleast 1 normal day is check , set All day start and end time
              setTimeInputs((timeInputs) => ({
                ...timeInputs,
                startTime: getTime24(all_day_json[0].startTime),
                endTime: getTime24(all_day_json[0].endTime),
                startMeridium: getMeridium24(all_day_json[0].startTime),
                endMeridium: getMeridium24(all_day_json[0].endTime),
              }));
            } else {
              // When We have not normal time all is set time selected when edit
              setTimeInputs((timeInputs) => ({
                ...timeInputs,
                startTime: timeInputs.startTime,
                endTime: timeInputs.endTime,
                startMeridium: "am",
                endMeridium: "pm",
              }));
            }

            // setInputs(inputs => ({ ...inputs, ["therapistAvailabilityId"]: therapistAvailability.id }));
            let id = [];
            res.data.languages &&
              res.data.languages.map((item) => {
                id.push(item.id);
              });
            setLanguageIds(id);
            setloading(false);
          } else {
            toast.success(res.message);
            history("/therapist-details/" + params.id);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    adminService.getAll(`languages?size=1000`).then(
      (res) => {
        setLanguage(sortLanguage(res.data.content || []));
        if (!params.id) {
          if (therapistForm.hasOwnProperty("languageIds"))
            setLanguageIds(therapistForm.languageIds);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    let apiurl_timezone =
      currentRole === "ROLE_RCE" ||
      currentRole === "ROLE_THERAPIST" ||
      currentRole === "ROLE_REGIONAL_EARLY_STEPS"
        ? "misc/timezones"
        : "misc/timezones";
    adminService.getAll(apiurl_timezone).then(
      (res) => {
        if (res.status === "Success") {
          setTimezones(res.data);
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  }, []);

  useEffect(() => {
    setInputs(inputs);
    dispatch(
      allActions.adminAction.setTherapistFormData(
        inputs,
        "therapistAvailabilityForm"
      )
    );
  }, [inputs]);

  useEffect(() => {
    setAvailabilityJson(availabilityJson);
    dispatch(
      allActions.adminAction.setTherapistFormData(
        availabilityJson,
        "therapistAvailabilityForm"
      )
    );
  }, [availabilityJson]);

  useEffect(() => {
    setTimeInputs(timeInputs);
    let startTime24 = moment(
      timeInputs.startTime + " " + timeInputs.startMeridium,
      ["h:mm A"]
    ).format("HH:mm:ss");
    let endTime24 = moment(timeInputs.endTime + " " + timeInputs.endMeridium, [
      "h:mm A",
    ]).format("HH:mm:ss");
    setInputs((inputs) => ({ ...inputs, ["selectedStartTime"]: startTime24 }));
    setInputs((inputs) => ({ ...inputs, ["selectedEndTime"]: endTime24 }));
    setInputs((inputs) => ({
      ...inputs,
      ["startTime"]: getTimeInUTC(startTime24),
    }));
    setInputs((inputs) => ({
      ...inputs,
      ["endTime"]: getTimeInUTC(endTime24),
    }));
  }, [timeInputs]);

  // For sorting the day
  availabilityJson.sort(function sortByDay(a, b) {
    let day1 = a.availableDay.toLowerCase();
    let day2 = b.availableDay.toLowerCase();
    return daySorter[day1] - daySorter[day2];
  });

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addtheripist">
            <label className="conetent-title">
              {params.id ? "Edit" : "Set"} Availability
            </label>
            <div className="row gutter-33">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label className="mb-3">Working Days</label> (
                  <span className="required-span">*</span>)
                  <br />
                  <label>Click to select working day(s)</label>
                  <div className="working-days">
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.mon ? "checked" : ""}
                          id="checkbox"
                          value="MONDAY"
                          name="mon"
                          onChange={handleChange}
                        />
                        <div className="days"> Mon </div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "MONDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-1"
                          type="checkbox"
                          value="MONDAY"
                          disabled={!inputs.mon && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-1">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.tue ? "checked" : ""}
                          id="checkbox"
                          value="TUESDAY"
                          name="tue"
                          onChange={handleChange}
                        />
                        <div className="days"> Tue</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "TUESDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-2"
                          type="checkbox"
                          value="TUESDAY"
                          disabled={!inputs.tue && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-2">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.wed ? "checked" : ""}
                          id="checkbox"
                          value="WEDNESDAY"
                          name="wed"
                          onChange={handleChange}
                        />
                        <div className="days"> Wed</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "WEDNESDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-3"
                          type="checkbox"
                          value="WEDNESDAY"
                          disabled={!inputs.wed && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-3">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.thu ? "checked" : ""}
                          id="checkbox"
                          value="THURSDAY"
                          name="thu"
                          onChange={handleChange}
                        />
                        <div className="days"> Thu</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "THURSDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-4"
                          type="checkbox"
                          value="THURSDAY"
                          disabled={!inputs.thu && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-4">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.fri ? "checked" : ""}
                          id="checkbox"
                          value="FRIDAY"
                          name="fri"
                          onChange={handleChange}
                        />
                        <div className="days"> Fri</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "FRIDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-5"
                          type="checkbox"
                          value="FRIDAY"
                          disabled={!inputs.fri && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-5">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.sat ? "checked" : ""}
                          id="checkbox"
                          value="SATURDAY"
                          name="sat"
                          onChange={handleChange}
                        />
                        <div className="days"> Sat</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "SATURDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-6"
                          type="checkbox"
                          value="SATURDAY"
                          disabled={!inputs.sat && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-6">Set Time</label>
                      </div>
                    </div>
                    <div className="d-block mr-3">
                      <label
                        className="working-days-checkbox form-control"
                        for="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={inputs.sun ? "checked" : ""}
                          id="checkbox"
                          value="SUNDAY"
                          name="sun"
                          onChange={handleChange}
                        />
                        <div className="days"> Sun</div>
                      </label>
                      <div className="unstyled centered">
                        <input
                          className="styled-checkbox"
                          checked={
                            availabilityJson.filter(
                              (item) =>
                                item.availableDay === "SUNDAY" &&
                                item.differentTime === true
                            ).length > 0
                              ? "checked"
                              : ""
                          }
                          id="styled-checkbox-7"
                          type="checkbox"
                          value="SUNDAY"
                          disabled={!inputs.sun && "disabled"}
                          onChange={handleChangeSetTime}
                        />
                        <label for="styled-checkbox-7">Set Time</label>
                      </div>
                    </div>
                  </div>
                  <div className="text-info-div mt-1 mb-2">
                    <img
                      src="../resources/assets/material-info-outline.svg"
                      alt="Info icon for Precautions"
                    />
                    <span className="text-info-tips">
                      &nbsp;Check to set different time for selected day(s)
                    </span>
                  </div>
                  {/*<span className="selectd-date-text"> 
                                        (You have selected: &nbsp;
                                        {daysJson && daysJson.map((item, index) =>
                                            inputs[item.day] ? item.name + ' ' : ""
                                        )}
                                    </span>)*/}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="mb-3">Working Time</label> (
                  <span className="required-span">*</span>)
                  <div className="row gutter-33">
                    <div className="col-md-3">
                      <h6 className="mb-3">Selected Day</h6>
                    </div>
                    <div className="col-md-4">
                      <h6>Start Time</h6>
                    </div>
                    <div className="col-md-4">
                      <h6>End Time</h6>
                    </div>
                  </div>
                  {availabilityJson &&
                    availabilityJson.map(
                      (item, index) =>
                        item.availableDay !== "" &&
                        item.differentTime === true && (
                          <>
                            <div className="row gutter-33 mt-4">
                              <div className="col-md-3">
                                <strong>
                                  <span>{item.availableDay}</span>
                                </strong>
                              </div>
                              <div className="col-md-4">
                                <div className="working-time d-flex">
                                  <TimeInput
                                    onChange={(e) =>
                                      handleChangeDiffTime(
                                        e,
                                        "startTime",
                                        item.availableDay
                                      )
                                    }
                                    initialValue={item.startTime}
                                    value={item.startTime}
                                  />
                                  {/* <Datetime
                                                            // input={true}
                                                            // isValidDate={true}
                                                            // strictParsing={true}
                                                            inputProps={getTimeFormatPropsInputComp}
                                                            className="form-control-time"
                                                            renderInput={renderInput}
                                                            initialViewMode="time"
                                                            updateOnView="time"
                                                            timeFormat="hh:mm"
                                                            timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                            value={item.startTime}
                                                            dateFormat={false}
                                                            onChange={(e) => handleChangeDiffTime(e, "startTime", item.availableDay)}
                                                        /> */}

                                  <div
                                    className="btn-group btn-group-toggle set-time-section"
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={`btn ${
                                        item.startMeridium === "am" && "active"
                                      }`}
                                      onClick={() =>
                                        handleChangeDiffMeridium(
                                          "startMeridium",
                                          "am",
                                          item.availableDay
                                        )
                                      }
                                    >
                                      <input
                                        type="radio"
                                        value="am"
                                        name="startMeridium"
                                        autocomplete="off"
                                      />{" "}
                                      AM
                                    </label>

                                    <label
                                      className={`btn ${
                                        item.startMeridium === "pm" && "active"
                                      }`}
                                      onClick={() =>
                                        handleChangeDiffMeridium(
                                          "startMeridium",
                                          "pm",
                                          item.availableDay
                                        )
                                      }
                                    >
                                      <input
                                        type="radio"
                                        value="pm"
                                        name="startMeridium"
                                        autocomplete="off"
                                      />{" "}
                                      PM
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="working-time d-flex">
                                  <div className="input-group">
                                    <TimeInput
                                      onChange={(e) =>
                                        handleChangeDiffTime(
                                          e,
                                          "endTime",
                                          item.availableDay
                                        )
                                      }
                                      initialValue={item.endTime}
                                      value={item.endTime}
                                    />
                                    {/* <Datetime
                                                                inputProps={getTimeFormatPropsInputComp}
                                                                className="form-control-time"
                                                                renderInput={renderInput}
                                                                initialViewMode="time"
                                                                updateOnView="time"
                                                                timeFormat="hh:mm"
                                                                timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                                value={item.endTime}
                                                                dateFormat={false}
                                                                // inputProps={{name:"endTime"}}
                                                                onChange={(e) => handleChangeDiffTime(e, "endTime", item.availableDay)}
                                                            /> */}
                                  </div>
                                  <div
                                    className="btn-group btn-group-toggle set-time-section"
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={`btn ${
                                        item.endMeridium === "am" && "active"
                                      }`}
                                      onClick={() =>
                                        handleChangeDiffMeridium(
                                          "endMeridium",
                                          "am",
                                          item.availableDay
                                        )
                                      }
                                    >
                                      <input
                                        type="radio"
                                        value="am"
                                        name="endMeridium"
                                        autocomplete="off"
                                      />{" "}
                                      AM
                                    </label>

                                    <label
                                      className={`btn ${
                                        item.endMeridium === "pm" && "active"
                                      }`}
                                      onClick={() =>
                                        handleChangeDiffMeridium(
                                          "endMeridium",
                                          "pm",
                                          item.availableDay
                                        )
                                      }
                                    >
                                      <input
                                        type="radio"
                                        value="pm"
                                        name="endMeridium"
                                        autocomplete="off"
                                      />{" "}
                                      PM
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-md-10 border-top mt-4 mb-4"
                              key={index}
                            ></div>
                          </>
                        )
                    )}
                  <div className="row gutter-33">
                    <div className="col-md-3">
                      {availabilityJson.filter(
                        (item) => item.differentTime === false
                      ).length > 0 && (
                        <strong>
                          <span>
                            {availabilityJson.filter(
                              (item) => item.differentTime === true
                            ).length == 0
                              ? "All"
                              : "Other"}{" "}
                            Selected Day(s)
                          </span>
                        </strong>
                      )}
                    </div>

                    <div className="col-md-4">
                      {availabilityJson.filter(
                        (item) => item.differentTime === false
                      ).length > 0 && (
                        <div className="working-time d-flex">
                          <TimeInput
                            onChange={(e) => handleChangeTime(e, "startTime")}
                            initialValue={timeInputs.startTime}
                            value={timeInputs.startTime}
                          />
                          {/* <Datetime
                                                        inputProps={getTimeFormatPropsInputComp}
                                                        className="form-control-time"
                                                        renderInput={renderInput}
                                                        initialViewMode="time"
                                                        updateOnView="time"
                                                        timeFormat="hh:mm"
                                                        timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                        value={timeInputs.startTime}
                                                        dateFormat={false}
                                                        onChange={(e) => handleChangeTime(e, "startTime")}
                                                    /> */}

                          <div
                            className="btn-group btn-group-toggle set-time-section"
                            data-toggle="buttons"
                          >
                            <label
                              className={`btn ${
                                timeInputs.startMeridium === "am" && "active"
                              }`}
                              onClick={() =>
                                handleChangeMeridium("startMeridium", "am")
                              }
                            >
                              <input
                                type="radio"
                                value="am"
                                name="startMeridium"
                                autocomplete="off"
                              />{" "}
                              AM
                            </label>

                            <label
                              className={`btn ${
                                timeInputs.startMeridium === "pm" && "active"
                              }`}
                              onClick={() =>
                                handleChangeMeridium("startMeridium", "pm")
                              }
                            >
                              <input
                                type="radio"
                                value="pm"
                                name="startMeridium"
                                autocomplete="off"
                              />{" "}
                              PM
                            </label>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      {availabilityJson.filter(
                        (item) => item.differentTime === false
                      ).length > 0 && (
                        <div className="working-time d-flex">
                          <div className="input-group">
                            <TimeInput
                              onChange={(e) => handleChangeTime(e, "endTime")}
                              initialValue={timeInputs.endTime}
                              value={timeInputs.endTime}
                            />
                            {/* <Datetime
                                                        inputProps={getTimeFormatPropsInputComp}
                                                        className="form-control-time"
                                                        renderInput={renderInput}
                                                        initialViewMode="time"
                                                        updateOnView="time"
                                                        timeFormat="hh:mm"
                                                        timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                        value={timeInputs.endTime}
                                                        dateFormat={false}
                                                        // inputProps={{name:"endTime"}}
                                                        onChange={(e) => handleChangeTime(e, "endTime")}
                                                    /> */}
                            {/* <input type="time" value={dateInputs.endTime} onChange={handleChange} name="endTime" aria-label="Hours" className="form-control" /> */}
                            {/* <input type="text" aria-label="Minutes" className="form-control" /> */}
                          </div>
                          <div
                            className="btn-group btn-group-toggle set-time-section"
                            data-toggle="buttons"
                          >
                            <label
                              className={`btn ${
                                timeInputs.endMeridium === "am" && "active"
                              }`}
                              onClick={() =>
                                handleChangeMeridium("endMeridium", "am")
                              }
                            >
                              <input
                                type="radio"
                                value="am"
                                name="endMeridium"
                                autocomplete="off"
                              />{" "}
                              AM
                            </label>

                            <label
                              className={`btn ${
                                timeInputs.endMeridium === "pm" && "active"
                              }`}
                              onClick={() =>
                                handleChangeMeridium("endMeridium", "pm")
                              }
                            >
                              <input
                                type="radio"
                                value="pm"
                                name="endMeridium"
                                autocomplete="off"
                              />{" "}
                              PM
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="mb-3">Timezone</label> (
                  <span className="required-span">*</span>)
                  <div className="col-md-3 m-0 p-0">
                    <select
                      className={`custom-select`}
                      onChange={handleChangeTimezone}
                      value={inputs.timezoneId}
                      defaultValue={inputs.timezoneId}
                      name="timezoneId"
                    >
                      <option value="">Select Timezone</option>
                      {timezones &&
                        timezones.map((info, index) => (
                          <>
                            <option value={info.id}>{`${info.code} -  ${(
                              info.timezone || ""
                            ).replaceAll("_", " ")}`}</option>
                          </>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Languages</label> (
                  <span className="required-span">*</span>)
                  <div className="input-group ">
                    {/* <input type="text" list="lang" className="form-control" placeholder="Select Language" onChange={handleChange} value={inputs.tempselectLangId} name="tempselectLangId"/>
                                            <datalist id="lang">
                                            <option>Select Language</option>
                                            {language && language.map((item, index) =>
                                                <option value={item.id}>{item.languageName}</option>
                                            )}
                                        </datalist> */}

                    <select
                      className="custom-select"
                      onChange={handleChange}
                      value={langClear.tempselectLangId}
                      //  defaultValue={inputs.tempselectLangId}
                      name="tempselectLangId"
                    >
                      <option value="0">Select Language</option>
                      {language &&
                        language.map((item, index) => (
                          <option value={item.id}>{item.languageName}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-5 align-self-end">
                <div className="form-group">
                  <button
                    onClick={() => addLanguage()}
                    className="btn  btn-primary add-button cursor-pointer"
                    data-toggle="button"
                    aria-pressed="false"
                    autocomplete="off"
                    disabled={langClear?.tempselectLangId === 0 ? true : false}
                  >
                    {/* <img src='../../resources/assets/Plus-Button-Icon-Round.svg' /> */}
                    <img
                      className="pr-2"
                      src="../resources/assets/plus-icon-button.svg"
                      alt=""
                    />
                    Add Language
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <table className="table custom-table">
                  <tbody>
                    {languageIds &&
                      languageIds.map((lang, index) => (
                        <tr className="bg-common-gray">
                          <td className="border-top-0 lang">
                            {getLanguageName(lang)}
                          </td>
                          {/* <td className="border-top-0 cursor-pointer text-right" onClick={()=>removeLanguage(lang)}><img src="../resources/assets/action-icon-delete.svg"/></td> */}
                          <td className="border-top-0  text-right">
                            <img
                              src="../resources/assets/action-icon-delete.svg"
                              className="cursor-pointer"
                              onClick={() => removeLanguage(lang)}
                            />
                          </td>
                        </tr>
                      ))}
                    {languageIds.length == 0 && (
                      <tr className="text-center">
                        <td colSpan="2">
                          <strong>No data found</strong>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row addtheripist">
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12 d-flex">
              {params.id ? (
                <button
                  className="btn  btn-primary d-block stepper-button-next"
                  type="submit"
                >
                  Submit
                </button>
              ) : (
                <>
                  <button
                    className="btn  btn-primary d-block stepper-button-back"
                    onClick={(e) => props.handleBack()}
                  >
                    Back
                  </button>
                  <button
                    className="btn  btn-primary d-block stepper-button-next"
                    type="submit"
                  >
                    Finish
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default TherapistSetAvailability;
