const initialTenantInfo = {
  largeLogoUrl: null,
  primaryColor: null,
  secondaryColor: null,
  smallLogoUrl: null,
  tenantId: null,
  tenantName: null,
  tenantUrl: null,
  tenantPemission: null,
};

const initialState = {
  loggedIn: false,
  profile: {},
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : [],
  therapistForm: localStorage.getItem("therapistForm")
    ? JSON.parse(localStorage.getItem("therapistForm"))
    : {},
  familyForm: localStorage.getItem("familyForm")
    ? JSON.parse(localStorage.getItem("familyForm"))
    : {},
  interpreterForm: localStorage.getItem("interpreterForm")
    ? JSON.parse(localStorage.getItem("interpreterForm"))
    : {},
  tenantInfo:
    localStorage.getItem("tenantInfo") &&
      localStorage.getItem("tenantInfo") !== "null" &&
      localStorage.getItem("tenantInfo") !== "undefined"
      ? JSON.parse(localStorage.getItem("tenantInfo")) || initialTenantInfo
      : initialTenantInfo,
};

const adminUser = (state = initialState, action) => {
  let therapistForm = state.therapistForm;
  let familyForm = state.familyForm;
  let interpreterForm = state.interpreterForm;

  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
      };
    case "SET_PROFILE_DATA":
      return {
        ...state,
        profile: action.payload,
      };
    case "LogInUserDetail":
      return {
        ...state,
        userData: action.payload,
      };
    case "StripeAccount":
      return {
        ...state,
        accountData: action.payload,
      };
    case "BillingInfo":
      return {
        ...state,
        billingData: action.payload,
      };
    case "LOGOUT":
      localStorage.removeItem("therapistForm");
      localStorage.removeItem("familyForm");
      localStorage.removeItem("interpreterForm");
      localStorage.removeItem("user");
      return {
        ...state,
        user: [],
        loggedIn: false,
        therapistForm: [],
        familyForm: [],
        interpreterForm: [],
        profile: {},
      };
    case "SET_THERAPIST_FORMDATA":
      let therapistFormObj = therapistForm.length == 0 ? {} : therapistForm;
      therapistFormObj[action.formKey] = action.payload; // Concat
      localStorage.setItem("therapistForm", JSON.stringify(therapistFormObj));
      return {
        ...state,
        therapistForm: therapistFormObj,
      };
    case "SET_FAMILY_FORMDATA":
      let familyFormObj = familyForm.length == 0 ? {} : familyForm;
      familyFormObj[action.formKey] = action.payload; // Concat
      localStorage.setItem("familyForm", JSON.stringify(familyFormObj));
      return {
        ...state,
        familyForm: familyFormObj,
      };
    case "SET_INTERPRETER_FORMDATA":
      let interpreterFormObj =
        interpreterForm.length == 0 ? {} : interpreterForm;
      interpreterFormObj[action.formKey] = action.payload; // Concat
      localStorage.setItem(
        "interpreterForm",
        JSON.stringify(interpreterFormObj)
      );
      return {
        ...state,
        interpreterForm: interpreterFormObj,
      };

    case "SET_APP_TENANT":
      localStorage.setItem("tenantInfo", JSON.stringify(action.payload));
      return {
        ...state,
        tenantInfo: action.payload,
      };

    case "CLEAR_ONBOARD_DATA":
      localStorage.removeItem("therapistForm");
      localStorage.removeItem("familyForm");
      localStorage.removeItem("interpreterForm");
      return {
        ...state,
        therapistForm: [],
        familyForm: [],
        interpreterForm: [],
      };
    case "TENANT_PERMISSION":
      return {
        ...state,
        tenantPemission: action.payload,
      };
    default:
      return state;
  }
};

export default adminUser;
