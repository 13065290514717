export const nameRegex =
  /^[a-zA-Z\s'àèéëïĳáêíîôóúûÆØÅæøåÄÖäöBCDFGQWXZbcfgqwxzŠšŽžÕÜõüßÇÊÎŞÛçşĂÂȘȚăâșțÔŴŶÁÉÍÏŵŷÓÚẂÝÀÈÌÒÙẀỲËẄŸẃýìòùẁỳẅÿĈĜĤĴŜŬĉĝĥĵŝŭĞİğıÐÞðþŐŰőű·ŒœãÑñÃẼĨŨỸ̃ẽĩũỹĄ́ĘĮŁŃ ąęįłń’Ǫā̄ēīōǫǭū'ŲųżćśźůA,EHIJKLMNOPRSTUYŻČĆĐĎĚŇŘŤŮďěňřťĽĹŔľĺŕĀĒĢĪĶĻŅŌŖŪģķļņŗĖėẢẠẰẲẴẮẶẦẨẪẤẬẺẸỀỂỄẾỆỈỊỎỌỒỔỖỐỘƠỜỞỠỚỢỦỤƯỪỬỮỨỰỶỴđảạằẳẵắặầẩẫấậẻẹềểễếệỉịỏọồổỗốơờởỡớợủụưừửữứựỷỵĕŏo᷄uАБВГДЕЖЗИКЛМНОПРСТУФХЦЧШЙЩЬЮЯЉЊЏ]+$/;
export const charSpaRegex = /^[A-Za-zaâî??AÂÎ??\s-]*[A-Za-zaâî??AÂÎ??-][A-Za-zaâî??AÂÎ??\s-]*$/ ;
export const numCharRegex = /^[a-z0-9]+$/i;
export const numRegex = /^[0-9]+$/;
export const charRegex = /^[a-zA-Z]+$/i;

export const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const decimalRegex = /^\d+\.\d{0,5}$/;

export const alphanumericRegx = /^[A-Za-z0-9âî??AÂÎ??\s+-]*[A-Za-z0-9âî??AÂÎ??+-][A-Za-z0-9âî??AÂÎ??\s+-][a-zA-Z0-9??\s+-]*$/ ;

export const spaceRegex = /(?!^\s+$)/;

export function emailValidation(data) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(data).toLowerCase());
}
