import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import produce from "immer";
import CreateTemplate from "../admin/CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../admin/CommonComponents";
import SignDate from "../admin/SignDate";
import DatePicker from "react-multi-date-picker";
import PlanAndEvolution from "./dischargeSummery/PlanAndEvolution";
import MedicalNecessity from "./dischargeSummery/MedicalNecessity";
import OtherDescription from "./dischargeSummery/OtherDescription";
import DischargSummryGoals from "./dischargeSummery/DischargSummryGoals";
import SimpleInfo from "./dischargeSummery/SimpleInfo";

function DischargeSummery(props) {
  const profile = useSelector((state) => state.adminUser.profile);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const formRef = React.useRef(null);
  const sigCanvas = useRef({});
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [familyInfo, setFamilyInfo] = useState({});
  const [prevLongGoals, setprevLongGoals] = useState([
    { name: "", progress: 0 },
  ]);
  const [prevShortGoals, setprevShortGoals] = useState([
    { name: "", progress: 0 },
  ]);

  const [tableValues, setTableValues] = useState([
    { id: "", description: "", criteria: "" },
  ]);
  const [newValues, setNewValues] = useState([
    { id: "", description: "", criteria: "" },
  ]);
  const [newLongGoals, setnewLongGoals] = useState([{ name: "", progress: 0 }]);
  const [newShotGoals, setnewShotGoals] = useState([{ name: "", progress: 0 }]);

  const [medicalNecessaryCriteria, setMedicalNecessaryCriteria] = useState([]);
  const [dischargeSummary, setDischargeSummary] = useState({});
  const [recipRadio, setRecipRadio] = useState('');
  const [isSubmit, setIsSubmit] = useState(
    typeof props?.data?.reply === "undefined" ? false : true
  );
  useEffect(() => {
    setDischargeSummary({
      step1: {
        therapyType: props?.selectedFamily?.familyMemberTherapies
          ? props?.selectedFamily?.familyMemberTherapies[0]?.therapy?.name
          : "",
        patientName:
          props?.selectedFamily?.firstName +
          " " +
          props?.selectedFamily?.lastName,
        chronologicalAge: props?.selectedFamily?.age,
        insuranceId: "",
        dob: props?.selectedFamily?.dob
          ? moment(props?.selectedFamily?.dob).format("YYYY-MM-DD") +
          `T00:00:00.000Z`
          : "",
        therapistName: profile?.firstName
          ? profile.firstName + " " + profile.lastName
          : "",
        therapistNpi: "",
        placeOfService: "",
        drNameNpi: props?.selectedFamily?.physicianNpiNumber,
        diagnosisICD10: props?.selectedFamily?.familyMemberTherapies
          ? props?.selectedFamily?.familyMemberTherapies[0]?.diagnosis[0]?.code
          : "",
        therapyDiagnosisICD10: "",
        currentPOCFromDate: "",
        currentPOCToDate: "",
        dateOfDischarge: "",
        currentEvaluationDate: "",
        previouPOCFromDate: "",
        previouPOCToDate: "",
        previousEvaluationDate: "",
        weeklyVisit: "",
        visitLength: "",
        months: "",
        weeks: "",
      },
      step2: {
        medicalNecessityCriteria: [],
        medicalNecessityCriteriaDefault: [
          { 
            id:"1",
            description:"Necessary to protect life, to prevent significant illness or significant disability, or to alleviate severe pain.",
            criteria:""
          },
          { 
            id:"2",
            description:"Individualized, specific, and consistent with symptoms or confirmed diagnosis of the illness or injury under treatment, and not in excess of the patient's needs.",
            criteria:""
          },
          { 
            id:"3",
            description:"Consistent with generally accepted professional medical standards as determined by the Medicaid program, not experimental or investigational.",
            criteria:""
          },
          { 
            id:"4",
            description:"Reflective of the level of service that can be safely furnished, and for which no equally effective and more conservative or less costly treatment is available statewide.",
            criteria:""
          },
          { 
            id:"5",
            description:"Furnished in a manner not primarily intended for the convenience of the recipient, the recipient's caretaker, or the provider.",
            criteria:""
          }
        ],
        specificICD10: "",
        allTreatmentServices: "",
        therapyMethod: "",
        monitoringCriteria: "",
        currentMedication: "",
      },
      step3: {
        otherTreatment: "",
        equipmentRequired: "",
        specializedDiet: {
          option: "",
          description: "",
        },
        methodDemonstrating: "",
        treatment: "",
        dischargeReason: "",
        recipRadio:"",
      },
      step4: {
        previousGoals: {
          longTerm: [""],
          shortTerm: [""],
        },
        newGoals: {
          longTerm: [""],
          shortTerm: [""],
        },
        therapistName: profile?.firstName
          ? profile.firstName + " " + profile.lastName
          : "",
        therapistSign: "",
        therapistSignDate: "",
        patientName: "",
      },
    })
  }, [props?.data])
  // const [dischargeSummary, setDischargeSummary] = useState({
  //   step1: {
  //     therapyType: props?.selectedFamily?.familyMemberTherapies
  //       ? props?.selectedFamily?.familyMemberTherapies[0]?.therapy?.name
  //       : "",
  //     patientName:
  //       props?.selectedFamily?.firstName +
  //       " " +
  //       props?.selectedFamily?.lastName,
  //     chronologicalAge: "",
  //     insuranceId: "",
  //     dob: props?.selectedFamily?.dob
  //       ? moment(props?.selectedFamily?.dob).format("YYYY-MM-DD") +
  //       `T00:00:00.000Z`
  //       : "",
  //     therapistName: profile?.firstName
  //       ? profile.firstName + " " + profile.lastName
  //       : "",
  //     therapistNpi: "",
  //     placeOfService: "",
  //     drNameNpi: props?.selectedFamily?.physicianNpiNumber,
  //     diagnosisICD10: props?.selectedFamily?.familyMemberTherapies
  //       ? props?.selectedFamily?.familyMemberTherapies[0]?.diagnosis[0]?.code
  //       : "",
  //     therapyDiagnosisICD10: "",
  //     currentPOCFromDate: "",
  //     currentPOCToDate: "",
  //     dateOfDischarge: "",
  //     currentEvaluationDate: "",
  //     previouPOCFromDate: "",
  //     previouPOCToDate: "",
  //     previousEvaluationDate: "",
  //     weeklyVisit: "",
  //     visitLength: "",
  //     months: "",
  //     weeks: "",
  //   },
  //   step2: {
  //     medicalNecessityCriteria: [],

  //     specificICD10: "",
  //     allTreatmentServices: "",
  //     therapyMethod: "",
  //     monitoringCriteria: "",
  //     currentMedication: "",
  //   },
  //   step3: {
  //     otherTreatment: "",
  //     equipmentRequired: "",
  //     specializedDiet: {
  //       option: "",
  //       description: "",
  //     },
  //     methodDemonstrating: "",
  //     treatment: "",
  //     dischargeReason: "",
  //   },
  //   step4: {
  //     previousGoals: {
  //       longTerm: [""],
  //       shortTerm: [""],
  //     },
  //     newGoals: {
  //       longTerm: [""],
  //       shortTerm: [""],
  //     },
  //     therapistName: profile?.firstName
  //       ? profile.firstName + " " + profile.lastName
  //       : "",
  //     therapistSign: "",
  //     therapistSignDate: "",
  //     patientName: "",
  //   },
  // });

  const [pageResult, setpageResult] = useState({
    adminSign: "",
    adminSignDate: "",
    reply: true,
    replyNeeded: false,
    sentBy: props.inputs?.sentBy ? props.inputs.sentBy : "",
    sentTo: props.inputs?.sentTo ? props.inputs.sentTo : "",
    therapistId: profile?.therapistId ? profile.therapistId : null,
    witnessSign: "",
    witnessSignDate: "",
    consent1: true,
    consent2: true,
    consent3: true,
    consent4: true,
    consent5: true,
    formId: props.inputs?.formId ? props.inputs.formId : null,
    familyId: props?.inputs?.familyId ? props?.inputs?.familyId : null,
    familyMemberId: props?.selectedFamily?.id
      ? props?.selectedFamily?.id
      : null,
    familyMemberName: "",
    familySign: "",
    familySignDate: "",
    interpreterId: null,
    interpreterSign: "",
    interpreterSignDate: "",
  });
  
  const patientName = dischargeSummary?.step1?.patientName;
  const therapyType = dischargeSummary?.step1?.therapyType;
  const therapistName = dischargeSummary?.step1?.therapistName;
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const radioVal = (e) => {
    console.log(e.target.value);
    setRecipRadio(e.target.value);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step3["recipRadio"] =
        e.target.value
      })
    );
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  //  const getValue= JSON.parse(props.data.jsonFormattedForm)
  //     const {dischargeSummary: relValue} = getValue
  const updateInfo = (e) => {
    const { name, value } = e.target;
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1[name] = value;
      })
    );
  };

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setpageResult({
      ...pageResult,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4["therapistSignDate"] = moment(currentDateTime)
          .local()
          .format("MM-DD-YYYY hh:mm a");
      })
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setpageResult({
              ...pageResult,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setDischargeSummary(
              produce((draftState) => {
                draftState.step4["therapistSignDate"] = moment(currentDateTime)
                  .local()
                  .format("MM-DD-YYYY hh:mm a");
                draftState.step4["therapistSign"] = res.data.signatureKey;
              })
            );
            // setFamilyImageURL("");
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "DischargeSummary.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };

  const lastInfo = (e) => {
    const { name, value } = e.target;
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4[name] = value;
      })
    );
  };
  const updatesInfo = (e) => {
    const { name, value } = e.target;
    setDischargeSummary(
      produce((draftState) => {
        draftState.step3[name] = value;
      })
    );
  };
  const startInfo = (e) => {
    const { name, value } = e.target;
    setDischargeSummary(
      produce((draftState) => {
        draftState.step2[name] = value;
      })
    );
  };

  const specializedInfo = (e) => {
    const { name, value } = e.target;
    setDischargeSummary(
      produce((draftState) => {
        draftState.step3.specializedDiet[name] = value;
      })
    );
  };
  const careFrom = (e) => {
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1["currentPOCFromDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const careTo = (e) => {
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1["currentPOCToDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const previouFrom = (e) => {
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1["previouPOCFromDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const previouTo = (e) => {
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1["previouPOCToDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const dischar = (e) => {
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1["dateOfDischarge"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const evalFrom = (e) => {
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1["currentEvaluationDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const evalTo = (e) => {
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1["previousEvaluationDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const changeInfo = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step1[name] = value;
      })
    );
  };
  // console.log("props.data", props.data.familyMember);
  const updateMedicalNecessaryCriteriaInfo = (e, indx) => {
    const lst = [...medicalNecessaryCriteria];
    lst[indx] = e.target.value;
    setMedicalNecessaryCriteria(lst);
  };
  const deleteRow = (i) => {
    //var row = document.getElementById(criteriaOt);
    //row.parentNode.removeChild(row);
    //document.getElementById("otLongPrev" + i).value = '';
    const arr = [...dischargeSummary.step2.medicalNecessityCriteriaDefault];
    arr.splice(i,1);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteriaDefault = arr;
      })
    );
  }
  const removePrevLongGoals = (i) => {
    const arr = [...prevLongGoals];
    arr.splice(i, 1);
    document.getElementById("longName" + i).value = '';
    document.getElementById("longPer" + i).value = '';
    setprevLongGoals(arr);
  };
  const removePrevShortGoals = (i) => {
    const arr = [...prevShortGoals];
    arr.splice(i, 1);
    document.getElementById("shortName" + i).value = '';
    document.getElementById("shortPer" + i).value = '';
    setprevShortGoals(arr);
  };
  const removeNewLongGoals = (i, e) => {
    const arr = [...newLongGoals];
    arr.splice(i, 1);
    document.getElementById("longGoalName" + i).value = '';
    document.getElementById("longGoalPer" + i).value = '';
    setnewLongGoals(arr);
  };
  const removeNewShotGoals = (i) => {
    const arr = [...newShotGoals];
    arr.splice(i, 1);
    document.getElementById("shortGoalName" + i).value = '';
    document.getElementById("shortGoalPer" + i).value = '';
    setnewShotGoals(arr);
  };
  const removeTableFields = (i) => {
    const arr = [...tableValues];
    arr.splice(i, 1);
    //let inputId = "textDes"+i;
    document.getElementById("decs" + i).value = '';
    document.getElementById("criteria" + i).value = '';
    setTableValues(arr);
  };
  const handlePrevLongGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr1 = [...prevLongGoals];
    newArr1[i] = {
      ...newArr1[i],
      [name]: value,
    };
    setprevLongGoals(newArr1);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4.previousGoals.longTerm = newArr1;
      })
    );
  };

  const handlePrevShortGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...prevShortGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setprevShortGoals(newArr);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4.previousGoals.shortTerm = newArr;
      })
    );
  };
  const handleNewLongGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...newLongGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setnewLongGoals(newArr);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4.newGoals.longTerm = newArr;
      })
    );
  };
  const handleNewShortGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...newShotGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setnewShotGoals(newArr);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4.newGoals.shortTerm = newArr;
      })
    );
  };
  const fillCriteria=(e,i)=>{
    console.log("fillCriteria",e.target.name,e.target.value);
    const { name, value } = e.target;
    let newArr = [...dischargeSummary.step2.medicalNecessityCriteriaDefault];
    let val = [
      "Necessary to protect life, to prevent significant illness or significant disability, or to alleviate severe pain.",
      "Individualized, specific, and consistent with symptoms or confirmed diagnosis of the illness or injury under treatment, and not in excess of the patients needs.",
      "Consistent with generally accepted professional medical standards as determined by the Medicaid program, not experimental or investigational.",
      "Reflective of the level of service that can be safely furnished, and for which no equally effective and more conservative or less costly treatment is available statewide.	",
      "Furnished in a manner not primarily intended for the convenience of the recipient, the recipient's caretaker, or the provider.",
    ];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      ["description"]: val[i],
      [name]: value,
    };
    //setTableValues(newArr);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteriaDefault = newArr;
      })
    );
  }
  const handlesimpleFields = (e, i, desc = "") => {
    const { name, value } = e.target;
    let newArr = [...newValues];
    let diffArr = [...tableValues];
    let val = [
      "Necessary to protect life, to prevent significant illness or significant disability, or to alleviate severe pain.",
      "Individualized, specific, and consistent with symptoms or confirmed diagnosis of the illness or injury under treatment, and not in excess of the patients needs.",
      "Consistent with generally accepted professional medical standards as determined by the Medicaid program, not experimental or investigational.",
      "Reflective of the level of service that can be safely furnished, and for which no equally effective and more conservative or less costly treatment is available statewide.	",
      "Furnished in a manner not primarily intended for the convenience of the recipient, the recipient's caretaker, or the provider.",
    ];
    if (desc) {
      diffArr[i] = {
        ...diffArr[i],
        ["id"]: i + 1,
        [name]: value,
      };
    } else {
      newArr[i] = {
        ...newArr[i],
        ["description"]: val[i],
        ["id"]: i + 1,
        [name]: value,
      };
    }

    setTableValues(diffArr);
    setNewValues(newArr);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteria = [...newArr, ...diffArr];
      })
    );
  };

  const handleTableFields = (e, i) => {
    const { name, value } = e.target;
    let newArr = [...tableValues];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      [name]: value,
    };

    setTableValues(newArr);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteria = [...newArr]
      })
    );
  };

  const addPrevLongGoals = () => {
    setprevLongGoals([...prevLongGoals, { name: "", progress: 0 }]);
  };
  const addPrevShortGoals = () => {
    setprevShortGoals([...prevShortGoals, { name: "", progress: 0 }]);
  };
  const addTableFields = () => {
    setTableValues([...tableValues, { description: "", criteria: "" }]);
  };
  const addNewLongGoals = () => {
    setnewLongGoals([...newLongGoals, { name: "", progress: 0 }]);
  };
  const addNewShotGoals = () => {
    setnewShotGoals([...newShotGoals, { name: "", progress: 0 }]);
  };

  const onSubmitFamilyForm = () => {
    setIsLoading(true);
    setDischargeSummary(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteria = [
          ...newValues,
          ...tableValues,
        ];
      })
    );
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4.previousGoals.shortTerm = [prevShortGoals];
      })
    );
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4.previousGoals.longTerm = [prevLongGoals];
      })
    );
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4.newGoals.shortTerm = [newShotGoals];
      })
    );
    setDischargeSummary(
      produce((draftState) => {
        draftState.step4.newGoals.longTerm = [newLongGoals];
      })
    );
    console.log("........................discharge", dischargeSummary);
    let formData = new FormData();
    console.log();
    formData.append(
      "formCreationForm",
      new Blob(
        [
          JSON.stringify({
            ...pageResult,
            jsonFormattedForm: JSON.stringify({ dischargeSummary }),
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((response) => {
        setIsLoading(false);
        setOpen(false);
        toast.success("Form Submitted successfully");
        props.handleBack("reload");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };
  // const deleteRow = (rowid) => {
  //   var row = document.getElementById(rowid);
  //   row.parentNode.removeChild(row);
  // }
  const relValue =
    typeof props?.data?.reply === "undefined"
      ? ""
      : JSON.parse(props?.data?.jsonFormattedForm);

  return (
    <>
      <>
        <div
          className={`col-md-${props?.from ? 12 : 10}`}
          id="my-code"
          ref={formRef}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: "100px",
            }}
          >
            {!props?.data?.replyNeeded && props?.data?.id ? (
              <>
                <HeaderDownloadButtons
                  isSubmit={true}
                  startDownload={startDownload}
                  setIsSubmit={setIsSubmit}
                />
              </>
            ) : (
              props?.data?.therapistSignDate && (
                <HeaderDownloadButtons
                  isSubmit={isSubmit}
                  startDownload={startDownload}
                  setIsSubmit={setIsSubmit}
                />
              )
            )}
          </div>
          <div className="card-lg">
            <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
              <FormHeader logoUrl={logoUrl} title="DISCHARGE SUMMARY" />
              <div style={{ textAlign: "center" }}>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step1?.therapyType
                ) : (
                  <input
                    type="text"
                    placeholder="therapy type goes here"
                    name="therapyType"
                    style={{ textAlign: "center" }}
                    className={`form-control form-input form-input-w-37per`}
                    defaultValue={therapyType}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
              </div>
              <hr />
              <br />

              <div className="card-lg__content">
                <SimpleInfo
                  isSubmit={isSubmit}
                  dischargeSummary={dischargeSummary}
                  setDischargeSummary={setDischargeSummary}
                  changeInfo={changeInfo}
                  relValue={relValue}
                />
                <br />
                <PlanAndEvolution
                  isSubmit={isSubmit}
                  dischargeSummary={dischargeSummary}
                  careFrom={careFrom}
                  careTo={careTo}
                  evalFrom={evalFrom}
                  evalTo={evalTo}
                  previouFrom={previouFrom}
                  previouTo={previouTo}
                  changeInfo={changeInfo}
                  relValue={relValue}
                  dischar={dischar}
                />
                <br />
                {/* <MedicalNecessity
                  relValue={relValue}
                  isSubmit={isSubmit}
                  handlesimpleFields={handlesimpleFields}
                  removeTableFields={removeTableFields}
                  addTableFields={addTableFields}
                  // handleTableFields={handleTableFields}
                  tableValues={tableValues}
                /> */}
                <div>
                  <table className="w-100per" style={{ border: "1px solid #000",borderBottom:"0", }}>
                    <>

                      <tr>
                        <th style={{ width: "80%",borderRight: "1px solid",borderBottom:"1px solid", }}>MEDICAL NECESSITY CRITERIA: {isSubmit ? (<></>) : (<button
                          type="button"
                          className="btn btn-success"
                          style={{
                            width: "45px",
                            height: "28px",
                            padding: "0px",
                            margin: "10px",
                          }}
                          onClick={() => addTableFields()}
                        >
                          +
                        </button>)}</th>
                        <th style={{ textAlign: "center",borderRight: "1px solid",borderBottom:"1px solid", }}>YES/NO</th>
                        <th style={{ textAlign: "center",borderBottom:"1px solid", }}></th>
                      </tr>
                      {isSubmit ? (
                        <>

                          {relValue?.dischargeSummary?.step2?.medicalNecessityCriteriaDefault.map(
                            (element, indexs) => (
                              <tr style={{ border: "1px solid" }}>
                                <td
                                  style={{
                                    width: "90%",
                                    borderRight: "1px solid",
                                    borderBottom:"1px solid",
                                    paddingLeft: 5,
                                  }}
                                >
                                  {
                                    relValue?.dischargeSummary?.step2?.medicalNecessityCriteriaDefault[indexs].description
                                  }
                                </td>
                                <td style={{ textAlign: "center", borderBottom:"1px solid", }}>
                                  {
                                    relValue?.dischargeSummary?.step2?.medicalNecessityCriteriaDefault[indexs].criteria
                                  }
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      ) : (
                        <>
                      {dischargeSummary?.step2?.medicalNecessityCriteriaDefault.map(
                            (element, indexs) => (
                        <tr style={{ border: "1px solid", borderBottom: "none" }} key={indexs}>
                          <td
                            style={{
                              width: "90%",
                              borderRight: "1px solid",
                              paddingLeft: 5,
                              borderBottom:"1px solid"
                            }}
                          >
                            {element.description}
                          </td>
                          <td style={{ textAlign: "center", borderRight: "1px solid",borderBottom:"1px solid" }}>

                            <input
                              //   ref={register({ required: true })}
                              id={"criteriaOt"+indexs}
                              type="text"
                              name="criteria"
                              value={element.criteria}
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => fillCriteria(e, indexs)}
                            />
                          </td>
                          <td style={{borderBottom:"1px solid"}}><button className="btn btn-sm btn-primary " type="button"
                            style={{
                              width: "40px",
                              height: "20px",
                              padding: "0px",
                              backgroundColor: "red",
                              marginRight: '0'
                            }} onClick={() => deleteRow(indexs)}><b>-</b></button></td>
                        </tr>
                        ))}
                        </>
                        )}
                      {isSubmit ? (
                        <>

                          {relValue?.dischargeSummary?.step2?.medicalNecessityCriteria.map(
                            (element, indexs) => (
                              <tr style={{ border: "1px solid" }}>
                                <td
                                  style={{
                                    width: "90%",
                                    borderRight: "1px solid",
                                    borderBottom:"1px solid",
                                    paddingLeft: 5,
                                  }}
                                >
                                  {
                                    relValue?.dischargeSummary?.step2
                                      ?.medicalNecessityCriteria[indexs]
                                      ?.description
                                  }

                                </td>
                                <td style={{ textAlign: "center", borderBottom: "1px solid", }}>
                                  {
                                    relValue?.dischargeSummary?.step2
                                      ?.medicalNecessityCriteria[indexs]
                                      ?.criteria
                                  }
                                </td>
                                <td style={{ textAlign: "center", borderBottom: "1px solid" }}></td>

                              </tr>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {tableValues.map((element, indexs) => (
                            <>
                              <tr style={{ border: "1px solid" }}>
                                <td
                                  style={{
                                    width: "90%",
                                    borderRight: "1px solid",
                                    borderBottom:"1px solid",
                                    paddingLeft: 5,
                                  }}
                                >
                                  <input
                                    // ref={register({ required: true })}
                                    id={"decs" + indexs}
                                    type="text"
                                    placeholder=""
                                    name="description"
                                    className={`form-control form-input form-input-w-60per`}
                                    value={element.description}
                                    onChange={(e) => handlesimpleFields(e, indexs, "desc")}
                                  />
                                </td>
                                <td style={{ textAlign: "center", borderRight: "1px solid",borderBottom:"1px solid" }}>
                                  <input
                                    //   ref={register({ required: true })}
                                    id={"criteria" + indexs}
                                    type="text"
                                    name="criteria"
                                    className={`form-control form-input form-input-w-120px`}
                                    value={element.criteria}
                                    onChange={(e) => handlesimpleFields(e, indexs, "desc")}
                                  />
                                </td>
                                <td style={{borderBottom:"1px solid"}}>
                                  {isSubmit ? (
                                    ''
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary "
                                      style={{
                                        width: "40px",
                                        height: "20px",
                                        padding: "0px",
                                        backgroundColor: "red",
                                        marginRight: '0'
                                      }}
                                      onClick={() => removeTableFields(indexs)}
                                    >
                                      <b>-</b>
                                    </button>
                                  )}
                                </td>
                              </tr>{" "}
                            </>
                          ))}
                        </>
                      )}
                    </>
                  </table>
                </div>
                <br />
                <p className="w-100per">
                  <b>
                    DESCRIPTION OF CONDITION, INCLUDING MOST SPECIFIC ICD-10 CODES:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step2?.specificICD10
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="specificICD10"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>ALL TREATMENT SERVICES TO BE PROVIDED TO RECIPIENT:</b>
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step2?.allTreatmentServices
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="allTreatmentServices"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>THERAPY METHODS, e.g., stretching, strengthening, language therapy, developmental sequencing,
                    etc.:</b>
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step2?.therapyMethod
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="therapyMethod"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />

                <br />
                <p className="w-100per">
                  <b>MONITORING CRITERIA, e.g., standardized tests, timed tests, observation, family or caregiver
                    reporting, etc.:</b>
                </p>

                {isSubmit ? (
                  relValue?.dischargeSummary?.step2?.monitoringCriteria
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="monitoringCriteria"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>CURRENT MEDICATIONS:</b>
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step2?.currentMedication
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="currentMedication"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b> OTHER TREATMENTS THE RECIPIENT IS RECEIVING:</b>
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step3?.otherTreatment
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="otherTreatment"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updatesInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>EQUIPMENT REQUIRED:</b>
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step3?.equipmentRequired
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="equipmentRequired"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updatesInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  {isSubmit ? (<b>
                      IS THE RECIPIENT ON A SPECIALIZED DIET?{relValue?.dischargeSummary?.step3?.recipRadio==='Yes' || relValue?.dischargeSummary?.step3?.recipRadio==='No' ? <span> : &nbsp;Yes{" "}
                      <input
                        id="recipYes"
                        type="radio"
                        value="Yes"
                        name="yes_no"
                        defaultValue={""}
                        checked={relValue?.dischargeSummary?.step3?.recipRadio==='Yes'}
                        onChange={(e) => radioVal(e)}
                        disabled={relValue?.dischargeSummary?.step3?.recipRadio==='No' ? true : true}
                      />{" "}
                      No{" "}
                      <input
                        type="radio"
                        value="No"
                        name="yes_no"
                        defaultValue={""}
                        checked={relValue?.dischargeSummary?.step3?.recipRadio==='No'}
                        onChange={(e) => radioVal(e)}
                        disabled={relValue?.dischargeSummary?.step3?.recipRadio==='Yes' ? true : true}
                      />{" "}</span>:''}
                    </b>) : (
                    <b>
                      IS THE RECIPIENT ON A SPECIALIZED DIET? : &nbsp;Yes{" "}
                      <input
                        id="recipYes"
                        type="radio"
                        value="Yes"
                        name="yes_no"
                        defaultValue={""}
                        onChange={(e) => radioVal(e)}
                      />{" "}
                      No{" "}
                      <input
                        type="radio"
                        value="No"
                        name="yes_no"
                        defaultValue={""}
                        onChange={(e) => radioVal(e)}
                      />{" "}
                    </b>
                  )}
                </p>

                {recipRadio === 'Yes' ?
                  <p className="w-100per">
                    <b>If yes, please specify:</b>
                  </p>
                  : ''}

                {isSubmit ? (
                  relValue?.dischargeSummary?.step3?.specializedDiet?.description
                ) : (
                  <>
                    {recipRadio === 'Yes' ?
                      <input
                        // ref={register({ required: true })}
                        type="text"
                        name="description"
                        className={`form-control form-input`}
                        defaultValue={""}
                        onChange={(e) => specializedInfo(e)}
                      />
                      : ''}</>
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>
                    METHODS OF DEMONSTRATING AND TEACHING THE FAMILY AND OTHER
                    RELEVANT CAREGIVERS WHO ARE INVOLVED WITH THE RECIPIENT:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step3?.methodDemonstrating
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="methodDemonstrating"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updatesInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>
                    {" "}
                    HOW THE TREATMENT WILL BE COORDINATED WITH THE OTHER SERVICE
                    NEEDS PRESCRIBED FOR THE RECIPIENT:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step3?.treatment
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="treatment"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updatesInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>OTHER INFORMATION/CONCERNS (Reason For Discharge):</b>{" "}
                </p>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step3?.otherInfo
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="otherInfo"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updatesInfo(e)}
                  />
                )}
                <br />
                {/* <br />
                <OtherDescription
                  isSubmit={isSubmit}
                  dischargeSummary={dischargeSummary}
                  updatesInfo={updatesInfo}
                  startInfo={startInfo}
                  specializedInfo={specializedInfo}
                  relValue={relValue}
                />
                <br /> */}
                {/* <DischargSummryGoals
                  isSubmit={isSubmit}
                  dischargeSummary={dischargeSummary}
                  prevLongGoals={prevLongGoals}
                  handlePrevLongGoals={handlePrevLongGoals}
                  addPrevLongGoals={addPrevLongGoals}
                  removePrevLongGoals={removePrevLongGoals}
                  prevShortGoals={prevShortGoals}
                  handlePrevShortGoals={handlePrevShortGoals}
                  addPrevShortGoals={addPrevShortGoals}
                  removePrevShortGoals={removePrevShortGoals}
                  newLongGoals={newLongGoals}
                  handleNewLongGoals={handleNewLongGoals}
                  addNewLongGoals={addNewLongGoals}
                  removeNewLongGoals={removeNewLongGoals}
                  newShotGoals={newShotGoals}
                  handleNewShortGoals={handleNewShortGoals}
                  addNewShotGoals={addNewShotGoals}
                  removeNewShotGoals={removeNewShotGoals}
                  relValue={relValue}
                /> */}
                <div className="w-100per">
                  <p className="w-100per" style={{ textDecoration: "underline" }}>
                    <b>Previous Goals:</b>{" "}
                  </p>
                  {isSubmit ? (
                    <div style={{ marginLeft: "40px", marginTop: "20px" }}>
                      <b>Long Term Goals : </b>
                      {relValue?.dischargeSummary?.step4?.previousGoals?.longTerm.map(
                        (element, indexs) => (
                          <div>
                            <label>{indexs + 1}.</label>
                            {
                              relValue?.dischargeSummary?.step4?.previousGoals?.longTerm[
                                indexs
                              ].name
                            }
                            <label style={{ width: "280px", paddingLeft: "16px" }}>
                              Progress:{" "}{relValue?.dischargeSummary?.step4?.previousGoals?.longTerm[indexs].progress}{"%"}</label>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div style={{ marginLeft: "40px", marginTop: "20px" }}>
                      <b>Long Term Goals : </b>{" "}
                      <button
                        type="button"
                        style={{ margin: "10px" }}
                        className="btn btn-success "
                        style={{
                          width: "40px",
                          height: "28px",
                          padding: "0px",
                        }}
                        onClick={() => addPrevLongGoals()}
                      >
                        +
                      </button>
                      {prevLongGoals.map((element, indexs) => (
                        <div>
                          {console.log("element")}
                          <label>{indexs + 1}.</label>
                          {isSubmit ? (
                            dischargeSummary?.previousGoals?.longTerm
                          ) : (
                            <input
                              // ref={register({ required: true })}
                              id={"longName" + indexs}
                              type="text"
                              placeholder=""
                              name="name"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.name}
                              onChange={(e) => handlePrevLongGoals(indexs, e)}
                            />
                          )}
                          <label style={{ width: "280px", paddingLeft: "16px" }}>
                            Progress:
                            <input
                              // ref={register({ required: true })}
                              id={"longPer" + indexs}
                              type="number"
                              placeholder=""
                              name="progress"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.progress}
                              onChange={(e) => handlePrevLongGoals(indexs, e)}
                            />
                            {"%"}
                          </label>
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-primary "
                            style={{
                              width: "105px",
                              height: "28px",
                              padding: "0px",
                              backgroundColor: "red",
                            }}
                            onClick={(e) => { removePrevLongGoals(indexs, e) }}
                          >
                            <b>-</b>
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                  {isSubmit ? (
                    <div style={{ marginLeft: "40px", marginTop: "20px" }}>
                      <b>Short Term Goals : </b>
                      {relValue?.dischargeSummary?.step4?.previousGoals?.shortTerm.map(
                        (element, indexs) => (
                          <div>
                            <label>{indexs + 1}.</label>
                            {
                              relValue?.dischargeSummary?.step4?.previousGoals?.shortTerm[
                                indexs
                              ].name
                            }
                            <label style={{ width: "280px", paddingLeft: "16px" }}>
                              Progress:{" "}{relValue?.dischargeSummary?.step4?.previousGoals?.shortTerm[indexs].progress}{"%"}</label>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div style={{ marginLeft: "40px", marginTop: "20px" }}>
                      <b>Short Term Goals : </b>{" "}<button
                        type="button"
                        style={{ margin: "10px" }}
                        className="btn btn-success "
                        style={{
                          width: "40px",
                          height: "28px",
                          padding: "0px",
                        }}
                        onClick={() => addPrevShortGoals()}
                      >
                        +
                      </button>
                      {prevShortGoals.map((element, indexs) => (
                        <div>
                          <label>{indexs + 1}.</label>
                          {isSubmit ? (
                            dischargeSummary?.previousGoals?.shortTerm
                          ) : (
                            <input
                              // ref={register({ required: true })}
                              id={"shortName" + indexs}
                              type="text"
                              placeholder=""
                              name="name"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.name}
                              onChange={(e) => handlePrevShortGoals(indexs, e)}
                            />
                          )}
                          <label style={{ width: "280px", paddingLeft: "16px" }}>
                            Progress:{" "}
                            <input
                              // ref={register({ required: true })}
                              id={"shortPer" + indexs}
                              type="number"
                              placeholder=""
                              name="progress"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.progress}
                              onChange={(e) => handlePrevShortGoals(indexs, e)}
                            />
                            {"%"}
                          </label>
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-primary "
                            style={{
                              width: "105px",
                              height: "28px",
                              padding: "0px",
                              backgroundColor: "red",
                            }}
                            onClick={() => removePrevShortGoals(indexs)}
                          >
                            <b>-</b>
                          </button>
                        </div>
                      ))}
                    </div>)}
                  <br />
                  <br />
                  <b style={{ textDecoration: "underline" }}>New Goals:</b>
                  <br />
                  {isSubmit ? (
                    <div style={{ marginLeft: "40px", marginTop: "20px" }}>
                      <b>Long Term Goals:</b>
                      {relValue?.dischargeSummary?.step4?.newGoals?.longTerm.map(
                        (element, indexs) => (
                          <div>
                            <label>{indexs + 1}.</label>
                            {
                              relValue?.dischargeSummary?.step4?.newGoals?.longTerm[
                                indexs
                              ].name
                            }
                            <label style={{ width: "280px", paddingLeft: "16px" }}>
                              Progress:{" "}{relValue?.dischargeSummary?.step4?.newGoals?.longTerm[indexs].progress}{"%"}</label>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div style={{ marginLeft: "40px", marginTop: "20px" }}>
                      <b>Long Term Goals : </b>{" "}<button
                        type="button"
                        style={{ margin: "10px" }}
                        className="btn btn-success "
                        style={{
                          width: "40px",
                          height: "28px",
                          padding: "0px",
                        }}
                        onClick={() => addNewLongGoals()}
                      >
                        +
                      </button>
                      {newLongGoals.map((element, indexs) => (
                        <div>
                          <label>{indexs + 1}.</label>
                          {isSubmit ? (
                            dischargeSummary?.newGoals?.longTerm
                          ) : (
                            <input
                              // ref={register({ required: true })}
                              id={"longGoalName" + indexs}
                              type="text"
                              placeholder=""
                              name="name"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.name}
                              onChange={(e) => handleNewLongGoals(indexs, e)}
                            />
                          )}
                          <label style={{ width: "280px", paddingLeft: "16px" }}>
                            Progress:{" "}
                            <input
                              id={"longGoalPer" + indexs}
                              // ref={register({ required: true })}
                              type="number"
                              placeholder=""
                              name="progress"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.progress}
                              onChange={(e) => handleNewLongGoals(indexs, e)}
                            />
                            {"%"}
                          </label>
                          {
                            <button
                              type="button"
                              style={{ margin: "10px" }}
                              className="btn btn-primary "
                              style={{
                                width: "105px",
                                height: "28px",
                                padding: "0px",
                                backgroundColor: "red",
                              }}
                              onClick={(e) => removeNewLongGoals(indexs, e)}
                            >
                              <b>-</b>
                            </button>
                          }
                        </div>
                      ))}
                    </div>)}
                  {isSubmit ? (
                    <div style={{ marginLeft: "40px", marginTop: "20px" }}>
                      <b>Short Term Goals : </b>
                      {relValue?.dischargeSummary?.step4?.newGoals?.shortTerm.map(
                        (element, indexs) => (
                          <div>
                            <label>{indexs + 1}.</label>
                            {
                              relValue?.dischargeSummary?.step4?.newGoals?.shortTerm[
                                indexs
                              ].name
                            }
                            <label style={{ width: "280px", paddingLeft: "16px" }}>
                              Progress:{" "}{relValue?.dischargeSummary?.step4?.newGoals?.shortTerm[indexs].progress}{"%"}</label>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div style={{ marginLeft: "40px", marginTop: "20px" }}>
                      <b>Short Term Goals : </b>{" "}<button
                        type="button"
                        style={{ margin: "10px" }}
                        className="btn btn-success "
                        style={{
                          width: "40px",
                          height: "28px",
                          padding: "0px",
                        }}
                        onClick={() => addNewShotGoals()}
                      >
                        +
                      </button>
                      {newShotGoals.map((element, indexs) => (
                        <div>
                          <label>{indexs + 1}.</label>
                          {isSubmit ? (
                            dischargeSummary?.newGoals?.shortTerm
                          ) : (
                            <input
                              // ref={register({ required: true })}
                              id={"shortGoalName" + indexs}
                              type="text"
                              placeholder=""
                              name="name"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.name}
                              onChange={(e) => handleNewShortGoals(indexs, e)}
                            />
                          )}
                          <label style={{ width: "280px", paddingLeft: "16px" }}>
                            Progress:
                            <input
                              // ref={register({ required: true })}
                              id={"shortGoalPer" + indexs}
                              type="number"
                              placeholder=""
                              name="progress"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.progress}
                              onChange={(e) => handleNewShortGoals(indexs, e)}
                            />
                            {"%"}
                          </label>
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-primary "
                            style={{
                              width: "105px",
                              height: "28px",
                              padding: "0px",
                              backgroundColor: "red",
                            }}
                            onClick={() => removeNewShotGoals(indexs)}
                          >
                            <b>-</b>
                          </button>
                        </div>
                      ))}
                    </div>)}
                </div>
                <br />
                <br />
                <b>THERAPIST SIGNATURE:</b>
                <br />
                {/* {isSubmit ? (
                  relValue?.dischargeSummary?.step1?.patientName
                ) : (
                  <input
                    type="text"
                    placeholder="Patient Name goes here"
                    name="patientName"
                    className={`form-control form-input form-input-w-37per`}
                    defaultValue={patientName}
                    onChange={(e) => lastInfo(e)}
                  />
                )}
                &nbsp;&nbsp;
                <br />
                <br />
                <br /> */}
                <label style={{ width: "auto", padding: "10px 0" }}>
                  <b>Therapist Name : </b>
                </label>
                {isSubmit ? (
                  relValue?.dischargeSummary?.step1?.therapistName
                ) : (
                  <input
                    type="text"
                    placeholder="therapy type goes here"
                    name="therapistName"
                    className={`form-control form-input form-input-w-37per`}
                    defaultValue={therapistName}
                    onChange={(e) => lastInfo(e)}
                  />
                )}
                <br/>
                <p>Therapist Name (please print)</p>
                <SignDate
                  date={
                    dischargeSummary?.step4?.therapistSignDate ||
                    relValue?.dischargeSummary?.step4?.therapistSignDate
                  }
                  familyImageURL={
                    isSubmit ? props?.data?.therapistSignUrl : familyImageURL
                  }
                  isSubmit={isSubmit}
                  setOpen={setOpen}
                  signTitle={""}
                  guardian=""
                  familyInfo={familyInfo}
                  isLoading={isLoading}
                  userType="therapist"
                />
                <DigitalSignPopup
                  modalState={open}
                  setModalState={setOpen}
                  save={save}
                  clear={clear}
                  sigCanvas={sigCanvas}
                />
                <br />
                <hr />
                <FormFooter
                  address={joinWithComma(
                    address1,
                    address2,
                    city,
                    country,
                    zipCode
                  )}
                />
                {!isSubmit ? (
                  <>
                    {errors.consent1 ||
                      (errors.consent2 && (
                        <p className="error-text">
                          <small>All fields are require</small>
                        </p>
                      ))}
                    {/* {signatureErr && (
                        <p className="text-red">
                          <small>{signatureErr}</small>
                        </p>
                      )} */}
                    <button
                      className="btn btn-theme-green"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                    {console.log(
                      "familyInfo.therapistSign",
                      familyInfo.therapistSign
                    )}
                    {/* {props?.data?.replyNeeded && ( */}
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={familyInfo.therapistSign ? false : true}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                    {/* )} */}
                  </>
                ) : (
                  <>
                    {!props?.isFormOpen && (
                      <button
                        className="btn btn-theme-green mb-10"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
}

export default DischargeSummery;
