import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from "moment";
import InputMask from "react-input-mask";
import { getTimeFormatPropsInputComp } from "../config/Config";

const TimeInput = ({
    initialValue,
    onChange,
    value
}) => {
    const [state, setState] = useState(value || initialValue);
    const change = (e) => {
        let time = e
        if (typeof time === "string") {
            const [h, m] = time.split(":");
            
            time = moment().hour(h === "00" ? '01' : h).minute(m);
            setState(time);
        } else {
            setState(time);
        }
        if(typeof onChange === 'function'){
            onChange(time)
        }
    };
    
    return (
        <Datetime
            inputProps={getTimeFormatPropsInputComp}
            renderInput={renderInput}
            initialViewMode="time"
            updateOnView="time"
            className="form-control-time"
            timeFormat="hh:mm"
            timeConstraints={{
                hours: { min: 1, max: 12, step: 1 },
                minute: { min: 1, max: 59, step: 1 }
            }}
            initialValue={initialValue}
            value={value || state}
            dateFormat={false}
            onChange={change}
        />
    );
}

export default TimeInput
const currentTime = () => {
    let t = moment();
    return t.hour() > 12 ? t.subtract(12, 'hours').hour() : t.hour()
}
TimeInput.defaultProps = {
    initialValue: currentTime,
}

const renderInput = (props, openCalendar) => {
    return <RenderInput {...props} openCalendar={openCalendar} />;
};
const RenderInput = ({ openCalendar, ...props }) => {
    const [time, setTime] = useState("");
    const isOne = time[0] === "1";
    const handleInput = (e) => {
        props.onChange(e);
    };

    useEffect(() => {
        const value = props.value;
        setTime(value);
    }, [props.value])

    return (
        <div className="input-group ml-2">
            <img src="/resources/assets/clock.svg" alt="clock" onClick={openCalendar} style={{ marginRight: 4 }} />
            <InputMask
                {...props}
                type="text"
                mask={isOne ? "12:59" : "19:59"}
                maskChar="0"
                onChange={handleInput}
                formatChars={{
                    "9": "[0-9]",
                    "1": "[0-1]",
                    "2": "[0-2]",
                    "5": "[0-5]"
                }}
            />
        </div>
    );
};
