import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MsgList from "./MsgList";
import { firebaseCofig } from "../../config/Config";
import { generateMsgKey } from "./msgUtil";

function Messages(props) {
  const [messages, setMessages] = useState("");
  const [messageList, setMessagelist] = useState([]);
  const [allMsg, setAllMsg] = useState([]);
  // let flatListRef;

  const profile = useSelector((state) => state.adminUser.profile);
  const tenantDetails = useSelector(
    (state) => state.adminUser.tenantInfo
  );

  const scrollRef = useRef(null);

  const allList = useSelector((state) => state.messages?.profiles);
  console.log("msg list",allList,tenantDetails);
  const myKey = generateMsgKey(profile?.userType, profile?.id, props.myChildId);
  const otherKey = generateMsgKey(
    props.otherUser?.userType,
    props.otherUser?.familyUserId
      ? props.otherUser?.familyUserId
      : props.otherUser?.id,
    props.otherChildId
  );

  const data = {
    email: profile.email,
    phoneNumber: "",
    isLoading: false,
    uniqueId: profile.id,
    profileImage: profile.profilePicUrl,
    userName: (profile.firstName || "") + " " + (profile.lastName || ""),
    lastName: profile.lastName,
    phone: profile.phone,
  };

  useEffect(() => {
    if (myKey && otherKey) {
      setMessagelist(Object.values(allList?.[myKey]?.[otherKey] || {}));
    }
    return () => {
      setAllMsg([]);
    };
  }, [myKey, otherKey, allList?.[myKey]?.[otherKey]]);

  useEffect(() => {
    console.log("firebase length",firebase.apps);
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseCofig);
      //  checkPermission();
    } else {
      firebase.app(); // if already initialized, use that one
    }
  }, []);
  useEffect(() => {
    scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight);
  }, [messageList]);

  // useEffect(() => {
  //   console.log({allMsg})
  // }, [allMsg])

  // useEffect(() => {
  //   let dbRef;
  //   // let dbRef1;
  //   if (!firebase.apps.length) {
  //     firebase.initializeApp(firebaseCofig);
  //     //  checkPermission();
  //   } else {
  //     firebase.app(); // if already initialized, use that one
  //   }
  //   if (otherKey && myKey ) {
  //     dbRef = firebase
  //       .database()
  //       .ref(tenantDetails?.tenantId)
  //       .child(myKey) // current user
  //       .child(otherKey) // other user

  //     // dbRef1 = firebase
  //     //   .database()
  //     //   .ref(tenantDetails?.tenantId)
  //     //   .child(myKey) // current user

  //     // dbRef1.on("child_added", (val, a, b) => {
  //     //   // console.log({ val, a, b, value: val.val() });
  //     //   let person = val.val();
  //     //   let key = val.val().key;
  //     //   let key2 = val.key;
  //     //   // console.log({person})
  //     //   setAllMsg((oldarr) => [...oldarr, {key, key2, person}]);
  //     // });
  //     // dbRef1.on("child_changed", (val, a, b) => {
  //     //   // console.log({ val, a, b, value: val.val() });
  //     //   let person = val.val();
  //     //   let key = val.key
  //     //   console.log({person, key})
  //     //   // setAllMsg((oldarr) => [...oldarr, person]);
  //     // });

  //     setMessagelist([]);

  //     dbRef.on("child_added", (val, a, b) => {
  //       // console.log({ val, a, b, value: val.val() });
  //       let person = val.val();
  //       setMessagelist((oldarr) => [...oldarr, person]);
  //     });
  //   }
  //   return () => {
  //     if (dbRef) {
  //       dbRef.off("child_added");
  //     }
  //     // setAllMsg([])
  //   };
  // }, [otherKey]);

  const handleMessaging = () => {
    console.log("test message");
    // if (data.phone == null || !props.otherUser?.phone) {
    //   toast.error("Value is null");
    // }
    if (messages.trim().length > 0) {
      let msgId = firebase
        .database()
        .ref(tenantDetails?.tenantId)
        .child(myKey) // current user
        .child(otherKey) // other user
        .push().key;

      let updates = {};
      let message = {
        message: messages.trim(),
        time: firebase.database.ServerValue.TIMESTAMP,
        from: myKey,
        users: [
          {
            [myKey]: {
              name: data.userName,
              image: data.profileImage,
            },
          },
          {
            [otherKey]: {
              name:
                (props.otherUser?.firstName || "") +
                " " +
                (props.otherUser?.lastName || ""),
              image: props.otherUser?.profilePicUrl
                ? props.otherUser?.profilePicUrl
                : props.otherUser?.profileUrl,
            },
          },
        ],
      };
      console.log("messages",messages,message,myKey,otherKey,msgId,tenantDetails?.tenantId);
      updates[
        [tenantDetails?.tenantId, myKey, otherKey, msgId].join("/")
        // "messages/" + data.phone + "/" + props.otherUser?.phone + "/" + msgId
      ] = message;
      updates[
        [tenantDetails?.tenantId, otherKey, myKey, msgId].join("/")
        // "messages/" + props.otherUser?.phone + "/" + data.phone + "/" + msgId
      ] = message;
      firebase.database().ref().update(updates).catch(error=>console.log("message error",error));
      setMessages("");
    }
  };
  console.log("messageList",messageList);
  return (
    <>
      {myKey && otherKey && (
        <div
          className={`session-detail-comp custom-table-view msg-complete-box ${props.bgColor}`}
        >
          <div ref={scrollRef} className="msg-complete-box-inner">
            <div className="msg-list px-2">
              <MsgList
                myKey={myKey}
                localUser={data}
                otherUser={props?.otherUser}
                messageList={messageList}
              />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleMessaging();
              }}
            >
              <div>
                <textarea
                  name="text"
                  className="form-control"
                  placeholder="Type your message"
                  autofocus
                  value={messages}
                  onChange={(e) => setMessages(e.target.value)}
                />
                <div className="row mt-3 form-group">
                  <div className="mt-1 col-md-8">
                    {/* <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        cursor="pointer"
                        color="blue"
                        height="16"
                        fill="currentColor"
                        className="bi bi-paperclip"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                      </svg>
                      <span>Attach audio/video/image file</span>
                    </span> */}
                  </div>
                  <div className="col-md-4">
                    <button
                      type="submit"
                      className="btn btn-primary custom-btn-green custom-btn-green-auto ml-4"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Messages;
