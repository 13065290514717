import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "./CommonComponents";

function CancellationForm(props) {
  const profile = useSelector((state) => state.adminUser.profile);
  const formRef = React.useRef(null);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [familyInfo, setFamilyInfo] = useState({
    id: props?.data?.id ? props.data.id : null,
    familyMemberName: props?.data?.familyMemberName
      ? props?.data?.familyMemberName
      : props?.data?.familyMember
        ? props.data.familyMember.firstName +
        " " +
        props.data.familyMember.lastName
        : " ",
    familyMemberDob: props?.data?.familyMemberDob
      ? props?.data?.familyMemberDob
      : props?.data?.familyMember
        ? props.data.familyMember.dob
        : " ",
  });
  const [mediareleaseform, setmediareleaseform] = useState({
    patientName: props?.data?.familyMemberName
      ? props?.data?.familyMemberName
      : props?.data?.familyMember
        ? props.data.familyMember.firstName +
        " " +
        props.data.familyMember.lastName
        : " ",
    dateofbirth: props?.data?.familyMemberDob
      ? props?.data?.familyMemberDob
      : props?.data?.familyMember
        ? props.data.familyMember.dob
        : " ",
  })
  const [signatureErr, setSignatureErr] = useState("");

  const [isSubmit, setIsSubmit] = useState(
    props?.from != "family" || props?.data?.reply
      ? true
      : props?.data?.replyNeeded == false
        ? !props?.data.replyNeeded
        : false
  );
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const onSubmitFamilyForm = (isSubmit = false) => {
    setIsLoading(true);
    let formData = new FormData();
    if (props?.data?.replyNeeded) {
      formData.append(
        "formUpdationForm",
        new Blob([JSON.stringify({ ...familyInfo, jsonFormattedForm: JSON.stringify({ mediareleaseform }) })], {
          type: "application/json",
        })
      );

      adminService
        .onboardPutUser(
          `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
          formData
        )
        .then((response) => {
          setIsLoading(true);
          toast.success("Form Submitted successfully")
          props?.from == "family" && props.handleBack();
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (props?.from === "admin") {
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("signature", familyImageURL);
      console.log("formData 220", formData);
      formData.append(
        "formCreationForm",
        new Blob([JSON.stringify(props?.inputs)], {
          type: "application/json",
        })
      );
      adminService
        .onboardUser(
          `submitted-forms`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Form Submitted successfully");
          props.setIsFocused(!props.isFocused);
          props?.from == "admin" && props.handleBack("reload");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };

  const updateInfo = (e) => {
    const { name, value } = e.target;
    setmediareleaseform({ ...mediareleaseform, [name]: value });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "CancellationForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const relValue = props?.data?.reply
    ? JSON.parse(props.data?.jsonFormattedForm)
    : "";
  console.log("propsprops", props?.data);
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        {/* {props?.data?.family?.id && (
          <HeaderDownloadButtons startDownload={startDownload} />
        )} */}  <div
          style={{
            position: 'absolute', top: 0, right: "100px"

          }}>
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.id &&
            props?.data?.reply && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}</div>
        <div className="card-lg">
          <FormHeader logoUrl={logoUrl} title="No Show/Cancellation Policy" />
          <hr />
          <br />

          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content">
              <div className="w-100per">
                <div style={{ width: "60%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Client's Name : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.mediareleaseform?.patientName
                    ) : (
                      <input
                        {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                        type="text"
                        placeholder="Child's name"
                        name="patientName"
                        className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                          }`}
                        defaultValue={familyInfo.familyMemberName}
                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "40%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>DOB : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.mediareleaseform?.dateofbirth.trim() &&
                      moment(relValue?.mediareleaseform?.dateofbirth).format("MM/DD/YYYY")
                    ) : (
                      <input
                        {...register('dateofbirth', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberDob === '' ? true : false })}
                        type="date"
                        name="dateofbirth"
                        placeholder="DOB"
                        className={`form-control form-input form-input-w-60per ${errors.familyMemberDob && "is-invalid"
                          }`}
                        defaultValue={familyInfo.familyMemberDob}
                        disabled={familyInfo?.familyMemberDob === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="w-100per">
                <p className="w-100per mt-10">
                  Regular attendance is required for our services to be
                  effective. Irregular attendance costs both the therapist and
                  the program time and money. It is therefore the responsibility
                  of the patient or his/her legal guardian to attend all
                  scheduled appointments. Please communicate with your therapist
                  to create a scheduling system that will be effective for you
                  and your family.
                </p>
                <p style={{ textDecoration: "underline" }}>
                  <b>Cancellation Policy:</b>
                </p>
                <p className="w-100per">
                  We request that if you must cancel your appointment, that you
                  give your therapist as much notice as possible. If you reach
                  your therapist before 60 minutes of your appointment, it is
                  considered a “cancellation”. We understand that things happen
                  with children, but please communicate with your therapist as
                  much and as soon as possible.
                </p>
                <p className="w-100per">
                  If you are going on vacation or will be out for an extended
                  period of time, please let your therapist know as soon as you
                  schedule your vacations, etc.
                </p>
                <ul>
                  <li>
                    After the first cancellation, the staff person will call you
                    to reschedule.
                  </li>
                  <li>
                    After two cancellations in a row, the Director of the
                    program will send you a letter / message to explain that you
                    must call him/ her if you wish to continue services.
                  </li>
                  <li>
                    After the third cancellation in a row, your case will be
                    closed.{" "}
                  </li>
                  <li>
                    If you cancel three (3) times, with some attendance in
                    between each cancellation, your therapist will discuss with
                    you some possible solutions to the problem of irregular
                    attendance.{" "}
                  </li>
                </ul>
                <p style={{ textDecoration: "underline" }}>
                  <b>No Show Policy :</b>
                </p>
                <p className="w-100per">
                  If you do not call to cancel before the staff person logs on
                  into the meeting, it is considered a “No Show”.
                </p>
                <ul>
                  <li>
                    After the first No Show, the therapist will call to
                    reschedule
                  </li>
                  <li>
                    After two No Shows, our office will contact you to see if
                    you desire to continue services and the Director of the
                    program will send you a letter explaining that you must call
                    if you wish to continue services.
                  </li>
                  <li>
                    After the third No Show {name} Network will issue a letter
                    to your family and your services coordinator to serve as
                    notification that services have been terminated.{" "}
                  </li>
                </ul>
                <p className="w-100per">
                  We understand that unforeseen circumstances may arise,
                  especially with children, that necessitate cancelling an
                  appointment. We request that if you must cancel your
                  appointment, that you give your therapist as much notice as
                  possible. While at least 24-hours notice is greatly
                  appreciated, a cancellation is defined as notice given less
                  than two hours prior to a scheduled appointment. Please
                  communicate with your therapist as soon as possible in the
                  event that you must cancel an appointment.
                </p>
                <p className="w-100per">
                  Our therapists will do whatever they can do to be available to
                  your child and accommodate your family’s schedule when making
                  appointments. It is expected in turn, that you will schedule
                  appointments in good faith and facilitate adequate time in
                  your schedule to keep Early Intervention sessions a priority.
                </p>
              </div>

              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {signatureErr && (
                    <p className="text-red">
                      <small>{signatureErr}</small>
                    </p>
                  )}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  <button
                    type={isLoading ? "button" : "submit"}
                    className="btn btn-success float-right1 mt-10 mb-10"
                  >
                    {isLoading ? "Loading...." : "Submit"}
                  </button>
                </>
              ) : (
                <>
                  {props?.from === 'admin' && props?.isFormOpen &&
                    <>
                      <button
                        className="btn btn-theme-green"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btn-success float-right1 mt-10 mb-10"
                      >
                        {isLoading ? "Loading...." : "Submit"}
                      </button>
                    </>

                  }
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CancellationForm;
