import React, { useEffect, useState } from "react";
import { getFormsByChild } from "../../services/formsServices";
import { _toastHandleErr } from "../../utils/commonFunctions";
import NewFormUpload from "./NewFormUpload";
import UploadedFormTh from "./UploadedFormTh";

const FormsTherapis = (props) => {
  const [state, setState] = useState({
    forms: [],
  });
  useEffect(() => {
    getForms(props.child?.id);
  }, [props.child?.id]);

  const getForms = (id) => {
    if (id) {
      getFormsByChild(id,props.archive)
        .then((res) => {
          console.log("static forms",res)
          setState((state) => ({ ...state, forms: res }));
        })
        .catch(_toastHandleErr);
    }
  };
  //console.log("form therapist props",state?.forms?.content.length);

  return (
    <div className="c-forms c-forms--therapist">
      {state?.forms?.content >= 0 ? 
      <p style={{marginTop:"10px"}}>No Data Found.</p> :
      <>
      {state?.forms?.content &&
        state.forms.content.map((form) => {
          
            
          return (
            <UploadedFormTh
              key={form.id}
              afterSuccess={() => {
                getForms(props.child?.id);
              }}
              {...props}
              form={form}
            />
          );
           
        })
        } 
        </>
        }
        
      <NewFormUpload
        afterSuccess={() => {
          getForms(props.child?.id);
        }}
        {...props}
      />
    </div>
  );
};

export default FormsTherapis;
