import React from "react";
import classNames from "classnames";
import moment from "moment";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ArchiveCaseNoteTableRow = ({
  childName,
  childProfilePic,
  therapist,
  therapy,
  onClick,
  active,
  caseNoteDTO,
  isTherepist,
  isInterepreter,
  isFamily,
  isAdmin,
  startDateTime,
  interpreterName,
  isShared,
}) => {
  const date = moment.utc(startDateTime).utc().local().format("DD MMMM, yyyy");
  const time = moment.utc(startDateTime).utc().local().format("hh:mm A");
  const statusText = () => {
    if (isTherepist) {
      if (isShared) {
        return "Shared";
      }
      if (caseNoteDTO?.drafted) {
        return "Pending";
      }
      if (interpreterName) {
        if (caseNoteDTO?.draftedInterpreter) {
          return "Submitted to Interpreter";
        }
      }
      return caseNoteDTO?.acceptCaseNotes
        ? "Accepted by Caretaker"
        : "Submitted";
    }
    if (interpreterName) {
      if (caseNoteDTO?.draftedInterpreter) {
        return "Pending translation";
      }
      if (isInterepreter) {
        return "Translated";
      }
    }
    if (isFamily) {
      return caseNoteDTO?.acceptCaseNotes ? "Accepted" : "Pending Acceptance";
    }
    if(isAdmin){
      if (caseNoteDTO?.drafted) {
        return "Pending";
      }
      return caseNoteDTO?.acceptCaseNotes
        ? "Accepted by Caretaker"
        : "Submitted";
    }
  };

  const history = useNavigate();
  return (
    <div
      onClick={onClick}
      className={classNames("p-case-notes__table-row", {
        "p-case-notes__table-row--active": active,
      })}
    >
      <div className="p-case-notes__table-col">
        <div className="p-case-notes__child-info">
          <img
            src={
              childProfilePic
                ? childProfilePic
                : "/resources/assets/defaultprofile.png"
            }
            alt={childName}
            className="p-case-notes__child-info-img"
          />
          <span className="text-capitalize">{childName}</span>
        </div>
      </div>
      <div className="p-case-notes__table-col p-case-notes__table-col--date">
        {date} <br />
        {time}
      </div>
      <div className="p-case-notes__table-col p-case-notes__table-col--therapy">
        {therapy}
      </div>
      <div className="p-case-notes__table-col p-case-notes__table-col--therapist">
        {therapist}
      </div>
      <div
        className="p-case-notes__table-col p-case-notes__table-col--therapist"
        style={{ flex: "1 1 45px" }}
      >
        {statusText()}
      </div>
      {/* {isTherepist && <div className="p-case-notes__table-col p-case-notes__table-col--therapist">
                {
                    isShared ? "Shared" : (
                        caseNoteDTO?.drafted ? "Pending" : (
                    interpreterName ? (!caseNoteDTO?.draftedInterpreter ? "Submitted" :"Submitted to Interpreter") : "Submitted" 
                ))}
            </div>}
            {
                isInterepreter && <div className="p-case-notes__table-col p-case-notes__table-col--therapist">
                {!caseNoteDTO?.draftedInterpreter ? "Translated" : "Pending translation"}
            </div>
            }
            {
                isFamily && <div className="p-case-notes__table-col p-case-notes__table-col--therapist">
                    {
                        interpreterName ? (
                            !caseNoteDTO?.draftedInterpreter ? (caseNoteDTO?.acceptCaseNotes ? "Accepted" : "Pending Acceptance") : "Pending translation"
                        ) : (caseNoteDTO?.acceptCaseNotes ? "Accepted" : "Pending Acceptance")
                    }
            </div>
            } */}
      <div
        className="p-case-notes__table-col p-case-notes__table-col--action view-all-link"
        style={{ flex: "0 1 110px" }}
      >
        <Link
          className="btn px-0 view-link"
          to={`/view-archive-case-notes/${caseNoteDTO?.id}`}
        >
          View Details
        </Link>
      </div>
    </div>
  );
};

export default ArchiveCaseNoteTableRow;
