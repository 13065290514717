import { adminService } from "./adminService";

// by therapist
export const getFamiliesListByTherapist = () => {
  return new Promise((resolve, rej) => {
    adminService.getAll(`therapist/family-members`).then((res) => {
      if (res?.status === "Success") {
        resolve(res?.data?.content);
      }
      rej(res);
    }, rej);
  });
};

export const getFamilyUserDetail = () => {
  return new Promise((resolve, rej) => {
    adminService.getAll(`families/detail`).then((res) => {
      if (res?.status === "Success") {
        resolve(res.data);
      }
      rej(res);
    }, rej);
  });
};

export const getFamilyListByInterpreter = (pageNo) => {
  return new Promise((resolve, rej) => {
    adminService.getAll(`families/interpreter?page=${pageNo}`).then((res) => {
      if (res?.status === "Success") {
        resolve(res.data);
      }
      rej(res);
    }, rej);
  });
};

export const getMyTeamDetail = (childId) => {
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`families/my-team?familyMemberId=${childId}`)
      .then((res) => {
        if (res?.status === "Success") {
          resolve(res.data);
        } else {
          rej(res);
        }
      }, rej);
  });
};

export const disableNotifications = (body) => {
  return new Promise((resolve, rej) => {
    adminService.postNew(`control-notification`, body).then((res) => {
      if (res?.status === "Success") {
        resolve(res?.data);
      }
      rej(res);
    }, rej);
  });
};
export const enableNotifications = (body) => {
  return new Promise((resolve, rej) => {
    adminService.deleteNew(`control-notification`, body).then((res) => {
      if (res?.status === "Success") {
        resolve(res?.data);
      }
      rej(res);
    }, rej);
  });
};

export const getListOfNotifictionsLabels = () => {
  return new Promise((resolve, rej) => {
    resolve([
      "Session created for Family",
      "Session created for Therapist",
      "Session created for Interpreter",
    ]);
  });
};

export const getDisabledNotifications = (id) => {
  return new Promise((resolve, rej) => {
    adminService.getAllNew(`control-notification/${id}`).then((res) => {
      if (res?.status === "Success") {
        resolve(res?.data);
      }
      rej(res);
    }, rej);
  });
};



// get therapist type

export const getTherapisttype = () => {
  return new Promise((resolve, rej) => {
    adminService.getAll(`/session-service/api/disciplines`).then((res) => {
      if (res?.status === "Success") {
        resolve(res.data);
      } else {
        rej(res);
      }
    }, rej);
  });
};