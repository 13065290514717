import React from "react";
import "./Tab.css";

function Tab(props) {
  return (
    <>
      <div className="col-md-12" id="myTab">
        <ul className="nav nav-tabs settings-tab tab-nav" role="tablist">
          {props.tabNav &&
            props.tabNav.map((items, index) => {
              return (
                <li
                  className={`${props.active === index ? "active" : ""}`}
                  key="index"
                  onClick={() => props.setActive(index)}
                >
                  {items}
                </li>
              );
            })}
          {/* <li className="active">Settings</li>
          <li>Others</li> */}
        </ul>
        <div className="TabContent">{props.children}</div>
      </div>
    </>
  );
}

export default Tab;
