import React, {useEffect, useState} from 'react';
// import ReactHlsPlayer from 'react-hls-player';
import ReactPlayer from 'react-player'
import classNames from 'classnames'
import { ReactSVG } from 'react-svg';
import { setBodyScroll } from '../../utils/commonFunctions';

function WatchVideo({
    sessioninfo, recordingList, closeModal
}) {
    const list = (recordingList || []) 
    const [state, setState] = useState({
        allRecordings: list,
        selectedIndex: 0, 
        selectedFile: list[0] || ""
    })
    useEffect(() => {
        setState((st)=> ({
            ...st,
            allRecordings: recordingList
        }))
    }, [recordingList])
    const {
        allRecordings,
        selectedIndex,
    } = state
    const videoPlayerProps = {
        autoPlay:true,
        controls:true,
        controlsList:'nodownload',
        width:740,
        height:416,
    }

    useEffect(() => {
        setBodyScroll(false)
        return () => {
            setBodyScroll(true)
        }
    }, [])

    const onNext = () => {
        if(state.selectedIndex >= state.allRecordings.length - 1 ){
            return null
        }
        setState(state => ({
            ...state,
            selectedFile: state.allRecordings[state.selectedIndex + 1],
            selectedIndex: state.selectedIndex + 1,
        }))
    }

    const onPrev = () => {
        if(state.selectedIndex <= 0){
            return null
        }
        setState(state => ({
            ...state,
            selectedFile: state.allRecordings[state.selectedIndex - 1],
            selectedIndex: state.selectedIndex - 1
        }))
    }

    return (
        <div className="col-md-2 custom-modal c-watch-video ">
            <div className="modal fade add-new-prop-modal show " tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered c-watch-video__dialog" role="document">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <div className="text-center w-100">
                                <h5 className="modal-title name">
                                    {sessioninfo?.info?.therapyname}
                                </h5>
                            </div>
                            <button onClick={() => closeModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <div className="close-background">
                                    <span aria-hidden="true">&times;</span>
                                </div>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12 text-center">
                                {
                                    allRecordings.length > 1 ? (
                                        <div>
                                            <div className="mb-3">
                                                <ReactPlayer
                                                    // url={allRecordings[selectedIndex]}
                                                    url={state.selectedFile}
                                                    {...videoPlayerProps}
                                                />
                                            </div>
                                            <div className="d-flex flex-wrap justify-content-center" >
                                                Recording {state.selectedIndex + 1}
                                                {/* {
                                                    allRecordings.map((_item, key) => {
                                                        return <button key={key} 
                                                            onClick={()=> {
                                                                setState(state => ({
                                                                    ...state,
                                                                    selectedIndex: key
                                                                }))
                                                            }}
                                                            className={classNames("btn m-1 flex-grow-1", {
                                                            "btn-outline-theme-blue": selectedIndex !== key,
                                                            "btn-theme-blue": selectedIndex === key,
                                                        })}>Recording {key + 1}</button>
                                                    })
                                                } */}
                                            </div>
                                        </div>
                                    ) :
                                    allRecordings.map((item, key) => {
                                        return <div key={key}>
                                            <ReactPlayer
                                                url={item}
                                                {...videoPlayerProps}
                                            />
                                        </div>
                                    })
                                }
                            </div>
                            {allRecordings.length > 1 && <div className="c-watch-video__btn-wrapper">
                                <span onClick={onPrev} className={classNames("c-watch-video__btn c-watch-video__btn--prev", {
                                    'd-none': state.selectedIndex <= 0
                                })}>
                                    <ReactSVG wrapper="span" src="/resources/assets/arrow-left.svg" />
                                </span>
                                <span onClick={onNext} className={classNames("c-watch-video__btn c-watch-video__btn--next", {
                                    'd-none': state.selectedIndex >= state.allRecordings.length -1
                                })}>
                                <ReactSVG wrapper="span" src="/resources/assets/arrow-right.svg" />
                                </span>
                            </div>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default WatchVideo;