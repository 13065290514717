import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import {
//   calcWeekCount,
//   getListOfDays,
//   getUTCTimeToLocalByDate,
//   getMeridiumByDate,
//   showWarning,
// } from "../utils/commonFunctions";
// import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";
// import { daysJson, monthJson } from "../config/Config";
// import { adminService } from "../services/adminService";
// import allActions from "../redux/actions";
// import { useDispatch, useSelector } from "react-redux";
// import ViewSessionCalendar from "./ViewSessionCalendar";
// import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";
// import appAction from "../redux/actions/appAction";
// import TimeInput from "../components/TimeInput";
import Individual from "../AddSession/Individual";
import GroupMarketingSession from "./GroupMarketingSession";

function AddNewMarketingSession() {
  let params = useParams();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isGroup = urlParams.get("group");
  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding">
          <div className="custom-table-view">
            <div className="content-header pb-0">
              <div className="text-left header-title">
                {params.id ? "Edit" : "Add New"} Appointment
              </div>
            </div>

            <div>
              <GroupMarketingSession />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewMarketingSession;
