import { appTypes } from "../types";

const initialState = {
    appLoading: false,
    soapNoteBillingData: []
}
const appReducer =  (state = initialState, action = {}) => {
    switch (action.type) {
        case appTypes.setAppLoader:
            return {
                ...state,
                appLoading: action.payload
            }
    
        case appTypes.setSoapNoteBillingInfo: 
            return {
                ...state,
                soapNoteBillingData: action.payload
            }
        default:
            return state;
    }
}
export default appReducer;
