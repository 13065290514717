import React from "react";
import { IoIosSearch } from "react-icons/io";
const CaseNoteHeader = (props) => {
  return (
    <div className="p-case-notes__header p-3">
      <div className="row justify-content-between align-items-center">
        <div className="col-auto">
          <h2 className="header-title">{props.title}</h2>
        </div>
        <div className="col-auto d-flex">
          <input
            type="text"
            placeholder="Client/Therapist Name"
            value={props.search}
            onChange={(e) => props.onChange(e.target.value)}
            className="form-control mr-3"
          />
          <button
            onClick={() => props.onSearch()}
            className="btn btn-theme-green"
          >
            <IoIosSearch />
          </button>
        </div>
      </div>
    </div>
  );
};
export default CaseNoteHeader;
