import React from "react";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";

function BillSign(props) {
  const { modalState, sigCanvas, save, clear, setIsSigned } = props;
  console.log("setIsSigned",setIsSigned);
  return (
    <>
      <Popup modal={modalState} open={modalState} closeOnDocumentClick={false}>
        {(close) => (
          <>
            <div className="col-md-12 popup-header">
              <h5>Digital Signature</h5>
            </div>
            <div className="col-md-12">
              <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{
                  className: "sigCanvas",
                }}
                onBegin={()=>setIsSigned(false)}
                onEnd={() => setIsSigned(true) }
              />
            </div>
            <div className="col-md-12 popup-btn-container">
              <button className="btn btn-success" onClick={save}>
                Save
              </button>
              <button className="btn btn-primary" onClick={clear}>
                Clear
              </button>
            </div>
          </>
        )}
      </Popup>
    </>
  );
}

export default BillSign;
