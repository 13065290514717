import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { adminService } from "../../services/adminService";
import { useForm } from "react-hook-form";
import allActions from "../../redux/actions";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

function AddService(props) {
  let params = useParams();
  const [isdelete, setIsDelete] = useState(false);
  const history = useNavigate();
  //const { register, formState: { errors }, handleSubmit } = useForm();
  const [therapistServiceId, settherapistServiceId] = useState(
    props.serviceinfo.info.therapistServiceId
      ? props.serviceinfo.info.therapistServiceId
      : []
  );
  const[therapyIdError,setTherapyError]=useState(false);
  const dispatch = useDispatch();
  let therapistForm = useSelector((state) => state.adminUser.therapistForm);
  const [therapistServices, setTherapistServices] = useState([]);
  const [therapies, setTherapies] = useState("");
  const [inputs, setInputs] = useState({
    serviceDetail: props.serviceinfo.info.serviceDetail
      ? props.serviceinfo.info.serviceDetail
      : "",
    therapyId: props.serviceinfo.info.therapyId
      ? props.serviceinfo.info.therapyId
      : null,
    id: props.serviceinfo.info.therapistServiceId
      ? props.serviceinfo.info.therapistServiceId
      : "",
    clinicNpiNumber: props.serviceinfo.info.clinicNpiNumber
      ? props.serviceinfo.info.clinicNpiNumber
      : "",
    clinicianNpiNumber: props.serviceinfo.info.clinicianNpiNumber
      ? props.serviceinfo.info.clinicianNpiNumber
      : "",
  });

  const addService = () => {
    // console.log("service add");
    // if (
    //   !props.serviceinfo.info.therapistServiceId &&
    //   props.servicedetail.filter(
    //     (item) => item?.therapy?.id == inputs.therapyId
    //   ).length > 0
    // ) {
    //   toast.warning("Serice already added, please add another service.");
    //   return;
    // }
    if (inputs?.therapyId !== null) {
      setTherapyError(false);
      //errors.therapyId = null;
      console.log("service add",inputs?.therapyId !== 'NaN' ? 'true' : 'false',inputs?.therapyId !== 0);
      let formData = new FormData();

      let serviceJson = props.servicedetail;

      if (props.isdelete) {
        serviceJson = serviceJson.filter((info) => info.id !== inputs.id);
      }
      if (props.serviceinfo.info.therapistServiceId) {
        let fileindex1 = serviceJson.findIndex((obj) => obj.id === inputs.id);
        inputs.id = props.serviceinfo.info.therapistServiceId;
        serviceJson[fileindex1] = inputs;
      } else {
        serviceJson.push(inputs);
      }

      let serviceJsonObj = [];
      serviceJson.map((item) => {
        let itemJson = {
          serviceDetail: 0,
          therapyId: item.therapy ? item.therapy.id : item.therapyId,
          id: item.id,
          clinicNpiNumber: item.clinicNpiNumber ? item.clinicNpiNumber : 0,
          clinicianNpiNumber: item.clinicianNpiNumber
            ? item.clinicianNpiNumber
            : 0,
        };
        serviceJsonObj.push(itemJson);
      });

      //return;

      let newform = {
        therapistServiceUpdateForms: serviceJsonObj,
      };

      formData.append(
        "therapistUpdateForm",
        new Blob([JSON.stringify(newform)], {
          type: "application/json",
        })
      );

      const id = params?.id || props?.id;
      if (id) {
        adminService.update(`/therapists/` + id, formData).then(
          (res) => {
            if (res.status === "Success") {
              if (
                props.isdelete
                  ? toast.success("Service deleted successfully!")
                  : toast.success(res.message)
              )
                props.addFun();
              setTimeout(() => {
                // history('/therapist')
              }, 2000);
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }else{
      setTherapyError(true);
      //errors.therapyId = 'error';
      console.log("Not service add");
      toast.warn("Please fill all the required information");
    }
  };

  function handleChange(e) {
    setTherapyError(false);
    const { name, value } = e.target;
    if (name === "hourlyRate") {
      setInputs((inputs) => ({ ...inputs, [name]: value ? value : "" }));
    } else{
      setInputs((inputs) => ({
        ...inputs,
        [name]: name === "therapyId" ? parseInt(value) : value,
      }));
    }
    console.log("therapy iD ............",inputs,e.target.value);
  }

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  // useEffect( () => {
  //     settherapistServiceId(therapistServiceId);
  // }, [therapistServiceId]);

  useEffect(() => {
    adminService.getAll(`therapies`).then(
      (res) => {
        setTherapies(res.data.content);
        if (therapistForm.hasOwnProperty("therapistServiceForms"))
          setTherapistServices(therapistForm.therapistServiceForms);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.closeModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <form>
        {props.isdelete ? (
          <>
            <div className="col-md-2 custom-modal">
              {/* Add Modal */}
              <div
                className="modal fade add-new-prop-modal show "
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content ">
                    <div className="modal-header">
                      <div className="addservice">
                        <h5
                          className="modal-title name"
                          id="exampleModalLongTitle"
                        >
                          Delete Service
                        </h5>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <h4>
                            <img
                              src="../resources/assets/Icon feather-trash-2.svg"
                              className="deleteicon"
                              alt=""
                            />
                            <span className="ml-3">Are You Sure?</span>
                          </h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <p>
                            Do you really want to delete{" "}
                            {props.serviceinfo.info.name} ?
                          </p>
                        </div>
                      </div>
                      <div className="text-center mb-3">
                        <hr className="col-md-11" />
                        <button
                          type="button"
                          className="btn btn-primary noModalbutton mr-4"
                          onClick={() => props.closeModal(false)}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          onClick={() => addService()}
                          className="btn btn-primary custom-btn-green ml-4"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-2 custom-modal">
              {/* Add Modal */}
              <div
                className="modal fade add-new-prop-modal show "
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-addservice modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content ">
                    <div className="modal-header">
                      <div className="addservice w-100 text-center m-auto">
                        <h5
                          className="modal-title name"
                          id="exampleModalLongTitle"
                        >
                          {props.isdelete
                            ? "Delete Service"
                            : props.serviceinfo.info.therapyId
                            ? "Edit Service"
                            : "Add Service"}
                        </h5>
                      </div>
                      <button
                        onClick={() => props.closeModal(false)}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <div className="close-background">
                          <p aria-hidden="true" className="cross">
                            &times;
                          </p>
                        </div>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="">
                            <p className="name">
                              Provider Type (
                              <span className="required-span">*</span>)
                            </p>
                            <div className="input-group ">
                              <select
                                className="custom-select w-100"
                                // ref={register}
                                //{...register('therapyId',{onChange:(e)=>handleChange(e), required: true })}
                                onChange={handleChange}
                                value={inputs?.therapyId}
                                name="therapyId"
                                disabled={
                                  props?.serviceinfo?.info?.therapyId
                                    ? true
                                    : false
                                }
                              >
                                <option value="0">Select Service Name</option>
                                {therapies &&
                                  therapies?.map((item, index) => (
                                    <option value={item.id}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                              {therapyIdError && (
                                  <div className="invalid-feedback-custom mt-2">
                                    Field is required
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-6 mt-3">
                          <div className="">
                            <p className="name">
                              Clinician NPI Number{" "}
                              {/*(<span className="required-span">*</span>)*/}
                            </p>
                            <input
                              defaultValue={
                                inputs.clinicianNpiNumber != 0
                                  ? inputs.clinicianNpiNumber
                                  : ""
                              }
                              disabled={params.uid ? true : false}
                              name="clinicianNpiNumber"
                              type="text"
                              onChange={handleChange}
                              className={`form-control`}
                              placeholder="Enter Clinician NPI Number"
                              maxLength="20"
                            />
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          <div className="">
                            <p className="name">
                              Clinic NPI Number{" "}
                              {/*(<span className="required-span">*</span>)*/}
                            </p>
                            <input
                              defaultValue={
                                inputs.clinicNpiNumber != 0
                                  ? inputs.clinicNpiNumber
                                  : ""
                              }
                              disabled={params.uid ? true : false}
                              name="clinicNpiNumber"
                              type="text"
                              onChange={handleChange}
                              className={`form-control`}
                              placeholder="Enter Clinic NPI Number"
                              maxLength="20"
                            />
                          </div>
                        </div>

                        {/*<div className="col-12 mt-3">
                                                    <div className="">
                                                        <p className="name">Service Details (<span className="required-span">*</span>)</p>
                                                        <textarea type="text" onChange={handleChange} value={inputs.serviceDetail} name="serviceDetail" className="form-control" placeholder="Enter Service Detail" />
                                                    </div>
                                                </div>*/}
                      </div>

                      <div className="text-center mb-3">
                        <hr className="col-md-11" />
                        {props.serviceinfo.info.therapyId ? (
                          <button
                            type="button"
                            onClick={() => addService()}
                            className="btn btn-primary custom-btn-green"
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => addService()}
                            className="btn btn-primary custom-btn-green"
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
}

export default AddService;
