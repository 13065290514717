import React from "react";
import ViewSessionCalendar from "../ViewSessionCalendar";

function SelectTherapist(props) {
  const {
    params,
    register,
    errors,
    handleChange,
    inputs,
    currentRole,
    therapist,
    setModalOpenClose,
    modalOpenClose,
  } = props;

  return (
    <>
      <div className="col-md-12">
        <div className="form-group">
          <label>Therapist</label> (<span className="required-span">*</span>)
          <div className="row ml-2">
            <img
              src="../resources/assets/user-icon-blue.svg"
              className="img-fluid"
              alt=""
            />
            <div className="col-md-10">
              <div className="input-group">
                {currentRole === "ROLE_REGIONAL_EARLY_STEPS" ? (
                  <select
                    {...register('therapistId',{onChange:(e)=>handleChange(e),required: inputs.therapistId === '' ? true : false })}
                    className={`custom-select ${
                      errors.therapistId && "is-invalid"
                    }`}
                    //onChange={handleChange}
                    value={inputs.therapistId}
                    defaultValue={inputs.therapistId}
                    name="therapistId"
                    disabled={params.id ? "disabled" : ""}
                  >
                    <option value="">Select Therapist</option>
                    {therapist &&
                      therapist.map((info, index) => (
                        <>
                          <option value={info.id}>
                            {info.userProfile.firstName}{" "}
                            {info.userProfile.lastName}
                          </option>
                        </>
                      ))}
                  </select>
                ) : (
                  <select
                    {...register('therapistId',{onChange:(e)=>handleChange(e), required: inputs.therapistId === '' ? true : false })}
                    className={`custom-select ${
                      errors.therapistId && "is-invalid"
                    }`}
                    //onChange={handleChange}
                    value={inputs.therapistId}
                    defaultValue={inputs.therapistId}
                    name="therapistId"
                    disabled={params.id ? "disabled" : ""}
                  >
                    <option value="">Select Therapist</option>
                    {therapist &&
                      therapist.map((info, index) => {
                        return (
                          <>
                            {info?.userProfile ? (
                              <option value={info?.id}>
                                {info?.userProfile.firstName}{" "}
                                {info?.userProfile.lastName}
                              </option>
                            ) : params.id ? (
                              <option value={info.therapist.id}>
                                {info.therapist.userProfile.firstName}{" "}
                                {info.therapist.userProfile.lastName}
                              </option>
                            ) : inputs.therapyId === info.therapy.id ? (
                              <option value={info.therapist.id}>
                                {info.therapist.userProfile.firstName}{" "}
                                {info.therapist.userProfile.lastName}
                              </option>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                  </select>
                )}

                <div className="input-group-prepend">
                  <img
                    className="m-auto"
                    src="../resources/assets/Polygon 1.svg"
                    alt=""
                  />
                </div>
              </div>
              {inputs.therapistId ? (
                <>
                  <a
                    href="javascript:void(0)"
                    onClick={() => setModalOpenClose(true)}
                  >
                    View Calendar
                  </a>
                  {modalOpenClose && (
                    <ViewSessionCalendar
                      closeModal={() => setModalOpenClose(false)}
                      userrole="ROLE_THERAPIST"
                      userid={inputs.therapistId}
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectTherapist;
