import React, { useEffect, useState } from "react";
import TenantAdminSettingTable from "./TenantAdminSettingTable";
import { useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { adminService } from "../../../services/adminService";
import { toast } from "react-toastify";
import Pagination from "react-responsive-pagination";
import TherapyList from "../../../components/TherapyList/TherapyList";
import Search from "../../../components/Admin/Search";
import usePageState from "../../../hooks/InitialState";
import SweetAlert from "react-bootstrap-sweetalert";

function TenantAdminSetting() {
  const { pageState, setPageState } = usePageState();
  let history = useNavigate();
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [therapy, setTherapy] = useState("");
  const [tenantList, setTenantList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  const onSearch = () => {
    getIcdCodeList();
  };

  useEffect(() => {
    getIcdCodeList();
  }, [search, page, therapy, status]);

  const getIcdCodeList = (id) => {
    setPageState((item) => ({ ...item, loading: true }));

    adminService
      .getAll(
        `tenantadmin?search=${search}&page=${page}&active=${status}&size=10&sort=id,desc`
      )
      .then((res) => {
        setPageState((item) => ({ ...item, loading: false }));
        if (res.status == "Success") {
          setTenantList(res?.data?.content);
          setTotalPages(res?.data?.totalPages);
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      })
      .catch((err) => {
        toast.error(err);
        setPageState((item) => ({ ...item, loading: false }));
      });
  };
  console.log("delete", confirmDeletion);
  const deleteUser = (id) => {
    //alert(id);
    adminService.DELETE(`tenantadmin/${id}`).then(
      (res) => {
        if (res.status === "Success") {
          setConfirmDeletion(false);
          toast.success(res.message);
          getIcdCodeList();
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
        //setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div className="ContentArea theropySession">
      <div className="no-padding dash-billing-info mr-0 flex-grow-1">
        <div className="custom-table-view min-h-100 p-0">
          {confirmDeletion && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={() => deleteUser(confirmDeletion?.id)}
              onCancel={() => setConfirmDeletion(false)}
              focusCancelBtn
            >
              You want to delete user "
              {confirmDeletion?.firstName + " " + confirmDeletion?.lastName}
              ".
            </SweetAlert>
          )}
          {/* <div class="content-header">
            <div class="text-left header-title pt-7px">ICD 10 Codes</div>
            <div class="text-right header-btn">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li>
                  <a>
                    <button
                      class="btn btn-primary custom-btn-green custom-btn-green-auto"
                      onClick={() => history("/add-icd-code")}
                    >
                      + Tenant Admin
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-md-12 mt-10 py-3 px-0">
            <div className="row">
              <div className="col-4 ">
                <Search
                  search={search}
                  setSearch={setSearch}
                  onSearch={onSearch}
                  placeholder="Tenant Admin Details"
                />
              </div>
              <div className="col-4">
                {/* <TherapyList defaultValue="" onChange={setTherapy} /> */}
              </div>
              <div
                className="col-4"
                style={{ textAlign: "right", fontSize: 18 }}
              >
                <button
                  class="btn btn-primary custom-btn-green custom-btn-green-auto"
                  onClick={() => history("/add-tenant")}
                >
                  + Tenant Admin
                </button>
              </div>
            </div>
          </div>
          {pageState.loading ? (
            <div className="p-5 mt-10em">
              <span className="loader"></span>
            </div>
          ) : (
            <>
              <div className="col-md-12 mt-10 p-0">
                <TenantAdminSettingTable
                  list={tenantList || []}
                  onDelete={setConfirmDeletion}
                />
              </div>
              {tenantList.length == 0 && (
                <div className="col-md-12 mt-10">
                  <p className="text-center">
                    <b>{search != "" ? "No match found" : "Data Not Found"}</b>
                  </p>
                </div>
              )}
              <div className="col-md-12 mt-10">
                <Pagination
                  total={totalPages}
                  current={page + 1}
                  onPageChange={(page) => setPage(page - 1)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TenantAdminSetting;
