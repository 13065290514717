import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { spaceRegex, alphanumericRegx } from "../../../components/Utils/Regex";
import TextInput from "../../../components/Admin/Inputs/TextInput";
import { adminService } from "../../../services/adminService";
import { toast } from "react-toastify";
import usePageState from "../../../hooks/InitialState";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function AddIcdCode() {
    const { setPageState, pageState } = usePageState();
    let history = useNavigate();
    const params = useParams();
    const {
        register,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm();
    const [inputs, setInputs] = useState({});
    const [therapyList, setTherapyList] = useState([]);

    useEffect(() => {
        getTherapyTypes();
    }, []);

    const getTherapyTypes = () => {
        adminService.getAll(`therapies`)
            .then((res) => {
                if (res.status == "Success") {
                    setTherapyList(res?.data.content);
                }
            })
            .catch((err) => toast.error(err));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: name == "therapyId" ? parseInt(value) : value,
        });

        console.log(watch(name), value, errors);
        if (name == "diagnosisDescription" && value.length > 100) {
            //alert("error");
            setError(name, {
                type: "maxLength",
                message: "Max 100 characters.",
            });
        }
        if (
            name == "diagnosisDescription" &&
            value.length < 101 &&
            value.length > 1
        ) {
            //alert;
            clearErrors("diagnosisDescription");
        }

        //console.log(errors);
    };

    const onSubmit = () => {
        setPageState((item) => ({ ...item, loading: true }));
        inputs.diagnosisCode = inputs.diagnosisCode.trim();
        inputs.diagnosisDescription = inputs.diagnosisDescription.trim();
        const fn = adminService.POST(`diagnoses`, JSON.stringify(inputs));

        fn.then((res) => {
            setPageState((item) => ({ ...item, loading: false }));
            if (res.status == "Success") {
                res?.data && setInputs(res?.data);
                history("/icd-code");
                toast.success(res?.data);
            } else {
                toast.error(res?.message || "Something went wrong");
            }
        }).catch((err) => {
            toast.error(err);
            setPageState((item) => ({ ...item, loading: false }));
        });
    };
    return (
        <div className="col-md-10 no-padding ContentArea theropySession">
            <div className="no-padding dash-billing-info mr-0 flex-grow-1">
                <div className="custom-table-view min-h-100">
                <div class="content-header">
                    <div class="text-left header-title pt-7px">{params?.id ? "Edit" : "Add"} ICD 10 Code</div>
                    <div class="text-right header-btn">
                    </div>
                </div>
                <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row ">
                        <div className="col-md-6 form-group mb-3 add-session-form">
                            <label>
                                Therapy Name <span className="required-span">*</span>
                            </label>
                            <div className="input-group">
                                <select
                                    {...register('therapyId',{ onChange:(e)=>handleChange(e),required: true })}
                                    className={`custom-select ${errors.therapyId && "is-invalid"
                                        }`}
                                    //onChange={handleChange}
                                    defaultValue={inputs?.therapyId}
                                    name="therapyId"
                                    disabled={params?.id ? "disabled" : ""}
                                >
                                    <option value="">Select Therapy</option>
                                    {therapyList.length > 0 &&
                                        therapyList.map((th) => {
                                            return (
                                                <option key={th.id} value={th.id}>
                                                    {th.name}
                                                </option>
                                            );
                                        })}
                                </select>
                                <div className="input-group-prepend">
                                    <img
                                        className="m-auto"
                                        src="../resources/assets/Polygon 1.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                            {errors.therapyId && (
                                <span className="fieldError">This field is required.</span>
                            )}
                        </div>
                        <div className="col-md-6 form-group mb-3">
                            <label>
                                ICD 10 Code <span className="required-span">*</span>
                            </label>
                            <input
                                label="ICD 10 Code"
                                name="diagnosisCode"
                                onChange={(e) => handleChange(e)}
                                {...register('diagnosisCode',{ onChange:(e) => handleChange(e),required: true, maxLength: 50, pattern: alphanumericRegx })}
                                defaultValue={inputs?.diagnosisCode}
                                placeholder="Enter ICD 10 Code"
                                className={`form-control ${errors?.diagnosisCode && "is-invalid"
                                    }`}
                            />
                            {errors.diagnosisCode && (
                                <span className="fieldError">
                                    {errors.diagnosisCode?.message ||
                                        "This field is required."}
                                </span>
                            )}
                            {/* <TextInput
                                label="ICD 10 Code"
                                name="diagnosisCode"
                                handleChange={handleChange}
                                {...register({ required: true,maxLength: 50,})}
                                value={inputs?.diagnosisCode}
                                err={errors?.diagnosisCode}
                                placeholder="Enter ICD 10 Code"
                                isRequire={true}
                                disabled={params?.id ? true : false}
                            /> */}
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-md-12 form-group mb-3">
                            <label>
                                Description <span className="required-span">*</span>
                            </label>
                            <textarea
                                {...register('diagnosisDescription',{ onChange:(e) => handleChange(e),required: true, maxLength: 100, pattern: alphanumericRegx })}
                                name="diagnosisDescription"
                                className={`form-control h-100 ${errors?.diagnosisDescription && "is-invalid"
                                    }`}
                                onChange={(e) => handleChange(e)}
                                defaultValue={inputs?.diagnosisDescription}
                                placeholder="Enter description"
                            />
                            {errors.diagnosisDescription && (
                                <span className="fieldError">
                                    {errors.diagnosisDescription?.message ||
                                        "This field is required."}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="row mt-10">
                        <div className="col-md-12">
                            <button
                                type="submit"
                                className="btn btn-theme-green"
                                style={{ minWidth: 100 }}
                            >
                                {pageState?.loading ? (
                                    <div className="d-flex justify-content-center">
                                        <span className="myLoader light sm d-inline"></span>
                                    </div>
                                ) : (
                                    <span>Submit</span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
}

export default AddIcdCode;