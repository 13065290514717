import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

function DeleteConfirmationPopup(props) {
  const { confirmDeletion, setConfirmDeletion, onDelete, name } = props;
  return (
    <>
      {confirmDeletion && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => onDelete(confirmDeletion?.id)}
          onCancel={() => setConfirmDeletion(false)}
          focusCancelBtn
        >
          You want to delete "{name}".
        </SweetAlert>
      )}
    </>
  );
}

export default DeleteConfirmationPopup;
