import React, { useState } from 'react'
import QueryReplyForm from './QueryReplyForm';

const AddQueryBtn =  ({ sessionId, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div>
        {isOpen ? (
          <div>
            <QueryReplyForm onSuccess={onSuccess} sessionId={sessionId} />
          </div>
        ) : (
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="c-query__btn btn btn-theme-green"
            >
              Ask a Query
            </button>
          )}
        <div></div>
      </div>
    );
};

export default AddQueryBtn
