import React from "react";
import Messages from "../../../components/messages/Messages";
import SessionsDetail from "../../../components/SessionsDetail";
import TeamDetail from "../../../components/TeamDetail";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Pagination from "react-responsive-pagination";
import moment from "moment-timezone";
import classNames from "classnames";
import StatusBadge from "../../../components/StatusBadge";
import { daysJson, monthJson } from "../../../config/Config";
import { getFullName, getStatusClassName } from "../../../utils/commonFunctions";
import { getUTCTimeToLocalByDateWithMeridium, calculateDuration } from "../../../utils/commonFunctions";

function OnBoardedInterpreter(props) {
  const {
    isInterpeter,
    families,
    loading,
    paginatePre,
    paginate,
    paginateNext,
    currentPage,
    totalpages,
    user_role,
    hasTherapistInFamily,
    profile,
    selectedUser,
    InterpreterChildRow,

    rowclickid,
    selectedChildId,
    onClickRow,
    pageNumbers,
    loadings,
    therapysessions,
    successSub,
    teamdata,
    setSorting,
    sorting,
  } = props;
  let sortBy = sorting == "DESC" ? "ASC" : "DESC";
  console.log("familes profile", profile.id,selectedUser);
  return (
    <>
      <div className="row no-gutters">
        <div className="col-md-8 therapist p-0">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" onClick={() => setSorting(sortBy)}>
                    Client Name{" "}
                    {sorting == "ASC" ? <FaAngleUp /> : <FaAngleDown />}
                  </th>
                  <th scope="col">Caretaker Member</th>
                  {
                    <th className="text-nowrap" scope="col">
                      Completed Appointment
                    </th>
                  }
                  {<th scope="col">Action</th>}
                </tr>
              </thead>

              {families.length > 0 ? (
                families &&
                families.map((item, index) => (
                  <>
                    <InterpreterChildRow
                      item={item}
                      subitem={item}
                      key={item?.familyMember?.id + "-" + index}
                      className={`cursor-pointer ${item?.parent?.id == rowclickid &&
                        item?.familyMember?.id === selectedChildId
                        ? "bg-light-cyan"
                        : ""
                        }`}
                      onClick={() => onClickRow(item?.parent?.id, item?.familyMember?.id, item?.parent)}
                    />
                    {/* <tbody key={index}>
                    {item.familyMembers &&
                      item.familyMembers.map((subitem, index) => (
                        <FamilyChildRow
                          item={item}
                          subitem={subitem}
                          key={subitem.id + "-" + index}
                          className={`cursor-pointer ${
                            item.id == rowclickid &&
                            subitem.id === selectedChildId
                              ? "bg-light-cyan"
                              : ""
                          }`}
                          onClick={() => onClickRow(item.id, subitem.id, item)}
                        />
                      ))}
                  </tbody> */}
                  </>
                ))
              ) : (
                <tbody>
                  <tr>
                    {<td></td>}
                    {<td></td>}
                    <td>
                      <div className="mt-5">
                        <h4>No Caretaker Available</h4>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          {loading ? (
            <></>
          ) : (
            <>
              {/* Pagination */}

              <div className="mt-10">
                <Pagination
                  total={totalpages}
                  current={currentPage}
                  onPageChange={(page) => paginate(page)}
                />
              </div>
              {/* <nav>
                <ul className="pagination justify-content-center">
                  <li onClick={() => paginatePre()} className="page-item">
                    {currentPage <= 0 ? (
                      <></>
                    ) : (
                      <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                        <img
                          className="pr-2"
                          src="../resources/assets/arrow-left.svg"
                        />
                      </div>
                    )}
                  </li>
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      onClick={() => paginate(number)}
                      className="page-item"
                    >
                      <div
                        className={`${
                          currentPage === number
                            ? "pagination-active page-link pagination"
                            : "page-link pagination cursor-pointer"
                        }`}
                      >
                        {number + 1}
                      </div>
                    </li>
                  ))}
                  <li onClick={() => paginateNext()} className="page-item">
                    {currentPage >= totalpages - 1 ? (
                      <></>
                    ) : (
                      <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                        <img
                          className="pr-2"
                          src="../resources/assets/arrow-right.svg"
                        />
                      </div>
                    )}
                  </li>
                </ul>
              </nav> */}
              {/* End */}
            </>
          )}
        </div>

        <div className="col-md-4 therapist table-th-merge-gap">
          <div className="table-responsive">
            <table className="table d-block">
              <thead className="d-block">
                <tr className="d-block">
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody className="bg-color1 d-block">
                <tr className="d-block">
                  {selectedUser && (
                    <td className="d-block">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {(
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="session-tab"
                              data-toggle="tab"
                              href="#session"
                              role="tab"
                              aria-controls="session"
                              aria-selected="true"
                            >
                              Sessions
                            </a>
                          </li>
                        )}
                        {(user_role === "ROLE_RCE" ||
                          user_role === "ROLE_REGIONAL_EARLY_STEPS") && (
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="team-tab"
                                data-toggle="tab"
                                href="#team"
                                role="tab"
                                aria-controls="team"
                                aria-selected="false"
                              >
                                Team
                              </a>
                            </li>
                          )}
                        {((
                          hasTherapistInFamily(profile.id, selectedUser))) && (
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="team-tab"
                                data-toggle="tab"
                                href="#team"
                                role="tab"
                                aria-controls="team"
                                aria-selected="false"
                              >
                                Messages
                              </a>
                            </li>
                          )}
                      </ul>
                      <div className="d-block tab-content" id="myTabContent">
                        {(
                          <div
                            className="tab-pane fade show active"
                            id="session"
                            role="tabpanel"
                            aria-labelledby="session-tab"
                          >
                            {loadings ? (
                              <div className="loader-small"></div>
                            ) : (
                              therapysessions.map((item, index) => (
                                <>
                                  <span className="row session-detail-date m-1">
                                    <b className="mr-2">
                                      {
                                        daysJson[
                                          parseInt(
                                            moment(item?.startDateTime).isoWeekday() === 0
                                              ? 6
                                              : moment(item?.startDateTime).isoWeekday() - 1
                                          )
                                        ].name
                                      }{" "}
                                      {moment(item?.startDateTime).format("DD")}{" "}
                                    </b>
                                    {
                                      monthJson[parseInt(moment(item?.startDateTime).format("MM")) - 1]
                                        .name
                                    }
                                    , {moment(item?.startDateTime).format("YYYY")}
                                  </span>
                                  <div
                                    className={classNames(
                                      `c-session-card session-detail-section`,
                                      `bg-white`,
                                      {
                                        [`c-session-card--${getStatusClassName(item?.status)}`]:
                                          getStatusClassName(item?.status),
                                      }
                                    )}
                                  >
                                    <StatusBadge status={item?.status} />
                                    <div className="row justify-content-between pr-5">
                                      <div className="col-auto">
                                        <div className="text-left">{item?.therapy?.name}</div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="user-info col-auto">
                                        <span className="admin-name">
                                          <img
                                            src={
                                              item?.therapist?.userProfile.profilePicUrl !== ""
                                                ? item?.therapist?.userProfile.profilePicUrl
                                                : "/resources/assets/defaultprofile.png"
                                            }
                                            className="image"
                                            alt=""
                                          />
                                          <div className="content text-left ml-2">
                                            <span>{item?.therapist?.userProfile.firstName + item?.therapist?.userProfile.lastName}</span>
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row justify-content-between">
                                      <div className="col-auto d-flex flex-wrap">
                                        <span className={`ts-time-interval p-0`}>
                                          <img
                                            className="pr-1"
                                            src={`/resources/assets/Icon-clock.svg`}
                                            alt=""
                                          />{" "}
                                          {getUTCTimeToLocalByDateWithMeridium(item?.startDateTime)} - {getUTCTimeToLocalByDateWithMeridium(item?.endDateTime)} | {calculateDuration(
                                            item?.startDateTime,
                                            item?.endDateTime
                                          )}
                                        </span>
                                      </div>

                                      {/* <div className="col-auto d-flex">
                                        {editLink && (
                                          <Link to={editLink}>
                                            <img
                                              className="pr-4"
                                              src={`/resources/assets/action-icon-edit.svg`}
                                              alt=""
                                            />
                                          </Link>
                                        )}
                                        {onCancel && (
                                          <Link onClick={onCancel}>
                                            <img
                                              src={`/resources/assets/cancel.png`}
                                              className="cancelled-icon"
                                              alt=""
                                            />
                                          </Link>
                                        )}
                                      </div> */}
                                    </div>
                                  </div>
                                  <hr/>
                                </>
                              ))
                              // <SessionsDetail
                              //   therapysessions={therapysessions}
                              //   successSub={() => successSub()}
                              //   bgColor="bg-color1"
                              //   page="family"
                              // />
                            )}
                          </div>
                        )}
                        {(user_role === "ROLE_RCE" ||
                          user_role === "ROLE_REGIONAL_EARLY_STEPS") && (
                            <div
                              className="tab-pane fade"
                              id="team"
                              role="tabpanel"
                              aria-labelledby="team-tab"
                            >
                              {loadings ? (
                                <div className="loader-small"></div>
                              ) : (
                                <TeamDetail
                                  teamData={teamdata}
                                  bgColor="bg-color1"
                                  page="family"
                                />
                              )}
                            </div>
                          )}
                        {((
                          hasTherapistInFamily(profile.id, selectedUser))) && (
                            <div
                              className={`d-block tab-pane fade`}
                              id="team"
                              role="tabpanel"
                              aria-labelledby="team-tab"
                            >
                              <Messages
                                otherChildId={selectedChildId}
                                otherUser={
                                  selectedUser?.userProfile
                                    ? selectedUser?.userProfile
                                    : selectedUser
                                }
                                bgColor="bg-color1"
                                page="family"
                              />
                            </div>
                          )}
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnBoardedInterpreter;
