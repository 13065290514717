const logIn = (userObj) => {
  return {
    type: "LOGIN",
    payload: userObj,
  };
};
const logInUserDetail = (userObj) => {
  return {
    type: "LogInUserDetail",
    payload: userObj,
  };
};
const stripeAccount = (userObj) => {
  return {
    type: "StripeAccount",
    payload: userObj,
  };
};
const billingInfo = (userObj) => {
  return {
    type: "BillingInfo",
    payload: userObj,
  };
};
const logOut = () => {
  return {
    type: "LOGOUT",
  };
};

const setTherapistFormData = (dataObj, formKey) => {
  return {
    type: "SET_THERAPIST_FORMDATA",
    payload: dataObj,
    formKey,
    formKey,
  };
};

const setFamilyFormData = (dataObj, formKey) => {
  return {
    type: "SET_FAMILY_FORMDATA",
    payload: dataObj,
    formKey,
    formKey,
  };
};

const setInterpreterFormData = (dataObj, formKey) => {
  return {
    type: "SET_INTERPRETER_FORMDATA",
    payload: dataObj,
    formKey,
    formKey,
  };
};

const clearOnboardData = () => {
  return {
    type: "CLEAR_ONBOARD_DATA",
  };
};

const setProfileData = (profile) => {
  return {
    type: "SET_PROFILE_DATA",
    payload: profile,
  };
};

const setTenantInfo = (tenantData) => {
  return {
    type: "SET_APP_TENANT",
    payload: tenantData,
  };
};

const setTenantPermission = (info) => {
  return {
    type: "TENANT_PERMISSION",
    payload: info,
  };
};

export default {
  billingInfo,
  logInUserDetail,
  logOut,
  logIn,
  setTherapistFormData,
  setFamilyFormData,
  clearOnboardData,
  setInterpreterFormData,
  setProfileData,
  setTenantInfo,
  setTenantPermission,
  stripeAccount,
};
