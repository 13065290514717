import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  calcWeekCount,
  getListOfDays,
  getUTCTimeToLocalByDate,
  getMeridiumByDate,
  showError,
  showSuccess,
  showWarning,
} from "../../utils/commonFunctions";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { daysJson, monthJson } from "../../config/Config";
import { adminService } from "../../services/adminService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import TimeInput from "../../components/TimeInput";

function EditSessionRequest(props) {
  let sessionid = props.sessioninfo.info.sessionid;
  const { register, formState: { errors }, handleSubmit } = useForm();
  let user = useSelector((state) => state.adminUser.user);
  let params = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  let [weekcount, setWeekCount] = useState(0);
  let currentRole = user.userDetails.roles[0];
  let today_date = new Date();
  const [inputs, setInputs] = useState({
    startDateTime: "", //2021-01-19T10:54:25.380Z
    endDateTime: "", //2021-01-19T10:54:25.380Z
    sessionId: sessionid,
    reason: "",
  });

  const [cancelAll, setcancelAll] = useState("ONE");

  const [dateInputs, setDateInputs] = useState({
    startTime: sessionid ? moment(today_date).format("hh:mm") : "07:00", //
    endTime: sessionid ? moment(today_date).format("hh:mm") : "07:00", // moment(today_date).format('hh:mm')
    session_date: today_date.toISOString().slice(0, 10),
    startMeridium: "am",
    endMeridium: "pm",
  });
  let [weeks, setWeeks] = useState(getListOfDays(weekcount));

  const getNextWeeK = () => {
    setWeeks(getListOfDays(weekcount + 1));
    setWeekCount(weekcount + 1);
  };

  const getPrevWeeK = () => {
    setWeeks(getListOfDays(weekcount - 1));
    setWeekCount(weekcount - 1);
  };

  const onSubmit = () => {
    let local_date_time = moment().format("YYYY-MM-DD hh:mm:ss a");
    let startUTCTime = moment(
      dateInputs.startTime + " " + dateInputs.startMeridium,
      ["h:mm A"]
    ).format("hh:mm:ss a");
    let startDateTimeUTC = moment(dateInputs.session_date + " " + startUTCTime);
    let timeInMin = startDateTimeUTC.diff(local_date_time, "minutes");
    let date_diff_minutes = moment(inputs.endDateTime).diff(
      moment(inputs.startDateTime),
      "minutes"
    );

    if (timeInMin <= 0) {
      showWarning("start_time_validation");
      return;
    }
    if (date_diff_minutes > 0 && date_diff_minutes < 30) {
      showWarning("time_diff_validation");
      return;
    }
    if (inputs.startDateTime >= inputs.endDateTime) {
      showWarning("start_end_time_validation");
      return;
    }
    if (sessionid) {
      inputs.sessionList = cancelAll;
      let putApi =
        user.userDetails.roles[0] === "ROLE_FAMILY"
          ? "family/sessions/"
          : "interpreter/sessions/";
      adminService
        .PUT(
          putApi + sessionid,
          JSON.stringify({
            ...inputs,
            timeZone: moment.tz.guess(),
          })
        )
        .then(
          (res) => {
            if (res.status === "Success") {
              toast.success(res.message);
              props.closeModal();
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };
  const onSubmitreq = () => {
    if (inputs.reason === "") {
      toast.warn("Reason is required!");
    } else {
      inputs.sessionList = cancelAll;
      adminService
        .PUT(
          `family/sessions/session-cancel-request/` + sessionid,
          JSON.stringify(inputs)
        )
        .then(
          (res) => {
            if (res.status === "Success") {
              toast.success(res.data);
              props.closeModal();
            } else {
              if (res.errorMessages.length > 0) {
                toast.success(res.errorMessages[0]);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const handleChangeTime = (e, name) => {
    setDateInputs((dateInputs) => ({
      ...dateInputs,
      [name]: moment(e._d).format("HH:mm"),
    }));
  };

  const handleChangeMeridium = (name, value) => {
    setDateInputs((dateInputs) => ({ ...dateInputs, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "session_date")
      setDateInputs((dateInputs) => ({ ...dateInputs, [name]: value }));
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const renderInput = (props, openCalendar) => {
    return (
      <div className="input-group ml-3">
        <img src="../resources/assets/clock.svg" onClick={openCalendar} />
        <input {...props} />
      </div>
    );
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.closeModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    if (sessionid) {
      let apiurl =
        user.userDetails.roles[0] === "ROLE_FAMILY"
          ? "family/sessions/"
          : "interpreter/sessions/";
      adminService.getAll(apiurl + sessionid).then(
        (res) => {
          if (res.status === "Success") {
            setDateInputs((dateInputs) => ({
              ...dateInputs,
              startTime: getUTCTimeToLocalByDate(res.data.startDateTime),
              endTime: getUTCTimeToLocalByDate(res.data.endDateTime),
              session_date: moment(
                moment.utc(res.data.startDateTime).local()
              ).format("YYYY-MM-DD"),
              startMeridium: getMeridiumByDate(res.data.startDateTime),
              endMeridium: getMeridiumByDate(res.data.endDateTime),
            }));
            setWeeks(getListOfDays(calcWeekCount(res.data.startDateTime)));
            setWeekCount(0);

            //setWeeks()
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error);
        }
      );
    }
  }, []);
  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  useEffect(() => {
    setDateInputs(dateInputs);
    let startUTCTime = moment(
      dateInputs.startTime + " " + dateInputs.startMeridium,
      ["h:mm A"]
    ).format("HH:mm:ss");
    let endUTCTime = moment(dateInputs.endTime + " " + dateInputs.endMeridium, [
      "h:mm A",
    ]).format("HH:mm:ss");
    let startDateTimeUTC = moment(dateInputs.session_date + " " + startUTCTime);
    let endDateTimeUTC = moment(dateInputs.session_date + " " + endUTCTime);
    let startDateUTC = moment.utc(startDateTimeUTC).toISOString();
    let endDateUTC = moment.utc(endDateTimeUTC).toISOString();
    setInputs((inputs) => ({ ...inputs, ["startDateTime"]: startDateUTC }));
    setInputs((inputs) => ({ ...inputs, ["endDateTime"]: endDateUTC }));
  }, [dateInputs]);

  useEffect(() => {
    setDateInputs(dateInputs);
  }, [dateInputs]);

  return (
    <>
      <form>
        <div className="col-md-2 custom-modal">
          <div
            className="modal fade add-new-prop-modal show "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered mb-10"
              role="document"
            >
              <div className="modal-content">
                <div
                  className={`${
                    props.isdelete ? "modal-header" : "headersession"
                  } d-block`}
                >
                  <div
                    className={
                      props.isdelete
                        ? "session-cancel-head"
                        : "session-edit-head"
                    }
                  >
                    <h5 className="modal-title name text-center">
                      {props.isdelete
                        ? "Session Cancel Request"
                        : "Session Edit Request"}
                    </h5>
                  </div>
                </div>

                <div className={props.isdelete ? "" : "row editsession"}>
                  {props.isdelete ? (
                    <>
                      <div className="row m-0">
                        <div className="col-md-12 d-flex mt-3">
                          <div className="d-flex align-items-center ml-4- mt-4">
                            <label></label>
                            <img
                              className=""
                              src="../resources/assets/Icon ionic-ios-calendar.svg"
                            />
                            <span className="selected-date ml-3">
                              <h5 className="modaldate mt-2">
                                {dateInputs.session_date.split("-")[2]}{" "}
                                {
                                  monthJson[
                                    parseInt(
                                      dateInputs.session_date.split("-")[1]
                                    ) - 1
                                  ].name
                                }{" "}
                                {dateInputs.session_date.split("-")[0]}
                              </h5>{" "}
                            </span>
                            <label></label>
                            <img
                              className="ml-4"
                              src="../resources/assets/clock.svg"
                            />
                            <span className="selected-date ml-3">
                              <h5 className="modaldate mt-2">
                                {dateInputs.startTime}
                                {dateInputs.startMeridium} to{" "}
                                {dateInputs.endTime}
                                {dateInputs.endMeridium}
                              </h5>
                            </span>
                          </div>
                          <br />
                        </div>
                      </div>
                      <div className="col-md-12">
                        {props?.sessioninfo?.sessionInfo?.recurringSession && (
                          <div className="form-group mb-20 mt-3">
                            <label>Remove Recurring Session</label>
                            <div className="custom-control custom-radio ">
                              <input
                                onChange={(e) => {
                                  setcancelAll(e.target.value);
                                }}
                                checked={cancelAll == "ONE" ? true : false}
                                type="radio"
                                id="radio-btn1"
                                value="ONE"
                                name="clearAll"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                for="radio-btn1"
                              >
                                This Session
                              </label>
                            </div>
                            <div className="custom-control custom-radio ">
                              <input
                                onChange={(e) => {
                                  setcancelAll(e.target.value);
                                }}
                                checked={
                                  cancelAll == "PROCEEDING" ? true : false
                                }
                                type="radio"
                                id="radio-btn2"
                                value="PROCEEDING"
                                name="clearAll"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                for="radio-btn2"
                              >
                                This and Following Sessions
                              </label>
                            </div>
                            <div className="custom-control custom-radio ">
                              <input
                                onChange={(e) => {
                                  setcancelAll(e.target.value);
                                }}
                                checked={cancelAll == "ALL" ? true : false}
                                type="radio"
                                id="radio-btn3"
                                value="ALL"
                                name="clearAll"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                for="radio-btn3"
                              >
                                All Upcoming Sessions
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 d-flex mt-3">
                        <tr>
                          <td>
                            <label className="name ml-3- mt-3">Reason </label>
                          </td>
                          <td>
                            &nbsp; (<span className="required-span">*</span>)
                          </td>
                        </tr>
                      </div>

                      <div className="col-md-12 ml-2- mb-10">
                        {/* <label className="name ml-4 mt-3">Reason</label> (<span className="required-span">*</span>)  */}
                        <textarea
                          className="form-control ml-2-"
                          //onChange={handleChange}
                          required
                          // {...register}
                          {...register('reason',{ onChange:(e)=>handleChange(e),required: true })}
                          type="text"
                          name="reason"
                        ></textarea>
                        {errors.reason && errors.reason.type === "required" && (
                          <div className="invalid-feedback-custom">
                            Reason is required
                          </div>
                        )}
                      </div>

                      <div className="col-md-12 text-center mb-5 mt-3">
                        <hr />
                        <div className="row">
                          <div className="col-md-6">
                            <button
                              type="button"
                              onClick={onSubmitreq}
                              className="btn btn-primary custom-btn-green submitreqbutton mr-3"
                            >
                              SUBMIT REQUEST
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              type="button"
                              className="btn btn-primary cancelsessionbutton ml-3"
                              onClick={() => props.closeModal(false)}
                            >
                              CLOSE
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-12">
                                                    <div className="d-flex align-items-center ml-4 mt-4">
                                                        <label></label>
                                                        <img className="" src='../resources/assets/Icon ionic-ios-calendar.svg' />
                                                        <span className="selected-date ml-3"><h5 className="modaldate mt-2">{dateInputs.session_date.split("-")[2]}  {monthJson[parseInt(dateInputs.session_date.split("-")[1]) - 1].name} {dateInputs.session_date.split("-")[0]}</h5> </span>
                                                        <label></label>
                                                        <img className="ml-4" src='../resources/assets/clock.svg' />
                                                        <span className="selected-date ml-3"><h5 className="modaldate mt-2">{dateInputs.startTime}{dateInputs.startMeridium} to {dateInputs.endTime}{dateInputs.endMeridium}</h5></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-11 ml-4 mt-4">
                                                    <label>Reason</label> (<span className="required-span">*</span>)
                                                    <textarea className="cancelreqtextarea form-control" onChange={handleChange} required {...register} {...register({ required: true })} type="text" name="reason"></textarea>
                                                    {errors.reason && errors.reason.type === "required" && (<div className="invalid-feedback-custom">Reason is required</div>)}
                                                </div>
                                                <div className="col-md-12 text-center mb-5 mt-3"><hr />
                                                    <button type="button" onClick={onSubmitreq} className="btn btn-primary custom-btn-green submitreqbutton mr-4">SUBMIT REQUEST</button>
                                                    <button type="button" className="btn btn-primary cancelsessionbutton ml-5" onClick={() => props.closeModal(false)}>CLOSE</button>
                                                </div> */}
                    </>
                  ) : (
                    <>
                      <div className="col-md-12 d-flex mt-3">
                        <div className="working-days">
                          {weekcount > 0 && (
                            <div className="col9 mr-4">
                              <button
                                onClick={() => getPrevWeeK()}
                                type="button"
                                className="btn Rectangle-691"
                              >
                                <img
                                  src="../resources/assets/arrow-left.svg"
                                  alt=""
                                />
                              </button>
                            </div>
                          )}
                          {weeks.length > 0 &&
                            weeks.map((day, index) => (
                              <label
                                className={`date-radio form-control ${
                                  moment().format("YYYY-MM-DD") >
                                  moment(day).format("YYYY-MM-DD")
                                    ? "disabled-dated-input"
                                    : ""
                                }`}
                                for="checkbox"
                              >
                                <input
                                  type="radio"
                                  id="radio"
                                  name="session_date"
                                  onChange={handleChange}
                                  value={day}
                                  disabled={
                                    new Date().getDate() >
                                    new Date(day).getDate()
                                      ? true
                                      : false
                                  }
                                  checked={
                                    new Date(day).getDate() ==
                                    new Date(dateInputs.session_date).getDate()
                                      ? true
                                      : false
                                  }
                                />
                                <div className="days">
                                  {" "}
                                  {daysJson[parseInt(index)].short}
                                  <br />
                                  {moment(day).format("DD")}
                                </div>
                              </label>
                            ))}
                          {
                            <div className="col9 ml-4">
                              <button
                                onClick={() => getNextWeeK()}
                                type="button"
                                className="btn Rectangle-691"
                              >
                                <img
                                  src="../resources/assets/arrow-right.svg"
                                  alt=""
                                />
                              </button>
                            </div>
                          }
                        </div>
                      </div>

                      <div className="col-md-8 mt-4 ml-3 add-session">
                        <div className="form-group d-flex align-items-center mb-10">
                          <label></label>
                          <img
                            className=""
                            src="../resources/assets/Icon ionic-ios-calendar.svg"
                          />
                          <h6 className="m-0 ml-4">You have selected -</h6>
                          <span className="selected-date ml-3">
                            {dateInputs.session_date.split("-")[2]}{" "}
                            {
                              monthJson[
                                parseInt(
                                  dateInputs.session_date.split("-")[1]
                                ) - 1
                              ].name
                            }{" "}
                            {dateInputs.session_date.split("-")[0]}{" "}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-12 ml-3 mt-3">
                        <div className="form-group mb-10">
                          <label>Working Time</label> (
                          <span className="required-span">*</span>)
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Start time</h6>
                              <div className="working-time d-flex">
                                <TimeInput
                                  onChange={(e) =>
                                    handleChangeTime(e, "startTime")
                                  }
                                  initialValue={dateInputs.startTime}
                                  value={dateInputs.startTime}
                                />
                                {/* <Datetime
                                                                    className="form-control-time"
                                                                    renderInput={renderInput}
                                                                    initialViewMode="time"
                                                                    updateOnView="time"
                                                                    timeFormat="hh:mm"
                                                                    timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                                    // initialValue={dateInputs.startTime}
                                                                    value={dateInputs.startTime}
                                                                    dateFormat={false}
                                                                    onChange={(e) => handleChangeTime(e, "startTime")}
                                                                /> */}
                                <div
                                  className="btn-group btn-group-toggle set-time-section ml-3"
                                  data-toggle="buttons"
                                >
                                  <label
                                    className={`btn ${
                                      dateInputs.startMeridium === "am" &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      handleChangeMeridium(
                                        "startMeridium",
                                        "am"
                                      )
                                    }
                                  >
                                    <input
                                      type="radio"
                                      value="am"
                                      name="startMeridium"
                                      autocomplete="off"
                                    />{" "}
                                    AM
                                  </label>

                                  <label
                                    className={`btn ${
                                      dateInputs.startMeridium === "pm" &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      handleChangeMeridium(
                                        "startMeridium",
                                        "pm"
                                      )
                                    }
                                  >
                                    <input
                                      type="radio"
                                      value="pm"
                                      name="startMeridium"
                                      autocomplete="off"
                                    />{" "}
                                    PM
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <h6>End time</h6>
                              <div className="working-time d-flex">
                                <div className="input-group ml-3">
                                  <TimeInput
                                    onChange={(e) =>
                                      handleChangeTime(e, "endTime")
                                    }
                                    initialValue={dateInputs.endTime}
                                    value={dateInputs.endTime}
                                  />
                                  {/* <Datetime
                                                                        className="form-control-time"
                                                                        renderInput={renderInput}
                                                                        initialViewMode="time"gti 
                                                                        updateOnView="time"
                                                                        timeFormat="hh:mm"
                                                                        timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                                        // initialValue={dateInputs.endTime}
                                                                        value={dateInputs.endTime}
                                                                        dateFormat={false}
                                                                        // inputProps={{name:"endTime"}}
                                                                        onChange={(e) => handleChangeTime(e, "endTime")}
                                                                    /> */}
                                </div>
                                <div
                                  className="btn-group btn-group-toggle set-time-section ml-3"
                                  data-toggle="buttons"
                                >
                                  <label
                                    className={`btn ${
                                      dateInputs.endMeridium === "am" &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      handleChangeMeridium("endMeridium", "am")
                                    }
                                  >
                                    <input
                                      type="radio"
                                      value="am"
                                      name="endMeridium"
                                      autocomplete="off"
                                    />{" "}
                                    AM
                                  </label>

                                  <label
                                    className={`btn ${
                                      dateInputs.endMeridium === "pm" &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      handleChangeMeridium("endMeridium", "pm")
                                    }
                                  >
                                    <input
                                      type="radio"
                                      value="pm"
                                      name="endMeridium"
                                      autocomplete="off"
                                    />{" "}
                                    PM
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 ml-3">
                        {props?.sessioninfo?.sessionInfo?.recurringSession && (
                          <div className="form-group mb-20 mt-3">
                            <label>Update Recurring Session</label>
                            <div className="custom-control custom-radio ">
                              <input
                                onChange={(e) => {
                                  setcancelAll(e.target.value);
                                }}
                                checked={cancelAll == "ONE" ? true : false}
                                type="radio"
                                id="radio-btn1"
                                value="ONE"
                                name="clearAll"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                for="radio-btn1"
                              >
                                This Session
                              </label>
                            </div>
                            <div className="custom-control custom-radio ">
                              <input
                                onChange={(e) => {
                                  setcancelAll(e.target.value);
                                }}
                                checked={
                                  cancelAll == "PROCEEDING" ? true : false
                                }
                                type="radio"
                                id="radio-btn2"
                                value="PROCEEDING"
                                name="clearAll"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                for="radio-btn2"
                              >
                                This and Following Sessions
                              </label>
                            </div>
                            <div className="custom-control custom-radio ">
                              <input
                                onChange={(e) => {
                                  setcancelAll(e.target.value);
                                }}
                                checked={cancelAll == "ALL" ? true : false}
                                type="radio"
                                id="radio-btn3"
                                value="ALL"
                                name="clearAll"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                for="radio-btn3"
                              >
                                All Upcoming Sessions
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 text-center mb-4">
                        <hr />
                        <button
                          type="button"
                          onClick={onSubmit}
                          className="btn btn-primary custom-btn-green submitreqbutton mr-4"
                        >
                          SUBMIT REQUEST
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary cancelsessionbutton ml-4"
                          onClick={() => props.closeModal(false)}
                        >
                          CLOSE
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditSessionRequest;
