import React, { useEffect, useState } from "react";
import emoji from "./images/png";
import animatedEmoji from "./images/gif";
import "./style.scss";
import { useSelector } from "react-redux";
const Emoji = () => {
  const [selected, setSelected] = useState(null);
  const [isAnimating, setIsAnimating] = useState(0);
  return (
    <div className="c-emoji">
      {selected && (
        <div
          className="c-emoji__image-wrapper"
          onAnimationEnd={() => {
            setIsAnimating(0);
          }}
          wobble={isAnimating}
        >
          <img
            src={animatedEmoji[selected]}
            alt={selected.split(".gif").join("")}
          />
        </div>
      )}
      <EmojiSender
        onClick={(item) => {
          setSelected(item);
          setIsAnimating(1);
        }}
      />
    </div>
  );
};

export const EmojiViewer = () => {
  const { visible } = useSelector((state) => state.emoji);
  return (
    <div className="c-emoji__holder">
      {visible.map((em) => {
        return (
          <div
            className="c-emoji__image-wrapper text-center"
            onAnimationEnd=""
            wobble="1"
          >
            <img src={animatedEmoji[em?.emoji?.content]} alt={em} />
            {em?.emoji?.sender && (
              <span className="c-emoji__sender-name text-capitalize">
                Sent by <br />
                {em.emoji.sender?.name || em.emoji.sender}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const EmojiSender = ({ onClick }) => {
  useEffect(() => {
    document.body.addEventListener("click", onBodyClick);
    document.addEventListener("keydown", onEsc);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
      document.removeEventListener("keydown", onEsc);
    };
  }, []);
  const onEsc = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };
  const onBodyClick = () => {
    setIsOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  const {
    relievedFace,
    smilingFaceWithHeartEyes,
    smilingFaceWithHearts,
    growingHeart,
    fireworks,
    collision,
    confettiBall,
    ...rest
  } = emoji;
  const main = {
    relievedFace,
    smilingFaceWithHeartEyes,
    smilingFaceWithHearts,
    growingHeart,
    fireworks,
    collision,
    confettiBall,
  };
  const onItemSelect = (item) => {
    setIsOpen(false);
    if (typeof onClick === "function") {
      onClick(item);
    }
  };
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        marginTop: 0,
        marginBottom: 0,
      }}
      className="c-emoji__sender d-flex"
    >
      {Object.keys(main).map((em) => {
        return (
          <React.Fragment key={em}>
            <button
              className="btn c-emoji__sender-btn"
              onClick={() => onItemSelect(em)}
            >
              <img src={emoji[em]} alt={em} />
            </button>
            {/* <br />{em} */}
          </React.Fragment>
        );
      })}
      <button
        className="btn c-emoji__sender-btn--more"
        onClick={() => setIsOpen((o) => !o)}
      >
        <span />
      </button>
      {isOpen && (
        <div className="c-emoji__sender-list-wrapper shadow">
          <div className="c-emoji__sender-list shadow">
            {Object.keys(rest).map((em) => {
              return (
                <React.Fragment key={em}>
                  <button
                    className="btn c-emoji__sender-btn"
                    onClick={() => onItemSelect(em)}
                  >
                    <img src={emoji[em]} alt={em} />
                  </button>
                  {/* <br />{em} */}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Emoji;
