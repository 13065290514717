import React, { useState } from "react";
import moment from "moment";
import ZoomAttachmentDto from "../../pages/Family/ZoomAttachmentDto";
import ResourceRow from "./ResourceRow";

const MyResourceTab = ({
  resources,
  resourceList,
  resourceSortBy,
  setResourceSortBy,
}) => {
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [detail, setDetail] = useState({
    details: "",
  });

  const Zoom = (date, resourceType, key, type) => {
    let details = {
      date: date,
      resourceType: resourceType,
      key: key,
      type,
    };
    setModalOpenClose(true);
    setDetail((detail) => ({ ...detail, ["details"]: details }));
  };

  return (
    <div className="p-4">
      {/* <div className="row">
        <div className="col-md-6 mb-3">
          <select
            className={`custom-select`}
            onChange={(e) => setResourceSortBy(e.target.value)}
            value={resourceSortBy}
            defaultValue={resourceSortBy}
            name="sortBy"
          >
            <option disabled selected>
              Sort by
            </option>
            <option value="dateAsc">Date in ASC</option>
            <option value="dateDesc">Date in DESC</option>
          </select>
        </div>
      </div> */}
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Session/Queries/Chat</th>
              <th scope="col" className="text-center">
                Resources
              </th>
            </tr>
          </thead>
          <tbody>
            {resources &&
              resources.map((item) => (
                <tr className={`cursor-pointer`}>
                  <th>
                    <div className="ml-3 mt-3 mb-3">{item.date}</div>
                  </th>
                  <td>
                    <div className="mt-3 mb-3">
                      <p className="name">{item.resourceType} </p>
                    </div>
                  </td>
                  <td>
                    <div className="mt-3 mb-3">
                      <img
                        src={item.attachmentDTO.key}
                        onClick={() =>
                          Zoom(
                            item.date,
                            item.resourceType,
                            item.attachmentDTO.key
                          )
                        }
                        alt=""
                        className="resources-image"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            {
              // Object.keys(resourceMap).map(item => {
              //     const data = {
              //         rowclickid,
              //         id: item.id,
              //         date: item.date,
              //         resourceType:  item.toLowerCase().replaceAll('_', ' '),
              //         Zoom,
              //         // key
              //         resourceList: resourceMap[item]
              //     }
              //     return <ResourceRow {...data} />
              // })
            }
            {Object.keys(resourceList)
              .sort((a, b) => {
                const getTimeInt = (str) => {
                  const arr = str
                    .split(",")
                    .join(" ")
                    .split(" ")
                    .filter(Boolean);
                  return moment(`${arr[2]}-${arr[0]}-${arr[1]}`).format("X");
                };
                return getTimeInt(b) - getTimeInt(a);
              })
              .map((item, index) => {
                const cItem = resourceList[item];
                return Object.keys(cItem).map((itx) => {
                  const data = {
                    id: index,
                    date: item,
                    resourceType: itx.toLowerCase().replaceAll("_", " "),
                    Zoom,
                    // key
                    resourceList: cItem[itx],
                  };
                  return <ResourceRow {...data} />;
                });
              })}
          </tbody>
        </table>
        {resources?.length == 0 && (
        <h5 className="text-center p-3">No Data Found</h5>
      )}
        {modalOpenClose && (
          <ZoomAttachmentDto
            detail={detail}
            closeModal={() => setModalOpenClose(false)}
          />
        )}
      </div>
    </div>
  );
};

export default MyResourceTab;
