import React, { useEffect, useState } from "react";
import { getFormsByChild } from "../../services/formsServices";
import { _toastHandleErr } from "../../utils/commonFunctions";
import FormReplyItem from "./FormReplyItem";

const ChildFormList = (props) => {
  const [state, setState] = useState({
    forms: [],
  });
  useEffect(() => {
    getForms(props.id, props?.sortBy);
  }, [props.id, props.sortBy]);

  const getForms = (id, sortBy = "dateDesc") => {
    const sort =
      sortBy == "dateAsc"
        ? `sort=createdDate,ASC`
        : sortBy == "dateDesc"
          ? `sort=createdDate,DESC`
          : sortBy == "titleAsc"
            ? `sort=fileName,ASC`
            : sortBy == "titleDesc"
              ? `sort=fileName,DESC`
              : `sort=createdDate,DESC`;

    if (id) {
      getFormsByChild(id, sort)
        .then((res) => {
          setState((state) => ({ ...state, forms: res }));
        })
        .catch(_toastHandleErr);
    }
  };

  if (!props.id) return null;

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Title</th>
            <th scope="col">Resources</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {state?.forms?.content &&
            state.forms.content.map((form) => {
              return (
                <FormReplyItem
                  afterSuccess={() => {
                    getForms(props.id);
                  }}
                  key={form.id}
                  childId={props.id}
                  form={form}
                />
              );
            }

            )}

        </tbody>
      </table>
      {state.forms.content == null && (
        <h5 className="text-center p-3">No Data Found</h5>
      )}
    </>
  );
};

export default ChildFormList;
