import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

import MediaPlayer from "./components/MediaPlayer";
// import "./Call.css";
import "../../pages/AgoraExternalVideo/Call.css";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { useFormContext } from "../../components/forms/FormContext";
import DemoAppSocket from "../../components/socket/DemoAppSocket";
import { useSelector } from "react-redux";
import AppTooltip from "../../components/app-tooltip/AppTooltip";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { emitEvent } from "../../hooks";
import MarketingUseAgora from "../AgoraExternalVideo/hooks/MarketingUseAgora";
import AgoraUi from "./MarketingAgoraUi";

const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });
console.log("hello", client);

const screenClient = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });

function MarketingCall(props: any) {
  const history = useNavigate();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const AppId = urlParams.get("id");
  const channelId = urlParams.get("channel");
  const tokenId = urlParams.get("token");
  const { agoraInfo, setAgoraInfo } = useFormContext();
  //let user = useSelector((state) => state.adminUser.profile);
  let user = props.profile;
  const [isPin, setIsPin] = useState(false);

  const sessionId = urlParams.get("session");
  const [appid, setAppid] = useState(AppId);
  const [token, setToken] = useState(tokenId);
  const [channel, setChannel] = useState(channelId);

  const [isScreen, setIsScreen] = useState(false);
  const [isOwnScreen, setIsOwnScreen] = useState(false);
  const [tokenDetail, setTokenDetail] = useState({});

  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    joinState,
    remoteUsers,
    handleAudio,
    handleVideo,
    handleScreen,
    leaveScreen,
    screenTrack,
  } = MarketingUseAgora(client, screenClient, setIsScreen, setIsOwnScreen, setIsPin);

  // const appAgoraState = useSelector((state) => state.agoraDetail);
  const [newAudioUser, setNewAudioUser] = useState([]);
  const [newVideoUser, setNewVideoUser] = useState([]);
  const profile = useSelector((state) => state.adminUser.profile);
  console.log("profile", profile?.userType, remoteUsers);

  const valuex = localStorage.getItem("user");

  const isUserLoggedIn =
    typeof valuex === "string"
      ? JSON.parse(valuex).accessToken
      : valuex?.accessToken || null;

  useEffect(() => {
    adminService
      .POST(
        `marketing/agora-tokens/${sessionId || props?.id}`,
        JSON.stringify({
          email: isUserLoggedIn ? "" : props.email,
        })
      )
      .then(
        (res) => {
          if (res?.status === "Success") {
            setTimeout(() => {
              setTokenDetail(res.data);
              setAppid(res.data.appId.trim());
              setToken(res.data.token.trim());
              setChannel(res.data.channelName.trim());
            }, 1000);
          } else {
            toast.error(res?.message || res?.error || res);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    if (isUserLoggedIn && profile?.userType == "ROLE_RCE") {
      adminService
        .PUT(
          `marketing/sessions/change-status/${
            sessionId || props?.id
          }/IN_PROGRESS`
        )
        .then((res) => {
          console.log("meeting status updated to inprogress");
        });
    }
  }, [sessionId]);

  const handleUser = (id, callFor = "audio") => {
    console.log("screen user detail", id, callFor);
    if (callFor === "audio") {
      if (newAudioUser.includes(id)) {
        const arr = newAudioUser.reduce((acc, curr) => {
          if (curr !== id) acc.push(curr);
          return acc;
        }, []);
        setNewAudioUser(arr);
        handleAudio("");
      } else {
        setNewAudioUser([...newAudioUser, id]);
        handleAudio(undefined);
      }
    } else if (callFor === "video") {
      if (newVideoUser.includes(id)) {
        const arr = newVideoUser.reduce((acc, curr) => {
          if (curr !== id) acc.push(curr);
          return acc;
        }, []);
        setNewVideoUser(arr);
        handleVideo("");
      } else {
        setNewVideoUser([...newVideoUser, id]);
        handleVideo(undefined);
      }
    } else if (callFor === "screen") {
      if (isScreen || isOwnScreen) {
        setIsScreen(false);
        setIsOwnScreen(false);
        // console.log("appAgoraState?.screens", appAgoraState?.screens);
        // emitEvent("agoraScreenShare", {
        //   isEnabled: false,
        // });
        leaveScreen();
      } else {
        // setIsScreen(id * 2);
        leaveScreen();

        handleScreen(appid, channel, token, id);
        console.log("handle screen", appid, channel, token, id);
      }
    }
  };

  // useEffect(() => {

  // }, [isScreen]);
  return (
    <div className="">
      {/* <DemoAppSocket agoraInfo={agoraInfo} setAgoraInfo={setAgoraInfo} /> */}

      {console.log("appsock", client.uid)}

      <AgoraUi
        tokenDetail={tokenDetail}
        isShowJoinBtn={props?.isShowJoinBtn}
        setIsStarted={props?.setIsStarted}
        join={join}
        appid={appid}
        channel={channel}
        token={token}
        userId={user?.id}
        localVideoTrack={localVideoTrack}
        joinState={joinState}
        clientId={client.uid}
        leave={leave}
        newVideoUser={newVideoUser}
        newAudioUser={newAudioUser}
        isOwnScreen={isOwnScreen}
        setIsOwnScreen={setIsOwnScreen}
        isScreen={isScreen}
        setIsScreen={setIsScreen}
        remoteUsers={remoteUsers}
        handleUser={handleUser}
        localAudioTrack={localAudioTrack}
        screenTrack={screenTrack}
        screenClient={screenClient}
        profile={user}
        setJoinedUsers={props?.setJoinedUsers}
        joinedUsers={props?.joinedUsers}
        profileInfo={props?.profile}
        sessionId={props?.sessionId}
        {...props?.therapyData}
        onLeave={props?.onLeave}
        postCaseNote={props?.postCaseNote}
        countDownTimer={props?.countDownTimer}
        permissionForAll={props?.permissionForAll}
        setPermissionForAll={props?.setPermissionForAll}
        setSessionDetail={props?.setSessionDetail}
        sessionDetail={props?.sessionDetail}
        selectedMembers={props?.selectedMembers}
        setSelectedMembers={props?.setSelectedMembers}
        isPin={isPin}
        setIsPin={setIsPin}
        recId={props?.recId}
        recToken={props?.recToken}
      />

      {/* <form className="call-form">
        <label>
          AppID:
          <input
            type="text"
            defaultValue={appid}
            name="appid"
            onChange={(event) => {
              setAppid(event.target.value);
            }}
          />
        </label>
        <label>
          Token(Optional):
          <input
            type="text"
            defaultValue={token}
            name="token"
            onChange={(event) => {
              setToken(event.target.value);
            }}
          />
        </label>
        <label>
          Channel:
          <input
            type="text"
            defaultValue={channel}
            name="channel"
            onChange={(event) => {
              setChannel(event.target.value);
            }}
          />
        </label>
        {appid && token && channel && (
          <div className="button-group">
            <button
              id="join"
              type="button"
              className="btn btn-primary btn-sm"
              disabled={joinState}
              onClick={() => {
                props?.setIsStarted(true);
                join(appid, channel, token, user?.id);
              }}
            >
              Join
            </button>
            <button
              id="leave"
              type="button"
              className="btn btn-primary btn-sm"
              disabled={!joinState}
              onClick={() => {
                leave();
              }}
            >
              Leave
            </button>
          </div>
        )}
      </form>
      <div className="player-container">
        <div className="inline-block local-player-wrapper">
          <p className="local-player-text">
            {localVideoTrack && `localTrack`}
            {joinState && localVideoTrack ? `(${client.uid})` : ""}
          </p>
          <MediaPlayer
            videoTrack={
              newVideoUser.includes(client.uid) ? undefined : localVideoTrack
            }
            audioTrack={
              newAudioUser.includes(client.uid) ? undefined : localAudioTrack
            }
            clientUid={client.uid}
            handleUser={handleUser}
            userType="local"
            isScreen={isScreen}
          ></MediaPlayer>
        </div>
        {remoteUsers.map((user) => {
          return (
            <div className="inline-block remote-player-wrapper" key={user.uid}>
              <p className="remote-player-text">{`remoteVideo(${user.uid})`}</p>
              {console.log("object user.audioTrack--", user.audioTrack)}
              <MediaPlayer
                videoTrack={
                  newVideoUser.includes(user.uid) ? undefined : user.videoTrack
                }
                audioTrack={
                  newVideoUser.includes(user.uid) ? undefined : user.audioTrack
                }
                clientUid={user.uid}
                handleUser={handleUser}
                userType="remote"
                isScreen={false}
              ></MediaPlayer>
            </div>
          );
        })}
      </div> */}
    </div>
  );
}

export default MarketingCall;
