import {emogiTypes} from '../types'

const showEmojiIn = (emoji) => {
    return {
        type: emogiTypes.showEmoji,
        payload: {
            emoji,
            timestamp: Date.now()
        }
    }
}
const hideEmoji = (emojiObj) => {
    return {
        type: emogiTypes.hideEmoji,
        payload: {
            ...emojiObj
        }
    }
}

export const showEmoji = (emoji) => (dispatch, getState) => {
    const toastAction = showEmojiIn(emoji);
    dispatch(toastAction);
    setTimeout(() => dispatch(hideEmoji(toastAction.payload)), 3500);
  };

const emojiActions = {
    showEmoji,
    hideEmoji,
}
export default emojiActions
