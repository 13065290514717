import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nameRegex, decimalRegex, spaceRegex, alphanumericRegx } from "../../../components/Utils/Regex";
//import TextInput from "../../../components/Admin/Inputs/TextInput";
import { adminService } from "../../../services/adminService";
import { toast } from "react-toastify";
import usePageState from "../../../hooks/InitialState";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function AddCptCode() {
    const { setPageState, pageState } = usePageState();
    //console.warn("hook", pageState);
    let history = useNavigate();
    const params = useParams();
    const {
        register,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm();
    const [inputs, setInputs] = useState({ cptFrequencies: null });
    const [therapyList, setTherapyList] = useState([]);

    useEffect(() => {
        getTherapyTypes();
    }, []);

    const getTherapyTypes = () => {
        adminService.getAll(`therapies`)
            .then((res) => {
                if (res.status == "Success") {
                    setTherapyList(res?.data.content);
                }
            })
            .catch((err) => toast.error(err));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("name value", name, value);
        //console.log("trim code",inputs.procCode.trim())
        //inputs.procCode = inputs.procCode.trim();
        setInputs({
            ...inputs,
            [name]:
                name == "encounter"
                    ? value == "true"
                        ? true
                        : false
                    : name == "duration"
                        ? parseInt(value)
                        : name == "maxFee"
                            ? parseFloat(value)
                            : name == "therapyId"
                                ? parseInt(value)
                                : value,
        });

        console.log(watch(name), value, errors);
        if (name == "description" && value.length > 100) {
            //alert("error");
            setError(name, {
                type: "maxLength",
                message: "Max 100 characters.",
            });
        }

        if (name == "description" && value.length < 101 && value.length > 1) {
            //alert;
            clearErrors("description");
        }
        console.log("inputs filed", inputs);
    };

    const onSubmit = () => {
        setPageState((item) => ({ ...item, loading: true }));
        inputs.procCode = inputs.procCode.trim();
        inputs.description = inputs.description.trim();
        // if(inputs.description.trim().length === 0)
        // {
        //     toast.error("valid description require");
        // }else{
        //     inputs.description=inputs.description.trim();
        // }
        const fn = adminService.POST(`cpts`, JSON.stringify(inputs));

        fn.then((res) => {
            setPageState((item) => ({ ...item, loading: false }));
            if (res.status == "Success") {
                console.log("response test", res);
                res?.data && setInputs(res?.data);
                toast.success(res?.data);
                history("/cpt-code");
            } else {
                toast.error(res?.message || "Something went wrong");
            }
        }).catch((err) => {
            setPageState((item) => ({ ...item, loading: false }));
            toast.error(err);
        });
    };
    return (
        <div className="col-md-10 no-padding ContentArea theropySession">
            <div className="no-padding dash-billing-info mr-0 flex-grow-1">
                <div className="custom-table-view min-h-100">
                    <div class="content-header">
                        <div class="text-left header-title pt-7px">{params?.id ? "Edit" : "Add"} CPT Code</div>
                        <div class="text-right header-btn">
                        </div>
                    </div>
                        <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-6 form-group mb-3 add-session-form">
                                    <label>
                                        Therapy Name <span className="required-span">*</span>
                                    </label>
                                    <div className="input-group">
                                        <select
                                            // {...register("therapyId", {
                                            //     required: true,
                                            // })}
                                            {...register('therapyId',{ onChange:(e)=>handleChange(e),required: true })}
                                            className={`custom-select ${errors.therapyId && "is-invalid"
                                                }`}
                                            id="therapyId"
                                            //onChange={handleChange}
                                            defaultValue={inputs?.therapyId}
                                            name="therapyId"
                                            disabled={params?.id ? "disabled" : ""}
                                        >
                                            <option value="">Select Therapy</option>
                                            {therapyList.length > 0 &&
                                                therapyList.map((th) => {
                                                    return <option value={th.id}>{th.name}</option>;
                                                })}
                                        </select>
                                        <div className="input-group-prepend">
                                            <img
                                                className="m-auto"
                                                src="../resources/assets/Polygon 1.svg"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    {errors.therapyId && (
                                        <span className="fieldError">This field is required.</span>
                                    )}
                                </div>
                                <div className="col-md-6 form-group mb-3">
                                    <label>
                                        Proc Code <span className="required-span">*</span>
                                    </label>
                                    <input
                                        name="procCode"
                                        //onChange={(e) => handleChange(e)}
                                        {...register('procCode',{ onChange:(e)=>handleChange(e),required: true, pattern: alphanumericRegx })}
                                        defaultValue={inputs?.procCode}
                                        placeholder="Enter Proc Code"
                                        className={`form-control ${errors?.procCode && "is-invalid"
                                            }`}
                                    />
                                    {errors.procCode && (
                                        <span className="fieldError">
                                            {errors.procCode?.message ||
                                                "This field is required."}
                                        </span>
                                    )}
                                    {/* <TextInput
                                label="Proc Code"
                                name="procCode"
                                {...register({ required: true })}
                                handleChange={handleChange}
                                // require={{
                                //     ...register("procCode", {
                                //         required: true,
                                //     }),
                                // }}
                                //{...register({ required: true})}
                                value={inputs?.procCode}
                                err={errors?.procCode}
                                isRequire={true}
                                placeholder="Proc Code"
                                disabled={params?.id ? true : false}
                            /> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 form-group mb-3 add-session-form">
                                    <label>Encounter</label>
                                    <div className="input-group">
                                        <select
                                            // {...register("encounter", {
                                            //     //required: true,
                                            // })}
                                            className={`custom-select ${errors.encounter && "is-invalid"
                                                }`}
                                            onChange={handleChange}
                                            defaultValue={inputs?.encounter}
                                            name="encounter"
                                        >
                                            <option value="">Select Encounter</option>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                        </select>
                                        <div className="input-group-prepend">
                                            <img
                                                className="m-auto"
                                                src="../resources/assets/Polygon 1.svg"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3 form-group">
                                    <label>Dur/Min</label>
                                    <input
                                        name="duration"
                                        type="number"
                                        //handleChange={handleChange}
                                        {...register('duration',{
                                            onChange:(e)=>handleChange(e),
                                            //required: true,
                                            valueAsNumber: true,
                                        })
                                        }
                                        className="form-control"
                                        defaultValue={inputs?.duration}
                                        //err={errors?.duration}
                                        //isRequire={true}
                                        placeholder="Duration"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3 form-group">
                                    <label>NOC</label>
                                    <input
                                        name="noc"
                                        onChange={(e)=>handleChange(e)}
                                        // require={{
                                        //     ...register("noc", {
                                        //         //required: true,
                                        //     }),
                                        // }}
                                        defaultValue={inputs?.noc}
                                        className="form-control"
                                        //err={errors?.noc}
                                        //isRequire={true}
                                        placeholder="NOC"
                                    />
                                </div>
                                <div className="col-md-6 mb-3 form-group">
                                    <label>Reporting System</label>
                                    <input
                                        name="reportingSystem"
                                        onChange={(e)=>handleChange(e)}
                                        className="form-control"
                                        // require={{
                                        //     ...register("reportingSystem", {
                                        //         //required: true,
                                        //     }),
                                        // }}
                                        defaultValue={inputs?.reportingSystem}
                                        //err={errors?.reportingSystem}
                                        //isRequire={true}
                                        placeholder="Reporting System"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {/* <div className="col-md-6">
                            <input
                                label="Frequency Factor"
                                name="frequencyFactor"
                                handleChange={handleChange}
                                require={{
                                    ...register("frequencyFactor", {
                                        //required: false,
                                    }),
                                }}
                                value={null}
                                err={errors?.frequencyFactor}
                                //isRequire={false}
                                placeholder="Frequency Factor"
                            />
                        </div> */}
                                <div className="col-md-6 mb-3 form-group">
                                    <label>Max Fee</label>
                                    <input
                                        name="maxFee"
                                        // type="number"
                                        //handleChange={handleChange}
                                        {...register('maxFee',{
                                            onChange:(e)=>handleChange(e),
                                                //required: true,
                                                pattern: decimalRegex,
                                            })
                                        }
                                        defaultValue={inputs?.maxFee}
                                        className="form-control"
                                        // err={errors?.maxFee}
                                        //isRequire={true}
                                        placeholder="Max Fee"
                                        step="0.1"
                                    />
                                    {errors.maxFee && (
                                        <span className="fieldError">
                                            {errors.maxFee?.message ||
                                                "Please enter decimal value."}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-12 form-group mb-3">
                                    <label>
                                        Description <span className="required-span">*</span>
                                    </label>
                                    <textarea
                                        {...register('description',{ onChange:(e)=>handleChange(e),required: true, maxLength: 100, pattern: alphanumericRegx })}
                                        name="description"
                                        className={`form-control h-100 ${errors?.description && "is-invalid"
                                            }`}
                                        //onChange={(e) => handleChange(e)}
                                        defaultValue={inputs?.description}
                                        placeholder="Enter description"
                                    />
                                    {errors.description && (
                                        <span className="fieldError">
                                            {errors.description?.message || "This field is required."}
                                        </span>
                                    )}
                                </div>

                                {/* {params?.id && (
                            <div className="col-md-12 form-group my-3 ">
                                <label className="mr-4">Status</label>

                                <BootstrapSwitchButton
                                    checked={inputs?.active}
                                    onstyle="outline-primary"
                                    offstyle="outline-danger"
                                    onlabel="Active"
                                    offlabel="Inactive"
                                    width={100}
                                    onChange={(checked) => {
                                        //alert(checked);
                                        setInputs((item) => ({ ...item, active: checked }));
                                    }}
                                />
                            </div>
                        )} */}
                            </div>
                            <div className="row mt-10">
                                <div className="col-md-12">
                                    <button
                                        type="submit"
                                        className="btn btn-theme-green"
                                        style={{ minWidth: 100 }}
                                    >
                                        {pageState?.loading ? (
                                            <div className="d-flex justify-content-center">
                                                <span className="myLoader light sm d-inline"></span>
                                            </div>
                                        ) : (
                                            <span>Submit</span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                </div>
            </div>
        </div>
    );
}

export default AddCptCode;
