import React from 'react';

function ShortTermsGoal(props) {
    console.log("shortTermGoals", props.shortTermGoals);
    let AreaofConcern = '';
    let Longdescription = '';
    let achievedPercentage = '';
    let key = '';
    //const [shortTermGoalsData, setshortTermGoalsData] = useState();
    // setshortTermGoalsData(props.shortTermGoals);
    let htmlContent = "";
    if(props.shortTermGoals){
    props.shortTermGoals.forEach(function(item,index){
        AreaofConcern = item.concern;
        Longdescription = item.description;
        achievedPercentage = item.achievedPercentage;
        //setshortTermGoalsData(index);
        htmlContent+= `<tr style={{ display: "table-row" }} key=${index}>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${index + 1}</td>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${AreaofConcern}</td>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${Longdescription}</td>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${achievedPercentage}</td>
    </tr>`;
        
        console.log("procedire item",index,key);
       // return key = index;
    })}
    console.log("procCode",key);
    return (
        <>
            <table className='procedureTable longGoalTable' style={{ width: "100%", display: "table", borderCollapse: "collapse", marginBottom: "10px" }}>
                    <thead style={{ display: "table-header-group" }}>
                    <tr style={{ display: "table-row" }}>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>S.No</th>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>Area of Concern</th>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>Short Term Description</th>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>Achieved ( % )</th>
                        </tr>
                    </thead>
                    <tbody style={{ display: "table-body-group" }} dangerouslySetInnerHTML={{__html: htmlContent}}>
                    
                    </tbody>
                </table>
        </>
    );
}

export default ShortTermsGoal;