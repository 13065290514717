import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation,Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { nameRegex, decimalRegex, spaceRegex, alphanumericRegx } from "../../../components/Utils/Regex";
//import TextInput from "../../../components/Admin/Inputs/TextInput";
import { adminService } from "../../../services/adminService";
import { toast } from "react-toastify";
import usePageState from "../../../hooks/InitialState";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function EditCptCode() {
    const { setPageState, pageState } = usePageState();
    //console.warn("hook", pageState);
    let history = useNavigate();
    const params = useParams();
    const {
        register,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm();
    const [inputs, setInputs] = useState({ cptFrequencies: null });
    //const [therapyList, setTherapyList] = useState([]);

    useEffect(() => {
        //getTherapyTypes();
        params?.id && getCptDetail(params?.id);
    }, []);

    // const getTherapyTypes = () => {
    //     adminService.getAll(`therapies`)
    //         .then((res) => {
    //             if (res.status == "Success") {
    //                 setTherapyList(res?.data.content);
    //             }
    //         })
    //         .catch((err) => toast.error(err));
    // };
    const getCptDetail = (id) => {
        adminService.getAll(`cpts/${id}`)
            .then((res) => {
                if (res.status == "Success") {
                    const data = res?.data;
                    setInputs({ ...inputs, ...data, therapyId: res?.data.therapyDto.id });
                    // setInputs({ ...inputs, therapy: res?.data.therapyDto.id });
                    setValue("duration", res?.data.duration);
                    setValue("maxFee", res?.data.maxFee);
                    setValue("description", res?.data.description);
                } else {
                    toast.error(res?.message || "Something went wrong");
                }
            })
            .catch((err) => toast.error(err));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("name value", name, value);
        //console.log("trim code",inputs.procCode.trim())
        //inputs.procCode = inputs.procCode.trim();
        setInputs({
            ...inputs,
            [name]:
                name == "duration"
                    ? parseInt(value)
                    : name == "maxFee"
                        ? parseFloat(value)
                        : name == "therapyId"
                            ? parseInt(value)
                            : value,
        });

        console.log(watch(name), value, errors,inputs);
        if (name == "description" && value.length > 100) {
            //alert("error");
            setError(name, {
                type: "maxLength",
                message: "Max 100 characters.",
            });
        }

        if (name == "description" && value.length < 101 && value.length > 1) {
            //alert;
            clearErrors("description");
        }
        //console.log("inputs filed", inputs);
    };

    const onSubmit = () => {
        console.log("cpt code", inputs);
        setPageState((item) => ({ ...item, loading: true }));
        //inputs.procCode = inputs.procCode.trim();
        inputs.description = inputs.description.trim();
        // if(inputs.description.trim().length === 0)
        // {
        //     toast.error("valid description require");
        // }else{
        //     inputs.description=inputs.description.trim();
        // }
        const fn = adminService.PUT(`cpts/${params?.id}`, JSON.stringify(inputs));

        fn.then((res) => {
            setPageState((item) => ({ ...item, loading: false }));
            if (res.status == "Success") {
                console.log("response test", res);
                res?.data && setInputs(res?.data);
                toast.success(res?.data);
                history("/cpt-code");
            } else {
                toast.error(res?.message || "Something went wrong");
            }
        }).catch((err) => {
            setPageState((item) => ({ ...item, loading: false }));
            toast.error(err);
        });
    };
    return (
        <div className="col-md-10 no-padding ContentArea theropySession">
            <div className="no-padding dash-billing-info mr-0 flex-grow-1">
                <div className="custom-table-view min-h-100">
                    <div class="content-header">
                        <div class="text-left header-title pt-7px">{params?.id ? "Edit" : "Add"} CPT Code</div>
                        <div class="text-right header-btn">
                        </div>
                    </div>
                    <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6 mb-3 form-group">
                                <label>Dur/Min</label>
                                <input
                                    name="duration"
                                    type="number"
                                    
                                    {...register('duration',{
                                        //required: true,
                                        valueAsNumber: true,
                                        onChange:(e) => handleChange(e)
                                    })
                                    }
                                    className="form-control"
                                    defaultValue={inputs?.duration}
                                    //err={errors?.duration}
                                    //isRequire={true}
                                    placeholder="Duration"
                                />
                            </div>
                            <div className="col-md-6 mb-3 form-group">
                                <label>Max Fee</label>
                                <input
                                    name="maxFee"
                                    // type="number"
                                    //onChange={(e) => handleChange(e)}
                                    {...register('maxFee',{
                                            //required: true,
                                            pattern: decimalRegex,
                                            onChange:(e) => handleChange(e)
                                        })
                                    }
                                    defaultValue={inputs?.maxFee}
                                    className="form-control"
                                    // err={errors?.maxFee}
                                    //isRequire={true}
                                    placeholder="Max Fee"
                                    step="0.1"
                                />
                                {errors.maxFee && (
                                    <span className="fieldError">
                                        {errors.maxFee?.message ||
                                            "Please enter decimal value."}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-md-12 form-group mb-3">
                                <label>
                                    Description <span className="required-span">*</span>
                                </label>
                                <textarea
                                    {...register('description',{ required: true, maxLength: 100,onChange:(e) => handleChange(e) })}
                                    name="description"
                                    className={`form-control h-100 ${errors?.description && "is-invalid"
                                        }`}
                                    //onChange={(e) => handleChange(e)}
                                    defaultValue={inputs?.description}
                                    placeholder="Enter description"
                                />
                                {errors.description && (
                                    <span className="fieldError">
                                        {errors.description?.message || "This field is required."}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row mt-10">
                            <div className="col-md-12">
                                <Link to="/cpt-code" className="btn btn-theme-blue">
                                    Back
                                </Link>
                                <button
                                    type="submit"
                                    className="btn btn-theme-green"
                                    style={{ marginLeft:"5px",minWidth: 100 }}
                                >
                                    {pageState?.loading ? (
                                        <div className="d-flex justify-content-center">
                                            <span className="myLoader light sm d-inline"></span>
                                        </div>
                                    ) : (
                                        <span>Submit</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditCptCode;
