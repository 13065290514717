/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import SessionsDetail from "../../components/SessionsDetail";
import TeamDetail from "../../components/TeamDetail";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Messages from "../../components/messages/Messages";
import allActions from "../../redux/actions";
import { paginationDisplayCount } from "../../config/Config";
import { getFamilyListByInterpreter } from "../../services/userServices";
import { _toastHandleErr } from "../../utils/commonFunctions";
import { useDebounce } from "../../hooks";
import { debounce } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import OnBoardedFamilies from "./FamilyTabs/OnBoardedFamilies";
import PendingFamilyList from "./FamilyTabs/PendingFamilyList";

function Family(props) {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.adminUser.user);
  let profile = useSelector((state) => state.adminUser.profile);
  const [families, setFamilies] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [therapysessions, setTherapySessions] = useState([]);
  const [teamdata, setTeamdata] = useState([]);
  const [search, setSearch] = useState("");
  const [rowclickid, setRowClickId] = useState(0);
  const [status, setStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedChildId, setSelectedChildId] = useState(null);
  let user_role = user.userDetails.roles[0];
  const [currentPage, setCurrentPage] = useState(0);
  const [totalpages, setTotalPages] = useState([]);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [requestReason, setRequestReason] = useState("");
  const [therapistInfo, setTherapistInfo] = useState({});
  const [familyView, setFamilyView] = useState("family-complete");
  const [sorting, setSorting] = useState("DESC");

  const isInterpeter = user_role === "ROLE_INTERPRETER";

  const hitApi = (api) => {
    if (typeof api === "function") {
      api();
    }
  };
  const debounceHitApi = useCallback(debounce(hitApi, 500), []);

  useEffect(() => {
    dispatch(allActions.adminAction.clearOnboardData());
    debounceHitApi(() => {
      fetchData();
    });
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [status, currentPage, paginationDisplayCount, sorting]);

  const fetchData = () => {
    setloading(true);
    (user_role === "ROLE_RCE" ||
      user_role === "ROLE_THERAPIST" ||
      user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
      adminService
        .getAll(
          // `families/filter?page=${currentPage}&pendingOnboarding=false&size=${paginationDisplayCount}&status=${status}&familyMemberName=${search}&sort=id,${sorting}`
          `families/all-family-members?page=${
            currentPage == 0 ? currentPage : currentPage - 1
          }&size=${paginationDisplayCount}&status=${status}&familyMemberName=${search}&sort=lastName,${sorting}`
        )
        .then(
          (res) => {
            if (res?.status === "Success") {
              setFamilies(res.data.content);
              setTotalPages(res.data.totalPages);
            } else {
              res?.message
                ? toast.error(res?.message)
                : console.log("Somthing went wrong");
            }
            setloading(false);
          },
          (error) => {
            console.log(error);
          }
        );

    if (isInterpeter && currentPage !== "undefined") {
      getFamilyListByInterpreter(currentPage)
        .then((res) => {
          setFamilies(
            res.content.map((item) => ({
              ...item.parent,
              familyMembers: [item.familyMember],
            }))
          );
          setTotalPages(res.totalPages);
        })
        .catch(_toastHandleErr)
        .finally(() => {
          setloading(false);
        });
    }
  };

  useEffect(() => {
    if (families?.length > 0) {
      const isSelected = families.some(
        (item) => item?.familyId === rowclickid && item?.id === selectedChildId
      );
      if (!isSelected) {
        onClickRow(families[0]?.familyId, families[0]?.id, families[0]);
      }
    } else {
      onClickRow(null, null, null);
    }
  }, [families, rowclickid, selectedChildId]);

  // useEffect(() => {
  //   if (families?.length > 0) {
  //     const list = families
  //       .map((itm) =>
  //         itm?.familyMembers?.map((xItm) => ({
  //           rId: itm.id,
  //           item: itm.userProfile,
  //           cId: xItm.id,
  //         }))
  //       )
  //       .flat();
  //     const isSelected = list.some(
  //       (item) => item?.rId === rowclickid && item?.cId === selectedChildId
  //     );
  //     if (!isSelected) {
  //       onClickRow(list[0]?.rId, list[0]?.cId, list[0]?.item);
  //     }
  //   } else {
  //     onClickRow(null, null, null);
  //   }
  // }, [families, rowclickid, selectedChildId]);

  //pagination
  const pageNumbers = [];

  for (let i = 0; i <= totalpages - 1; i++) {
    pageNumbers.push(i);
  }

  const paginate = (number) => {
    setCurrentPage(number);
  };
  const paginatePre = () => {
    setCurrentPage(currentPage - 1);
  };
  const paginateNext = () => {
    setCurrentPage(currentPage + 1);
  };
  //end

  const hasTherapistInFamily = (profileId, family) => {
    // const list = family?.familyMembers
    //   ?.map((item) => item?.familyMemberTherapies)
    //   ?.flat()
    //   ?.map((item) => item?.therapist?.userProfile?.id);
    const list =
      family?.familyMemberTherapies &&
      family?.familyMemberTherapies.map(
        (item) => item?.therapist?.userProfile?.id
      );
    return (list || []).includes(profileId);
  };

  const onClickRow = (id, childid, usr) => {
    console.log("selected user",usr);
    if (childid) {
      setloadings(true);
      setRowClickId(id);
      setSelectedUser(usr);
      setSelectedChildId(childid);
      {
        (user_role === "ROLE_RCE" ||
          user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
          adminService
            .getById(`sessions/filter/formatted?familyMemberId=` + childid)
            .then(
              (res) => {
                if (res.status === "Success") {
                  setTherapySessions(res.data.content);
                } else {
                  toast.error(res.message);
                }
                setloadings(false);
              },
              (error) => {
                console.log(error);
              }
            );
      }
      {
        (user_role === "ROLE_RCE" ||
          user_role == "ROLE_REGIONAL_EARLY_STEPS") &&
          adminService
            .getById(`families/my-team/rce?familyMemberId=` + childid)
            .then(
              (res) => {
                if (res.status === "Success") {
                  setTeamdata(res.data);
                } else {
                  toast.error(res.message);
                }
                setloadings(false);
              },
              (error) => {
                console.log(error);
              }
            );
      }

      {
        user_role === "ROLE_THERAPIST" &&
          childid &&
          adminService
            .getById(
              `therapist/sessions/filter/formatted?familyMemberId=` + childid
            )
            .then(
              (res) => {
                if (res.status === "Success") {
                  setTherapySessions(res.data.content);
                } else {
                  toast.error(res.message);
                }
                setloadings(false);
              },
              (error) => {
                console.log(error);
              }
            );
      }
    }
  };

  const successSub = () => {
    onClickRow(rowclickid, selectedChildId);
  };

  const addFamily = () => {
    return (
      <Link to={`/add-family`}>
        <button className="btn btn-primary custom-btn-green custom-btn-green-auto">
          <img
            className="pr-2"
            src="../resources/assets/plus-icon-button.svg"
            alt=""
          />{" "}
          Add Client
        </button>
      </Link>
    );
  };

  const handlePermissionRequest = () => {
    return (
      <button
        onClick={() =>
          therapistInfo?.onboardPermission == "false"
            ? setIsConfirmation(true)
            : toast.error("You already sent request wait for response")
        }
        className="btn btn-primary custom-btn-green custom-btn-green-auto"
      >
        <img
          className="pr-2"
          src="../resources/assets/plus-icon-button.svg"
          alt=""
        />{" "}
        Request to onboard Client
      </button>
    );
  };

  const handleOnboardPrmission = () => {
    //   api call to send request to onboard family
    if (requestReason) {
      const formData = new FormData();
      const status = "request";
      const data = { onboardPermission: status, reason: requestReason };
      formData.append(
        "therapistUpdateForm",
        new Blob([JSON.stringify(data)], {
          type: "application/json",
        })
      );
      adminService
        .onboardPutUser(`therapists/${profile?.therapistId}`, formData)
        .then((response) => {
          setRequestReason("");
          setIsConfirmation(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  useEffect(() => {
    const id = profile?.therapistId;
    adminService
      .getAll(`therapists/${id}`)
      .then((res) => {
        setTherapistInfo(res.data);
      })
      .catch((err) => console.log(err));
  }, [profile?.therapistId]);
  return (
    <>
      <div className="col-md-10 no-padding  d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            <div className="content-header">
              <div className="text-left header-title pt-12px">Client</div>
              <div className="text-right header-btn">
                {/* {(user_role === 'ROLE_RCE' || user_role === 'ROLE_REGIONAL_EARLY_STEPS') && */}

                <ul
                  className="nav nav-tabs"
                  id="myTab"
                  role="tablist"
                  style={{ borderBottom: 0 }}
                >
                  <li className="pt-12px">
                    {
                      !isInterpeter &&
                      profile.userType == "ROLE_THERAPIST" &&
                      therapistInfo?.onboardPermission == "true"
                        ? addFamily()
                        : profile.userType == "ROLE_THERAPIST" &&
                          (therapistInfo?.onboardPermission == "false" ||
                            therapistInfo?.onboardPermission == "request") &&
                          ""
                      // handlePermissionRequest()
                    }
                    {!isInterpeter &&
                      profile.userType != "ROLE_THERAPIST" &&
                      addFamily()}
                  </li>
                  {/* <li
                    className="nav-item dashboard"
                    onClick={() => setFamilyView("family-complete")}
                  >
                    <a
                      className="nav-link active"
                      id="complete-tab"
                      data-toggle="tab"
                      href="#complete"
                      role="tab"
                      aria-controls="complete"
                      aria-selected="true"
                    >
                      {familyView === "family-complete" ? (
                        <img
                          src="../resources/assets/calendar-list-select.svg"
                          alt=""
                        />
                      ) : (
                        <img src="../resources/assets/calendarM.svg" alt="" />
                      )}
                    </a>
                  </li>

                  <li
                    className="nav-item dashboard"
                    onClick={() => setFamilyView("family-pending")}
                  >
                    <a
                      className="nav-link"
                      id="pending-tab"
                      data-toggle="tab"
                      href="#pending"
                      role="tab"
                      aria-controls="pending"
                      aria-selected="false"
                    >
                      {familyView === "family-pending" ? (
                        <img src="../resources/assets/calendarW.svg" alt="" />
                      ) : (
                        <img
                          src="../resources/assets/calendar-gray.svg"
                          alt=""
                        />
                      )}
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            {isConfirmation && (
              <SweetAlert
                // info
                showCancel
                confirmBtnText="Submit!"
                confirmBtnBsStyle="info"
                title="Reason"
                onConfirm={() => handleOnboardPrmission()}
                onCancel={() => setIsConfirmation(false)}
                focusCancelBtn
              >
                <textarea
                  name="text"
                  className="form-control"
                  placeholder="Type your reason"
                  autofocus
                  value={requestReason}
                  onChange={(e) => setRequestReason(e.target.value)}
                />
              </SweetAlert>
            )}
            <form>
              {/* {user_role === 'ROLE_THERAPIST' &&
                                <>
                                    <div className="row mt-5"></div>
                                </>
                            } */}

              <div className="row">
                {(user_role === "ROLE_RCE" ||
                  user_role === "ROLE_THERAPIST" ||
                  user_role === "ROLE_REGIONAL_EARLY_STEPS") && (
                  <>
                    <div className="col-md-6 justify-content-between align-items-center mt-4 mb-4 d-flex">
                      <div className="col-auto w-75">
                        <input
                          type="search"
                          className="form-control search"
                          placeholder="Search by Name"
                          onChange={(e) => setSearch(e.target.value)}
                          autofocus
                        />
                      </div>

                      <div className="col-auto">
                        <select
                          className="form-control time"
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option className="form-control time" value="">
                            All
                          </option>
                          <option className="form-control time" value={true}>
                            Active
                          </option>
                          <option className="form-control time" value={false}>
                            Inactive
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2"></div>
                  </>
                )}
              </div>
            </form>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="complete"
                    role="tabpanel"
                    aria-labelledby="complete-tab"
                  >
                    <OnBoardedFamilies
                      isInterpeter={isInterpeter}
                      families={families}
                      loading={loading}
                      paginatePre={paginatePre}
                      paginate={paginate}
                      paginateNext={paginateNext}
                      currentPage={currentPage}
                      totalpages={totalpages}
                      user_role={user_role}
                      hasTherapistInFamily={hasTherapistInFamily}
                      profile={profile}
                      selectedUser={selectedUser}
                      FamilyChildRow={FamilyChildRow}
                      rowclickid={rowclickid}
                      selectedChildId={selectedChildId}
                      onClickRow={onClickRow}
                      pageNumbers={pageNumbers}
                      loadings={loadings}
                      therapysessions={therapysessions}
                      successSub={successSub}
                      teamdata={teamdata}
                      setSorting={setSorting}
                      sorting={sorting}
                    />
                  </div>
                  {/* <div
                    className="tab-pane fade"
                    id="pending"
                    role="tabpanel"
                    aria-labelledby="pending-tab"
                  >
                    <PendingFamilyList families={families} />
                  </div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Family;

const FamilyChildRow = ({ onClick, className, subitem, item }) => {
  const user = useSelector((state) => state.adminUser.user);
  let user_role = user.userDetails.roles[0];
  const isInterpeter = user_role === "ROLE_INTERPRETER";
  return (
    <tr onClick={onClick} className={className}>
      <th>
        <div className="user-info">
          <span className="admin-name">
            <img
              src={
                subitem.profileUrl
                  ? subitem.profileUrl
                  : "/resources/assets/defaultprofile.png"
              }
              alt="Profile"
              className="image mr-2"
            />
            <div className="content">
              <span className="name name-table">
                {subitem.firstName} {subitem.lastName}
              </span>
            </div>
          </span>
        </div>
      </th>
      <td>
        <div className="name-table">
          {/* {item.userProfile.firstName} {item.userProfile.lastName} */}
          {subitem.familyName}
        </div>
      </td>
      {!isInterpeter && (
        <td>
          <div
            className="name-table ml-0 mr-5 text-center"
            title="Completed/Total"
          >
            {item?.sessions || item.session}
          </div>
        </td>
      )}
      {!isInterpeter && (
        <td>
          <div>
            <Link
              to={"/child-detail/" + item.familyId + `/` + item.id}
              className="view-all-link text-nowrap"
            >
              <div className="name name-table">
                View Details
                <img
                  className="pl-1"
                  src="../resources/assets/arrow.svg"
                  alt=""
                />
              </div>
            </Link>
          </div>
        </td>
      )}
    </tr>
  );
};
