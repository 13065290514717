import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import {
//   calcWeekCount,
//   getListOfDays,
//   getUTCTimeToLocalByDate,
//   getMeridiumByDate,
//   showWarning,
// } from "../utils/commonFunctions";
// import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";
// import { daysJson, monthJson } from "../config/Config";
// import { adminService } from "../services/adminService";
// import allActions from "../redux/actions";
// import { useDispatch, useSelector } from "react-redux";
// import ViewSessionCalendar from "./ViewSessionCalendar";
// import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";
// import appAction from "../redux/actions/appAction";
// import TimeInput from "../components/TimeInput";
import Individual from "./AddSession/Individual";
import GroupSession from "./AddSession/GroupSession";

function AddNewSession() {
  let params = useParams();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isGroup = urlParams.get("group");
  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding">
          <div className="custom-table-view">
            <div className="content-header pb-0">
              <div className="text-left header-title">
                {params.id ? "Edit" : "Add New"} Session
              </div>
              {!params.id && (
                <div className="text-right header-btn">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="own-tab"
                        data-toggle="tab"
                        href="#own"
                        role="tab"
                        aria-controls="own"
                        aria-selected="true"
                      >
                        Individual
                      </a>
                    </li>
                    {!params.id && (
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="shared-tab"
                          data-toggle="tab"
                          href="#shared"
                          role="tab"
                          aria-controls="shared"
                          aria-selected="false"
                        >
                          Group
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            {!params.id && (
              <div
                className="d-block tab-content"
                id="myTabContent"
                style={{ paddingBottom: 20 }}
              >
                <div
                  className="tab-pane fade active show"
                  id="own"
                  role="tabpanel"
                  aria-labelledby="own-tab"
                >
                  <div className="row">
                    <Individual />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="shared"
                  role="tabpanel"
                  aria-labelledby="shared-tab"
                >
                  <div className="row">
                    <GroupSession />
                  </div>
                </div>
              </div>
            )}
            <div>
              {params.id &&
                (isGroup == "true" ? <GroupSession /> : <Individual />)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewSession;
