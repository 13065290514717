import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Switch, Link, useLocation } from "react-router-dom";
//import { useAlert,positions } from 'react-alert';

import FamilyParentInformation from "../Representative/OnBoardingUser/FamilyParentInformation";
import FamilyChildrenInformation from "../Representative/OnBoardingUser/FamilyChildrenInformation";
import FamilyChildrenInsuranceInfo from "../Representative/OnBoardingUser/FamilyChildrenInsuranceInfo";
import FamilyAssignTherapy from "../Representative/OnBoardingUser/FamilyAssignTherapy";

import Stepper from "../../components/Stepper";

function AddFamily() {
  //const alert = useAlert();
  let loading = false;
  const history = useNavigate();
  //const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState("");
  //const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
  const steps = getSteps();
  const [familyId, setFamilyId] = useState(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    return [
      "Caretaker Information",
      "Client Information",
      "Insurance Information",
      "Assign Therapy",
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <FamilyParentInformation
            familyId={familyId}
            setFamilyId={setFamilyId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      case 1:
        return (
          <FamilyChildrenInformation
            familyId={familyId}
            setFamilyId={setFamilyId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      case 2:
        return (
          <FamilyChildrenInsuranceInfo
            familyId={familyId}
            setFamilyId={setFamilyId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      case 3:
        return (
          <FamilyAssignTherapy
            familyId={familyId}
            setFamilyId={setFamilyId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    setActiveStep(0);
  }, []);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Add Client</div>
            </div>
            <div className="row stepper">
              {steps.map((label, index) => (
                <div
                  className={`col-md-3 stepper-steps ${
                    activeStep == index && activeStep != steps.length - 1
                      ? "active"
                      : activeStep > index
                      ? "complete"
                      : activeStep === steps.length - 1
                      ? "complete done"
                      : ""
                  }`}
                >
                  <div className="step-count">
                    <label>{index + 1}</label>
                  </div>{" "}
                  <label className="label-text-family">{label}</label>
                </div>
              ))}
              {/* <Stepper activeStep={activeStep} steps={getSteps()}/> */}
            </div>
            {getStepContent(activeStep)}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddFamily;
