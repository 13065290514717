import React, { useState, useEffect } from "react";
import { adminService } from "../../services/adminService";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InterpreterPersonalInformation from "./OnBoardingUser/InterpreterPersonalInformation";

function EditInterpreterDetail(props) {
  let params = useParams();
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
  const history = useNavigate();
  useEffect(() => {
    tenantPemission?.interpreter == false && history("/dashboard");
  }, [tenantPemission?.interpreter]);

  const [interpreter, setInterpreter] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    adminService.getById(`interpreters/` + params.id).then(
      (res) => {
        setInterpreter(res.data);
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [params.id]);

  function changeInterpreterData(e) {}
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <InterpreterPersonalInformation />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditInterpreterDetail;
