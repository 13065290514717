import React, { useEffect, useRef, useState } from "react";
import SockJsClient from "react-stomp";

import { useDispatch, useSelector } from "react-redux";
import { useEventListner } from "../../hooks/eventHook";
import agoraAction from "../../redux/actions/agoraAction";
import { SOCKET_URL } from "../../config/Config";
import emojiActions from "../../redux/actions/emojiAction";

const AppSocket = (props) => {
  const user = useSelector((st) => st.adminUser.user);
  const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
  const profile = useSelector((st) => st.adminUser.profile);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const appAgoraState = useSelector((state) => state.agoraDetail);
  
  useEffect(() => {
    const list = [];

    appAgoraState?.currentSession?.familyMemberDtoSet &&
      appAgoraState?.currentSession.familyMemberDtoSet.map((fm, i) => {
        const haveUser = appAgoraState?.users.filter(
          (f) => f.agoraId == fm.familyUserId
        );
        if (haveUser.length == 1) {
          list.push(fm);
          props?.setJoinedUsers(list);
        }
        console.log(
          "client.remoteUsers..1..",
          fm.familyUserId,
          appAgoraState?.users.map((u) => u.agoraId)
        );
      });
  }, [appAgoraState?.users]);

  const onMessageReceived = (msg) => {
    let topic = msg?.topic ? msg.topic : JSON.parse(msg.data).topic;
    console.log(
      "connection..............11",
      topic,
      msg
      // JSON.parse(msg.data)?.data
      // appAgoraState?.currentSession
    );
    if (topic == "agoraControl") {
      dispatch(
        agoraAction.setControls({
          ...JSON.parse(msg.data).data,
          receiverIds: msg.receiverIds,
          userList: msg?.userList,
        })
      );
    } else if (topic == "userLeave") {
      dispatch(
        agoraAction.userLeave({ ...JSON.parse(msg.data).data, isLocal: false })
      );
    } else if (topic == "screenShare") {
      console.log("appAgoraState?.screen", JSON.parse(msg.data).data.isEnabled);
      if (JSON.parse(msg.data).data.isEnabled) {
        dispatch(
          agoraAction.screenShare(
            JSON.parse(msg.data).data?.uid,
            JSON.parse(msg.data).data.isEnabled,
            msg?.userList[0]
          )
        );
      } else {
        dispatch(
          agoraAction.screenShare(null, JSON.parse(msg.data).data.isEnabled)
        );
      }
    } else if (topic == "agoraRecording") {
      dispatch(agoraAction.setRecording(JSON.parse(msg.data).data.isEnabled));
    } else if (topic == "agoraEmoji") {
      dispatch(emojiActions.showEmoji(JSON.parse(msg.data).data));
    } else if (topic == "agoraVolumeIndicator") {
      dispatch(agoraAction.setVolumeIndicator(JSON.parse(msg.data).data));
    } else if (topic == "agoraScreenShare") {
      dispatch(agoraAction.screenShare(JSON.parse(msg.data).data));
    } else {
      switch (msg.topic) {
        case "userJoined":
          dispatch(
            agoraAction.userJoined({
              ...msg.data,
              isLocal: false,
            })
          );
          break;

        case "userLeave":
          dispatch(agoraAction.userLeave({ ...msg.data, isLocal: false }));
          break;

        case "screenShare":
          dispatch(agoraAction.screenShare(msg.data.uid, msg.data.isEnabled));
          break;

        case "agoraRecording":
          console.log("connnnneeectionnn");
          dispatch(agoraAction.setRecording(msg.data.isEnabled));
          break;

        case "agoraEmoji":
          dispatch(emojiActions.showEmoji(msg.data));
          break;

        case "agoraControl":
          // agoraAction.setControls({
          //   ...JSON.parse(msg.data).data,
          //   receiverIds: msg.receiverIds,
          //   userList: msg?.userList,
          // });
          dispatch(
            agoraAction.setControls({
              ...msg.data,
              receiverIds: msg.receiverIds,
              userList: msg?.userList,
            })
          );
          break;

        default:
          break;
      }
    }
  };
  const { familyMember, interpreter, therapist, familyMemberDtoSet } =
    props.sessionDetail || {};

  const [state, setState] = useState({
    myId: null,
  });
  // const family_ids =
  //   familyMemberDtoSet?.length > 0 ? familyMemberDtoSet.map((fm) => fm.id) : [];
  const family_ids =
    props?.joinedUsers?.length > 0 ? props?.joinedUsers.map((fm) => fm.id) : [];
  const receiverIds = [
    profile?.userType !== "ROLE_INTERPRETER" &&
      interpreter?.userProfile?.id &&
      `ROLE_INTERPRETER-${interpreter?.userProfile?.id}`,

    profile?.userType !== "ROLE_THERAPIST" &&
      therapist?.userProfile?.id &&
      `ROLE_THERAPIST-${therapist?.userProfile?.id}`,
  ].filter((id) => id);

  const allReceiverIds = [
    interpreter?.userProfile?.id &&
      `ROLE_INTERPRETER-${interpreter?.userProfile?.id}`,
    therapist?.userProfile?.id &&
      `ROLE_THERAPIST-${therapist?.userProfile?.id}`,
  ].filter((id) => id);

  // const currentProfile =
  //   appAgoraState?.currentSession?.familyMemberDtoSet &&
  //   appAgoraState?.currentSession?.familyMemberDtoSet?.filter(
  //     (f) => f.familyUserId == profile.id
  //   )[0];

  useEffect(() => {
    let myId;

    const {
      familyMember,
      interpreter,
      therapist,
      familyMemberDtoSet,
      selectedMembers,
    } = props.sessionDetail || {};
    if (profile?.userType === "ROLE_FAMILY") {
      myId =
        props?.joinedUsers.length > 0
          ? props?.joinedUsers?.map((fm) => `${profile?.userType}-${fm?.id}`)
          : [];
      // myId = `${profile?.userType}-${currentProfile?.id}`;
    } else if (profile?.userType === "ROLE_INTERPRETER") {
      myId = `${profile?.userType}-${interpreter?.userProfile?.id}`;
    } else {
      myId = `${profile?.userType}-${therapist?.userProfile?.id}`;
    }
    setState((st) => ({ ...st, myId }));
  }, [profile?.userType, props.sessionDetail]);

  const msgGenerator = ({ topic, data }, includeMe) => {
    family_ids.map((id) => allReceiverIds.push(`ROLE_FAMILY-${id}`));
    profile?.userType !== "ROLE_FAMILY" &&
      family_ids.map((id) => receiverIds.push(`ROLE_FAMILY-${id}`));
    const userList = [];
    props?.joinedUsers.length > 0 &&
      props?.joinedUsers.map((fm) => {
        userList.push({
          id: fm.id,
          familyUserId: fm.familyUserId,
          familyId: fm.familyId,
          emoji: fm?.emoji,
          screen: fm?.screen,
        });
      });

    return {
      receiverIds: data?.selectedUserIds
        ? data?.selectedUserIds
        : includeMe
        ? allReceiverIds
        : receiverIds,
      data: JSON.stringify({
        time: new Date(),
        sender: state.myId,
        topic,
        data,
      }),
      userList: userList,
      tenantId: tenantId || user?.tenantDetails?.tenantId,
    };
  };

  useEventListner("agorUserJoined", (usr) => {
    const msg = msgGenerator({ topic: "userJoined", data: usr });
    sendMsg(JSON.stringify(msg));
  });
  useEventListner("agoraUserLeave", (usr) => {
    const msg = msgGenerator({ topic: "userLeave", data: usr });
    sendMsg(JSON.stringify(msg));
  });
  useEventListner("agoraScreenShare", (data) => {
    const msg = msgGenerator({ topic: "screenShare", data: data });
    sendMsg(JSON.stringify(msg));
  });
  useEventListner("agoraRecording", (data) => {
    const msg = msgGenerator({ topic: "agoraRecording", data: data });
    sendMsg(JSON.stringify(msg));
  });
  useEventListner("agoraEmoji", (data) => {
    const msg = msgGenerator({ topic: "agoraEmoji", data: data }, true);
    sendMsg(JSON.stringify(msg));
  });

  useEventListner("agoraControl", (data) => {
    data.agoraId = profile.id;
    data.sender = profile.firstName + " " + profile.lastName;
    const msg = msgGenerator({ topic: "agoraControl", data: data }, true);
    sendMsg(JSON.stringify(msg));
  });

  const sendMsg = (msg) => {
    if (ref.current?.client?.connected) {
      // ref.current.sendMessage("/app/generic", msg);
      ref.current.sendMessage("/topics/all", msg);
    } else {
      console.log("__socket not connected", { msg });
    }
  };
  return (
    <div>
      <SockJsClient
        ref={ref}
        url={SOCKET_URL}
        topics={[
          `/topics/generic/${state.myId}/${
            tenantId || user?.tenantDetails?.tenantId
          }`,
          // `/topics/screen-share/${state.myId}`,
          `/app/generic`,
          `/topics/all`,
        ]}
        // options={{transports: ['polling']}}
        onConnect={(c) => console.log("Connection-onconnect", c)}
        onDisconnect={(ee) => {
          console.log("Disconnected!", ee);
        }}
        onMessage={(msg) => onMessageReceived(msg)}
        debug={process.env.NODE_ENV === "development"}
      />
    </div>
  );
};

export default AppSocket;
