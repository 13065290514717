import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import { adminService } from "../../../services/adminService";
import allActions from "../../../redux/actions";
import { monthJson } from "../../../config/Config";
import moment from "moment-timezone";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DatePicker from "react-date-picker";
import appAction from "../../../redux/actions/appAction";
import {
  getFullName,
  getNumberFromMask,
  getShortInsurance,
} from "../../../utils/commonFunctions";
import InputFileBtn from "../../InputFileBtn";
import { nameRegex } from "../../../utils/regex";

function FamilyChildrenInformation(props) {
  const { register, handleSubmit, formState: { errors,touched }, setValue, trigger } =
    useForm();
  let today_date = new Date();
  let formatTwoDigits = (digit) => ("0" + digit).slice(-2);
  const thisYear = today_date.getFullYear();
  const thisMonth = today_date.getMonth() + 1;
  const today = formatTwoDigits(today_date.getDate());
  today_date = moment(today_date).format("YYYY-MM-DD");
  const history = useNavigate();
  const dispatch = useDispatch();
  let params = useParams();
  let [therapies, setTherapies] = useState([]);
  let [therapist, setTherapist] = useState([]);
  let [diagnoses, setDiagnoses] = useState("");
  // let [tempAssignTherapy, setTempAssignTherapy] = useState([]);
  const [refDateValue, handleChangeDate] = useState(new Date());
  const [family, setFamily] = useState([]);
  const [loading, setloading] = useState(false);
  let familyForm = useSelector((state) => state.adminUser.familyForm);
  let jsonFound = familyForm.hasOwnProperty("familyMemberForms");
  const [familyMembers, setFamilyMembers] = useState(
    jsonFound ? familyForm.familyMemberForms : []
  );
  // const [insurance, setInsurance] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [deletetherapyid, setDeleteTherapyId] = useState([]);
  const [status, setStatus] = useState("");
  const profilePicKeyRef = useRef(null);
  const [profilePicKeyType, setProfilePicKeyType] = useState(false);
  const [currentYears, setCurrentYears] = useState(0);
  const [currentMonths, setCurrentMonths] = useState(0);
  const [currentDays, setCurrentDays] = useState(0);
  const [deletedFamilyMemberIds, setDeletedFamilyMemberIds] = useState([]);
  const [insuranceDetails, setInsuranceDetails] = useState({
    payeeName: "",
    payeePhoneNumber: "",
    payeeAddress: "",
    insurancePlan: "",
    groupNumber: "",
    policyNumber: "",
    insuranceType: "",
  });
  const [dateInputs, setdateInputs] = useState({
    year: thisYear,
    month: thisMonth,
    day: today,
  });

  const submitBtnRef = useRef(null);
  let year = dateInputs.year;
  let leapYear =
    year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0) ? 29 : 28;

  let maxvalue =
    dateInputs.month === 2
      ? leapYear
      : dateInputs.month === 4
      ? 30
      : dateInputs.month === 6
      ? 30
      : dateInputs.month === 9
      ? 30
      : dateInputs.month === 11
      ? 30
      : 31;

  const [inputs, setInputs] = useState({
    firstName: jsonFound ? familyForm.familyMemberForms.firstName : "",
    lastName: jsonFound ? familyForm.familyMemberForms.lastName : "",
    about: jsonFound ? familyForm.familyMemberForms.about : "",
    dob: jsonFound ? familyForm.familyMemberForms.dob : "",
    interpreterId: jsonFound ? familyForm.familyMemberForms.interpreterId : 0,
    familyMemberTherapies: jsonFound
      ? familyForm.familyMemberForms.familyMemberTherapies
      : [],
    profilePicKey: profilePicKeyRef ? profilePicKeyRef.current : "",
    profileImage: [],
    id: params.childid,
    active: status,
    gender: jsonFound ? familyForm.familyMemberForms.gender : "",
    allergies: jsonFound ? familyForm.familyMemberForms.allergies : "",
    attachmentKeys: jsonFound
      ? familyForm.familyMemberForms.attachmentKeys
      : [],
    medications: jsonFound ? familyForm.familyMemberForms.medications : "",
    precautions: jsonFound ? familyForm.familyMemberForms.precautions : "",
    therapyId: "",
    therapistId: "",
    diagnosisIds: [],
    familyMemberTherapyForms: [],
    insuranceDetailForms: [],
    deletedInsuranceDetailIds: [],
    newInsuranceDetailForm: [],
  });

  const [assignServices, setAssignServices] = useState({
    therapyId: "",
    therapistId: "",
  });

  useEffect(() => {
    if (profileImage === "") {
      let url = "../../resources/assets/defaultprofile.png";
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "defaultProfile.jpg", {
            type: blob.type,
          });
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            file.url = reader.result;
            file.isnew = false;
            setProfileImage(file);
            setInputs((inputs) => ({ ...inputs, profileImage: file }));
          };
        });
    }
    // console.log("profileImage...", profileImage.name);
  }, [profileImage]);

  useEffect(() => {
    if (
      errors.profilePicKey !== undefined &&
      errors.profilePicKey.type === "required"
    ) {
      setProfilePicKeyType(true);
    } else {
      setProfilePicKeyType(false);
    }
  }, [errors]);

  useEffect(() => {
    if (params.id) {
      setloading(true);
      getFamilyInfo();

      getTherapies();
    }
  }, []);

  const getFamilyInfo = () => {
    adminService
      .getById(`families/` + params.id + `/family-member/` + params.childid)
      .then(
        (res) => {
          if (res.status === "Success") {
            let response = res.data.familyMembers[0];
            setFamily(res.data);
            setInputs({
              firstName: response.firstName,
              lastName: response.lastName,
              about: response.about,
              dob: response.dob,
              gender: response.gender,
              profileImage: response.profileUrl,
              id: params.childid,
              allergies: response.allergies,
              precautions: response.precautions,
              medications: response.medications,
              attachmentKeys: response.attachmentKeys,
              active: response.active,
              insuranceDetailUpdateForms: response.insuranceDetailDtoList
                ? response.insuranceDetailDtoList
                : [],
              deletedInsuranceDetailIds: [],
              newInsuranceDetailForm: [],
            });
            setValue("firstName",response.firstName);
            setValue("lastName",response.lastName);
            setValue("about",response.about);
            //setValue("dob",response.dob);
            setValue("gender",response.gender);

            setFamilyMembers(response);
            let dob_edit = response.dob.split("-");
            setdateInputs({
              year: dob_edit && dob_edit[0],
              month: dob_edit && parseInt(dob_edit[1]),
              day: dob_edit && dob_edit[2],
            });
            setValue("year",dob_edit && dob_edit[0]);
            setValue("month",dob_edit && parseInt(dob_edit[1]));
            setValue("day",dob_edit && dob_edit[2]);
            dispatch(
              allActions.adminAction.setFamilyFormData(
                response,
                "familyMemberForms"
              )
            );
            setloading(false);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getTherapies = () => {
    adminService.getAll(`therapies`).then(
      (res) => {
        setTherapies(res.data.content);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const onSubmitNext = () => {
    if (familyMembers.length === 0) {
      toast.warn("Please add child information");
    } else if (
      inputs.firstName &&
      inputs.lastName &&
      dateInputs.day.length < 2
    ) {
      toast.warn("Date of birth day is invalid");
    } else {
      if (params.id) {
        // for sedn dignosysids into int
        let familyMemberTherapies = [];
        inputs.familyMemberTherapies.map((item, index) => {
          if (item.isnew) {
            let diagnosisIds = [];
            item.diagnosisIds.map((item2) => {
              let splitArray = item2.split("$&");
              diagnosisIds.push(parseInt(splitArray[0]));
            });
            item.diagnosisIds = diagnosisIds;
            familyMemberTherapies.push(item);
          }
        });

        if (familyMemberTherapies.length > 0)
          inputs["newFamilyMemberTherapyForms"] = familyMemberTherapies.filter(
            (obj) => obj.isnew === true
          );

        if (deletetherapyid.length > 0)
          inputs["deletedFamilyMemberTherapyIds"] = deletetherapyid;

        if (inputs.insuranceDetailUpdateForms.length > 0)
          inputs["newInsuranceDetailForm"] =
            inputs.insuranceDetailUpdateForms.filter(
              (obj) => obj.isnew === true
            );

        inputs["insuranceDetailUpdateForms"] =
          inputs.insuranceDetailUpdateForms.filter((obj) => obj.isnew !== true);

        let formDataPut = {
          familyMemberUpdateForms: [inputs],
        };

        let formData = new FormData();
        // formData.append('profileImage', inputs.profileImage)

        formData.append(
          "familyUpdateForm",
          new Blob([JSON.stringify(formDataPut)], {
            type: "application/json",
          })
        );

        dispatch(appAction.setAppLoading(true));
        adminService
          .update(`/families/` + params.id, formData)
          .then(
            (res) => {
              if (res.status === "Success") {
                toast.success(res.message);
                getFamilyInfo();
                getTherapies();
                // setTimeout(() => {
                //   history(
                //     "/child-detail/" + params.id + "/" + params.childid
                //   );
                // }, 2000);
                history("/family");
              } else {
                toast.error(res.message);
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            dispatch(appAction.setAppLoading(false));
          });
      } else {
        dispatch(
          allActions.adminAction.setFamilyFormData(
            familyMembers,
            "familyMemberForms"
          )
        );
        props.handleNext();
      }
    }

    console.log("familyMembers", familyMembers);
  };

  const onSubmitSaveAndBack = (e) => {
    const childList = [];
    const familyMemberUpdateForms = [];
    familyMembers.length > 0 &&
      familyMembers.map((member) => {
        const data = {
          id: member?.id || null,
          firstName: member.firstName,
          lastName: member.lastName,
          dob: member.dob,
          gender: member.gender,
          about: member.about,
          profilePicKey: member.profilePicKey,
        };
        member?.id ? familyMemberUpdateForms.push(data) : childList.push(data);
      });
    console.log("familyMemberUpdateForms", childList, familyMemberUpdateForms);
    let formData = new FormData();
    formData.append("profileImage", familyForm?.userProfileForm.profileImage);
    const userBasicInfo = {
      firstName: familyForm?.userProfileForm?.firstName,
      lastName: familyForm?.userProfileForm?.lastName,
      email: familyForm?.userProfileForm?.email,
      phone: familyForm?.userProfileForm?.phone,
      about: "",
      gender: familyForm?.userProfileForm?.gender,
    };
    if (props?.familyData) {
      formData.append(
        "familyUpdateForm",
        new Blob(
          [
            JSON.stringify({
              languageIds: familyForm?.languageIds,
              relationship: familyForm?.relationship,
              userProfileUpdateForm: userBasicInfo,
              pendingOnboarding: true,
              newFamilyMemberForms: childList,
              familyMemberUpdateForms,
              deletedFamilyMemberIds,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    } else {
      formData.append(
        "familyForm",
        new Blob(
          [
            JSON.stringify({
              languageIds: familyForm?.languageIds,
              relationship: familyForm?.relationship,
              userProfileForm: userBasicInfo,
              pendingOnboarding: true,
              familyMemberForms: childList,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }

    dispatch(appAction.setAppLoading(true));
    if (!props?.familyData) {
      adminService
        .onboardUser(`families`, formData)
        .then(
          (res) => {
            console.log("object", res);
            if (res?.data?.id) {
              history("/pending-users-onboarding");
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    } else {
      adminService
        .update(`/families/` + props?.familyData.id, formData)
        .then(
          (res) => {
            if (res?.status === "Success") {
              history("/pending-users-onboarding");
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    }
  };

  useEffect(() => {
    if (props?.familyData) {
      let data =
        props?.familyData?.familyMembers &&
        props?.familyData?.familyMembers.map((d) => {
          d.insuranceDetailForms = [];
          d.familyMemberTherapyForms = [];
          d.insuranceDetailForms = [];
          d.deletedInsuranceDetailIds = [];
          d.newInsuranceDetailForm = [];
          return d;
        });

      setFamilyMembers(data || []);
    }
  }, [props?.familyData]);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "therapyId") {
      adminService
        .getAll(`therapists/filter?therapyId=${parseInt(value)}&sort=id,DESC`)
        .then(
          (res) => {
            if (res.data.content.length > 0) {
              setTherapist(res.data.content);
              setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
            } else {
              toast.warn("Therapist not available for selected therapy");
              setTherapist([]);
            }
          },
          (error) => {
            console.log(error);
          }
        );

      adminService.getAll(`diagnoses?therapyId=${parseInt(value)}`).then(
        (res) => {
          if (res.data.content.length > 0) {
            setInputs((inputs) => ({ ...inputs, ["diagnosisIds"]: [] }));
            setDiagnoses(res.data.content);
          } else {
            toast.warn("ICD 10 codes is not available for selected therapy");
          }
        },
        (error) => {
          console.log(error);
        }
      );

      //  TODO modify the above code for loading purpose please verify
      // dispatch(appAction.setAppLoading(true));
      // Promise.allSettled([
      //     adminService.getAll(`therapists/filter?therapyId=${parseInt(value)}&sort=id,DESC`),
      //     adminService.getAll(`diagnoses?therapyId=${parseInt(value)}`)
      // ]).then((results) => {
      //     results.forEach(({ status, value: res }, index) => {
      //         if (status === "fulfilled") {
      //             switch (index) {
      //                 case 0:
      //                     if (res.data.content.length > 0) {
      //                         setTherapist(res.data.content);
      //                         setInputs(inputs => ({ ...inputs, [name]: parseInt(res) }));

      //                     } else {
      //                         toast.warn("Therapist not available for selected therapy");
      //                     }
      //                     break;

      //                 case 1:

      //                     if (res.data.content.length > 0) {
      //                         setInputs(inputs => ({ ...inputs, ["diagnosisIds"]: [] }));
      //                         setDiagnoses(res.data.content);

      //                     } else {
      //                         toast.warn("ICD 10 codes is not available for selected therapy");
      //                     }
      //                     break;
      //                 default:
      //                     break;
      //             }
      //         } else {
      //             console.log(res)
      //         }
      //     });
      //     dispatch(appAction.setAppLoading(false));
      // })
    } else if (name === "diagnosisIds") {
      let valueIds = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setInputs((inputs) => ({ ...inputs, [name]: valueIds }));
    } else if (name === "therapistId")
      setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
    else setInputs((inputs) => ({ ...inputs, [name]: value }));

    setAssignServices((assignServices) => ({
      ...assignServices,
      [name]: value,
    }));
  }

  function handleChangeDOB(e) {
    const { name, value, min, max } = e.target;
    //const { touched } = formState;
    // trigger

    // dateInputs.day = Math.max(Number(min), Math.min(Number(max), Number(dateInputs.day)));
    setdateInputs((dateInputs) => ({ ...dateInputs, [name]: value }));
    // setInputs(inputs => ({ ...inputs, ['dob']: `${dateInputs.year}-${parseInt(dateInputs.month) > 9 ? dateInputs.month : "0" + dateInputs.month}-${dateInputs.day}` }));
    setTimeout(() => {
      if (touched.day === true) {
        trigger("day");
      }
    }, 100);
  }

  const getYear = () => {
    const options = [];
    const minOffset = 0;
    const maxOffset = 60;

    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i;
      options.push(<option value={year}>{year}</option>);
    }
    return options;
  };

  const clearFormData = () => {
    setValue("firstName","");
    setValue("lastName","");
    setValue("about","");
    setValue("year",thisYear);
    setValue("month",thisMonth);
    setValue("day",today);
    setProfileImage("");
    setdateInputs({
      year: thisYear,
      month: thisMonth,
      day: today,
    });
    setInputs((inputs) => ({
      ...inputs,
      firstName: "",
    }));
    setTimeout(() => {
      setInputs((inputs) => ({ ...inputs, lastName: "" }));
      setTimeout(() => {
        setInputs((inputs) => ({ ...inputs, gender: "" }));
        setTimeout(() => {
          setInputs((inputs) => ({ ...inputs, about: "" }));
        }, 300);
      }, 300);
    }, 300);
  };

  const uploadDefaultImage = async () => {
    let formData = new FormData();

    console.log("profileImage", profileImage.name);
    formData.append("imageFile", profileImage);
    dispatch(appAction.setAppLoading(true));
    console.log("profileImage12", profileImage.name);
    return await adminService.POSTUploadImage(`upload/image`, formData);
  };

  const addFamilyMembersWithDefaultImage = () => {
    uploadDefaultImage().then(
      (res) => {
        if (res.status === "Success") {
          profilePicKeyRef.current = res.data.fileKey;
          setInputs((inputs) => ({
            ...inputs,
            ["profilePicKey"]: res.data.fileKey,
          }));
          dispatch(appAction.setAppLoading(false));
          addFamilyMembers(profilePicKeyRef.current);
        } else {
          toast.error(res.message);
        }
        // setProfileImage("");
      },
      (error) => {
        console.log("Error >>>>>>>", error);
      }
    );
    // .finally((res) => {
    //   console.log("res.", res);
    //   dispatch(appAction.setAppLoading(false));
    //   addFamilyMembers();
    // });
  };

  const addFamilyMembers = (currentImgKey) => {
    if (today_date < inputs.dob) {
      toast.warn("DOB can't be a future date");
      return;
    }

    if (inputs.firstName && inputs.lastName && dateInputs.day.length < 2) {
      toast.warn("Date of birth day is invalid");
    }
    console.log("res.", familyMembers, inputs);
    inputs.profilePicKey = currentImgKey;

    // setProfileImage("");
    if (inputs.firstName && inputs.lastName) {
      if (familyMembers.length > 0) {
        let fileindex =
          familyMembers &&
          familyMembers.findIndex(
            (obj) =>
              obj.firstName === inputs.firstName &&
              obj.lastName === inputs.lastName
          );
        if (fileindex === -1) {
          setFamilyMembers([...familyMembers, inputs]);
          dispatch(
            allActions.adminAction.setFamilyFormData(
              [...familyMembers, inputs],
              "familyMemberForms"
            )
          );
          clearFormData();
        } else {
          toast.warning("Client already added");
        }
      } else {
        let newFamilyMembers = [];
        if (
          profilePicKeyRef.current !== null ||
          profilePicKeyRef.current !== ""
        ) {
          newFamilyMembers = [
            ...familyMembers,
            {
              ...inputs,
              profilePicKey: currentImgKey,
              // profilePicKeyRef.current
            },
          ];
          setFamilyMembers([
            ...familyMembers,
            {
              ...inputs,
              profilePicKey: currentImgKey,
              // profilePicKeyRef.current
            },
          ]);
          // clearFormData();
        } else {
          newFamilyMembers = [...familyMembers, inputs];
          setFamilyMembers([...familyMembers, inputs]);
        }
        dispatch(
          allActions.adminAction.setFamilyFormData(
            newFamilyMembers,
            "familyMemberForms"
          )
        );
        clearFormData();
      }
    } else {
      toast.warning("Please fill the required fields");
    }
    // setProfileImage("");
  };

  const removeFamilyMembers = (index) => {
    const list = [...familyMembers];
    const delIds = deletedFamilyMemberIds ? [...deletedFamilyMemberIds] : [];
    console.log(
      "deletedFamilyMemberIds",
      list[index].id,
      deletedFamilyMemberIds
    );
    list[index]?.id && delIds.push(list[index].id);
    setDeletedFamilyMemberIds(delIds);
    list.splice(index, 1);
    setFamilyMembers(list);
    dispatch(
      allActions.adminAction.setFamilyFormData(list, "familyMemberForms")
    );
  };

  const assignTherapyTherapist = () => {
    if (
      inputs.therapistId &&
      inputs.therapyId &&
      inputs.diagnosisIds.length > 0
    ) {
      let fileindex1 = inputs.familyMemberTherapies.findIndex((obj) =>
        obj.id
          ? obj.therapy.id === inputs.therapyId
          : obj.therapyId === inputs.therapyId
      );
      if (fileindex1 !== -1) {
        toast.warn("Service already added, please add another service");
      } else {
        inputs.referralDate = moment(refDateValue)
          .format("YYYY-MM-DD")
          .toString();
        let therapisName =
          therapist.length > 0
            ? therapist.filter((item) => item.id === inputs.therapistId)[0]
                .userProfile.firstName +
              " " +
              therapist.filter((item) => item.id === inputs.therapistId)[0]
                .userProfile.lastName
            : "";

        let serviceAdd = {
          diagnosisIds: inputs.diagnosisIds,
          referralDate: inputs.referralDate,
          therapistId: inputs.therapistId,
          therapyId: inputs.therapyId,
          isnew: true,
          therapisName: therapisName,
        };

        familyMembers["familyMemberTherapies"].push(serviceAdd);
        setFamilyMembers(familyMembers);
        setInputs((inputs) => ({
          ...inputs,
          ["familyMemberTherapies"]: familyMembers["familyMemberTherapies"],
        }));
        dispatch(
          allActions.adminAction.setFamilyFormData(
            familyMembers,
            "familyMemberForms"
          )
        );
      }
    } else {
      toast.warn("Please fill all the required fields");
    }
    setAssignServices({
      referralDate: new Date(),
      therapyId: "",
      therapistId: "",
    });
    setDiagnoses("");
    handleChangeDate(new Date());
    submitBtnRef.current.click();
  };

  const removeTempAssignTherapy = (id, isnew, deleted_therapy_id) => {
    setInputs((inputs) => ({
      ...inputs,
      ["familyMemberTherapies"]: inputs.familyMemberTherapies.filter((item) =>
        isnew ? item.therapyId !== id : item.therapy.id !== id
      ),
    }));
    !isnew &&
      setDeleteTherapyId([...deletetherapyid, parseInt(deleted_therapy_id)]);
    setTimeout(() => {
      submitBtnRef.current.click();
    }, 500);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FileSize = e.target.files[0].size / 1024 / 1024; // in MB
    if (file.name.match(/.(jpg|jpeg|jpeg2000|png|svg)$/i)) {
      if (FileSize > 2) {
        toast.warn("Please upload file having size MAX 2MB");
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          file.url = reader.result;
          setInputs((inputs) => ({ ...inputs, ["profileImage"]: file }));
          let formData = new FormData();
          formData.append("imageFile", file);
          dispatch(appAction.setAppLoading(true));
          adminService
            .POSTUploadImage(`upload/image`, formData)
            .then(
              (res) => {
                if (res.status === "Success") {
                  file.isnew = true;
                  setProfileImage(file);
                  setInputs((inputs) => ({
                    ...inputs,
                    ["profilePicKey"]: res.data.fileKey,
                  }));
                  setProfilePicKeyType(false);
                } else {
                  toast.error(res.message);
                }
              },
              (error) => {
                console.log("Error >>>>>>>", error);
              }
            )
            .finally(() => {
              dispatch(appAction.setAppLoading(false));
            });
        };
      }
    } else {
      toast.warn("Please upload file having extensions - JPG,JPEG,PNG,SVG");
    }
  };

  function getTherapyName(id) {
    return therapies.length > 0
      ? therapies.filter((item) => item.id === id)[0].description
      : "";
  }

  const handleStatus = (name, value) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  function change(e) {
    const { name, value } = e.target;
    setInsuranceDetails((insuranceDetails) => ({
      ...insuranceDetails,
      [name]: value,
    }));
  }

  const removeInsuranceInformation = (fname, lname, policyNumber, id) => {
    setInputs((inputs) => ({
      ...inputs,
      ["insuranceDetailUpdateForms"]: inputs.insuranceDetailUpdateForms.filter(
        (item) => item.policyNumber !== policyNumber
      ),
    }));
    let deletedInsuranceDetailIds = inputs.deletedInsuranceDetailIds;
    deletedInsuranceDetailIds.push(id);
    setInputs((inputs) => ({
      ...inputs,
      ["deletedInsuranceDetailIds"]: deletedInsuranceDetailIds,
    }));
  };

  const addInsuranceInformation = () => {
    var payeeNumber = getNumberFromMask(insuranceDetails.payeePhoneNumber);
    if (
      !insuranceDetails.policyNumber ||
      !insuranceDetails.payeeAddress ||
      !insuranceDetails.payeeName ||
      !insuranceDetails.payeePhoneNumber ||
      !insuranceDetails.groupNumber ||
      !insuranceDetails.insurancePlan ||
      !insuranceDetails.insuranceType
    ) {
      toast.warn("Please enter all required insurance information");
    } else if (payeeNumber.length !== 10) {
      toast.warn("Phone number should be 10 digit");
      return;
    } else {
      let fileindex =
        inputs.insuranceDetailUpdateForms &&
        inputs.insuranceDetailUpdateForms.findIndex(
          (obj) => obj.policyNumber === insuranceDetails.policyNumber
        );
      if (fileindex !== -1) {
        toast.warn("Policy already added, please add another policy");
      } else {
        let insuranceInformation = {
          payeeAddress: insuranceDetails.payeeAddress,
          payeeName: insuranceDetails.payeeName,
          payeePhoneNumber: insuranceDetails.payeePhoneNumber,
          policyNumber: insuranceDetails.policyNumber,
          groupNumber: insuranceDetails.groupNumber,
          insurancePlan: insuranceDetails.insurancePlan,
          insuranceType: insuranceDetails.insuranceType,
          isnew: true,
        };
        let current_insurance = inputs.insuranceDetailUpdateForms;
        current_insurance.push(insuranceInformation);
        setInputs((inputs) => ({
          ...inputs,
          ["insuranceDetailUpdateForms"]: current_insurance,
        }));
      }
      setInsuranceDetails({
        payeeName: "",
        payeePhoneNumber: "",
        payeeAddress: "",
        insurancePlan: "",
        groupNumber: "",
        policyNumber: "",
        insuranceType: "",
      });
    }
  };

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  useEffect(() => {
    setdateInputs(dateInputs);
    setInputs((inputs) => ({
      ...inputs,
      ["dob"]: `${dateInputs.year}-${
        parseInt(dateInputs.month) > 9
          ? dateInputs.month
          : "0" + dateInputs.month
      }-${dateInputs.day}`,
    }));
    setInputs((inputs) => ({
      ...inputs,
      ["familyMemberTherapies"]: familyMembers["familyMemberTherapies"],
    }));

    const dobMonth =
      parseInt(dateInputs.month) > 9
        ? dateInputs.month
        : "0" + dateInputs.month;
    var today = new Date();
    var birthday = new Date(dateInputs.year, dobMonth - 1, dateInputs.day);

    dateDiff(today, birthday);
  }, [dateInputs]);

  function dateDiff(startingDate, endingDate) {
    var startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()] + 1;
    }

    setCurrentYears(yearDiff);
    setCurrentMonths(monthDiff);
    if (dateInputs.day !== "") {
      setCurrentDays(dayDiff);
    } else {
      setCurrentDays(0);
    }
  }
  console.log("datemonth",dateInputs);
  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="addtheripist">
            <label className="conetent-title">
              {params.id ? "Edit Client Information" : "Client Information"}
            </label>
            <form onSubmit={handleSubmit(addFamilyMembersWithDefaultImage)}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    {params.id ? (
                      <tr>
                        <td>
                          <label className="name">Profile Picture</label>{" "}
                          {/*(<span className="required-span">*</span>)*/}
                        </td>
                        <td>
                          <div className="status">
                            <label className="name">Status</label>
                            <div className="working-time">
                              <div
                                className="btn-group btn-group-toggle set-time-section"
                                data-toggle="buttons"
                              >
                                <label
                                  className={`btn ${
                                    inputs.active === true && "active"
                                  }`}
                                  onClick={() => handleStatus("active", "true")}
                                >
                                  <input
                                    type="radio"
                                    value="true"
                                    name="active"
                                    autocomplete="off"
                                  />{" "}
                                  Active
                                </label>

                                <label
                                  className={`btn ${
                                    inputs.active === false && "active"
                                  }`}
                                  onClick={() =>
                                    handleStatus("active", "false")
                                  }
                                >
                                  <input
                                    type="radio"
                                    value="false"
                                    name="active"
                                    autocomplete="off"
                                  />{" "}
                                  InActive
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        <label>Profile Picture</label>
                        {/* (<span className="required-span">*</span>)*/}
                      </>
                    )}
                    {params.id ? (
                      <span>
                        <div className="row">
                          {family.familyMembers &&
                            family.familyMembers.map((item) => (
                              <div className="col-md-4">
                                <img
                                  src={
                                    profileImage.isnew
                                      ? profileImage.url
                                      : item.profileUrl
                                  }
                                  alt=""
                                  className="settingsimg"
                                />
                              </div>
                            ))}
                          <InputFileBtn
                            id="fileuplaod"
                            name="profilePicKey"
                            onChange={(e) => handleImageChange(e)}
                          />{" "}
                          {/* <div className="col-md-6 ml-5">
                                                        {family.familyMembers && family.familyMembers.map((item) =>
                                                            <div className="form-group">
                                                                <label>Status</label>
                                                                <select className="form-control w-50" onChange={(e) => setStatus(e.target.value)} defaultValue={item.active} id="exampleFormControlSelect1">
                                                                    <option value={true}>Active</option>
                                                                    <option value={false}>Inactive</option>
                                                                </select>
                                                            </div>
                                                        )}
                                                    </div> */}
                        </div>
                        <br />
                      </span>
                    ) : (
                      <label for="fileuplaod" className="fileuplaod">
                        <div className="content-box">
                          <img
                            src={
                              profileImage &&
                              profileImage.name !== "defaultProfile.jpg"
                                ? profileImage.url
                                : "../resources/assets/file-upload-icon.svg"
                            }
                            alt="Family"
                          />
                          <div className="file-title">
                            Drop your image here, or <strong>browse</strong>{" "}
                          </div>
                          <div className="file-name">
                            Support- JPG,JPEG,PNG,SVG
                          </div>
                        </div>
                        <input
                          type="file"
                          id="fileuplaod"
                          name="profilePicKey"
                          onChange={(e) => handleImageChange(e)}
                          // {...register({ required: inputs.profilePicKey ? false : true })}
                        />
                      </label>
                    )}
                    {profilePicKeyType && (
                      <div className="invalid-feedback-custom">
                        Please upload profile picture
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label> (
                    <span className="required-span">*</span>)
                    <input
                      defaultValue={inputs.firstName}
                      // defaultValue={inputs.firstName}
                      name="firstName"
                      //onChange={handleChange}
                      type="text"
                      className={`form-control ${
                        errors.firstName && "is-invalid"
                      }`}
                      placeholder="Enter First Name"
                      {...register('firstName',{
                        onChange:(e)=>handleChange(e),
                        required: true,
                        maxLength: 30,
                        validate: (value) => {
                          const regex = nameRegex;
                          const result = regex.test(value);
                          setInputs((inputs) => ({
                            ...inputs,
                            ["firstName"]: value,
                          }));
                          return result;
                        },
                      })}
                    />
                    {errors.firstName &&
                      errors.firstName.type === "required" && (
                        <div className="invalid-feedback-custom">
                          First name is required
                        </div>
                      )}
                    {errors.firstName &&
                      errors.firstName.type === "pattern" && (
                        <div className="invalid-feedback-custom">
                          Alphabetical characters only
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label> (
                    <span className="required-span">*</span>)
                    <input
                      defaultValue={inputs.lastName}
                      // defaultValue={inputs.lastName}
                      name="lastName"
                      type="text"
                      //onChange={handleChange}
                      className={`form-control ${
                        errors.lastName && "is-invalid"
                      }`}
                      placeholder="Enter Last Name"
                      {...register('lastName',{
                        onChange:(e)=>handleChange(e),
                        required: true,
                        maxLength: 30,
                        validate: (value) => {
                          const regex = nameRegex;
                          const result = regex.test(value);
                          setInputs((inputs) => ({
                            ...inputs,
                            ["lastName"]: value,
                          }));
                          return result;
                        },
                      })}
                    />
                    {errors.lastName && errors.lastName.type === "required" && (
                      <div className="invalid-feedback-custom">
                        Last name is required
                      </div>
                    )}
                    {errors.lastName && errors.lastName.type === "pattern" && (
                      <div className="invalid-feedback-custom">
                        Alphabetical characters only
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Date of Birth</label> (
                    <span className="required-span">*</span>)
                    <div className="row">
                      <div className="col-md-2">
                        <label className="dob-label">Year</label>
                        <div className="input-group dob-box">
                          
                          <select
                            {...register('year',{ onChange:(e)=>handleChangeDOB(e),required: true })}
                            className={`custom-select ${
                              errors.year && "is-invalid"
                            }`}
                            //onChange={handleChangeDOB}
                            defaultValue={dateInputs.year}
                            name="year"
                          >
                            {getYear()}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <label className="dob-label">Month</label>
                        <div className="input-group dob-box">
                          <select
                            {...register('month',{ onChange:(e)=>handleChangeDOB(e),required: true })}
                            className={`custom-select ${
                              errors.month && "is-invalid"
                            }`}
                            //onChange={handleChangeDOB}
                            value={dateInputs.month}
                            name="month"
                          >
                            {monthJson &&
                              monthJson.map((item, index) => (
                                <option value={item.index}>{item.short}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label className="dob-label">Day</label>{" "}
                        <small>(Ex: 05)</small>
                        <input
                          {...register('day',{
                            onChange:(e)=>handleChangeDOB(e),
                            required: true,
                            minLength: 2,
                            maxLength: 2,
                            pattern: /\d+/,
                            min: 1,
                            max: maxvalue,
                          })}
                          //value={dateInputs.day}
                          defaultValue={dateInputs.day}
                          name="day"
                          //type="tel"
                          type="number"
                          onKeyPress={handleChangeDOB}
                          //onChange={handleChangeDOB}
                          onBlur={handleChangeDOB}
                          /*onWheel={event => { 
                                                        event.preventDefault(); 
                                                        if(typeof event.target.blur === 'function' ){
                                                            event.target.blur()
                                                        }
                                                    }}*/
                          className={`form-control ${
                            errors.day ? "is-invalid" : ""
                          } dob-box`}
                          // placeholder="05"
                        />
                        {errors.day && errors.day.type === "required" && (
                          <div className="invalid-feedback-custom">
                            Day is required
                          </div>
                        )}
                        {errors.day &&
                          (errors.day.type === "max" ||
                            errors.day.type === "min") && (
                            <div className="invalid-feedback-custom">
                              Invalid Date
                            </div>
                          )}
                        {errors.day &&
                          (errors.day.type === "minLength" ||
                            errors.day.type === "maxLength") && (
                            <div className="invalid-feedback-custom"></div>
                          )}
                      </div>
                      <div className="col-md-5 dob-label font-weight-bold">
                        <label className="mt-5">
                          {currentYears} Years {currentMonths} Months{" "}
                          {currentDays} Days
                          {/*monthJson[parseInt(dateInputs.month) - 1].name} {dateInputs.day}, {dateInputs.year*/}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Gender</label> (
                    <span className="required-span">*</span>)<br />
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                       // onChange={handleChange}
                        type="radio"
                        id="customRadio1"
                        value="MALE"
                        checked={inputs.gender === "MALE" ? "checked" : ""}
                        name="gender"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="customRadio1"
                      >
                        Male
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                        //onChange={handleChange}
                        type="radio"
                        id="customRadio2"
                        value="FEMALE"
                        checked={inputs.gender === "FEMALE" ? "checked" : ""}
                        name="gender"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="customRadio2"
                      >
                        Female
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                        //onChange={handleChange}
                        type="radio"
                        id="customRadio3"
                        value="NOT_DISCLOSED"
                        checked={
                          inputs.gender === "NOT_DISCLOSED" ? "checked" : ""
                        }
                        name="gender"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="customRadio3"
                      >
                        Other
                      </label>
                    </div>
                    {errors.gender && errors.gender.type === "required" && (
                      <div className="invalid-feedback-custom">
                        Gender is required
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>About</label> (
                    <span className="required-span">*</span>)
                    <textarea
                      {...register('about',{ onChange:(e)=>handleChange(e),required: true })}
                      defaultValue={inputs.about}
                      //maxlength="150"
                      //defaultValue={inputs.about}
                      name="about"
                      rows="7"
                      //onChange={handleChange}
                      className={`form-control h-100 ${
                        errors.about && "is-invalid"
                      }`}
                      placeholder="Client's favorite activities and foods/ likes /dislikes etc."
                    ></textarea>
                    {errors.about?.type === "required" && (
                      <div className="invalid-feedback-custom">
                        About is required
                      </div>
                    )}
                  </div>
                </div>

                {params.id ? (
                  <></>
                ) : (
                  <div className="col-md-2">
                    <button
                      type="submit"
                      className="btn  btn-primary d-block custom-btn-green"
                      style={{ width: 180 }}
                    >
                      <img
                        className="pr-2"
                        src="../resources/assets/plus-icon-button.svg"
                        alt=""
                      />
                      Add Client
                    </button>
                  </div>
                )}

                {params.id && (
                  <>
                    <div className="col-md-4">
                      <div className="form-group mb-4">
                        <label>Allergies</label>
                        <textarea
                          maxlength="500"
                          defaultValue={inputs.allergies}
                          name="allergies"
                          rows="3"
                          onChange={handleChange}
                          className={`form-control h-100 ${
                            errors.allergies && "is-invalid"
                          }`}
                          placeholder="Enter Allergies"
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group mb-4">
                        <label>Precautions</label>
                        <textarea
                          maxlength="500"
                          defaultValue={inputs.precautions}
                          name="precautions"
                          rows="3"
                          onChange={handleChange}
                          className={`form-control h-100 ${
                            errors.precautions && "is-invalid"
                          }`}
                          placeholder="Enter Precautions"
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group mb-4">
                        <label>Medications</label>
                        <textarea
                          maxlength="500"
                          defaultValue={inputs.medications}
                          name="medications"
                          rows="3"
                          onChange={handleChange}
                          className={`form-control h-100 ${
                            errors.medications && "is-invalid"
                          }`}
                          placeholder="Enter Medications"
                        ></textarea>
                      </div>
                    </div>

                    {/* For Service Section */}

                    <label className="conetent-title mt-4">
                      Assigned Therapy
                    </label>

                    <div className="col-md-12">
                      <div className="form-group mb-4">
                        <table className="table custom-table assigned-therapy">
                          <tbody>
                            <tr className="">
                              <td className="border-top-0">
                                Physician NPI Number <br />{" "}
                                <span>
                                  {familyMembers &&
                                    familyMembers.physicianNpiNumber}
                                </span>{" "}
                              </td>
                              <td className="border-top-0">
                                Physician First Name
                                <br />{" "}
                                <span>
                                  {familyMembers &&
                                    familyMembers.physicianFirstName}
                                </span>
                              </td>
                              <td className="border-top-0">
                                Physician Last Name
                                <br />
                                <span>
                                  {familyMembers &&
                                    familyMembers.physicianLastName}
                                </span>
                              </td>
                            </tr>
                            <tr className="">
                              <td className="border-top-0">
                                Allergies
                                <br />{" "}
                                <span>
                                  {familyMembers && familyMembers.allergies}
                                </span>
                              </td>
                              <td className="border-top-0">
                                Precautions
                                <br />
                                <span>
                                  {familyMembers && familyMembers.precautions}
                                </span>
                              </td>
                              <td className="border-top-0">
                                Medications
                                <br />
                                <span>
                                  {familyMembers && familyMembers.medications}
                                </span>
                              </td>
                            </tr>
                            <tr className="">
                              <td className="border-top-0">
                                Interpreter
                                <br />{" "}
                                <span>
                                  {familyMembers.interpreter &&
                                    familyMembers.interpreter.userProfile
                                      .firstName +
                                      " " +
                                      familyMembers.interpreter.userProfile
                                        .lastName}
                                </span>
                              </td>
                              <td className="border-top-0">
                                <br />{" "}
                              </td>
                              <td className="border-top-0">
                                <br />{" "}
                              </td>
                            </tr>
                            {/* <tr className="">
                                                                <td className="border-top-0">Attachements<br /> 
                                                                    <div className="attachment-images-section-view mt-2 d-flex">
                                                                        {familyMembers.attachments && familyMembers.attachments.map((item, index) =>
                                                                            <div className="images">
                                                                                <button type="button" onClick={(e) => removeAttachments(item.attachmentKeys)}>
                                                                                    <img className="close" src="../resources/assets/close-fill-blue.svg" />
                                                                                </button>
                                                                                <img className="img" src={item.url} />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td className="border-top-0"><br /> </td>
                                                                <td className="border-top-0"><br /> </td>
                                                            </tr> */}
                            {inputs.familyMemberTherapies &&
                              inputs.familyMemberTherapies.map((item) => (
                                <>
                                  <tr className="">
                                    <td className="border-top-0">
                                      Service Type <br />
                                      <span>
                                        {item.isnew
                                          ? getTherapyName(item.therapyId)
                                          : item.therapy.name}
                                      </span>{" "}
                                    </td>
                                    <td className="border-top-0">
                                      Referral Date <br />
                                      <span>{item.referralDate}</span>{" "}
                                    </td>
                                    <td className="border-top-0">
                                      Therapist
                                      <br />
                                      <span>
                                        {item.isnew
                                          ? item.therapisName
                                          : item.therapist.userProfile
                                              .firstName +
                                            " " +
                                            item.therapist.userProfile.lastName}
                                      </span>{" "}
                                    </td>

                                    <td
                                      className="border-top-0 cursor-pointer text-right"
                                      onClick={() =>
                                        inputs.familyMemberTherapies.length > 1
                                          ? removeTempAssignTherapy(
                                              item.isnew
                                                ? item.therapyId
                                                : item.therapy.id,
                                              item.isnew,
                                              item.isnew ? "" : item.id
                                            )
                                          : console.log(
                                              "Min one service should be here"
                                            )
                                      }
                                    >
                                      {inputs?.familyMemberTherapies.length >
                                        1 && (
                                        <img src="../../resources/assets/action-icon-delete.svg" />
                                      )}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colSpan="4"
                                      className="border-top-0 w-50"
                                    >
                                      ICD 10 Code <br />
                                      <table className="table custom-table">
                                        {!item.isnew &&
                                          item.diagnosis &&
                                          item.diagnosis.map((item1) => (
                                            <tr className="bg-common-gray">
                                              <td className="w-0">
                                                {item1.code} -{" "}
                                                {item1.description}
                                              </td>
                                            </tr>
                                          ))}

                                        {item.isnew &&
                                          item.diagnosisIds &&
                                          item.diagnosisIds.map((item) => (
                                            <tr className="bg-common-gray">
                                              <td className="w-0">
                                                {Number.isInteger(item)
                                                  ? ""
                                                  : item.split("$&")[1] +
                                                    " - " +
                                                    item.split("$&")[2]}
                                              </td>
                                            </tr>
                                          ))}
                                      </table>
                                    </td>
                                  </tr>
                                  <tr className="border-sestion-full h-2 bg-white">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </>
                              ))}

                            {familyMembers.length == 0 && (
                              <tr className="text-center">
                                <td colSpan="4">
                                  <strong>
                                    No service found, please add service from
                                    service details section
                                  </strong>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="">
                        <label className="conetent-title p-0">
                          Service Details
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 border-sestion-full mb-3"></div>

                    <div className="col-md-4">
                      <div className="form-group mb-4">
                        <label>Service Type</label> (
                        <span className="required-span">*</span>)
                        <div className="d-flex">
                          <img
                            className="pr-3"
                            src="../../resources/assets/user-icon-blue.svg"
                          />
                          <div className="input-group">
                            <select
                              className="custom-select"
                              onChange={handleChange}
                              value={assignServices.therapyId}
                              name="therapyId"
                              required
                            >
                              <option value="0">Select Service Type</option>
                              {therapies &&
                                therapies.map((item, index) => (
                                  <option value={item.id}>
                                    {item.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group mb-4">
                        <label>Referral Date</label> (
                        <span className="required-span">*</span>)
                        <div className="d-flex">
                          <div className="input-group">
                            <DatePicker
                              onChange={handleChangeDate}
                              value={refDateValue}
                              name="referralDate"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group mb-4">
                        <label>Therapist</label> (
                        <span className="required-span">*</span>)
                        <div className="d-flex">
                          <img
                            className="pr-3"
                            src="../../resources/assets/user-icon-blue.svg"
                          />
                          <div className="input-group">
                            <select
                              className="custom-select"
                              onChange={handleChange}
                              value={assignServices.therapistId}
                              name="therapistId"
                              required
                            >
                              <option selected>Select Therapist</option>
                              {therapist &&
                                therapist.map((item, index) => (
                                  <option value={item.id}>
                                    {item.userProfile.firstName}{" "}
                                    {item.userProfile.lastName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="form-group mb-4">
                        <label>ICD 10 Codes</label> (
                        <span className="required-span">*</span>)
                        <div className="d-flex">
                          <div className="input-group">
                            <select
                              className="custom-select"
                              value={inputs.diagnosisIds}
                              onChange={handleChange}
                              multiple
                              name="diagnosisIds"
                              size="5"
                              style={{ overflowX: "auto" }}
                            >
                              <option selected>Select Code</option>
                              {diagnoses &&
                                diagnoses.map((item, index) => (
                                  <option
                                    value={
                                      item.id +
                                      "$&" +
                                      item.code +
                                      "$&" +
                                      item.description
                                    }
                                  >
                                    {item.code}- {item.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-5">
                                <div className="form-group mb-4">
                                    <label>ICD 10 Codes Description</label>
                                    <div className="d-flex icd-h-desc">
                                        <table className="table custom-table">

                                            <tr className="bg-common-gray">
                                                <td><strong>Code</strong></td>
                                                <td><strong>Description</strong></td>
                                            </tr>

                                            {inputs.diagnosisIds && inputs.diagnosisIds.map(item =>
                                                <tr className="bg-common-gray">
                                                    <td>{item.split('$&')[1]}</td>
                                                    <td>{item.split('$&')[2]}</td>
                                                </tr>
                                            )}

                                        </table>
                                    </div>

                                </div>
                            </div> */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="d-block">&nbsp;</label>
                        <button
                          type="button"
                          onClick={() =>
                            assignTherapyTherapist(
                              inputs.firstName,
                              inputs.lastName
                            )
                          }
                          className="cursor-pointer btn btn-primary custom-btn-green custom-btn-green-auto"
                        >
                          <img
                            className="pr-2"
                            src="../../resources/assets/plus-icon-button.svg"
                            alt="Click to assign therapy"
                          />{" "}
                          Add Service
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </form>

            {params.id ? (
              <>
                {familyMembers.firstName && (
                  <>
                    <div className="col-md-12">
                      {inputs.insuranceDetailUpdateForms &&
                        inputs.insuranceDetailUpdateForms.length !== 3 && (
                          <div className="">
                            <label className="conetent-title p-0">
                              Insurance Information
                            </label>
                            <div className="col-md-12 border-sestion-full mb-3"></div>

                            <div className="card-body">
                              <form
                                onSubmit={handleSubmit(addInsuranceInformation)}
                              >
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Payee Name</label> (
                                      <span className="required-span">*</span>)
                                      <input
                                        //value={insuranceDetails.payeeName}
                                        defaultValue={
                                          insuranceDetails.payeeName
                                        }
                                        name="payeeName"
                                        //onChange={change}
                                        type="text"
                                        className={`form-control ${
                                          errors.payeeName && "is-invalid"
                                        }`}
                                        placeholder="Enter Payee Name"
                                        {...register('payeeName',{
                                          onChange:(e)=>change(e),
                                          maxLength: 30,
                                          validate: (value) => {
                                            const regex = /^$|^[a-zA-Z 0-9]+$/;
                                            const result = regex.test(value);
                                            setInsuranceDetails(
                                              (insuranceDetails) => ({
                                                ...insuranceDetails,
                                                ["payeeName"]: value,
                                              })
                                            );
                                            return result;
                                          },
                                        })}
                                      />
                                      {errors.payeeName &&
                                        errors.payeeName.type ===
                                          "required" && (
                                          <div className="invalid-feedback-custom">
                                            Payee name is required
                                          </div>
                                        )}
                                      {errors.payeeName &&
                                        errors.payeeName.type ===
                                          "validate" && (
                                          <div className="invalid-feedback-custom">
                                            Alphabetical and numbers only
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Payee/Claims Address</label> (
                                      <span className="required-span">*</span>)
                                      <input
                                        value={insuranceDetails.payeeAddress}
                                        //defaultValue={inputs.payeeAddress}
                                        name="payeeAddress"
                                        onChange={change}
                                        type="text"
                                        className={`form-control`}
                                        placeholder="Enter Payee/Claims Address"
                                        //{...register({ maxLength: 40, pattern: nameRegex })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Payee Phone Number</label> (
                                      <span className="required-span">*</span>)
                                      <InputMask
                                        mask="(999) 999-9999"
                                        value={
                                          insuranceDetails.payeePhoneNumber
                                        }
                                        type="tel"
                                        name="payeePhoneNumber"
                                        placeholder="Enter Payee Phone Number"
                                        className={`form-control`}
                                        {...register('payeePhoneNumber',{onChange:(e)=>change(e),required:true})}
                                        //onChange={change}
                                      >
                                        {/* ({props}) => <input
                                                                        // value={insuranceDetails.payeePhoneNumber}
                                                                        //defaultValue={inputs.payeePhoneNumber}
                                                                        // name="payeePhoneNumber" onChange={change}
                                                                        // type="number"
                                                                        className={`form-control ${errors.firstName && 'is-invalid'}`}
                                                                        placeholder="Enter Payee Phone Number"
                                                                        // {...register({ minLength: 10, maxLength: 10, pattern: /\d+/ })}
                                                                        {...props}
                                                                    /> */}
                                      </InputMask>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Insurance Plan</label> (
                                      <span className="required-span">*</span>)
                                      <input
                                        value={insuranceDetails.insurancePlan}
                                        //defaultValue={inputs.insurancePlan}
                                        name="insurancePlan"
                                        onChange={change}
                                        type="text"
                                        className={`form-control`}
                                        placeholder="Enter Insurance Plan"
                                        // {...register({ pattern: nameRegex })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Group Number</label> (
                                      <span className="required-span">*</span>)
                                      <input
                                        value={insuranceDetails.groupNumber}
                                        //defaultValue={inputs.groupNumber}
                                        name="groupNumber"
                                        //onChange={change}
                                        type="number"
                                        onWheel={(event) => {
                                          event.preventDefault();
                                          if (
                                            typeof event.target.blur ===
                                            "function"
                                          ) {
                                            event.target.blur();
                                          }
                                        }}
                                        className={`form-control ${
                                          errors.groupNumber && "is-invalid"
                                        }`}
                                        placeholder="Enter Group Number"
                                        {...register('groupNumber',{ onChange:(e)=>change(e),pattern: /\d+/ })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Policy Number</label> (
                                      <span className="required-span">*</span>)
                                      <input
                                        value={insuranceDetails.policyNumber}
                                        //defaultValue={inputs.policyNumber}
                                        name="policyNumber"
                                        //onChange={change}
                                        type="number"
                                        onWheel={(event) => {
                                          event.preventDefault();
                                          if (
                                            typeof event.target.blur ===
                                            "function"
                                          ) {
                                            event.target.blur();
                                          }
                                        }}
                                        className={`form-control ${
                                          errors.policyNumber && "is-invalid"
                                        }`}
                                        placeholder="Enter Policy Number"
                                        {...register('policyNumber',{ onChange:(e)=>change(e),pattern: /\d+/ })}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Type of Insurance</label>
                                      <div className="input-group">
                                        <select
                                          className={`custom-select ${
                                            errors.insuranceType && "is-invalid"
                                          }`}
                                          onChange={change}
                                          value={insuranceDetails.insuranceType}
                                          name="insuranceType"
                                        >
                                          <option value="">
                                            Select Type of Insurance
                                          </option>
                                          <option
                                            value="PRIMARY"
                                            disabled={`${
                                              inputs.insuranceDetailUpdateForms &&
                                              inputs.insuranceDetailUpdateForms.filter(
                                                (item) =>
                                                  item.insuranceType ===
                                                  "PRIMARY"
                                              ).length > 0
                                                ? "disabled"
                                                : ""
                                            }`}
                                          >
                                            Primary
                                          </option>
                                          <option
                                            value="SECONDARY"
                                            disabled={`${
                                              inputs.insuranceDetailUpdateForms &&
                                              inputs.insuranceDetailUpdateForms.filter(
                                                (item) =>
                                                  item.insuranceType ===
                                                  "SECONDARY"
                                              ).length > 0
                                                ? "disabled"
                                                : ""
                                            }`}
                                          >
                                            Secondary
                                          </option>
                                          <option
                                            value="OTHER"
                                            disabled={`${
                                              inputs.insuranceDetailUpdateForms &&
                                              inputs.insuranceDetailUpdateForms.filter(
                                                (item) =>
                                                  item.insuranceType === "OTHER"
                                              ).length > 0
                                                ? "disabled"
                                                : ""
                                            }`}
                                          >
                                            Other
                                          </option>
                                        </select>
                                      </div>
                                      {errors.insuranceType &&
                                        errors.insuranceType.type ===
                                          "required" && (
                                          <div className="invalid-feedback-custom">
                                            Select Language
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2">
                                    <button
                                      type="submit"
                                      style={{ width: 180 }}
                                      className="btn  btn-primary d-block custom-btn-green"
                                    >
                                      <img
                                        className="pr-2"
                                        src="../../resources/assets/plus-icon-button.svg"
                                        alt=""
                                      />
                                      Add Insurance
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}

                      <label className="conetent-title">
                        Assigned Insurance Information
                      </label>
                      <table className="table custom-table assigned-therapy">
                        <tbody>
                          {inputs.insuranceDetailUpdateForms ? (
                            inputs.insuranceDetailUpdateForms
                              .sort(
                                (a, b) =>
                                  getShortInsurance[a.insuranceType] -
                                  getShortInsurance[b.insuranceType]
                              )
                              .map((infoInsurance, index) => (
                                <>
                                  <tr>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Payee Name <br />{" "}
                                      <span>{infoInsurance.payeeName}</span>
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Payee Address <br />{" "}
                                      <span>{infoInsurance.payeeAddress}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Payee Phone <br />{" "}
                                      <span>
                                        {" "}
                                        {infoInsurance.payeePhoneNumber}{" "}
                                      </span>
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Insurance Plan <br />{" "}
                                      <span>
                                        {" "}
                                        {infoInsurance.insurancePlan}{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Group Number <br />{" "}
                                      <span> {infoInsurance.groupNumber} </span>
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Policy Number <br />{" "}
                                      <span>
                                        {" "}
                                        {infoInsurance.policyNumber}{" "}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Type of Insurance <br />{" "}
                                      <span>
                                        {infoInsurance.insuranceType}{" "}
                                      </span>
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="4" className="text-left">
                                      <div className="d-flex">
                                        <img
                                          className="pl-3"
                                          src="../../resources/assets/action-icon-delete.svg"
                                          className="cursor-pointer"
                                          onClick={() =>
                                            removeInsuranceInformation(
                                              familyMembers.firstName,
                                              familyMembers.lastName,
                                              infoInsurance.policyNumber,
                                              infoInsurance.id
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="h-2 bg-white">
                                    <td colSpan="2"></td>
                                  </tr>
                                </>
                              ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan="2">
                                <strong>No data found</strong>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </>
            ) : (
              <label className="conetent-title mt-4">
                Added Children Information
              </label>
            )}
          </div>

          {params.id ? (
            <div className="row addtheripist">
              <div className="col-md-12">
                <hr />
              </div>
              <div className="col-md-12 d-flex">
                <button
                  ref={submitBtnRef}
                  className="btn  btn-primary d-block stepper-button-next"
                  type="submit"
                  onClick={onSubmitNext}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="col-md-12 mt-0">
                <table className="table custom-table">
                  <tbody>
                    {familyMembers.length > 0 ? (
                      familyMembers.map((info, index) => (
                        <tr>
                          <td>
                            <div className="col-auto">
                              <div className="user-info user-info-table">
                                <span className="admin-name justify-content-start">
                                  <img
                                    src={
                                      info?.profileImage?.url
                                        ? info?.profileImage?.url
                                        : info?.profileUrl
                                        ? info?.profileUrl
                                        : "/resources/assets/defaultprofile.png"
                                    }
                                    alt="Client Profile Picture"
                                    className="user-info-img"
                                  />
                                  <div className="content ml-3">
                                    <sapn className="info-name">
                                      {info.firstName} {info.lastName}
                                    </sapn>
                                    <sapn className="ml-5 info-dob">
                                      Gender -{" "}
                                      {info.gender === "NOT_DISCLOSED"
                                        ? "Other"
                                        : info.gender === "FEMALE"
                                        ? "Female"
                                        : "Male"}
                                    </sapn>
                                    <span className="ml-5 info-dob">
                                      Date of Birth -{" "}
                                      {
                                        monthJson[
                                          parseInt(info.dob.split("-")[1]) - 1
                                        ].name
                                      }{" "}
                                      {info.dob.split("-")[2]},{" "}
                                      {info.dob.split("-")[0]}
                                      {/* {info.dob} */}
                                    </span>
                                    <small>
                                      <br />
                                      {info.about}
                                    </small>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              {/* <img className="pr-3" src="../resources/assets/action-icon-edit.svg" /> */}
                              {/* <div className="action-devider"></div> &nbsp;&nbsp;&nbsp; */}
                              <img
                                className="pl-3"
                                src="../resources/assets/action-icon-delete.svg"
                                className="cursor-pointer"
                                onClick={() => removeFamilyMembers(index)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan="2">
                          <strong>No data found</strong>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="row addtheripist">
                <div className="col-md-12">
                  <hr />
                </div>
                <div className="col-md-12 d-flex">
                  <button
                    type="button"
                    className="btn  btn-primary d-block stepper-button-back"
                    onClick={(e) => props.handleBack()}
                  >
                    Back
                  </button>
                  {!params.id && (
                    <button
                      className="btn  btn-primary d-block stepper-button-next"
                      id="save-and-back"
                      onClick={(e) => onSubmitSaveAndBack(e)}
                    >
                      Save & Return
                    </button>
                  )}
                  <button
                    className="btn  btn-primary d-block stepper-button-next"
                    type="button"
                    onClick={onSubmitNext}
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default FamilyChildrenInformation;
