import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";
import { adminService } from "../../services/adminService";
import TeamChangeRequest from "./TeamChangeRequest";
import RequestSession from "./RequestSession";
import Messages from "../../components/messages/Messages";
import { maskPhoneNumber } from "../../utils/commonFunctions";

function MyTeam(props) {
  const [teamdetails, setTeamDetails] = useState([]);
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [reqSessionModal, setReqSessionModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [isinterpreter, setIsInterpreter] = useState(false);
  const [childDetail, setChildDetail] = useState(false);
  const [rowclickid, setRowClickId] = useState(0);
  const [detail, setDetail] = useState({
    details: "",
  });
  const [inputs, setInputs] = useState({
    familyMemberId: "",
  });

  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (inputs.familyMemberId) {
      setloading(true);
      adminService
        .getAll(`families/my-team?familyMemberId=${inputs.familyMemberId}`)
        .then(
          (res) => {
            if (res?.status === "Success") {
              setTeamDetails(res.data);
              setloading(false);
            } else {
              setloading(false);
              res?.message
                ? toast.error(res?.message)
                : console.log("Somthing went wrong");
            }
          },
          (error) => {
            console.log(error);
            setloading(false);
          }
        );
    }
  }, [inputs.familyMemberId]);

  useEffect(() => {
    if (childDetail?.familyMembers?.length > 0) {
      setInputs((input) => ({
        ...input,
        familyMemberId: childDetail?.familyMembers[0].id,
      }));
    }
  }, [childDetail?.familyMembers]);

  useEffect(() => {
    const therapistList =
      teamdetails?.therapists?.map((item, index) => ({
        key: item.id + "therapist" + index,
        item,
      })) || [];

    const list = [
      ...therapistList,
      teamdetails?.interpreter && {
        key: teamdetails?.interpreter.id,
        item: teamdetails?.interpreter,
      },
    ].filter(Boolean);
    if (list.length > 0) {
      const isSelected = list.some((itx) => itx.key === rowclickid);
      if (!isSelected) {
        onClickRow(list[0].key, list[0].item);
      }
    } else {
      onClickRow(null, null);
    }
  }, [teamdetails]);

  useEffect(() => {
    adminService.getAll(`families/detail`).then(
      (res) => {
        if (res?.status === "Success") {
          setChildDetail(res.data);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const onClickRow = (id, user) => {
    setRowClickId(id);
    setSelectedUser(user?.userProfile);
  };
  const ChangeTherapist = (id, firstName, lastName) => {
    let details = {
      //therapistid: therapistid,
      id: id,
      firstName: firstName,
      lastName: lastName,
      familyMemberId: inputs.familyMemberId,
      familyId: childDetail.userProfile.id,
    };
    setIsInterpreter(false);
    setModalOpenClose(true);
    setDetail((detail) => ({ ...detail, ["details"]: details }));
  };
  const reqSession = (id) => {
    setReqSessionModal(true);
    let details = {
      id: id,
      familyMemberId: inputs.familyMemberId,
    };
    setDetail((detail) => ({ ...detail, ["details"]: details }));
  };
  const ChangeInterpreter = (id, firstName, lastName) => {
    let details = {
      id: id,
      firstName: firstName,
      familyMemberId: inputs.familyMemberId,
      lastName: lastName,
    };
    setIsInterpreter(true);
    setModalOpenClose(true);
    setDetail((detail) => ({ ...detail, ["details"]: details }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "familyMemberId") {
      setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
    } else setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">My Team</div>
            </div>
            <div className="row no-gutters mt-5">
              <div className="col-md-6 ml-3">
                <div className="form-group">
                  <label>Select Child</label>
                  <div className="row ml-2">
                    <img
                      src="../resources/assets/family-icon-blue.svg"
                      className="img-fluid"
                    />
                    <div className="col-md-10">
                      <div className="input-group">
                        <select
                          className={`custom-select`}
                          onChange={handleChange}
                          value={inputs.familyMemberId}
                          defaultValue={inputs.familyMemberId}
                          name="familyMemberId"
                        >
                          <option selected>Select Child</option>
                          {childDetail.familyMembers &&
                            childDetail.familyMembers.map((item, index) => (
                              <option key={item.id} value={item.id}>
                                {item.firstName + " " + item.lastName}
                              </option>
                            ))}
                        </select>
                        <div className="input-group-prepend">
                          <img
                            className="m-auto"
                            src="../resources/assets/Polygon 1.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8 therapist p-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="border-0" scope="col">
                          Name
                        </th>
                        <th className="border-0" scope="col">
                          Details
                        </th>
                        <th className="border-0" scope="col"></th>
                      </tr>
                    </thead>
                    {loading ? (
                      <div className="loader"></div>
                    ) : (
                      <>
                        <tbody>
                          {teamdetails.therapists &&
                            teamdetails.therapists.map((item, index) => {
                              const key = item.id + "therapist" + index;
                              return (
                                <tr
                                  key={key}
                                  onClick={() => onClickRow(key, item)}
                                  className={`cursor-pointer ${
                                    item.id + "therapist" + index == rowclickid
                                      ? "bg-light-cyan"
                                      : ""
                                  }`}
                                >
                                  <td>
                                    <div className="user-info mt-3 mb-5 ml-2">
                                      <span className="admin-name justify-content-start">
                                        <img
                                          src={
                                            item.userProfile.profilePicUrl !==
                                            ""
                                              ? item.userProfile.profilePicUrl
                                              : "/resources/assets/defaultprofile.png"
                                          }
                                          className="image"
                                          alt=""
                                        />
                                        <div className="content ml-3">
                                          <span className="view-all-link">
                                            {item.userProfile.firstName}{" "}
                                            {item.userProfile.lastName}
                                          </span>
                                          <small>
                                            <br />
                                            {item.userProfile.about}
                                          </small>
                                          <Link
                                            className=""
                                            onClick={() =>
                                              reqSession(item.userProfile.id)
                                            }
                                          >
                                            <small>
                                              <br />
                                              <u>Request Session</u>
                                            </small>
                                          </Link>
                                          <Link
                                            onClick={() =>
                                              ChangeTherapist(
                                                item.userProfile.id,
                                                item.userProfile.firstName,
                                                item.userProfile.lastName
                                              )
                                            }
                                          >
                                            <small>
                                              <br />
                                              <u>Change Request</u>
                                            </small>
                                          </Link>
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="mt-3">
                                      <h6>Contact Email:</h6>
                                      <p className="view-all-link">
                                        {item.userProfile.email}
                                      </p>
                                    </div>
                                    <div>
                                      <h6>Contact Phone:</h6>
                                      <p className="view-all-link">
                                        {maskPhoneNumber(
                                          item.userProfile.phone
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="mr-4">
                                      <img
                                        src="../resources/assets/pointing-arrow.svg"
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                        {teamdetails.interpreter && (
                          <tbody>
                            <tr
                              onClick={() =>
                                onClickRow(
                                  teamdetails.interpreter &&
                                    teamdetails.interpreter.id,
                                  teamdetails.interpreter
                                )
                              }
                              className={`cursor-pointer ${
                                teamdetails.interpreter &&
                                teamdetails.interpreter.id == rowclickid
                                  ? "bg-light-cyan"
                                  : ""
                              }`}
                            >
                              <td>
                                <div className="user-info mt-3 mb-5 ml-2">
                                  <span className="admin-name justify-content-start">
                                    <img
                                      src={
                                        teamdetails.interpreter &&
                                        (teamdetails.interpreter.userProfile
                                          .profilePicUrl !== ""
                                          ? teamdetails.interpreter.userProfile
                                              .profilePicUrl
                                          : "/resources/assets/defaultprofile.png")
                                      }
                                      className="image"
                                      alt=""
                                    />
                                    <div className="content ml-3">
                                      <span className="view-all-link">
                                        {teamdetails.interpreter &&
                                          teamdetails.interpreter.userProfile
                                            .firstName}{" "}
                                        {teamdetails.interpreter &&
                                          teamdetails.interpreter.userProfile
                                            .lastName}
                                      </span>
                                      <small>
                                        <br />
                                        {teamdetails.interpreter &&
                                          teamdetails.interpreter.userProfile
                                            .about}
                                      </small>
                                      <Link
                                        onClick={() =>
                                          ChangeInterpreter(
                                            teamdetails.interpreter &&
                                              teamdetails.interpreter
                                                .userProfile.id,
                                            teamdetails.interpreter &&
                                              teamdetails.interpreter
                                                .userProfile.firstName,
                                            teamdetails.interpreter &&
                                              teamdetails.interpreter
                                                .userProfile.lastName
                                          )
                                        }
                                      >
                                        <small>
                                          <br />
                                          <u>Change Request</u>
                                        </small>
                                      </Link>
                                    </div>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="mt-3">
                                  <h6>Contact Email:</h6>
                                  <p className="view-all-link">
                                    {teamdetails.interpreter &&
                                      teamdetails.interpreter.userProfile.email}
                                  </p>
                                </div>
                                <div>
                                  <h6>Contact Phone:</h6>
                                  <p className="view-all-link">
                                    {teamdetails.interpreter &&
                                      maskPhoneNumber(
                                        teamdetails.interpreter.userProfile
                                          .phone
                                      )}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="mr-4">
                                  <img
                                    src="../resources/assets/pointing-arrow.svg"
                                    alt=""
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                        {teamdetails && teamdetails.length == 0 && (
                          <tr>
                            <td colSpan="3">
                              <h5 className="text-center p-3">
                                Please select child
                              </h5>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>

              <div className="col-md-4 therapist table-th-merge-gap">
                <div className="table-responsive">
                  <table className="table d-block">
                    <thead className="d-block">
                      <tr className="d-block">
                        <th className="border-0" scope="col">
                          Messages
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-color1 d-block">
                      <tr className="d-block">
                        <td
                          className={classNames("d-block", {
                            "p-0": !selectedUser,
                          })}
                        >
                          {selectedUser && (
                            <Messages
                              otherUser={selectedUser}
                              myChildId={inputs.familyMemberId}
                              bgColor="bg-color1"
                              page="family"
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpenClose && (
        <TeamChangeRequest
          closeModal={() => setModalOpenClose(false)}
          isinterpreter={isinterpreter}
          info={detail}
        />
      )}
      {reqSessionModal && (
        <RequestSession
          closeModal={() => setReqSessionModal(false)}
          reqSessionModal={reqSessionModal}
          info={detail}
        />
      )}
    </>
  );
}
export default MyTeam;
