import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FormFooter,
  FormHeader,
  HeaderDownloadButtons,
} from "./CommonComponents";
import { useForm } from "react-hook-form";
import CreateTemplate from "./CreateTemplate";
import { adminService } from "../../../services/adminService";

function HippaNoticeForm(props) {
  const formRef = React.useRef(null);
  const { handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "HippaNoticeForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const onSubmitFamilyForm = (isSubmit = false) => {
    setIsLoading(true);
    let formData = new FormData();
    // formData.append("signature", familyImageURL);
    console.log("formData 220", formData);
    formData.append(
      "formCreationForm",
      new Blob([JSON.stringify(props?.inputs)], {
        type: "application/json",
      })
    );
    adminService
      .onboardUser(
        `submitted-forms`,
        formData
      )
      .then((response) => {
        setIsLoading(false);
        toast.success("Form Submitted successfully");
        props.setIsFocused(!props.isFocused);
        props?.from == "admin" && props.handleBack("reload");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >  <div
        style={{
          position: 'absolute', top: 0, right: "100px"

        }}>
          {props?.data?.id && (
            <HeaderDownloadButtons startDownload={startDownload} />
          )}</div>
        <div className="card-lg">
          <FormHeader
            logoUrl={logoUrl}
            title="HIPAA NOTICE OF PRIVACY PRACTICES"
          />
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <hr />
            <div className="card-lg__content">
              <div style={{ width: "100%" }}>
                <p style={{ width: "100%" }}>
                  {name} Network is required by law to keep your health
                  information safe. This information may include:{" "}
                </p>
                <ul style={{ width: "100%" }}>
                  <li>Your medical history </li>
                  <li>Your test results </li>
                  <li>Treatment notes</li>
                  <li>Insurance information </li>
                  <li>
                    Notes from your doctor, teacher or other healthcare providers
                  </li>
                </ul>
                <p style={{ width: "100%" }}>
                  A government rule, called the Health Insurance Portability and
                  Accountability Act, or HIPAA for short, requires that you get a
                  copy of this privacy notice. We will ask you to sign a paper
                  saying that you have been given this notice. Read and refer to
                  the notice at any time to see how your health information can be
                  used and who can see it.
                </p>
                <p style={{ width: "100%", fontWeight: "bold", marginTop: 30 }}>
                  <b>How Your Health Information May be Used or Shared</b>
                </p>
                <p style={{ width: "100%" }}>
                  We may use or share your health information without your
                  permission for the following reasons:{" "}
                </p>
                <ul style={{ width: "100%" }}>
                  <li>
                    <b>Treatment</b> We may share information with doctors and
                    other health care providers who care for you. For example, if
                    your doctor orders speech therapy, we will share the results
                    of our treatment with that doctor.
                  </li>
                  <li>
                    <b>Payment</b> We may use and share information about the
                    treatment you receive with your insurance company or other
                    payer to receive payment for services. This may include
                    sharing important medical information. We may share
                    information to:{" "}
                    <ol>
                      <li>
                        Get the insurance company’s permission to start treatment{" "}
                      </li>
                      <li>Get permission for more treatment </li>
                      <li>Get paid for the treatment you receive</li>
                    </ol>
                  </li>
                  <li>
                    Health Care Operations. We may use and share your health
                    information to run our practice and be sure that all patients
                    receive good care. For example, we may use your health
                    information to:{" "}
                    <ol>
                      <li>See how well our services are working </li>
                      <li>See how well our staff is doing</li>
                      <li>See how we compare to other therapy companies</li>
                      <li>Make our services better </li>
                      <li>Help others study health care services </li>
                    </ol>
                  </li>
                </ul>
                <p style={{ width: "100%", marginTop: 30 }}>
                  <b>
                    Your Health Information May Also Be Used or Shared Without
                    Your Permission for:{" "}
                  </b>
                </p>
                <ul>
                  <li>
                    <b>Abuse and Neglect</b> We may share your health information
                    with government agencies when there is evidence of abuse,
                    neglect or domestic violence.{" "}
                  </li>
                  <li>
                    <b>Appointment Reminders</b> We may use your information to
                    remind you of upcoming appointments. Reminders may be sent in
                    the mail, by e-mail, or by phone call or voicemail message. If
                    you do not wish to get reminders, please let your provider
                    know.{" "}
                  </li>
                  <li>
                    <b>As Required by Law</b> We will share your information when
                    we are told to do so by federal, state, or local law. We will
                    also share information if we are asked by the police or
                    courts.
                  </li>
                  <li>
                    <b>Public Health Risks</b> We may report information to public
                    health agencies as required by law. This may be done to help
                    prevent disease, injury, or disability. It may also be done to
                    report medical device safety issues to the Food and Drug
                    Administration and to report diseases and infections.{" "}
                  </li>
                  <li>
                    <b>Regulatory Oversight</b> We may use or share your
                    information with agencies overseeing health care. This may
                    include sharing information for audits, licensure, and
                    inspections.
                  </li>
                  <li>
                    <b>Research</b> We may share your health information with
                    researchers to be included in their research project.
                    Information will be shared only for projects that have been
                    through a special approval process. These projects have rules
                    to protect your privacy too.{" "}
                  </li>
                  <li>
                    <b>Threats to Health and Safety</b> Your health information
                    may be shared if we believe that it will prevent a threat to
                    your health and safety or the health and safety of others.{" "}
                  </li>
                  <li>
                    <b>Worker’s Compensation</b> We will share your information
                    with Worker’s compensation if your case is being considered as
                    a work-related injury or illness.{" "}
                  </li>
                </ul>
                <p style={{ width: "100%", marginTop: 30 }}>
                  <b>
                    When Your Permission is Needed to Use or Share Your Health
                    Information{" "}
                  </b>
                </p>
                <p style={{ width: "100%" }}>
                  You must give us permission to use or share your health
                  information for any situation that is not listed in this notice.
                  You will be asked to sign a form called an authorization, to
                  allow us to use or share your information. You are allowed to
                  take back this authorization, called revoking authorization at
                  any time. We will not be able to get back the information that
                  we shared with your permission.{" "}
                </p>
                <p style={{ width: "100%" }}>
                  <b>Your Privacy Rights</b> You have the right to:{" "}
                </p>
                <ul>
                  <li>
                    <b>Ask us not to share your information</b> You can ask us not
                    to share your information for treatment, payment, or health
                    care operations. You can also ask us not to share information
                    with people involved in your care, like family members or
                    friends. You must ask for limits in writing. We must share
                    information when required by law. We do not have to agree to
                    what you ask.{" "}
                  </li>
                  <li>
                    <b>Ask us to contact you privately</b> You can ask us to
                    contact you only in a certain way or at a certain place. For
                    example, you may want us to call you but not to email you. Or
                    you may want us to call you at work and not at home. You must
                    ask us in writing. We will do all we can to do what you ask.{" "}
                  </li>
                  <li>
                    <b>Look at and copy your health information</b> You can ask us
                    to change information that you think is wrong. You can also
                    ask that we add information that is missing. You must ask us
                    in writing and give us a reason for the change. We do not have
                    to make the change.
                  </li>
                  <li>
                    <b>
                      Get a report of how and when your information was used or
                      shared
                    </b>{" "}
                    You can ask us to tell you when your information was shared
                    and who we shared it with. There are some rules about this.{" "}
                    <ol>
                      <li>You need to ask us in writing.</li>
                      <li>
                        You must tell us the dates you are asking about and if you
                        want a paper or electronic copy. o You may get information
                        going back 6 years, but it cannot be for earlier than
                        April 14, 2003. This is the date when the government
                        privacy rules took effect.{" "}
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>Get a paper copy of this privacy notice</b> You can get a
                    paper copy of this notice at any time. You can get a copy even
                    if you have already signed the form saying you have seen this
                    notice.{" "}
                  </li>
                  <li>
                    <b>File complaints</b> You can file a complaint with the
                    government if you think that:{" "}
                  </li>
                </ul>
                <p style={{ width: "100%" }}>
                  <b>Who is covered by This Notice?</b>{" "}
                </p>
                <p style={{ width: "100%" }}>
                  The people who must follow the rules in this notice are:{" "}
                </p>
                <ul>
                  <li>
                    All licensed and non-licensed therapists working at {name}{" "}
                    Network.{" "}
                  </li>
                </ul>
                <p style={{ width: "100%" }}>
                  <b>Changes to the Information in this Notice</b>
                </p>
                <p style={{ width: "100%" }}>
                  We may change this notice at any time. Changes may apply to
                  information we already have in your file and to any new
                  information. Copies of the new notice will be available from our
                  staff. The notice will have a date on the front page to tell you
                  when it went into effect.{" "}
                </p>
                <p style={{ width: "100%" }}>
                  <b>Complaints:</b> You may file a complaint if you think that we
                  did something wrong with your information. You can complain to
                  your regional office of the United States Office of Civil
                  Rights. To find out more about filing complaints go to &nbsp;
                  <a
                    href="www.hhs.gov/ocr/privacy/hipaa/complaints/index.html"
                    target="_blank"
                  >
                    www.hhs.gov/ocr/privacy/hipaa/complaints/index.html
                  </a>{" "}
                  All complaints must be in writing. You will not get in trouble
                  for filing a complaint.
                </p>
              </div>
              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {props?.from === 'admin' && props?.isFormOpen &&
                <>
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  <button
                    type={isLoading ? "button" : "submit"}
                    className="btn btn-success float-right1 mt-10 mb-10"
                  >
                    {isLoading ? "Loading...." : "Submit"}
                  </button>
                </>

              }
              {!props?.isFormOpen && (
                <button
                  className="btn btn-theme-green mb-10"
                  onClick={() => props?.handleBack()}
                  type="button"
                >
                  Back
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default HippaNoticeForm;
