import React, { useState } from 'react';
import { getUTCTimeToLocalByDateWithMeridium } from '../../../utils/commonFunctions';
function ProcedureTableList(props) {
    console.log("ProcedureTableList", props.procedureList);
    let procCode = '';
    let icdCode = '';
    let startTime = '';
    let endTime = '';
    let units = '';
    let sessionType = '';
    let key = '';
    //const [procedureListData, setProcedureListData] = useState();
    // setProcedureListData(props.procedureList);
    let htmlContent = "";
    if(props.procedureList){
    props.procedureList.forEach(function(item,index){
        procCode = item?.cptCode ? item?.cptCode?.procCode : '';
        icdCode = item?.diagnosis ? item?.diagnosis.code : '';
        startTime = getUTCTimeToLocalByDateWithMeridium(item.startTime);
        endTime = getUTCTimeToLocalByDateWithMeridium(item.endTime);
        units = item.units;
        sessionType = item.groupSession ? "Group" : "Individual";
        //setProcedureListData(index);
        htmlContent+= `<tr style={{ display: "table-row" }} key=${index}>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${procCode}</td>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${icdCode}</td>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${startTime}</td>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${endTime}</td>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${units}</td>
        <td className="tdBorder" style={{ padding: "8px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>${sessionType}</td>
    </tr>`;
        
        console.log("procedire item", procCode,index,key);
       // return key = index;
    })}
    console.log("procCode", procCode,key);
    return (
        <>
        <table className='procedureTable procedureGoals' style={{ width: "100%", display: "table", borderCollapse: "collapse", marginBottom: "10px" }}>
                    <thead style={{ display: "table-header-group" }}>
                        <tr style={{ display: "table-row" }}>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>CPT Code</th>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>ICD 10 Code</th>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>Session Start Time</th>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>Session End Time</th>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>Units</th>
                            <th style={{ padding: "10px", display: "table-cell", borderSize: "1px", borderSpacing: "0", borderColor: "#dee2e6", borderStyle: "solid" }}>Session Type</th>
                        </tr>
                    </thead>
                    <tbody style={{ display: "table-body-group" }} dangerouslySetInnerHTML={{__html: htmlContent}}>
                       
                    </tbody>
                </table>
        </>
    );
}

export default ProcedureTableList;