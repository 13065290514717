import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminService } from "../../../services/adminService";
import { IoIosTrash, IoMdCreate } from "react-icons/io";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "react-responsive-pagination";

function PendingTherapistList(props) {
  // const { therapistList } = props;
  const history = useNavigate();
  const [therapistList, setThrepistList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setloading] = useState(true);
  const [search, setSearch] = useState("");
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  useEffect(() => {
    getTherapistList();
  }, [currentPage]);

  const getTherapistList = () => {
    adminService
      .getAll(
        `therapists/filter?pendingOnboarding=true&page=${
          currentPage == 0 ? currentPage : currentPage - 1
        }&size=${100}&status=&name=&sort=id,DESC`
      )
      .then(
        (res) => {
          if (res?.status === "Success") {
            setThrepistList(res.data.content);
            setTotalPages(res.data.totalPages);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const deleteUser = (id) => {
    adminService.DELETE(`therapists/${id}`).then(
      (res) => {
        if (res.status === "Success") {
          setConfirmDeletion(false);
          toast.success(res.message);
          getTherapistList();
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <div className="row no-gutters">
        {confirmDeletion && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={() => deleteUser(confirmDeletion?.id)}
            onCancel={() => setConfirmDeletion(false)}
            focusCancelBtn
          >
            You want to delete user "{confirmDeletion?.userProfile?.firstName}".
          </SweetAlert>
        )}
        <div className="col-md-12 therapist p-0">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Therapist</th>
                  <th scope="col">User type</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {therapistList.length > 0 &&
                  therapistList &&
                  therapistList.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            {item.userProfile.firstName +
                              " " +
                              item.userProfile.lastName}
                          </td>
                          <td>
                            {item.userProfile.userType == "ROLE_THERAPIST"
                              ? "Therapist"
                              : item.userProfile.userType}
                          </td>
                          <td>{item.userProfile.email}</td>
                          <td>{item.userProfile.phone}</td>
                          <th>
                            <button
                              className="btn btn-theme-info btn-sm mb-10"
                              onClick={() =>
                                history(`/edit-pending-therapist/${item.id}`)
                              }
                            >
                              <IoMdCreate />
                            </button>
                            <button
                              className="btn btn-theme-info btn-sm mb-10"
                              onClick={() => setConfirmDeletion(item)}
                            >
                              <IoIosTrash />
                            </button>
                          </th>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {therapistList.length == 0 && (
              <h5 className="text-center p-3">No Data Found</h5>
            )}
            <Pagination
              total={totalPages}
              current={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PendingTherapistList;
