import React, { useState, useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import moment from "moment";

function EventPage(props) {
  console.log("recuring props", props.recurringEvent);
  const {
    inputs,
    handleChange,
    setInputs,
    recurringEvent,
    setrecurringEvent,
    dates,
    setdates,
    id,
  } = props;

  const setDate = (e) => {
    setInputs((input) => ({
      ...input,
      customSessionForm: {
        ...inputs?.customSessionForm,
        sessionEndDateTime:
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`,
      },
    }));
  };
  const setMonthDate = (e) => {
    setInputs((input) => ({
      ...input,
      customSessionForm: {
        ...inputs?.customSessionForm,
        weekDateTimeList:
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`,
      },
    }));
  };

  const handleSelectWeekDays = (d) => {
    const newLst = [...inputs.customSessionForm.weekDayList];
    if (newLst.includes(d)) {
      const dd = newLst.filter((f) => f != d);
      updateWeekList(dd);
    } else {
      newLst.push(d);
      updateWeekList(newLst);
    }
  };

  const updateWeekList = (list) => {
    setInputs((input) => ({
      ...input,
      customSessionForm: {
        ...inputs?.customSessionForm,
        weekDayList: list,
      },
    }));
  };

  // useEffect(() => {
  //   const selectedDay = moment(inputs?.startDateTime).format("dddd");
  //   const selectedDate =
  //     moment(inputs?.startDateTime).format("YYYY-MM-DD") + `T00:00:00.000Z`;
  //   if (!id) {
  //     setInputs((iputs) => ({
  //       ...iputs,
  //       customSessionForm: {
  //         ...inputs.customSessionForm,
  //         weekDayList: [selectedDay.toUpperCase()],
  //         weekDateTimeList: selectedDate,
  //         sessionEndDateTime: selectedDate,
  //       },
  //     }));
  //   }
  // }, [inputs?.startDateTime]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group row mb-20">
            <label className={id ? "col-md-3 textDisable" : "col-md-3"} style={{ marginTop: 5 }}>
              Repeat every
            </label>
            <div className="col-md-1 p-0">
              <div className="form-group mb-0">
                <div className="input-group">
                  <input
                    type="number"
                    min={1}
                    name="recurringFrequency"
                    value={inputs?.customSessionForm.recurringFrequency}
                    onChange={handleChange}
                    className={id ? "recurring-sm-input recurring-sm-inpt form-control disableInput" : "recurring-sm-input recurring-sm-inpt form-control"}
                    disabled={id ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 input-group">
              <select
                onChange={handleChange}
                className={id ? "recurring-sm-input recurring-sm-inpt form-control disableInput" :"recurring-sm-input recurring-sm-inpt form-control"}
                value={inputs.customSessionForm.sessionFrequency}
                name="sessionFrequency"
                style={{ height: "auto" }}
                disabled={id ? true : false}
              >
                <option value="Daily">Day</option>
                <option value="Weekly">Week</option>
                <option value="Monthly">Month</option>
              </select>
              {/* <div className="input-group-prepend">
                <img
                  className="m-auto"
                  src="../resources/assets/Polygon 1.svg"
                  alt=""
                />
              </div> */}
            </div>
            <br />
          </div>
        </div>
      </div>
      {inputs.customSessionForm.sessionFrequency === "Monthly1" && (
        <div className="row mb-20">
          <div className="col-md-7">
            <div className="form-group mb-0">
              <label>Monthly Date</label>
            </div>
            <div className="input-group sm-date-picker">
              <DatePicker
                multiple={false}
                value={inputs?.customSessionForm.weekDateTimeList}
                onChange={setMonthDate}
                name="weekDateTimeList"
                format="MM-DD-YYYY"
                // disabled={recurringEvent == "On" ? false : true}
                minDate={inputs?.startDateTime}
                editable={false}
              />
            </div>
          </div>
        </div>
      )}
      {inputs.customSessionForm.sessionFrequency === "Weekly" && (
        <div className="row mb-20">
          <div className="col-md-12">
            <div className="form-group mb-0">
              <label className={id ? "textDisable" : ''}>Repeats On</label>
            </div>
            <div className="col-md-12 p-0">
              {[
                "SUNDAY",
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY",
              ].map((d) => {
                return (
                  <div
                    className={`select-week-day inline-flex ${id ? "disable-week-days" : ""}
                  ${
                    [...inputs.customSessionForm.weekDayList].includes(d) ||
                    moment(inputs?.startDateTime)
                      .format("dddd")
                      .toUpperCase() === d
                      ? "seleted-week-day"
                      : ""
                  }
                  `}
                    onClick={() =>
                      id
                        ? console.log("")
                        : moment(inputs?.startDateTime)
                            .format("dddd")
                            .toUpperCase() === d
                        ? console.log("")
                        : handleSelectWeekDays(d)
                    }
                    style={{
                      cursor:
                        moment(inputs?.startDateTime)
                          .format("dddd")
                          .toUpperCase() === d
                          ? "default"
                          : "pointer",
                    }}
                  >
                    {d.charAt(0)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="row mb-10">
        <div className="col-md-12">
          <div className="form-group mb-5px">
            <label>Ends</label>
          </div>
        </div>
        <div className="col-md-2">
          <div className="custom-control custom-radio ">
            <input
              onChange={(e) => {
                setrecurringEvent(e.target.value);
              }}
              checked={recurringEvent == "On" ? true : false}
              type="radio"
              id="radio-btn"
              value="On"
              name="recurrngEvent"
              className="custom-control-input"
              disabled={id && recurringEvent == "After" ? true : false}
            />
            <label className="custom-control-label" for="radio-btn">
              On
            </label>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className="input-group sm-date-picker"
            style={{ width: 115, marginTop: -5 }}
          >
            <DatePicker
              multiple={false}
              value={inputs?.customSessionForm.sessionEndDateTime}
              onChange={setDate}
              name="sessionEndDateTime"
              format="MM-DD-YYYY"
              disabled={
                id && recurringEvent == "On"
                  ? false
                  : recurringEvent == "On"
                  ? false
                  : true
              }
              minDate={
                id
                  ? moment().format("YYYY-MM-DD") + `T00:00:00.000Z`
                  : inputs?.startDateTime
              }
              editable={false}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <div
            className="custom-control custom-radio "
            style={{ marginTop: 6 }}
          >
            <input
              onChange={(e) => {
                setrecurringEvent(e.target.value);
              }}
              checked={recurringEvent == "After" ? true : false}
              type="radio"
              id="radio-btn1"
              value="After"
              name="recurrngEvent"
              className="custom-control-input"
              disabled={id && recurringEvent == "On" ? true : false}
            />
            <label className={(id && recurringEvent == "On") ? "custom-control-label textDisable" : 'custom-control-label'} for="radio-btn1">
              After
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div
            className="input-group inline-flex"
            style={{ width: 50, marginTop: -5 }}
          >
            <input
              type="number"
              value={inputs?.customSessionForm.sessionCount}
              onChange={handleChange}
              name="sessionCount"
              min={1}
              className={id ? "recurring-sm-input recurring-count form-control disableInput" : "recurring-sm-input recurring-count form-control"}
              disabled={
                id && recurringEvent == "After"
                  ? false
                  : recurringEvent == "After"
                  ? false
                  : true
              }
            />
          </div>
          <div className="input-group inline-flex" style={{ width: 110 }}>
            <input
              type="text"
              value="Occurrences"
              className={ id ? "recurring-sm-input recurring-count-occur form-control disableInput" : "recurring-sm-input recurring-count-occur form-control"}
              readOnly
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EventPage;
