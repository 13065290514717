import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "./CommonComponents";
import SignDate from "./SignDate";

function ConsentToTreat(props) {
  const sigCanvas = useRef({});
  const sigWitnessCanvas = useRef({});
  const profile = useSelector((state) => state.adminUser.profile);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const formRef = React.useRef(null);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [witnessModal, setWitnessModal] = useState(false);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [witnessImageURL, setWitnessImageURL] = useState(null);
  const { inputs, setInputs, signatures, setSignatures } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [familyInfo, setFamilyInfo] = useState({});
  console.log("consent to", props?.inputs?.familyMemberId);
  const [mediareleaseform, setmediareleaseform] = useState({
    familySign:
      props?.data?.familySignDate && props?.data?.family
        ? props?.data?.family?.userProfile?.signatureUrl
        : false,
    patientName: props?.data?.familyMemberName
      ? props?.data?.familyMemberName
      : props?.data?.familyMember
        ? props.data.familyMember.firstName +
        " " +
        props.data.familyMember.lastName
        : " ",
    dateofbirth: props?.data?.familyMemberDob
      ? props?.data?.familyMemberDob
      : props?.data?.familyMember
        ? props.data.familyMember.dob
        : " ",
    familySignDate: props?.data?.familySignDate
      ? props.data.familySignDate
      : false,
  });
  const [isSubmit, setIsSubmit] = useState(
    props?.from != "family" || props?.data?.reply
      ? true
      : props?.data?.replyNeeded == false
        ? !props?.data.replyNeeded
        : false
  );

  useEffect(() => {
    setFamilyInfo({
      id: props?.data?.id ? props.data.id : null,
      familyMemberName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      familyMemberDob: props?.data?.familyMemberDob
        ? props?.data?.familyMemberDob
        : props?.data?.familyMember
          ? props.data.familyMember.dob
          : " ",
      familySign:
        props?.data?.familySignDate &&
          props?.data?.familySignDate &&
          props?.data?.family
          ? props?.data?.family?.userProfile?.signatureUrl
          : false,
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
      consent1: props?.data?.consent2 ? props.data.consent2 : false,
      consent2: props?.data?.consent2 ? props.data.consent2 : false,
    });
  }, [props?.data]);

  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const [signatureErr, setSignatureErr] = useState("");

  const clearWitness = () => sigWitnessCanvas.current.clear();
  const saveWitness = () => {
    setWitnessImageURL(
      sigWitnessCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setWitnessModal(false);
    setFamilyInfo({
      ...familyInfo,
      witnessSignDate: moment(new Date()).format("DD-MM-YYYY h:s:a"),
    });
  };

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setmediareleaseform({
      ...mediareleaseform,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setmediareleaseform({
              ...mediareleaseform,
              familySign: res.data.signatureKey,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const onSubmitFamilyForm = (isSubmit = false) => {
    console.log("form user",props?.from);
    
    if (props?.data?.replyNeeded) {
      setIsLoading(true);
      if (familyInfo?.familySignDate) {
        let formData = new FormData();
        // formData.append("signature", familyImageURL);
        formData.append(
          "formUpdationForm",
          new Blob(
            [
              JSON.stringify({
                ...familyInfo,
                jsonFormattedForm: JSON.stringify({ mediareleaseform }),
              }),
            ],
            {
              type: "application/json",
            }
          )
        );
        adminService
          .onboardPutUser(
            `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
            formData
          )
          .then((response) => {
            setIsLoading(false);
            toast.success("Form Submitted successfully")
            props?.from == "family" && props.handleBack();
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        setSignatureErr("Signature is required!");
      }
    } else if (props?.from === "admin") {
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("signature", familyImageURL);
      console.log("formData 220", formData);
      formData.append(
        "formCreationForm",
        new Blob([JSON.stringify(props?.inputs)], {
          type: "application/json",
        })
      );
      adminService
        .onboardUser(
          `submitted-forms`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Form Submitted successfully");
          props.setIsFocused(!props.isFocused);
          props?.from == "admin" && props.handleBack("reload");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };

  const updateInfo = (e) => {
    const { name, value } = e.target;
    setmediareleaseform({ ...mediareleaseform, [name]: value });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "ConsentToTreatForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const relValue = props.data.reply
    ? JSON.parse(props?.data?.jsonFormattedForm)
    : "";
  // console.log("props.data", props.data.familyMember);
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >  <div
        style={{
          position: 'absolute', top: 0, right: "100px"

        }}>
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.familySignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}</div>
        <div className="card-lg">
          <FormHeader logoUrl={logoUrl} title="Consent To Treat" />
          <hr />
          <br />
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content">
              <div className="w-100per">
                <div style={{ width: "60%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Child's Name : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.mediareleaseform?.patientName
                    ) : (
                      <input
                        {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                        type="text"
                        placeholder="Client's name"
                        name="patientName"
                        className={`form-control form-input form-input-w-60per ${errors.patientName && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberName}
                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "40%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>DOB : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.mediareleaseform?.dateofbirth.trim() &&
                      moment(relValue?.mediareleaseform?.dateofbirth).format(
                        "MM/DD/YYYY"
                      )
                    ) : (
                      <input
                        {...register('dateofbirth', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberDob === '' ? true : false })}
                        type="date"
                        name="dateofbirth"
                        placeholder="DOB"
                        className={`form-control form-input form-input-w-60per ${errors.dateofbirth && "is-invalid"
                          }`}
                        defaultValue={familyInfo.familyMemberDob}
                        disabled={familyInfo?.familyMemberDob === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="w-100per">
                <p className="w-100per text-dcor-underline">
                  <b>Statement of Authority to Consent:</b>
                </p>
                <p className="w-100per">
                  I certify that I have the legal authority to consent to
                  treatment, medication, release of information, and all legal
                  issues involving the above-named client. Upon request, I will
                  provide {name} Network with proper legal documentation to
                  support this claim. I further agree that if my status as legal
                  guardian should change, I will immediately notify {name}{" "}
                  Network of the name, address, and telephone number of the
                  person who has assumed guardianship of the above-named client.
                </p>

                <p className="w-100per text-dcor-underline">
                  <b>Consent to Participate in Services:</b>
                </p>
                <p className="w-100per">
                  I consent for the above-named client to participate in
                  assessment and treatment through {name} Network online and at
                  other physical locations as deemed necessary by treating
                  provider.
                </p>

                <p className="w-100per text-dcor-underline">
                  <b>Confidentiality:</b>
                </p>
                <p className="w-100per">
                  I understand that all information related to the above-named
                  client’s assessment and treatment is treated with strict
                  confidentiality. No information related to the client, either
                  verbal or written, will be released to other agencies or
                  individuals without the express written consent of the
                  client’s legal guardian. By law, the rules of confidentiality
                  do not hold under the following conditions:
                </p>
                <ol>
                  <li>
                    If abuse or neglect of a minor, disabled or elderly person
                    is reported or suspected, the professional involved is
                    required to report it to the Department of Children and
                    Families for investigation.
                  </li>
                  <li>
                    If during the course of services, the professional involved
                    receives information that someone’s life is in danger that
                    professional has a duty to warn the potential victim.
                  </li>
                  <li>
                    If our records or staff testimony are subpoenaed by court
                    order, we are required to produce records or appear in court
                    to answer questions regarding the client.
                  </li>
                </ol>
                <p className="w-100per text-dcor-underline">
                  <b>Release of Information:</b>
                </p>
                <ul>
                  <li>
                    I understand that the individuals responsible for care at{" "}
                    {name} Network will need to have access to confidential
                    information for the purpose of assessment and treatment
                    coordination. I further understand that confidential
                    information will need to be released to the funding source
                    for treatment (e.g. Private Insurance, Medicaid, Children's
                    Medical Services, Early Steps, Healthy Start) in order to
                    process claims and obtain reimbursement. I authorize the
                    confidential information contained in the following
                    documents to be released to the necessary funding agency,{" "}
                    {name} Network and its affiliated healthcare institutions
                    and programs and if required, the Department of Children and
                    Family Services.{" "}
                  </li>
                  <li>Client Information Sheet </li>
                  <li>Treatment Plan </li>
                  <li>Psychological Evaluation </li>
                  <li>Behavioral Program</li>
                  <li>Treatment Plan Reviews</li>
                  <li>Progress Notes</li>
                  <li>Discharge Summary</li>
                </ul>
                <p className="w-100per">
                  The information on this page has been explained to me. I
                  understand that I may revoke consent for the above at any
                  time. However, I cannot revoke consent for action that has
                  already been taken. A copy of this release will be valid as
                  the original.
                </p>
              </div>

              <SignDate
                date={relValue?.mediareleaseform?.familySignDate || mediareleaseform?.familySignDate}
                familyImageURL={familyImageURL}
                isSubmit={isSubmit}
                setOpen={setOpen}
                signTitle="Guardian"
                guardian="Legal Guardian"
                familyInfo={familyInfo}
              />

              {/* <SignDate
                date={familyInfo?.witnessSignDate}
                familyImageURL={witnessImageURL}
                isSubmit={isSubmit}
                setOpen={setWitnessModal}
                signTitle="Witness"
                guardian="Legal Guardian"
              /> */}

              {/* <DigitalSignPopup
                modalState={witnessModal}
                setModalState={setWitnessModal}
                save={saveWitness}
                clear={clearWitness}
                sigCanvas={sigWitnessCanvas}
              /> */}

              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />
              <br />
              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {errors.consent1 ||
                    (errors.consent2 && (
                      <p className="error-text">
                        <small>All fields are require</small>
                      </p>
                    ))}
                  {signatureErr && (
                    <p className="text-red">
                      <small>{signatureErr}</small>
                    </p>
                  )}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {props?.data.replyNeeded && (
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={familyInfo.familySign ? false : true}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {props?.from === 'admin' && props?.isFormOpen &&
                    <>
                      <button
                        className="btn btn-theme-green"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btn-success float-right1 mt-10 mb-10"
                      >
                        {isLoading ? "Loading...." : "Submit"}
                      </button>
                    </>

                  }
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ConsentToTreat;
