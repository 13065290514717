import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { adminService } from "../../services/adminService";
import {
  getListOfDays,
  getUTCTimeToLocalByDate,
  getMeridiumByDate,
  showError,
  showSuccess,
  showWarning,
} from "../../utils/commonFunctions";
import { daysJson, messages, monthJson } from "../../config/Config";
import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { useForm } from "react-hook-form";
import TimeInput from "../../components/TimeInput";
import { useDispatch } from "react-redux";
import appActions from "../../redux/actions/appAction";

function RequestSession(props) {
  const { register, formState: { errors }, handleSubmit } = useForm();
  console.log(props);
  const [isLoading, setIsLoading] = useState(false);
  let params = useParams();
  let [weekcount, setWeekCount] = useState(0);
  const dispatch = useDispatch();
  let [weeks, setWeeks] = useState(getListOfDays(weekcount));
  let today_date = new Date();
  const [inputs, setInputs] = useState({
    startDateTime: "", //2021-01-19T10:54:25.380Z
    endDateTime: "", //2021-01-19T10:54:25.380Z
    familyMemberId: props.info.details.familyMemberId,
    therapistId: props.info.details.id,
    note: "",
  });
  const [dateInputs, setDateInputs] = useState({
    startTime: params.id ? moment(today_date).format("hh:mm") : "07:00", //
    endTime: params.id ? moment(today_date).format("hh:mm") : "08:00", // moment(today_date).format('hh:mm')
    session_date: today_date.toISOString().slice(0, 10),
    startMeridium: "am",
    endMeridium: "am",
  });
  useEffect(() => {
    dispatch(appActions.setAppLoading(isLoading));
    return () => {
      dispatch(appActions.setAppLoading(false));
    };
  }, [dispatch, isLoading]);

  const getNextWeeK = () => {
    setWeeks(getListOfDays(weekcount + 1));
    setWeekCount(weekcount + 1);
  };

  const getPrevWeeK = () => {
    setWeeks(getListOfDays(weekcount - 1));
    setWeekCount(weekcount - 1);
  };

  const handleChangeTime = (e, name) => {
    setDateInputs((dateInputs) => ({
      ...dateInputs,
      [name]: moment(e._d).format("HH:mm"),
    }));
  };

  const handleChangeMeridium = (name, value) => {
    console.log(name);
    setDateInputs((dateInputs) => ({ ...dateInputs, [name]: value }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "session_date")
      setDateInputs((dateInputs) => ({ ...dateInputs, [name]: value }));
    else setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const renderInput = (props, openCalendar) => {
    return (
      <div className="input-group ml-3">
        <img src="../resources/assets/clock.svg" onClick={openCalendar} />
        <input {...props} />
      </div>
    );
  };

  const submit = () => {
    let local_date_time = moment().format("YYYY-MM-DD hh:mm:ss a");
    let startUTCTime = moment(
      dateInputs.startTime + " " + dateInputs.startMeridium,
      ["h:mm A"]
    ).format("hh:mm:ss a");
    let startDateTimeUTC = moment(dateInputs.session_date + " " + startUTCTime);
    let timeInMin = startDateTimeUTC.diff(local_date_time, "minutes");
    let date_diff_minutes = moment(inputs.endDateTime).diff(
      moment(inputs.startDateTime),
      "minutes"
    );
    if (timeInMin <= 0) {
      showWarning("start_time_validation");
      return;
    }
    if (date_diff_minutes > 0 && date_diff_minutes < 30) {
      showWarning("time_diff_validation");
      return;
    }
    if (inputs.startDateTime >= inputs.endDateTime) {
      showWarning("start_end_time_validation");
      return;
    }
    if (inputs.note === "") {
      toast.warning("Please add a note!");
    } else {
      setIsLoading(true);
      adminService
        .POST(`family/sessions/session-create-request`, JSON.stringify(inputs))
        .then(
          (res) => {
            if (res.status === "Success") {
              toast.success(res.data);
              setIsLoading(false);
              props.closeModal();
            } else {
              toast.error(res.errorMessages[0]);
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    setDateInputs(dateInputs);
    let startUTCTime = moment(
      dateInputs.startTime + " " + dateInputs.startMeridium,
      ["h:mm A"]
    ).format("HH:mm:ss");
    let endUTCTime = moment(dateInputs.endTime + " " + dateInputs.endMeridium, [
      "h:mm A",
    ]).format("HH:mm:ss");
    let startDateTimeUTC = moment(dateInputs.session_date + " " + startUTCTime);
    let endDateTimeUTC = moment(dateInputs.session_date + " " + endUTCTime);
    let startDateUTC = moment.utc(startDateTimeUTC).toISOString();
    let endDateUTC = moment.utc(endDateTimeUTC).toISOString();
    setInputs((inputs) => ({ ...inputs, ["startDateTime"]: startDateUTC }));
    setInputs((inputs) => ({ ...inputs, ["endDateTime"]: endDateUTC }));
  }, [dateInputs]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.closeModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <form>
        <div className="col-md-2 custom-modal">
          {/* Add Modal */}
          <div
            className="modal fade add-new-prop-modal show "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content ">
                <div className="header-reqsession h-auto">
                  <div className="addservice mx-auto w-100 text-center py-3">
                    <h5 className="modal-title name" id="exampleModalLongTitle">
                      Request Session
                    </h5>
                  </div>
                </div>
                <div className="body-reqsession">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <div className="working-days">
                          {!params.id && weekcount > 0 && (
                            <div className="col9">
                              <button
                                onClick={() => getPrevWeeK()}
                                type="button"
                                className="btn Rectangle-691"
                              >
                                <img src="../resources/assets/arrow-left.svg" />
                              </button>
                            </div>
                          )}
                          {weeks.length > 0 &&
                            weeks.map((day, index) => (
                              <label
                                className="date-radio form-control"
                                for="checkbox"
                              >
                                <input
                                  type="radio"
                                  id="radio"
                                  name="session_date"
                                  onChange={handleChange}
                                  value={day}
                                  disabled={
                                    moment().format("YYYY-MM-DD") >
                                    moment(day).format("YYYY-MM-DD")
                                      ? true
                                      : false
                                  }
                                  checked={
                                    new Date(day).getDate() ==
                                    new Date(dateInputs.session_date).getDate()
                                      ? true
                                      : false
                                  }
                                />
                                <div className="days">
                                  {daysJson[parseInt(index)].short}
                                  <br />
                                  {}
                                  {moment(day).format("DD")}
                                </div>
                              </label>
                            ))}

                          {!params.id && (
                            <div className="col9">
                              <button
                                onClick={() => getNextWeeK()}
                                type="button"
                                className="btn Rectangle-691"
                              >
                                <img src="../resources/assets/arrow-right.svg" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-8 mt-4 ml-3 add-session">
                        <div className="form-group d-flex align-items-center">
                          <label></label>
                          <img
                            className=""
                            src="../resources/assets/Icon ionic-ios-calendar.svg"
                          />
                          <h6 className="m-0 ml-4">You have selected -</h6>
                          <span className="selected-date ml-3">
                            {dateInputs.session_date.split("-")[2]}{" "}
                            {
                              monthJson[
                                parseInt(
                                  dateInputs.session_date.split("-")[1]
                                ) - 1
                              ].name
                            }{" "}
                            {dateInputs.session_date.split("-")[0]}{" "}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ml-3 mb-0">
                          <label>Working Time</label> (
                          <span className="required-span">*</span>)
                          <div className="row">
                            <div className="col-12">
                              <h6>Start time</h6>
                              <div className="working-time d-flex">
                                <TimeInput
                                  onChange={(e) =>
                                    handleChangeTime(e, "startTime")
                                  }
                                  initialValue={dateInputs.startTime}
                                  value={dateInputs.startTime}
                                />
                                {/* <Datetime
                                                                    className="form-control-time"
                                                                    renderInput={renderInput}
                                                                    initialViewMode="time"
                                                                    updateOnView="time"
                                                                    timeFormat="hh:mm"
                                                                    timeConstraints={{ hours: { min: 1, max: 12, step: 1 } }}
                                                                    // initialValue={dateInputs.startTime}
                                                                    value={dateInputs.startTime}
                                                                    dateFormat={false}
                                                                    onChange={(e) => handleChangeTime(e, "startTime")}
                                                                /> */}

                                <div
                                  className="btn-group btn-group-toggle set-time-section ml-3"
                                  data-toggle="buttons"
                                >
                                  <label
                                    className={`btn ${
                                      dateInputs.startMeridium == "am" &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      handleChangeMeridium(
                                        "startMeridium",
                                        "am"
                                      )
                                    }
                                  >
                                    <input
                                      type="radio"
                                      value="am"
                                      name="startMeridium"
                                      autocomplete="off"
                                    />{" "}
                                    AM
                                  </label>

                                  <label
                                    className={`btn ${
                                      dateInputs.startMeridium == "pm" &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      handleChangeMeridium(
                                        "startMeridium",
                                        "pm"
                                      )
                                    }
                                  >
                                    <input
                                      type="radio"
                                      value="pm"
                                      name="startMeridium"
                                      autocomplete="off"
                                    />{" "}
                                    PM
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mt-2">
                              <h6>End time</h6>
                              <div className="working-time d-flex">
                                <TimeInput
                                  onChange={(e) =>
                                    handleChangeTime(e, "endTime")
                                  }
                                  initialValue={dateInputs.endTime}
                                  value={dateInputs.endTime}
                                />

                                <div
                                  className="btn-group btn-group-toggle set-time-section ml-3"
                                  data-toggle="buttons"
                                >
                                  <label
                                    className={`btn ${
                                      dateInputs.endMeridium == "am" && "active"
                                    }`}
                                    onClick={() =>
                                      handleChangeMeridium("endMeridium", "am")
                                    }
                                  >
                                    <input
                                      type="radio"
                                      value="am"
                                      name="endMeridium"
                                      autocomplete="off"
                                    />{" "}
                                    AM
                                  </label>

                                  <label
                                    className={`btn ${
                                      dateInputs.endMeridium == "pm" && "active"
                                    }`}
                                    onClick={() =>
                                      handleChangeMeridium("endMeridium", "pm")
                                    }
                                  >
                                    <input
                                      type="radio"
                                      value="pm"
                                      name="endMeridium"
                                      autocomplete="off"
                                    />{" "}
                                    PM
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ml-3 mb-0">
                          <label>Note</label> (
                          <span className="required-span">*</span>)
                          <div className="row">
                            <div className="col-md-10">
                              <textarea
                                className="form-control"
                                //onChange={handleChange}
                                {...register('note',{onChange:(e)=>handleChange(e),required: true })}
                                maxLength="100"
                                type="text"
                                name="note"
                              ></textarea>
                              {errors.note && (<p className="invalid-feedback-custom">This Field is Required.</p>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center mb-4">
                    <hr />
                    <button
                      type="button"
                      onClick={submit}
                      className="btn btn-primary custom-btn-green submitreqbutton mr-4"
                    >
                      SUBMIT REQUEST
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary cancelsessionbutton ml-4"
                      onClick={() => props.closeModal(false)}
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default RequestSession;
