import { useState, useEffect } from "react";
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  MicrophoneAudioTrackInitConfig,
  CameraVideoTrackInitConfig,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
  ILocalVideoTrack,
  ILocalAudioTrack,
  ScreenVideoTrackInitConfig,
} from "agora-rtc-sdk-ng";
import { useDispatch, useSelector } from "react-redux";
import agoraAction from "../../../redux/actions/agoraAction";
import { emitEvent } from "../../../hooks/eventHook";
import { toast } from "react-toastify";

export default function useAgora(
  client: IAgoraRTCClient | undefined,
  screenClient: IAgoraRTCClient | undefined,
  setIsScreen: any,
  setIsOwnScreen: any,
  setIsPin: any
): {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  joinState: boolean;
  leave: Function;
  join: Function;
  remoteUsers: IAgoraRTCRemoteUser[];
  handleAudio: Function;
  handleVideo: Function;
  handleScreen: Function;
  leaveScreen: Function;
  screenTrack: ILocalVideoTrack | undefined;
} {
  const [localVideoTrack, setLocalVideoTrack] = useState<
    ILocalVideoTrack | undefined
  >(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<
    ILocalAudioTrack | undefined
  >(undefined);
  const [screenTrack, setScreenTrack] = useState<ILocalVideoTrack | undefined>(
    undefined
  );
  const dispatch = useDispatch();

  const [joinState, setJoinState] = useState(false);

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  // let x = AgoraRTC.createMicrophoneAudioTrack({
  //   AEC: true, ANS: true
  // });

  async function createLocalTracks(
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    //console.log("microphoneTrack",audioConfig);
    const cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig);

    console.log("microphoneTrack", microphoneTrack);

    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);

    return [microphoneTrack, cameraTrack];
  }
  console.log("screenClient", screenClient?._uid);
  console.log(`level...171`, client);

  async function createLocalScreenTracks(
    appid: string,
    channel: string,
    token?: string,
    uid?: string | number | null
  ) {
    if (!screenClient) return;
    //await screenClient.join(appid, channel, token || null);
    try {
      const [promise1, screenTrack] = await Promise.all([
        new Promise(resolve => {
          console.log("start a Promise, it will setTimeout for 5.000 seconds")
          setTimeout(() => resolve(), 5000);
        }
        ),
        AgoraRTC.createScreenVideoTrack({ encoderConfig: "1080p_2", optimizationMode: "detail" }, "disable")
      ])
      await screenClient.join(appid, channel, token || null);
      // const screenTrack = await AgoraRTC.createScreenVideoTrack({
      //   // Set the encoder configurations. For details, see the API description.
      //   encoderConfig: "1080p_1",
      //   // Set the video transmission optimization mode as prioritizing video quality.
      //   optimizationMode: "detail",
      // });
      setScreenTrack(screenTrack);
      screenClient && setIsScreen(screenClient?._uid);
      screenClient && setIsOwnScreen(screenClient?._uid);
      await screenClient.publish(screenTrack);
      emitEvent("agoraScreenShare", {
        uid: screenClient?._uid,
        isEnabled: true,
      });
      setIsPin(false);
      dispatch(agoraAction.screenShare(screenClient?._uid, true));

      // (window as any).screenClient = screenClient;
    } catch (error) {
      leaveScreen();
    }
    return screenClient;
  }

  async function join(
    appid: string,
    channel: string,
    token?: string,
    uid?: string | number | null,
    setIsLocalJoined: any
  ) {
    if (!client) return;
    try {
      const [microphoneTrack, cameraTrack] = await createLocalTracks();
      await client.join(appid, channel, token || null, uid);
      await client.publish([microphoneTrack, cameraTrack]);

      (window as any).client = client;
      (window as any).videoTrack = cameraTrack;
      setJoinState(true);
      setIsLocalJoined(true);

    } catch (e) {
      setIsLocalJoined(false);
      toast.error(e?.message);
    }
  }

  async function handleAudio(audio: any) {
    // await AgoraRTC.createMicrophoneAudioTrack({
    //   AEC: true
    // });
    audio === undefined
      ? localAudioTrack.setMuted(true)
      : localAudioTrack.setMuted(false);
  }

  async function handleVideo(audio: any) {
    audio === undefined
      ? localVideoTrack.setMuted(true)
      : localVideoTrack.setMuted(false);
  }

  async function handleScreen(
    appid: string,
    channel: string,
    token?: string,
    uid?: string | number | null
  ) {
    createLocalScreenTracks(appid, channel, token, uid);
  }

  async function leaveScreen() {
    emitEvent("agoraScreenShare", {
      isEnabled: false,
    });
    setIsScreen(false);
    setIsOwnScreen(false);
    if (screenTrack) {
      screenTrack?.stop();
      screenTrack?.close();
      setRemoteUsers(
        client.remoteUsers.filter((u) => u.uid != screenClient?._uid)
      );
    }

    await screenClient?.leave();
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    await client?.leave();
    window.location.href = "/therapy-sessions";
  }

  // client && client.enableAudioVolumeIndicator();
  // client &&
  //   client.on("volume-indicator", (volumes) => {
  //     console.log(`level...111`);
  //     volumes.forEach((volume, index) => {
  //       console.log(`level...${index} UID ${volume.uid} Level ${volume.level}`);
  //     });
  //   });

  screenTrack &&
    screenTrack.on("track-ended", () => {
      leaveScreen();
    });

  useEffect(() => {
    AgoraRTC.getPlaybackDevices(true);
    if (!client) return;

    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      console.log("user published", client.remoteUsers);
      await client.subscribe(user, mediaType);
      //await client.publish(localAudioTrack);
      dispatch(
        agoraAction.userJoined(
          client.remoteUsers.map((u) => ({ agoraId: u.uid, islocal: false }))
        )
      );
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
    };

    client && client?.enableAudioVolumeIndicator();
    client &&
      client.on("volume-indicator", (volumes) => {
        const arr = [];
        console.log("volumes?.attr", volumes);
        volumes.forEach((volume, index) => {
          arr.push(volume);
          console.log(
            `level...${index} UID ${volume.uid} Level ${volume.level}`
          );
        });
        dispatch(agoraAction.setVolumeIndicator(arr));
      });

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    leave,
    join,
    remoteUsers,
    handleAudio,
    handleVideo,
    handleScreen,
    leaveScreen,
    screenTrack,
  };
}
