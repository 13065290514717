import React, { useState, useEffect } from "react";
import AppModal from "../../components/AppModal";
import EventPage from "./EventPage";
import moment from "moment";
import { IoIosEye, IoMdCreate } from "react-icons/io";

function SelectEvent(props) {
  const {
    register,
    errors,
    inputs,
    currentRole,
    setInputs,
    recurringEvent,
    setrecurringEvent,
    dates,
    setdates,
    recurringModal,
    setrecurringModal,
    id,
  } = props;
  const [openEventModal, setopenEventModal] = useState(false);

  // useEffect(() => {
  //   setrecurringModal(false)
  //   setopenEventModal(inputs?.customSessionForm?.recurring);
  // }, [inputs?.customSessionForm?.recurring]);

  // useEffect(() => {
  //   inputs?.customSessionForm?.weekDateTimeList &&
  //     inputs?.customSessionForm?.weekDateTimeList.length > 0 &&
  //     setdates(inputs.customSessionForm.weekDateTimeList);
  // }, [inputs?.customSessionForm?.weekDateTimeList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      customSessionForm: {
        ...inputs?.customSessionForm,
        [name]: value,
      },
    }));
  };

  const cancelEvent = () => {
    // setInputs((inputs) => ({
    //   ...inputs,
    //   customSessionForm: {
    //     recurring: false,
    //     recurringFrequency: 1,
    //     sessionCount: 1,
    //     sessionEndDateTime: new Date(),
    //     sessionFrequency: "Daily",
    //     weekDateTimeList: [],
    //     weekDayList: [],
    //   },
    // }));
    setrecurringModal(false);
  };

  const handleRecurringData = () => {
    const data = { ...inputs.customSessionForm };
    data.recurring = true;
    if (recurringEvent == "On") {
      data.sessionCount = 0;
      // data.sessionEndDateTime =
      //   moment(data.sessionEndDateTime).format("YYYY-MM-DD") + `T00:00:00.000Z`;
    } else {
      data.sessionEndDateTime = null;
    }
    if (data.sessionFrequency === "Weekly") {
      data.weekDateTimeList = [];
    }
    if (data.sessionFrequency === "Monthly") {
      const montDates = [];
      // dates.map((dateVal) => {
      //   dateVal =
      //     moment(dateVal.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      //   montDates.push(dateVal);
      // });
      // data.weekDateTimeList =
      //   moment(data.weekDateTimeList).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      // data.weekDateTimeList = data.weekDateTimeList;
      data.weekDayList = [];
    }
    setInputs((inputs) => ({
      ...inputs,
      customSessionForm: { ...data },
    }));

    setrecurringModal(false);
    setopenEventModal(false);
  };

  return (
    <>
      {(currentRole === "ROLE_RCE" ||
        currentRole === "ROLE_REGIONAL_EARLY_STEPS" ||
        currentRole === "ROLE_THERAPIST") && (
        <div className="col-md-12 mb-25 form-group">
          <input
            type="checkbox"
            name="recurring"
            checked={inputs?.customSessionForm?.recurring}
            onChange={(e) => {
              setInputs((inputs) => ({
                ...inputs,
                customSessionForm: {
                  recurring: false,
                  recurringFrequency: 1,
                  sessionCount: 1,
                  sessionEndDateTime: inputs?.startDateTime,
                  sessionFrequency: "Daily",
                  weekDateTimeList: inputs?.startDateTime,
                  weekDayList: [],
                },
              }));
              setrecurringModal(e.target.checked);
              // setInputs((inputs) => ({
              //   ...inputs,
              //   customSessionForm: {
              //     ...inputs?.customSessionForm,
              //     recurring: e.target.checked,
              //   },
              // }));
            }}
            disabled={props?.id ? true : false}
          />
          <label>Recurring Session</label>{" "}
          {inputs?.customSessionForm?.recurring && (
            <IoMdCreate
              className="cursor-pointer"
              onClick={() => setrecurringModal(true)}
            />
          )}
        </div>
      )}

      {recurringModal && (
        <AppModal
          title="Recurring Session"
          isOpen={recurringModal}
          onClose={() => {
            cancelEvent();
          }}
          //   modalSize="lg"
        >
          <EventPage
            inputs={inputs}
            setInputs={setInputs}
            handleChange={handleChange}
            recurringEvent={recurringEvent}
            setrecurringEvent={setrecurringEvent}
            dates={dates}
            setdates={setdates}
            id={id}
          />
          <div className="row mt-30">
            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn btn-primary stepper-button-next"
                  style={{ width: "50%" }}
                  type="button"
                  disabled={
                    inputs.customSessionForm.sessionFrequency === "Monthly" &&
                    !inputs?.customSessionForm.weekDateTimeList
                      ? true
                      : // : inputs.customSessionForm.sessionFrequency ===
                        //     "Weekly" &&
                        //   inputs.customSessionForm.weekDayList.length == 0
                        // ? true
                        false
                  }
                  onClick={() => handleRecurringData()}
                >
                  Submit
                </button>

                <button
                  type="button"
                  onClick={() => {
                    cancelEvent();
                  }}
                  className="btn  btn-primary stepper-button-back"
                  style={{ width: "50%", margin: "auto" }}
                  // disabled={id ? true : false}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </AppModal>
      )}
    </>
  );
}

export default SelectEvent;
