import React from "react";

function SelectFamilyChild(props) {
  const { params, register, errors, handleChange, inputs, family } = props;
  return (
    <>
      <div className="col-md-12">
        <div className="form-group">
          <label>Caretaker & Client</label> (
          <span className="required-span">*</span>)
          <div className="row ml-2">
            <img
              src="../resources/assets/family-icon-blue.svg"
              className="img-fluid"
              alt=""
            />
            <div className="col-md-10">
              <div className="input-group">
                <select
                  {...register('familyMemberId',{ onChange:(e)=>handleChange(e),required: true })}
                  className={`custom-select ${
                    errors.familyMemberId && "is-invalid"
                  }`}
                  //onChange={handleChange}
                  value={inputs.familyMemberId}
                  defaultValue={inputs.familyMemberId}
                  name="familyMemberId"
                  disabled={params.id ? "disabled" : ""}
                >
                  <option value="">Select Caretaker & Client</option>
                  {family &&
                    family.map((info, index) => (
                      <>
                        {info.familyMembers &&
                          info.familyMembers.map((minfo, index) => (
                            <>
                              {minfo?.familyMemberTherapies && (
                                <option value={minfo.id}>
                                  {info.userProfile.firstName +
                                    " " +
                                    info.userProfile.lastName}
                                  /{minfo.firstName + " " + minfo.lastName}
                                </option>
                              )}
                            </>
                          ))}
                      </>
                    ))}
                </select>
                <div className="input-group-prepend">
                  <img
                    className="m-auto"
                    src="../resources/assets/Polygon 1.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectFamilyChild;
