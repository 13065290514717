import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { adminService } from "../../services/adminService";
import MyResourceTab from "../../components/resource/MyResourceTab";
import FamilyForms from "../../components/forms/FamilyForms";
import Tabs from "../../components/Tabs";

function MyResources() {
  const [resources, setResources] = useState([]);
  const [resourceMap, setResourceMap] = useState({});
  const [resourceList, setResourceList] = useState([]);
  const [resourceSortBy, setResourceSortBy] = useState("dateDesc");

  useEffect(() => {
    const sort =
      resourceSortBy == "dateAsc"
        ? `sort=createdDate,ASC`
        : resourceSortBy == "dateDesc"
        ? `sort=createdDate,DESC`
        : resourceSortBy == "titleAsc"
        ? `sort=fileName,ASC`
        : resourceSortBy == "titleDesc"
        ? `sort=fileName,DESC`
        : `sort=createdDate,DESC`;
    adminService.getAll(`resources`).then(
      (res) => {
        if (res?.data) {
          setResources(res.data.content);
          setResourceMap(res.data.resourceMap || {});
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, [resourceSortBy]);
  useEffect(() => {
    console.log({ resourceList });
  }, [resourceList]);
  useEffect(() => {
    if (resourceMap) {
      let frame1 = Object.keys(resourceMap)
        .map((item) => {
          return resourceMap[item].map((res) => {
            const date = moment(res.createdDate).format("MMMM DD, YYYY");
            return { ...res, type: item, date: date };
          });
        })
        .flat();
      var grouped = _.mapValues(_.groupBy(frame1, "date"), (item) =>
        _.groupBy(item, "type")
      );
      setResourceList(grouped);
    }
  }, [resourceMap]);

  const tabsData = [
    {
      title: "Forms",
      id: "forms",
      content: (
        <div className="row">
          <div className="col-md-12 therapist p-0">
            <FamilyForms />
          </div>
        </div>
      ),
    },
    {
      title: "Session Resources",
      id: "resources",
      content: (
        <div className="row">
          <div className="col-md-12 therapist p-0">
            <MyResourceTab
              resources={resources}
              resourceList={resourceList}
              resourceSortBy={resourceSortBy}
              setResourceSortBy={setResourceSortBy}
            />
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            <div className="content-header">
              <div className="text-left header-title">My Resources</div>
            </div>
            <div className="row no-gutters mt-5">
              <Tabs data={tabsData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyResources;
