import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import allActions from "../redux/actions";
import { adminActions } from "../actions";
import { adminService } from "../services/adminService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import firebase from "firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseCofig, apiUrl } from "../config/Config";
import AdminNormalAccount from "./AdminNormalAccount";

function Login() {
  let d = new Date();
  const Year = d.getFullYear();
  const [inactive, setInactive] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [stripeAccountCompleted, setstripeAccountCompleted] = useState();
  let params = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const sessionId = params.id;
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const valuex = localStorage.getItem("user");

  const isUserLoggedIn =
    typeof valuex === "string"
      ? JSON.parse(valuex).accessToken
      : valuex?.accessToken || null;
  console.log("login params", params);
  const [tenantinfo, setTenantInfo] = useState({
    largeLogoUrl: "",
    primaryColor: "",
    secondaryColor: "",
    smallLogoUrl: "",
    tenantId: "",
    tenantName: "",
    tenantUrl: "",
  });
  const { email, password } = inputs;

  const onSubmit = (props) => {
    if (email && password) {
      try {
        adminActions.login(email, password).then(
          (res) => {
            console.log("userdetail login", res.data);
            if (res?.status === "Success") {
              getNormalBillingCard(res?.data, res?.data?.accessToken, res?.data?.sessionId);
              dispatch(allActions.adminAction.logInUserDetail(res.data));
              //getBilling(res?.data,res?.data?.accessToken, res?.data?.sessionId)
              //getAccountId();
              //console.log("accountId stripeAccountCompleted",accountId,stripeAccountCompleted)
              signIn(res?.data?.accessToken);
              if (res.data.userDetails.newUser) {
                // toast.success(res.message);
                res.data.tenantDetails = tenantinfo;
                dispatch(allActions.adminAction.logIn(res.data));
                history("/reset-password", { state: { loginDetail: res?.data, tenantId: tenantinfo?.tenantId, tenantName: tenantinfo?.name } });

              } else if (res?.data?.userDetails?.roles?.[0] === "ROLE_FAMILY") {
                //history("/card-login",);
                //console.log("userdetail login", res?.data?.userDetails);
                if (res?.data?.userDetails?.creditCardAuthorization === false || res?.data?.userDetails?.paymentInfo === false || res?.data?.userDetails?.creditCardAttach === false) {
                  //history("/card-login", { state: { loginDetail: res?.data, tenantId: tenantinfo?.tenantId, user: res?.data } });
                  history("/card-login");
                } else {
                  dispatch(allActions.adminAction.logIn(res.data));
                  history("/dashboard");
                }
              }
              else if (res?.data?.userDetails?.roles?.[0] === "ROLE_RCE" && res?.data?.userDetails?.superTenantAdmin) {
                getBilling(res?.data, res?.data?.accessToken, res?.data?.sessionId)
              } else if (res?.data?.userDetails?.roles?.[0] === "ROLE_RCE" && res?.data?.userDetails?.superTenantAdmin === false) {
                getNormalBilling(res?.data, res?.data?.accessToken, res?.data?.sessionId)
              }
              else {
                toast.success(res.message);
                res.data.tenantDetails = tenantinfo;
                setTimeout(() => {
                  dispatch(allActions.adminAction.logIn(res.data));
                  if (sessionId) {
                    history(`/video-therapy/${sessionId}`);
                  } else if (sessionId) {
                    history(`/marketing-video-therapy/${sessionId}`);
                  } else {
                    history("/dashboard");
                  }
                }, 2000)
              }
            } else {
              res?.message && toast.error(res?.message);
            }
          },
          (error) => { }
        );
      } catch (error) {
        toast.error(error);
      }
    }
  };
  function signIn(token) {
    console.log("firebase login");
    fetch(`${apiUrl}/sessions/firebase/credentials`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'X-TenantID': tenantinfo?.tenantId
      }
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log('Success: firebse', data);
        firebase.auth().signInWithEmailAndPassword(data?.email, data?.password)
          .then((userCredential) => {
            // User signed in successfully
            var user = userCredential.user;
            console.log("User signed in:", user);
          })
          .catch((error) => {
            console.error("Sign in error:", error);
          });
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  useEffect(() => {

    if (params.sessionexpired) {
      toast.error("Session expired! please login again");
    }
    // for get tenant information
    let tenantUrl =
      window.location.host === "meisqa.techment.com"
        ? "meisqa.techment.com"
        : window.location.host === "localhost:3000" ||
          window.location.host === "meisdev.techment.com"
          ? "dev-tenant-1.techment.com"
          : window.location.host;

    //tenantUrl = "dev-tenant-1.techment.com";

    adminService.getAllAuth(`tenants?tenantUrl=${tenantUrl}`).then(
      (res) => {
        setTenantInfo(res.data && res.data[0]);
        let userData = {
          tenantDetails: res.data && res.data[0],
        };
        dispatch(allActions.adminAction.logIn(userData));
        dispatch(allActions.adminAction.setTenantInfo(res.data[0]));
        console.log("check", res.data, res.data[0].name);
        if (res?.code === 404 && res?.message == "Tenant is not active") {
          toast.error(res?.message);
          setInactive(true);
        }
      },
      (error) => {
        console.log("check err", error);
      }
    );
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseCofig);
      //  checkPermission();
    } else {
      firebase.app(); // if already initialized, use that one
    }
    console.log("user login", isUserLoggedIn);
    //getAccountId();
  }, []);
  // useEffect(()=>{
  //   getAccountId();
  // },[])
  const getNormalBillingCard = (res, token, sessionId) => {
    fetch(`${apiUrl}/misc/billing-company-detail?tenantId=${tenantinfo?.tenantId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'SessionId': sessionId,
        'X-TenantID': tenantinfo?.tenantId
      }
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log('admin normal Success:', data?.data?.stripeAccountId === null, data?.data?.stripeAccountId);
        if (data?.status === "Success") {
          dispatch(allActions.adminAction.stripeAccount(data?.data?.stripeAccountId));
          dispatch(allActions.adminAction.billingInfo(data?.data));
        } else {
          toast.error(res.message);
        }

        //setAccountId(data?.data?.stripeAccountId);
        //setstripeAccountCompleted(data?.data?.stripeAccountCompleted);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  const getNormalBilling = (res, token, sessionId) => {
    fetch(`${apiUrl}/misc/billing-company-detail?tenantId=${tenantinfo?.tenantId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'SessionId': sessionId,
        'X-TenantID': tenantinfo?.tenantId
      }
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log('admin normal Success:', data?.data?.stripeAccountId === null, data?.data?.stripeAccountId);
        if (data?.status === "Success") {
          if (data?.data?.stripeAccountId === null) {
            history("/admin-normal-account");
          } else {
            dispatch(allActions.adminAction.logIn(res));
            history("/dashboard");
          }
        } else {
          toast.error(res.message);
        }

        //setAccountId(data?.data?.stripeAccountId);
        //setstripeAccountCompleted(data?.data?.stripeAccountCompleted);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  const getBilling = async (res, token, sessionId) => {
    console.log("tenantId", tenantinfo?.tenantId, res);
    fetch(`${apiUrl}/misc/billing-company-detail?tenantId=${tenantinfo?.tenantId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'SessionId': sessionId,
        'X-TenantID': tenantinfo?.tenantId
      }
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log('Success:', data.data, data?.data?.stripeAccountCompleted === false);
        if (data?.data?.stripeAccountId === null || data?.data?.stripeAccountCompleted === false) {
          history("/add-account", { state: { loginDetail: res, tenantId: tenantinfo?.tenantId, accountId: data?.data?.stripeAccountId, stripeAccountCompleted: data?.data?.stripeAccountCompleted } });
        } else {
          dispatch(allActions.adminAction.logIn(res));
          history("/dashboard");
        }
        //setAccountId(data?.data?.stripeAccountId);
        //setstripeAccountCompleted(data?.data?.stripeAccountCompleted);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  console.log("accountId", accountId);
  return (
    <>
      <div className="col">
        <div className="container p-0">
          <div className="row align-items-center login-page">
            <div className="col-md-6 no-padding p-0">
              <div className="log-wrapper-left">
                <img
                  src={`${sessionId ? "../" : ""
                    }../resources/assets/Mask Group 2.png`}
                  className="mask-login-left"
                />
                {
                  <img
                    src={`${sessionId ? "../" : ""
                      }../resources/assets/MIS-1.png`}
                    className="login-logo"
                  />
                }
                {/*window.location.host !== "cfdx.mis-live.com" ? <img  className="login-logo"  src={'../resources/assets/MIS-1.png'} alt="" />: <img  className="login-logo"  src={'https://meid-dev-public.s3.us-east-2.amazonaws.com/billinglogo.png'} alt="" /> */}
              </div>
              <img
                src={`${sessionId ? "../" : ""
                  }../resources/assets/left-login-img.jpg`}
                className="left-img img-fluid"
              />
              <img
                src={`${sessionId ? "../" : ""}../resources/assets/MIS-1.png`}
                className="copyright-image "
              />
              <span className="copyright-text">Copyright @RCE {Year}</span>
            </div>

            <div className="col-md-6 no-padding">
              <div className="row valign_wrapper padd-b-60-all ">
                <div className="custom-table-view-login">
                  <div className="col-lg-12 min-hei-250-all">
                    <div className="text-center login">
                      {/* <img className="mb-3 mb-sm-5 tenant-logo-img" src={tenantinfo && tenantinfo.largeLogoUrl} alt="" />*/}

                      {
                        tenantinfo && tenantinfo?.largeLogoUrl && (
                          <img
                            className="mb-3 mb-sm-5 tenant-logo-img"
                            src={tenantinfo && tenantinfo.largeLogoUrl}
                            alt=""
                          />
                        )
                        // : (
                        //   <img
                        //     className="mb-3 mb-sm-5 tenant-logo-img"
                        //     src="https://meisdevpublic.s3.us-east-2.amazonaws.com/meis-logo.png"
                        //     alt=""
                        //   />
                        // )
                      }
                      <div className="form-signin-wrapper">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autocomplete="off"
                          className="custom-form form-custome-elements floting-form"
                        >
                          <h1 className="h3 mb-3 font-weight-normal text-left">
                            Login
                          </h1>
                          <div className="form-group">
                            <input
                              name="email"
                              onChange={handleChange}
                              defaultValue={email}
                              type="text"
                              id="inputEmail"
                              className={"form-control"}
                              required
                            />
                            <label
                              className="form-control-placeholder"
                              for="inputEmail"
                            >
                              Enter Email/Mobile
                            </label>
                          </div>
                          <div className="form-group">
                            <input
                              name="password"
                              onChange={handleChange}
                              defaultValue={password}
                              type="password"
                              id="inputPassword"
                              className="form-control"
                              autoComplete="off"
                              required
                            />
                            <label
                              className="form-control-placeholder"
                              for="inputPassword"
                            >
                              Enter Password
                            </label>
                          </div>
                          <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                              <button
                                className="btn custom-btn-green"
                                type="submit"
                                //disabled={inactive}
                                block={true}
                              >
                                Login
                              </button>
                            </div>
                            <div className="col-auto">
                              <div className="checkbox">
                                <Link to={`/forgot-password`}>
                                  <u>Forgot password?</u>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
