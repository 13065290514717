import { agoraTypes } from "../types";

const userJoined = (userObj) => {
  return {
    type: agoraTypes.userJoined,
    payload: userObj,
  };
};

const userLeave = (userObj) => {
  return {
    type: agoraTypes.userLeave,
    payload: userObj,
  };
};

const screenShare = (user, isEnabled) => {
  return {
    type: agoraTypes.screenShare,
    payload: {
      user,
      isEnabled,
    },
  };
};

const setRecording = (isEnabled) => {
  return {
    type: agoraTypes.agoraRecord,
    payload: isEnabled,
  };
};

const clear = () => {
  return {
    type: agoraTypes.clear,
  };
};

const setSession = (session) => {
  return {
    type: agoraTypes.setSession,
    payload: session,
  };
};

const setControls = (userObj) => {
  return {
    type: agoraTypes.agoraControl,
    payload: userObj,
  };
};

const setVolumeIndicator = (userObj) => {
  return {
    type: agoraTypes.volumeIndicator,
    payload: userObj,
  };
};

const agoraAction = {
  userJoined,
  userLeave,
  screenShare,
  clear,
  setSession,
  setRecording,
  setControls,
  setVolumeIndicator,
};
export default agoraAction;
