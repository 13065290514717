import React, { Component, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Route, Switch, Link, useLocation } from "react-router-dom";
//import { useAlert,positions } from 'react-alert';

import InterpreterPersonalInformation from "./OnBoardingUser/InterpreterPersonalInformation";
import InterpreterLanguageInformation from "./OnBoardingUser/InterpreterLanguageInformation";
import InterpreterSetAvailability from "./OnBoardingUser/InterpreterSetAvailability";

//import Stepper from "../../components/Stepper";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";

function EditInterpreter() {
  const params = useParams();
  //const alert = useAlert();
  let loading = false;
  const history = useNavigate();
  //const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState("");
  //const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
  const steps = getSteps();
  const [therapistId, setInterpreterId] = useState(null);
  const [therapistData, setTherapistData] = useState();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    return ["Personal Information", "Interpreting Details", "Set Availability"];
  }

  useEffect(() => {
    getTherapistDetail();
  }, []);

  const getTherapistDetail = () => {
    adminService.getAll(`interpreters/${params.interpreterId}`).then(
      (res) => {
        if (res.status === "Success") {
          setTherapistData(res.data);
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <InterpreterPersonalInformation
            therapistId={therapistId}
            setInterpreterId={setInterpreterId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
            therapistData={therapistData}
          />
        );
      case 1:
        return (
          <InterpreterLanguageInformation
            therapistId={therapistId}
            setInterpreterId={setInterpreterId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
            therapistData={therapistData}
          />
        );
      case 2:
        return (
          <InterpreterSetAvailability
            therapistId={therapistId}
            setInterpreterId={setInterpreterId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
            therapistData={therapistData}
          />
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    setActiveStep(0);
  }, []);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Add Interpreter</div>
            </div>
            <div className="row stepper">
              {steps.map((label, index) => (
                <div
                  className={`col-md-4 stepper-steps ${
                    activeStep == index && activeStep != steps.length - 1
                      ? "active"
                      : activeStep > index
                      ? "complete"
                      : activeStep === steps.length - 1
                      ? "complete done"
                      : ""
                  }`}
                >
                  <div className="step-count">
                    <label>{index + 1}</label>
                  </div>{" "}
                  <label className="label-text-family">{label}</label>
                </div>
              ))}
              {/* <Stepper activeStep={activeStep} steps={getSteps()}/> */}
            </div>
            {getStepContent(activeStep)}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditInterpreter;
