import React, { useState, useEffect } from "react";
import usePageState from "../../hooks/InitialState";
import { adminService } from "../../services/adminService";
import "./TherapyList.scss";

function TherapyList(props) {
  const { pageState, setPageState } = usePageState();
  const {therapyList, setTherapyList} = useState();
  const { error, onChange, defaultValue } = props;
  //const [list, setList] = useState([]);

  const getTherapyDetail = (id) => {
    adminService.getAll(`therapies?active=true`)
    .then((res) => {
      console.log("get therapies data", res?.data);
      if (res.status == "Success") {
        setPageState((item) => ({ ...item, data: res?.data?.content }));
      }
    })
    .catch((err) => {
      console.log("err therapies data", err);
    });
};

useEffect(() => {
  getTherapyDetail();
}, []);

  return (
    <>
      <div className="therapyListWrapper">
        <select
          className={`custom-select form-control therapyList  ${
            error && "is-invalid"
          }`}
          onChange={(e) => onChange(e.target.value)}
          defaultValue={defaultValue}
          name="therapyId"
        >
          <option value="">Select Therapy</option>
          {pageState?.data?.length > 0 &&
            pageState?.data.map((th) => {
              return (
                <option key={th.id} value={th.id}>
                  {th.name}
                </option>
              );
            })}
        </select>
        <div className="input-group-prepend">
          <img
            className="m-auto"
            src="../resources/assets/Polygon 1.svg"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default TherapyList;
