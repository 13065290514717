import React from "react";
import { AttachmentFile } from "../attachment";
import { fileDownloadHandler } from "../../utils/ImageDownload";

const ResourceRow = ({ id, date, resourceType, key, Zoom, resourceList }) => {
  return (
    <tr className={`cursor-pointer`}>
      <th
        style={{
          width: "15%",
        }}
      >
        <div className="">{date}</div>
      </th>
      <td
        style={{
          width: "15%",
        }}
      >
        <div className="">
          <p className="name text-capitalize my-auto">{resourceType} </p>
        </div>
      </td>
      <td
        style={{
          width: "70%",
        }}
      >
        <div className="ml-3 mt-2 d-flex flex-wrap">
          {resourceList.map((img, index) => {
            const resType = img?.fileType || "";
            const fileType = resType.includes("image")
              ? "image"
              : resType.includes("video")
              ? "video"
              : "";
            return (
              <AttachmentFile
                onClick={() => {
                  if (fileType) {
                    Zoom(date, resourceType, img.key, fileType);
                  } else {
                    fetch(img.key, {
                      method: "GET",
                      headers: { "Content-Type": "application/" + resType },
                    })
                      .then((response) => {
                        return response.blob();
                      })
                      .then((blob) => {
                        const url = window.URL.createObjectURL(
                          new Blob([blob])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", img.fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                      })
                      .catch((e) => {
                        console.log("eeee", e);
                        fileDownloadHandler(img.key);
                      });
                  }
                }}
                {...img}
                className="resources-image"
                key={index + img.id}
                src={img.key}
                noLink
                attachmentType={fileType}
              />
            );
          })}
        </div>
      </td>
    </tr>
  );
};

export default ResourceRow;
