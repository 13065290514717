import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { adminService } from "../services/adminService";
import { toast } from "react-toastify";
//import { monthJson } from "../../config/Config";
import moment from "moment-timezone";
import {
    getUTCTimeToLocalByDateWithMeridium,
    getUTCDateTimeToLocalByDateWithMeridium24,
    calculateMinsLeft,
} from "../utils/commonFunctions";
//import DeleteSession from "../../pages/Representative/DeleteSession";
//import EditSessionRequest from "../../pages/Representative/EditSessionRequest";
import { useSelector } from "react-redux";
import StatusBadge from "./StatusBadge";
//import UrlImageDownloader from "react-url-image-downloader";
//import { fileDownloadHandler } from "../../utils/ImageDownload";
//import { IoMdDownload, IoIosEye } from "react-icons/io";
import ViewImageModal from "../pages/Representative/ViewImageModal";

function ViewArchiveTherapySession() {
    let params = useParams();
    let user = useSelector((state) => state.adminUser.user);
    const [modalOpenClose, setModalOpenClose] = useState(false);
    const [modalOpenClose1, setModalOpenClose1] = useState(false);
    const [iscancelreq, setIsCancelReq] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [deletesessiondetail, setdeletesessiondetail] = useState({
        info: "",
    });
    const [childList, setChildList] = useState([]);
    const [commonChildList, setCommonChildList] = useState([]);
    const [watchImage, setWatchImage] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (params) {
            let api = "archive/sessions/";
            adminService.getAll(api + params.id).then(
                (res) => {
                    if (res.status === "Success") {
                        console.log("response api", res.data);
                        setData(res.data);
                        setLoading(false);
                    } else {
                        toast.error(res.message);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, []);

    const Deletesession = (id, name, sessionInfo) => {
        let data = {
            sessionid: id,
            therapyname: name,
        };
        setdeletesessiondetail((deletesessiondetail) => ({
            ...deletesessiondetail,
            ["info"]: data,
            sessionInfo,
        }));
        setModalOpenClose(true);
    };
    const Editsession = (id, name) => {
        let data = {
            sessionid: id,
            therapyname: name,
        };
        setdeletesessiondetail((deletesessiondetail) => ({
            ...deletesessiondetail,
            ["info"]: data,
        }));
        setModalOpenClose1(true);
        setIsCancelReq(false);
    };
    const cancelSessionReq = (id, name, sessionInfo) => {
        let data = {
            sessionid: id,
            therapyname: name,
        };
        setdeletesessiondetail((deletesessiondetail) => ({
            ...deletesessiondetail,
            ["info"]: data,
            sessionInfo,
        }));
        setModalOpenClose1(true);
        setIsCancelReq(true);
    };

    //   const successSub = () => {
    //     setLoading(true);
    //     if (params) {
    //       let api =
    //         user.userDetails.roles[0] === "ROLE_RCE" ||
    //         user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS"
    //           ? "sessions/"
    //           : user.userDetails.roles[0] === "ROLE_FAMILY"
    //           ? "family/sessions/"
    //           : user.userDetails.roles[0] === "ROLE_THERAPIST"
    //           ? "therapist/sessions/"
    //           : "interpreter/sessions/";
    //       adminService.getAll(api + params.id).then(
    //         (res) => {
    //           if (res.status === "Success") {
    //             setData(res.data);
    //             setLoading(false);
    //           } else {
    //             toast.error(res.message);
    //           }
    //         },
    //         (error) => {
    //           console.log(error);
    //         }
    //       );
    //     }
    //   };

    useEffect(() => {
        user.userDetails.roles[0] === "ROLE_FAMILY" && getChildList();
    }, [data]);

    const getChildList = () => {
        adminService.getAll(`families/get-family-member`).then(
            (res) => {
                setChildList(res.data.content);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        let arr = [];
        if (
            user.userDetails.roles[0] === "ROLE_FAMILY" &&
            data?.familyMemberDtoSet
        ) {
            arr = childList.filter((o1) =>
                data?.familyMemberDtoSet.some((o2) => o1.id === o2.id)
            );
        }
        setCommonChildList(arr);
    }, [childList]);

    const downloadWhiteboardImage = (key) => {
        // sessions-board/download/{key}
        adminService
            .getAll(
                `sessions-board/download/7edaa067-fb40-4836-aed2-a0a4b59ee5a9_defaultProfile.jpg`
            )
            .then(
                (res) => {
                    console.log("response..", res);
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const time = calculateMinsLeft(data?.startDateTime);
    const endTime = calculateMinsLeft(data?.endDateTime);
    if (
        time < 5 &&
        endTime >= 1 &&
        data?.status != "COMPLETED" &&
        data?.status != "CANCELLED"
    ) {
        data.isSessionReady = true;
    } else {
        data.isSessionReady = false;
    }
    return (
        <>
            <div className="col-md-10 no-padding">
                <div className="no-padding dash-billing-info mr-0">
                    <div className="custom-table-view">
                        <div className="content-header">
                            <div className="text-left header-title ml-3">
                                Therapy Sessions of{" "}
                                {data.therapist}
                                {/* <button
                  type="button"
                  onClick={() => fileDownloadHandler(imageArray[0])}
                >
                  Download Pictures
                </button> */}
                            </div>
                        </div>
                        {loading ? (
                            <div className="loader"></div>
                        ) : (
                            <>
                                <div className="row mt-4 ml-2">
                                    <div className="col-md-12">
                                        <p className="name mb-1">
                                            Date:{" "}
                                            {moment(
                                                moment.utc(data && data.startDateTime).local()
                                            ).format("DD")}{" "}
                                            {
                                                moment(data && data.startDateTime).format("MMMM")
                                                // monthJson[
                                                //   parseInt(
                                                //     moment(data && data.startDateTime).format("MM")
                                                //   ) - 1
                                                // ].name
                                            }{" "}
                                            {moment(data && data.startDateTime).format("YYYY")}
                                        </p>
                                        <p className="name mb-1">
                                            Time:{" "}
                                            {getUTCTimeToLocalByDateWithMeridium(
                                                data && data.startDateTime
                                            )}{" "}
                                            to{" "}
                                            {getUTCTimeToLocalByDateWithMeridium(
                                                data && data.endDateTime
                                            )}{" "}
                                        </p>
                                        <p className="name mb-1">
                                            Therapy: {data.therapy}{" "}
                                        </p>
                                        <p className="name mb-1">
                                            Therapist:{" "}
                                            {data.therapist
                                                ? data.therapist
                                                : "None Needed"}{" "}
                                        </p>
                                        <p className="name mb-1">
                                            Interpreter:{" "}
                                            {data.interpreter
                                                ? data.interpreter
                                                : "None Needed"}{" "}
                                        </p>
                                        <p className="name mb-1">
                                        
                                             <b>Clients:</b>
                                             {data.clients && data.clients.map((item,i)=>(
                                                    <span key={i}>{i != 0 && ","}{" "}{item}</span>
                                             ))
                                            }
                                            {/*{user.userDetails.roles[0] == "ROLE_FAMILY" ? (
                                                <>
                                                    {commonChildList.map((cc, i) => {
                                                        return (
                                                            <span>
                                                                {i != 0 && ","}{" "}
                                                                {cc.firstName + " " + cc.lastName}
                                                            </span>
                                                        );
                                                    })}
                                                    {data?.familyMemberDtoSet &&
                                                        data?.familyMemberDtoSet.map((fm) => {
                                                            return (
                                                                <span>
                                                                    {commonChildList.length > 0
                                                                        ? !commonChildList.find(
                                                                            (d) => d.id == fm.id
                                                                        ) && (
                                                                            <>
                                                                                {commonChildList.length > 0 && ","}{" "}
                                                                                {fm.firstName + " " + fm.lastName}
                                                                            </>
                                                                        )
                                                                        : fm.firstName + " " + fm.lastName}
                                                                </span>
                                                            );
                                                        })}
                                                    {commonChildList.length == 0 &&
                                                        data?.familyMemberDtoSet &&
                                                        data?.familyMemberDtoSet.length == 0 && (
                                                            <>No Client Present</>
                                                        )}
                                                </>
                                            ) : (
                                                <>
                                                    {data?.familyMemberDtoSet &&
                                                        data?.familyMemberDtoSet.map((fm, i) => {
                                                            return (
                                                                <span>
                                                                    {i != 0 && ","}{" "}
                                                                    {fm.firstName + " " + fm.lastName}
                                                                </span>
                                                            );
                                                        })}
                                                    {commonChildList.length == 0 &&
                                                        data?.familyMemberDtoSet &&
                                                        data?.familyMemberDtoSet.length == 0 && (
                                                            <>&nbsp;No Client Present</>
                                                        )}
                                                </>
                                            )} */}
                                        </p>
                                        <p className="name mb-1">Description: </p>
                                        <p className="description"> {data.description} </p>
                                        <p className="name mb-1">
                                            Status: &nbsp;
                                            {data.status === "ACTION_NEEDED" ? (
                                                <StatusBadge
                                                    status={
                                                        user.userDetails.roles[0] == "ROLE_THERAPIST"
                                                            ? data.status
                                                            : "ACTION_NEEDED_PENDING"
                                                    }
                                                />
                                            ) : data.status === "IN_PROGRESS" &&
                                                getUTCDateTimeToLocalByDateWithMeridium24(
                                                    data.endDateTime
                                                ) >=
                                                getUTCDateTimeToLocalByDateWithMeridium24(
                                                    new Date()
                                                ) ? (
                                                <StatusBadge status={data.status} />
                                            ) : (
                                                <StatusBadge status={data.status} />
                                            )}
                                        </p>
                                    </div>
                                </div>

                                <div className="row mt-4 ml-2 mb-3">
                                    <div className="col-md-2">
                                        <Link
                                            to={`/archive-therapy-session`}
                                            className="btn  btn-primary t-back"
                                        >
                                            Back
                                        </Link>
                                    </div>
                                    {/* {data.status === "CANCELLED" ? (
                                        <></>
                                    ) : data.status === "COMPLETED" ? (
                                        <></>
                                    ) : data.status === "NOT_COMPLETED" ? (
                                        <>
                                            <td></td>
                                        </>
                                    ) : data.status === "MISSED" ? (
                                        <div className="col-md-2">
                                            {user.userDetails.roles[0] === "ROLE_RCE" ||
                                                user.userDetails.roles[0] ===
                                                "ROLE_REGIONAL_EARLY_STEPS" ? (
                                                <Link
                                                    to={`/edit-therapy-session/` + data.id}
                                                    className="btn btn-primary custom-btn-yellow t-edit"
                                                >
                                                    Edit
                                                </Link>
                                            ) : user.userDetails.roles[0] === "ROLE_THERAPIST" ? (
                                                <Link
                                                    to={`/edit-therapy-session/` + data.id}
                                                    className="btn btn-primary custom-btn-yellow t-edit"
                                                >
                                                    Edit
                                                </Link>
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        Editsession(
                                                            data.id,
                                                            data.therapy && data.therapy.name
                                                        )
                                                    }
                                                    className="btn btn-primary custom-btn-yellow t-edit"
                                                >
                                                    Edit
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            {getUTCDateTimeToLocalByDateWithMeridium24(
                                                data.endDateTime
                                            ) <
                                                getUTCDateTimeToLocalByDateWithMeridium24(new Date()) ? (
                                                ""
                                            ) : (
                                                <>
                                                    <div className="col-md-2">
                                                        {user.userDetails.roles[0] === "ROLE_RCE" ||
                                                            user.userDetails.roles[0] ===
                                                            "ROLE_REGIONAL_EARLY_STEPS" ? (
                                                            <Link
                                                                to={`/edit-therapy-session/` + data.id}
                                                                className="btn btn-primary custom-btn-yellow t-edit"
                                                            >
                                                                Edit
                                                            </Link>
                                                        ) : user.userDetails.roles[0] ===
                                                            "ROLE_THERAPIST" ? (
                                                            <Link
                                                                to={`/edit-therapy-session/` + data.id}
                                                                className="btn btn-primary custom-btn-yellow t-edit"
                                                            >
                                                                Edit
                                                            </Link>
                                                        ) : (
                                                            <button
                                                                onClick={() =>
                                                                    Editsession(
                                                                        data.id,
                                                                        data.therapy && data.therapy.name
                                                                    )
                                                                }
                                                                className="btn btn-primary custom-btn-yellow t-edit"
                                                            >
                                                                Edit
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6">
                                                        {user.userDetails.roles[0] === "ROLE_RCE" ||
                                                            user.userDetails.roles[0] ===
                                                            "ROLE_REGIONAL_EARLY_STEPS" ? (
                                                            <button
                                                                onClick={() =>
                                                                    Deletesession(
                                                                        data.id,
                                                                        data.therapy && data.therapy.name,
                                                                        data
                                                                    )
                                                                }
                                                                className="btn btn-primary t-cancel"
                                                            >
                                                                Cancel Session
                                                            </button>
                                                        ) : user.userDetails.roles[0] ===
                                                            "ROLE_THERAPIST" ? (
                                                            <button
                                                                onClick={() =>
                                                                    Deletesession(
                                                                        data.id,
                                                                        data.therapy && data.therapy.name,
                                                                        data
                                                                    )
                                                                }
                                                                className="btn btn-primary t-cancel"
                                                            >
                                                                Cancel Session
                                                            </button>
                                                        ) : user.userDetails.roles[0] === "ROLE_FAMILY" ? (
                                                            <button
                                                                onClick={() =>
                                                                    cancelSessionReq(
                                                                        data.id,
                                                                        data.therapy && data.therapy.name,
                                                                        data
                                                                    )
                                                                }
                                                                className="btn btn-primary t-cancel"
                                                            >
                                                                Cancel Session
                                                            </button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )
                                    }

                                    {data?.locationOfTreatment === "Teletherapy" &&
                                        user.userDetails.roles[0] !== "ROLE_RCE" &&
                                        user.userDetails.roles[0] !== "ROLE_REGIONAL_EARLY_STEPS" &&
                                        (data.status === "UP_COMING" ||
                                            data.status === "IN_PROGRESS") && (
                                            <div className="col-md-2">
                                                {data?.isSessionReady && (
                                                    <Link
                                                        to={`/video-therapy/${data.id}`}
                                                        className="btn btn-theme-green"
                                                    >
                                                        Join Now
                                                    </Link>
                                                )}
                                            </div>
                                        )} */}
                                </div>
                            </>
                        )}
                        {/* {modalOpenClose && (
              <DeleteSession
                successSub={() => successSub()}
                closeModal={() => setModalOpenClose(false)}
                sessioninfo={deletesessiondetail}
              />
            )}
            {modalOpenClose1 && (
              <EditSessionRequest
                isdelete={iscancelreq}
                // successSub={() => successSub()}
                closeModal={() => setModalOpenClose1(false)}
                sessioninfo={deletesessiondetail}
              />
            )} */}
                    </div>
                </div>

                {data?.boardKeySet && data.boardKeySet.length > 0 && (
                    <div className="no-padding dash-billing-info mr-0">
                        <div className="row custom-table-view">
                            <div className="text-left header-title ml-3 col-md-12 p-0 mb-10">
                                Whiteboard Images
                            </div>
                            {data.boardKeySet.map((img) => {
                                return (
                                    <>
                                        <div className="col-md-2 mb-10">
                                            <div className="card card-1 plr-10 download-board-card">
                                                <img src={img} width="100%" height={100} />
                                                <button
                                                    type="button"
                                                    className="download-board"
                                                    onClick={() =>
                                                        setWatchImage({
                                                            img: img,
                                                            therapyname:
                                                                data.therapy && data.therapy.description,
                                                        })
                                                    }
                                                >
                                                    {/* <IoIosEye /> */}
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                )}
                {watchImage && (
                    <ViewImageModal
                        closeModal={() => setWatchImage(false)}
                        watchImage={watchImage}
                    />
                )}
            </div>
        </>
    );
}

export default ViewArchiveTherapySession;
