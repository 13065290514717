import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { adminService } from "../services/adminService";

function AcceptChangeTherapist(props) {
  const [therapy, setTherapy] = useState([]);
  const [therapist, setTherapist] = useState([]);
  const [interpreter, setInterpreter] = useState([]);
  const [childName, setChildName] = useState([]);
  const [inputs, setInputs] = useState({
    therapyId: props.info.info.therapyId,
    therapistId: "",
    id: props.info.info.familyMemberId,
  });
  const [input, setInput] = useState({
    interpreterId: "",
    id: props.info.info.familyMemberId,
  });

  useEffect(() => {
    adminService.getAll(`therapies/${props.info.info.therapyId}`).then(
      (res) => {
        if (res.status === "Success") {
          setTherapy(res.data);
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    adminService
      .getAll(`therapists/filter?therapyId=${props.info.info.therapyId}`)
      .then(
        (res) => {
          if (res.status === "Success") {
            setTherapist(res.data.content);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    adminService
      .getAll(`families/family-member/${props.info.info.familyMemberId}`)
      .then(
        (res) => {
          if (res.status === "Success") {
            setChildName(res.data);
            let id = [];
            res.data &&
              res.data.map((item) => {
                <>
                  {item.languages &&
                    item.languages.map((info) => id.push(info.id))}
                </>;
              });
            adminService.getAll(`interpreters/filter?languageIds=${id}`).then(
              (res) => {
                if (res.status === "Success") {
                  setInterpreter(res.data.content);
                } else {
                  toast.error(res.message);
                }
              },
              (error) => {
                console.log(error);
              }
            );
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const acceptchangetherapist = () => {
    if (inputs.therapistId === "") {
      toast.warn("Please select a therapist");
    } else {
      let familyId = childName[0].id;
      console.log(familyId);
      let formDataPut = {
        id: inputs.id,
        familyMemberTherapyUpdateForms: [inputs],
      };
      let form = { familyMemberUpdateForms: [formDataPut] };
      let formData = new FormData();

      // formData.append('familyMemberUpdateForm', new Blob([JSON.stringify(formDataPut)], {
      //     type: "application/json"
      // }));

      formData.append(
        "familyUpdateForm",
        new Blob([JSON.stringify(form)], {
          type: "application/json",
        })
      );

      adminService.update(`/families/${familyId}`, formData).then(
        (res) => {
          if (res.status === "Success") {
            props.closeModal(false);
            let data = {
              familyMemberId: props.info.info.familyMemberId,
              changedTherapistId: inputs.therapistId,
              sessionId: props.info.info.sessionId,
            };
            adminService
              .PUT(
                "sessions/accept-therapist-change-request",
                JSON.stringify(data)
              )
              .then(
                (res) => {
                  if (res.status === "Success") {
                    toast.success(res.data);
                    setTimeout(() => {
                      props.afterSub();
                    }, 1000);
                  } else {
                    toast.error(res.message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  const acceptchangeinterpreter = () => {
    if (input.interpreterId === "") {
      toast.warn("Please select an interpreter");
    } else {
      let familyId = childName[0].id;
      console.log(familyId);
      let formDataPut = { familyMemberUpdateForms: [input] };
      let formData = new FormData();

      formData.append(
        "familyUpdateForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );

      adminService.update(`/families/${familyId}`, formData).then(
        (res) => {
          if (res.status === "Success") {
            props.closeModal(false);
            let data = {
              familyMemberId: props.info.info.familyMemberId,
              sessionId: props.info.info.sessionId,
              changedInterpreterId: input.interpreterId,
            };
            adminService
              .PUT(
                "sessions/accept-interpreter-change-request",
                JSON.stringify(data)
              )
              .then(
                (res) => {
                  if (res.status === "Success") {
                    toast.success(res.data);
                    setTimeout(() => {
                      props.afterSub();
                    }, 1000);
                  } else {
                    toast.error(res.message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.closeModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
  }

  function handleChangeInterpreter(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  return (
    <>
      <form>
        <div className="col-md-2 custom-modal">
          {/* Add Modal */}
          <div
            className="modal fade add-new-prop-modal show "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="text-center w-100">
                    <h5 className="modal-title name" id="exampleModalLongTitle">
                      &nbsp; &nbsp;{" "}
                      {props.isinterpreter
                        ? "Interpreter Change Request"
                        : "Therapist Change Request"}
                    </h5>
                  </div>
                  <button
                    onClick={() => props.closeModal(false)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <div className="close-background">
                      <p aria-hidden="true" className="cross">
                        &times;
                      </p>
                    </div>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 mt-3">
                      <h5>
                        <span className="name">Child Name:</span>{" "}
                        {props.info.info.childName}
                      </h5>
                      {props.isinterpreter ? (
                        <></>
                      ) : (
                        <h5>
                          <span className="name">Service Type:</span>{" "}
                          {therapy.name}
                        </h5>
                      )}
                      <h5>
                        <span className="name">
                          Previous{" "}
                          {props.isinterpreter ? "Interpreter" : "Therapist"}{" "}
                          Name:
                        </span>{" "}
                        {props.info.info.name}
                      </h5>
                    </div>
                  </div>

                  {props.isinterpreter ? (
                    <div className="row mt-3 ">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Interpreter</label> (
                          <span className="required-span">*</span>)
                          <div className="d-flex">
                            <img
                              className="pr-3"
                              src="../../resources/assets/user-icon-blue.svg"
                            />
                            <div className="input-group">
                              <select
                                className="custom-select"
                                onChange={handleChangeInterpreter}
                                name="interpreterId"
                                required
                              >
                                <option selected>Select Interpreter</option>
                                {interpreter &&
                                  interpreter.map((item) => (
                                    <option value={item.id}>
                                      {item.userProfile.firstName}{" "}
                                      {item.userProfile.lastName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Therapist</label> (
                          <span className="required-span">*</span>)
                          <div className="d-flex">
                            <img
                              className="pr-3"
                              src="../../resources/assets/user-icon-blue.svg"
                            />
                            <div className="input-group">
                              <select
                                className="custom-select"
                                onChange={handleChange}
                                name="therapistId"
                                required
                              >
                                <option selected>Select Therapist</option>
                                {therapist &&
                                  therapist.map((item) => (
                                    <option value={item.id}>
                                      {item.userProfile.firstName}{" "}
                                      {item.userProfile.lastName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="text-center mb-5">
                  <hr className="col-md-11" />
                  {props.isinterpreter ? (
                    <button
                      type="button"
                      onClick={acceptchangeinterpreter}
                      className="btn btn-primary custom-btn-green submitreqbutton mt-3"
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={acceptchangetherapist}
                      className="btn btn-primary custom-btn-green submitreqbutton mt-3"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AcceptChangeTherapist;
