// import config from 'config';
import {
  authHeader,
  authHeaderMultiPartUpload,
  authHeaderMultiPartUploadJSONContent,
} from "../utils/Helper";
import { apiUrl, apiUrlAuth, api } from "../config/Config";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export const adminService = {
  forgotPass,
  login,
  logout,
  getAll,
  getAllAuth,
  getAllNew,
  POST,
  postNew,
  PUT,
  PATCH,
  putNew,
  update,
  getById,
  POSTUploadImage,
  PUTUploadImage,
  downloadPDFByByteArrayServer,
  downloadPdfFromHtml,
  POSTDownLoad,
  onboardUser,
  resetPassword,
  restPass,
  DELETE,
  deleteNew,
  getAllNotifications,
  notificationSeen,
  onboardPutUser,
  onboardPutUserJSONContent,
  onboardUserJSONContent,
};

function onboardUserJSONContent(endpoint, dataobj) {
  let options = {
    method: "POST",
    headers: authHeaderMultiPartUploadJSONContent(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function onboardUser(endpoint, dataobj) {
  let options = {
    method: "POST",
    headers: authHeaderMultiPartUpload(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function login(email, password) {
  let loginreq = {};
  if (!isNaN(email)) {
    loginreq = {
      phone: email,
      password: password,
    };
  } else {
    loginreq = {
      email: email,
      password: password,
    };
  }
  let phone = email;
  let options = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(loginreq),
  };
  return fetch(`${apiUrlAuth}/auth/login`, options)
    .then(handleResponseAuth)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('user', JSON.stringify(user));
      // localStorage.setItem("token","AAAAAAAAAAAAAAAA");
      return user;
    })
    .catch((error) => console.log("error", error));
}

function resetPassword(dataobj) {
  let options = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(dataobj),
  };
  return fetch(`${apiUrlAuth}/users/${dataobj.userUniqueId}`, options)
    .then(handleResponseAuth)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('user', JSON.stringify(user));
      // localStorage.setItem("token","AAAAAAAAAAAAAAAA");
      return user;
    })
    .catch((error) => console.log("error", error));
}

function restPass(dataobj) {
  let options = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(dataobj),
  };
  return fetch(`${apiUrlAuth}/auth/reset-password`, options)
    .then(handleResponseAuth)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('user', JSON.stringify(user));
      // localStorage.setItem("token","AAAAAAAAAAAAAAAA");
      return user;
    })
    .catch((error) => console.log("error", error));
}

function logout(props) {
  localStorage.removeItem("user");
  toast.error(
    "Session has been expired, please try again, please logout and login again"
  );
  window.location.href = "/login/sessionexpired=true";
}

function PUT(endpoint, dataobj) {
  let options = {
    method: "PUT",
    headers: authHeader(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function PATCH(endpoint) {
  let options = {
    method: "PATCH",
    headers: authHeader(),
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function putNew(endpoint, dataobj) {
  let options = {
    method: "PUT",
    headers: authHeader(),
    body: dataobj,
  };
  return fetch(`${api + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function DELETE(endpoint, dataobj) {
  let options = {
    method: "DELETE",
    headers: authHeader(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function deleteNew(endpoint, dataobj) {
  let options = {
    method: "DELETE",
    headers: authHeader(),
    body: dataobj,
  };
  return fetch(`${api + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function POST(endpoint, dataobj) {
  let options = {
    method: "POST",
    headers: authHeader(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function downloadPDFByByteArrayServer(endpoint, dataobj) {
  var myHeaders = new Headers();
  myHeaders.append("SessionId", "3c533df4-414d-4016-bdd9-9dcc8b1223bb");
  myHeaders.append("X-TenantID", "dev-tenant-1");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(dataobj);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiUrl + "/" + endpoint}`, requestOptions);
}

function downloadPdfFromHtml(
  html,
  options = {
    filename: "file.pdf",
  }
) {
  return new Promise((resolve, reject) => {
    adminService.downloadPDFByByteArrayServer(`html-to-pdf/i-text`, html).then(
      (response) => {
        response
          .blob()
          .then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = options.filename;
            a.click();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      },
      (error) => {
        reject(error);
        console.log(error);
      }
    );
  });
}

function POSTDownLoad(endpoint, dataobj) {
  const handle = (response) =>
    response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        // if any error like validation check or data not found, etc...
        if (response.status === 400) {
          return data;
        }
        if (response.status === 401 || response.status === 403) {
          // auto logout if 401 response returned from api
          logout();
        }
        const error = (data && data.message) || response.statusText;
        //return Promise.reject(error);
        return response;
      }
      if (data.code == 401) {
        logout();
      }

      return response;
    });
  let options = {
    method: "POST",
    headers: authHeader(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then((response) => response.body)
    .then((body) => {
      const reader = body.getReader();

      return new ReadableStream({
        start(controller) {
          return pump();

          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }

              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((url) => {
      window.open(url);
    })
    .catch((err) => console.error(err));
  // .then(stream => new Response(stream))
  // .then(response => {
  //
  //     const re  =response.getReader()
  //     re.read(({ done, value }) => {
  //     })
  //     return response
  // })
  // .then(blob => {
  //
  //     // URL.createObjectURL(blob)
  //     window.open(URL.createObjectURL(blob));
  // })
  // .catch(error => console.log('error', error));
}

function postNew(endpoint, dataobj) {
  let options = {
    method: "POST",
    headers: authHeader(),
    body: dataobj,
  };
  return fetch(`${api + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function PUTUploadImage(endpoint, dataobj, signal) {
  let options = {
    method: "PUT",
    headers: authHeaderMultiPartUpload(),
    body: dataobj,
    signal,
  };
  return fetch(`http://3.130.73.230:8080/session-service/api/forms`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function POSTUploadImage(endpoint, dataobj, signal) {
  let options = {
    method: "POST",
    headers: authHeaderMultiPartUpload(),
    body: dataobj,
    signal,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function update(endpoint, dataobj) {
  let options = {
    method: "PUT",
    headers: authHeaderMultiPartUpload(),
    body: dataobj,
  };
  return fetch(`${apiUrl + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function getAllAuth(endpoint, signal) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    signal,
  };
  return fetch(`${apiUrlAuth}/${endpoint}`, requestOptions)
    .then(handleResponse)
    .catch((err) => console.log("error", err));
}

function getAll(endpoint, signal) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    signal,
  };
  return fetch(`${apiUrl}/${endpoint}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log("error", err));
}

function getAllNew(endpoint, signal) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    signal,
  };
  return fetch(`${api}/${endpoint}`, requestOptions)
    .then(handleResponse)
    .catch((err) => console.log("error", err));
}

function getAllNotifications(endpoint) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${api}/${endpoint}`, requestOptions)
    .then(handleResponse)
    .catch((err) => console.log("error", err));
}

function notificationSeen(endpoint) {
  let options = {
    method: "PUT",
    headers: authHeader(),
  };

  return fetch(`${api + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function forgotPass(endpoint) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${apiUrlAuth}/${endpoint}`, requestOptions).then(
    handleResponse
  );
}

function getById(endpoint) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/${endpoint}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text);
      if (!response?.ok) {
        // if any error like validation check or data not found, etc...
        if (response?.status === 400) {
          return data;
        }
        if (response?.status === 401 || response?.status === 403) {
          // auto logout if 401 response returned from api
          logout();
        }
        const error = (data && data.message) || response.statusText;
        //return Promise.reject(error);
        return data;
      }
      if (data.code == 401) {
        logout();
      }

      return data;
    })
    .catch((err) => console.log("object----------", err));
}

function handleResponseAuth(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      // if any error like validation check or data not found, etc...
      if (response.status === 400) {
        return data;
      }
      if (response.status === 401 || response.status === 403) {
        return data;
      }
      const error = (data && data.message) || response.statusText;
      //return Promise.reject(error);
      return data;
    }

    return data;
  });
}

function onboardPutUser(endpoint, dataobj) {
  let options = {
    method: "PUT",
    headers: authHeaderMultiPartUpload(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}

function onboardPutUserJSONContent(endpoint, dataobj) {
  let options = {
    method: "PUT",
    headers: authHeaderMultiPartUploadJSONContent(),
    body: dataobj,
  };
  return fetch(`${apiUrl + "/" + endpoint}`, options)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => console.log("error", error));
}
