import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import allActions from "../../../redux/actions";
import { useForm } from "react-hook-form";
import { adminService } from "../../../services/adminService";
import { toast } from "react-toastify";
import { normalizeInput } from "../../../utils/commonFunctions";
import appAction from "../../../redux/actions/appAction";
import InputFileBtn from "../../InputFileBtn";
import { nameRegex } from "../../../utils/regex";

function TherapistPersonalInformation(props) {
  const saveNextRef = useRef(null);
  const { register, handleSubmit, formState: { errors },setValue } = useForm();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  let therapistForm = useSelector((state) => state.adminUser.therapistForm);
  let jsonFound = therapistForm.hasOwnProperty("userProfileForm");
  const [profileImage, setProfileImage] = useState("");
  const [therapist, setTherapist] = useState([]);
  const [errorPhone, setErrorPhone] = useState("");
  let params = useParams();
  const [inputs, setInputs] = useState({
    therapistId: "",
    pendingOnboarding: true,
    onboardPermission: false,
    updatePermission: false,
    firstName: jsonFound ? therapistForm.userProfileForm.firstName : "",
    lastName: jsonFound ? therapistForm.userProfileForm.lastName : "",
    email: jsonFound ? therapistForm.userProfileForm.email : "",
    phone: jsonFound ? therapistForm.userProfileForm.phone : "",
    about: jsonFound ? therapistForm.userProfileForm.about : "",
    gender: jsonFound ? therapistForm.userProfileForm.gender : "",
    active: jsonFound ? therapistForm.userProfileForm.active : false,
    profileImage: jsonFound
      ? therapistForm.userProfileForm.profileImage
      : profileImage,
  });
  console.log("interpreter param", params);
  useEffect(() => {
    if (profileImage === "") {
      let url = "../../resources/assets/defaultprofile.png";
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "defaultProfile.jpg", {
            type: blob.type,
          });
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            file.url = reader.result;
            file.isnew = true;
            setProfileImage(file);
            setInputs((inputs) => ({ ...inputs, profileImage: file }));
          };
        });
    }
  }, [profileImage]);

  useEffect(() => {
    if (params.therapistId) {
      setloading(true);
      adminService.getById(`therapists/` + params.therapistId).then(
        (res) => {
          if (res.status === "Success") {
            console.log("therapists prosonalinfo",res.data);
            setTherapist(res.data);
            setValue("firstName",res.data.userProfile.firstName);
            setValue("lastName",res.data.userProfile.lastName);
            setValue("email",res.data.userProfile.email);
            setValue("phone",res.data.userProfile.phone);
            setValue("about",res.data.userProfile.about);
            dispatch(
              allActions.adminAction.setTherapistFormData(
                {
                  credentials: res?.data?.credentials,
                  disciplines_id: res?.data?.disciplinesDto?.id,
                  hourlyRate: res?.data?.hourlyRate,
                  licenseNumberState: res?.data?.licenseNumberState,
                  medicaidNumber: res?.data?.medicaidNumber,
                  taxonomy: res?.data?.taxonomy,
                  otherDiscipline: res?.data?.otherDiscipline,
                },
                "otherFields"
              )
            );
            dispatch(
              allActions.adminAction.setTherapistFormData(
                {
                  ...res.data.userProfile,
                  therapistServices: res.data?.therapistServices,
                },
                "userProfileForm"
              )
            );

            let phone = res.data.userProfile && res.data.userProfile.phone;
            //res.data.userProfile.phone  = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
            setInputs({
              ...res.data.userProfile,
              therapistServices: res.data?.therapistServices,
            });
            setloading(false);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (params?.id) {
      //console.log("therapist profile");
      setloading(true);
      adminService.getById(`therapists/` + params.id).then(
        (res) => {
          
          if (res.status === "Success") {
            console.log("therapist profile",res.data.userProfile);
            setInputs(res?.data?.userProfile);
            setTherapist(res.data);
            dispatch(
              allActions.adminAction.setTherapistFormData(
                res.data.userProfile,
                "userProfileForm"
              )
            );
            setValue("firstName",res.data.userProfile.firstName);
            setValue("lastName",res.data.userProfile.lastName);
            setValue("email",res.data.userProfile.email);
            setValue("phone",res.data.userProfile.phone);
            setValue("about",res.data.userProfile.about);

            let phone = res.data.userProfile && res.data.userProfile.phone;
           
            //res.data.userProfile.phone  = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
            
            setloading(false);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (params.uid) {
      setloading(true);
      adminService.getById(`marketing-registrations/all/` + params.uid).then(
        (res) => {
          if (res.status === "Success") {
            dispatch(
              allActions.adminAction.setTherapistFormData(
                res.data,
                "userProfileForm"
              )
            );
            let phone = res.data && res.data.phone;
            res.data.phone = `(${phone.slice(0, 3)}) ${phone.slice(
              3,
              6
            )}-${phone.slice(6, 10)}`;
            setInputs(res.data);
            setloading(false);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [params]);

  useEffect(() => {
    console.log("therapist data",props)
    if (props?.therapistData) {
      setInputs({
        ...inputs,
        firstName: props?.therapistData.userProfile.firstName,
        lastName: props?.therapistData.userProfile.lastName,
        email: props?.therapistData.userProfile.email,
        phone: props?.therapistData.userProfile.phone,
        gender: props?.therapistData.userProfile.gender,
        about: props?.therapistData.userProfile.about,
        profileImage: props?.therapistData.userProfile.profilePicUrl,
        familyId: props?.therapistData.id,
        therapistServices: props?.therapistData?.therapistServices,
      });
    }
  }, [props?.therapistData]);

  const [isSaveBack, setIsSaveBack] = useState(false);
  const onSubmit = (data) => {
    console.log("isSaveBack t", isSaveBack);
    if (isSaveBack) {
      console.log("isSaveBack111122222", isSaveBack);
      createTherapist();
    } else {
      console.log("isSaveBack111133", isSaveBack);

      if (params.id) {
        //inputs.active = status;
        inputs.phone = inputs.phone.replace(/[^\d]/g, "");
        let formDataPut = { userProfileUpdateForm: inputs };
        let formData = new FormData();
        formData.append("profileImage", inputs.profileImage);
        formData.append(
          "therapistUpdateForm",
          new Blob([JSON.stringify(formDataPut)], {
            type: "application/json",
          })
        );

        dispatch(appAction.setAppLoading(true));
        adminService
          .update(`/therapists/` + params.id, formData)
          .then(
            (res) => {
              if (res?.status === "Success") {
                toast.success(res.message);
                setTimeout(() => {
                  history("/therapist-details/" + params.id);
                }, 2000);
              } else {
                res?.message && toast.error(res?.message);
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            dispatch(appAction.setAppLoading(false));
          });
      } else {
        console.log("isSaveBack1111 t", isSaveBack,inputs);
        let phone_no = inputs.phone;
        if (phone_no === "") {
          console.log("phone",inputs.phone);
          toast.error("Phone number is required");
          setErrorPhone("Phone number is required");
          return;
        }

        // if ( phone_no && phone_no.length !== 14){
        //setErrorPhone("Invalid phone format. ex: (555) 555-5555");
        // toast.error("Invalid phone format. ex: (555) 555-5555");
        //   return;
        // }else
        setErrorPhone("");

        let checkUserReq = {
          email: inputs.email,
          phone: inputs.phone.replace(/[^\d]/g, ""),
        };
        // createTherapist("next");
        if (props?.therapistData) {
          console.log("isSaveBack1111..", isSaveBack);
          //alert("ok");
          createTherapist("next");
        } else {
          console.log("isSaveBack11112222222222", isSaveBack);
          // createTherapist();
          dispatch(appAction.setAppLoading(true));
          adminService
            .POST(`user-profiles/check-user`, JSON.stringify(checkUserReq))
            .then(
              (res) => {
                if (res.status === "Success") {
                  if (res.data.userAlreadyExists) {
                    if (res.data.emailAlreadyExists)
                      toast.error("User email address already exists");
                    else if (res.data.phoneAlreadyExists)
                      toast.error("User phone number already exists");
                  } else {
                    dispatch(
                      allActions.adminAction.setTherapistFormData(
                        inputs,
                        "userProfileForm"
                      )
                    );
                    props?.setTherapistId(res.data.id);
                    props.handleNext();
                  }
                } else {
                  toast.error(res.message);
                }
              },
              (error) => {
                console.log(error);
              }
            )
            .finally(() => {
              dispatch(appAction.setAppLoading(false));
            });
        }
      }
    }
  };

  const onSubmitSaveAndBack = (e) => {
    setIsSaveBack(true);
    // console.log("isSaveBack11..", isSaveBack);
    setTimeout(() => {
      setIsSaveBack(true);
      // console.log("isSaveBack11", isSaveBack);
      // if (isSaveBack) {
      saveNextRef.current.click();
      // }
    }, 1000);
  };

  const createTherapist = (isnext = "") => {
    //alert("ok");
    setIsSaveBack(false);
    dispatch(appAction.setAppLoading(true));
    let formData = new FormData();
    const basicInfo = {
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      phone: inputs.phone,
      about: inputs.about,
      gender: inputs.gender,
      active: true,
    };
    const otherInfo = {
      pendingOnboarding: inputs.pendingOnboarding,
      onboardPermission: inputs.onboardPermission,
      updatePermission: inputs.updatePermission,
    };
    if (props?.therapistData) {
      let formDataPut = { userProfileUpdateForm: basicInfo, ...otherInfo };
      formData.append(
        "therapistUpdateForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );
    } else {
      let formDataPut = { userProfileForm: basicInfo, ...otherInfo };
      console.log("therapist test2",formDataPut);
      formData.append(
        "therapistForm",
        new Blob([JSON.stringify(formDataPut)], {
          type: "application/json",
        })
      );
    }
    formData.append("profileImage", inputs.profileImage);
    console.log("interpreter formData",formData,props?.therapistData);
    if (props?.therapistData) {
      console.log("inputs", props?.therapistData);
      updateOnboardTherapist(props?.therapistData.id, formData, isnext);
    } else {
      onboardTherapist(formData, isnext);
    }
  };

  const onboardTherapist = (formData, isnext) => {
    adminService
      .onboardUser(`therapists`, formData)
      .then(
        (res) => {
          dispatch(appAction.setAppLoading(false));

          //alert("go");
          if (res?.status === "Success") {
            dispatch(
              allActions.adminAction.setTherapistFormData(
                inputs,
                "userProfileForm"
              )
            );
            if (isnext) {
              props.handleNext();
            } else {
              // dispatch(allActions.adminAction.clearOnboardData());
              toast.success(res.message);
              setTimeout(() => {
                history("/pending-users-onboarding?tab=therapist");
              }, 2000);
            }
          } else {
            //alert("ok");
            toast.error(res?.message || "Somthing went wrong.");
          }
        },
        (error) => {
          console.log("Error >>>>>>>", error);
        }
      )
      .finally(() => {
        dispatch(appAction.setAppLoading(false));
      });
  };

  const updateOnboardTherapist = (id, formData, isnext) => {
    adminService
      .update(`/therapists/${id}`, formData)
      .then(
        (res) => {
          dispatch(appAction.setAppLoading(false));
          console.log("object", res);
          if (res?.data?.id) {
            if (isnext) {
              // props?.handleNext();
              dispatch(
                allActions.adminAction.setTherapistFormData(
                  inputs,
                  "userProfileForm"
                )
              );
              props.handleNext();
            } else {
              history("/pending-users-onboarding?tab=therapist");
            }
          } else {
            toast.error(res?.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally((error) => {
        dispatch(appAction.setAppLoading(false));
      });
  };

  const handleStatus = (name, value) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  function handleChange(e) {
    console.log("phone",e.target)
    const { name, value } = e.target;
    if (name === "phone") {
      const currentValue = value.replace(/[^\d]/g, "");
      
      if (currentValue.length === 10) setErrorPhone("");
      //setInputs(inputs => ({ ...inputs, [name]: normalizeInput(value) }));
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FileSize = e.target.files[0].size / 1024 / 1024; // in MB
    if (file.name.match(/.(jpg|jpeg|jpeg2000|png|svg)$/i)) {
      if (FileSize > 2) {
        toast.warn("Please upload file having size MAX 2MB");
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          file.url = reader.result;
          file.isnew = true;
          setProfileImage(file);
          setInputs((inputs) => ({ ...inputs, [name]: file }));
        };
      }
    } else {
      toast.warn("Please upload file having extensions - JPG,JPEG,PNG,SVG");
    }
  };

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addtheripist">
            <label className="conetent-title">
              {" "}
              {params.id
                ? "Edit Therapist Personal Information"
                : "Personal Information"}
            </label>
            <div className="row gutter-33">
              <div className="col-12">
                <div className="form-group">
                  {params.id ? (
                    <tr>
                      <td>
                        <label className="name">Profile Picture</label> (
                        <span className="required-span">*</span>)
                      </td>
                      <td>
                        <div className="status">
                          <label className="name">Status</label>
                          <div className="working-time">
                            <div
                              className="btn-group btn-group-toggle set-time-section"
                              data-toggle="buttons"
                            >
                              <label
                                className={`btn ${
                                  inputs.active === true && "active"
                                }`}
                                onClick={() => handleStatus("active", "true")}
                              >
                                <input
                                  type="radio"
                                  value="true"
                                  name="active"
                                  autocomplete="off"
                                />{" "}
                                Active
                              </label>

                              <label
                                className={`btn ${
                                  inputs.active === false && "active"
                                }`}
                                onClick={() => handleStatus("active", "false")}
                              >
                                <input
                                  type="radio"
                                  value="false"
                                  name="active"
                                  autocomplete="off"
                                />{" "}
                                InActive
                              </label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      <label>Profile Picture</label>
                    </>
                  )}

                  {params.id || props?.therapistData ? (
                    <span>
                      <div className="row">
                        <div className="col-md-4">
                          {/* <img src={inputs.profileImage ? inputs.profileImage.url : therapist.userProfile && therapist.userProfile.profilePicUrl}
                                                        alt="" className="settingsimg" /> */}

                          <img
                            src={
                              inputs?.profileImage?.url
                                ? inputs.profileImage.url
                                : inputs?.profileImage
                                ? inputs.profileImage
                                : therapistForm.userProfileForm &&
                                  therapistForm.userProfileForm.profilePicUrl
                                ? therapistForm.userProfileForm.profilePicUrl
                                : "/resources/assets/defaultprofile.png"
                            }
                            // src={
                            //   inputs.profileImage
                            //     ? inputs.profileImage.url
                            //     : therapist.userProfile &&
                            //       therapist.userProfile.profilePicUrl
                            //     ? therapist.userProfile.profilePicUrl
                            //     : "/resources/assets/defaultprofile.png"
                            // }
                            alt=""
                            className="settingsimg"
                          />
                        </div>
                        <InputFileBtn
                          id="fileuplaod"
                          name="profileImage"
                          onChange={(e) => handleImageChange(e)}
                        />
                      </div>
                      <br />
                    </span>
                  ) : (
                    <label for="fileuplaod" className="fileuplaod">
                      <div className="content-box">
                        <img
                          src={
                            inputs.profileImage &&
                            inputs.profileImage.name !== "defaultProfile.jpg"
                              ? inputs.profileImage.url
                              : "../resources/assets/file-upload-icon.svg"
                          }
                          alt="Family"
                        />
                        <div className="file-title">
                          Drop your image here, or <strong>browse</strong>{" "}
                        </div>
                        <div className="file-name">
                          Support- JPG,JPEG,PNG,SVG
                        </div>
                      </div>
                      <input
                        type="file"
                        id="fileuplaod"
                        name="profileImage"
                        onChange={(e) => handleImageChange(e)}
                        {...register('profileImage',{required: inputs.profileImage ? false : true})}
                      />
                    </label>
                  )}
                  {errors.profileImage &&
                    errors.profileImage.type === "required" && (
                      <div className="invalid-feedback-custom">
                        Please upload profile picture
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label> (
                  <span className="required-span">*</span>)
                  <input
                    //defaultValue={inputs.firstName}
                    defaultValue={inputs?.firstName}
                    name="firstName"
                    //onChange={handleChange}
                    disabled={params.uid ? true : false}
                    type="text"
                    className={`form-control ${
                      errors.firstName && "is-invalid"
                    }`}
                    placeholder="Enter First Name"
                    {...register('firstName',{
                      onChange:(e)=>handleChange(e),
                      required: true,
                      maxLength: 30,
                      pattern: nameRegex,
                    })}
                  />
                  {errors.firstName && errors.firstName.type === "required" && (
                    <div className="invalid-feedback-custom">
                      First name is required
                    </div>
                  )}
                  {errors.firstName && errors.firstName.type === "pattern" && (
                    <div className="invalid-feedback-custom">
                      Alphabetical characters only
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label> (
                  <span className="required-span">*</span>)
                  <input
                    defaultValue={inputs?.lastName}
                    disabled={params.uid ? true : false}
                    name="lastName"
                    type="text"
                    className={`form-control ${
                      errors.lastName && "is-invalid"
                    }`}
                    placeholder="Enter Last Name"
                    {...register('lastName',{
                      onChange:(e)=>handleChange(e),
                      required: true,
                      maxLength: 30,
                      pattern: nameRegex,
                    })}
                    
                  />
                  {errors.lastName && errors.lastName.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Last name is required
                    </div>
                  )}
                  {errors.lastName && errors.lastName.type === "pattern" && (
                    <div className="invalid-feedback-custom">
                      Alphabetical characters only
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Gender</label> (
                  <span className="required-span">*</span>)<br />
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      
                      type="radio"
                      id="customRadio1"
                      value="MALE"
                      {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                      //onChange={handleChange}
                      checked={inputs?.gender === "MALE" ? "checked" : ""}
                      name="gender"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="customRadio1">
                      Male
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      
                      type="radio"
                      id="customRadio2"
                      value="FEMALE"
                      {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                      //onChange={handleChange}
                      checked={inputs?.gender === "FEMALE" ? "checked" : ""}
                      name="gender"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="customRadio2">
                      Female
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      
                      type="radio"
                      id="customRadio3"
                      value="NOT_DISCLOSED"                      
                      //onChange={handleChange} 
                      {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                      checked={
                        inputs?.gender === "NOT_DISCLOSED" ? "checked" : ""
                      }
                      name="gender"
                      className="custom-control-input"
                      
                    />
                    <label className="custom-control-label" for="customRadio3">
                      Other
                    </label>
                  </div>
                  {errors.gender && errors.gender.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Gender is required
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Email Address</label> (
                  <span className="required-span">*</span>)
                  <input         
                    // defaultValue={inputs.email}
                    defaultValue={inputs?.email}
                    disabled={params.uid ? true : false}
                    //disabled={params.uid ? true : params.id ? true : false}
                    name="email"
                    type="email"
                    //onChange={handleChange}
                    className={`form-control ${errors.email && "is-invalid"}`}
                    placeholder="Enter Email Address"
                    {...register('email',{
                      onChange:(e)=>handleChange(e),
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Email address is required
                    </div>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <div className="invalid-feedback-custom">
                      Please enter valid email address
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Phone Number</label> (
                  <span className="required-span">*</span>)
                  <input
                    //defaultValue={(inputs.phone)?}
                    defaultValue={inputs?.phone}
                    disabled={params.uid ? true : false}
                    //disabled={params.uid ? true : params.id ? true : false}
                    name="phone"
                    type="text"
                    //onChange={(e)=>handleChange(e)}
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    placeholder="Enter Phone Number"
                    maxlength="10"
                    {...register('phone',{ onChange:(e)=>handleChange(e),required: true, pattern: /^\d{9,10}$/ })}
                  />
                  {errors.phone?.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Phone number is required
                    </div>
                  )}
                  {errorPhone ? (
                    <div className="invalid-feedback-custom">
                      Please enter 10 digit phone number
                    </div>
                  ) : (
                    ""
                  )}
                  {errors.phone && errors.phone.type === "pattern" && (
                    <div className="invalid-feedback-custom">
                      Please enter valid phone number
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>About</label> (<span className="required-span">*</span>
                  )
                  <textarea
                    //defaultValue={inputs.about}
                    //maxlength="1500"
                    defaultValue={inputs?.about}
                    //disabled={params.uid ? true : false}
                    name="about"
                    rows="5"
                    //onChange={handleChange}
                    className={`form-control h-100 ${
                      errors.about && "is-invalid"
                    }`}
                    placeholder="Enter About therapist"
                    {...register('about',{ onChange:(e)=>handleChange(e),required: true })}
                  ></textarea>
                  {errors?.about?.type === "required" && (
                    <div className="invalid-feedback-custom">
                      About is required
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row addtheripist">
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12 d-flex">
              {!params.id && (
                <button
                  type="button"
                  className="btn  btn-primary d-block stepper-button-next"
                  id="save-and-back"
                  onClick={(e) => onSubmitSaveAndBack(e)}
                >
                  Save & Return
                </button>
              )}
              <button
                className="btn  btn-primary d-block stepper-button-next"
                type="submit"
                ref={saveNextRef}
              >
                {params.id ? "Submit" : "Save & Continue"}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default TherapistPersonalInformation;
