import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../admin/CommonComponents";
import SignDate from "./SignDate";
import BasicInfo from "../therapist/dischargeSummery/BasicInfo";
import PlanAndEvolution from "../therapist/dischargeSummery/PlanAndEvolution";
import MedicalNecessity from "../therapist/dischargeSummery/MedicalNecessity";
import OtherDescription from "../therapist/dischargeSummery/OtherDescription";
import DischargSummryGoals from "../therapist/dischargeSummery/DischargSummryGoals";
import MedicalTable from "../therapist/dischargeSummery/MedicalTable";
import DynamicGoals from "../therapist/dischargeSummery/DynamicGoals";
import PocDescription from "../therapist/dischargeSummery/PocDescription";
import produce from "immer";
import DatePicker from "react-multi-date-picker";

function PocStForm(props) {
  //var checkBoxVal = document.querySelector('input[name="yes_no"]:checked').value;
  const profile = useSelector((state) => state.adminUser.profile);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const formRef = React.useRef(null);
  const sigCanvas = useRef({});
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [familyInfo, setFamilyInfo] = useState({});
  const [prevLongGoals, setprevLongGoals] = useState([{}]);
  const [prevShortGoals, setprevShortGoals] = useState([{}]);
  const [counter, setCounter] = useState(0);
  const [tableValues, setTableValues] = useState([
    { rowId: counter, description: "", criteria: "" },
  ]);
  const [newLongGoals, setnewLongGoals] = useState([{}]);
  const [newShotGoals, setnewShotGoals] = useState([{}]);
  const [recipRadio, setRecipRadio] = useState('');
  const [isSubmit, setIsSubmit] = useState(
    typeof props?.data?.reply === "undefined" ? false : true
  );

  const [PocSt, setPocSt] = useState({

  });

  const [pageResult, setpageResult] = useState({
    adminSign: "",
    adminSignDate: "",
    reply: props.inputs?.reply ? props.inputs.reply : true,
    replyNeeded: false,
    sentBy: props.inputs?.sentBy ? props.inputs.sentBy : "",
    sentTo: props.inputs?.sentTo ? props.inputs.sentTo : "",
    therapistId: profile?.therapistId ? profile.therapistId : null,
    witnessSign: "",
    witnessSignDate: "",
    consent1: true,
    consent2: true,
    consent3: true,
    consent4: true,
    consent5: true,
    formId: props.inputs?.formId ? props.inputs.formId : null,
    familyId: props?.inputs?.familyId ? props?.inputs?.familyId : null,
    familyMemberId: props?.inputs?.familyMemberId ? props?.inputs?.familyMemberId : null,
    familyMemberName: "child one",
    familySign: "",
    familySignDate: "",
    interpreterId: null,
    interpreterSign: "",
    interpreterSignDate: "",
  });
  //const [serviceType,setServiceType] = useState();
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  console.log("selectedFamily", props?.selectedFamily?.familyMemberTherapies);
  let serviceName = "";
  // useEffect(()=>{
  //   props?.selectedFamily?.familyMemberTherapies.map((item)=>{
  //     console.log("profile.therapistId",profile.therapistId,item.therapist.id);
  //     if(item.therapist.id === profile.therapistId){
  //       console.log("serviceType",item.therapy.name);
  //       setServiceType(item.therapy.name);
  //       serviceName=item.therapy.name;
  //     }
  //   })
  // })
  useEffect(() => {
    setPocSt({
      step1: {
        serviceType: props?.serviceType,
        patientName:
          props?.selectedFamily?.firstName +
          " " +
          props?.selectedFamily?.lastName,
        chronologicalAge: props?.selectedFamily?.age,
        insuranceId: "",
        dob: props?.selectedFamily?.dob
          ? moment(props?.selectedFamily?.dob).format("YYYY-MM-DD") +
          `T00:00:00.000Z`
          : "",
        therapistName: profile?.firstName
          ? profile.firstName + " " + profile.lastName
          : "",
        therapistNpi: "",
        placeOfService: "",
        drNameNpi: props?.selectedFamily?.physicianNpiNumber,
        diagnosisICD10: props?.selectedFamily?.familyMemberTherapies
          ? props?.selectedFamily?.familyMemberTherapies[0]?.diagnosis[0]?.code
          : "",
        therapyDiagnosisICD10: "",
        currentPOCFromDate: "",
        currentPOCToDate: "",
        currentEvaluationDate: "",
        previouPOCFromDate: "",
        previouPOCToDate: "",
        previousEvaluationDate: "",
        weeklyVisit: "",
        visitLength: "",
        months: "",
        weeks: "",
      },
      step2: {
        medicalNecessityCriteria: [],

        specificICD10: "",
        allTreatmentServices: "",
        therapyMethod: "",
        monitoringCriteria: "",
        currentMedication: "",
      },
      step3: {
        otherTreatment: "",
        equipmentRequired: "",
        specializedDiet: {
          option: "",
          description: "",
        },
        methodDemonstrating: "",
        treatment: "",
        otherInfo: "",
        recipRadio: ""
      },
      step4: {
        previousGoals: {
          longTerm: [],
          shortTerm: [],
        },
        newGoals: {
          longTerm: [],
          shortTerm: [],
        },
        patientName: "",
        therapistName: "",
        therapistSign: "",
        therapistSignDate: "",
      },

    });
  }, [props?.data]);

  const patientName = PocSt.step1?.patientName;
  const therapistName = PocSt.step1?.therapistName;
  const chronologicalAge = PocSt.step1?.chronologicalAge;
  const dob = PocSt.step1?.dob;
  const updateInfo = (e) => {
    const { name, value } = e.target;
    setPocSt(
      produce((draftState) => {
        draftState.step3[name] = value;
      })
    );
  };
  const radioVal = (e) => {
    console.log(e.target.value);
    setRecipRadio(e.target.value);
    setPocSt(
      produce((draftState) => {
        draftState.step3["recipRadio"] =
          e.target.value
      })
    );
  }
  const startInfo = (e) => {
    const { name, value } = e.target;
    setPocSt(
      produce((draftState) => {
        draftState.step2[name] = value;
      })
    );
  };
  const patientInfo = (e) => {
    const { name, value } = e.target;
    setPocSt(
      produce((draftState) => {
        draftState.step4[name] = value;
      })
    );
  };
  const specializedInfo = (e) => {
    const { name, value } = e.target;
    console.log("specializedInfo", name, value);
    setPocSt(
      produce((draftState) => {
        draftState.step3.specializedDiet[name] = value;
      })
    );
  };

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setpageResult({
      ...pageResult,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setPocSt(
      produce((draftState) => {
        draftState.step4["therapistSignDate"] = moment(currentDateTime)
          .local()
          .format("MM-DD-YYYY hh:mm a");
      })
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setpageResult({
              ...pageResult,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setPocSt(
              produce((draftState) => {
                draftState.step4["therapistSign"] = res.data.signatureKey;
                draftState.step4["therapistSignDate"] = moment(currentDateTime)
                  .local()
                  .format("MM-DD-YYYY hh:mm a");
              })
            );
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "POC-ST.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };

  const removePrevLongGoals = (i, e) => {
    document.getElementById("longName" + i).value = '';
    //e.target.value='';
    const arr1 = [...prevLongGoals];

    // setTimeout(() => {
    arr1.splice(i, 1);
    //}, 500)
    //setTimeout(() => {
    setprevLongGoals(arr1);
    // }, 1000)
  };
  const removePrevShortGoals = (i) => {
    document.getElementById("shortOldPoc" + i).value = '';
    const arr = [...prevShortGoals];
    arr.splice(i, 1);
    setprevShortGoals(arr);
  };
  const removeNewLongGoals = (i, e) => {
    document.getElementById("nameLongNew" + i).value = '';
    const arr = [...newLongGoals];
    arr.splice(i, 1);
    setnewLongGoals(arr);
  };
  const removeNewShotGoals = (i) => {
    document.getElementById("shortPoc" + i).value = '';
    const arr = [...newShotGoals];
    arr.splice(i, 1);
    setnewShotGoals(arr);
  };
  const removeTableFields = (i) => {

    let arr = [...tableValues];
    console.log("removeTableFields id", arr, arr.length, " #i ", i);
    // document.getElementById("descriptionPoc" + i).value = '';
    // document.getElementById("criteriaPoc" + i).value = '';
    console.log("delte before ", arr);
    const newArr = arr.filter((row) => {
      console.log("row.rowId !== i ", row.rowId, "#i ", i)
      return row.rowId !== i
    }
    )
    console.log("delete after", newArr);
    //setTimeout(()=>{
    //arr.splice(i, 1);
    setTableValues(newArr);
    // console.log("arr",arr,arr.length);
    //},1000)
  };
  const handlePrevLongGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr1 = [...prevLongGoals];
    newArr1[i] = {
      ...newArr1[i],
      [name]: value,
    };
    setprevLongGoals(newArr1);
    setPocSt(
      produce((draftState) => {
        draftState.step4.previousGoals.longTerm = newArr1;
      })
    );
  };

  const handlePrevShortGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...prevShortGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setprevShortGoals(newArr);
    setPocSt(
      produce((draftState) => {
        draftState.step4.previousGoals.shortTerm = newArr;
      })
    );
  };
  const handleNewLongGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...newLongGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setnewLongGoals(newArr);
    setPocSt(
      produce((draftState) => {
        draftState.step4.newGoals.longTerm = newArr;
      })
    );
  };
  const handleNewShortGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...newShotGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setnewShotGoals(newArr);
    setPocSt(
      produce((draftState) => {
        draftState.step4.newGoals.shortTerm = newArr;
      })
    );
  };
  const handleTableFields = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...tableValues];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      [name]: value,
    };
    setTableValues(newArr);
    setPocSt(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteria = newArr;
      })
    );
  };

  const addPrevLongGoals = () => {
    setprevLongGoals([...prevLongGoals, { name: "" }]);
  };
  const addPrevShortGoals = () => {
    setprevShortGoals([...prevShortGoals, { name: "" }]);
  };
  var rowId = 0;
  const addTableFields = () => {
    // let rowUniq = counter+1;
    //  let rowUniq = rowId === 0 ? 1 :;
    console.log("before row uniq", counter)
    let c = counter;
    c = c + 1
    setCounter(c)

    let tv = [...tableValues]
    console.log("tv before ", tv);
    tv.push({ rowId: c, description: "", criteria: "" })
    console.log("tv after ", tv);

    setTableValues(tv);

    // setTimeout(()=>{
    // console.log("aftr row uniq" ,c,counter)
    //},500)
    //console.log("after add ",tableValues);

  };
  const addNewLongGoals = () => {
    setnewLongGoals([...newLongGoals, { name: "" }]);
  };
  const addNewShotGoals = () => {
    setnewShotGoals([...newShotGoals, { name: "" }]);
  };
  const updateBoxInfo = (e, indx) => {
    const { name, value } = e.target;
    const newArray = [...PocSt.step2.therapyType];
    newArray[indx] = {
      ...newArray[indx],
      isChecked: !newArray[indx].isChecked,
    };

    setPocSt(
      produce((draftState) => {
        draftState.step2.therapyType = newArray;
      })
    );
  };
  const changeInfo = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setPocSt(
      produce((draftState) => {
        draftState.step1[name] = value;
      })
    );
  };
  const setDate = (e) => {
    setPocSt(
      produce((draftState) => {
        draftState.step1["dob"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const careFrom = (e) => {
    setPocSt(
      produce((draftState) => {
        draftState.step1["currentPOCFromDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const careTo = (e) => {
    setPocSt(
      produce((draftState) => {
        draftState.step1["currentPOCToDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const previouFrom = (e) => {
    setPocSt(
      produce((draftState) => {
        draftState.step1["previouPOCFromDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const previouTo = (e) => {
    setPocSt(
      produce((draftState) => {
        draftState.step1["previouPOCToDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const evalFrom = (e) => {
    setPocSt(
      produce((draftState) => {
        draftState.step1["currentEvaluationDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const evalTo = (e) => {
    setPocSt(
      produce((draftState) => {
        draftState.step1["previousEvaluationDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };

  // var b = moment(PocSt?.step1?.dob).format('YYYY-MM-DD');
  // let dt = b
  // let age = '';

  // let bY = Number(moment(dt).format('YYYY'));
  // let bM = Number(moment(dt).format('MM'));
  // let bD = Number(moment(dt).format('DD'));

  // let tY = Number(moment().format('YYYY'));
  // let tM = Number(moment().format('MM'));
  // let tD = Number(moment().format('DD'));

  // age += (tY - bY) + ' Years ';

  // if (tM < bM) {
  //     age += (tM - bM + 12) + ' Months ';
  //     tY = tY - 1;
  // } else {
  //     age += (tM - bM) + ' Months '
  // }

  // if (tD < bD) {
  //     age += (tD - bD + 30) + ' Days ';
  //     tM = tM - 1;
  // } else {
  //     age += (tD - bD) + ' Days '
  // }
  // {PocSt?.step1?.dob && setPocSt(
  //   produce((draftState) => {
  //     draftState.step1["chronologicalAge"] = age;
  //   })
  //     );}
  const onSubmitFamilyForm = () => {
    setIsLoading(true);
    setPocSt(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteria = [tableValues];
      })
    );
    setPocSt(
      produce((draftState) => {
        draftState.step4.previousGoals.shortTerm = [prevShortGoals];
      })
    );
    setPocSt(
      produce((draftState) => {
        draftState.step4.previousGoals.longTerm = [prevLongGoals];
      })
    );
    setPocSt(
      produce((draftState) => {
        draftState.step4.newGoals.shortTerm = [newShotGoals];
      })
    );
    setPocSt(
      produce((draftState) => {
        draftState.step4.newGoals.longTerm = [newLongGoals];
      })
    );

    console.log("........................discharge", PocSt);
    let formData = new FormData();
    console.log();
    formData.append(
      "formCreationForm",
      new Blob(
        [
          JSON.stringify({
            ...pageResult,
            jsonFormattedForm: JSON.stringify({ PocSt }),
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((response) => {
        setIsLoading(false);
        setOpen(false);
        toast.success("Form Submitted successfully");
        props.handleBack("reload");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };
  const relValue =
    typeof props?.data?.reply === "undefined"
      ? ""
      : JSON.parse(props?.data?.jsonFormattedForm);
  return (
    <>
      <>
        <div
          className={`col-md-${props?.from ? 12 : 10}`}
          id="my-code"
          ref={formRef}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: "100px",
            }}
          >
            {!props?.data?.replyNeeded && props?.data?.id ? (
              <>
                <HeaderDownloadButtons
                  isSubmit={true}
                  startDownload={startDownload}
                  setIsSubmit={setIsSubmit}
                />
              </>
            ) : (
              props?.data?.therapistSignDate && (
                <HeaderDownloadButtons
                  isSubmit={isSubmit}
                  startDownload={startDownload}
                  setIsSubmit={setIsSubmit}
                />
              )
            )}</div>
          <div className="card-lg">
            <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
              <FormHeader
                logoUrl={logoUrl}
                title="Plan of Care - Speech Therapy"
              />

              <hr />
              <br />
              <div style={{ textAlign: "center" }}>
                <label style={{ width: "auto" }}>
                  <b>SERVICE TYPE:  </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  relValue?.PocSt?.step1?.serviceType
                ) : (
                  <>
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      placeholder="Service Name"
                      name="serviceType"
                      className={`form-control form-input form-input-w-37per `}
                      defaultValue={PocSt?.step1?.serviceType}
                      onChange={(e) => changeInfo(e)}
                    />
                  </>
                )}</div>
              <div className="card-lg__content">
                <br />
                <div style={{ width: "100%", border: "1px solid" }}>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Patient Name : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.patientName
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder="Patient Name"
                          name="patientName"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocSt?.step1?.patientName}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Insurance ID : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.insuranceId
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          name="insuranceId"
                          placeholder="Insurance ID"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocSt?.step1?.insuranceId}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Chronological Age : </b>
                        {isSubmit ? (
                          relValue?.PocSt?.step1?.chronologicalAge + " Years"
                        ) : (
                          PocSt?.step1?.chronologicalAge + " Years"
                        )
                        }
                      </label>
                      &nbsp;&nbsp;
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>DOB : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.dob?.trim() &&
                        moment(PocSt.dob).format("MM/DD/YYYY")
                      ) : (
                        <div className="form-date-input">
                          <DatePicker
                            multiple={false}
                            value={dob}
                            onChange={setDate}
                            name="dob"
                            format="MM-DD-YYYY"
                            editable={false}
                          />
                        </div>

                      )}
                      {/* Math.floor(moment(new Date()).diff(moment("02/26/1978","MM/DD/YYYY"),'years',true))) */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Therapist Name : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.therapistName
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder="Therapist's name"
                          name="therapistName"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocSt?.step1?.therapistName}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Therapist NPI : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.therapistNpi
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          name="therapistNpi"
                          placeholder="Therapist NPI"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocSt?.step1?.therapistNpi}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Place of Service : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.placeOfService
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder="Place of Service"
                          name="placeOfService"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocSt?.step1?.placeOfService}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Dr. Name & NPI : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.drNameNpi
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          name="drNameNpi"
                          placeholder="Dr. Name & NPI"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocSt?.step1?.drNameNpi}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Diagnosis ICD 10 : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.diagnosisICD10
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder="Diagnosis ICD 10"
                          name="diagnosisICD10"
                          className={`form-control form-input form-input-w-50per`}
                          defaultValue={PocSt?.step1?.diagnosisICD10}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Therapist Diagnosis ICD 10 : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocSt?.step1?.therapyDiagnosisICD10
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          name="therapyDiagnosisICD10"
                          placeholder="Therapist Diagnosis ICD 10"
                          className={`form-control form-input form-input-w-50per`}
                          defaultValue={PocSt?.step1?.therapyDiagnosisICD10}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>CURRENT PLAN OF CARE PERIOD: FROM </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.PocSt?.step1?.currentPOCFromDate ? moment(relValue?.PocSt?.step1?.currentPOCFromDate).format("MM-DD-YYYY") : ''}</>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocSt?.step1?.currentPOCFromDate}
                      defaultValue={""}
                      onChange={careFrom}
                      name="currentPOCFromDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>

                )}
                <b> TO </b>
                {isSubmit ? (
                  <>{relValue?.PocSt?.step1?.currentPOCToDate ? moment(relValue?.PocSt?.step1?.currentPOCToDate).format("MM-DD-YYYY") : ''}<br /></>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocSt?.step1?.currentPOCToDate}
                      defaultValue={""}
                      onChange={careTo}
                      name="currentPOCToDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>CURRENT EVALUATION DATE: </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.PocSt?.step1?.currentEvaluationDate ? moment(relValue?.PocSt?.step1?.currentEvaluationDate).format("MM-DD-YYYY") : ''} <br /></>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocSt?.step1?.currentEvaluationDate}
                      onChange={evalFrom}
                      name="currentEvaluationDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>PREVIOUS PLAN OF CARE PERIOD: FROM </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.PocSt?.step1?.previouPOCFromDate ? moment(relValue?.PocSt?.step1?.previouPOCFromDate).format("MM-DD-YYYY") : ''}</>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocSt?.step1?.previouPOCFromDate}
                      onChange={previouFrom}
                      name="previouPOCFromDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>
                )}
                <b> TO </b>
                {isSubmit ? (
                  <>{relValue?.PocSt?.step1?.previouPOCToDate ? moment(relValue?.PocSt?.step1?.previouPOCToDate).format("MM-DD-YYYY") : ''}<br /></>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocSt?.step1?.previouPOCToDate}
                      onChange={previouTo}
                      name="previouPOCToDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>PREVIOUS EVALUATION DATE: </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.PocSt?.step1?.previousEvaluationDate ? moment(relValue?.PocSt?.step1?.previousEvaluationDate
                  ).format("MM-DD-YYYY") : ''} <br /></>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocSt?.step1?.previousEvaluationDate}
                      onChange={evalTo}
                      name="previousEvaluationDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>

                )}

                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>FREQUENCY: WEEKLY VISITS:</b>{" "}
                  {isSubmit ? (
                    <>{relValue?.PocSt?.step1?.weeklyVisit}</>
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="weeklyVisit"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                  <b> VISIT LENGTH: </b>{" "}
                  {isSubmit ? (
                    <>{relValue?.PocSt?.step1?.visitLength}</>
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="visitLength"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                  <p style={{ display: "inline-block" }}>minutes</p><br />
                </label>
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>DURATION: MONTHS:</b>{" "}
                  {isSubmit ? (
                    relValue?.PocSt?.step1?.months
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="months"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                  <b> WEEKS #: </b>{" "}
                  {isSubmit ? (
                    <>{relValue?.PocSt?.step1?.weeks}<br /></>
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="weeks"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                </label>
                <br />
                <br />
                <br />
                {isSubmit ? (
                  <>
                    <div>
                      <table className="w-100per" style={{ border: "1px solid #000",borderBottom: "0", }}>
                        <>
                          <tr style={{ border: "1px solid #000" }}>
                            <th style={{
                              width: "90%", borderRight: "1px solid",borderBottom: "1px solid",
                              paddingLeft: 5,
                            }}>
                              MEDICAL NECESSITY CRITERIA:
                            </th>
                            <th style={{ textAlign: "center",borderBottom: "1px solid", }}>YES/NO</th>
                          </tr>

                          {relValue?.PocSt?.step2?.medicalNecessityCriteria.map(
                            (element, indexs) => (
                              <>
                                <tr style={{ border: "1px solid" }}>
                                  <td
                                    style={{
                                      width: "90%",
                                      borderRight: "1px solid",
                                      borderBottom: "1px solid",
                                      paddingLeft: 5,
                                    }}
                                  >
                                    {
                                      relValue?.PocSt?.step2
                                        ?.medicalNecessityCriteria[indexs]
                                        ?.description
                                    }
                                  </td>
                                  <td style={{ textAlign: "center",borderBottom: "1px solid", }}>
                                    {
                                      relValue?.PocSt?.step2
                                        ?.medicalNecessityCriteria[indexs]
                                        ?.criteria
                                    }
                                  </td>
                                </tr>{" "}
                              </>
                            )
                          )}
                        </>
                      </table>
                    </div>
                  </>
                ) : (
                  <div style={{ width: "60%" }}>
                    <table className="w-100per"><>
                      <tr>
                        <th style={{ width: "90%" }}>MEDICAL NECESSITY CRITERIA : <button
                          type="button"
                          className="btn btn-success "
                          style={{
                            width: "45px",
                            height: "28px",
                            padding: "0px",
                            margin: "10px",
                          }}
                          onClick={() => addTableFields()}
                        >
                          +
                        </button></th>
                        <th style={{ textAlign: "center" }}>YES/NO</th>
                      </tr>
                      {tableValues.map((element, indexs) => (
                        <>
                          <tr style={{ border: "1px solid" }} key={indexs}>
                            <td
                              style={{
                                width: "90%",
                                borderRight: "1px solid",
                                paddingLeft: 5,
                              }}
                            >
                              <input
                                // ref={register({ required: true })}
                                type="text"
                                placeholder=""
                                name="description"
                                className={`form-control form-input form-input-w-60per`}
                                value={element.description || ''}
                                onChange={(e) => handleTableFields(indexs, e)}
                              />
                            </td>
                            <td style={{
                              borderRight: "1px solid",
                              paddingLeft: 5,
                              textAlign: "center"
                            }}>

                              <input
                                //   ref={register({ required: true })}
                                id={"criteriaPoc" + indexs}
                                type="text"
                                name="criteria"
                                className={`form-control form-input form-input-w-120px`}
                                defaultValue={element.criteria}
                                onChange={(e) =>
                                  handleTableFields(indexs, e)
                                }
                              />

                            </td>
                            <td><button
                              type="button"
                              className="btn btn-primary "
                              style={{
                                width: "50px",
                                height: "28px",
                                padding: "0px",
                                backgroundColor: "red",
                                marginRight: "0"
                              }}
                              onClick={() => removeTableFields(element.rowId)}
                            >
                              <b>-</b>
                            </button></td>
                          </tr>{" "}
                        </>
                      ))}</>
                    </table>
                  </div>
                )}
                <br />
                {/* DESCRIPTION */}
                <br />
                <p className="w-100per">
                  <b>
                    DESCRIPTION OF CONDITION, INCLUDING MOST SPECIFIC ICD-10
                    CODES:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step2?.specificICD10
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="specificICD10"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>ALL TREATMENT SERVICES TO BE PROVIDED TO RECIPIENT:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step2?.allTreatmentServices
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="allTreatmentServices"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>THERAPY METHODS:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step2?.therapyMethod
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="therapyMethod"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />

                <br />
                <p className="w-100per">
                  <b>MONITORING CRITERIA:</b>
                </p>

                {isSubmit ? (
                  relValue?.PocSt?.step2?.monitoringCriteria
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="monitoringCriteria"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>CURRENT MEDICATIONS:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step2?.currentMedication
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="currentMedication"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b> OTHER TREATMENTS THE RECIPIENT IS RECEIVING:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step3?.otherTreatment
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="otherTreatment"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>EQUIPMENT REQUIRED:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step3?.equipmentRequired
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="equipmentRequired"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  {isSubmit ? (<b>
                    IS THE RECIPIENT ON A SPECIALIZED DIET?{relValue?.PocSt?.step3?.recipRadio === 'Yes' || relValue?.PocSt?.step3?.recipRadio === 'No' ? <span>: &nbsp;Yes{" "}
                      <input
                        id="recipYes"
                        type="radio"
                        value="Yes"
                        name="yes_no"
                        defaultValue={""}
                        checked={relValue?.PocSt?.step3?.recipRadio === 'Yes'}
                        onChange={(e) => radioVal(e)}
                        disabled={relValue?.PocSt?.step3?.recipRadio === 'No' ? true : true}
                      />{" "}
                      No{" "}
                      <input
                        type="radio"
                        value="No"
                        name="yes_no"
                        defaultValue={""}
                        checked={relValue?.PocSt?.step3?.recipRadio === 'No'}
                        onChange={(e) => radioVal(e)}
                        disabled={relValue?.PocSt?.step3?.recipRadio === 'Yes' ? true : true}
                      />{" "}</span> : ''}
                  </b>) : (
                    <b>
                      IS THE RECIPIENT ON A SPECIALIZED DIET? : &nbsp;Yes{" "}
                      <input
                        id="recipYes"
                        type="radio"
                        value="Yes"
                        name="yes_no"
                        defaultValue={""}
                        onChange={(e) => radioVal(e)}
                      />{" "}
                      No{" "}
                      <input
                        type="radio"
                        value="No"
                        name="yes_no"
                        defaultValue={""}
                        onChange={(e) => radioVal(e)}
                      />{" "}
                    </b>
                  )}
                </p>

                {recipRadio === 'Yes' ?
                  <p className="w-100per">
                    <b>If yes, please specify:</b>
                  </p>
                  : ''}

                {isSubmit ? (
                  relValue?.PocSt?.step3?.specializedDiet?.description
                ) : (
                  <>
                    {recipRadio === 'Yes' ?
                      <input
                        // ref={register({ required: true })}
                        type="text"
                        name="description"
                        className={`form-control form-input`}
                        defaultValue={""}
                        onChange={(e) => specializedInfo(e)}
                      />
                      : ''}</>
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>
                    METHODS OF DEMONSTRATING AND TEACHING THE FAMILY AND OTHER
                    RELEVANT CAREGIVERS WHO ARE INVOLVED WITH THE RECIPIENT:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step3?.methodDemonstrating
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="methodDemonstrating"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>
                    {" "}
                    HOW THE TREATMENT WILL BE COORDINATED WITH THE OTHER SERVICE
                    NEEDS PRESCRIBED FOR THE RECIPIENT:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step3?.treatment
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="treatment"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>OTHER INFORMATION/CONCERNS (Reason For Discharge):</b>{" "}
                </p>
                {isSubmit ? (
                  relValue?.PocSt?.step3?.otherInfo
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="otherInfo"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                {/* DynamicGoals */}
                <div className="w-100per">
                  <p
                    className="w-100per"
                    style={{ textDecoration: "underline" }}
                  >
                    <b>Previous Goals:</b>{" "}
                  </p>
                  {isSubmit ? (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Long Term Goals:</b>
                    {relValue?.PocSt?.step4?.previousGoals?.longTerm?.map((element, indexs) => (
                      <div>
                        {console.log("element")}
                        <label>{indexs + 1}.</label>
                        {
                          relValue?.PocSt?.step4?.previousGoals?.longTerm[indexs].name}


                      </div>
                    ))}


                  </div>) : (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Long Term Goals : </b>{" "}<button
                      type="button"
                      className="btn btn-success "
                      style={{
                        width: "40px",
                        height: "28px",
                        padding: "0px",
                        margin: "10px"
                      }}
                      onClick={() => addPrevLongGoals()}
                    >
                      +
                    </button>
                    {prevLongGoals.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {isSubmit ? (
                          relValue?.PocSt?.step4?.previousGoals?.longTerm
                        ) : (
                          <>
                            <input
                              // ref={register({ required: true })}
                              id={"longName" + indexs}
                              type="text"
                              placeholder=""
                              name="name"
                              className={`form-control form-input form-input-w-60per`}
                              value={element.name}
                              onChange={(e) => handlePrevLongGoals(indexs, e)}
                            />
                          </>
                        )}

                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{
                            width: "105px",
                            height: "28px",
                            padding: "0px",
                            backgroundColor: "red",
                            margin: "10px"
                          }}
                          onClick={(e) => removePrevLongGoals(indexs, e)}
                        >
                          <b>-</b>
                        </button>
                      </div>
                    ))}

                    <div style={{ padding: "10px" }}>

                    </div>
                  </div>)}
                  {isSubmit ? (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Short Term Goals : </b>
                    {relValue?.PocSt?.step4?.previousGoals?.shortTerm?.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {relValue?.PocSt?.step4?.previousGoals?.shortTerm[indexs].name}
                      </div>
                    ))}

                  </div>) : (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Short Term Goals : <button
                      type="button"
                      className="btn btn-success "
                      style={{
                        width: "40px",
                        height: "28px",
                        padding: "0px",
                        margin: "10px"
                      }}
                      onClick={() => addPrevShortGoals()}
                    >
                      +
                    </button></b>
                    {prevShortGoals.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {isSubmit ? (
                          PocSt?.step4?.previousGoals?.shortTerm
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            id={"shortOldPoc" + indexs}
                            type="text"
                            placeholder=""
                            name="name"
                            className={`form-control form-input form-input-w-60per`}
                            value={element.name}
                            onChange={(e) => handlePrevShortGoals(indexs, e)}
                          />
                        )}

                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{
                            width: "105px",
                            height: "28px",
                            padding: "0px",
                            backgroundColor: "red",
                            margin: "10px"
                          }}
                          onClick={() => removePrevShortGoals(indexs)}
                        >
                          <b>-</b>
                        </button>
                      </div>
                    ))}
                  </div>)}
                  <br />
                  <br />
                  <b style={{ textDecoration: "underline" }}>New Goals:</b>
                  <br />
                  {isSubmit ? (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Long Term Goals : </b>
                    {relValue?.PocSt?.step4?.newGoals?.longTerm.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {relValue?.PocSt?.step4?.newGoals?.longTerm[indexs].name}
                      </div>
                    ))}

                  </div>) : (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Long Term Goals : <button
                      type="button"
                      className="btn btn-success "
                      style={{
                        width: "40px",
                        height: "28px",
                        padding: "0px",
                        margin: "10px"
                      }}
                      onClick={() => addNewLongGoals()}
                    >
                      +
                    </button></b>
                    {newLongGoals.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {isSubmit ? (
                          PocSt?.step4?.newGoals?.longTerm
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            id={"nameLongNew" + indexs}
                            type="text"
                            placeholder=""
                            name="name"
                            className={`form-control form-input form-input-w-60per`}
                            value={element.name}
                            onChange={(e) => handleNewLongGoals(indexs, e)}
                          />
                        )}

                        {
                          <button
                            type="button"
                            className="btn btn-primary "
                            style={{
                              width: "105px",
                              height: "28px",
                              padding: "0px",
                              backgroundColor: "red",
                              margin: "10px"
                            }}
                            onClick={() => removeNewLongGoals(indexs)}
                          >
                            <b>-</b>
                          </button>
                        }
                      </div>
                    ))}
                  </div>)}
                  {isSubmit ? (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Short Term Goals : </b>
                    {relValue?.PocSt?.step4?.newGoals?.shortTerm.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {relValue?.PocSt?.step4?.newGoals?.shortTerm[indexs].name}
                      </div>
                    ))}

                  </div>) : (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Short Term Goals : <button
                      type="button"
                      className="btn btn-success "
                      style={{
                        width: "40px",
                        height: "28px",
                        padding: "0px",
                        margin: "10px"
                      }}
                      onClick={() => addNewShotGoals()}
                    >
                      +
                    </button></b>
                    {newShotGoals.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {isSubmit ? (
                          PocSt?.step4?.newGoals?.shortTerm
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            id={"shortPoc" + indexs}
                            type="text"
                            placeholder=""
                            name="name"
                            className={`form-control form-input form-input-w-60per`}
                            value={element.name}
                            onChange={(e) => handleNewShortGoals(indexs, e)}
                          />
                        )}

                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{
                            width: "105px",
                            height: "28px",
                            padding: "0px",
                            backgroundColor: "red",
                            margin: "10px"
                          }}
                          onClick={() => removeNewShotGoals(indexs)}
                        >
                          <b>-</b>
                        </button>
                      </div>
                    ))}
                  </div>)}
                </div>
                <br />
                <label style={{ width: "auto" }}>
                  <b>SIGNATURES –</b>
                </label>

                &nbsp;&nbsp;
                <br />
                <label style={{ width: "auto", padding: "10px 0" }}>
                  <b>Patient Name : </b>
                </label>
                {isSubmit ? (
                  relValue?.PocSt?.step1?.patientName
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    placeholder="Patient Name"
                    name="patientName"
                    className={`form-control form-input form-input-w-37per`}
                    defaultValue={patientName}
                    onChange={(e) => patientInfo(e)}
                  />)}
                &nbsp;&nbsp;
                <br />
                <br/>
                <label style={{ width: "auto", padding: "10px 0" }}>
                  <b>Therapist Name : </b>
                </label>
                {isSubmit ? (
                  relValue?.PocSt?.step1?.therapistName
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    placeholder="Therapist Name"
                    name="therapistName"
                    className={`form-control form-input form-input-w-37per`}
                    defaultValue={therapistName}
                    onChange={(e) => patientInfo(e)}
                  />)}
                <br />
                <br />
                <SignDate
                  date={PocSt?.step4?.therapistSignDate || relValue?.PocSt?.step4?.therapistSignDate}
                  familyImageURL={
                    isSubmit ? props?.data?.therapistSignUrl : familyImageURL
                  }
                  isSubmit={isSubmit}
                  setOpen={setOpen}
                  signTitle={"Therapist"}
                  guardian="Therapist"
                  familyInfo={familyInfo}
                  isLoading={isLoading}
                  userType="therapist"
                />
                <DigitalSignPopup
                  modalState={open}
                  setModalState={setOpen}
                  save={save}
                  clear={clear}
                  sigCanvas={sigCanvas}
                />
                <br />
                <p className="w-100per">
                  <i>
                    By signing as the therapist, I hereby certify this plan of
                    care was written based on evaluation results and the goals
                    are reasonable and appropriate for this patient, and that if
                    this prescription is for a continuing plan, I have reviewed
                    the patient's progress and adjusted the plan of care goals
                    if necessary.
                  </i>
                </p>
                <label style={{ width: "auto", padding: "10px 0" }}>
                  <b>PHYSICIAN SIGNATURE: </b>
                </label>
                <br />
                <label style={{ width: "auto", paddingLeft: "10px 0" }}>
                  Dr ______________________
                </label>
                <br />
                <br />
                <br />
                <p>PCP Signature/Date</p>
                <br />
                <p className="w-100per">
                  <i>
                    By signing as the PCP and/or other authorized provider, I
                    hereby certify that I have reviewed each element of the
                    therapy plan of care, that the goals are reasonable and
                    appropriate for this patient, and that if this prescription
                    is for a continuing plan, I have reviewed the patient's
                    progress and adjusted the plan of care goals if necessary.
                  </i>
                </p>
                <br />
                <hr />
                <FormFooter
                  address={joinWithComma(
                    address1,
                    address2,
                    city,
                    country,
                    zipCode
                  )}
                />
                {!isSubmit ? (
                  <>
                    {errors.consent1 ||
                      (errors.consent2 && (
                        <p className="error-text">
                          <small>All fields are require</small>
                        </p>
                      ))}
                    {/* {signatureErr && (
                        <p className="text-red">
                          <small>{signatureErr}</small>
                        </p>
                      )} */}
                    <button
                      className="btn btn-theme-green"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                    {console.log(
                      "familyInfo.therapistSign",
                      familyInfo.therapistSign
                    )}
                    {/* {props?.data?.replyNeeded && ( */}
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={familyInfo.therapistSign ? false : true}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                    {/* )} */}
                  </>
                ) : (
                  <>
                    {!props?.isFormOpen && (
                      <button
                        className="btn btn-theme-green mb-10"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
}

export default PocStForm;
