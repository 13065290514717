import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminService } from "../services/adminService";
import { toast } from "react-toastify";
import adminAction from "../redux/actions/adminAction";

function Header(props) {
  let user = useSelector((state) => state.adminUser.user);
  let user1 = useSelector((state) => state.adminUser);
  let profile = useSelector((state) => state.adminUser.profile);
  let tenantInfo = useSelector((state) => state.adminUser.tenantInfo);
  const agoraUsers = useSelector((state) => state.agoraDetail?.users);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [notificationCount, setNotificationCount] = useState([]);
  const [roleName, setRoleName] = useState("");
  // const [profile, setProfile] = useState([]);

  useEffect(() => {
    adminService.getAll(`user-profiles/user-detail`).then(
      (res) => {
        if (res?.status === "Success") {
          // setProfile(res.data);
          dispatch(adminAction.setProfileData(res.data));
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    // let timer =  setInterval(() => {
    let api =
      user.userDetails.roles[0] === "ROLE_RCE"
        ? `notifications/get-new-notification-count/admin`
        : user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS"
          ? `notifications/get-new-notification-count/admin`
          : `notifications/get-new-notification-count/${user.userDetails.userUniqueId}`;
    adminService.getAllNotifications(api).then(
      (res) => {
        if (res?.status === "Success") {
          setNotificationCount(res.data);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );
    //     }, 1000);
    // clearInterval(()=>{
    //     return(timer);
    // })
  }, []);

  const afterSeen = () => {
    let api =
      user.userDetails.roles[0] === "ROLE_RCE"
        ? `notifications/get-new-notification-count/admin`
        : user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS"
          ? `notifications/get-new-notification-count/admin`
          : `notifications/get-new-notification-count/${user.userDetails.userUniqueId}`;
    adminService.getAllNotifications(api).then(
      (res) => {
        if (res.status === "Success") {
          setNotificationCount(res.data);
          history("/notifications");
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const seenNotification = () => {
    let api =
      user.userDetails.roles[0] === "ROLE_RCE"
        ? `notifications/update-notification-seen-value/admin`
        : user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS"
          ? `notifications/update-notification-seen-value/admin`
          : `notifications/update-notification-seen-value/${user.userDetails.userUniqueId}`;
    adminService.notificationSeen(api).then(
      (res) => {
        if (res.status === "Success") {
          afterSeen();
        } else {
          toast.error(res.message);
          //history("/notifications");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const redirectTo = (link) => {
    if (agoraUsers && agoraUsers.length > 0) {
      const confirm = window.confirm("Your call will be disconnected.");
      if (confirm) history(link);
    } else {
      history(link);
    }
  };

  useEffect(() => {
    const currentRoleName =
      user.userDetails.roles[0] === "ROLE_RCE"
        ? "Tenant Admin"
        : user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS"
          ? "Regional Early Steps"
          : (user.userDetails.roles[0] || "").toLowerCase().replace("role_", "");
    setRoleName(currentRoleName);
  }, [user.userDetails.roles[0]]);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <Link to='/dashboard'>
                {(tenantInfo && tenantInfo?.largeLogoUrl) ||
                  user.tenantDetails?.larlargeLogoUrlgeLogoUrl ? (
                  <img
                    className="tenant-logo-img-header"
                    src={
                      tenantInfo?.largeLogoUrl ||
                      user.tenantDetails?.larlargeLogoUrlgeLogoUrl
                    }
                    alt=""
                  />
                ) : (
                  <img
                    className="tenant-logo-img-header"
                    src="https://meisdevpublic.s3.us-east-2.amazonaws.com/meis-logo.png"
                    alt=""
                  />
                )}
              </Link>
            </div>
            <div className="col-auto">
              <div className="user-info">
                <span className="admin-name">
                  <img
                    src={
                      profile.profilePicUrl
                        ? profile.profilePicUrl
                        : "/resources/assets/defaultprofile.png"
                    }
                    className="image"
                    alt="User profile img"
                  />
                  <div className="content ml-2">
                    <sapn className="text-capitalize">
                      {/* {user.userDetails.roles[0] === "ROLE_RCE"
                        ? "Tenant Admin"
                        : user.userDetails.roles[0] ===
                          "ROLE_REGIONAL_EARLY_STEPS"
                        ? "Regional Early Steps"
                        : (user.userDetails.roles[0] || "")
                            .toLowerCase()
                            .replace("role_", "")} */}
                      {roleName == "family" ? "Caretaker" : roleName}
                    </sapn>
                    <large className="name">
                      <br />
                      {profile.firstName} {profile.lastName}
                    </large>
                    <small>
                      <br />
                      {user.userDetails.email}
                    </small>
                  </div>
                  <div className="notification-icon pl-5 mr-3">
                    <Link to="/notifications">
                      <img
                        src={"/resources/assets/bell.svg"}
                        alt="Notification"
                        onClick={seenNotification}
                      />
                    </Link>
                    <span id="group">
                      {notificationCount === 0 ? (
                        <></>
                      ) : (
                        <span className="badge badge-light">
                          {notificationCount}
                        </span>
                      )}
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
