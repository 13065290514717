import React from "react";
import classNames from "classnames";
import { appStatusList, capitalString } from "../utils/commonFunctions";
import { ReactSVG } from "react-svg";

const StatusBadge = ({ status, ...props }) => {
  if (!status) {
    return null;
  }
  const cStatus = appStatusList.find((st) => st.name === status);
  const displayStatus = capitalString(cStatus.label.toLowerCase()).replaceAll(
    "_",
    " "
  );
  let image = cStatus.img;
  const imgAr = image.split(".");
  const isSvg = imgAr[imgAr.length - 1] === "svg";
  return (
    <span
      {...props}
      className={classNames("badge badge-primary session-status", {
        [`s-${cStatus.className}`]: cStatus.className,
      })}
    >
      {image &&
        (isSvg ? (
          <ReactSVG wrapper="span" src={`/resources/assets/${image}`} />
        ) : (
          <img className="" alt={status} src={`/resources/assets/${image}`} />
        ))}
      {cStatus.label || displayStatus}
    </span>
  );
};

export default StatusBadge;
