import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { adminService } from "../../services/adminService";

//import CreateForm from "../admin/CreateForm";

function CreateForm() {
  const user = localStorage.getItem("user");
  const userUniqeId = JSON.parse(user)?.userDetails?.userUniqueId;

  const [initialData, setInitialData] = useState({
    familyLoading: false,
    formLoading: false,
    loading: false,
    data: "",
    error: "",
  });

  const [replyNeeded, setReplyNeeded] = useState(false);
  const [inputs, setInputs] = useState({
    formId: "",
    familyMemberId: "",
    sentBy: userUniqeId,
  });

  const history = useNavigate();
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [familyList, setFamilyList] = useState([]);
  const [formNameList, setFormNameList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "familyMemberId") {
      const family_id = document.getElementById(value).value;
      setInputs((item) => ({
        ...item,
        familyId: family_id,
        sentTo: family_id,
        [name]: value,
      }));
    } else {
      setInputs((item) => ({ ...item, [name]: value }));
    }

    if (name === "formId" && (value === 3 || value === 7)) {
      setReplyNeeded(false);
    } else {
      setReplyNeeded(true);
    }
  };

  const onSubmit = (data) => {
    console.log("form data 1", data);
    //history({ pathname: "/form-setups", state: inputs });

    setInitialData((item) => ({ ...item, loading: true }));

    let formData = new FormData();
    // formData.append("signature", signatures);
    console.log("inputs", inputs);
    formData.append(
      "formCreationForm",
      new Blob([JSON.stringify(inputs)], {
        type: "application/json",
      })
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((res) => {
        //alert("ok");
        console.log("submitted-forms", res);
        setInitialData((item) => ({ ...item, loading: true }));
        if (res.status === "Success") {
          alert("ok");
          toast.success(res?.message);
          history("/form-list");
        } else {
          alert("error");
          //toast.error(res?.message);
          throw new errors();
        }

        // setIsFormOpen(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.message || "Something went wrong");
      });
  };

  const getFamilyNameList = () => {
    setInitialData((item) => ({ ...item, familyLoading: true }));
    adminService.getAll("families/withoutfilter").then(
      (res) => {
        if (res?.status === "Success") {
          setFamilyList(res.data);
          setInitialData((item) => ({ ...item, familyLoading: false }));
        } else {
          res?.message
            ? toast.error(res?.message)
            : toast.error("Something went wrong");
          setInitialData((item) => ({ ...item, familyLoading: false }));
        }
      },
      (error) => {
        toast.error(error);
        setInitialData((item) => ({ ...item, familyLoading: false }));
      }
    );
  };

  const getFormNameList = () => {
    setInitialData((item) => ({ ...item, formLoading: true }));
    adminService.getAll("adminForms").then(
      (res) => {
        if (res?.status === "Success") {
          setFormNameList(res.data.content);
          setInitialData((item) => ({ ...item, formLoading: false }));
        } else {
          res?.message
            ? toast.error(res?.message)
            : toast.error("Something went wrong");
          setInitialData((item) => ({ ...item, formLoading: false }));
        }
      },
      (error) => {
        toast.error(error);
        setInitialData((item) => ({ ...item, formLoading: false }));
      }
    );
  };

  useEffect(() => {
    getFamilyNameList();
    getFormNameList();
  }, []);

  return (
    <>
      <div className="col-md-10">
        <div className="no-padding">
          <div className="custom-table-view">
            <div className="row mb-10">
              <div className="col-md-12">
                <div className="text-left header-title">Creat form</div>
              </div>

              {initialData.familyLoading || initialData.formLoading ? (
                <div className=" ml-3 mt-5">
                  <span className="loader"></span>
                </div>
              ) : (
                <div className="col-md-12">
                  <form
                    className="add-session-form form-list"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-10">
                          <label>Family</label> (
                          <span className="required-span">*</span>)
                          <div className="input-group">
                            <select
                              {...register('familyMemberId',{ onChange:(e) => handleChange(e),required: true })}
                              className={`custom-select ${
                                errors.familyMemberId && "is-invalid"
                              }`}
                              //onChange={(e) => handleChange(e)}
                              name="familyMemberId"
                            >
                              <option value="">Select family</option>
                              {familyList &&
                                familyList.map((info, index) => (
                                  <>
                                    {info.familyMembers &&
                                      info.familyMembers.map((minfo, index) => (
                                        <>
                                          <input
                                            type="hidden"
                                            id={minfo.id}
                                            value={info.id}
                                          />
                                          <option value={minfo.id}>
                                            {info.userProfile.firstName +
                                              " " +
                                              info.userProfile.lastName}
                                            /
                                            {minfo.firstName +
                                              " " +
                                              minfo.lastName}
                                          </option>
                                        </>
                                      ))}
                                  </>
                                ))}
                            </select>
                            <div className="input-group-prepend">
                              <img
                                className="m-auto"
                                src="../resources/assets/Polygon 1.svg"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-10">
                          <label>Form</label> (
                          <span className="required-span">*</span>)
                          <div className="input-group">
                            <select
                              {...register('formId',{ onChange:(e) => handleChange(e),required: true })}
                              className={`custom-select ${
                                errors.formId && "is-invalid"
                              }`}
                              //onChange={(e) => handleChange(e)}
                              name="formId"
                            >
                              <option value="">Select Form</option>
                              {formNameList &&
                                formNameList.map((info, index) => (
                                  <>
                                    <option value={info.id}>{info.name}</option>
                                  </>
                                ))}
                            </select>
                            <div className="input-group-prepend">
                              <img
                                className="m-auto"
                                src="../resources/assets/Polygon 1.svg"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {replyNeeded && (
                        <div className="col-md-12 mb-10">
                          <input
                            type="checkbox"
                            name="replyNeeded"
                            // {...register('replyNeeded',{ onChange:(e) => handleChange(e),required: false })}
                            className={`${errors.replyNeeded && "is-invalid"}`}
                            //defaultValue={inputs.replyNeeded}
                            // onChange={() =>
                            //   setInputs((inputs) => ({
                            //     ...inputs,
                            //     replyNeeded: !inputs.replyNeeded,
                            //   }))
                            // }
                           onChange={(e) => handleChange(e)}
                          />{" "}
                          Need Reply?
                        </div>
                      )}

                      <div className="col-md-12">
                        <button
                          className="btn btn-theme-green mb-10"
                          onClick={() => history("/therapist-form-list")}
                          type="button"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-theme-green mb-10"
                        >
                          {initialData.loading ? "loading.." : "Send"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateForm;
