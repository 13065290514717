import { wrap } from "lodash";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Switch, Link, useLocation } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { getHoursFromSecond } from "../../utils/commonFunctions";
import moment from "moment-timezone";
import { monthJson } from "../../config/Config";
import { useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import BillingChart from "../BillingChart";
import { paginationDisplayCount } from "../../config/Config";
import { AiOutlineEye } from "react-icons/ai";

function BillingInfo() {
  let user = useSelector((state) => state.adminUser.user);
  let user_role = user.userDetails.roles[0];
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [billingInfo, setBillingInfo] = useState({});
  const [totalBilling, setTotalBilling] = useState({});
  const [billingInfoListing, setBillingInfoListing] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(0);
  const [totalpages, setTotalPages] = useState([]);

  //const dispatch = useDispatch();

  useEffect(() => {
    tenantPemission?.billing == false && history("/dashboard");
  }, [tenantPemission?.billing]);

  //pagination
  const pageNumbers = [];

  for (let i = 0; i <= totalpages - 1; i++) {
    pageNumbers.push(i);
  }

  const paginate = (number) => {
    setCurrentPage(number);
  };
  const paginatePre = () => {
    setCurrentPage(currentPage - 1);
  };
  const paginateNext = () => {
    setCurrentPage(currentPage + 1);
  };
  //end

  useEffect(() => {
    getData();
  }, [currentPage, paginationDisplayCount]);

  const getData = () => {
    let sessionFromDateTime =
      moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
    let sessionToDateTime = moment(endDate).format("YYYY-MM-DD") + "T23:59:59";
    let qry_param_filter = `page=${currentPage}&size=${paginationDisplayCount}`;
    setloadings(true);
    // let qry_param_filter = `&name=${props.searchname}&startFromDateTime=${props.startFromDateTime}&startToDateTime=${props.endToDateTime}`
    let api =
      user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS"
        ? `session-billings/rce?${qry_param_filter}`
        : user_role === "ROLE_THERAPIST"
          ? `session-billings/therapist?${qry_param_filter}`
          : `session-billings/interpreter?${qry_param_filter}`;

    adminService.getAll(`${api}&sort=createdDate,DESC`).then(
      (res) => {
        if (res.status === "Success") {
          //setDashboard(res.data);
          setBillingInfoListing(res.data.content);
          setTotalPages(res.data.totalPages);
        } else {
          toast.error(res.message);
        }
        setloadings(false);
      },
      (error) => {
        console.log(error);
      }
    );

    (user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
      adminService.getAll(`session-billings/dashboard`).then(
        (res) => {
          if (res.status === "Success") {
            setBillingInfo(res.data);
            setTotalBilling(
              res.data.paymentDone +
              res.data.paymentPending +
              res.data.pendingSubmissionToFederalOffice
            );
          } else {
            toast.error(res.message);
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const search = () => {
    let sessionFromDateTime =
      moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
    let sessionToDateTime = moment(endDate).format("YYYY-MM-DD") + "T23:59:59";
    let qry_param_filter = `name=${searchName}&billingStatus=${status}&billingUser=${userRole}&sessionFromDateTime=${sessionFromDateTime}&sessionToDateTime=${sessionToDateTime}&page=${currentPage}&size=${paginationDisplayCount}`;
    setloadings(true);

    let api =
      user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS"
        ? `session-billings/rce?${qry_param_filter}&sort=createdDate,DESC`
        : user_role === "ROLE_THERAPIST"
          ? "session-billings/therapist"
          : "session-billings/interpreter";

    adminService.getAll(api).then(
      (res) => {
        if (res.status === "Success") {
          //setDashboard(res.data);
          setBillingInfoListing(res.data.content);
          setTotalPages(res.data.totalPages);
        } else {
          toast.error(res.message);
        }
        setloadings(false);
      },
      (error) => {
        console.log(error);
      }
    );

    (user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
      adminService.getAll(`session-billings/dashboard`).then(
        (res) => {
          if (res.status === "Success") {
            setBillingInfo(res.data);
          } else {
            toast.error(res.message);
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const SubmitApproval = (id, status) => {
    console.log(id, status);
    let url = `session-billings/${id}/${status}`;

    adminService.PATCH(url).then(
      (res) => {
        if (res.status === "Success") {
          console.log(res.data);
          //setDashboard(res.data);
          //setBillingInfoListing(res.data.content);
          //setTotalPages(res.data.totalPages);
          toast.error(res.message);
        } else {
          toast.error(res.message);
        }
        setloadings(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  function currencyFormat(currency) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency);
  }
  return (
    <div className="col-md-10 no-padding d-flex flex-column">
      <div className="no-padding dash-billing-info mr-0 flex-grow-1">
        <div className="custom-table-view min-h-100">
          <div className="content-header">
            <div className="text-left header-title">{user_role === 'ROLE_THERAPIST' ? 'Therapist' : 'Interpreter'} Billing Info  </div>
          </div>
          <div className="p-2">
            {(user_role === "ROLE_RCE" ||
              user_role === "ROLE_REGIONAL_EARLY_STEPS") && (
                <>
                  {" "}
                  <div
                    className={
                      totalBilling === 0
                        ? "dashboard-billing-count row rounded-0 pt-0 pb-4"
                        : "dashboard-billing-count row rounded-0 pt-3 pb-3"
                    }
                  >
                    {totalBilling === 0 ? (
                      <></>
                    ) : (
                      <div className="col-md-3 mb-3">
                        <BillingChart billingInfo={billingInfo} />
                        {/* <div className="donut-inner">
                                    <h5 style={{ color: 'white' }}>$000</h5>
                                </div> */}
                      </div>
                    )}
                    <div className={totalBilling === 0 ? "col-md-4" : "col-md-3"}>
                      <div className="item">
                        <div className="content billing-blue-count">
                          <div className="billing-count payment-submit">
                            {currencyFormat(
                              billingInfo.pendingApprovalCount
                                ? billingInfo.pendingApprovalCount
                                : 0
                            )}
                          </div>
                          <div className="billing-title">
                            Pending Submission to State/Federal Office
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={totalBilling === 0 ? "col-md-4" : "col-md-3"}>
                      <div className="item">
                        <div className="content billing-blue-count">
                          <div className="billing-count payment-pending">
                            {currencyFormat(
                              billingInfo.paymentPending
                                ? billingInfo.paymentPending
                                : 0
                            )}
                          </div>
                          <div className="billing-title">Payment Pending</div>
                        </div>
                      </div>
                    </div>
                    <div className={totalBilling === 0 ? "col-md-4" : "col-md-3"}>
                      <div className="item">
                        <div className="content billing-blue-count">
                          <div className="billing-count payment-done">
                            {currencyFormat(
                              billingInfo.paymentDone
                                ? billingInfo.paymentDone
                                : 0
                            )}
                          </div>
                          <div className="billing-title">Payment Done</div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row mb-4">
                    <div className="col-md-3 padding-right-0">
                      <input
                        name="search"
                        className="billing-info-search form-control h-46"
                        onChange={(e) => setSearchName(e.target.value)}
                        placeholder="Search by Client Name"
                        autofocus
                      />
                    </div>

                    {/* <div className="col-auto filter-groups">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <button className="btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img className="" src="../resources/assets/filter-icon-user.svg" />
                                            <span>Therapist</span>
                                            <img className="pl-2" src="../resources/assets/filter-icon-arrow-down.svg" />
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="javascript:void()">Dr. Jhon Deo</a>
                                            <a className="dropdown-item" href="javascript:void()">Michael Doe</a>
                                            <a className="dropdown-item" href="javascript:void()">Dr. Peter Siddle</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    <div className="col-md-2 padding-right-0">
                      <select
                        className="form-control billing-dropdown"
                        onClick={(e) => setUserRole(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option className="form-control" value="ROLE_THERAPIST">
                          Therapist
                        </option>
                        <option className="form-control" value="ROLE_INTERPRETER">
                          Interpreter
                        </option>
                      </select>
                    </div>
                    {/* <div className="col-auto filter-groups"> */}
                    {/* <div className="input-group">
                                    <div className="input-group-prepend">
                                        <button className="btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img className="" src="../resources/assets/filter-icon-dots.svg" />
                                            <span>Pending</span>
                                            <img className="pl-2" src="../resources/assets/filter-icon-arrow-down.svg" />
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="javascript:void()">Complete</a>
                                        </div>
                                    </div>
                                </div> */}
                    {/* </div> */}
                    <div className="col-md-3 padding-right-0">
                      <select
                        className="form-control billing-dropdown"
                        onClick={(e) => setStatus(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option
                          className="form-control"
                          value=""
                        >
                          All
                        </option>
                        <option
                          className="form-control"
                          value="PENDING_APPROVAL"
                        >
                          Pending Approval
                        </option>
                        <option
                          className="form-control"
                          value="PAYMENT_PENDING"
                        >
                          Payment Pending
                        </option>
                        <option className="form-control" value="PAID">
                          Paid
                        </option>
                        <option className="form-control" value="VOID">
                          Void
                        </option>
                        <option className="form-control" value="PAST_DUE">
                          Past Due
                        </option>
                        <option className="form-control" value="PAYMENT_IN_PROGRESS">Payment in Progress</option>
                        <option className="form-control" value="PAYMENT_FAILED">Payment Failed</option>
                      </select>
                    </div>

                    {/* <div className="col-auto filter-groups">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <button className="btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img className="" src="../resources/assets/filter-icon-calendar.svg" />
                                                <span>Start Date</span>
                                                <img className="pl-2" src="../resources/assets/filter-icon-arrow-down.svg" />
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                    <div className="col-auto padding-right-0">
                      {/* <label>Start Date</label> */}
                      <div className="d-flex ">
                        <div className="input-group">
                          <DatePicker
                            onChange={setStartDate}
                            value={startDate}
                            name="startDate"
                            className="datepickercal"
                          />
                        </div>
                      </div>
                      <div className="time ml-2">Start Date</div>
                    </div>
                    {/* <div className="col-auto filter-groups">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <button className="btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img className="" src="../resources/assets/filter-icon-calendar.svg" />
                                                <span>End Date</span>
                                                <img className="pl-2" src="../resources/assets/filter-icon-arrow-down.svg" />
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                    <div className="col-auto padding-right-0">
                      {/* <label>End Date</label> */}
                      <div className="d-flex">
                        <div className="input-group">
                          <DatePicker
                            onChange={setEndDate}
                            value={endDate}
                            name="endDate"
                            className="datepickercal"
                          />
                        </div>
                      </div>
                      <div className="time ml-2">End Date</div>
                    </div>

                    <div className="col-auto">
                      {/* <button className="btn custom-btn-green" style={{width:130,height:50}} type="submit" onClick={search}> Search</button> */}
                      <button
                        className="btn btn-theme-green"
                        type="submit"
                        onClick={search}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="32"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </>
              )}

            <div className="row mt-2 mb-2">
              <div className="col-md-8">
                <span>
                  Here are the payment details and invoices of therapist
                </span>
              </div>
              {/* <div className="col-auto">
                                    <div className="billing-info-nxtpre btn-group filter-next-prev" role="group">
                                        <button className="btn next" onClick={() => paginatePre()} type="button" >
                                            <img className="pr-2" src="../resources/assets/arrow-left.svg" />
                                        </button>
                                        <div className="action-devider-button"></div>
                                        <button className="btn prev" onClick={() => paginateNext()} type="button">
                                            <img className="pl-2" src="../resources/assets/arrow-right.svg" />
                                        </button>
                                    </div>
                                </div> */}
            </div>

            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Session Date</th>
                    <th scope="col">Client Name</th>
                    {user_role === "ROLE_RCE" && (
                      <th scope="col">Therapist/Interpreter</th>
                    )}

                    <th scope="col">Therapy</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Total</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                {loadings ? (
                  <div className="loader"></div>
                ) : (
                  <tbody>
                    {billingInfoListing &&
                      billingInfoListing.map((item, index) => (
                        <tr>
                          {/* <td><span className="name-table name">{monthJson[parseInt(moment(item.sessionDateTime).format("MM"))].short + " " + moment(item.sessionDateTime).format("DD") + ", " + moment(item.sessionDateTime).format("YYYY")}</span></td> */}
                          <td>
                            <span className="name-table name">
                              {moment.utc(item.sessionDateTime).utc().local().format("DD MMMM, yyyy")}<br />
                              {moment.utc(item.sessionDateTime).utc().local().format("hh:mm A")}
                            </span>
                          </td>
                          <td>
                            <span className="name-table">
                              {item?.familyMemberName?.[0]}
                            </span>
                          </td>
                          {user_role === "ROLE_RCE" && (
                            <td>
                              <span className="name-table">
                                {item?.therapistInterpreterName}
                              </span>
                            </td>
                          )}

                          <td>
                            <span className="name-table">
                              {item.therapyDto.name}
                            </span>
                          </td>
                          <td>
                            <span className="name-table name">
                              {" "}
                              {getHoursFromSecond(item.durationInSeconds)}
                            </span>
                          </td>
                          <td>
                            <span className="name-table name">
                              {currencyFormat(item.totalBilling)}
                            </span>
                          </td>

                          <td className="fd-flex">
                            {item?.billingStatus === "PAID" && (
                              <span className="badge badge-primary session-status s-completePay ">
                                <img
                                  className=""
                                  src="../resources/assets/approve-icon-button.svg"
                                />{" "}
                                Paid
                              </span>
                            )}
                            {item?.billingStatus === "PENDING_APPROVAL" && (
                              <span className="badge badge-primary session-status s-approve">
                                <img
                                  className=""
                                  src="../resources/assets/pending-icon-button.svg"
                                />{" "}
                                Approval Pending
                              </span>
                            )}
                            {/* {var a = moment(item?.overDueDate);
                                                        var b = moment().format("YYYY-MM-DD");
                                                        moment(item?.overDueDate).diff(moment().format("YYYY-MM-DD"), 'days')} */}
                            {item?.billingStatus ===
                              "PAYMENT_PENDING" && (
                                <span className={moment(item?.overDueDate).diff(moment().format("YYYY-MM-DD"), 'days') < 0 ? "badge badge-primary session-status s-cancelled " : "badge badge-primary session-status s-missed "}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14.042" height="14.042" viewBox="0 0 14.042 14.042">
                                    <g>
                                      <g>
                                        <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" d="M16.042 9.521A6.521 6.521 0 1 1 9.521 3a6.521 6.521 0 0 1 6.521 6.521z" transform="translate(.5 .5) translate(-3 -3)" />
                                      </g>
                                    </g>

                                    <path fill="currentcolor" d="M17.011 7.662a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.912zm0 5.47a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.912zm0-2.735a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.909z" transform="translate(-9.187 -3.75)" />
                                  </svg>{" "}
                                  Payment Pending
                                </span>
                              )}
                            {item?.billingStatus ===
                              "VOID" && (
                                <span className="badge badge-primary session-status s-hide ">
                                  <img
                                    className=""
                                    src="../resources/assets/cancel.svg"
                                  />{" "}
                                  Void
                                </span>
                              )}
                            {item?.billingStatus ===
                              "PAST_DUE" && (
                                <span className="badge badge-primary session-status s-cancelled ">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                  </svg>{" "}
                                  Past Due
                                </span>
                              )}
                            {item?.billingStatus ===
                              "WAIVED_OFF" && (
                                <span className="badge badge-primary session-status s-hide ">
                                  <img
                                    className=""
                                    src="../resources/assets/cancel.svg"
                                  />{" "}
                                  Waived Off
                                </span>
                              )}
                            {item?.billingStatus ===
                              "PAYMENT_IN_PROGRESS" && (
                                <span className="badge badge-primary session-status s-missed ">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                  </svg>{" "}
                                  Auto Pay in Progress
                                </span>
                              )}
                            {item?.billingStatus ===
                              "PAYMENT_FAILED" && (
                                <span className="badge badge-primary session-status s-cancelled ">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                                  </svg>{" "}
                                  Auto Pay Failed
                                </span>
                              )}
                            {item?.billingStatus ===
                              "UNCOLLECTIBLE" && (
                                <span className="badge badge-primary session-status s-hide ">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                                  </svg>{" "}
                                  Uncollectible
                                </span>
                              )}
                          </td>
                          {/* <td>
                            {item.billingStatus === "PENDING_SUBMISSION" && (
                              <div className="btn-group dropleft ml-3">
                                <Link
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="../resources/assets/Icon ionic-md-more.svg" />
                                </Link>
                                <div
                                  className="dropdown-menu"
                                  style={{
                                    width: 148,
                                  }}
                                >
                                  <Link
                                    className="dropdown-item"
                                    to={"edit-billing-info/" + item?.id}
                                  >
                                    <img
                                      className="pr-2"
                                      src="../resources/assets/action-icon-edit.svg"
                                    />
                                    Edit
                                  </Link>
                                  <div className="dropdown-divider"></div>
                                  <Link
                                    to={"billing-info/" + item?.id}
                                    className="dropdown-item"
                                  >
                                    <AiOutlineEye
                                      size={20}
                                      color="#5187eb"
                                    ></AiOutlineEye>{" "}
                                    View
                                  </Link>
                                  <div className="dropdown-divider"></div>
                                  <button
                                    className="btn button-withicon-green ml-2"
                                    type="submit"
                                    block={true}
                                  >
                                    Submit for Approval
                                  </button>
                                </div>
                              </div>
                            )} 
                          </td>*/}
                        </tr>
                      ))}

                    {/* <tr>
                                        <td><span className="name-table name">Aug 12, 2020</span></td>
                                        <td><span className="name-table">Roy Baristow</span></td>
                                        <td><span className="name-table">Occupational  Therapy</span></td>
                                        <td><span className="name-table name"> 1:40 Hrs</span></td>
                                        <td><span className="name-table name">$140</span></td>

                                        <td className="fd-flex">
                                            <span className="badge badge-primary session-status s-pending"><img className="" src="../resources/assets/pending-icon-button.svg" /> Pending</span>
                                        </td>
                                        <td>
                                            <div className="btn-group dropleft ml-3">
                                                <Link data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src="../resources/assets/Icon ionic-md-more.svg" />
                                                </Link>
                                                <div className="dropdown-menu">
                                                    <Link className="dropdown-item"><img className="pr-2" src="../resources/assets/action-icon-edit.svg" />Edit</Link>
                                                    <div className="dropdown-divider"></div>
                                                    <Link className="dropdown-item"><img className="pr-2" src="../resources/assets/action-icon-delete.svg" />Delete</Link>
                                                    <div className="dropdown-divider"></div>
                                                    <button className="btn button-withicon-green ml-2" type="submit" block={true}>Submit for Approval</button>
                                                </div>
                                            </div>
                                        </td>

                                    </tr> */}
                  </tbody>
                )}
                {billingInfoListing && billingInfoListing.length == 0 && (
                  <tr>
                    <td colSpan="7">
                      <h5 className="text-center p-3">No detail available</h5>
                    </td>
                  </tr>
                )}
              </table>
            </div>
            {loadings ? (
              <></>
            ) : (
              <>
                {/* Pagination */}
                <nav>
                  <ul className="pagination justify-content-center">
                    <li onClick={() => paginatePre()} className="page-item">
                      {currentPage <= 0 ? (
                        <></>
                      ) : (
                        <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                          <img
                            className="pr-2"
                            src="../resources/assets/arrow-left.svg"
                          />
                        </div>
                      )}
                    </li>
                    {pageNumbers.map((number) => (
                      <li
                        key={number}
                        onClick={() => paginate(number)}
                        className="page-item"
                      >
                        <div
                          className={`${currentPage === number
                            ? "pagination-active page-link pagination"
                            : "page-link pagination cursor-pointer"
                            }`}
                        >
                          {number + 1}
                        </div>
                      </li>
                    ))}
                    <li onClick={() => paginateNext()} className="page-item">
                      {currentPage >= totalpages - 1 ? (
                        <></>
                      ) : (
                        <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                          <img
                            className="pr-2"
                            src="../resources/assets/arrow-right.svg"
                          />
                        </div>
                      )}
                    </li>
                    <li>
                      <button
                        className="btn btn-success ml-3"
                        style={{ borderRadius: "0.25rem" }}
                        //onClick={() => setfilter("archive")}
                        onClick={() => history.push("archive-billing-info")}
                      >
                        Archive
                      </button>
                    </li>
                  </ul>
                </nav>
                {/* End */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingInfo;