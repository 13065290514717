import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { adminService } from "../services/adminService";
import { useForm } from "react-hook-form";
import { adminActions } from '../actions'

import { toast } from 'react-toastify';

function ForgotPassword() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);



    const onSubmit = () => {

        adminService.forgotPass(`auth/forgot-password/${email}`).then(
            res => {
                if (res.status === "Success") {
                    toast.success(res.message);
                    setSuccess(true)
                }
                else {
                    toast.error(res.message);
                }
            },
            error => {
                console.log(error)
            }
        );

    }



    return (
        <>
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center login-page">
                    <div className="col-md-6 no-padding p-0">
                        <div className="log-wrapper-left">
                            <img src='/resources/assets/Mask Group 2.png' className="mask-login-left" />
                            <img src='/resources/assets/MIS-1.png' className="login-logo" />
                        </div>
                        <img src='/resources/assets/pass-left.png' className="left-img img-fluid" />
                        <img src='../resources/assets/MIS-1.png' className="copyright-image " />
                            <span className="copyright-text">Copyright @RCE 2021</span>
                    </div>
                    <div className="col-md-6 no-padding">
                        <div className="row valign_wrapper padd-b-60-all ">
                        <div className="custom-table-view-login">
                            <div className="col-lg-12 min-hei-250-all">
                                <div className="text-center login">
                                    {success ?
                                        <>
                                            <p className="email-send-success">Email has been sent successfully, please check your email and follow instruction to reset your password.</p>
                                            <Link to="/login">
                                                Click here to return to the login page
                                        </Link>
                                        </> :
                                        <div className="form-signin-wrapper">
                                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="custom-form form-custome-elements" >
                                                <h1 className="h3 mb-3 font-weight-normal text-left">Forgot Password?</h1>
                                                <div className="form-group">
                                                    <input
                                                        name="email"
                                                        //onChange={e => setEmail(e.target.value)}
                                                        // value={email} 
                                                        defaultValue={email}
                                                        type="email"
                                                        id="email"
                                                        className={`form-control ${errors.email && 'is-invalid'}`}
                                                        placeholder="Enter your registered email"
                                                        {...register('email',{onChange:(e) => setEmail(e.target.value), required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                                        autoFocus />

                                                    {errors.email?.type === "required" && (<div className="invalid-feedback-custom text-left">Email address is required</div>)}
                                                    {errors.email && errors.email.type === "pattern" && (<div className="invalid-feedback-custom text-left">Please enter valid email address</div>)}

                                                </div>
                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-auto">
                                                        <button className="btn custom-btn-green" type="submit">Submit</button>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="checkbox">
                                                            <Link to={`/login`}>
                                                                <u>Cancel</u>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }
                                </div>
                            </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;