import React, { createContext, useContext, useReducer, useState } from "react";

export const FormContext = createContext({});

export const FormProvider = ({ children }) => {
  const [signatures, setSignatures] = useState(null);
  const [agoraInfo, setAgoraInfo] = useState({});
  const [inputs, setInputs] = useState({
    formId: null,
    sentBy: null,
    sentTo: null,
    replyNeeded: false,
    reply: false,
    therapistId: null,
    familyId: null,
    familyMemberId: null,
    InterpreterId: null,
    adminSignDate: null,
    therapistSignSignDate: null,
    familySignDate: null,
    interpreterSignDate: null,
    witnessSignDate: null,
    consent1: null,
    consent2: null,
    consent3: null,
    consent4: null,
    consent5: null,
  });

  const value = {
    inputs,
    setInputs,
    signatures,
    setSignatures,
    agoraInfo,
    setAgoraInfo,
  };
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export function useFormContext() {
  return useContext(FormContext);
}
