import React from 'react';
import ReactDOM from "react-dom/client";
import './index.scss'; 
import App from './App';
import reportWebVitals from './reportWebVitals'
;
import ReduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import rootReducer from './redux/reducers'
import { createStore, applyMiddleware, compose } from "redux";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const composeEnhancers = (
  typeof window !== 'undefined' && process.env.NODE_ENV !== "production" && 
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) || compose
const store = createStore(
  rootReducer,
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeEnhancers(applyMiddleware(ReduxThunk))
)
const root = ReactDOM.createRoot(document.getElementById("root"));
export const appReduxStore = store;
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer />
      <App />
    </React.StrictMode>
  </Provider>
  //document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
