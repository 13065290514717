import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { adminService } from "../../../services/adminService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "../FormContext";
import Pagination from "../../Pagination";
import FormTable from "../admin/FormTable";
import CreateForm from "../admin/CreateForm";
import DischargeSummery from "./DischargeSummery";
import FormList from "./FormList";
import ConsultationDocumentationForm from "../admin/ConsultationDocumentationForm";
import ParentProvider from "../other/ParentProvider";
import QuaterlyProgressForm from "../admin/QuaterlyProgressForm";
import PocStForm from "../admin/PocStForm";
import PocPtForm from "../admin/PocPtForm";
import PocDaltonForm from "../admin/PocDaltonForm";
import PocOtForm from "../admin/PocOtForm";
import SampleEvaluation from "./SampleEvaluation";
import AuthToDiscoloseInfo from "../other/AuthToDiscoloseInfo";
import ConsentToTreat from "../admin/ConsentToTreat";
import MedicalInsuranceForm from "../admin/MedicalInsuranceForm";
import HippaNoticeForm from "../admin/HippaNoticeForm";
import CancellationForm from "../admin/CancellationForm";
import ReleaseToCommunicateForm from "../admin/ReleaseToCommunicateForm";
import MediaReleaseForm from "../admin/MediaReleaseForm";
import Acknowledgement from "../other/Acknowledgement";
import ChangeInCoverage from "../other/ChangeInCoverage";
import MedicoidForm from "../admin/MedicoidForm";
import DatePicker, { DateObject } from "react-multi-date-picker";
import moment from "moment";

function ArchiveTherapistFormList() {
    const history = useNavigate();
    let user = useSelector((state) => state.adminUser.user);
    let profile = useSelector((state) => state.adminUser.profile);
    const { inputs, setInputs, signatures, setSignatures } = useFormContext();

    const [currentPage, setCurrentPage] = useState(0);
    const { register, errors, handleSubmit } = useForm();
    const [familyList, setFamilyList] = useState([]);

    const [formNameList, setFormNameList] = useState([]);
    const [pageDetail, setPageDetail] = useState({ page: 0, totalpages: 0 });
    const [submittedFormList, setSubmittedFormList] = useState({
        isLoading: true,
        list: [],
    });
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [family, setFamily] = useState({});
    const [createForm, setCreateForm] = useState(false);
    const [selectedFamily, setselectedFamily] = useState({});
    const [disciplines, setDisciplines] = useState('');
    const [serviceType, setServiceType] = useState();
    const [userType, setUserType] = useState();
    const [archiveFormData,setArchiveFormData]=useState([]);
    const [isDownloadBtn, setDownloadBtn] = useState(false);
    const [flag, setFlag] = useState(false);
    const [values, setValues] = useState([
        new DateObject().subtract(5, "days"),
        new DateObject()
    ])
    let jsonDates = JSON.stringify(values[0]);
    let jsonDatesTo = JSON.stringify(values[1]);
    let date = moment(parseInt(jsonDates)).format('YYYY-MM-DD');
    let dateTo = moment(parseInt(jsonDatesTo)).format('YYYY-MM-DD');
    const dateto = moment(parseInt(jsonDates)).format('MM/DD/YYYY');
    const datefrom = moment(parseInt(jsonDatesTo)).format('MM/DD/YYYY');
    // const diffTime = Math.abs(date1 - date2);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    const date1 = new Date(dateto);
    const date2 = new Date(datefrom);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffTime + " milliseconds");
    console.log(diffDays + " days");
    const getArchiveApi = `startDateTime=${date}T00:00:00Z&endDateTime=${dateTo}T00:00:00Z&formId=1&familyMemberId=1`;
    useEffect(() => {
        const url = `archive_forms/filter?${getArchiveApi}`;
        //console.log("filter", filter);
        if (values.length === 2) {
          adminService
            .getAll(url)
            .then((res) => {
              //setDownloadBtn(false);
              if (res?.data) {
                setArchiveFormData(res?.data?.content);
                console.log("form lsit archive",res?.data?.content);
                // setPageInfo({
                //   totalPages: res?.data?.totalPages,
                //   page: res?.data?.number,
                // });
                if(res?.data?.content){
                setDownloadBtn(true);
              }
              }
            })
            .catch((e) => console.log("e", e));
        }
      }, [getArchiveApi,archiveFormData]);
    useEffect(() => {
        if (profile.therapistId) {
            getFamilyNameList();
            getFormNameList();
            // getSubmittedFormList(pageDetail.page);
        }
        adminService.getAll(`user-profiles/user-detail`).then(
            (res) => {
                if (res?.status === "Success") {
                    console.log("user-detail", res.data);
                    setDisciplines(res.data?.disciplines?.name);
                    setUserType(res.data?.userType);
                    // setProfile(res.data);
                    //dispatch(adminAction.setProfileData(res.data));
                } else {
                    res?.message
                        ? toast.error(res?.message)
                        : console.log("Somthing went wrong");
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }, [profile.therapistId]);

    useEffect(() => {
        setInputs({
            ...inputs,
            sentBy: profile?.userUniqeId,
            therapistId: profile?.therapistId,
        });
    }, [profile?.firstName]);
    const getFamilyNameList = () => {
        adminService
            .getAll(
                `families/filter?therapistId=${profile.therapistId}&page=0&size=2147483647`
            )
            .then(
                (res) => {
                    if (res?.status === "Success") {
                        setFamilyList(res.data.content);
                    } else {
                        res?.message
                            ? toast.error(res?.message)
                            : console.log("Somthing went wrong");
                    }
                },
                (error) => {
                    toast.error(error);
                }
            );
    };

    const getFormNameList = () => {
        adminService.getAll("adminForms").then(
            (res) => {
                if (res?.status === "Success") {
                    setFormNameList(res.data.content);
                } else {
                    res?.message
                        ? toast.error(res?.message)
                        : console.log("Somthing went wrong");
                }
            },
            (error) => {
                toast.error(error);
            }
        );
    };

    const getSubmittedFormList = (pageNo = 0) => {
        adminService
            .getAll(`archive_forms?page=${pageNo}&size=10&sort=id,DESC`)
            .then((response) => {
                setSubmittedFormList({ isLoading: false, list: response.data.content });
                setPageDetail({
                    ...pageDetail,
                    page: pageNo,
                    totalpages: response.data.totalPages,
                });
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    useEffect(() => {
        getSubmittedFormList(currentPage);
    }, [currentPage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "formId") {
            // setIsFormOpen(true);
            value == 3 &&
                setInputs((inputs) => ({ ...inputs, reply: true, replyNeeded: false }));
        }
        if (name === "familyMemberId") {
            const family_id = document.getElementById(value).value;
            const fmly = familyList.filter((f) => f.id == family_id)[0];
            const fmlyMember = fmly.familyMembers.filter((f) => f.id == value)[0];
            console.log("selectedFamily value", value, fmlyMember);
            setselectedFamily(fmlyMember);
            fmlyMember?.familyMemberTherapies.map((item) => {
                console.log("profile.therapistId", profile.therapistId, item.therapist.id);
                if (item.therapist.id === profile.therapistId) {
                    console.log("serviceType", item.therapy.name);
                    setServiceType(item.therapy.name);
                    // serviceName=item.therapy.name;
                }
            })
            setInputs({
                ...inputs,
                familyId: family_id,
                sentTo: family_id,
                [name]: value,
            });
        } else {
            setInputs((inputs) => ({ ...inputs, [name]: value }));
        }
    };
    // const onSubmit = () => {
    //   setIsFormOpen(true);
    // };
    const onSubmit = (html = "") => {
        let formData = new FormData();
        setIsFormOpen(false);
        // formData.append("signature", signatures);
        formData.append(
            "formCreationForm",
            new Blob([JSON.stringify(inputs)], {
                type: "application/json",
            })
        );
        adminService
            .onboardUser(`archive_forms`, formData)
            .then((response) => {
                getSubmittedFormList(pageDetail.page);
                setIsFormOpen(false);
                toast.success("Form Submitted successfully");
                setInputs({
                    ...inputs,
                    familyId: null,
                    sentTo: null,
                    familyMemberId: null,
                    formId: null,
                    replyNeeded: false,
                    reply: null,
                });
                handleBack();
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const handleBack = (isreload = "") => {
        setCreateForm(false);
        setIsFormOpen(false);
        setInputs({
            ...inputs,
            familyId: null,
            sentTo: null,
            familyMemberId: null,
            formId: null,
            replyNeeded: null,
            reply: null,
        });
        isreload && getSubmittedFormList(0);
    };

    const showForms = (isShow = "") => {
        return (
            <>
                {inputs.formId == 7 ? (
                    <DischargeSummery
                        from="therapist"
                        data={family}
                        handleBack={handleBack}
                        isFormOpen={isShow ? false : isFormOpen}
                        setInputs={setInputs}
                        inputs={inputs}
                        onSubmitTherapistForm={onSubmit}
                        selectedFamily={selectedFamily}
                    />) : inputs.formId == 8 ? (
                        <Acknowledgement
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            inputs={inputs}
                            familyList={familyList}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 10 ? (
                        <ConsultationDocumentationForm
                            from="therapist"
                            data={family}
                            inputs={inputs}
                            setInputs={setInputs}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                            selectedFamily={selectedFamily}
                        />
                    ) : inputs.formId == 11 ? (
                        <ParentProvider
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            inputs={inputs}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 9 ? (
                        <AuthToDiscoloseInfo
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 12 ? (
                        <QuaterlyProgressForm
                            from="therapist"
                            data={family}
                            inputs={inputs}
                            disciplines={disciplines}
                            selectedFamily={selectedFamily}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 1 ? (
                        <ConsentToTreat
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 2 ? (
                        <>
                            <MedicalInsuranceForm
                                from="therapist"
                                data={family}
                                handleBack={handleBack}
                                isFormOpen={isShow ? false : isFormOpen}
                            />
                        </>
                    ) : inputs.formId == 3 ? (
                        <HippaNoticeForm
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 4 ? (
                        <CancellationForm
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 5 ? (
                        <ReleaseToCommunicateForm
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 6 ? (
                        <MediaReleaseForm
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 8 ? (
                        <Acknowledgement
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 17 ? (
                        <ChangeInCoverage
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 13 ? (
                        <PocStForm
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            inputs={inputs}
                            isFormOpen={isShow ? false : isFormOpen}
                            selectedFamily={selectedFamily}
                            serviceType={serviceType}
                        />
                    ) : inputs.formId == 14 ? (
                        <PocPtForm
                            from="therapist"
                            data={family}
                            inputs={inputs}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                            selectedFamily={selectedFamily}
                        />
                    ) : inputs.formId == 15 ? (
                        <PocDaltonForm
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            inputs={inputs}
                            isFormOpen={isShow ? false : isFormOpen}
                            selectedFamily={selectedFamily}
                        />
                    ) : inputs.formId == 16 ? (
                        <>
                            <PocOtForm
                                from="therapist"
                                data={family}
                                handleBack={handleBack}
                                inputs={inputs}
                                isFormOpen={isShow ? false : isFormOpen}
                                selectedFamily={selectedFamily}
                            />
                        </>
                    ) : inputs.formId == 18 ? (
                        <MedicoidForm
                            from="therapist"
                            data={family}
                            handleBack={handleBack}
                            isFormOpen={isShow ? false : isFormOpen}
                        />
                    ) : inputs.formId == 19 ? (
                        <>
                            <SampleEvaluation
                                from="therapist"
                                data={family}
                                handleBack={handleBack}
                                isFormOpen={isShow ? false : isFormOpen}
                                setInputs={setInputs}
                                inputs={inputs}
                                familyList={familyList}
                                onSubmitTherapistForm={onSubmit}
                                selectedFamily={selectedFamily}
                            />
                        </>
                    ) : (
                    ""
                )}
            </>
        );
    };

    return (
        <>
            <div className="col-md-10 no-padding">
                <div className="no-padding">
                    {createForm ? (
                        <div className="custom-table-view">
                            <div className="row mb-10">
                                <div className="col-md-6">
                                    {!isFormOpen && (
                                        <div className="text-left header-title">Create Forms</div>
                                    )}
                                </div>
                                <div className="col-md-6 text-right">
                                    {/* <button
                  className="btn custom-btn-green"
                  onClick={() => handleBack()}
                >
                  Back
                </button> */}
                                </div>
                            </div>
                            {isFormOpen && showForms()}
                            <CreateForm
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                register={register}
                                handleChange={handleChange}
                                familyList={familyList}
                                formNameList={formNameList}
                                setInputs={setInputs}
                                inputs={inputs}
                                errors={errors}
                                setIsFormOpen={setIsFormOpen}
                                isFormOpen={isFormOpen}
                                handleBack={handleBack}
                            />
                        </div>
                    ) : (
                        <div className="custom-table-view">
                            <div className="row mb-10">
                                <div className="col-md-6">
                                    {!isFormOpen && (
                                        <div className="text-left header-title">Forms</div>
                                    )}
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="archiveGoalHeader">
                                        <div className="text-left"><b>Select Date : </b>
                                            <DatePicker
                                                value={values}
                                                onChange={date => { setValues(date); setFlag(!flag); }}
                                                maxDate={new DateObject()}
                                                format="MM-DD-YYYY"
                                                range
                                            />
                                            {diffDays > 365 ? <p><b className="text-danger">Please select date range with in one year</b></p> : ''}
                                        </div>
                                        <div className="text-right pl-2">
                                            {values.length === 2 && diffDays <= 365 && isDownloadBtn ? <CaseNoteDownload
                                                downloadName={`ArchiveCaseNote.pdf`}
                                                targetRef={formRef}
                                                beforeStart={onPdfCreate}
                                                onComplete={onPdfComplete}
                                            />
                                                : ''}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {submittedFormList.isLoading ? (
                                <div className="loader" />
                            ) : (
                                <>
                                    {isFormOpen ? (
                                        showForms("show")
                                    ) : (
                                        <>
                                            <FormTable
                                                userType="admin"
                                                list={submittedFormList.list}
                                                setIsFormOpen={setIsFormOpen}
                                                setFamily={setFamily}
                                                setInputs={setInputs}
                                                inputs={inputs}
                                            />
                                            {!submittedFormList.isLoading &&
                                                submittedFormList.list?.length == 0 && (
                                                    <h5 className="text-center p-3">No Data Found</h5>
                                                )}
                                            <div className="row">
                                                <div
                                                    style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
                                                >
                                                    <div style={{ marginTop: "2px" }}>
                                                        <Pagination
                                                            totalpages={pageDetail.totalpages}
                                                            currentPage={currentPage}
                                                            setCurrentPage={setCurrentPage}
                                                        />
                                                    </div>
                                                    <button className="btn btn-success ml-3" style={{ borderRadius: "0.25rem" }} onClick={() => history(-1)}>Back</button>

                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ArchiveTherapistFormList;
