import React, { useEffect, useState, useRef } from 'react';
import {
  CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer
} from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from "react-router-dom";
import DigitalSignPopup from "../components/digitalSignPopup/BillSign";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { apiUrl } from '../config/Config';
//import adminActions from '../redux/actions/adminAction';
import allActions from '../redux/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function AddCard(props) {
  const dispatch = useDispatch();
  let history = useNavigate();
  let location = useLocation();
  const sigCanvas = useRef({});
  const [show, setShow] = useState(false);
  const [tenantName, setTenantName] = useState('');
  const handleClose = () => {
    setShow(false);
    setCardAgree(!cardAgree);
  };
  const handleShow = () => {
    setShow(true)
    setCardAgree(!cardAgree);
  }

  const [open, setOpen] = useState(false);
  const handleSignModal = () => {
    setOpen(true);
    setShow(false);
    //setCardAgree(!cardAgree);
  }

  const [cardAgree, setCardAgree] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [CardNumberValidate, setCardNumberValidate] = useState('');
  const [expireDateValidate, setexpireDateValidate] = useState('');
  const [cvcValidate, setCvcValidate] = useState('');
  const [formComplete, setFormComplete] = useState(false);
  const [formExpireComplete, setFormExpireComplete] = useState(false);
  const [formCvcComplete, setFormCvcComplete] = useState(false);
  const [CheckboxList, setCheckBoxList] = useState({ saveCard: true, autoPay: false });
  const [loader, setLoader] = useState(false);
  const [customerId, setCustomerId] = useState('');
  console.log("location", location, tenantName);
  let accessToken = useSelector((state) => state?.adminUser?.userData?.accessToken);
  let sessionId = useSelector((state) => state?.adminUser?.userData?.sessionId);
  const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
  let userUniqueId = useSelector((state) => state?.adminUser?.userData?.userDetails?.userUniqueId);
  let userEmail = useSelector((state) => state?.adminUser?.userData?.userDetails?.email);
  let user = useSelector((state) => state?.adminUser?.userData);
  console.log("login user data----", user);
  useEffect(() => {
    fetch(`${apiUrl}/misc/billing-company-detail?tenantId=${tenantId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        'SessionId': sessionId,
        'X-TenantID': tenantId
      },
    })
      .then((response) => response.json())
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("accountId res", res.data)
            setAccountId(res?.data?.stripeAccountId);
            setTenantName(res?.data?.name);
            console.log("tenant name", res?.data?.name);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });

    const stripeCustomer = fetch(`${apiUrl}/payments/stripe-details/${userUniqueId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        'SessionId': sessionId,
        'X-TenantID': tenantId
      },
    }).then((response) => response.json())
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("stripeCustomer id", res?.data?.stripeCustomerId);
            setCustomerId(res?.data?.stripeCustomerId);
            //setAccountId(res?.data?.stripeAccountId);
          } else {
            //toast.error(res.message);
            console.log("stripe customer", res.message);
          }
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
    //console.log("stripeCustomer",stripeCustomer,stripeCustomer.stripeCustomerId);
    //setCustomerId(res?.data?.stripeCustomerId);
  }, [])
  const validate = (e) => {
    console.log("validate", e);
    if (e.complete === true) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
    if (e?.error !== null) {
      setCardNumberValidate(e?.error?.message);
    }
  }
  const validateExpire = (e) => {
    console.log("validate", e);
    if (e.complete === true) {
      setFormExpireComplete(true);
    } else {
      setFormExpireComplete(false);
    }
    if (e?.error !== null) {
      setexpireDateValidate(e?.error?.message);
    }
  }
  const validateCVC = (e) => {
    console.log("validate", e);
    if (e.complete === true) {
      setFormCvcComplete(true);
    } else {
      setFormCvcComplete(false);
    }
    if (e?.error !== null) {
      setCvcValidate(e?.error?.message);
    }
  }
  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    if (isSigned === false) {
      setCardAgree(!cardAgree);
      toast.error("please sign the form..");
    } else {
      let apiData = {
        "userUniqueId": userUniqueId,
      }
      fetch(`${apiUrl}/payments/credit-card-authorization`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken,
          'SessionId': sessionId,
          'X-TenantID': tenantId
        },
        body: JSON.stringify(apiData)
      })
        .then(
          (res) => {
            if (res.status === "Success") {

            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            toast.error(error.info || error.msg);
          }
        )
        .finally(() => {
          // setSignLoading(false);
        });
      // We don't want to let default form submission happen here,
      // which would refresh the page.

      const { stripe, elements } = props;
      if (!stripe || !elements) return;
      const card = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
      //const result = await stripe.createPaymentMethod(card);

      const result = await stripe
        .createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            name: 'Jenny Rosen',
          },
        })
        .then(result => {
          return result?.paymentMethod?.id;
        });

      console.log("paymentList", result, elements.getElement(CardNumberElement));
      if (customerId === '') {
        const customerdata = {
          "email": userEmail,
        };
        const results = await fetch("https://api.stripe.com/v1/customers", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
            'Stripe-Account': accountId,
          },
          body: Object.keys(customerdata)
            .map(key => key + '=' + customerdata[key])
            .join('&'),
          // body: Object.keys(paymentList)
          //   .map(key => key + '=' + paymentList[key])
          //   .join('&'),
        }).then(response => response.json())
        console.log("customer $$$$$$$$$" + JSON.stringify(results));
        //setcustomerResponse(results);
        let customerResponse = results;
        const genCard = {
          'customer': customerResponse?.id,

        }

        //backend send data of customer
        const detailForBackend = {
          "autoPay": CheckboxList.autoPay,
          "stripeCustomerId": genCard.customer,
          "saveCard": CheckboxList.saveCard,
          "userUniqueId": userUniqueId
        }
        //console.log("genCard", customerResponse, paymentResponse.paymentMethod.id);
        const sdf = fetch(`${apiUrl}/payments/register-stripe-user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
            'SessionId': sessionId,
            'X-TenantID': tenantId
          },
          body: JSON.stringify(detailForBackend)
        })
          .then(
            (res) => {
              if (res.status === "Success") {
                console.log("sdf customerResponse", res.data);
              } else {
                toast.error(res.message);
              }
            },
            (error) => {
              toast.error(error.info || error.msg);
            }
          )
          .finally(() => {
            // setSignLoading(false);
          });

        //default payment id

        console.log("paymentId", result);
        if (result !== '') {
          const paymentresults = await fetch("https://api.stripe.com/v1/payment_methods/" + result + "/attach", {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
              'Stripe-Account': accountId,
            },
            body: Object.keys(genCard)
              .map(key => key + '=' + genCard[key])
              .join('&'),
          }).then((response) => {
            //response.json()
            if (response.status === 200) {
              let apiData = {
                "userUniqueId": userUniqueId,
                "creditCardAttach": true
              }
              fetch(`${apiUrl}/payments/attach-successfully`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + accessToken,
                  'SessionId': sessionId,
                  'X-TenantID': tenantId
                },
                body: JSON.stringify(apiData)
              })
                .then(
                  (res) => {
                    if (res.status === "Success") {

                    } else {
                      toast.error(res.message);
                    }
                  },
                  (error) => {
                    toast.error(error.info || error.msg);
                  }
                )
                .finally(() => {
                  // setSignLoading(false);
                });

              const postdata = {
                "invoice_settings[default_payment_method]": result
              };
              const rawData = new URLSearchParams(Object.keys(postdata).map(key => [key, postdata[key]]));
              console.log(rawData.toString());
              const defaultCardSet = fetch("https://api.stripe.com/v1/customers/" + genCard.customer, {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
                  'Stripe-Account': accountId,
                },
                body: rawData,
              }).then(response => {
                response.json()
              })
              console.log("defaultCardSet", defaultCardSet);
              elements.getElement(CardNumberElement).clear();
              elements.getElement(CardExpiryElement).clear();
              elements.getElement(CardCvcElement).clear();
              setCardAgree(false);
              // setCheckBoxList({
              //   saveCard: true, autoPay: false
              // })
              dispatch(allActions.adminAction.logIn(user));
              history("/dashboard/");
              setLoader(false);
              toast.success("Card Added Successfully");
            } else {
              let apiData = {
                "userUniqueId": userUniqueId,
                "creditCardAttach": false
              }
              fetch(`${apiUrl}/payments/attach-successfully`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + accessToken,
                  'SessionId': sessionId,
                  'X-TenantID': tenantId
                },
                body: JSON.stringify(apiData)
              })
                .then(
                  (res) => {
                    if (res.status === "Success") {

                    } else {
                      toast.error(res.message);
                    }
                  },
                  (error) => {
                    toast.error(error.info || error.msg);
                  }
                )
                .finally(() => {
                  // setSignLoading(false);
                });
              toast.error("Please enter valid card details");

              setLoader(false);
              setCardAgree(false);
              //window.location.reload();
              elements.getElement(CardNumberElement).clear();
              elements.getElement(CardExpiryElement).clear();
              elements.getElement(CardCvcElement).clear();
            }
          })
          console.log('\n\n\nlogging: \n', genCard, '\n', 'blank', '\n', paymentresults);

        }

      } else {
        const genCard = {
          'customer': customerId,

        }
        if (result !== '') {
          const paymentresults = await fetch("https://api.stripe.com/v1/payment_methods/" + result + "/attach", {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
              'Stripe-Account': accountId,
            },
            body: Object.keys(genCard)
              .map(key => key + '=' + genCard[key])
              .join('&'),
          }).then((response) => {
            //response.json()
            if (response.status === 200) {
              let apiData = {
                "userUniqueId": userUniqueId,
                "creditCardAttach": true
              }
              fetch(`${apiUrl}/payments/attach-successfully`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + accessToken,
                  'SessionId': sessionId,
                  'X-TenantID': tenantId
                },
                body: JSON.stringify(apiData)
              })
                .then(
                  (res) => {
                    if (res.status === "Success") {

                    } else {
                      toast.error(res.message);
                    }
                  },
                  (error) => {
                    toast.error(error.info || error.msg);
                  }
                )
                .finally(() => {
                  // setSignLoading(false);
                });
              const postdata = {
                "invoice_settings[default_payment_method]": result
              };
              const rawData = new URLSearchParams(Object.keys(postdata).map(key => [key, postdata[key]]));
              console.log(rawData.toString());
              const defaultCardSet = fetch("https://api.stripe.com/v1/customers/" + genCard.customer, {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
                  'Stripe-Account': accountId,
                },
                body: rawData,
              }).then(response => {
                response.json()
              })
              console.log("defaultCardSet", defaultCardSet);
              elements.getElement(CardNumberElement).clear();
              elements.getElement(CardExpiryElement).clear();
              elements.getElement(CardCvcElement).clear();
              setCardAgree(false);
              // setCheckBoxList({
              //   saveCard: true, autoPay: false
              // })
              dispatch(allActions.adminAction.logIn(user));
              history("/dashboard/");
              setLoader(false);
              toast.success("Card Added Successfully");
            } else {
              let apiData = {
                "userUniqueId": userUniqueId,
                "creditCardAttach": false
              }
              fetch(`${apiUrl}/payments/attach-successfully`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + accessToken,
                  'SessionId': sessionId,
                  'X-TenantID': tenantId
                },
                body: JSON.stringify(apiData)
              })
                .then(
                  (res) => {
                    if (res.status === "Success") {

                    } else {
                      toast.error(res.message);
                    }
                  },
                  (error) => {
                    toast.error(error.info || error.msg);
                  }
                )
                .finally(() => {
                  // setSignLoading(false);
                });
              toast.error("Please enter valid card details");
              setLoader(false);
              setCardAgree(false);
              // window.location.reload();
              elements.getElement(CardNumberElement).clear();
              elements.getElement(CardExpiryElement).clear();
              elements.getElement(CardCvcElement).clear();
            }
          })
          console.log('\n\n\nlogging: \n', genCard, '\n', 'blank', '\n', paymentresults);

        }

      }


    }
  };
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    // setFamilyImageURL(
    //   sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    // );
    setOpen(false);
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    console.log("sigCanvas.current", signatureData);
    updateSignature(signatureData);
  };
  const updateSignature = (signatureData) => {
    //const apiUrl = `user-profiles/${profile.id}`;
    fetch(`${apiUrl}/user-profiles/1`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        'SessionId': sessionId,
        'X-TenantID': tenantId
      },
      body: JSON.stringify(signatureData)
    })
      .then(
        (res) => {
          if (res.status === "Success") {

          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const onNotificationChange = (e) => {
    //console.log("notificationList",e.target.value,e.target.name,e.target.checked);
    const { name, checked } = e.target;
    let arr = { ...CheckboxList };
    arr = {
      ...arr,
      [name]: e.target.checked,
    };
    console.log("notificationList", arr)
    setCheckBoxList(arr);
  }
  console.log("sigCanvas", sigCanvas);
  return (
    <div className='min-h-100'>
      {loader && (
        <div className="loader-overlay">
          <div className="loader" />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number (<span class="required-span">*</span>)</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => validate(e)}
              required
            />
            <p className='text-danger'>{CardNumberValidate !== '' ? CardNumberValidate : ''}</p>
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="expiry">Expiration Date (<span class="required-span">*</span>)</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => validateExpire(e)}
              required
            />
            <p className='text-danger'>{expireDateValidate !== '' ? expireDateValidate : ''}</p>
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="cvc">CVC (<span class="required-span">*</span>)</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => validateCVC(e)}
              required
            />
            <p className='text-danger'>{cvcValidate !== '' ? cvcValidate : ''}</p>
          </div>
        </div>
        <div>
          <label><input onChange={(e) => onNotificationChange(e)} checked={CheckboxList?.autoPay} type="checkbox" class="form-check-inline" name="autoPay" />Auto Pay</label>
        </div>
        <label>
          <input
            onClick={handleShow}
            checked={cardAgree}
            type="checkbox"
            class="form-check-inline"
            name="agreeTurms"
          />Apply Card Authorization</label>
        {/* <button onClick={handleOpen}>Open modal</button> */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Payment Terms and Conditions</Modal.Title>
            <button onClick={handleClose} class="btn-close">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 20 20">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg></button>
          </Modal.Header>
          <Modal.Body>
            <p>By your electronic signature of this form, you authorize charges to your credit card throught Stripe via Simple Practice for services rendered. These charges will appear on your bank/credit card statement as {tenantName}. you have the right to request a paper copy of this document.
            </p>
            <p>I authorize {tenantName} to charge my credit card through Stripe.</p>
            <p>CANCELLATION POLICY: I also agree that my credit card can be charged for any session cancellation as per mentioned in Payment Policy form.</p>
            <p>I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify {tenantName} in writing of any changes in my account information or termination of this authorization.</p>
            <p>I certify that I am an authorized user of this credit card and will not dispute these scheduled transactions with my bank or credit card company as long as the transactions correspond to the terms indicated in this authorization form. I acknowledge that credit card transactions could be linked to Protected Health Information.</p>
            <button onClick={handleSignModal} className="btn btn-success">Please Sign</button>
          </Modal.Body>
        </Modal>
        <DigitalSignPopup
          modalState={open}
          setModalState={setOpen}
          save={save}
          clear={clear}
          sigCanvas={sigCanvas}
          setIsSigned={setIsSigned}
        />
        <hr className="mb-4" />
        <button className="btn btn-success" type="submit" disabled={formComplete && formExpireComplete && formCvcComplete && cardAgree && props.stripe ? false : true}>
          Add Card
        </button>
      </form>
    </div>
  );
}

export default function InjectCheckOutLogin(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <AddCard amountData={props.amount} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}