import React from "react";
import classNames from "classnames";
import { CaseNoteInfoCol } from "../../components/case-note-detail";
//import { CaseNoteInfo, CaseNoteInfoCol } from "./";
//import CaseNoteBillingInfo from "./CaseNoteBillingInfo";
// import './print.css'
import { useSelector } from "react-redux";
import moment from "moment";

const ArchiveCaseNotePrint = React.forwardRef((props, ref) => {
  let propsData = props.casenote;
  let formateData = JSON.stringify({ propsData })
  // const printProps = {
  //   childImage: state.childProfilePic,
  //   childName: state.childName,
  //   infoProps,
  //   icdCode: currentDiagnoses
  //     ? `(${currentDiagnoses.code}) ${currentDiagnoses.description}`
  //     : state.caseNoteDTO?.diagnosisId,
  //   cptCode: currentCpt
  //     ? `(${currentCpt.procCode}) ${currentCpt.description}`
  //     : state.caseNoteDTO?.cptId,
  //   billableRate,
  //   duration,
  //   caseNoteDTO: state.caseNoteDTO,
  //   isAccepetedByFamily,
  //   interpreterName,
  //   isTherepist,
  //   isInterepreterDraft,
  //   translatedDir,
  // };
  // const { childImage, childName, icdCode, cptCode, caseNoteDTO } = printProps;
  // const {
  //   sessionNumber,
  //   date,
  //   therapist,
  //   therapy,
  //   clinicianNpiNumber,
  //   clinicNpiNumber,
  //   groupNpiNumber,
  // } = infoProps;
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  // if (soapNoteBillingData?.length <= 0) {
  //   return null;
  // }
  const {
    name,
    address1,
    address2,
    city,
    country,
    state: billingState,
    zipCode,
    logoUrl,
  } = soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  const relValue =
    typeof props?.data?.reply === "undefined"
      ? ""
      : JSON.parse(formateData);

  console.log("relValue casenote", formateData, propsData);
  // return (

  // )
  return (
    <>
      <div ref={ref} className="col-md-12">
        {propsData.length === 0 ? "No Data Found" : <>
          {propsData?.map((item, i) => (
            <>
              <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "40%" }}>
                  {logoUrl && (
                    <img
                      src={logoUrl}
                      alt={name}
                      className="img-fluid c-casenote-billing-info__image"
                    />
                  )}
                </div>
                <div style={{ width: "60%", textAlign: "right" }}>
                  <p className="mb-0" style={{ marginBottom: 0, width: "100%" }}>
                    <b style={{ fontSize: "1.1em" }}>{name}</b>
                  </p>
                  <p className="mb-1" style={{ width: "100%" }}>
                    <span className="d-block" style={{ marginBottom: 0 }}>
                      {joinWithComma(address1, address2)}
                    </span>
                    <span className="d-block" style={{ marginBottom: 0 }}>
                      {joinWithComma(city, billingState, country, zipCode)}
                    </span>
                  </p>
                </div>
              </div>
              <table width="100%" key={i}>
                <tbody>
                  {/* <tr>
                    <td className="text-left">
                      <div className="c-casenote-billing-info text-left d-flex justify-content-between align-items-center">
                        <div className="c-casenote-billing-info__image-wrapper pl-2 pr-2">
                          {logoUrl && (
                            <img
                              src={logoUrl}
                              alt={name}
                              className="img-fluid c-casenote-billing-info__image"
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td></td>
                    <td style={{ textAlign: "right" }} className="text-right">
                      <div className="c-casenote-billing-info text-right d-flex justify-content-end align-items-center">
                        <div className="c-casenote-bililng-info__content">
                          <p className="mb-0" style={{ marginBottom: 0 }}>
                            <b style={{ fontSize: "1.1em" }}>{name}</b>
                          </p>
                          <p className="mb-1">
                            <span className="d-block" style={{ marginBottom: 0 }}>
                              {joinWithComma(address1, address2)}
                            </span>
                            <span className="d-block" style={{ marginBottom: 0 }}>
                              {joinWithComma(city, billingState, country, zipCode)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <div className="p-case-note-detail__user">

                        <img src={item.childProfilePic} alt={item.childName} />
                        <p
                          className="p-case-note-detail__child-name text-capitalize"
                          style={{ width: "100%" }}
                        >
                          {item.childName}
                          &nbsp;
                          {item?.childDOB && (
                            <small>
                              (<b>DOB: </b>
                              {moment(item?.childDOB).format("MM/DD/YYYY")})
                            </small>
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CaseNoteInfoCol label="Session Id" value={item?.caseNoteDTO?.sessionDto.id} />
                    </td>
                    <td>
                      <CaseNoteInfoCol label="Session Date" value={moment.utc(item?.startDateTime).utc().local().format("DD MMMM, yyyy")} />
                    </td>
                    <td>
                      <CaseNoteInfoCol label="Therapist" value={item?.therapist} />
                    </td>
                  </tr>
                  <tr>
                    {item?.interpreterName && (
                      <td>
                        <CaseNoteInfoCol
                          label="Interpreter"
                          value={item?.interpreterName}
                        />
                      </td>
                    )}
                    <td>
                      <CaseNoteInfoCol label="Therapy" value={item?.therapy} />
                    </td>
                    <td>
                      <CaseNoteInfoCol
                        label="Clinician NPI Number"
                        value={item?.caseNoteDTO?.clinicianNpiNumber}
                      />
                    </td>
                  </tr>
                  <tr>
                    {item?.caseNoteDTO?.groupNpiNumber && (
                      <td>
                        <CaseNoteInfoCol
                          label="Group NPI Number"
                          value={item?.caseNoteDTO?.groupNpiNumber}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>
                      <CaseNoteInfoCol label="ICD 10 Code" value={item?.caseNoteDTO?.diagnosisId} />
                    </td>
                    <td>
                      <CaseNoteInfoCol label="CPT Code" value={item?.caseNoteDTO?.cptId} />
                    </td>
                    {/* {userType !== "ROLE_FAMILY" && userType !== "ROLE_INTERPRETER" && (
                  <td>
                  </td>
                )} */}
                  </tr>
                  <tr>
                    <td>
                      <CaseNoteInfoCol label="Duration/Unit" value={item?.duration} />
                    </td>
                    <td>
                      <CaseNoteInfoCol label="Units" value={item?.caseNoteDTO?.units} />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <CaseNoteInfoCol
                        label="Description"
                        value={item?.caseNoteDTO?.sessionDescription}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <CaseNoteInfoCol
                        label="Session Note"
                        value={item?.caseNoteDTO?.description}
                      />
                    </td>
                  </tr>
                  {!item?.caseNoteDTO?.draftedInterpreter && item?.interpreterName && !props.isTherepist && (
                    <tr>
                      <td colSpan="3">
                        <CaseNoteInfoCol
                          label="Translation"
                          value={item?.caseNoteDTO?.translatedDescription}
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <CaseNoteInfoCol noValue label="Therapist Signature" />
                      <div className="d-flex">
                        <div
                          className="sign-img"
                          style={{
                            width: "160px",
                            height: "60px",
                            border: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            src={item?.caseNoteDTO?.therapistSignatureKey}
                            alt="my signature"
                            className="sign-img1"
                            style={{ width: "130px", height: "40px" }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      {item?.caseNoteDTO?.acceptCaseNotes && item?.caseNoteDTO?.familySignatureKey && (
                        <CaseNoteInfoCol noValue label="Family Signature" />
                      )}
                      <div className="d-flex">
                        {item?.caseNoteDTO?.acceptCaseNotes && item?.caseNoteDTO?.familySignatureKey && (
                          <>

                            <div
                              className="sign-img"
                              style={{
                                width: "160px",
                                height: "60px",
                                border: 0,
                                marginBottom: "10px",
                              }}
                            >
                              <img
                                src={item?.caseNoteDTO?.familySignatureKey}
                                alt="my signature"
                                className="sign-img1"
                                style={{ width: "130px", height: "40px" }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <p style={{ pageBreakBefore: "always" }}>&nbsp;</p>
              <footer></footer>
            </>
          ))}
        </>
        }
      </div>
    </>

  );
}
);

export default ArchiveCaseNotePrint;
