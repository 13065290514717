const interpreterHourlyRate = {required: {
    value: true,
    message: 'Hourly Rate is required'
}, min: {
    value: 0.01,
    message: 'Hourly rate cannot be equal to or less than $0'
}, max: {
    value: 999.00,
    message: 'Hourly Rate cannot be greater than $999.00'
}}
const interpreterLanguage = {required: {
    value: true,
    message: "Language is required"
}}
const validationRules = {
    interpreterHourlyRate,
    interpreterLanguage
}

export default validationRules