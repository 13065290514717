import React, { useState, useEffect } from "react";
import { adminService } from "../../services/adminService";
import AttachmentFile from "./AttachmentFile";
import { getFileType, _toastHandleErr, _toasthandleRes } from "../../utils/commonFunctions";

const AttachmentForm = ({
  enableUpload,
  showAttachments,
  attachments,
  label,
  accept,
  ...props
}) => {

  const [state, setState] = useState({
    localAttachments: []
  })

  const onFileAttach = (e) => {
    const files = e.target.files;
    const target = e.target
    if (files) {
      const fileArr = Array.from(files)
      setState(st => ({
        ...st,
        localAttachments: [...st.localAttachments, ...fileArr.map((item, index) => {
          const retItem = {
            file: item,
            isLoading: true,
            key: `${index}-${new Date().getTime()}`
          }
          if(index === fileArr.length -1){
            //   reset()
            target.value = "" 
          }
          return retItem
        })]
      }))
    }
  };
  
  return (
    <div className="c-attachments">
      {enableUpload && (
        <label className="cursor-pointer">
          <img
            src="../resources/assets/Icon material-attach-file.svg"
            alt=""
            className="paperclip-btn mb-5"
          />
          <span className="paperclip-btn-p">{label}</span>
          <input
            type="file"
            onChange={onFileAttach}
            accept={accept}
            // ref={register}
            multiple={props.multiple}
            name={props.name}
            className="d-none"
            id="therapy-attach-file"
          />
        </label>
      )}
          {showAttachments && (
          attachments?.length > 0 || 
          state.localAttachments?.length > 0 ) &&
          <label className="pt-4 d-block">
            <span className="paperclip-btn-p">Attachments</span>
          </label>}
          <div className="c-attachments__list">
            {(showAttachments && attachments?.length > 0) && attachments?.map((file, _id) => {
              const fileType = getFileType(file.key?.split("?")[0]);
              return (
                <AttachmentFile
                  {...file}
                  src={file.key}
                  key={file.id + file.key + _id}
                  attachmentType={fileType}
                  fileName={file.fileName || null}
                  className={`c-attachments__file c-attachments__file--${fileType}`}
                  onDelete={props.onDelete && typeof props.onDelete === "function" ? ()=> {
                    if(props.onDelete && typeof props.onDelete === "function"){
                      props.onDelete(file, _id)
                    }
                  }: undefined}
                />
              );
            })}
            {
              state.localAttachments.map((file, index) => {
                return (
                  <AttachmentUploader onSuccess={(data)=> {
                    props.onSuccess(data);
                    setState(st=> ({
                      ...st,
                      localAttachments: st.localAttachments.filter(item => item.key !== file.key)
                    }))
                  }} key={index} file={file.file} />
                )
              })
            }
          </div>
    </div>
  );
};

export default AttachmentForm;

AttachmentForm.defaultProps = {
    enableUpload: true,
    showAttachments: true,
    onSuccess: (e) => {console.log(e)},
    name: 'file',
    label: 'Attach audio/video/image file',
    accept: 'image/*, audio/*, video/*'
}


const AttachmentUploader = ({file, ...props}) => {

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    uploadFile()
  }, []);

  const uploadFile = () => {
    if(!file){
      return
    }
    const apiUrl = props.apiUrl || "attachments";
    const formData = new FormData();
    formData.append("profileImage", file);
    setIsLoading(true)
    adminService
      .POSTUploadImage(apiUrl, formData)
      .then((res) => {
        handleUploadSuccess(res, props.onSuccess)
      })
      .catch(_toastHandleErr).finally(()=> {
        setIsLoading(false)
      });
  }

  if(isLoading){
    return <AttachmentLoader />
  }
  return (
  <span />
  )

}

AttachmentUploader.defaultProps = {
  onSuccess: (e) => {console.log(e)},
}

const handleUploadSuccess = (res, onSuccess) => {
  if (res.status === "Success") {
    if(onSuccess && typeof onSuccess === "function"){
      onSuccess({
        ...res.data,
      });
    }
  }else{
    _toasthandleRes(res);
  }
}

const AttachmentLoader = () => (
  <div className="d-inline-block c-attachments__upload-loader position-relative">
    <div className="loader-small" />
  </div>
)
