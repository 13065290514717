import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminService } from "../services/adminService";
import { useSelector } from "react-redux";
import {
  getUTCTimeToLocalByDateWithMeridium,
  getUTCDateToLocalByDateWithMeridium,
} from "../utils/commonFunctions";
import moment from "moment-timezone";
import { daysJson, monthJson } from "../config/Config";
import AcceptChangeTherapist from "../pages/AcceptChangeTherapist";

function Notifications() {
  let history = useNavigate();
  let today_date = new Date();
  const [notification, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isinterpreter, setIsInterpreter] = useState(false);
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [paymentSuccessAdmin, setPaymentSuccessAdmin] = useState(new Set());
  const [paymentSuccessFamily, setPaymentSuccessFamily] = useState('');
  const [paymentFailedAdmin, setPaymentFailedAdmin] = useState('');
  const [sessionList, setSessionList] = useState('');
  let user = useSelector((state) => state.adminUser.user);
  const [detail, setDetail] = useState({
    info: "",
  });
  const [input, setInput] = useState({
    sessionId: "",
    notificationId: "",
  });
  const [inputs, setInputs] = useState({
    sessionId: "",
    endDateTime: "",
    startDateTime: "",
    notificationName: "",
  });
  useEffect(() => {
    setLoading(true);
    let api =
      user.userDetails.roles[0] === "ROLE_RCE"
        ? `notifications/get-Representative?page=${page}&size=10`
        : user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS"
          ? `notifications/get-Representative?page=${page}&size=10`
          : user.userDetails.roles[0] === "ROLE_THERAPIST"
            ? `notifications/get-Therapist/${user.userDetails.userUniqueId}?page=${page}&size=10`
            : user.userDetails.roles[0] === "ROLE_INTERPRETER"
              ? `notifications/get-Interpreter/${user.userDetails.userUniqueId}?page=${page}&size=10`
              : `notifications/get-Family/${user.userDetails.userUniqueId}?page=${page}&size=10`;
    adminService.getAllNotifications(api).then(
      (res) => {
        if (res.status === "Success") {
          setTotalPage(res.data.totalNoOfPages);
          setNotifications(res.data.notificationHistoryList);
          setLoading(false);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, [page]);
  //console.log("paymentSuccessAdmin", user.userDetails.roles[0] === "ROLE_RCE", Array.isArray(paymentSuccessAdmin), paymentSuccessAdmin)
  const afterSub = () => {
    console.log("test accept 70");
   // setLoading(true);
    let api =
      user.userDetails.roles[0] === "ROLE_RCE"
        ? `notifications/get-Representative?page=${page}&size=10`
        : user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS"
          ? `notifications/get-Representative?page=${page}&size=10`
          : user.userDetails.roles[0] === "ROLE_THERAPIST"
            ? `notifications/get-Therapist/${user.userDetails.userUniqueId}?page=${page}&size=10`
            : user.userDetails.roles[0] === "ROLE_INTERPRETER"
              ? `notifications/get-Interpreter/${user.userDetails.userUniqueId}?page=${page}&size=10`
              : `notifications/get-Family/${user.userDetails.userUniqueId}?page=${page}&size=10`;
    adminService.getAllNotifications(api).then(
      (res) => {
        if (res.status === "Success") {
          setTotalPage(res.data.totalNoOfPages);
          console.log("subApiRes",res?.data?.notificationHistoryList);
          setNotifications(res?.data?.notificationHistoryList);
          //history.push('/notifications');
          setLoading(false);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
      }
    ).catch(e => {
      console.log(e)
      
    })
    .finally(a => {
      console.log('finally', a)
      setLoading(false);
    })
  };

  /*Cancel Session Accept and Deny*/
  const acceptCancelSession = (id, sessionId, familyMemberId, title, item) => {
    let input = {
      sessionId: sessionId,
      notificationId: id,
      familyMemberId: familyMemberId,
      notificationName: title,
    };
    const sessionList = item.messages.split("session_list=")[1].split(",")[0];
    input.sessionList = sessionList == "null" ? null : sessionList;
    let api =
      user.userDetails.roles[0] === "ROLE_THERAPIST"
        ? "therapist/sessions/accept-session-cancel-request/"
        : "sessions/accept-session-cancel-request/";
    setLoading(true);
    adminService.PUT(api + input.sessionId, JSON.stringify(input)).then(
      (res) => {
        if (res.status === "Success") {
          afterSub();
          toast.success(res.data);
          
          //setTimeout(() => {
            
            //setLoading(false);
          //}, 1000);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    console.log("input", input);
  };

  const denyCancelSession = (id, sessionId, familyMemberId) => {

    let input = {
      sessionId: sessionId,
      notificationId: id,
      familyMemberId: familyMemberId,
    };
    let api =
      user.userDetails.roles[0] === "ROLE_THERAPIST"
        ? "therapist/sessions/deny-session-cancel-request/"
        : "sessions/deny-session-cancel-request/";
    setLoading(true);
    adminService.PUT(api + input.sessionId, JSON.stringify(input)).then(
      (res) => {
        if (res.status === "Success") {
          setTimeout(()=>{
            afterSub();
          },1000)
          
          toast.success(res.data);
          
          // setTimeout(() => {
            
          //   setLoading(false);
          // }, 1000);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  //end

  /*Change Session Accept and Deny*/
  const acceptChangeSession = (
    sessionId,
    title,
    startDateTime,
    endDateTime,
    id,
    familyMemberId,
    item
  ) => {

    let inputs = {
      sessionId: sessionId,
      endDateTime: endDateTime,
      startDateTime: startDateTime,
      notificationName: title,
      notificationId: id,
      familyMemberId: familyMemberId,
    };
    console.log("accept session",item.messages);
    //const sessionList = item.messages.split("session_list=")[1].split(",")[0];
    //inputs.sessionList = sessionList == "null" ? null : sessionList;

    let api =
      user.userDetails.roles[0] === "ROLE_THERAPIST"
        ? "therapist/sessions/accept-session/"
        : "sessions/accept-session/";
    setLoading(true);
    adminService.PUT(api + sessionId, JSON.stringify(inputs)).then(
      (res) => {
        if (res.status === "Success") {
          afterSub();
          toast.success(res.data);
          
          document.getElementById(
            `accept-reject-btn-${sessionId}-${id}`
          ).style.visibility = "hidden";
          
          //setTimeout(() => {
            
            //setLoading(false);
          //}, 1000);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const denyChangeSession = (
    sessionId,
    title,
    startDateTime,
    endDateTime,
    familyMemberId,
    id
  ) => {

    let inputs = {
      sessionId: sessionId,
      endDateTime: endDateTime,
      startDateTime: startDateTime,
      notificationName: title,
      familyMemberId: familyMemberId,
      notificationId: id,
    };
    let api =
      user.userDetails.roles[0] === "ROLE_THERAPIST"
        ? "therapist/sessions/deny-session/"
        : "sessions/deny-session/";
    setLoading(true);
    adminService.PUT(api + sessionId, JSON.stringify(inputs)).then(
      (res) => {
        if (res.status === "Success") {
          afterSub();
          toast.success(res.data);
          document.getElementById(
            `accept-reject-btn-${sessionId}-${id}`
          ).style.visibility = "hidden";
          
          // setTimeout(() => {
            
          //   setLoading(false);
          // }, 1000);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  //end

  //Deny Therapist and Interpreter Change request
  const denyTherapistChangeRequest = (familyMemberId, name, sessionId) => {

    let data = {
      familyMemberId: familyMemberId,
      therapistName: name,
      sessionId: sessionId,
    };
    setLoading(true);
    adminService
      .PUT("sessions/deny-therapist-change-request", JSON.stringify(data))
      .then(
        (res) => {
          if (res.status === "Success") {
            afterSub();
            toast.success(res.data);
            // setLoading(false);
            // setTimeout(() => {
              
            // }, 1000);
          } else {
            toast.error(res.message);
            setLoading(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const denyInterpreterChangeRequest = (familyMemberId, name, sessionId) => {

    let data = {
      familyMemberId: familyMemberId,
      interpreterName: name,
      sessionId: sessionId,
    };
    setLoading(true);
    adminService
      .PUT("sessions/deny-interpreter-change-request", JSON.stringify(data))
      .then(
        (res) => {
          if (res.status === "Success") {
            afterSub();
            toast.success(res.data);
            // setLoading(false);
            // setTimeout(() => {
              

            // }, 1000);
          } else {
            toast.error(res.message);
            setLoading(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  //end

  //Accept Therapist amd Interpreter Change Request

  const acceptChangeTherapist = (
    childname,
    familyMemberId,
    therapyId,
    name,
    sessionId
  ) => {
    let info = {
      childName: childname,
      familyMemberId: familyMemberId,
      therapyId: therapyId,
      name: name,
      sessionId: sessionId,
    };
    setDetail((detail) => ({ ...detail, ["info"]: info }));
    setModalOpenClose(true);
    setIsInterpreter(false);
  };
  const acceptChangeInterpreter = (familyMemberId, name, sessionId) => {
    let info = {
      familyMemberId: familyMemberId,
      name: name,
      sessionId: sessionId,
    };
    setDetail((detail) => ({ ...detail, ["info"]: info }));
    setModalOpenClose(true);
    setIsInterpreter(true);
  };
  //end

  const onAccept = (notificationItem) => {
    const {
      title,
      sessionId,
      startDateTime,
      endDateTime,
      name,
      familyMemberId,
      flagName,
      therapyId,
      id,
    } = notificationItem;
    switch (title) {
      case "Request For Change In Therapy Session by Caretaker":
      case "Request For Change In Therapy Session by Interpreter":
        acceptChangeSession(
          sessionId,
          title,
          startDateTime,
          endDateTime,
          id,
          familyMemberId,
          notificationItem
        );
        break;
      case "Request to Change Interpreter by Caretaker":
        acceptChangeInterpreter(
          familyMemberId,
          name,
          sessionId,
          notificationItem
        );
        break;

      case "Request to change Therapist by Caretaker":
        acceptChangeTherapist(
          flagName,
          familyMemberId,
          therapyId,
          name,
          sessionId,
          notificationItem
        );
        break;
      default:
        acceptCancelSession(
          id,
          sessionId,
          familyMemberId,
          title,
          notificationItem
        );
        break;
    }
  };

  const onReject = (notificationItem) => {
    switch (notificationItem.title) {
      case "Request For Change In Therapy Session by Caretaker":
      case "Request For Change In Therapy Session by Interpreter":
        denyChangeSession(
          notificationItem.sessionId,
          notificationItem.title,
          notificationItem.startDateTime,
          notificationItem.endDateTime,
          notificationItem?.familyMemberId,
          notificationItem?.id
        );
        break;
      case "Request to change Therapist by Caretaker":
        denyTherapistChangeRequest(
          notificationItem.familyMemberId,
          notificationItem.name,
          notificationItem.sessionId
        );
        break;
      case "Request to Change Interpreter by Caretaker":
        denyInterpreterChangeRequest(
          notificationItem.familyMemberId,
          notificationItem.name,
          notificationItem.sessionId
        );
        break;
      default:
        denyCancelSession(
          notificationItem.id,
          notificationItem.sessionId,
          notificationItem.familyMemberId
        );
        break;
    }
  };

  useEffect(() => {
    setInputs(inputs);
    console.log(inputs);
  }, [inputs]);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Notifications</div>
            </div>
            {loading && <div className="loader"></div>}
            {!loading && (
              <>
                <table className="table">
                  {notification.length > 0 &&
                    notification &&
                    notification.map((info, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td colSpan="2">
                            <div className="text-left ml-3">
                              <h5 className="mt-2">
                                <NotificationDateLabel date={info.date} />
                              </h5>
                            </div>
                          </td>
                        </tr>
                        {info.notifications &&
                          info.notifications.map((item, i) => (
                            <NotificationRow
                              item={item}
                              loading={loading}
                              key={`${item.id}-${i}`}
                              onAccept={() => {
                                onAccept(item);
                              }}
                              onReject={() => {
                                onReject(item);
                              }}
                              user={user}
                            />
                          ))}
                      </React.Fragment>
                    ))}
                </table>
                {notification.length <= 0 && (
                  <div>
                    <h4 className="text-center name mt-5 mb-5">
                      No notification present
                    </h4>
                  </div>
                )}
                <div className="row">
                  <div
                    className="btn-group filter-next-prev next-prev-session-day-list"
                    role="group"
                  >
                    <button
                      onClick={() => setPage(page - 1)}
                      className="btn next"
                      type="button"
                      disabled={page == 0 ? true : false}
                    >
                      <img
                        className="pr-2"
                        src="../resources/assets/arrow-left.svg"
                        alt=""
                      />
                    </button>
                    <div className="action-devider-button"></div>
                    <button
                      onClick={() => setPage(page + 1)}
                      className="btn prev"
                      type="button"
                      disabled={
                        totalPage == page + 1 || totalPage == 0 ? true : false
                      }
                    >
                      <img
                        className="pl-2"
                        src="../resources/assets/arrow-right.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {modalOpenClose && (
        <AcceptChangeTherapist
          closeModal={() => setModalOpenClose(false)}
          isinterpreter={isinterpreter}
          info={detail}
          afterSub={() => afterSub()}
        />
      )}
    </>
  );
}

export default Notifications;

const NotificationRow = ({ loading, item, onAccept, onReject, user }) => {
  return (
    <tr>
      <td>
        <div className="user-info">
          <span className="admin-name justify-content-start">
            <img
              src={
                item.icon ? item.icon : "../resources/assets/defaultprofile.png"
              }
              className="image"
              alt=""
            />
            <div className="content ml-2">
              <NotificationTitle {...item} user={user} />
              <small className="d-block">
                Time:{" "}
                {getUTCTimeToLocalByDateWithMeridium(item.notificationDateTime)}
              </small>
            </div>
          </span>
        </div>
      </td>
      <td style={{ width: 10 }}>
        {moment().isBefore(moment.utc(item.startDateTime)) && // added to fix the bug for old date accept or reject
          item.clickable && (
            <NotificationAction
              itemSessionId={item.sessionId}
              onAccept={onAccept}
              onReject={onReject}
              item={item}
              loading={loading}
            />
          )}
      </td>
    </tr>
  );
};

const NotificationDateLabel = ({ date }) => {
  if (date === "Today") {
    return <b>Today</b>;
  }
  if (date === "Yesterday") {
    return <b>Yesterday</b>;
  }
  return (
    <>
      <b>
        {
          daysJson[
            parseInt(
              moment(date).isoWeekday() === 0
                ? 6
                : moment(date).isoWeekday() - 1
            )
          ].name
        }{" "}
        {moment(date).format("DD")}{" "}
      </b>
      {monthJson[parseInt(moment(date).format("MM")) - 1].name},{" "}
      {moment(date).format("YYYY")}
    </>
  );
};

const NotificationTitle = ({
  title,
  body,
  startDateTime,
  reason,
  name,
  endDateTime,
  user,
}) => {
  if (user.userDetails.roles[0] === "ROLE_INTERPRETER") {
    if (title === "Accepted Session Change Request By Therapist") {
      let bodyP = body.split(" to");
      if (!bodyP[0].includes(",")) {
        bodyP = bodyP[0] + " to ";
      }
      return (
        <span>
          {bodyP}{" "}
          <b>
            Time: {getUTCTimeToLocalByDateWithMeridium(startDateTime)} -{" "}
            {getUTCTimeToLocalByDateWithMeridium(endDateTime)}
          </b>{" "}
          on <b>{getUTCDateToLocalByDateWithMeridium(startDateTime)}</b>
        </span>
      );
    }
  } else {
    if (title === "Accepted Session Change Request By Therapist") {
      return <span>{body}</span>;
    }
  }
  if (title === "Accepted Session Change Request By Representative") {
    return <span>{body}</span>;
  }

  if (title.toLowerCase() === "session create request by caretaker") {
    // let bodyP = body.split(" on");
    // if (!bodyP[0].includes(",")) {
    //   bodyP = bodyP[0] + ",";
    // }
    return (
      <span>
        <b>{name}</b>
        {body}
        <b>
          Time: {getUTCTimeToLocalByDateWithMeridium(startDateTime)} -{" "}
          {getUTCTimeToLocalByDateWithMeridium(endDateTime)}
        </b>{" "}
        on <b>{getUTCDateToLocalByDateWithMeridium(startDateTime)}</b>
        <br />
        <span>
          <b>Request Note:</b>
          <span></span>
        </span>{" "}
        "
        <span style={{ fontStyle: "italic" }}>
          <b>{reason}</b>
        </span>
        "
      </span>
    );
  }
  if (body.includes("wants to cancel Schedule")) {
    return (
      <>
        <span>
          <b>{name}</b> {body}
        </span>
        <span>
          {/* for{" "} */}
          <b>
            Time: {getUTCTimeToLocalByDateWithMeridium(startDateTime)} -{" "}
            {getUTCTimeToLocalByDateWithMeridium(endDateTime)}
          </b>{" "}
          on <b>{getUTCDateToLocalByDateWithMeridium(startDateTime)}</b>
        </span>
        <br />
        <span>
          <b>Reason:</b> {reason}
        </span>
      </>
    );
  }
  if (body.includes("wants to change Schedule")) {
    return (
      <>
        <span>
          <b>{name}</b> {body}
        </span>
        <span>
          <br />
          <b>
            Time: {getUTCTimeToLocalByDateWithMeridium(startDateTime)} -{" "}
            {getUTCTimeToLocalByDateWithMeridium(endDateTime)}
          </b>{" "}
          on <b>{getUTCDateToLocalByDateWithMeridium(startDateTime)}</b>
        </span>
      </>
    );
  }
  if (body.includes("Session has been cancelled by")) {
    return (
      <>
        <span>{body}</span>
        {reason && (
          <>
            {reason.includes("Reason for cancelling the Session is : null") ? (
              <></>
            ) : (
              <>
                <br />
                <span>
                  <b>Reason:</b>{" "}
                  {reason.includes("Reason for cancelling the Session is :")
                    ? reason.replace(
                      "Reason for cancelling the Session is :",
                      ""
                    )
                    : reason}
                </span>
              </>
            )}
          </>
        )}
      </>
    );
  }
  if (title === "Payments Notification Success") {
    return (
      <span>{body} {moment.utc(startDateTime).utc().local().format(
        "DD MMMM YYYY, hh:mm a"
      )} session has been successfully completed.</span>
    )
  }
  if (title === "Payments Notification Failure") {
    return (
      <span>{body} {moment.utc(startDateTime).utc().local().format(
        "DD MMMM YYYY, hh:mm a"
      )} session has been failed.</span>
    )
  }
  return <span>{body}</span>;
};

const NotificationAction = ({ itemSessionId, onAccept, onReject, item,loading }) => {
  return (
    <div
      className="d-flex align-items-center"
      id={`accept-reject-btn-${itemSessionId}-${item.id}`}
    >
      {onAccept && (
        <button
          className="btn btn-primary custom-btn-yellow"
          onClick={onAccept}
          disabled={loading ? true : false}
        >
          Accept
        </button>
      )}
      {onReject && (
        <button
          className="btn btn-primary btn-rounded-black ml-3"
          onClick={onReject}
          disabled={loading ? true : false}
        >
          <img
            src="../resources/assets/Icon ionic-md-close.svg"
            alt="cancel"
            className="Icon-ionic-md-close"
          />
        </button>
      )}
    </div>
  );
};
