const fileDownloadHandler = async (pictureUrl) => {
  console.log(pictureUrl);
  const response = await fetch(pictureUrl, {
    mode: "cors",
    "Access-Control-Allow-Origin": "*",
  });
  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "file";
    a.click();
  });
};

// var fs = require("fs"),
//   request = require("request");

// var download1 = function (uri, filename, callback) {
//   request.head(uri, function (err, res, body) {
//     console.log("content-type:", res.headers["content-type"]);
//     console.log("content-length:", res.headers["content-length"]);

//     request(uri);
//   });
// };

function download(source) {
  const fileName = source.split("/").pop();
  var el = document.createElement("a");
  el.setAttribute("href", source);
  el.setAttribute("download", fileName);
  document.body.appendChild(el);
  el.click();
  el.remove();
}

/* Helper function */
function download_file(pictureUrl) {
  // for non-IE
  var fileURL =
    "https://meisdevprivate.s3.us-east-2.amazonaws.com/e91b9d58-c2ab-4be7-b128-412a1dd2fad6_IMG_0002.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220124T080031Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Credential=AKIA6E2XYX3LARFUJ7FK%2F20220124%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=5577d964e7ad535da2ee208180b76615cb1e0beff0886f6dddd89e122ff0f2f4";
  var fileName = "brochure.png";

  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
}

// download('https://www.google.com/images/srpr/logo3w.png', 'google.png', function(){
//   console.log('done');
// });

export { fileDownloadHandler, download, download_file };
