import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../../../components/digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../../services/adminService";
import moment from "moment";
import CreateTemplate from "../../FormElements/CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../../FormElements/CommonComponents";
import SignDate from "../../FormElements/SignDate";

function ConsultationDocumentationForm(props) {
  const formRef = React.useRef(null);
  const { register, errors, handleSubmit } = useForm();
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "ConsultationDocumentationForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };

  const [familyInfo, setFamilyInfo] = useState({});
  const updateInfo = (e) => {
    const { name, value } = e.target;
    setFamilyInfo({ ...familyInfo, [name]: value });
  };

  return (
    <>
      <div className={`col-md-12`} id="my-code" id="my-code" ref={formRef}>
        {props?.data?.family?.id && (
          <HeaderDownloadButtons startDownload={startDownload} />
        )}
        <FormHeader logoUrl={logoUrl} title="Consultation Documentation" />
        <hr />
        <div className="card-lg page1">
          <div className="pdf-header" style={{ textAlign: "center" }}>
            {/* <b>Consultation Documentation</b> */}
            <p>
              (To be completed by those participating in consultation session)
            </p>
          </div>
          <div className="pdf-para1">
            <p>
              <b>
                Parent was notified and invited to participate on
                <input
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "2px solid #222",
                  }}
                  ref={register({ required: true })}
                  type="text"
                  placeholder="Print name"
                  name="familyName"
                  className={`form-control ${
                    errors.familyName && "is-invalid"
                  }`}
                  defaultValue={familyInfo.familyName}
                  onChange={(e) => updateInfo(e)}
                />{" "}
                by (method)
                <input
                  type="text"
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "2px solid #222",
                  }}
                />
              </b>
            </p>
            <p style={{ padding: 5, border: "1px solid #222" }}>
              <b>
                If the consultation meeting will potentially result in change of
                outcomes or services, the Primary Service Provider will contact
                Service Coordinator prior to meeting. Service Coordinator
                contacted on{" "}
                <input
                  type="text"
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "2px solid #222",
                  }}
                />{" "}
                by (method){" "}
                <input
                  type="text"
                  style={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "2px solid #222",
                  }}
                />
              </b>
            </p>
            <div style={{ display: "inline-block", width: "100%" }}>
              <br />
              <div style={{ display: "block", width: "50%", float: "left" }}>
                <p>
                  <>
                    Child’s Name:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  <>
                    Service Coordinator:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    Start Time:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                    End Time:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <p>
                  <>
                    DOB:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    Date of Consultation:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    Location:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
              </div>
            </div>
            <p style={{ paddingLeft: 50, marginTop: 15 }}>
              <div style={{ display: "inline-block", width: "100%" }}>
                <b>
                  • Successes to implementing strategies and achieving goals for
                  Outcome #{" "}
                  <input
                    type="text"
                    style={{
                      width: 80,
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </b>
              </div>
              <div style={{ display: "inline-block", width: "100%" }}>
                <input
                  type="textarea"
                  rows="4"
                  cols="50"
                  style={{
                    width: "100%",
                    height: "60px",
                    display: "block",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                ></input>
              </div>
            </p>
            <p style={{ paddingLeft: 50, marginTop: 15 }}>
              <div style={{ display: "inline-block", width: "100%" }}>
                <b>
                  • Successes to implementing strategies and achieving goals for
                  Outcome #{" "}
                  <input
                    type="text"
                    style={{
                      width: 80,
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </b>
              </div>
              <div style={{ display: "inline-block", width: "100%" }}>
                <input
                  type="textarea"
                  rows="4"
                  cols="50"
                  style={{
                    width: "100%",
                    height: "60px",
                    display: "block",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                ></input>
              </div>
            </p>
            <div style={{ display: "inline-block", width: "100%" }}>
              <p>
                The <b>team</b> (family, caregivers, primary service provider
                and supporting providers)
                <b>
                  {" "}
                  will continue or modify the following strategies to achieve
                  goals for Outcome #
                </b>
                <input
                  type="text"
                  style={{
                    width: 80,
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                />
              </p>
            </div>
            <div style={{ display: "inline-block", width: "100%" }}>
              <input
                type="textarea"
                rows="4"
                cols="50"
                style={{
                  width: "100%",
                  height: "60px",
                  display: "block",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              ></input>
            </div>
            <p style={{ marginTop: 15 }}>
              IFSP Team meeting is needed to discuss recommended changes in
              services, frequency, and/or duration of services:
            </p>
            <p>
              <div style={{ display: "inline-block", width: "100%" }}>
                <input type="checkbox" /> YES <input type="checkbox" /> NO
              </div>
            </p>
            <div
              style={{
                display: "inline-block",
                width: "100%",
                border: "1px solid #222",
                padding: 5,
              }}
            >
              <p>
                Participating Team Members/Signatures: (PSP indicated with *)
              </p>
              <div style={{ display: "block", width: "50%", float: "left" }}>
                <p>
                  <>
                    Parent/ Guardian:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  <>
                    OT:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    SLP:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  Service Coordinator:{" "}
                  <input
                    type="text"
                    style={{
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </p>
              </div>
              <div style={{ display: "block", width: "50%", float: "left" }}>
                <p>
                  <>
                    ITDS:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  <>
                    PT:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    EI:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  Other:{" "}
                  <input
                    type="text"
                    style={{
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </p>
              </div>
            </div>
            <div
              style={{ display: "inline-block", width: "100%", marginTop: 15 }}
            >
              <p>
                <small
                  style={{ display: "inline-block", verticalAlign: "top" }}
                >
                  Copy to:
                </small>{" "}
                <small style={{ display: "inline-block" }}>
                  Family/ Guardian <br />
                  Early Steps Service
                  <br />
                  Coordinator within 5 business days
                  <br />
                  Team Providers (whether present or not)
                </small>
              </p>
            </div>
          </div>
        </div>

        <div className="card-lg page2">
          <div className="pdf-header" style={{ textAlign: "center" }}>
            <b style={{ fontSize: "120%" }}>
              Consultation Documentation, Continued
            </b>
          </div>
          <div className="pdf-para1">
            <div style={{ display: "inline-block", width: "100%" }}>
              <br />
              <div style={{ display: "block", width: "50%", float: "left" }}>
                <p>
                  <>
                    Child’s Name:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  <>
                    Service Coordinator:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <p>
                  <>
                    DOB:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    Date of Consultation:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
              </div>
            </div>

            <p style={{ paddingLeft: 50, marginTop: 15 }}>
              <div style={{ display: "inline-block", width: "100%" }}>
                <b>
                  • Successes to implementing strategies and achieving goals for
                  Outcome #{" "}
                  <input
                    type="text"
                    style={{
                      width: 80,
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </b>
              </div>
              <div style={{ display: "inline-block", width: "100%" }}>
                <input
                  type="textarea"
                  rows="4"
                  cols="50"
                  style={{
                    width: "100%",
                    height: "60px",
                    display: "block",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                ></input>
              </div>
            </p>
            <p style={{ paddingLeft: 50, marginTop: 15 }}>
              <div style={{ display: "inline-block", width: "100%" }}>
                <b>
                  • Successes to implementing strategies and achieving goals for
                  Outcome #{" "}
                  <input
                    type="text"
                    style={{
                      width: 80,
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </b>
              </div>
              <div style={{ display: "inline-block", width: "100%" }}>
                <input
                  type="textarea"
                  rows="4"
                  cols="50"
                  style={{
                    width: "100%",
                    height: "60px",
                    display: "block",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                ></input>
              </div>
            </p>
            <div style={{ display: "inline-block", width: "100%" }}>
              <p>
                The <b>team</b> (family, caregivers, primary service provider
                and supporting providers)
                <b>
                  {" "}
                  will continue or modify the following strategies to achieve
                  goals for Outcome #
                </b>
                <input
                  type="text"
                  style={{
                    width: 80,
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                />
              </p>
            </div>
            <div style={{ display: "inline-block", width: "100%" }}>
              <input
                type="textarea"
                rows="4"
                cols="50"
                style={{
                  width: "100%",
                  height: "60px",
                  display: "block",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              ></input>
            </div>

            <p style={{ paddingLeft: 50, marginTop: 15 }}>
              <div style={{ display: "inline-block", width: "100%" }}>
                <b>
                  • Successes to implementing strategies and achieving goals for
                  Outcome #{" "}
                  <input
                    type="text"
                    style={{
                      width: 80,
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </b>
              </div>
              <div style={{ display: "inline-block", width: "100%" }}>
                <input
                  type="textarea"
                  rows="4"
                  cols="50"
                  style={{
                    width: "100%",
                    height: "60px",
                    display: "block",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                ></input>
              </div>
            </p>
            <p style={{ paddingLeft: 50, marginTop: 15 }}>
              <div style={{ display: "inline-block", width: "100%" }}>
                <b>
                  • Successes to implementing strategies and achieving goals for
                  Outcome #{" "}
                  <input
                    type="text"
                    style={{
                      width: 80,
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </b>
              </div>
              <div style={{ display: "inline-block", width: "100%" }}>
                <input
                  type="textarea"
                  rows="4"
                  cols="50"
                  style={{
                    width: "100%",
                    height: "60px",
                    display: "block",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                ></input>
              </div>
            </p>

            <div style={{ display: "inline-block", width: "100%" }}>
              <p>
                The <b>team</b> (family, caregivers, primary service provider
                and supporting providers)
                <b>
                  {" "}
                  will continue or modify the following strategies to achieve
                  goals for Outcome #
                </b>
                <input
                  type="text"
                  style={{
                    width: 80,
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderBottom: "1px solid #222",
                  }}
                />
              </p>
            </div>
            <div style={{ display: "inline-block", width: "100%" }}>
              <input
                type="textarea"
                rows="4"
                cols="50"
                style={{
                  width: "100%",
                  height: "60px",
                  display: "block",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              ></input>
            </div>

            <p style={{ marginTop: 15 }}></p>

            <div
              style={{
                display: "inline-block",
                width: "100%",
                border: "1px solid #222",
                padding: 5,
              }}
            >
              <p>
                <b>Billing Information:</b>{" "}
              </p>
              <div style={{ display: "block", width: "40%", float: "left" }}>
                <p>
                  <>
                    Billing Information:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  <>
                    Service Provided (Taxonomy Code:{" "}
                    <input
                      type="text"
                      style={{
                        width: "100px",
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                    )
                  </>
                </p>
                <p>
                  <>
                    Natural Environment Support Fee
                    <input
                      type="text"
                      style={{
                        width: "100px",
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                    )
                  </>
                </p>
                <p>
                  <>
                    Travel (MapQuest Attached) (Total Miles
                    <input
                      type="text"
                      style={{
                        width: "50px",
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                    )
                  </>
                </p>
                <p style={{ textAlign: "center" }}>Total Amount Requested</p>
              </div>
              <div style={{ display: "block", width: "40%", float: "left" }}>
                <p>
                  <>
                    <>
                      Diagnosis Code{" "}
                      <input
                        type="text"
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderBottom: "1px solid #222",
                        }}
                      />
                    </>
                  </>
                </p>
                <p>
                  <>
                    ${" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    ${" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  ${" "}
                  <input
                    type="text"
                    style={{
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </p>
                <p>
                  ${" "}
                  <input
                    type="text"
                    style={{
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </p>
              </div>
            </div>
            <div
              style={{ display: "inline-block", width: "100%", marginTop: 15 }}
            >
              <p>
                <small
                  style={{ display: "inline-block", verticalAlign: "top" }}
                >
                  Copy to:
                </small>{" "}
                <small style={{ display: "inline-block" }}>
                  Family/ Guardian <br />
                  Early Steps Service
                  <br />
                  Coordinator within 5 business days
                  <br />
                  Team Providers (whether present or not)
                </small>
              </p>
            </div>
          </div>
        </div>

        <div className="card-lg page3">
          <div className="pdf-header" style={{ textAlign: "center" }}>
            <b style={{ display: "block" }}>
              CONSULTATION DOCUMENTATION FORM INSTRUCTIONS
            </b>
            <br />
            <p>
              The purpose of this form is to serve as uniform documentation of
              consultation services. Each team member who is billing must have a
              form completed for each Consultation in which they participate.
              During consultation sessions, the members participating should
              appoint a recorder to LEGIBLY complete the form from Child’s Name
              to IFSP Team Meeting Yes No. Copies should then be made for each
              participant and the family. The original goes to the Service
              Coordinator to place in the child’s file. Each enrolled Early
              Steps provider can bill for Consultation using the form as invoice
              documentation. Although they may participate in the consultation,
              professionals and providers who are not enrolled would not be able
              to bill. If any team provider did not participate in the
              Consultation session, a copy should be provided to them so they
              can be informed.
            </p>
          </div>
          <div className="pdf-para1">
            <div
              style={{ display: "inline-block", width: "100%", marginTop: 15 }}
            >
              <p>
                <b>Instructions:</b>
              </p>
              <br />
              <div style={{ display: "block", width: "50%", float: "left" }}>
                <p>
                  <>
                    Child’s Name:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />{" "}
                  </>
                </p>
                <p>
                  <>
                    Service Coordinator:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    Start Time:{" "}
                    <input
                      type="time"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <p>
                  <>
                    DOB:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    Date of Consultation:{" "}
                    <input
                      type="text"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
                <p>
                  <>
                    End Time:{" "}
                    <input
                      type="time"
                      style={{
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderBottom: "1px solid #222",
                      }}
                    />
                  </>
                </p>
              </div>
            </div>
            <p>
              Location:{" "}
              <input
                type="text"
                style={{
                  width: "calc(100% - 120px)",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              />
            </p>
            <p>
              Successes and Challenges to implementing strategies and achieving
              goals:{" "}
              <input
                type="text"
                style={{
                  width: "400px",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              />
            </p>
            <p>
              The team (family, caregivers, primary service provider and
              supporting providers) will continue or modify the following
              strategies to achieve goals:{" "}
              <input
                type="text"
                style={{
                  width: "500px",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              />
            </p>
            <p>
              PSP:{" "}
              <input
                type="text"
                style={{
                  width: "500px",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              />
            </p>
            <p>
              Consulting Team Members:{" "}
              <input
                type="text"
                style={{
                  width: "500px",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              />
            </p>
            <p>
              Family Participation:{" "}
              <input
                type="text"
                style={{
                  width: "500px",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: "1px solid #222",
                }}
              />
            </p>
            <p style={{ marginTop: 30, fontSize: "120%" }}>
              <b>
                <u>
                  ALL THE ABOVE FIELDS SHOULD BE IDENTICAL FOR ALL PARTICIPANTS’
                  FORMS
                </u>
              </b>
            </p>
            <p>
              When each provider receives their copy of the completed form, they
              will complete the remaining fields before billing.
            </p>
            <div
              style={{ display: "inline-block", width: "100%", marginTop: 15 }}
            >
              <br />
              <div style={{ display: "block", width: "60%", float: "left" }}>
                <p>
                  Provider/Participant Name (Print):{" "}
                  <input
                    type="text"
                    style={{
                      width: "200px",
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </p>
              </div>
              <div style={{ display: "block", width: "40%", float: "left" }}>
                <p>
                  Signature:{" "}
                  <input
                    type="text"
                    style={{
                      width: "calc(100% - 120px)",
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  />
                </p>
              </div>
            </div>
            <b style={{ textAlign: "center", display: "block" }}>
              <i>
                Each participant should find their designation and sign, if
                face-to-face. Provider signature lines should include the code
                signifying if participation was Face-to-Face or Phone{" "}
              </i>
            </b>

            <p style={{ textAlign: "center" }}>
              Consultation time must be authorized on the Individualized Family
              Support Plan (IFSP).Billing is based on the location of the
              Consultation session.
            </p>
          </div>

          <hr />
          <FormFooter
            address={joinWithComma(address1, address2, city, country, zipCode)}
          />
          {!props?.isFormOpen && (
            <button
              className="btn btn-theme-green mb-10"
              onClick={() => props?.handleBack()}
              type="button"
            >
              Back
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default ConsultationDocumentationForm;
