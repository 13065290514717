import React, { useState } from "react";
import { WhiteWebSdk, RoomWhiteboard } from "white-react-sdk";
import {
  useAgoraState,
  useAgoraMutation,
} from "../../components/agora/utils/container";
import RTCClient from "../../components/agora/rtc-client";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function MarketingWhiteBoard(props) {
  const params = useParams();
  const stateCtx = useAgoraState();
  console.log("agora statectx", stateCtx)
  const localClient = React.useMemo(() => {
    const client = new RTCClient();
    if (!client._created) {
      client.createClient({ codec: stateCtx.codec, mode: stateCtx.mode });
      client._created = true;
    }
    return client;
  }, [stateCtx.codec, stateCtx.mode]);
  const [roomInfo, setRoomInfo] = useState({});
  const AK = "ZD4NN2m1VcLr7eJd";
  // "dZUN8MSlGHTJaPAK";
  const SK = "KjLKYOEV2flHvjllPN64LgODTVOtoX4c";
  // "0wdOtBx-Y4iSMFGfm1ZTzb8vRnxlOlwu";
  // static OSSREGION=oss region
  // static BUCKET=oss bucket
  // static FOLDER=oss folder
  // static PREFIX=oss url
  const APPIDENTIFIER = "_3EmAIMVEey3wuEl352N4w/IBqE1yBUqkTIaA";
  // "_gs88EhHEeyzN-cKaw1vLQ/pFBKokc2kAt5XQ";

  const sdkToken =
    "NETLESSSDK_YWs9WkQ0Tk4ybTFWY0xyN2VKZCZub25jZT0yYzRlMmRkMC05Yjc2LTExZWMtOTgwOS00ZmRhZWU0ZWViYjYmcm9sZT0wJnNpZz1lYTExMDUxYTNlYTk0NWJlOTExMzNhMzIyNTVkZjRjYzUxMTAxODEwOWZlOTk4ZWE5N2Q3OTFlZjQyZTQ1NThj";

  // "NETLESSSDK_YWs9ZFpVTjhNU2xHSFRKYVBBSyZub25jZT0yMTQzYjVkMC00ODRkLTExZWMtYjMzNy1lNzBhNmIwZDZmMmQmcm9sZT0wJnNpZz0zNDQ1YzkzNjA4NzZjYjllMDk2YmIzNjVmZTkzZTc4N2M3MjEwZDgyNDM1ZGZlMTdmMTVkZWFmOTE0N2JkNTAx";
  const whiteWebSdk = new WhiteWebSdk({
    appIdentifier: "_gs88EhHEeyzN-cKaw1vLQ/pFBKokc2kAt5XQ",
    region: "in-mum",
  });

  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }

  const [state, setState] = React.useState({});

  React.useEffect(() => {
    connectRoom();
  }, []);

  const connectRoom = () => {
    var _uid = localClient._uid;
    // var self = this;
    var roomUUID = "";

    createRoom()
      .then(function (roomJSON) {
        // The room is created successfully, get the roomJSON describing the content of the room
        roomUUID = roomJSON.uuid;
        return createRoomToken(roomUUID);
      })
      .then(function (roomToken) {
        // The roomToken of the room has been checked out
        setRoomInfo({
          uuid: roomUUID,
          roomToken: roomToken,
          uid: `${_uid}`,
        });
        const info = whiteWebSdk.joinRoom({
          uuid: roomUUID,
          roomToken: roomToken,
          uid: `${_uid}`,
        });
        return info;
      })
      .then(function (room) {
        setState({ room: room });
        getToolBar(room);
      })
      .catch(function (err) {
        // failed to create room
        console.error("err, ===== ", err);
      });
  };

  const getToolBar = (room) => {
    var toolbar = document.getElementById("toolbar");
    var toolNames = [
      "arrow",
      "ellipse",
      "eraser",
      "hand",
      "laserPointer",
      "pencil",
      "rectangle",
      "selector",
      "shape",
      "straight",
      "text",
    ];

    for (var idx in toolNames) {
      var toolName = toolNames[idx];
      var btn = document.createElement("BUTTON");
      btn.setAttribute("id", "btn" + toolName);
      btn.setAttribute("class", "save-whiteboard-btn");
      var t = document.createTextNode(toolName);
      btn.appendChild(t);

      // Listen for the event of clicking a button.
      btn.addEventListener("click", function (obj) {
        var ele = obj.target;
        // Call the setMemberState method to set the whiteboard tool.
        room.setMemberState({
          currentApplianceName: ele.getAttribute("id").substring(3),
          shapeType: "pentagram",
          strokeColor: [0, 0, 225],
          strokeWidth: 1,
          textSize: 40,
          defaultStrokeWidth: 1,
          defaultStrokeColor: [0, 0, 225],
        });
      });
      toolbar.appendChild(btn);
    }
  };

  const createRoom = () => {
    var url = "https://api.netless.link/v5/rooms";
    var requestInit = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: sdkToken,
        region: "in-mum",
      },
    };
    return window.fetch(url, requestInit).then(function (response) {
      const res = response.json();
      return res;
    });
  };

  const createRoomToken = (roomUUID) => {
    var url = "https://api.netless.link/v5/tokens/rooms/" + roomUUID;
    var requestInit = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: sdkToken,
        region: "in-mum",
      },
      body: JSON.stringify({
        lifespan: 0, // indicates that the Room Token will never expire
        role: "admin", // indicates that Room Token has Admin authority
      }),
    };
    return window.fetch(url, requestInit).then(function (response) {
      return response.json();
    });
  };
  console.log("params", params.id);
  const saveCanvas = () => {
    const canvasSave = document.getElementsByTagName("canvas")[0];
    const d = canvasSave.toDataURL("image/png");
    // const w = window.open('about:blank', 'image from canvas');
    var a = document.createElement("a");
    a.href = d;
    a.download = "output.png";
    document.body.appendChild(a);
    a.click();
    a.remove();
    // api => sessions-board
    // const formData = new formData()

    const formData = new FormData();
    formData.append(
      "SessionBoardForm",
      new Blob([JSON.stringify({ sessionId: params.sessionid })])
    );
    formData.append("whiteBoard", d);
    const data = { sessionId: parseInt(params.sessionid), whiteBoardBase64: d };
    console.log("?????", data, typeof params.sessionid);
    adminService
      .onboardUserJSONContent(`marketing/sessions-board`, JSON.stringify(data))
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("savedddddddddd");
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log("Error >>>>>>>", error);
        }
      );
    // w.document.write("<img src='"+d+"' alt='from canvas'/>");
    console.log("Saved!", formData);
  };

  const addImage = () => {
    whiteWebSdk
      .joinRoom({
        uuid: roomInfo.uuid,
        roomToken: roomInfo.roomToken,
        uid: `${roomInfo.uid}`,
      })
      .then(function (room) {
        room.bindHtmlElement(document.getElementById("whiteboard"));
        // Create new scenes and insert them to the specified scene directory.
        // var scenes = [
        //   {
        //     name: "new-page5",
        //     ppt: {
        //       width: 720,
        //       height: 640,
        //       src: "https://qa-tenant-1.techment.com/resources/assets/left-login-img.jpg",
        //     },
        //   },
        // ];
        // room.putScenes("/", scenes);

        // // Output a list of all scenes in the room.
        // var scenemap = room.entireScenes();
        // setState({ room: room });
        // console.log("scene map:", scenemap);

        var width = 1280;
        var height = 720;
        var imageURLs = [
          "https://qa-tenant-1.techment.com/resources/assets/left-login-img.jpg",
          "http://www.africau.edu/images/default/sample.pdf",
          "https://qa-tenant-1.techment.com/resources/assets/left-login-img.jpg",
          "https://qa-tenant-1.techment.com/resources/assets/left-login-img.jpg",
          "https://qa-tenant-1.techment.com/resources/assets/left-login-img.jpg",
          "https://qa-tenant-1.techment.com/resources/assets/left-login-img.jpg",
        ];
        var scenes = imageURLs.map(function (url, index) {
          return {
            name: "" + (index + 1),
            ppt: {
              src: url,
              width: width,
              height: height,
            },
          };
        });

        // Add the scenes to a specified scene directory.
        room.putScenes("/AgoraFPA", scenes);

        // Switch to the first newly inserted scene.
        room.setScenePath("/AgoraFPA/1");

        // Define the "Next Page" and "Previous Page" buttons.
        var nextpage = document.getElementById("nextpage");
        var prepage = document.getElementById("prepage");

        // Click the "Next Page" button to switch to the next scene.
        nextpage.addEventListener("click", function () {
          var nextIndex = room.state.sceneState.index + 1;
          var scenes = room.state.sceneState.scenes;

          if (nextIndex < scenes.length) {
            room.setSceneIndex(nextIndex);
          }
        });

        // Click the "Previous Page" button to switch to the previous scene.
        prepage.addEventListener("click", function () {
          var preIndex = room.state.sceneState.index - 1;
          var scenes = room.state.sceneState.scenes;

          if (preIndex >= 0) {
            room.setSceneIndex(preIndex);
          }
        });
      })
      .catch(function (err) {
        console.error(err);
      });
  };
  console.log("room..", state?.room);

  const uploadPdf = () => {
    let options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: sdkToken,
        region: "in-mum",
      },
      body: JSON.stringify({
        resource: "http://www.africau.edu/images/default/sample.pdf",
        type: "static",
        preview: true,
        scale: 1,
        outputFormat: "jpg",
      }),
    };
    const responseData = window
      .fetch(`https://api.netless.link/v5/services/conversion/tasks`, options)
      .then(function (response) {
        const res = response.json();
        console.log("response123", response, res);
        return res;
      });

    responseData
      .then((r) => {
        console.log("response12345...", r);
        let options1 = {
          method: "GET",
          headers: {
            "content-type": "application/json",
            token: sdkToken,
            region: "in-mum",
          },
        };
        setTimeout(() => {
          if (r.uuid) {
            const responseData1 = window
              .fetch(
                `https://api.netless.link/v5/services/conversion/tasks/${r.uuid}?type=static`,
                options1
              )
              .then(function (response) {
                const res = response.json();
                console.log("response123456", response, res);
                return res;
              });

            responseData1
              .then((r) => console.log("response12345678", r))
              .catch((e) => console.log("ee", e));
          }
        }, 10000);
      })
      .catch((e) => console.log("er", e));
  };

  return (
    <>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-md-2">
            {/* <button type="button" onClick={() => uploadPdf()}>
          uploadPdf
        </button>
        <button type="button" onClick={() => addImage()}>
          addImage
        </button>
        <button type="button" id="prepage">
          prev
        </button>
        <button type="button" id="nextpage">
          next
        </button> */}
            <button
              type="button"
              className="save-whiteboard-btn"
              onClick={() => saveCanvas()}
            >
              <img src="../../../resources/assets/1.png" alt="" />
            </button>
            <div id="toolbar" style={{ zIndex: 10 }}></div>
          </div>
          <div className="col-md-10">
            {state.room ? (
              <div className="white-board-container" id="whiteboard">
                <>
                  <RoomWhiteboard
                    room={state.room}
                    style={{
                      width: "100%",
                      height: "100vh",
                    }}
                  />
                </>
              </div>
            ) : <div className="loader"></div>}
          </div>

        </div>
      </div>
    </>
  );
}

export default MarketingWhiteBoard;
