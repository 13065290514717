import React from 'react'
import classNames from 'classnames'
const CaseNoteInfoCol = ({ label, value ,noValue, fixed }) => {
    return (
        <div className={classNames("p-case-note-detail__info-col", {
            "p-case-note-detail__info-col--fixed-width": fixed
        })}>
            <p className="p-case-note-detail__info-label">{label}</p>
            {!noValue && <p className="p-case-note-detail__info-value">{value}</p>}
        </div>
    )
}

export default CaseNoteInfoCol