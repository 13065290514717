import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { adminService } from "../../services/adminService";

function TeamChangeRequest(props) {
  let therapistname =
    props.info.details.firstName + " " + props.info.details.lastName;
  let id = props.info.details.id;
  let familyMemberId = props.info.details.familyMemberId;

  const [childDetail, setChildDetail] = useState([]);
  const [therapyservicedetail, setTherapyServiceDetail] = useState([]);
  const [loading, setloading] = useState(false);
  const [inputs, setInputs] = useState({
    therapistName: therapistname,
    reason: "",
    therapistId: id,
    familyMemberId: familyMemberId,
    therapyId: "",
    childName: "",
  });
  const [input, setInput] = useState({
    interpreterName: therapistname,
    familyMemberId: familyMemberId,
    reason: "",
    interpreterId: id,
  });

  const submitreqfortherapist = () => {
    if (inputs.reason === "" && inputs.therapyId === "") {
      toast.warn("Please add your reason!");
    } else {
      adminService
        .PUT(`family/sessions/therapist-change-request`, JSON.stringify(inputs))
        .then(
          (res) => {
            if (res.status === "Success") {
              toast.success(res.data);
              props.closeModal();
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };
  const submitreqforinterpreter = () => {
    if (inputs.reason === "") {
      toast.warn("Please add your reason!");
    } else {
      adminService
        .PUT(
          `family/sessions/interpreter-change-request`,
          JSON.stringify(input)
        )
        .then(
          (res) => {
            if (res.status === "Success") {
              toast.success(res.data);
              props.closeModal();
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  useEffect(() => {
    setloading(true);
    adminService.getAll(`families/detail`).then(
      (res) => {
        if (res.status === "Success") {
          setChildDetail(res.data.familyMembers);
          let familyMembersObj = [...res.data.familyMembers];
          let fileindex1 = familyMembersObj.findIndex(
            (obj) => obj.id === familyMemberId
          );

          setTherapyServiceDetail(
            res.data.familyMembers[fileindex1].familyMemberTherapies.filter(
              (item) => item.therapist.userProfile.id === id
            )
          );
          setloading(false);
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "therapyId") {
      setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
      //setInput(input => ({ ...input, [name]: value }));
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
      setInput((input) => ({ ...input, [name]: value }));
    }
  }

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  useEffect(() => {
    setInput(input);
  }, [input]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.closeModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <form>
        <div className="col-md-2 custom-modal">
          {/* Add Modal */}
          <div
            className="modal fade add-new-prop-modal show "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="addservice w-100 text-center m-auto">
                    <h5 className="modal-title name" id="exampleModalLongTitle">
                      Change {props.isinterpreter ? "Interpreter" : "Therapist"}
                    </h5>
                  </div>
                  <button
                    onClick={() => props.closeModal(false)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <div className="close-background">
                      <p aria-hidden="true" className="cross">
                        &times;
                      </p>
                    </div>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 mt-3 ">
                      <h5>
                        <span className="name">
                          {props.isinterpreter ? "Interpreter" : "Therapist"}{" "}
                          Name:
                        </span>{" "}
                        {props.info.details.firstName}{" "}
                        {props.info.details.lastName}
                      </h5>
                    </div>
                  </div>

                  {props.isinterpreter ? (
                    <></>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Service Type</label> (
                          <span className="required-span">*</span>)
                          <div className="d-flex">
                            <img
                              className="pr-3"
                              src="../../resources/assets/user-icon-blue.svg"
                              alt=""
                            />
                            <div className="input-group">
                              <select
                                className="custom-select"
                                required
                                onChange={handleChange}
                                name="therapyId"
                              >
                                <option value="">Select Service Type</option>
                                {therapyservicedetail &&
                                  therapyservicedetail.map((info) => (
                                    <option value={info.therapy.id}>
                                      {info.therapy.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-12">
                      <div className=" ">
                        {/* <label>Reason</label> (<span className="required-span">*</span>)
                                                    <textarea className="form-control"
                                                    onChange={handleChange}
                                                    type="text" name="reason">
                                                </textarea> */}
                        <label className="name">Reason</label> (
                        <span className="required-span">*</span>)
                        <div className="input-group ">
                          <select
                            className="custom-select"
                            onChange={handleChange}
                            name="reason"
                          >
                            <option value="">Select Reason</option>
                            <option value="Treatment is not showing progress">
                              Treatment is not showing progress
                            </option>
                            <option value="Lack of knowledge">
                              Lack of knowledge
                            </option>
                            <option value="Poor communication">
                              Poor communication
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center mb-5">
                  <hr className="col-md-12" />
                  {props.isinterpreter ? (
                    <button
                      type="button"
                      onClick={submitreqforinterpreter}
                      className="btn btn-primary custom-btn-green submitreqbutton mt-3"
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={submitreqfortherapist}
                      className="btn btn-primary custom-btn-green submitreqbutton mt-3"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default TeamChangeRequest;
