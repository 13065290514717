import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { adminService } from '../../services/adminService';
import { toast } from "react-toastify";
import moment from "moment-timezone";
//import CaseNoteDownload from '../case-note-detail/CaseNoteDownload';
import { useSelector, useDispatch } from "react-redux";
import appAction from '../../redux/actions/appAction';
import InvoicePrint from '../../components/payment/InvoicePrint';
import DownLoadInvoiceBtn from '../../components/payment/DownLoadInvoiceBtn';
import { numRegex } from '../../components/Utils/Regex';
import { useForm } from "react-hook-form";
import _ from "lodash";
//import InvoicePrint from './InvoicePrint';
//import DownLoadInvoiceBtn from './DownLoadInvoiceBtn';

function ViewArchiveClientBill(props) {
    const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
    const formRef = useRef(null);
    const history = useNavigate();
    let id = useParams();
    const {
        register,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm();
    console.log("user id", id.id);
    const dispatch = useDispatch();
    const [invoiceDetail, setInVoiceDetail] = useState([]);
    const [accountId, setAccountId] = useState('');
    const [npiName, setNpiName] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [superBill, setSuperBill] = useState(false);
    const [lateFee, setLatFee] = useState(false);
    const [paymentOverDue, setPaymentOverDue] = useState({ lateFees: '', paymentOverDueDays: '' })
    const [addAdditionalField, setAdditionalField] = useState([{}]);
    const [totalAmount, setTotalAmount] = useState('');
    const [addAdditionalCancellation, setAdditionalCancellation] = useState([{}]);
    const [addAdditionalData, setAdditionalData] = useState([{ id: '', amount: '', description: '' }]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const invoiceDetail = adminService
            .getAll(`archive/invoice/${id.id}`)
            .then((res) => {
                //console.log("invoiceDetail", res.data);
                setInVoiceDetail(res.data);
                let str = res?.data?.therapistDto?.therapistServices;
                let i = res?.data?.diagnosisDTO?.therapyDto?.id;
                //let s = str.slice(0, i);
                //setNpiName(s);
                let filtered_array = _.filter(
                    str, function (o) {
                        return o?.therapy?.id === i;
                    }
                );
                console.log("filtered_array", filtered_array?.[0]?.clinicianNpiNumber, i, str);
                setAdditionalField(res?.data?.invoiceDetails);
                setTotalAmount(res?.data?.totalAmount);
                setNpiName(filtered_array?.[0]?.clinicianNpiNumber);
                let filtered_arrays = _.filter(
                    res?.data?.invoiceDetails, function (o) {
                        return o.sessionBillingId === null;
                    }
                );
                let fillerArrays = [...filtered_arrays];
                const fillerArraysloop = fillerArrays.map((i) => {
                    return (
                        //console.log("return array", i)
                        {
                            ["id"]: i?.id,
                            ["amount"]: i?.amount,
                            ["description"]: i?.description
                        }
                    )
                })
                setAdditionalData(fillerArraysloop);
                //setBillingList(res?.data?.content);
                //setCustomerId(res?.data?.stripeCustomerId);
            })
    }, [accountId])
    useEffect(() => {
        getAccountId();
    }, [])
    const getAccountId = async () => {
        await adminService.getAll(`misc/billing-company-detail?tenantId=${tenantId}`).then(
            (res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("connect account", res.data.stripeAccountId);
                    setAccountId(res?.data?.stripeAccountId);
                } else {
                    //toast.error(res.message);
                }
                //setloadings(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }
    const handleAdditionalField = (e, i, id) => {
        const { name, value } = e.target;
        console.log("name value", name, value)
        let newArr = [...addAdditionalField];
        //let newArrNew = [...addAdditionalField];
        newArr[i] = {
            ...newArr[i],
            ["id"]: id,
            [name]: value,
        };
        setAdditionalField(newArr);
        let filtered_array = _.filter(
            newArr, function (o) {
                return o.sessionBillingId === null;
            }
        );
        let fillerArrays = [...filtered_array];
        const fillerArraysloop = fillerArrays.map((i) => {
            return (
                //console.log("return array", i)
                {
                    ["id"]: i?.id,
                    ["amount"]: i?.amount,
                    ["description"]: i?.description
                }
            )
        })
        setAdditionalData(fillerArraysloop);
        console.log("additional field", newArr, filtered_array, fillerArrays, fillerArraysloop);
    };
    const handleCancelation = (e, i, id) => {
        const { name, value } = e.target;
        console.log("name value", name, value)
        let newArr = [...addAdditionalField];
        //let newArrNew = [...addAdditionalField];
        newArr[i] = {
            ...newArr[i],
            ["id"]: id,
            [name]: value,
        };
        setAdditionalField(newArr);
        const fillerArraysloop = newArr.map((i) => {
            return (
                //console.log("return array", i)
                {
                    ["id"]: i?.id,
                    ["amount"]: i?.amount,
                    ["description"]: i?.description
                }
            )
        })
        setAdditionalData(fillerArraysloop);
    }
    const DeleteAddition = (i) => {
        console.log("line Item id", i);
        adminService.PUT(`payments/delete/${i}`)
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("delete data res", res.data);
                    //setInVoiceDetail(res.data);
                    setAdditionalField(res?.data?.invoiceDetails);
                    setTotalAmount(res?.data?.totalAmount);
                    //setPaymentOverDue({ lateFees: '', paymentOverDueDays: '' })
                    //toast.success("Late fee adedd successfully");
                } else {
                    toast.error(res.message);
                }

            })
    }
    const saveData = (p) => {
        console.log("invoice id", p);
        let bodyText = {
            "invoiceDate": moment().format("YYYY-MM-DD"),
            "invoiceDetailFormList": addAdditionalData.map((i) => {
                return i;
            }),
            "invoiceNo": p
        }
        console.log("saved data", bodyText);
        adminService.PUT(`payments/edit`, JSON.stringify(bodyText))
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("save data res", res.data);
                    toast.success("Data updated successfully");
                    setTotalAmount(res?.data?.totalAmount);
                    //setPaymentOverDue({ lateFees: '', paymentOverDueDays: '' })
                    //toast.success("Late fee adedd successfully");
                } else {
                    toast.error(res.message);
                }

            })
    }
    const duePaymentHandle = (e) => {
        var input = document.getElementById("pasDay");
        var inputAmount = document.getElementById("pasAmount");

        input.addEventListener("mousewheel",
            function (event) {
                event.target.blur()
            });
        inputAmount.addEventListener("mousewheel",
            function (event) {
                event.target.blur()
            });
        const { name, value } = e.target;
        console.log("name", name,value);
        setPaymentOverDue((paymentOverDue) => ({ ...paymentOverDue, [name]: parseInt(value) }))
    }
    const Submit = (i) => {
        console.log("invoice number", i);
        setLoader(true);
        if (invoiceDetail?.provider?.signatureUrl === null) {
            toast.error("Please upload your signature");
            setLoader(false);
        } else {
            let postData = { "superBill": invoiceDetail?.superBill }
            adminService.PUT(`payments/submit-approval/${i}`, JSON.stringify(postData))
                .then((res) => {
                    if (res.status === "Success") {
                        //setDashboard(res.data);
                        console.log("Approve Invoice", res.data);
                        toast.success("Invoice approved successfully");
                        history("/client-billing");
                        setLoader(false);

                    } else {
                        toast.error(res.message);
                        setLoader(false);
                    }

                })
        }
    }
    const lateFeeSubmit = () => {
        if (lateFee === true) {
            adminService.PUT(`payments/add-late-fees/${id.id}`, JSON.stringify(paymentOverDue))
                .then((res) => {
                    if (res.status === "Success") {
                        //setDashboard(res.data);
                        console.log("sdf customerResponse", res.data);
                        setPaymentOverDue({ lateFees: '', paymentOverDueDays: '' })
                        toast.success("Late fee adedd successfully");
                    } else {
                        //toast.error("Please enter valid late fee");
                    }

                })
        }
    }
    const setSuperBillStatus = (e) => {
        const { name, value } = e.target;
        setSuperBill(!superBill);
        let postData = { [name]: e.target.checked }
        adminService.PUT(`payments/superBill/${id.id}`, JSON.stringify(postData))
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("addtional data", res.data);
                    toast.success("Invoice Generated Successfully");
                    const invoiceDetail = adminService
                        .getAll(`payments/invoice/${id.id}`)
                        .then((res) => {
                            console.log("invoiceDetail", res.data);
                            setInVoiceDetail(res.data);
                            let str = res?.data?.invoiceDetails?.[0]?.description;
                            let i = str.indexOf(",");
                            let s = str.slice(0, i);
                            setNpiName(s);
                            //setBillingList(res?.data?.content);
                            //setCustomerId(res?.data?.stripeCustomerId);
                        })
                } else {
                    toast.error(res.message);
                }

            })
    }
    const onPdfCreate = (cb) => {
        setIsDownloading(true);
        dispatch(appAction.setAppLoading(true));
        setTimeout(() => {
            if (typeof cb === "function") {
                cb();
            }
        }, 200);
    };

    const onPdfComplete = () => {
        setIsDownloading(false);
        dispatch(appAction.setAppLoading(false));
    };
    //console.log(s)
    //console.log("addAdditionalField",addAdditionalField);

    return (
        <div className="col-md-10 no-padding">
            <div className="no-padding dash-billing-info">
                <div className="custom-table-view min-h-100">
                    {loader && (
                        <div className="loader-overlay">
                            <div className="loader" />
                        </div>
                    )}

                    <div className='row'>
                        <div className='col-md-6'>
                            {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' && invoiceDetail?.caseNoteUnits !== null ? <><label><input style={{ marginLeft: "10px" }} type="checkbox" name='superBill' checked={invoiceDetail?.superBill ? true : superBill} onChange={(e) => setSuperBillStatus(e)} /><b>Generate Super Bill</b></label></>
                                : ''}
                        </div>
                        <div className='col-md-6 text-right'>
                            {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <></> : <DownLoadInvoiceBtn
                                downloadName={invoiceDetail?.superBill ? `SB _${invoiceDetail?.invoiceNo}.pdf` : `INV _${invoiceDetail?.invoiceNo}.pdf`}
                                targetRef={formRef}
                                beforeStart={onPdfCreate}
                                onComplete={onPdfComplete}
                            />}
                        </div>
                    </div>
                    <hr />
                    <div className='p-4'>
                        <div class="row mb-10">
                            <div class="col-md-6">
                                <p className='addressInvoice'><span>From, </span><span style={{ marginLeft: "10px" }}>{invoiceDetail?.billingCompany?.name}<br />{invoiceDetail?.billingCompany?.address1}</span></p>
                            </div>
                            <div className='col-md-6 text-right'>
                                <img className='img-fluid invoiceLogo' src={invoiceDetail?.billingCompany?.logoUrl} />
                            </div>
                        </div>
                        <hr />
                        <h4 className='text-center'>{invoiceDetail?.superBill ? "Statement for Insurance Reimbursement" : "Invoice"}</h4>
                        <div className='row'>
                            <div className='col-md-9'>
                                <p className='addressInvoice'><span>To, </span><span style={{ marginLeft: "10px" }}>{invoiceDetail?.familyMemberDto?.firstName + " " + invoiceDetail?.familyMemberDto?.lastName}</span></p>
                            </div>
                            <div className='col-md-3'>
                                <div className='text-left'>
                                    <p className='mb-0'><span>Invoice </span>|<span> {invoiceDetail?.invoiceNo}</span></p>
                                    <p><span>Issue Date </span>|<span> {invoiceDetail?.invoiceDate ? moment(invoiceDetail?.invoiceDate).format("MM/DD/YYYY") : moment().format('MM/DD/YYYY')}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-9'>
                                <p className='mb-0'>Client,</p>
                                <p className='mb-0'>{invoiceDetail?.familyMemberDto?.firstName + " " + invoiceDetail?.familyMemberDto?.lastName}</p>
                                <p className='mb-0'>{invoiceDetail?.familyMemberDto?.phone}</p>
                                <p className='mb-0'>{invoiceDetail?.familyMemberDto?.email}</p>
                                {invoiceDetail?.superBill ? <p className='mb-0'>DOB : {invoiceDetail?.familyMemberDto?.dob ? moment(invoiceDetail?.familyMemberDto?.dob).format("MM/DD/YYYY") : ''}</p> : ''}
                            </div>
                            <div className='col-md-3'>
                                <div className='text-left'>
                                <p className='mb-0'>Provider,</p>
                                    <p className='mb-0'>{invoiceDetail?.provider?.firstName + " " + invoiceDetail?.provider?.lastName}</p>
                                    {invoiceDetail?.superBill ? '' : <p className='mb-0'>Tax ID : {invoiceDetail?.therapistDto?.taxonomy}</p>}
                                    {invoiceDetail?.superBill ? <></> : <p className='mb-0'>NPI : {invoiceDetail?.billingCompany?.groupNpiNumber}</p>}
                                    <p className='mb-0'>{invoiceDetail?.provider?.phone}</p>
                                    <p className='mb-0'>{invoiceDetail?.provider?.email}</p>
                                    {invoiceDetail?.superBill ? <p className='mb-0'>NPI : {invoiceDetail?.billingCompany?.groupNpiNumber}</p> : <></>}
                                    {invoiceDetail?.superBill ? <p>Licenses : {invoiceDetail?.therapistDto?.licenseNumberState}</p> : ''}
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-9'></div>
                            <div className='col-md-3'>
                                <div className='text-left'>
                                    {invoiceDetail?.superBill ? <p className='mb-0'><span>Practice </span>|<span> Tax ID : {invoiceDetail?.therapistDto?.taxonomy}<br />NPI : {npiName}</span></p>
                                        : ''}
                                </div>
                            </div>
                        </div>
                        {invoiceDetail?.superBill ?
                            <>
                                <div className='table-responsive mt-3'>
                                    <table className='table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td><b>DX</b></td>
                                                <td><b>Diagnosis Code</b></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>( {invoiceDetail?.diagnosisDTO?.code} ) {invoiceDetail?.diagnosisDTO?.description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {invoiceDetail?.caseNoteUnits === null ?
                                    <>
                                        <div className='table-responsive mt-3'>
                                            <table className='table table-bordered'>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Date</b></td>
                                                        <td><b>Service</b></td>
                                                        <td><b>DX</b></td>
                                                        <td><b>Description</b></td>
                                                        <td className='text-right'><b>Units</b></td>
                                                        <td className='text-right'><b>Fee</b></td>
                                                        <td className='text-right'><b>Paid</b></td>
                                                    </tr>
                                                    {addAdditionalField?.length ? addAdditionalField?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item?.invoiceDate ? moment(item?.invoiceDate).format("MM/DD/YYYY") : moment().format('MM/DD/YYYY')}</td>
                                                            <td>{item?.sessionBillingId ? invoiceDetail?.diagnosisDTO?.therapyDto?.code : ''}</td>
                                                            <td>{item?.sessionBillingId ? '1' : ''}</td>
                                                            <td>{item?.description}</td>
                                                            <td className='text-right'>{item?.sessionBillingId ? invoiceDetail?.caseNoteUnits : ''}</td>
                                                            <td className='text-right'><b>$ {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <input style={{ width: "87%", display: "inline-block" }} className="form-control" name="amount" value={item?.amount} onChange={(e) => handleCancelation(e, index, item?.id)} /> : ''}</b></td>
                                                            <td className='text-right'><b>$ {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <input style={{ width: "87%", display: "inline-block" }} className="form-control" name="amount" value={item?.amount} onChange={(e) => handleCancelation(e, index, item?.id)} /> : ''}</b></td>
                                                        </tr>
                                                    ))
                                                        :
                                                        <tr><td colSpan={3}>No Data Available</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <button className="btn button-withicon-green" onClick={() => saveData(invoiceDetail?.invoiceNo)}>Save Detail</button> : ''}
                                    </>
                                    :
                                    <>
                                        <div className='table-responsive mt-3'>
                                            <table className='table table-bordered'>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Date</b></td>
                                                        <td><b>Service</b></td>
                                                        <td><b>DX</b></td>
                                                        <td><b>Description</b></td>
                                                        <td className='text-right'><b>Units</b></td>
                                                        <td className='text-right'><b>Fee</b></td>
                                                        <td className='text-right'><b>Paid</b></td>
                                                        {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <td width="6%"><b>Action</b></td> : ''}
                                                    </tr>
                                                    {addAdditionalField?.length ? addAdditionalField?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item?.invoiceDate ? moment(item?.invoiceDate).format("MM/DD/YYYY") : moment().format('MM/DD/YYYY')}</td>
                                                            <td>{item?.sessionBillingId ? invoiceDetail?.diagnosisDTO?.therapyDto?.code : ''}</td>
                                                            <td>{item?.sessionBillingId ? '1' : ''}</td>
                                                            <td>{item?.sessionBillingId === null && invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ?
                                                                <input style={{ width: "92%", display: "inline-block" }} className="form-control" name="description" value={item?.description} onChange={(e) => handleAdditionalField(e, index, item?.id)} /> : item?.description}</td>
                                                            <td className='text-right'>{item?.sessionBillingId ? invoiceDetail?.caseNoteUnits : ''}</td>
                                                            <td className='text-right'><b>$ {item?.sessionBillingId === null && invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ?
                                                                <input style={{ width: "87%", display: "inline-block" }} className="form-control" name="amount" value={item?.amount} onChange={(e) => handleAdditionalField(e, index, item?.id)} /> : item?.amount}</b></td>
                                                            <td className='text-right'><b>$ {item?.sessionBillingId === null && invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ?
                                                                <input style={{ width: "87%", display: "inline-block" }} className="form-control" name="amount" value={item?.amount} onChange={(e) => handleAdditionalField(e, index, item?.id)} /> : item?.amount}</b></td>
                                                            {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <td className='text-center'>{item?.sessionBillingId === null ? <button className='deleteBtn' onClick={() => DeleteAddition(item?.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg></button> : ''}</td> : ''}
                                                        </tr>
                                                    ))
                                                        :
                                                        <tr><td colSpan={3}>No Data Available</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {invoiceDetail?.caseNoteUnits !== null && addAdditionalField?.length > 1 && invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <><button className="btn button-withicon-green" onClick={() => saveData(invoiceDetail?.invoiceNo)}>Save Detail</button></> : ''}
                                    </>
                                }

                            </>
                            :
                            <>
                                {invoiceDetail?.caseNoteUnits === null ?
                                    <>
                                        <div className='table-responsive mt-3'>
                                            <table className='table table-bordered'>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Date</b></td>
                                                        <td><b>Description</b></td>
                                                        <td className='text-right'><b>Amount</b></td>
                                                    </tr>
                                                    {addAdditionalField?.length ? addAdditionalField?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item?.invoiceDate ? moment(item?.invoiceDate).format("MM/DD/YYYY") : moment().format('MM/DD/YYYY')}</td>
                                                            <td>{item?.description}</td>
                                                            <td className='text-right'><b>$ {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <input style={{ width: "92%", display: "inline-block" }} className="form-control" name="amount" value={item?.amount} onChange={(e) => handleCancelation(e, index, item?.id)} /> : item?.amount}</b></td>
                                                        </tr>
                                                    ))
                                                        :
                                                        <tr><td colSpan={3}>No Data Available</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <button className="btn button-withicon-green" onClick={() => saveData(invoiceDetail?.invoiceNo)}>Save Detail</button> : ''}
                                    </>
                                    :
                                    <>
                                        <div className='table-responsive mt-3'>
                                            <table className='table table-bordered'>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Date</b></td>
                                                        <td><b>Description</b></td>
                                                        <td className='text-right'><b>Amount</b></td>
                                                        {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <td width="6%"><b>Action</b></td> : ''}
                                                    </tr>
                                                    {addAdditionalField?.length ? addAdditionalField?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item?.invoiceDate ? moment(item?.invoiceDate).format("MM/DD/YYYY") : moment().format('MM/DD/YYYY')}</td>
                                                            <td>{item?.sessionBillingId === null && invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ?
                                                                <input style={{ width: "92%", display: "inline-block" }} className="form-control" name="description" value={item?.description} onChange={(e) => handleAdditionalField(e, index, item?.id)} /> : item?.description}</td>
                                                            <td className='text-right'><b>$ {item?.sessionBillingId === null && invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ?
                                                                <input style={{ width: "92%", display: "inline-block" }} className="form-control" name="amount" value={item?.amount} onChange={(e) => handleAdditionalField(e, index, item?.id)} /> : item?.amount}</b></td>
                                                            {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <td className='text-center'>{item?.sessionBillingId === null ? <button className='deleteBtn' onClick={() => DeleteAddition(item?.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg></button> : ''}</td> : ''}
                                                        </tr>
                                                    ))
                                                        :
                                                        <tr><td colSpan={3}>No Data Available</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {invoiceDetail?.caseNoteUnits !== null && addAdditionalField?.length > 1 && invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <><button className="btn button-withicon-green" onClick={() => saveData(invoiceDetail?.invoiceNo)}>Save Detail</button></> : ''}
                                    </>
                                }
                            </>
                        }
                        <div className='row mt-3 align-items-end'>
                            {invoiceDetail?.superBill ?
                                <>
                                    <div className='col-md-2'>
                                        <p className='mb-0'>{invoiceDetail?.provider?.signatureDateTime ? moment(invoiceDetail?.provider?.signatureDateTime).format("MM/DD/YYYY") : ''}</p>
                                        <p><b>Date</b></p>
                                    </div>
                                    <div className='col-md-2'>
                                        <p className='mb-0'>{invoiceDetail?.provider?.signatureUrl ? <img src={invoiceDetail?.provider?.signatureUrl} className="img-fluid" /> : ''}</p>
                                        <p><b>Signature</b></p>
                                    </div>
                                    <div className='col-md-4'></div></>
                                :
                                <div className='col-md-8'></div>
                            }
                            <div className='col-md-4'>
                                {invoiceDetail?.superBill ?
                                    <>
                                        <p style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span><b>Total Charges</b></span>
                                            <span className='text-right'>$ {totalAmount}</span>
                                        </p>
                                        <p style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span><b>Total Paid</b></span>
                                            <span className='text-right'>$ {totalAmount}</span>
                                        </p></>
                                    : <p style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span><b>Total</b></span>
                                        <span className='text-right'>$ {totalAmount}</span>
                                    </p>
                                }
                                <p style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span>Make payments to : </span>
                                    <span className='text-right'>{invoiceDetail?.provider?.firstName + " " + invoiceDetail?.provider?.lastName}</span>
                                </p>
                            </div>
                        </div>
                        <hr />
                        {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ?
                            <>
                                <label><input type="checkBox" name="lateFee" checked={lateFee} onChange={() => setLatFee(!lateFee)} />Apply Late Fee</label>
                                {lateFee ?
                                    <form onSubmit={handleSubmit(lateFeeSubmit)}>
                                        <div className='applyLateFee'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <lable className="mb-2">Payment Over Due Days ( <span class="required-span">*</span> )</lable>
                                                    <input id="pasDay" min="0" ref={register({ required: true, pattern: numRegex, })} type="number" className="form-control mb-3" name="paymentOverDueDays" defaultValue={paymentOverDue?.paymentOverDueDays} onChange={(e) => duePaymentHandle(e)} />
                                                    {errors.paymentOverDueDays && (
                                                        <span className="fieldError">This field is required.</span>
                                                    )}

                                                </div>
                                                <div className='col-md-6'>
                                                    <lable className="mb-2">Late Fees Amount ( <span class="required-span">*</span> )</lable>
                                                    <input id="pasAmount" min="0" ref={register({ required: true, pattern: numRegex })} type="number" className="form-control mb-3" name="lateFees" defaultValue={paymentOverDue?.lateFees} onChange={(e) => duePaymentHandle(e)} />
                                                    {errors.lateFees && (
                                                        <span className="fieldError">This field is required.</span>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn button-withicon-green">Save Detail</button>
                                        </div>
                                    </form>
                                    :
                                    ''}<br /></>
                            :
                            ''}
                        {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' || invoiceDetail?.billingStatus === 'WAIVED_OFF' ? <></> : <p><b>Note:</b> Please pay your invoice within {moment(invoiceDetail?.overDueDate).format(
                            "DD MMMM YYYY"
                        )}, otherwise {invoiceDetail?.lateFees ? '$' + invoiceDetail?.lateFees : ''} late fee applies</p>}
                        {/* {isDownloading ? <div className="min-h-100 bg-white" ><div className="loader" /></div> : ""} */}

                        <div className="d-none"><InvoicePrint ref={formRef} billingData={invoiceDetail} npiName={npiName} /></div>
                        {/* <div className='row'>
                                <div className='col-md-6 d-inline-flex'>
                                    <DownLoadInvoiceBtn
                                        downloadName={invoiceDetail?.superBill ? `SB _${invoiceDetail?.invoiceNo}.pdf` : `INV _${invoiceDetail?.invoiceNo}.pdf`}
                                        targetRef={formRef}
                                        beforeStart={onPdfCreate}
                                        onComplete={onPdfComplete}
                                    />
                                </div>
                            </div> */}

                        {accountId === null ? <p className='text-danger'>Note: Please complete your account details.</p> : <p></p>}
                        {invoiceDetail?.billingStatus === 'PENDING_APPROVAL' ? <><button className="btn btn-theme-green" onClick={() => Submit(invoiceDetail?.invoiceNo)} disabled={accountId === null ? true : false}>Approve</button></> : ''}
                        <Link className='btn btn-theme-blue mr-2' to="/client-billing-archive">Back</Link>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewArchiveClientBill;