import React, { Component, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Switch, Link, useLocation } from 'react-router-dom';


function Stepper(props) {
    //const alert = useAlert();
    const history = useNavigate();
    //const dispatch = useDispatch();
    let location = useLocation();

    return (
        <>
            <div className="row stepper">
                {props.steps.map((label,index) => (
                    <div className={`col-md-4 stepper-steps ${props.activeStep==index && props.activeStep != props.steps.length-1  ? 'active':props.activeStep>index ?'complete':props.activeStep === props.steps.length-1 ? 'complete done':''}`}>
                    <div className="step-count"><label>{index+1}</label></div> <label className="label-text">{label}</label>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Stepper;