import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdCreate, IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function TherapyTypeTable(props) {
    let history = useNavigate();
    const [lessMore, setLessMore] = useState(null);

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Therapy Name</th>
                        <th>Therapy Code</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {props?.list.length > 0 &&
                        props.list.map((data) => {
                            return (
                                <tr key={data.id}>
                                    <td>
                                        <b>{data?.name}</b>
                                    </td>
                                    <td className="text-center">{data?.code}</td>
                                    <td>
                                        {data?.description?.slice(0, 30)}
                                        {data?.description.length > 30 && lessMore != data.id && (
                                            <>
                                                <span
                                                    className="cursor-pointer"
                                                    style={{ color: "#387af6" }}
                                                    onClick={() => setLessMore(data.id)}
                                                >
                                                    ...<small>Show More</small>
                                                </span>
                                            </>
                                        )}
                                        {lessMore == data.id && data?.description?.slice(30, 100)}
                                        {lessMore == data.id && (
                                            <span
                                                className="cursor-pointer"
                                                style={{ color: "#387af6" }}
                                                onClick={() => setLessMore(null)}
                                            >
                                                &nbsp;<small>Show Less</small>
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        {data?.active === true ? (
                                            <span className="text-success">Active</span>
                                        ) : (
                                            <span className="text-danger">Inactive</span>
                                        )}
                                    </td>
                                    <td className="text-center">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-1">View</Tooltip>}
                                        >
                                            <button
                                                className="btn btn-theme-info btn-sm "
                                                onClick={() =>
                                                    history(`/view-therapy-type/${data.id}`)
                                                }
                                            >
                                                <IoMdEye />
                                            </button>
                                        </OverlayTrigger>

                                        
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}

export default TherapyTypeTable;