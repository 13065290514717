import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { FormFooter, FormHeader } from "../admin/CommonComponents";

function ClientContactRegister(props) {
  const formRef = React.useRef(null);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  return (
    <>
      <div className={`col-md-${props?.from ? 12 : 10}`} id="my-code">
        <div className="card-lg">
          <FormHeader logoUrl={logoUrl} title="CLIENT CONTACT REGISTER" />
          <hr />
          <div className="card-lg__content">
            <div style={{ width: "100%", textAlign: "center" }}>
              <p>
                <b>
                  Every time any attempt is made to reach anyone related to the
                  client / case, the details of the contact needs to be
                  registered, specifying:
                </b>
              </p>
            </div>
            <div style={{ width: "100%" }}>
              <table style={{ width: "100%", border: "1px solid black" }}>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: 0,
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: 0,
                    }}
                  >
                    Time
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: 0,
                    }}
                  >
                    Person Contacted
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: 0,
                    }}
                  >
                    Phone
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: 0,
                    }}
                  >
                    Email Address
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: 0,
                    }}
                  >
                    Purpose of contact
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      paddingLeft: 5,
                      borderBottom: 0,
                    }}
                  >
                    Notes
                  </th>
                  <th
                    colSpan={2}
                    style={{ border: "1px solid black", paddingLeft: 5 }}
                  >
                    Follow up
                  </th>
                </tr>
                <tr>
                  <th style={{ border: "1px solid black", borderTop: 0 }}></th>
                  <th style={{ border: "1px solid black", borderTop: 0 }}></th>
                  <th style={{ border: "1px solid black", borderTop: 0 }}></th>
                  <th style={{ border: "1px solid black", borderTop: 0 }}></th>
                  <th style={{ border: "1px solid black", borderTop: 0 }}></th>
                  <th style={{ border: "1px solid black", borderTop: 0 }}></th>
                  <th style={{ border: "1px solid black", borderTop: 0 }}></th>
                  <th style={{ border: "1px solid black", paddingLeft: 5 }}>
                    Yes
                  </th>
                  <th style={{ border: "1px solid black", paddingLeft: 5 }}>
                    No
                  </th>
                </tr>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((d) => {
                  return (
                    <tr>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 5px",
                        }}
                      ></th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 5px",
                        }}
                      ></th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 5px",
                        }}
                      ></th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 5px",
                        }}
                      ></th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 5px",
                        }}
                      ></th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 5px",
                        }}
                      ></th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 5px",
                        }}
                      ></th>
                      <th
                        style={{ border: "1px solid black", paddingLeft: 5 }}
                      ></th>
                      <th
                        style={{ border: "1px solid black", paddingLeft: 5 }}
                      ></th>
                    </tr>
                  );
                })}
              </table>
            </div>
            <br />
            <hr />
            <FormFooter
              address={joinWithComma(
                address1,
                address2,
                city,
                country,
                zipCode
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientContactRegister;
