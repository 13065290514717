import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";
import _ from "lodash";

import Agora from "../../components/agora/Agora";
import { useAgoraMutation } from "../../components/agora/utils/container";
import { adminService } from "../../services/adminService";
import Counter from "../../components/Counter";
import AttachmentFile from "../../components/attachment/AttachmentFile";
import { getFileType } from "../../utils/commonFunctions";
import AppSocket from "../../components/socket/AppSocket";
import agoraAction from "../../redux/actions/agoraAction";
import agoraActionNew from "../../redux/actions/agoraActionNew";
import DigitalSignPopup from "../../components/digitalSignPopup";
import useSpeechToText from "react-hook-speech-to-text";
import SweetAlert from "react-bootstrap-sweetalert";
import TestPage from "../TestPage";
import Call from "../AgoraExternalVideo/Call";

function VideoTherapy() {
  const [agoraNewConfig, setAgoraNewConfig] = useState();

  const history = useNavigate();
  const sigCanvas = useRef({});
  const dummyStart = true;
  const param = useParams();
  const user = useSelector((state) => state.adminUser.user);
  const mutationCtx = useAgoraMutation();
  const [therapyData, setTherapyData] = useState({});
  const [timer, setTimer] = useState({});
  const [profile, setProfile] = useState({});
  const [isStarted, setIsStarted] = useState(false);
  const [isJoinable, setIsJoinable] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [caseNoteDetail, setCaseNoteDetail] = useState({});
  const [imageLoading, setImageLoading] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [sessionDetail, setSessionDetail] = useState({});
  const [recToken, setRecToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [therapistImageURL, setTherapistImageURL] = useState(null);
  const [caseDescription, setCaseDescription] = useState(" ");
  const [value, setValue] = useState("");
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [additionalTime, setAdditionalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(1);
  const [permissionForAll, setPermissionForAll] = useState(true);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [joinedUsers, setJoinedUsers] = useState([]);
  const [isShowJoinBtn, setIsShowJoinBtn] = useState(false);

  const clear = () => sigCanvas.current.clear();

  const { debounce } = _;

  const dispatch = useDispatch();

  useEffect(() => {
    if (param.id) {
      //console.warn("v Param", param.id);
      adminService.POST(`agora-tokens/withoutUser/${param.id}`).then(
        (res) => {
          if (res?.status === "Success") {
            setTherapyData(res.data);

            // dispatch(
            //   agoraActionNew.configData({
            //     channel: res.data.channelName,
            //     appId: res.data.appId,
            //     token: res.data.token,
            //   })
            // );

            setAgoraNewConfig((item) => ({
              ...item,
              channel: res.data.channelName,
              appId: res.data.appId,
              token: res.data.token,
            }));

            mutationCtx.updateConfig({
              channel: res.data.channelName,
              appId: res.data.appId,
              token: res.data.token,
              // role
            });
          } else {
            toast.error(res?.message || res?.error || res);
          }
        },
        (error) => {
          console.log(error);
        }
      );
      adminService.getAll(`user-profiles/user-detail`).then(
        (res) => {
          if (res?.status === "Success") {
            setProfile(res.data);

            // dispatch(
            //   agoraActionNew.configUpdate({
            //     uid: res.data.id,
            //   })
            // );

            setAgoraNewConfig((item) => ({ ...item, uid: res.data.id }));

            mutationCtx.updateConfig({
              uid: res.data.id,
            });
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [param.id]);

  useEffect(() => {
    if (sessionDetail && param.id) {
      if (profile.userType === "ROLE_THERAPIST") {
        const { therapist, familyMember, inerperter, familyMemberDtoSet } =
          sessionDetail;
        // const family_ids =
        //   familyMemberDtoSet?.length > 0
        //     ? familyMemberDtoSet.map((fm) => fm.id)
        //     : [];
        // const recid = [therapist?.id, family_ids.join(","), inerperter?.id]
        //   .map((i) => i || `0`)
        //   .join("");

        const ids = [therapist?.id, inerperter?.id];
        const family_ids =
          familyMemberDtoSet?.length > 0 &&
          familyMemberDtoSet.map((fm) => ids.push(fm.id));
        // ids.push(familyMemberDtoSet[0].id)
        const recid = ids.map((i) => i || `0`).join("");
        adminService.POST(`agora-tokens/${param.id}/${randomRecId}`).then(
          (res) => {
            if (res.data) {
              setRecToken(res.data.token);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      dispatch(agoraAction.setSession(sessionDetail));
    }
    return () => {
      dispatch(agoraAction.setSession(null));
    };
  }, [param.id, sessionDetail]);
  // console.log("sessionDetail2222222", sessionDetail);
  useEffect(() => {
    return () => {
      if (typeof mutationCtx.clearAll === "function") {
        mutationCtx.clearAll();
      }
      dispatch(agoraAction.clear());
    };
  }, []);

  useEffect(() => {
    if (profile.id) {
      let apiUrl = "";
      switch (profile.userType) {
        case "ROLE_THERAPIST":
          apiUrl = "therapist/sessions/";
          break;
        case "ROLE_FAMILY":
          apiUrl = "family/sessions/";
          break;
        case "ROLE_INTERPRETER":
          apiUrl = "interpreter/sessions/";
          break;
        default:
            apiUrl = "therapist/sessions/";
            break;
      }
      if (apiUrl) {
        getSessionInfo(apiUrl);
      }

      if (profile.userType === "ROLE_THERAPIST") {
        adminService.getAll(`case-notes/sessions/${param.id}`).then(
          (res) => {
            if (res.status === "Success") {
              setCaseNoteDetail(res.data);
              setCaseDescription(res.data.description);
              setAttachments(res.data.attachmentsList);
              if (!res.data.id) {
                postCaseNote(res.data.id);
              }
            } else {
              postCaseNote(null, true);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }, [profile]);

  const getSessionInfo = (apiUrl) => {
    adminService.getAll(apiUrl + param.id).then(
      (res) => {
        if (res.status === "Success") {
          console.log("interpreter res",res.data);
          setSessionDetail(res.data);
          const startTime = moment.utc(res.data.startDateTime).local();
          const endTime = moment.utc(res.data.endDateTime).local();
          const maxDuration = moment.duration(endTime.diff(startTime));
          const duration = moment.duration(endTime.diff(moment()));
          setTimer({
            ...timer,
            startTime,
            endTime,
            maxDuration,
            et: res.data.endDateTime,
            st: res.data.startDateTime,
            duration,
            startDuration: moment.duration(startTime.diff(moment())),
          });
          setIsShowJoinBtn(true);
        } else {
          toast.error(res.message || res.error);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer.startTime) {
        // const startt =moment(timer.startTime).add(1, 'day').add(3, 'hours').add(12, 'minutes');
        const inTime = moment()
          .add(0, "hours")
          .isBetween(timer.startTime, timer.endTime);
        if (dummyStart) {
          if (!isJoinable) {
            setIsJoinable(true);
          }
        } else if (inTime) {
          if (!isJoinable) {
            setIsJoinable(true);
          }
        }
      }
    }, 1000);
    if (interval && isStarted) {
      clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer, isStarted]);

  useEffect(() => {
    if (profile.userType === "ROLE_THERAPIST") {
      debouncePostCaseNote(caseNoteDetail.id, caseDescription);
    }
  }, [caseDescription]);

  const _handleRes = (res, msg) => {
    if (res.status?.toLowerCase() === "success") {
      toast.success("Saved");
    } else {
      toast.error(res.message);
    }
  };
  const _handleErr = (err) => {
    toast.error(err.info || err.msg || err.message);
  };

  useEffect(() => {
    console.log("attachments", attachments);
    const data = {
      id: null,
      // attachmentsList123: ids,
      attachmentsList: attachments.map((item) => ({ id: item.id })),
      description: caseDescription,
      sessionId: param.id,
      ...caseNoteDetail,
      drafted: true,
    };
    if(isStarted && profile.userType !== "ROLE_INTERPRETER") { updateCaseNote(data);}
  }, [attachments]);

  // let newAttachments = attachments;

  const postCaseNote = (id = null, noMsg = false, overrideDes = null) => {
    const attchmnt = [...attachments];
    const ids = attchmnt.map((item) => ({ id: item.id }));
    const data = {
      id: id,
      // attachmentsList123: ids,
      attachmentsList: ids,
      description: overrideDes || caseDescription,
      sessionId: param.id,
      ...caseNoteDetail,
      drafted: true,
    };
    // console.log("attachments caseNote", ids, "===", Attchmnt);

    if (!noMsg) {
      setIsSaving(true);
    }
    if(profile.userType !== "ROLE_INTERPRETER")
    {
      updateCaseNote(data);
    }
  };

  const updateCaseNote = (data, noMsg = false) => {
    data.description = data.description.toString();
    data.sessionId = parseInt(data.sessionId);
    adminService
      .POST("case-notes", JSON.stringify(data))
      .then((res) => {
        if (res.status?.toLowerCase() === "success") {
          setCaseNoteDetail(res.data);
        }
        if (!noMsg) {
          setIsSaving(false);
          // _handleRes(res);
        }
      })
      .catch((err) => {
        if (!noMsg) {
          setIsSaving(false);
          _handleErr(err);
        }
      });
  };

  const noMsgPostCaseNote = (id = null, des = "") => {
    postCaseNote(id, true, des);
  };
  const debouncePostCaseNote = useCallback(
    debounce(noMsgPostCaseNote, 1000),
    []
  );

  const fileInputField = () => {
    return (
      <input
        disabled={imageLoading}
        type="file"
        onChange={(e) => onFileAttach(e)}
        className="d-none therapy-attach-file divya"
        id="therapy-attach-file"
      />
    );
  };

  const onFileAttach = (e) => {
    console.log("attachments", attachments);
    const files = e.target.files;
    if (files) {
      if (files.length > 0) {
        setImageLoading(true);
      }
      Array.from(files).forEach((file) => {
        const apiUrl = "attachments";
        const formData = new FormData();
        formData.append("profileImage", file);
        adminService
          .POSTUploadImage(apiUrl, formData)
          .then((res) => {
            if (res.status === "Success") {
              document.getElementById("therapy-attach-file").value = "";
              setAttachments([
                ...attachments,
                {
                  id: res.data.id,
                  key: res.data.key,
                  fileName: res.data.fileName,
                },
              ]);
            } else {
              document.getElementById("therapy-attach-file").value = "";
              _handleErr(res);
            }
            setImageLoading(false);
          })
          .catch((err) => {
            _handleErr(err);
            setImageLoading(false);
          });
        // const reader = new FileReader();
        // const url = reader.readAsDataURL(file);
        // reader.onloadend = function (e) {
        //     setAttachments([
        //         ...attachments, {
        //             url,
        //             src: reader.result
        //         }
        //     ])
        // }
      });
    }
  };
  const onLeave = (cb) => {
    if (profile.userType === "ROLE_THERAPIST") {
      adminService
        .PUT(`sessions/stop-session/${param.id}`)
        .then((res) => {
          if (res.status === "Success") {
            if (typeof cb === "function") {
              cb();
            }
          } else {
            if (typeof cb === "function") {
              cb();
            }
            // toast.error(res.message || res.error);
          }
        })
        .catch((err) => {
          if (typeof cb === "function") {
            cb();
          }
        });
    } else {
      if (typeof cb === "function") {
        cb();
      }
    }
  };

  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  useEffect(() => {
    let data = "";
    if (caseDescription && results.length > 0) {
      data = caseDescription + " " + results[results.length - 1].transcript;
    } else {
      data = results.map((r) => r.transcript);
    }
    setCaseDescription(data);
  }, [results]);

  const [countDownTimer, setCountDownTimer] = useState(null);
  const [randomRecId, setRandomRecId] = useState(
    `${Math.floor(10000000 + Math.random() * 90000000)}`
  );

  useEffect(() => {
    profile.userType === "ROLE_THERAPIST" &&
      (countDownTimer == "00:02:00" || countDownTimer == "00:05:00") &&
      setIsConfirmation(true);

    (countDownTimer == "00:00:38" ||
      countDownTimer == "00:00:45" ||
      countDownTimer == "00:01:35" ||
      countDownTimer == "00:04:45") &&
      profile.userType === "ROLE_FAMILY" &&
      getSessionInfo("family/sessions/");

      (countDownTimer == "00:00:38" ||
      countDownTimer == "00:00:45" ||
      countDownTimer == "00:01:35" ||
      countDownTimer == "00:04:45") &&
      profile.userType === "ROLE_INTERPRETER" &&
      getSessionInfo("interpreter/sessions/")

    const currentDT = moment(new Date()).utc().local();
    const endDT = moment(sessionDetail?.endDateTime).utc().local();
    countDownTimer == "00:00:00" &&
      moment(currentDT).isSame(endDT) &&
      (window.location.href = "/therapy-sessions");
  }, [countDownTimer]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sessionDetail?.endDateTime) {
        const then = moment(sessionDetail?.endDateTime).utc().local();
        const now = moment().utc().local();
        const countdown = moment(then - now);
        const days = countdown.format("D");
        const hours = countdown.format("HH");
        const minutes = countdown.format("mm");
        const seconds = countdown.format("ss");
        let newTimer = "";
        if (days - 1) {
          const d1 = days - 1;
          let d2 = d1 < 10 ? `0${d1}` : d1;
          newTimer += `${d2}${hours ? ":" : ""}`;
        } else {
          newTimer += "";
        }
        if (hours) {
          newTimer += `${hours}${minutes ? ":" : ""}`;
        } else {
          newTimer += "";
        }
        if (minutes) {
          newTimer += `${minutes}:`;
        }
        if (seconds) {
          newTimer += `${seconds}`;
        }
        setCountDownTimer(newTimer);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [sessionDetail?.endDateTime]);

  const [isConfirm, setIsConfirm] = useState(false);

  const updateTime = () => {
    //
    const newTime = moment
      .utc(sessionDetail?.endDateTime)
      .add(additionalTime, "minutes")
      .local();
    updateEndTime(newTime);
  };

  const [newCTime, setnewCTime] = useState(null);
  const updateEndTime = (newTime) => {
    setIsConfirmation(false);
    adminService
      .onboardPutUserJSONContent(
        `sessions/endTime/${param.id}`,
        JSON.stringify({
          endDateTime: newTime.toISOString(),
        })
      )
      .then((response) => {
        if (response) {
          if (response?.data?.endDateTime) {
            const endTime = moment.utc(response.data.endDateTime).local();
            setSessionDetail({
              ...sessionDetail,
              endDateTime: response?.data?.endDateTime,
            });
            setTimer({
              ...timer,
              endTime,
            });
            const date = moment.utc(response?.data?.endDateTime).local();
            setnewCTime(date);
            setTimer({ ...timer, endTimee: date });
          }
          setIsConfirm(false);
          setIsConfirmation(false);
          toast.success("Status updated");
        } else {
          response?.message
            ? toast.error(response?.message)
            : console.log("Somthing went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Status not updated");
      });
  };

  const updateSignature = () => {
    setTherapistImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(signatureData))
      .then(
        (res) => {
          if (res.status === "Success") {
            setOpen(false);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {});
  };

  const deleteAttachment = (i) => {
    console.log(attachments);
    const attachmentList = [...attachments];
    attachmentList.splice(i, 1);

    setAttachments(attachmentList);
  };

  useEffect(() => {
    dispatch(agoraActionNew.configData({ ...agoraNewConfig }));
  }, [agoraNewConfig]);

  console.log("isLocalJoined", joinedUsers);
  return (
    <div className="video-therapy">
      <div className="row video-therapy__main-row">
        {/* {countDownTimer} */}
        {isConfirmation && (
          <SweetAlert
            // info
            focusCancelBtn
            custom
            showCancel
            showCloseButton
            confirmBtnText={`${isConfirm ? "Submit" : "Yes"}`}
            cancelBtnText={`${isConfirm ? "Cancel" : "No"}`}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="light"
            customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
            title="Do you want to extend session time?"
            onConfirm={() => (isConfirm ? updateTime() : setIsConfirm(true))}
            onCancel={() => setIsConfirmation(false)}
          >
            {isConfirm ? (
              <>
                <label>Enter the time in minutes</label>
                <input
                  name="number"
                  className="form-control"
                  placeholder="Minutes"
                  autofocus
                  min={1}
                  max={120}
                  required
                  // value={additionalTime}
                  onChange={(e) => setAdditionalTime(e.target.value)}
                />
              </>
            ) : (
              <></>
            )}
          </SweetAlert>
        )}
        {/* <button onClick={() => setIsConfirmation(true)}>Update</button> */}

        <div className="video-therapy__meeting-col">
          <AppSocket
            sessionDetail={sessionDetail}
            id={param.id}
            permissionForAll={permissionForAll}
            setPermissionForAll={setPermissionForAll}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            setJoinedUsers={setJoinedUsers}
            joinedUsers={joinedUsers}
          />
          {isJoinable || dummyStart ? (
            therapyData.channelName &&
            profile.id && (
              <>
                <Call
                  id={param.id}
                  setIsStarted={setIsStarted}
                  isShowJoinBtn={isShowJoinBtn}
                  setJoinedUsers={setJoinedUsers}
                  joinedUsers={joinedUsers}
                  profile={profile}
                  sessionId={param.id}
                  {...therapyData}
                  onLeave={onLeave}
                  postCaseNote={postCaseNote}
                  countDownTimer={countDownTimer}
                  permissionForAll={permissionForAll}
                  setPermissionForAll={setPermissionForAll}
                  setSessionDetail={setSessionDetail}
                  sessionDetail={sessionDetail}
                  selectedMembers={selectedMembers}
                  setSelectedMembers={setSelectedMembers}
                  recId={randomRecId}
                  recToken={recToken}
                />
                {/* <Agora
                onJoin={() => {
                  setIsStarted(true);
                }}
                recToken={recToken}
                profile={profile}
                sessionId={param.id}
                {...therapyData}
                onLeave={onLeave}
                postCaseNote={postCaseNote}
                countDownTimer={countDownTimer}
                permissionForAll={permissionForAll}
                setPermissionForAll={setPermissionForAll}
                setSessionDetail={setSessionDetail}
                sessionDetail={sessionDetail}
                selectedMembers={selectedMembers}
                setSelectedMembers={setSelectedMembers}
                user={user}
                recId={randomRecId}
                setJoinedUsers={setJoinedUsers}
                joinedUsers={joinedUsers}
                isShowJoinBtn={isShowJoinBtn}
              /> */}
              </>
            )
          ) : (
            <div className="container text-center">
              <p className="h3">
                Meeting will start in{" "}
                {timer.startDuration && <Counter time={timer.startDuration} />}
              </p>
            </div>
          )}
          {}
          <div className="row d-none">
            <div className="col-md-5 mr-5 ml-5">
              <img
                src="../resources/assets/Rectangle 704.svg"
                className="parent"
                alt="parent"
              />
              <img
                src="../resources/assets/Group 1.svg"
                className="over-img"
                alt="over-img"
              />
            </div>
            <div className="col-md-5 ml-5">
              <img
                src="../resources/assets/Rectangle 705.svg"
                className="videoimg ml-3"
                alt="vid"
              />
            </div>
          </div>
          <div className="row mt-4 d-none">
            <div className="col-md-4 mt-3">
              <button className="btn btn-danger custom-btn-red mt-4 ml-5">
                Stop
              </button>
            </div>
            <div className="col-md-4">
              <div className="row mt-5">
                <div className="col-md-2 mt-4">
                  <Link className=" audio-video-background">
                    <img
                      src="../resources/assets/Icon awesome-video.svg"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="col-md-2 mt-4">
                  <Link className=" audio-video-background">
                    <img
                      src="../resources/assets/Icon awesome-microphone.svg"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-right ">
              <img
                className="ml-3 mt-5"
                src="../resources/assets/Ellipse 15.svg"
                alt=""
              />
              <img
                className="ml-3 mt-5"
                src="../resources/assets/Ellipse 16.svg"
                alt=""
              />
              <img
                className="ml-3 mt-5"
                src="../resources/assets/Ellipse 17.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        {/* <div className="video-therapy__meeting-col NewAgora">
          <TestPage></TestPage>
        </div> */}

        {isStarted && profile.userType === "ROLE_THERAPIST" ? (
          <>
            <div className="mb-3 no-padding video-therapy__info">
              <div className="video-therapy__info-container">
                <div className="row">
                  <div className="video-therapy__info-time-col">
                    <div className="video-therapy__info-timer">
                      <h5 className="text-center">
                        <img src="../resources/assets/b-clock.svg" alt="" />{" "}
                        Start Time
                      </h5>
                      <h4 className="text-center name">
                        {timer.startTime?.format("HH:mm A")}
                      </h4>
                    </div>
                  </div>
                  <div className="video-therapy__info-time-col">
                    <div className="video-therapy__info-timer">
                      <h5 className="text-center">
                        <img src="../resources/assets/b-clock.svg" alt="" />{" "}
                        Remaining Time
                      </h5>
                      <h4 className="text-center name">
                        {isStarted && <span>{countDownTimer}</span>}
                        {/* {isStarted && (
                          <Counter
                            time={moment.duration(
                              timer.endTime?.diff(moment())
                            )}
                            setCurrentTime={setCurrentTime}
                            currentTime={currentTime}
                            newCTime={
                              newCTime
                                ? moment.duration(newCTime?.diff(moment()))
                                : newCTime
                            }
                            isCountDown={isCountDown}
                            setIsCountDown={setIsCountDown}
                            endTimee={timer?.endTimee}
                          />
                        )} */}
                      </h4>

                      {timer.endTime && (
                        <>
                          {/* {timer.startTime.format('DD-MM-yy:HH-mm-ssA')} <br />
                                            {timer.endTime.format('DD-MM-yy:HH-mm-ssA')} */}
                          {/* <Counter time={moment.duration(timer.endTime.diff(moment()))} /> */}
                        </>
                      )}
                      {/* <h4 className="text-center name">15 : 15 Min</h4> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-0">
                    <h5 className="float-left">Session Notes</h5>
                    {/* <button
                      onClick={
                        isRecording ? stopSpeechToText : startSpeechToText
                      }
                      className={`float-right ${
                        isRecording ? "custom-mic-active-btn" : "custom-mic-btn"
                      }`}
                    >
                      <img src="../resources/assets/mic.png" alt="" />
                    </button> */}
                  </div>
                </div>
                <div className="video-therapy__case-note mt-1">
                  {profile.userType === "ROLE_THERAPIST" ? (
                    <>
                      <textarea
                        onChange={(e) => {
                          setCaseDescription(e.target.value);
                        }}
                        value={caseDescription}
                        placeholder="Please write Session Notes here."
                        className="video-therapy__case-note-input"
                      ></textarea>
                      {interimResult && (
                        <img src="../resources/assets/monophy.gif" width={20} />
                      )}
                    </>
                  ) : null}
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <label
                        htmlFor="therapy-attach-file"
                        className={classNames("cursor-pointer w-100", {
                          "no-evt": imageLoading,
                        })}
                      >
                        <img
                          src="../resources/assets/Icon material-attach-file.svg"
                          alt=""
                          className="paperclip-btn"
                        />
                        <span className="paperclip-btn-p">
                          Attach audio/video/image file
                        </span>
                        <div id="file-input">
                          <input
                            disabled={imageLoading}
                            type="file"
                            onChange={(e) => onFileAttach(e)}
                            className="d-none therapy-attach-file"
                            id="therapy-attach-file"
                          />
                        </div>
                        <span
                          className={classNames("btn", {
                            "has-loader": imageLoading,
                          })}
                        >
                          {imageLoading && <i className="loader-small"></i>}
                        </span>
                      </label>
                      <div className="video-therapy__case-note-attachments">
                        {attachments.map((file, _id) => {
                          const fileType = getFileType(file.key?.split("?")[0]);
                          console.log("_id", file);
                          return (
                            <AttachmentFile
                              {...file}
                              src={file.key}
                              key={file.id + _id}
                              attachmentType={fileType}
                              className={`video-therapy__case-note-attachment video-therapy__case-note-attachment--${fileType}`}
                              onDelete={() => deleteAttachment(_id)}
                            />
                          );
                        })}
                      </div>
                      {/* <button
                        type="button"
                        disabled={imageLoading || isSaving}
                        onClick={postCaseNote}
                        className="btn btn-primary custom-btn-green ml-5"
                      >
                        {!isSaving ? "Save as draft" : "Saving"}
                      </button> */}
                      {therapistImageURL && (
                        <img
                          src={therapistImageURL}
                          alt="my signature"
                          style={{
                            display: "block",
                            // width: "186px",
                            marginBottom: 10,
                          }}
                          className="sigCanvas12 sign-img"
                        />
                      )}
                      <button
                        type="button"
                        disabled={imageLoading || isSaving}
                        onClick={() => setOpen(true)}
                        className="btn btn-primary custom-btn-green"
                        style={{ width: 186 }}
                      >
                        {therapistImageURL ? "Update" : "Create"}&nbsp;
                        Signature
                      </button>
                      <DigitalSignPopup
                        modalState={open}
                        setModalState={setOpen}
                        save={updateSignature}
                        clear={clear}
                        sigCanvas={sigCanvas}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          isStarted &&
          (profile.userType === "ROLE_FAMILY" || profile.userType === "ROLE_INTERPRETER") && (
            <>
              <div className="mb-3 no-padding video-therapy__info">
                <div className="video-therapy__info-container">
                  <div className="align-items-center login-page">
                    <div className="col-md-12 no-padding p-0">
                      <div className="log-wrapper-left">
                        <img
                          src="../resources/assets/Mask Group 2.png"
                          className="mask-login-left"
                        />
                        {
                          <img
                            src="../resources/assets/MIS-1.png"
                            className="login-logo"
                          />
                        }
                      </div>
                      <img
                        src="../resources/assets/left-login-img.jpg"
                        className="left-img img-fluid"
                        style={{ height: "auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
}

export default VideoTherapy;
