import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getListOfDays,
  calcWeekCount,
  calcMonthCount,
} from "../../utils/commonFunctions";
import MarketingSessionsTableComp from "./MarketingSessionsTableComp";
import ArchiveTherapySessionTable from "../../components/ArchiveTherapySessionTable";

import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";
import { monthJson } from "../../config/Config";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { adminService } from "../../services/adminService";
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  getUTCTimeToLocalByDateWithMeridium,
  calculateDuration,
  getUTCTimeToLocalByDateWithMeridium24,
} from "../../utils/commonFunctions";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getUserProfile } from "../../services/caseNoteServices";
import MarketingDashboardSessionCalender from "../MarketingDashboardSessionCalender";

const localizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

function MarketingSession() {
  let user = useSelector((state) => state.adminUser.user);
  let [startFromDateTime, setStartFromDateTime] = useState("");
  let [endToDateTime, setEndToDateTime] = useState("");
  let [weekcount, setWeekCount] = useState(0);
  let [weeks, setWeeks] = useState(getListOfDays(weekcount, false));
  let [ismonth, setIsMonth] = useState(false);
  let [searchname, setSearchName] = useState("");
  const [allEventsCal, setAllEventsCal] = useState([]);
  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [sessionview, setSessionView] = useState("session-list");
  useEffect(() => {
    let prevMonth = moment().subtract(4, "months").format("YYYY-MM-DD");
    console.log(
      "month",
      moment(prevMonth).subtract(1, "months").format("YYYY-MM-DD"),
      prevMonth
    );
    setloading(true);
    getUserProfile()
      .then((res) => {
        // setProfile(res)
        setloading(false);
        setUserInfo(res);
      })
      .catch((err) => {
        if (err) {
          toast.error(
            err?.message || err?.info || err?.msg || "Somthing went wrong"
          );
        }
      });
  }, []);
  let qry_param_filter =
    user.userDetails.roles[0] === "ROLE_THERAPIST"
      ? `&therapistId=${userInfo?.id}`
      : user.userDetails.roles[0] === "ROLE_INTERPRETER"
      ? `&interpreterId=${userInfo?.id}`
      : "";

  const getNextWeeK = () => {
    setWeeks(getListOfDays(weekcount + 1, ismonth));
    setWeekCount(weekcount + 1);
  };

  const getPrevWeeK = () => {
    setWeeks(getListOfDays(weekcount - 1, ismonth));
    setWeekCount(weekcount - 1);
  };

  const setWeekMonth = (val) => {
    if (val === "week") {
      if (ismonth) {
        setWeekCount(0);
        setIsMonth(false);
        setWeeks(getListOfDays(0, false));
      }
    } else {
      if (!ismonth) {
        setWeekCount(0);
        setIsMonth(true);
        setWeeks(getListOfDays(0, true));
      }
    }
    /** TODO fix it proper commenting out for fixing the bug of changeing week its temp
     * and using it above
     */
    // if (val === "week") {
    //     if (ismonth) {
    //         const newWeekCount = calcWeekCount(weeks[0]);
    //         setWeekCount(newWeekCount)
    //         setIsMonth(false)
    //         // setWeeks(getListOfDays(weekcount, false));
    //         setWeeks(getListOfDays(newWeekCount, false));
    //     }
    // } else {
    //     if (!ismonth) {
    //         const newWeekCount = calcMonthCount(weeks[0]);
    //         setWeekCount(newWeekCount)
    //         setIsMonth(true)
    //         setWeeks(getListOfDays(newWeekCount, true));
    //     }
    // }
    /** end commenting out */
  };

  function handleChange(e) {
    setSearchName(e.target.value);
  }

  useEffect(() => {
    let startFromDateTime =
      moment(weeks.length > 0 && weeks[0]).format("YYYY-MM-DD") + "T00:00:00";
    setStartFromDateTime(startFromDateTime);
    let endToDateTime =
      moment(weeks.length > 0 && weeks[weeks.length - 1]).format("YYYY-MM-DD") +
      "T23:59:59";
    setEndToDateTime(endToDateTime);
  }, [weeks]);

  // useEffect(() => {
  //     console.log("weeks", {
  //         weeks,
  //         list: getListOfDays(weekcount, ismonth)
  //     });
  // }, [weekcount])

  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            <div className="content-header pb-0">
              <div className="text-left header-title pt-7px">
                Marketing Appointments
              </div>
              {user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS" ||
              user.userDetails.roles[0] === "ROLE_RCE" ||
              user.userDetails.roles[0] === "ROLE_THERAPIST" ||
              user.userDetails.roles[0] === "ROLE_FAMILY" ? (
                <div className="text-right header-btn">
                  <ul
                    className="nav nav-tabs"
                    id="myTab"
                    role="tablist"
                    style={{ borderBottom: 0 }}
                  >
                    {(user.userDetails.roles[0] ===
                      "ROLE_REGIONAL_EARLY_STEPS" ||
                      user.userDetails.roles[0] === "ROLE_RCE" ||
                      user.userDetails.roles[0] === "ROLE_THERAPIST") && (
                      <li className="pt-7px">
                        <Link to={`/add-marketing-session`}>
                          <button className="btn btn-primary custom-btn-green custom-btn-green-auto">
                            <img
                              className="pr-2"
                              src="../resources/assets/plus-icon-button.svg"
                              alt=""
                            />
                            Add New Appointment
                          </button>
                        </Link>
                      </li>
                    )}
                    <li
                      className="nav-item dashboard"
                      onClick={() => setSessionView("session-list")}
                    >
                      <a
                        className="nav-link active"
                        id="session-tab"
                        data-toggle="tab"
                        href="#session"
                        role="tab"
                        aria-controls="session"
                        aria-selected="true"
                      >
                        {sessionview === "session-list" ? (
                          <img
                            src="../resources/assets/calendar-list-select.svg"
                            alt=""
                          />
                        ) : (
                          <img src="../resources/assets/calendarM.svg" alt="" />
                        )}
                      </a>
                    </li>

                    <li
                      className="nav-item dashboard"
                      onClick={() => setSessionView("session-cal")}
                    >
                      <a
                        className="nav-link"
                        id="team-tab"
                        data-toggle="tab"
                        href="#team"
                        role="tab"
                        aria-controls="team"
                        aria-selected="false"
                      >
                        {sessionview === "session-cal" ? (
                          <img src="../resources/assets/calendarW.svg" alt="" />
                        ) : (
                          <img
                            src="../resources/assets/calendar-gray.svg"
                            alt=""
                          />
                        )}
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="session"
                role="tabpanel"
                aria-labelledby="session-tab"
              >
                <>
                  <div className="row">
                    <div className="col-md-7 justify-content-between align-items-center mt-4 mb-3 d-flex">
                      <div className="col-auto therapy-session-search">
                        <input
                          onChange={handleChange}
                          type="search"
                          name="text"
                          className="form-control search"
                          placeholder={`Search`}
                          autofocus
                        />
                      </div>

                      {/* <div className="col-auto">
                                    <button className="btn btn-theme-green" type="button" onClick={()=>setSearchNameFun()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                            <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                        </svg>
                                    </button>
                                </div> */}

                      <div className="col-md-9 d-flex justify-content-end">
                        <div className="col-auto">
                          <select
                            className="form-control time"
                            onChange={(e) => setWeekMonth(e.target.value)}
                            id="exampleFormControlSelect1"
                          >
                            <option className="form-control time" value="week">
                              Week
                            </option>
                            <option className="form-control time" value="Month">
                              Month
                            </option>
                          </select>
                        </div>
                        <span className="font-weight-bold d-flex align-items-center">
                          {weeks.length > 0 &&
                            weeks.map((day, index) =>
                              index === 0
                                ? moment(day).format("DD") +
                                  " " +
                                  monthJson[parseInt(day.split("-")[1]) - 1]
                                    .name
                                : index === weeks.length - 1
                                ? " - " +
                                  moment(day).format("DD") +
                                  " " +
                                  monthJson[parseInt(day.split("-")[1]) - 1]
                                    .name +
                                  ", " +
                                  day.split("-")[0]
                                : ""
                            )}
                        </span>
                      </div>

                      <div className="btn-group filter-next-prev" role="group">
                        <button
                          onClick={() => getPrevWeeK()}
                          className="btn next"
                          type="button"
                        >
                          <img
                            className="pr-2"
                            src="../resources/assets/arrow-left.svg"
                            alt=""
                          />
                        </button>
                        <div className="action-devider-button"></div>
                        <button
                          onClick={() => getNextWeeK()}
                          className="btn prev"
                          type="button"
                        >
                          <img
                            className="pl-2"
                            src="../resources/assets/arrow-right.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />

                  {startFromDateTime !== "" && (
                    <MarketingSessionsTableComp
                      startFromDateTime={startFromDateTime}
                      endToDateTime={endToDateTime}
                      searchname={searchname}
                    />
                  )}
                </>
              </div>
              <div
                className="tab-pane fade"
                id="team"
                role="tabpanel"
                aria-labelledby="team-tab"
              >
                {loading
                  ? "loding"
                  : userInfo?.id != undefined && (
                      <MarketingDashboardSessionCalender
                        userrole={user.userDetails.roles[0]}
                        isCal={true}
                        userid={userInfo?.id}
                      />
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketingSession;
