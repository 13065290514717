import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import {
  getCaseNote,
  getArchiveCaseNote,
  getDiagnoses,
  getCpt,
  getUserProfile,
  draftByInterpreter,
  acceptByFamily,
  signoffByFamily,
} from "../../services/caseNoteServices";
import { adminService } from "../../services/adminService";
import { isRtl, sortLanguage } from "../../utils/commonFunctions";
import {
  CaseNoteInfo,
  CaseNoteInfoCol,
} from "../../components/case-note-detail";
import Query from "../../components/query/Query";
import { AttachmentForm } from "../../components/attachment";
import adminAction from "../../redux/actions/adminAction";
import AppModal from "../../components/AppModal";
import CaseNoteShareForm from "../../components/case-note-detail/CaseNoteShareForm";
import appAction from "../../redux/actions/appAction";
import CaseNoteBillingInfo from "../../components/case-note-detail/CaseNoteBillingInfo";
import CaseNoteDownload from "../../components/case-note-detail/CaseNoteDownload";
import CaseNotePrint from "../../components/case-note-detail/CaseNotePrint";
import DigitalSignPopup from "../../components/digitalSignPopup";
import Modal from "react-modal";
import SweetAlert from "react-bootstrap-sweetalert";

const customStyles = {
  content: {
    maxWidth: "450px",
    minHeight: "150px",
    paddingTop: "30px",
    paddingLeft: "45px",
    borderRadius: "12px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
  },
};
const ViewArchiveCaseNotes = () => {
  const [therapistImageURL, setTherapistImageURL] = useState(null);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [signFor, setSignFor] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const sigCanvas = useRef({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [diagnoses, setDiagnoses] = useState([]);
  const [cpt, setCpt] = useState([]);
  const [signLoading, setSignLoading] = useState(false);
  const [billableLoading, setBillableLoading] = useState(true);
  const [billableRate, setBillableRate] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLang, setSelectedLang] = useState("");
  const [isShareModal, setIsShareModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [goalsCreated, setGoalsCreated] = useState(false);
  const dispatch = useDispatch();
  const profile = useSelector((st) => st.adminUser.profile);
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  let history = useNavigate();
  const user = useSelector((st) => st.adminUser.user)?.userDetails;
  const caseNoteRef = React.useRef(null);

  useEffect(() => {
    loadInitials();
  }, [id]);

  const loadInitials = async () => {
    setIsLoading(true);
    Promise.allSettled([getUserProfile(), getArchiveCaseNote(id)]).then(
      (results) => {
        results.forEach(({ status, value }, index) => {
          if (status === "fulfilled") {
            switch (index) {
              case 0:
                // setProfile(value);
                break;

              case 1:
                setState(value);
                setUnits(value?.caseNoteDTO?.units);
                setAttachments(value?.caseNoteDTO?.attachmentsList || []);
                break;

              // case 2:
              //     setDiagnoses(value);
              //     break;

              // case 3:
              //     setCpt(value);
              //     break;

              default:
                break;
            }
          } else {
            const msg =
              value?.message ||
              value?.info ||
              value?.msg ||
              "Something went wrong";
            toast.error(msg);
          }
        });
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getUserProfile();
  }, [profile]);

  useEffect(() => {
    if (isSaving) {
      dispatch(appAction.setAppLoading(true));
    } else {
      dispatch(appAction.setAppLoading(false));
    }
    return () => {
      dispatch(appAction.setAppLoading(false));
    };
  }, [isSaving]);

  const [units, setUnits] = useState(
    state?.caseNoteDTO ? state?.caseNoteDTO?.units : null
  );
  const [cptIdValue, setCptId] = useState(
    state?.caseNoteDTO ? state?.caseNoteDTO?.cptId : null
  );
  useEffect(() => {
    if (state) {
      getBillableRate(state?.caseNoteDTO?.billableRate);
      setBillableValue(state?.caseNoteDTO?.diagnosisId);
    }
  }, [state?.caseNoteDTO]);

  useEffect(() => {
    if (state) {
      if (state.caseNoteDTO?.sessionDto?.id) {
        getDiagnoses(state.caseNoteDTO?.familyMemberDto?.id, state.caseNoteDTO?.sessionDto?.therapy?.id, !isSubmmited).then(
          (value) => {
            setDiagnoses(value);
          }
        );
        getCpt(state.caseNoteDTO?.sessionDto?.id, !isSubmmited).then(
          (value) => {
            setCpt(value);
          }
        );
      }
    }
  }, [state.caseNoteDTO?.sessionDto.id]);

  const dateMoment = moment.utc(state.startDateTime).utc().local();

  const date = dateMoment.format("DD MMMM, yyyy");
  const { duration, therapy, therapist, interpreterName } = state;
  const isTherepist = (user?.roles || []).includes("ROLE_THERAPIST");
  const isFamily = (user?.roles || []).includes("ROLE_FAMILY");
  const isInterepreter = (user?.roles || []).includes("ROLE_INTERPRETER");
  const isSubmmited = !state.caseNoteDTO?.drafted;

  const isSharedCaseNote = state?.caseNoteDTO?.sharedTherapist?.some((item) => {
    return item?.userProfile?.id === profile.id;
  });
  const translatedDir = isRtl(
    state?.caseNoteDTO?.languageDto?.languageCode?.trim() ||
      selectedLang?.languageCode?.trim() ||
      (state.caseNoteDTO?.familyLanguages || [])[0]?.languageCode?.trim()
  )
    ? "rtl"
    : "ltr";

  const currentDiagnoses = diagnoses.find(
    (itm) => +state.caseNoteDTO?.diagnosisId === +itm.id
  );
  const currentCpt = cpt.find((itm) => +state.caseNoteDTO?.cptId === +itm.id);

  const {
    drafted: isTherapistDraft,
    draftedInterpreter: isInterepreterDraft,
    acceptCaseNotes: isAccepetedByFamily,
  } = state?.caseNoteDTO || {};
  const infoProps = {
    sessionNumber: state.caseNoteDTO?.sessionDto.id,
    date,
    therapist,
    interpreterName,
    therapy,
    clinicianNpiNumber: state.caseNoteDTO?.clinicianNpiNumber,
    clinicNpiNumber: state.caseNoteDTO?.clinicNpiNumber,
    groupNpiNumber: state.caseNoteDTO?.groupNpiNumber,
  };

  const onSignatureUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("digitalSignature", file);
      setSignLoading(true);
      updateSignature(formData);
    }
  };

  const save = () => {
    if (signFor == "therapist") {
      setTherapistImageURL(
        sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
      );
    } else {
      setFamilyImageURL(
        sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
      );
    }

    const formData = new FormData();
    formData.append(
      "digitalSignature",
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    setSignLoading(true);
    updateSignature(signatureData);

    setOpen(false);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            getUserProfile()
              .then((res) => {
                // setProfile(res)
                dispatch(adminAction.setProfileData(res));
                setSignLoading(false);
              })
              .catch((err) => {
                setSignLoading(false);
                toast.error(err.message || err.info || err.msg);
              });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        setSignLoading(false);
      });
  };

  const setBillableValue = (selectedRate) => {
    if (selectedRate !== "" && selectedRate !== null) {
      if (selectedRate === "0") {
        setBillableRate("0");
        setCptId("0");
        setBillableLoading(true);
        setUnits("0");
      } else {
        setBillableLoading(false);
        setBillableRate(state?.caseNoteDTO?.billableRate);
        setCptId(state?.caseNoteDTO?.cptId);
        setUnits(state?.caseNoteDTO?.units);
      }
    } else {
      setBillableRate("");
      setBillableLoading(true);
      setCptId("");
      setUnits("");
    }
  };
  const getBillableRate = (selectedRate, cptList, selectedId) => {
    if (selectedId) {
      const fee = cptList?.find((c) => `${c.id}` === selectedId)?.maxFee;
      setBillableRate(fee);
      setCptId(selectedId);
    } else if (selectedRate) {
      setBillableRate(selectedRate);
      setCptId(selectedRate);
    }
  };

  const generateCaseNoteData = (isDrafted) => {
    const { cptId, diagnosisId, description, ...rest } = getValues();
    return {
      // ...state.caseNoteDTO,
      id: state.caseNoteDTO.id,
      attachmentsList: [
        ...attachments
          .map((item) => ({ id: item.id }))
          .filter((item) => item.id),
      ],
      ...rest,
      cptId: +cptId,
      diagnosisId: +diagnosisId,
      description,
      billableRate,
      sessionId: state.caseNoteDTO.sessionDto.id,
      familyMemberId: state.caseNoteDTO.familyMemberDto.id,
      drafted: isDrafted,
      draftedInterpreter: true,
      goalStatus: state?.caseNoteDTO?.goalStatus,
    };
  };
  const _handleRes = (res, msg) => {
    if (res?.status?.toLowerCase() === "success") {
      toast.success(msg);
    } else {
      toast.error(res.message);
    }
  };
  const _handleErr = (err) => {
    toast.error(err.info || err.msg || err.message);
  };
  const draftCaseNote = (e) => {
    e.preventDefault();

    setIsSaving(true);

    adminService
      .PUT(
        "case-notes",
        JSON.stringify({
          ...generateCaseNoteData(true),
        })
      )
      .then((res) => {
        if (res?.status?.toLowerCase() === "success") {
          toast.success("Saved");
          checkGoal("saveasdraft");
        } else {
          toast.error(res.message);
        }
        // _handleRes(res, "Saved");
        setIsSaving(false);
      })
      .catch((err) => {
        setIsSaving(false);
        _handleErr(err);
      });
  };

  const checkGoal = (callFrom = "submit") => {
    const { description, ...rest } = getValues();
    let reqdata = {
      sessionId: state?.caseNoteDTO?.sessionDto?.id,
      caseNoteId: state.caseNoteDTO?.id,
      description: description || state?.caseNoteDTO?.sessionDescription,
      estimatedDuration: null,
      familyMemberId: state.caseNoteDTO?.familyMemberDto?.id,
      nextSessionDate: null,
      visitingFrequency: null,
    };
    // goals for this session has been discarded
    if (state?.caseNoteDTO?.goalStatus == "DISCARDED") {
      toast.warning("Goals for this session has been discarded.");
      // setGoalsCreated(true);
    } else if (state?.caseNoteDTO?.goalStatus == "DEFAULT") {
      adminService
        .POST(`goals/casenote`, JSON.stringify(reqdata))
        .then((res) => {
          if (res?.status === "Failure") {
            console.log("callFrom..", callFrom);
            state?.caseNoteDTO?.goalStatus == "DEFAULT" &&
              setOpenModal(callFrom != "saveasdraft" ? "submit" : callFrom);
          } else if (res?.status === "Success") {
            callFrom == "saveasdraft"
              ? history(`/misgoals/${id}`)
              : postCaseNote();
          }
        })
        .catch((err) => {});
    } else {
      if (callFrom == "saveasdraft") {
        state?.caseNoteDTO?.goalStatus != "REJECTED" &&
          history(`/misgoals/${id}`);
      } else {
        postCaseNote();
      }
    }
  };

  const postCaseNote = (_e) => {
    if (!state?.caseNoteDTO?.therapistSignatureKey && !profile.signatureUrl) {
      toast.error("Please Upload Signature!");
      return;
    }
    setIsSaving(true);
    adminService
      .PUT(
        "case-notes",
        JSON.stringify({
          ...generateCaseNoteData(false),
        })
      )
      .then((res) => {
        setIsSaving(false);
        getCaseNote(id).then((value) => {
          setState(value);
          setUnits(value?.caseNoteDTO?.units);
          setAttachments(value?.caseNoteDTO?.attachmentsList || []);
        });
        if (res?.status?.toLowerCase() === "success") {
          toast.success("Submitted");
          // checkGoal();
          state?.caseNoteDTO?.goalStatus != "REJECTED" &&
            history(`/misgoals/${id}`);
        } else {
          toast.error(res.message);
        }
        // _handleRes(res, "Submitted");
      })
      .catch((err) => {
        setIsSaving(false);
        _handleErr(err);
      });
  };
  const saveTranslation = (e) => {
    e.preventDefault();
    setIsSaving(true);
    adminService
      .POST(
        `case-notes/${state.caseNoteDTO?.id}/translation`,
        JSON.stringify({
          translatedNote: getValues().translatedDescription,
          languageId: +getValues().languageDto,
        })
      )
      .then((res) => {
        getCaseNote(id).then((value) => {
          setState(value);
          setUnits(value?.caseNoteDTO?.units);
          //setAttachments(value?.caseNoteDTO?.attachmentsList || []);
        });
        _handleRes(res, "Submitted Successfully");
      })
      .catch((err) => {
        _handleErr(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  const draftTranslation = (e) => {
    e.preventDefault();

    setIsSaving(true);
    draftByInterpreter(
      JSON.stringify({
        id: state.caseNoteDTO.id,
        languageId: +getValues().languageDto,
        translatedNote: getValues().translatedDescription,
      })
    )
      .then((res) => {
        setIsSaving(false);
        toast.success("Saved Successfully");
      })
      .catch((err) => {
        setIsSaving(false);
        _handleErr(err);
      });
  };

  const acceptCaseNote = (e) => {
    e.preventDefault();
    let isSign = true;
    if (profile.userType == "ROLE_FAMILY") {
      isSign = familyImageURL ? true : false;
    }
    if (!profile.signatureUrl && !isSign) {
      toast.error("Please upload the signature.");
      return;
    }

    setIsSaving(true);
    signoffByFamily(state.caseNoteDTO.id)
      .then((res) => {})
      .catch(_handleErr);
    acceptByFamily(state.caseNoteDTO.id)
      .then((res) => {
        setIsSaving(false);
        toast.success("Accepted");
        getCaseNote(id).then((value) => {
          setState(value);
          setUnits(value?.caseNoteDTO?.units);
          //setAttachments(value?.caseNoteDTO?.attachmentsList || []);
        });
      })
      .catch((err) => {
        setIsSaving(false);
        _handleErr(err);
      });
  };

  const queryParams = {
    childName: state?.childName,
    therapy: state?.therapy,
    therapist: state?.therapist,
    interpreterName: state?.interpreterName,
    startDateTime: state?.startDateTime,
    childProfilePic: state?.childProfilePic,
    sessionId: state.caseNoteDTO?.sessionDto?.id,
    query: state?.queryDTO,
    languageDto: state?.caseNoteDTO?.languageDto,
    familyLanguages: state?.caseNoteDTO?.familyLanguages,
    isSharedCaseNote,
    replyBtn:false,
    archive:true
  };

  const onPdfCreate = (cb) => {
    setIsDownloading(true);
    dispatch(appAction.setAppLoading(true));
    setTimeout(() => {
      if (typeof cb === "function") {
        cb();
      }
    }, 200);
  };

  const onPdfComplete = () => {
    setIsDownloading(false);
    dispatch(appAction.setAppLoading(false));
  };

  const openSingModal = (val = "therapist") => {
    setOpen(true);
    setSignFor(val);
  };

  const clear = () => sigCanvas.current.clear();

  // yes Handler Create Goals
  const yesHandler = async (e) => {
    const { description, ...rest } = getValues();
    let reqdata = {
      sessionId: state?.caseNoteDTO?.sessionDto?.id,
      caseNoteId: state.caseNoteDTO?.id,
      description: description || state.caseNoteDTO?.description,
      estimatedDuration: null,
      familyMemberId: state.caseNoteDTO?.familyMemberDto?.id,
      nextSessionDate: null,
      visitingFrequency: null,
    };

    e.preventDefault();

    adminService.POST(`goals`, JSON.stringify(reqdata)).then((res) => {
      console.log("Post APi sucessfully", res.data);
      if (res?.code == 400) {
        toast.warning(res?.message);
        setOpenModal(false);
      } else if (res?.code >= 200 && res?.code <= 299) {
        openModal == "submit"
          ? postCaseNote()
          : history(`/misgoals/${id}`);
      }
      if (res.status === "Failure") {
        // setGoalsCreated(true);
      }
    });
  };
  const onCloseHandler = () => {
    setGoalsCreated(false);
    setOpenModal(false);
  };
  const noHandler = () => {
    setOpenModal(false);
    adminService
      .getAll(`case-notes/nogoals/${id}`)
      .then((res) => {
        loadInitials();
      })
      .catch((e) => console.log("e", e));
  };
  return (
    <>
      {isDownloading && (
          <div className="loader" />
      )}
      <div className="col-md-10">
        {isLoading && <div className="loader" />}
        <div className="d-none">
          <CaseNotePrint
            ref={caseNoteRef}
            state={state}
            infoProps={infoProps}
            currentDiagnoses={currentDiagnoses}
            currentCpt={currentCpt}
            billableRate={billableRate}
            duration={duration}
            isAccepetedByFamily={isAccepetedByFamily}
            interpreterName={interpreterName}
            isInterepreterDraft={isInterepreterDraft}
            isTherepist={isTherepist}
            translatedDir={translatedDir}
            userType={profile.userType}
          />
        </div>
        {!isLoading && !isSubmmited && !isTherepist && !isSharedCaseNote && (
          <div className="p-case-note-detail">
            <div className="p-case-note-detail__header text-center">
              Session Note is not submitted yet.
            </div>
          </div>
        )}
        {!isLoading && (isSubmmited || isTherepist || isSharedCaseNote) && (
          <div className="p-case-note-detail">
            <div className="p-case-note-detail__header pl-20">
              <div className="d-flex justify-content-between">
                <h2 className="header-title">Session Note </h2>
                <CaseNoteBillingInfo />
              </div>
            </div>
            <div className="p-case-note-detail__body">
              <div className="d-flex justify-content-between">
                <div className="p-case-note-detail__user flex-grow-1">
                  <img src={state.childProfilePic} alt={state.childName} />
                  <h4 className="p-case-note-detail__child-name text-capitalize">
                    {state.childName}&nbsp;
                    {state?.childDOB && (
                      <small>
                        (<b>DOB: </b>
                        {moment(state?.childDOB).format("MM/DD/YYYY")})
                      </small>
                    )}
                  </h4>
                </div>
                {isSubmmited && (
                  <div style={{ flex: 0 }}>
                    <CaseNoteDownload
                      downloadName={`Session-Note-${state.childName}.pdf`}
                      targetRef={caseNoteRef}
                      beforeStart={onPdfCreate}
                      onComplete={onPdfComplete}
                    />
                  </div>
                )}
              </div>
              <CaseNoteInfo {...infoProps} />

              <div className="row">
                <div className="col-md-4 mb-3">
                  <CaseNoteInfoCol
                    noValue={!isSubmmited}
                    label="ICD 10 Code"
                    value={
                      currentDiagnoses
                        ? `(${currentDiagnoses.code}) ${currentDiagnoses.description}`
                        : state.caseNoteDTO?.diagnosisId
                    }
                  />
                  {diagnoses.length > 0 &&
                    isTherepist &&
                    !isSubmmited &&
                    !isSharedCaseNote && (
                      <div className="select-wrapper">
                        <select
                          name="diagnosisId"
                          {...register('diagnosisId',{ onChange:(e) => {
                            setBillableValue(e.target.value);
                          },required: true })}
                          defaultValue={state.caseNoteDTO?.diagnosisId}
                          className="form-control"
                          // onChange={(e) => {
                          //   setBillableValue(e.target.value);
                          // }}
                        >
                          <option value="">Please Select</option>
                          {/* <option value="0">Not Applicable</option>*/}
                          {diagnoses.map((item) => (
                            <option
                              key={item.id}
                              value={item.id}
                            >{`(${item.code}) ${item.description}`}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  {errors.diagnosisId && (
                    <span className="error-text">
                      Please Select ICD 10 Code
                    </span>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <CaseNoteInfoCol
                    noValue={!isSubmmited}
                    label="CPT Code"
                    value={
                      currentCpt
                        ? `(${currentCpt.procCode}) ${currentCpt.description}`
                        : state.caseNoteDTO?.cptId
                    }
                  />
                  {cpt.length > 0 &&
                    isTherepist &&
                    !isSubmmited &&
                    !isSharedCaseNote && (
                      <div
                        className={`select-wrapper ${
                          billableLoading ? "disable-select" : ""
                        }`}
                      >
                        <select
                          name="cptId"
                          {...register('cptId',{
                            onChange:(e) => {
                              getBillableRate(
                                state?.caseNoteDTO?.billableRate,
                                cpt,
                                e.target.value
                              );
                            },
                            required: true,
                          })}
                          // onChange={(e) => {
                          //   getBillableRate(
                          //     state?.caseNoteDTO?.billableRate,
                          //     cpt,
                          //     e.target.value
                          //   );
                          // }}
                          value={cptIdValue ? cptIdValue : ""}
                          className="form-control"
                          disabled={billableLoading}
                        >
                          <option value="">Please Select</option>
                          {/*<option value="0">Not Applicable</option>*/}
                          {cpt.map((item) => (
                            <option
                              key={item.id}
                              value={item.id}
                            >{`(${item.procCode}) ${item.description}`}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  {errors.cptId && (
                    <span className="error-text">Please Select Cpt Code</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <CaseNoteInfoCol
                    label="Duration/Unit"
                    value={billableRate === "0" ? "Not Applicable" : duration}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <CaseNoteInfoCol
                    noValue={!isSubmmited}
                    label="Units"
                    value={state?.caseNoteDTO?.units}
                  />
                  {!isSubmmited && isTherepist && !isSharedCaseNote && (
                    <input
                      type="number"
                      defaultValue={state?.caseNoteDTO?.units}
                      min={1}
                      step="1"
                      value={units}
                      // onChange={(e) => {
                      //   const { value } = e.target;
                      //   setUnits(parseInt(value));
                      // }}
                      onWheel={(event) => {
                        event.preventDefault();
                        if (typeof event.target.blur === "function") {
                          event.target.blur();
                        }
                      }}
                      name="units"
                      {...register('units',{ onChange:(e) => {
                        const { value } = e.target;
                        setUnits(parseInt(value));
                      },required: true })}
                      className="form-control"
                    />
                  )}
                  {errors.units && (
                    <span className="error-text">Please Enter Units</span>
                  )}
                </div>
              </div>
              <div className="p-case-note-detail__content">
                <form>
                  <div className="d-block">
                    <div className="w-100">
                      <h5 className="p-case-note-detail__content-heading">
                        Description
                      </h5>
                      <p>{state.caseNoteDTO?.sessionDescription}</p>
                    </div>
                    <div className="w-100">
                      <div
                        className={classNames(
                          "p-case-note-detail__main-casenote",
                          {
                            "p-case-note-detail__main-casenote--submitted":
                              (isTherepist && isSubmmited) ||
                              (isInterepreter && !isInterepreterDraft) ||
                              isFamily,
                          }
                        )}
                      >
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <h5 className="p-case-note-detail__content-heading">
                              Session Notes details
                            </h5>
                            {isTherepist && !isSharedCaseNote && (
                              <div
                                onClick={() => setIsShareModal(true)}
                                className="p-case-note-detail__share"
                              >
                                <ReactSVG
                                  wrapper="span"
                                  fill="currentColor"
                                  src="../resources/assets/Icon feather-share-2.svg"
                                />
                                <span className="pl-2">Share</span>
                              </div>
                            )}
                          </div>
                          {isTherepist && !isSharedCaseNote && (
                            <AppModal
                              title="Share Session Notes"
                              onClose={() => setIsShareModal(false)}
                              isOpen={isShareModal}
                            >
                              <CaseNoteShareForm
                                date={state.startDateTime}
                                therapy={therapy}
                                childName={state?.childName}
                                sharedTherapist={
                                  state?.caseNoteDTO?.sharedTherapist
                                }
                                id={id}
                                isOpen={isShareModal}
                                onComplete={() => {
                                  setIsShareModal(false);
                                  loadInitials();
                                }}
                                description={state.caseNoteDTO?.description}
                              />
                            </AppModal>
                          )}
                          {!isTherepist || isSharedCaseNote || isSubmmited ? (
                            <p className="preview">
                              {state.caseNoteDTO?.description}
                            </p>
                          ) : (
                            <textarea
                              defaultValue={state.caseNoteDTO?.description}
                              name="description"
                              {...register}
                              disabled={!isTherepist || isSubmmited}
                              className="mb-10 p-case-note-detail__input  p-case-note-detail__input--translate"
                            ></textarea>
                          )}
                        </div>
                        {state.interpreterName && !isTherepist && (
                          // (isFamily )
                          <div className="w-100">
                            <h5 className="p-case-note-detail__content-heading">
                              {state?.caseNoteDTO?.languageDto ? (
                                <span>
                                  Translated in{" "}
                                  {
                                    state?.caseNoteDTO?.languageDto
                                      ?.languageName
                                  }
                                </span>
                              ) : (
                                <span>Translation</span>
                              )}
                              {isInterepreter &&
                                !state?.caseNoteDTO?.languageDto &&
                                isInterepreterDraft && (
                                  <select
                                    name="languageDto"
                                    onChange={(e) => {
                                      setSelectedLang(
                                        state.caseNoteDTO?.familyLanguages.find(
                                          (lng) => lng.id === +e.target.value
                                        )
                                      );
                                    }}
                                    className="form-control-sm ml-2"
                                    {...register}
                                  >
                                    {sortLanguage(
                                      state.caseNoteDTO?.familyLanguages
                                    )?.map((lang) => (
                                      <option key={lang.id} value={lang.id}>
                                        {lang.languageName}
                                      </option>
                                    ))}
                                  </select>
                                )}
                            </h5>
                            {!isInterepreter || !isInterepreterDraft ? (
                              <p className="preview" dir={translatedDir}>
                                {state?.caseNoteDTO?.translatedDescription}
                              </p>
                            ) : (
                              <textarea
                                defaultValue={
                                  state.caseNoteDTO?.translatedDescription
                                }
                                name="translatedDescription"
                                {...register}
                                dir={translatedDir}
                                disabled={
                                  !isInterepreter || !isInterepreterDraft
                                }
                                placeholder="Translation"
                                className="mb-10 p-case-note-detail__input p-case-note-detail__input--translate"
                              ></textarea>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-100">
                      <AttachmentForm
                        attachments={attachments}
                        enableUpload={
                          isTherepist && !isSubmmited && !isSharedCaseNote
                        }
                        onSuccess={(file) => {
                          setAttachments((attachments) => [
                            ...attachments,
                            file,
                          ]);
                        }}
                        onDelete={
                          isTherepist && !isSubmmited && !isSharedCaseNote
                            ? (file, _id) => {
                                const newAttachments = attachments.filter(
                                  (item, key) => key !== _id
                                );
                                setAttachments(newAttachments);
                              }
                            : undefined
                        }
                        label="Attach audio/video/image/document file"
                        accept="image/*, audio/*, video/*, .pdf, .doc, .docx"
                      />
                    </div>

                    <div className="row mt-20">
                      <div className="col-md-4 mb-3">
                        <CaseNoteInfoCol noValue label="Therapist Signature" />

                        <div className="d-flex1">
                          {therapistImageURL ? (
                            <img
                              src={therapistImageURL}
                              alt="my signature"
                              style={{
                                // display: "block",
                                // width: "auto",
                                marginBottom: 10,
                              }}
                              className="sign-img"
                            />
                          ) : (
                            (state?.caseNoteDTO?.therapistSignatureKey ||
                              (isTherepist &&
                                !isSharedCaseNote &&
                                profile.signatureUrl)) && (
                              <img
                                className="sign-img"
                                src={
                                  (isTherepist &&
                                    !isSharedCaseNote &&
                                    profile?.signatureUrl) ||
                                  state?.caseNoteDTO?.therapistSignatureKey
                                }
                                alt="signature"
                              />
                            )
                          )}
                        </div>
                        {isTherepist && !isSubmmited && !isSharedCaseNote && (
                          <button
                            type="button"
                            className="btn btn-theme-green mt-10"
                            onClick={() => openSingModal("therapist")}
                          >
                            {therapistImageURL || profile?.signatureUrl
                              ? "Update"
                              : "Upload"}
                          </button>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <CaseNoteInfoCol noValue label="Caretaker Signature" />
                        <div className="d-flex1">
                          <div style={{ display: "block" }}>
                            {familyImageURL ? (
                              <img
                                src={familyImageURL}
                                alt="my signature"
                                style={{
                                  marginBottom: 10,
                                }}
                                className="sign-img"
                              />
                            ) : (
                              (isAccepetedByFamily || isFamily) &&
                              (state?.caseNoteDTO?.familySignatureKey ||
                                (isFamily && profile.signatureUrl)) && (
                                <img
                                  className="sign-img"
                                  src={
                                    (isFamily && profile?.signatureUrl) ||
                                    state?.caseNoteDTO?.familySignatureKey
                                  }
                                  alt="signature"
                                />
                              )
                            )}
                          </div>
                          {isFamily && !isAccepetedByFamily && (
                            <button
                              type="button"
                              className="btn btn-theme-green mt-10"
                              onClick={() => openSingModal("family")}
                            >
                              {familyImageURL || profile?.signatureUrl
                                ? "Update"
                                : "Upload"}{" "}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <DigitalSignPopup
                          modalState={open}
                          setModalState={setOpen}
                          save={save}
                          clear={clear}
                          sigCanvas={sigCanvas}
                        />
                      </div>
                    </div>
                    {isTherepist && !isSubmmited && !isSharedCaseNote && (
                      <div className="row">
                        <div className="col-12 p-case-note-detail__actions">
                          <button
                            onClick={draftCaseNote}
                            className="action-btn btn btn-theme-green"
                          >
                            Save as Draft
                          </button>
                          <button
                            // onClick={handleSubmit(postCaseNote)}
                            onClick={handleSubmit(checkGoal)}
                            className="action-btn btn btn-outline-theme-blue"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                    {isInterepreter && isInterepreterDraft && (
                      <div className="row">
                        <div className="col-12 p-case-note-detail__actions">
                          <button
                            onClick={draftTranslation}
                            className="action-btn btn btn-theme-green"
                          >
                            Save as Draft
                          </button>
                          <button
                            onClick={saveTranslation}
                            className="action-btn btn btn-theme-green"
                          >
                            Submit Translation
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </form>

                {!isAccepetedByFamily &&
                  isFamily &&
                  (interpreterName ? (
                    !isInterepreterDraft && (
                      <button
                        onClick={acceptCaseNote}
                        className="action-btn btn btn-theme-green"
                      >
                        Accept Session Note
                      </button>
                    )
                  ) : (
                    <button
                      onClick={acceptCaseNote}
                      className="action-btn btn btn-theme-green"
                    >
                      Accept Session Note
                    </button>
                  ))}
                {isAccepetedByFamily && (
                  <Query
                    onFirstSuccess={() => {
                      getCaseNote(id).then((value) => {
                        setState(value);
                        setUnits(value?.caseNoteDTO?.units);
                        //setAttachments(value?.caseNoteDTO?.attachmentsList || []);
                      });
                    }}
                    {...queryParams}
                  />
                )}
              </div>
              {/* <button className="btn btn-success ml-3 mt-3" onClick={()=>history.goBack()}>Back</button> */}
            
            </div>
          </div>
        )}

        {openModal && (
          <>
            <AppModal
              title="Confirmation"
              // hideHeaderBackground={true}
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
            >
              <h4 className="text-center mb-25">
                Do you want to create goals for {state.childName} for{" "}
                {state.therapy}?
              </h4>
              <>
                <div className="d-flex justify-content-center mb-10">
                  <button
                    onClick={(e) => noHandler(e)}
                    className="btn btn-danger"
                  >
                    No
                  </button>
                  <button
                    onClick={(e) => yesHandler(e)}
                    className="btn btn-theme-green"
                  >
                    Yes
                  </button>
                </div>
              </>
            </AppModal>
          </>
        )}
      </div>
    </>
  );
};

export default ViewArchiveCaseNotes;
