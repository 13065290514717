import React from "react";
import AppRoutes from "./Router";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AgoraProvider } from "./components/agora/utils/container";
import { FormProvider } from "./components/forms/FormContext";
function App() {
  return (
    <FormProvider>
      <AgoraProvider>
        <Router>
            <AppRoutes />
        </Router>
      </AgoraProvider>
    </FormProvider>
  );
}

export default App;
