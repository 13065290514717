import React from 'react'
import { useSelector } from 'react-redux'

const CaseNoteBillingInfo = () => {
    const soapNoteBillingData = useSelector(st => st.appState?.soapNoteBillingData)
    if(soapNoteBillingData?.length <= 0){
        return null
    }
    const {
        name,
        address1,
        address2,
        city,
        country,
        state,
        zipCode,
        logoUrl
    } = soapNoteBillingData
    const joinWithComma = (...params) => params.filter(Boolean).join(", ") 
    return (
        <div className="c-casenote-billing-info text-right d-flex align-items-center" >
            <div className="c-casenote-billing-info__image-wrapper pl-2 pr-2">
                {logoUrl && <img src={logoUrl} alt={name} className="img-fluid c-casenote-billing-info__image"/>}
            </div>
            <div className="c-casenote-bililng-info__content">
                <h4 className="mb-0">{name}</h4>
                <p className="mb-1" >
                    <span className="d-block">{joinWithComma(address1,address2)}</span>
                    <span className="d-block">{joinWithComma(city, state, country, zipCode)}</span>
                </p>
            </div>
        </div>
    )
}

export default CaseNoteBillingInfo
