import React, { useEffect, useState } from 'react'
import firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import { firebaseCofig } from '../../config/Config';
import { generateMsgKey } from './msgUtil';
import messageAction from '../../redux/actions/messageAction';
import { getFamiliesListByTherapist, getFamilyListByInterpreter, getFamilyUserDetail, getMyTeamDetail } from '../../services/userServices';

const AllMessages = (props) => {

    const profile = useSelector((state) => state.adminUser.profile);
    const tenantId = useSelector((state) => state.adminUser.tenantInfo.tenantId);
    //const tenantId1 = useSelector((state) => state.adminUser.tenantInfo.tenantId);
    console.log("profile user", profile);
    // const myKey = generateMsgKey(profile?.userType, profile?.id, props.myChildId )
    const dispatch = useDispatch();
    const isFamily = profile?.userType === "ROLE_FAMILY"
    const [state, setState] = useState({
        profiles: []
    })

    useEffect(() => {
        return () => {
            dispatch(messageAction.clear())
        }
    }, [])

    useEffect(() => {

        let dbRef = [];
        console.log("firebase app", firebase.apps.length);
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseCofig);
        } else {
            firebase.app();
        }

        state.profiles.forEach((dbUsr, index) => {
            if (dbUsr.myKey) {
                getMsgUsers(dbUsr.myKey, profile?.userType || "").then(list => {
                    dispatch(messageAction.addUserList(list))
                })

                dbRef[index] = firebase
                    .database()
                    .ref(tenantId)
                    .child(dbUsr.myKey)
            }
            console.log("user firebase", tenantId, dbUsr.myKey);
        })

        console.log("updates", dbRef, state.profiles);
        dbRef.forEach(db => {
            db.on("child_added", (val) => {
                let msg = val.val();
                let key = val.key
                dispatch(messageAction.addMsgList(db.key, key, msg))
            });
            db.on("child_changed", (val) => {
                let msg = val.val();
                let key = val.key;
                dispatch(messageAction.addMsgList(db.key, key, msg))
            });
        })

        return () => {
            if (dbRef && dbRef.length > 0) {
                dbRef.forEach(db => {
                    db.off("child_added");
                    db.off("child_changed");
                })
            }
        };
    }, [state.profiles])

    useEffect(() => {

        (async () => {
            if (tenantId) {

                const usr = isFamily ? await getFamilyUserDetail() : null;
                const profiles = isFamily ? usr?.familyMembers.map(child => ({
                    name: `${child.firstName || ""} ${child.lastName}`.trim(),
                    profileUrl: child.profileUrl,
                    childId: child.id,
                    id: usr.id,
                    userType: profile?.userType,
                    myKey: generateMsgKey(profile?.userType, profile?.id, child.id)
                })) : [{
                    name: `${profile.firstName || ""} ${profile.lastName}`.trim(),
                    profileUrl: profile.profilePicUrl,
                    childId: null,
                    id: profile.id,
                    userType: profile?.userType,
                    myKey: generateMsgKey(profile?.userType, profile?.id, null)
                }]
                console.log("profiles firebase", profiles, profile.firstName, isFamily);

                setState(state => ({
                    ...state,
                    profiles
                }))


            }
        })();
    }, [tenantId, profile?.userType])
    useEffect(()=>{
        console.log("profile state",state);
    },[state])

    const getMsgUsers = (key, userRole) => {
        const lastId = key.split('-').reverse()[0]
        return new Promise((resolve, rej) => {
            if (userRole.includes('FAMILY')) {
                getMyTeamDetail(key.split('-').reverse()[0]).then(res => {
                    
                    
                    const i1 = [res.interpreter?.userProfile, ...res.therapists?.map(i => i?.userProfile)].filter(Boolean)
                    const list = i1.map(itx => {
                        const item = {
                            key: generateMsgKey(itx.userType, itx?.id),
                            name: `${itx?.firstName || ""} ${itx?.lastName || ""}`.trim(),
                            image: itx?.profilePicUrl
                        }
                        return item
                    })
                    resolve(list || [])
                }).catch(rej)
            } else if (userRole.includes('THERAPIST')) {
                getFamiliesListByTherapist().then(res => {
                    const list = res.map(itx => {
                        const item = {
                            key: generateMsgKey('ROLE_FAMILY', itx.parent?.userProfile?.id, itx.familyMember?.id),
                            name: `${itx.familyMember?.firstName || ""} ${itx.familyMember?.lastName || ""}`.trim(),
                            image: itx.familyMember?.profileUrl
                        }
                        return item
                    })
                    resolve(list || [])
                }).catch(rej)
            } else if (userRole.includes('INTERPRETER')) {
                getFamilyListByInterpreter().then(res => {
                    const list = res?.content?.map(itx => {
                        const item = {
                            key: generateMsgKey('ROLE_FAMILY', itx.parent?.userProfile?.id, itx.familyMember?.id),
                            name: `${itx.familyMember?.firstName || ""} ${itx.familyMember?.lastName || ""}`.trim(),
                            image: itx.familyMember?.profileUrl
                        }
                        return item
                    })
                    resolve(list || [])
                }).catch(rej)
            }
        })
    }

    return (
        <div />
    )
}

export default AllMessages
