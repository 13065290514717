import React, { useEffect } from "react";
import TextInput from "../../../components/Admin/Inputs/TextInput";
import { emailRegex } from "../../../components/Utils/Regex";
import { useForm } from "react-hook-form";
import InputFileBtn from "../../../components/Admin/Inputs/InputFileBtn";
import { useNavigate, useParams } from "react-router-dom";
import RadioInput from "../../../components/Admin/Inputs/RadioInput";
//import { emailValidation } from "../../../Helpers/Validations";
import { emailValidation } from "../../../components/Utils/Regex";

function UserForm(props) {
  const tenantId = localStorage.getItem("tenantId");
  //console.log("setId", tenantId);
  let params = useParams();
  //console.log(params);
  let history = useNavigate();
  const {
    initialData,
    onSubmit,
    handleChange,
    inputs,
    handleImageChange,
    isOnboard,
    formError,
    setFormError,
    setActiveStep,
    profileImage
  } = props;

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
    setValue,
  } = useForm();

  const Watch = () => {
    const ab = watch("about");
    console.log(ab);
  };

  const watchSubmit = () => {
    const getEmail = watch("email");
    const email = emailValidation(getEmail);
    const phone = watch("phone");

    console.log(email);
    console.log(getEmail);
    if (phone.length < 9 && phone.length > 0) {
      //alert("ok");
      setFormError((item) => ({
        ...item,
        phone: "9-10 digits phone number required.",
      }));
    } else {
      setFormError((item) => ({
        ...item,
        phone: "",
      }));
    }
    if (email === false && getEmail.length > 0) {
      //alert("ok");
      setFormError((item) => ({
        ...item,
        email: "Invalid email format .",
      }));
    } else {
      setFormError((item) => ({
        ...item,
        email: "",
      }));
    }
  };

  useEffect(() => {
    setValue("firstName", inputs?.firstName);
    setValue("lastName", inputs?.lastName);
    setValue("email", inputs?.email);
    setValue("phone", inputs?.phone);
    setValue("gender", inputs?.gender);
    setValue("about", inputs?.about);
    //setValue("tenantId", inputs?.tenantId || params.tenantId || tenantId);
    setValue("profileImage", inputs?.profileImage);
  }, [inputs]);

  //setValue("tenantId", inputs?.tenantId || params.tenantId || tenantId);

  return (
    <>
      <form
        className="addtheripist add-session-form p-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row mt-10">
          <div className="col-12">
            <div className="form-group mb-4">
              <label>Profile Picture</label>{" "}
              {params.id ? (
                <span>
                  <div className="row">
                    <div className="col-md-4">
                      {initialData?.imageUploading && (
                        <span className="loader"></span>
                      )}

                      {profileImage ? (
                        <img
                          src={profileImage?.url}
                          className="settingsimg rec mb-0"
                        />
                      ) : (
                        <img
                          src={
                            inputs?.profileImage
                              ? inputs.profileImage
                              : "/resources/assets/defaultprofile.png"
                          }
                          alt=""
                          className="settingsimg rec mb-0"
                        />
                      )}
                    </div>
                    <InputFileBtn
                      id="fileuplaod"
                      name="profileImage"
                      {...register("profileImage", {
                        onChange: (e) => handleImageChange(e),
                        required: false,
                        //required: inputs.profileImage == "" ? true : false,
                        //pattern: nameRegex,
                      })}
                    //onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                  <br />
                </span>
              ) : (
                <label for="fileuplaod" className="fileuplaod">
                  <div className="content-box">
                    {initialData?.imageUploading && (
                      <span className="loader"></span>
                    )}

                    {profileImage ? (
                      <img src={profileImage?.url} className="" alt="" />
                    ) : (
                      <img
                        src={
                          profileImage
                            ? profileImage?.url
                            : "../resources/assets/file-upload-icon.svg"
                        }
                        alt=""
                      />
                    )}
                    <div className="file-title">
                      Drop your image here or <strong>browse</strong>{" "}
                    </div>
                    <div className="file-name">Supports- JPG,JPEG,PNG,SVG</div>
                  </div>
                  <input
                    type="file"
                    id="fileuplaod"
                    name="profileImage"
                    {...register("profileImage", {
                      onChange: (e) => handleImageChange(e),
                      required: false,
                      //required: inputs.profileImage == "" ? true : false,
                      //pattern: nameRegex,
                    })
                    }
                  //onChange={(e) => handleImageChange(e)}
                  />
                </label>
              )}
              {errors.profileImage &&
                errors.profileImage.type === "required" && (
                  <div className="invalid-feedback-custom">
                    Please upload profile picture
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-6">
            <label>First Name</label> (
            <span className="required-span">*</span>)
            <input
              type="text"
              //label="First Name"
              name="firstName"
              //handleChange={handleChange}
              {...register("firstName", {
                onChange: (e) => handleChange(e),
                required: true,
                maxLength: 50,
                //pattern: nameRegex,
              })}
              defaultValue={inputs?.firstName}
              className={`form-control ${errors.firstName && "is-invalid"
                }`}
              //err={errors?.firstName}
              //isRequire={true}
              placeholder="First Name"
            />
            {errors.firstName && errors.firstName.type === "required" && (
              <div className="invalid-feedback-custom">
                First name is required
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label>Last Name</label> (
            <span className="required-span">*</span>)
            <input
              type="text"
              //label="Last Name"
              name="lastName"
              //handleChange={handleChange}
              {
              ...register("lastName", {
                onChange: (e) => handleChange(e),
                required: true,
                maxLength: 50,
                //pattern: nameRegex,
              })
              }
              className={`form-control ${errors.lastName && "is-invalid"
                }`}
              defaultValue={inputs?.lastName}
              //err={errors?.lastName}
              //isRequire={true}
              placeholder="Last Name"
            />
            {errors.lastName && errors.lastName.type === "required" && (
              <div className="invalid-feedback-custom">
                Last name is required
              </div>
            )}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-6">
            <label>Email</label> (
            <span className="required-span">*</span>)
            <input
              //label="Email"
              type="email"
              name="email"
              // handleChange={handleChange}
              defaultValue={inputs?.email}
              {
              ...register("email", {
                onChange: (e) => handleChange(e),
                required: true,
                maxLength: 100,
                pattern: emailRegex,
              })
              }
              className={`form-control ${errors.email && "is-invalid"}`}
              //err={errors?.email}
              //advError={formError?.email}
              //isRequire={true}
              placeholder="Email"
              disabled={params?.id ? true : false}
            />
            {errors.email?.type === "required" && (
              <div className="invalid-feedback-custom">
                Email address is required
              </div>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <div className="invalid-feedback-custom">
                Please enter valid email address
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label>Phone Number</label>
            <span className="required-span"> *</span>
            <input
              name="phone"
              type="number"
              //onChange={(e) => handleChange(e)}
              {...register('phone', {
                onChange: (e) => handleChange(e),
                required: true,
                pattern: /^[0-9]+$/,
              })
              }
              className="form-control"
              defaultValue={inputs?.phone}
              //err={errors?.duration}
              //isRequire={true}
              placeholder="Phone Number"
              disabled={params?.id ? true : false}
            />
            {errors.phone && (
              <span className="fieldError">
                {errors.phone?.message || 'This field is required.'}
              </span>
            )}
            {/* <TextInput
              type="number"
              label="Phone Number"
              name="phone"
              handleChange={handleChange}
              require={{
                ...register("phone", {
                  required: true,
                  maxLength: 10,
                  minLength: 9,
                  pattern: /^\d{9,10}$/
                }),
              }}
              value={inputs?.phone}
              err={errors?.phone}
              advError={formError.phone}
              isRequire={true}
              disabled={params?.id ? true : false}
              placeholder="Phone Number"
            /> */}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-12 mb-4">
            <label>Gender</label> <span className="required-span">*</span><br/>
            <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id="customRadi1"
                      value="MALE"
                      {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                      //onChange={handleChange}
                      checked={inputs.gender === "MALE" ? "checked" : ""}
                      name="gender"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="customRadi1">
                      Male
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      
                      type="radio"
                      id="customRadio2"
                      value="FEMALE"
                      {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                      //onChange={handleChange}
                      checked={inputs.gender === "FEMALE" ? "checked" : ""}
                      name="gender"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="customRadio2">
                      Female
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      
                      type="radio"
                      id="customRadio3"
                      value="NOT_DISCLOSED"                      
                      //onChange={handleChange} 
                      {...register('gender',{ onChange:(e)=>handleChange(e),required: true })}
                      checked={
                        inputs?.gender === "NOT_DISCLOSED" ? "checked" : ""
                      }
                      name="gender"
                      className="custom-control-input"
                      
                    />
                    <label className="custom-control-label" for="customRadio3">
                      Other
                    </label>
                  </div>
                  {errors.gender && errors.gender.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Gender is required
                    </div>
                  )}
            {/* <div className="marketingAddRadio">
              <input
                label="Male"
                type="radio"
                name="gender"
                //handleChange={handleChange}
                {
                ...register("gender", {
                  handleChange: (e) => handleChange(e),
                  required: true,
                  maxLength: 50,
                  //pattern: nameRegex,
                })
                }
                val="MALE"
                value={inputs?.gender}
                //err={errors?.gender}
                id="customRadi1"
                className="custom-control-input"
              />
              <input
                label="Female"
                name="gender"
                //handleChange={handleChange}
                {...register("gender", {
                  handleChange: (e) => handleChange(e),
                  required: true,
                  maxLength: 50,
                  //pattern: nameRegex,
                })
                }
                val="FEMALE"
                value={inputs?.gender}
                //err={errors?.gender}
                id="customRadio2"
                className="custom-control-input"
              />
              <input
                label="Other"
                className="custom-control-input"
                name="gender"
                //handleChange={handleChange}
                {...register("gender", {
                  handleChange: (e) => handleChange(e),
                  required: true,
                  maxLength: 50,
                  //pattern: nameRegex,
                })
                }
                val="NOT_DISCLOSED"
                value={inputs?.gender}
                //err={errors?.gender}
                id="customRadio3"
              />
            </div> */}
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-md-12 form-group">
            <label>
              About <span className="required-span">*</span>
            </label>
            <textarea
              name="about"
              //onChange={Watch}
              //onKeyUpCapture={handleChange}
              {...register("about", {
                onChange: (e) => handleChange(e),
                required: true,
                //pattern: nameRegex,
              })}
              defaultValue={inputs?.about}
              isRequire={true}
              placeholder="Enter about user"
              className={`form-control h-100 ${errors?.about && "is-invalid"}`}
            //ref={aboutRef}
            ></textarea>
            {errors.about && (
              <span className="fieldError">This field is required.</span>
            )}
          </div>

          <div className="col-md-12 mb-4">
            <span
              className="btn btn-theme-blue nxt-btn mr-3 "
              onClick={() => history("/marketing-user")}
            >
              Back
            </span>

            <button
              className="btn btn-theme-green nxt-btn"
              onClick={() => watchSubmit()}
            >
              {initialData.loading === true ? (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="myLoader light sm d-inline"></span>
                </span>
              ) : (
                <>Submit</>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default UserForm;
