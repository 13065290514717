import React from "react";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { IoIosTrash, IoMdCreate } from "react-icons/io";
import DeleteConfirmationPopup from "../../components/Admin/Popup/DeleteConfirmationPopup";
import TableItem from "./TableItem";

function OtherTable(props) {
  const history = useNavigate();
  const { confirmDeletion, setConfirmDeletion } = props;
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            {props.headRow.map((data) => {
              return <th scope="col">{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {props.list.map((data, index) => {
            return (
              <>
                <TableItem
                  data={data}
                  key={index}
                  confirmDeletion={confirmDeletion}
                  setConfirmDeletion={setConfirmDeletion}
                />
              </>
            );
          })}
        </tbody>
      </table>
      {confirmDeletion && (
        <DeleteConfirmationPopup
          confirmDeletion={confirmDeletion}
          setConfirmDeletion={setConfirmDeletion}
          onDelete={props?.onDelete}
          name={confirmDeletion?.name}
        />
      )}
    </div>
  );
}

export default OtherTable;
