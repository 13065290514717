import React, { Component, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../redux/actions'
import { adminActions } from '../actions'
import { adminService } from "../services/adminService";
//import { useAlert,positions } from 'react-alert';
import Validations from "../utils/Validations.js";

function CreatePassword() {
    let params = useParams();
    //const alert = useAlert();
    const history = useNavigate();
    //const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        newpassword: '',
        confirmnewpassword: '',
        validNewPass: '',
        validNewConfirmPass: '',
        validBothPass: ''
    });

    const onSubmit = (event) => {
        event.preventDefault();    
        // Form Validation starts
        if(inputs.newpassword.trim() === ''){
            setInputs(validNewPass => ({ ...inputs, validNewPass: 'This field is required.' }));
        } else {
            setInputs(validNewPass => ({ ...inputs, validNewPass: '' }));
        }
        
        if(inputs.confirmnewpassword.trim() === ''){
            setInputs(validNewConfirmPass => ({ ...inputs, validNewConfirmPass: 'This field is required.' }));
        } else {
            setInputs(validNewConfirmPass => ({ ...inputs, validNewConfirmPass: '' }));
        }
        
        if(inputs.newpassword.trim() !== inputs.confirmnewpassword.trim()){
            setInputs(validBothPass => ({ ...inputs, validBothPass: 'This password do not match.' }));
        } else {
            setInputs(validBothPass => ({ ...inputs, validBothPass: '' }));
        }
        
        // API Call to be made
        // console.log(params.token + '--' + params.session);

    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    return (
        <>
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-md-4 no-padding">
                        <form onSubmit={e => { onSubmit(e); }} autoComplete="off" className="custom-form form-custome-elements" >
                            <h1 className="h3 mb-3 font-weight-normal text-left">Create New Password</h1>
                            <div className="form-group">
                                <input name="newpassword" onChange={handleChange} value={inputs.newpassword} type="password" id="newpassword" className={'form-control'} placeholder="Enter New Password" autoFocus />
                                { inputs.validNewPass ? 
                                    <small className="form-text text-danger">{inputs.validNewPass}</small>
                                : '' }
                            </div>
                            <div className="form-group">
                                <input name="confirmnewpassword" onChange={handleChange} value={inputs.confirmnewpassword} type="password" id="confirmnewpassword" className="form-control" placeholder="Confirm New Password" />
                                { inputs.validNewConfirmPass ? 
                                    <small className="form-text text-danger">{inputs.validNewConfirmPass}</small>
                                : '' }
                                { inputs.validBothPass ? 
                                    <small className="form-text text-danger">{inputs.validBothPass}</small>
                                : '' }
                            </div>
                            <div className="row justify-content-between align-items-center">
                                <div className="col-auto">
                                    <button className="btn custom-btn-green" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreatePassword;