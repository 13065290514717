import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { charSpaRegex, spaceRegex, alphanumericRegx } from "../../../components/Utils/Regex";
import TextInput from "../../../components/Admin/Inputs/TextInput";
import { adminService } from "../../../services/adminService";
import { toast } from "react-toastify";
import usePageState from "../../../hooks/InitialState";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
//import { nameRegex } from "../../../utils/regex";

function AddTherapyType(props) {
    const { setPageState, pageState } = usePageState();
    let history = useNavigate();
    const params = useParams();
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        setError,
        clearErrors,
        setValue,
    } = useForm();
    const [inputs, setInputs] = useState({ name: '', code: '', description: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
        //setValue(name, value);
        console.log(watch(name), value, errors);
        if (name == "description" && value.length > 100) {
            //alert("error");
            setError(name, {
                type: "maxLength",
                message: "Max 100 characters.",
            });
        }

        if (name == "description" && value.length < 101 && value.length > 1) {
            //alert;
            clearErrors("description");
        }
    };

    const onSubmit = () => {
        setPageState((item) => ({ ...item, loading: true }));
        inputs.code = inputs.code.trim();
        inputs.name = inputs.name.trim();
        inputs.description = inputs.description.trim();
        const fn = adminService.POST(`therapies`, JSON.stringify(inputs));

        fn.then((res) => {
            setPageState((item) => ({ ...item, loading: false }));
            if (res.status == "Success") {
                res?.data && setInputs(res?.data);
                toast.success(res?.data);
                history("/therapy-types");
            } else {
                toast.error(res?.message || "Something went wrong");
            }
        }).catch((err) => {
            toast.error(err);
            setPageState((item) => ({ ...item, loading: false }));
        });
    };
    return (
        <div className="col-md-10 no-padding ContentArea theropySession">
            <div className="no-padding dash-billing-info mr-0 flex-grow-1">
                <div className="custom-table-view min-h-100">
                    <div class="content-header">
                        <div class="text-left header-title pt-7px">{params?.id ? "Edit" : "Add"} Therapy Type</div>
                        <div class="text-right header-btn">
                        </div>
                    </div>
                    <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6 form-group mb-3">
                                <label>
                                    Therapy name <span className="required-span">*</span>
                                </label>
                                <input
                                    label="Therapy name"
                                    name="name"
                                    {...register('name',{
                                        onChange:(e) => handleChange(e),
                                        required: true,
                                        maxLength: 30,
                                        pattern: charSpaRegex
                                    })}
                                    //onChange={(e) => handleChange(e)}
                                    defaultValue={inputs?.name}
                                    placeholder="Therapy name"
                                    className={`form-control ${errors?.name && "is-invalid"
                                        }`}
                                />
                                {errors.name && (
                                    <span className="fieldError">
                                        {errors.name?.message ||
                                            "This field is required."}
                                    </span>
                                )}
                                {/* <TextInput
                                label="Therapy name"
                                name="name"
                                handleChange={handleChange}
                                {...register({ required: true })}
                                value={inputs?.name}
                                err={errors?.name}
                                isRequire={true}
                                placeholder="Therapy Name"
                                disabled={params?.id ? true : false}
                            /> */}
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <label>
                                    Therapy Code <span className="required-span">*</span>
                                </label>
                                <input
                                    label="Therapy Code"
                                    name="code"
                                    {...register('code',{ onChange:(e) => handleChange(e),required: true, maxLength: 50, pattern: alphanumericRegx })}
                                    //onChange={(e) => handleChange(e)}
                                    defaultValue={inputs?.code}
                                    placeholder="Therapy Code"
                                    className={`form-control ${errors?.code && "is-invalid"
                                        }`}
                                />
                                {errors.code && (
                                    <span className="fieldError">
                                        {errors.code?.message ||
                                            "This field is required."}
                                    </span>
                                )}
                                {/* <TextInput
                                label="Therapy Code"
                                name="code"
                                handleChange={handleChange}
                                {...register({ required: true, maxLength: 50, })}
                                value={inputs?.code}
                                err={errors?.code}
                                isRequire={true}
                                placeholder="Therapy Code"
                                disabled={params?.id ? true : false}
                            /> */}
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-md-12 form-group mb-3">
                                <label>
                                    Description <span className="required-span">*</span>
                                    {/*  <span className="required-span">*</span> */}
                                </label>
                                <textarea
                                    {...register('description',{ onChange:(e) => handleChange(e),required: true, maxLength: 100, pattern: alphanumericRegx })}
                                    // {...register("description", {
                                    //     required: true,
                                    //     maxLength: 100,
                                    // })}
                                    name="description"
                                    className={`form-control h-100 ${errors?.description && "is-invalid"
                                        }`}
                                    //onChange={(e) => handleChange(e)}
                                    defaultValue={inputs?.description}
                                    placeholder="Enter description"
                                />{" "}
                                {errors.description && (
                                    <span className="fieldError">
                                        {errors.description?.message || "This field is required."}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row mt-10">
                            <div className="col-md-12">
                                <button
                                    type="submit"
                                    className="btn btn-theme-green"
                                    style={{ minWidth: 100 }}
                                >
                                    {pageState?.loading ? (
                                        <div className="d-flex justify-content-center">
                                            <span className="myLoader light sm d-inline"></span>
                                        </div>
                                    ) : (
                                        <span>Submit</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddTherapyType;