import React from "react";
import TimeInput from "../../components/TimeInput";

function SelectTime(props) {
  const { dateInputs, handleChangeTime, handleChangeMeridium } = props;
  return (
    <>
      <div className="col-md-12">
        <div className="form-group">
          <label>Working Time</label> (<span className="required-span">*</span>)
          <div className="row">
            <div className="col-md-6">
              <h6>Start time</h6>
              <div className="working-time d-flex">
                <TimeInput
                  onChange={(e) => handleChangeTime(e, "startTime")}
                  initialValue={dateInputs.startTime}
                  value={dateInputs.startTime}
                />

                <div
                  className="btn-group btn-group-toggle set-time-section"
                  data-toggle="buttons"
                >
                  <label
                    className={`btn ${
                      dateInputs.startMeridium === "am" && "active"
                    }`}
                    onClick={() => handleChangeMeridium("startMeridium", "am")}
                  >
                    <input
                      type="radio"
                      value="am"
                      name="startMeridium"
                      autocomplete="off"
                    />{" "}
                    AM
                  </label>

                  <label
                    className={`btn ${
                      dateInputs.startMeridium === "pm" && "active"
                    }`}
                    onClick={() => handleChangeMeridium("startMeridium", "pm")}
                  >
                    <input
                      type="radio"
                      value="pm"
                      name="startMeridium"
                      autocomplete="off"
                    />{" "}
                    PM
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h6>End time</h6>
              <div className="working-time d-flex">
                <div className="input-group">
                  <TimeInput
                    onChange={(e) => handleChangeTime(e, "endTime")}
                    initialValue={dateInputs.endTime}
                    value={dateInputs.endTime}
                  />
                </div>
                <div
                  className="btn-group btn-group-toggle set-time-section"
                  data-toggle="buttons"
                >
                  <label
                    className={`btn ${
                      dateInputs.endMeridium === "am" && "active"
                    }`}
                    onClick={() => handleChangeMeridium("endMeridium", "am")}
                  >
                    <input
                      type="radio"
                      value="am"
                      name="endMeridium"
                      autocomplete="off"
                    />{" "}
                    AM
                  </label>

                  <label
                    className={`btn ${
                      dateInputs.endMeridium === "pm" && "active"
                    }`}
                    onClick={() => handleChangeMeridium("endMeridium", "pm")}
                  >
                    <input
                      type="radio"
                      value="pm"
                      name="endMeridium"
                      autocomplete="off"
                    />{" "}
                    PM
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectTime;
