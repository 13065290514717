import React from "react";
import classNames from "classnames";
import { _toastHandleErr, _toasthandleRes } from "../../utils/commonFunctions";
import { AttachmentForm } from "../attachment";
import DisplayDate from "../DisplayDate";
import QueryTranslteForm from "./QueryTranslteForm";

const QueryItem = (props) => {
  const {
    date,
    detail,
    title,
    translatedDetail,
    profilePicKey,
    isTranslation,
    name,
    attachmentsList,
    translateForm,
    familyLanguages,
    languageDto,
    id,
    isReply,
    onSuccess,
    interpreterName
  } = props;
  const formProps = {
    familyLanguages,
    languageDto,
    id,
    isReply,
    onSuccess
  }
  return (
    <div
      className={classNames("c-query__item", {
        "c-query__item--translation": isTranslation,
      })}
    >
      <div className="c-query__info">
        <div className="c-query__info-user">
          <img
            src={profilePicKey || "../../resources/assets/defaultprofile.png"}
            alt="user"
            className="c-query__info-user-img"
          />
          <span className="c-query__info-user-name">{name}</span>
        </div>
        <div className="c-query__info-date">
          <DisplayDate date={date} />
        </div>
      </div>
      <div className="c-query__content">
        {title && <p className="c-query__content-heading">{title}</p>}
        <p className="c-query__content-text">{detail}</p>
        {attachmentsList && attachmentsList.length > 0 && (
          <AttachmentForm 
            label="Attach audio/video/image/document file"
            accept="image/*, audio/*, video/*, .pdf, .doc, .docx"
            enableUpload={false} attachments={attachmentsList} />
            
        )}
        {translatedDetail ? (
          <div className="c-query__translation">
            <QueryItem
              {...props}
              attachmentsList={[]}
              title={null}
              name={interpreterName}
              detail={translatedDetail}
              translatedDetail={null}
              isTranslation={true}
              translateForm={false}
              profilePicKey={null}
            />
          </div>
        ) : (
          translateForm && <QueryTranslteForm {...formProps} />
        )}
      </div>
    </div>
  );
};

export default QueryItem;
