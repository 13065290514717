import React, { Component, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { adminService } from '../../../services/adminService';
import allActions from '../../../redux/actions';
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import appAction from '../../../redux/actions/appAction';
import { sortLanguage } from '../../../utils/commonFunctions';
import validationRules from '../../../utils/validationRules';
import CurrencyInput from '../../../components/CurrencyInput';

function InterpreterLanguageInformation(props) {
    const saveNextRef = useRef(null);
    const history = useNavigate();
    let params = useParams();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const dispatch = useDispatch();
    let interpreterForm = useSelector(state => state.adminUser.interpreterForm);
    let jsonFound = interpreterForm.hasOwnProperty("userProfileForm");
    const [language, setLanguage] = useState([]);
    const [languageIds, setLanguageIds] = useState(interpreterForm?.userProfileForm?.interpreterLanguages || []);
    const [inputs, setInputs] = useState([]);
    const [isSaveBack, setIsSaveBack] = useState(false);
    const [removeLang, setRemoveLang] = useState([]);
    console.log("interpreterForm language", interpreterForm);
    useEffect(() => {
        if (params.interpreterId) {
            //setloading(true);
            adminService.getById(`interpreters/` + params.interpreterId).then(
                (res) => {
                    if (res.status === "Success") {
                        //setLanguageIds(res.data.interpreterLanguages);
                        console.log("interpreter lang", res.data)
                        dispatch(
                            allActions.adminAction.setInterpreterFormData(
                                {
                                    ...res.data.userProfile,
                                    interpreterLanguages: res.data.interpreterLanguages,
                                },
                                "userProfileForm"
                            )
                        );
                        //dispatch(allActions.adminAction.setInterpreterFormData(res.data.interpreterLanguages, 'interpreterLanguageForms'))
                        let phone = res.data.userProfile && res.data.userProfile.phone;
                        //res.data.userProfile.phone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;

                        //setloading(false);
                    } else {
                        toast.error(res.message);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        console.log("interpreterForm language 57", interpreterForm);
    }, []);
    const onSubmitSaveAndBack = () => {
        setIsSaveBack(true);
        setTimeout(() => {
            setIsSaveBack(true);
            saveNextRef.current.click();
        }, 1000);
    };
    const onSubmit = () => {
        if (isSaveBack) {
            createTherapist();
        } else {
            console.log("languageIds next", languageIds.length)
            if (params.interpreterId && languageIds.length > 0) {
                dispatch(appAction.setAppLoading(true));
                let formData = new FormData();
                formData.append(
                    "profileImage",
                    jsonFound && interpreterForm.userProfileForm.profileImage
                );
                const basicInfo = {
                    firstName: jsonFound ? interpreterForm.userProfileForm.firstName : "",
                    lastName: jsonFound ? interpreterForm.userProfileForm.lastName : "",
                    email: jsonFound ? interpreterForm.userProfileForm.email : "",
                    phone: jsonFound ? interpreterForm.userProfileForm.phone : "",
                    about: jsonFound ? interpreterForm.userProfileForm.about : "",
                    gender: jsonFound ? interpreterForm.userProfileForm.gender : "",
                    active: jsonFound ? interpreterForm.userProfileForm.active : false,
                };
                const therapistServiceForms = inputs;
                let formDataPut = {
                    userProfileUpdateForm: basicInfo,
                    newInterpreterLanguageForms: therapistServiceForms,
                    deletedInterpreterLanguageIds: removeLang,
                    pendingOnboarding: true,
                    timezoneId: null,
                };
                formData.append(
                    "interpreterUpdateForm",
                    new Blob([JSON.stringify(formDataPut)], {
                        type: "application/json",
                    })
                );
                adminService
                    .update(`/interpreters/${props?.therapistData.id}`, formData)
                    .then(
                        (res) => {
                            dispatch(appAction.setAppLoading(false));
                            console.log("object", res);
                            if (res?.data?.id) {
                                    // props?.handleNext();
                                    dispatch(
                                        allActions.adminAction.setInterpreterFormData(
                                            inputs,
                                            "userProfileForm"
                                        )
                                    );
                                    dispatch(allActions.adminAction.setInterpreterFormData(res.data.interpreterLanguages, 'interpreterLanguageForms'));
                                    props.handleNext();
                                
                            } else {
                                toast.error(res?.message);
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
                    .finally((error) => {
                        dispatch(appAction.setAppLoading(false));
                    });
            } else {
                if (languageIds.length > 0) {
                    dispatch(allActions.adminAction.setInterpreterFormData(languageIds, 'interpreterLanguageForms'));
                    props.handleNext();
                } else {
                    console.log("Please add Interpreting Details");
                    toast.warning("Please add Interpreting Details");
                }
            }
        }

    }
    const createTherapist = (isnext = "") => {
        setIsSaveBack(false);
        dispatch(appAction.setAppLoading(true));
        let formData = new FormData();
        formData.append(
            "profileImage",
            jsonFound && interpreterForm.userProfileForm.profileImage
        );
        const basicInfo = {
            firstName: jsonFound ? interpreterForm.userProfileForm.firstName : "",
            lastName: jsonFound ? interpreterForm.userProfileForm.lastName : "",
            email: jsonFound ? interpreterForm.userProfileForm.email : "",
            phone: jsonFound ? interpreterForm.userProfileForm.phone : "",
            about: jsonFound ? interpreterForm.userProfileForm.about : "",
            gender: jsonFound ? interpreterForm.userProfileForm.gender : "",
            active: jsonFound ? interpreterForm.userProfileForm.active : false,
        };
        const otherInfo = {
            pendingOnboarding: inputs.pendingOnboarding,
            onboardPermission: inputs.onboardPermission,
            updatePermission: inputs.updatePermission,
        };

        const therapistServiceForms = inputs;
        if (props?.therapistData) {
            console.log("remove lang", removeLang);
            let formDataPut = {
                userProfileUpdateForm: basicInfo,
                newInterpreterLanguageForms: therapistServiceForms,
                deletedInterpreterLanguageIds: removeLang,
                pendingOnboarding: true,
                timezoneId: null,
            };
            formData.append(
                "interpreterUpdateForm",
                new Blob([JSON.stringify(formDataPut)], {
                    type: "application/json",
                })
            );
        } else {
            let formDataPut = {
                userProfileForm: basicInfo,
                interpreterLanguageForms: therapistServiceForms,
                pendingOnboarding: true,
                timezoneId: null,
            };
            formData.append(
                "interpreterForm",
                new Blob([JSON.stringify(formDataPut)], {
                    type: "application/json",
                })
            );
        }

        if (props?.therapistData) {
            updateOnboardTherapist(props?.therapistData.id, formData, isnext);
        } else {
            onboardTherapist(formData, isnext);
        }
    };
    const onboardTherapist = (formData, isnext) => {
        adminService
            .onboardUser(`interpreters`, formData)
            .then(
                (res) => {
                    dispatch(appAction.setAppLoading(false));

                    //alert("go");
                    if (res?.status === "Success") {
                        console.log("inpterpreter", inputs);
                        dispatch(
                            allActions.adminAction.setInterpreterFormData(
                                inputs,
                                "userProfileForm"
                            )
                        );
                        if (isnext) {
                            props.handleNext();
                        } else {
                            // dispatch(allActions.adminAction.clearOnboardData());
                            toast.success(res.message);
                            setTimeout(() => {
                                history("/pending-users-onboarding?tab=interpreter");
                            }, 2000);
                        }
                    } else {
                        //alert("ok");
                        toast.error(res?.message || "Somthing went wrong.");
                    }
                },
                (error) => {
                    console.log("Error >>>>>>>", error);
                }
            )
            .finally(() => {
                dispatch(appAction.setAppLoading(false));
            });
    };
    const updateOnboardTherapist = (id, formData, isnext) => {
        adminService
            .update(`/interpreters/${id}`, formData)
            .then(
                (res) => {
                    dispatch(appAction.setAppLoading(false));
                    console.log("object", res);
                    if (res?.data?.id) {
                        if (isnext) {
                            // props?.handleNext();
                            dispatch(
                                allActions.adminAction.setInterpreterFormData(
                                    inputs,
                                    "userProfileForm"
                                )
                            );
                            props.handleNext();
                        } else {
                            history("/pending-users-onboarding?tab=interpreter");
                        }
                    } else {
                        toast.error(res?.message);
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
            .finally((error) => {
                dispatch(appAction.setAppLoading(false));
            });
    };
    const addLanguage = () => {
        if (inputs.languageId && inputs.hourlyRate) {
            if (inputs.hourlyRate > 0 && inputs.hourlyRate <= 999) {
                let fileindex = languageIds.findIndex(obj => obj.languageId == inputs.languageId);
                if (fileindex == -1) {
                    setLanguageIds([...languageIds, inputs]);
                    //setTherapistServices([...therapistServices, inputs]);
                    //dispatch(allActions.adminAction.setInterpreterFormData(inputs,'interpreterLanguageForms'));
                }
                else {
                    toast.warning("Please add another language")
                }
            }
            else {
                toast.warning("Hourly rate must be $1-$999")
            }
        }
        else {
            toast.warning("Please fill required information")
        }
        setInputs({
            languageId: 0,
            hourlyRate: 0.0
        })
    }
    let langRemoveItem = new Set();
    const removeLanguage = (id) => {
        //const mySet1 = new Set()
        let languageIdvalue = languageIds.filter((item) => item.languageId === id);
        //let languageIdvalue = (languageId) => { return removeLang.find((data)=>data.languageId === languageId)}
        //langRemoveItem.add(languageIdvalue[0].id);
        setRemoveLang([...removeLang, languageIdvalue[0].id]);
        console.log("remove function", languageIds, languageIdvalue, removeLang);
        setLanguageIds(languageIds.filter((item) => item.languageId !== id));

    }

    function getLanguageName(id) {
        //console.log("langulage id", id,typeof language,language);
        return language.filter((lang) => lang.id === id)[0]?.languageName;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if (name == 'hourlyRate') {
            setInputs(inputs => ({ ...inputs, [name]: value ? value : '' }));
        } else
            setInputs(inputs => ({ ...inputs, [name]: parseInt(value) }));
    }

    useEffect(() => {
        setInputs(inputs);
    }, [inputs])


    useEffect(() => {
        dispatch(appAction.setAppLoading(true));
        adminService.getAll(`languages?size=1000`).then(
            res => {
                setLanguage(sortLanguage(res.data.content || []));
                if (interpreterForm.hasOwnProperty('interpreterLanguageForms'))
                    setLanguageIds(interpreterForm.interpreterLanguageForms)
            },
            error => {
                console.log(error)
            }
        ).finally(() => {
            dispatch(appAction.setAppLoading(false));
        });
    }, [])

    console.log("laguages id", languageIds, interpreterForm);
    return (
        <>
            <form onSubmit={e => {
                e.preventDefault();
                onSubmit();
            }}>
                <div className="addtheripist mw-100">
                    <label className="conetent-title">Interpreting Details</label>
                    <div className="row ">
                        <IntLanguageSelectForm
                            onSubmit={({ languageId, hourlyRate }) => {
                                if (languageIds.some(item => item.languageId === +languageId)) {
                                    toast.warn('Language is already added');
                                    return;
                                }
                                setLanguageIds(ids => [...ids, {
                                    languageId: +languageId, hourlyRate
                                }])
                                setInputs([...inputs, {
                                    languageId: +languageId,
                                    hourlyRate: hourlyRate
                                }])
                            }}
                            languageList={language}
                        />
                        {/* <div className="col-md-3">
                            <div className="form-group">
                                <label>Language Name</label>
                                <div className="input-group ">
                                    <select className="custom-select" onChange={handleChange} value={inputs.languageId} name="languageId">
                                        <option selected>Select Language</option>
                                        {language && language.map((item, index) =>
                                            <option value={item.id}>{item.languageName}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 form-group">
                            <label>Hourly Rate</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <input type="number" onChange={handleChange} value={inputs.hourlyRate} name="hourlyRate" className="form-control" placeholder="0.0" required />
                            </div>
                        </div>


                        <div className="col-md-3">
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <div>
                                    <button type="button" onClick={() => addLanguage()} className="btn btn-primary add-button cursor-pointer" data-toggle="button" aria-pressed="false" autocomplete="off">
                                        
                                        <img className="pr-2" src="../resources/assets/plus-icon-button.svg" alt="" />
                                     Add Language
                                    </button>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="conetent-title">Added Languages</label>
                                <table className="table custom-table">
                                    <tbody>
                                        {languageIds && languageIds.map((lang, index) =>
                                            <tr className="bg-common-gray">
                                                <td className="border-top-0">{index + 1}</td>
                                                <td className="border-top-0 lang">{getLanguageName(lang?.languageId)}</td>
                                                <td className="border-top-0"><strong>${parseFloat(lang.hourlyRate).toFixed(2)}/Hr</strong></td>
                                                {/* <td className="border-top-0 cursor-pointer text-right" onClick={()=>removeLanguage(lang.languageId)}> */}
                                                <td className="border-top-0 text-right" onClick={() => removeLanguage(lang.languageId)}>
                                                    <button type="button" onClick={() => removeLanguage(lang.languageId)} className="btn-rounded-black cursor-pointer">
                                                        <img src="../resources/assets/ionic-md-close.svg" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                        {languageIds.length == 0 && (
                                            <tr className="text-center"><td colSpan="2"><strong>No data found</strong></td></tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>




                    </div>


                </div>

                {/* <div className="row">
                    <div className="col-md-12"><hr /></div>
                    <div className="col-md-12 d-flex">
                        <button className="btn  btn-primary d-block stepper-button-back" onClick={e => props.handleBack()}>
                            Back
                        </button>
                        <button className="btn  btn-primary d-block stepper-button-next" type="submit">
                            Next
                        </button>
                    </div>
                </div> */}
                <div className="row addtheripist">
                    <div className="col-md-12">
                        <hr />
                    </div>
                    <div className="col-md-12 d-flex">
                        <button
                            type="button"
                            className="btn  btn-primary d-block stepper-button-back"
                            onClick={(e) => props.handleBack()}
                        >
                            Back
                        </button>
                        {/* {!params.id && ( */}
                        <button
                            className="btn  btn-primary d-block stepper-button-next"
                            id="save-and-back"
                            type="button"
                            onClick={(e) => onSubmitSaveAndBack(false)}
                        >
                            Save & Return
                        </button>
                        {/* )} */}
                        <button
                            className="btn  btn-primary d-block stepper-button-next"
                            type="submit"
                            ref={saveNextRef}
                        >
                            Save & Continue
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default InterpreterLanguageInformation;



const IntLanguageSelectForm = ({
    languageList, onSubmit
}) => {
    const { register, formState: { errors }, reset, handleSubmit, getValues, setValue, trigger, formState: { isDirty } } = useForm();

    const onLangAdd = () => {
        //console.log("langAdd",isDirty)
        if (typeof onSubmit === 'function') {
            onSubmit(getValues())
        }
        reset()
    }
    useEffect(() => {
        register && register("hourlyRate", validationRules.interpreterHourlyRate)
    }, [register])

    return (
        <>
            <div className="col-xl-3 col-md-4">
                <div className="form-group">
                    <label>Language Name (<span class="required-span">*</span>)</label>
                    <div className="input-group ">
                        <select
                            {...register('languageId',validationRules.interpreterLanguage)}
                            className="custom-select"
                            name="languageId">
                            <option value="" selected>Select Language</option>
                            {languageList && languageList.map((item, index) =>
                                <option value={item.id}>{item.languageName}</option>
                            )}
                        </select>
                    </div>
                    {errors.languageId && <span className="error-text" >{errors.languageId.message}</span>}
                </div>
            </div>
            <div className="col-xl-2 col-md-3">
                <div className="form-group">
                    <label>Hourly Rate (<span class="required-span">*</span>)</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <CurrencyInput
                            onChange={e => {
                                // handleChange(e)
                                setValue("hourlyRate", e.target.value)
                                if (isDirty) {
                                    trigger("hourlyRate")
                                }
                            }}
                            name="hourlyRate"
                            className="form-control"
                            placeholder="0.0"
                        />
                        {/* <input 
                                ref={register(validationRules.interpreterHourlyRate)}
                                type="number" 
                                onWheel={event => { 
                                    event.preventDefault(); 
                                    if(typeof event.target.blur === 'function' ){
                                        event.target.blur()
                                    }
                                }}
                                name="hourlyRate" 
                                className="form-control" 
                                placeholder="0.0" /> */}
                    </div>
                    {errors.hourlyRate && <span className="error-text" >{errors.hourlyRate.message}</span>}
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                    <label>&nbsp;</label>
                    <div>
                        <button type="button" onClick={handleSubmit(onLangAdd)} className="btn btn-primary add-button cursor-pointer" data-toggle="button" aria-pressed="false" autocomplete="off">
                            {/* <img src='../../resources/assets/Plus-Button-Icon-Round.svg' /> */}
                            <img className="pr-2" src="../resources/assets/plus-icon-button.svg" alt="" />
                            Add Language
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12" />
        </>
    )
}