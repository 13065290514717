import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgora from "./hooks/useAgora";
import MediaPlayer from "./components/MediaPlayer";
import "./Call.css";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { useFormContext } from "../../components/forms/FormContext";
import DemoAppSocket from "../../components/socket/DemoAppSocket";

import { useDispatch, useSelector } from "react-redux";

const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });
const screenClient = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });

function CallNew() {

   const agoraRdx = useSelector((state) => state.agoraDetailNew.configData);
  //console.warn("call appId", props?.config?.configData?.appId);
  //console.warn("call channel", props?.config?.configData?.channel);
  //console.warn("call token", props?.config?.configData?.token);
  //console.warn("call uid", props?.config?.configData?.uid);
  console.warn("Agora Data List", agoraRdx);
  //console.warn("Agora Data", stateCtx.config.token);

  const history = useNavigate();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  //let AppId = agoraRdx?.appId;//urlParams.get("id");
  //let channelId = agoraRdx?.channel;//urlParams.get("channel");
  //let tokenId = agoraRdx?.token;//urlParams.get("token");
  
  // let appid = agoraRdx?.appId;//urlParams.get("id"); //
  // let channel =  agoraRdx?.channel;//urlParams.get("channel"); "demo"
  // let token = agoraRdx?.token;//urlParams.get("token"); 
  let appid = "97e4b2456dab4f3698e0333f1b797742"; 
  let channel =  "demo";
  let token = "00697e4b2456dab4f3698e0333f1b797742IAAUfkV2q/W98X2W5DPTfDodf7Ym+JqpsYHkOlCQTaEBDKDfQtYAAAAAEAAqKWnBhPMiYgEAAQCG8yJi";

  let uid = agoraRdx?.id;//urlParams.get("token");

  const { agoraInfo, setAgoraInfo } = useFormContext();
  console.log("ok ss", appid,  channel, token, uid );

  //let [appid, setAppid] = useState(agoraRdx?.appId);
  //let [channel, setChannel] = useState(agoraRdx?.channel);
  //let [token, setToken] = useState(agoraRdx?.token);
 console.log("Logs ", appid,token,channel);

  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    joinState,
    remoteUsers,
    handleAudio,
    handleVideo,
    handleScreen,
    leaveScreen,
  } = useAgora(client, screenClient);

  const [newAudioUser, setNewAudioUser] = useState([]);
  const [newVideoUser, setNewVideoUser] = useState([]);

  const [isScreen, setIsScreen] = useState(false);
  // const [tokenDetail, setTokenDetail] = useState({});

  // useEffect(() => {
  //   adminService.POST(`agora-tokens/${sessionId}`).then(
  //     (res) => {
  //       if (res?.status === "Success") {
  //         setTokenDetail(res.data);
  //         setAppid(res.data.appId);
  //         setToken(res.data.token);
  //         setChannel(res.data.channelName);
  //       } else {
  //         toast.error(res?.message || res?.error || res);
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }, [sessionId]);

  const handleUser = (id, callFor = "audio") => {
    if (callFor === "audio") {
      if (newAudioUser.includes(id)) {
        const arr = newAudioUser.reduce((acc, curr) => {
          if (curr !== id) acc.push(curr);
          return acc;
        }, []);
        setNewAudioUser(arr);
        handleAudio("");
      } else {
        setNewAudioUser([...newAudioUser, id]);
        handleAudio(undefined);
      }
    } else if (callFor === "video") {
      if (newVideoUser.includes(id)) {
        const arr = newVideoUser.reduce((acc, curr) => {
          if (curr !== id) acc.push(curr);
          return acc;
        }, []);
        setNewVideoUser(arr);
        handleVideo("");
      } else {
        setNewVideoUser([...newVideoUser, id]);
        handleVideo(undefined);
      }
    } else if (callFor === "screen") {
      if (isScreen) {
        setIsScreen(false);
        leaveScreen();
      } else {
        setIsScreen(true);
        handleScreen(appid, channel, token, id * 2);
      }
    }
  };

  return (
    <div className="call">
      <DemoAppSocket agoraInfo={agoraInfo} setAgoraInfo={setAgoraInfo} />

      {appid && token && channel && (
          <div className="button-group">
            <button
              id="join"
              type="button"
              className="btn btn-primary btn-sm"
              disabled={joinState}
              onClick={() => {
                join(appid, channel, token, uid);
              }}
            >
              Join
            </button>
            <button
              id="leave"
              type="button"
              className="btn btn-primary btn-sm"
              disabled={!joinState}
              onClick={() => {
                leave();
              }}
            >
              Leave
            </button>
          </div>
        )}

      <div className="player-container">
        <div className="inline-block local-player-wrapper">
          <p className="local-player-text">
            {localVideoTrack && `localTrack`}
            {joinState && localVideoTrack ? `(${client.uid})` : ""}
          </p>
          <MediaPlayer
            videoTrack={
              newVideoUser.includes(client.uid) ? undefined : localVideoTrack
            }
            audioTrack={
              newAudioUser.includes(client.uid) ? undefined : localAudioTrack
            }
            clientUid={client.uid}
            handleUser={handleUser}
            userType="local"
            isScreen={isScreen}
          ></MediaPlayer>
        </div>
        {remoteUsers.map((user) => {
          // console.log(`remoteVideo(${user.uid})`, user)
          return (
            <div className="inline-block remote-player-wrapper" key={user.uid}>
              <p className="remote-player-text">{`remoteVideo(${user.uid})`}</p>
              {console.log("object user.audioTrack--", user.audioTrack)}
              <MediaPlayer
                videoTrack={
                  newVideoUser.includes(user.uid) ? undefined : user.videoTrack
                }
                audioTrack={
                  newVideoUser.includes(user.uid) ? undefined : user.audioTrack
                }
                clientUid={user.uid}
                handleUser={handleUser}
                userType="remote"
                isScreen={false}
              ></MediaPlayer>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CallNew;
