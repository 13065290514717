import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
} from "agora-rtc-sdk-ng";
import React, { useRef, useEffect, useState } from "react";
import { adminService } from "../../services/adminService";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { ReactSVG } from "react-svg";

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  clientUid: any;
  handleUser: Function;
  userType: any;
  isScreen: any;
  isOwnScreen: any;
  remoteUsersCount: any;
  isSmall: any;
  black: any;
  placeholder: any;
  setIsScreen: any;
  onPin: Function;
  isPin: any;
  sessionId:any;
}

const MarketingMediaPlayer = (props: VideoPlayerProps) => {
  console.log("screen share uid",props,props.clientUid,props?.isPin,props?.isScreen,props?.isOwnScreen);
  const valuex = localStorage.getItem("user");

  const isUserLoggedIn =
    typeof valuex === "string"
      ? JSON.parse(valuex).accessToken
      : valuex?.accessToken || null;

  const container = useRef<HTMLDivElement>(null);

  const appAgoraState = useSelector((state) => state.agoraDetail);
  const { therapist, interpreter, familyMember, familyMemberDtoSet } =
    appAgoraState?.currentSession;
  console.log("appAgoraState?.volumeIndicators", familyMemberDtoSet);
  const isVolumeIndicatorOn = appAgoraState?.volumeIndicators
    ? appAgoraState?.volumeIndicators.filter((vi) => vi.uid == props?.clientUid)
    : [{ uid: "" }];
  useEffect(() => {
    if (!container.current) return;
    props.videoTrack?.play(container.current);
    return () => {
      props.videoTrack?.stop();
    };
  }, [container, props.videoTrack]);

  useEffect(() => {
    if (props.userType === 'remote' && props.audioTrack) {
      props.audioTrack?.play();
    } else {
      props.audioTrack?.stop();
    }
    return () => {
      props.audioTrack?.stop();
    };
  }, [props.audioTrack]);

  const [userInfo, setuserInfo] = useState({});
  const [childInfo, setchildInfo] = useState({});
  useEffect(() => {
    if (props.clientUid != undefined) {
      getUserInfo(props.clientUid);

      const currentProfile =
        familyMemberDtoSet &&
        familyMemberDtoSet?.marketingRegistrations?.filter((f) => f.familyUserId == props.clientUid)[0];

      currentProfile && setchildInfo(currentProfile);
    }
  }, [props.clientUid]);
  const getUserInfo = (uid: any) => {
    // if (isUserLoggedIn && isUserLoggedIn.d)
    //   adminService.getAll(`user-profiles/${uid}`).then(
    //     (res) => {
    //       if (res?.status === "Success") {
    //         setuserInfo(res.data);
    //       }
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // else

    adminService.getAll(`marketing-registrations/user/${uid}/${props?.sessionId}`).then(
      (res) => {
        if (res?.status === "Success") {
          console.log("marketing user list",res.data);
          setuserInfo(res.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <div
        className={classNames(
          `avc__video-wrapper streams--${
            props?.remoteUsersCount > 7
              ? 4
              : props?.remoteUsersCount > 3 && props?.remoteUsersCount <= 7
              ? 3
              : props?.remoteUsersCount
          } total-user-${props?.remoteUsersCount}`,
          {
            "avc__video-wrapper--small-1": props?.isSmall,
            "avc__video-wrapper--pinned":
              props?.isPin == props.clientUid
                ? true
                : props?.isScreen == props.clientUid
                ? true
                : props?.isOwnScreen == props.clientUid
                ? true
                : false,
            "avc__video-wrapper--black": props?.black,
            "avc__video-wrapper--placeholder": props?.placeholder,
            "volume-indicator":
              isVolumeIndicatorOn[0]?.uid == props.clientUid &&
              isVolumeIndicatorOn[0]?.level > 0,
          }
        )}
        style={{ margin: 10 }}
      >
        <>
          {!props?.placeholder && (
            <p
              style={{
                color: "black",
                fontWeight: "bold",
                position: "absolute",
                zIndex: 9999999,
                left: 10,
                top: 6,
                right: 10,
                wordBreak: "break-all",
              }}
            >
              {childInfo?.firstName != undefined
                ? childInfo?.firstName + " " + childInfo?.lastName
                : userInfo?.firstName != undefined &&
                  userInfo?.firstName + " " + userInfo?.lastName}
            </p>
          )}
          {!props.videoTrack && (
            <>
              {!props?.placeholder && (
                <div className="over-img-middle">
                  {childInfo?.profileUrl &&
                  childInfo?.profileUrl
                    .toString()
                    .toLowerCase()
                    .indexOf("defaultprofile") < 0 ? (
                    <img src={childInfo?.profileUrl} />
                  ) : childInfo?.firstName ? (
                    <div className="text">
                      {childInfo?.firstName.charAt(0) +
                        childInfo?.lastName.charAt(0)}
                    </div>
                  ) : userInfo?.profilePicUrl &&
                    userInfo?.profilePicUrl
                      .toString()
                      .toLowerCase()
                      .indexOf("defaultprofile") < 0 ? (
                    <img src={userInfo?.profilePicUrl} />
                  ) : (
                    userInfo?.firstName && (
                      <div className="text">
                        {userInfo?.firstName.charAt(0) +
                          userInfo?.lastName.charAt(0)}
                      </div>
                    )
                  )}
                </div>
              )}
            </>
          )}

          <div
            className={` avc__video stream-player grid-player`}
            ref={container}
          >
            <div className="avc__video-pin-wrapper">
              <button
                onClick={() => props?.onPin(props.clientUid)}
                className="btn avc__video-pin"
              >
                <ReactSVG src="../resources/assets/fullscreen.svg" />
              </button>
            </div>
            {!props?.placeholder && (
              <div className="avc__video-indicators">
                {!props.audioTrack && (
                  <img
                    src="../resources/assets/no-microphone.svg"
                    alt="mute "
                  />
                )}
                {!props.videoTrack && (
                  <img
                    src="../resources/assets/no-video.svg"
                    alt="video mute SS"
                  />
                )}
              </div>
            )}
          </div>
        </>
      </div>
      {/* <div
        ref={container}
        className="video-player"
        style={{ width: "320px", height: "240px" }}
      ></div>
      <div className="">
        <div className="over-img-middle">
          {childInfo?.profileUrl ? (
            <img src={childInfo?.profileUrl} />
          ) : (
            userInfo?.profilePicUrl && <img src={userInfo?.profilePicUrl} />
          )}
        </div>
      </div>
      {props.clientUid && (
        <>
          <button
            onClick={() =>
              props.userType === "local" &&
              props.handleUser(props.clientUid, "audio")
            }
          >
            {props.audioTrack ? "Mute" : "Unmute"}
          </button>
          {props.userType === "local" && (
            <button
              onClick={() =>
                props.userType === "local" &&
                props.handleUser(props.clientUid, "screen")
              }
            >
              {props.isScreen ? "UnShare" : "Share"}
            </button>
          )}
          <button
            onClick={() =>
              props.userType === "local" &&
              props.handleUser(props.clientUid, "video")
            }
          >
            {props.videoTrack ? "On" : "Off"}
          </button>
          {props?.userType + "=====" + props.clientUid}
        </>
      )} */}
    </>
  );
};

export default MarketingMediaPlayer;
