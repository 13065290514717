import React from 'react';
import FamilyParentInformation from './OnBoardingUser/FamilyParentInformation';

function EditFamilyParentInformation(props) {    

    return (
        <>
            <div className="col-md-10 no-padding">
                <div className="no-padding dash-billing-info mr-0">
                    <div className="custom-table-view">                       
                        <FamilyParentInformation/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditFamilyParentInformation;