import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "../admin/CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../admin/CommonComponents";
import SignDate from "../admin/SignDate";
import NameSignDate from "../admin/NameSignDate";

function Acknowledgement(props) {
  console.log("parentname", props?.data?.familyMemberName
    ? props?.data?.familyMemberName
    : props?.data?.familyMember
      ? props?.data?.familyMember?.firstName +
      " " +
      props?.data?.familyMember?.lastName
      : " ");
  const formRef = React.useRef(null);
  const profile = useSelector((state) => state.adminUser.profile);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [witnessImageURL, setWitnessImageURL] = useState(null);
  const [witnessModal, setWitnessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sigCanvas = useRef({});
  const sigWitnessCanvas = useRef({});
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const [isSubmit, setIsSubmit] = useState(
    props?.from != "family" || props?.data?.reply
      ? true
      : props?.data?.replyNeeded == false
        ? !props?.data.replyNeeded
        : false
  );

  const [familyInfo, setFamilyInfo] = useState({});

  const [acknowledgement, setacknowledgement] = useState({
    guardianInitials: props?.data?.familyName ? props.data.familyName : "",
    guardianSign:
      props?.data?.familySignDate && props?.data?.family
        ? props?.data?.family?.userProfile?.signatureUrl
        : false,
    printName: props?.data?.familyMemberName
      ? props?.data?.familyMemberName
      : props?.data?.familyMember
        ? props?.data?.familyMember?.firstName +
        " " +
        props?.data?.familyMember?.lastName
        : " ",
    patientDob: props?.data?.familyMemberDob
      ? props?.data?.familyMemberDob
      : props?.data?.familyMember
        ? props.data.familyMember.dob
        : " ",
    guardianSignDate: props?.data?.familySignDate
      ? props.data.familySignDate
      : false,
  });
  useEffect(() => {
    setFamilyInfo({
      id: props?.data?.id ? props.data.id : null,
      familyMemberName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      familyMemberId: props?.inputs?.familyMemberId
        ? props?.inputs?.familyMemberId
        : null,
      familyMemberDob: props?.data?.familyMemberDob
        ? props?.data?.familyMemberDob
        : props?.data?.familyMember
          ? props.data.familyMember.dob
          : " ",
      // signature: familyImageURL,
      familySign:
        props?.data?.familySignDate && props?.data?.family
          ? props?.data?.family?.userProfile?.signatureUrl
          : false,
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
      familyName: props?.data?.familyName ? props.data.familyName : "",
    });
  }, [props?.data]);
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  console.log(
    moment(acknowledgement.patientDob).format("MM-DD-YYYY"),
    "patientDob"
  );
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const clearWitness = () => sigWitnessCanvas.current.clear();
  const saveWitness = () => {
    setWitnessImageURL(
      sigWitnessCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setWitnessModal(false);
    setFamilyInfo({
      ...familyInfo,
      witnessSignDate: moment(new Date()).format("DD-MM-YYYY h:s:a"),
    });
  };

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setacknowledgement({
      ...acknowledgement,
      guardianSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };
  const [signatureErr, setSignatureErr] = useState("");
  const onSubmitFamilyForm = (isSubmit = false) => {
    setIsLoading(true);
    if (props?.data?.replyNeeded) {
      if (familyInfo?.familySignDate) {
        let formData = new FormData();
        // formData.append("signature", familyImageURL);
        formData.append(
          "formUpdationForm",
          new Blob(
            [
              JSON.stringify({
                ...familyInfo,
                jsonFormattedForm: JSON.stringify({ acknowledgement }),
              }),
            ],
            {
              type: "application/json",
            }
          )
        );
        adminService
          .onboardPutUser(
            `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
            formData
          )
          .then((response) => {
            setIsLoading(true);
            toast.success("Form Submitted successfully");
            props?.from == "family" && props.handleBack("reload");
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        setSignatureErr("Signature is required!");
      }
    } else if (props?.from === "admin") {
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("signature", familyImageURL);
      console.log("formData 220", formData);
      formData.append(
        "formCreationForm",
        new Blob([JSON.stringify(props?.inputs)], {
          type: "application/json",
        })
      );
      adminService
        .onboardUser(
          `submitted-forms`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Form Submitted successfully");
          props.setIsFocused(!props.isFocused);
          props?.from == "admin" && props.handleBack("reload");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };
  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("res.data.signatureUrl", res.data.signatureUrl);
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setacknowledgement({
              ...acknowledgement,
              guardianSign: res.data.signatureKey,
              guardianSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const updateInfo = (e) => {
    const { name, value } = e.target;
    setacknowledgement({ ...acknowledgement, [name]: value });
  };

  const startDownload = () => {
    const html = CreateTemplate(formRef);
    console.log("html", html);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "Acknowledgement.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const relValue = props?.data?.reply
    ? JSON.parse(props.data?.jsonFormattedForm)
    : "";
  let dobValue = moment(acknowledgement.patientDob).format("MM-DD-YYYY");
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        {" "}
        <div
          style={{
            position: "absolute",
            top: 0,
            right: "100px",
          }}
        >
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.familySignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}
        </div>
        <div className="card-lg" style={{ width: "100%" }}>
          <FormHeader logoUrl={logoUrl} title="ACKNOWLEDGEMENTS" />
          <hr />
          <br />
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <div style={{ width: "60%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Patient’s Name : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.acknowledgement?.printName
                    ) : (
                      <input
                        {...register('printName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                        type="text"
                        maxlength="40"
                        placeholder="Client's name"
                        name="printName"
                        className={`form-control form-input form-input-w-60per ${errors.printName && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberName}
                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "40%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Date Of Birth : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.acknowledgement?.patientDob.trim() &&
                      moment(acknowledgement.dob).format("MM/DD/YYYY")
                    ) : (
                      <input
                        {...register('patientDob', { onChange: (e) => updateInfo(e), required: familyInfo.familyMemberDob === '' ? true : false })}
                        type="date"
                        name="patientDob"
                        placeholder="DOB"
                        className={`form-control form-input form-input-w-60per ${errors.patientDob && "is-invalid"
                          }`}
                        defaultValue={familyInfo.familyMemberDob}
                        disabled={familyInfo.familyMemberDob === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <p style={{ width: "100%" }}>
                  <b>Acknowledgement of HIPAA PRACTICES</b>
                </p>
                <p style={{ width: "100%" }}>
                  By signing this form, I acknowledge that I have received a
                  copy of {name} Network HIPAA practices and have read and
                  understand their policies
                </p>
                <div style={{ width: "90%" }}>
                  <label style={{ width: "auto" }}>
                    Guardian Initials :
                  </label>
                  &nbsp;&nbsp;
                  {isSubmit ? (
                    relValue?.acknowledgement?.guardianInitials
                  ) : (
                    <input
                      maxlength="40"
                      style={{
                        height: 18,
                        width: "60%",
                        border: "none",
                        display: "inline-block",
                        borderBottom: "1px solid",
                        borderRadius: 0,
                        fontSize: 16,
                        background: "white",
                        padding: 0,
                      }}
                      {...register('guardianInitials', { onChange: (e) => updateInfo(e), required: familyInfo.familyName === '' ? true : false })}
                      type="text"
                      placeholder="Client's name"
                      name="guardianInitials"
                      className={`form-control ${errors.guardianInitials && "is-invalid"
                        }`}
                      defaultValue={familyInfo.familyName}
                      disabled={familyInfo.familyName === '' ? false : true}
                    //onChange={(e) => updateInfo(e)}
                    />
                  )}
                </div>
              </div>

              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <p style={{ width: "100%" }}>
                  <b>Acknowledgement of NO SHOW / CANCELLATION POLICY</b>
                </p>
                <p style={{ width: "100%" }}>
                  By signing this form, I acknowledge that I have received a
                  copy of {name} Network No Show / Cancellation Policy and have
                  read and understand their policies.
                </p>
                <div style={{ width: "90%" }}>
                  <label style={{ width: "auto" }}>
                    Guardian Initials :
                  </label>
                  &nbsp;&nbsp;
                  {isSubmit ? (
                    relValue?.acknowledgement?.guardianInitials
                  ) : (
                    <input
                      maxlength="40"
                      style={{
                        height: 18,
                        width: "60%",
                        border: "none",
                        display: "inline-block",
                        borderBottom: "1px solid",
                        borderRadius: 0,
                        fontSize: 16,
                        background: "white",
                        padding: 0,
                      }}
                      {...register('guardianInitials', { onChange: (e) => updateInfo(e), required: familyInfo.familyName === '' ? true : false })}
                      type="text"
                      placeholder="Client's name"
                      name="guardianInitials"
                      className={`form-control ${errors.guardianInitials && "is-invalid"
                        }`}
                      defaultValue={familyInfo.familyName}
                      disabled={familyInfo.familyName === '' ? false : true}
                    //onChange={(e) => updateInfo(e)}
                    />
                  )}
                </div>
              </div>

              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <p style={{ width: "100%" }}>
                  <b>Acknowledgement of GUARDIAN / PROVIDER AGREEMENT</b>
                </p>
                <p style={{ width: "100%" }}>
                  By signing this form, I acknowledge that I have received a
                  copy of {name} Network No Show / Cancellation Policy and have
                  read and understand their policies.
                </p>
              </div>

              <br />

              <NameSignDate
                date={acknowledgement?.guardianSignDate ||
                  relValue?.acknowledgement?.guardianSignDate
                }
                familyImageURL={familyImageURL}
                isSubmit={isSubmit}
                setOpen={setOpen}
                signTitle="Guardian"
                errors={errors}
                familyInfo={familyInfo}
                updateInfo={updateInfo}
                inputName={
                  isSubmit
                    ? relValue?.acknowledgement?.guardianInitials
                    : familyInfo.familyName
                }
                register={register}
              />

              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />

              <br />
              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {signatureErr && (
                    <p className="text-red">
                      <small>{signatureErr}</small>
                    </p>
                  )}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {props?.data.replyNeeded && (
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={familyInfo.familySign ? false : true}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {props?.from === 'admin' && props?.isFormOpen &&
                    <>
                      <button
                        className="btn btn-theme-green"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btn-success float-right1 mt-10 mb-10"
                      >
                        {isLoading ? "Loading...." : "Submit"}
                      </button>
                    </>

                  }
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Acknowledgement;
