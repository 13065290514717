import adminAction from './adminAction'
import messageAction from './messageAction'
import appAction from './appAction'
import emojiAction from './emojiAction'
const allActions = {
    adminAction,
    messageAction,
    appAction,
    emojiAction
}

export default allActions