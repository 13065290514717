import React from "react";
import Messages from "../../../components/messages/Messages";
import SessionsDetail from "../../../components/SessionsDetail";
import TeamDetail from "../../../components/TeamDetail";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Pagination from "react-responsive-pagination";

function OnBoardedFamilies(props) {
  const {
    isInterpeter,
    families,
    loading,
    paginatePre,
    paginate,
    paginateNext,
    currentPage,
    totalpages,
    user_role,
    hasTherapistInFamily,
    profile,
    selectedUser,
    FamilyChildRow,

    rowclickid,
    selectedChildId,
    onClickRow,
    pageNumbers,
    loadings,
    therapysessions,
    successSub,
    teamdata,
    setSorting,
    sorting,
  } = props;
  let sortBy = sorting == "DESC" ? "ASC" : "DESC";
  return (
    <>
      <div className="row no-gutters">
        <div className="col-md-8 therapist p-0">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" onClick={() => setSorting(sortBy)}>
                    Client Name{" "}
                    {sorting == "ASC" ? <FaAngleUp /> : <FaAngleDown />}
                  </th>
                  <th scope="col">Caretaker Member</th>
                  {!isInterpeter && (
                    <th className="text-nowrap" scope="col">
                      Completed Appointment
                    </th>
                  )}
                  {!isInterpeter && <th scope="col">Action</th>}
                </tr>
              </thead>

              {families.length > 0 ? (
                families &&
                families.map((item, index) => (
                  <>
                    <FamilyChildRow
                      item={item}
                      subitem={item}
                      key={item.id + "-" + index}
                      className={`cursor-pointer ${
                        item.familyId == rowclickid &&
                        item.id === selectedChildId
                          ? "bg-light-cyan"
                          : ""
                      }`}
                      onClick={() => onClickRow(item.familyId, item.id, item)}
                    />
                    {/* <tbody key={index}>
                    {item.familyMembers &&
                      item.familyMembers.map((subitem, index) => (
                        <FamilyChildRow
                          item={item}
                          subitem={subitem}
                          key={subitem.id + "-" + index}
                          className={`cursor-pointer ${
                            item.id == rowclickid &&
                            subitem.id === selectedChildId
                              ? "bg-light-cyan"
                              : ""
                          }`}
                          onClick={() => onClickRow(item.id, subitem.id, item)}
                        />
                      ))}
                  </tbody> */}
                  </>
                ))
              ) : (
                <tbody>
                  <tr>
                    {!isInterpeter && <td></td>}
                    {!isInterpeter && <td></td>}
                    <td>
                      <div className="mt-5">
                        <h4>No Caretaker Available</h4>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          {loading ? (
            <></>
          ) : (
            <>
              {/* Pagination */}

              <div className="mt-10">
                <Pagination
                  total={totalpages}
                  current={currentPage}
                  onPageChange={(page) => paginate(page)}
                />
              </div>
              {/* <nav>
                <ul className="pagination justify-content-center">
                  <li onClick={() => paginatePre()} className="page-item">
                    {currentPage <= 0 ? (
                      <></>
                    ) : (
                      <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                        <img
                          className="pr-2"
                          src="../resources/assets/arrow-left.svg"
                        />
                      </div>
                    )}
                  </li>
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      onClick={() => paginate(number)}
                      className="page-item"
                    >
                      <div
                        className={`${
                          currentPage === number
                            ? "pagination-active page-link pagination"
                            : "page-link pagination cursor-pointer"
                        }`}
                      >
                        {number + 1}
                      </div>
                    </li>
                  ))}
                  <li onClick={() => paginateNext()} className="page-item">
                    {currentPage >= totalpages - 1 ? (
                      <></>
                    ) : (
                      <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                        <img
                          className="pr-2"
                          src="../resources/assets/arrow-right.svg"
                        />
                      </div>
                    )}
                  </li>
                </ul>
              </nav> */}
              {/* End */}
            </>
          )}
        </div>

        <div className="col-md-4 therapist table-th-merge-gap">
          <div className="table-responsive">
            <table className="table d-block">
              <thead className="d-block">
                <tr className="d-block">
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody className="bg-color1 d-block">
                <tr className="d-block">
                  {selectedUser && (
                    <td className="d-block">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {!isInterpeter && (
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="session-tab"
                              data-toggle="tab"
                              href="#session"
                              role="tab"
                              aria-controls="session"
                              aria-selected="true"
                            >
                              Sessions
                            </a>
                          </li>
                        )}
                        {(user_role === "ROLE_RCE" ||
                          user_role === "ROLE_REGIONAL_EARLY_STEPS") && (
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="team-tab"
                              data-toggle="tab"
                              href="#team"
                              role="tab"
                              aria-controls="team"
                              aria-selected="false"
                            >
                              Team
                            </a>
                          </li>
                        )}
                        {((user_role === "ROLE_THERAPIST" &&
                          hasTherapistInFamily(profile.id, selectedUser)) ||
                          isInterpeter) && (
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="team-tab"
                              data-toggle="tab"
                              href="#team"
                              role="tab"
                              aria-controls="team"
                              aria-selected="false"
                            >
                              Messages
                            </a>
                          </li>
                        )}
                      </ul>
                      <div className="d-block tab-content" id="myTabContent">
                        {!isInterpeter && (
                          <div
                            className="tab-pane fade show active"
                            id="session"
                            role="tabpanel"
                            aria-labelledby="session-tab"
                          >
                            {loadings ? (
                              <div className="loader-small"></div>
                            ) : (
                              <SessionsDetail
                                therapysessions={therapysessions}
                                successSub={() => successSub()}
                                bgColor="bg-color1"
                                page="family"
                              />
                            )}
                          </div>
                        )}
                        {(user_role === "ROLE_RCE" ||
                          user_role === "ROLE_REGIONAL_EARLY_STEPS") && (
                          <div
                            className="tab-pane fade"
                            id="team"
                            role="tabpanel"
                            aria-labelledby="team-tab"
                          >
                            {loadings ? (
                              <div className="loader-small"></div>
                            ) : (
                              <TeamDetail
                                teamData={teamdata}
                                bgColor="bg-color1"
                                page="family"
                              />
                            )}
                          </div>
                        )}
                        {((user_role === "ROLE_THERAPIST" &&
                          hasTherapistInFamily(profile.id, selectedUser)) ||
                          isInterpeter) && (
                          <div
                            className={`d-block tab-pane fade ${
                              isInterpeter && "show active"
                            }`}
                            id="team"
                            role="tabpanel"
                            aria-labelledby="team-tab"
                          >
                            <Messages
                              otherChildId={selectedChildId}
                              otherUser={
                                selectedUser?.userProfile
                                  ? selectedUser?.userProfile
                                  : selectedUser
                              }
                              bgColor="bg-color1"
                              page="family"
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnBoardedFamilies;
