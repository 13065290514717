import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import allActions from "../../redux/actions";
import { adminActions } from "../../actions";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { apiUrlAuth, apiUrl } from "../../config/Config";
import "./imp.scss";

function Impersonation() {
  let params = useParams();
  console.warn(params.id);

  const dispatch = useDispatch();
  const history = useNavigate();

  const [initialData, setInitialData] = useState({
    loader: true,
    error: "",
    loaded: false,
    data: [],
  });

  const { loader, error, data, loaded } = initialData;

  useEffect(() => {
    // let accountDetail = fetch(`${apiUrl}/misc/billing-company-detail?tenantId=${tenantinfo?.tenantId}`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer ' + token,
    //     'SessionId': sessionId,
    //     'X-TenantID': tenantinfo?.tenantId
    //   }
    // }).then((response) => response.json())
    //alert("ok");
    const data = { tenantId: params.id };

    let userInfo = JSON.parse(localStorage.getItem("user"));
    console.log("Already Exist User", userInfo);
    console.log("Already Exist User Info", userInfo?.userDetails);

    if (userInfo?.userDetails) {
      if (userInfo?.userDetails?.roles[0] == "ROLE_RCE") {
        history("/");
      } else {
        toast.error("User already logged in, please logout and try again");
        history("/");
      }
    } else {
      //alert("fetch");
      fetch(apiUrlAuth + "/auth/impersonate", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "X-TenantId": params.id,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === "Success") {
            //Clear local storage
            //localStorage.clear();

            if (res.data.userDetails.newUser) {
              //toast.success(res.message);
              //res.data.tenantDetails = tenantinfo;
              //dispatch(allActions.adminAction.logOut()); //logout if logged
              dispatch(allActions.adminAction.logIn(res.data));
              history("/reset-password");
            } else {
              fetch(`${apiUrl}/misc/billing-company-detail?tenantId=${params.id}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + res.data.accessToken,
                  'SessionId': res.data.sessionId,
                  'X-TenantID': params.id
                }
              }).then((response) => response.json())
              //Then with the data from the response in JSON...
              .then((data) => {
                console.log('admin normal Success:', data?.data?.stripeAccountId === null, data?.data?.stripeAccountId);
                if (data?.status === "Success") {
                  if (data?.data?.stripeAccountId === null) {
                    history("/admin-normal-account");
                  } else {
                    dispatch(allActions.adminAction.logIn(res.data));
                    history("/dashboard");
                  }
                } else {
                  toast.error(res.message);
                }
                
                //setAccountId(data?.data?.stripeAccountId);
                //setstripeAccountCompleted(data?.data?.stripeAccountCompleted);
              })
              //toast.success(res.message);
              //dispatch(allActions.adminAction.logOut()); //logout if logged
              // console.log("dis logIn", res.data);
              // dispatch(allActions.adminAction.logIn(res.data));
              // history("/dashboard");
              //res.data.tenantDetails = tenantinfo;
              // dispatch(allActions.adminAction.logIn(res.data));
              // if (sessionId) {
              //   history(`/video-therapy/${sessionId}`);
              // } else {
              //   history("/dashboard");
              // }
            }
          } else {
            toast.error(res.message);
            history("/login");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          history("/login");
        });
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center screenSize">
        <div className="align-self-center pos-relative mt-50">
          {loader ? (
            <div className="">
              <div className="loader"></div>
            </div>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </>
  );
}

export default Impersonation;
