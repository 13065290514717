import { appReduxStore } from "..";

export function authHeader() {
  // return authorization header with jwt token
  var store = appReduxStore.getState();
  let user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const TenantID =
    store?.adminUser?.tenantInfo?.tenantId ||
    (user && user.tenantDetails && user.tenantDetails.tenantId) ||
    (process.env.NODE_ENV === "development" && "dev-tenant-1");
  if (user && user.accessToken) {
    return {
      Authorization: "Bearer " + user.accessToken,
      SessionId: user.sessionId,
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-TenantID": TenantID || user.tenantDetails.tenantId,
      // 'Origin': '*',
      // "Access-Control-Allow-Origin" : "*",
      // "Access-Control-Allow-Credentials" : true,
      // "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-TenantID":
        TenantID || (user && user.tenantDetails && user.tenantDetails.tenantId),
    };
  }
}

export function authHeaderMultiPartUpload() {
  // return authorization header with jwt token
  var store = appReduxStore.getState();
  let user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const TenantID =
    store?.adminUser?.tenantInfo?.tenantId ||
    (user && user.tenantDetails && user.tenantDetails.tenantId) ||
    (process.env.NODE_ENV === "development" && "dev-tenant-1");
  if (user && user.accessToken) {
    return {
      Authorization: "Bearer " + user.accessToken,
      SessionId: user.sessionId,
      "X-TenantID": TenantID || user.tenantDetails.tenantId,
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-TenantID":
        TenantID || (user && user.tenantDetails && user.tenantDetails.tenantId),
    };
  }
}

export function authHeaderMultiPartUploadJSONContent() {
  // return authorization header with jwt token
  var store = appReduxStore.getState();
  let user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const TenantID =
    store?.adminUser?.tenantInfo?.tenantId ||
    (user && user.tenantDetails && user.tenantDetails.tenantId) ||
    (process.env.NODE_ENV === "development" && "dev-tenant-1");
  if (user && user.accessToken) {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
      SessionId: user.sessionId,
      "X-TenantID": TenantID || user.tenantDetails.tenantId,
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-TenantID":
        TenantID || (user && user.tenantDetails && user.tenantDetails.tenantId),
    };
  }
}
