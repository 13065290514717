import React, { useEffect, useState } from "react";
import moment from "moment";
import { adminService } from "../services/adminService";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { daysJson, monthJson } from "../config/Config";
import {
  getUTCTimeToLocalByDateWithMeridium,
  calculateDuration,
  getUTCTimeToLocalByDateWithMeridium24,
} from "../utils/commonFunctions";

import DashboardSessionList from "./DashboardSessionList";
// import { now } from 'lodash';

const localizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

function DashboardSessionCalender(props) {
  const history = useNavigate();
  const [loading, setloading] = useState(false);
  const [allEventsCal, setAllEventsCal] = useState([]);
  let [sessions, setSessions] = useState([]);
  let user = useSelector((state) => state.adminUser.user);
  let profile = useSelector((state) => state.adminUser.profile);
  const [childList, setChildList] = useState([]);

  let qry_param_filter =
    props.userrole === "ROLE_THERAPIST"
      ? `&therapistId=${props.userid}`
      // : props.userrole === "ROLE_INTERPRETER"
      // ? `&interpreterId=${props.userid}`
      : "";

  const [sessionview, setSessionView] = useState("session-list");
  const [teamdetails, setTeamDetails] = useState([]);

  const renderEventContent = (eventInfo) => {
    return (
      <div>
        <p>{eventInfo.event.title}</p>
        <img className="eventimage" src={eventInfo.event.url} />
      </div>
    );
  };

  useEffect(() => {
    user.userDetails.roles[0] === "ROLE_FAMILY" && getChildList();
  }, [allEventsCal]);

  const getChildList = () => {
    adminService.getAll(`families/get-family-member`).then(
      (res) => {
        if (res?.message == "Success") {
          setChildList(res.data.content);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    let arr = [];
    if (
      user.userDetails.roles[0] === "ROLE_FAMILY" &&
      allEventsCal?.familyMemberDtoSet
    ) {
      arr = childList.filter((o1) =>
        allEventsCal?.familyMemberDtoSet.some((o2) => o1.id === o2.id)
      );
    }
    // setCommonChildList(arr);
  }, [childList]);
  const [currentDateData, setCurrentDateData] = useState({
    startFromDateTime: "",
    endToDateTime: "",
  });
  useEffect(() => {
    let date = new Date();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    m = m < 10 ? "0" + m : m;
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let startFromDateTime = y + "-" + m + "-01" + "T00:00:00";
    let endToDateTime = y + "-" + m + "-" + lastDay.getDate() + "T23:59:59";
    setCurrentDateData({
      startFromDateTime: startFromDateTime,
      endToDateTime: endToDateTime,
    });
  }, []);
  useEffect(() => {
    let date = new Date();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    m = m < 10 ? "0" + m : m;
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let startFromDateTime =
      currentDateData?.startFromDateTime || y + "-" + m + "-01" + "T00:00:00";
    let endToDateTime =
      currentDateData?.endToDateTime ||
      y + "-" + m + "-" + lastDay.getDate() + "T23:59:59";
    setloading(true);
    {
      (user.userDetails.roles[0] === "ROLE_RCE" ||
        user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS") &&
        adminService
          .getAll(
            `sessions/filter/formatted/?page=0&size=1000&sort=startDateTime,DESC${qry_param_filter}&startFromDateTime=${startFromDateTime}&startToDateTime=${endToDateTime}&timeZone=${moment.tz.guess()}`
          )
          .then(
            (res) => {
              if (res?.status === "Success") {
                let sessionAryCal = [];
                res.data.content &&
                  res.data.content[0]?.map((item) => {
                    item.therapySession?.map((sessioninfo) => {
                      let arr = [];
                      if (
                        user.userDetails.roles[0] === "ROLE_FAMILY" &&
                        sessioninfo?.familyMemberDtoSet
                      ) {
                        arr = childList.filter((o1) =>
                          sessioninfo?.familyMemberDtoSet.some(
                            (o2) => o1.id === o2.id
                          )
                        );
                      }
                      let name = "";
                      name +=
                        user.userDetails.roles[0] == "ROLE_FAMILY" &&
                        arr.length > 0
                          ? arr[0]?.firstName + " " + arr[0]?.lastName
                          : sessioninfo?.familyMemberDtoSet[0]?.firstName +
                            " " +
                            sessioninfo?.familyMemberDtoSet[0]?.lastName;

                      name +=
                        sessioninfo.familyMemberDtoSet.length > 1
                          ? ` +${
                              sessioninfo.familyMemberDtoSet.length - 1
                            } more`
                          : "";

                      let array = {
                        id: sessioninfo.id,
                        title:
                          "( " +
                          getUTCTimeToLocalByDateWithMeridium(
                            sessioninfo.startDateTime
                          ) +
                          "-" +
                          getUTCTimeToLocalByDateWithMeridium(
                            sessioninfo.endDateTime
                          ) +
                          " ) \n" +
                          name +
                          " " +
                          " \n" +
                          sessioninfo.therapy.description,
                        allDay: false,
                        start: new Date(moment.utc(sessioninfo.startDateTime)),
                        end: new Date(moment.utc(sessioninfo.endDateTime)),
                        // url: sessioninfo.familyMember.profileUrl
                        // url: 'https://www.google.com/'
                        status: sessioninfo.status,
                      };
                      sessionAryCal.push(array);
                    });
                  });
                setAllEventsCal(sessionAryCal);
                //setTherapySessions(res.data.content);
                setloading(false);
              } else {
                setloading(false);
                res?.message
                  ? toast.error(res?.message)
                  : console.log("Somthing went wrong");
              }
            },
            (error) => {
              console.log(error);
              setloading(false);
            }
          );
    }

    {
      user.userDetails.roles[0] === "ROLE_FAMILY" &&
        adminService
          .getAll(
            `families/filter/formatted/?page=0&size=20&sort=startDateTime,DESC${qry_param_filter}&startFromDateTime=${startFromDateTime}&startToDateTime=${endToDateTime}&timeZone=${moment.tz.guess()}`
          )
          .then(
            (res) => {
              if (res?.status === "Success") {
                let sessionAryCal = [];
                res.data.content &&
                  res.data.content[0]?.map((item) => {
                    item.therapySession?.map((sessioninfo) => {
                      // console.log("sessioninfo", familyMemberDetail);

                      let arr = [];
                      if (
                        user.userDetails.roles[0] === "ROLE_FAMILY" &&
                        sessioninfo?.familyMemberDtoSet
                      ) {
                        arr = childList.filter((o1) =>
                          sessioninfo?.familyMemberDtoSet.some(
                            (o2) => o1.id === o2.id
                          )
                        );
                      }
                      let name = "";
                      name +=
                        user.userDetails.roles[0] == "ROLE_FAMILY" &&
                        arr.length > 0
                          ? arr[0]?.firstName + " " + arr[0]?.lastName
                          : sessioninfo?.familyMemberDtoSet[0]?.firstName +
                            " " +
                            sessioninfo?.familyMemberDtoSet[0]?.lastName;

                      name +=
                        sessioninfo.familyMemberDtoSet.length > 1
                          ? ` +${
                              sessioninfo.familyMemberDtoSet.length - 1
                            } more`
                          : "";

                      const familyMemberDetail =
                        sessioninfo?.familyMemberDtoSet.filter(
                          (f) => f.familyUserId == profile?.id
                        );

                      // console.log("sessioninfo", familyMemberDetail);
                      let array = {
                        id: sessioninfo.id,
                        title:
                          "( " +
                          getUTCTimeToLocalByDateWithMeridium(
                            sessioninfo.startDateTime
                          ) +
                          "-" +
                          getUTCTimeToLocalByDateWithMeridium(
                            sessioninfo.endDateTime
                          ) +
                          " ) \n" +
                          "" +
                          name +
                          " \n" +
                          sessioninfo.therapy.description,
                        allDay: false,
                        start: new Date(moment.utc(sessioninfo.startDateTime)),
                        end: new Date(moment.utc(sessioninfo.endDateTime)),
                        url: familyMemberDetail[0]?.profileUrl,
                        status: sessioninfo.status,
                      };
                      sessionAryCal.push(array);
                    });
                  });
                setAllEventsCal(sessionAryCal);
                //  setTherapySessions(res.data.content);
                setloading(false);
              } else {
                setloading(false);
                res?.message
                  ? toast.error(res?.message)
                  : console.log("Somthing went wrong");
              }
            },
            (error) => {
              console.log(error);
              setloading(false);
            }
          );
    }
    {
      user.userDetails.roles[0] === "ROLE_THERAPIST" &&
        adminService
          .getAll(
            `therapist/sessions/filter/formatted/?page=0&size=1000&sort=startDateTime,DESC${qry_param_filter}&startFromDateTime=${startFromDateTime}&startToDateTime=${endToDateTime}&timeZone=${moment.tz.guess()}`
          )
          .then(
            (res) => {
              if (res?.status === "Success") {
                let sessionAryCal = [];
                res.data.content &&
                  res.data.content[0]?.map((item) => {
                    item.therapySession?.map((sessioninfo) => {
                      let arr = [];
                      if (
                        user.userDetails.roles[0] === "ROLE_FAMILY" &&
                        sessioninfo?.familyMemberDtoSet
                      ) {
                        arr = childList.filter((o1) =>
                          sessioninfo?.familyMemberDtoSet.some(
                            (o2) => o1.id === o2.id
                          )
                        );
                      }
                      let name = "";
                      name +=
                        user.userDetails.roles[0] == "ROLE_FAMILY" &&
                        arr.length > 0
                          ? arr[0]?.firstName + " " + arr[0]?.lastName
                          : sessioninfo?.familyMemberDtoSet[0]?.firstName +
                            " " +
                            sessioninfo?.familyMemberDtoSet[0]?.lastName;

                      name +=
                        sessioninfo.familyMemberDtoSet.length > 1
                          ? ` +${
                              sessioninfo.familyMemberDtoSet.length - 1
                            } more`
                          : "";

                      let array = {
                        id: sessioninfo.id,
                        title:
                          "( " +
                          getUTCTimeToLocalByDateWithMeridium(
                            sessioninfo.startDateTime
                          ) +
                          "-" +
                          getUTCTimeToLocalByDateWithMeridium(
                            sessioninfo.endDateTime
                          ) +
                          " ) \n" +
                          name +
                          " \n" +
                          sessioninfo.therapy.description,
                        allDay: false,
                        start: new Date(moment.utc(sessioninfo.startDateTime)),
                        end: new Date(moment.utc(sessioninfo.endDateTime)),
                        url: sessioninfo?.familyMemberDtoSet[0]?.profileUrl,
                        status: sessioninfo.status,
                      };
                      sessionAryCal.push(array);
                    });
                  });
                setAllEventsCal(sessionAryCal);
                // setTherapySessions(res.data.content);
                setloading(false);
              } else {
                setloading(false);
                res?.message
                  ? toast.error(res?.message)
                  : console.log("Somthing went wrong");
              }
            },
            (error) => {
              console.log(error);
              setloading(false);
            }
          );
    }

    {
      user.userDetails.roles[0] === "ROLE_INTERPRETER" &&
        adminService
          .getAll(
            `interpreter/sessions/filter/formatted/?page=0&size=1000&sort=startDateTime,DESC${qry_param_filter}&startFromDateTime=${startFromDateTime}&startToDateTime=${endToDateTime}&timeZone=${moment.tz.guess()}`
          )
          .then(
            (res) => {
              if (res.status === "Success") {
                console.log("interpreter calender",res.data.content);
                let sessionAryCal = [];
                res.data.content &&
                  res.data.content[0]?.map((item) => {
                    item.therapySession?.map((sessioninfo) => {
                      let arr = [];
                      if (
                        user.userDetails.roles[0] === "ROLE_FAMILY" &&
                        sessioninfo?.familyMemberDtoSet
                      ) {
                        arr = childList.filter((o1) =>
                          sessioninfo?.familyMemberDtoSet.some(
                            (o2) => o1.id === o2.id
                          )
                        );
                      }
                      let name = "";
                      name +=
                        user.userDetails.roles[0] == "ROLE_FAMILY" &&
                        arr.length > 0
                          ? arr[0]?.firstName + " " + arr[0]?.lastName
                          : sessioninfo?.familyMemberDtoSet[0]?.firstName +
                            " " +
                            sessioninfo?.familyMemberDtoSet[0]?.lastName;

                      name +=
                        sessioninfo.familyMemberDtoSet.length > 1
                          ? ` +${
                              sessioninfo.familyMemberDtoSet.length - 1
                            } more`
                          : "";

                      let array = {
                        id: sessioninfo.id,
                        title:
                          "( " +
                          getUTCTimeToLocalByDateWithMeridium(
                            sessioninfo.startDateTime
                          ) +
                          "-" +
                          getUTCTimeToLocalByDateWithMeridium(
                            sessioninfo.endDateTime
                          ) +
                          " ) \n" +
                          name +
                          " " +
                          " \n" +
                          sessioninfo.therapy.description,
                        allDay: false,
                        start: new Date(moment.utc(sessioninfo.startDateTime)),
                        end: new Date(moment.utc(sessioninfo.endDateTime)),
                        url: sessioninfo?.familyMemberDtoSet[0]?.profileUrl,
                        status: sessioninfo.status,
                      };
                      sessionAryCal.push(array);
                    });
                  });
                setAllEventsCal(sessionAryCal);
                // setTherapySessions(res.data.content);
                setloading(false);
              } else {
                toast.error(res.message);
                setloading(false);
              }
            },
            (error) => {
              console.log(error);
              setloading(false);
            }
          );
    }
  }, [currentDateData]);

  const onChnageDateRange = (e) => {
    // console.log("eeeeee", e.length, e);
    let date1 = "";
    let date2 = "";
    if (Array.isArray(e)) {
      date1 = e[0];
      date2 = e[e.length - 1];
    } else {
      date1 = e.start;
      date2 = e.end;
    }
    let d1 = moment(date1).format("DD");
    let m1 = moment(date1).format("MM");
    let y1 = moment(date1).format("YYYY");

    let d2 = moment(date2).format("DD");
    let m2 = moment(date2).format("MM");
    let y2 = moment(date2).format("YYYY");

    let startFromDateTime = y1 + "-" + m1 + "-" + d1 + "T00:00:00";
    let endToDateTime = y2 + "-" + m2 + "-" + d2 + "T23:59:59";

    setCurrentDateData({
      startFromDateTime: startFromDateTime,
      endToDateTime: endToDateTime,
    });
  };

  return (
    <>
      <div className="no-padding dash-billing-info dashboard-session-cal">
        <div className="custom-table-view">
          <div className={`content-header pb-0 ${props.isCal ? "d-none" : ""}`}>
            {!props.isCal && !props.isCal && (
              <div className="text-left header-title">Sessions</div>
            )}
            {!props.isCal && !props.isCal && (
              <div className="text-right header-title">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    className="nav-item dashboard"
                    onClick={() =>
                      loading ? "" : setSessionView("session-list")
                    }
                  >
                    <a
                      className="nav-link active"
                      id="session-tab"
                      data-toggle="tab"
                      href="#session"
                      role="tab"
                      aria-controls="session"
                      aria-selected="true"
                    >
                      {sessionview === "session-list" ? (
                        <img
                          src="../resources/assets/calendar-list-select.svg"
                          alt=""
                        />
                      ) : (
                        <img src="../resources/assets/calendarM.svg" alt="" />
                      )}
                    </a>
                  </li>

                  <li
                    className="nav-item dashboard"
                    onClick={() =>
                      loading ? "" : setSessionView("session-cal")
                    }
                  >
                    <a
                      className={`${
                        loading ? "custom-nav-link-padding" : "nav-link"
                      }`}
                      id="team-tab"
                      data-toggle={`${loading ? "tab1" : "tab"}`}
                      href={`${loading ? "javascript:void(0)" : "#team"}`}
                      role="tab"
                      aria-controls="team"
                      aria-selected="false"
                    >
                      {sessionview === "session-cal" ? (
                        <img
                          src="../resources/assets/calendarW.svg"
                          className="ml-3"
                          alt=""
                        />
                      ) : (
                        <img
                          src="../resources/assets/calendar-gray.svg"
                          className="ml-3"
                          alt=""
                        />
                      )}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {loading && (
            <div className="loader" style={{ zIndex: 9, top: "35%" }}></div>
          )}
          {props.isCal && props.isCal ? (
            <Calendar
              showMultiDayTimes
              events={allEventsCal}
              popup
              // eventContent={renderEventContent}
              views={["month", "week", "day"]}
              initialView="dayGridMonth"
              // views = {['month']}
              step={30}
              timeslots={2}
              initialDate={new Date()}
              //defaultView='week'
              //max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
              components={{
                timeSlotWrapper: ColoredDateCellWrapper,
              }}
              localizer={localizer}
              onSelectEvent={(event) => {
                if (
                  user?.userDetails?.roles == "ROLE_THERAPIST" ||
                  user?.userDetails?.roles == "ROLE_RCE"
                ) {
                  window.open(
                    `/therapy-sessions-details/${event.id}`,
                    "_blank"
                  );
                } else if (
                  event.status !== "CANCELLED" &&
                  (user?.userDetails?.roles !== "ROLE_FAMILY" ||
                    user?.userDetails?.roles !== "ROLE_INTERPRETER")
                ) {
                  window.open(
                    `/therapy-sessions-details/${event.id}`,
                    "_blank"
                  );
                } else {
                  console.log("family can not open canceled session");
                }
              }}
              // onEventDrop={this.onEventDrop}
              // onEventResize={this.onEventResize}
              // resizable
              onRangeChange={(e) => onChnageDateRange(e)}
            />
          ) : (
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="session"
                role="tabpanel"
                aria-labelledby="session-tab"
              >
                <DashboardSessionList />
              </div>
              <div
                className="tab-pane fade"
                id="team"
                role="tabpanel"
                aria-labelledby="team-tab"
              >
                <div className="col-auto mt-5">
                  <Calendar
                    showMultiDayTimes
                    events={allEventsCal}
                    views={["month", "week", "day"]}
                    popup
                    // eventContent={renderEventContent}
                    // views={allEventsCal}
                    // initialView="dayGridMonth"
                    step={30}
                    timeslots={2}
                    initialDate={new Date()}
                    // defaultView='week'
                    //max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                    components={{
                      timeSlotWrapper: ColoredDateCellWrapper,
                    }}
                    localizer={localizer}
                    onSelectEvent={(event) => {
                      if (
                        user?.userDetails?.roles == "ROLE_THERAPIST" ||
                        user?.userDetails?.roles == "ROLE_RCE"
                      ) {
                        window.open(
                          `/therapy-sessions-details/${event.id}`,
                          "_blank"
                        );
                      } else if (
                        event.status !== "CANCELLED" &&
                        (user?.userDetails?.roles !== "ROLE_FAMILY" ||
                          user?.userDetails?.roles !== "ROLE_INTERPRETER")
                      ) {
                        window.open(
                          `/therapy-sessions-details/${event.id}`,
                          "_blank"
                        );
                      } else {
                        console.log("family can not open canceled session");
                      }
                    }}
                    // onEventDrop={this.onEventDrop}
                    // onEventResize={this.onEventResize}
                    // resizable
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardSessionCalender;
