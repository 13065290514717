import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { adminService } from "../../../services/adminService";
import MediaReleaseForm from "./MediaReleaseForm";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "../FormContext";
import Pagination from "../../Pagination";
import FormTable from "./FormTable";
import HippaNoticeForm from "./HippaNoticeForm";
import MedicalInsuranceForm from "./MedicalInsuranceForm";
import CancellationForm from "./CancellationForm";
import ReleaseToCommunicateForm from "./ReleaseToCommunicateForm";
import ConsentToTreat from "./ConsentToTreat";
import DischargeSummery from "../therapist/DischargeSummery";
import Acknowledgement from "../other/Acknowledgement";
import AuthToDiscoloseInfo from "../other/AuthToDiscoloseInfo";
import ChangeInCoverage from "../other/ChangeInCoverage";
import MedicoidForm from "./MedicoidForm";
import SampleEvaluation from "../therapist/SampleEvaluation";
import ParentProvider from "../other/ParentProvider";
import ConsultationDocumentationForm from "./ConsultationDocumentationForm";
import PocDaltonForm from "./PocDaltonForm";
import PocStForm from "./PocStForm";
import PocPtForm from "./PocPtForm";
import PocOtForm from "./PocOtForm";
import QuaterlyProgressForm from "./QuaterlyProgressForm";
import DischargeSummary from "../../../pages/Forms/PDFs/EarlySteps/DischargeSummary";
import PaginationTabs from "../../../pages/pagination";
import PaymentPolicy from "./PaymentPolicy";

function FamilyFormList() {
  let user = useSelector((state) => state.adminUser.user);
  const { inputs, setInputs, signatures, setSignatures } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { register, errors, handleSubmit } = useForm();
  const [family, setFamily] = useState({});
  const [formNameList, setFormNameList] = useState([]);
  const [pageDetail, setPageDetail] = useState({
    totalPages: 0,
    currentPage: 0,
    pages: [],
    sortBy: "createdDate,DESC",
    perPage: 10,
  });
  const [submittedFormList, setSubmittedFormList] = useState({
    isLoading: true,
    list: [],
  });

  const [isFormOpen, setIsFormOpen] = useState(true);

  useEffect(() => {
    handleData();
  }, []);
  const getListApi = ({ currentPage, sortBy, perPage }) =>
    `submitted-forms?page=${currentPage == 0 ? currentPage : currentPage - 1
    }&size=${perPage}&sort=${sortBy}`;
  const getSubmittedFormList = (pageNo) => {
    setIsLoading(true);
    setSubmittedFormList({ ...submittedFormList, isLoading: true });
    adminService
      .getAll(getListApi(pageDetail))
      .then((response) => {
        setIsFormOpen(false);
        setPageContent(response);
        // setSubmittedFormList({ list: response.data.content, isLoading: false });
        // setPageDetail({
        //   ...pageDetail,
        //   page: pageNo,
        //   totalpages: response.data.totalPages,
        // });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  const onPaginateFetch = (number) => {
    setSubmittedFormList({
      ...submittedFormList,
      isLoading: true,
    });
    adminService
      .getAll(
        getListApi(
          {
            ...pageDetail,
            currentPage: number,
          }
        )
      )
      .then((res) => {
        setPageContent(res);
      })
      .catch((err) => {
        setSubmittedFormList({
          ...submittedFormList,
          isLoading: false,
        });
        console.log({ err });
      });
  };
  const paginate = (number) => {
    console.log("number", number);
    setPageDetail((q) => ({
      ...q,
      currentPage: number,
    }));
    onPaginateFetch(number);
  };
  const setPageContent = (res) => {
    if (res.data?.content) {
      setSubmittedFormList({
        ...submittedFormList,
        list: res.data?.content,
        isLoading: false,
      });
      setPageDetail((q) => ({
        ...q,
        totalPages: res.data?.totalPages,
        pages: new Array(res.data?.totalPages).fill().map((_i, inx) => inx),
      }));
    }
  };
  useEffect(() => {
    getSubmittedFormList(currentPage);
  }, [currentPage]);

  const handleData = () => {
    getSubmittedFormList(pageDetail.pages);
  };
  console.log("data.form.id---", inputs.formId);
  return (
    <div className="col-md-10 no-padding">
      <div className="no-padding">
        <div className="custom-table-view">
          {isLoading ? (<>
            <div className="row mb-10">
              <div className="col-md-12">
                <div className="text-left header-title">Forms</div>
              </div>
            </div>
            <div className="loader" /></>
          ) : (<>
            {!isFormOpen && (
              <div className="row mb-10">
                <div className="col-md-12">
                  <div className="text-left header-title">Forms</div>
                </div>
              </div>
            )}
            {isFormOpen ? (
              <>
                {inputs.formId == 1 ? (
                  <ConsentToTreat
                    from="family"
                    data={family}
                    handleBack={handleData}
                  />
                ) : inputs.formId == 2 ? (
                  <>
                    <MedicalInsuranceForm
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  </>
                ) : inputs.formId == 3 ? (
                  <HippaNoticeForm
                    from="family"
                    data={family}
                    handleBack={handleData}
                  />
                ) : inputs.formId == 20 ? (
                  <PaymentPolicy
                    from="family"
                    data={family}
                    handleBack={handleData}
                  />
                ) : inputs.formId == 4 ? (
                  <CancellationForm
                    from="family"
                    data={family}
                    handleBack={handleData}
                  />
                ) : inputs.formId == 5 ? (
                  <ReleaseToCommunicateForm
                    from="family"
                    data={family}
                    handleBack={handleData}
                  />
                ) : inputs.formId == 6 ? (
                  <MediaReleaseForm
                    from="family"
                    data={family}
                    handleBack={handleData}
                  />
                ) : inputs.formId == 7 ? (
                  <DischargeSummery
                    from="family"
                    data={family}
                    handleBack={handleData}
                  />) : inputs.formId == 8 ? (
                    <Acknowledgement
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 9 ? (
                    <AuthToDiscoloseInfo
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 10 ? (
                    <ConsultationDocumentationForm
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 16 ? (
                    <PocOtForm
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 17 ? (
                    <ChangeInCoverage
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 11 ? (
                    <ParentProvider
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 12 ? (
                    <QuaterlyProgressForm
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 13 ? (
                    <PocStForm
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 14 ? (
                    <PocPtForm
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 15 ? (
                    <PocDaltonForm
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 18 ? (
                    <MedicoidForm
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : inputs.formId == 19 ? (
                    <SampleEvaluation
                      from="family"
                      data={family}
                      handleBack={handleData}
                    />
                  ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {submittedFormList.isLoading ? (
                  <div className="loader" />
                ) : (
                  <>
                    <FormTable
                      userType="family"
                      list={submittedFormList.list}
                      setIsFormOpen={setIsFormOpen}
                      setFamily={setFamily}
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    {!submittedFormList.isLoading &&
                      submittedFormList.list?.length == 0 && (
                        <h5 className="text-center p-3">No Data Found</h5>
                      )}
                    <PaginationTabs
                      shareQuery={pageDetail}
                      paginate={paginate}
                    />
                    {/* <Pagination
                    totalpages={pageDetail.totalpages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  /> */}
                  </>
                )}
              </>
            )}
          </>)}
        </div>

      </div>
    </div>
  );
}

export default FamilyFormList;
