import React,{useEffect} from 'react';
import ReactPlayer from 'react-player'

function ZoomAttachmentDto(props) {



    const {
        date,
        resourceType,
        key,
        type
    } = props.detail?.details || {}
    const isVideo = type === "video";

    useEffect(() => {
        const close = (e) => {
          if(e.keyCode === 27){
            props.closeModal(false)
          }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
    },[])

    return (
        <>
            <form>
                <div className="col-md-2 custom-modal">
                    <div className="modal fade add-new-prop-modal show " id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-title ml-3" id="exampleModalLongTitle">
                                        <p>{date} <small className="name"><br/>{resourceType}</small></p>
                                       
                                    </div>
                                    <button onClick={() => props.closeModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-background">
                                            <p aria-hidden="true" className="cross">&times;</p>
                                        </div>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="col-md-12">
                                        {
                                            isVideo && <ReactPlayer controls={true} url={key} />
                                        }
                                        {
                                            !isVideo &&
                                            <img src={key} alt="" className="resources-modal-image" />
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ZoomAttachmentDto;