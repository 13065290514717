import React from 'react'
import ReactSelect from 'react-select'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
const DocSelect = 
({ options, onChange, value }) => {
    // const filterColors = (inputValue) => {
    //     if (!inputValue) {
    //         return options
    //     }
    //     return options.filter(i => {
    //         return i.name.toLowerCase().includes(inputValue.toLowerCase())
    //     }
    //     );
    // };
    // const promiseOptions = inputValue => {
    //     return new Promise(resolve => {
    //         setTimeout(() => {
    //             resolve(filterColors(inputValue));
    //         }, 1000);
    //     });
    // }
    const onFiler = (opt, input) => {
        
        const {
            firstName, lastName
        } = opt.data?.userProfile || {}
        const name = [firstName, lastName].filter(Boolean).join(" ").toLowerCase()
        // (option: Option, rawInput: string) => boolean
        return name.includes((input || "").toLowerCase())
    }
    return (
        <ReactSelect
            options={options}
            isMulti={true}
            filterOption={onFiler}
            // menuIsOpen={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={onChange}
            defaultOptions={options}
            // loadOptions={promiseOptions} // use if async
            value={value}
            cacheOptions
            classNamePrefix="c-doc-select"
            components={{
                MultiValue: DocSelectMultiValue,
                Option: DocSelectOption,
            }}
        />
    )
}

export default DocSelect

DocSelect.defaultProps = {
    options: [
        // {
        //     name: 'Dr. Jennifer Chris ',
        //     specialist: 'Speech ',
        //     id: 1,
        //     value: 1,
        // },
        // {
        //     name: 'test a ',
        //     specialist: 'Language',
        //     id: 2,
        //     value: 2,
        // },
        // {
        //     name: 'test B ',
        //     specialist: 'Pathologist',
        //     id: 3,
        //     value: 3,
        // },
    ],
}

const DocSelectOption = ({ innerProps, isDisabled, data, isSelected }) => {
    return <div className={classNames("c-doc-select__option", {
        "c-doc-select__option--selected": isSelected
    })} {...innerProps}>
        <div className="c-doc-select__option-left">
            <span className="c-doc-select__option-name">
                {`${data?.userProfile?.firstName} ${data?.userProfile?.lastName}`}
            </span>
            {/* TODO add this  */}
            {/* <span className="c-doc-select__option-spcl">
                {data.specialist}
            </span> */}
        </div>
        <div className="c-doc-select__option-right">
            <ReactSVG className={classNames("c-doc-select__option-icon", {
                "c-doc-select__option-icon--selected": isSelected
            })} wrapper="span" src="../resources/assets/Icon feather-check.svg" />
        </div>
    </div>
}

const DocSelectMultiValue = ({ data, innerProps, removeProps }) => {
    return <div {...innerProps} className="c-doc-select__value c-doc-select__value--multi" >
        <span>{`${data?.userProfile?.firstName} ${data?.userProfile?.lastName}`}</span>
        <span className="cursor-pointer c-doc-select__value-close" onClick={removeProps.onClick}>
        <ReactSVG 
            wrapper="span"
            src="../resources/assets/close.svg" />
        </span>
    </div>
}