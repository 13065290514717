import React from 'react'

const RecentMsgItem = ({
    image, name, msg, onClick
}) => {
    return (
        <div
            onClick={onClick} 
            style={{
                paddingTop: 1,
                paddingBottom: 1
            }} className="cursor-pointer border-top" >
            <div className="user-info px-3 py-2">
                <span className="admin-name justify-content-start ">
                    <img src={image || '/resources/assets/defaultprofile.png'} className="c-recent-msg__img" alt="" />
                    <div className="content overflow-hidden" >
                        <span>{name}</span>
                        <small className="d-block text-ellipsis" >{msg}</small>
                    </div>
                </span>
            </div>
        </div>
    )
}

export default RecentMsgItem
