import React from "react";
import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";

function SelectDate(props) {
  const {
    params,
    weekcount,
    getPrevWeeK,
    weeks,
    handleChange,
    dateInputs,
    daysJson,
    getNextWeeK,
    monthJson,
  } = props;
  return (
    <>
      <div className="col-md-12 d-flex">
        <div className="working-days">
          {/* !params.id && */}
          {weekcount > 0 && (
            <div className="col9 mr-4">
              <button
                onClick={() => getPrevWeeK()}
                type="button"
                className="btn Rectangle-691"
              >
                <img src="../resources/assets/arrow-left.svg" alt="" />
              </button>
            </div>
          )}
          {weeks.length > 0 &&
            weeks.map((day, index) => (
              <label
                className={`date-radio form-control ${
                  moment().format("YYYY-MM-DD") >
                  moment(day).format("YYYY-MM-DD")
                    ? "disabled-dated-input"
                    : ""
                }`}
                for="checkbox"
              >
                <input
                  type="radio"
                  id="radio"
                  name="session_date"
                  onChange={handleChange}
                  value={day}
                  disabled={
                    moment().format("YYYY-MM-DD") >
                    moment(day).format("YYYY-MM-DD")
                      ? true
                      : false
                  }
                  checked={
                    new Date(day).getDate() ==
                    new Date(dateInputs.session_date).getDate()
                      ? true
                      : false
                  }
                />
                <div className="days">
                  {daysJson[parseInt(index)].short}
                  <br />
                  {moment(day).format("DD")}
                </div>
              </label>
            ))}
          {/* !params.id && */}
          {
            <div className="col9 ml-4">
              <button
                onClick={() => getNextWeeK()}
                type="button"
                className="btn Rectangle-691"
              >
                <img src="../resources/assets/arrow-right.svg" alt="" />
              </button>
            </div>
          }
        </div>
      </div>
      <div className="col-md-8 mt-4 add-session">
        <div className="form-group d-flex align-items-center">
          <label></label>
          <img
            className=""
            src="../resources/assets/Icon ionic-ios-calendar.svg"
            alt=""
          />
          <h6 className="m-0 ml-4">You have selected -</h6>
          <span className="selected-date ml-3">
            {dateInputs.session_date.split("-")[2]}{" "}
            {
              monthJson[parseInt(dateInputs.session_date.split("-")[1]) - 1]
                .name
            }{" "}
            {dateInputs.session_date.split("-")[0]}{" "}
          </span>
        </div>
      </div>
    </>
  );
}

export default SelectDate;
