import React from 'react'
import classNames from 'classnames'

const InputFileBtn = React.forwardRef((props, ref) => {
    const {
        onChange,
        id,
        name,
        className,
        label,
        labelClass
    } = props
    return (
        <div className={classNames("input-btn-green", [className])}>
            <input className="choosefile mb-5"
                ref={ref}
                type="file"
                id={id}
                name={name}
                onChange={onChange}                                                     
            />
            <div className={classNames("change1", [labelClass] )}>
                {label}
            </div>
        </div>
    )
})

export default InputFileBtn

InputFileBtn.defaultProps = {
    label: 'Change'
}
