import React, { useEffect, useState } from "react";
import moment from "moment";
import DocSelect from "../DocSelect";
import {
  getTherapistList,
  shareCaseNote,
} from "../../services/caseNoteServices";
import { useSelector } from "react-redux";
import { _toastHandleErr } from "../../utils/commonFunctions";
import { toast } from "react-toastify";

const CaseNoteShareForm = ({
  therapy,
  date,
  description,
  id,
  onComplete,
  sharedTherapist,
  isOpen,
  childName,
}) => {
  const dateMoment = moment.utc(date).utc().local();
  const user = useSelector((state) => state.adminUser.user)?.userDetails;
  const profile = useSelector((state) => state.adminUser.profile);

  const [therapistList, setTherapistList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ((user?.roles || []).includes("ROLE_THERAPIST")) {
      getTherapistList()
        .then((res) => {
          setTherapistList(
            res
              ?.filter((therapist) => {
                return profile.id !== therapist?.userProfile?.id;
              })
              .map((item) => ({ ...item, value: item.id }))
          );
        })
        .catch(console.log);
    }
    return () => {
      setTherapistList([]);
    };
  }, [user?.roles]);

  useEffect(() => {
    setSelected(sharedTherapist?.map((item) => ({ ...item, value: item.id })));
    return () => {
      setSelected([]);
    };
  }, [isOpen]);

  const onChange = (value) => {
    setSelected(value);
  };

  const onFullfill = () => {
    if (typeof onComplete === "function") {
      onComplete();
    }
  };

  const onSubmit = () => {
    if (selected.length > 0 || true) {
      setIsLoading(true);
      shareCaseNote(id, JSON.stringify(selected.map((item) => item.id)))
        .then((res) => {
          console.log("shared", res);
          toast.success("Session Note shared successfully");
          setIsLoading(false);
          onFullfill();
        })
        .catch((err) => {
          _toastHandleErr(err || "Something went wrong");
          setIsLoading(false);
          onFullfill();
        });
    }
  };

  return (
    <div className="col-12">
      {/* <span className="p-case-notes__card-date">
                <b>
                    {dateMoment.format('dddd DD')}
                </b>
                {dateMoment.format(' MMMM, yyyy')}
            </span> */}
      <div className="p-case-notes__card-title pb-2">
        <b>Sharing Session Notes for {childName}</b>
      </div>
      {/* <div className="p-case-notes__card-content">
                {description?.substring(0, 98)}
                {description?.length > 98 && "..."}
            </div> */}
      <div className="form pt-3">
        <DocSelect
          onChange={onChange}
          options={therapistList}
          value={selected}
        />
        <div className="w-100 text-center mt-5">
          <button
            type="button"
            disabled={isLoading}
            onClick={onSubmit}
            className="btn btn-theme-green btn-large"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CaseNoteShareForm;
