import React from "react";
import Thumbnail from "./Thumbnail";
import {
  capitalString,
  displayGender,
  getFullName,
  maskPhoneNumber,
} from "../utils/commonFunctions";
import { Link } from "react-router-dom";
import classNames from "classnames";

const BasicUserdetail = ({
  therapistInfo,
  image,
  firstName,
  lastName,
  status,
  appointments,
  gender,
  phone,
  email,
  editLink,
  about,
  age,
  isInSmall,
  dob,
  userType,
  currentUserType,
}) => {
  const name = getFullName(firstName, lastName);
  return (
    <div className="c-basic-user-detail">
      <div className="row">
        <div className="col-10">
          <div className="row">
            <div className="c-basic-user-detail__image-col">
              <div className="">{image && <Thumbnail src={image} />}</div>
            </div>
            <div className="c-basic-user-detail__content-col">
              <div className="c-basic-user-detail__status-row d-flex">
                <p className="name c-basic-user-detail__status">
                  {capitalString(name)}
                </p>
                {age && (
                  <p className="c-basic-user-detail__status">Age: {age}</p>
                )}
                {status && (
                  <p className="c-basic-user-detail__status">
                    Status: {status}
                  </p>
                )}
                {!(appointments === null || appointments === undefined) && (
                  <p className="c-basic-user-detail__status">
                    Appointment: {appointments}
                  </p>
                )}
              </div>
              <div className="row">
                {gender && (
                  <div className="col-auto">
                    <h6 className="name mt-4">Gender</h6>
                    <p>{displayGender(gender)} </p>
                  </div>
                )}
                {dob && (
                  <div className="col-auto">
                    <h6 className="name mt-4">Date of Birth</h6>
                    <p>{dob} </p>
                  </div>
                )}
                {phone && (
                  <div className="col-auto">
                    <h6 className="name mt-4">Phone No.</h6>
                    <p>{/*maskPhoneNumber(phone)*/ phone} </p>
                  </div>
                )}
                {email && (
                  <div className="col-auto">
                    <h6 className="name mt-4">Email</h6>
                    <p>{email} </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {editLink && (
          <div
            className={classNames("col-md-1 mt-5", {
              "ml-5 text-right": !isInSmall,
            })}
          >
            {userType == "ROLE_FAMILY" && userType == "ROLE_RCE" &&
            therapistInfo?.updatePermission == "true" ? (
              <Link to={editLink}>
                <img src="/resources/assets/Icon feather-edit-3.svg" alt="" />
              </Link>
            ) : userType == "ROLE_THERAPIST" ? (
              <Link to={editLink}>
                <img src="/resources/assets/Icon feather-edit-3.svg" alt="" />
              </Link>
            ) : currentUserType == "ROLE_RCE" ? (
              <Link to={editLink}>
                <img src="/resources/assets/Icon feather-edit-3.svg" alt="" />
              </Link>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {about && (
        <div className="row ml-3 mt-5">
          <div className="col-md-12">
            <h4 className="name">About</h4>
            <p className="preview">{about && about.replace("↵", "<br />")}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicUserdetail;
