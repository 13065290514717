import React, { useEffect, useState } from 'react';
import { adminService } from '../../services/adminService';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
function CardList() {
  const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
  const [accountId, setAccountId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [selected, setSelected] = useState('yes');
  const [cardList, setCardList] = useState([]);
  const [deleteCards, setDeleteCard] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const [autoPay, setAutoPay] = useState(false);
  let cards = ['amex', 'diners', 'discover', 'jcb', 'mastercard', 'visa'];
  //const urlApi = "https://api.stripe.com/v1/customers/" + customerId + "/payment_methods?type=card";
  useEffect(() => {
    (async () => {
      setLoader(true);
      const stripeCustomer = await adminService
        .getAll(`payments/stripe-details`)
        .then((res) => {
          console.log("stripe customer", res.data);
          setCustomerId(res?.data?.stripeCustomerId);
          setAutoPay(res?.data?.autoPay);
        })
      if (customerId !== '' && accountId !== '') {

        const results = await fetch("https://api.stripe.com/v1/customers/" + customerId + "/payment_methods?type=card", {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content'p-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
            'Stripe-Account': accountId,
          },

          // body  :Object.keys(genCard)
          // .map(key => key + '=' + genCard[key])
          // .join('&'),
        }).then(response => response.json())
        console.log("results card list" + JSON.stringify(results));
        setCardList(results.data);
        setLoader(false);


        const retriveCustomer = await fetch("https://api.stripe.com/v1/customers/" + customerId, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
            'Stripe-Account': accountId,
          },
        }).then(response => {
          return response.json()
        })
        console.log("retriveCustomer", retriveCustomer?.invoice_settings?.default_payment_method);
        setPaymentId(retriveCustomer?.invoice_settings?.default_payment_method);
      }
    })();
  }, [customerId, accountId]);
  useEffect(() => {
    getAccountId();
  })
  const handleAutoPay = (e) => {
    const { name, value } = e.target;
    setAutoPay(!autoPay);
    let postData = { [name]: e.target.checked }
    adminService.PUT(`payments/stripe-details`, JSON.stringify(postData))
      .then((res) => {
        if (res.status === "Success") {
          //setDashboard(res.data);
          console.log("autopay data", res.data);
          toast.success("Your payment mode has been changed");
        } else {
          toast.error(res.message);
        }

      })
  }
  const getAccountId = async () => {
    await adminService.getAll(`misc/billing-company-detail?tenantId=${tenantId}`).then(
      (res) => {
        if (res.status === "Success") {
          //setDashboard(res.data);
          console.log("connect account", res.data.stripeAccountId);
          setAccountId(res?.data?.stripeAccountId);
        } else {
          //toast.error(res.message);
        }
        //setloadings(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const defaultCard = async (p, e) => {
    setLoader(true);
    console.log("paymentid", p, e);
    setSelected(e.target.name);
    //default payment id
    const postdata = {
      "invoice_settings[default_payment_method]": p
    };
    const rawData = new URLSearchParams(Object.keys(postdata).map(key => [key, postdata[key]]));
    console.log(rawData.toString());
    const defaultCardSet = await fetch("https://api.stripe.com/v1/customers/" + customerId, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
        'Stripe-Account': accountId,
      },
      body: rawData,
    }).then(response => {
      response.json()
    })
    console.log("defaultCardSet", defaultCardSet);
    const retriveCustomer = await fetch("https://api.stripe.com/v1/customers/" + customerId, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
        'Stripe-Account': accountId,
      },
    }).then(response => {
      setLoader(false);
      return response.json()

    })
    console.log("retriveCustomer", retriveCustomer?.invoice_settings?.default_payment_method);
    setPaymentId(retriveCustomer?.invoice_settings?.default_payment_method);
  }
  //console.log("cardlist lenght", cardList.length);
  const deleteCard = async (p) => {
    setLoader(true);
    if (p === paymentId || cardList?.length <= 1) {
      if (cardList?.length <= 1) {
        toast.error("Please Add another card and make it default");
      } else if(p === paymentId){
        toast.error("Please make another card as default");
      }
      setLoader(false);
    } else {
      const detachPaymnet = await fetch("https://api.stripe.com/v1/payment_methods/" + p + "/detach", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content'p-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
          'Stripe-Account': accountId,
        },
      }).then(response => {
        setLoader(false);
        return response.json()
      }
      )
      console.log("detachPaymnet" + JSON.stringify(detachPaymnet), p, detachPaymnet);
    }
    const results = await fetch("https://api.stripe.com/v1/customers/" + customerId + "/payment_methods?type=card", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content'p-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
        'Stripe-Account': accountId,
      },

      // body  :Object.keys(genCard)
      // .map(key => key + '=' + genCard[key])
      // .join('&'),
    }).then(response => response.json())
    //console.log("results card list" + JSON.stringify(results));
    setCardList(results.data)

  }
  return (
    <div className="col-md-10 no-padding">
      <div className="no-padding dash-billing-info">
        <div className="custom-table-view">

          <div class="row mb-10">
            <div class="col-md-6">
              <div class="text-left header-title">Card List</div>
            </div>
            <div class="col-md-6 text-right" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}><span style={{ display: "inline-flex" }}><input type="checkbox" role="switch" name="autoPay" style={{ marginRight: "5px" }} onChange={(e) => handleAutoPay(e)} checked={autoPay ? true : autoPay} /><b style={{ marginRight: "25px" }}>AutoPay</b></span>
              <Link className="btn btn-primary custom-btn-green custom-btn-green-auto" to="/card" style={{ fontSize: "1rem" }}><img
                className="pr-2"
                src="../resources/assets/plus-icon-button.svg"
                alt=""
              /> Add Card</Link>
            </div>
          </div>
          <hr />
          {loader && (
            <div className="loader-overlay">
              <div className="loader" />
            </div>
          )}
          <div className='row p-2'>
            {cardList?.length ? cardList.map((item, index) => (
              <div className='col-md-6' key={index}>
                <div className='Bankcard'>
                  <p style={{ textAlign: "right" }}>{item?.card?.brand ? <img src={cards.filter(element => element === item?.card?.brand)?.length === 0 ? '../resources/assets/card/default.png' : '../resources/assets/card/' + item?.card?.brand + ".png"} width="50" /> : ''}</p>
                  <p><b>Card Number</b> : XXXX XXXX XXXX {item?.card?.last4}</p>
                  <p><b>Expiry Date</b> : {String(item?.card?.exp_month)?.length < 2 ? '0' + item?.card?.exp_month : item?.card?.exp_month}/{String(item?.card?.exp_year).slice(-2)}</p>
                  <hr />
                  <p><b>Default Card </b><input type="radio" name={"default" + index} onChange={(index) => defaultCard(item?.id, index)} checked={paymentId === item?.id || selected === "default" + index} /></p>
                  <p><b>Delete Card </b><button onClick={(e) => deleteCard(item?.id, e)} className="cardDelete"><img src='../resources/assets/action-icon-delete.svg' alt="" /></button></p>
                </div>
              </div>
            )
            ) : <p className='pl-1'>{cardList?.error ? cardList?.error?.message : "No Card Available"}</p>
            }
          </div>

        </div>
      </div>
    </div>
  );
}

export default CardList;