import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "../admin/CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../admin/CommonComponents";
import SignDate from "../admin/SignDate";
import BasicInfo from "./dischargeSummery/BasicInfo";
import produce from "immer";
import DatePicker from "react-multi-date-picker";

function SampleEvaluation(props) {
  const profile = useSelector((state) => state.adminUser.profile);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const formRef = React.useRef(null);
  const sigCanvas = useRef({});
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [familyInfo, setFamilyInfo] = useState({});
  const [isSubmit, setIsSubmit] = useState(
    typeof props?.data?.reply === "undefined" ? false : true
  );

  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  console.log("soapNoteBillingData", soapNoteBillingData);
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  console.log("selectedFamily", props?.selectedFamily);
  const [sampleEvaluation, setsampleEvaluation] = useState({
    step1: {
      patientName:
        props?.selectedFamily?.firstName +
        " " +
        props?.selectedFamily?.lastName,
      chronologicalAge: "",
      insuranceId: "",
      dob: props?.selectedFamily?.dob
        ? moment(props?.selectedFamily?.dob).format("YYYY-MM-DD") +
        `T00:00:00.000Z`
        : "",
      therapistName: profile?.firstName
        ? profile.firstName + " " + profile.lastName
        : "",
      therapistNpi: "",
      placeOfService: "",
      drNameNpi: props?.selectedFamily?.physicianNpiNumber,
      diagnosisICD10: props?.selectedFamily?.familyMemberTherapies
        ? props?.selectedFamily?.familyMemberTherapies[0]?.diagnosis[0]?.code
        : "",
      therapyDiagnosisICD10: "",
      evaluationDate: "",
    },
    step2: {
      birthMedicalHistory: "",
      hearing: [
        {
          label: "WFL refer to audiological eval:",
          isChecked: false,
        },
        {
          label: "Unknown at this time.",
          isChecked: false,
        },
        {
          label: "Appears WFL at this time for Speech.",
          isChecked: false,
        },
        {
          label: "History of ear infections.",
          isChecked: false,
        },
        {
          label: "No Concerns reported.",
          isChecked: false,
        },
        {
          label: "PE Tubes.",
          isChecked: false,
        },
        {
          label: "Other",
          isChecked: false,
          description: "",
        },
      ],
      pragmatic: [
        {
          label: "WNL overall",
          isChecked: false,
        },
        {
          label: "Further Eval Rx",
          isChecked: false,
        },
        {
          label: "Eye contact:",
          subArray: [
            {
              label: "WNL",
              isChecked: false,
            },
            {
              label: "Brief",
              isChecked: false,
            },
            {
              label: "None",
              isChecked: false,
            },
            {
              label: "Joint Attention",
              isChecked: false,
            },
          ],
        },
        {
          label: "Comm. Intent",
          subArray: [
            {
              label: "Yes",
              isChecked: false,
            },
          ],
        },
        {
          label: "Imitations (verbal/nonverbal a topic",
          isChecked: false,
        },
        {
          label: "Initiates a topic",
          isChecked: false,
        },
        {
          label: "Maintains topic",
          isChecked: false,
        },
        {
          label: "Variety of topics",
          isChecked: false,
        },
        {
          label: "Other",
          description: "",
          isChecked: false,
        },
      ],
      primaryLanguage: "",
      testAdministered: "",
      skillPresent: "",
      skillAcquire: "",
    },
    step3: {
      speechMechanism: {
        structureFunctionArear: {
          label: "Structures/Function apear WFL",
          isChecked: false,
        },
        lipsRom: {
          protrusion: "",
          retraction: "",
          rounding: "",
        },
        openMouthPosture: "",
        tongue: {
          drooling: "",
          jawRomWFL: "",
          asymmetry: "",
          palate: [
            { label: "WFL", isChecked: false },
            { label: "X", isChecked: false },
            { label: "Other", description: "", isChecked: false },
          ],
        },
        lateralization: "",
        elevation: "",
        depression: "",
        protrusion: { label: "WFL", isChecked: false },
        other: { label: "Other", isChecked: false, description: "" },
      },
      speech: [
        {
          label: "WFL",
          isChecked: false,
        },
        {
          label: "S/S of apraxia observed",
          isChecked: false,
        },
        {
          label: "Other",
          isChecked: false,
          description: "",
        },
      ],
      oralMotorFeeding: [
        {
          label: "WNL",
          isChecked: false,
        },
        {
          label: "Other",
          isChecked: false,
          description: "",
        },
      ],
      standardScore: "",
      testAdministered: "",
      testAgeEquivalent: "",
    },
    step4: {
      finalPosition: "",
      fluency: [
        {
          label: "WNL/Fluent",
          isChecked: false,
        },
        {
          label: "Other",
          description: "",
          isChecked: false,
        },
      ],
      impression: "",
      therapistSign: "",
      therapistSignDate: "",
      therapistName: "",
      phonologicalProcess: "",
      initialPosition: "",
      medicalPosition: "",
      voiceRespiration: [
        {
          label: "WFL overall",
          isChecked: false,
        },
        {
          label: "Quality",
          subArray: [
            {
              label: "Hoarse",
              isChecked: false,
            },
            {
              label: "Breathy",
              isChecked: false,
            },
            {
              label: "Strained",
              isChecked: false,
            },
            {
              label: "Wet/gurgle",
              isChecked: false,
            },
          ],
        },
        {
          label: "Resonance:",
          subArray: [
            {
              //
              label: "Hyper",
              isChecked: false,
            },
            {
              label: "Hypo",
              isChecked: false,
            },
            {
              label: "Fluctuation",
              isChecked: false,
            },
          ],
        },
        {
          label: "Intensity:",
          subArray: [
            {
              label: "Soft",
              isChecked: false,
            },
            {
              label: "Loud",
              isChecked: false,
            },
            {
              label: "Fluctuating",
              isChecked: false,
            },
          ],
        },
        {
          label: "Other",
          isChecked: false,
          description: "",
        },
      ],
    },
  });

  const [pageResult, setpageResult] = useState({
    adminSign: "",
    adminSignDate: "",
    reply: false,
    replyNeeded: false,
    sentBy: props.inputs?.sentBy ? props.inputs.sentBy : "",
    sentTo: props.inputs?.sentTo ? props.inputs.sentTo : "",
    therapistId: profile?.therapistId ? profile?.therapistId : null,
    witnessSign: "",
    witnessSignDate: "",
    // consent1: true,
    // consent2: true,
    // consent3: true,
    // consent4: true,
    // consent5: true,
    formId: 19,
    familyId: props?.inputs?.familyId ? props?.inputs?.familyId : null,
    familyMemberId: props?.selectedFamily?.id
      ? props?.selectedFamily?.id
      : null,
    familyMemberName: "child one",
    familySign: "",
    familySignDate: "",
    interpreterId: null,
    interpreterSign: "",
    interpreterSignDate: "",
  });

  const positionInfo = (e) => {
    const { name, value } = e.target;
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step4[name] = value;
      })
    );
  };
  const articulationInfo = (e) => {
    const { name, value } = e.target;
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3[name] = value;
      })
    );
  };
  const postureInfo = (e) => {
    const { name, value } = e.target;
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3.speechMechanism[name] = value;
      })
    );
  };
  const tongueInfo = (e) => {
    const { name, value } = e.target;
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3.speechMechanism.tongue[name] = value;
      })
    );
  };
  const updateInfo = (e) => {
    const { name, value } = e.target;
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step2[name] = value;
      })
    );
  };
  const speechInfo = (e) => {
    const { name, value } = e.target;
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3.speechMechanism.lipsRom[name] = value;
      })
    );
  };
  const changeInfo = (e) => {
    const { name, value } = e.target;
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step1[name] = value;
      })
    );
  };
  const setEvolutionDate = (e) => {
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step1["evaluationDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setpageResult({
      ...pageResult,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step4["therapistSignDate"] = moment(currentDateTime)
          .local()
          .format("MM-DD-YYYY hh:mm a");
      })
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };
  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setpageResult({
              ...pageResult,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setsampleEvaluation(
              produce((draftState) => {
                draftState.step4["therapistSignDate"] = moment(currentDateTime)
                  .local()
                  .format("MM-DD-YYYY hh:mm a");
                draftState.step4["therapistSign"] = res.data.signatureKey;
              })
            );
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    console.log("html", html);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "EvaluationForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const structureinfo = (e, indx) => {
    const newArray = {
      ...sampleEvaluation.step3.speechMechanism.structureFunctionArear,
    };

    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3.speechMechanism.structureFunctionArear.isChecked =
          !newArray.isChecked;
      })
    );
  };
  const updatehearingInfo = (e, indx, desc = "") => {
    const { name, value, checked } = e.target;
    const newArray = [...sampleEvaluation.step2.hearing];
    if (desc) {
      newArray[6] = {
        ...newArray[6],
        description: value,
      };
    } else {
      newArray[indx] = {
        ...newArray[indx],
        isChecked: !newArray[indx].isChecked,
      };
    }

    setsampleEvaluation(
      produce((draftState) => {
        draftState.step2.hearing = newArray;
      })
    );
  };

  const pragmaticInfo = (e, indx, desc = "") => {
    const { name, value, checked } = e.target;
    const newArray = [...sampleEvaluation.step2.pragmatic];

    if (desc) {
      newArray[8] = {
        ...newArray[8],
        description: value,
      };
    } else {
      newArray[indx] = {
        ...newArray[indx],
        isChecked: !newArray[indx].isChecked,
      };
    }
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step2.pragmatic = newArray;
      })
    );
  };
  const motorInfo = (e, indx, desc = "") => {
    const { name, value, checked } = e.target;
    const newArray = [...sampleEvaluation.step3.speech];

    if (desc) {
      newArray[2] = {
        ...newArray[2],
        description: value,
      };
    } else {
      newArray[indx] = {
        ...newArray[indx],
        isChecked: !newArray[indx].isChecked,
      };
    }

    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3.speech = newArray;
      })
    );
  };
  const protrusionInfo = (e) => {
    const newArray = { ...sampleEvaluation.step3.speechMechanism.protrusion };

    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3.speechMechanism.protrusion.isChecked =
          !newArray.isChecked;
      })
    );
  };
  const oralInfo = (e, indx, desc = "") => {
    const { name, value, checked } = e.target;
    const newArray = [...sampleEvaluation.step3.oralMotorFeeding];

    if (desc) {
      newArray[1] = {
        ...newArray[1],
        description: value,
      };
    } else {
      newArray[indx] = {
        ...newArray[indx],
        isChecked: !newArray[indx].isChecked,
      };
    }
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3.oralMotorFeeding = newArray;
      })
    );
  };
  const seperateInfo = (e, indx) => {
    const newArray = [...sampleEvaluation.step2.pragmatic[2].subArray];
    newArray[indx] = {
      ...newArray[indx],
      isChecked: !newArray[indx].isChecked,
    };
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step2.pragmatic[2].subArray = newArray;
      })
    );
  };
  const intentInfo = (e, indx) => {
    const newArray = [...sampleEvaluation.step2.pragmatic[3].subArray];
    newArray[indx] = {
      ...newArray[indx],
      isChecked: !newArray[indx].isChecked,
    };
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step2.pragmatic[3].subArray = newArray;
      })
    );
  };
  const palateInfo = (e, indx, desc = "") => {
    const { name, value, checked } = e.target;
    const newArray = [...sampleEvaluation.step3.speechMechanism.tongue.palate];

    if (desc) {
      newArray[2] = {
        ...newArray[2],
        description: value,
      };
    } else {
      newArray[indx] = {
        ...newArray[indx],
        isChecked: !newArray[indx].isChecked,
      };
    }

    setsampleEvaluation(
      produce((draftState) => {
        draftState.step3.speechMechanism.tongue.palate = newArray;
      })
    );
  };
  const intensityInfo = (e, indx) => {
    const newArray = [...sampleEvaluation.step4.voiceRespiration[3].subArray];
    newArray[indx] = {
      ...newArray[indx],
      isChecked: !newArray[indx].isChecked,
    };
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step4.voiceRespiration[3].subArray = newArray;
      })
    );
  };

  const resonanceInfo = (e, indx) => {
    const newArray = [...sampleEvaluation.step4.voiceRespiration[2].subArray];
    newArray[indx] = {
      ...newArray[indx],
      isChecked: !newArray[indx].isChecked,
    };
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step4.voiceRespiration[2].subArray = newArray;
      })
    );
  };

  const qualityInfo = (e, indx) => {
    const newArray = [...sampleEvaluation.step4.voiceRespiration[1].subArray];
    newArray[indx] = {
      ...newArray[indx],
      isChecked: !newArray[indx].isChecked,
    };
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step4.voiceRespiration[1].subArray = newArray;
      })
    );
  };
  const wflInfo = (e, indx, desc) => {
    const { name, value, checked } = e.target;
    const newArray = [...sampleEvaluation.step4.voiceRespiration];
    if (desc) {
      newArray[4] = {
        ...newArray[4],
        description: value,
      };
    } else {
      newArray[indx] = {
        ...newArray[indx],
        isChecked: !newArray[indx].isChecked,
      };
    }

    setsampleEvaluation(
      produce((draftState) => {
        draftState.step4.voiceRespiration = newArray;
      })
    );
  };

  const fluencyInfo = (e, indx, desc = "") => {
    const { name, value, checked } = e.target;
    const newArray = [...sampleEvaluation.step4.fluency];

    if (desc) {
      newArray[indx] = {
        ...newArray[indx],
        description: value,
      };
    } else {
      newArray[indx] = {
        ...newArray[indx],
        isChecked: !newArray[indx].isChecked,
      };
    }

    setsampleEvaluation(
      produce((draftState) => {
        draftState.step4.fluency = newArray;
      })
    );
  };
  const relValue =
    typeof props?.data?.reply === "undefined"
      ? ""
      : JSON.parse(props?.data?.jsonFormattedForm);

  const onSubmitFamilyForm = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append(
      "formCreationForm",
      new Blob(
        [
          JSON.stringify({
            ...pageResult,
            jsonFormattedForm: JSON.stringify({ sampleEvaluation }),
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((response) => {
        setIsLoading(false);
        setOpen(false);
        toast.success("Form Submitted successfully");
        props.handleBack("reload");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };
  return (
    <>
      <>
        <div
          className={`col-md-${props?.from ? 12 : 10}`}
          id="my-code"
          ref={formRef}
        >
          {" "}
          <div
            style={{
              position: "absolute",
              top: 0,
              right: "100px",
            }}
          >
            {!props?.data?.replyNeeded && props?.data?.id ? (
              <>
                <HeaderDownloadButtons
                  isSubmit={isSubmit}
                  startDownload={startDownload}
                  setIsSubmit={setIsSubmit}
                />
              </>
            ) : (
              props?.data?.therapistSignDate && (
                <HeaderDownloadButtons
                  isSubmit={isSubmit}
                  startDownload={startDownload}
                  setIsSubmit={setIsSubmit}
                />
              )
            )}
          </div>
          <div className="card-lg">
            <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
              <FormHeader logoUrl={logoUrl} title="EVALUATION TEMPLATE" />
              <div style={{ textAlign: "center" }}>
                <label>SPEECH/LANGUAGE/SWALLOWING EVALUATION</label>
              </div>
              <hr />
              <br />

              <div className="card-lg__content">
                <BasicInfo
                  isSubmit={isSubmit}
                  sampleEvaluation={sampleEvaluation}
                  changeInfo={changeInfo}
                  relValue={relValue}
                  setsampleEvaluation={setsampleEvaluation}
                />
                <br />
                <label style={{ width: "auto", paddingTop: "10px" }}>
                  <b>EVALUATION DATE :&nbsp;</b>
                </label>
                {isSubmit ? (
                  relValue?.sampleEvaluation?.step1?.evaluationDate.trim() &&
                  moment(sampleEvaluation.evaluationDate).format("MM/DD/YYYY")
                ) : (
                  <>
                    <div className="form-date-input">
                      <DatePicker
                        multiple={false}
                        value={sampleEvaluation?.step1?.evaluationDate}
                        onChange={setEvolutionDate}
                        name="evaluationDate"
                        format="MM-DD-YYYY"
                        editable={false}
                      />
                    </div>
                    {/* <input
                    // ref={register({ required: true })}
                    type="date"
                    name="evaluationDate"
                    placeholder="Evolution Date"
                    className={`form-control form-input form-input-w-120px`}
                    defaultValue={sampleEvaluation?.step1?.evaluationDate}
                    onChange={(e) => changeInfo(e)}
                  /> */}
                  </>
                )}
                <p style={{ width: "100%", paddingTop: "10px" }}>
                  <b style={{ textDecoration: "underline" }}>
                    Birth/Medical History:
                  </b>
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step2?.birthMedicalHistory
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      placeholder=""
                      name="birthMedicalHistory"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => updateInfo(e)}
                    />
                  )}
                </p>
                <b style={{ textDecoration: "underline", paddingTop: "20px" }}>
                  Hearing:
                </b>
                <br />
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",

                    paddingLeft: "60px",
                  }}
                >
                  <div
                    style={{ display: "block", width: "50%", float: "left" }}
                  >
                    <p style={{ width: "100%" }}>
                    {isSubmit ? (
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="1"
                          defaultValue={""}
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[0]
                              ?.isChecked
                          }
                          disabled={relValue?.sampleEvaluation?.step2?.hearing[0]
                            ?.isChecked ? true : true}
                          onChange={(e) => updatehearingInfo(e, 0)}
                        />

                        <label> WFL refer to audiological eval </label>
                      </>
                    ):(
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="1"
                          defaultValue={""}
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[0]
                              ?.isChecked
                          }
                          onChange={(e) => updatehearingInfo(e, 0)}
                        />

                        <label> WFL refer to audiological eval </label>
                      </>
                    )}
                    </p>
                    <p style={{ width: "100%" }}>
                    {isSubmit ? (
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="2"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[1]
                              ?.isChecked
                          }
                          defaultValue={""}
                          disabled={relValue?.sampleEvaluation?.step2?.hearing[1]
                            ?.isChecked ? true : true}
                          onChange={(e) => updatehearingInfo(e, 1)}
                        />
                        Unknown at this time{" "}
                      </>):(<>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="2"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[1]
                              ?.isChecked
                          }
                          defaultValue={""}
                          onChange={(e) => updatehearingInfo(e, 1)}
                        />
                        Unknown at this time{" "}
                      </>)}
                    </p>
                    <p style={{ width: "100%" }}>
                    {isSubmit ? (
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="3"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[2]
                              ?.isChecked
                          }
                          defaultValue={""}
                          disabled={relValue?.sampleEvaluation?.step2?.hearing[2]
                            ?.isChecked ? true : true}
                          onChange={(e) => updatehearingInfo(e, 2)}
                        />
                        Appears WFL at this time for Speech{" "}
                      </>
                    ):(
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="3"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[2]
                              ?.isChecked
                          }
                          defaultValue={""}
                          onChange={(e) => updatehearingInfo(e, 2)}
                        />
                        Appears WFL at this time for Speech{" "}
                      </>
                    )}
                    </p>
                    <p style={{ width: "100%" }}>
                    {isSubmit ? (
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="4"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[3]
                              ?.isChecked
                          }
                          defaultValue={""}
                          disabled={relValue?.sampleEvaluation?.step2?.hearing[3]
                            ?.isChecked ? true : true}
                          onChange={(e) => updatehearingInfo(e, 3)}
                        />
                        History of ear infections{" "}
                      </>):(<>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="4"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[3]
                              ?.isChecked
                          }
                          defaultValue={""}
                          onChange={(e) => updatehearingInfo(e, 3)}
                        />
                        History of ear infections{" "}
                      </>)}
                    </p>
                    <p style={{ width: "100%" }}>
                    {isSubmit ? (
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="5"
                          defaultValue={""}
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[4]
                              ?.isChecked
                          }
                          disabled={relValue?.sampleEvaluation?.step2?.hearing[4]
                            ?.isChecked ? true : true}
                          onChange={(e) => updatehearingInfo(e, 4)}
                        />
                        No Concerns reported{" "}
                      </>):(<>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="5"
                          defaultValue={""}
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[4]
                              ?.isChecked
                          }
                          onChange={(e) => updatehearingInfo(e, 4)}
                        />
                        No Concerns reported{" "}
                      </>)}
                    </p>
                  </div>
                  <div
                    style={{ display: "block", width: "50%", float: "left" }}
                  >
                    <p style={{ width: "100%" }}>
                    {isSubmit ? (
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="6"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[5]
                              ?.isChecked
                          }
                          defaultValue={""}
                          disabled={relValue?.sampleEvaluation?.step2?.hearing[5]
                            ?.isChecked ? true : true}
                          onChange={(e) => updatehearingInfo(e, 5)}
                        />
                        PE Tubes
                      </>):(<>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="6"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[5]
                              ?.isChecked
                          }
                          defaultValue={""}
                          onChange={(e) => updatehearingInfo(e, 5)}
                        />
                        PE Tubes
                      </>)}
                    </p>
                    <p style={{ width: "100%" }}>
                      
                      <>
                      {isSubmit ? (
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="7"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[6]
                              ?.isChecked
                          }
                          defaultValue={""}
                          disabled={relValue?.sampleEvaluation?.step2?.hearing[6]
                            ?.isChecked ? true : true}
                          onChange={(e) => updatehearingInfo(e, 6)}
                        />):(<input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="7"
                          checked={
                            relValue?.sampleEvaluation?.step2?.hearing[6]
                              ?.isChecked
                          }
                          defaultValue={""}
                          onChange={(e) => updatehearingInfo(e, 6)}
                        />)}
                        Other{" "}
                        {isSubmit ? (
                          relValue?.sampleEvaluation?.step2?.hearing[6]
                            ?.description
                        ) : (
                          <input
                            type="text"
                            placeholder=""
                            name="description"
                            defaultValue={""}
                            onChange={(e) => updatehearingInfo(e, 6, "desc")}
                          />
                        )}
                      </>
                    </p>
                  </div>
                </div>
                <br />
                <p style={{ paddingTop: "10px", width: "100%" }}>
                  <b>Other:</b> The family does not report any concerns
                  regarding her hearing with the exception of having had PE
                  tubes placed recently.
                </p>
                <b style={{ textDecoration: "underline", paddingTop: "10px" }}>
                  Language:
                </b>
                <br />
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    // paddingTop: "10px",
                  }}
                >
                  <div
                    style={{ display: "block", width: "40%", float: "left" }}
                  >
                    <p style={{ width: "100%" }}>
                      <>
                        Primary Language(s) in home:&nbsp;
                        {isSubmit ? (
                          relValue?.sampleEvaluation?.step2?.primaryLanguage
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            type="text"
                            placeholder="  "
                            name="primaryLanguage"
                            className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={""}
                            onChange={(e) => updateInfo(e)}
                          />
                        )}
                      </>
                    </p>
                  </div>
                  <div
                    style={{ display: "block", width: "40%", float: "left" }}
                  >
                    <p style={{ width: "100%" }}>
                      <>
                        Test Administered:
                        {isSubmit ? (
                          relValue?.sampleEvaluation?.step2?.testAdministered
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            type="text"
                            placeholder="  "
                            name="testAdministered"
                            className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={""}
                            onChange={(e) => updateInfo(e)}
                          />
                        )}
                      </>
                    </p>
                  </div>
                </div>
                <br />
                <b style={{ textDecoration: "underline", paddingTop: "10px" }}>
                  Skills Present:
                </b>
                {isSubmit ? (
                  relValue?.sampleEvaluation?.step2?.skillPresent
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    placeholder="  "
                    name="skillPresent"
                    className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                      }`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <b style={{ textDecoration: "underline", paddingTop: "10px" }}>
                  Skills to acquire:
                </b>
                {isSubmit ? (
                  relValue?.sampleEvaluation?.step2?.skillAcquire
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    placeholder="  "
                    name="skillAcquire"
                    className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                      }`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <br />
                <b style={{ textDecoration: "underline", paddingTop: "10px" }}>
                  Pragmatic/Social:
                </b>
                <br />
                <br />
                <p
                  className="ml-28"
                  style={{ marginLeft: "7px", width: "100%" }}
                >
                  {isSubmit ? (
                    <>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="1"
                    defaultValue={""}
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[0]?.isChecked
                    }
                    disabled={relValue?.sampleEvaluation?.step2?.pragmatic[0]?.isChecked ? true : true}
                    onChange={(e) => pragmaticInfo(e, 0)}
                  />
                  <label style={{ marginRight: "28px" }}>WNL overall</label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="2"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[1]?.isChecked
                    }
                    disabled={relValue?.sampleEvaluation?.step2?.pragmatic[1]?.isChecked ? true : true}
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 1)}
                  />
                  Further Eval Rx</>):(<>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="1"
                    defaultValue={""}
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[0]?.isChecked
                    }
                    onChange={(e) => pragmaticInfo(e, 0)}
                  />
                  <label style={{ marginRight: "28px" }}>WNL overall</label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="2"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[1]?.isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 1)}
                  />
                  Further Eval Rx</>)}
                </p>
                <p
                  style={{
                    paddingTop: "10px",
                    marginRight: "28px",
                    width: "100%",
                  }}
                >
                  {isSubmit ? (
                    <>
                  <label style={{ marginRight: "28px" }}>Eye contact:</label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="3"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[2]
                        ?.subArray[0].isChecked
                    }
                    defaultValue={""}
                    disabled={relValue?.sampleEvaluation?.step2?.pragmatic[2]
                      ?.subArray[0].isChecked ? true : true}
                    onChange={(e) => seperateInfo(e, 0)}
                  />
                  <label style={{ marginRight: "28px" }}> WNL </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="4"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[2]
                        ?.subArray[1].isChecked
                    }
                    defaultValue={""}
                    disabled={relValue?.sampleEvaluation?.step2?.pragmatic[2]
                      ?.subArray[1].isChecked ? true : true}
                    onChange={(e) => seperateInfo(e, 1)}
                  />
                  <label style={{ marginRight: "28px" }}> Brief </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="5"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[2]
                        ?.subArray[2].isChecked
                    }
                    disabled={relValue?.sampleEvaluation?.step2?.pragmatic[2]
                      ?.subArray[2].isChecked ? true : true }
                    defaultValue={""}
                    onChange={(e) => seperateInfo(e, 2)}
                  />
                  <label style={{ marginRight: "28px" }}>None: </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="6"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[2]
                        ?.subArray[3].isChecked
                    }
                    disabled={relValue?.sampleEvaluation?.step2?.pragmatic[2]
                      ?.subArray[3].isChecked ? true : true}
                    defaultValue={""}
                    onChange={(e) => seperateInfo(e, 3)}
                  />
                  <label style={{ paddingRight: "18px" }}>
                    Joint Attention{" "}
                  </label></>):(<>
                  <label style={{ marginRight: "28px" }}>Eye contact:</label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="3"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[2]
                        ?.subArray[0].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => seperateInfo(e, 0)}
                  />
                  <label style={{ marginRight: "28px" }}> WNL </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="4"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[2]
                        ?.subArray[1].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => seperateInfo(e, 1)}
                  />
                  <label style={{ marginRight: "28px" }}> Brief </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="5"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[2]
                        ?.subArray[2].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => seperateInfo(e, 2)}
                  />
                  <label style={{ marginRight: "28px" }}>None: </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="6"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[2]
                        ?.subArray[3].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => seperateInfo(e, 3)}
                  />
                  <label style={{ paddingRight: "18px" }}>
                    Joint Attention{" "}
                  </label></>)}
                </p>
                <p
                  style={{
                    paddingTop: "10px",
                    paddingRight: "18px",
                    width: "100%",
                  }}
                >
                  <label style={{ marginRight: "28px" }}>
                    {" "}
                    Comm. Intent:{"  "}
                  </label>
                  {isSubmit ? (<input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="7"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[3]
                        ?.subArray[0].isChecked
                    }
                    disabled={relValue?.sampleEvaluation?.step2?.pragmatic[3]
                      ?.subArray[0].isChecked ? true : true}
                    defaultValue={""}
                    onChange={(e) => intentInfo(e, 0)}
                  />):(<input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="7"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[3]
                        ?.subArray[0].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => intentInfo(e, 0)}
                  />)}
                  Yes{" "}
                </p>
                <p
                  className="ml-28"
                  style={{ marginLeft: "7px", width: "100%" }}
                >
                  {"  "}
                  {isSubmit ? (
                    <>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="8"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[4].isChecked
                    }
                    disabled
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 4)}
                  />{" "}
                  <label
                    className="mr-20"
                    style={{ paddingRight: "10px", marginRight: "10px" }}
                  >
                    {" "}
                    Imitations (verbal/nonverbal){" "}
                  </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="9"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[5].isChecked
                    }
                    disabled
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 5)}
                  />{" "}
                  <label
                    className="mr-20"
                    style={{ paddingRight: "10px", marginRight: "10px" }}
                  >
                    Initiates a topic{" "}
                  </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="10"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[6].isChecked
                    }
                    disabled
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 6)}
                  />{" "}
                  <label
                    className="mr-20"
                    style={{ paddingRight: "10px", marginRight: "10px" }}
                  >
                    Maintains topic{" "}
                  </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="11"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[7].isChecked
                    }
                    disabled
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 7)}
                  />{" "}
                  <label style={{ paddingRight: "18px" }}>
                    Variety of topics
                  </label>
                  </>):(<>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="8"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[4].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 4)}
                  />{" "}
                  <label
                    className="mr-20"
                    style={{ paddingRight: "10px", marginRight: "10px" }}
                  >
                    {" "}
                    Imitations (verbal/nonverbal){" "}
                  </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="9"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[5].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 5)}
                  />{" "}
                  <label
                    className="mr-20"
                    style={{ paddingRight: "10px", marginRight: "10px" }}
                  >
                    Initiates a topic{" "}
                  </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="10"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[6].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 6)}
                  />{" "}
                  <label
                    className="mr-20"
                    style={{ paddingRight: "10px", marginRight: "10px" }}
                  >
                    Maintains topic{" "}
                  </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="11"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[7].isChecked
                    }
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 7)}
                  />{" "}
                  <label style={{ paddingRight: "18px" }}>
                    Variety of topics
                  </label>
                  </>)}
                </p>
                <p
                  className="ml-28"
                  style={{ marginLeft: "7px", width: "100%" }}
                >
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="12"
                    checked={
                      relValue?.sampleEvaluation?.step2?.pragmatic[8].isChecked
                    }
                    disabled={isSubmit ? true : false}
                    defaultValue={""}
                    onChange={(e) => pragmaticInfo(e, 8)}
                  />
                  <label style={{ paddingRight: "18px" }}>
                    <b>Other: </b>
                  </label>
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step2?.pragmatic[8]?.description
                  ) : (
                    <input
                      type="text"
                      placeholder=""
                      name="description"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => pragmaticInfo(e, 8, "desc")}
                    />
                  )}
                </p>
                <br />
                <b style={{ textDecoration: "underline", paddingTop: "10px" }}>
                  Oral Motor:{" "}
                </b>
                <br />
                <br />
                <b style={{ textDecoration: "underline", paddingTop: "10px" }}>
                  Speech Mechanism/Oral Musculature{" "}
                </b>
                <br />
                <br />
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    clear:"both"
                  }}
                >
                  <div style={{ display: "block", width: "40%", float: "left" }}>
                    <p className="ml-20" style={{ width: "100%" }}>
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="structure"
                          checked={
                            relValue?.sampleEvaluation?.step3?.speechMechanism
                              ?.structureFunctionArear.isChecked
                          }
                          disabled={isSubmit ? true : false}
                          defaultValue={""}
                          onChange={(e) => structureinfo(e, 0)}
                        />{" "}
                        Structures/Function appear WFL{" "}
                      </>
                    </p>
                  </div>
                  <div style={{ display: "block", width: "59%", float: "right" }}>
                    <p style={{ width: "100%", wordBreak: "break-word" }}>
                      <>
                        Lips ROM: Protrusion{" "}
                        {isSubmit ? (
                          <div style={{ width: "100%", wordBreak: "break-all",paddingRight:"10px" }}>{relValue?.sampleEvaluation?.step3
                            ?.speechMechanism?.lipsRom?.protrusion}</div>
                        ) : (
                          <input
                            type="text"
                            placeholder="  "
                            name="protrusion"
                            className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={""}
                            onChange={(e) => speechInfo(e, 0)}
                          />
                        )}
                      </>
                    </p>
                    <p style={{ paddingLeft: 68, width: "100%", wordBreak: "break-all" }}>
                      <>
                        Retraction:{" "}
                        {isSubmit ? (
                          <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3
                            ?.speechMechanism?.lipsRom?.retraction}</div>
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            type="text"
                            placeholder="  "
                            name="retraction"
                            className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={""}
                            onChange={(e) => speechInfo(e, 1)}
                          />
                        )}
                      </>
                    </p>
                    <p style={{ paddingLeft: 68, width: "100%", wordBreak: "break-all" }}>
                      <>
                        Rounding:{" "}
                        {isSubmit ? (
                          <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3
                            ?.speechMechanism?.lipsRom?.rounding}</div>
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            type="text"
                            placeholder="  "
                            name="rounding"
                            className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={""}
                            onChange={(e) => speechInfo(e, 2)}
                          />
                        )}
                      </>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    clear:"both"
                  }}
                >
                  <div style={{ display: "block", width: "40%", float: "left" }}>
                    <p style={{ width: "100%" }}>
                      Open Mouth Posture:
                      {isSubmit ? (
                        <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3?.speechMechanism
                          ?.openMouthPosture}</div>
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="openMouthPosture"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => postureInfo(e, 0)}
                        />
                      )}
                    </p>
                  </div>
                  <div style={{ display: "block", width: "59%", float: "right" }}>
                    <p style={{ paddingLeft: 87,width: "100%" }}>
                      <>
                        Tongue ROM: Drooling:{" "}
                        {isSubmit ? (
                          <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3
                            ?.speechMechanism?.tongue?.drooling}</div>
                        ) : (
                          <input
                            type="text"
                            placeholder="  "
                            name="drooling"
                            className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={""}
                            onChange={(e) => tongueInfo(e, 0)}
                          />
                        )}
                      </>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    clear:"both"
                  }}
                >
                  <div style={{ display: "block", width: "40%", float: "left" }}>
                    <p style={{ width: "100%" }}>
                      Lateralization:
                      {isSubmit ? (
                        <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3?.speechMechanism
                          ?.lateralization}</div>
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="lateralization"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => postureInfo(e, 1)}
                        />
                      )}
                    </p>
                  </div>
                  <div style={{ display: "block", width: "59%", float: "right" }}>
                    <p style={{ paddingLeft: 87, width: "100%" }}>
                      <>
                        Jaw ROM: WFL{" "}
                        {isSubmit ? (
                          <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3
                            ?.speechMechanism?.tongue?.jawRomWFL}</div>
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            type="text"
                            placeholder="  "
                            name="jawRomWFL"
                            className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={""}
                            onChange={(e) => tongueInfo(e, 1)}
                          />
                        )}
                      </>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    clear:"both"
                  }}
                >
                  <div style={{ display: "block", width: "40%", float: "left" }}>
                    <p style={{ width: "100%" }}>
                      Elevation:{" "}
                      {isSubmit ? (
                        <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3?.speechMechanism
                          ?.elevation}</div>
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="elevation"
                          className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => postureInfo(e, 2)}
                        />
                      )}{" "}
                    </p>
                  </div>
                  <div style={{ display: "block", width: "59%", float: "right" }}>
                    <p style={{ paddingLeft: 87, width: "100%" }}>
                      <>
                        Asymmetry/Weakness
                        {isSubmit ? (
                          <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3
                            ?.speechMechanism?.tongue?.asymmetry}</div>
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            type="text"
                            placeholder="  "
                            name="asymmetry"
                            className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={""}
                            onChange={(e) => tongueInfo(e, 2)}
                          />
                        )}
                      </>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    clear:"both"
                  }}
                >
                  <div style={{ display: "block", width: "100%", float: "left" }}>
                    <p style={{ width: "100%" }}>
                      Depression:{" "}
                      {isSubmit ? (
                        <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3?.speechMechanism
                          ?.depression}</div>
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="depression"
                          className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => postureInfo(e, 3)}
                        />
                      )}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    clear:"both"
                  }}
                >
                  <div style={{ display: "block", width: "40%", float: "left" }}>
                    <p style={{ width: "100%" }}>
                      <label style={{ marginRight: "28px" }}>Protrusion:</label>
                      <input
                        type="checkbox"
                        className={`form-check-input`}
                        value="true"
                        name="43"
                        checked={
                          relValue?.sampleEvaluation?.step3?.speechMechanism
                            ?.protrusion?.isChecked
                        }
                        disabled={isSubmit ? true : false}
                        defaultValue={""}
                        onChange={(e) => protrusionInfo(e)}
                      />
                      <label style={{ marginRight: "28px" }}>WFL </label>
                    </p>
                  </div>
                  <div style={{ display: "block", width: "59%", float: "right" }}>
                    <p style={{ width: "100%" }}>
                      <>
                        Palate:
                        <p className="ml-20" style={{ width: "100%" }}>
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            value="true"
                            name="wfl"
                            checked={
                              relValue?.sampleEvaluation?.step3
                                ?.speechMechanism?.tongue?.palate[0]
                                ?.isChecked
                            }
                            disabled={isSubmit ? true : false}
                            defaultValue={""}
                            onChange={(e) => palateInfo(e, 0)}
                          />{" "}
                          <label style={{ marginRight: "28px" }}>
                            {" "}
                            WNL{" "}
                          </label>
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            value="true"
                            name="x"
                            checked={
                              relValue?.sampleEvaluation?.step3
                                ?.speechMechanism?.tongue?.palate[1]
                                ?.isChecked
                            }
                            defaultValue={""}
                            disabled={isSubmit ? true : false}
                            onChange={(e) => palateInfo(e, 1)}
                          />{" "}
                          <label style={{ marginRight: "28px" }}>
                            {" "}
                            X{" "}
                          </label>{" "}
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            value="true"
                            name="other"
                            checked={
                              relValue?.sampleEvaluation?.step3
                                ?.speechMechanism?.tongue?.palate[2]
                                ?.isChecked
                            }
                            defaultValue={""}
                            disabled={isSubmit ? true : false}
                            onChange={(e) => palateInfo(e, 2)}
                          />{" "}
                          <label>Other </label>{" "}
                          {isSubmit ? (
                            <div style={{paddingRight:"10px"}}>{relValue?.sampleEvaluation?.step3
                              ?.speechMechanism?.tongue?.palate[2]
                              ?.description}</div>
                          ) : (
                            <input
                              type="text"
                              placeholder=""
                              name="description"
                              className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                }`}
                              defaultValue={""}
                              onChange={(e) => palateInfo(e, 2, "desc")}
                            />
                          )}
                        </p>{" "}
                      </>
                    </p>
                  </div>
                  {/* <div
                    style={{ display: "block", width: "40%", float: "left" }}
                  >
                    <p className="ml-20" style={{ width: "100%" }}>
                      <>
                        <input
                          type="checkbox"
                          className={`form-check-input`}
                          value="true"
                          name="structure"
                          checked={
                            relValue?.sampleEvaluation?.step3?.speechMechanism
                              ?.structureFunctionArear.isChecked
                          }
                          defaultValue={""}
                          onChange={(e) => structureinfo(e, 0)}
                        />{" "}
                        Structures/Function appear WFL{" "}
                      </>
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <p style={{ width: "100%" }}>
                      Open Mouth Posture:
                      {isSubmit ? (
                        relValue?.sampleEvaluation?.step3?.speechMechanism
                          ?.openMouthPosture
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="openMouthPosture"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => postureInfo(e, 0)}
                        />
                      )}
                    </p>

                    <p style={{ width: "100%" }}>
                      Lateralization:
                      {isSubmit ? (
                        relValue?.sampleEvaluation?.step3?.speechMechanism
                          ?.lateralization
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="lateralization"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => postureInfo(e, 1)}
                        />
                      )}
                    </p>

                    <p style={{ width: "100%" }}>
                      Elevation:{" "}
                      {isSubmit ? (
                        relValue?.sampleEvaluation?.step3?.speechMechanism
                          ?.elevation
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="elevation"
                          className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => postureInfo(e, 2)}
                        />
                      )}{" "}
                    </p>
                    <p style={{ width: "100%" }}>
                      Depression:{" "}
                      {isSubmit ? (
                        relValue?.sampleEvaluation?.step3?.speechMechanism
                          ?.depression
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="depression"
                          className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => postureInfo(e, 3)}
                        />
                      )}
                    </p>
                    <p style={{ width: "100%" }}>
                      <label style={{ marginRight: "28px" }}>Protrusion:</label>
                      <input
                        type="checkbox"
                        className={`form-check-input`}
                        value="true"
                        name="43"
                        checked={
                          relValue?.sampleEvaluation?.step3?.speechMechanism
                            ?.protrusion?.isChecked
                        }
                        defaultValue={""}
                        onChange={(e) => protrusionInfo(e)}
                      />
                      <label style={{ marginRight: "28px" }}>WFL </label>
                    </p>
                  </div>{" "}*/}
                  {/* Lips ROM: */}
                  {/*<div
                    style={{ display: "block", width: "59%", float: "right" }}
                  >
                    <p style={{ width: "100%" }}>
                      <>
                        <div>
                          <p style={{ width: "100%", wordBreak: "break-word" }}>
                            <>
                              Lips ROM: Protrusion{" "}
                              {isSubmit ? (
                                <div style={{ paddingLeft: 68, width: "100%", wordBreak: "break-word" }}>{relValue?.sampleEvaluation?.step3
                                  ?.speechMechanism?.lipsRom?.protrusion}</div>
                              ) : (
                                <input
                                  type="text"
                                  placeholder="  "
                                  name="protrusion"
                                  className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                                    }`}
                                  defaultValue={""}
                                  onChange={(e) => speechInfo(e, 0)}
                                />
                              )}
                            </>
                          </p>
                          <p style={{ paddingLeft: 68, width: "100%", wordBreak: "break-word" }}>
                            <>
                              Retraction:{" "}
                              {isSubmit ? (
                                relValue?.sampleEvaluation?.step3
                                  ?.speechMechanism?.lipsRom?.retraction
                              ) : (
                                <input
                                  // ref={register({ required: true })}
                                  type="text"
                                  placeholder="  "
                                  name="retraction"
                                  className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                                    }`}
                                  defaultValue={""}
                                  onChange={(e) => speechInfo(e, 1)}
                                />
                              )}
                            </>
                          </p>
                          <p style={{ paddingLeft: 68, width: "100%", wordBreak: "break-word" }}>
                            <>
                              Rounding:{" "}
                              {isSubmit ? (
                                relValue?.sampleEvaluation?.step3
                                  ?.speechMechanism?.lipsRom?.rounding
                              ) : (
                                <input
                                  // ref={register({ required: true })}
                                  type="text"
                                  placeholder="  "
                                  name="rounding"
                                  className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                                    }`}
                                  defaultValue={""}
                                  onChange={(e) => speechInfo(e, 2)}
                                />
                              )}
                            </>
                          </p>
                        </div>
                      </>
                    </p>
                    <br />

                    {/* <div style={{ marginLeft: "-75px" }}>Tongue ROM:</div> */}
                    {/*<p style={{ width: "100%" }}>
                      <>
                        <div>
                          <p style={{ width: "100%" }}>
                            <>
                              Tongue ROM: Drooling:{" "}
                              {isSubmit ? (
                                relValue?.sampleEvaluation?.step3
                                  ?.speechMechanism?.tongue?.drooling
                              ) : (
                                <input
                                  type="text"
                                  placeholder="  "
                                  name="drooling"
                                  className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                                    }`}
                                  defaultValue={""}
                                  onChange={(e) => tongueInfo(e, 0)}
                                />
                              )}
                            </>
                          </p>
                          <p style={{ paddingLeft: 87, width: "100%" }}>
                            <>
                              Jaw ROM: WFL{" "}
                              {isSubmit ? (
                                relValue?.sampleEvaluation?.step3
                                  ?.speechMechanism?.tongue?.jawRomWFL
                              ) : (
                                <input
                                  // ref={register({ required: true })}
                                  type="text"
                                  placeholder="  "
                                  name="jawRomWFL"
                                  className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                                    }`}
                                  defaultValue={""}
                                  onChange={(e) => tongueInfo(e, 1)}
                                />
                              )}
                            </>
                          </p>
                          <p style={{ paddingLeft: 87, width: "100%" }}>
                            <>
                              Asymmetry/Weakness
                              {isSubmit ? (
                                relValue?.sampleEvaluation?.step3
                                  ?.speechMechanism?.tongue?.asymmetry
                              ) : (
                                <input
                                  // ref={register({ required: true })}
                                  type="text"
                                  placeholder="  "
                                  name="asymmetry"
                                  className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                    }`}
                                  defaultValue={""}
                                  onChange={(e) => tongueInfo(e, 2)}
                                />
                              )}
                            </>
                          </p>
                          <p style={{ width: "100%" }}>
                            <>
                              Palate:
                              <p className="ml-20" style={{ width: "100%" }}>
                                <input
                                  type="checkbox"
                                  className={`form-check-input`}
                                  value="true"
                                  name="wfl"
                                  checked={
                                    relValue?.sampleEvaluation?.step3
                                      ?.speechMechanism?.tongue?.palate[0]
                                      ?.isChecked
                                  }
                                  defaultValue={""}
                                  onChange={(e) => palateInfo(e, 0)}
                                />{" "}
                                <label style={{ marginRight: "28px" }}>
                                  {" "}
                                  WNL{" "}
                                </label>
                                <input
                                  type="checkbox"
                                  className={`form-check-input`}
                                  value="true"
                                  name="x"
                                  checked={
                                    relValue?.sampleEvaluation?.step3
                                      ?.speechMechanism?.tongue?.palate[1]
                                      ?.isChecked
                                  }
                                  defaultValue={""}
                                  onChange={(e) => palateInfo(e, 1)}
                                />{" "}
                                <label style={{ marginRight: "28px" }}>
                                  {" "}
                                  X{" "}
                                </label>{" "}
                                <input
                                  type="checkbox"
                                  className={`form-check-input`}
                                  value="true"
                                  name="other"
                                  checked={
                                    relValue?.sampleEvaluation?.step3
                                      ?.speechMechanism?.tongue?.palate[2]
                                      ?.isChecked
                                  }
                                  defaultValue={""}
                                  onChange={(e) => palateInfo(e, 2)}
                                />{" "}
                                <label>Other </label>{" "}
                                {isSubmit ? (
                                  relValue?.sampleEvaluation?.step3
                                    ?.speechMechanism?.tongue?.palate[2]
                                    ?.description
                                ) : (
                                  <input
                                    type="text"
                                    placeholder=""
                                    name="description"
                                    className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                      }`}
                                    defaultValue={""}
                                    onChange={(e) => palateInfo(e, 2, "desc")}
                                  />
                                )}
                              </p>{" "}
                            </>
                          </p>
                        </div>
                      </>
                    </p>
                  </div> */}
                </div>
                <p style={{ width:"100%",clear:"both"}}>
                <b style={{ textDecoration: "underline", paddingTop: "10px",clear:"both" }}>
                  Oral Motor/Feeding:
                </b>
                </p>
                <br />
                <br />
                <p style={{ marginLeft: "28px" }}>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="4"
                    checked={
                      relValue?.sampleEvaluation?.step3?.oralMotorFeeding[0]
                        ?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => oralInfo(e, 0)}
                  />{" "}
                  WNL
                </p>
                <p style={{ marginLeft: "28px" }}>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="5"
                    checked={
                      relValue?.sampleEvaluation?.step3?.oralMotorFeeding[1]
                        ?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => oralInfo(e, 1)}
                  />{" "}
                  <label style={{ marginRight: "10px" }}>Other</label>
                  {isSubmit ? (
                    <p style={{width:"100%"}}>{relValue?.sampleEvaluation?.step3?.oralMotorFeeding[1]
                      ?.description}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder=""
                      name="description"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => oralInfo(e, 1, "desc")}
                    />
                  )}
                </p>{" "}
                <br /> <br />
                <b style={{ textDecoration: "underline", paddingTop: "10px" }}>
                  Speech:
                </b>
                <br />
                <p style={{ paddingTop: "10px", width: "100%" }}>
                  Motor Planning
                </p>
                <p className="ml-28" style={{ marginLeft: 8, width: "100%" }}>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="4"
                    checked={
                      relValue?.sampleEvaluation?.step3?.speech[0]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => motorInfo(e, 0)}
                  />{" "}
                  <label style={{ marginRight: "28px" }}>WFL </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="4"
                    checked={
                      relValue?.sampleEvaluation?.step3?.speech[1]?.isChecked
                    }
                    disabled={isSubmit ? true : false}
                    defaultValue={""}
                    onChange={(e) => motorInfo(e, 1)}
                  />{" "}
                  <label style={{ marginRight: "28px" }}>
                    S/S of apraxia observed{" "}
                  </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="4"
                    checked={
                      relValue?.sampleEvaluation?.step3?.speech[2]?.isChecked
                    }
                    disabled={isSubmit ? true : false}
                    defaultValue={""}
                    onChange={(e) => motorInfo(e, 2)}
                  />
                  <label style={{ paddingRight: "18px" }}>Other: </label>
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step3?.speech[2]?.description
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="description"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => motorInfo(e, 2, "desc")}
                    />
                  )}
                </p>
                <br />
                <p
                  style={{
                    textDecoration: "underline",

                    width: "100%",
                  }}
                >
                  <b>Articulation:</b>{" "}
                </p>
                <p style={{ width: "100%" }}>Test Administered:</p>
                <p style={{ width: "100%" }}>
                  Standard score of{" "}
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step3?.standardScore
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="standardScore"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => articulationInfo(e)}
                    />
                  )}{" "}
                  and a test-age equivalent of{" "}
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step3?.testAgeEquivalent
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="testAgeEquivalent"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => articulationInfo(e)}
                    />
                  )}
                </p>
                <p style={{ width: "100%" }}>
                  <b>Phonological processes observed: </b>{" "}
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step4?.phonologicalProcess
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="phonologicalProcess"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => positionInfo(e)}
                    />
                  )}{" "}
                </p>
                <p style={{ width: "100%" }}>
                  <b>Initial Position (substitutions/deletions): </b>{" "}
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step4?.initialPosition
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="initialPosition"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => positionInfo(e)}
                    />
                  )}{" "}
                </p>
                <p style={{ width: "100%" }}>
                  <b>Medial Position (substitutions/deletions): </b>{" "}
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step4?.medicalPosition
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="medicalPosition"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => positionInfo(e)}
                    />
                  )}{" "}
                </p>
                <p style={{ width: "100%" }}>
                  <b>Final Position (substitutions/deletions): </b>{" "}
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step4?.finalPosition
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="finalPosition"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => positionInfo(e)}
                    />
                  )}{" "}
                </p>
                <br />
                <p
                  style={{
                    width: "100%",
                    textDecoration: "underline",
                  }}
                >
                  <b>Voice/Respiration:</b>
                  <br />
                </p>
                <p className="ml-28" style={{ marginLeft: 8, width: "100%" }}>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="1"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[0]
                        ?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => wflInfo(e, 0)}
                  />
                  WFL overall
                </p>
                <p style={{ width: "100%" }}>
                  {" "}
                  <label style={{ paddingRight: "18px" }}>
                    Quality: &nbsp;
                  </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="21"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[1]
                        ?.subArray[0]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => qualityInfo(e, 0)}
                  />
                  <label style={{ paddingRight: "28px" }}> Hoarse </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="22"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[1]
                        ?.subArray[1]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => qualityInfo(e, 1)}
                  />
                  <label style={{ paddingRight: "28px" }}>Breathy </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="23"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[1]
                        ?.subArray[2]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => qualityInfo(e, 2)}
                  />
                  <label style={{ paddingRight: "28px" }}>Strained </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="24"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[1]
                        ?.subArray[3]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => qualityInfo(e, 3)}
                  />
                  <label style={{ paddingRight: "28px" }}>Wet/gurgle </label>
                </p>{" "}
                <p style={{ width: "100%" }}>
                  <label style={{ paddingRight: "28px" }}>Resonance: </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="32"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[2]
                        ?.subArray[0]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => resonanceInfo(e, 0)}
                  />
                  <label style={{ paddingRight: "28px" }}>Hyper </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="33"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[2]
                        ?.subArray[1]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => resonanceInfo(e, 1)}
                  />
                  <label style={{ paddingRight: "28px" }}>Hypo </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="34"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[2]
                        ?.subArray[2]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => resonanceInfo(e, 2)}
                  />
                  <label style={{ paddingRight: "28px" }}>Fluctuation</label>
                </p>
                <p style={{ marginLeft: "10px", width: "100%" }}>
                  <label style={{ marginRight: "28px" }}>Intensity: </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="41"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[3]
                        ?.subArray[0]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => intensityInfo(e, 0)}
                  />{" "}
                  <label style={{ marginRight: "28px" }}>Soft </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="42"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[3]
                        ?.subArray[1]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => intensityInfo(e, 1)}
                  />
                  <label style={{ paddingRight: "28px" }}>Loud </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="43"
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[3]
                        ?.subArray[2]?.isChecked
                    }
                    defaultValue={""}
                    disabled={isSubmit ? true : false}
                    onChange={(e) => intensityInfo(e, 2)}
                  />
                  <label style={{ paddingRight: "28px" }}>Fluctuating </label>
                  <input
                    type="checkbox"
                    className={`form-check-input`}
                    value="true"
                    name="5"
                    defaultValue={""}
                    checked={
                      relValue?.sampleEvaluation?.step4?.voiceRespiration[4]
                        ?.isChecked
                    }
                    disabled={isSubmit ? true : false}
                    onChange={(e) => wflInfo(e, 4)}
                  />
                  <label style={{ paddingRight: "8px" }}>Other </label>
                  {isSubmit ? (
                    relValue?.sampleEvaluation?.step4?.voiceRespiration[4]
                      ?.description
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="description"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => wflInfo(e, 4, "desc")}
                    />
                  )}
                </p>
                <br />
                <p style={{ marginLeft: "10px", width: "100%" }}>
                  <b>Fluency: </b>
                  <p className="ml-20" style={{ marginLeft: 8, width: "100%" }}>
                    <input
                      type="checkbox"
                      className={`form-check-input`}
                      value="true"
                      name="0"
                      checked={
                        relValue?.sampleEvaluation?.step4?.fluency[0]?.isChecked
                      }
                      defaultValue={""}
                      disabled={isSubmit ? true : false}
                      onChange={(e) => fluencyInfo(e, 0)}
                    />
                    <label style={{ paddingRight: "28px" }}>WNL/Fluent </label>
                    <input
                      type="checkbox"
                      className={`form-check-input`}
                      value="true"
                      name="1"
                      checked={
                        relValue?.sampleEvaluation?.step4?.fluency[1]?.isChecked
                      }
                      defaultValue={""}
                      disabled={isSubmit ? true : false}
                      onChange={(e) => fluencyInfo(e, 1)}
                    />
                    <label style={{ paddingRight: "18px" }}>Other</label>
                    {isSubmit ? (
                      relValue?.sampleEvaluation?.step4?.fluency[1]?.description
                    ) : (
                      <input
                        type="text"
                        placeholder="  "
                        name="description"
                        className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                          }`}
                        defaultValue={""}
                        onChange={(e) => fluencyInfo(e, 1, "desc")}
                      />
                    )}
                  </p>
                  <br />
                  <p style={{ textDecoration: "underline", width: "100%" }}>
                    <label style={{ paddingRight: "18px" }}>
                      <b>IMPRESSION: </b>{" "}
                    </label>
                  </p>

                  <p style={{ width: "100%" }}>
                    <label>Therapist’s Name : &nbsp;</label>
                    {isSubmit ? (
                      relValue?.sampleEvaluation?.step1?.therapistName
                    ) : (
                      <input
                        type="text"
                        placeholder="  "
                        name="therapistName"
                        className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                          }`}
                        defaultValue={sampleEvaluation?.step1?.therapistName}
                        onChange={(e) => positionInfo(e)}
                      />
                    )}{" "}
                  </p>

                  <SignDate
                    date={
                      sampleEvaluation.step4.therapistSignDate ||
                      relValue?.sampleEvaluation?.step4?.therapistSignDate
                    }
                    familyImageURL={
                      isSubmit ? props?.data?.therapistSignUrl : familyImageURL
                    }
                    isSubmit={isSubmit}
                    setOpen={setOpen}
                    signTitle={"Therapist"}
                    guardian="Therapist"
                    familyInfo={familyInfo}
                    isLoading={isLoading}
                    userType="therapist"
                  />
                  <DigitalSignPopup
                    modalState={open}
                    setModalState={setOpen}
                    save={save}
                    clear={clear}
                    sigCanvas={sigCanvas}
                  />
                </p>
                <br />
                <hr />
                <FormFooter
                  address={joinWithComma(
                    address1,
                    address2,
                    city,
                    country,
                    zipCode
                  )}
                />
                {!isSubmit ? (
                  <>
                    {errors.consent1 ||
                      (errors.consent2 && (
                        <p className="error-text">
                          <small>All fields are require</small>
                        </p>
                      ))}
                    {/* {signatureErr && (
                        <p className="text-red">
                          <small>{signatureErr}</small>
                        </p>
                      )} */}
                    <button
                      className="btn btn-theme-green"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                    {/* {props?.data?.replyNeeded && ( */}
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={familyInfo?.therapistSign ? false : true}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                    {/* )} */}
                  </>
                ) : (
                  <>
                    {!props?.isFormOpen && (
                      <button
                        className="btn btn-theme-green mb-10"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
}

export default SampleEvaluation;
