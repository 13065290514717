import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { adminService } from "../../../services/adminService";
import MediaReleaseForm from "./MediaReleaseForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "../FormContext";
import Pagination from "../../Pagination";
import FormTable from "./FormTable";
import HippaNoticeForm from "./HippaNoticeForm";
import CreateForm from "./CreateForm";
import MedicalInsuranceForm from "./MedicalInsuranceForm";
import CancellationForm from "./CancellationForm";
import ReleaseToCommunicateForm from "./ReleaseToCommunicateForm";
import ConsentToTreat from "./ConsentToTreat";
import ConsultationDocumentationForm from "./ConsultationDocumentationForm";
import FormList from "../../../pages/Forms/FormList";
import Acknowledgement from "../other/Acknowledgement";
import AuthToDiscoloseInfo from "../other/AuthToDiscoloseInfo";
import ParentProvider from "../other/ParentProvider";
import ChangeInCoverage from "../other/ChangeInCoverage";
import DischargeForm from "./DischargeForm";
import QuaterlyProgressForm from "./QuaterlyProgressForm";
import PocStForm from "./PocStForm";
import PocPtForm from "./PocPtForm";
import PocDaltonForm from "./PocDaltonForm";
import PocOtForm from "./PocOtForm";
import MedicoidForm from "./MedicoidForm";
import DischargeSummery from "../therapist/DischargeSummery";
import SampleEvaluation from "../therapist/SampleEvaluation";
import PaginationTabs from "../../../pages/pagination";
import PaymentPolicy from "./PaymentPolicy";

function AdminFormList() {
  const history = useNavigate();
  let user = useSelector((state) => state.adminUser.user);
  let profile = useSelector((state) => state.adminUser.profile);
  const { inputs, setInputs, signatures, setSignatures } = useFormContext();

  const [currentPage, setCurrentPage] = useState(0);
  const { register, formState: { errors }, handleSubmit, setValue } = useForm();
  const [familyList, setFamilyList] = useState([]);
  const [getPhone, setPhone] = useState([]);
  const [selectedFamily, setselectedFamily] = useState({});
  const [formNameList, setFormNameList] = useState([]);
  const [pageDetail, setPageDetail] = useState({
    totalPages: 0,
    currentPage: 0,
    pages: [],
    sortBy: "createdDate,DESC",
    perPage: 10,
  });
  const [submittedFormList, setSubmittedFormList] = useState({
    isLoading: true,
    list: [],
  });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [family, setFamily] = useState({});
  const [createForm, setCreateForm] = useState(false);
  const [userType, setUserType] = useState();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    getFamilyNameList();
    getFormNameList();
    getPhonelist();
    getSubmittedFormList(pageDetail.pages);
  }, [isFocused]);

  useEffect(() => {
    adminService.getAll(`user-profiles/user-detail`).then(
      (res) => {
        if (res?.status === "Success") {
          console.log("user-detail", res.data);
          //setDisciplines(res.data?.disciplines?.name);
          setUserType(res.data?.userType);
          // setProfile(res.data);
          //dispatch(adminAction.setProfileData(res.data));
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );
    setInputs({
      ...inputs,
      sentBy: profile?.userUniqeId,
      // sentBy: profile
      //   ? profile?.firstName + " " + profile?.lastName
      //   : user?.userDetails?.userUniqueId,
    });
  }, [profile?.firstName]);
  console.log(familyList[inputs.familyId], "FamilyListFamilyList");
  const getFamilyNameList = () => {
    adminService.getAll("families/withoutfilter").then(
      (res) => {
        if (res?.status === "Success") {
          setFamilyList(res.data);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  };
  const getPhonelist = () => {
    adminService.getAll("submitted-forms/mobile/tenant").then(
      (res) => {
        if (res?.status === "Success") {
          setPhone(res.data.phone);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  };
  console.log(getPhonelist, "getPhonelistdddddd");
  console.log(getPhone, "fgergegdd");
  const getFormNameList = () => {
    adminService.getAll("adminForms").then(
      (res) => {
        if (res?.status === "Success") {
          setFormNameList(res.data.content);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  };
  const getListApi = ({ currentPage, sortBy, perPage }) =>
    `submitted-forms?page=${currentPage == 0 ? currentPage : currentPage - 1
    }&size=${perPage}&sort=${sortBy}`;
  const getSubmittedFormList = (pageNo) => {
    adminService
      .getAll(getListApi(pageDetail))
      .then((response) => {
        setPageContent(response);
        // setSubmittedFormList({ isLoading: false, list: response.data.content });
        // setPageDetail({
        //   ...pageDetail,
        //   page: pageNo,
        //   totalpages: response.data.totalPages,
        // });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const onPaginateFetch = (number) => {
    setSubmittedFormList({
      ...submittedFormList,
      isLoading: true,
    });
    adminService
      .getAll(
        getListApi(
          {
            ...pageDetail,
            currentPage: number,
          }
        )
      )
      .then((res) => {
        setPageContent(res);
      })
      .catch((err) => {
        setSubmittedFormList({
          ...submittedFormList,
          isLoading: false,
        });
        console.log({ err });
      });
  };
  const paginate = (number) => {
    console.log("number", number);
    setPageDetail((q) => ({
      ...q,
      currentPage: number,
    }));
    onPaginateFetch(number);
  };
  const setPageContent = (res) => {
    if (res.data?.content) {
      setSubmittedFormList({
        ...submittedFormList,
        list: res.data?.content,
        isLoading: false,
      });
      setPageDetail((q) => ({
        ...q,
        totalPages: res.data?.totalPages,
        pages: new Array(res.data?.totalPages).fill().map((_i, inx) => inx),
      }));
    }
  };
  useEffect(() => {
    getSubmittedFormList(currentPage);
  }, [currentPage]);

  const handleChange = (e) => {
    console.log("form changes", e);
    const { name, value } = e.target;
    if (name === "formId") {
      // setIsFormOpen(true);
      value == 3 &&
        setInputs((inputs) => ({ ...inputs, reply: false, replyNeeded: true }));
      value == 8 &&
        setInputs((inputs) => ({ ...inputs, reply: false, replyNeeded: true }));
      value == 9 &&
        setInputs((inputs) => ({ ...inputs, reply: false, replyNeeded: true }));
      value == 17 &&
        setInputs((inputs) => ({ ...inputs, reply: false, replyNeeded: true }));
      value == 18 &&
        setInputs((inputs) => ({ ...inputs, reply: false, replyNeeded: true }));
      value == 8 ||
        value == 9 ||
        value == 17 ||
        (value == 18 &&
          setInputs((inputs) => ({
            ...inputs,
            therapistId:
              familyList[inputs.familyId]?.familyMembers[0]
                ?.familyMemberTherapies[0]?.therapist?.id,
            familyMemberId: inputs?.familyMemberId
              ? inputs?.familyMemberId
              : null,
            familyId: inputs?.familyId ? inputs?.familyId : null,
          })));
    }
    if (name === "familyMemberId") {
      const family_id = document.getElementById(value).value;
      const fmly = familyList.filter((f) => f.id == family_id)[0];
      const fmlyMember = fmly.familyMembers.filter((f) => f.id == value)[0];
      console.log("selectedFamily value", value, fmlyMember);
      setselectedFamily(fmlyMember);
      console.log(family_id, name, "family---id");
      //setselectedFamily(inputs);
      setInputs({
        ...inputs,
        familyId: family_id,
        sentTo: family_id,
        [name]: value,
      });
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  };
  // const onSubmit = () => {
  //   setIsFormOpen(true);
  // };
  const onSubmit = () => {
    let formData = new FormData();
    setIsFormOpen(false);
    // formData.append("signature", signatures);
    console.log("inputs", inputs, inputs?.familyMemberId);
    formData.append(
      "formCreationForm",
      new Blob([JSON.stringify(inputs)], {
        type: "application/json",
      })
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((response) => {
        getSubmittedFormList(pageDetail.page);
        setIsFormOpen(false);
        toast.success("Form Submitted successfully");
        setInputs({
          ...inputs,
          familyId: null,
          sentTo: null,
          familyMemberId: null,
          formId: null,
          replyNeeded: null,
          reply: null,
        });
        handleBack();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleBack = () => {
    console.log("handleback");
    setCreateForm(false);
    setIsFormOpen(false);
    setValue("familyId", null);
    setValue("sentTo", null);
    setValue("familyMemberId", null);
    setValue("formId", null);
    setValue("replyNeeded", null);
    setValue("reply", null);
    setInputs({
      ...inputs,
      familyId: null,
      sentTo: null,
      familyMemberId: null,
      formId: null,
      replyNeeded: null,
      reply: null,
    });
  };

  const showForms = (isShow = "") => {
    return (
      <>
        {inputs.formId == 1 ? (
          <ConsentToTreat
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 2 ? (
          <>
            <MedicalInsuranceForm
              from="admin"
              data={family}
              handleBack={handleBack}
              inputs={inputs}
              setIsFocused={setIsFocused}
              isFocused={isFocused}
              isFormOpen={isShow ? false : isFormOpen}
            />
          </>
        ) : inputs.formId == 3 ? (
          <HippaNoticeForm
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 20 ? (
          <PaymentPolicy
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
            selectedFamily={selectedFamily}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
          />
        ) : inputs.formId == 4 ? (
          <CancellationForm
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 5 ? (
          <ReleaseToCommunicateForm
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 6 ? (
          <MediaReleaseForm
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 8 ? (
          <Acknowledgement
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            familyList={familyList}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 9 ? (
          <AuthToDiscoloseInfo
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            familyList={familyList}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 11 ? (
          <ParentProvider
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 17 ? (
          <ChangeInCoverage
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            familyList={familyList}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 18 ? (
          <MedicoidForm
            from="admin"
            data={family}
            handleBack={handleBack}
            inputs={inputs}
            familyList={familyList}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 7 ? (
          <DischargeSummery
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 10 ? (
          <ConsultationDocumentationForm
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 12 ? (
          <QuaterlyProgressForm
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 13 ? (
          <PocStForm
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 14 ? (
          <PocPtForm
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 15 ? (
          <PocDaltonForm
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 16 ? (
          <PocOtForm
            from="admin"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
          />
        ) : inputs.formId == 19 ? (
          <>
            <SampleEvaluation
              from="admin"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
              setInputs={setInputs}
              inputs={inputs}
              onSubmitTherapistForm={onSubmit}
            />
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className="col-md-10 no-padding">
      <div className="no-padding">
        {createForm ? (
          <div className="custom-table-view">
            <div className="row mb-10">
              <div className="col-md-6">
                {!isFormOpen && (
                  <div className="text-left header-title">Create Forms</div>
                )}
              </div>
              <div className="col-md-6 text-right">
                {/* <button
                  className="btn custom-btn-green"
                  onClick={() => handleBack()}
                >
                  Back
                </button> */}
              </div>
            </div>
            {isFormOpen && showForms()}
            <CreateForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              handleChange={handleChange}
              familyList={familyList}
              formNameList={formNameList}
              setInputs={setInputs}
              inputs={inputs}
              errors={errors}
              setIsFormOpen={setIsFormOpen}
              isFormOpen={isFormOpen}
              handleBack={handleBack}
            />
          </div>
        ) : (
          <div className="custom-table-view">
            <div className="row mb-10">
              <div className="col-md-6">
                <div className="text-left header-title">Forms</div>
              </div>
              <div className="col-md-6 text-right">
                {isFormOpen ? (
                  <>
                    {/* <button
                    className="btn custom-btn-green"
                    onClick={() => setIsFormOpen(false)}
                  >
                    Back
                  </button> */}
                  </>
                ) : (
                  <button
                    className="btn custom-btn-green w-auto"
                    onClick={() => {
                      setCreateForm(true);
                      setFamily({});
                    }}
                  >
                    + Caretaker Form
                  </button>
                )}
              </div>
            </div>
            {submittedFormList.isLoading ? (
              <div className="loader" />
            ) : (
              <>
                {isFormOpen ? (
                  showForms("show")
                ) : (
                  <>
                    <FormTable
                      userType="admin"
                      list={submittedFormList.list}
                      setIsFormOpen={setIsFormOpen}
                      setFamily={setFamily}
                      setInputs={setInputs}
                      inputs={inputs}
                    />
                    {!submittedFormList.isLoading &&
                      submittedFormList.list?.length == 0 && (
                        <h5 className="text-center p-3">No Data Found</h5>
                      )}
                    <div className="row">
                      <div
                        style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
                      >
                        <div style={{ marginTop: "2px" }}>
                          <PaginationTabs
                            shareQuery={pageDetail}
                            paginate={paginate}
                          />
                          {/* <Pagination
                              totalpages={pageDetail.totalPages}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                            /> */}
                        </div>
                        {userType == "ROLE_RCE" ?
                          <button
                            className=" btn btn-success ml-3"
                            style={{ borderRadius: "0.25rem !important" }}
                            //onClick={() => setfilter("archive")}
                            onClick={() => history("/archive-admin-form-list")}
                          >
                            Archive
                          </button>
                          : ''}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {/* <FormList /> */}
    </div>
  );
}

export default AdminFormList;
