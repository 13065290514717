import React, { useState } from "react";
import { IoIosTrash, IoMdEye } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationPopup from "../../components/Admin/Popup/DeleteConfirmationPopup";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function TableItem(props) {
  const { data, confirmDeletion, setConfirmDeletion } = props;
  const history = useNavigate();
  return (
    <>
      <tr>
        <td>
          <div className="d-flex">
            <div className="logo mr-2">
              <img
                className="small-tenant-img"
                style={{ objectFit: "scale-down", borderRadius: 0,width:"40px" }}
                src={
                  data?.profilePicKey
                    ? data?.profilePicKey
                    : "../../../resources/assets/defaultprofile.png"
                }
              />
            </div>
            <div className="itemInfo" style={{ alignSelf: "center" }}>
              <p className="m-0">
                <b>{`${data?.firstName} ${data?.lastName}`}</b>
              </p>
              {/* <small state={{ opacity: ".5" }}>shortName</small> */}
            </div>
          </div>
        </td>
        <td>{data?.email}</td>
        <td>{data?.phone}</td>
        <td style={{ width: 100 }}>
          {data?.gender == "NOT_DISCLOSED"
            ? "Other"
            : data?.gender == "MALE"
            ? "Male"
            : "Female"}
        </td>
        <td style={{ width: 150 }}>
          <button
            //to={`/other-user/${data?.id}`}
            onClick={() =>
              history(`/marketing-user/${data?.id}`, {state:{ data: data }})
            }
            className="btn btn-theme-info btn-sm  mb-10"
          >
            <IoMdEye />
          </button>

          <button
            className="btn btn-theme-info btn-sm ml-10 mb-10"
            onClick={() =>
              history(`/edit-marketing-user/${data?.id}`, {state:{ data: data }})
            }
          >
            <FiEdit />
          </button>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip-3">Delete</Tooltip>}
          >
            <button
              className="btn btn-theme-info btn-sm ml-10 mb-10 "
              onClick={() =>
                setConfirmDeletion({
                  name: `${data?.firstName} ${data?.lastName}`,
                  id: data?.id,
                })
              }
            >
              <IoIosTrash className="btn-red" />
            </button>
          </OverlayTrigger>
        </td>
      </tr>
    </>
  );
}

export default TableItem;
