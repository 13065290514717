import React from "react";
import Table from "react-bootstrap/esm/Table";
import { IoIosTrash, IoMdCreate } from "react-icons/io";

function GoalsTable(props) {
  return (
    <>
      <Table id="table" className="mb-0" striped bordered hover>
        <thead>
          <tr>
            <th className="text-center font-bold">S.No</th>
            <th className="text-center font-bold">Area of Concern</th>
            <th className="text-center font-bold">{props.name} Term Description</th>
            <th className="text-center font-bold">Achieved ( % )</th>
            <th className="text-center font-bold" style={{ width: 105 }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {props?.list &&
            props?.list.length > 0 &&
            props?.list.map((goal, index) => {
              return (
                <>
                  <tr key={`${props?.name}-${index}`}>
                    <td className="text-center" key={index}>
                      {index + 1}
                    </td>

                    <td className="text-center" key={index}>
                      {goal.concern}
                    </td>
                    <td className="text-center" key={index}>
                      {goal.description}
                    </td>
                    <td className="text-center" key={index}>
                      {" "}
                      {goal.achievedPercentage}
                    </td>
                    <td className="text-center">
                      {props?.goalData?.drafted && (
                        <div className="d-flex justify-content-lg-center">
                          {
                            <button
                              className="btn btn-theme-info btn-sm mb-10"
                              type="button"
                              onClick={() => {
                                props?.setEditGoalData(goal);
                                props?.setAddLongGoals(props?.name);
                              }}
                              disabled={
                                goal?.editable === false &&
                                props?.goalData?.drafted === false
                              }
                            >
                              <IoMdCreate />
                            </button>
                          }
                          {!(goal?.editable === false) && (
                            <button
                              type="button"
                              className="btn btn-theme-info btn-sm mb-10"
                              onClick={(e) =>
                                props?.DeleteLongGoals(goal, props?.name, index)
                              }
                            >
                              <IoIosTrash className="text-light-red" />
                            </button>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </Table>
    </>
  );
}

export default GoalsTable;
