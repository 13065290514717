
export const generateMsgKey = (role, userProfileId, childId) => {
    const myRole = role?.replace('ROLE_', '')
    return [myRole, userProfileId, childId].filter(Boolean).join('-')
}

export const getAllMsgLink = (role) =>{
    switch (role) {
        case 'ROLE_FAMILY':
            return '/my-team'
            
        case 'ROLE_THERAPIST':
        case 'ROLE_INTERPRETER':
            return '/family'
    
        default:
            return "#"
    }
}