import {messageTypes} from '../types'

const initialState = {
    profiles: {
    },
    otherUsers: [],
}


const messageReducer = (state = initialState, action) => {
    const {
        type, payload
    } = action
    switch(type){
        case messageTypes.clear:
            return initialState
        
        case messageTypes.addMsgList:
            const newState = {
                ...state,
                profiles: {
                    ...state.profiles,
                    [payload.profileKey]: {
                        ...state.profiles[payload.profileKey],
                        [payload.key]: payload.messages
                    }
                }
            }
            return newState 

        case messageTypes.updateMsgList:            
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    [payload.profileKey]: {
                        [payload.key]: payload.messages
                    }
                }
            }
        
        case messageTypes.updateUsers: 
            return {
                ...state,
                otherUsers: [
                    state.otherUsers,
                    payload,
                ].flat()
            }

        default:
            return state
    }
}

export default messageReducer;