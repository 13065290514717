import React from "react";
import { IoIosSearch } from "react-icons/io";

function Search(props) {
  return (
    <>
      <div className="d-flex search-container">
        <input
          type="text"
          placeholder={`Search by ${props?.placeholder || "Name"}`}
          value={props.search}
          onChange={(e) => props.setSearch(e.target.value)}
          className="form-control mr-3"
        />
        <button
          onClick={() => props.onSearch()}
          className="btn btn-theme-green"
        >
          <IoIosSearch />
        </button>
      </div>
    </>
  );
}

export default Search;
