import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import produce from "immer";
import { adminService } from "../../../services/adminService";
import moment from "moment";

import CreateTemplate from "../admin/CreateTemplate";
import SignDate from "../admin/SignDate";
import { upperCase } from "lodash";
import {
  FormFooter,
  FormHeader,
  HeaderDownloadButtons,
} from "../admin/CommonComponents";

function AuthToDiscoloseInfo(props) {
  const jsonData = (props?.data?.jsonFormattedForm && JSON.parse(props?.data?.jsonFormattedForm)) || null;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  const formRef = React.useRef(null);
  const sigCanvas = useRef({});
  const profile = useSelector((state) => state.adminUser.profile);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [authorization, setauthorization] = useState({});
  const [valid, setValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signatureErr, setSignatureErr] = useState("");
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const [familyInfo, setFamilyInfo] = useState();

  useEffect(() => {
    setFamilyInfo({
      id: props?.data?.id ? props.data.id : null,
      familyMemberName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      familyMemberDob: props?.data?.familyMemberDob
        ? props?.data?.familyMemberDob
        : props?.data?.familyMember
          ? props.data.familyMember.dob
          : " ",
      familySign:
        props?.data?.familySignDate && props?.data?.family
          ? props?.data?.family?.userProfile?.signatureUrl
          : false,
      familyMemberId: props?.inputs?.familyMemberId
        ? props?.inputs?.familyMemberId
        : null,
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
      familyRelationship: props?.data.family
        ? props?.data?.family?.relationship
        : "",
      familyName: props?.data?.familyName ? props.data.familyName : "",
    });
    setauthorization({
      childName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      authorizationDisclosure: props?.data?.familyName
        ? props.data.familyName
        : "",
      relationship: props?.data.family
        ? props?.data?.family?.relationship
        : "",
      childDob: props?.data?.familyMemberDob
        ? props?.data?.familyMemberDob
        : props?.data?.familyMember
          ? props.data.familyMember.dob
          : " ",
      familySign:
        props?.data?.familySignDate && props?.data?.family
          ? props?.data?.family?.userProfile?.signatureUrl
          : false,
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
      streetAddress: {
        street: jsonData?.streetAddress?.street || "",
        streetName: jsonData?.streetAddress?.streetName || "",
        city: jsonData?.streetAddress?.city || "",
        state: jsonData?.streetAddress?.state || "",
        zipcode: jsonData?.streetAddress?.zipcode || "",
      },
    });
  }, [props?.data]);
  const [isSubmit, setIsSubmit] = useState(
    props?.from !== "family" || props?.data?.reply
      ? true
      : props?.data?.replyNeeded === false
        ? !props?.data.replyNeeded
        : false
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setauthorization({
      ...authorization,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };
  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("res.data.signatureUrl", res.data.signatureUrl);
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setauthorization({
              ...authorization,
              familySign: res.data.signatureKey,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const onSubmitFamilyForm = (isSubmit = false) => {
    // console.log("authorization", authorization);
    setIsLoading(true);
    if (props?.data?.replyNeeded) {
      if (familyInfo?.familySignDate) {
        let formData = new FormData();
        // formData.append("signature", familyImageURL);
        formData.append(
          "formUpdationForm",
          new Blob(
            [
              JSON.stringify({
                ...familyInfo,
                jsonFormattedForm: JSON.stringify(authorization),
              }),
            ],
            {
              type: "application/json",
            }
          )
        );
        adminService
          .onboardPutUser(
            `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
            formData
          )
          .then((response) => {
            setIsLoading(true);
            toast.success("Form Submitted successfully");
            props?.from === "family" && props.handleBack();
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        setSignatureErr("Signature is required!");
      }
    } else if (props?.from === "admin") {
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("signature", familyImageURL);
      console.log("formData 220", formData);
      formData.append(
        "formCreationForm",
        new Blob([JSON.stringify(props?.inputs)], {
          type: "application/json",
        })
      );
      adminService
        .onboardUser(
          `submitted-forms`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Form Submitted successfully");
          props.setIsFocused(!props.isFocused);
          props?.from == "admin" && props.handleBack("reload");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };
  const str = name;
  const buildAcronym = (str = "") => {
    const strArr = str.split(" ");
    let res = "";
    strArr.forEach((el) => {
      const [char] = el;
      if (char && char === char.toUpperCase() && char !== char.toLowerCase()) {
        res += char;
      }
    });
    return res;
  };

  const updateInfo = (e) => {
    const { name, value } = e.target;

    setauthorization({ ...authorization, [name]: value });
  };
  const adressInfo = (i, e) => {
    const { name, value } = e.target;
    setValid(true);
    setauthorization(
      produce((draftState) => {
        draftState.streetAddress[name] = value;
      })
    );
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "Authorization form.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const relValue = props?.data?.reply
    ? JSON.parse(props?.data?.jsonFormattedForm)
    : "";

  console.log("revalue", props, relValue.childName);

  // const thi = JSON.stringify(upperCase(name)).replace('"', "").replace('"', "")
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        {" "}
        <div
          style={{
            position: "absolute",
            top: 0,
            right: "100px",
          }}
        >
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.familySignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}
        </div>
        <div className="card-lg">
          <FormHeader
            logoUrl={logoUrl}
            title={
              "AUTHORIZATION TO DISCLOSE INFORMATION TO" +
              " " +
              buildAcronym(str) +
              " " +
              "NETWORK"
            }
          />
          {/* <div>{(thi.split(' ').shift().charAt(0) + thi.split(' ').pop().charAt(1)+thi.split(' ').pop().charAt(2)+thi.split(' ').pop().charAt(3)).toUpperCase()}</div> */}
          <div style={{ textAlign: "center" }}>
            <h6 className="mt-10 mb-10" style={{ textDecoration: "underline" }}>
              Release Form
            </h6>
          </div>
          <hr />

          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content">
              <br />
              <div className="w-100per">
                <p className="w-100per">
                  I voluntarily authorize and request the release of all of{" "}
                  {isSubmit ? (
                    relValue?.childName
                  ) : (
                    <input
                      {...register('childName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                      maxlength="45"
                      type="text"
                      placeholder="Patient’s name"
                      name="childName"
                      className={`form-control form-input form-input-w-60per ${errors.childName && "is-invalid"}`}
                      defaultValue={familyInfo?.familyMemberName}
                      disabled={familyInfo?.familyMemberName === '' ? false : true}
                    //onChange={(e) => updateInfo(e)}
                    />
                  )}{" "}
                  (child’s name ), born on{" "}
                  {isSubmit ? (
                    relValue?.childDob
                  ) : (
                    <input
                      {...register('childDob', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberDob === '' ? true : false })}
                      type="date"
                      name="childDob"
                      placeholder="DOB"
                      className={`form-control form-input form-input-w-37per ${errors.childDob && "is-invalid"
                        }`}
                      defaultValue={familyInfo?.familyMemberDob}
                      disabled={familyInfo?.familyMemberDob === '' ? false : true}
                    // defaultValue={
                    //   props?.data?.familyMember
                    //     ? props.data.familyMember.dob
                    //     : " "
                    // }
                    // onChange={(e) => updateInfo(e)}
                    />
                  )}
                  , medical records and other information related to treating
                  and performing necessary tasks. This includes specific
                  permission to release:
                </p>
                <ol>
                  <li>
                    All records and other information regarding any treatment,
                    hospitalization, and outpatient care for{" "}
                    {isSubmit ? (
                      relValue?.childName
                    ) : (
                      <input
                        {...register('childName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                        maxlength="45"
                        type="text"
                        placeholder="Patient’s name"
                        name="childName"
                        className={`form-control form-input form-input-w-60per ${errors.childName && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberName}
                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}{" "}
                    (child’s name).{" "}
                  </li>
                  <li>
                    Copies of any medical or educational tests or evaluations,
                    including individualized education plans, triennial
                    assessments, psychological, speech and language,
                    occupational, physical therapy evaluations, and any other
                    records that can help evaluate function and assist in
                    treatment.
                  </li>
                </ol>
              </div>
              <div className="w-100per">
                <p className="w-100per">
                  Information and records may be obtained from / or released to:
                </p>
                <ul>
                  <li>
                    All medical sources including hospitals, clinics, labs,
                    physicians, psychologists, etc.
                  </li>
                  <li>
                    All educational sources including schools, teachers, records
                    administrators, counselors, etc.
                  </li>
                  <li>Social workers / rehabilitation counselors</li>
                  <li>Therapists</li>
                  <li>
                    Others who may know about condition (family, neighbors,
                    friends)
                  </li>
                </ul>
              </div>
              <p className="w-100per">
                *I authorize the use of a copy (including electronic copy) of
                this form for the disclosure of the information described above.
              </p>
              <p className="w-100per">
                *I understand that there are some circumstances where this
                information may be redisclosed to other parties.
              </p>
              <p className="w-100per">
                *I may write to {name} Network and my sources to revoke this
                authorization at any time.{" "}
              </p>
              <p className="w-100per">
                *{name} Network will provide me with a copy of the material
                being disclosed
              </p>
              <p className="w-100per">
                <b>
                  *I have read this form and agree to the disclosure above:{" "}
                </b>{" "}
              </p>
              <br />
              <div className="w-100per">
                <div style={{ width: "50%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Child’s Name: </b>
                    </label>{" "}
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.childName
                    ) : (
                      <input
                        {...register('childName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                        maxlength="45"
                        type="text"
                        placeholder="Patient’s name"
                        name="childName"
                        className={`form-control form-input form-input-w-60per ${errors.childName && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberName}
                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "50%", display: "inline-block" }}>
                  <div className="w-100per">
                    <label style={{ width: "auto" }}>
                      <b>Child’s Date of Birth: </b>
                    </label>{" "}
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.childDob
                    ) : (
                      <input
                        {...register('childDob', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberDob === '' ? true : false })}
                        type="date"
                        name="childDob"
                        placeholder="DOB"
                        className={`form-control form-input form-input-w-37per ${errors.childDob && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberDob}
                        disabled={familyInfo?.familyMemberDob === '' ? false : true}
                      // defaultValue={
                      //   props?.data?.familyMember
                      //     ? props.data.familyMember.dob
                      //     : " "
                      // }
                      // onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br></br>
              <div className="w-100per">
                <div style={{ width: "50%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Person authorizing disclosure: </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.authorizationDisclosure
                    ) : (
                      <input
                        {...register('authorizationDisclosure', { onChange: (e) => updateInfo(e), required: familyInfo?.familyName === '' ? true : false })}
                        type="text"
                        placeholder="Print name"
                        name="authorizationDisclosure"
                        className={`form-control form-input form-input-w-60per ${errors.authorizationDisclosure && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyName}
                        disabled={familyInfo?.familyName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "50%", display: "inline-block" }}>
                  <div className="w-100per">
                    <label style={{ width: "auto" }}>
                      <b>Relationship to Patient:</b>
                    </label>{" "}
                    {isSubmit ? (
                      relValue?.relationship
                    ) : (
                      <>
                        <br />
                        <input
                          {...register('relationship', { onChange: (e) => updateInfo(e), required: familyInfo?.familyRelationship === '' ? true : false })}
                          type="text"
                          name="relationship"
                          placeholder="Relationship"
                          className={`form-control form-input form-input-w-37per ${errors.relationship && "is-invalid"
                            }`}
                          defaultValue={familyInfo?.familyRelationship}
                          disabled={familyInfo?.familyRelationship === '' ? false : true}
                        //onChange={(e) => updateInfo(e)}
                        />
                      </>
                    )}
                  </div>
                </div>
                <br />
                <div style={{ width: "100%", marginTop: "20px" }}>
                  <SignDate
                    date={
                      authorization?.familySignDate || relValue?.familySignDate
                    }
                    familyImageURL={familyImageURL}
                    isSubmit={isSubmit}
                    setOpen={setOpen}
                    signTitle=""
                    familyInfo={familyInfo}
                  />
                </div>

                <DigitalSignPopup
                  modalState={open}
                  setModalState={setOpen}
                  save={save}
                  clear={clear}
                  sigCanvas={sigCanvas}
                />
                <br />
                <div style={{ width: "100%", display: "inline-block" }}>
                  <div className="w-100per">
                    <label style={{ width: "auto", marginBottom: 5 }}>
                      <b>Street Address : </b>
                    </label>
                    &nbsp;&nbsp;
                    <div style={{ width: "100%", marginBottom: 15 }}>
                      {isSubmit ? (
                        <>{relValue?.streetAddress?.street}</>
                      ) : (
                        <input
                          {...register('street', { onChange: (e) => adressInfo(0, e), required: true })}
                          type="text"
                          name="street"
                          placeholder="Street #"
                          className={`form-control form-input form-input-w-37per ${errors.street && "is-invalid"
                            }`}
                          defaultValue={""}
                        //onChange={(e) => adressInfo(0, e)}
                        />
                      )}
                    </div>
                    <div style={{ width: "100%", marginBottom: 15 }}>
                      {isSubmit ? (
                        <>
                          <b>Street Name :&nbsp;</b>
                          {relValue?.streetAddress?.streetName}
                        </>
                      ) : (
                        <input
                          {...register('streetName', { onChange: (e) => adressInfo(1, e), required: true })}
                          type="text"
                          name="streetName"
                          placeholder="Street Name"
                          className={`form-control form-input form-input-w-37per ${errors.streetName && "is-invalid"
                            }`}
                          defaultValue={""}
                        //onChange={(e) => adressInfo(1, e)}
                        />
                      )}
                    </div>
                    <div style={{ width: "100%", marginBottom: 15 }}>
                      {isSubmit ? (
                        <>
                          <b>City :&nbsp;</b>
                          {relValue?.streetAddress?.city}
                        </>
                      ) : (
                        <input
                          {...register('city', { onChange: (e) => adressInfo(2, e), required: true })}
                          type="text"
                          name="city"
                          placeholder="City"
                          className={`form-control form-input form-input-w-37per ${errors.city && "is-invalid"
                            }`}
                          defaultValue={""}
                        //onChange={(e) => adressInfo(2, e)}
                        />
                      )}
                    </div>
                    <div style={{ width: "100%", marginBottom: 15 }}>
                      {isSubmit ? (
                        <>
                          <b>State :&nbsp;</b>
                          {relValue?.streetAddress?.state}
                        </>
                      ) : (
                        <input
                          {...register('state', { onChange: (e) => adressInfo(3, e), required: true })}
                          type="text"
                          name="state"
                          placeholder="State"
                          className={`form-control form-input form-input-w-37per ${errors.state && "is-invalid"
                            }`}
                          defaultValue={""}
                        //onChange={(e) => adressInfo(3, e)}
                        />
                      )}
                    </div>
                    <div style={{ width: "100%", marginBottom: 15 }}>
                      {isSubmit ? (
                        <>
                          <b>Zipcode :&nbsp;</b>
                          {relValue?.streetAddress?.zipcode}
                        </>
                      ) : (
                        <input
                          {...register('zipcode', { onChange: (e) => adressInfo(4, e), required: true })}
                          type="number"
                          name="zipcode"
                          placeholder="Zip Code"
                          className={`form-control form-input form-input-w-37per ${errors.zipcode && "is-invalid"
                            }`}
                          defaultValue={""}
                        //onChange={(e) => adressInfo(4, e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {signatureErr && (
                    <p className="text-red">
                      <small>{signatureErr}</small>
                    </p>
                  )}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {props?.data.replyNeeded && (
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={!familyInfo?.familySign || !valid}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {props?.from === 'admin' && props?.isFormOpen &&
                    <>
                      <button
                        className="btn btn-theme-green"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btn-success float-right1 mt-10 mb-10"
                      >
                        {isLoading ? "Loading...." : "Submit"}
                      </button>
                    </>

                  }
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AuthToDiscoloseInfo;