import React, { useEffect, useState } from "react";
import moment from "moment";

function Counter(props) {
  const [time, setTime] = useState(
    props?.newCTime || props.time || moment.duration()
  );
  useEffect(() => {
    setTimeout(() => {
      if (props.forward) {
        setTime(moment.duration(time.asSeconds() + 1, "seconds"));
      } else if (props?.newCTime) {
        setTime(moment.duration(props?.newCTime.asSeconds() - 1, "seconds"));
      } else {
        console.log("props?--------------------", time);
        if (!props?.newCTime) {
          console.log("props?--------------------", time);
          setTime(moment.duration(time.asSeconds() - 1, "seconds"));
        }
      }
    }, 1000);
  }, [props.forward, time]);

  const addZero = (num, onlyPos = false) => {
    let myNum = num;
    if (onlyPos) {
      myNum = Math.abs(num);
    }
    if (myNum < 0) {
      return myNum > 9 ? myNum : `00`;
    } else {
      return myNum > 9 ? myNum : `0${myNum}`;
    }
  };
  const displayTimer = (timer) => {
    let text = "";
    const hours = timer.hours();
    const minutes = timer.minutes();
    const seconds = timer.seconds();

    if (timer && timer._milliseconds > 0) {
      text = hours ? text + addZero(hours) + ":" : text;
      text =
        minutes !== undefined
          ? text + addZero(minutes, Boolean(hours)) + ":"
          : text;
      text = seconds !== undefined ? text + addZero(seconds, true) : text;
    } else {
      text = "00:00";
    }

    return text;
  };

  useEffect(() => {
    if (props?.setCurrentTime) {
      props?.setIsCountDown &&
        displayTimer(time) == "00:00" &&
        props?.setIsCountDown(true);
      props?.setCurrentTime(displayTimer(time));
    }
  }, [time]);
  return <span>{displayTimer(time)}</span>;
}

export default Counter;
