import React from "react";
import classNames from "classnames";
import { CaseNoteInfo, CaseNoteInfoCol } from "./";
import CaseNoteBillingInfo from "./CaseNoteBillingInfo";
// import './print.css'
import { useSelector } from "react-redux";
import moment from "moment";

const CaseNotePrint = React.forwardRef(
  (
    {
      state,
      infoProps,
      currentDiagnoses,
      currentCpt,
      billableRate,
      duration,
      isAccepetedByFamily,
      interpreterName,
      isInterepreterDraft,
      isTherepist,
      translatedDir,
      userType,
    },
    ref
  ) => {
    const printProps = {
      childImage: state.childProfilePic,
      childName: state.childName,
      infoProps,
      icdCode: currentDiagnoses
        ? `(${currentDiagnoses.code}) ${currentDiagnoses.description}`
        : state.caseNoteDTO?.diagnosisId,
      cptCode: currentCpt
        ? `(${currentCpt.procCode}) ${currentCpt.description}`
        : state.caseNoteDTO?.cptId,
      billableRate,
      duration,
      caseNoteDTO: state.caseNoteDTO,
      isAccepetedByFamily,
      interpreterName,
      isTherepist,
      isInterepreterDraft,
      translatedDir,
    };
    const { childImage, childName, icdCode, cptCode, caseNoteDTO } = printProps;
    const {
      sessionNumber,
      date,
      therapist,
      therapy,
      clinicianNpiNumber,
      clinicNpiNumber,
      groupNpiNumber,
    } = infoProps;
    const soapNoteBillingData = useSelector(
      (st) => st.appState?.soapNoteBillingData
    );
    // if (soapNoteBillingData?.length <= 0) {
    //   return null;
    // }
    const {
      name,
      address1,
      address2,
      city,
      country,
      state: billingState,
      zipCode,
      logoUrl,
    } = soapNoteBillingData;
    const joinWithComma = (...params) => params.filter(Boolean).join(", ");

    // return (

    // )
    return (
      <>
        <div ref={ref} className="c-casenote-print">
          <table width="100%">
            <tbody>
              <tr>
                <td className="text-left">
                  <div className="c-casenote-billing-info text-left d-flex justify-content-between align-items-center">
                    <div className="c-casenote-billing-info__image-wrapper pl-2 pr-2">
                      {logoUrl && (
                        <img
                          src={logoUrl}
                          alt={name}
                          className="img-fluid c-casenote-billing-info__image"
                        />
                      )}
                    </div>
                  </div>
                </td>
                <td></td>
                <td style={{ textAlign: "right" }} className="text-right">
                  <div className="c-casenote-billing-info text-right d-flex justify-content-end align-items-center">
                    <div className="c-casenote-bililng-info__content">
                      <p className="mb-0" style={{ marginBottom: 0 }}>
                        <b style={{ fontSize: "1.1em" }}>{name}</b>
                      </p>
                      <p className="mb-1">
                        <span className="d-block" style={{ marginBottom: 0 }}>
                          {joinWithComma(address1, address2)}
                        </span>
                        <span className="d-block" style={{ marginBottom: 0 }}>
                          {joinWithComma(city, billingState, country, zipCode)}
                        </span>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="p-case-note-detail__user">
                    <img src={childImage} alt={childName} />
                    <p
                      className="p-case-note-detail__child-name text-capitalize"
                      style={{ width: "100%" }}
                    >
                      {childName}
                      &nbsp;
                      {state?.childDOB && (
                        <small>
                          (<b>DOB: </b>
                          {moment(state?.childDOB).format("MM/DD/YYYY")})
                        </small>
                      )}
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <CaseNoteInfoCol label="Session Id" value={sessionNumber} />
                </td>
                <td>
                  <CaseNoteInfoCol label="Session Date" value={date} />
                </td>
                <td>
                  <CaseNoteInfoCol label="Therapist" value={therapist} />
                </td>
              </tr>
              <tr>
                {interpreterName && (
                  <td>
                    <CaseNoteInfoCol
                      label="Interpreter"
                      value={interpreterName}
                    />
                  </td>
                )}
                <td>
                  <CaseNoteInfoCol label="Therapy" value={therapy} />
                </td>
                <td>
                  <CaseNoteInfoCol
                    label="Clinician NPI Number"
                    value={clinicianNpiNumber}
                  />
                </td>
                {/* {
                                !interpreterName && 
                                <td>
                                    <CaseNoteInfoCol fixed={false} label="Clinic NPI Number" value={clinicNpiNumber} />
                                </td>
                            } */}
              </tr>
              {/* {
                            interpreterName &&  
                            <tr>
                                <td colSpan="3" >
                                    <CaseNoteInfoCol fixed={false} label="Clinic NPI Number" value={clinicNpiNumber} />
                                </td>
                            </tr>
                        } */}
              {groupNpiNumber && (
                <tr>
                  <td>
                    <CaseNoteInfoCol
                      label="Group NPI Number"
                      value={groupNpiNumber}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <CaseNoteInfoCol label="ICD 10 Code" value={icdCode} />
                </td>
                <td>
                  <CaseNoteInfoCol label="CPT Code" value={cptCode} />
                </td>
                {userType !== "ROLE_FAMILY" && userType !== "ROLE_INTERPRETER" && (
                  <td>
                    {/* <CaseNoteInfoCol
                      label="Billable Rate"
                      value={billableRate ? `$${billableRate}/unit` : ""}
                    /> */}
                  </td>
                )}
              </tr>
              <tr>
                <td>
                  <CaseNoteInfoCol label="Duration/Unit" value={duration} />
                </td>
                <td>
                  <CaseNoteInfoCol label="Units" value={caseNoteDTO?.units} />
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="3">
                  <CaseNoteInfoCol
                    label="Description"
                    value={caseNoteDTO?.sessionDescription}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <CaseNoteInfoCol
                    label="Session Note"
                    value={caseNoteDTO?.description}
                  />
                </td>
              </tr>
              {!isInterepreterDraft && interpreterName && !isTherepist && (
                <tr>
                  <td colSpan="3">
                    <CaseNoteInfoCol
                      label="Translation"
                      value={caseNoteDTO?.translatedDescription}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <CaseNoteInfoCol noValue label="Therapist Signature" />
                  <div className="d-flex">
                    {/* <img
                      className="sign-img"
                      src={caseNoteDTO?.therapistSignatureKey}
                      alt="signature"
                    /> */}
                    <div
                      className="sign-img"
                      style={{
                        width: "160px",
                        height: "60px",
                        border: 0,
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        src={caseNoteDTO?.therapistSignatureKey}
                        alt="my signature"
                        className="sign-img1"
                        style={{ width: "130px", height: "40px" }}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  {isAccepetedByFamily && caseNoteDTO?.familySignatureKey && (
                    <CaseNoteInfoCol noValue label="Family Signature" />
                  )}
                  <div className="d-flex">
                    {isAccepetedByFamily && caseNoteDTO?.familySignatureKey && (
                      <>
                        {/* <img
                        className="sign-img"
                        src={caseNoteDTO?.familySignatureKey}
                        alt="signature"
                      /> */}

                        <div
                          className="sign-img"
                          style={{
                            width: "160px",
                            height: "60px",
                            border: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            src={caseNoteDTO?.familySignatureKey}
                            alt="my signature"
                            className="sign-img1"
                            style={{ width: "130px", height: "40px" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
);

export default CaseNotePrint;
