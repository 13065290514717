import React, { useEffect } from "react";
import classNames from "classnames";

const AppModal = ({
  title,
  onClose,
  isOpen,
  modalSize = "",
  children,
  hideHeaderBackground = false,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("keydown", onEsc);
    return () => {
      document.removeEventListener("keydown", onEsc);
    };
  }, []);

  const onEsc = (e) => {
    if (e.key === "Escape" && typeof onClose === "function") {
      onClose();
    }
  };
  return (
    <div className="col-md-2 custom-modal">
      <div
        className={classNames("modal fade overflow-auto", {
          show: isOpen,
        })}
        tabIndex="-1"
        role="dialog"
        aria-hidden={isOpen}
      >
        <div
          className={`modal-dialog modal-dialog-centered ${
            modalSize == "lg" ? "modal-lg" : ""
          }`}
          role="document"
        >
          <div className="modal-content ">
            <div
              className="modal-header"
              style={{
                backgroundColor: hideHeaderBackground
                  ? "transparent"
                  : "#eaf1fe",
              }}
            >
              <div className="w-100 text-center">
                <h5 className="modal-title">{title}</h5>
              </div>
              <button
                onClick={onClose}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <div className="close-background">
                  <span aria-hidden="true">&times;</span>
                </div>
              </button>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppModal;
