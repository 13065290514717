import React from "react";
import { EmojiSender } from "../../components/emoji/Emoji";
import { emitEvent } from "../../hooks/eventHook";
import { useDispatch, useSelector } from "react-redux";

function EmojiControl(props) {
  const appAgoraState = useSelector((state) => state.agoraDetail);
  return (
    <>
      <EmojiSender
        onClick={(e) => {
          emitEvent("agoraEmoji", {
            uid: props?.clientId,
            content: e,
            sender: {
              name:
                props?.profile?.userType == "ROLE_FAMILY"
                  ? [
                      appAgoraState?.currentSession?.familyMemberDtoSet &&
                        appAgoraState?.currentSession?.familyMemberDtoSet.filter(
                          (f) => f.familyUserId == props?.currentUserProfile.id
                        )[0]?.firstName,
                      appAgoraState?.currentSession?.familyMemberDtoSet &&
                        appAgoraState?.currentSession?.familyMemberDtoSet.filter(
                          (f) => f.familyUserId == props?.currentUserProfile.id
                        )[0]?.lastName,
                    ].join(" ")
                  : [
                      props?.currentUserProfile?.firstName,
                      props?.currentUserProfile?.lastName,
                    ].join(" "),
            },
          });
        }}
      />
    </>
  );
}

export default EmojiControl;
