import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "./CommonComponents";
import SignDate from "./SignDate";
import produce from "immer";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

function ConsultationDocumentationForm(props) {
  const profile = useSelector((state) => state.adminUser.profile);
  const formRef = React.useRef(null);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const sigCanvas = useRef({});
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  const [open, setOpen] = useState(false);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  // {consultation:
  const [familyInfo, setFamilyInfo] = useState({});
  const [prevShortGoals, setprevShortGoals] = useState([{ id: "", description: "" }]);
  const [newLongGoals, setnewLongGoals] = useState([{ id: "", description: "" }]);
  const [newShotGoals, setnewShotGoals] = useState([{ id: "", description: "" }]);
  const [isSubmit, setIsSubmit] = useState(
    typeof props?.data?.reply === "undefined" ? false : true
  );
  const [pageResult, setpageResult] = useState({
    adminSign: "",
    adminSignDate: "",
    reply: props.inputs?.reply ? props.inputs.reply : true,
    replyNeeded: false,
    sentBy: props.inputs?.sentBy ? props.inputs.sentBy : "",
    sentTo: props.inputs?.sentTo ? props.inputs.sentTo : "",
    therapistId: profile?.therapistId ? profile.therapistId : null,
    witnessSign: "",
    witnessSignDate: "",
    consent1: true,
    consent2: true,
    consent3: true,
    consent4: true,
    consent5: true,
    formId: props.inputs?.formId ? props.inputs.formId : null,
    familyId: props?.inputs?.familyId ? props?.inputs?.familyId : null,
    familyMemberId: props?.inputs?.familyMemberId ? props?.inputs?.familyMemberId : null,
    familyMemberName: "child one",
    familySign: "",
    familySignDate: "",
    interpreterId: null,
    interpreterSign: "",
    interpreterSignDate: "",
  });
  const [consultation, setconsultation] = useState({
    participateName: " ",
    participateMethod: "",
    contactName: " ",
    contactMethod: "",
    childName: props?.selectedFamily?.firstName +
      " " +
      props?.selectedFamily?.lastName,
    childDob: props?.selectedFamily?.dob
      ? moment(props?.selectedFamily?.dob).format("YYYY-MM-DD") +
      `T00:00:00.000Z`
      : "",
    serviceCoordinator: "",
    documentOfConsultation: "",
    startTime: new Date(),
    endTime: new Date(),
    location: "",
    succussDescription: "",

    challangeDescription: "",

    teamStrategiesDescription: "",
    teamMemberSign: "",
    teamMemberSignDate: "",
    facePhoneData: [
      { name: "Parent/ Guardian", phone: false, faceToFace: false },
      { name: "ITDS", faceToFace: false, phone: false },
      { name: "OT", phone: false, faceToFace: false },
      { name: "PT", faceToFace: false, phone: false },
      { name: "SLP", faceToFace: false, phone: false },
      { name: "EI", phone: false, faceToFace: false },
      { name: "Service Coordinator", faceToFace: false, phone: false },
      { name: "Other", phone: false, faceToFace: false },
    ],

    billingInfo: {
      renderingProvider: " ",
      diagnosisCode: " ",
      texonomyCode: "",
      texonomyFee: "",
      totalMiles: "",
      travelFee: "",
      supportFee: "",
      totalRequestedAmount: "",
    },
  });

  console.log("props consulation", props?.selectedFamily?.firstName +
    " " +
    props?.selectedFamily?.lastName);



  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "ConsultationDocumentationForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };



  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      teamMemberSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setpageResult({
      ...pageResult,
      teamMemberSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setconsultation(
      produce((draftState) => {
        draftState["teamMemberSignDate"] = moment(currentDateTime)
          .local()
          .format("MM-DD-YYYY hh:mm a");
      })
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              teamMemberSign: res.data.signatureKey,
              teamMemberSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setpageResult({
              ...pageResult,
              teamMemberSign: res.data.signatureKey,
              teamMemberSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setconsultation(
              produce((draftState) => {
                draftState["teamMemberSign"] = res.data.signatureKey;
                draftState["teamMemberSignDate"] = moment(currentDateTime)
                  .local()
                  .format("MM-DD-YYYY hh:mm a");
              })
            );
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };


  const billingInfo = (e) => {
    const { name, value } = e.target;
    setconsultation(
      produce((draftState) => {
        draftState.billingInfo[name] = value;
      })
    );
  };
  const careFrom = (e) => {
    //const { name, value } = e.target;
    //console.log("careFrom",value,name);
    setconsultation(
      produce((draftState) => {
        draftState.startTime = new Date(e)
      })
    );
  };
  const careTo = (e) => {
    setconsultation(
      produce((draftState) => {
        draftState["endTime"] = new Date(e)
      })
    );
    console.log("endTime",moment(consultation.endTime).format("hh:mm A"));
    // const { name, value } = e.target;
    // setconsultation(
    //   produce((draftState) => {
    //     draftState.endTime = value
    //   })
    // );
  };
  const updateInfo = (e) => {
    const { name, value } = e.target;
    setconsultation({ ...consultation, [name]: value });
  };
  const updateBoxInfo = (e, i) => {
    const { name, value } = e.target;
    const newArray = [...consultation.facePhoneData];
    newArray[i] = {
      ...newArray[i],
      [name]: !newArray[i].faceToFace,
      [name]: !newArray[i].phone,
    };
    console.log(newArray, "newArray");
    setconsultation(
      produce((draftState) => {
        draftState.facePhoneData = newArray;
      })
    );
  };
  const setDate = (e) => {
    setconsultation(
      produce((draftState) => {
        draftState["childDob"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const consDate = (e) => {
    setconsultation(
      produce((draftState) => {
        draftState["documentOfConsultation"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };

  const removePrevShortGoals = (i) => {
    const arr = [...prevShortGoals];
    arr.splice(i, 1);
    //let inputId = "textDes"+i;
    document.getElementById("challange" + i).value = '';
    setprevShortGoals(arr);
  };
  const removeNewShotGoals = i => {
    const arr = [...newShotGoals];
    console.log("arr aa", arr, i);
    arr.splice(i, 1);
    //let inputId = "textDes"+i;
    document.getElementById("textDes" + i).value = '';
    setnewShotGoals(arr);
  };
  const removeNewLongGoals = (i, e) => {
    const arr = [...newLongGoals];
    arr.splice(i, 1);
    document.getElementById("challange" + i).value = '';
    setnewLongGoals(arr);
  };
  const handleNewShortGoals = (i, value, name) => {
    //const { name, value } = e.target;
    // console.log("handleNewShortGoals", name, value)
    // setnewShotGoals({ ...newLongGoals, [name]: value });
    // setconsultation({ ...consultation, [name]: value });
    // setconsultation(
    //   produce((draftState) => {
    //     draftState.teamStrategiesDescription = value;
    //   })
    // );
    let newArr = [...newShotGoals];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      ["description"]: value,
    };
    setnewShotGoals(newArr);
    setconsultation(
      produce((draftState) => {
        draftState.teamStrategiesDescription = newArr;
      })
    );
  };
  const handleNewLongGoals = (i, value, name) => {
    //setnewLongGoals({ ...newShotGoals, [name]: value });
    //setconsultation({ ...consultation, [name]: value });
    //const { name, value } = e.target;
    let newArr = [...newLongGoals];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      ["description"]: value,
    };
    setnewLongGoals(newArr);
    setconsultation(
      produce((draftState) => {
        draftState.challangeDescription = newArr;
      })
    );
  };
  const handlePrevShortGoals = (i, value, name) => {
    //const { name, value } = e.target;
    //setprevShortGoals({ ...prevShortGoals, [name]: value });
    //setconsultation({ ...consultation, [name]: value })
    let newArr = [...prevShortGoals];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      ["description"]: value,
    };
    setprevShortGoals(newArr);
    setconsultation(
      produce((draftState) => {
        draftState.succussDescription = newArr;
      })
    );
  };
  const addPrevShortGoals = () => {
    setprevShortGoals([...prevShortGoals, {}]);
  };
  const addNewLongGoals = () => {
    setnewLongGoals([...newLongGoals, { id: "", description: "" }]);
  };
  const addNewShotGoals = () => {
    setnewShotGoals([...newShotGoals, {}]);
  };
  const onSubmitFamilyForm = () => {
    setIsLoading(true);
    setconsultation(
      produce((draftState) => {
        draftState.succussDescription = prevShortGoals;
      })
    );
    setconsultation(
      produce((draftState) => {
        draftState.challangeDescription = newLongGoals;
      })
    );
    setconsultation(
      produce((draftState) => {
        draftState.teamStrategiesDescription = newShotGoals;
      })
    );
    console.log("........................discharge", consultation);
    let formData = new FormData();
    console.log();
    formData.append(
      "formCreationForm",
      new Blob(
        [
          JSON.stringify({
            ...pageResult,
            jsonFormattedForm: JSON.stringify({ consultation }),
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((response) => {
        setIsLoading(false);
        setOpen(false);
        toast.success("Form Submitted successfully");
        props.handleBack("reload");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };
  const relValue =
    typeof props?.data?.reply === "undefined"
      ? ""
      : JSON.parse(props?.data?.jsonFormattedForm);
  //console.log("relvale",relValue);
  return (
    <>
      <div className={`col-md-12`} id="my-code" ref={formRef}>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: "100px",
          }}
        >
          {props?.data?.family?.id && (
            <HeaderDownloadButtons startDownload={startDownload} />
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
          <div>
            <FormHeader logoUrl={logoUrl} title="Consultation Documentation" />
            <div className="pdf-header" style={{ textAlign: "center" }}>
              {/* <b>Consultation Documentation</b> */}
              <p className="w-100per">
                (To be completed by those participating in consultation
                session)
              </p>
            </div>
            <hr />
            <div className="card-lg page1">
              <div className="pdf-para1">
                <p className="w-100per">
                  <b>
                    Parent was notified and invited to participate on{" "}
                    {isSubmit ? (
                      relValue?.consultation?.participateName
                    ) : (
                      <input
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderBottom: "2px solid #222",
                        }}
                        //ref={register({ required: true })}
                        type="text"
                        placeholder="--"
                        name="participateName"
                        className={`form-control ${errors.familyName && "is-invalid"
                          }`}
                        defaultValue={familyInfo.participateName}
                        onChange={(e) => updateInfo(e)}
                      />)}{" "}
                    by (method){" "}
                    {isSubmit ? (
                      relValue?.consultation?.participateMethod
                    ) : (
                      <input
                        type="text"
                        name="participateMethod"
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderBottom: "2px solid #222",
                        }}
                        onChange={(e) => updateInfo(e)}
                      />)}
                  </b>
                </p>
                <p className="w-100per">
                  <p style={{ padding: 5, border: "1px solid #222", width: "100%" }}>
                    <b>
                      If the consultation meeting will potentially result in
                      change of outcomes or services, the Primary Service Provider
                      will contact Service Coordinator prior to meeting. Service
                      Coordinator contacted on{" "}
                      {isSubmit ? (
                        relValue?.consultation?.contactName
                      ) : (<input
                        type="text"
                        name="contactName"
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderBottom: "2px solid #222",
                        }}
                        onChange={(e) => updateInfo(e)}
                      />)}{" "}
                      by (method){" "}
                      {isSubmit ? (
                        relValue?.consultation?.contactMethod
                      ) : (<input
                        type="text"
                        name="contactMethod"
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderBottom: "2px solid #222",
                        }}
                        onChange={(e) => updateInfo(e)}
                      />)}
                    </b>
                  </p>
                </p>
                <div style={{ display: "inline-block", width: "100%" }}>
                  <br />
                  <div
                    style={{ display: "block", width: "60%", float: "left" }}
                  >
                    <p>
                      <>
                        <b>Child’s Name :</b>{" "}
                        {isSubmit ? (
                          relValue?.consultation?.childName
                        ) : (
                          <input
                            type="text"
                            name="childName"
                            defaultValue={consultation?.childName}
                            style={{
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            onChange={(e) => updateInfo(e)}
                          />)}{" "}
                      </>
                    </p>
                    <p>
                      <>

                        <b>Service Coordinator : </b>{" "}
                        {isSubmit ? (
                          relValue?.consultation?.serviceCoordinator
                        ) : (<input
                          type="text"
                          name="serviceCoordinator"
                          style={{
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: "1px solid #222",
                          }}
                          onChange={(e) => updateInfo(e)}
                        />)}
                      </>
                    </p>
                    <p>
                      <>
                        <b>Start Time :</b>{" "}
                        {isSubmit ? (
                          moment(relValue?.consultation.startTime).format("hh:mm A")
                          
                        ) : (
                          <div className="form-date-input">
                            <DatePicker
                            disableDayPicker
                            value={consultation?.startTime}
                              onChange={careFrom}
                              name="startTime"
                              format="hh:mm A"
                              plugins={[
                                <TimePicker hideSeconds />,
                              ]}
                              editable={false}
                              multiple={false}
                            />
                          </div>
                          // <input
                          //   type="text"
                          //   name="startTime"
                          //   style={{
                          //     width:"150px",
                          //     borderTop: 0,
                          //     borderLeft: 0,
                          //     borderRight: 0,
                          //     borderBottom: "1px solid #222",
                          //   }}
                          //   onChange={(e) => updateInfo(e)}
                          // />
                        )}
                        {" "}
                        <b>End Time :</b>{" "}
                        {isSubmit ? (
                          moment(relValue?.consultation.endTime).format("hh:mm A")
                        ) : (
                          <div className="form-date-input">
                            <DatePicker
                            disableDayPicker
                            value={consultation?.endTime}
                              onChange={careTo}
                              name="endTime"
                              format="hh:mm A"
                              plugins={[
                                <TimePicker hideSeconds />,
                              ]}
                              editable={false}
                              multiple={false}
                            /></div>
                          // <input
                          //   type="text"
                          //   name="endTime"
                          //   style={{
                          //     width: "150px",
                          //     borderTop: 0,
                          //     borderLeft: 0,
                          //     borderRight: 0,
                          //     borderBottom: "1px solid #222",
                          //   }}
                          //   onChange={(e) => updateInfo(e)}
                          // />
                        )}
                      </>
                    </p>
                  </div>
                  <div style={{ width: "40%", float: "left" }}>
                    <p>
                      <>

                        <b>DOB :</b>{" "}
                        {isSubmit ? (

                          moment(relValue?.consultation?.childDob).format("MM/DD/YYYY")
                        ) : (
                          <div className="form-date-input">
                            <DatePicker
                              multiple={false}
                              value={consultation?.childDob}
                              onChange={setDate}
                              name="childDob"
                              format="MM-DD-YYYY"
                              editable={false}

                            />
                          </div>

                        )}

                      </>
                    </p>
                    <p>
                      <>
                        <b>Date of Consultation :</b>{" "}
                        {isSubmit ? (

                          <>{relValue?.consultation?.documentOfConsultation ? moment(relValue?.consultation?.documentOfConsultation).format("MM/DD/YYYY") : ''}</>
                        ) : (
                          <div className="form-date-input">
                            <DatePicker
                              multiple={false}
                              value={consultation?.documentOfConsultation}
                              onChange={consDate}
                              name="documentOfConsultation"
                              format="MM-DD-YYYY"
                              editable={false}

                            />
                          </div>

                        )}

                      </>
                    </p>
                    <p>
                      <>
                        <b>Location :</b>{" "}
                        {isSubmit ? (
                          relValue?.consultation?.location
                        ) : (
                          <input
                            type="text"
                            name="location"
                            style={{
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            onChange={(e) => updateInfo(e)}
                          />)}
                      </>
                    </p>
                  </div>
                </div>
                <div style={{ marginTop: 15 }}>
                  {isSubmit ? (
                    <p style={{ marginTop: "20px", width: "100%" }}>
                      <b>
                        {" "}
                        • Successes to implementing strategies and achieving
                        goals for Outcome #{" "}
                      </b><br />
                      {relValue?.consultation?.succussDescription == ''? '':
                      <>{relValue?.consultation?.succussDescription?.map((element, indexs) => (
                        <>{relValue?.consultation?.succussDescription[indexs].description}<br /></>
                      ))}</>
                    }
                    </p>
                  ) : (
                    <p style={{ marginTop: "20px", width: "100%" }}>
                      <b>
                        {" "}
                        • Successes to implementing strategies and achieving
                        goals for Outcome #{" "}
                      </b>{" "}
                      <button
                        type="button"
                        style={{
                          margin: "10px", width: "40px",
                          height: "28px",
                          padding: "0px",
                        }}
                        className="btn btn-success "
                        onClick={() => addPrevShortGoals()}
                      >
                        +
                      </button>
                      {/* <input
                        type="textarea"
                        name="succussDescription"
                        defaultValue={prevShortGoals.succussDescription}
                        rows="4"
                        cols="50"
                        style={{
                          width: "100%",
                          height: "60px",
                          display: "block",
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderBottom: "1px solid #222",
                        }}
                        onChange={(e) => handlePrevShortGoals(e.target.name, e.target.value)}
                      ></input> */}
                      {prevShortGoals.map((element, indexs) => (
                        <div key={indexs}>
                          <input
                            type="textarea"
                            id={"challange" + indexs}
                            rows="4"
                            cols="50"
                            value={element.description || ''}
                            style={{
                              width: "100%",
                              height: "60px",
                              display: "block",
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            onChange={(e) => handlePrevShortGoals(indexs, e.target.value, e.target.name)}
                          ></input>
                          <button
                            type="button"
                            className="btn btn-primary "
                            style={{
                              width: "105px",
                              height: "28px",
                              padding: "0px",
                              backgroundColor: "red",
                              margin: "10px",
                            }}
                            onClick={() => removePrevShortGoals(indexs)}
                          >
                            <b>-</b>
                          </button>
                        </div>
                      ))}
                    </p>
                  )}
                </div>
                <div style={{ marginTop: 15 }}>
                  {isSubmit ? (
                    <p style={{ marginTop: "20px", width: "100%" }}>
                      <b>
                        {" "}
                        • Challenges to implementing strategies and achieving
                        goals for Outcome #{" "}
                      </b><br />
                      {relValue?.consultation?.challangeDescription == '' ? '' : <>
                      {relValue?.consultation?.challangeDescription?.map((element, indexs) => (
                        <>{relValue?.consultation?.challangeDescription[indexs].description}<br /></>
                      ))}</>}
                    </p>
                  ) : (
                    <p style={{ marginTop: "20px", width: "100%" }}>
                      <b>
                        {" "}
                        • Challenges to implementing strategies and achieving
                        goals for Outcome #{" "}
                      </b>{" "}
                      <button
                        type="button"
                        style={{
                          margin: "10px", width: "40px",
                          height: "28px",
                          padding: "0px",
                        }}
                        className="btn btn-success "
                        onClick={() => addNewLongGoals()}
                      >
                        +
                      </button>
                      {/* <input
                        type="textarea"
                        rows="4"
                        cols="50"
                        defaultValue={newLongGoals.description}
                        name="challangeDescription"
                        style={{
                          width: "100%",
                          height: "60px",
                          display: "block",
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderBottom: "1px solid #222",
                        }}
                        onChange={(e) => handleNewLongGoals(e.target.name, e.target.value)}
                      ></input> */}
                      {newLongGoals.map((element, indexs) => (
                        <div key={indexs}>
                          <input
                            type="textarea"
                            id={"challange" + indexs}
                            rows="4"
                            cols="50"
                            value={element.description || ''}
                            style={{
                              width: "100%",
                              height: "60px",
                              display: "block",
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            onChange={(e) => handleNewLongGoals(indexs, e.target.value, e.target.name)}
                          ></input>
                          <button
                            type="button"
                            className="btn btn-primary "
                            style={{
                              width: "105px",
                              height: "28px",
                              padding: "0px",
                              backgroundColor: "red",
                              margin: "10px",
                            }}
                            onClick={() => removeNewLongGoals(indexs)}
                          >
                            <b>-</b>
                          </button>
                        </div>
                      ))}
                    </p>
                  )}
                </div>
                <div style={{ marginTop: 15 }}>
                  {isSubmit ? (
                    <p style={{ marginTop: "20px", width: "100%" }}><b>The team (family, caregivers, primary service
                      provider and supporting providers)</b>
                      <b>
                        {" "}
                        will continue or modify the following strategies to
                        achieve goals for Outcome #
                      </b><br />
                      {relValue?.consultation?.teamStrategiesDescription == '' ? '' : <>
                      {relValue?.consultation?.teamStrategiesDescription?.map((element, indexs) => (
                        <>{relValue?.consultation?.teamStrategiesDescription[indexs].description}<br /></>
                      ))}</>}
                    </p>
                  ) : (
                    <p style={{ marginTop: "20px", width: "100%" }}>
                      <b>The team (family, caregivers, primary service
                        provider and supporting providers)</b>
                      <b>
                        {" "}
                        will continue or modify the following strategies to
                        achieve goals for Outcome #
                      </b>{" "}
                      <button
                        type="button"
                        style={{
                          margin: "10px", width: "40px",
                          height: "28px",
                          padding: "0px",
                        }}
                        className="btn btn-success"
                        onClick={() => addNewShotGoals()}
                      >
                        + {" "}
                      </button>
                      {/* <div
                        style={{ display: "inline-block", width: "100%" }}
                      >
                        <input
                          type="text"
                          name="teamStrategiesDescription"
                          style={{
                            width: "100%",
                            height: "60px",
                            display: "block",
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: "1px solid #222",
                          }}
                          onChange={(e) => handleNewShortGoals(e.target.name, e.target.value)}
                        />
                      </div> */}
                      {newShotGoals.map((element, indexs) => (
                        <div key={indexs}>
                          <div
                            style={{ display: "inline-block", width: "100%" }}
                          >
                            <input
                              type="text"
                              id={"textDes" + indexs}
                              value={element.description}
                              style={{
                                width: "100%",
                                height: "60px",
                                display: "block",
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: "1px solid #222",
                              }}
                              onChange={(e) => handleNewShortGoals(indexs, e.target.value, e.target.name)}
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary "
                            style={{
                              width: "105px",
                              height: "28px",
                              padding: "0px",
                              backgroundColor: "red",
                              margin: "10px",
                            }}
                            onClick={() => removeNewShotGoals(indexs)}
                          >
                            <b>-</b>
                          </button>
                        </div>
                      ))}
                    </p>
                  )}
                </div>
                {isSubmit ? (
                  <p style={{ marginTop: 15, width: "100%" }}>
                    IFSP Team meeting is needed to discuss recommended changes in
                    services, frequency, and/or duration of services:
                  </p>) : (<p style={{ marginTop: 15 }}>
                    IFSP Team meeting is needed to discuss recommended changes in
                    services, frequency, and/or duration of services:
                    <span> <input type="radio" name="yes_no" style={{ marginRight: "2px" }} />Yes
                      {" "} <input type="radio" name="yes_no" style={{ marginRight: "2px" }} /> No</span>
                  </p>)}

                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    border: "1px solid #222",
                    padding: 10,
                  }}
                >
                  <p style={{ width: "100%" }}>
                    Participating Team Members/Signatures: (PSP indicated with
                    *)
                  </p>
                  <SignDate
                    date={
                      consultation?.teamMemberSignDate ||
                      relValue?.consultation?.teamMemberSignDate
                    }
                    familyImageURL={
                      isSubmit ? props?.data?.therapistSignUrl : familyImageURL
                    }
                    isSubmit={isSubmit}
                    setOpen={setOpen}
                    signTitle={" Team Members"}
                    guardian=" Team Members"
                    familyInfo={familyInfo}
                    isLoading={isLoading}
                    userType="therapist"
                  />
                  <DigitalSignPopup
                    modalState={open}
                    setModalState={setOpen}
                    save={save}
                    clear={clear}
                    sigCanvas={sigCanvas}
                  />
                  <br />
                  <br />
                  <div
                    style={{ display: "block", width: "50%", float: "left" }}
                  >
                    <p>{isSubmit ? (
                      <>
                        Parent/ Guardian:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[0]?.faceToFace
                          }
                          disabled={relValue?.consultation?.facePhoneData[0]?.phone ? true : true}
                          onChange={(e) => updateBoxInfo(e, 0)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[0]?.phone
                          }
                          disabled={relValue?.consultation?.facePhoneData[0]?.faceToFace ? true : true}
                          onChange={(e) => updateBoxInfo(e, 0)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>
                      </>) : (<>
                        Parent/ Guardian:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[0]?.faceToFace
                          }
                          onChange={(e) => updateBoxInfo(e, 0)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[0]?.phone
                          }
                          onChange={(e) => updateBoxInfo(e, 0)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>
                      </>)}
                    </p>
                    <p>
                      {isSubmit ? (
                        <>
                          OT:{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="faceToFace"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[2]?.faceToFace
                            }
                            disabled={relValue?.consultation?.facePhoneData[2]?.phone ? true : true}
                            onChange={(e) => updateBoxInfo(e, 2)}
                          />
                          <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="phone"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[2].phone
                            }
                            disabled={relValue?.consultation?.facePhoneData[2]?.faceToFace ? true : true}
                            onChange={(e) => updateBoxInfo(e, 2)}
                          // onChange={(e) => updateBoxInfo(e, 5)}
                          />
                          <label style={{ width: "auto" }}>Phone</label>
                        </>) : (<>
                          OT:{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="faceToFace"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[2]?.faceToFace
                            }
                            onChange={(e) => updateBoxInfo(e, 2)}
                          />
                          <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="phone"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[2].phone
                            }
                            onChange={(e) => updateBoxInfo(e, 2)}
                          // onChange={(e) => updateBoxInfo(e, 5)}
                          />
                          <label style={{ width: "auto" }}>Phone</label>
                        </>)}
                    </p>
                    <p>
                      {isSubmit ? (
                        <>
                          SLP:{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="faceToFace"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[4]?.faceToFace
                            }
                            disabled={relValue?.consultation?.facePhoneData[4]?.phone ? true : true}
                            onChange={(e) => updateBoxInfo(e, 4)}
                          />
                          <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="phone"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[4]?.phone
                            }
                            disabled={relValue?.consultation?.facePhoneData[4]?.faceToFace ? true : true}
                            onChange={(e) => updateBoxInfo(e, 4)}
                          // onChange={(e) => updateBoxInfo(e, 5)}
                          />
                          <label style={{ width: "auto" }}>Phone</label>{" "}
                        </>) : (<>
                          SLP:{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="faceToFace"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[4]?.faceToFace
                            }
                            onChange={(e) => updateBoxInfo(e, 4)}
                          />
                          <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="phone"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[4]?.phone
                            }
                            onChange={(e) => updateBoxInfo(e, 4)}
                          // onChange={(e) => updateBoxInfo(e, 5)}
                          />
                          <label style={{ width: "auto" }}>Phone</label>{" "}
                        </>)}
                    </p>
                    <p>
                      {isSubmit ? (<>Service Coordinator:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[6]?.faceToFace
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[6]?.phone ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 6)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[6]?.phone
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[6]?.faceToFace ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 6)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>
                      </>) : (<>Service Coordinator:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[6]?.faceToFace
                          }
                          onChange={(e) => updateBoxInfo(e, 6)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[6]?.phone
                          }
                          onChange={(e) => updateBoxInfo(e, 6)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>
                      </>)}
                    </p>
                  </div>
                  <div
                    style={{ display: "block", width: "50%", float: "left" }}
                  >
                    <p>{isSubmit ? (
                      <>
                        ITDS:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[1]?.faceToFace
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[1]?.phone ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 1)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[1]?.phone
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[1]?.faceToFace ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 1)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>{" "}
                      </>) : (<>
                        ITDS:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[1]?.faceToFace
                          }
                          onChange={(e) => updateBoxInfo(e, 1)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[1]?.phone
                          }
                          onChange={(e) => updateBoxInfo(e, 1)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>{" "}
                      </>)}
                    </p>
                    <p>{isSubmit ? (
                      <>
                        PT:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[3]?.faceToFace
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[3]?.phone ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 3)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[3]?.phone
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[3]?.faceToFace ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 3)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>
                      </>) : (<>
                        PT:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[3]?.faceToFace
                          }
                          onChange={(e) => updateBoxInfo(e, 3)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[3]?.phone
                          }
                          onChange={(e) => updateBoxInfo(e, 3)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>
                      </>)}
                    </p>
                    <p>{isSubmit ? (
                      <>
                        EI:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[5]?.faceToFace
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[5]?.phone ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[5]?.phone
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[5]?.faceToFace ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 5)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>{" "}
                      </>) : (<>
                        EI:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[5]?.faceToFace
                          }
                          onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[5]?.phone
                          }
                          onChange={(e) => updateBoxInfo(e, 5)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label>{" "}
                      </>)}
                    </p>
                    <p>{isSubmit ? (
                      <>Other:{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="faceToFace"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[7]?.faceToFace
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[7]?.phone ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 7)}
                        />
                        <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                        <input
                          style={{ marginRight: "3px" }}
                          type="checkbox"
                          value="true"
                          name="phone"
                          defaultValue={""}
                          checked={
                            relValue?.consultation?.facePhoneData[7]?.phone
                          }
                          disabled={
                            relValue?.consultation?.facePhoneData[7]?.faceToFace ? true : true
                          }
                          onChange={(e) => updateBoxInfo(e, 7)}
                        // onChange={(e) => updateBoxInfo(e, 5)}
                        />
                        <label style={{ width: "auto" }}>Phone</label></>) : (<>Other:{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="faceToFace"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[7]?.faceToFace
                            }
                            onChange={(e) => updateBoxInfo(e, 7)}
                          />
                          <label style={{ width: "auto" }}>Face-to-Face</label>{" "}
                          <input
                            style={{ marginRight: "3px" }}
                            type="checkbox"
                            value="true"
                            name="phone"
                            defaultValue={""}
                            checked={
                              relValue?.consultation?.facePhoneData[7]?.phone
                            }
                            onChange={(e) => updateBoxInfo(e, 7)}
                          // onChange={(e) => updateBoxInfo(e, 5)}
                          />
                          <label style={{ width: "auto" }}>Phone</label></>)}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "block",
                    width: "100%"
                  }}
                >
                  <p style={{
                    display: "block",
                    width: "100%"
                  }}>
                    <small
                      style={{ verticalAlign: "top" }}
                    >
                      Copy to:
                    </small>{" "}
                    <small style={{ display: "inline-block" }}>
                      Family/ Guardian <br />
                      Early Steps Service
                      <br />
                      Coordinator within 5 business days
                      <br />
                      Team Providers (whether present or not)
                    </small>
                  </p>
                </div>
              </div>
            </div>

            <div className="card-lg page2">
              <div className="pdf-header" style={{ textAlign: "center" }}>
                <div style={{ display: "inline-block", width: "100%" }}>
                  <input
                    type="textarea"
                    rows="4"
                    cols="50"
                    style={{
                      width: "100%",
                      height: "60px",
                      display: "block",
                      borderTop: 0,
                      borderLeft: 0,
                      borderRight: 0,
                      borderBottom: "1px solid #222",
                    }}
                  ></input>
                </div>

                <p style={{ marginTop: 15 }}></p>

                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    border: "1px solid #222",
                    padding: 10,
                  }}
                >
                  <p>
                    <b>Billing Information:</b>{" "}
                  </p>
                  <div
                    style={{ display: "block", width: "50%", float: "left", textAlign: "left" }}
                  >

                    <p>
                      <>Rendering Provider  {isSubmit ? (
                        relValue?.consultation?.billingInfo?.renderingProvider
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder=""
                          name="renderingProvider"
                          style={{
                            width: "100px",
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: "1px solid #222",
                          }}
                          className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={familyInfo.familyMemberName}
                          onChange={(e) => billingInfo(e)}
                        />
                      )}{" "}</>
                    </p>
                    <p>
                      <>
                        Service Provided (Taxonomy Code:{" "}
                        {isSubmit ? (
                          relValue?.consultation?.billingInfo?.texonomyCode
                        ) : (
                          <input
                            //ref={register({ required: true })}
                            type="text"
                            placeholder=""
                            name="texonomyCode"
                            style={{
                              width: "100px",
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={familyInfo.familyMemberName}
                            onChange={(e) => billingInfo(e)}
                          />
                        )}{" "}
                        )
                      </>
                    </p>
                    <p>
                      <>
                        Natural Environment Support Fee

                      </>
                    </p>
                    <p>
                      <>
                        Travel (MapQuest Attached) (Total Miles
                        {isSubmit ? (
                          relValue?.consultation?.billingInfo?.totalMiles
                        ) : (
                          <input
                            //ref={register({ required: true })}
                            type="text"
                            placeholder=""
                            name="totalMiles"
                            style={{
                              width: "50px",
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={familyInfo.familyMemberName}
                            onChange={(e) => billingInfo(e)}
                          />
                        )}
                        )
                      </>
                    </p>
                    <p>
                      <> Total Amount Requested
                        {/* {isSubmit ? (
                          relValue?.consultation?.billingInfo?.totalRequestedAmount
                        ) : (
                          <input
                            //ref={register({ required: true })}
                            type="number"
                            placeholder=""
                            name="totalRequestedAmount"
                            style={{
                              width: "50px",
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={familyInfo.familyMemberName}
                            onChange={(e) => billingInfo(e)}
                          />
                        )} */}
                      </>
                    </p>
                  </div>
                  <div
                    style={{ display: "block", width: "50%", float: "left", textAlign: "left" }}
                  >
                    <p>
                      <>
                        <>
                          Diagnosis Code{" "}
                          {isSubmit ? (
                            relValue?.consultation?.billingInfo?.diagnosisCode
                          ) : (
                            <input
                              //ref={register({ required: true })}
                              type="text"
                              placeholder=""
                              name="diagnosisCode"
                              style={{
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: "1px solid #222",
                              }}
                              className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                }`}
                              defaultValue={familyInfo.familyMemberName}
                              onChange={(e) => billingInfo(e)}
                            />
                          )}
                        </>
                      </>
                    </p>
                    <p>
                      <>
                        ${" "}
                        {isSubmit ? (
                          relValue?.consultation?.billingInfo?.texonomyFee
                        ) : (
                          <input
                            //ref={register({ required: true })}
                            type="text"
                            placeholder=""
                            name="texonomyFee"
                            style={{
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={familyInfo.familyMemberName}
                            onChange={(e) => billingInfo(e)}
                          />
                        )}
                      </>
                    </p>
                    <p>
                      <>
                        ${" "}
                        {isSubmit ? (
                          relValue?.consultation?.billingInfo?.travelFee
                        ) : (
                          <input
                            //ref={register({ required: true })}
                            type="text"
                            placeholder=""
                            name="travelFee"
                            style={{
                              borderTop: 0,
                              borderLeft: 0,
                              borderRight: 0,
                              borderBottom: "1px solid #222",
                            }}
                            className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                              }`}
                            defaultValue={familyInfo.familyMemberName}
                            onChange={(e) => billingInfo(e)}
                          />
                        )}
                      </>
                    </p>
                    <p>
                      ${" "}
                      {isSubmit ? (
                        relValue?.consultation?.billingInfo?.supportFee
                      ) : (
                        <input
                          //ref={register({ required: true })}
                          type="text"
                          placeholder=""
                          name="supportFee"
                          style={{
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: "1px solid #222",
                          }}
                          className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={familyInfo.familyMemberName}
                          onChange={(e) => billingInfo(e)}
                        />
                      )}
                    </p>
                    <p>{" "}$
                      {isSubmit ? (
                        relValue?.consultation?.billingInfo?.totalRequestedAmount
                      ) : (
                        <input
                          //ref={register({ required: true })}
                          type="number"
                          placeholder=""
                          name="totalRequestedAmount"
                          style={{
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: "1px solid #222",
                          }}
                          className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={familyInfo.familyMemberName}
                          onChange={(e) => billingInfo(e)}
                        />
                      )}
                    </p>
                  </div>
                </div>
                <br />
                <div
                  style={{
                    display: "block",
                    width: "100%"
                  }}
                >
                  <p style={{
                    display: "block",
                    width: "100%"
                  }}>
                    <small
                      style={{ verticalAlign: "top" }}
                    >
                      Copy to:
                    </small>{" "}
                    <small style={{ display: "inline-block" }}>
                      Family/ Guardian <br />
                      Early Steps Service
                      <br />
                      Coordinator within 5 business days
                      <br />
                      Team Providers (whether present or not)
                    </small>
                  </p>
                </div>
              </div>
            </div>

            <div className="card-lg page3">
              <div className="pdf-header" >
                <h4 style={{ textAlign: "center" }}>
                  CONSULTATION DOCUMENTATION FORM INSTRUCTIONS
                </h4>
                <br />
                <p style={{
                  display: "block",
                  width: "100%"
                }}>
                  The purpose of this form is to serve as uniform documentation
                  of consultation services. Each team member who is billing must
                  have a form completed for each Consultation in which they
                  participate. During consultation sessions, the members
                  participating should appoint a recorder to LEGIBLY complete
                  the form from Child’s Name to IFSP Team Meeting Yes No. Copies
                  should then be made for each participant and the family. The
                  original goes to the Service Coordinator to place in the
                  child’s file. Each enrolled Early Steps provider can bill for
                  Consultation using the form as invoice documentation. Although
                  they may participate in the consultation, professionals and
                  providers who are not enrolled would not be able to bill. If
                  any team provider did not participate in the Consultation
                  session, a copy should be provided to them so they can be
                  informed.
                </p>
              </div>
              <div className="pdf-para1">
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    marginTop: 15,
                  }}
                >
                  <h5>
                    Instructions:
                  </h5>
                  <br />
                  <div
                    style={{ display: "block", width: "50%", float: "left" }}
                  >
                    <p>
                      <b> Child’s Name:</b>
                      <p
                        className="w-100per"
                        style={{ textDecoration: "underline" }}
                      > <i>Full name of child</i></p>
                    </p>
                    <p>
                      <b> Service Coordinator:</b>
                      <p
                        className="w-100per"
                        style={{ textDecoration: "underline" }}
                      > <i>Name</i></p>
                    </p>
                    <p>
                      <><b>Start Time:</b> <i>Beginning time of consultation session</i></>
                    </p>
                  </div>
                  <div style={{ width: "50%", float: "left" }}>
                    <b>DOB:</b>
                    <p>
                      <p
                        className="w-100per"
                        style={{ textDecoration: "underline" }}
                      ><i> Date of birth of child</i> </p>
                    </p>
                    <p>
                      <b>Date of Consultation:</b>
                      <p
                        className="w-100per"
                        style={{ textDecoration: "underline" }}
                      > <i>MM/DD/YYYY</i></p>
                    </p>
                    <p>
                      <><b>End Time:</b> <i>End time of consultation session</i></>
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <p style={{
                  display: "block",
                  width: "100%"
                }}>
                  <b>Location:</b> <i>  This is the location where the meeting occurred. If
                    face-to-face, enter the location as i.e. Home, Local Early
                    Steps, Playpen Therapy; if occurred by phone, enter the
                    location as Phone.</i>
                </p>
                <p style={{
                  display: "block",
                  width: "100%"
                }}>
                  <b>Successes and Challenges to implementing strategies and
                    achieving goals:</b> <i>Narrative of the discussion, by individual
                      outcome.</i>
                </p>
                <p style={{
                  display: "block",
                  width: "100%"
                }}>
                  <b>The team (family, caregivers, primary service provider and
                    supporting providers) will continue or modify the following
                    strategies to achieve goals:</b> Narrative of the
                  recommendation(s) resulting from the consultation, by
                  individual outcome.
                </p>
                <p style={{
                  display: "block",
                  width: "100%"
                }}>
                  <b>PSP:</b> <i> Name and credentials of the current Primary Service
                    Provider</i>
                </p>
                <p style={{
                  display: "block",
                  width: "100%"
                }}>
                  <b>Consulting Team Members:</b><i> List all members participating in the
                    consultation and check Face-to-Face or Phone and obtain
                    signatures of those present.</i>
                </p>
                <p style={{
                  display: "block",
                  width: "100%"
                }}>
                  <b>Family Participation:</b><i> The name(s) of the family member(s) and
                    check Phone, Face-to-Face or Declined Invitation</i>
                </p>
                <p style={{ width: "100%", marginTop: 30, fontSize: "120%", textDecoration: "underline" }}>
                  <b>
                    <u>
                      ALL THE ABOVE FIELDS SHOULD BE IDENTICAL FOR ALL
                      PARTICIPANTS’ FORMS
                    </u>
                  </b>
                </p>
                <p style={{
                  display: "block",
                  width: "100%"
                }}>
                  When each provider receives their copy of the completed form,
                  they will complete the remaining fields before billing.
                </p>
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    marginTop: 15,
                  }}
                >
                  <br />
                  <div
                    style={{ display: "block", width: "60%", float: "left" }}
                  >
                    <p>
                      <b>Provider/Participant Name (Print):{" "}</b>
                      <p
                        className="w-100per"
                        style={{ textDecoration: "underline" }}
                      > <i> LEGIBLE name of provider/participant </i></p>
                    </p>

                  </div>
                  <div
                    style={{ display: "block", width: "40%", float: "left" }}
                  >
                    <p>
                      <b>Signature:{" "}</b>
                      <p
                        className="w-100per"
                        style={{ textDecoration: "underline" }}
                      > <i>Provider/Participant signature</i></p>

                    </p>
                  </div>
                </div>
                <b style={{ textAlign: "center", display: "block", width: "100%", }}>
                  <i>
                    Each participant should find their designation and sign, if
                    face-to-face. Provider signature lines should include the
                    code signifying if participation was Face-to-Face or Phone{" "}
                  </i>
                </b>

                <p style={{ textAlign: "center", width: "100%", }}>
                  Consultation time must be authorized on the Individualized
                  Family Support Plan (IFSP).Billing is based on the location of
                  the Consultation session.
                </p>
              </div>

              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {/* {props?.data?.replyNeeded && ( */}
                  <button
                    type={isLoading ? "button" : "submit"}
                    className="btn btn-success float-right1 mt-10 mb-10"
                    disabled={consultation.teamMemberSign ? false : true}
                  >
                    {isLoading ? "Loading...." : "Submit"}
                  </button>
                  {/* )} */}
                </>
              ) : (
                <>
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ConsultationDocumentationForm;
