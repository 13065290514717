import React, { useState } from 'react';
import { useSelector } from "react-redux";
import moment from "moment";
import ProcedureTableList from './goalsComponent.js/ProcedureTableList';
import LongTermGoals from './goalsComponent.js/LongTermGoals';
import ShortTermsGoal from './goalsComponent.js/ShortTermsGoal';
import { map } from 'lodash';

const ArchiveGoalPrint = React.forwardRef((props, ref) => {
    let propsData = props.goals;
    let formateData = JSON.stringify({ propsData })
    console.log("session note detail", props.goals.caseNoteListDto);
    const dateMoment = moment.utc(props.goals.caseNoteListDto?.caseNoteDTO?.sessionDto?.startDateTime).utc().local();
    const date = dateMoment.format("DD MMMM, yyyy");
    const icdCodeActive = props.goals.caseNoteListDto?.caseNoteDTO?.diagnosisId;
    const soapNoteBillingData = useSelector(
        (st) => st.appState?.soapNoteBillingData
    );
    const {
        name,
        address1,
        address2,
        city,
        country,
        state: billingState,
        zipCode,
        logoUrl,
    } = soapNoteBillingData;
    const joinWithComma = (...params) => params.filter(Boolean).join(", ");
    const relValue =
    typeof props?.data?.reply === "undefined"
      ? ""
      : JSON.parse(formateData);
    console.log("relValue goals",formateData,relValue);
    return (
        <>
            <div ref={ref} className="col-md-12">
            {propsData.length === 0 ? "No Data Found" : <>
            {propsData?.map((item,i)=>(
                <>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ width: "40%" }}>
                        {logoUrl && (
                            <img
                                src={logoUrl}
                                alt={name}
                                className="img-fluid c-casenote-billing-info__image"
                            />
                        )}
                    </div>
                    <div style={{ width: "60%", textAlign: "right" }}>
                        <p className="mb-0" style={{ marginBottom: 0, width: "100%" }}>
                            <b style={{ fontSize: "1.1em" }}>{name}</b>
                        </p>
                        <p className="mb-1" style={{ width: "100%" }}>
                            <span className="d-block" style={{ marginBottom: 0 }}>
                                {joinWithComma(address1, address2)}
                            </span>
                            <span className="d-block" style={{ marginBottom: 0 }}>
                                {joinWithComma(city, billingState, country, zipCode)}
                            </span>
                        </p>
                    </div>
                </div>
                
                    
                <table key={i}>
                    <tbody>
                    <tr>
                        <td>
                            <img src={item?.childProfilePic} width={60} style={{ borderRadius: "50%" }} />
                            <p style={{ width: "100%", marginBottom: "0" }}>{item?.childName}
                                &nbsp;
                                {item?.goalsDto?.caseNoteListDto?.childDOB && (
                                    <small>
                                        (<b>DOB: </b>
                                        {moment(item?.goalsDto?.caseNoteListDto?.childDOB).format("MM/DD/YYYY")})
                                    </small>
                                )}
                                </p>
                        </td>
                    </tr>
                    </tbody>
                   <tr>
                        <td>
                            <p><b>Session Id</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.sessionDto?.id}</p>
                        </td>
                        <td>
                            <p><b>Session Date</b></p>
                            <p style={{ marginTop:"0" }}>{moment.utc(item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.sessionDto?.startDateTime).utc().local().format("DD MMMM, yyyy")}</p>
                        </td>
                        <td>
                            <p><b>Therapist</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.therapist}</p>
                        </td>
                    </tr>
                     <tr>
                        <td>
                            <p><b>Therapy</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.sessionDto?.therapy?.name}</p>
                        </td>
                        <td>
                            <p><b>Clinician NPI Number</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.clinicianNpiNumber}</p>
                        </td>
                        <td>
                            <p><b>Group NPI Number</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.groupNpiNumber}</p>
                        </td>
                    </tr>
                     <tr>
                        <td>
                            <p><b>ICD 10 Code</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.procedureList?.map((val)=>(
                                val?.diagnosis?.code + " " + val?.diagnosis?.description
                            ))}</p>
                        </td>
                        <td>
                            <p><b>CPT Code</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.procedureList?.map((val)=>(
                                val?.cptCode?.procCode + " " + val?.cptCode?.description
                            ))}</p>
                        </td>
                    </tr>
                    <tr style={{ marginBottom: "0" }}>
                        <td>
                            <p><b>Duration/Unit</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.duration}</p>
                        </td>
                        <td>
                            <p><b>Units</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.units}</p>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><p><b>Therapist Signature</b></p>
                            <div className="d-flex">
                                <div
                                    className="sign-img"
                                    style={{
                                        width: "160px",
                                        height: "60px",
                                        border: 0,
                                        marginBottom: "10px",
                                    }}
                                >
                                    <img
                                        src={item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.therapistSignatureKey}
                                        alt="my signature"
                                        className="sign-img1"
                                        style={{ width: "130px", height: "40px" }}
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            {item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.familySignatureKey ?
                                <>
                                    <p><b>Family Signature</b></p>
                                    <div className="d-flex">
                                        <div
                                            className="sign-img"
                                            style={{
                                                width: "160px",
                                                height: "60px",
                                                border: 0,
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <img
                                                src={item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.familySignatureKey}
                                                alt="my signature"
                                                className="sign-img1"
                                                style={{ width: "130px", height: "40px" }}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <p><b>Description</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.sessionDescription}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><b>Session Note</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.description}</p>
                        </td>
                    </tr>
                    {item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.translatedDescription ?
                        <tr>
                            <td>
                                <p><b>Translation</b></p>
                                <p style={{ marginTop:"0" }}>{item?.goalsDto?.caseNoteListDto?.caseNoteDTO?.translatedDescription}</p>
                            </td>
                        </tr>
                        :
                        ''
                    }
                </table>
                <br/>
                <p style={{ pageBreakAfter: "always" }}>&nbsp;</p>
                <h3>Goals and Milestones</h3>
                <hr />
                <table>
                    <tr>
                        <td>
                            <p><b>Session Date</b></p>
                            <p style={{ marginTop:"0" }}>{moment(item?.goalsDto?.sessionDate).format("MM/DD/YYYY")}</p>
                        </td>
                        <td>
                            <p><b>Next Session Date</b></p>
                            <p style={{ marginTop:"0" }}>{moment(item?.goalsDto?.nextSessionDate).format("MM/DD/YYYY")}</p>
                        </td>
                        <td>
                            <p><b>Therapy Type</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.therapyType}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><b>Frequency of Visit</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.visitingFrequency}</p>
                        </td>
                        <td>
                            <p><b>Estimated Duration</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.estimatedDuration}</p>
                        </td>
                        <td>
                            <p><b>Location of Treatment</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.location}</p>
                        </td>
                    </tr>
                    <tr><td><p><b>Procedure</b></p></td></tr>
                </table>
                <ProcedureTableList procedureList={item?.goalsDto?.procedureList}/>
                <table>
                    <tr>
                        <td>
                            <p><b>Session Note</b></p>
                        </td>
                        <td></td>
                        <td style={{ fontSize: "12px", textAlign: "right" }}><p><b>Last update:</b>{" "}
                            {item?.goalsDto?.sessionNoteTimestamp &&
                                moment
                                    .utc(item?.goalsDto?.sessionNoteTimestamp)
                                    .utc()
                                    .local()
                                    .format("MM-DD-yyyy hh:mm a")}</p></td>
                    </tr>
                    <tr>
                        <td><p style={{ width: "100%", marginTop:"0" }}>{item?.goalsDto?.sessionNote}</p></td>
                    </tr>
                    <tr>
                        <td>
                            <p><b>Plan</b></p>
                            <p style={{ marginTop:"0" }}>{item?.goalsDto?.plan}</p>
                        </td>
                    </tr>
                    <tr><td><p><b>Long Term Goals</b></p></td></tr>
                </table>
                <LongTermGoals longTermGoals={item?.goalsDto?.longTermGoals}/>
                <table style={{ width: "100%", display: "table", borderCollapse: "collapse", marginBottom: "10px" }}>
                    <tr><td><p><b>Short Term Goals</b></p></td></tr>
                </table>
                <ShortTermsGoal shortTermGoals={item?.goalsDto?.shortTermGoals} />
                <br/>
                <br/>
                <table>
                    <tr>
                        <td>
                            <p><b>Signature</b></p>
                            <div className="d-flex">
                                {/* <img
                      className="sign-img"
                      src={caseNoteDTO?.therapistSignatureKey}
                      alt="signature"
                    /> */}
                                <div
                                    className="sign-img"
                                    style={{
                                        width: "160px",
                                        height: "60px",
                                        border: 0,
                                        marginBottom: "10px",
                                    }}
                                >
                                    <img
                                        src={item?.goalsDto?.therapistSignatureKey}
                                        alt="my signature"
                                        className="sign-img1"
                                        style={{ width: "130px", height: "40px" }}
                                    />
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td style={{ fontSize: "12px", textAlign: "right" }}><p><b>Date:</b>{" "}
                            {item?.goalsDto?.signatureDate &&
                                moment
                                    .utc(item?.goalsDto?.signatureDate)
                                    .utc()
                                    .local()
                                    .format("MM-DD-yyyy hh:mm a")}</p></td>
                    </tr>
                </table>
                <p style={{ pageBreakAfter: "always" }}>&nbsp;</p>
                </>
                
                )) }
                </>
                }
            </div>
        </>
    );
}
)
export default ArchiveGoalPrint;