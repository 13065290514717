import React, { useState, useEffect, useRef } from "react";
import Pagination from "react-responsive-pagination";
import GoalsHeader from "./GoalsHeader";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { useDispatch, useSelector } from "react-redux";
import GoalList from "./GoalList";
import Table from "react-bootstrap/esm/Table";
import classNames from "classnames";
import moment from "moment";
import CaseNoteDownload from "../../components/case-note-detail/CaseNoteDownload";
import ArchiveGoalPrint from "../Therapist/ArchiveGoalPrint";
import appAction from "../../redux/actions/appAction";

function Goals(props) {
  let params = useParams();
  const formRef = useRef(null);
  console.log("goals props", props);
  const history = useNavigate();
  const [currentTab, setCurrentTab] = useState(
    params?.tab || "drafted"
  );
  const dispatch = useDispatch();

  const [userType, setUserType] = useState();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [filter, setfilter] = useState("");
  const [goals, setGoals] = useState([]);
  const [search, setsearch] = useState("");
  const [loading, setloading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    totalPages: 0,
    page: 0,
  });
  //const [archive, setArchive] = useState(null);
  ///session-service/api/archive/goals (GET)
  //const archiveFunction = () => {};

  useEffect(() => {
    adminService.getAll(`user-profiles/user-detail`).then(
      (res) => {
        if (res?.status === "Success") {
          console.log("user-detail", res.data);
          setUserType(res.data?.userType);
          // setProfile(res.data);
          //dispatch(adminAction.setProfileData(res.data));
        } else {
          // res?.message
          //   ? toast.error(res?.message)
          console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );
    const url =
      filter === "drafted"
        ? `goals/all?page=${pageInfo?.page}&size=20&drafted=true&name=${search}&sort=sessionDate,DESC`
        : filter === "submitted"
          ? `goals/all?page=${pageInfo?.page}&size=20&submitted=true&completed=false&name=${search}&sort=sessionDate,DESC`
          : filter === "completed"
            ? `goals/all?page=${pageInfo?.page}&size=20&completed=true&name=${search}&sort=sessionDate,DESC`
            : // : filter === "archive"
            // ? `archive/goals`
            `goals/all?page=${pageInfo?.page}&size=20&name=${search}&sort=sessionDate,DESC`;
    console.log("filter", filter);
    adminService
      .getAll(url)
      .then((res) => {
        if (res?.data) {
          setGoals(res?.data?.content);
          setPageInfo({
            totalPages: res?.data?.totalPages,
            page: res?.data?.number,
          });
        }
        setloading(false);
      })
      .catch((e) => console.log("e", e));
  }, [filter, search, pageInfo?.page]);

  const onSearch = (val) => {
    console.log(val);
  };

  const handlePagination = (p) => {
    setPageInfo({
      ...pageInfo,
      page: p,
    });
  };

  //   const TableHeader = () => {
  //     return (
  //       <>
  //         <tr>
  //           <th>Client Name</th>
  //           <th>Session Date</th>
  //           <th>Therapy</th>
  //           <th>Therapist</th>
  //           <th>Action</th>
  //         </tr>
  //       </>
  //     );
  //   };
  const onPdfCreate = (cb) => {
    setIsDownloading(true);
    dispatch(appAction.setAppLoading(true));
    setTimeout(() => {
      if (typeof cb === "function") {
        cb();
      }
    }, 200);
  };

  const onPdfComplete = () => {
    setIsDownloading(false);
    dispatch(appAction.setAppLoading(false));
  };
  const TableHeader = () => {
    return (
      <div className="p-case-notes__table-header">
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header">
          Client Name
        </div>
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header">
          Session Date
        </div>
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header">
          Therapy
        </div>
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header">
          Therapist
        </div>
        <div
          className="ptb-20 p-case-notes__table-head-col cn-tbl-header"
          style={{ flex: "1 1 45px" }}
        >
          Status
        </div>
        <div
          className="ptb-20 p-case-notes__table-head-col cn-tbl-header"
          style={{ flex: "0 1 100px" }}
        >
          Action
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="p-case-notes col-md-10 d-flex flex-column">
        <div
          className={"card border-0 shadow-sm mt-10 mb-10"}
          style={{ marginBottom: 20 }}
        >
          <GoalsHeader
            title="All Goals"
            search={search}
            onSearch={onSearch}
            onChange={setsearch}
            currentTab={currentTab}
            setfilter={setfilter}
          />
          {/* <Table id="table" striped bordered hover>
            <TableHeader />
            <GoalList list={goals} />
          </Table> */}
        {/* <div className="d-none"><ArchiveGoalPrint ref={formRef} goals={goals} /></div>
          <CaseNoteDownload
                    downloadName={`ArchiveGoalsAndMilestone.pdf`}
                    targetRef={formRef}
                    beforeStart={onPdfCreate}
                    onComplete={onPdfComplete}
                  />*/}
          <TableHeader /> 
          {loading ? <div style={{ paddingTop: 200 }}>
            <div className=" bg-white">
              <div className="loader" />
            </div>
          </div> : <>
            {goals.length ?
              goals.map((goal) => {
                return (
                  <div
                    //   onClick={onClick}
                    className={classNames("p-case-notes__table-row", {
                      "p-case-notes__table-row--active": goal?.active,
                    })}
                  >
                    <div className="p-case-notes__table-col">
                      <div className="p-case-notes__child-info">
                        <img
                          src={
                            goal?.childProfilePic
                              ? goal?.childProfilePic
                              : "/resources/assets/defaultprofile.png"
                          }
                          alt={goal?.childName}
                          className="p-case-notes__child-info-img"
                        />
                        <span className="text-capitalize">{goal?.childName}</span>
                      </div>
                    </div>
                    <div className="p-case-notes__table-col p-case-notes__table-col--date">
                      {moment
                        .utc(goal?.sessionDate)
                        .utc()
                        .local()
                        .format("DD MMMM, yyyy")}{" "}
                      <br />
                      {moment
                        .utc(goal?.sessionDate)
                        .utc()
                        .local()
                        .format("hh:mm A")}
                    </div>
                    <div className="p-case-notes__table-col p-case-notes__table-col--therapy">
                      {goal?.goalsDto?.therapyType}
                    </div>
                    <div className="p-case-notes__table-col p-case-notes__table-col--therapist">
                      {goal?.therapist}
                    </div>
                    <div
                      className="p-case-notes__table-col p-case-notes__table-col--therapist"
                      style={{ flex: "1 1 45px" }}
                    >
                      {goal?.goalsDto?.completed === true &&
                        goal?.goalsDto?.drafted === false
                        ? "Completed"
                        : goal?.goalsDto?.drafted === true
                          ? "Drafted"
                          : "Submitted"}
                    </div>
                    <div
                      className="p-case-notes__table-col p-case-notes__table-col--action view-all-link"
                      style={{ flex: "0 1 100px" }}
                    >
                      <Link
                        className="btn px-0 view-link"
                        to={`/misgoals/${goal?.goalsDto?.caseNoteId}`}
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                );
              })
              : <p className="p-3 text-center"><b>No Data Found</b></p>}</>}
          <div className="row">
            <div
              style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}
            >
              <div style={{ marginTop: "15px" }}>
                <Pagination
                  total={pageInfo?.totalPages}
                  current={pageInfo?.page + 1}
                  onPageChange={(page) => handlePagination(page - 1)}

                /></div>
              {userType == "ROLE_THERAPIST" ?
                <button
                  className=" btn btn-success ml-3"
                  //onClick={() => setfilter("archive")}
                  onClick={() => history("/archiveGoals")}
                >
                  Archive
                </button>
                :
                ''}
              {userType == "ROLE_RCE" ?
                <button
                  className=" btn btn-success ml-3"
                  //onClick={() => setfilter("archive")}
                  onClick={() => history("/archiveGoals")}
                >
                  Archive
                </button>
                :
                ''}
            </div>
          </div>
          {/* <div className="row justify-content-end">
            <div className="p-3">

            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Goals;
