import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "./CommonComponents";
import SignDate from "./SignDate";

function ReleaseToCommunicateForm(props) {
  const formRef = React.useRef(null);
  const sigCanvas = useRef({});
  const sigWitnessCanvas = useRef({});
  const profile = useSelector((state) => state.adminUser.profile);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const { inputs, setInputs, signatures, setSignatures } = useFormContext();
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [witnessModal, setWitnessModal] = useState(false);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [witnessImageURL, setWitnessImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mediareleaseform, setmediareleaseform] = useState({});

  const [isSubmit, setIsSubmit] = useState(
    props?.from != "family" || props?.data?.reply
      ? true
      : props?.data?.replyNeeded == false
        ? !props?.data.replyNeeded
        : false
  );
  const [familyInfo, setFamilyInfo] = useState();

  useEffect(() => {
    setFamilyInfo({
      id: props?.data?.id ? props.data.id : null,
      familyMemberName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      familyMemberDob: props?.data?.familyMemberDob
        ? props?.data?.familyMemberDob
        : props?.data?.familyMember
          ? props.data.familyMember.dob
          : " ",
      familySign:
        props?.data?.familySignDate && props?.data?.family
          ? props?.data?.family?.userProfile?.signatureUrl
          : false,
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
      consent1: false,
      consent2: false,
    });
    setmediareleaseform({
      familySign:
        props?.data?.familySignDate && props?.data?.family
          ? props?.data?.family?.userProfile?.signatureUrl
          : false,
      patientName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      dateofbirth: props?.data?.familyMemberDob
        ? props?.data?.familyMemberDob
        : props?.data?.familyMember
          ? props.data.familyMember.dob
          : " ",
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
      consent1: false,
      consent2: false,
    });
  }, [props?.data]);
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const [signatureErr, setSignatureErr] = useState("");

  const clearWitness = () => sigWitnessCanvas.current.clear();
  const saveWitness = () => {
    setWitnessImageURL(
      sigWitnessCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setWitnessModal(false);
    setFamilyInfo({
      ...familyInfo,
      witnessSignDate: moment(new Date()).format("DD-MM-YYYY h:s:a"),
    });
  };

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setmediareleaseform({
      ...mediareleaseform,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setmediareleaseform({
              ...mediareleaseform,
              familySign: res.data.signatureKey,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const onSubmitFamilyForm = (isSubmit = false) => {
    setIsLoading(true);
    if (props?.data?.replyNeeded) {
      if (familyInfo?.familySignDate) {
        let formData = new FormData();
        // formData.append("signature", familyImageURL);
        formData.append(
          "formUpdationForm",
          new Blob(
            [
              JSON.stringify({
                ...familyInfo,
                jsonFormattedForm: JSON.stringify({ mediareleaseform }),
              }),
            ],
            {
              type: "application/json",
            }
          )
        );
        adminService
          .onboardPutUser(
            `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
            formData
          )
          .then((response) => {
            setIsLoading(true);
            toast.success("Form Submitted successfully")
            props?.from == "family" && props.handleBack();
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        setSignatureErr("Signature is required!");
      }
    } else if (props?.from === "admin") {
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("signature", familyImageURL);
      console.log("formData 220", formData);
      formData.append(
        "formCreationForm",
        new Blob([JSON.stringify(props?.inputs)], {
          type: "application/json",
        })
      );
      adminService
        .onboardUser(
          `submitted-forms`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Form Submitted successfully");
          props.setIsFocused(!props.isFocused);
          props?.from == "admin" && props.handleBack("reload");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };

  const updateInfo = (e) => {
    const { name, value } = e.target;
    setmediareleaseform({ ...mediareleaseform, [name]: value });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "ReleaseToCommunicationForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const relValue = props.data.reply
    ? JSON.parse(props?.data?.jsonFormattedForm)
    : "";
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        <div
          style={{
            position: 'absolute', top: 0, right: "100px"

          }}>
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.familySignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}</div>
        <div className="card-lg">
          <FormHeader
            logoUrl={logoUrl}
            title="RELEASE TO COMMUNICATE BY EMAIL, TEXT MESSAGE & OTHER NON-SECURE MEANS"
          />
          <hr />
          <br />
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content">
              <div className="w-100per">
                <div style={{ width: "60%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Client Name : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.mediareleaseform?.patientName
                    ) : (
                      <input
                        {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                        type="text"
                        placeholder="Client's name"
                        name="patientName"
                        className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberName}
                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "40%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Date of Birth : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.mediareleaseform?.dateofbirth.trim() &&
                      moment(relValue?.mediareleaseform?.dateofbirth).format(
                        "MM/DD/YYYY"
                      )
                    ) : (
                      <input
                        {...register('dateofbirth', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberDob === '' ? true : false })}
                        type="date"
                        name="dateofbirth"
                        placeholder="DOB"
                        className={`form-control form-input form-input-w-60per ${errors.familyMemberDob && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberDob}
                        onChange={(e) => updateInfo(e)}
                        disabled={familyInfo?.familyMemberDob === '' ? false : true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="w-100per">
                <p className="w-100per">
                  It may become useful during the course of treatment to
                  communicate by email, text message (e.g. “SMS”) or other
                  electronic methods of communication. Be informed that these
                  methods, in their typical form, are not confidential means of
                  communication. If you use these methods to communicate with
                  your provider in {name} Network (MEIS), there is a reasonable
                  chance that a third party may be able to intercept and
                  eavesdrop on those messages. The kinds of parties that may
                  intercept these messages include, but are not limited to:
                </p>
                <ul>
                  <li>
                    People in your home or other environments who can access
                    your phone, computer or other devices that you use to read
                    and write messages.{" "}
                  </li>
                  <li>
                    Your employer, if you use your work email to communicate
                    with MEIS{" "}
                  </li>
                  <li>
                    Third parties on the internet such as server administrators
                    and others who monitor internet traffic
                  </li>
                </ul>
                <p className="w-100per">
                  If you do not want others to potentially access these
                  communications sent via e-mail or text, please talk with your
                  provider about ways to keep your communications safe and
                  confidential.{" "}
                </p>
              </div>

              <br />
              <div style={{ textAlign: "center" }}>
                <h5
                  className="mt-10 mb-10"
                  style={{ textDecoration: "underline" }}
                >
                  CONSENT FOR TRANSMISSION OF PROTECTED HEALTH INFORMATION BY
                  NON-SECURE MEANS
                </h5>
              </div>
              <br />
              <div className="col-md-12">
                <input
                  {...register('consent1', {
                    onChange: (e) => setmediareleaseform({
                      ...mediareleaseform,
                      consent1: !mediareleaseform?.consent1,
                    }), required: relValue?.mediareleaseform?.consent1 === false ? true : false
                  })}
                  type="checkbox"
                  name="consent1"
                  className={`form-check-inline ${errors.consent1 && "is-invalid"
                    }`}
                  defaultValue={relValue?.mediareleaseform?.consent1}
                  checked={relValue?.mediareleaseform?.consent1}
                  // onChange={(e) =>
                  //   setmediareleaseform({
                  //     ...mediareleaseform,
                  //     consent1: !mediareleaseform?.consent1,
                  //   })
                  // }
                  disabled={
                    profile?.userType === "ROLE_RCE" || isSubmit ? true : false || relValue?.mediareleaseform?.consent1 === false
                  }
                />
                I consent to allow {name} Network to use unsecured email and
                mobile phone text messaging to transmit to me the following
                protected health information.
                <ul>
                  <li>
                    Information related to the scheduling of meetings or other
                    appointments{" "}
                  </li>
                  <li>Information related to billing and payment </li>
                  <li>
                    Consultation with other therapists and service coordinators{" "}
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <input
                  {...register('consent2', {
                    onChange: (e) =>
                      setmediareleaseform({
                        ...mediareleaseform,
                        consent2: !mediareleaseform?.consent2,
                      }), required: relValue?.mediareleaseform?.consent2 === false ? true : false
                  })}
                  className={`form-check-inline ${errors.consent1 && "is-invalid"
                    }`}
                  type="checkbox"
                  name="consent2"
                  defaultValue={relValue?.mediareleaseform?.consent2}
                  checked={relValue?.mediareleaseform?.consent2}
                  // onChange={(e) =>
                  //   setmediareleaseform({
                  //     ...mediareleaseform,
                  //     consent2: !mediareleaseform?.consent2,
                  //   })
                  // }
                  disabled={
                    profile?.userType == "ROLE_RCE" || isSubmit ? true : false || relValue?.mediareleaseform?.consent2 === false
                  }
                />
                I DO NOT consent to allow {name} Network to use unsecured email
                and mobile phone text messaging.
              </div>
              <br />
              <div className="w-100per">
                <p className="w-100per">
                  I have been informed of the risks, including but not limited
                  to, my confidentiality in treatment of transmitting my
                  protected health information by unsecured means. I understand
                  that I am not required to sign this agreement in order to
                  receive treatment. I also understand that I may terminate the
                  consent at any time.{" "}
                </p>
              </div>

              <SignDate
                date={relValue?.mediareleaseform?.familySignDate || mediareleaseform?.familySignDate}
                familyImageURL={familyImageURL}
                isSubmit={isSubmit}
                setOpen={setOpen}
                signTitle="Guardian"
                familyInfo={familyInfo}
              />

              {/* <SignDate
                date={familyInfo?.witnessSignDate}
                familyImageURL={witnessImageURL}
                isSubmit={isSubmit}
                setOpen={setWitnessModal}
                signTitle="Witness"
              />

              <DigitalSignPopup
                modalState={witnessModal}
                setModalState={setWitnessModal}
                save={saveWitness}
                clear={clearWitness}
                sigCanvas={sigWitnessCanvas}
              /> */}

              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />
              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {signatureErr && (
                    <p className="text-red">
                      <small>{signatureErr}</small>
                    </p>
                  )}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {props?.data.replyNeeded && (
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={
                        mediareleaseform?.familySign &&
                          mediareleaseform?.consent1 &&
                          mediareleaseform?.consent2
                          ? false
                          : true
                      }
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {props?.from === 'admin' && props?.isFormOpen &&
                    <>
                      <button
                        className="btn btn-theme-green"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btn-success float-right1 mt-10 mb-10"
                      >
                        {isLoading ? "Loading...." : "Submit"}
                      </button>
                    </>

                  }
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ReleaseToCommunicateForm;
