import React from 'react';
import TeamCard from './TeamCard';

function TeamDetail(props) {
    const interpreterid = props.teamData.interpreter && props.teamData.interpreter.id;
    const {
        email: intEmail,
        phone: intPhone,
        lastName: intLastName,
        firstName: intFirstName,
        profilePicUrl: intProfilePicUrl,
    } = props.teamData?.interpreter?.userProfile || {}

    return (
        <>
            <div className={` c-team-list session-detail-comp custom-table-view ${props.bgColor}`}>
                {
                    props.teamData?.therapists?.length > 0 && <span className="c-team-list__saperator-heading pt-3 d-block text-muted" >Therapist</span>
                }
                {props.teamData?.therapists && props.teamData.therapists.map((item) => {
                    const {
                        profilePicUrl,
                        firstName, lastName,
                        phone, email,
                    } = item?.userProfile || {}
                    return <TeamCard 
                        phone={phone} 
                        email={email} 
                        firstName={firstName} 
                        lastName={lastName} 
                        userImg={profilePicUrl} 
                        editLink={`/edit-therapist-details/` + item.id}
                        key={item.id}
                        classname={props.bgColor === 'bg-color1' ? 'bg-white' : ''} />
                    }
                )}
                {
                    props.teamData.interpreter && 
                    <>
                    <span className="c-team-list__saperator-heading pt-3 d-block text-muted" >Interpreter</span>
                    <TeamCard 
                        phone={intPhone} 
                        email={intEmail} 
                        firstName={intFirstName} 
                        lastName={intLastName} 
                        userImg={intProfilePicUrl} 
                        editLink={`edit-interpreter-details/` + interpreterid}
                        classname={props.bgColor === 'bg-color1' ? 'bg-white' : ''} 
                    />
                    </>
                }
                {props.teamData && props.teamData.length === 0 &&
                    <div className={`session-detail-section mt-3 ${props.bgColor === 'bg-color1' ? 'bg-white' : ''}`}>
                        <div className="text-center">
                            <p className="name mt-2">No Team Available</p>
                        </div>
                    </div>
                }
            </div>

        </>
    )
}

export default TeamDetail;