import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

const AppLoader = () => {
    const appLoading = useSelector(state => state.appState?.appLoading)
    return (
        <div className={classNames("c-app-loader", {
            "c-app-loader--show": appLoading
        })} >
            <div className="loader"></div>
        </div>
    )
}

export default AppLoader
