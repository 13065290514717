import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  calcWeekCount,
  getListOfDays,
  getUTCTimeToLocalByDate,
  getMeridiumByDate,
  showWarning,
} from "../../utils/commonFunctions";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { daysJson, monthJson } from "../../config/Config";
import { adminService } from "../../services/adminService";
import allActions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ViewSessionCalendar from "../ViewSessionCalendar";
import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";
import appAction from "../../redux/actions/appAction";
import TimeInput from "../../components/TimeInput";
import SelectDate from "./SelectDate";
import SelectTime from "./SelectTime";
import SelectFamilyChild from "./SelectFamilyChild";
import SelectTherapy from "./SelectTherapy";
import SelectTherapist from "./SelectTherapist";
import SelectSessionType from "./SelectSessionType";
import SelectLocationTreatment from "./SelectLocationTreatment";
import SelectInterpreter from "./SelectInterpreter";
import { MultiSelect } from "react-multi-select-component";
import TimeGenerate from "./TimeGenerate";
import SelectEvent from "./SelectEvent";
import SweetAlert from "react-bootstrap-sweetalert";
import AppModal from "../../components/AppModal";

function GroupSession(props) {
  const { register, formState: { errors }, handleSubmit,setValue } = useForm();
  let user = useSelector((state) => state.adminUser.user);
  const profile = useSelector((state) => state.adminUser.profile);
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
  let params = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  let [weekcount, setWeekCount] = useState(0);
  let [family, setFamily] = useState([]);
  let [therapies, setTherapies] = useState([]);
  let [interpreter, setInterpreter] = useState([]);
  let [therapist, setTherapist] = useState([]);
  let [weeks, setWeeks] = useState(getListOfDays(weekcount));
  let [therapistUserId, setTherapistUserId] = useState("");
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [modalOpenClose1, setModalOpenClose1] = useState(false);
  const [startDateUTC, setStartDateUTC]=useState();
  const [selected, setSelected] = useState([]);
  const [familyList, setFamilyList] = useState([]);
  const [loader,setLoader]=useState(false);

  let currentRole = user.userDetails.roles[0];
  let today_date = new Date();
  const [inputs, setInputs] = useState({
    status: "PENDING",
    description: "",
    startDateTime: "", //2021-01-19T10:54:25.380Z
    endDateTime: "", //2021-01-19T10:54:25.380Z
    familyMemberId: [],
    interpreterId: "",
    sessionType: "THERAPY",
    therapistId: "",
    therapyId: "",
    locationOfTreatment: "Teletherapy",
    otherLocationText: "",
    customSessionForm: {
      recurring: false,
      recurringFrequency: 1,
      sessionCount: 1, // (Send sessionCount=0 incase sessionEndDate is provided)
      sessionEndDateTime: null, //(Send  sessionEndDate =null in case sessionCount  is provided)
      sessionFrequency: "Daily",
      weekDateTimeList: null,
      weekDayList: [],
    },
  });
  const [recurringEvent, setrecurringEvent] = useState("On");
  const [recurringSession, setrecurringSession] = useState(false);
  const [updateRecurringSessModal, setupdateRecurringSessModal] =
    useState(false);
  const [dates, setdates] = useState([]);
  const [isUpdateAll, setisUpdateAll] = useState("ONE");
  const [recurringModal, setrecurringModal] = useState(false);
  //THERAPY("THERAPY"), EVALUATION("EVALUATION"), RE_EVALUATION("RE_EVALUATION"); SessionType

  const [prevDates, setprevDates] = useState({
    startTime: null,
    endTime: null,
    session_date: null,
    startMeridium: null,
    endMeridium: null,
  });
  const [dateInputs, setDateInputs] = useState({
    startTime: params.id ? moment(today_date).format("hh:mm") : "07:00", //
    endTime: params.id ? moment(today_date).format("hh:mm") : "08:00", // moment(today_date).format('hh:mm')
    session_date: today_date.toISOString().slice(0, 10),
    startMeridium: "am",
    endMeridium: "am",
  });

  const getNextWeeK = () => {
    setWeeks(getListOfDays(weekcount + 1));
    setWeekCount(weekcount + 1);
  };

  const getPrevWeeK = () => {
    setWeeks(getListOfDays(weekcount - 1));
    setWeekCount(weekcount - 1);
  };

  const handleChangeTime = (e, name) => {
    TimeGenerate.handleTime(e, name, setDateInputs, dateInputs);
  };

  const handleChangeMeridium = (name, value) => {
    setDateInputs((dateInputs) => ({ ...dateInputs, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "session_date")
      setDateInputs((dateInputs) => ({ ...dateInputs, [name]: value }));
    else if (name === "sessionType" || name === "description")
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    else if (name === "familyMemberId") {
      setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
      profile?.userType == "ROLE_THERAPIST" && getTherapistByFamilyID(value);
    } else if (name === "locationOfTreatment" || name === "otherLocationText") {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else if (name === "therapistId") {
      setInputs((inputs) => ({ ...inputs, [name]: parseInt(value) }));
      profile?.userType == "ROLE_RCE" && getTherapyByTherapistID(value);
    } else {
      setInputs((inputs) => ({
        ...inputs,
        [name]: value ? parseInt(value) : "",
      }));
    }
  };

  function getTherapyByTherapistID(therapist_id) {
    let dataArr = therapist.filter((f) => f.id == therapist_id);
    setTherapies(dataArr[0].therapistServices);
  }

  function getTherapistByFamilyID(family_member_id) {
    if (currentRole !== "ROLE_REGIONAL_EARLY_STEPS") {
      try {
        family.filter((item) => {
          let dataArray = item.familyMembers.filter(
            (group) => group.id == family_member_id
          );

          if (dataArray.length > 0) {
            setTherapies(
              dataArray[0].familyMemberTherapies &&
              dataArray[0].familyMemberTherapies
            );
            setTherapist(dataArray[0].familyMemberTherapies);
            tenantPemission?.interpreter &&
              setInterpreter(
                dataArray[0].interpreter ? dataArray[0].interpreter : []
              );
            setInputs((inputs) => ({
              ...inputs,
              ["interpreterId"]: dataArray[0].interpreter
                ? parseInt(dataArray[0].interpreter.id)
                : "",
            }));
            setInputs((inputs) => ({
              ...inputs,
              ["therapistId"]: therapistUserId ? therapistUserId : 0,
            }));
            setInputs((inputs) => ({ ...inputs, ["therapyId"]: 0 }));
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (inputs?.therapyId) {
      adminService
        .getAll(
          `family-member-therapy/${inputs?.therapyId}/${profile?.userType == "ROLE_THERAPIST"
            ? profile.therapistId
            : inputs?.therapistId
          }?size=20000`
        )
        .then((res) => {
          setFamily(res.data);
          getFamilyList(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [inputs?.therapyId]);

  useEffect(() => {
    if (profile?.therapistId && profile?.userType == "ROLE_THERAPIST") {
      getTherapyList();
      setInputs({ ...inputs, therapistId: profile?.therapistId });
    } else {
      getTherapistListForAdmin();
    }
  }, [profile?.therapistId]);

  const getTherapyList = () => {
    adminService
      .getAll(`therapist-service/${profile?.therapistId}`)
      .then((res) => {
        setTherapies(res.data.content);
      })
      .catch((err) => console.log(err));
  };

  const getTherapistListForAdmin = () => {
    adminService.getAll("therapists").then(
      (res) => {
        setTherapist(res.data);
      },
      (error) => {
        toast.error(error);
      }
    );
  };

  const renderInput = (props, openCalendar) => {
    return (
      <div className="input-group ml-2">
        <img
          src="../resources/assets/clock.svg"
          onClick={openCalendar}
          style={{ marginRight: 4 }}
          alt=""
        />
        <input {...props} />
      </div>
    );
  };

  useEffect(() => {
    dispatch(appAction.setAppLoading(true));
    if (
      currentRole === "ROLE_RCE" ||
      currentRole === "ROLE_REGIONAL_EARLY_STEPS"
    ) {
      adminService
        .getAll("families/filter?sort=id,DESC&page=0&size=10000")
        .then(
          (res) => {
            if (res.status === "Success") {
              setFamily(res.data.content);
              dispatch(appAction.setAppLoading(false));
            } else {
              toast.error(res.message);
              dispatch(appAction.setAppLoading(false));
            }
          },
          (error) => {
            toast.error(error);
            dispatch(appAction.setAppLoading(false));
          }
        );
    }

    // for edit session if condition params.id, this will autofill the all the values
    if (params.id) {
      let apiurl =
        currentRole === "ROLE_RCE" ||
          currentRole === "ROLE_REGIONAL_EARLY_STEPS"
          ? "sessions/"
          : "therapist/sessions/";
      adminService.getAll(apiurl + params.id).then(
        (res) => {
          if (res.status === "Success") {
            const arr = [];
            res?.data?.familyMemberDtoSet &&
              res?.data?.familyMemberDtoSet.map((info) => {
                const name =
                  info.firstName + " " + info.lastName + "/" + info.familyName;
                arr.push({ label: name, value: info.familyMemberId });
                return info;
              });
            setSelected(arr);
            setrecurringSession(res?.data?.recurringSession);
            setrecurringEvent(res.data.sessionEndDate ? "On" : "After");
            setValue("therapyId",res.data.therapy.id);
            setInputs({
              familyMemberId: res?.data?.familyMemberDtoSet.map((fm) => fm.id),
              therapyId: res.data.therapy.id,
              therapistId: res.data.therapist.id,
              description: res.data.description,
              interpreterId: res.data.interpreter
                ? res.data.interpreter.id
                : "",
              locationOfTreatment: res.data.locationOfTreatment
                ? res.data.locationOfTreatment
                : "",
              otherLocationText: res.data.otherLocationText
                ? res.data.otherLocationText
                : "",
              customSessionForm: {
                recurring: res.data.recurringSession,
                recurringFrequency: res.data.recurringFrequency,
                sessionCount: res.data.sessionCount, // (Send sessionCount=0 incase sessionEndDate is provided)
                sessionEndDateTime:res?.data?.sessionEndDate || startDateUTC
                  // res.data.sessionEndDate || res.data.startDateTime
                , //(Send  sessionEndDate =null in case sessionCount  is provided)
                sessionFrequency: res.data.sessionFrequency,
                weekDateTimeList:
                  (res?.data?.sessionDateTimeList &&
                    moment(res?.data?.sessionDateTimeList[0]).format(
                      "MM-DD-YYYY"
                    )) ||
                    startDateUTC,
                weekDayList: res?.data?.weekDayList || [],
              },
            });
            if (currentRole === "ROLE_REGIONAL_EARLY_STEPS") {
              adminService.getAll("therapists").then(
                (res) => {
                  setTherapist(res.data);
                },
                (error) => {
                  toast.error(error);
                }
              );
            } else
              setTherapist(
                res.data.familyMemberDtoSet[0].familyMemberTherapies
              );

            setInterpreter(res.data.familyMemberDtoSet[0].interpreter);
            setTherapies(res.data.therapy);
            setprevDates({
              startTime: getUTCTimeToLocalByDate(res.data.startDateTime),
              endTime: getUTCTimeToLocalByDate(res.data.endDateTime),
              session_date: moment(
                moment.utc(res.data.startDateTime).local()
              ).format("YYYY-MM-DD"),
              startMeridium: getMeridiumByDate(res.data.startDateTime),
              endMeridium: getMeridiumByDate(res.data.endDateTime),
            });
            setDateInputs((dateInputs) => ({
              ...dateInputs,
              startTime: getUTCTimeToLocalByDate(res.data.startDateTime),
              endTime: getUTCTimeToLocalByDate(res.data.endDateTime),
              session_date: moment(
                moment.utc(res.data.startDateTime).local()
              ).format("YYYY-MM-DD"),
              startMeridium: getMeridiumByDate(res.data.startDateTime),
              endMeridium: getMeridiumByDate(res.data.endDateTime),
            }));
            //alert(calcWeekCount(res.data.endDateTime))
            let weekdaycount = calcWeekCount(res.data.startDateTime);
            setWeeks(getListOfDays(weekdaycount));
            setWeekCount(weekdaycount);
            dispatch(appAction.setAppLoading(false));
          } else {
            toast.error(res.message);
            dispatch(appAction.setAppLoading(false));
          }
        },
        (error) => {
          toast.error(error);
          dispatch(appAction.setAppLoading(false));
        }
      );
    } else {
      dispatch(appAction.setAppLoading(false));
    }

    // for ROLE_REGIONAL_EARLY_STEPS login
    if (currentRole === "ROLE_REGIONAL_EARLY_STEPS") {
      adminService.getAll("therapies").then(
        (res) => {
          setTherapies(res.data.content);
        },
        (error) => {
          toast.error(error);
        }
      );
      adminService.getAll("therapists").then(
        (res) => {
          setTherapist(res.data);
        },
        (error) => {
          toast.error(error);
        }
      );
      adminService.getAll("interpreters/filter").then(
        (res) => {
          setInterpreter(res.data.content);
        },
        (error) => {
          toast.error(error);
        }
      );
    }

    // for get therapist ID
    if (currentRole === "ROLE_THERAPIST") {
      adminService.getAll(`user-profiles/user-detail`).then(
        (res) => {
          if (res.status === "Success") {
            setTherapistUserId(res.data.therapistId);
            setInputs((inputs) => ({
              ...inputs,
              ["therapistId"]: parseInt(res.data.therapistId),
            }));
            adminService
              .getAll(
                `families/filter?sort=id,DESC&therapistId=${res.data.therapistId}`
              )
              .then(
                (res) => {
                  if (res.status === "Success") {
                    setFamily(res.data.content);
                    dispatch(appAction.setAppLoading(false));
                  } else {
                    toast.error(res.message);
                    dispatch(appAction.setAppLoading(false));
                  }
                },
                (error) => {
                  toast.error(error);
                  dispatch(appAction.setAppLoading(false));
                }
              );
            //families/filter?sort=id,DESC${currentRole === 'ROLE_THERAPIST' ? '&therapistId='+therapistUserId:''}
          } else {
            toast.error(res.message);
            dispatch(appAction.setAppLoading(false));
          }
        },
        (error) => {
          console.log(error);
          dispatch(appAction.setAppLoading(false));
        }
      );
    }
  }, []);

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  const [dateInfo, setDateInfo] = useState("");

  useEffect(() => {
    setDateInputs(dateInputs);
    let startUTCTime = moment(
      dateInputs.startTime + " " + dateInputs.startMeridium,
      ["h:mm A"]
    ).format("HH:mm:ss");
    let endUTCTime = moment(dateInputs.endTime + " " + dateInputs.endMeridium, [
      "h:mm A",
    ]).format("HH:mm:ss");
    let startDateTimeUTC = moment(dateInputs.session_date + " " + startUTCTime);
    let endDateTimeUTC = moment(dateInputs.session_date + " " + endUTCTime);
    let startDateUTC = moment.utc(startDateTimeUTC).toISOString();
    setStartDateUTC(startDateUTC);
    let endDateUTC = moment.utc(endDateTimeUTC).toISOString();

    const selectedDate =
      moment(startDateTimeUTC).format("YYYY-MM-DD") + `T00:00:00.000Z`;

    setInputs((inputs) => ({
      ...inputs,
      ["startDateTime"]: startDateUTC,
      ["endDateTime"]: endDateUTC,
    }));
    setDateInfo(startDateUTC);
  }, [dateInputs]);

  const getFamilyList = (family) => {
    const options = [];
    {
      family &&
        family.map((info, index) => {
          const name = info.familyName + "/" + info.familyMemberName;
          options.push({ label: name, value: info.familyMemberId });
          return info;
        });
    }
    setFamilyList(options);
  };

  const onSubmit = (isUpdate) => {
    setupdateRecurringSessModal(false);
    let local_date_time = moment().format("YYYY-MM-DD hh:mm:ss a");
    let startUTCTime = moment(
      dateInputs.startTime + " " + dateInputs.startMeridium,
      ["h:mm A"]
    ).format("hh:mm:ss a");
    let startDateTimeUTC = moment(dateInputs.session_date + " " + startUTCTime);
    let timeInMin = startDateTimeUTC.diff(local_date_time, "minutes");
    let date_diff_minutes = moment(inputs.endDateTime).diff(
      moment(inputs.startDateTime),
      "minutes"
    );
    if (timeInMin <= 0) {
      showWarning("start_time_validation");
      return;
    }
    if (date_diff_minutes > 0 && date_diff_minutes < 30) {
      showWarning("time_diff_validation");
      return;
    }
    if (inputs.startDateTime >= inputs.endDateTime) {
      showWarning("start_end_time_validation");
      return;
    }
    let url = "sessions";
    if (profile?.userType == "ROLE_THERAPIST") {
      inputs.therapistId = profile.therapistId;
      url = "therapist/sessions";
    }

    const body = { ...inputs };
    const data = body.customSessionForm;
    body.customSessionForm.recurringFrequency = parseInt(
      body.customSessionForm.recurringFrequency
    );

    const selectedDay = moment(inputs?.startDateTime).format("dddd");
    if (recurringEvent == "On") {
      body.customSessionForm.sessionCount = 0;
      body.customSessionForm.sessionEndDateTime =
        moment(data.sessionEndDateTime).format("YYYY-MM-DD") + `T00:00:00.000Z`;
    } else {
      body.customSessionForm.sessionCount = parseInt(
        body.customSessionForm.sessionCount
      );
      body.customSessionForm.sessionEndDateTime = null;
    }
    if (body.customSessionForm.sessionFrequency === "Daily") {
      body.customSessionForm.weekDateTimeList = [];
      body.customSessionForm.weekDayList = [];
    }
    if (body.customSessionForm.sessionFrequency === "Weekly") {
      body.customSessionForm.weekDateTimeList = [];
      if (body.customSessionForm.weekDayList.length == 0) {
        body.customSessionForm.weekDayList = [selectedDay.toUpperCase()];
      } else {
        const newArr = [...body.customSessionForm.weekDayList];
        newArr.push(selectedDay.toUpperCase());
        body.customSessionForm.weekDayList = [...new Set(newArr)];
      }
    }
    if (body.customSessionForm.sessionFrequency === "Monthly") {
      body.customSessionForm.weekDateTimeList = [];
      body.customSessionForm.weekDayList = [];
    }
    body.customSessionForm.recurring === false &&
      (body.customSessionForm.sessionEndDateTime = null);

    if (params.id) {
      setLoader(true);
      let apiUrlEdit =
        currentRole === "ROLE_RCE" ||
          currentRole === "ROLE_REGIONAL_EARLY_STEPS"
          ? "sessions/"
          : "therapist/sessions/";
      delete body["familyMemberId"];
      delete body["interpreterId"];
      delete body["therapistId"];
      delete body["therapyId"];
      dispatch(appAction.setAppLoading(true));

      // inputs.updateAll = isUpdate;
      body.sessionList = isUpdateAll;

      adminService
        .PUT(apiUrlEdit + params.id, JSON.stringify(body))
        .then(
          (res) => {
            if (res?.status === "Success") {
              toast.success(res.message);
              setTimeout(() => {
                history("/therapy-sessions");
                setLoader(false);
              }, 2000);
            } else {
              res?.message
                ? toast.error(res?.message)
                : console.log("Somthing went wrong");
              setLoader(false);
            }
          },
          (error) => {
            console.log(error);
            setLoader(false);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    } else {
      setLoader(true);
      adminService
        .POST(url, JSON.stringify(body))
        .then(
          (res) => {
            if (res?.status === "Success") {
              dispatch(allActions.adminAction.clearOnboardData());
              toast.success(res.message);
              setTimeout(() => {
                history("/therapy-sessions");
                setLoader(false);
              }, 2000);
            } else {
              res?.message
                ? toast.error(res?.message)
                : console.log("Somthing went wrong");
              setLoader(false);
            }
          },
          (error) => {
            console.log("Error >>>>>>>", error);
            setLoader(false);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    }
  };

  useEffect(() => {
    const ids = [...inputs?.familyMemberId];
    selected.length > 0 &&
      selected.map((s) => {
        ids.push(s.value);
      });
    ids.length > 0 && setInputs({ ...inputs, familyMemberId: ids });
  }, [selected]);

  return (
    <>
      <div className="col-md-12 no-padding">
        <div className="no-padding">
          <div className="custom-table-view">
            <form
              className="addtheripist add-session-form p-0"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <SelectDate
                  params={params}
                  weekcount={weekcount}
                  getPrevWeeK={getPrevWeeK}
                  weeks={weeks}
                  handleChange={handleChange}
                  dateInputs={dateInputs}
                  daysJson={daysJson}
                  getNextWeeK={getNextWeeK}
                  monthJson={monthJson}
                />
                <SelectTime
                  dateInputs={dateInputs}
                  handleChangeTime={handleChangeTime}
                  handleChangeMeridium={handleChangeMeridium}
                />

                {profile?.userType != "ROLE_THERAPIST" && (
                  <SelectTherapist
                    params={params}
                    register={register}
                    errors={errors}
                    handleChange={handleChange}
                    inputs={inputs}
                    therapist={therapist}
                    currentRole={currentRole}
                    setModalOpenClose={setModalOpenClose}
                    modalOpenClose={modalOpenClose}
                  />
                )}
                <SelectTherapy
                  params={params}
                  register={register}
                  errors={errors}
                  handleChange={handleChange}
                  inputs={inputs}
                  therapies={therapies}
                  currentRole={currentRole}
                  therapistUserId={therapistUserId}
                  therapyFor="group"
                />
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Caretaker & Client</label> (
                    <span className="required-span">*</span>)
                    <div className="row ml-2">
                      <img
                        src="../resources/assets/family-icon-blue.svg"
                        className="img-fluid"
                        alt=""
                      />
                      <div className="col-md-10">
                        <div className="input-group">
                          <MultiSelect
                            className={`custom-select ${params?.id && "custom-select-disable"
                              }`}
                            options={familyList}
                            value={selected}
                            onChange={setSelected}
                            labelledBy="Select Caretaker & Client"
                            disabled={params?.id ? true : false}
                          />
                          <div className="input-group-prepend">
                            <img
                              className="m-auto"
                              src="../resources/assets/Polygon 1.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SelectSessionType
                  params={params}
                  register={register}
                  errors={errors}
                  handleChange={handleChange}
                  inputs={inputs}
                  currentRole={currentRole}
                />
                <SelectLocationTreatment
                  register={register}
                  errors={errors}
                  handleChange={handleChange}
                  inputs={inputs}
                  currentRole={currentRole}
                />
                <SelectInterpreter
                  params={params}
                  interpreter={interpreter}
                  errors={errors}
                  handleChange={handleChange}
                  inputs={inputs}
                  currentRole={currentRole}
                  setModalOpenClose1={setModalOpenClose1}
                  modalOpenClose1={modalOpenClose1}
                />

                {/* {!params.id && ( */}
                <SelectEvent
                  register={register}
                  errors={errors}
                  handleChange={handleChange}
                  inputs={inputs}
                  setInputs={setInputs}
                  currentRole={currentRole}
                  recurringEvent={recurringEvent}
                  setrecurringEvent={setrecurringEvent}
                  dates={dates}
                  setdates={setdates}
                  recurringModal={recurringModal}
                  setrecurringModal={setrecurringModal}
                  id={params.id}
                />
                {/* )} */}

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>{" "}
                    <textarea
                      defaultValue={inputs.description}
                      onChange={handleChange}
                      name="description"
                      rows="5"
                      className={`form-control h-100 ${errors.description && "is-invalid"
                        }`}
                      placeholder="Add your description"
                      autofocus
                    ></textarea>
                    {errors.description?.type === "required" && (
                      <div className="invalid-feedback-custom">
                        About is required
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 d-flex">
                  {recurringSession && (
                    <button
                      className="btn  btn-primary d-block stepper-button-next"
                      type={`${JSON.stringify(dateInputs) === JSON.stringify(prevDates)
                        ? "submit"
                        : "button"
                        }`}
                      onClick={() => {
                        JSON.stringify(dateInputs) === JSON.stringify(prevDates)
                          ? console.log("no change")
                          : setupdateRecurringSessModal(true);
                      }}
                    >
                      Update Schedule
                    </button>
                  )}
                  {recurringSession === false && (
                    <button
                      className="btn  btn-primary d-block stepper-button-next"
                      type="submit"
                      disabled={loader ? true : false}
                    >
                      {params.id ? "Update Schedule" : "Add to Schedule"}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        {updateRecurringSessModal && (
          <>
            <AppModal
              title="Recurring Session"
              isOpen={updateRecurringSessModal}
              onClose={() => setupdateRecurringSessModal(false)}
            >
              {/* <h4 className="text-center mb-25">
                Do you want to update all remaining sessions?
              </h4> */}
              <div className="form-group mb-20">
                <label>Update recurring event</label>
                <div className="custom-control custom-radio ">
                  <input
                    onChange={(e) => {
                      setisUpdateAll(e.target.value);
                    }}
                    checked={isUpdateAll == "ONE" ? true : false}
                    type="radio"
                    id="radio-btn"
                    value="ONE"
                    name="updateAll"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" for="radio-btn">
                    This Session
                  </label>
                </div>
                <div className="custom-control custom-radio ">
                  <input
                    onChange={(e) => {
                      setisUpdateAll(e.target.value);
                    }}
                    checked={isUpdateAll == "PROCEEDING" ? true : false}
                    type="radio"
                    id="radio-btn1"
                    value="PROCEEDING"
                    name="updateAll"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" for="radio-btn1">
                    This and Following Sessions
                  </label>
                </div>
                <div className="custom-control custom-radio ">
                  <input
                    onChange={(e) => {
                      setisUpdateAll(e.target.value);
                    }}
                    checked={isUpdateAll == "ALL" ? true : false}
                    type="radio"
                    id="radio-btn2"
                    value="ALL"
                    name="updateAll"
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" for="radio-btn2">
                    All Upcoming Sessions
                  </label>
                </div>
              </div>
              <>
                <div className="d-flex justify-content-center mb-10">
                  <button
                    onClick={() => onSubmit(false)}
                    className="btn btn-danger"
                  >
                    No
                  </button>
                  <button
                    onClick={() => onSubmit(true)}
                    className="btn btn-theme-green"
                  >
                    Yes
                  </button>
                </div>
              </>
            </AppModal>
          </>
        )}
      </div>
    </>
  );
}

export default GroupSession;
