import moment from "moment-timezone";

const handleTime = (e, name, setDateInputs, dateInputs) => {
  let newName = name == "startTime" ? "endTime" : "startTime";

  let sm = dateInputs.startMeridium;
  let em = dateInputs.endMeridium;
  if (name == "startTime") {
    const hm =
      moment(e._d ? e._d : e).format("HH") + " " + dateInputs.startMeridium;

    if (hm == "12 am") {
      sm = "pm";
    }
    if (hm == "12 pm") {
      sm = "am";
    }
    if (hm == "11 am") {
      em = "pm";
      console.log("hm--", em);
    }
    if (hm == "11 pm") {
      em = "am";
    }

    setDateInputs((dateInputs) => ({
      ...dateInputs,
      [name]: moment(e._d ? e._d : e).format("HH:mm"),
      [newName]:
        moment(e._d ? e._d : e).format("HH") == 12
          ? moment(e._d ? e._d : e)
              .subtract(11, "hours")
              .format("HH:mm")
          : moment(e._d ? e._d : e)
              .add(1, "hours")
              .format("HH:mm"),
      startMeridium: sm,
      endMeridium: em,
    }));
  } else {
    const hem =
      moment(e._d ? e._d : e).format("HH") + " " + dateInputs.endMeridium;

    if (hem == "12 am") {
      em = "pm";
      console.log("object--1");
    }
    if (hem == "12 pm") {
      em = "am";
      console.log("object--2");
    }

    if (hem == "01 am") {
      sm = "am";
      console.log("object--3");
    }
    if (hem == "01 pm") {
      sm = "pm";
      console.log("object--4");
    }

    setDateInputs((dateInputs) => ({
      ...dateInputs,
      [name]: moment(e._d ? e._d : e).format("HH:mm"),
      // [newName]:
      //   moment(e._d ? e._d : e).format("HH") == 1
      //     ? moment(e._d ? e._d : e)
      //         .add(11, "hours")
      //         .format("HH:mm")
      //     : moment(e._d ? e._d : e).format("HH") == 12
      //     ? moment(e._d ? e._d : e)
      //         .subtract(1, "hours")
      //         .format("HH:mm")
      //     : moment(e._d ? e._d : e)
      //         .subtract(1, "hours")
      //         .format("HH:mm"),
      // startMeridium: sm,
      // endMeridium: em,
    }));
  }
};

const TimeGenerate = {
  handleTime,
};

export default TimeGenerate;
