import React, { useState } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import moment from "moment";
import { adminService } from "../../services/adminService";
import { _toastHandleErr, _toasthandleRes } from "../../utils/commonFunctions";
import { AttachmentForm } from "../attachment";
import { useParams } from "react-router-dom";

const postQueryReply = (data) =>
  adminService.POST(`query-replies`, JSON.stringify(data));
const postQuery = (data) => adminService.POST(`queries`, JSON.stringify(data));

const QueryReplyForm = ({ id, isReply, sessionId, onSuccess }) => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const { getValues, register, formState: { errors }, handleSubmit, reset } = useForm();

  const onSubmitSuccess = () => {
    setAttachments([]);
    if (onSuccess && typeof onSuccess === "function") {
      onSuccess();
    }
  };
  const onSubmit = (e) => {
    setIsLoading(true);
    const data = isReply
      ? {
          queryId: id,
          attachments: attachments.map((item) => ({ id: item.id })),
          ...getValues(),
        }
      : {
          sessionId,
          queryDate: moment().format("YYYY-MM-DD"),
          attachmentList: attachments.map((item) => ({ id: item.id })),
          title: " ",
          ...getValues(),
        };
    const method = isReply ? postQueryReply : postQuery;
    data.caseNoteId = params.id;
    method(data)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        reset();
        onSubmitSuccess();
        _toasthandleRes(
          res,
          isReply ? "Reply added successfully" : "Query added successfully"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        _toastHandleErr(err);
      });
  };

  return (
    <div className="c-query-reply">
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isReply ? (
          <>
            {/* <input
              name="title"
              {...register({ required: true })}
              placeholder="Query Title"
              type="text"
              className="c-query-reply__input bg-white c-query-reply__input form-control mb-3"
            />
            {errors.title && (
              <span className="error-text">Title is required</span>
            )} */}
            <textarea
              name="detail"
              placeholder="Query detail"
              {...register('detail',{ required: true })}
              className="c-query-reply__text c-textarea"
            />
            {errors.detail && (
              <span className="error-text">Query detail is required</span>
            )}
          </>
        ) : (
          <>
            <textarea
              name="reply"
              {...register('reply',{ required: true })}
              className="c-query-reply__text c-textarea"
            />
            {errors.reply && (
              <span className="error-text">Reply is required</span>
            )}
          </>
        )}
        <AttachmentForm
          attachments={attachments}
          onSuccess={(file) => {
            setAttachments((attachments) => [...attachments, file]);
          }}
          onDelete={(_file, _id) => {
            const newAttachments = attachments.filter(
              (_item, key) => key !== _id
            );
            setAttachments(newAttachments);
          }}
          label="Attach audio/video/image/document file"
          accept="image/*, audio/*, video/*, .pdf, .doc, .docx"
        />
        <br />
        <button
          disabled={isLoading}
          className={classNames("btn btn-theme-green mt-3", {
            "has-loader": isLoading,
          })}
          type="submit"
        >
          {isReply ? "Reply" : "Submit"}
          {isLoading && <i className="loader-small"></i>}
        </button>
      </form>
    </div>
  );
};

export default QueryReplyForm;
