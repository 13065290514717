import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DigitalSignPopup from "../../components/digitalSignPopup";
import { getUserProfile } from "../../services/caseNoteServices";
import { adminService } from "../../services/adminService";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import adminAction from "../../redux/actions/adminAction";
import ProcedureTable from "./goalsComponent.js/ProcedureTable";
import GoalsTable from "./goalsComponent.js/GoalsTable";
import GoalsMilestonePrint from "../Therapist/GoalsMilestonePrint";
import CaseNoteDownload from "../../components/case-note-detail/CaseNoteDownload";
import appAction from "../../redux/actions/appAction";
//import GoalsModal from "./goalsComponent.js/GoalsModal";
//import AppModal from "../../components/AppModal";
import DatePicker from "react-multi-date-picker";
const customStyles = {
  content: {
    minWidth: "400px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
  },
};
const ViewArchiveGoal = () => {
  let d = new Date();
  const [drafted, setDrafted] = useState(false);
  const sigCanvas = useRef({});
  const formRef = React.useRef(null);
  const history = useNavigate();
  const { id } = useParams();
  const [goals, setGoals] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [closeGoals, setCloseGoals] = useState(false);
  const [therapistImageURL, setTherapistImageURL] = useState(null);
  const [goalsCompleted, setGoalsCompleted] = useState(false);
  const [signatureDateTime, setsignatureDateTime] = useState(null);
  const [addLongGoals, setAddLongGoals] = useState(false);
  const [editLongGoals, setEditLongGoals] = useState(false);
  const [editGoalData, setEditGoalData] = useState({});
  const [signatureError, setSignatureError] = useState(false);
  const [goalError, setGoalError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const profile = useSelector((st) => st.adminUser.profile);
  const user = useSelector((st) => st.adminUser.user)?.userDetails;
  const [caseNoteIcdCode, setCaseNoteIcdCode] = useState();
  const [caseNoteCptCode, setCaseNoteCptCode] = useState();
  const [checked, setChecked] = useState(false);
  const [signLoading, setSignLoading] = useState(false);
  const sigWitnessCanvas = useRef({});
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [addShortGoals, setAddShortGoals] = useState(false);
  const [editShortGoals, setEditShortGoals] = useState(false);

  const [state, setState] = useState({});
  const [openSingModal, setOpenSignModal] = useState(false);

  const [formData, setFormData] = useState({
    nextSessionDate: "",
    visitingFrequency: "",
    estimatedDuration: "",
    sessionNote: "",
    plan: "",
    longTermGoals: [],
    shortTermGoals: [],
    completed: false,
    goalsAchieved: false,
  });

  // useEffect(() => {
  //   if (profile && formData?.drafted) {
  //     setTherapistImageURL(profile?.signatureUrl);
  //     setsignatureDateTime(
  //       moment(profile?.signatureDateTime).format("DD-MM-yyyy hh:mm a")
  //     );
  //   }
  // }, [formData?.drafted, profile]);

  useEffect(() => {
    loadInitials();
  }, [id]);
  const loadInitials = async () => {
    console.log("id", id);
    setIsLoading(true);
    adminService
      .getAll(`archive/goals/${id}`)
      .then((res) => {
        console.log("MIS GOAL RESPONSES", res.data);
        setGoals(res.data);
        setFormData({
          nextSessionDate: res?.data?.nextSessionDate
            ? moment(res?.data?.nextSessionDate).format("YYYY-MM-DD") +
            `T00:00:00.000Z`
            : null,
          visitingFrequency: res?.data?.visitingFrequency,
          estimatedDuration: res?.data?.estimatedDuration,
          plan: res?.data?.plan,
          goalsAchieved: res?.data?.goalsAchieved,
          completed: res?.data?.completed,
          longTermGoals: res?.data?.longTermGoals,
          shortTermGoals: res?.data?.shortTermGoals,
          sessionNote: res?.data?.sessionNote,
        });
        res.data.procedureList.map((val) => {
          if (val.diagnosis.id === res.data.caseNoteListDto?.caseNoteDTO?.diagnosisId) {
            setCaseNoteIcdCode("(" + val.diagnosis.code + ") " + val.diagnosis.description);
            //console.log("icdCode",val.diagnosis.code);
          }
          if (val.cptCode.id === res.data.caseNoteListDto?.caseNoteDTO?.cptId) {
            setCaseNoteCptCode("(" + val.cptCode.procCode + ") " + val.cptCode.description);
            //console.log("icdCode",val.diagnosis.code);
          }
        })
        setsignatureDateTime(res?.data?.signatureDate);

        if (res?.data?.drafted == false) {
          profile?.signatureUrl
            ? setTherapistImageURL(profile?.signatureUrl)
            : setTherapistImageURL(res?.data?.therapistSignatureKey);
          // setsignatureDateTime(
          //   moment(profile?.signatureDateTime).format("DD-MM-yyyy hh:mm a")
          // );
        }
        // resolveWithData(resolve, rej, res);
      })
      .catch((err) => {
        // rej(err);
      });
  };

  // Digital Signature
  // const onSignatureUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append("digitalSignature", file);
  //     setSignLoading(true);
  //     updateSignature(formData);
  //   }
  // };

  const isTherepist = (user?.roles || []).includes("ROLE_THERAPIST");

  const isSubmmited = !state.caseNoteDTO?.drafted;
  const isSharedCaseNote = state?.caseNoteDTO?.sharedTherapist?.some((item) => {
    return item?.userProfile?.id === profile.id;
  });

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );

    const formData1 = new FormData();
    formData1.append(
      "digitalSignature",
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    setSignLoading(true);
    updateSignature(signatureData);

    setOpen(false);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    setSignatureError(false);
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            getUserProfile()
              .then((res) => {
                setTherapistImageURL(res?.signatureUrl);
                setsignatureDateTime(res?.signatureDateTime);
                // setsignatureDateTime(
                //   moment
                //     .utc(res?.signatureDateTime)
                //     .local()
                //     .format("DD-MM-yyyy hh:mm a")
                // );
                // setProfile(res)
                dispatch(adminAction.setProfileData(res));
                setSignLoading(false);
              })
              .catch((err) => {
                setSignLoading(false);
                toast.error(err.message || err.info || err.msg);
              });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        setSignLoading(false);
      });
  };
  const SignatureHandler = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const yesHandler = () => {
    setFormData((formData) => ({ ...formData, completed: true }));
    setGoalsCompleted(true);
    setCloseGoals(false);
  };

  const noHandler = () => {
    setCloseGoals(false);
    setFormData((formData) => ({ ...formData, completed: false }));
  };

  const handleGoalsAchive = () => {
    setFormData((formData) => ({ ...formData, goalsAchieved: true }));
    setGoalsCompleted(false);
  };

  const handleCloseGoals = () => {
    setFormData((formData) => ({ ...formData, goalsAchieved: false }));
    setGoalsCompleted(false);
  };

  // Goals Method

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    if (name === "completed") {
      formData?.completed
        ? setFormData((formData) => ({ ...formData, [name]: false }))
        : setCloseGoals(true);
    } else {
      setFormData((formData) => ({
        ...formData,
        [name]: value ? value : "",
      }));
    }
  };

  const removeListItem = (name, index) => {
    if (name == "Long") {
      const lst = [...formData?.longTermGoals];
      lst.splice(index, 1);
      console.log("index", index, lst);
      setFormData({ ...formData, longTermGoals: lst });
    } else if (name == "Short") {
      const lst = [...formData?.shortTermGoals];
      lst.splice(index, 1);
      setFormData({ ...formData, shortTermGoals: lst });
    }
  };

  const DeleteLongGoals = (goal, name, index) => {
    const url = name == "Long" ? "goals/longtermgoal" : "goals/shorttermgoal";
    if (!goal?.isNewItem) {
      adminService
        .DELETE(`${url}/${goal?.id}`)
        .then((res) => {
          console.log("Goals Deleted", res.data);
          if (res?.status === "Success") {
            removeListItem(name, index);
          }
        })
        .catch((e) => console.log("e", e));
    } else {
      removeListItem(name, index);
    }
  };

  const closeHandler = (e) => {
    e.preventDefault();
    setAddShortGoals(false);
    setAddLongGoals(false);
    setEditLongGoals(false);
    setEditShortGoals(false);
  };

  const onAddLongGoals = (e) => {
    e.preventDefault();
  };

  const saveAsDraft = (isDraft = true) => {
    const longTermGoalList = [];
    const shortTermGoalList = [];

    formData?.longTermGoals &&
      formData?.longTermGoals.map((d) => {
        const dt = {
          concern: d.concern,
          description: d.description,
          achievedPercentage: d.achievedPercentage,
        };
        !d?.isNewItem ? (dt.id = d.id) : (dt.id = 0);
        longTermGoalList.push(dt);
      });

    formData?.shortTermGoals &&
      formData?.shortTermGoals.map((d) => {
        const dt = {
          concern: d.concern,
          description: d.description,
          achievedPercentage: d.achievedPercentage,
        };
        !d?.isNewItem ? (dt.id = d.id) : (dt.id = 0);
        shortTermGoalList.push(dt);
      });
    const draftData = {
      completed: formData?.completed,
      // drafted: true,
      estimatedDuration: formData?.estimatedDuration,
      id: goals?.id,
      longTermGoals: longTermGoalList,
      nextSessionDate: formData?.nextSessionDate
        ? moment(formData?.nextSessionDate).format("YYYY-MM-DDThh:mm:ss")
        : "",
      plan: formData?.plan,
      sessionNote: formData?.sessionNote,
      shortTermGoals: shortTermGoalList,
      // signatureDate: profile?.signatureDate,
      therapistSignatureKey: profile?.signatureKey,
      visitingFrequency: formData?.visitingFrequency,
      goalsAchieved: formData?.goalsAchieved,
    };
    // caseNoteDrafted
    if (!isDraft) {
      draftData.drafted = false;
      if (goals?.caseNoteDrafted) {
        toast.warning(
          "Session Note submission is required before submitting Goals"
        );
      } else {
        draftData.signatureDate =
          signatureDateTime || moment(new Date()).format("YYYY-MM-DDThh:mm:ss");
        console.log("draftData", draftData);
        // moment(new Date()).format(
        //   "YYYY-MM-DDThh:mm:ss"
        // );
        // console.log("draftData", draftData);
        saveGoalsData(`goals/submit/${goals?.id}`, draftData);
      }
    } else {
      draftData.drafted = true;
      saveGoalsData(`goals/${goals?.id}`, draftData);
    }
  };
  const saveGoalsData = (url, draftData) => {
    adminService.PUT(url, JSON.stringify(draftData)).then(
      (res) => {
        if (res.status === "Success") {
          toast.success(res.message);
          setTimeout(() => {
            history("/goals");
          }, 1000);
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const setDate = (e) => {
    // setInputs((input) => ({
    //   ...input,
    //   customSessionForm: {
    //     ...inputs?.customSessionForm,
    //     sessionEndDateTime:
    //       moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`,
    //   },
    // }));
    setFormData((formData) => ({
      ...formData,
      nextSessionDate:
        moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`,
    }));
  };
  const onPdfCreate = (cb) => {
    setIsDownloading(true);
    dispatch(appAction.setAppLoading(true));
    setTimeout(() => {
      if (typeof cb === "function") {
        cb();
      }
    }, 200);
  };

  const onPdfComplete = () => {
    setIsDownloading(false);
    dispatch(appAction.setAppLoading(false));
  };
  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="d-none"><GoalsMilestonePrint ref={formRef} goals={goals} cptCode={caseNoteCptCode} icdCode={caseNoteIcdCode} /></div>
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            {/* {isDownloading && (
              <div className="loader" />
            )} */}

            <div className="content-header pb-0">
              <div className="text-left header-title pt-7px">
                <h2 className="header-title">Goals and Milestones</h2>
              </div>
              {(goals.drafted === false) ?
                <div className="text-right">
                  <CaseNoteDownload
                    downloadName={`ArchiveGoalsAndMilestone.pdf`}
                    targetRef={formRef}
                    beforeStart={onPdfCreate}
                    onComplete={onPdfComplete}
                  />
                </div> : ''}
            </div>
            <form>
              <div className="mt-3 d-flex justify-content-between">
                <div className="col-4">
                  <label className="font-weight-bold">Session Date</label>
                  {/* <input
                    className="form-control px-3"
                    type="text"
                    value={`${moment(goals?.sessionDate).format("MM-DD-YYYY")}`}
                    name="sessionDate"
                    readOnly={true}
                    disabled={true}
                  /> */}
                  <p>{`${moment(goals?.sessionDate).format("MM-DD-YYYY")}`}</p>
                </div>
                <div className="col-4 mis-goal">
                  <label className="font-weight-bold">
                    Next Session Date:
                    {/* {formData?.nextSessionDate} */}
                  </label>
                  {/* <DatePicker
                    multiple={false}
                    value={formData?.nextSessionDate}
                    onChange={setDate}
                    name="sessionEndDateTime"
                    format="MM-DD-YYYY"
                    editable={false}
                    minDate={goals?.sessionDate}
                    disabled={goals?.drafted == false ? true : false}
                  /> */}
                  <p>{formData?.nextSessionDate}</p>
                </div>
                <div className="col-4">
                  <label className="font-weight-bold">Therapy Type</label>{" "}
                  {/* <input
                    className="form-control px-3"
                    type="text"
                    defaultValue={goals && goals?.therapyType}
                    name="therapyType"
                    placeholder="Therapy Type"
                    readOnly={true}
                    disabled={true}
                  /> */}
                  <p>{goals && goals?.therapyType}</p>
                </div>
              </div>

              <div className="mt-5">
                <div className="d-flex justify-content-between">
                  <div className="col-4">
                    <label className="font-weight-bold">
                      Frequency of Visit
                    </label>{" "}
                    {/* <input
                      defaultValue={formData?.visitingFrequency}
                      className="form-control px-3"
                      type="text"
                      placeholder="Frequency of Visit"
                      name="visitingFrequency"
                      onChange={(e) => handleValueChange(e)}
                      disabled={goals?.drafted == false ? true : false}
                    /> */}
                    <p>{formData?.visitingFrequency}</p>
                  </div>
                  <div className="col-4">
                    <label className="font-weight-bold">
                      Estimated Duration
                    </label>{" "}
                    {/* <input
                      defaultValue={formData?.estimatedDuration}
                      className="form-control"
                      type="text"
                      placeholder="Estimated Duration"
                      name="estimatedDuration"
                      onChange={(e) => handleValueChange(e)}
                      disabled={goals?.drafted == false ? true : false}
                    /> */}
                    <p>{formData?.estimatedDuration}</p>
                  </div>
                  <div className="col-4">
                    <label className="font-weight-bold">
                      Location of Treatment
                    </label>{" "}
                    {/* <input
                      defaultValue={goals && goals ? goals?.location : ""}
                      className="form-control"
                      type="text"
                      placeholder="Location of Treatment"
                      name="location"
                      readOnly={true}
                      disabled={true}
                    /> */}
                    <p>{goals ? goals?.location : ""}</p>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h5 className="px-3 p-case-note-detail__content-header">
                  Procedure
                </h5>
                <div className="col-md-12">
                  <ProcedureTable
                    list={goals?.procedureList || []}
                    handleValueChange={handleValueChange}
                  />

                  <div className="row">
                    <div className="col-md-6 mt-5 text-left p-0">
                      <label htmlFor="subjectivegoal">
                        <h5 className=" px-3 p-case-note-detail__content-header">
                          {" "}
                          Session Note
                        </h5>
                      </label>
                    </div>
                    <div className="col-md-6 mt-5 text-right">
                      <label>
                        <b>Last update:</b>{" "}
                        {goals?.sessionNoteTimestamp &&
                          moment
                            .utc(goals?.sessionNoteTimestamp)
                            .utc()
                            .local()
                            .format("MM-DD-yyyy hh:mm a")}
                      </label>
                    </div>
                    <div className="col-md-12">
                      {/* <textarea
                        //name="text"
                        className="form-control"
                        placeholder="Type your note"
                        id="sessionNote"
                        name="sessionNote"
                        ref={register({ required: true })}
                        defaultValue={formData?.sessionNote}
                        onChange={(e) => handleValueChange(e)}
                        disabled={goals?.drafted == false ? true : false}
                      /> */}
                      <p>{formData?.sessionNote}</p>
                    </div>
                    <div className="col-md-12">
                      {errors.sessionNote && (
                        <span className="error-text">
                          Please Enter Session Note.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="mt-5">
                      <label htmlFor="subjectivegoal">
                        <h5 className="px-3 p-case-note-detail__content-header">
                          {" "}
                          Plan
                        </h5>
                      </label>
                    </div>
                    <div className="col-md-12">
                      {/* <textarea
                        // name="text"
                        className="form-control"
                        placeholder="Type your plan"
                        name="plan"
                        defaultValue={formData?.plan}
                        onChange={(e) => handleValueChange(e)}
                        disabled={goals?.drafted == false ? true : false}
                      /> */}
                      <p>{formData?.plan}</p>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-6">
                      <h5 className="p-case-note-detail__content-header">
                        Long Term Goals
                      </h5>
                    </div>
                    <div className="col-md-6 text-right mb-10">
                      {goals?.drafted && (
                        <button
                          className="btn btn-theme-green"
                          type="button"
                          onClick={(e) => setAddLongGoals("Long")}
                        >
                          + Goal
                        </button>
                      )}
                    </div>
                    <div className="col-md-12 mt-1">
                      <GoalsTable
                        list={formData?.longTermGoals}
                        handleValueChange={handleValueChange}
                        DeleteLongGoals={DeleteLongGoals}
                        editGoalData={editGoalData}
                        setEditGoalData={setEditGoalData}
                        setAddLongGoals={setAddLongGoals}
                        goalData={goals}
                        name="Long"
                      />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-6">
                      <h5 className="p-case-note-detail__content-header">
                        Short Term Goals
                      </h5>
                    </div>
                    <div className="col-md-6 text-right mb-10">
                      {goals?.drafted && (
                        <button
                          className="btn btn-theme-green"
                          type="button"
                          onClick={() => setAddLongGoals("Short")}
                        >
                          + Goal
                        </button>
                      )}
                    </div>
                    <div className="col-md-12 mt-1">
                      <GoalsTable
                        list={formData?.shortTermGoals}
                        handleValueChange={handleValueChange}
                        DeleteLongGoals={DeleteLongGoals}
                        editGoalData={editGoalData}
                        setEditGoalData={setEditGoalData}
                        setAddLongGoals={setAddLongGoals}
                        goalData={goals}
                        name="Short"
                      />
                    </div>
                    <div className="col-md-12">
                      {goalError && (
                        <p className="mb-0 text-red">{goalError}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <input
                        className="mt-5"
                        type="checkbox"
                        name="completed"
                        checked={formData?.completed}
                        onChange={(e) =>
                          formData?.completed ? "" : handleValueChange(e)
                        }
                        disabled={
                          goals?.drafted === false || formData?.completed
                        }
                      />
                      <label htmlFor="markascompleted">Mark as Completed</label>
                    </div>
                    {/* have all goals been mate */}
                    {formData?.completed && (
                      <div className="col-md-4">
                        <input
                          className="mt-5"
                          type="checkbox"
                          name="goalsAchieved"
                          readOnly={true}
                          disabled={true}
                          checked={formData?.goalsAchieved}
                        />
                        <label htmlFor="markascompleted">
                          All Goals Achieved
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="row mt-20">
                    <div className="col-md-6">
                      <label className="font-weight-bold">Signature</label>
                    </div>
                    <div className="col-md-6 text-right">
                      <p className="p-case-note-detail__content-header">
                        <b>Date : </b>
                        {(therapistImageURL ||
                          (profile?.signatureUrl &&
                            goals?.drafted == false)) && (
                            <>
                              {signatureDateTime
                                ? moment
                                  .utc(signatureDateTime)
                                  .local()
                                  .format("MM-DD-yyyy hh:mm a")
                                : profile?.signatureUrl &&
                                !goals?.drafted &&
                                moment
                                  .utc(profile?.signatureDateTime)
                                  .local()
                                  .format("MM-DD-yyyy hh:mm a")}
                            </>
                          )}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="d-flex1">
                        {therapistImageURL ? (
                          <img
                            src={therapistImageURL}
                            alt="my signature"
                            style={{
                              marginBottom: 10,
                            }}
                            className="sign-img"
                          />
                        ) : profile?.signatureUrl && !goals?.drafted ? (
                          <img
                            src={profile?.signatureUrl}
                            alt="my signature"
                            style={{
                              marginBottom: 10,
                            }}
                            className="sign-img"
                          />
                        ) : (
                          (state?.caseNoteDTO?.therapistSignatureKey ||
                            (isTherepist &&
                              !isSharedCaseNote &&
                              profile.signatureUrl)) && (
                            <>
                              {/* <img
                              className="sign-img"
                              src={
                                (isTherepist &&
                                  !isSharedCaseNote &&
                                  profile?.signatureUrl) ||
                                state?.caseNoteDTO?.therapistSignatureKey
                              }
                              alt="signature"
                            /> */}
                            </>
                          )
                        )}
                      </div>
                      {/* {isTherepist && !isSubmmited && !isSharedCaseNote && (
                        <button
                          type="button"
                          className="btn btn-theme-green mt-10"
                          onClick={() => openSingModal("therapist")}
                        >
                          {therapistImageURL || profile?.signatureUrl
                            ? "Update"
                            : "Upload"}
                        </button>
                      )} */}
                    </div>

                    {/* <div className="col-md-4 mb-3">
                      <DigitalSignPopup
                        modalState={open}
                        setModalState={setOpen}
                        save={save}
                        clear={clear}
                        sigCanvas={sigCanvas}
                      />
                    </div>
                    <div className="col-md-4 text-right"></div> */}
                  </div>

                  {/* <div className="mb-5 d-flex justify-content-between">
                    {goals?.drafted && (
                      <div>
                        <button
                          onClick={SignatureHandler}
                          className="btn btn-success"
                        >
                          {therapistImageURL ? "Update" : "Create"} Signature
                        </button>
                        {signatureError && (
                          <p className="mb-0 text-red">{signatureError}</p>
                        )}
                      </div>
                    )}
                  </div> */}
                  {/* {isTherepist &&
                    !isSubmmited &&
                    !isSharedCaseNote &&
                    goals?.drafted && (
                      <button
                        type="button"
                        className="btn btn-theme-green mt-10"
                        onClick={() => openSingModal("therapist")}
                      >
                        {therapistImageURL || profile?.signatureUrl
                          ? "Update"
                          : "Upload"}
                      </button>
                    )} */}
                  <DigitalSignPopup
                    modalState={open}
                    setModalState={setOpen}
                    save={save}
                    clear={clear}
                    sigCanvas={sigCanvas}
                  />
                  {/* {goals?.drafted && (
                    <div className="row">
                      <div className="col-12 p-case-note-detail__actions">
                        {!formData?.completed && (
                          <button
                            type="button"
                            onClick={() =>
                              formData?.completed
                                ? console.log("")
                                : saveAsDraft(true)
                            }
                            className="action-btn btn btn-theme-green"
                            // disabled={
                            //   !signatureDateTime ? true : formData?.completed
                            // }
                          >
                            Save as Draft
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => {
                            const lTGoalLength = formData?.longTermGoals.length;
                            const stmGoalLength =
                              formData?.shortTermGoals.length;

                            if (lTGoalLength + stmGoalLength > 0) {
                              if (signatureDateTime) {
                                setSignatureError(false);
                                setGoalError(false);
                                saveAsDraft(false);
                              } else {
                                setSignatureError("Signature is required.");
                              }
                            } else {
                              console.log("goal");
                              setGoalError(
                                "Minimum one short/long term goal is required."
                              );
                            }
                          }}
                          className="action-btn btn btn-outline-theme-blue"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </form>
          </div>
        </div>

        {/*{addLongGoals && (
          <>
            <GoalsModal
              addLongGoals={addLongGoals}
              customStyles={customStyles}
              closeHandler={closeHandler}
              onAddLongGoals={onAddLongGoals}
              setAddLongGoals={setAddLongGoals}
              //addLongGoals={addLongGoals}
              setFormData={setFormData}
              formData={formData}
              editGoalData={editGoalData}
              setEditGoalData={setEditGoalData}
              goalData={goals}
            />
          </>
        )}

         {closeGoals && (
          <>
            <AppModal
              title="Confirmation"
              isOpen={closeGoals}
              onClose={() => setCloseGoals(false)}
            >
              <h4 className="text-center mb-25">
                Are you sure want to close the goals?
              </h4>
              <div className="d-flex justify-content-center mb-10">
                <button
                  type="button"
                  onClick={() => noHandler()}
                  className="btn btn-danger"
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => yesHandler()}
                  className="btn btn-success"
                >
                  Yes
                </button>
              </div>
            </AppModal>
          </>
        )}

        {goalsCompleted && (
          <>
            <AppModal
              title="Confirmation"
              isOpen={goalsCompleted}
              onClose={() => setGoalsCompleted(false)}
            >
              <h4 className="text-center mb-25">Have all goals been met?</h4>
              <div className="d-flex justify-content-center mb-10">
                <button
                  type="button"
                  onClick={() => handleCloseGoals()}
                  className="btn btn-danger"
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => handleGoalsAchive()}
                  className="btn btn-success"
                >
                  Yes
                </button>
              </div>
            </AppModal>
          </>
        )} */}
      </div>
    </>
  );
};

export default ViewArchiveGoal;
