export const agoraTypes = {
  clear: "agora_clear",
  setSession: "setSession",
  userJoined: "userJoined",
  userLeave: "userLeave",
  screenShare: "screenShare",
  agoraRecord: "agoraRecord",
  agoraControl: "agoraControl",
  volumeIndicator: "volumeIndicator",
};

export const messageTypes = {
  clear: "message_clear",
  addMsgList: "addMsgList",
  updateMsgList: "updateMsgList",
  updateUsers: "updateMsgUsers",
};

export const appTypes = {
  setAppLoader: "setAppLoader",
  setSoapNoteBillingInfo: "setSoapNoteBillingInfo",
};

export const emogiTypes = {
  showEmoji: "showEmoji",
  hideEmoji: "hideEmoji",
};
