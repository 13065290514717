import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtherTable from "./OtherTable";
import Pagination from "react-responsive-pagination";
import Search from "../../components/Admin/Search";
//import Service from "../../Service";
import { adminService } from "../../services/adminService";

import { toast } from "react-toastify";

function OtherUser() {
  let history = useNavigate();
  const [initialData, setInitialData] = useState({
    loading: false,
    error: false,
    data: [],
    otherUsers: [
      {
        id: 1,
        firstName: "baa",
        lastName: "sd",
        gender: "male",
        email: "ddsf@efef.fee",
      },
    ],
  });

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [otherUsers, setOtherUsers] = useState([]);
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  const deleteData = (id) => {
    //alert(id);
    console.log("delete", confirmDeletion);
    //setConfirmDeletion(false);
    adminService.DELETE(`marketing-registrations/${id}`)
      .then((res) => {
        if (res.status == "Success") {
          setConfirmDeletion(false);
          toast.success("User Deleted Successfully");
          getUserList();
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      })
      .catch((err) => toast.error(err));
  };

  const onSearch = () => {
    console.log("search", search);
    getUserList();
  };

  const getUserList = () => {
    setInitialData((item) => ({ ...item, loading: true }));

    adminService.getAll(
      `marketing-registrations?search=${search}&page=${page}&size=10&sort=id,desc`
    )
      .then((res) => {
        console.warn("get other list ", res.data);
        setInitialData((item) => ({ ...item, loading: false }));

        if (res.status === "Success") {
          console.log("marketingUser", res?.data);
          setOtherUsers(res?.data?.content);
          setTotalPages(res?.totalPages);
        } else {
          toast.error(res.data?.message || "Something went wrong.");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong.");
        setInitialData((item) => ({ ...item, loading: false }));
      });
  };

  useEffect(() => {
    getUserList();
  }, [search, page]);

  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            <div className="card border-none p-10">
              <div className="row">
                <div className="col-md-6">
                  <h4>Other Users</h4>
                </div>
                <div className="col-md-6">
                  <button
                    onClick={() => history("/add-marketing-user")}
                    className="btn btn-theme-green float-right"
                  >
                    + Other User
                  </button>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-6 mt-10">
                  <Search search={search} setSearch={setSearch} onSearch={onSearch} />
                </div>
                {initialData.loading ? (
                  <div className="p-5 mt-10em">
                    <span className="loader"></span>
                  </div>
                ) : (
                  <>
                    <div className="col-md-12 mt-10">
                      <OtherTable
                        headRow={["Name", "Email", "Phone No", "Gender", "Action"]}
                        list={otherUsers || []}
                        tableName="other"
                        onDelete={deleteData}
                        confirmDeletion={confirmDeletion}
                        setConfirmDeletion={setConfirmDeletion}
                      />
                    </div>
                    {otherUsers.length == 0 && (
                      <div className="col-md-12 mt-10">
                        <p className="text-center">
                          <b>{search != "" ? "No match found" : "Data Not Found"}</b>
                        </p>
                      </div>
                    )}
                    <div className="col-md-12 mt-10">
                      <Pagination
                        total={totalPages}
                        current={page + 1}
                        onPageChange={(page) => setPage(page - 1)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherUser;
