import { agoraTypes } from "../types";

const initialState = {
  // currentSession: null,
  // users: [],
  // screens: [],
  // isRecording: false,
  // controls: {
  //   screen: false,
  //   emoji: false,
  // },
  // volumeIndicators: [],
  configData: null,
};

// const userJoinedNew = (user, list) => {
//   if (list.some((usr) => usr.agoraId === user?.agoraId)) {
//     return list.map((currentUsr) => {
//       if (currentUsr.agoraId === user.agoraId) {
//         return {
//           ...currentUsr,
//           ...user,
//         };
//       }
//       return currentUsr;
//     });
//   }
//   return [...list, user];
// };

// const userLeaveNew = (user, list) => {
//   const uid =
//     typeof user === "string" || typeof user === "number" ? user : user?.id;
//   return list.filter((usr) => {
//     if (usr.agoraId && user?.agoraId) {
//       return usr.agoraId !== user?.agoraId;
//     }
//     return usr.id !== uid;
//   });
// };

// const screenShareNew = ({ isEnabled, user }, screens) => {
//   const uid =
//     typeof user === "string" || typeof user === "number" ? user : user?.id;
//   if (isEnabled) {
//     return [uid, ...(screens || [])];
//   } else {
//     (screens || [])?.filter((id) => id !== uid);
//   }
// };

// const addAgoraVolumeIndicatorNew = (indicators, initiallist) => {
//   return [...initiallist, indicators];
// };

const agoraNew = (state = initialState, action) => {
  console.warn("DIS", action);
  //alert("ok");
  const { type, payload } = action;
  switch (type) {
    case "configData":
      return {
        ...state,
        configData: payload.users,
      };

    // case "configUpdate":
    //   const old = initialState.configData;
    //   return {
    //     ...state,
    //     configData: { ...old, payload.users },
    //   };

    // case agoraTypes.setSession:
    //   return {
    //     ...state,
    //     currentSession: payload,
    //   };
    // case agoraTypes.clear:
    //   return initialState;

    // case agoraTypes.userJoined:
    //   return {
    //     ...state,
    //     users: userJoinedNew(payload, state.users),
    //   };

    // case agoraTypes.userLeave:
    //   return {
    //     ...state,
    //     users: userLeaveNew(payload, state.users),
    //   };

    // case agoraTypes.screenShare:
    //   return {
    //     ...state,
    //     screens: screenShareNew(payload, state.screens),
    //   };

    // case agoraTypes.agoraRecord:
    //   return {
    //     ...state,
    //     isRecording: payload,
    //   };
    // case agoraTypes.agoraControl: {
    //   console.log("agoraControl", {
    //     ...state,
    //     controls: { ...state.controls, ...payload },
    //   });
    //   return {
    //     ...state,
    //     controls: { ...state.controls, ...payload },
    //   };
    // }

    // case agoraTypes.volumeIndicator:
    //   return {
    //     ...state,
    //     volumeIndicators: payload,
    //   };

    //   case "configData":
    //     alert("ok");
    //     return {
    //       ...state,
    //       configData: payload,
    //     };

    default:
      return state;
  }
};

export default agoraNew;
