import { adminService } from "./adminService";

const resolveWithData = (resolve, rej, response) => {
  if (response?.data) {
    resolve(response?.data);
  } else {
    rej(response);
  }
};
const resolveWithContent = (resolve, rej, response) => {
  if (response?.data?.content) {
    resolve(response.data.content);
  } else if (response?.data) {
    resolve(response.data);
  } else {
    rej(response);
  }
};
export const getCaseNote = async (id) => {
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`case-notes/${id}`)
      .then((res) => {
        resolveWithData(resolve, rej, res);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

export const getArchiveCaseNote = async (id) => {
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`archive/case-notes/${id}`)
      .then((res) => {
        resolveWithData(resolve, rej, res);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

export const getDiagnoses = async (sessionId,therapyId, active = true) => {
  if (sessionId) {
    return new Promise((resolve, rej) => {
      adminService
        .getAll(
          `diagnoses/familyMember/${sessionId}?active=${active}&size=20000&therapyId=${therapyId}`
        )
        .then((res) => {
          resolveWithData(resolve, rej, res);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`diagnoses`)
      .then((res) => {
        if (res.data?.content) {
          resolve(res.data.content);
        } else {
          rej(res);
        }
      })
      .catch((err) => {
        rej(err);
      });
  });
};
export const getCpt = async (sessionId, active) => {
  if (sessionId) {
    return new Promise((resolve, rej) => {
      adminService
        .getAll(`cpts/sessions/${sessionId}?active=${active}&size=20000`)
        .then((res) => {
          resolveWithData(resolve, rej, res);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`cpts`)
      .then((res) => {
        resolveWithContent(resolve, rej, res);
      })
      .catch((err) => {
        rej(err);
      });
  });
};
export const getUserProfile = () => {
  return new Promise((resolve, rej) => {
    adminService.getAll(`user-profiles/user-detail`).then(
      (res) => {
        if (res?.status === "Success") {
          resolve(res.data);
        } else {
          rej(res);
        }
      },
      (error) => {
        rej(error);
      }
    );
  });
};

export const draftByInterpreter = async (body) => {
  return new Promise((resolve, rej) => {
    adminService.PUT(`case-notes/translation`, body).then(
      (res) => {
        resolveWithData(resolve, rej, res);
      },
      (error) => {
        rej(error);
      }
    );
  });
};

export const submitByInterpreter = async (id, body) => {
  return new Promise((resolve, rej) => {
    adminService.POST(`case-notes​/${id}​/translation`, body).then(
      (res) => {
        resolveWithData(resolve, rej, res);
      },
      (error) => {
        rej(error);
      }
    );
  });
};

export const acceptByFamily = async (id) => {
  return new Promise((resolve, rej) => {
    adminService.PUT(`case-notes/${id}/families`).then(
      (res) => {
        if (res.status === "Success") {
          resolve(res);
        } else {
          rej(res);
        }
      },
      (error) => {
        rej(error);
      }
    );
  });
};

export const signoffByFamily = (id, body) => {
  // /session-service/api/case-notes/{id}/signed-off/family
  return new Promise((resolve, rej) => {
    adminService.PUT(`case-notes/${id}/signed-off/family`, body).then(
      (res) => {
        // resolveWithData(resolve, rej, res)
      },
      (error) => {
        // rej(error)
      }
    );
  });
};

export const getTherapistList = () => {
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`therapists`)
      .then((res) => {
        resolveWithContent(resolve, rej, res);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

export const shareCaseNote = (id, body) => {
  return new Promise((resolve, rej) => {
    adminService.PUT(`case-notes/${id}/share`, body).then(
      (res) => {
        if (res.status === "Success") {
          resolve(res.data);
        } else {
          rej(res);
        }
      },
      (error) => {
        rej(error);
      }
    );
  });
};

export const getSharedCaseNotes = () => {
  // /session-service/api/case-notes/shared
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`case-notes/shared`)
      .then((res) => {
        resolveWithContent(resolve, rej, res);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

export const getSoapNoteBillingData = (tenantId) => {
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`misc/billing-company-detail?tenantId=${tenantId}`)
      .then((res) => {
        resolveWithData(resolve, rej, res);
        console.log(res, "uihiuhu");
      })
      .catch((err) => {
        rej(err);
      });
  });
};

// MIS Goals
export const getMisGoalsService = async (id) => {
  return new Promise((resolve, rej) => {
    adminService
      .getAll(`/session-service/api/goals/${id}`)
      .then((res) => {
        resolveWithData(resolve, rej, res);
      })
      .catch((err) => {
        rej(err);
      });
  });
};
