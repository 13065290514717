import React, { useEffect, useState } from "react";
import { adminService } from "../services/adminService";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import classNames from "classnames";
import { CaseNoteHeader, CaseNoteTableRow } from "../components/case-notes";
import { getSharedCaseNotes } from "../services/caseNoteServices";
import PaginationTabs from "./pagination/index";

const CaseNotes = () => {
  const history = useNavigate();
  const [currentTab, setCurrentTab] = useState("own");
  const [state, setState] = useState({
    caseNotes: [],
    sharedCaseNotes: [],
    myCaseNotes: [],
    mySharedCaseNotes: [],
    isLoading: true,
    isSharedLoading: true,
    selected: {},
  });
  const [stateShared, setStateShared] = useState({
    caseNotes: [],
    sharedCaseNotes: [],
    myCaseNotes: [],
    mySharedCaseNotes: [],
    isLoading: true,
    isSharedLoading: true,
    selected: {},
  });

  // const [sharedState, setSharedState] = useState({
  //     sharedCaseNotes: [],
  //     mySharedCaseNotes: [],
  //     isSharedLoading: true,
  //     selected: {},
  // })

  const [search, setSearch] = useState("");
  const [sharedSearch, setSharedSearch] = useState("");
  const [query, setQuery] = useState({
    totalPages: 0,
    currentPage: 0,
    pages: [],
    sortBy: "createdDate,DESC",
    perPage: 10,
  });
  const [queryShared, setQueryShared] = useState({
    totalPages: 0,
    currentPage: 0,
    pages: [],
    sortBy: "createdDate,DESC",
    perPage: 10,
  });
  // const [sharedQuery, setSharedQuery] = useState({
  //     totalPages: 0,
  //     currentPage: 0,
  //     pages: [],
  //     sortBy: 'startDateTime,DESC',
  //     perPage: 10,
  // })
  const [userType, setUserType] = useState();
  const user = useSelector((st) => st.adminUser.user)?.userDetails;
  const isTherepist = (user?.roles || []).includes("ROLE_THERAPIST");
  const isInterepreter = (user?.roles || []).includes("ROLE_INTERPRETER");
  const isFamily = (user?.roles || []).includes("ROLE_FAMILY");
  const isAdmin = (user?.roles || []).includes("ROLE_RCE");
  const getListApi = ({ currentPage, sortBy, perPage }, s = search) =>
    `case-notes/all?name=${s}&page=${currentPage == 0 ? currentPage : currentPage - 1
    }&size=${perPage}&sort=${sortBy}`;
  let timeout;
  console.log("isAdmin",isAdmin);
  const getSharedListApi = (
    { currentPage, sortBy, perPage },
    s = sharedSearch
  ) =>
    `case-notes/shared?name=${s}&page=${currentPage == 0 ? currentPage : currentPage - 1
    }&size=${perPage}&sort=${sortBy}`;

  useEffect(() => {
    setState({
      ...state,
      isLoading: true,
    });

    getSoapNoteList();

    if (isTherepist) {
      setStateShared({
        ...stateShared,
        isLoading: true,
      });
      getSharedCaseNotes();
    }
  }, []);

  const getSoapNoteList = () => {
    adminService
      .getAll(getListApi(query, search))
      .then((res) => {
        setPageContent(res);
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        console.log({ err });
      });
  };

  const getSharedCaseNotes = () => {
    adminService
      .getAll(getSharedListApi(queryShared, sharedSearch))
      .then((res) => {
        setPageContentShared(res);
      })
      .catch((err) => {
        setStateShared({
          ...stateShared,
          isLoading: false,
        });
        console.log({ err });
      });
  };

  useEffect(() => {
    adminService.getAll(`user-profiles/user-detail`).then(
      (res) => {
        if (res?.status === "Success") {
          console.log("user-detail", res.data);
          setUserType(res.data?.userType);
          // setProfile(res.data);
          //dispatch(adminAction.setProfileData(res.data));
        } else {
          // res?.message
          //   ? toast.error(res?.message)
          console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );
    const caseNotes = [...state.myCaseNotes];
    setState((prevState) => ({ ...prevState, caseNotes }));
  }, [state.myCaseNotes]);

  useEffect(() => {
    const caseNotes = [...stateShared.myCaseNotes];
    setStateShared((prevState) => ({ ...prevState, caseNotes }));
  }, [stateShared.myCaseNotes]);

  const onSearch = () => {
    console.log("object", search, sharedSearch);
    if (currentTab == "own") {
      getSoapNoteList();
    } else {
      getSharedCaseNotes();
    }
  };

  useEffect(() => {
    console.log("currentTab", currentTab);
    if (currentTab == "own") {
      getSoapNoteList();
    }
    if (currentTab == "shared") {
      getSharedCaseNotes();
    }
  }, [search, sharedSearch, currentTab]);

  const setPageContentShared = (res) => {
    if (res.data?.content) {
      setStateShared({
        ...stateShared,
        myCaseNotes: res.data?.content,
        isLoading: false,
      });
      setQueryShared((q) => ({
        ...q,
        totalPages: res.data?.totalPages,
        pages: new Array(res.data?.totalPages).fill().map((_i, inx) => inx),
      }));
    }
  };

  const onPaginateFetch = (number) => {
    setState({
      ...state,
      isLoading: true,
    });
    adminService
      .getAll(
        getListApi(
          {
            ...query,
            currentPage: number,
          },
          search
        )
      )
      .then((res) => {
        setPageContent(res);
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        console.log({ err });
      });
  };

  const setPageContent = (res) => {
    if (res.data?.content) {
      setState({
        ...state,
        myCaseNotes: res.data?.content,
        isLoading: false,
      });
      setQuery((q) => ({
        ...q,
        totalPages: res.data?.totalPages,
        pages: new Array(res.data?.totalPages).fill().map((_i, inx) => inx),
      }));
    }
  };

  const paginate = (number) => {
    console.log("number", number);
    setQuery((q) => ({
      ...q,
      currentPage: number,
    }));
    onPaginateFetch(number);
  };
  const paginatePre = () => {
    paginate(query.currentPage - 1);
  };
  const paginateNext = () => {
    paginate(query.currentPage + 1);
  };

  // const onSelect = (caseNote) => {
  //     setState({
  //         ...state,
  //         selected: caseNote
  //     })
  // }

  const TableHeader = () => {
    return (
      <div className="p-case-notes__table-header">
        <div className="p-case-notes__table-head-col cn-tbl-header">
          Client Name
        </div>
        <div className="p-case-notes__table-head-col cn-tbl-header">
          Session Date
        </div>
        <div className="p-case-notes__table-head-col cn-tbl-header">
          Therapy
        </div>
        <div className="p-case-notes__table-head-col cn-tbl-header">
          Therapist
        </div>
        <div
          className="p-case-notes__table-head-col cn-tbl-header"
          style={{ flex: "1 1 45px" }}
        >
          Status
        </div>
        <div
          className="p-case-notes__table-head-col cn-tbl-header"
          style={{ flex: "0 1 100px" }}
        >
          Action
        </div>
      </div>
    );
  };

  return (
    <div className="p-case-notes col-md-10 d-flex flex-column">
      <div
        className={classNames("card border-0 shadow-sm", "")}
        style={{ marginBottom: 20 }}
      >
        <CaseNoteHeader
          title="All Session Notes"
          search={currentTab == "own" ? search : sharedSearch}
          onSearch={onSearch}
          onChange={currentTab == "own" ? setSearch : setSharedSearch}
          currentTab={currentTab}
        />
      </div>
      {/* {state.isLoading ? <div className="min-h-100 bg-white" ><div className="loader" /></div> : "" } */}
      {
        <div className={classNames("card border-0 shadow-sm", "")}>
          <div className="p-case-notes__main-box">
            <div className="p-case-notes__table p-case-notes__table--full">
              <div className="p-case-notes__table-body soap-note-tabs">
                {user?.roles[0] !== "ROLE_FAMILY" && user?.roles[0] !== "ROLE_RCE" && user?.roles[0] !== "ROLE_INTERPRETER" && (
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() => setCurrentTab("own")}
                    >
                      <a
                        className="nav-link active"
                        id="own-tab"
                        data-toggle="tab"
                        href="#own"
                        role="tab"
                        aria-controls="own"
                        aria-selected="true"
                      >
                        Session Notes
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setCurrentTab("shared")}
                    >
                      <a
                        className="nav-link"
                        id="shared-tab"
                        data-toggle="tab"
                        href="#shared"
                        role="tab"
                        aria-controls="shared"
                        aria-selected="false"
                      >
                        Shared Session Notes
                      </a>
                    </li>
                  </ul>
                )}
                <div
                  className="d-block tab-content"
                  id="myTabContent"
                  style={{ paddingBottom: 20 }}
                >
                  <div
                    className="tab-pane fade active show"
                    id="own"
                    role="tabpanel"
                    aria-labelledby="own-tab"
                  >

                    <TableHeader />
                    {state.isLoading ? (
                      <div>
                        <div className=" bg-white">
                          <div className="loader" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {state.caseNotes?.map((caseNote) => (
                      <CaseNoteTableRow
                        {...caseNote}
                        isTherepist={isTherepist}
                        isInterepreter={isInterepreter}
                        isFamily={isFamily}
                        isAdmin={isAdmin}
                        key={caseNote.caseNoteDTO?.id}
                      />
                    ))}
                    {!state.isLoading && state.caseNotes?.length == 0 && (
                      <h5 className="text-center p-3">No Data Found</h5>
                    )}
                    <div className="row">
                      <div
                        style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
                      >
                        <div style={{ marginTop: "2px" }}>
                          {query.totalPages > 0 && (
                            <PaginationTabs
                              shareQuery={query}
                              pagePre={paginatePre}
                              pageNext={paginateNext}
                              pageShare={paginate}
                              paginate={paginate}
                            />
                          )}
                        </div>
                        {userType == "ROLE_THERAPIST" ?
                          <Link className="btn btn-success ml-3" name="own" to={{ pathname: "/archive-case-notes", btnName: "own" }}>Archive</Link>
                          : ''}
                        {userType == "ROLE_RCE" ?
                          <Link className="btn btn-success ml-3" name="own" to={{ pathname: "/archive-case-notes", btnName: "own" }}>Archive</Link>
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="shared"
                    role="tabpanel"
                    aria-labelledby="shared-tab"
                  >
                    <TableHeader />
                    {stateShared.isLoading ? (
                      <div>
                        <div className=" bg-white">
                          <div className="loader" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {stateShared.caseNotes?.map((caseNote) => (
                      <CaseNoteTableRow
                        {...caseNote}
                        isTherepist={isTherepist}
                        isInterepreter={isInterepreter}
                        isFamily={isFamily}
                        key={caseNote.caseNoteDTO?.id}
                      />
                    ))}
                    {!stateShared.isLoading &&
                      stateShared.caseNotes?.length == 0 && (
                        <h5 className="text-center p-3">No Data Found</h5>
                      )}
                    <div className="row mt-3">
                      <div
                        style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
                      >
                        {userType == "ROLE_THERAPIST" ?
                          <Link className=" btn btn-success ml-3" name="shared" to={{ pathname: "/archive-case-notes", btnName: "shared" }}>Archive</Link>
                          : ''}
                      </div>
                    </div>
                    {/* {!stateShared.isLoading && queryShared.totalPages > 0  &&
                                        <PaginationTabs 
                                            shareQuery={queryShared}
                                            pagePre={paginatePreShared}
                                            pageNext={paginateNextShared}
                                            pageShare={paginateShared}
                                        />
                                    } */}
                  </div>
                </div>
              </div>
            </div>
            {/* <CaseNoteColDetail {...state.selected} /> */}
          </div>
        </div>
      }
    </div >
  );
};

export default CaseNotes;