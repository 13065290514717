/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SessionsDetail from "../../components/SessionsDetail";
import { adminService } from "../../services/adminService";
import allActions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { paginationDisplayCount } from "../../config/Config";
import { debounce } from "lodash";

function Interpreter(props) {
  const dispatch = useDispatch();
  const [interpreter, setInterpreter] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [search, setSearch] = useState("");
  const [therapysessions, setTherapySessions] = useState([]);
  const [rowclickid, setRowClickId] = useState(null);
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalpages, setTotalPages] = useState([]);
  const history = useNavigate();
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);

  useEffect(() => {
    tenantPemission?.interpreter == false && history("/dashboard");
  }, [tenantPemission?.interpreter]);

  useEffect(() => {
    dispatch(allActions.adminAction.clearOnboardData());
    const controller = new AbortController();
    const signal = controller.signal;
    debounceHitApi(() => {
      fetchData(signal);
    });
    return () => {
      setloading(false);
      controller.abort();
    };
  }, [search, currentPage]);

  useEffect(() => {
    fetchData();
  }, [status, currentPage, paginationDisplayCount]);

  const fetchData = (signal) => {
    setloading(true);
    adminService
      .getAll(
        `interpreters/filter?page=${currentPage}&size=${paginationDisplayCount}&status=${status}&name=${search}&sort=id,DESC`,
        signal
      )
      .then(
        (res) => {
          if (res?.status === "Success") {
            setInterpreter(res.data.content);
            setTotalPages(res.data.totalPages);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log({ error });
          if (error.name !== "AbortError") {
            setloading(false);
          }
        }
      );
  };

  useEffect(() => {
    if (interpreter?.length > 0) {
      const isSelected = interpreter.some((item) => item.id === rowclickid);
      if (!isSelected) {
        onClickRow(interpreter[0]?.id);
      }
    } else {
      onClickRow(null);
    }
  }, [rowclickid, interpreter]);

  //pagination
  const pageNumbers = [];

  for (let i = 0; i <= totalpages - 1; i++) {
    pageNumbers.push(i);
  }

  const paginate = (number) => {
    setCurrentPage(number);
  };
  const paginatePre = () => {
    setCurrentPage(currentPage - 1);
  };
  const paginateNext = () => {
    setCurrentPage(currentPage + 1);
  };
  //end

  const hitApi = (api) => {
    if (typeof api === "function") {
      api();
    }
  };
  const debounceHitApi = useCallback(debounce(hitApi, 500), []);

  const onClickRow = (id) => {
    if (id) {
      setloadings(true);
      setRowClickId(id);
      adminService
        .getById(`sessions/filter/formatted?interpreterId=` + id)
        .then(
          (res) => {
            if (res.status === "Success") {
              setTherapySessions(res.data.content);
            } else {
              toast.error(res.message);
            }
            setloadings(false);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const successSub = () => {
    onClickRow(rowclickid);
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Interpreter</div>
              <div className="text-right header-btn">
                <Link to={`/add-interpreter`}>
                  <button className="btn btn-primary custom-btn-green custom-btn-green-auto">
                    <img
                      className="pr-2"
                      src="../resources/assets/plus-icon-button.svg"
                      alt=""
                    />{" "}
                    Add Interpreter
                  </button>
                </Link>
              </div>
            </div>
            <form>
              <div className="row">
                <div className="col-md-6 justify-content-between align-items-center mt-4 mb-4 d-flex">
                  <div className="col-auto w-75">
                    <input
                      type="search"
                      name="text"
                      className="form-control search"
                      placeholder="Search by Name"
                      onChange={(e) => setSearch(e.target.value)}
                      autofocus
                    />
                  </div>

                  <div className="col-auto">
                    <select
                      className="form-control time"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option className="form-control time" value="">
                        All
                      </option>
                      <option className="form-control time" value={true}>
                        Active
                      </option>
                      <option className="form-control time" value={false}>
                        Inactive
                      </option>
                    </select>
                  </div>

                  <div className="col-auto">
                    <button className="btn btn-theme-green">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="32"
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-2"></div>
                <div className="col-md-2"></div>
              </div>
            </form>

            {loading ? (
              <div className="loader"></div>
            ) : (
              <>
                <div className="row no-gutters">
                  <div className="col-md-8 therapist p-0">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Completed Appointment</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        {interpreter.length > 0 ? (
                          interpreter &&
                          interpreter.map((item, index) => (
                            <tbody>
                              <tr
                                onClick={() => onClickRow(item.id)}
                                className={`cursor-pointer ${
                                  item.id == rowclickid ? "bg-light-cyan" : ""
                                }`}
                              >
                                <td>
                                  <div className="user-info">
                                    <span className="admin-name">
                                      <img
                                        src={item.userProfile.profilePicUrl}
                                        alt=""
                                        className="image mr-2"
                                      />
                                      <div className="content">
                                        <span className="name-table name">
                                          {item.userProfile.firstName}{" "}
                                          {item.userProfile.lastName}
                                        </span>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="name-table ml-0 mr-5 text-center">
                                    {item.appointments}
                                  </div>
                                </td>
                                <td>
                                  <div className="name-table">
                                    {item.userProfile.active === true
                                      ? "Active"
                                      : "InActive"}
                                  </div>
                                </td>
                                <td>
                                  <Link
                                    to={"/interpreter-details/" + item.id}
                                    className="view-all-link"
                                  >
                                    <div className="name-table name">
                                      View Details
                                      <img
                                        className="pl-2"
                                        src="../resources/assets/arrow.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>
                                <div className="mt-5">
                                  <h5>No Interpreter Available</h5>
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                    {loading ? (
                      <></>
                    ) : (
                      <>
                        {/* Pagination */}
                        <nav>
                          <ul className="pagination justify-content-center">
                            <li
                              onClick={() => paginatePre()}
                              className="page-item"
                            >
                              {currentPage <= 0 ? (
                                <></>
                              ) : (
                                <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                                  <img
                                    className="pr-2"
                                    src="../resources/assets/arrow-left.svg"
                                  />
                                </div>
                              )}
                            </li>
                            {pageNumbers.map((number) => (
                              <li
                                key={number}
                                onClick={() => paginate(number)}
                                className="page-item"
                              >
                                <div
                                  className={`${
                                    currentPage === number
                                      ? "pagination-active page-link pagination"
                                      : "page-link pagination cursor-pointer"
                                  }`}
                                >
                                  {number + 1}
                                </div>
                              </li>
                            ))}
                            <li
                              onClick={() => paginateNext()}
                              className="page-item"
                            >
                              {currentPage >= totalpages - 1 ? (
                                <></>
                              ) : (
                                <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                                  <img
                                    className="pr-2"
                                    src="../resources/assets/arrow-right.svg"
                                  />
                                </div>
                              )}
                            </li>
                          </ul>
                        </nav>
                        {/* End */}
                      </>
                    )}
                  </div>

                  <div className="col-md-4 no-padding text-center therapist table-th-merge-gap bg-color1">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="bg-color1">
                          <tr>
                            {rowclickid !== null ? (
                              <td>
                                {loadings && (
                                  <div className="loader-small"></div>
                                )}
                                {!loadings && (
                                  <SessionsDetail
                                    therapysessions={therapysessions}
                                    successSub={() => successSub()}
                                    bgColor="bg-color1"
                                    page="interpreter"
                                    interpreterId={rowclickid}
                                  />
                                )}
                              </td>
                            ) : (
                              <td>
                                <div className="py-5">
                                  Click to select row to see the sessions
                                </div>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Interpreter;
