import React, { useEffect, useState } from "react";
import Tab from "../components/Tabs/Tab";
import MySetting from "./Settings/MySetting";
import TenantAdminSetting from "./Settings/TenantAdminSettingTab/TenantAdminSetting";
import { useSelector } from "react-redux";

function Settings() {
  let user = useSelector((state) => state.adminUser.user);
  const profile = useSelector((state) => state.adminUser.profile);

  let apiLink = profile?.userType == "ROLE_RCE" ? "control-notification/admin" : profile?.userType == 'ROLE_THERAPIST' ? "control-notification/" + user.userDetails.userUniqueId : profile?.userType == 'ROLE_FAMILY' ? "control-notification/" + user.userDetails.userUniqueId : '';
    console.log("user detail", user.userDetails.roles[0]);

  const [activeTab, SetActiveTab] = useState(0);

  const tabContent = () => {
    console.log("tab", activeTab);
    switch (activeTab) {
      case 1:
        return <TenantAdminSetting />;
        break;
      // case '2':
      //   return <ComponentTwo/>;
      // .. etc
      default:
        return <MySetting />;
    }
  };

  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            <div className="content-header pb-0 border-bottom">
              <div className="text-left header-title ">Settings</div>
            </div>
            {/* <MySetting /> */}
            {user.userDetails.roles[0] === "ROLE_RCE" && user.userDetails.superTenantAdmin ?
              <div className="mt-4">
                <Tab
                  tabNav={["My Settings", "Tenant Admin"]}
                  active={activeTab}
                  setActive={SetActiveTab}
                >
                  {tabContent()}
                </Tab>
              </div>
              :
              <MySetting userUniqueId={user.userDetails.userUniqueId} roles={user.userDetails.roles[0].toString()} />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
