import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import {
  submitForm,
  updateReplyForm,
  updateForm,
} from "../../services/formsServices";
import {
  downloadFile,
  _toastHandleErr,
  _toasthandleRes,
} from "../../utils/commonFunctions";
import { AttachmentFile } from "../attachment";
import { toast } from "react-toastify";
import AppModal from "../AppModal";
import AttachmentBtn from "../attachment/AttachmentBtn";
import { useForm } from "react-hook-form";
import { fileDownloadHandler } from "../../utils/ImageDownload";

const FormReplyForm = ({
  isOpen,
  onClose,
  afterSuccess,
  form,
  childId,
  ...props
}) => {
  const attachmentType = form.fileType?.includes("image")
    ? "image"
    : form.fileType?.includes("video/mp4") ? 'video' : form.fileType?.includes("audio/mpeg") ? 'audio' : 'document';
  const user = useSelector((state) => state.adminUser.user?.userDetails);
  const [state, setState] = useState({
    isModal: false,
    formState: {},
    isLoading: false,
  });

  const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm();

  useEffect(() => {
    if (form.fileFormDto) {
      const { fileName, fileType, key, id } = form.fileFormDto;
      setValue("title", fileName);
      const type = fileType?.includes("image") ? "image" : "document";
      setState((state) => ({
        ...state,
        formState: {
          ...state.formState,
          id,
          file: {
            url: key,
            type,
            fileType,
          },
        },
      }));
    }
  }, [form.fileFormDto]);

  const onSubmit = () => {
    if (!state.formState?.file) {
      toast.error("Please attach form");
      return;
    }
    const sender =
      typeof user?.roles === "string"
        ? user?.roles
        : user?.roles?.length > 0 && user?.roles[0];
    const dataObj = {
      ...getValues(),
      form:
        state.formState?.file?.file !== undefined
          ? state.formState?.file?.file
          : state.formState?.file,
      familyMemberId: childId,
      therapistId: form.therapistId,
      sender,
      reply: true,
      replyId: form?.fileFormDto?.id ? form?.fileFormDto?.id : form?.id,
    };
    const data = new FormData();
    Object.keys(dataObj).forEach((item) => {
      data.append([item], dataObj[item]);
    });

    setState((state) => ({ ...state, isLoading: true }));
    if (form?.fileFormDto?.key) {
      let formId = form?.fileFormDto?.id ? form?.fileFormDto?.id : form?.id;
      updateForm(formId, data)
        .then((res) => {
          _toasthandleRes(res);
          if (typeof afterSuccess === "function") {
            afterSuccess();
          }
        })
        .catch(_toastHandleErr)
        .finally(() => {
          setState((state) => ({
            ...state,
            isLoading: false,
            isModal: false,
          }));
        });
    } else {
      submitForm(data)
        .then((res) => {
          _toasthandleRes(res);
          if (typeof afterSuccess === "function") {
            afterSuccess();
          }
        })
        .catch(_toastHandleErr)
        .finally(() => {
          setState((state) => ({
            ...state,
            isLoading: false,
            isModal: false,
          }));
        });
    }
  };

  const onChange = (e) => {
    const { files } = e.target;
    const type = files[0]?.type?.includes("image") ? "image" : "document";

    const reader = new FileReader();
    reader.onloadend = function (file) {
      setState((state) => ({
        ...state,
        formState: {
          ...state.formState,
          file: {
            file: files[0],
            url: file.target.result,
            type,
            fileType: files[0]?.type,
            fileName: files[0]?.name,
          },
        },
      }));
    };
    reader.readAsDataURL(files[0]);
  };
  const toggleModal = () => {
    setState((state) => ({ ...state, isModal: !state.isModal }));
  };

  const replyFormType = form?.fileFormDto?.fileType?.includes("image")
    ? "image"
    : form?.fileFormDto?.fileType?.includes("video/mp4") ? 'video' : form?.fileFormDto?.fileType?.includes("audio/mpeg") ? 'audio' : 'document';

  return (
    <div>
      <div className="d-flex  align-items-end">
        <div className="col-auto text-center">
          <AttachmentFile
            fileType={form.fileType}
            className="mx-auto"
            src={form.key}
            attachmentType={attachmentType}
          />
          <button
            onClick={() => {
              // fileDownloadHandler(form.key);
              window.open(form.key, "_blank");
            }}
            className="btn btn-theme-green"
          >
            Download
          </button>
        </div>
        <div className="col-auto">
          {form?.fileFormDto?.key && (
            <AttachmentFile
              fileType={form?.fileFormDto?.fileType}
              src={form.fileFormDto.key}
              className="mx-auto"
              attachmentType={replyFormType}
              className={`c-attachments__file c-attachments__file--${replyFormType}`}
            />
          )}
          {form.reply && (
            <button
              onClick={toggleModal}
              disabled={state.isLoading}
              className="btn btn-theme-green"
            >
              {form?.fileFormDto?.key ? "Update" : "Upload"}
            </button>
          )}
        </div>
      </div>
      <AppModal
        title="Upload Form"
        isOpen={state.isModal}
        onClose={toggleModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            {...register('title',{ required: true })}
            name="title"
            placeholder="form title"
            className="form-control"
          />
          {errors.title && (
            <span className="w-100 d-block pt-2 text-left error-text">
              Title is required
            </span>
          )}
          <div className="align-items-center d-flex mt-2">
            {state.formState.file?.url && (
              <AttachmentFile
                {...state.formState.file}
                src={state.formState.file.url}
                attachmentType={state.formState?.file?.type}
                className={`c-attachments__file c-attachments__file--${state.formState.file.type}`}
              />
            )}
            <AttachmentBtn
              onChange={onChange}
              accept="image/*, .pdf, .doc, .docx"
              label={state.formState.file?.url ? "Change Form" : "Attach Form"}
            />
          </div>
          <button
            disabled={state.isLoading}
            type="submit"
            className={classNames("btn btn-theme-green mb-4", {
              "has-loader": state.isLoading,
            })}
          >
            Submit
            {state.isLoading && <i className="loader-small"></i>}
          </button>
        </form>
      </AppModal>
    </div>
  );
};
export default FormReplyForm;
