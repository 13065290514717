import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Switch, Link, useLocation } from "react-router-dom";
//import { useAlert,positions } from 'react-alert';

import InterpreterPersonalInformation from "../Representative/OnBoardingUser/InterpreterPersonalInformation";
import InterpreterLanguageInformation from "./OnBoardingUser/InterpreterLanguageInformation";
import InterpreterSetAvailability from "../Representative/OnBoardingUser/InterpreterSetAvailability";

import Stepper from "../../components/Stepper";
import { useSelector, useDispatch } from "react-redux";

function AddInterpreter() {
  //const alert = useAlert();
  let loading = false;
  const history = useNavigate();
  //const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState("");
  const [therapistId, setInterpreterId] = useState(null);
  //const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
  const steps = getSteps();
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    tenantPemission?.interpreter == false && history("/dashboard");
  }, [tenantPemission?.interpreter]);

  function getSteps() {
    return ["Personal Information", "Interpreting Details", "Set Availability"];
  }

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <InterpreterPersonalInformation
          therapistId={therapistId}
          setInterpreterId={setInterpreterId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      case 1:
        return (
          <InterpreterLanguageInformation
          therapistId={therapistId}
          setInterpreterId={setInterpreterId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      case 2:
        return (
          <InterpreterSetAvailability
          therapistId={therapistId}
          setInterpreterId={setInterpreterId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    setActiveStep(0);
  }, []);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Add Interpreter</div>
            </div>
            <Stepper activeStep={activeStep} steps={getSteps()} />
            {getStepContent(activeStep)}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddInterpreter;
