import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  getUTCTimeToLocalByDateWithMeridium,
  calculateDuration,
} from "../utils/commonFunctions";
import moment from "moment-timezone";
import { daysJson, monthJson } from "../config/Config";
import DeleteSession from "../pages/Representative/DeleteSession";
import { toast } from "react-toastify";
import { adminService } from "../services/adminService";
import { useSelector } from "react-redux";
import SessionCard from "./SessionCard";

function SessionsDetail(props) {
  let location = useLocation();
  const [modalOpenClose, setModalOpenClose] = useState(false);
  let user = useSelector((state) => state.adminUser.user);
  const [therapysessions, setTherapySessions] = useState([]);
  const [deletesessiondetail, setdeletesessiondetail] = useState({
    info: "",
  });

  const Deletesession = (id, name, sessionInfo) => {
    let data = {
      sessionid: id,
      therapyname: name,
    };
    setdeletesessiondetail((deletesessiondetail) => ({
      ...deletesessiondetail,
      ["info"]: data,
      sessionInfo,
    }));
    setModalOpenClose(true);
  };

  const successSub = () => {
    let startFromDateTime = moment().format("YYYY-MM-DD") + "T00:00:00";
    let startToDateTime = moment().format("YYYY-MM-DD") + "T23:59:59";
    adminService
      .getAll(
        `sessions/filter/formatted/?page=0&size=20&sort=startDateTime,DESC&startFromDateTime=${startFromDateTime}&startToDateTime=${startToDateTime}&timeZone=${moment.tz.guess()}`
      )
      .then(
        (res) => {
          if (res.status === "Success") {
            setTherapySessions(res.data.content);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <>
      <div className={`session-detail-comp custom-table-view ${props.bgColor}`}>
        <div className="content-header mb-3 pt-0">
          <div className="text-left session-tab-heading header-title">
            {location.pathname.includes("/dashboard") && "Today's"}{" "}
            {location.pathname.includes("/interpreter") && "Upcoming"}{" "}
            {!location.pathname.includes("/family") && "Sessions"}
          </div>
          <div className="text-right header-btn">
            {props.allLink && (
              <Link
                to={props.allLink}
                className="btn btn-primary custom-btn-yellow custom-btn-yellow-sm"
              >
                View All
              </Link>
            )}
            {!props.allLink && !location.pathname.includes("/family") && (
              <Link
                to={`/therapy-sessions${
                  location.pathname.includes("/therapist")
                    ? `?therapistId=${
                        props?.therapistid ? props?.therapistid : ""
                      }`
                    : props?.interpreterId
                    ? `?interpreterId=${
                        props?.interpreterId ? props?.interpreterId : ""
                      }`
                    : props?.childId
                    ? `?familyMemberId=${props?.childId}`
                    : ""
                }`}
                className="btn btn-primary custom-btn-yellow custom-btn-yellow-sm"
              >
                View All
              </Link>
            )}
          </div>
        </div>

        {props.therapysessions.length > 0 ? (
          props.therapysessions &&
          props.therapysessions.map((item) => (
            <>
              {item &&
                item.map((info, key) => (
                  <>
                    <span className="row session-detail-date m-1">
                      <b className="mr-2">
                        {
                          daysJson[
                            parseInt(
                              moment(info.date).isoWeekday() === 0
                                ? 6
                                : moment(info.date).isoWeekday() - 1
                            )
                          ].name
                        }{" "}
                        {moment(info.date).format("DD")}{" "}
                      </b>
                      {
                        monthJson[parseInt(moment(info.date).format("MM")) - 1]
                          .name
                      }
                      , {moment(info.date).format("YYYY")}
                    </span>
                    {info.therapySession &&
                      info.therapySession.map((subinfo) => {
                        const {
                          id,
                          therapist,
                          therapy,
                          startDateTime,
                          endDateTime,
                          status,
                          groupSession,
                        } = subinfo;
                        const sessionCardProps = {
                          key: "sessionCard" + key + id,
                          firstName: therapist?.userProfile.firstName,
                          lastName: therapist?.userProfile.lastName,
                          userImg: therapist?.userProfile.profilePicUrl,
                          therapyName: therapy?.name,
                          startTime:
                            getUTCTimeToLocalByDateWithMeridium(startDateTime),
                          endTime:
                            getUTCTimeToLocalByDateWithMeridium(endDateTime),
                          duration: calculateDuration(
                            startDateTime,
                            endDateTime
                          ),
                          status,
                          className: "bg-white",
                          editLink:
                            ![
                              "CANCELLED",
                              "COMPLETED",
                              "NOT_COMPLETED",
                            ].includes(status) &&
                            "/edit-therapy-session/" +
                              id +
                              `?group=${groupSession}`,
                          onCancel:
                            ![
                              "CANCELLED",
                              "COMPLETED",
                              "NOT_COMPLETED",
                              "MISSED",
                            ].includes(status) &&
                            ["ROLE_RCE", "ROLE_THERAPIST"].includes(
                              user.userDetails.roles[0]
                            ) &&
                            (() =>
                              Deletesession(
                                subinfo.id,
                                subinfo.therapy.name,
                                subinfo
                              )),
                        };
                        return <SessionCard {...sessionCardProps} />;
                      })}{" "}
                    <hr />
                  </>
                ))}
            </>
          ))
        ) : (
          <div
            className={`session-detail-section mt-3 ${
              props.bgColor === "bg-color1" ? "bg-white" : ""
            }`}
          >
            <div className="text-center">
              <p className="name mt-2">No Session Available</p>
            </div>
          </div>
        )}
      </div>
      {modalOpenClose && (
        <DeleteSession
          successSub={() => successSub()}
          closeModal={() => setModalOpenClose(false)}
          sessioninfo={deletesessiondetail}
          therapysessions={therapysessions}
        />
      )}
    </>
  );
}

export default SessionsDetail;
