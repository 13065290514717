// agora backup
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  useAgoraState,
  useAgoraMutation,
} from "../../components/agora/utils/container";
import moment from "moment";
import useStream from "../../components/agora/utils/use-stream";
import { emitEvent } from "../../hooks/eventHook";
// import RTCClient from "./rtc-client";
// import StreamPlayer from "./stream-player";
import classNames from "classnames";
import {
  useNavigate,
  // useParams
} from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toast } from "react-toastify";
import { adminService } from "../../services/adminService";
import { ReactSVG } from "react-svg";
import Counter from "../../components/Counter";
// import { emitEvent } from "../../hooks/eventHook";
import { useDispatch, useSelector } from "react-redux";
// import { genAgoraJoinedUser } from "./agoraHelpers";
// import agoraAction from "../../redux/actions/agoraAction";
import AppTooltip from "../../components/app-tooltip/AppTooltip";
import { EmojiSender, EmojiViewer } from "../../components/emoji/Emoji";
import MarketingMediaPlayer from "./MarketingMediaPlayer";
// import WhiteBoard from "./whiteboard";
import RemoteUserList from "../../components/agora/RemoteUserList";
import EmojiControl from "../AgoraExternalVideo/EmojiControl";

const AgoraFullScreen = ({ tooltip, ...props }) => {
  return (
    <AppTooltip tooltip={tooltip}>
      <button {...props} className="btn bg-transparent agora-full-screen">
        <img
          alt="full screen"
          src="../resources/assets/Responsive-scales.svg"
        />
      </button>
    </AppTooltip>
  );
};

const AgoraUi = (props) => {
  const vidBtnRef = useRef(null);
  const audBtnRef = useRef(null);
  const quitBtnRef = useRef(null);
  const appAgoraState = useSelector((state) => state.agoraDetail);
  const currentUserProfile = useSelector((state) => state.adminUser?.profile);
  const userInfo = useSelector((state) => state.adminUser?.user);
  const dispatch = useDispatch();
  const stateCtx = useAgoraState();
  const mutationCtx = useAgoraMutation();
  const [isFullScreen, setIsFullScreen] = useState(null);
  const [isLocalJoined, setIsLocalJoined] = useState(false);
  const [recordingInfo, setRecordingInfo] = useState({});
  const [isRecording, setIsRecording] = useState(false);
  const handleFullScreen = useFullScreenHandle();
  const [pinnedUser, setPinnedUser] = useState(null);
  const [videoStream, setVideoStream] = useState(null);
  const channelName = stateCtx.config.channel;
  const [recordings, setRecordings] = useState([]);
  const history = useNavigate();
  const [isWhiteBoard, setIsWhiteBoard] = useState(false);
  const [forScreen, setForScreen] = useState(false);
  const [accessToRemoteUsers, setAccessToRemoteUsers] = useState([]);
  const [userListModal, setUserListModal] = useState(false);
  const [resourcesId, setresourcesId] = useState(null);
  const [marketingUser,setMarketingUsers]=useState();
  const {
    permissionForAll,
    setPermissionForAll,
    sessionDetail,
    setSessionDetail,
    selectedMembers,
    setSelectedMembers,
    // isShowJoinBtn,
    // setIsLocalJoined,

    isShowJoinBtn,
    setIsStarted,
    join,
    appid,
    channel,
    token,
    userId,
    localVideoTrack,
    joinState,
    clientId,
    leave,
    newVideoUser,
    newAudioUser,
    isOwnScreen,
    setIsOwnScreen,
    isScreen,
    setIsScreen,
    remoteUsers,
    handleUser,
    localAudioTrack,
    screenClient,
    isPin,
    setIsPin,
    tokenDetail,
    onLeave,
  } = props;

  const [profileId, setProfileId] = useState(props.profile.id);

  const valuex = localStorage.getItem("user");

  const isUserLoggedIn =
    typeof valuex === "string"
      ? JSON.parse(valuex).accessToken
      : valuex?.accessToken || null;
  useEffect(() => {
    const list = [];
    appAgoraState?.currentSession?.marketingRegistrations &&
      appAgoraState?.currentSession.marketingRegistrations.map((fm, i) => {
        const haveUser = remoteUsers.filter((f) => f.uid == fm.userUniqueId);
        if (haveUser.length > 0) {
          list.push(fm);
          props?.setJoinedUsers(list);
        }
      });
      adminService.getAll(`marketing-registrations/user/${userId}/${props.sessionId}`).then(
        (res) => {
          if (res?.status === "Success") {
            console.log("marketing user list",res.data);
            setMarketingUsers(res.data);
            
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }, [remoteUsers]);

  useEffect(() => {
    const screenStatus =
      appAgoraState?.screens && appAgoraState?.screens > 0
        ? appAgoraState?.screens[appAgoraState?.screens.length - 1]
        : false;

    if (remoteUsers.length > 0) {
      setIsScreen(screenStatus);
      screenStatus !== false &&
        recordingInfo.sid &&
        isUserLoggedIn &&
        updateRecord(screenStatus);
    }
  }, [appAgoraState?.screens]);

  useEffect(() => {
    if (isUserLoggedIn) checkAnyRecording();
    return () => {
      if (isRecording && isUserLoggedIn) {
        stopRecord();
      }
    };
  }, []);
  useEffect(() => {
    if (props?.countDownTimer == "00:00:00") {
      document.getElementById("quitBtnRef").click();
      // leaveCall();
    }
  }, [props?.countDownTimer]);
  useEffect(() => {
    if (isFullScreen === true) {
      handleFullScreen.enter();
    } else if (isFullScreen === false) {
      if (document) {
        handleFullScreen.exit();
      }
    }
  }, [isFullScreen]);
  useEffect(() => {
    emitEvent("agoraRecording", {
      uid: clientId,
      isEnabled: isRecording,
    });
    return () => {
      emitEvent("agoraRecording", { uid: clientId, isEnabled: false });
    };
  }, [isRecording]);
  useEffect(() => {
    setProfileId(props.profile.id);
  }, [props]);
  console.log("screenClient", screenClient);
  const recordingId = props?.recId;
  const tips = {
    join: "Click to join video call",
    recordingBtn:
      remoteUsers.length == 0
        ? "Recording can start with two or more users"
        : isRecording
          ? "Stop recording"
          : "Start Recording",
    recording: "This call is recording",
    hangup: "End call",
    audio: "Turn off mic",
    screen: null,
    fullScreen: !isFullScreen ? "Fullscreen" : "Exit Fullscreen",
  };

  const currentProfile =
    appAgoraState?.currentSession?.marketingRegistrations &&
    appAgoraState?.currentSession?.marketingRegistrations?.filter(
      (f) => f.userUniqueId == props?.profile.id
    )[0];

  
  const handlePermission = (name, isAll = true, id = null, val = null) => {
    const usrs = props?.joinedUsers;
    if (id) {
      const list = usrs.filter((f) => `ROLE_FAMILY-${f.id}` == id);
      console.log("usrs....", list);
      list[0][name] = val;
    } else {
      usrs.map((u) => (u[name] = false));
    }
    const enabledEmojiUserList = [];
    const disabledEmojiUserList = [];
    usrs.map((u) =>
      u.emoji
        ? enabledEmojiUserList.push(`ROLE_FAMILY-${u.id}`)
        : disabledEmojiUserList.push(`ROLE_FAMILY-${u.id}`)
    );
    setPermissionForAll(isAll);
    switch (name) {
      case "screen": {
        emitEvent("agoraControl", {
          screen: val,
          selectedUserId: [id],
        });

        break;
      }
      case "emoji": {
        let lst = [...selectedMembers];
        if (lst.includes(id)) {
          lst = lst.filter((f) => f != id);
        } else {
          lst.push(id);
        }
        setSelectedMembers(lst);
        emitEvent("agoraControl", {
          emoji: val,
          selectedUserId: val ? enabledEmojiUserList : disabledEmojiUserList,
        });
        break;
      }
      default:
        throw new Error(`Unknown click handler, name: ${name}`);
    }
  };



  const handleOnPing = (id) => {
    setIsScreen(false);
    setIsOwnScreen(false);
    setTimeout(() => {
      setIsPin(id == isPin ? false : id);
    }, 500);
  };

  const getRecDuration = (time) => {
    const today = moment();
    const someday = moment(time);
    const diff = today.diff(someday, "second");
    const dur = moment.duration(diff, "seconds");
    return dur;
  };
  const checkAnyRecording = () => {
    if (props.sessionId) {
      adminService
        .getAll("session-recordings/single/" + props.sessionId)
        .then((res) => {
          setRecordings(res?.data);
        });
    }
  };

  const recordAccure = () => {
    // create recording entry on DB

    if (isUserLoggedIn) {
      adminService
        .POST(
          "marketing/session-recordings/acquire-resource",
          JSON.stringify({
            cname: tokenDetail.channelName,
            uid: recordingId, //config.uid // use unique id from users
            // uid: `${appAgoraState?.currentSession?.therapist.id}`,
          })
        )
        .then((res) => {
          const resourceId = res.data?.resourceId;
          if (resourceId) {
            // start recording
            setresourcesId(resourceId);

            startRecord("start-recording", resourceId);
          }
        })
        .catch((err) => {
          toast.error(err.info || err.msg || err);
        });
    }
  };

  const startRecord = (startConfig, resourceId) => {
    console.log("//// startRe", appAgoraState?.currentSession?.marketingRegistrations,remoteUsers);
    const backgroundConfigList = [];
    
    backgroundConfigList.push({
      uid: marketingUser?.id,
      image_url: marketingUser?.profilePicUrl,
      render_mode: 1,
    });
    appAgoraState?.currentSession?.marketingRegistrations.map((fm) => {
      const haveUser = remoteUsers.filter((f) => f.uid == fm.userProfileId);
      haveUser.length == 1 &&
        backgroundConfigList.push({
          uid: fm.userProfileId,
          image_url: fm.profileUrl,
          render_mode: 1,
        });
    });
    console.log("recording list data",backgroundConfigList);
    // appAgoraState?.currentSession?.tenantAdmins.map((fm) => {
    //   const haveUser = remoteUsers.filter((f) => f.uid == fm.userUniqueId);
    //   haveUser.length == 1 &&
    //     backgroundConfigList.push({
    //       uid: fm.userUniqueId,
    //       image_url: fm.profileUrl,
    //       render_mode: 1,
    //     });
    // });

    // appAgoraState?.currentSession?.marketingRegistrations.map((fm) => {
    //   const haveUser = remoteUsers.filter((f) => f.uid == fm.userUniqueId);
    //   haveUser.length == 1 &&
    //     backgroundConfigList.push({
    //       uid: fm.userUniqueId,
    //       image_url: fm.profileUrl,
    //       render_mode: 1,
    //     });
    // });

    // appAgoraState?.currentSession?.therapists.map((fm) => {
    //   const haveUser = remoteUsers.filter((f) => f.uid == fm.userUniqueId);
    //   haveUser.length == 1 &&
    //     backgroundConfigList.push({
    //       uid: fm.userUniqueId,
    //       image_url: fm.profileUrl,
    //       render_mode: 1,
    //     });
    // });
    // appAgoraState?.currentSession?.families.map((fm) => {
    //   const haveUser = remoteUsers.filter((f) => f.uid == fm.userUniqueId);
    //   haveUser.length == 1 &&
    //     backgroundConfigList.push({
    //       uid: fm.userUniqueId,
    //       image_url: fm.profileUrl,
    //       render_mode: 1,
    //     });
    // });

    // const thrpst = appAgoraState?.currentSession?.therapist;
    // thrpst &&
    //   backgroundConfigList.push({
    //     uid: thrpst?.userProfile?.id,
    //     image_url: thrpst?.userProfile?.profilePicUrl,
    //     render_mode: 1,
    //   });
    adminService
      .POST(
        `marketing/session-recordings/session/${props.sessionId}/start-recording`,
        JSON.stringify({
          clientRequest: {
            token: props.recToken, //config.token
            recordingConfig: {
              transcodingConfig: {
                mixedVideoLayout: 2,
                maxResolutionUid: `${isScreen || isOwnScreen || profileId}`,
                // regionCount: otherStreams.length + 1,
                backgroundColor: "#000000",
                defaultUserBackgroundImage:
                  "https://prodpublicmeis.s3.us-east-2.amazonaws.com/defaultProfile.jpeg",
                backgroundConfig: backgroundConfigList,
              },
            },
          },
          cname: tokenDetail.channelName,
          resourceId,
          uid: recordingId, //config.uid
          // uid: `${appAgoraState?.currentSession?.therapist.id}`,
        })
      )
      .then((res) => {
        console.log("start-record", res);

        if (res.data?.sid) {
          setIsRecording(true);
          setRecordingInfo(res.data);
        }
      })
      .catch((err) => {
        toast.error(err.info || err.msg || err);
      });
  };
  let flag = false;
  const updateRecord = (sharedScreenId) => {
    flag = !flag;

    console.log("sharedScreenId", sharedScreenId);
    const backgroundConfigList = [];
    appAgoraState?.currentSession?.marketingRegistrations.map((fm) => {
      const haveUser = remoteUsers.filter((f) => f.uid == fm.userProfileId);
      haveUser.length == 1 &&
        backgroundConfigList.push({
          uid: fm.userProfileId,
          image_url: fm.profileUrl,
          render_mode: 1,
        });
    });
    // const thrpst = appAgoraState?.currentSession?.therapist;
    // thrpst &&
    //   backgroundConfigList.push({
    //     uid: thrpst?.userProfile?.id,
    //     image_url: thrpst?.userProfile?.profilePicUrl,
    //     render_mode: 1,
    //   });
    backgroundConfigList.push({
      uid: marketingUser?.id,
      image_url: marketingUser?.profilePicUrl,
      render_mode: 1,
    });
    adminService
      .POST(
        `marketing/session-recordings/update`,
        JSON.stringify({
          clientRequest: {
            token: props.recToken, //config.token
            mixedVideoLayout: 2,
            maxResolutionUid: `${sharedScreenId}`,

            backgroundColor: "#FFFFFF",
            defaultUserBackgroundImage:
              "https://prodpublicmeis.s3.us-east-2.amazonaws.com/defaultProfile.jpeg",
            backgroundConfig: backgroundConfigList,
          },

          //   transcodingConfig: {
          //     // regionCount: otherStreams.length + 1,
          //     backgroundColor: "#FF0000",
          //     defaultUserBackgroundImage:
          //       "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/481px-Cat03.jpg",
          //     backgroundConfig: backgroundConfigList,
          //   },
          // },
          cname: tokenDetail.channelName,
          resourceId: recordingInfo.resourceId,
          uid: recordingId, //config.uid
          sid: recordingInfo.sid,
          // uid: `${appAgoraState?.currentSession?.therapist.id}`,
        })
      )
      .then((res) => {
        // toast.success("Update layout");
        // if (res.data?.sid) {
        //   setIsRecording(true);
        //   setRecordingInfo(res.data);
        // }
      })
      .catch((err) => {
        toast.error(err.info || err.msg || err);
      });
  };

  const stopRecord = () => {
    return new Promise((res, rej) => {
      setIsRecording(false);
      adminService
        .POST(
          `marketing/session-recordings/${recordingInfo.sessionRecordingId}/stop`,
          JSON.stringify({
            cname: tokenDetail.channelName,
            resourceId: recordingInfo.resourceId,
            sid: recordingInfo.sid,
            uid: recordingInfo.uid || recordingId, //`${config.uid}`
            // uid: `${appAgoraState?.currentSession?.therapist.id}`,
          })
        )
        .then((response) => {
          res(response);
        })
        .catch((err) => {
          console.log(err);
          rej();
        });
    });
  };
  console.log("clientid", clientId);
  

  // on stop session
  const handleLeave = () => {
    const role = userInfo?.userDetails?.roles[0];
    console.log("user role",userInfo);
    //role != "ROLE_FAMILY" && props?.postCaseNote();
    // if (role == "ROLE_FAMILY" || role == "ROLE_INTERPRETER") {
    //   window.location.href = "/marketing-sessions";
    // } else if (role == "ROLE_RCE") {
      if (isRecording) {
        stopRecord()
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            console.log("finally");
            onLeave(() => {
              leave();
            });
            //window.location.href = "/marketing-sessions";
          });
      } else {
        onLeave(() => {
          leave();
        });
      }
    // } else{
    //   if (isRecording) {
    //     stopRecord()
    //       .then((res) => {
    //         console.log(res);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //       .finally(() => {
    //         console.log("finally");
    //         onLeave(() => {
    //           leave();
    //         });
    //         history.goBack(-1);
    //       });
    //   } else {
    //     onLeave(() => {
    //       leave();
    //     });
    //   }
    // }
  };

  return (
    <div className="meeting">
      <div className="current-view">
        <div className="flex-container">
          <div className="d-none"></div>
          <FullScreen
            onChange={(state) => {
              setIsFullScreen(state);
            }}
            handle={handleFullScreen}
          >
            <div className="avc">
              {!isLocalJoined && (
                <div className="position-relative">
                  <div className="col-md-12 avc__join-btn-wrapper">
                    <div className="p-5 text-center">
                      {sessionDetail?.status == "COMPLETED" &&
                        "Appointment has been completed"}
                      {token && sessionDetail?.status != "COMPLETED" && (
                        <AppTooltip tooltip={tips.join}>
                          <button
                            onClick={() => {
                              join(
                                appid,
                                channel,
                                token,
                                userId,
                                setIsLocalJoined
                              );
                              setIsStarted(true);
                            }}
                            className="btn btn-lg btn-theme-green"
                          >
                            Join Now
                          </button>
                        </AppTooltip>
                      )}
                    </div>
                  </div>
                  <div className="avc__video-container id---1">
                    <MarketingMediaPlayer placeholder={true} />
                    <MarketingMediaPlayer placeholder={true} />
                  </div>
                </div>
              )}
              {isLocalJoined && (
                <AgoraFullScreen
                  tooltip={tips.fullScreen}
                  onClick={() => {
                    setIsFullScreen(!isFullScreen);
                  }}
                />
              )}
              {isLocalJoined && (
                <div
                  className={classNames("avc__video-container id---2", {
                    "avc__video-container--has-pinned": isPin
                      ? true
                      : isScreen !== false
                        ? true
                        : isOwnScreen != false
                          ? true
                          : false,
                  })}
                >
                  {isLocalJoined && <EmojiViewer />}

                  {isLocalJoined && (
                    <MarketingMediaPlayer
                      videoTrack={
                        newVideoUser.includes(clientId)
                          ? undefined
                          : localVideoTrack
                      }
                      audioTrack={
                        newAudioUser.includes(clientId)
                          ? undefined
                          : localAudioTrack
                      }
                      clientUid={clientId}
                      handleUser={handleUser}
                      userType="local"
                      setIsScreen={setIsScreen}
                      isScreen={isScreen}
                      isOwnScreen={isOwnScreen}
                      placeholder={false}
                      remoteUsersCount={remoteUsers.length}
                      screenClient={screenClient}
                      onPin={handleOnPing}
                      isPin={isPin}
                      sessionId={props.sessionId}
                    ></MarketingMediaPlayer>
                  )}

                  {remoteUsers.map((user) => {
                    return (
                      <>
                        <MarketingMediaPlayer
                          videoTrack={
                            newVideoUser.includes(user.uid)
                              ? undefined
                              : user.videoTrack
                          }
                          audioTrack={
                            newVideoUser.includes(user.uid)
                              ? undefined
                              : user.audioTrack
                          }
                          clientUid={user.uid}
                          handleUser={handleUser}
                          userType="remote"
                          isScreen={isScreen}
                          setIsScreen={setIsScreen}
                          isOwnScreen={isOwnScreen}
                          remoteUsersCount={remoteUsers.length}
                          screenClient={user}
                          onPin={handleOnPing}
                          isPin={isPin}
                          sessionId={props.sessionId}
                        ></MarketingMediaPlayer>
                      </>
                    );
                  })}
                </div>
              )}
              {isLocalJoined && (
                <div className="avc__info align-items-center">
                  <div className="avc__stop-wrapper">
                    {marketingUser?.sessionOwner ? (
                      <AppTooltip
                        wrapper
                        tooltip={
                          remoteUsers.length == 0
                            ? "Recording can start with two or more users"
                            : isRecording
                              ? "Stop recording"
                              : "Start Recording"
                        }
                      >
                        <button
                          onClick={
                            isRecording ? stopRecord : () => recordAccure()
                          }
                          disabled={
                            !(
                              isRecording ||
                              (remoteUsers && remoteUsers.length > 0)
                            )
                          }
                          className="btn btn-danger w-125"
                        >
                          {isRecording ? "Stop Recording" : "Start Recording"}
                        </button>
                      </AppTooltip>
                    ) : (
                      appAgoraState.isRecording && (
                        <AppTooltip tooltip={tips.recording}>
                          <div className="avc__info-rec-other">Rec</div>
                        </AppTooltip>
                      )
                    )}
                    {isRecording && (
                      <span className="pl-2">
                        <Counter
                          time={getRecDuration(recordingInfo?.startTime)}
                          forward={true}
                        />
                      </span>
                    )}
                  </div>
                  <div className="avc__track-wrapper">
                    <div className="p-2">
                      <AppTooltip wrapper tooltip={tips.camera}>
                        <button
                          onClick={() => handleUser(clientId, "video")}
                          className={classNames(
                            {
                              muted: !(newVideoUser.includes(clientId)
                                ? undefined
                                : localVideoTrack),
                            },
                            "btn audio-video-background"
                          )}
                        >
                          <img
                            src="../resources/assets/Icon awesome-video.svg"
                            alt=""
                          />
                        </button>
                      </AppTooltip>
                    </div>
                    <div className="p-2">
                      <AppTooltip wrapper tooltip={tips.hangup}>
                        <button
                          id="quitBtnRef"
                          onClick={handleLeave}
                          title="End call"
                          className="btn audio-video-background bg-danger"
                        >
                          <img src="../resources/assets/end-call.svg" alt="" />
                        </button>
                      </AppTooltip>
                    </div>
                    <div className="p-2">
                      <AppTooltip wrapper tooltip={tips.audio}>
                        <button
                          id="aud-btn"
                          onClick={() => handleUser(clientId, "audio")}
                          className={classNames(
                            {
                              muted: !(newAudioUser.includes(clientId)
                                ? undefined
                                : localAudioTrack),
                            },
                            "btn audio-video-background"
                          )}
                        >
                          <img
                            src="../resources/assets/Icon awesome-microphone.svg"
                            alt=""
                          />
                        </button>
                      </AppTooltip>
                    </div>
                    <div className="p-2">
                      <AppTooltip
                        wrapper
                        tooltip={
                          isOwnScreen && isScreen
                            ? "Stop screen share"
                            : !isOwnScreen && isScreen
                              ? "Another user is sharing screen"
                              : props.profile?.userType !== "ROLE_RCE" &&
                                appAgoraState?.controls?.userList
                                ? appAgoraState?.controls?.userList.filter(
                                  (u) => u.id == currentProfile.id
                                )[0]?.screen == true && "Start Screen share"
                                // : props.profile?.userType !== "ROLE_RCE" &&
                                //   !appAgoraState?.controls?.userList
                                //   ? "You don't have permission to share screen"
                                  : !isOwnScreen && !isScreen && "Start screen share"
                        }
                      >
                        <button
                          disabled={
                            !isOwnScreen && isScreen
                              ? true : false
                          }
                          onClick={() => {
                            handleUser(clientId, "screen");
                          }}
                          className={classNames(
                            {
                              active: isPin ? false : isOwnScreen && isScreen,
                            },
                            "btn audio-video-background audio-video-background--screen"
                          )}
                        >
                          <ReactSVG
                            fill="currentColor"
                            src="../resources/assets/share.svg"
                          />
                        </button>
                      </AppTooltip>
                    </div>
                    {/* <RemoteUserList
                      modalState={userListModal}
                      setModalState={setUserListModal}
                      handlePermission={handlePermission}
                      joinedUsers={props?.joinedUsers}
                    /> */}

                    {/* {props.profile?.userType === "ROLE_RCE" && (
                      <div className="p-2">
                        <div className="dropdown">
                          <button
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className={classNames(
                              "btn audio-video-background dropdown-toggle custom-dropdown-toggle"
                            )}
                          >
                            <img
                              src="../resources/assets/Icon ionic-md-more.svg"
                              alt=""
                            />
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              className="dropdown-item"
                              href="javascript:void(0)"
                              onClick={() => setUserListModal(true)}
                            >
                              Users
                            </a>
                            <a
                              className={`dropdown-item ${props?.joinedUsers &&
                                  props?.joinedUsers.filter(
                                    (u) => u.screen == true
                                  ).length > 0
                                  ? ""
                                  : "disable-a"
                                }`}
                              href="javascript:void(0)"
                              onClick={() =>
                                props?.joinedUsers &&
                                  props?.joinedUsers.filter(
                                    (u) => u.screen == true
                                  ).length > 0
                                  ? handlePermission("screen")
                                  : console.log("good")
                              }
                            >
                              Disable screen sharing for all
                            </a>
                            <a
                              className={`dropdown-item ${props?.joinedUsers &&
                                  props?.joinedUsers.filter(
                                    (u) => u.emoji == true
                                  ).length > 0
                                  ? ""
                                  : "disable-a"
                                }`}
                              href="javascript:void(0)"
                              onClick={() =>
                                props?.joinedUsers &&
                                  props?.joinedUsers.filter(
                                    (u) => u.emoji == true
                                  ).length > 0
                                  ? handlePermission("emoji")
                                  : console.log("good")
                              }
                            >
                              Disable emoji for all
                            </a>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {props.profile?.userType === "ROLE_RCE" && marketingUser?.sessionOwner && (
                      <div className="p-2">
                        <button
                          onClick={() =>
                            window.open(
                              `/marketing/whiteboard/${props.sessionId}`,
                              "_blank"
                            )
                          }
                          className={"btn audio-video-background"}
                        >
                          <img
                            src="../resources/assets/file-earmark-text.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    )}
                  </div>
                  {props.profile?.userType == "ROLE_RCE" ? (
                    <></>
                    // <EmojiControl
                    //   clientId={clientId}
                    //   profile={props?.profile}
                    //   currentUserProfile={currentUserProfile}
                    // />
                  ) : (
                    props.profile?.userType == "ROLE_FAMILY" && (
                      <>
                        {appAgoraState?.controls?.userList &&
                          appAgoraState?.controls?.userList.length > 0 &&
                          appAgoraState?.controls?.userList.filter(
                            (u) => u.id == currentProfile?.id
                          )[0]?.emoji && (
                            <EmojiControl
                              clientId={clientId}
                              profile={props?.profile}
                              currentUserProfile={currentUserProfile}
                            />
                          )}
                      </>
                    )
                  )}
                </div>
              )}
            </div>
          </FullScreen>
        </div>
      </div>
    </div>
  );
};

export default AgoraUi;
