import React from "react";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";

function RemoteUserList(props) {
  const {
    modalState,
    setModalState,
    allUsers,
    handlePermission,
    sessionDetail,
    setSessionDetail,
    users,
  } = props;

  return (
    <>
      <Popup modal={modalState} open={modalState} closeOnDocumentClick={false}>
        {(close) => (
          <>
            <div
              className="col-md-12 popup-header"
              style={{
                border: "none",
                background: "#eaf1fe",
                marginTop: 0,
                paddingTop: 6,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <tr>
                <th style={{ padding: 5, paddingRight: 10, width: 200 }}>
                  Users&nbsp;
                </th>
                <th style={{ width: 80 }}>Screen&nbsp;</th>
                <th style={{ width: 80 }}>Emoji&nbsp;</th>
              </tr>
            </div>
            <div className="col-md-12">
              {props?.joinedUsers &&
                props?.joinedUsers.length > 0 &&
                props?.joinedUsers.map((fm, i) => {
                  return (
                    <>
                      <tr>
                        <td
                          style={{ padding: 5, paddingRight: 10, width: 200 }}
                        >
                          {[fm.firstName, fm.lastName].join(" ")}
                        </td>
                        <td style={{ width: 80 }}>
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id={`customSwitches${i}`}
                              onChange={(e) =>
                                // console.log("handlePermission")
                                handlePermission(
                                  "screen",
                                  false,
                                  `ROLE_FAMILY-${fm.id}`,
                                  e.target.checked
                                )
                              }
                              checked={fm?.screen}
                            />
                            <label
                              class="custom-control-label"
                              for={`customSwitches${i}`}
                            ></label>
                          </div>
                        </td>
                        <td style={{ width: 80 }}>
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id={`customSwitches-${i}`}
                              onChange={(e) =>
                                // console.log("handlePermission")
                                handlePermission(
                                  "emoji",
                                  false,
                                  `ROLE_FAMILY-${fm.id}`,
                                  e.target.checked
                                )
                              }
                              checked={fm?.emoji}
                            />
                            <label
                              class="custom-control-label"
                              for={`customSwitches-${i}`}
                            ></label>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}

              {/* {allUsers?.familyMemberDtoSet &&
                allUsers.familyMemberDtoSet.map((fm, i) => {
                  const haveUser = users.filter(
                    (f) => f.agoraId == fm.familyUserId
                  );
                  return (
                    <>
                      {haveUser.length == 1 && (
                        <tr>
                          <td
                            style={{ padding: 5, paddingRight: 10, width: 200 }}
                          >
                            {[fm.firstName, fm.lastName].join(" ")}
                          </td>
                          <td>
                            <div class="custom-control custom-switch">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id={`customSwitches${i}`}
                                onChange={(e) =>
                                  // console.log("handlePermission")
                                  handlePermission(
                                    "screen",
                                    false,
                                    `ROLE_FAMILY-${fm.id}`,
                                    e.target.checked
                                  )
                                }
                                checked={fm?.screen}
                              />
                              <label
                                class="custom-control-label"
                                for={`customSwitches${i}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <div class="custom-control custom-switch">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id={`customSwitches-${i}`}
                                onChange={(e) =>
                                  // console.log("handlePermission")
                                  handlePermission(
                                    "emoji",
                                    false,
                                    `ROLE_FAMILY-${fm.id}`,
                                    e.target.checked
                                  )
                                }
                                checked={fm?.emoji}
                              />
                              <label
                                class="custom-control-label"
                                for={`customSwitches-${i}`}
                              ></label>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })} */}
            </div>
            {props?.joinedUsers && props?.joinedUsers.length == 0 && (
              <div className="text-center mt-10">No User Found</div>
            )}
            <div className="col-md-12"></div>
            <div className="col-md-12 popup-btn-container">
              <button
                className="btn btn-secondary"
                onClick={() => setModalState(false)}
              >
                Close
              </button>
            </div>
          </>
        )}
      </Popup>
    </>
  );
}

export default RemoteUserList;
