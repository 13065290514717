import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { adminService } from '../../services/adminService';
import moment from "moment-timezone";
//import CaseNoteDownload from '../case-note-detail/CaseNoteDownload';
import { useSelector, useDispatch } from "react-redux";
import appAction from '../../redux/actions/appAction';
import InvoicePrint from './InvoicePrint';
import DownLoadInvoiceBtn from './DownLoadInvoiceBtn';
import { toast } from "react-toastify";
import _ from "lodash";

function BillingDetail(props) {
    const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
    let history = useNavigate();
    const formRef = useRef(null);
    let id = useParams();
    console.log("user id", id.id);
    const dispatch = useDispatch();
    const [accountId, setAccountId] = useState('');
    const [invoiceDetail, setInVoiceDetail] = useState([]);
    const [npiName, setNpiName] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        const invoiceDetail = adminService
            .getAll(`payments/invoice/family/${id.id}`)
            .then((res) => {
                console.log("invoiceDetail", res.data);
                setInVoiceDetail(res.data);
                let str = res?.data?.therapistDto?.therapistServices;
                let i = res?.data?.diagnosisDTO?.therapyDto?.id;
                //let s = str.slice(0, i);
                //setNpiName(s);
                let filtered_array = _.filter(
                    str, function (o) {
                        return o?.therapy?.id === i;
                    }
                );
                console.log("filtered_array", filtered_array?.[0]?.clinicianNpiNumber, i, str);
                setNpiName(filtered_array?.[0]?.clinicianNpiNumber);
                //setBillingList(res?.data?.content);
                //setCustomerId(res?.data?.stripeCustomerId);
            })
    }, [accountId])
    useEffect(() => {
        getAccountId();
    })
    const getAccountId = async () => {
        await adminService.getAll(`misc/billing-company-detail?tenantId=${tenantId}`).then(
            (res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("connect account", res.data.stripeAccountId);
                    setAccountId(res?.data?.stripeAccountId);
                } else {
                    //toast.error(res.message);
                }
                //setloadings(false);
            },
            (error) => {
                console.log(error);
            }
        );
    }
    const onPdfCreate = (cb) => {
        setIsDownloading(true);
        dispatch(appAction.setAppLoading(true));
        setTimeout(() => {
            if (typeof cb === "function") {
                cb();
            }
        }, 200);
    };

    const onPdfComplete = () => {
        setIsDownloading(false);
        dispatch(appAction.setAppLoading(false));
    };
    const finalPaymentCalls = async (id, i) => {
        setLoader(true);
        console.log("stripeInvoiceId", id);
        const results = await fetch("https://api.stripe.com/v1/invoices/" + id + "/pay", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
                'Stripe-Account': accountId,
            },

        }).then(response => {
            setLoader(false);
            return response.json();
        })
        if (results?.error) {
            toast.error(results?.error?.message);
            setLoader(false);
        } else {
            toast.success("Payment successfully completed");
        }
        console.log("payNow", results)
        if (!results?.error) {
            const updateStatus = await adminService
                .PUT(`payments/update/${i}`, JSON.stringify("PAID"))
                .then((res) => {
                    console.log("invoiceDetail", res.data);
                    history("/billing-info")
                })
        } else {
            const updateStatus = await adminService
                .PUT(`payments/update/${i}`, JSON.stringify("PAYMENT_FAILED"))
                .then((res) => {
                    console.log("invoiceDetail", res.data);
                    history("/billing-info")
                })
        }
    }
    //console.log(s)
    return (
        <div className="col-md-10 no-padding">
            <div className="no-padding dash-billing-info">
                <div className="custom-table-view min-h-100">
                    {loader && (
                        <div className="loader-overlay">
                            <div className="loader" />
                        </div>
                    )}
                    <div className='row'>
                        <div className='col-md-9'></div>
                        <div className='col-md-3 text-right'>
                            <DownLoadInvoiceBtn
                                downloadName={invoiceDetail?.superBill ? `SB _${invoiceDetail?.invoiceNo}.pdf` : `INV _${invoiceDetail?.invoiceNo}.pdf`}
                                targetRef={formRef}
                                beforeStart={onPdfCreate}
                                onComplete={onPdfComplete}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className='p-4'>
                        <div class="row mb-10">
                            <div class="col-md-6">
                                <p className='addressInvoice'><span>From, </span><span style={{ marginLeft: "10px" }}>{invoiceDetail?.billingCompany?.name}<br />{invoiceDetail?.billingCompany?.address1}</span></p>
                            </div>
                            <div className='col-md-6 text-right'>
                                <img className='img-fluid invoiceLogo' src={invoiceDetail?.billingCompany?.logoUrl} />
                            </div>
                        </div>
                        <hr />
                        <h4 className='text-center'>{invoiceDetail?.superBill ? "Statement for Insurance Reimbursement" : "Invoice"}</h4>
                        <div className='row'>
                            <div className='col-md-9'>
                                <p className='addressInvoice'><span>To, </span><span style={{ marginLeft: "10px" }}>{invoiceDetail?.familyMemberDto?.firstName + " " + invoiceDetail?.familyMemberDto?.lastName}</span></p>
                            </div>
                            <div className='col-md-3'>
                                <div className='text-left'>
                                    <p className='mb-0'><span>Invoice </span>|<span> {invoiceDetail?.invoiceNo}</span></p>
                                    <p><span>Issue Date </span>|<span> {invoiceDetail?.invoiceDate ? moment(invoiceDetail?.invoiceDate).format("MM/DD/YYYY") : moment().format('MM/DD/YYYY')}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-9'>
                                <p className='mb-0'>Client,</p>
                                <p className='mb-0'>{invoiceDetail?.familyMemberDto?.firstName + " " + invoiceDetail?.familyMemberDto?.lastName}</p>
                                <p className='mb-0'>{invoiceDetail?.familyMemberDto?.phone}</p>
                                <p className='mb-0'>{invoiceDetail?.familyMemberDto?.email}</p>
                                {invoiceDetail?.superBill ? <p className='mb-0'>DOB : {invoiceDetail?.familyMemberDto?.dob ? moment(invoiceDetail?.familyMemberDto?.dob).format("MM/DD/YYYY") : ''}</p> : ''}
                            </div>
                            <div className='col-md-3'>
                                <div className='text-left'>
                                    <p className='mb-0'>Provider,</p>
                                    <p className='mb-0'>{invoiceDetail?.provider?.firstName + " " + invoiceDetail?.provider?.lastName}</p>
                                    {invoiceDetail?.superBill ? '' : <p className='mb-0'>Tax ID : {invoiceDetail?.therapistDto?.taxonomy}</p>}
                                    {invoiceDetail?.superBill ? <></> : <p className='mb-0'>NPI : {invoiceDetail?.billingCompany?.groupNpiNumber}</p>}
                                    <p className='mb-0'>{invoiceDetail?.provider?.phone}</p>
                                    <p className='mb-0'>{invoiceDetail?.provider?.email}</p>
                                    {invoiceDetail?.superBill ? <p className='mb-0'>NPI : {invoiceDetail?.billingCompany?.groupNpiNumber}</p> : <></>}
                                    {invoiceDetail?.superBill ? <p>Licenses : {invoiceDetail?.therapistDto?.licenseNumberState}</p> : ''}
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-9'></div>
                            <div className='col-md-3'>
                                <div className='text-left'>
                                    {invoiceDetail?.superBill ? <p className='mb-0'><span>Practice </span>|<span> Tax ID : {invoiceDetail?.therapistDto?.taxonomy}<br />NPI : {npiName}</span></p>
                                        : ''}
                                </div>
                            </div>
                        </div>
                        {invoiceDetail?.superBill ?
                            <>
                                <div className='table-responsive mt-3'>
                                    <table className='table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td><b>DX</b></td>
                                                <td><b>Diagnosis Code</b></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>({invoiceDetail?.diagnosisDTO?.code}){invoiceDetail?.diagnosisDTO?.description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='table-responsive mt-3'>
                                    <table className='table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td><b>Date</b></td>
                                                <td><b>Service</b></td>
                                                <td><b>DX</b></td>
                                                <td><b>Description</b></td>
                                                <td className='text-right'><b>Units</b></td>
                                                <td className='text-right'><b>Fee</b></td>
                                                <td className='text-right'><b>Paid</b></td>
                                            </tr>
                                            {invoiceDetail?.invoiceDetails?.length ? invoiceDetail?.invoiceDetails.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.invoiceDate ? moment(item?.invoiceDate).format("MM/DD/YYYY") : moment().format('MM/DD/YYYY')}</td>
                                                    <td>{item?.sessionBillingId ? invoiceDetail?.diagnosisDTO?.therapyDto?.code : ''}</td>
                                                    <td>{item?.sessionBillingId ? '1' : ''}</td>
                                                    <td>{item?.description}</td>
                                                    <td className='text-right'>{item?.sessionBillingId ? invoiceDetail?.caseNoteUnits : ''}</td>
                                                    <td className='text-right'><b>$ {item?.amount}</b></td>
                                                    <td className='text-right'><b>$ {item?.amount}</b></td>
                                                </tr>
                                            ))
                                                :
                                                <tr><td colSpan={7}>No Data Available</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            :
                            <div className='table-responsive mt-3'>
                                <table className='table table-bordered'>
                                    <tbody>
                                        <tr>
                                            <td><b>Date</b></td>
                                            <td><b>Description</b></td>
                                            <td className='text-right'><b>Amount</b></td>
                                        </tr>
                                        {invoiceDetail?.invoiceDetails?.length ? invoiceDetail?.invoiceDetails.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item?.invoiceDate ? moment(item?.invoiceDate).format("MM/DD/YYYY") : moment().format('MM/DD/YYYY')}</td>
                                                <td>{item?.description}</td>
                                                <td className='text-right'><b>$ {item?.amount}</b></td>
                                            </tr>
                                        ))
                                            :
                                            <tr><td colSpan={3}>No Data Available</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className='row mt-3 align-items-end'>
                            {invoiceDetail?.superBill ?
                                <>
                                    <div className='col-md-2'>
                                        <p>{moment(invoiceDetail?.provider?.signatureDateTime).format("MM/DD/YYYY")}</p>
                                        <p><b>Date</b></p>
                                    </div>
                                    <div className='col-md-2'>
                                        <p><img className='img-fluid' src={invoiceDetail?.provider?.signatureUrl} /></p>
                                        <p><b>Signature</b></p>
                                    </div>
                                    <div className='col-md-5'></div></>
                                :
                                <div className='col-md-9'></div>
                            }
                            <div className='col-md-3'>
                                {invoiceDetail?.superBill ?
                                    <>
                                        <p style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span><b>Total Charges</b></span>
                                            <span className='text-right'>$ {invoiceDetail?.totalAmount}</span>
                                        </p>
                                        <p style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span><b>Total Paid</b></span>
                                            <span className='text-right'>$ {invoiceDetail?.totalAmount}</span>
                                        </p></>
                                    : <p style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span><b>Total</b></span>
                                        <span className='text-right'>$ {invoiceDetail?.totalAmount}</span>
                                    </p>
                                }
                                <p style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span>Make payments to : </span>
                                    <span className='text-right'>{invoiceDetail?.provider?.firstName + " " + invoiceDetail?.provider?.lastName}</span>
                                </p>
                            </div>
                        </div>
                        <hr />
                        {invoiceDetail?.lateFees !== null ? <p><b>Note:</b> Please pay your invoice within {moment(invoiceDetail?.overDueDate).format(
                            "DD MMMM YYYY"
                        )}, otherwise ${invoiceDetail?.lateFees} late fee applies</p> : ''}
                        {/* {isDownloading ? <div className="min-h-100 bg-white" ><div className="loader" /></div> : ""} */}
                        <div className="d-none"><InvoicePrint ref={formRef} billingData={invoiceDetail} npiName={npiName} /></div>
                        <div className='row'>
                            <div className='col-md-6 d-inline-flex'>
                                <Link className='btn btn-theme-blue mr-2' to="/billing-info">Back</Link>
                                {invoiceDetail?.paymentStatus === 'PENDING' ? <button className='btn btn-theme-green' onClick={() => finalPaymentCalls(invoiceDetail?.stripeInvoiceId, invoiceDetail?.invoiceNo)}>Pay Now</button> : ''}
                                {invoiceDetail?.paymentStatus === 'PAYMENT_FAILED' || invoiceDetail?.paymentStatus === 'UNCOLLECTIBLE' ? <button className='btn btn-theme-green' onClick={() => finalPaymentCalls(invoiceDetail?.stripeInvoiceId, invoiceDetail?.invoiceNo)}>Pay Now</button> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillingDetail;