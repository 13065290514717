import { adminService } from "./adminService";

export const submitForm = (body) => {
  return new Promise((resolve, rej) => {
    adminService
      .POSTUploadImage("forms", body)
      .then((res) => {
        resolve(res);
      })
      .catch(rej);
  });
};

export const updateReplyForm = (body) => {
  return new Promise((resolve, rej) => {
    adminService
      .PUTUploadImage("forms", body)
      .then((res) => {
        resolve(res);
      })
      .catch(rej);
  });
};

export const updateForm = (id, body) => {
  return new Promise((resolve, rej) => {
    adminService
      .update("/forms/" + id, body)
      .then((res) => {
        resolve(res);
      })
      .catch(rej);
  });
};

export const getFormsByChild = (childId,archive,sortBy = "") => {
  return new Promise((resolve, rej) => {
    if(archive === "archive")
    {
      adminService
      .getAll(`archive_form/family-member/${childId}?${sortBy}`)
      .then((res) => {
        if (res?.data) {
          resolve(res.data);
        } else {
          rej(res);
        }
      })
      .catch(rej);
    }else{
    adminService
      .getAll(`forms/family-member/${childId}?${sortBy}`)
      .then((res) => {
        if (res?.data) {
          resolve(res.data);
        } else {
          rej(res);
        }
      })
      .catch(rej);
    }
  });
};
