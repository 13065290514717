import React from "react";

function SelectSessionType(props) {
  const { params, register, errors, handleChange, inputs, currentRole } = props;
  return (
    <>
      {!params.id &&
        (currentRole === "ROLE_RCE" ||
          currentRole === "ROLE_REGIONAL_EARLY_STEPS") && (
          <div className="col-md-12">
            <div className="form-group">
              <label>Session Type</label> (
              <span className="required-span">*</span>)
              <div className="row ml-2">
                <img
                  src="../resources/assets/session_type.svg"
                  className="img-fluid"
                  alt=""
                />
                <div className="col-md-10">
                  <div className="input-group" style={{ marginLeft: 9 }}>
                    <select
                      {...register('sessionType',{
                        onChange:(e)=>handleChange(e),
                        required:
                          inputs.sessionType && inputs.sessionType.length === 0
                            ? true
                            : false,
                      })}
                      className={`custom-select ${
                        errors.sessionType && "is-invalid"
                      }`}
                      //onChange={handleChange}
                      defaultValue={inputs.sessionType}
                      value={inputs.sessionType}
                      name="sessionType"
                      disabled={params.id ? "disabled" : ""}
                    >
                      <option value="">Select Session Type</option>
                      {currentRole === "ROLE_RCE" && (
                        <option value="THERAPY">THERAPY</option>
                      )}
                      <option value="EVALUATION">EVALUATION</option>
                      <option value="RE_EVALUATION">RE_EVALUATION</option>
                    </select>
                    <div className="input-group-prepend">
                      <img
                        className="m-auto"
                        src="../resources/assets/Polygon 1.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default SelectSessionType;
