import React, { useState, useEffect } from "react";
import SessionsDetail from "../../components/SessionsDetail";
import { adminService } from "../../services/adminService";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TeamDetail from "../../components/TeamDetail";
import { useSelector } from "react-redux";
import Messages from "../../components/messages/Messages";
import FormsTherapis from "../../components/forms/FormsTherapis";
import {
  capitalString,
  displayGender,
  getFileType,
  getFullName,
  getShortInsurance,
} from "../../utils/commonFunctions";
import { ReactSVG } from "react-svg";
import { AttachmentFile } from "../../components/attachment";
import Thumbnail from "../../components/Thumbnail";
import BasicUserdetail from "../../components/BasicUserdetail";
import { useNavigate } from "react-router-dom";

function FamilyDetails(props) {
  const history = useNavigate();
  let user = useSelector((state) => state.adminUser.user);
  let profile = useSelector((state) => state.adminUser.profile);
  const [teamdata, setTeamdata] = useState([]);
  const [familydetails, setFamilyDetails] = useState([]);
  const [loading, setloading] = useState(true);
  const [loadings, setloadings] = useState(false);
  const [therapysessions, setTherapySessions] = useState([]);
  const [therapistInfo, setTherapistInfo] = useState({});
  const [filter, setfilter] = useState("");
  let user_role = user.userDetails.roles[0];
  console.log("profile admin",profile);
  let params = useParams();
  const hasTherapistInFamily = (profileId, family) => {
    const list = family?.familyMembers
      ?.map((item) => item?.familyMemberTherapies)
      ?.flat()
      ?.map((item) => item?.therapist?.userProfile?.id);
    return (list || []).includes(profileId);
  };

  useEffect(() => {
    //setloading(true);
    (user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
      adminService
        .getById(
          `families/` +
          params.id +
          `/family-member/` +
          params.childid
        )
        .then(
          (res) => {
            setFamilyDetails(res.data);
            setloading(false);
          },
          (error) => {
            console.log(error);
          }
        );

    (user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
      adminService
        .getById(`families/my-team/rce?familyMemberId=` + params.childid)
        .then(
          (res) => {
            if (res.status === "Success") {
              setTeamdata(res.data);
            } else {
              toast.error(res.message);
            }
            setloadings(false);
          },
          (error) => {
            console.log(error);
          }
        );

    user_role === "ROLE_THERAPIST" &&
      adminService
        .getById(
          `therapist/family-members/` +
          params.childid +
          `/family/` +
          params.id
        )
        .then(
          (res) => {
            setFamilyDetails(res.data);
            setloading(false);
          },
          (error) => {
            console.log(error);
          }
        );
        user_role === "ROLE_INTERPRETER" &&
        adminService
          .getById(
            `families/` +
            params.id + `/family-member/` +
            params.childid
          )
          .then(
            (res) => {
              setFamilyDetails(res.data);
              setloading(false);
            },
            (error) => {
              console.log(error);
            }
          );
  }, [filter]);

  useEffect(() => {
    //setloadings(true);

    (user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
      adminService
        .getById(`sessions/filter/formatted?familyMemberId=` + params.childid)
        .then(
          (res) => {
            if (res.status === "Success") {
              setTherapySessions(res.data.content);
              setloadings(false);
            } else {
              toast.error(res.message);
            }
            setloading(false);
          },
          (error) => {
            console.log(error);
          }
        );

    user_role === "ROLE_THERAPIST" &&
      adminService
        .getById(
          `therapist/sessions/filter/formatted?familyMemberId=` + params.childid
        )
        .then(
          (res) => {
            if (res.status === "Success") {
              setTherapySessions(res.data.content);
              setloadings(false);
            } else {
              toast.error(res.message);
            }
            setloading(false);
          },
          (error) => {
            console.log(error);
          }
        );
  }, [params.id]);

  const dob = (x) => {
    let r = new Date();
    let t = Math.floor((r - x) / 1000 / 60 / 60 / 24 / 365);
  };
  dob(new Date(2010, 4, 2));
  const archiveStatus = ()=>{
    setloading(false);
    setfilter("archive")
  }
  const resetarchiveStatus = ()=>{
    setloading(false);
    setTimeout(()=>{
      setfilter("")
    },1000)
  }
  const parentBasicDetail = {
    image: familydetails?.userProfile?.profilePicUrl
      ? familydetails?.userProfile?.profilePicUrl
      : "/resources/assets/defaultprofile.png",
    firstName: familydetails?.userProfile?.firstName,
    lastName: familydetails?.userProfile?.lastName,
    // status: familydetails?.userProfile?.isActive === true ? "Active" : "InActive",
    gender: familydetails?.userProfile?.gender,
    isInSmall: true,
    email: familydetails?.userProfile?.email,
    phone: familydetails?.userProfile?.phone,
    about: familydetails?.userProfile?.about,
    other: familydetails,
    userType: familydetails?.userProfile?.userType,
    currentUserType: profile?.userType,
  };

  useEffect(() => {
    const id = profile?.therapistId;
    adminService
      .getAll(`therapists/${id}`)
      .then((res) => {
        setTherapistInfo(res.data);
      })
      .catch((err) => console.log(err));
  }, [profile?.therapistId]);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Client Details</div>
            </div>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <>
                <div className="row no-gutters">
                  <div className="col-md-8">
                    {familydetails?.familyMembers &&
                      familydetails?.familyMembers?.map((familyMember) => {
                        const basicInfo = {
                          image: familyMember?.profileUrl
                            ? familyMember?.profileUrl
                            : "/resources/assets/defaultprofile.png",
                          firstName: familyMember?.firstName,
                          lastName: familyMember?.lastName,
                          status:
                            familyMember?.isActive === true
                              ? "Active"
                              : "InActive",
                          gender: familyMember?.gender,
                          editLink:
                            (user_role === "ROLE_RCE" ||
                              user_role === "ROLE_THERAPIST" ||
                              user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
                            "/edit-child-detail/" +
                            familydetails.id +
                            "/" +
                            familyMember?.id,
                          about: familyMember?.about,
                          isInSmall: true,
                          dob: familyMember.dob,
                          userType: familyMember?.userType,
                          currentUserType: profile?.userType,
                        };
                        return (
                          <BasicUserdetail
                            key={familyMember.id}
                            therapistInfo={therapistInfo}
                            {...basicInfo}
                          />
                        );
                      })}

                    {familydetails?.familyMembers &&
                      familydetails.familyMembers.map((item) => (
                        <>
                          <div className="row ml-3 mr-3">
                            <div className="col-md-12 form-group mb-4">
                              <h4 className="name">Assigned Therapy</h4>

                              <table className="table custom-table assigned-therapy">
                                <tbody>
                                  <tr>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Physician NPI Number <br />{" "}
                                      <span>{item.physicianNpiNumber}</span>{" "}
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Physician First Name
                                      <br />{" "}
                                      <span>{item.physicianFirstName}</span>
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Physician Last Name
                                      <br />
                                      <span>{item.physicianLastName}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Allergies
                                      <br />{" "}
                                      <span>
                                        {item.allergies ? item.allergies : "-"}
                                      </span>
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Precautions
                                      <br />
                                      <span>
                                        {item.precautions
                                          ? item.precautions
                                          : "--"}
                                      </span>
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Medications
                                      <br />
                                      <span>
                                        {item.medications
                                          ? item.medications
                                          : "--"}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      Interpreter
                                      <br />{" "}
                                      <span>
                                        {item.interpreter
                                          ? item.interpreter.userProfile
                                            .firstName +
                                          " " +
                                          item.interpreter.userProfile
                                            .lastName
                                          : "None needed"}
                                      </span>
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      <br />{" "}
                                    </td>
                                    <td
                                      className="border-top-0 insurance-table"
                                      style={{ minWidth: 50, maxWidth: 50 }}
                                    >
                                      <br />{" "}
                                    </td>
                                  </tr>
                                  {item &&
                                    item?.attachments &&
                                    item?.attachments.length > 0 && (
                                      <tr>
                                        <td
                                          className="border-top-0 insurance-table"
                                          style={{ minWidth: 50, maxWidth: 50 }}
                                        >
                                          Attachements
                                          <br />
                                          <div className="attachment-images-section-view mt-2 d-flex flex-wrap">
                                            {item &&
                                              item?.attachments &&
                                              item.attachments.map(
                                                (item, _id) => (
                                                  <div
                                                    title={item.fileName}
                                                    className={
                                                      (`c-attachments__file c-attachments__file--${item.fileType}`,
                                                        "c-attachments__file")
                                                    }
                                                  >
                                                    <div className="c-attachments__file-inner">
                                                      <a
                                                        href={item.key}
                                                        className=""
                                                        download
                                                        target={"_blank"}
                                                      >
                                                        {item.fileType ===
                                                          "image" ||
                                                          item.fileType ===
                                                          "image/jpeg" ||
                                                          item.fileType ===
                                                          "image/png" ||
                                                          item.fileType ===
                                                          "image/svg+xml" ? (
                                                          <img
                                                            className="attachment-img"
                                                            src={item.key}
                                                            alt="attachement"
                                                          />
                                                        ) : (
                                                          <ReactSVG
                                                            src={
                                                              item.fileType ===
                                                                "video/mp4" ||
                                                                item.fileType ===
                                                                "video"
                                                                ? "/resources/assets/Icon awesome-video.svg"
                                                                : item.fileType ===
                                                                  "application/msword"
                                                                  ? "/resources/assets/docs.svg"
                                                                  : item.fileType ===
                                                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                    ? "/resources/assets/docs.svg"
                                                                    : item.fileType ===
                                                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                      ? "/resources/assets/excels.svg"
                                                                      : "/resources/assets/Icon awesome-file-pdf.svg"
                                                            }
                                                          />
                                                        )}
                                                      </a>
                                                    </div>
                                                    <span className="c-attachments__file-name d-block">
                                                      {item.fileName}
                                                    </span>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        </td>
                                        <td
                                          className="border-top-0 insurance-table"
                                          style={{ minWidth: 50, maxWidth: 50 }}
                                        >
                                          <br />{" "}
                                        </td>
                                        <td
                                          className="border-top-0 insurance-table"
                                          style={{ minWidth: 50, maxWidth: 50 }}
                                        >
                                          <br />{" "}
                                        </td>
                                      </tr>
                                    )}
                                  {item?.familyMemberTherapies &&
                                    item.familyMemberTherapies.map((item) => (
                                      <>
                                        <tr className="h-2 bg-white">
                                          <td colSpan="4"></td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="border-top-0 insurance-table"
                                            style={{
                                              minWidth: 50,
                                              maxWidth: 50,
                                            }}
                                          >
                                            Service Type <br />
                                            <span>
                                              {item.therapy.name}
                                            </span>{" "}
                                          </td>
                                          <td
                                            className="border-top-0 insurance-table"
                                            style={{
                                              minWidth: 50,
                                              maxWidth: 50,
                                            }}
                                          >
                                            Referral Date <br />
                                            <span>
                                              {item.referralDate}
                                            </span>{" "}
                                          </td>
                                          <td
                                            className="border-top-0 insurance-table"
                                            style={{
                                              minWidth: 50,
                                              maxWidth: 50,
                                            }}
                                          >
                                            Therapist
                                            <br />
                                            <span>
                                              {
                                                item.therapist.userProfile
                                                  .firstName
                                              }{" "}
                                              {
                                                item.therapist.userProfile
                                                  .lastName
                                              }
                                            </span>{" "}
                                          </td>

                                          {/* <td className="border-top-0 cursor-pointer text-right" onClick={() => removeTempAssignTherapy(item.therapyId, info.firstName, info.lastName)}>
                                                                            <img src="../resources/assets/action-icon-delete.svg" />
                                                                        </td> */}
                                        </tr>
                                        <tr>
                                          <td
                                            colSpan="4"
                                            className="border-top-0 w-50 insurance-table"
                                            style={{
                                              minWidth: 50,
                                              maxWidth: 50,
                                            }}
                                          >
                                            ICD 10 Code <br />
                                            <table className="table custom-table">
                                              {item?.diagnosis &&
                                                item.diagnosis.map((item1) => (
                                                  <tr className="bg-common-gray">
                                                    <td className="w-0">
                                                      {item1.code} -{" "}
                                                      {item1.description}
                                                    </td>
                                                  </tr>
                                                ))}
                                            </table>
                                          </td>
                                        </tr>
                                        <tr className="h-2 bg-white">
                                          <td colSpan="4"></td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="row ml-3 mr-3">
                            <div className="col-md-12 form-group mb-4">
                              <h4 className="name">Insurance Information</h4>

                              <table className="table custom-table assigned-therapy">
                                <tbody>
                                  {item?.insuranceDetailDtoList ? (
                                    item.insuranceDetailDtoList
                                      .sort(
                                        (a, b) =>
                                          getShortInsurance[a.insuranceType] -
                                          getShortInsurance[b.insuranceType]
                                      )
                                      .map((infoInsurance, index) => (
                                        <>
                                          <tr>
                                            <td
                                              className="border-top-0 insurance-table"
                                              style={{
                                                minWidth: 50,
                                                maxWidth: 50,
                                              }}
                                            >
                                              Payee Name <br />{" "}
                                              <span>
                                                {infoInsurance.payeeName}
                                              </span>
                                            </td>
                                            <td
                                              className="border-top-0 insurance-table"
                                              style={{
                                                minWidth: 50,
                                                maxWidth: 50,
                                              }}
                                            >
                                              Payee Address <br />{" "}
                                              <span>
                                                {infoInsurance.payeeAddress}
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="border-top-0 insurance-table"
                                              style={{
                                                minWidth: 50,
                                                maxWidth: 50,
                                              }}
                                            >
                                              Payee Phone <br />{" "}
                                              <span>
                                                {" "}
                                                {infoInsurance.payeePhoneNumber.replace(
                                                  /(\d{3})(\d{3})(\d{4})/,
                                                  "($1) $2-$3"
                                                )}{" "}
                                              </span>
                                            </td>
                                            <td
                                              className="border-top-0 insurance-table"
                                              style={{
                                                minWidth: 50,
                                                maxWidth: 50,
                                              }}
                                            >
                                              Insurance Plan <br />{" "}
                                              <span>
                                                {" "}
                                                {
                                                  infoInsurance.insurancePlan
                                                }{" "}
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="border-top-0 insurance-table"
                                              style={{
                                                minWidth: 50,
                                                maxWidth: 50,
                                              }}
                                            >
                                              Group Number <br />{" "}
                                              <span>
                                                {" "}
                                                {infoInsurance.groupNumber}{" "}
                                              </span>
                                            </td>
                                            <td
                                              className="border-top-0 insurance-table"
                                              style={{
                                                minWidth: 50,
                                                maxWidth: 50,
                                              }}
                                            >
                                              Policy Number <br />{" "}
                                              <span>
                                                {" "}
                                                {
                                                  infoInsurance.policyNumber
                                                }{" "}
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="border-top-0 insurance-table"
                                              style={{
                                                minWidth: 50,
                                                maxWidth: 50,
                                              }}
                                            >
                                              Type of Insurance <br />{" "}
                                              <span>
                                                {infoInsurance.insuranceType}{" "}
                                              </span>
                                            </td>
                                            <td
                                              className="border-top-0 insurance-table"
                                              style={{
                                                minWidth: 50,
                                                maxWidth: 50,
                                              }}
                                            ></td>
                                          </tr>
                                          <tr className="h-2 bg-white">
                                            <td colSpan="2"></td>
                                          </tr>
                                        </>
                                      ))
                                  ) : (
                                    <tr className="text-center">
                                      <td colSpan="2">
                                        <strong>No data found</strong>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ))}
                    <div className="col-md-12">
                      <hr />
                    </div>
                    <div className="row mt-5 ml-4">
                      <div className="col-md-10">
                        <h4 className="name">Caretaker Details</h4>
                        <BasicUserdetail
                          therapistInfo={therapistInfo}
                          {...parentBasicDetail}
                        />
                        <h4 className="name mt-3">Languages</h4>

                        <div className="row">
                          {familydetails?.languages &&
                            familydetails.languages.map((item) => (
                              <div className="col-md-4">
                                <p className="lang-box">{item.languageName}</p>
                              </div>
                            ))}
                        </div>
                        {/* <Link to={`/add-therapy-session`} >
                                                    <button className="btn btn-primary custom-btn-green custom-btn-green-auto">
                                                        <img className="pr-2" src={`${window.location.origin}/resources/assets/plus-icon-button.svg`} alt="" />
                                                        Add New Session
                                                    </button>
                                                </Link> */}
                      </div>
                      {user_role === "ROLE_THERAPIST" ? (
                        <div className="col-md-2">
                          {therapistInfo?.updatePermission == "true" && (
                            <Link
                              to={`/edit-parent-detail/` + familydetails.id}
                            >
                              <img
                                src={`${window.location.origin}/resources/assets/Icon feather-edit-3.svg`}
                                alt=""
                              />
                            </Link>
                          )}
                        </div>
                      ) : (
                        (user_role === "ROLE_RCE" ||
                          user_role === "ROLE_REGIONAL_EARLY_STEPS") && (
                          <div className="col-md-2">
                            <Link
                              to={`/edit-parent-detail/` + familydetails.id}
                            >
                              <img
                                src={`${window.location.origin}/resources/assets/Icon feather-edit-3.svg`}
                                alt=""
                              />
                            </Link>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 no-padding text-center therapist-detail">
                    <div className="table-responsive">
                      <table className="table d-block">
                        <tbody className="bg-color1 d-block">
                          <tr className="d-block">
                            <td className="d-block">
                              <ul
                                className="nav nav-tabs"
                                id="myTab"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    id="session-tab"
                                    data-toggle="tab"
                                    href="#session"
                                    role="tab"
                                    aria-controls="session"
                                    aria-selected="true"
                                    onClick={resetarchiveStatus}
                                  >
                                    Session
                                  </a>
                                </li>
                                {(user_role === "ROLE_RCE" ||
                                  user_role ===
                                  "ROLE_REGIONAL_EARLY_STEPS") && (
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="team-tab"
                                        data-toggle="tab"
                                        href="#team"
                                        role="tab"
                                        aria-controls="team"
                                        aria-selected="false"
                                        onClick={resetarchiveStatus}
                                      >
                                        Team
                                      </a>
                                    </li>
                                  )}
                                {user_role === "ROLE_THERAPIST" &&
                                  hasTherapistInFamily(
                                    profile.id,
                                    familydetails
                                  ) && (
                                    <>
                                      <li className="nav-item">
                                        <a
                                          className="nav-link"
                                          id="team-tab"
                                          data-toggle="tab"
                                          href="#team"
                                          role="tab"
                                          aria-controls="team"
                                          aria-selected="false"
                                          onClick={resetarchiveStatus}
                                        >
                                          Messages
                                        </a>
                                      </li>
                                      <li className="nav-item">
                                        <a
                                          className="nav-link"
                                          id="forms-tab"
                                          data-toggle="tab"
                                          href="#forms"
                                          role="tab"
                                          aria-controls="forms"
                                          aria-selected="false"
                                        >
                                          Forms
                                        </a>
                                      </li>
                                    </>
                                  )}
                                  {user_role === "ROLE_RCE" &&(
                                    <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      id="forms-tab"
                                      data-toggle="tab"
                                      href="#forms"
                                      role="tab"
                                      aria-controls="forms"
                                      aria-selected="false"
                                    >
                                      Forms
                                    </a>
                                  </li>
                                  )}
                              </ul>
                              <div
                                className="d-block tab-content"
                                id="myTabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="session"
                                  role="tabpanel"
                                  aria-labelledby="session-tab"
                                >
                                  {loading ? (
                                    <p mt-5 ml-5>
                                      Loading...
                                    </p>
                                  ) : (
                                    <SessionsDetail
                                      therapysessions={therapysessions}
                                      bgColor="bg-color1"
                                      page="family"
                                      childId={params?.childid}
                                    />
                                  )}
                                </div>
                                {user_role === "ROLE_RCE" && (
                                  <div
                                    className="tab-pane fade text-left"
                                    id="team"
                                    role="tabpanel"
                                    aria-labelledby="team-tab"
                                  >
                                    <TeamDetail
                                      teamData={teamdata}
                                      bgColor="bg-color1"
                                      page="family"
                                    />
                                  </div>
                                )}
                                {user_role === "ROLE_THERAPIST" &&
                                  hasTherapistInFamily(
                                    profile.id,
                                    familydetails
                                  ) && (
                                    <>
                                      <div
                                        className="tab-pane fade position-relative"
                                        id="team"
                                        role="tabpanel"
                                        aria-labelledby="team-tab"
                                      >
                                        <Messages
                                          otherChildId={params.childid}
                                          otherUser={familydetails?.userProfile}
                                          bgColor="bg-color1"
                                          page="family"
                                        />
                                      </div>
                                      
                                      <div
                                        className="tab-pane fade position-relative"
                                        id="forms"
                                        role="tabpanel"
                                        aria-labelledby="team-tab"
                                      >
                                        {/* <Messages otherUser={familydetails?.userProfile} bgColor="bg-color1" page="family" /> */}
                                        
                                        {filter === "archive" ?
                                          <FormsTherapis
                                            isTherapist={
                                              user_role === "ROLE_THERAPIST"
                                            }
                                            archive={filter}
                                            child={
                                              familydetails?.familyMembers
                                                ?.length > 0 &&
                                              familydetails?.familyMembers[0]
                                            }
                                            parent={familydetails?.userProfile}
                                            therapist={profile}
                                          />
                                          :
                                          <>
                                          {user_role === "ROLE_THERAPIST" ?
                                          <button
                                            className=" btn btn-theme-green mt-2"
                                            style={{ borderRadius: "0.25rem !important" }}
                                            onClick={archiveStatus}
                                          >
                                            Archive
                                          </button>
                                          : ''}
                                          <FormsTherapis
                                            isTherapist={
                                              user_role === "ROLE_THERAPIST"
                                            }
                                            archive={filter}
                                            child={
                                              familydetails?.familyMembers
                                                ?.length > 0 &&
                                              familydetails?.familyMembers[0]
                                            }
                                            parent={familydetails?.userProfile}
                                            therapist={profile}
                                          />
                                          </>
                                        }
                                      </div>
                                    </>
                                  )}
                                  {user_role === "ROLE_RCE" && (
                                    <div
                                    className="tab-pane fade position-relative"
                                    id="forms"
                                    role="tabpanel"
                                    aria-labelledby="forms-tab"
                                  >
                                    {/* <Messages otherUser={familydetails?.userProfile} bgColor="bg-color1" page="family" /> */}
                                    
                                    {filter === "archive" ?
                                      <FormsTherapis
                                        isTherapist={
                                          user_role === "ROLE_THERAPIST"
                                        }
                                        archive={filter}
                                        child={
                                          familydetails?.familyMembers
                                            ?.length > 0 &&
                                          familydetails?.familyMembers[0]
                                        }
                                        parent={familydetails?.userProfile}
                                        therapist={profile}
                                      />
                                      :
                                      <>
                                      {user_role === "ROLE_RCE" ?
                                      <button
                                        className=" btn btn-theme-green mt-2"
                                        style={{ borderRadius: "0.25rem !important" }}
                                        onClick={archiveStatus}
                                      >
                                        Archive
                                      </button>
                                      : ''}
                                      <FormsTherapis
                                        isTherapist={
                                          user_role === "ROLE_THERAPIST"
                                        }
                                        isFamily={user_role === "ROLE_RCE"}
                                        archive={filter}
                                        child={
                                          familydetails?.familyMembers
                                            ?.length > 0 &&
                                          familydetails?.familyMembers[0]
                                        }
                                        parent={familydetails?.userProfile}
                                        therapist={profile}
                                      />
                                      </>
                                    }
                                  </div>
                                  )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FamilyDetails;