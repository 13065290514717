import adminUser from "./adminUser";
import agoraDetail from "./agoraReducer";
import agoraDetailNew from "./agoraReducerNew";
import messages from "./messageReducer";
import emoji from "./emojiReducer";
import appState from "./appReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  adminUser,
  agoraDetail,
  agoraDetailNew,
  messages,
  appState,
  emoji,
});

export default rootReducer;
