import React from "react";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

function AttachmentFile({
  className,
  src,
  attachmentType,
  onDelete,
  onClick,
  noLink,
  fileName,
}) {
  var ext = fileName && fileName.split(".").pop().toUpperCase();
  console.log("fileName", fileName,src,attachmentType);
  return (
    <div
      title={fileName}
      onClick={onClick}
      className={classNames(className, "c-attachments__file", {
        "c-attachments__file--has-filename": fileName,
      })}
    >
      <div className="c-attachments__file-inner">
        {onDelete && (
          <button
            title="delete"
            onClick={onDelete}
            type="button"
            className="btn"
          >
            <ReactSVG src="/resources/assets/close-fill-blue.svg" />
          </button>
        )}
        <a
          href={noLink ? "javascript:void(0)" : src}
          className=""
          download
          target={noLink ? "_self" : "_blank"}
        >
          {attachmentType === "image" ? (
            <img className="attachment-img" src={src} alt="attachement" />
          ) : (
            <ReactSVG
              src={
                attachmentType === "video"
                  ? "/resources/assets/Icon awesome-video.svg"
                  : ext === "MP4"||
                    ext === "MOV"||
                    ext === "WMV"||
                    ext === "AVI"||
                    ext === "AVCHD"||
                    ext === "FLV"||
                    ext === "F4V"||
                    ext === "SWF"||
                    ext === "MKV"||
                    ext === "WEBM"||
                    ext === "HTML5"
                  ? "/resources/assets/Icon awesome-video.svg"
                  : ext === "M4A" ||
                    ext === "FLAC" ||
                    ext === "MP4" ||
                    ext === "WAV" ||
                    ext === "WMA" ||
                    ext === "AAC" ||
                    ext === "MP3"
                  ? "/resources/assets/Icon awesome-microphone.svg"
                  :
                  attachmentType === "audio"
                  ? "/resources/assets/Icon awesome-microphone.svg"
                  : "/resources/assets/Icon awesome-file-pdf.svg"
              }
            />
          )}
        </a>
      </div>
      <span className="c-attachments__file-name d-block">{fileName}</span>
    </div>
  );
}

export default AttachmentFile;
