import React from "react";
import classNames from "classnames";

const MsgItem = ({
  date,
  showDate,
  from,
  showFrom,
  sender,
  timeText,
  message,
  time,
  isLocal
}) => {
  return (
    <>
      {showDate && (
        <div className="row mt-4 mb-4 session-detail-date">
          <div className="col-auto">{date}</div>
        </div>
      )}

      <div
        key={time + from}
        className={classNames("msg-item", {
          "msg-item--nosender": showFrom,
          "msg-item--local": isLocal
        })}
      >
        <span className="msg-user-name">
          {`${sender?.name}`}
          <span className="msg-user-time"> {timeText}</span>
        </span>
        <span className="msg-user-img">
          {console.log("sender image",sender) }
          <img
            src={sender?.profileImage ? sender?.profileImage : sender?.profilePicUrl ? sender?.profilePicUrl : sender?.familyProfilePic ? sender?.familyProfilePic : '/resources/assets/defaultprofile.png'}
            alt={sender?.userName}
          />
        </span>
        <span className="msg-text preview">{message}</span>
      </div>
    </>
  );
};

export default MsgItem