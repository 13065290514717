import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Switch, Link, useLocation } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { getHoursFromSecond } from "../../utils/commonFunctions";
import moment from "moment-timezone";
import { monthJson } from "../../config/Config";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-date-picker";
import BillingChart from "../BillingChart";
import { paginationDisplayCount } from "../../config/Config";
import { AiOutlineEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import { numRegex } from "../../components/Utils/Regex";
import DatePickers, { DateObject } from "react-multi-date-picker";
import ArchiveInvoicePrint from "../../components/payment/ArchiveInvoicePrint";
import DownLoadInvoiceBtn from "../../components/payment/DownLoadInvoiceBtn";
import appAction from "../../redux/actions/appAction";

function ClientBillingArchive(props) {
    const {
        register,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm();
    let location = useLocation();
    const dispatch = useDispatch();
    const formRef = useRef(null);
    let user = useSelector((state) => state.adminUser.user);
    let user_role = user.userDetails.roles[0];
    var d = new Date();
    let c = d.setMonth(d.getMonth() - 1);
    let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
    const history = useNavigate();
    const [loading, setloading] = useState(false);
    const [loadings, setloadings] = useState(false);
    const [billingInfo, setBillingInfo] = useState({});
    const [totalBilling, setTotalBilling] = useState({});
    const [billingInfoListing, setBillingInfoListing] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [status, setStatus] = useState("");
    const [startDate, setStartDate] = useState(new Date(c));
    const [endDate, setEndDate] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(0);
    const [totalpages, setTotalPages] = useState([]);
    const [show, setShow] = useState(false);
    const [showAdditional, setShowAdditional] = useState(false);
    const [showPolicyfee, setShowPolicyfee] = useState(false);
    const [paymentOverDue, setPaymentOverDue] = useState({ lateFees: '', paymentOverDueDays: '' })
    const [dueDays, setDueDays] = useState({ paymentOverDueDays: '' });
    const [addAdditionalField, setAdditionalField] = useState([{}]);
    const [invoiceId, setInvoiceId] = useState('');
    const [loader, setLoader] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadBtn, setDownloadBtn] = useState(false);
    const [npiName, setNpiName] = useState('');
    const [archiveInvoice, setArchiveInvoice] = useState([]);
    const [values, setValues] = useState([
        new DateObject().subtract(5, "days"),
        new DateObject()
    ])
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = (i) => {
        setShow(true);
        setInvoiceId(i);
    }
    const handlePolicyfeeShow = () => {
        setShowPolicyfee(true);
    }
    const handlePolicyfeeClose = () => {
        setShowPolicyfee(false);
        //setDueDays({ paymentOverDueDays: '' })
    }
    const handleAdditionalClose = () => {
        setShowAdditional(false);
        setInvoiceId('');
    };
    const handleAdditionalShow = (i) => {
        setShowAdditional(true);
        setInvoiceId(i);
    }
    //const dispatch = useDispatch();

    let jsonDates = JSON.stringify(values[0]);
    let jsonDatesTo = JSON.stringify(values[1]);
    let date = moment(parseInt(jsonDates)).format('YYYY-MM-DD');
    let dateTo = moment(parseInt(jsonDatesTo)).format('YYYY-MM-DD');
    const dateto = moment(parseInt(jsonDates)).format('MM/DD/YYYY');
    const datefrom = moment(parseInt(jsonDatesTo)).format('MM/DD/YYYY');
    const date1 = new Date(dateto);
    const date2 = new Date(datefrom);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffTime + " milliseconds");
    console.log(diffDays + " days");
    let qry_param_filter = `filter?startDateTime=${date}T00:00:00Z&endDateTime=${dateTo}T23:59:59Z`;

    useEffect(() => {
        const url = `archive/invoices/${qry_param_filter}&sort=invoiceDate,DESC`;

        if (values.length === 2) {
            adminService
                .getAll(url)
                .then((res) => {
                    setDownloadBtn(false);
                    if (res?.data) {
                        console.log("invoice archive", res?.data?.content);
                        setArchiveInvoice(res?.data?.content);
                        let str = res?.data?.therapistDto?.therapistServices;
                        let i = res?.data?.diagnosisDTO?.therapyDto?.id;
                        //let s = str.slice(0, i);
                        //setNpiName(s);
                        let filtered_array = _.filter(
                            str, function (o) {
                                return o?.therapy?.id === i;
                            }
                        );
                        setNpiName(filtered_array?.[0]?.clinicianNpiNumber);
                        setDownloadBtn(true);
                    }
                })
                .catch((e) => console.log("e", e));
        }
    }, [qry_param_filter]);

    useEffect(() => {
        tenantPemission?.billing == false && history("/dashboard");
        adminService.getAll(`payments/late-fees`)
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("response policy fee", res.data.paymentOverDueDays);
                    setDueDays({ paymentOverDueDays: res?.data?.paymentOverDueDays });
                } else {
                    toast.error(res.message);
                }

            })
    }, [tenantPemission?.billing]);
    const handleAdditionalField = (e, i) => {
        var inputAmount = document.getElementById("amount" + i);
        inputAmount.addEventListener("mousewheel",
            function (event) {
                event.target.blur()
            });
        const { name, value } = e.target;
        console.log("name value", name, value)
        let newArr = [...addAdditionalField];
        newArr[i] = {
            ...newArr[i],
            [name]: value,
        };
        setAdditionalField(newArr);
        console.log("additional field", newArr);
    };
    const addAdditionalFieldBtn = () => {
        setAdditionalField([...addAdditionalField, { amount: "", description: "" }]);
    };
    const removePrevLongGoals = (i) => {
        const arr1 = [...addAdditionalField];
        document.getElementById("amount" + i).value = '';
        document.getElementById("dec" + i).value = '';
        arr1.splice(i, 1);
        setAdditionalField(arr1);
    };
    const SubmitAdditionalFeild = (i) => {
        const postData = {
            "invoiceDate": moment().format('YYYY-MM-DD'),
            "invoiceDetailFormList": [...addAdditionalField],
            "invoiceNo": invoiceId
        }
        console.log("submit addtional data", postData);
        adminService.PUT(`payments/edit`, JSON.stringify(postData))
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("addtional data", res.data);
                    setAdditionalField([{ amount: "", description: "" }]);
                    handleAdditionalClose(false);
                    toast.success("Additional Data adedd successfully");
                } else {
                    toast.error(res.message);
                }

            })
    }
    //pagination
    const pageNumbers = [];

    for (let i = 0; i <= totalpages - 1; i++) {
        pageNumbers.push(i);
    }

    const paginate = (number) => {
        setCurrentPage(number);
    };
    const paginatePre = () => {
        setCurrentPage(currentPage - 1);
    };
    const paginateNext = () => {
        setCurrentPage(currentPage + 1);
    };
    //end

    useEffect(() => {
        getData();
    }, [currentPage, paginationDisplayCount]);
    const duePaymentHandle = (e) => {
        var inputAmount = document.getElementById("pasAmount");
        inputAmount.addEventListener("mousewheel",
            function (event) {
                event.target.blur()
            });
        const { name, value } = e.target;
        console.log("name", name);
        setPaymentOverDue((paymentOverDue) => ({ ...paymentOverDue, [name]: parseInt(value) }))
    }
    const overDueSubmit = (i) => {
        setLoader(true);
        //console.log("invoice number", i);
        adminService.PUT(`payments/apply-late-fees/${invoiceId}`, JSON.stringify(paymentOverDue))
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("sdf customerResponse", res.data);
                    setPaymentOverDue({ lateFees: '', paymentOverDueDays: '' })
                    setShow(false);
                    setLoader(false);
                    window.location.reload();
                    toast.success("Late fee adedd successfully");
                } else {
                    toast.error(res.message);
                }

            })
    }
    const getData = () => {
        setloadings(true);
        let sessionFromDateTime =
            moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
        let sessionToDateTime = moment(endDate).format("YYYY-MM-DD") + "T23:59:59";
        let qry_param_filter = `name=${searchName != '' ? searchName : ''}&billingStatus=${status != '' ? status : ''}&sessionFromDateTime=${sessionFromDateTime}&sessionToDateTime=${sessionToDateTime}&page=${currentPage}&size=${paginationDisplayCount}&sort=invoiceDate,DESC`
        let api = `archive/invoices?${qry_param_filter}`;

        adminService.getAll(api).then(
            (res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    setBillingInfoListing(res.data.content);
                    setTotalPages(res.data.totalPages);
                } else {
                    toast.error(res.message);
                }
                setloadings(false);
            },
            (error) => {
                console.log(error);
            }
        );
        (user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
            adminService.getAll(`archive/dashboard/rce`).then(
                (res) => {
                    if (res.status === "Success") {
                        setBillingInfo(res.data);
                        setTotalBilling(
                            res.data.paymentDone +
                            res.data.paymentPending +
                            res.data.pendingSubmissionToFederalOffice
                        );
                    } else {
                        toast.error(res.message);
                    }
                    setloading(false);
                },
                (error) => {
                    console.log(error);
                }
            );
    };
    const handleDueDays = (e) => {
        var input = document.getElementById("pasDay");
        input.addEventListener("mousewheel",
            function (event) {
                event.target.blur()
            });
        const { name, value } = e.target;
        setDueDays((dueDays) => ({ ...dueDays, [name]: parseInt(value) }));
    }
    const submitPolicyFee = () => {
        adminService.PUT(`payments/late-fees`, JSON.stringify(dueDays))
            .then((res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    console.log("response policy fee", res.data);
                    //setDueDays({ paymentOverDueDays: '' });
                    toast.success("Due Days Added successfully");
                    setShowPolicyfee(false);
                } else {
                    toast.error(res.message);
                }

            })
    }
    const search = () => {
        let sessionFromDateTime =
            moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
        let sessionToDateTime = moment(endDate).format("YYYY-MM-DD") + "T23:59:59";
        let qry_param_filter = `name=${searchName}&billingStatus=${status}&sessionFromDateTime=${sessionFromDateTime}&sessionToDateTime=${sessionToDateTime}&page=${currentPage}&size=${paginationDisplayCount}&sort=invoiceDate,DESC`;
        setloadings(true);

        let api = `archive/invoices?${qry_param_filter}`;

        adminService.getAll(api).then(
            (res) => {
                if (res.status === "Success") {
                    //setDashboard(res.data);
                    setBillingInfoListing(res.data.content);
                    setTotalPages(res.data.totalPages);
                } else {
                    toast.error(res.message);
                }
                setloadings(false);
            },
            (error) => {
                console.log(error);
            }
        );
        (user_role === "ROLE_RCE" || user_role === "ROLE_REGIONAL_EARLY_STEPS") &&
            adminService.getAll(`archive/dashboard/rce`).then(
                (res) => {
                    if (res.status === "Success") {
                        setBillingInfo(res.data);
                    } else {
                        toast.error(res.message);
                    }
                    setloading(false);
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const SubmitApproval = (id, status) => {
        console.log(id, status);
        let url = `session-billings/${id}/${status}`;

        adminService.PATCH(url).then(
            (res) => {
                if (res.status === "Success") {
                    console.log(res.data);
                    //setDashboard(res.data);
                    //setBillingInfoListing(res.data.content);
                    //setTotalPages(res.data.totalPages);
                    toast.error(res.message);
                } else {
                    toast.error(res.message);
                }
                setloadings(false);
            },
            (error) => {
                console.log(error);
            }
        );
    };
    const onPdfCreate = (cb) => {
        setIsDownloading(true);
        dispatch(appAction.setAppLoading(true));
        setTimeout(() => {
            if (typeof cb === "function") {
                cb();
            }
        }, 200);
    };

    const onPdfComplete = () => {
        setIsDownloading(false);
        dispatch(appAction.setAppLoading(false));
    };
    function currencyFormat(currency) {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(currency);
    }
    return (
        <div className="col-md-10 no-padding d-flex flex-column">
            <div className="no-padding dash-billing-info mr-0 flex-grow-1">
                <div className="custom-table-view min-h-100">
                    <div className="d-none"><ArchiveInvoicePrint ref={formRef} billingData={archiveInvoice} npiName={npiName} /></div>
                    <div className="content-header">
                        <div className="text-left header-title">Client Billing Info </div>
                        <div className="text-right form-date-input sessionFilterDate">
                            <b>Select Invoice Date : </b>
                            <DatePickers
                                value={values}
                                onChange={setValues}
                                maxDate={new DateObject()}
                                format="MM-DD-YYYY"
                                range
                            />
                            {diffDays > 365 ? <p><b className="text-danger">Please select date range with in one year</b></p> : ''}
                            <div className="float-right pl-2">
                                {values.length === 2 && diffDays <= 365 ? <DownLoadInvoiceBtn
                                    downloadName={`Invoice.pdf`}
                                    targetRef={formRef}
                                    beforeStart={onPdfCreate}
                                    onComplete={onPdfComplete}
                                />
                                    : ''}
                            </div>
                        </div>

                    </div>
                    <div className="p-2">
                        {(user_role === "ROLE_RCE" ||
                            user_role === "ROLE_REGIONAL_EARLY_STEPS") && (
                                <>
                                    {" "}
                                    <div
                                        className={
                                            totalBilling === 0
                                                ? "dashboard-billing-count row rounded-0 pt-0 pb-4"
                                                : "dashboard-billing-count row rounded-0 pt-3 pb-3"
                                        }
                                    >
                                        {totalBilling === 0 ? (
                                            <></>
                                        ) : (
                                            <div className="col-md-3 mb-3">
                                                <BillingChart billingInfo={billingInfo} />
                                                {/* <div className="donut-inner">
                                    <h5 style={{ color: 'white' }}>$000</h5>
                                </div> */}
                                            </div>
                                        )}
                                        <div className={totalBilling === 0 ? "col-md-4" : "col-md-3"}>
                                            <div className="item">
                                                <div className="content billing-blue-count">
                                                    <div className="billing-count payment-submit">
                                                        {
                                                            billingInfo.totalBilling
                                                                ? billingInfo.totalBilling
                                                                : 0
                                                        }
                                                    </div>
                                                    <div className="billing-title">
                                                        Billing till date
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={totalBilling === 0 ? "col-md-4" : "col-md-3"}>
                                            <div className="item">
                                                <div className="content billing-blue-count">
                                                    <div className="billing-count payment-pending">
                                                        {currencyFormat(
                                                            billingInfo.paymentPending
                                                                ? billingInfo.paymentPending
                                                                : 0
                                                        )}
                                                    </div>
                                                    <div className="billing-title">Payment Pending</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={totalBilling === 0 ? "col-md-4" : "col-md-3"}>
                                            <div className="item">
                                                <div className="content billing-blue-count">
                                                    <div className="billing-count payment-done">
                                                        {currencyFormat(
                                                            billingInfo.paymentDone
                                                                ? billingInfo.paymentDone
                                                                : 0
                                                        )}
                                                    </div>
                                                    <div className="billing-title">Payment Done</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-3 padding-right-0">
                                            <input
                                                name="search"
                                                className="billing-info-search form-control h-46"
                                                onChange={(e) => setSearchName(e.target.value)}
                                                placeholder="Search by Client Name"
                                                autofocus
                                            />
                                        </div>
                                        <div className="col-md-3 padding-right-0">
                                            <select
                                                className="form-control billing-dropdown"
                                                onClick={(e) => setStatus(e.target.value)}
                                                id="exampleFormControlSelect1"
                                            >
                                                <option
                                                    className="form-control"
                                                    value=""
                                                >
                                                    All
                                                </option>
                                                <option className="form-control" value="PAID">
                                                    Paid
                                                </option>
                                                <option className="form-control" value="VOID">
                                                    Void
                                                </option>
                                                <option className="form-control" value="WAIVED_OFF">Waived Off</option>
                                                <option className="form-control" value="UNCOLLECTIBLE">Uncollectible</option>
                                                {/* <option
                                                    className="form-control"
                                                    value="PENDING_APPROVAL"
                                                >
                                                    Approval Pending
                                                </option>
                                                <option
                                                    className="form-control"
                                                    value="PAYMENT_PENDING"
                                                >
                                                    Payment Pending
                                                </option>
                                                
                                                <option className="form-control" value="PAYMENT_IN_PROGRESS">Auto Pay in Progress</option>
                                                <option className="form-control" value="PAYMENT_FAILED">Auto Pay Failed</option>
                                                
                                                <option className="form-control" value="PAST_DUE">
                                                    Past Due
                                                </option> */}
                                            </select>
                                        </div>
                                        <div className="col-auto padding-right-0">
                                            {/* <label>Start Date</label> */}
                                            <div className="d-flex ">
                                                <div className="input-group">
                                                    <DatePicker
                                                        onChange={setStartDate}
                                                        value={startDate}
                                                        name="startDate"
                                                        className="datepickercal"
                                                    />
                                                </div>
                                            </div>
                                            <div className="time ml-2">Invoice Start Date</div>
                                        </div>
                                        <div className="col-auto padding-right-0">
                                            {/* <label>End Date</label> */}
                                            <div className="d-flex">
                                                <div className="input-group">
                                                    <DatePicker
                                                        onChange={setEndDate}
                                                        value={endDate}
                                                        name="endDate"
                                                        className="datepickercal"
                                                    />
                                                </div>
                                            </div>
                                            <div className="time ml-2">Invoice End Date</div>
                                        </div>

                                        <div className="col-auto pl-1 pr-0">
                                            {/* <button className="btn custom-btn-green" style={{width:130,height:50}} type="submit" onClick={search}> Search</button> */}
                                            <button
                                                className="btn btn-theme-green mr-0"
                                                type="submit"
                                                onClick={search}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25"
                                                    height="32"
                                                    fill="currentColor"
                                                    className="bi bi-search"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Session Date</th>
                                        <th scope="col">Client</th>
                                        {user_role === "ROLE_RCE" && (
                                            <th scope="col">Therapist</th>
                                        )}

                                        <th scope="col">Therapy</th>
                                        <th scope="col">Total</th>
                                        <th>Invoice Date</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                {loadings ? (
                                    <div className="loader"></div>
                                ) : (
                                    <tbody>
                                        {billingInfoListing &&
                                            billingInfoListing.map((item, index) => (
                                                <tr>
                                                    {/* <td><span className="name-table name">{monthJson[parseInt(moment(item.sessionDateTime).format("MM"))].short + " " + moment(item.sessionDateTime).format("DD") + ", " + moment(item.sessionDateTime).format("YYYY")}</span></td> */}
                                                    <td>
                                                        <span className="name-table name">
                                                            {moment.utc(item.sessionDateTime).utc().local().format("DD MMMM, yyyy")}<br />
                                                            {moment.utc(item.sessionDateTime).utc().local().format("hh:mm A")}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="name-table">
                                                            {item?.familyMemberName}
                                                        </span>
                                                    </td>
                                                    {user_role === "ROLE_RCE" && (
                                                        <td>
                                                            <span className="name-table">
                                                                {item?.therapistName}
                                                            </span>
                                                        </td>
                                                    )}

                                                    <td>
                                                        <span className="name-table">
                                                            {item.therapyDto.name}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="name-table name">
                                                            {currencyFormat(item.totalAmount)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="name-table name">
                                                            {" "}
                                                            {item?.invoiceDate ? moment(item?.invoiceDate).format(
                                                                "DD MMMM, yyyy"
                                                            ) : '-'}
                                                        </span>
                                                    </td>
                                                    <td className="fd-flex">
                                                        {item?.clientInvoiceDto?.billingStatus === "PAID" && (
                                                            <span className="badge badge-primary session-status s-completePay ">
                                                                <img
                                                                    className=""
                                                                    src="../resources/assets/approve-icon-button.svg"
                                                                />{" "}
                                                                Paid
                                                            </span>
                                                        )}
                                                        {item?.clientInvoiceDto?.billingStatus === "PENDING_APPROVAL" && (
                                                            <span className="badge badge-primary session-status s-approve">
                                                                <img
                                                                    className=""
                                                                    src="../resources/assets/pending-icon-button.svg"
                                                                />{" "}
                                                                Approval Pending
                                                            </span>
                                                        )}
                                                        {/* {var a = moment(item?.clientInvoiceDto?.overDueDate);
                                                        var b = moment().format("YYYY-MM-DD");
                                                        moment(item?.clientInvoiceDto?.overDueDate).diff(moment().format("YYYY-MM-DD"), 'days')} */}
                                                        {item?.clientInvoiceDto?.billingStatus ===
                                                            "PAYMENT_PENDING" && (
                                                                <span className={moment(item?.clientInvoiceDto?.overDueDate).diff(moment().format("YYYY-MM-DD"), 'days') < 0 ? "badge badge-primary session-status s-cancelled " : "badge badge-primary session-status s-missed "}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.042" height="14.042" viewBox="0 0 14.042 14.042">
                                                                        <g>
                                                                            <g>
                                                                                <path fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" d="M16.042 9.521A6.521 6.521 0 1 1 9.521 3a6.521 6.521 0 0 1 6.521 6.521z" transform="translate(.5 .5) translate(-3 -3)" />
                                                                            </g>
                                                                        </g>

                                                                        <path fill="currentcolor" d="M17.011 7.662a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.912zm0 5.47a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.912zm0-2.735a.912.912 0 1 0-.912.912.914.914 0 0 0 .912-.909z" transform="translate(-9.187 -3.75)" />
                                                                    </svg>{" "}
                                                                    Payment Pending
                                                                </span>
                                                            )}
                                                        {item?.clientInvoiceDto?.billingStatus ===
                                                            "VOID" && (
                                                                <span className="badge badge-primary session-status s-hide ">
                                                                    <img
                                                                        className=""
                                                                        src="../resources/assets/cancel.svg"
                                                                    />{" "}
                                                                    Void
                                                                </span>
                                                            )}
                                                        {item?.clientInvoiceDto?.billingStatus ===
                                                            "PAST_DUE" && (
                                                                <span className="badge badge-primary session-status s-cancelled ">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                                    </svg>{" "}
                                                                    Past Due
                                                                </span>
                                                            )}
                                                        {item?.clientInvoiceDto?.billingStatus ===
                                                            "WAIVED_OFF" && (
                                                                <span className="badge badge-primary session-status s-hide ">
                                                                    <img
                                                                        className=""
                                                                        src="../resources/assets/cancel.svg"
                                                                    />{" "}
                                                                    Waived Off
                                                                </span>
                                                            )}
                                                        {item?.clientInvoiceDto?.billingStatus ===
                                                            "PAYMENT_IN_PROGRESS" && (
                                                                <span className="badge badge-primary session-status s-missed ">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                                    </svg>{" "}
                                                                    Auto Pay in Progress
                                                                </span>
                                                            )}
                                                        {item?.clientInvoiceDto?.billingStatus ===
                                                            "PAYMENT_FAILED" && (
                                                                <span className="badge badge-primary session-status s-cancelled ">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                                                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                                                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                                                                    </svg>{" "}
                                                                    Auto Pay Failed
                                                                </span>
                                                            )}
                                                        {item?.clientInvoiceDto?.billingStatus ===
                                                            "UNCOLLECTIBLE" && (
                                                                <span className="badge badge-primary session-status s-hide ">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                                                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                                                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                                                                    </svg>{" "}
                                                                    Uncollectible
                                                                </span>
                                                            )}
                                                    </td>
                                                    <td>
                                                        <div className="therapy-session btn-group dropleft ml-3">
                                                            <Link
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <img style={{ height: "1rem" }} src="../resources/assets/Icon ionic-md-more.svg" />
                                                            </Link>
                                                            <div
                                                                className="dropdown-menu"
                                                                style={{
                                                                    width: 148,
                                                                }}
                                                            >
                                                                <Link className="dropdown-item" to={"/view-archive-client-bill/" + item?.invoiceNo}><svg className="mr-2" stroke="currentColor" fill="#387af6" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M255.8 112c-80.4 0-143.8 50.6-219.6 133.3-5.5 6.1-5.6 15.2-.1 21.3C101 338.3 158.2 400 255.8 400c96.4 0 168.7-77.7 220.1-134 5.3-5.8 5.6-14.6.5-20.7C424 181.8 351.5 112 255.8 112zm4.4 233.9c-53 2.4-96.6-41.2-94.1-94.1 2.1-46.2 39.5-83.6 85.7-85.7 53-2.4 96.6 41.2 94.1 94.1-2.1 46.2-39.5 83.6-85.7 85.7z"></path><path d="M256 209c0-6 1.1-11.7 3.1-16.9-1 0-2-.1-3.1-.1-36.9 0-66.6 31.4-63.8 68.9 2.4 31.3 27.6 56.5 58.9 58.9 37.5 2.8 68.9-26.9 68.9-63.8 0-1.3-.1-2.6-.1-3.9-5.6 2.5-11.7 3.9-18.2 3.9-25.2 0-45.7-21.1-45.7-47z"></path></svg> View</Link>
                                                            </div>
                                                        </div>
                                                        {/* )} */}

                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                )}
                                {billingInfoListing && billingInfoListing.length == 0 && (
                                    <tr>
                                        <td colSpan="7">
                                            <h5 className="text-center p-3">No detail available</h5>
                                        </td>
                                    </tr>
                                )}
                            </table>

                        </div>
                        {loadings ? (
                            <></>
                        ) : (
                            <>
                                {/* Pagination */}
                                <nav>
                                    <ul className="pagination justify-content-center">
                                        <li onClick={() => paginatePre()} className="page-item">
                                            {currentPage <= 0 ? (
                                                <></>
                                            ) : (
                                                <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                                                    <img
                                                        className="pr-2"
                                                        src="../resources/assets/arrow-left.svg"
                                                    />
                                                </div>
                                            )}
                                        </li>
                                        {pageNumbers.map((number) => (
                                            <li
                                                key={number}
                                                onClick={() => paginate(number)}
                                                className="page-item"
                                            >
                                                <div
                                                    className={`${currentPage === number
                                                        ? "pagination-active page-link pagination"
                                                        : "page-link pagination cursor-pointer"
                                                        }`}
                                                >
                                                    {number + 1}
                                                </div>
                                            </li>
                                        ))}
                                        <li onClick={() => paginateNext()} className="page-item">
                                            {currentPage >= totalpages - 1 ? (
                                                <></>
                                            ) : (
                                                <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                                                    <img
                                                        className="pr-2"
                                                        src="../resources/assets/arrow-right.svg"
                                                    />
                                                </div>
                                            )}
                                        </li>
                                        <li>
                                        <Link
                                            to={`/client-billing`}
                                            style={{ borderRadius:".25rem" }}
                                            className="btn btn-success ml-3"
                                        >
                                            Back
                                        </Link>
                                        </li>
                                    </ul>
                                </nav>
                                {/* End */}

                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientBillingArchive;