import React,{useRef} from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

function BillingChart(props) {
    const ref = useRef();
    const data = {
        // labels: ['Federal Office', 'Payment Done', 'Payment Pending'],     
        datasets: [
            {
                label: 'Population',
                data: [props.billingInfo.pendingSubmissionToFederalOffice ? props.billingInfo.pendingSubmissionToFederalOffice : 0,
                props.billingInfo.paymentDone ? props.billingInfo.paymentDone : 0,
                props.billingInfo.paymentPending ? props.billingInfo.paymentPending : 0,
                ],
                backgroundColor: ['#fbe200', '#abff00', '#72ffec']
            }
        ],
    }
    return (
        <div>
            <Doughnut
                ref={ref}
                data={data}
                width={350}
                height={150}
                options={{
                    cutoutPercentage: 80,
                    rotation: 180,
                    maintainAspectRatio: false
                }}                
            />            
        </div>
    )
}

export default BillingChart;