import React from 'react'
import moment from 'moment'

const DisplayDate = ({date, format}) => {
    return (
        <span>{ date !== null && moment.utc(date).utc().local().format(format)}</span>
    )
}

export default DisplayDate

DisplayDate.defaultProps = {
    format: 'D MMMM, YYYY'
}