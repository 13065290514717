import React, { useEffect, useState, useRef } from 'react';
import {
  useStripe, useElements,
  CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer
} from '@stripe/react-stripe-js';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import DigitalSignPopup from "../digitalSignPopup/BillSign";
import { adminService } from '../../services/adminService';
import { toast } from "react-toastify";
//import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CheckoutForm(props) {
  let history = useNavigate();
  const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
  const profile = useSelector((state) => state.adminUser.profile);
  const sigCanvas = useRef({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  //const[tenantName,setTenantName]=useState('');
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const { name } =
    soapNoteBillingData;
  const handleClose = () => {
    setShow(false);
    setCardAgree(!cardAgree);
  };
  const handleShow = () => {
    setShow(true)
    setCardAgree(!cardAgree);
  }

  const [open, setOpen] = useState(false);
  const handleSignModal = () => {
    setOpen(true);
    setShow(false);
    //setCardAgree(!cardAgree);
  }

  const [accountId, setAccountId] = useState('');
  const [cardAgree, setCardAgree] = useState(false);
  const [customerResponse, setcustomerResponse] = useState('');
  const [CardNumberValidate, setCardNumberValidate] = useState('');
  const [expireDateValidate, setexpireDateValidate] = useState('');
  const [cvcValidate, setCvcValidate] = useState('');
  const [formComplete, setFormComplete] = useState(false);
  const [formExpireComplete, setFormExpireComplete] = useState(false);
  const [formCvcComplete, setFormCvcComplete] = useState(false);
  //const [userDetail, setUserDetail] = useState();
  const [isSigned, setIsSigned] = useState(false);
  const [CheckboxList, setCheckBoxList] = useState({ saveCard: true, autoPay: false });
  const stripe = useStripe();
  const elements = useElements();
  const[tenantName,setTenantName]=useState('');
  //console.log("amountData", props.amountData);

  useEffect(() => {
    adminService.getAll(`misc/billing-company-detail?tenantId=${tenantId}`).then(
      (res) => {
        if (res.status === "Success") {
          //setDashboard(res.data);
          console.log("connect account", res.data.stripeAccountId,res?.data?.name);
          setAccountId(res?.data?.stripeAccountId);
          setTenantName(res?.data?.name);
        } else {
          //toast.error(res.message);
        }
        //setloadings(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [isSigned, accountId])
  const validate = (e) => {
    console.log("validate", e);
    if (e.complete === true) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
    if (e?.error !== null) {
      setCardNumberValidate(e?.error?.message);
    }
  }
  const validateExpire = (e) => {
    console.log("validate", e);
    if (e.complete === true) {
      setFormExpireComplete(true);
    } else {
      setFormExpireComplete(false);
    }
    if (e?.error !== null) {
      setexpireDateValidate(e?.error?.message);
    }
  }
  const validateCVC = (e) => {
    console.log("validate", e);
    if (e.complete === true) {
      setFormCvcComplete(true);
    } else {
      setFormCvcComplete(false);
    }
    if (e?.error !== null) {
      setCvcValidate(e?.error?.message);
    }
  }

  // CardNumberElement.on('change', function(event) {
  //   var displayError = document.getElementById('card-errors');
  //   if (event.error) {
  //     displayError.textContent = event.error.message;
  //   } else {
  //     displayError.textContent = '';
  //   }
  // });

  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    if (isSigned === false) {
      setCardAgree(!cardAgree);
      toast.error("please sign the form..");
    } else {
      // We don't want to let default form submission happen here,
      // which would refresh the page.

      const { stripe, elements } = props;
      if (!stripe || !elements) return;
      const card = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
      //const result = await stripe.createPaymentMethod(card);

      const result = await stripe
        .createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            name: 'Jenny Rosen',
          },
        })
        .then(result => {
          return result?.paymentMethod?.id;
        });
      let paymentResponse = result;

      console.log("paymentList", result);

      const stripeCustomer = await adminService
        .getAll(`payments/stripe-details`)
        .then((res) => {
          //console.log("response stripe-detail", res.data);
          return res.data;
        })

      console.log("stripe customer", stripeCustomer);
      const genCard = {
        'customer': stripeCustomer.stripeCustomerId,

      }
      //let paymentId = paymentResponse;
      console.log("paymentId", result);
      if (result !== '') {
        const paymentresults = await fetch("https://api.stripe.com/v1/payment_methods/" + result + "/attach", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + "rk_test_51L93VpSIqiHQiTzc0NCkneVgzWQBmVtW9z847HHaaTfEvorfTwciinYc0VFXY9PsAeQjvUzZTybvfGu0Q8IIIou500QvCKpknJ",
            'Stripe-Account': accountId,
          },
          body: Object.keys(genCard)
            .map(key => key + '=' + genCard[key])
            .join('&'),
        }).then((response) => {
          //response.json()
          console.log("response attach", response);
          if (response.status === 200) {
            elements.getElement(CardNumberElement).clear();
            elements.getElement(CardExpiryElement).clear();
            elements.getElement(CardCvcElement).clear();
            setCardAgree(false);
            // setCheckBoxList({
            //   saveCard: true, autoPay: false
            // })
            toast.success("Card Added Successfully");
            setLoader(false);
            history('/card-list');
          } else {
            toast.error("Please enter valid card details");
            setLoader(false);
            setCardAgree(false);
          }
        })
        //console.log('\n\n\nlogging: \n', genCard, '\n', 'blank', '\n', paymentresults);

      }


      //console.log("detailForBackend", detailForBackend,sdf);
    }
  };
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    // setFamilyImageURL(
    //   sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    // );
    setOpen(false);
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    console.log("sigCanvas.current", signatureData);
    updateSignature(signatureData);
  };
  const updateSignature = (signatureData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(signatureData))
      .then(
        (res) => {
          if (res.status === "Success") {

          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const onNotificationChange = (e) => {
    //console.log("notificationList",e.target.value,e.target.name,e.target.checked);
    const { name, checked } = e.target;
    let arr = { ...CheckboxList };
    arr = {
      ...arr,
      [name]: e.target.checked,
    };
    console.log("notificationList", arr)
    setCheckBoxList(arr);
  }
  console.log("sigCanvas", sigCanvas);
  return (
    <div className='min-h-100'>
      {loader && (
        <div className="loader-overlay">
          <div className="loader" />
        </div>
      )}
      <h2 className="header-title">Add Card Details</h2>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number (<span class="required-span">*</span>)</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => validate(e)}
              required
            />
            <p className='text-danger'>{CardNumberValidate !== '' ? CardNumberValidate : ''}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Expiration Date (<span class="required-span">*</span>)</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => validateExpire(e)}
              required
            />
            <p className='text-danger'>{expireDateValidate !== '' ? expireDateValidate : ''}</p>

          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cvc">CVC (<span class="required-span">*</span>)</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={(e) => validateCVC(e)}
              required
            />
            <p className='text-danger'>{cvcValidate !== '' ? cvcValidate : ''}</p>
          </div>
        </div>
        <label>
          <input
            onClick={handleShow}
            checked={cardAgree}
            type="checkbox"
            class="form-check-inline"
            name="agreeTurms"
          />Apply Card Authorization</label>
        {/* <button onClick={handleOpen}>Open modal</button> */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Payment Terms and Conditions</Modal.Title>
            <button onClick={handleClose} class="btn-close">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 20 20">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg></button>
          </Modal.Header>
          <Modal.Body>
            <p>By your electronic signature of this form, you authorize charges to your credit card throught Stripe via Simple Practice for services rendered. These charges will appear on your bank/credit card statement as {tenantName}. you have the right to request a paper copy of this document.
            </p>
            <p>I authorize {tenantName} to charge my credit card through Stripe.</p>
            <p>CANCELLATION POLICY: I also agree that my credit card can be charged for any session cancellation as per mentioned in Payment Policy form.</p>
            <p>I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify {tenantName} in writing of any changes in my account information or termination of this authorization.</p>
            <p>I certify that I am an authorized user of this credit card and will not dispute these scheduled transactions with my bank or credit card company as long as the transactions correspond to the terms indicated in this authorization form. I acknowledge that credit card transactions could be linked to Protected Health Information.</p>
            <button onClick={handleSignModal} className="btn btn-success">Please Sign</button>
          </Modal.Body>
        </Modal>
        <DigitalSignPopup
          modalState={open}
          setModalState={setOpen}
          save={save}
          clear={clear}
          sigCanvas={sigCanvas}
          setIsSigned={setIsSigned}
        />
        <hr className="mb-4" />
        {/* <p>{formComplete ? 'incomplete card detail' : ''}</p> */}
        <button className="btn btn-success" type="submit" disabled={formComplete && formExpireComplete && formCvcComplete && cardAgree && props.stripe ? false : true}>
          Add Card
        </button>
      </form>
    </div>
  );
}

export default function InjectCheckOut(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm amountData={props.amount} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}