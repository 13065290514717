import React, { Children, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { toast } from 'react-toastify'
import { submitForm } from '../../services/formsServices'
import { _toastHandleErr, _toasthandleRes } from '../../utils/commonFunctions'
import AppModal from '../AppModal'
import AttachmentBtn from '../attachment/AttachmentBtn'
import AttachmentFile from '../attachment/AttachmentFile'
import { useNavigate } from "react-router-dom";

const NewFormUpload = (props) => {
    const history = useNavigate();
    console.log("props upload", props);
    const user = useSelector(state => state.adminUser.user?.userDetails)

    const [state, setState] = useState({
        isModal: false,
        formState: {},
        isLoading: false
    })

    const { register, getValues, formState: { errors }, handleSubmit, setValue, reset } = useForm()

    useEffect(() => {
        if(props.form){
            const {
                fileName, reply, fileType, id, key
            } = props.form
            setValue('title', fileName)
            setValue('reply', reply)
            const type = fileType?.includes('image') ? 'image' : 'document'
            setState(state => ({...state, formState: {
                ...state.formState,
                id,
                file: {
                    url: key,
                    type,
                    fileType
                }
            }}))
        }


    }, [props.form])

    const toggleModal = () => {
        setState(state => ({ ...state, isModal: !state.isModal }))
    }
    
    const onChange = e => {
        const {
            files
        } = e.target
        const type = files[0]?.type?.includes('image') ? 'image' : 'document'
        const reader = new FileReader()
        reader.onloadend = function (file) {
            setState(state => ({
                ...state, formState: {
                    ...state.formState,
                    file: {
                        file: files[0],
                        url: file.target.result,
                        type,
                        fileName: files[0]?.name,
                    },
                }
            }))
        }
        reader.readAsDataURL(files[0])
    }

    const onSubmit = () => {
        if (!state.formState?.file?.file) {
            toast.error("Please attach form");
            return;
        }
        const sender = typeof user?.roles === 'string' ? user?.roles : (
            user?.roles?.length > 0 && user?.roles[0]
        )
        const dataObj = {
            ...getValues(),
            form: state.formState?.file?.file,
            familyMemberId: props.child?.id,
            therapistId: props.therapist?.id,
            sender,
        }
        const data = new FormData()
        Object.keys(dataObj).forEach(item => {
            data.append([item], dataObj[item])
        })
        setState(state => ({ ...state, isLoading: true }))
        submitForm(data).then(res => {
            _toasthandleRes(res)
            reset()
            setState(state => ({ 
                ...state, 
                formState: {}
            }))
            if(typeof props.afterSuccess === 'function'){
                props.afterSuccess()
            }
        }).catch(_toastHandleErr)
        .finally(() => {
            setState(state => ({ 
                ...state, 
                isLoading: false,
                isModal: false
            }))
        })
    }

    return (
        <>
        {props.archive === 'archive' ? <></> : props.isFamily ? <></> :
        <div className="c-forms__uploader" >
            
            <button disabled={state.isLoading} onClick={toggleModal} className="btn btn-theme-green c-forms__upload-btn">
                <ReactSVG wrapper="span" src="/resources/assets/Icon feather-upload.svg" />
                <span className="pl-2">Upload</span>
            </button>
            
            {/* {props.isTherapist ?
                          <button
                            className=" btn btn-theme-green ml-3"
                            style={{borderRadius:"0.25rem !important"}}
                            //onClick={() => setfilter("archive")}
                            onClick={() => history("archive-case-notes")}
                          >
                            Archive
                          </button>
                          : ''} */}
            <AppModal
                title="Upload Form"
                isOpen={state.isModal}
                onClose={toggleModal}
            >
                <form onSubmit={handleSubmit(onSubmit)} >
                    <input type="text" {...register('title',{ required: true })} name="title" placeholder="form title" className="form-control" />
                    {
                        errors.title && <span className="w-100 d-block pt-2 text-left error-text">Title is required</span>
                    }
                    <div className="align-items-center d-flex mt-2">
                        {state.formState.file?.url && 
                        <AttachmentFile
                            {...(state.formState.file)}
                            {...(state.formState.file?.file || {})}
                            src={state.formState.file.url}
                            attachmentType={state.formState?.file?.type}
                            // fileName={state.formState?.file?.fileName}
                            className={`c-attachments__file c-attachments__file--${state.formState.file.type}`}
                            // onDelete={removeImage}
                            />
                        }
                        <AttachmentBtn onChange={onChange} accept="image/*, .pdf, .doc, .docx" label={state.formState.file?.url ? "Change Form" : "Attach Form"} />
                    </div>
                    <div className="pt-2 text-left">
                        <label className="cursor-pointer" >
                            <input className="form-check-inline" type="checkbox" name="reply" {...register('reply')} />
                            <span>Reply Needed</span>
                        </label>
                    </div>
                    <button disabled={state.isLoading} type="submit" className={classNames("btn btn-theme-green mb-4", {
                        "has-loader": state.isLoading,
                    })}>
                        Submit
                        {state.isLoading && <i className="loader-small"></i>}
                    </button>
                </form>
            </AppModal>
        </div>
    }
    </>
    )
}
export default NewFormUpload
