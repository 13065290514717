import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "../admin/CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../admin/CommonComponents";
import SignDate from "../admin/SignDate";

function ChangeInCoverage(props) {
  const formRef = React.useRef(null);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState([]);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const profile = useSelector((state) => state.adminUser.profile);
  console.log(profile, "uygtyifyt");
  const [isLoading, setIsLoading] = useState(false);
  const sigCanvas = useRef({});
  const [coveragePolicy, setcoveragePolicy] = useState({});

  const [isSubmit, setIsSubmit] = useState(
    props?.from != "family" || props?.data?.reply
      ? true
      : props?.data?.replyNeeded == false
        ? !props?.data.replyNeeded
        : false
  );

  const [familyInfo, setFamilyInfo] = useState({
    id: props?.data?.id ? props.data.id : null,
    familyMemberName: props?.data?.familyMemberName
      ? props?.data?.familyMemberName
      : props?.data?.familyMember
        ? props.data.familyMember.firstName +
        " " +
        props.data.familyMember.lastName
        : " ",
    familyRelationship: props?.data.family
      ? props?.data?.family?.relationship
      : "",
    familyMemberId: props?.inputs?.familyMemberId
      ? props?.inputs?.familyMemberId
      : null,
    familyMemberDob: props?.data?.familyMemberDob
      ? props?.data?.familyMemberDob
      : props?.data?.familyMember
        ? props.data.familyMember.dob
        : " ",
    familySign:
      props?.data?.familySignDate && props?.data?.family
        ? props?.data?.family?.userProfile?.signatureUrl
        : false,
    familySignDate: props?.data?.familySignDate
      ? props.data.familySignDate
      : false,
    familyName: props?.data?.familyName ? props.data.familyName : "",
  });
  useEffect(() => {
    setcoveragePolicy({
      printName: props?.data?.familyName ? props.data.familyName : "",
      familySign:
        props?.data?.familySignDate && props?.data?.family
          ? props?.data?.family?.userProfile?.signatureUrl
          : false,
      patientName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      relationShip: props?.data.family ? props?.data?.family?.relationship : "",
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
    });
  }, [props?.data]);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  useEffect(() => {
    getPhoneNo();
  }, []);
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    let currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setcoveragePolicy({
      ...coveragePolicy,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const getPhoneNo = () => {
    adminService.getAll("submitted-forms/mobile/tenant").then(
      (res) => {
        if (res?.status === "Success") {
          setPhone(res.data.phone);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  };
  const [signatureErr, setSignatureErr] = useState("");
  const onSubmitFamilyForm = (isSubmit = false) => {
    setIsLoading(true);
    if (props?.data?.replyNeeded) {
      if (familyInfo?.familySignDate) {
        let formData = new FormData();
        // formData.append("signature", familyImageURL);
        formData.append(
          "formUpdationForm",
          new Blob(
            [
              JSON.stringify({
                ...familyInfo,
                jsonFormattedForm: JSON.stringify({ coveragePolicy }),
              }),
            ],
            {
              type: "application/json",
            }
          )
        );
        adminService
          .onboardPutUser(
            `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
            formData
          )
          .then((response) => {
            setIsLoading(true);
            toast.success("Form Submitted successfully");
            props?.from == "family" && props.handleBack();
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        setSignatureErr("Signature is required!");
      }
    } else if (props?.from === "admin") {
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("signature", familyImageURL);
      console.log("formData 220", formData);
      formData.append(
        "formCreationForm",
        new Blob([JSON.stringify(props?.inputs)], {
          type: "application/json",
        })
      );
      adminService
        .onboardUser(
          `submitted-forms`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Form Submitted successfully");
          props.setIsFocused(!props.isFocused);
          props?.from == "admin" && props.handleBack("reload");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };

  const updateInfo = (e) => {
    const { name, value } = e.target;
    setcoveragePolicy({ ...coveragePolicy, [name]: value });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "coveragepolicy.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const relValue = props?.data?.reply
    ? JSON.parse(props.data?.jsonFormattedForm)
    : "";

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("res.data.signatureUrl", res.data.signatureUrl);
            const currentDateTime = new Date();
            setcoveragePolicy({
              ...coveragePolicy,
              familySign: res.data.signatureKey,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: "100px",
          }}
        >
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.familySignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
          <div className="card-lg">
            <FormHeader
              logoUrl={logoUrl}
              title="CHANGE IN COVERAGE POLICY AGREEMENT"
            />
            <hr />
            <br />

            <div className="card-lg__content">
              <div className="w-100per">
                <label style={{ width: "auto" }}>
                  <b>Patient’s Name : </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  relValue?.coveragePolicy?.patientName
                ) : (
                  <input
                    {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                    type="text"
                    placeholder="Patient’s name"
                    name="patientName"
                    className={`form-control form-input form-input-w-60per ${errors.patientName && "is-invalid"
                      }`}
                    defaultValue={familyInfo?.familyMemberName}
                    disabled={familyInfo?.familyMemberName === '' ? false : true}
                  //onChange={(e) => updateInfo(e)}
                  />
                )}
              </div>
              <br />
              <div className="w-100per">
                <p className="w-100per">
                  {name} Network will bill your insurance, Medicaid, or Part C
                  for Therapy services rendered. However, if you or your child
                  has any change in coverage, including:
                </p>
                <ul>
                  <li>Change in Medicaid provider</li>
                  <li>Loss of Medicaid coverage</li>
                  <li>New private insurance policy</li>
                  <li>Change in private insurance policy</li>
                  <li>Loss of private insurance</li>
                  <li>Any other change in your child’s insurance coverage</li>
                </ul>
              </div>
              <div className="w-100per">
                <p className="w-100per">
                  You MUST contact our office immediately at {phone}. If we are
                  not informed of these changes, it may be impossible for us to
                  bill your insurance or Medicaid carrier.
                </p>
              </div>
              <div className="w-100per">
                <p className="w-100per">
                  The accuracy of this information is the patient’s
                  responsibility; therefore, you could be held responsible for
                  the balance if your carrier denies due to inaccurate or
                  incomplete information.
                </p>
              </div>

              <br />
              <div className="w-100per">
                <div style={{ width: "50%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Print Name : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.coveragePolicy?.printName
                    ) : (
                      <input
                        {...register('printName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyName === '' ? true : false })}
                        type="text"
                        placeholder="Print name"
                        name="printName"
                        className={`form-control form-input form-input-w-60per ${errors.printName && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyName}
                        disabled={familyInfo?.familyName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "50%", display: "inline-block" }}>
                  <div className="w-100per">
                    <label style={{ width: "auto" }}>
                      <b>Relationship to patient : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.coveragePolicy?.relationShip
                    ) : (
                      <input
                        {...register('familyRelationship', { onChange: (e) => updateInfo(e), required: familyInfo?.familyRelationship === '' ? true : false })}
                        type="text"
                        name="familyRelationship"
                        placeholder="relationShip"
                        className={`form-control form-input form-input-w-37per ${errors.familyRelationship && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyRelationship}
                        disabled={familyInfo?.familyRelationship === '' ? false : true}
                      // onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div style={{ width: "100%", marginTop: 20 }}>
                <SignDate
                  date={
                    coveragePolicy?.familySignDate ||
                    relValue?.coveragePolicy?.familySignDate
                  }
                  familyImageURL={familyImageURL}
                  isSubmit={isSubmit}
                  setOpen={setOpen}
                  signTitle=""
                  familyInfo={familyInfo}
                />
              </div>

              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />
            </div>

            <br />
            <hr />
            <FormFooter
              address={joinWithComma(
                address1,
                address2,
                city,
                country,
                zipCode
              )}
            />
            {!isSubmit ? (
              <>
                {signatureErr && (
                  <p className="text-red">
                    <small>{signatureErr}</small>
                  </p>
                )}
                <button
                  className="btn btn-theme-green"
                  onClick={() => props?.handleBack()}
                  type="button"
                >
                  Back
                </button>
                {props?.data.replyNeeded && (
                  <button
                    type={isLoading ? "button" : "submit"}
                    className="btn btn-success float-right1 mt-10 mb-10"
                    disabled={familyInfo.familySign ? false : true}
                  >
                    {isLoading ? "Loading...." : "Submit"}
                  </button>
                )}
              </>
            ) : (
              <>
                {props?.from === 'admin' && props?.isFormOpen &&
                  <>
                    <button
                      className="btn btn-theme-green"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                  </>

                }
                {!props?.isFormOpen && (
                  <button
                    className="btn btn-theme-green mb-10"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                )}
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default ChangeInCoverage;
