import React from "react";

function TherapistSignDate(props) {
  const {
    date,
    familyImageURL,
    isSubmit,
    setOpen,
    signTitle,
    errors,
    parentProvider,
    updateInfo,
    inputName,
    register,
  } = props;
  return (
    <>
      <div
        className="row mt-30"
        style={{ marginLeft: 0, width: "100%", display: "block" }}
      >
        <div style={{ display: "block" }}></div>
        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-block",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}>
            <b>{signTitle} Name</b>
          </div>
        </div>
        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-block",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div
            className="form-row1"
            style={{ width: "90%"}}
          >
            <b>Signature</b>
          </div>
        </div>
        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-block",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%", marginLeft:"5px"}}>
            <b>Date</b>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{ marginLeft: 0, width: "100%", display: "flex" }}
      >
        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-flex",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          {inputName}
        </div>
        <div
          className="col-md-4"
          style={{ width: "33%", display: "inline-flex" }}
        >
          <div className="form-row1" style={{ width: "90%", marginLeft: "-15px" }}>
            <div className="col-md-12">
              {familyImageURL ? (
                <img
                  src={
                    props?.isLoading
                      ? props?.userType == "therapist"
                        ? "familyInfo?.therapistSign"
                        : "familyInfo?.familySign"
                      : familyImageURL
                  }
                  alt="my signature"
                  className="sign-img"
                  style={{ maxwidth: "160px", maxHeight: "80px" }}
                />
              ) : isSubmit && parentProvider?.therapistSign ? (
                <img
                  src={parentProvider?.therapistSign}
                  alt="my signature"
                  className="sign-img"
                   style={{ maxwidth: "160px", maxHeight: "80px" }}
                />
              ) : parentProvider?.familySign ? (
                <img
                  src={parentProvider?.familySign}
                  alt="my signature"
                  className="sign-img"
                  style={{ maxwidth: "160px", maxHeight: "80px" }}
                />
              ) : null}{" "}
            </div>

            <br />
            <div className="col-md-12 pl-0" >
              {!isSubmit && (
                <button
                  type="button"
                  className="btn btn-success create-sign-btn"
                  onClick={() => setOpen(true)}
                >
                  {familyImageURL ? "Update" : "Create"} Your Signature
                </button>
              )}
            </div>
          </div>
        </div>

        <div
          className="col-md-4"
          style={{
            width: "33%",
            display: "inline-flex",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div className="form-row1" style={{ width: "90%" }}>
            {(familyImageURL || (isSubmit && parentProvider?.therapistSign)) &&
              date}
          </div>
        </div>
      </div>
    </>
  );
}

export default TherapistSignDate;
