import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import FormReplyForm from './FormReplyForm'

const FormReplyItem = ({ form, childId, afterSuccess }) => {
    if (!form.id) {
        return null
    }
    const date = moment.utc(form.createdDate).utc().local().format('MMM DD, YYYY')
    return (
        <tr>
            <th>
                <div className="ml-3 mt-1 mb-1">
                    {date}
                </div>
            </th>
            <td>
                <div className="mt-1 mb-1">
                    <span>
                        {form.fileName}
                    </span>
                </div>
            </td>
            <td>
                <div className="mt-1 mb-1">
                    <FormReplyForm afterSuccess={afterSuccess} childId={childId} form={form} />
                </div>
            </td>
            <td>
                <div className="mt-1 mb-1">
                    <span style={{
                        minWidth: 110
                    }} className={classNames("badge p-2 font-weight-normal", 
                        !form.reply || form.fileFormDto?.key ? "badge-theme-light-blue" : "badge-theme-light-red")} >
                            {form.reply ? (form.fileFormDto?.key ? "Uploaded" : "Pending") : 'Reply not needed'}
                        </span>
                </div>
            </td>
        </tr>
    )
}

export default FormReplyItem
