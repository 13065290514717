import React, { useEstate } from "react";
import moment from "moment";
import produce from "immer";
import { useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";

function BasicInfo(props) {
  const {
    isSubmit,
    sampleEvaluation,
    changeInfo,
    relValue,
    setsampleEvaluation,
  } = props;
  const profile = useSelector((state) => state.adminUser.profile);
  const setDate = (e) => {
    setsampleEvaluation(
      produce((draftState) => {
        draftState.step1["dob"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };

  var b = moment(sampleEvaluation?.step1?.dob).format("YYYY-MM-DD");
  let dt = b;
  let age = "";

  let bY = Number(moment(dt).format("YYYY"));
  let bM = Number(moment(dt).format("MM"));
  let bD = Number(moment(dt).format("DD"));

  let tY = Number(moment().format("YYYY"));
  let tM = Number(moment().format("MM"));
  let tD = Number(moment().format("DD"));

  age += tY - bY + " Years ";

  if (tM < bM) {
    age += tM - bM + 12 + " Months ";
    tY = tY - 1;
  } else {
    age += tM - bM + " Months ";
  }

  if (tD < bD) {
    age += tD - bD + 30 + " Days ";
    tM = tM - 1;
  } else {
    age += tD - bD + " Days ";
  }
  {
    sampleEvaluation?.step1?.dob &&
      setsampleEvaluation(
        produce((draftState) => {
          draftState.step1["chronologicalAge"] = age;
        })
      );
  }

  return (
    <>
      <div style={{ width: "100%", border: "1px solid" }}>
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
            borderRight: "1px solid",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>Patient Name : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.patientName
            ) : (
              <input
                // ref={register({ required: true })}
                type="text"
                placeholder="Patient Name"
                name="patientName"
                className={`form-control form-input form-input-w-50per `}
                defaultValue={sampleEvaluation?.step1?.patientName}
                onChange={(e) => changeInfo(e)}
              />
            )}
          </div>
        </div>
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>Insurance ID : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.insuranceId
            ) : (
              <input
                // ref={register({ required: true })}
                type="text"
                name="insuranceId"
                placeholder="Insurance ID"
                className={`form-control form-input form-input-w-50per `}
                defaultValue={sampleEvaluation?.step1?.insuranceId}
                onChange={(e) => changeInfo(e)}
              />
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          border: "1px solid",
          borderTop: "none",
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
            borderRight: "1px solid",
          }}
        >
          <div style={{ width: "100%" }}>
            <label>
              <b>Chronological Age : </b>
              {isSubmit ? (
                <>{relValue?.sampleEvaluation?.step1?.chronologicalAge}</>
              ) : (
                <>{sampleEvaluation?.step1?.dob && <>{age}</>}</>
              )}
            </label>
          </div>
        </div>
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>DOB : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.dob?.trim() &&
              moment(sampleEvaluation.dob).format("MM/DD/YYYY")
            ) : (
              <div className="form-date-input">
                <DatePicker
                  multiple={false}
                  value={sampleEvaluation?.step1?.dob}
                  onChange={setDate}
                  name="dob"
                  format="MM-DD-YYYY"
                  editable={false}
                />
              </div>
            )}
            {/* Math.floor(moment(new Date()).diff(moment("02/26/1978","MM/DD/YYYY"),'years',true))) */}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          border: "1px solid",
          borderTop: "none",
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
            borderRight: "1px solid",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>Therapist Name : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.therapistName
            ) : (
              <input
                // ref={register({ required: true })}
                type="text"
                placeholder="Therapist's name"
                name="therapistName"
                className={`form-control form-input form-input-w-50per `}
                defaultValue={sampleEvaluation?.step1?.therapistName}
                onChange={(e) => changeInfo(e)}
              />
            )}
          </div>
        </div>
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>Therapist NPI : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.therapistNpi
            ) : (
              <input
                // ref={register({ required: true })}
                type="text"
                name="therapistNpi"
                placeholder="Therapist NPI"
                className={`form-control form-input form-input-w-50per `}
                defaultValue={sampleEvaluation?.step1?.therapistNpi}
                onChange={(e) => changeInfo(e)}
              />
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          border: "1px solid",
          borderTop: "none",
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
            borderRight: "1px solid",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>Place of Service : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.placeOfService
            ) : (
              <input
                // ref={register({ required: true })}
                type="text"
                placeholder="Place of Service"
                name="placeOfService"
                className={`form-control form-input form-input-w-50per `}
                defaultValue={sampleEvaluation?.step1?.placeOfService}
                onChange={(e) => changeInfo(e)}
              />
            )}
          </div>
        </div>
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>Dr. Name & NPI : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.drNameNpi
            ) : (
              <input
                // ref={register({ required: true })}
                type="text"
                name="drNameNpi"
                placeholder="Dr. Name & NPI"
                className={`form-control form-input form-input-w-50per `}
                defaultValue={sampleEvaluation?.step1?.drNameNpi}
                onChange={(e) => changeInfo(e)}
              />
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          border: "1px solid",
          borderTop: "none",
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
            borderRight: "1px solid",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>Diagnosis ICD 10 : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.diagnosisICD10
            ) : (
              <input
                // ref={register({ required: true })}
                type="text"
                placeholder="Diagnosis ICD 10"
                name="diagnosisICD10"
                className={`form-control form-input form-input-w-50per`}
                defaultValue={sampleEvaluation?.step1?.diagnosisICD10}
                onChange={(e) => changeInfo(e)}
              />
            )}
          </div>
        </div>
        <div
          style={{
            paddingLeft: 10,
            width: "50%",
            display: "inline-block",
          }}
        >
          <div style={{ width: "90%", paddingTop: 7 }}>
            <label style={{ width: "auto" }}>
              <b>Therapist Diagnosis ICD 10 : </b>
            </label>
            &nbsp;&nbsp;
            {isSubmit ? (
              relValue?.sampleEvaluation?.step1?.therapyDiagnosisICD10
            ) : (
              <input
                // ref={register({ required: true })}
                type="text"
                name="therapyDiagnosisICD10"
                placeholder="Therapist Diagnosis ICD 10"
                className={`form-control form-input form-input-w-50per`}
                defaultValue={sampleEvaluation?.step1?.therapyDiagnosisICD10}
                onChange={(e) => changeInfo(e)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicInfo;
