import React from "react";
import Table from "react-bootstrap/esm/Table";
import { getUTCTimeToLocalByDateWithMeridium } from "../../../utils/commonFunctions";

function ProcedureTable(props) {
  return (
    <>
      <Table className="mb-0" striped bordered hover>
        <thead>
          <tr>
            <th className="text-center font-bold">CPT Code</th>
            <th className="text-center font-bold">ICD 10 Code</th>
            <th className="text-center font-bold">Session Start Time</th>
            <th className="text-center font-bold">Session End Time</th>
            <th className="text-center font-bold">Units</th>
            <th className="text-center font-bold">Session Type</th>
          </tr>
        </thead>

        <tbody>
          {props?.list?.length > 0 &&
            props.list.map((item, id) => (
              <>
                <tr key={`procedure-${id}`}>
                  {" "}
                  <td className="text-center" key={id}>
                    {item?.cptCode && item?.cptCode?.procCode}
                    <div>{item?.cptCode && item?.cptCode?.description}</div>
                  </td>
                  <td className="text-center" key={id}>
                    {item?.cptCode && item?.diagnosis?.code}
                    <div>{item?.cptCode && item?.diagnosis?.description}</div>
                  </td>
                  <td className="text-center" key={id}>
                    {getUTCTimeToLocalByDateWithMeridium(item?.startTime)}
                  </td>
                  <td className="text-center" key={id}>
                    {getUTCTimeToLocalByDateWithMeridium(item?.endTime)}
                  </td>
                  <td className="text-center" key={id}>
                    {item?.units}
                  </td>
                  <td className="text-center" key={id}>
                    {item?.groupSession ? "Group" : "Individual"}
                  </td>
                </tr>
              </>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default ProcedureTable;
