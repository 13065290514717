import React, { useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import Pagination from "react-responsive-pagination";

function PaginationTabs({
  shareQuery,
  pagePre,
  pageNext,
  pageShare,
  paginate,
}) {
  const { debounce } = _;
  const enterPage = (e) => {
    console.log("nummber..", e);
    setCurrentPage(
      e ? (e >= shareQuery.totalPages ? shareQuery.totalPages - 1 : e) : 1
    );
    paginate(
      e ? (e >= shareQuery.totalPages ? shareQuery.totalPages - 1 : e) : 1
    );
  };
  const [currentPage, setCurrentPage] = useState(shareQuery.currentPage + 1);
  return (
    <>
      <div className="mt-10">
        <Pagination
          total={shareQuery.totalPages}
          current={shareQuery.currentPage}
          onPageChange={(page) => paginate(page)}
        />
      </div>
      {/* <div className="d-flex justify-content-center mt-3">
        <div className="btn-group pagination justify-content-center">
          <button
            type="button"
            disabled={shareQuery.currentPage <= 0}
            onClick={() => pagePre()}
            className="btn btn-outline-primary"
          >
            <img
              className=""
              src="./resources/assets/arrow-left.svg"
              alt="prev"
            />
          </button>
          {shareQuery?.pages &&
            shareQuery.pages.map((number) => (
              <button
                type="button"
                key={number}
                onClick={() => pageShare(number)}
                className={classNames(
                  "btn",
                  shareQuery.currentPage === number
                    ? "btn-primary"
                    : "btn-outline-primary"
                )}
              >
                {number + 1}
              </button>
            ))}
          <input
            type="number"
            min={1}
            defaultValue={currentPage}
            onChange={
              (e) =>
                // debounce(enterPage, 1000)
                enterPage(e.target.value)
              // paginate(
              //   e.target.value > currentPage
              //     ? currentPage
              //     : e.target.value < 0
              //     ? 1
              //     : e.target.value
              // )
            }
          />
          <button
            type="button"
            disabled={shareQuery.currentPage >= shareQuery.totalPages - 1}
            onClick={() => pageNext()}
            className="btn btn-outline-primary"
          >
            <img
              className=""
              src="./resources/assets/arrow-right.svg"
              alt="next"
            />
          </button>
        </div>
      </div> */}
      {/* <p className="text-center mt-10">
        <b>Total Pages:</b>
        {shareQuery.totalPages - 1}{" "}
      </p> */}
    </>
  );
}

export default PaginationTabs;
