import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { adminService } from "../../services/adminService";
import { _toastHandleErr, _toasthandleRes } from "../../utils/commonFunctions";
import QueryReplyForm from "./QueryReplyForm";
import QueryItem from "./QueryItem";
import QueryReplyList from "./QueryReplyList";
import AddQueryBtn from "./AddQueryBtn";

const Query = ({ sessionId, ...props }) => {
  console.log("props.replyBtn",props.replyBtn);
  const user = useSelector((state) => state.adminUser.user)?.userDetails;
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [state, setState] = useState({
    query: { id: props.query?.id || null } || props.query,
  });
  const { query } = state;
  useEffect(() => {
    if (props.query?.id) {
      fetchQuery(props.query?.id);
    }
  }, [props.query?.id]);

  const isTherepist = (user?.roles || []).includes("ROLE_THERAPIST");
  const isFamily = (user?.roles || []).includes("ROLE_FAMILY");
  const isInterepreter = (user?.roles || []).includes("ROLE_INTERPRETER");

  const fetchQuery = (id) => {
    if (props.archive) {
      adminService.getAll(`archive/queries/${id}`).then((res) => {
        if (res?.data) {
          console.log("archive query",res?.data);
          setState({
            ...state,
            query: res.data,
          });
        }
      });
    } else {
      adminService.getAll(`queries/${id}`).then((res) => {
        if (res?.data) {
          console.log("query",res?.data);
          setState({
            ...state,
            query: res.data,
          });
        }
      });
    }
  };
  const onSuccess = () => {
    setIsReplyOpen(false);
    if (query?.id) {
      fetchQuery(query.id);
    } else {
      if (props.onFirstSuccess && typeof props.onFirstSuccess === "function") {
        props.onFirstSuccess();
      }
    }
  };
  const mainQuery = {
    ...query,
    profilePicKey: props?.childProfilePic,
    name: props?.childName,
  };

  return (
    <div className="c-query">
      {((query && query.id) || isFamily) && (
        <p className="c-basic-heading">Queries</p>
      )}
      {query && query.id ? (
        <div className="c-query__main">
          <div className="c-query__list">
            <QueryItem
              interpreterName={props.interpreterName}
              translateForm={isInterepreter}
              date={query?.queryDate}
              familyLanguages={props.familyLanguages}
              languageDto={props.languageDto}
              onSuccess={onSuccess}
              {...mainQuery}
            />
            <QueryReplyList
              onSuccess={onSuccess}
              interpreterName={props.interpreterName}
              translateForm={isInterepreter}
              familyLanguages={props.familyLanguages}
              languageDto={props.languageDto}
              data={query?.queryReplies}
            />
          </div>
          {(isFamily || (isTherepist && !props.isSharedCaseNote)) && props.replyBtn &&
            (isReplyOpen ? (
              <QueryReplyForm
                onSuccess={onSuccess}
                isReply={true}
                id={query.id}
              />
            ) : (
              <button
                onClick={() => setIsReplyOpen(!isReplyOpen)}
                className="btn btn-theme-green"
              >
                Reply
              </button>
            ))}
        </div>
      ) : (
        isFamily && <AddQueryBtn onSuccess={onSuccess} sessionId={sessionId} />
      )}
    </div>
  );
};

export default Query;
