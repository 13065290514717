
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdCreate, IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function IcdCodeTable(props) {
    let history = useNavigate();

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Therapy Name</th>
                        <th>ICD 10 code</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {props?.list.length > 0 &&
                        props?.list.map((data) => {
                            return (
                                <tr key={data.id}>
                                    <td>
                                        <b>{data?.therapyDto?.name}</b>
                                    </td>
                                    <td>{data?.code}</td>
                                    <td>{data?.description?.slice(0, 70)}</td>
                                    <td style={{ width: 100 }}>
                                        {data?.active === true ? (
                                            <span className="text-success">Active</span>
                                        ) : (
                                            <span className="text-danger">Inactive</span>
                                        )}
                                    </td>
                                    <td style={{ width: 100 }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-1">View</Tooltip>}
                                        >
                                            <button
                                                className="btn btn-theme-info btn-sm mb-10"
                                                onClick={() =>
                                                    history(`/view-icd-code/${data.id}`)
                                                }
                                            >
                                                <IoMdEye />
                                            </button>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}

export default IcdCodeTable;