import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../../../components/digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../../services/adminService";
import moment from "moment";
import CreateTemplate from "../../FormElements/CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../../FormElements/CommonComponents";
import SignDate from "../../FormElements/SignDate";
//import NameSignDate from "../admin/NameSignDate";

function Acknowledgement(props) {
  const formRef = React.useRef(null);
  const { register, errors, handleSubmit } = useForm();

  const profile = useSelector((state) => state.adminUser.profile);

  const [open, setOpen] = useState(false);
  const [witnessModal, setWitnessModal] = useState(false);

  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [witnessImageURL, setWitnessImageURL] = useState(null);

  const formInfo = props?.data || {};
  const OpenBy = props?.from;
  //alert(OpenBy);

  const [familyInfo, setFamilyInfo] = useState({
    familyName: props?.data?.familyMember.familyName,
    dob: props?.data?.familyMember?.dob,
  });

  const sigCanvas = useRef({});
  const sigWitnessCanvas = useRef({});
  const [isSubmit, setIsSubmit] = useState();
  const [familyReq, setFamilyReq] = useState(
    props?.from == "family" ? true : false
  );
  const [therapyReq, setTherapyReq] = useState(
    props?.from == "therapy" ? true : false
  );
  //props?.from != "family" || props?.data?.reply ? true : false

  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;

  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const clearWitness = () => sigWitnessCanvas.current.clear();
  const saveWitness = () => {
    setWitnessImageURL(
      sigWitnessCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setWitnessModal(false);
    setFamilyInfo({
      ...familyInfo,
      witnessSignDate: moment(new Date()).format("DD-MM-YYYY h:s:a"),
    });
  };

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    //set signature url
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    let currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });

    //set form data for signature upload
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };

  const onSubmitFamilyForm = (isSubmit = false) => {
    console.log("familyInfo.name ", familyImageURL, familyInfo);
    // let formData = new FormData();
    // formData.append("signature", familyImageURL);
    // formData.append(
    //   "formUpdationForm",
    //   new Blob([JSON.stringify(familyInfo)], {
    //     type: "application/json",
    //   })
    // );
    // adminService
    //   .onboardPutUser(
    //     `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
    //     formData
    //   )
    //   .then((response) => {
    //     props?.from == "family" && props.handleBack();
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  };

  const updateInfo = (e) => {
    const { name, value } = e.target;
    setFamilyInfo({ ...familyInfo, [name]: value });
  };

  const startDownload = () => {
    setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "Acknowledgement.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };

  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        {/* <HeaderDownloadButtons
          isSubmit={isSubmit}
          startDownload={startDownload}
          setIsSubmit={setIsSubmit}
        /> */}

        <div className="card-lg">
          <FormHeader logoUrl={logoUrl} title="ACKNOWLEDGEMENTS" />
          <hr />
          <br />
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content">
              <div style={{ width: "100%" }}>
                <div style={{ width: "60%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Patient’s Name : </b>
                    </label>
                    &nbsp;&nbsp;
                    <input
                      ref={register({ required: familyReq })}
                      type="text"
                      placeholder="Patient’s Name"
                      name="familyMemberName"
                      className={`form-control form-input form-input-w-60per ${
                        errors.familyMemberName && "is-invalid"
                      }`}
                      defaultValue={familyInfo?.familyName}
                      onChange={(e) => updateInfo(e)}
                    />
                  </div>
                </div>
                <div style={{ width: "40%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>DOB : </b>
                    </label>
                    &nbsp;&nbsp;
                    <input
                      ref={register({ required: familyReq })}
                      type="dob"
                      name="familyMemberDob"
                      placeholder="DOB"
                      className={`form-control form-input form-input-w-60per ${
                        errors.familyMemberDob && "is-invalid"
                      }`}
                      defaultValue={familyInfo.dob}
                      onChange={(e) => updateInfo(e)}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <p>
                  <b>Acknowledgement of HIPAA PRACTICES</b>
                </p>
                <p>
                  By signing this form, I acknowledge that I have received a
                  copy of {name} Network HIPAA practices and have read and
                  understand their policies
                </p>
                <div style={{ width: "90%" }}>
                  <label style={{ width: "auto" }}>
                    <b>Guardian Initials : </b>
                  </label>
                  &nbsp;&nbsp;
                  <input
                    style={{
                      height: 18,
                      width: "60%",
                      border: "none",
                      display: "inline-block",
                      borderBottom: "1px solid",
                      borderRadius: 0,
                      fontSize: 16,
                      background: "white",
                      padding: 0,
                    }}
                    ref={register({ required: familyReq })}
                    type="text"
                    placeholder="Family Guardian Initial"
                    name="familyGuardianInitial1"
                    className={`form-control ${
                      errors.familyGuardianInitial1 && "is-invalid"
                    }`}
                    defaultValue={familyInfo.familyGuardianInitial1}
                    onChange={(e) => updateInfo(e)}
                  />
                </div>
              </div>

              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <p>
                  <b>Acknowledgement of NO SHOW / CANCELLATION POLICY</b>
                </p>
                <p>
                  By signing this form, I acknowledge that I have received a
                  copy of {name} Network No Show / Cancellation Policy and have
                  read and understand their policies.
                </p>
                <div style={{ width: "90%" }}>
                  <label style={{ width: "auto" }}>
                    <b>Guardian Initials : </b>
                  </label>
                  &nbsp;&nbsp;
                  <input
                    style={{
                      height: 18,
                      width: "60%",
                      border: "none",
                      display: "inline-block",
                      borderBottom: "1px solid",
                      borderRadius: 0,
                      fontSize: 16,
                      background: "white",
                      padding: 0,
                    }}
                    ref={register({ required: familyReq })}
                    type="text"
                    placeholder="Family Guardian Initial"
                    name="familyGuardianInitial2"
                    className={`form-control ${
                      errors.familyGuardianInitial2 && "is-invalid"
                    }`}
                    defaultValue={familyInfo.familyGuardianInitial2}
                    onChange={(e) => updateInfo(e)}
                  />
                </div>
              </div>

              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <p>
                  <b>Acknowledgement of GUARDIAN / PROVIDER AGREEMENT</b>
                </p>
                <p>
                  By signing this form, I acknowledge that I have received a
                  copy of {name} Network No Show / Cancellation Policy and have
                  read and understand their policies.
                </p>
              </div>

              <br />

              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ width: "40%" }}>
                    <input
                      style={{
                        height: 18,
                        width: "60%",
                        border: "none",
                        display: "inline-block",
                        borderBottom: "1px solid",
                        borderRadius: 0,
                        fontSize: 16,
                        background: "white",
                        padding: 0,
                      }}
                      ref={register({ required: familyReq })}
                      type="text"
                      placeholder="Name of Guardian"
                      name="GuardianName2"
                      className={`form-control ${
                        errors.GuardianName2 && "is-invalid"
                      }`}
                      defaultValue={familyInfo.GuardianName2}
                      onChange={(e) => updateInfo(e)}
                    />
                  </td>
                  <td style={{ width: "40%" }}>
                    {witnessImageURL ? (
                      <>
                        <img src={witnessImageURL} />
                      </>
                    ) : (
                      <>
                        {OpenBy != "admin" && (
                          <button
                            type="button"
                            className="btn btn-theme-blue"
                            onClick={() => setWitnessModal(true)}
                          >
                            Create Signature
                          </button>
                        )}
                      </>
                    )}
                  </td>
                  <td style={{ width: "20%" }}>
                    <p>{familyInfo?.witnessSignDate}</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%" }}>
                    <p>Printed Name of Guardian</p>
                  </td>
                  <td style={{ width: "40%" }}>
                    <p>Signature of Guardian</p>
                  </td>
                  <td style={{ width: "20%" }}>
                    <p>Date</p>
                  </td>
                </tr>
              </table>

              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />

              <DigitalSignPopup
                modalState={witnessModal}
                setModalState={setWitnessModal}
                save={saveWitness}
                clear={clearWitness}
                sigCanvas={sigWitnessCanvas}
              />

              {OpenBy != "admin" && (
                <button
                  type="submit"
                  className="btn btn-theme-green float-right mt-10 mb-10"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
          <br />
          <hr />
          <FormFooter
            address={joinWithComma(address1, address2, city, country, zipCode)}
          />
        </div>
      </div>
    </>
  );
}

export default Acknowledgement;
