/*
// Dev Server URL 3.21.88.8
*/

//export const apiUrl = "http://3.21.88.8:9091/session-service/api";
//export const apiUrlAuth = "http://3.21.88.8:9090/auth-service/api";
//export const api = "http://3.21.88.8:9092/api";
//export const SOCKET_URL = "http://3.21.88.8:9091/mis-websocket";

/*
// Latest Dev Server URL 3.13.225.187
*/
// export const apiUrl = "http://3.13.225.187:9091/session-service/api";
// export const apiUrlAuth = "http://3.13.225.187:9090/auth-service/api";
// export const api = "http://3.13.225.187:9092/api";
// export const SOCKET_URL = "http://3.13.225.187:9091/mis-websocket";

// export const apiUrl = "https://misdev.techment.com/session-service/api";
// export const apiUrlAuth = "https://misdev.techment.com/auth-service/api";
// export const api = "https://misdev.techment.com/api";
// export const SOCKET_URL = "https://misdev.techment.com/mis-websocket";

// export const apiUrl = "http://18.116.131.13:9091/session-service/api";
// export const apiUrlAuth = "http://18.116.131.13:9090/auth-service/api";
// export const api = "http://18.116.131.13:9092/api";
// export const SOCKET_URL = "http://18.116.131.13:9091/mis-websocket";
// Dev Url
export const apiUrl = "https://meis-dev.techment.com/session-service/api";
export const apiUrlAuth = "https://meis-dev.techment.com/auth-service/api";
export const api = "https://meis-dev.techment.com/api";
export const SOCKET_URL = "https://meis-dev.techment.com/mis-websocket";

/*
// QA Server URL 3.130.73.230 http://3.130.73.230:9092/api
*/

// export const apiUrl = "http://3.130.73.230:9092/session-service/api";
// export const apiUrlAuth = "http://3.130.73.230:9092/auth-service/api";
// export const api = "http://3.130.73.230:9092/api";
// export const SOCKET_URL = "http://3.130.73.230:9092/mis-websocket";

// export const apiUrl = "https://meisqa.techment.com/session-service/api";
// export const apiUrlAuth = "https://meisqa.techment.com/auth-service/api";
// export const api = "https://meisqa.techment.com/api";
// export const SOCKET_URL = "https://meisqa.techment.com/mis-websocket";

// export const apiUrl = "http://localhost:9091/session-service/api";
// export const apiUrlAuth = "http://localhost:9090/auth-service/api";
// export const api = "http://localhost:9092/api";
// export const SOCKET_URL = "http://localhost:9091/mis-websocket";

/*
// Staging Server URL 18.221.202.110
// */

// OLD URL'S
// export const apiUrl = "http://18.221.202.110:8080/session-service/api";
// export const apiUrlAuth = "http://18.221.202.110:8080/auth-service/api";
// export const api = "http://18.221.202.110:9092/api";

// New STAGING URL
// export const apiUrl = "https://meisstag.techment.com/session-service/api";
// export const apiUrlAuth = "https://meisstag.techment.com/auth-service/api";
// export const api = "https://meisstag.techment.com/api";
// export const SOCKET_URL = "https://meisstag.techment.com/mis-websocket";

// New Production URL
// export const apiUrl = "https://meis.mis-live.com/session-service/api";
// export const apiUrlAuth = "https://meis.mis-live.com/auth-service/api";
// export const api = "https://meis.mis-live.com/api";
// export const SOCKET_URL = "https://meis.mis-live.com/mis-websocket";

/**
 *
 * url for the production
 * ip 3.143.162.122
 *
 */

// export const apiUrl = "http://3.143.162.122:9091/session-service/api";
// export const apiUrlAuth = "http://3.143.162.122:8080/auth-service/api";
// export const api = "http://3.143.162.122:9092/api";
// export const SOCKET_URL = "http://3.143.162.122:9091/mis-websocket";

/**
 * @constant
 * appid for agora
 *
 */

export const agoraAppId = "038b9af66c92421e9eca4b9ded8eafa2";

/**
 *
 * config for the firebase
 *
 */

export const firebaseCofig = {
  apiKey: "AIzaSyDGE1vo2s-m35-7wI5sM-lSJ11n7FL4b5k",
  authDomain: "meis-dev.firebaseapp.com",
  databaseURL: "https://meis-dev-default-rtdb.firebaseio.com",
  projectId: "meis-dev",
  storageBucket: "meis-dev.appspot.com",
  messagingSenderId: "581868426889",
  appId: "1:581868426889:web:221da5d0b5547d04b79858",
  measurementId: "G-KJH0VZ66N1",
};

export const monthJson = [
  { index: 1, name: "January", short: "Jan", maxvalue: 31 },
  { index: 2, name: "February", short: "Feb", maxvalue: 28 },
  { index: 3, name: "March", short: "Mar", maxvalue: 31 },
  { index: 4, name: "April", short: "Apr", maxvalue: 30 },
  { index: 5, name: "May", short: "May", maxvalue: 31 },
  { index: 6, name: "June", short: "June", maxvalue: 30 },
  { index: 7, name: "July", short: "July", maxvalue: 31 },
  { index: 8, name: "August", short: "Aug", maxvalue: 31 },
  { index: 9, name: "September", short: "Sept", maxvalue: 30 },
  { index: 10, name: "October", short: "Oct", maxvalue: 31 },
  { index: 11, name: "November", short: "Nov", maxvalue: 30 },
  { index: 12, name: "December", short: "Dec", maxvalue: 31 },
];
export const daysJson = [
  { index: 1, day: "mon", name: "Monday", short: "Mon" },
  { index: 2, day: "tue", name: "Tuesday", short: "Tue" },
  { index: 3, day: "wed", name: "Wednesday", short: "Wed" },
  { index: 4, day: "thu", name: "Thursday", short: "Thu" },
  { index: 5, day: "fri", name: "Friday", short: "Fri" },
  { index: 6, day: "sat", name: "Saturday", short: "Sat" },
  { index: 7, day: "sun", name: "Sunday", short: "Sun" },
];

export const daySorter = {
  //"sunday": 0, // << if sunday is first day of week
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const messages = [
  { key: "empty", message: "Field can't be empty" },
  {
    key: "start_end_time_validation_timezhone",
    message:
      "Start time can not be less from end time, UTC timezhone is not same",
  },
  {
    key: "start_end_time_validation",
    message: "Start time can not be less from end time",
  },
  {
    key: "password_notmatch",
    message: "Your password and confirmation password do not match.",
  },
  {
    key: "language_added",
    message: "Language already added, please add other language.",
  },
  {
    key: "start_time_validation",
    message: "Start date/time must be future date/time",
  },
  {
    key: "time_diff_validation",
    message: "Time difference can not be less from 30 minutes",
  },
];
export const paginationDisplayCount = 10;

export const getTimeFormatPropsInputComp = {
  placeholder: "00:00",
  required: true,
  title: "Please enter time between(01:00 - 12:59)",
  type: "time",
  //pattern : /\d+/,
  pattern: "(1[0-2]|0?[1-9]):[0-5][0-9]",
};
