import React from "react";
import CaseNoteInfoCol from "./CaseNoteInfoCol";

const CaseNoteInfo = ({
  sessionNumber,
  date,
  therapist,
  interpreterName,
  therapy,
  clinicianNpiNumber,
  clinicNpiNumber,
  groupNpiNumber,
}) => {
  return (
    <div className="p-case-note-detail__info w-100">
      <div className="row flex-grow-1">
        <div className="col-md-4 mb-3">
          <CaseNoteInfoCol label="Session Id" value={sessionNumber} />
        </div>
        <div className="col-md-4 mb-3">
          <CaseNoteInfoCol label="Session Date" value={date} />
        </div>
        <div className="col-md-4 mb-3">
          <CaseNoteInfoCol label="Therapist" value={therapist} />
        </div>
      </div>
      <div style={{ width: "100%" }} />
      <div className="row flex-grow-1">
        {interpreterName && (
          <div className="col-md-4 mb-3">
            <CaseNoteInfoCol label="Interpreter" value={interpreterName} />
          </div>
        )}
        <div className="col-md-4 mb-3">
          <CaseNoteInfoCol label="Therapy" value={therapy} />
        </div>
        <div className="col-md-4 mb-3">
          <CaseNoteInfoCol
            label="Clinician NPI Number"
            value={clinicianNpiNumber}
          />
        </div>
        {groupNpiNumber && (
          <div className="col-md-4 mb-3">
            <CaseNoteInfoCol label="Group NPI Number" value={groupNpiNumber} />
          </div>
        )}
        {/* {
                    !interpreterName && 
                    <div className="col-md-4 mb-3">
                        <CaseNoteInfoCol fixed={false} label="Clinic NPI Number" value={clinicNpiNumber} />
                    </div>
                } */}
      </div>
      {/* {
                interpreterName && <>
                <div style={{width: '100%'}} />
                <div className="row flex-grow-1">
                    <div className="col-md-4 mb-3">
                        <CaseNoteInfoCol fixed={false} label="Clinic NPI Number" value={clinicNpiNumber} />
                    </div>
                </div>
                </>
            } */}
    </div>
  );
};

export default CaseNoteInfo;
