import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SessionsDetail from "../../components/SessionsDetail";
import { adminService } from "../../services/adminService";
import allActions from "../../redux/actions";
import { useDispatch } from "react-redux";
import { paginationDisplayCount } from "../../config/Config";
import Pagination from "../../components/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";

function Therapist(props) {
  const dispatch = useDispatch();
  const [therapist, setTherapist] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [search, setSearch] = useState("");
  const [therapysessions, setTherapySessions] = useState([]);
  const [status, setStatus] = useState("");
  const [rowclickid, setRowClickId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalpages, setTotalPages] = useState([]);
  const [therapistid, setTherapistId] = useState({
    info: "",
  });
  const [isConfirmation, setIsConfirmation] = useState(false);
  // const [confirmMsg, setConfirmMsg] = useState("");
  // const [currentId, setCurrentId] = useState(0);

  useEffect(() => {
    dispatch(allActions.adminAction.clearOnboardData());
    setloading(true);
    getList();
  }, [status, search, currentPage, paginationDisplayCount]);

  const getList = () => {
    adminService
      .getAll(
        `therapists/filter?pendinfOnboarding=false&page=${currentPage}&size=${paginationDisplayCount}&status=${status}&name=${search}&sort=id,DESC`
      )
      .then(
        (res) => {
          if (res?.status === "Success") {
            setTherapist(res.data.content);
            setTotalPages(res.data.totalPages);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    if (therapist?.length > 0) {
      const isSelected = therapist.some((item) => item.id === rowclickid);
      if (!isSelected) {
        onClickRow(therapist[0]?.id);
      }
    } else {
      onClickRow(null);
    }
  }, [rowclickid, therapist]);
  //pagination
  const pageNumbers = [];

  for (let i = 0; i <= totalpages - 1; i++) {
    pageNumbers.push(i);
  }

  const paginate = (number) => {
    setCurrentPage(number);
  };
  const paginatePre = () => {
    setCurrentPage(currentPage - 1);
  };
  const paginateNext = () => {
    setCurrentPage(currentPage + 1);
  };
  //end

  const successSub = () => {
    onClickRow(rowclickid);
  };

  const onClickRow = (id) => {
    setloadings(true);
    setRowClickId(id);
    if (id !== null) {
      adminService.getById(`sessions/filter/formatted?therapistId=` + id).then(
        (res) => {
          if (res.status === "Success") {
            setTherapySessions(res.data.content);
            setloadings(false);
          } else {
            toast.error(res.message);
          }
          setloading(false);
        },
        (error) => {
          console.log("__err", error);
        }
      );
    }
    let data = {
      therapistid: id,
    };
    setTherapistId({ ...therapistid, ["info"]: data });
  };

  const handleOnboardPrmission = (info, statusFor = "onboard") => {
    //   api call to give permission to onboard family
    const formData = new FormData();
    let data = {};
    let toastMsg = "";
    if (statusFor == "onboard") {
      const status = info?.onboardPermission == "false" ? "true" : "false";
      data = { onboardPermission: status };
      toastMsg =
        info?.onboardPermission == "false"
          ? "Onboard Permission Granted Successfully!"
          : "Onboard Permission Denied Successfully!";
    } else {
      const status =
        info?.updatePermission == "false" || info?.updatePermission == null
          ? "true"
          : "false";
      data = { updatePermission: status };
      toastMsg =
        info?.updatePermission == "false" || info?.updatePermission == null
          ? "Edit/Delete Permission Granted Successfully!"
          : "Edit/Delete Permission Denied Successfully!";
    }
    formData.append(
      "therapistUpdateForm",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    adminService
      .onboardPutUser(`therapists/${info?.id}`, formData)
      .then((response) => {
        toast.success(toastMsg);
        getList();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Status not updated");
      });
    setIsConfirmation(false);
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Therapist</div>
              <div className="text-right header-btn">
                <Link to={`/add-therapist`}>
                  <button className="btn btn-primary custom-btn-green custom-btn-green-auto">
                    <img
                      className="pr-2"
                      src="../resources/assets/plus-icon-button.svg"
                      alt=""
                    />{" "}
                    Add Therapist
                  </button>
                </Link>
              </div>
            </div>
            <form>
              <div className="row">
                <div className="col-md-6 justify-content-between align-items-center mt-4 mb-4 d-flex">
                  <div className="col-auto w-75">
                    <input
                      type="search"
                      name="text"
                      className="form-control search"
                      placeholder="Search by Name"
                      onChange={(e) => setSearch(e.target.value)}
                      autofocus
                    />
                  </div>

                  <div className="col-auto">
                    <select
                      className="form-control time"
                      onChange={(e) => setStatus(e.target.value)}
                      id="exampleFormControlSelect1"
                    >
                      <option className="form-control time" value="">
                        All
                      </option>
                      <option className="form-control time" value={true}>
                        Active
                      </option>
                      <option className="form-control time" value={false}>
                        Inactive
                      </option>
                    </select>
                  </div>

                  {/* <div className="col-auto">
                                <button className="btn btn-theme-green" type="submit" disabled={loading} block={true}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                        <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                    </svg>
                                </button>
                            </div> */}
                </div>
              </div>
            </form>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <>
                <div className="row no-gutters">
                  <div className="col-md-8 therapist p-0">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Completed Appointment</th>
                            <th scope="col">Status</th>
                            {/* <th scope="col">Onboard Permission</th> */}
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        {therapist.length ? (
                          therapist &&
                          therapist.map((item, i) => {
                            return (
                              <tbody>
                                <tr
                                  key={i}
                                  className={`cursor-pointer ${
                                    item.id === rowclickid
                                      ? "bg-light-cyan"
                                      : ""
                                  }`}
                                >
                                  <td onClick={() => onClickRow(item.id)}>
                                    <div className="user-info">
                                      <span className="admin-name">
                                        <img
                                          src={
                                            item.userProfile.profilePicUrl ||
                                            "/resources/assets/defaultprofile.png"
                                          }
                                          alt=""
                                          className="image mr-2"
                                        />
                                        <div className="content">
                                          <span className="name-table name">
                                            {item.userProfile.firstName}{" "}
                                            {item.userProfile.lastName}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                  <td onClick={() => onClickRow(item.id)}>
                                    <div className="name-table ml-0 mr-5 text-center">
                                      {item.appointments}
                                    </div>
                                  </td>
                                  <td onClick={() => onClickRow(item.id)}>
                                    <div className="name-table">
                                      {item.userProfile.active === true
                                        ? "Active"
                                        : "InActive"}
                                    </div>
                                  </td>
                                  {/* <td className="text-center">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-success dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      Permission
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          handleOnboardPrmission(
                                            item,
                                            "onboard"
                                          )
                                        }
                                      >
                                        {item?.onboardPermission == "true"
                                          ? "Deny Onboard Permission"
                                          : "Onboard Permission"}
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          handleOnboardPrmission(item, "update")
                                        }
                                      >
                                        {item?.updatePermission == "true"
                                          ? "Deny Edit/Delete Permission"
                                          : "Edit/Delete Permission"}
                                      </a>
                                    </div>
                                  </div>
                                </td> */}
                                  <td>
                                    {/* <Link
                                    to={`/therapist-details/` + item.id}
                                    className="view-all-link"
                                  >
                                    <div className="name-table">
                                      {" "}
                                      View Details{" "}
                                      <img
                                        className="pl-2"
                                        src="../resources/assets/arrow.svg"
                                        alt=""
                                      />{" "}
                                    </div>
                                  </Link> */}
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-success dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Action
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                        style={{ width: 250, padding: 0 }}
                                      >
                                        <tr>
                                          <td colSpan={2}>
                                            <Link
                                              className="dropdown-item"
                                              to={
                                                `/therapist-details/` + item.id
                                              }
                                              style={{ paddingLeft: 0 }}
                                            >
                                              View Details
                                            </Link>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="w-100per">
                                            Onboard Permission
                                          </td>
                                          <td>
                                            <div class="custom-control custom-switch">
                                              <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id={`customSwitches${i}`}
                                                onChange={(e) =>
                                                  // console.log("handlePermission")
                                                  handleOnboardPrmission(
                                                    item,
                                                    "onboard"
                                                  )
                                                }
                                                checked={
                                                  item?.onboardPermission ==
                                                  "true"
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                class="custom-control-label"
                                                for={`customSwitches${i}`}
                                              ></label>
                                            </div>
                                          </td>
                                        </tr>
                                        {/* <a
                                        className="dropdown-item"
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          handleOnboardPrmission(
                                            item,
                                            "onboard"
                                          )
                                        }
                                      >
                                        {item?.onboardPermission == "true"
                                          ? "Deny Onboard Permission"
                                          : "Onboard Permission"}
                                          
                                      </a> */}
                                        {/* <a
                                        className="dropdown-item"
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          handleOnboardPrmission(item, "update")
                                        }
                                      >
                                        {item?.updatePermission == "true"
                                          ? "Deny Edit/Delete Permission"
                                          : "Edit/Delete Permission"}
                                      </a> */}
                                        <tr>
                                          <td>Edit/Delete Permission</td>
                                          <td>
                                            <div class="custom-control custom-switch">
                                              <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id={`customSwitches-${i}`}
                                                onChange={(e) =>
                                                  // console.log("handlePermission")
                                                  handleOnboardPrmission(
                                                    item,
                                                    "update"
                                                  )
                                                }
                                                checked={
                                                  item?.updatePermission ==
                                                  "true"
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                class="custom-control-label"
                                                for={`customSwitches-${i}`}
                                              ></label>
                                            </div>
                                          </td>
                                        </tr>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })
                        ) : (
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>
                                <div className="mt-5">
                                  <h5>No Therapist Available</h5>
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                    {loading ? (
                      <></>
                    ) : (
                      <>
                        {/* Pagination */}
                        <nav>
                          <ul className="pagination justify-content-center">
                            <li
                              onClick={() => paginatePre()}
                              className="page-item"
                            >
                              {currentPage <= 0 ? (
                                <></>
                              ) : (
                                <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                                  <img
                                    className="pr-2"
                                    src="../resources/assets/arrow-left.svg"
                                  />
                                </div>
                              )}
                            </li>
                            {pageNumbers.map((number) => (
                              <li
                                key={number}
                                onClick={() => paginate(number)}
                                className="page-item"
                              >
                                <div
                                  className={`${
                                    currentPage === number
                                      ? "pagination-active page-link pagination"
                                      : "page-link pagination cursor-pointer"
                                  }`}
                                >
                                  {number + 1}
                                </div>
                              </li>
                            ))}
                            <li
                              onClick={() => paginateNext()}
                              className="page-item"
                            >
                              {currentPage >= totalpages - 1 ? (
                                <></>
                              ) : (
                                <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                                  <img
                                    className="pr-2"
                                    src="../resources/assets/arrow-right.svg"
                                  />
                                </div>
                              )}
                            </li>
                          </ul>
                        </nav>
                        {/* End */}
                      </>
                    )}

                    {/* <Pagination totalPages={totalpages}/> */}
                  </div>

                  <div className="col-md-4 no-padding text-center therapist table-th-merge-gap bg-color1">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="bg-color1">
                          <tr>
                            {rowclickid !== null ? (
                              <td className="h-25">
                                {loadings ? (
                                  <div className="loader-small"></div>
                                ) : (
                                  <SessionsDetail
                                    therapysessions={therapysessions}
                                    successSub={() => successSub()}
                                    therapistid={rowclickid}
                                    bgColor="bg-color1"
                                    page="therapist"
                                  />
                                )}
                              </td>
                            ) : (
                              <td>
                                <div className="py-5">
                                  Click to select row to see the sessions
                                </div>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Therapist;
