import React from "react";
import Popup from "reactjs-popup";
import SignatureCanvas from "react-signature-canvas";

function index(props) {
  const { modalState, sigCanvas, save, clear, setModalState } = props;
  return (
    <>
      <Popup modal={modalState} open={modalState} closeOnDocumentClick={false}>
        {(close) => (
          <>
            <div className="col-md-12 popup-header">
              <h5>Digital Signature</h5>
            </div>
            <div className="col-md-12">
              <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{
                  className: "sigCanvas",
                }}
              />
            </div>
            <div className="col-md-12 popup-btn-container">
              <button className="btn btn-success" onClick={save}>
                Save
              </button>
              <button className="btn btn-primary" onClick={clear}>
                Clear
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setModalState(false)}
              >
                Close
              </button>
            </div>
          </>
        )}
      </Popup>
    </>
  );
}

export default index;
