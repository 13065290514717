import moment from "moment";
import React from "react";
import { IoMdCreate, IoIosEye } from "react-icons/io";

function FormTable(props) {
 
  const handleFormDetail = (data) => {
    console.log("Select Form ", data);
    console.log("props.inputs", props.inputs);
    console.log("data.form.id", data.form.id);
    props.setIsFormOpen(true);
    props.setFamily(data);
    props?.setInputs &&
      props.setInputs({ ...props.inputs, formId: data.form.id });
  };
  return (
    <>
      <div className="row therapist">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table">
              <thead style={{ width: "100%" }}>
                <tr>
                  <th scope="col">
                    <b>Form</b>
                  </th>
                  {props.userType !== "admin" && (
                    <th scope="col">
                      <b>Send By</b>
                    </th>
                  )}
                  <th scope="col">
                    <b>Caretaker/Client</b>
                  </th>
                  <th scope="col">
                    <b>Status</b>
                  </th>
                  <th scope="col">
                    <b>Created Date</b>
                  </th>
                  <th scope="col">
                    <b>Action</b>
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {props.list.length > 0 &&
                  props.list.map((data) => {
                    console.log("data", data);
                    return (
                      <>
                        <tr>
                          <td>{data.form.name}</td>
                          {props.userType !== "admin" && (
                            <td>
                              {data?.sentByUserRole === "ROLE_RCE" ? "Admin" : "Therapist"}/
                              {data.adminProfile.firstName +
                                " " +
                                data.adminProfile.lastName}
                            </td>
                          )}
                          <td>
                            {data.family.userProfile.firstName}/
                            {data.familyMember.firstName +
                              " " +
                              data.familyMember.lastName}
                          </td>
                          <td>
                            {console.log("data", data?.replyNeeded)}
                            {data?.replyNeeded
                              ? 
                              data.form.id == 3
                                ? "Reply not needed"
                                : 
                                data.reply
                                ? "Submitted"
                                : "Pending"
                              : "Reply not needed"}
                          </td>
                          <td>
                            {moment(data.createdDate).format("MM/DD/YYYY")}
                          </td>
                          <td>
                            {!data?.replyNeeded ? (
                              <button
                                // className="btn btn-lg btn-info form-view-button"
                                className="btn btn-theme-info btn-sm mb-10"
                                onClick={() => handleFormDetail(data)}
                              >
                                <IoIosEye />
                              </button>
                            ) : props.userType !== "admin" ? (
                              <button
                                // className={`btn btn-lg ${
                                //   data.form.id == 3 || data.reply
                                //     ? "btn-info"
                                //     : "btn-theme-green"
                                // } form-view-button`}
                                className="btn btn-theme-info btn-sm mb-10"
                                onClick={() => handleFormDetail(data)}
                              >
                                {data.form.id == 3 || data.reply ? (
                                  <IoIosEye />
                                ) : (
                                  <IoMdCreate />
                                )}
                              </button>
                            ) : data.form.id == 3 || data.reply ? (
                              <button
                                // className="btn btn-lg btn-info form-view-button"
                                className="btn btn-theme-info btn-sm mb-10"
                                onClick={() => handleFormDetail(data)}
                              >
                                <IoIosEye />
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormTable;