import {appTypes} from '../types';

const setAppLoading = (isLoading = false) => {
    return {
       type: appTypes.setAppLoader,
       payload: isLoading
    }
}
const setSoapNoteBillingInfo = (info) => {
    return {
       type: appTypes.setSoapNoteBillingInfo,
       payload: info
    }
}
const appActions = {
    setAppLoading,
    setSoapNoteBillingInfo,
}
export default appActions
