import React from 'react'

function CalenderDemo() {
    const getCalender = () =>{
        fetch("https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=6731de76-14a6-49ae-97bc-6eba6914391e&response_type=code&redirect_uri=http://localhost/myapp/&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&state=12345").then((res)=>console.log("calender res",res))
    }
  return (
    <div><button onClick={getCalender}>calenderDemo</button></div>
  )
}
export default CalenderDemo;