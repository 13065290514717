import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { adminService } from "../../../services/adminService";
import allActions from "../../../redux/actions";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { normalizeInput, sortLanguage } from "../../../utils/commonFunctions";
import appAction from "../../../redux/actions/appAction";
import InputFileBtn from "../../InputFileBtn";
import { nameRegex } from "../../../utils/regex";

function FamilyParentInformation(props) {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const saveNextRef = useRef(null);
  let params = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  let familyForm = useSelector((state) => state.adminUser.familyForm);
  let jsonFound = familyForm.hasOwnProperty("userProfileForm");
  const [loadings, setLoadings] = useState(false);
  const [relationship, setRelationship] = useState(
    familyForm.relationship ? familyForm.relationship : ""
  );
  const [language, setLanguage] = useState("");
  const [languageIds, setLanguageIds] = useState(
    jsonFound ? familyForm.languageIds : []
  );
  const [profileImage, setProfileImage] = useState("defaultProfile.jpg");
  const [familyid, setFamilyId] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [inputs, setInputs] = useState({
    firstName: jsonFound ? familyForm.userProfileForm.firstName : "",
    lastName: jsonFound ? familyForm.userProfileForm.lastName : "",
    email: jsonFound ? familyForm.userProfileForm.email : "",
    phone: jsonFound ? familyForm.userProfileForm.phone : "",
    tempselectLangId: jsonFound
      ? familyForm.userProfileForm.tempselectLangId
      : 0,
    gender: jsonFound ? familyForm.userProfileForm.gender : "",
    profileImage: jsonFound
      ? familyForm.userProfileForm.profileImage
      : profileImage,
    familyId: "",
    pendingOnboarding: false,
  });

  const [langClear, setLangClear] = useState({
    tempselectLangId: jsonFound
      ? familyForm.userProfileForm.tempselectLangId
      : 0,
  });

  useEffect(() => {
    if (profileImage === "") {
      let url = "../../resources/assets/defaultprofile.png";
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "defaultProfile.jpg", {
            type: blob.type,
          });
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            file.url = reader.result;
            file.isnew = true;
            setProfileImage(file);
            setInputs((inputs) => ({ ...inputs, profileImage: file }));
          };
        });
    }
  }, [profileImage]);

  useEffect(() => {
    if (params.id) {
      console.log("params id", params.id);
      setLoadings(true);
      adminService.getById(`families/` + params.id).then(
        (res) => {
          if (res.status === "Success") {
            dispatch(
              allActions.adminAction.setFamilyFormData(
                res.data.userProfile,
                "userProfileForm"
              )
            );
            dispatch(
              allActions.adminAction.setFamilyFormData(
                res.data.relationship,
                "relationship"
              )
            );
            setInputs(res.data.userProfile);
            setValue("firstName", res.data.userProfile.firstName);
            setValue("lastName", res.data.userProfile.lastName);
            setValue("email", res.data.userProfile.email);
            setValue("phone", res.data.userProfile.phone);
            setValue("relationship", res.data.relationship);
            let phone = res.data.userProfile && res.data.userProfile.phone;
            // res.data.userProfile.phone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
            setFamilyId(
              res.data.familyMembers && parseInt(res.data.familyMembers[0].id)
            );
            setRelationship(res.data.relationship);
            let id = [];
            res.data.languages &&
              res.data.languages.map((item) => {
                id.push(item.id);
              });
            setLanguageIds(id);
            setLoadings(false);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (params.uid) {
      console.log("params uid", params.uid);
      setLoadings(true);
      adminService.getById(`marketing-registrations/all/` + params.uid).then(
        (res) => {
          if (res.status === "Success") {
            dispatch(
              allActions.adminAction.setFamilyFormData(
                res.data,
                "userProfileForm"
              )
            );
            setInputs(res.data);
            let phone = res.data && res.data.phone;
            res.data.phone = `(${phone.slice(0, 3)}) ${phone.slice(
              3,
              6
            )}-${phone.slice(6, 10)}`;
            setLoadings(false);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, []);
  useEffect(() => {
    console.log("inputs", inputs, props?.familyData);
    if (props?.familyData) {
      console.log("props familydata", props?.familyData);
      setInputs({
        ...inputs,
        firstName: props?.familyData.userProfile.firstName,
        lastName: props?.familyData.userProfile.lastName,
        email: props?.familyData.userProfile.email,
        phone: props?.familyData.userProfile.phone,
        gender: props?.familyData.userProfile.gender,
        profileImage: props?.familyData.userProfile.profilePicUrl,
        familyId: props?.familyData.id,
      });
      setValue("firstName", props?.familyData.userProfile.firstName);
      setValue("lastName", props?.familyData.userProfile.lastName);
      setValue("email", props?.familyData.userProfile.email);
      setValue("phone", props?.familyData.userProfile.phone);
      setValue("relationship", props?.familyData.relationship);
      setRelationship(props?.familyData?.relationship);
      const langIds = [];
      props?.familyData?.languages &&
        props?.familyData?.languages.map((lang) => langIds.push(lang.id));
      setLanguageIds(langIds);
      console.log("langIds", langIds);
    }
  }, [props?.familyData]);
  const [isSaveBack, setIsSaveBack] = useState(false);
  const onSubmit = (e) => {
    // e.preventDefault();
    console.log("sSaveBack w", isSaveBack);
    if (languageIds.length == 0) {
      toast.warn("Please add at least 1 language");
    } else if (isSaveBack) {
      console.log("object............good");
      createFamily();
    } else {
      if (params.id) {
        let formDataPut = {
          userProfileUpdateForm: inputs,
          languageIds: languageIds,
          relationship: relationship,
        };

        let formData = new FormData();
        formData.append("profileImage", inputs.profileImage);
        delete inputs.profileImage;
        formData.append(
          "familyUpdateForm",
          new Blob([JSON.stringify(formDataPut)], {
            type: "application/json",
          })
        );
        dispatch(appAction.setAppLoading(true));
        adminService
          .update(`/families/` + params.id, formData)
          .then(
            (res) => {
              if (res.status == "Success") {
                toast.success(res.message);
                setTimeout(() => {
                  history("/child-detail/" + params.id + "/" + familyid);
                }, 1000);
              } else {
                toast.error(res.message);
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .finally(() => {
            dispatch(appAction.setAppLoading(false));
          });
      } else {
        // setLoadings(true);
        let phone_no = inputs.phone;
        if (phone_no === "") {
          toast.error("Phone number is required");
          setErrorPhone("Phone number is required");
          return;
        }

        /*if (phone_no && phone_no.length !== 14) {
                    setErrorPhone("Invalid phone format. ex: (555) 555-5555");
                    toast.error("Invalid phone format. ex: (555) 555-5555");
                    return;
                } else*/
        setErrorPhone("");
        let checkUserReq = {
          email: inputs.email,
          phone: inputs.phone.replace(/[^\d]/g, ""),
        };
        if (props?.familyData) {
          createFamily("next");
        } else {
          dispatch(appAction.setAppLoading(true));
          adminService
            .POST(`user-profiles/check-user`, JSON.stringify(checkUserReq))
            .then(
              (res) => {
                // setLoadings(false);
                if (res.status == "Success") {
                  if (res.data.userAlreadyExists) {
                    if (res.data.emailAlreadyExists)
                      toast.error("User email address already exists");
                    else if (res.data.phoneAlreadyExists)
                      toast.error("User phone number already exists");
                  } else {
                    dispatch(
                      allActions.adminAction.setFamilyFormData(
                        inputs,
                        "userProfileForm"
                      )
                    );
                    dispatch(
                      allActions.adminAction.setFamilyFormData(
                        relationship,
                        "relationship"
                      )
                    );
                    props?.setFamilyId(res.data.id);
                    props.handleNext();
                  }
                } else {
                  toast.error(res.message);
                }
              },
              (error) => {
                console.log(error);
              }
            )
            .finally(() => {
              dispatch(appAction.setAppLoading(false));
            });
        }
      }
    }
  };

  const createFamily = (isnext = "") => {
    const parentsForm = new FormData();
    parentsForm.append("profileImage", inputs.profileImage);
    const userBasicInfo = {
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      phone: inputs.phone,
      about: "",
      gender: inputs.gender,
    };
    if (props?.familyData) {
      parentsForm.append(
        "familyUpdateForm",
        new Blob(
          [
            JSON.stringify({
              languageIds: languageIds,
              relationship: relationship,
              userProfileUpdateForm: userBasicInfo,
              pendingOnboarding: true,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    } else {
      parentsForm.append(
        "familyForm",
        new Blob(
          [
            JSON.stringify({
              languageIds: languageIds,
              relationship: relationship,
              userProfileForm: userBasicInfo,
              pendingOnboarding: true,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }

    dispatch(appAction.setAppLoading(true));
    if (props?.familyData) {
      console.log("inputs", props?.familyData);
      updateOnboardFamily(props?.familyData.id, parentsForm, isnext);
    } else {
      onboardFamily(parentsForm, isnext);
    }
  };

  const updateOnboardFamily = (id, parentsForm, isnext) => {
    adminService
      .update(`/families/${id}`, parentsForm)
      .then(
        (res) => {
          console.log("object", res);
          if (res?.data?.id) {
            if (isnext) {
              // props?.handleNext();
              dispatch(
                allActions.adminAction.setFamilyFormData(
                  inputs,
                  "userProfileForm"
                )
              );
              dispatch(
                allActions.adminAction.setFamilyFormData(
                  relationship,
                  "relationship"
                )
              );
              props.handleNext();
            } else {
              history("/pending-users-onboarding");
            }
          } else {
            toast.error(res?.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        dispatch(appAction.setAppLoading(false));
      });
  };

  const onboardFamily = (parentsForm, isnext) => {
    adminService
      .onboardUser(`families`, parentsForm)
      .then(
        (res) => {
          if (res.status == "Success") {
          console.log("object", res);
          if (res?.data?.id) {
            history("/pending-users-onboarding");
          }
        }else{
          toast.error(res.message);
        }
        },
        (error) => {
          console.log("error msg",error);
        }
      )
      .finally(() => {
        dispatch(appAction.setAppLoading(false));
      });
  };

  const onSubmitSaveAndBack = (e) => {
    setIsSaveBack(true);
    console.log("sSaveBack..--", isSaveBack);
    if (languageIds.length == 0) {
      toast.warn("Please add at least 1 language");
    } else if (isSaveBack) {
      console.log("object............good");
      // createFamily();
      saveNextRef.current.click();
    }
    // saveNextRef.current.click();
  };

  const allowNumbersOnly = (event) => {
    if (event.keyCode >= 65 && event.keyCode <= 90) {
      event.preventDefault();
      return;
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "phone") {
      const currentValue = value.replace(/[^\d]/g, "");
      if (currentValue.length === 10) setErrorPhone("");
      // setInputs(inputs => ({ ...inputs, [name]: normalizeInput(value) }));
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else setInputs((inputs) => ({ ...inputs, [name]: value }));
    setLangClear((langClear) => ({ ...langClear, [name]: value }));
  }

  const addLanguage = () => {
    adminService
      .getAll(
        `interpreters?interpreterLanguages.language.id=${parseInt(
          inputs.tempselectLangId
        )}`
      )
      .then(
        (res) => {
          // if (res.data.content.length > 0) {
          if (inputs.tempselectLangId != 0) {
            let fileindex = languageIds.findIndex(
              (obj) => obj == inputs.tempselectLangId
            );
            if (fileindex == -1) {
              setLanguageIds([
                ...languageIds,
                parseInt(inputs.tempselectLangId),
              ]);
              //dispatch(allActions.adminAction.setFamilyFormData(languageIds,'languageIds'));
              setLangClear({
                tempselectLangId: 0,
              });
            }
            // else{
            //     toast.warning("Language is already added, please add another language!")
            // }
          } else {
            toast.warning("Please select a language!");
          }
          // } else {
          //     toast.warn("Interpreter not available for selected language, select other language");
          // }
          setLangClear({
            tempselectLangId: 0,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const removeLanguage = (id) => {
    setLanguageIds(languageIds.filter((item) => item !== id));
  };

  function getLanguageName(id) {
    return language.length > 0
      ? language.filter((lang) => lang.id === id)[0].languageName
      : "";
  }

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  useEffect(() => {
    setRelationship(relationship);
    dispatch(
      allActions.adminAction.setFamilyFormData(relationship, "relationship")
    );
  }, [relationship]);

  useEffect(() => {
    setLanguageIds(languageIds);
    dispatch(
      allActions.adminAction.setFamilyFormData(languageIds, "languageIds")
    );
  }, [languageIds]);

  const handleImageChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FileSize = e.target.files[0].size / 1024 / 1024; // in MB
    if (file.name.match(/.(jpg|jpeg|jpeg2000|png|svg)$/i)) {
      if (FileSize > 2) {
        toast.warn("Please upload file having size MAX 2MB");
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          file.url = reader.result;
          file.isnew = true;
          setProfileImage(file);
          setInputs((inputs) => ({ ...inputs, [name]: file }));
        };
      }
    } else {
      toast.warn("Please upload file having extensions - JPG,JPEG,PNG,SVG");
    }
  };

  useEffect(() => {
    adminService.getAll(`languages?size=1000`).then(
      (res) => {
        setLanguage(sortLanguage(res.data.content || []));
        if (familyForm.hasOwnProperty("languageIds"))
          setLanguageIds(familyForm.languageIds);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <>
      {loadings || props?.loading ? (
        <div className="loader"></div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addtheripist">
            <label className="conetent-title">Caretaker Information</label>

            <div className="row gutter-33">
              <div className="col-12">
                <div className="form-group">
                  <label>Profile Picture</label>{" "}
                  {/* (<span className="required-span">*</span>)*/}
                  {params.id || props?.familyData ? (
                    <span>
                      <div className="row">
                        <div className="col-md-4">
                          <img
                            src={
                              inputs?.profileImage?.url
                                ? inputs.profileImage.url
                                : inputs?.profileImage
                                  ? inputs.profileImage
                                  : familyForm.userProfileForm &&
                                    familyForm.userProfileForm.profilePicUrl
                                    ? familyForm.userProfileForm.profilePicUrl
                                    : "/resources/assets/defaultprofile.png"
                            }
                            alt=""
                            className="settingsimg"
                          />
                        </div>
                        <InputFileBtn
                          id="fileuplaod"
                          name="profileImage"
                          onChange={(e) => handleImageChange(e)}
                        />
                      </div>
                      <br />
                    </span>
                  ) : (
                    <label for="fileuplaod" className="fileuplaod">
                      <div className="content-box">
                        <img
                          src={
                            inputs.profileImage !== "defaultProfile.jpg"
                              ? inputs.profileImage.url
                              : "../resources/assets/file-upload-icon.svg"
                          }
                          alt="Family"
                        />
                        <div className="file-title">
                          Drop your image here, or <strong>browse</strong>{" "}
                        </div>
                        <div className="file-name">
                          Support- JPG,JPEG,PNG,SVG
                        </div>
                      </div>
                      <input
                        type="file"
                        id="fileuplaod"
                        name="profileImage"
                        onChange={(e) => handleImageChange(e)}
                      //{...register({ required: inputs.profileImage ? false : true })}
                      />
                    </label>
                  )}
                  {errors.profileImage &&
                    errors.profileImage.type === "required" && (
                      <div className="invalid-feedback-custom">
                        Please upload profile picture
                      </div>
                    )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>First Name</label> (
                  <span className="required-span">*</span>)
                  <input
                    // defaultValue={inputs.firstName}
                    defaultValue={inputs.firstName}
                    disabled={params.uid ? true : false}
                    name="firstName"
                    //onChange={handleChange}
                    type="text"
                    className={`form-control ${errors.firstName && "is-invalid"
                      }`}
                    placeholder="Enter First Name"
                    {...register('firstName', {
                      onChange: (e) => handleChange(e),
                      required: true,
                      maxLength: 30,
                      pattern: nameRegex,
                    })}
                    autoComplete="off"
                  />
                  {errors.firstName && errors.firstName.type === "required" && (
                    <div className="invalid-feedback-custom">
                      First name is required
                    </div>
                  )}
                  {errors.firstName && errors.firstName.type === "pattern" && (
                    <div className="invalid-feedback-custom">
                      Alphabetical characters only
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Last Name</label> (
                  <span className="required-span">*</span>)
                  <input
                    {...register('lastName', {
                      onChange: (e) => handleChange(e),
                      required: true,
                      maxLength: 30,
                      pattern: nameRegex,
                    })}
                    // defaultValue={inputs.lastName}
                    defaultValue={inputs.lastName}
                    disabled={params.uid ? true : false}
                    name="lastName"
                    type="text"
                    //onChange={handleChange}
                    className={`form-control ${errors.lastName && "is-invalid"
                      }`}
                    placeholder="Enter Last Name"
                  />
                  {errors.lastName && errors.lastName.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Last name is required
                    </div>
                  )}
                  {errors.lastName && errors.lastName.type === "pattern" && (
                    <div className="invalid-feedback-custom">
                      Alphabetical characters only
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Relation</label> (
                  <span className="required-span">*</span>)
                  <div className="input-group ">
                    <select
                      {...register('relationship', { onChange: (e) => setRelationship(e.target.value), required: true })}
                      className={`custom-select ${errors.relationship && "is-invalid"
                        }`}
                      //onChange={(e) => setRelationship(e.target.value)}
                      defaultValue={relationship}
                      //defaultValue={relationship}
                      name="relationship"
                    >
                      <option value="">Select Relation</option>
                      <option value="MOTHER">Mother</option>
                      <option value="FATHER">Father</option>
                    </select>
                  </div>
                  {errors.relationship &&
                    errors.relationship.type === "required" && (
                      <div className="invalid-feedback-custom">
                        Relation is required
                      </div>
                    )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Gender</label> (
                  <span className="required-span">*</span>)<br />
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      {...register('gender', { onChange: (e) => handleChange(e), required: true })}
                      //onChange={handleChange}
                      type="radio"
                      id="customRadio1"
                      value="MALE"
                      checked={inputs.gender === "MALE" ? "checked" : ""}
                      name="gender"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="customRadio1">
                      Male
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      {...register('gender', { onChange: (e) => handleChange(e), required: true })}
                      //onChange={handleChange}
                      type="radio"
                      id="customRadio2"
                      value="FEMALE"
                      checked={inputs.gender === "FEMALE" ? "checked" : ""}
                      name="gender"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="customRadio2">
                      Female
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      {...register('gender', { onChange: (e) => handleChange(e), required: true })}
                      //onChange={handleChange}
                      type="radio"
                      id="customRadio3"
                      value="NOT_DISCLOSED"
                      checked={
                        inputs.gender === "NOT_DISCLOSED" ? "checked" : ""
                      }
                      name="gender"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="customRadio3">
                      Other
                    </label>
                  </div>
                  {errors.gender && errors.gender.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Gender is required
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Email Address</label> (
                  <span className="required-span">*</span>)
                  <input
                    {...register('email', {
                      onChange: (e) => handleChange(e),
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    // defaultValue={inputs.email}
                    defaultValue={inputs.email}
                    disabled={params.uid ? true : false}
                    //disabled={params.uid ? true : params.id ? true : false}
                    name="email"
                    type="email"
                    //onChange={handleChange}
                    className={`form-control ${errors.email && "is-invalid"}`}
                    placeholder="Enter Email Address"
                  />
                  {errors.email?.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Email address is required
                    </div>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <div className="invalid-feedback-custom">
                      Please enter valid email address
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Phone Number</label> (
                  <span className="required-span">*</span>)
                  <input
                    {...register('phone', { onChange: (e) => handleChange(e), required: true, pattern: /^\d{9,10}$/ })}
                    //defaultValue={inputs.phone}
                    defaultValue={inputs.phone}
                    disabled={params.uid ? true : false}
                    //disabled={params.uid ? true : params.id ? true : false}
                    name="phone"
                    type="text"
                    // onChange={handleChange}
                    onKeyDown={(e) => allowNumbersOnly(e)}
                    className={`form-control ${errors.phone && "is-invalid"}`}
                    placeholder="Enter Phone Number"
                    maxlength="10"
                  />
                  {errors.phone?.type === "required" && (
                    <div className="invalid-feedback-custom">
                      Phone number is required
                    </div>
                  )}
                  {errorPhone ? (
                    <div className="invalid-feedback-custom">
                      Please enter 10 digit phone number
                    </div>
                  ) : (
                    ""
                  )}
                  {errors.phone && errors.phone.type === "pattern" && (
                    <div className="invalid-feedback-custom">
                      Please enter valid phone number
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Languages</label> (
                  <span className="required-span">*</span>)
                  <div className="input-group">
                    <select
                      {...register('tempselectLangId', {
                        onChange: (e) => handleChange(e),
                        required: languageIds.length === 0 ? true : false,
                      })}
                      className={`custom-select ${errors.tempselectLangId && "is-invalid"
                        }`}
                      //onChange={handleChange}
                      defaultValue={langClear.tempselectLangId}
                      value={langClear.tempselectLangId}
                      // defaultValue={inputs.tempselectLangId}
                      name="tempselectLangId"
                    >
                      <option value="">Select Language</option>
                      {language &&
                        language.map((item, index) => (
                          <option value={item.id}>{item.languageName}</option>
                        ))}
                    </select>
                  </div>
                  {errors.tempselectLangId &&
                    errors.tempselectLangId.type === "required" && (
                      <div className="invalid-feedback-custom">
                        Select Language
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md-3 align-self-end">
                <div className="form-group">
                  <button
                    type="button"
                    onClick={() => addLanguage()}
                    className="btn btn-primary add-button cursor-pointer"
                    disabled={langClear.tempselectLangId === '' || langClear.tempselectLangId === 0 ? true : false}
                  >
                    {/* <img src='../../resources/assets/Plus-Button-Icon-Round.svg' /> */}
                    <img
                      className="pr-2"
                      src="../resources/assets/plus-icon-button.svg"
                      alt=""
                    />
                    Add Language
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <table className="table custom-table">
                  <tbody>
                    {languageIds &&
                      languageIds.map((lang, index) => (
                        <tr className="bg-common-gray">
                          <td className="border-top-0 lang">
                            {getLanguageName(lang)}
                          </td>
                          {/* <td className="border-top-0 cursor-pointer text-right" onClick={()=>removeLanguage(lang)}><img src="../resources/assets/action-icon-delete.svg"/></td> */}
                          <td className="border-top-0 text-right">
                            <img
                              src="../resources/assets/action-icon-delete.svg"
                              onClick={() => removeLanguage(lang)}
                              className="cursor-pointer"
                            />
                          </td>
                        </tr>
                      ))}
                    {languageIds.length == 0 && (
                      <tr className="text-center">
                        <td colSpan="2">
                          <strong>No data found</strong>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row addtheripist">
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12 d-flex">
              {!params.id && (
                <button
                  className="btn  btn-primary d-block stepper-button-next"
                  id="save-and-back"
                  onClick={(e) => onSubmitSaveAndBack(e)}
                >
                  Save & Return
                </button>
              )}
              <button
                className="btn  btn-primary d-block stepper-button-next"
                type="submit"
                {...saveNextRef}
              >
                {params.id ? "Submit" : "Save & Continue"}
              </button>
              {loadings ? <div className="loader-small"></div> : ""}
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default FamilyParentInformation;
