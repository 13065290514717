import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Switch, Link, useLocation } from "react-router-dom";
//import { useAlert,positions } from 'react-alert';

import TherapistPersonalInformation from "../Representative/OnBoardingUser/TherapistPersonalInformation";
import TherapistServiceInformation from "../Representative/OnBoardingUser/TherapistServiceInformation";
import TherapistSetAvailability from "../Representative/OnBoardingUser/TherapistSetAvailability";

import Stepper from "../../components/Stepper";

function AddTherapist() {
  //const alert = useAlert();
  let loading = false;
  const history = useNavigate();
  //const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState("");
  //const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
  const steps = getSteps();
  const [therapistId, setTherapistId] = useState(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    return ["Personal Information", "Service Information", "Set Availability"];
  }

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <TherapistPersonalInformation
            therapistId={therapistId}
            setTherapistId={setTherapistId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      case 1:
        return (
          <TherapistServiceInformation
            therapistId={therapistId}
            setTherapistId={setTherapistId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      case 2:
        return (
          <TherapistSetAvailability
            therapistId={therapistId}
            setTherapistId={setTherapistId}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    setActiveStep(0);
  }, []);

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Add Therapist</div>
            </div>
            <Stepper activeStep={activeStep} steps={getSteps()} />
            {getStepContent(activeStep)}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTherapist;
