import React, { useState } from "react";
import { useParams } from "react-router-dom";

import HippaNoticeForm from "../../components/forms/admin/HippaNoticeForm";
import MediaReleaseForm from "../../components/forms/admin/MediaReleaseForm";

import MedicalInsuranceForm from "../../components/forms/admin/MedicalInsuranceForm";
import CancellationForm from "../../components/forms/admin/CancellationForm";
import ReleaseToCommunicateForm from "../../components/forms/admin/ReleaseToCommunicateForm";
import ConsentToTreat from "./PDFs/ConsentToTreat";

import MediaReleaseForm2 from "./FormInputs/MediaReleaseForm";
import Acknowledgement from "./PDFs/NewPDFs/Acknowledgement";
import DischargeSummary from "./PDFs/EarlySteps/DischargeSummary";
import ConsultationDocumentationForm from "./PDFs/EarlySteps/ConsultationDocumentationForm";

function FormSetups(props) {
  const params = useParams();
  const [form, setForm] = useState(props?.location?.state || null);
  console.log("setForm ", form);

  const [inputs, setinputs] = useState();

  const switchForm = () => {
    switch (parseInt(form.formId)) {
      case 1:
        return (
          <ConsentToTreat
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;
      case 2:
        return (
          <>
            <MedicalInsuranceForm
              from="admin"
              data={form.data}
              handleBack={false}
              isFormAssigned={params?.id ? true : false}
            />
            <Acknowledgement from="admin" data={form} />
          </>
        );
        break;
      case 3:
        return (
          <HippaNoticeForm
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;
      case 4:
        return (
          <CancellationForm
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;
      case 5:
        return (
          <ReleaseToCommunicateForm
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;
      case 6:
        return (
          <MediaReleaseForm
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;

      case 7:
        return (
          <DischargeSummary
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;

      case 8:
        return (
          <ConsultationDocumentationForm
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;

      case 9:
        return (
          <DischargeSummary
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;

      case 10:
        return (
          <Acknowledgement
            from="admin"
            data={form.data}
            handleBack={false}
            isFormAssigned={params?.id ? true : false}
          />
        );
        break;

      default:
        return (
          <div className="col-12 p-5">
            <p className="text-center">Sorry no form available.</p>
          </div>
        );
        break;
    }
  };

  return (
    <>
      <div className="col-md-10">
        <div className="cardBox">{switchForm()}</div>
      </div>
    </>
  );
}

export default FormSetups;
