import { agoraTypes } from "../types";

const initialState = {
  currentSession: null,
  users: [],
  screens: [],
  isRecording: false,
  controls: {
    screen: false,
    emoji: false,
  },
  volumeIndicators: [],
};

const userJoined = (user, list) => {
  if (list.some((usr) => usr.agoraId === user?.agoraId)) {
    return list.map((currentUsr) => {
      if (currentUsr.agoraId === user.agoraId) {
        return {
          ...currentUsr,
          ...user,
        };
      }
      return currentUsr;
    });
  }
  return [...list, user];
};

const userLeave = (user, list) => {
  const uid =
    typeof user === "string" || typeof user === "number" ? user : user?.id;
  return list.filter((usr) => {
    if (usr.agoraId && user?.agoraId) {
      return usr.agoraId !== user?.agoraId;
    }
    return usr.id !== uid;
  });
};

const screenShare = (payload, screens) => {
  const { isEnabled, user } = payload;
  console.log("isEnabled....", payload);
  if (isEnabled) {
    const uid =
      typeof user === "string" || typeof user === "number" ? user : user?.id;
    // return [uid, ...(screens || [])];
    return [uid];
  } else {
    // return (screens || [])?.filter((id) => id !== uid);
    return [];
  }
};

const addAgoraVolumeIndicator = (indicators, initiallist) => {
  return [...initiallist, indicators];
};

const agora = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case agoraTypes.setSession:
      return {
        ...state,
        currentSession: payload,
      };
    case agoraTypes.clear:
      return initialState;

    case agoraTypes.userJoined:
      return {
        ...state,
        users: userJoined(payload, state.users),
      };

    case agoraTypes.userLeave:
      return {
        ...state,
        users: userLeave(payload, state.users),
      };

    case agoraTypes.screenShare:
      return {
        ...state,
        screens: screenShare(payload, state.screens),
      };

    case agoraTypes.agoraRecord:
      return {
        ...state,
        isRecording: payload,
      };
    case agoraTypes.agoraControl: {
      return {
        ...state,
        controls: { ...state.controls, ...payload },
      };
    }

    case agoraTypes.volumeIndicator:
      return {
        ...state,
        volumeIndicators: payload,
      };
    default:
      return state;
  }
};

export default agora;
