import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../admin/CommonComponents";
import SignDate from "./SignDate";
import BasicInfo from "../therapist/dischargeSummery/BasicInfo";
import PlanAndEvolution from "../therapist/dischargeSummery/PlanAndEvolution";
import MedicalNecessity from "../therapist/dischargeSummery/MedicalNecessity";
import OtherDescription from "../therapist/dischargeSummery/OtherDescription";
import DischargSummryGoals from "../therapist/dischargeSummery/DischargSummryGoals";
import MedicalTable from "../therapist/dischargeSummery/MedicalTable";
import DynamicGoals from "../therapist/dischargeSummery/DynamicGoals";
import PocDescription from "../therapist/dischargeSummery/PocDescription";
import produce from "immer";
import DatePicker from "react-multi-date-picker";
import MedicalNecessityPt from "../therapist/dischargeSummery/MedicalNecessityPt";

function PocPtForm(props) {
  const profile = useSelector((state) => state.adminUser.profile);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const formRef = React.useRef(null);
  const sigCanvas = useRef({});
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [familyInfo, setFamilyInfo] = useState({});
  const [prevLongGoals, setprevLongGoals] = useState([{}]);
  const [prevShortGoals, setprevShortGoals] = useState([{}]);
  const [recipRadio, setRecipRadio] = useState('');
  const [newLongGoals, setnewLongGoals] = useState([{}]);
  const [newShotGoals, setnewShotGoals] = useState([{}]);
  const [tableValues, setTableValues] = useState([
    { id: "", description: "", criteria: "" },
  ]);
  const [newValues, setNewValues] = useState([
    { id: "", description: "", criteria: "" },
  ]);
  const [isSubmit, setIsSubmit] = useState(
    typeof props?.data?.reply === "undefined" ? false : true
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  console.log("selectedFamily", props?.selectedFamily);
  const [PocPt, setPocPt] = useState({});

  const [pageResult, setpageResult] = useState({
    adminSign: "",
    adminSignDate: "",
    reply: props.inputs?.reply ? props.inputs.reply : true,
    replyNeeded: false,
    sentBy: props.inputs?.sentBy ? props.inputs.sentBy : "",
    sentTo: props.inputs?.sentTo ? props.inputs.sentTo : "",
    therapistId: profile?.therapistId ? profile.therapistId : null,
    witnessSign: "",
    witnessSignDate: "",
    consent1: true,
    consent2: true,
    consent3: true,
    consent4: true,
    consent5: true,
    formId: props.inputs?.formId ? props.inputs.formId : null,
    familyId: props?.inputs?.familyId ? props?.inputs?.familyId : null,
    familyMemberId: props?.inputs?.familyMemberId ? props?.inputs?.familyMemberId : null,
    familyMemberName: "child one",
    familySign: "",
    familySignDate: "",
    interpreterId: null,
    interpreterSign: "",
    interpreterSignDate: "",
  });

  useEffect(() => {
    setPocPt({
      step1: {

        patientName:
          props?.selectedFamily?.firstName +
          " " +
          props?.selectedFamily?.lastName,
        chronologicalAge: props?.selectedFamily?.age,
        insuranceId: "",
        dob: props?.selectedFamily?.dob
          ? moment(props?.selectedFamily?.dob).format("YYYY-MM-DD") +
          `T00:00:00.000Z`
          : "",
        therapistName: profile?.firstName
          ? profile.firstName + " " + profile.lastName
          : "",
        therapistNpi: "",
        placeOfService: "",
        drNameNpi: props?.selectedFamily?.physicianNpiNumber,
        diagnosisICD10: props?.selectedFamily?.familyMemberTherapies
          ? props?.selectedFamily?.familyMemberTherapies[0]?.diagnosis[0]?.code
          : "",
        therapyDiagnosisICD10: "",
        currentPOCFromDate: "",
        currentPOCToDate: "",
        currentEvaluationDate: "",
        previouPOCFromDate: "",
        previouPOCToDate: "",
        previousEvaluationDate: "",
        weeklyVisit: "",
        visitLength: "",
        months: "",
        weeks: "",
      },
      step2: {
        medicalNecessityCriteria: [],
        medicalNecessityCriteriaDefault: [
          { 
            id:"1",
            description:"Necessary to protect life, to prevent significant illness or significant disability, or to alleviate severe pain.",
            criteria:""
          },
          { 
            id:"2",
            description:"Individualized, specific, and consistent with symptoms or confirmed diagnosis of the illness or injury under treatment, and not in excess of the patient's needs.",
            criteria:""
          },
          { 
            id:"3",
            description:"Consistent with generally accepted professional medical standards as determined by the Medicaid program, not experimental or investigational.",
            criteria:""
          },
          { 
            id:"4",
            description:"Reflective of the level of service that can be safely furnished, and for which no equally effective and more conservative or less costly treatment is available statewide.",
            criteria:""
          },
          { 
            id:"5",
            description:"Furnished in a manner not primarily intended for the convenience of the recipient, the recipient's caretaker, or the provider.",
            criteria:""
          }
        ],
        specificICD10: "",
        allTreatmentServices: "",
        therapyMethod: "",
        monitoringCriteria: "",
        currentMedication: "",
      },
      step3: {
        otherTreatment: "",
        equipmentRequired: "",
        specializedDiet: {
          option: "",
          description: "",
        },
        methodDemonstrating: "",
        treatment: "",
        otherInfo: "",
        recipRadio:"",
      },
      step4: {
        previousGoals: {
          longTerm: [],
          shortTerm: [],
        },
        newGoals: {
          longTerm: [],
          shortTerm: [],
        },
        patientName: "",
        therapistName: "",
        therapistSign: "",
        therapistSignDate: "",
      },

    });
  }, [props?.data]);
  
  const patientName = PocPt.step1?.patientName;
  const therapistName = PocPt.step1?.therapistName;
  const chronologicalAge = PocPt.step1?.chronologicalAge;
  const dob = PocPt.step1?.dob;
  const therapistNpi = PocPt.step1?.therapistNpi;
  console.log("pocpt", props);
  const updateInfo = (e) => {
    const { name, value } = e.target;
    setPocPt(
      produce((draftState) => {
        draftState.step3[name] = value;
      })
    );
  };
  const startInfo = (e) => {
    const { name, value } = e.target;
    setPocPt(
      produce((draftState) => {
        draftState.step2[name] = value;
      })
    );
  };
  const patientInfo = (e) => {
    const { name, value } = e.target;
    setPocPt(
      produce((draftState) => {
        draftState.step4[name] = value;
      })
    );
  };
  const specializedInfo = (e) => {
    const { name, value } = e.target;
    setPocPt(
      produce((draftState) => {
        draftState.step3.specializedDiet[name] = value;
      })
    );
  };

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setpageResult({
      ...pageResult,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setPocPt(
      produce((draftState) => {
        draftState.step4["therapistSignDate"] = moment(currentDateTime)
          .local()
          .format("MM-DD-YYYY hh:mm a");
      })
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setpageResult({
              ...pageResult,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setPocPt(
              produce((draftState) => {
                draftState.step4["therapistSign"] = res.data.signatureKey;
                draftState.step4["therapistSignDate"] = moment(currentDateTime)
                  .local()
                  .format("MM-DD-YYYY hh:mm a");
              })
            );
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "POC-PT.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const deleteRow = (i) => {
    //var row = document.getElementById(criteriaOt);
    //row.parentNode.removeChild(row);
    const arr = [...PocPt.step2.medicalNecessityCriteriaDefault];
    //document.getElementById("otLongPrev" + i).value = {};
    arr.splice(i,1);
    setPocPt(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteriaDefault = arr;
      })
    );
  }
  const removePrevLongGoals = (i) => {
    document.getElementById("nameLongPoc" + i).value = '';
    const arr1 = [...prevLongGoals];
    arr1.splice(i, 1);
    setprevLongGoals(arr1);
  };
  const removePrevShortGoals = (i) => {
    document.getElementById("nameShortPoc" + i).value = '';
    const arr = [...prevShortGoals];
    arr.splice(i, 1);
    setprevShortGoals(arr);
  };
  const removeNewLongGoals = (i, e) => {
    document.getElementById("longNewPoc" + i).value = '';
    const arr = [...newLongGoals];
    arr.splice(i, 1);
    setnewLongGoals(arr);
  };
  const removeNewShotGoals = (i) => {
    document.getElementById("namePocShort" + i).value = '';
    const arr = [...newShotGoals];
    arr.splice(i, 1);
    setnewShotGoals(arr);
  };
  const removeTableFields = (i) => {
    document.getElementById("medicalDec" + i).value = '';
    document.getElementById("medicalCriteria" + i).value = '';
    const arr = [...tableValues];
    arr.splice(i, 1);
    setTableValues(arr);
  };
  const handlePrevLongGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr1 = [...prevLongGoals];
    newArr1[i] = {
      ...newArr1[i],
      [name]: value,
    };
    setprevLongGoals(newArr1);
    setPocPt(
      produce((draftState) => {
        draftState.step4.previousGoals.longTerm = newArr1;
      })
    );
  };

  const handlePrevShortGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...prevShortGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setprevShortGoals(newArr);
    setPocPt(
      produce((draftState) => {
        draftState.step4.previousGoals.shortTerm = newArr;
      })
    );
  };
  const handleNewLongGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...newLongGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setnewLongGoals(newArr);
    setPocPt(
      produce((draftState) => {
        draftState.step4.newGoals.longTerm = newArr;
      })
    );
  };
  const handleNewShortGoals = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...newShotGoals];
    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    setnewShotGoals(newArr);
    setPocPt(
      produce((draftState) => {
        draftState.step4.newGoals.shortTerm = newArr;
      })
    );
  };
  const handleTabFields = (i, e) => {
    const { name, value } = e.target;
    let newArr = [...tableValues];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      [name]: value,
    };
    setTableValues(newArr);
    setPocPt(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteria = newArr;
      })
    );
  };

  const addPrevLongGoals = () => {
    setprevLongGoals([...prevLongGoals, { name: "" }]);
  };
  const addPrevShortGoals = () => {
    setprevShortGoals([...prevShortGoals, { name: "" }]);
  };
  const addTableFields = () => {
    setTableValues([...tableValues, { description: "", criteria: "" }]);
  };
  const addNewLongGoals = () => {
    setnewLongGoals([...newLongGoals, { name: "" }]);
  };
  const addNewShotGoals = () => {
    setnewShotGoals([...newShotGoals, { name: "" }]);
  };
  const updateBoxInfo = (e, indx) => {
    const { name, value } = e.target;
    const newArray = [...PocPt.step2.therapyType];
    newArray[indx] = {
      ...newArray[indx],
      isChecked: !newArray[indx].isChecked,
    };

    setPocPt(
      produce((draftState) => {
        draftState.step2.therapyType = newArray;
      })
    );
  };
  const changeInfo = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setPocPt(
      produce((draftState) => {
        draftState.step1[name] = value;
      })
    );
  };
  const setDate = (e) => {
    setPocPt(
      produce((draftState) => {
        draftState.step1["dob"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const fillCriteria=(e,i)=>{
    console.log("fillCriteria",e.target.name,e.target.value);
    const { name, value } = e.target;
    let newArr = [...PocPt.step2.medicalNecessityCriteriaDefault];
    let val = [
      "Necessary to protect life, to prevent significant illness or significant disability, or to alleviate severe pain.",
      "Individualized, specific, and consistent with symptoms or confirmed diagnosis of the illness or injury under treatment, and not in excess of the patients needs.",
      "Consistent with generally accepted professional medical standards as determined by the Medicaid program, not experimental or investigational.",
      "Reflective of the level of service that can be safely furnished, and for which no equally effective and more conservative or less costly treatment is available statewide.	",
      "Furnished in a manner not primarily intended for the convenience of the recipient, the recipient's caretaker, or the provider.",
    ];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      ["description"]: val[i],
      [name]: value,
    };
    //setTableValues(newArr);
    setPocPt(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteriaDefault = newArr;
      })
    );
  }
  
  const handlesimpleFields = (e, i, desc = "") => {

    const { name, value } = e.target;
    let newArr = [...newValues];
    let diffArr = [...tableValues];
    let val = [
      "Necessary to protect life, to prevent significant illness or significant disability, or to alleviate severe pain.",
      "Individualized, specific, and consistent with symptoms or confirmed diagnosis of the illness or injury under treatment, and not in excess of the patients needs.",
      "Consistent with generally accepted professional medical standards as determined by the Medicaid program, not experimental or investigational.",
      "Reflective of the level of service that can be safely furnished, and for which no equally effective and more conservative or less costly treatment is available statewide.	",
      "Furnished in a manner not primarily intended for the convenience of the recipient, the recipient's caretaker, or the provider.",
    ];
    if (desc) {
      diffArr[i] = {
        ...diffArr[i],
        ["id"]: i + 1,
        [name]: value,
      };
    } else {
      newArr[i] = {
        ...newArr[i],
        ["description"]: val[i],
        ["id"]: i + 1,
        [name]: value,
      };
    }
    console.log("newArr,diffArr",newArr,diffArr);
    setTableValues(diffArr);
    setNewValues(newArr);
    setPocPt(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteria = [...newArr, ...diffArr];
      })
    );
  };
  const radioVal = (e) => {
    console.log(e.target.value);
    setRecipRadio(e.target.value);
    setPocPt(
      produce((draftState) => {
        draftState.step3["recipRadio"] =
        e.target.value
      })
    );
    
  }
  const careFrom = (e) => {
    setPocPt(
      produce((draftState) => {
        draftState.step1["currentPOCFromDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const careTo = (e) => {
    setPocPt(
      produce((draftState) => {
        draftState.step1["currentPOCToDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const previouFrom = (e) => {
    setPocPt(
      produce((draftState) => {
        draftState.step1["previouPOCFromDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const previouTo = (e) => {
    setPocPt(
      produce((draftState) => {
        draftState.step1["previouPOCToDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const evalFrom = (e) => {
    setPocPt(
      produce((draftState) => {
        draftState.step1["currentEvaluationDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const evalTo = (e) => {
    setPocPt(
      produce((draftState) => {
        draftState.step1["previousEvaluationDate"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };

  // var b = moment(PocPt?.step1?.dob).format('YYYY-MM-DD');
  // let dt = b
  // let age = '';

  // let bY = Number(moment(dt).format('YYYY'));
  // let bM = Number(moment(dt).format('MM'));
  // let bD = Number(moment(dt).format('DD'));

  // let tY = Number(moment().format('YYYY'));
  // let tM = Number(moment().format('MM'));
  // let tD = Number(moment().format('DD'));

  // age += (tY - bY) + ' Years ';

  // if (tM < bM) {
  //     age += (tM - bM + 12) + ' Months ';
  //     tY = tY - 1;
  // } else {
  //     age += (tM - bM) + ' Months '
  // }

  // if (tD < bD) {
  //     age += (tD - bD + 30) + ' Days ';
  //     tM = tM - 1;
  // } else {
  //     age += (tD - bD) + ' Days '
  // }
  // {PocPt?.step1?.dob && setPocPt(
  //   produce((draftState) => {
  //     draftState.step1["chronologicalAge"] = age;
  //   })
  //     );}
  const onSubmitFamilyForm = () => {
    setIsLoading(true);
    setPocPt(
      produce((draftState) => {
        draftState.step2.medicalNecessityCriteria = [tableValues];
      })
    );
    setPocPt(
      produce((draftState) => {
        draftState.step4.previousGoals.shortTerm = [prevShortGoals];
      })
    );
    setPocPt(
      produce((draftState) => {
        draftState.step4.previousGoals.longTerm = [prevLongGoals];
      })
    );
    setPocPt(
      produce((draftState) => {
        draftState.step4.newGoals.shortTerm = [newShotGoals];
      })
    );
    setPocPt(
      produce((draftState) => {
        draftState.step4.newGoals.longTerm = [newLongGoals];
      })
    );

    console.log("........................discharge", PocPt);
    let formData = new FormData();
    console.log();
    formData.append(
      "formCreationForm",
      new Blob(
        [
          JSON.stringify({
            ...pageResult,
            jsonFormattedForm: JSON.stringify({ PocPt }),
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((response) => {
        setIsLoading(false);
        setOpen(false);
        toast.success("Form Submitted successfully");
        props.handleBack("reload");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };
  const relValue =
    typeof props?.data?.reply === "undefined"
      ? ""
      : JSON.parse(props?.data?.jsonFormattedForm);

  return (
    <>
      <>
        <div
          className={`col-md-${props?.from ? 12 : 10}`}
          id="my-code"
          ref={formRef}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: "100px",
            }}
          >
            {!props?.data?.replyNeeded && props?.data?.id ? (
              <>
                <HeaderDownloadButtons
                  isSubmit={true}
                  startDownload={startDownload}
                  setIsSubmit={setIsSubmit}
                />
              </>
            ) : (
              props?.data?.therapistSignDate && (
                <HeaderDownloadButtons
                  isSubmit={isSubmit}
                  startDownload={startDownload}
                  setIsSubmit={setIsSubmit}
                />
              )
            )}</div>
          <div className="card-lg">
            <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
              <FormHeader
                logoUrl={logoUrl}
                title="Plan of Care – Physical Therapy
"
              />

              <hr />
              <br />

              <div className="card-lg__content">
                <br />
                <div style={{ width: "100%", border: "1px solid" }}>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Patient Name : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.patientName
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder="Patient Name"
                          name="patientName"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={patientName}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Insurance ID : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.insuranceId
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          name="insuranceId"
                          placeholder="Insurance ID"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocPt?.step1?.insuranceId}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Chronological Age : </b>
                        {isSubmit ? (
                          relValue?.PocPt?.step1?.chronologicalAge + " Years"
                        ) : (
                          PocPt?.step1?.chronologicalAge + " Years"
                        )
                        }
                      </label>

                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>DOB : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.dob?.trim() &&
                        moment(PocPt.dob).format("MM/DD/YYYY")
                      ) : (
                        <div className="form-date-input">
                          <DatePicker
                            multiple={false}
                            value={dob}
                            onChange={setDate}
                            name="dob"
                            format="MM-DD-YYYY"
                            editable={false}
                          />
                        </div>

                      )}
                      {/* Math.floor(moment(new Date()).diff(moment("02/26/1978","MM/DD/YYYY"),'years',true))) */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Therapist Name : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.therapistName
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder="Therapist's name"
                          name="therapistName"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={therapistName}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Therapist NPI : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.therapistNpi
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          name="therapistNpi"
                          placeholder="Therapist NPI"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={therapistNpi}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Place of Service : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.placeOfService
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder="Place of Service"
                          name="placeOfService"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocPt?.step1?.placeOfService}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Dr. Name & NPI : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.drNameNpi
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          name="drNameNpi"
                          placeholder="Dr. Name & NPI"
                          className={`form-control form-input form-input-w-50per `}
                          defaultValue={PocPt?.step1?.drNameNpi}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid",
                    borderTop: "none",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                      borderRight: "1px solid",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Diagnosis ICD 10 : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.diagnosisICD10
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          placeholder="Diagnosis ICD 10"
                          name="diagnosisICD10"
                          className={`form-control form-input form-input-w-50per`}
                          defaultValue={PocPt?.step1?.diagnosisICD10}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      width: "50%",
                      display: "inline-block",
                    }}
                  >
                    <div style={{ width: "90%", paddingTop: 7 }}>
                      <label style={{ width: "auto" }}>
                        <b>Therapist Diagnosis ICD 10 : </b>
                      </label>
                      &nbsp;&nbsp;
                      {isSubmit ? (
                        relValue?.PocPt?.step1?.therapyDiagnosisICD10
                      ) : (
                        <input
                          // ref={register({ required: true })}
                          type="text"
                          name="therapyDiagnosisICD10"
                          placeholder="Therapist Diagnosis ICD 10"
                          className={`form-control form-input form-input-w-50per`}
                          defaultValue={PocPt?.step1?.therapyDiagnosisICD10}
                          onChange={(e) => changeInfo(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>CURRENT PLAN OF CARE PERIOD: FROM </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.PocPt?.step1?.currentPOCFromDate ? moment(relValue?.PocPt?.step1?.currentPOCFromDate).format("MM-DD-YYYY") : ''}</>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocPt?.step1?.currentPOCFromDate}
                      defaultValue={""}
                      onChange={careFrom}
                      name="currentPOCFromDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>

                )}
                <b> TO </b>
                {isSubmit ? (
                  <>{relValue?.PocPt?.step1?.currentPOCToDate ? moment(relValue?.PocPt?.step1?.currentPOCToDate).format("MM-DD-YYYY") : ''}<br/></>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocPt?.step1?.currentPOCToDate}
                      defaultValue={""}
                      onChange={careTo}
                      name="currentPOCToDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>CURRENT EVALUATION DATE: </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.PocPt?.step1?.currentEvaluationDate ? moment(relValue?.PocPt?.step1?.currentEvaluationDate).format("MM-DD-YYYY") : ''}<br/></>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocPt?.step1?.currentEvaluationDate}
                      onChange={evalFrom}
                      name="currentEvaluationDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>PREVIOUS PLAN OF CARE PERIOD: FROM </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.PocPt?.step1?.previouPOCFromDate ? moment(relValue?.PocPt?.step1?.previouPOCFromDate).format("MM-DD-YYYY") : ''}</>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocPt?.step1?.previouPOCFromDate}
                      onChange={previouFrom}
                      name="previouPOCFromDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>
                )}
                <b> TO </b>
                {isSubmit ? (
                  <>{relValue?.PocPt?.step1?.previouPOCToDate ? moment(relValue?.PocPt?.step1?.previouPOCToDate).format("MM-DD-YYYY") : ''}<br/></>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocPt?.step1?.previouPOCToDate}
                      onChange={previouTo}
                      name="previouPOCToDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>PREVIOUS EVALUATION DATE: </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.PocPt?.step1?.previousEvaluationDate ? moment(relValue?.PocPt?.step1?.previousEvaluationDate
                  ).format("MM-DD-YYYY") : ''}<br/></>
                ) : (
                  <div className="form-date-input">
                    <DatePicker
                      multiple={false}
                      value={PocPt?.step1?.previousEvaluationDate}
                      onChange={evalTo}
                      name="previousEvaluationDate"
                      format="MM-DD-YYYY"
                      editable={false}
                    />
                  </div>

                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>FREQUENCY: WEEKLY VISITS:</b>{" "}
                  {isSubmit ? (
                    relValue?.PocPt?.step1?.weeklyVisit
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="weeklyVisit"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                  <b> VISIT LENGTH: </b>{" "}
                  {isSubmit ? (
                    relValue?.PocPt?.step1?.visitLength
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="visitLength"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                  <p style={{ display: "inline-block" }}>minutes<br/></p>
                </label>
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>DURATION: MONTHS:</b>{" "}
                  {isSubmit ? (
                    relValue?.PocPt?.step1?.months
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="months"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                  <b> WEEKS #: </b>{" "}
                  {isSubmit ? (
                    <>{relValue?.PocPt?.step1?.weeks}<br/></>
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="weeks"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                </label>
                <br />
                <br />
                <br />
                {/* <MedicalNecessityPt
                  relValue={relValue}
                  isSubmit={isSubmit}
                  handlesimpleFields={handlesimpleFields}
                  removeTableFields={removeTableFields}
                  addTableFields={addTableFields}
                  tableValues={tableValues}
                /> */}
                <div>
                  <table className="w-100per" style={{ border: "1px solid #000",borderBottom:"0", }}>
                    <>

                      <tr>
                        <th style={{ width: "90%",borderRight: "1px solid",borderBottom:"1px solid",
                              paddingLeft: 5, }}>MEDICAL NECESSITY CRITERIA : {isSubmit ? <></> : <button
                          type="button"
                          className="btn btn-success "
                          style={{
                            width: "45px",
                            height: "28px",
                            padding: "0px",
                            margin: "10px",
                          }}
                          onClick={() => addTableFields()}
                        >
                          +
                        </button>}</th>
                        <th style={{ textAlign: "center",borderBottom: "1px solid", }}>YES/NO</th>
                      </tr>
                      {isSubmit ? (
                        <>

                          {relValue?.PocPt?.step2?.medicalNecessityCriteriaDefault.map(
                            (element, indexs) => (
                              <tr style={{ border: "1px solid", }}>
                                <td
                                  style={{
                                    width: "90%",
                                    borderBottom: "1px solid",
                                    borderRight: "1px solid",
                                    paddingLeft: 5,
                                  }}
                                >
                                  {
                                    relValue?.PocPt?.step2?.medicalNecessityCriteriaDefault[indexs].description
                                  }
                                </td>
                                <td style={{ textAlign: "center", borderBottom: "1px solid", }}>
                                  {
                                    relValue?.PocPt?.step2?.medicalNecessityCriteriaDefault[indexs].criteria
                                  }
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      ) : (
                        <>
                      {PocPt?.step2?.medicalNecessityCriteriaDefault.map(
                            (element, indexs) => (
                        <tr style={{ border: "1px solid", borderBottom: "none" }} key={indexs}>
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            {element.description||''}
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            <input
                              //   ref={register({ required: true })}
                              id={"criteriaOt"+indexs}
                              type="text"
                              name="criteria"
                              value={element.criteria||''}
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => fillCriteria(e, indexs)}
                            />
                          </td>
                          <td><button className="btn btn-sm btn-primary " type="button"
                            style={{
                              width: "40px",
                              height: "20px",
                              padding: "0px",
                              backgroundColor: "red",
                              marginRight: '0'
                            }} onClick={() => deleteRow(indexs)}><b>-</b></button></td>
                        </tr>
                        ))}
                        </>
                        )}
                      {/* {isSubmit ? (<><tr style={{ border: "1px solid", borderBottom: "none" }} id="Necessary01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Necessary to protect life, to prevent significant illness or
                            significant disability, or to alleviate severe pain.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            {/* <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 0)}
                            /> 
                          </td>
                        </tr>
                        <tr style={{ border: "1px solid", borderBottom: "none" }} id="Individualized01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Individualized, specific, and consistent with symptoms or
                            confirmed diagnosis of the illness or injury under treatment,
                            and not in excess of the patient&#39;s needs.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            {/* <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 1)}
                            /> 
                          </td>
                        </tr>
                        <tr style={{ border: "1px solid", borderBottom: "none" }} id="Consistent01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Consistent with generally accepted professional medical
                            standards as determined by the Medicaid program, not
                            experimental or investigational.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            {/* <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 2)}
                            /> 
                          </td>
                        </tr>
                        <tr style={{ border: "1px solid", borderBottom: "none" }} id="Reflective01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Reflective of the level of service that can be safely furnished,
                            and for which no equally effective and more conservative or less
                            costly treatment is available statewide.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            {/* <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 3)}
                            /> 
                          </td>
                        </tr>
                        <tr style={{ border: "1px solid" }} id="Furnished01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Furnished in a manner not primarily intended for the convenience
                            of the recipient, the recipient&#39;s caretaker, or the
                            provider.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            {/* <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 4)}
                            /> 
                          </td>
                        </tr></>) : (<>
                        <tr style={{ border: "1px solid", borderBottom: "none" }} id="Necessary01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Necessary to protect life, to prevent significant illness or
                            significant disability, or to alleviate severe pain.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 0)}
                            />
                          </td>
                          <td><button className="btn btn-sm btn-primary "
                            style={{
                              width: "40px",
                              height: "20px",
                              padding: "0px",
                              backgroundColor: "red",
                              marginRight: '0'
                            }} onClick={() => deleteRow('Necessary01')}><b>-</b></button></td>
                        </tr>
                        <tr style={{ border: "1px solid", borderBottom: "none" }} id="Individualized01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Individualized, specific, and consistent with symptoms or
                            confirmed diagnosis of the illness or injury under treatment,
                            and not in excess of the patient&#39;s needs.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 1)}
                            />
                          </td>
                          <td><button className="btn btn-sm btn-primary "
                            style={{
                              width: "40px",
                              height: "20px",
                              padding: "0px",
                              backgroundColor: "red",
                              marginRight: '0'
                            }} onClick={() => deleteRow('Individualized01')}><b>-</b></button></td>
                        </tr>
                        <tr style={{ border: "1px solid", borderBottom: "none" }} id="Consistent01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Consistent with generally accepted professional medical
                            standards as determined by the Medicaid program, not
                            experimental or investigational.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 2)}
                            />
                          </td>
                          <td><button className="btn btn-sm btn-primary "
                            style={{
                              width: "40px",
                              height: "20px",
                              padding: "0px",
                              backgroundColor: "red",
                              marginRight: '0'
                            }} onClick={() => deleteRow('Consistent01')}><b>-</b></button></td>
                        </tr>
                        <tr style={{ border: "1px solid", borderBottom: "none" }} id="Reflective01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Reflective of the level of service that can be safely furnished,
                            and for which no equally effective and more conservative or less
                            costly treatment is available statewide.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 3)}
                            />
                          </td>
                          <td><button className="btn btn-sm btn-primary "
                            style={{
                              width: "40px",
                              height: "20px",
                              padding: "0px",
                              backgroundColor: "red",
                              marginRight: '0'
                            }} onClick={() => deleteRow('Reflective01')}><b>-</b></button></td>
                        </tr>
                        <tr style={{ border: "1px solid" }} id="Furnished01">
                          <td
                            style={{
                              width: "90%",
                              border: "1px solid",
                              paddingLeft: 5,
                            }}
                          >
                            Furnished in a manner not primarily intended for the convenience
                            of the recipient, the recipient&#39;s caretaker, or the
                            provider.
                          </td>
                          <td style={{ textAlign: "center", border: "1px solid" }}>

                            <input
                              //   ref={register({ required: true })}
                              type="text"
                              name="criteria"
                              className={`form-control form-input form-input-w-120px`}
                              onChange={(e) => handlesimpleFields(e, 4)}
                            />
                          </td>
                          <td><button className="btn btn-sm btn-primary "
                            style={{
                              width: "40px",
                              height: "20px",
                              padding: "0px",
                              backgroundColor: "red",
                              marginRight: '0'
                            }} onClick={() => deleteRow('Furnished01')}><b>-</b></button></td>
                        </tr>
                      </>)} */}
                      {isSubmit ? (
                        <>

                          {relValue?.PocPt?.step2?.medicalNecessityCriteria.map(
                            (element, indexs) => (
                              <tr style={{ border: "1px solid" }}>
                                <td
                                  style={{
                                    width: "90%",
                                    border: "1px solid",
                                    paddingLeft: 5,
                                  }}
                                >
                                  {
                                    relValue?.PocPt?.step2?.medicalNecessityCriteria[indexs].description
                                  }
                                </td>
                                <td style={{ textAlign: "center", border: "1px solid", }}>
                                  {
                                    relValue?.PocPt?.step2?.medicalNecessityCriteria[indexs].criteria
                                  }
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {tableValues.map((element, indexs) => (
                            <>
                              <tr style={{ border: "1px solid" }}>
                                <td
                                  style={{
                                    width: "90%",
                                    border: "1px solid",
                                    paddingLeft: 5,
                                  }}
                                >
                                  <input
                                    // ref={register({ required: true })}
                                    id={"medicalDec" + indexs}
                                    type="text"
                                    placeholder=""
                                    name="description"
                                    className={`form-control form-input form-input-w-60per`}
                                    value={element.description}
                                    onChange={(e) => handlesimpleFields(e, indexs, "desc")}
                                  />
                                </td>
                                <td style={{ textAlign: "center", border: "1px solid" }}>
                                  <input
                                    //   ref={register({ required: true })}
                                    id={"medicalCriteria" + indexs}
                                    type="text"
                                    name="criteria"
                                    className={`form-control form-input form-input-w-120px`}
                                    value={element.criteria}
                                    onChange={(e) => handlesimpleFields(e, indexs, "desc")}
                                  />
                                </td>
                                <td>{isSubmit ? (
                                  <></>
                                ) : (

                                  <button
                                    type="button"
                                    className="btn btn-primary "
                                    style={{
                                      width: "40px",
                                      height: "20px",
                                      padding: "0px",
                                      backgroundColor: "red",
                                      marginRight: '0'
                                    }}
                                    onClick={() => removeTableFields(indexs)}
                                  >
                                    <b>-</b>
                                  </button>
                                )}</td>
                              </tr>{" "}
                            </>
                          ))}
                        </>
                      )}
                    </>
                  </table>
                </div>
                <br />
                {/* DESCRIPTION */}
                <br />
                <p className="w-100per">
                  <b>
                    DESCRIPTION OF CONDITION, INCLUDING MOST SPECIFIC ICD-10
                    CODES:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step2?.specificICD10
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="specificICD10"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>ALL TREATMENT SERVICES TO BE PROVIDED TO RECIPIENT:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step2?.allTreatmentServices
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="allTreatmentServices"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>THERAPY METHODS:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step2?.therapyMethod
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="therapyMethod"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />

                <br />
                <p className="w-100per">
                  <b>MONITORING CRITERIA:</b>
                </p>

                {isSubmit ? (
                  relValue?.PocPt?.step2?.monitoringCriteria
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="monitoringCriteria"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>CURRENT MEDICATIONS:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step2?.currentMedication
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="currentMedication"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => startInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b> OTHER TREATMENTS THE RECIPIENT IS RECEIVING:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step3?.otherTreatment
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="otherTreatment"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>EQUIPMENT REQUIRED:</b>
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step3?.equipmentRequired
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="equipmentRequired"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  {isSubmit ? (<b>
                      IS THE RECIPIENT ON A SPECIALIZED DIET?{relValue?.PocPt?.step3?.recipRadio==='Yes' || relValue?.PocPt?.step3?.recipRadio==='No'? <span> : &nbsp;Yes{" "}
                      <input
                        id="recipYes"
                        type="radio"
                        value="Yes"
                        name="yes_no"
                        defaultValue={""}
                        checked={relValue?.PocPt?.step3?.recipRadio==='Yes'}
                        onChange={(e) => radioVal(e)}
                        disabled={relValue?.PocPt?.step3?.recipRadio==='No' ? true : true }
                      />{" "}
                      No{" "}
                      <input
                        type="radio"
                        value="No"
                        name="yes_no"
                        defaultValue={""}
                        checked={relValue?.PocPt?.step3?.recipRadio==='No'}
                        onChange={(e) => radioVal(e)}
                        disabled={relValue?.PocPt?.step3?.recipRadio==='Yes' ? true : true }
                      />{" "}</span>:''}
                    </b>) : (
                    <b>
                      IS THE RECIPIENT ON A SPECIALIZED DIET? : &nbsp;Yes{" "}
                      <input
                        id="recipYes"
                        type="radio"
                        value="Yes"
                        name="yes_no"
                        defaultValue={""}
                        onChange={(e) => radioVal(e)}
                      />{" "}
                      No{" "}
                      <input
                        type="radio"
                        value="No"
                        name="yes_no"
                        defaultValue={""}
                        onChange={(e) => radioVal(e)}
                      />{" "}
                    </b>
                  )}
                </p>

                {recipRadio === 'Yes' ?
                  <p className="w-100per">
                    <b>If yes, please specify:</b>
                  </p>
                  : ''}

                {isSubmit ? (
                  relValue?.PocPt?.step3?.specializedDiet?.description
                ) : (
                  <>
                    {recipRadio === 'Yes' ?
                      <input
                        // ref={register({ required: true })}
                        type="text"
                        name="description"
                        className={`form-control form-input`}
                        defaultValue={""}
                        onChange={(e) => specializedInfo(e)}
                      />
                      : ''}</>
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>
                    METHODS OF DEMONSTRATING AND TEACHING THE FAMILY AND OTHER
                    RELEVANT CAREGIVERS WHO ARE INVOLVED WITH THE RECIPIENT:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step3?.methodDemonstrating
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="methodDemonstrating"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>
                    {" "}
                    HOW THE TREATMENT WILL BE COORDINATED WITH THE OTHER SERVICE
                    NEEDS PRESCRIBED FOR THE RECIPIENT:
                  </b>
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step3?.treatment
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="treatment"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                <p className="w-100per">
                  <b>OTHER INFORMATION/CONCERNS (Reason For Discharge):</b>{" "}
                </p>
                {isSubmit ? (
                  relValue?.PocPt?.step3?.otherInfo
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    name="otherInfo"
                    className={`form-control form-input`}
                    defaultValue={""}
                    onChange={(e) => updateInfo(e)}
                  />
                )}
                <br />
                <br />
                {/* DynamicGoals */}
                <div className="w-100per">
                  <p
                    className="w-100per"
                    style={{ textDecoration: "underline" }}
                  >
                    <b>Previous Goals:</b>{" "}
                  </p>
                  {isSubmit ? (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Long Term Goals:</b>
                    {relValue?.PocPt?.step4?.previousGoals?.longTerm?.map((element, indexs) => (
                      <div>
                        {console.log("element")}
                        <label>{indexs + 1}.</label>
                        {
                          relValue?.PocPt?.step4?.previousGoals?.longTerm[indexs].name}


                      </div>
                    ))}


                  </div>) : (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Long Term Goals : <button
                      type="button"
                      style={{ margin: "10px" }}
                      className="btn btn-success "
                      style={{
                        width: "40px",
                        height: "28px",
                        padding: "0px",
                      }}
                      onClick={() => addPrevLongGoals()}
                    >
                      +
                    </button></b>
                    {prevLongGoals.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {isSubmit ? (
                          relValue?.PocPt?.step4?.previousGoals?.longTerm
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            id={"nameLongPoc" + indexs}
                            type="text"
                            placeholder=""
                            name="name"
                            className={`form-control form-input form-input-w-60per`}
                            value={element.name}
                            onChange={(e) => handlePrevLongGoals(indexs, e)}
                          />
                        )}

                        <button
                          type="button"
                          style={{ margin: "10px" }}
                          className="btn btn-primary "
                          style={{
                            width: "105px",
                            height: "28px",
                            padding: "0px",
                            backgroundColor: "red",
                          }}
                          onClick={(e) => removePrevLongGoals(indexs, e)}
                        >
                          <b>-</b>
                        </button>
                      </div>
                    ))}
                  </div>)}
                  {isSubmit ? (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Short Term Goals : </b>
                    {relValue?.PocPt?.step4?.previousGoals?.shortTerm?.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {relValue?.PocPt?.step4?.previousGoals?.shortTerm[indexs].name}
                      </div>
                    ))}

                  </div>) : (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Short Term Goals : <button
                      type="button"
                      style={{ margin: "10px" }}
                      className="btn btn-success "
                      style={{
                        width: "40px",
                        height: "28px",
                        padding: "0px",
                      }}
                      onClick={() => addPrevShortGoals()}
                    >
                      +
                    </button></b>
                    {prevShortGoals.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {isSubmit ? (
                          PocPt?.step4?.previousGoals?.shortTerm
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            id={"nameShortPoc" + indexs}
                            type="text"
                            placeholder=""
                            name="name"
                            className={`form-control form-input form-input-w-60per`}
                            value={element.name}
                            onChange={(e) => handlePrevShortGoals(indexs, e)}
                          />
                        )}

                        <button
                          type="button"
                          style={{ margin: "10px" }}
                          className="btn btn-primary "
                          style={{
                            width: "105px",
                            height: "28px",
                            padding: "0px",
                            backgroundColor: "red",
                          }}
                          onClick={() => removePrevShortGoals(indexs)}
                        >
                          <b>-</b>
                        </button>
                      </div>
                    ))}
                  </div>)}
                  <br />
                  <br />
                  <b style={{ textDecoration: "underline" }}>New Goals:</b>
                  <br />
                  {isSubmit ? (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Long Term Goals:</b>
                    {relValue?.PocPt?.step4?.newGoals?.longTerm.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {relValue?.PocPt?.step4?.newGoals?.longTerm[indexs].name}
                      </div>
                    ))}

                  </div>) : (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Long Term Goals : <button
                      type="button"
                      style={{ margin: "10px" }}
                      className="btn btn-success "
                      style={{
                        width: "40px",
                        height: "28px",
                        padding: "0px",
                      }}
                      onClick={() => addNewLongGoals()}
                    >
                      +
                    </button></b>
                    {newLongGoals.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {isSubmit ? (
                          PocPt?.step4?.newGoals?.longTerm
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            id={"longNewPoc" + indexs}
                            type="text"
                            placeholder=""
                            name="name"
                            className={`form-control form-input form-input-w-60per`}
                            value={element.name}
                            onChange={(e) => handleNewLongGoals(indexs, e)}
                          />
                        )}

                        {
                          <button
                            type="button"
                            style={{ margin: "10px" }}
                            className="btn btn-primary "
                            style={{
                              width: "105px",
                              height: "28px",
                              padding: "0px",
                              backgroundColor: "red",
                            }}
                            onClick={(e) => removeNewLongGoals(indexs, e)}
                          >
                            <b>-</b>
                          </button>
                        }
                      </div>
                    ))}
                  </div>)}
                  {isSubmit ? (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Short Term Goals : </b>
                    {relValue?.PocPt?.step4?.newGoals?.shortTerm.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {relValue?.PocPt?.step4?.newGoals?.shortTerm[indexs].name}
                      </div>
                    ))}

                  </div>) : (<div style={{ marginLeft: "40px", marginTop: "20px" }}>
                    <b>Short Term Goals : <button
                      type="button"
                      style={{ margin: "10px" }}
                      className="btn btn-success "
                      style={{
                        width: "40px",
                        height: "28px",
                        padding: "0px",
                      }}
                      onClick={() => addNewShotGoals()}
                    >
                      +
                    </button></b>
                    {newShotGoals.map((element, indexs) => (
                      <div>
                        <label>{indexs + 1}.</label>
                        {isSubmit ? (
                          PocPt?.step4?.newGoals?.shortTerm
                        ) : (
                          <input
                            // ref={register({ required: true })}
                            id={"namePocShort" + indexs}
                            type="text"
                            placeholder=""
                            name="name"
                            className={`form-control form-input form-input-w-60per`}
                            value={element.name}
                            onChange={(e) => handleNewShortGoals(indexs, e)}
                          />
                        )}

                        <button
                          type="button"
                          style={{ margin: "10px" }}
                          className="btn btn-primary "
                          style={{
                            width: "105px",
                            height: "28px",
                            padding: "0px",
                            backgroundColor: "red",
                          }}
                          onClick={() => removeNewShotGoals(indexs)}
                        >
                          <b>-</b>
                        </button>
                      </div>
                    ))}
                  </div>)}
                </div>
                <br />
                <label style={{ width: "auto" }}>
                  <b>SIGNATURES –</b>
                </label>
                &nbsp;&nbsp;
                <br />
                <label style={{ width: "auto", padding: "10px 0" }}>
                  <b>Patient Name : </b>
                </label>
                {isSubmit ? (
                  relValue?.PocPt?.step1?.patientName
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    placeholder="Patient Name"
                    name="patientName"
                    className={`form-control form-input form-input-w-37per`}
                    defaultValue={patientName}
                    onChange={(e) => patientInfo(e)}
                  />)}
                &nbsp;&nbsp;
                <br />
                <br/>
                <label style={{ width: "auto", padding: "10px 0" }}>
                  <b>Therapist Name : </b>
                </label>
                {isSubmit ? (
                  relValue?.PocPt?.step1?.therapistName
                ) : (
                  <input
                    // ref={register({ required: true })}
                    type="text"
                    placeholder="Patient Name"
                    name="therapistName"
                    className={`form-control form-input form-input-w-37per`}
                    defaultValue={therapistName}
                    onChange={(e) => patientInfo(e)}
                  />)}
                <br/>
                <br/>
                <SignDate
                  date={PocPt?.step4?.therapistSignDate || relValue?.PocPt?.step4?.therapistSignDate}
                  familyImageURL={
                    isSubmit ? props?.data?.therapistSignUrl : familyImageURL
                  }
                  isSubmit={isSubmit}
                  setOpen={setOpen}
                  signTitle={"Therapist"}
                  guardian="Therapist"
                  familyInfo={familyInfo}
                  isLoading={isLoading}
                  userType="therapist"
                />
                <DigitalSignPopup
                  modalState={open}
                  setModalState={setOpen}
                  save={save}
                  clear={clear}
                  sigCanvas={sigCanvas}
                />
                <br />
                <p className="w-100per">
                  <i>
                    By signing as the therapist, I hereby certify this plan of
                    care was written based on evaluation results and the goals
                    are reasonable and appropriate for this patient, and that if
                    this prescription is for a continuing plan, I have reviewed
                    the patient's progress and adjusted the plan of care goals
                    if necessary.
                  </i>
                </p>
                <label style={{ width: "auto", padding: "10px 0" }}>
                  <b>PHYSICIAN SIGNATURE: </b>
                </label>
                <br />
                <label style={{ width: "auto", paddingLeft: "10px 0" }}>
                  Dr ______________________
                </label>
                <br />
                <br />
                <br />
                <p>PCP Signature/Date</p>
                <br />
                <p className="w-100per">
                  <i>
                    By signing as the PCP and/or other authorized provider, I
                    hereby certify that I have reviewed each element of the
                    therapy plan of care, that the goals are reasonable and
                    appropriate for this patient, and that if this prescription
                    is for a continuing plan, I have reviewed the patient's
                    progress and adjusted the plan of care goals if necessary.
                  </i>
                </p>
                <br />
                <hr />
                <FormFooter
                  address={joinWithComma(
                    address1,
                    address2,
                    city,
                    country,
                    zipCode
                  )}
                />
                {!isSubmit ? (
                  <>
                    {errors.consent1 ||
                      (errors.consent2 && (
                        <p className="error-text">
                          <small>All fields are require</small>
                        </p>
                      ))}
                    {/* {signatureErr && (
                        <p className="text-red">
                          <small>{signatureErr}</small>
                        </p>
                      )} */}
                    <button
                      className="btn btn-theme-green"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                    {console.log(
                      "familyInfo.therapistSign",
                      familyInfo.therapistSign
                    )}
                    {/* {props?.data?.replyNeeded && ( */}
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={familyInfo.therapistSign ? false : true}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                    {/* )} */}
                  </>
                ) : (
                  <>
                    {!props?.isFormOpen && (
                      <button
                        className="btn btn-theme-green mb-10"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
}

export default PocPtForm;
