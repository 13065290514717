import React from "react";
import Emoji from "../components/emoji/Emoji";
import CallNew from "./AgoraExternalVideo/CallNew";
import Call from "./AgoraExternalVideo/Call";
import AgoraTest from "./AgoraTest";
import {
  useAgoraState,
  useAgoraMutation,
} from "../components/agora/utils/container";

import { useDispatch, useSelector } from "react-redux";

const TestPage = () => {
  const mutationCtx = useAgoraMutation();
  const stateCtx = useAgoraState();
  //const agoraRdx = useSelector((state) => state.agoraDetailNew);

  //console.warn("RDX Log", agoraRdx);

  return (
    <div>
      <Call />
      {/* <AgoraTest /> */}

      {/* <p>With Api Auth Credentials</p>
      <Call />
      <p>With Api</p> */}
      {/* <CallNew />
      <Emoji /> */}
    </div>
  );
};

export default TestPage;
