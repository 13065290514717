import React, { useState } from "react";
import { useNavigate, useParams, useLocation,useSearchParams } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { FaFemale, FaMale } from "react-icons/fa";
import "./otherUser.scss";

function OtherUserDetail() {
  let history = useNavigate();
  const params = useParams();
  const location = useLocation()
 // const queryParameters = new URLSearchParams(location?.data)
  console.log("userdetails..........",location,params,history);

  const { id, about, firstName, lastName, gender, email, phone, profilePicKey } =
    location?.state?.data;
  const [userInfo, setUserInfo] = useState({});
  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            <div className="card border-none">
              <div className="row p-10">
                <div className="col-md-6">
                  <h4>User Details</h4>
                </div>
                <div className="col-md-6">
                  
                </div>
              </div>
              <hr className="mt-0" />
              <div className="col-md-12">
                <div className="row m-0">
                  <div className="col-md-2">
                    <img
                      src={profilePicKey || "/resources/assets/defaultprofile.png"}
                      alt="Profile"
                      className="tenant-detail-logo"
                      style={{
                        borderRadius: 0,
                        height: 120,
                        objectFit: "scale-down",
                        marginBottom: 30,
                      }}
                      width="100%"
                    />
                  </div>
                  <div className="col-md-8">
                    <p className="name c-basic-user-detail__status">
                      {`${firstName} ${lastName}`}
                      <span className="c-basic-user-detail__status">
                        {" "}
                        |
                        <small>
                          {gender == "NOT_DISCLOSED"
                            ? "  Other"
                            : gender == "MALE"
                              ? " Male"
                              : " Female"}
                        </small>
                      </span>
                    </p>
                    <div className="row mt-10">
                      <div className="col-md-6">
                        <p className="name c-basic-user-detail__status mb-0">
                            Email
                        </p>
                        <p>
                          {email || ""}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="name c-basic-user-detail__status mb-0">
                            Phone
                        </p>
                        <p>
                          {phone || ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => history(`/edit-marketing-user/${id}`)}
                      src={"/resources/assets/action-icon-edit.svg"}
                      alt=""
                      className="float-right"
                    />
                    {/* <AiOutlineEdit size="22" className="EditIcon"></AiOutlineEdit> */}
                  </div>
                </div>
                <div className="row mt-10">
                  <div className="col-md-12 ">
                    <div className="pb-3" style={{ paddingLeft: 20 }}>
                      <p className="name c-basic-user-detail__status">About</p>
                      <p>{about}</p>
                    </div>
                  </div>
                </div>
                <button
                    onClick={() => history("/marketing-user")}
                    className="btn btn-theme-green"
                  >
                    Back
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherUserDetail;
