import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { adminService } from "../../services/adminService";
import { daysJson } from "../../config/Config";
import AddService from "./AddService";
import { getUTCTimeToLocalByDateWithMeridium24 } from "../../utils/commonFunctions";
import { toast } from "react-toastify";
import Thumbnail from "../../components/Thumbnail";
import BasicUserdetail from "../../components/BasicUserdetail";
import { useSelector } from "react-redux";
import AppModal from "../../components/AppModal";
import { useForm } from "react-hook-form";
import { alphanumericRegx } from "../../components/Utils/Regex";

function TherapistDetails() {
  let params = useParams();
  //console.log("therapistdetail",props,params.id);
  const { register, formState: { errors }, handleSubmit, setValue } = useForm();
  const [therapistdetails, setTherapistDetails] = useState("");
  const [loading, setloading] = useState(false);
  const [modalOpenClose, setModalOpenClose] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isdelete, setIsDelete] = useState(false);
  const [editservicedetail, setEditServiceDetail] = useState({
    info: "",
  });
  const [discipline, setDiscipline] = useState([]);
  const [inputs, setInputs] = useState({});
  const [otherInput, setOtherInput] = useState(false);
  let profile = useSelector((state) => state.adminUser.profile);
  //let editservicedetail = {}
  const addFun = () => {
    adminService.getAll(`therapists/` + params.id).then(
      (res) => {
        setTherapistDetails(res.data);
        setModalOpenClose(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const deleteService = (
    rowid,
    id,
    name,
    clinicNpiNumber,
    clinicianNpiNumber,
    desc
  ) => {
    if (therapistdetails.therapistServices.length == 1) {
      toast.warning("Can't delete the service");
      return;
    }
    let data = {
      serviceDetail: desc,
      clinicNpiNumber: clinicNpiNumber,
      clinicianNpiNumber: clinicianNpiNumber,
      therapyId: id,
      therapistServiceId: rowid,
      name: name,
    };
    setEditServiceDetail((editservicedetail) => ({
      ...editservicedetail,
      ["info"]: data,
    }));
    setIsDelete(true);
    setModalOpenClose(true);
  };

  const addService = () => {
    setEditServiceDetail((editservicedetail) => ({
      ...editservicedetail,
      ["info"]: {},
    }));
    setIsDelete(false);
    setModalOpenClose(true);
  };

  const editService = (
    rowid,
    id,
    clinicNpiNumber,
    clinicianNpiNumber,
    desc
  ) => {
    let data = {
      serviceDetail: desc,
      clinicNpiNumber: clinicNpiNumber,
      clinicianNpiNumber: clinicianNpiNumber,
      therapyId: id,
      therapistServiceId: rowid,
    };
    setEditServiceDetail((editservicedetail) => ({
      ...editservicedetail,
      ["info"]: data,
    }));
    setIsDelete(false);
    setModalOpenClose(true);
  };

  useEffect(() => {
    setloading(true);
    getInfo();
  }, [params.id]);

  const getInfo = () => {
    adminService.getAll(`therapists/` + params.id).then(
      (res) => {
        if (res?.data) {
          setTherapistDetails(res?.data);
          setInputs({
            disciplines_id: res?.data?.disciplinesDto?.id,
            credentials: res?.data?.credentials,
            hourlyRate: res?.data?.hourlyRate,
            otherDiscipline: res?.data?.otherDiscipline,
            licenseNumberState: res?.data?.licenseNumberState,
            medicaidNumber: res?.data?.medicaidNumber,
            taxonomy: res?.data?.taxonomy,
          });
          setValue("disciplines_id", res?.data?.disciplinesDto?.id);
          setloading(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    setEditServiceDetail(editservicedetail);
  }, [editservicedetail]);

  const basicInfo = {
    image: therapistdetails?.userProfile?.profilePicUrl,
    firstName: therapistdetails?.userProfile?.firstName,
    lastName: therapistdetails?.userProfile?.lastName,
    status:
      therapistdetails?.userProfile?.active === true ? "Active" : "InActive",
    appointments: therapistdetails?.appointments,
    gender: therapistdetails?.userProfile?.gender,
    phone: therapistdetails?.userProfile?.phone,
    email: therapistdetails?.userProfile?.email,
    editLink: "/edit-therapist-details/" + therapistdetails?.id,
    about: therapistdetails?.userProfile?.about,
    image: therapistdetails?.userProfile?.profilePicUrl,
    // therapistInfo: therapistdetails,
    userType: therapistdetails?.userProfile?.userType,
    currentUserType: profile?.userType,
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append(
      "therapistUpdateForm",
      new Blob([JSON.stringify(inputs)], {
        type: "application/json",
      })
    );
    adminService
      .update(`/therapists/${params.id}`, formData)
      .then(
        (res) => {
          if (res?.status == "Success") {
            toast.success(res?.message);
            getInfo();
          } else {
            toast.error(res?.message);
          }
          setIsModal(false);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(() => {
        setIsModal(false);
      });
  };

  useEffect(() => {
    adminService
      .getAll(`/disciplines`)
      .then((res) => {
        setDiscipline(res.data);
        // resolveWithData(resolve, rej, res);
      })
      .catch((err) => {
        // rej(err);
      });
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "disciplines_id") {
      if (value === "0") {
        setOtherInput(true);
      } else {
        setOtherInput(false);
      }
      setInputs((inputs) => ({
        ...inputs,
        [name]: name === "disciplines_id" ? parseInt(value) : value,
      }));
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value ? value : "" }));
    }
  }

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">Therapist Details</div>
            </div>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <>
                <BasicUserdetail {...basicInfo} />
                <hr />
                <div className="p-3">
                  <div className="col-md-12">
                    <div className="col-md-12 text-right">
                      <Link>
                        <img
                          onClick={() => setIsModal(true)}
                          src="/resources/assets/Icon feather-edit-3.svg"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="mb-0 mt-10">
                          <b>Discipline :&nbsp;</b>
                        </p>
                        {therapistdetails?.disciplinesDto?.name}
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0 mt-10">
                          <b>Credentials :&nbsp;</b>
                        </p>
                        {therapistdetails?.credentials}
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0 mt-10">
                          <b>Hourly Rate :&nbsp;</b>
                        </p>
                        {therapistdetails?.hourlyRate}
                      </div>
                    </div>
                    {therapistdetails?.disciplinesDto?.id == 0 && (
                      <div className="row">
                        <div className="col-md-12">
                          <p className="mb-0 mt-10">
                            <b>Other Discipline :&nbsp;</b>
                          </p>
                          {therapistdetails?.otherDiscipline}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <p className="mb-0 mt-10">
                          <b>License Number and State :&nbsp;</b>
                        </p>
                        {therapistdetails?.licenseNumberState}
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0 mt-10">
                          <b>Medicaid Number :&nbsp;</b>
                        </p>
                        {therapistdetails?.medicaidNumber}
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0 mt-10">
                          <b>Taxonomy :&nbsp;</b>
                        </p>
                        {therapistdetails?.taxonomy}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row ml-3 mt-5 justify-content-between">
                  <div className="col-auto">
                    <h5 className="name">Services Details</h5>
                  </div>
                  <div className="col-auto interpreter-detail-btn">
                    <button
                      type="button"
                      onClick={addService}
                      className="btn btn-primary custom-btn-green"
                    >
                      <img
                        className="pr-2"
                        src="../resources/assets/plus-icon-button.svg"
                        alt=""
                      />
                      Add Service
                    </button>

                    {modalOpenClose && (
                      <AddService
                        isdelete={isdelete}
                        addFun={() => addFun()}
                        closeModal={() => setModalOpenClose(false)}
                        serviceinfo={editservicedetail}
                        servicedetail={
                          therapistdetails.therapistServices &&
                          therapistdetails.therapistServices
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="row row ml-3 justify-content-between">
                  <div className="col-md-12 m-auto">
                    <table className="table table-hover mt-4">
                      <thead>
                        <th
                          className="thead"
                          style={{ maxWidth: 40, minWidth: 40 }}
                        >
                          S No.
                        </th>
                        <th
                          className="thead"
                          style={{ maxWidth: 100, minWidth: 100 }}
                        >
                          Provider Type
                        </th>
                        {/*  <th className="thead" style={{maxWidth:100,minWidth:100}}>Service Details</th> */}
                        <th
                          className="thead"
                          style={{ maxWidth: 120, minWidth: 120 }}
                        >
                          Clinic NPI Number
                        </th>
                        <th
                          className="thead"
                          style={{ maxWidth: 120, minWidth: 120 }}
                        >
                          Clinician NPI Number
                        </th>
                        <th
                          className="thead"
                          style={{ maxWidth: 80, minWidth: 80 }}
                        >
                          Actions
                        </th>
                      </thead>
                      <tbody>
                        {therapistdetails.therapistServices &&
                          therapistdetails.therapistServices.map(
                            (item, index) => (
                              <tr>
                                <td style={{ maxWidth: 40, minWidth: 40 }}>
                                  <p className="preview">{index + 1}</p>
                                </td>
                                <td style={{ maxWidth: 100, minWidth: 100 }}>
                                  <p className="preview">
                                    {item.therapy && item.therapy.name}
                                  </p>
                                </td>
                                {/* <td style={{maxWidth:100,minWidth:100}}><p className="preview">{item.serviceDetail}</p></td>*/}
                                <td style={{ maxWidth: 120, minWidth: 120 }}>
                                  <p className="preview">
                                    {item.clinicNpiNumber != 0
                                      ? item.clinicNpiNumber
                                      : ""}
                                  </p>
                                </td>
                                <td style={{ maxWidth: 120, minWidth: 120 }}>
                                  <p className="preview">
                                    {item.clinicianNpiNumber != 0
                                      ? item.clinicianNpiNumber
                                      : ""}
                                  </p>
                                </td>
                                <td style={{ maxWidth: 80, minWidth: 80 }}>
                                  <Link>
                                    <img
                                      className="pr-4"
                                      src="../resources/assets/action-icon-edit.svg"
                                      alt=""
                                      onClick={() =>
                                        editService(
                                          item.id,
                                          item.therapy.id,
                                          item.clinicNpiNumber,
                                          item.clinicianNpiNumber,
                                          item.serviceDetail
                                        )
                                      }
                                    />
                                  </Link>
                                  <Link>
                                    <img
                                      src="../resources/assets/action-icon-delete.svg"
                                      alt=""
                                      onClick={() =>
                                        deleteService(
                                          item.id,
                                          item.therapy.id,
                                          item.therapy.name,
                                          item.clinicNpiNumber,
                                          item.clinicianNpiNumber,
                                          item.serviceDetail
                                        )
                                      }
                                    />
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>

                    <hr />
                  </div>
                </div>

                <div className="row ml-3 mt-5">
                  <div className="col-md-8">
                    <h5 className="name">Working Days and Time</h5>

                    {/* <p className="mt-4">
                                            {daysJson && daysJson.map((item, index) =>
                                                therapistdetails.therapistAvailability && therapistdetails.therapistAvailability[item.day] ? item.name + ' ' : ""
                                            )}
                                        </p> */}
                  </div>
                  <div className="col-md-3 ml-5 text-right">
                    {/* <Link to={`/EditTherapistAvailability/`}> */}
                    <Link
                      to={"/edit-therapist-availability/" + therapistdetails.id}
                    >
                      <img
                        src="../resources/assets/Icon feather-edit-3.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="col-md-12">
                  <table className="table table-hover mt-4">
                    <thead>
                      <th>Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Timezone</th>
                    </thead>
                    <tbody>
                      {therapistdetails.therapistAvailability &&
                        therapistdetails.therapistAvailability.map(
                          (item, index) => (
                            <tr>
                              <td>{item.availableDay}</td>
                              <td>
                                {getUTCTimeToLocalByDateWithMeridium24(
                                  item.startTime
                                )}
                              </td>
                              <td>
                                {getUTCTimeToLocalByDateWithMeridium24(
                                  item.endTime
                                )}
                              </td>
                              <td>{`${therapistdetails.timezone.code
                                }(${therapistdetails.timezone.timezone.replaceAll(
                                  "_",
                                  " "
                                )})`}</td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                </div>
                
                <div className="row ml-3">
                  <div className="col-md-10">
                    {/* <h5 className="name">Working Time</h5>
                                        <p className="mt-4">Start Time : {getUTCTimeToLocalByDateWithMeridium24(therapistdetails.therapistAvailability && therapistdetails.therapistAvailability.startTime)}   &nbsp; &nbsp; &nbsp;  End Time : {getUTCTimeToLocalByDateWithMeridium24(therapistdetails.therapistAvailability && therapistdetails.therapistAvailability.endTime)}</p> */}
                    <h4 className="name mt-3">Languages</h4>

                    <div className="row mt-1">
                      {therapistdetails.languages &&
                        therapistdetails.languages.map((item) => (
                          <div className="col-md-3">
                            <p className="lang-box">{item.languageName}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            <>
              <AppModal
                title=""
                isOpen={isModal}
                onClose={() => setIsModal(false)}
                modalSize="lg"
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Discipline</label> (
                        <span className="required-span">*</span>)
                        <div className="input-group ">
                          <select
                            defaultValue={inputs?.disciplines_id}
                            //className="custom-select"
                            {...register('disciplines_id', {
                              onChange: (e) => {
                                setInputs({ ...inputs, otherDiscipline: null });
                                handleChange(e);
                              },
                              required: true,
                            })}
                            className={`custom-select form-control ${errors.disciplines_id && "is-invalid"
                              }`}
                            // onChange={(e) => {
                            //   setInputs({ ...inputs, otherDiscipline: null });
                            //   handleChange(e);
                            // }}

                            name="disciplines_id"
                            id="disciplines_id"
                          >
                            <option value="">Select Discipline </option>
                            {discipline &&
                              discipline.map((disc, index) => (
                                <option key={index} value={disc.id}>
                                  {disc.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Credentials</label>{" "}
                        {/*(<span className="required-span">*</span>)*/}
                        <input
                          defaultValue={inputs.credentials}
                          // disabled={params.uid ? true : false}
                          name="credentials"
                          type="text"
                          onChange={(e) => handleChange(e)}
                          className={`form-control`}
                          placeholder="Credentials"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Hourly Rate</label>{" "}
                        {/*(<span className="required-span">*</span>)*/}
                        <input
                          value={inputs.hourlyRate}
                          // disabled={params.uid ? true : false}
                          name="hourlyRate"
                          type="number"
                          onChange={(e) => handleChange(e)}
                          className={`form-control`}
                          placeholder="Hourly Rate"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {inputs?.disciplines_id == 0 && (
                        <div className="form-group">
                          <label>Other</label>{" "}
                          {/*(<span className="required-span">*</span>)*/}
                          <input
                            defaultValue={inputs.otherDiscipline}
                            // disabled={params.uid ? true : false}
                            name="otherDiscipline"
                            type="text"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            className={`form-control`}
                            placeholder="other"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>License Number and State </label>{" "}
                        {/*(<span className="required-span">*</span>)*/}
                        <input
                          defaultValue={inputs.licenseNumberState}
                          // disabled={params.uid ? true : false}
                          name="licenseNumberState"
                          //type="number"
                          //onChange={(e) => handleChange(e)}
                          className={`form-control`}
                          placeholder="License Number and State"
                          {...register('licenseNumberState', { onChange: (e) => handleChange(e), pattern: alphanumericRegx })}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Medicaid Number</label>{" "}
                        {/*(<span className="required-span">*</span>)*/}
                        <input
                          defaultValue={inputs.medicaidNumber}
                          // disabled={params.uid ? true : false}
                          name="medicaidNumber"
                          type="number"
                          onChange={(e) => handleChange(e)}
                          className={`form-control`}
                          placeholder="Medicaid Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Taxonomy</label>{" "}
                        {/*(<span className="required-span">*</span>)*/}
                        <input
                          value={inputs.taxonomy}
                          // disabled={params.uid ? true : false}
                          name="taxonomy"
                          type="text"
                          onChange={(e) => handleChange(e)}
                          className={`form-control`}
                          placeholder="Taxonomy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 d-flex1 pl-511">
                      <div className="d-flex">
                        <button
                          className="btn btn btn-primary d-block stepper-button-next"
                          type="submit"
                        >
                          Submit
                        </button>

                        <button
                          type="button"
                          onClick={() => setIsModal(false)}
                          className="btn  btn-primary d-block stepper-button-back"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </AppModal>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default TherapistDetails;
