import React, { useState } from "react";

function Pagination(props) {
  const { currentPage, setCurrentPage } = props;
  //pagination
  const pageNumbers = [];

  for (let i = 0; i <= props.totalpages - 1; i++) {
    pageNumbers.push(i);
  }

  const paginate = (number) => {
    setCurrentPage(number);
  };
  const paginatePre = () => {
    setCurrentPage(currentPage - 1);
  };
  const paginateNext = () => {
    setCurrentPage(currentPage + 1);
  };
  //end

  return (
    <>
      <nav>
        <ul className="pagination justify-content-center">
          <li onClick={() => paginatePre()} className="page-item">
            {currentPage <= 0 ? (
              <></>
            ) : (
              <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                <img
                  className="pr-2"
                  src="../resources/assets/arrow-left.svg"
                />
              </div>
            )}
          </li>
          {pageNumbers.map((number) => (
            <li
              key={number}
              onClick={() => paginate(number)}
              className="page-item"
            >
              <div
                className={`${
                  currentPage === number
                    ? "pagination-active page-link pagination"
                    : "page-link pagination cursor-pointer"
                }`}
              >
                {number + 1}
              </div>
            </li>
          ))}
          <li onClick={() => paginateNext()} className="page-item">
            {currentPage >= props.totalpages - 1 ? (
              <></>
            ) : (
              <div className="page-link nxt-pre nxt-pre-active cursor-pointer">
                <img
                  className="pr-2"
                  src="../resources/assets/arrow-right.svg"
                />
              </div>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Pagination;
