import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/adminService';
import FamilyChildrenInformation from './OnBoardingUser/FamilyChildrenInformation';

function ChildrenInformation(props) {    

    return (
        <>
            <div className="col-md-10 no-padding">
                <div className="no-padding dash-billing-info mr-0">
                    <div className="custom-table-view">                       
                        <FamilyChildrenInformation/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChildrenInformation;