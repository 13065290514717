import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation,Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import { adminService } from "../services/adminService";
import moment from "moment-timezone";
import { daysJson } from "../config/Config";
import { useSelector } from "react-redux";
import DeleteSession from "../pages/Representative/DeleteSession";
import EditSessionRequest from "../pages/Representative/EditSessionRequest";
import WatchVideo from "../pages/Representative/WatchVideo";
import TherapySessionsTableRow from "./TherapySessionsTableRow";
import DatePicker, { DateObject } from "react-multi-date-picker";

import sortSessionByTime from "../utils/sessionSorting";

function ArchiveTherapySessionTable(props) {
    let prevMonth = moment().subtract(4, 'months').format('YYYY-MM-DD');
    let pervNext = moment(prevMonth).subtract(1, 'months').format('YYYY-MM-DD');
    let rangeDate = [prevMonth, pervNext];
    let user = useSelector((state) => state.adminUser.user);
    let params = useParams();
    let location = useLocation();
    const history = useNavigate();
    const [modalOpenClose, setModalOpenClose] = useState(false);
    const [modalOpenClose1, setModalOpenClose1] = useState(false);
    const [watchvideo, setWatchvideo] = useState(false);
    const [recordingList, setRecordingList] = useState([]);
    const [therapysessions, setTherapySessions] = useState([]);
    const [loading, setloading] = useState(true);
    const [iscancelreq, setIsCancelReq] = useState(false);
    const [reason, setReason] = useState("");
    const [values, setValues] = useState([
        new DateObject().subtract(4, "month").subtract(5, "days"),
        new DateObject().subtract(4, "month")
    ])
    //const[prevDate,setPrevDate]=useState(moment().subtract(4, 'months').format('YYYY-MM-DD'));
    //const[nextDate,setNextDate]=useState(moment(prevMonth).subtract(1, 'months').format('YYYY-MM-DD'));
    const [deletesessiondetail, setdeletesessiondetail] = useState({
        info: "",
    });
    const isTherepist = (user?.userDetails?.roles || []).includes(
        "ROLE_THERAPIST"
    );
    const isFamily = (user?.userDetails?.roles || []).includes("ROLE_FAMILY");
    const isInterepreter = (user?.userDetails?.roles || []).includes(
        "ROLE_INTERPRETER"
    );
    const isSessionAuttender = isTherepist || isFamily || isInterepreter;

    let isUserSessions = location.search
        ? `${location.search.replace("?", "&")}`
        : "";
    let jsonDates = JSON.stringify(values[0]);
    let jsonDatesTo = JSON.stringify(values[1]);
    let date = moment(parseInt(jsonDates)).format('YYYY-MM-DD');
    let dateTo = moment(parseInt(jsonDatesTo)).format('YYYY-MM-DD');
    console.log("jsonDates", date, dateTo);
    let qry_param_filter = `&name=&startFromDateTime=${date
        }T00:00:00&startToDateTime=${dateTo
        }T00:00:00${isUserSessions}&timeZone=${moment.tz.guess()}`;
    const { debounce } = _;

    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const hitApi = (api) => {
        if (typeof api === "function") {
            api();
        }
    };
    const debounceHitApi = useCallback(debounce(hitApi, 500), []);
    useEffect(() => {
        adminService
            .getAll(
                `archive/sessions?page=${page}&size=20&sort=startDateTime,DESC${qry_param_filter}`
            )
            .then(
                (res) => {
                    if (res?.status === "Success") {
                        //alert("ok");
                        console.log("sessions archive", res.data);
                        setPage(page);
                        setTotalPage(res.data.totalPages);
                        setTherapySessions(sortSessionByTime(res));
                        // url == "sessions"
                        //   ? setTherapySessions(res.data.content)
                        //   : setTherapySessions(sortSessionByTime(res));
                    } else {
                        res?.message
                            ? toast.error(res?.message)
                            : console.log("Somthing went wrong");
                    }
                    setloading(false);
                },
                (error) => {
                    console.log(error);
                }
            );
    }, [values, qry_param_filter])
    // const prevfilterDate = () =>{

    // }
    // const NextfilterDate = () =>{

    // }
    //   const archiveSession = () => {
    //     adminService.getAll(`archive/sessions`).then(
    //       (res) => {
    //         if (res?.status === "Success") {
    //           console.log("archive session", res.data);
    //           //setUserType(res.data?.userType);
    //         } else {
    //           // res?.message
    //           //   ? toast.error(res?.message)
    //           console.log("Somthing went wrong");
    //         }
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     );
    //   }
    useEffect(() => {
        setloading(true);

        // (user.userDetails.roles[0] === "ROLE_RCE" ||
        //   user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS") &&
        //   debounceHitApi(() => {
        //     getAllSessions();
        //   });

        // user.userDetails.roles[0] === "ROLE_FAMILY" &&
        //   debounceHitApi(() => {
        //     getFamilySessions();
        //   });

        // user.userDetails.roles[0] === "ROLE_THERAPIST" &&
        //   debounceHitApi(() => {
        //     getTherapistSessions();
        //   });

        // user.userDetails.roles[0] === "ROLE_INTERPRETER" &&
        //   debounceHitApi(() => {
        //     getInterpreterSessions();
        //   });
    }, [page, qry_param_filter]);

    //   const successSub = () => {
    //     let api =
    //       user.userDetails.roles[0] === "ROLE_RCE" ||
    //         user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS"
    //         ? getAllSessions()
    //         : getTherapistSessions();
    //   };

    const watchVideo = (id, name, list) => {
        let data = {
            sessionid: id,
            therapyname: name,
        };
        setdeletesessiondetail((deletesessiondetail) => ({
            ...deletesessiondetail,
            ["info"]: data,
        }));
        if (list && list.length > 0) {
            setRecordingList(list);
        } else {
            getRecording(id);
        }
        setWatchvideo(true);
    };

    const Deletesession = (id, name, sessionInfo) => {
        let data = {
            sessionid: id,
            therapyname: name,
        };
        // user.userDetails.roles[0] === "ROLE_THERAPIST"
        setdeletesessiondetail((deletesessiondetail) => ({
            ...deletesessiondetail,
            ["info"]: data,
            sessionInfo,
        }));
        setModalOpenClose(true);
    };

    const cancelSessionReq = (id, name, sessionInfo) => {
        let data = {
            sessionid: id,
            therapyname: name,
        };
        setdeletesessiondetail((deletesessiondetail) => ({
            ...deletesessiondetail,
            ["info"]: data,
            sessionInfo,
        }));
        setModalOpenClose1(true);
        setIsCancelReq(true);
    };

    const Editsession = (id, name, sessionInfo) => {
        let data = {
            sessionid: id,
            therapyname: name,
        };
        setdeletesessiondetail((deletesessiondetail) => ({
            ...deletesessiondetail,
            ["info"]: data,
            sessionInfo,
        }));
        setModalOpenClose1(true);
        setIsCancelReq(false);
    };

    const getRecording = (id) => {
        adminService
            .getAll(`session-recordings/${id}`)
            .then((res) => {
                setRecordingList(res.data.recordings);
            })
            .catch(console.log);
    };

    const therapySessionsdetails = (id, session) => {
        if (
            user?.userDetails?.roles == "ROLE_THERAPIST" ||
            user?.userDetails?.roles == "ROLE_RCE"
        ) {
            history(`/archive-therapy-session/` + id);
        } else if (
            session.status !== "CANCELLED" &&
            (user?.userDetails?.roles !== "ROLE_FAMILY" ||
                user?.userDetails?.roles !== "ROLE_INTERPRETER")
        ) {
            history(`/archive-therapy-session/` + id);
        }
    };
    const setDate = (e) => {
        let dateRange = moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
        console.log("dateRange", dateRange);
    };

    //   const getAllSessions = () => {
    //     setAllSessions("sessions");
    //   };

    //   const getTherapistSessions = () => {
    //     setAllSessions("therapist/sessions");
    //   };

    //   const getInterpreterSessions = () => {
    //     setAllSessions("interpreter/sessions");
    //   };

    //   const getFamilySessions = () => {
    //     setAllSessions("families");
    //   };

    //   const setAllSessions = () => {

    //   };

    // if (loading) {
    //     return <div className="loader"></div>;
    // }
    function subtractMonths(numOfMonths, date = new Date()) {
        date.setMonth(date.getMonth() - numOfMonths);

        return date;
    }

    return (
        <>
            <div className="col-md-10 no-padding d-flex flex-column">
                <div className="no-padding dash-billing-info mr-0 flex-grow-1">
                    <div className="custom-table-view min-h-100">
                        <div class="content-header">
                            <div class="text-left header-title pt-7px">Therapy Sessions</div>
                            <div className="text-right form-date-input sessionFilterDate">
                                <b>Select Date : </b>
                                <DatePicker
                                    value={values}
                                    onChange={setValues}
                                    maxDate={subtractMonths(4)}
                                    format="MM-DD-YYYY"
                                    range
                                />
                                {/* {JSON.stringify({ values })} */}
                                {/* <DatePicker
                                    multiple={false}
                                    value={rangeDate}
                                    onChange={setDate}
                                    name="daterange"
                                    format="YYYY-MM-DD"
                                    editable={false}
                                    numberOfMonths={2}
                                /> */}
                            </div>
                        </div>
                        {loading ? <div className="loader"></div> :
                            <div className="table-responsive">
                                {therapysessions.length > 0 ? (
                                    therapysessions &&
                                    therapysessions.map((item, _i) => (
                                        <div key={_i} className="row">
                                            <div className="col-12 therapy-session c-therapy-session-table">
                                                <table className="table table-borderless table-hover">
                                                    <tbody>
                                                        {item &&
                                                            item.map((info, _i2) => {
                                                                if (
                                                                    !(
                                                                        info?.therapySession &&
                                                                        info?.therapySession.length > 0
                                                                    )
                                                                ) {
                                                                    return null;
                                                                }
                                                                return (
                                                                    <React.Fragment key={_i2}>
                                                                        {/* <div className="col-md-1 no-padding text-center m-auto">
                                    <div className="billing-item date-width">
                                        <h5 className="bill-title"><p className="session-dd">
                                            {daysJson[parseInt(moment(info.date).isoWeekday() === 0 ? 6 : moment(info.date).isoWeekday() - 1)].short}<br />{moment(info.date).format("DD")}</p>
                                        </h5>
                                    </div>
                                </div> */}

                                                                        {info.therapySession &&
                                                                            info.therapySession.map((subinfo, index) => (
                                                                                <TherapySessionsTableRow
                                                                                    key={"" + _i + _i2 + index}
                                                                                    isFirst={index === 0}
                                                                                    isToday={moment().isSame(
                                                                                        moment(info.date),
                                                                                        "days"
                                                                                    )}
                                                                                    displayDate={
                                                                                        <span
                                                                                            className={
                                                                                                moment().isSame(
                                                                                                    moment(info.date),
                                                                                                    "days"
                                                                                                )
                                                                                                    ? "text-primary"
                                                                                                    : ""
                                                                                            }
                                                                                        >
                                                                                            {/* <small>
                                                                {daysJson[parseInt(moment(info.date).isoWeekday() === 0 ? 6 : moment(info.date).isoWeekday() - 1)].short}
                                                            </small> */}
                                                                                            {moment(info.date).format(
                                                                                                "dddd DD MMMM, YYYY"
                                                                                            )}
                                                                                            {
                                                                                                // moment().isSame(moment(info.date), 'days') && <small>Today</small>
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                    total={info.therapySession.length}
                                                                                    therapySessionsdetails={
                                                                                        therapySessionsdetails
                                                                                    }
                                                                                    subinfo={subinfo}
                                                                                    watchVideo={watchVideo}
                                                                                    user={user}
                                                                                    Editsession={Editsession}
                                                                                    Deletesession={Deletesession}
                                                                                    cancelSessionReq={cancelSessionReq}
                                                                                />
                                                                            ))}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <h5 className="text-center">
                                        No Archive Session available
                                    </h5>
                                )}

                                <div className="row">
                                    <div className="col-md-10">
                                        <div
                                            className="btn-group filter-next-prev next-prev-session-day-list"
                                            style={{ display: "flex" }}
                                            role="group"
                                        >
                                            <button
                                                onClick={() => setPage(page - 1)}
                                                className="btn next"
                                                type="button"
                                                disabled={page == 0 ? true : false}
                                            >
                                                <img
                                                    className="pr-2"
                                                    src="../resources/assets/arrow-left.svg"
                                                    alt=""
                                                />
                                            </button>
                                            <div className="action-devider-button"></div>
                                            <button
                                                onClick={() => setPage(page + 1)}
                                                className="btn prev"
                                                type="button"
                                                disabled={totalPage == page + 1 || totalPage == 0 ? true : false}
                                            >
                                                <img
                                                    className="pl-2"
                                                    src="../resources/assets/arrow-right.svg"
                                                    alt=""
                                                />
                                            </button>
                                            <Link
                                            to={`/therapy-sessions`}
                                            style={{ borderRadius:".25rem" }}
                                            className="btn btn-success ml-3"
                                        >
                                            Back
                                        </Link>

                                        </div>
                                    </div>
                                </div>
                                {/* {modalOpenClose && (
          <DeleteSession
            successSub={() => successSub()}
            closeModal={() => setModalOpenClose(false)}
            sessioninfo={deletesessiondetail}
          />
        )}
        {modalOpenClose1 && (
          <EditSessionRequest
            isdelete={iscancelreq}
            successSub={() => successSub()}
            closeModal={() => setModalOpenClose1(false)}
            sessioninfo={deletesessiondetail}
          />
        )} */}
                                {watchvideo && (
                                    <WatchVideo
                                        recordingList={recordingList}
                                        closeModal={() => setWatchvideo(false)}
                                        sessioninfo={deletesessiondetail}
                                    />
                                )}
                            </div>
                        }
                    </div>

                </div>
            </div>
        </>
    );
}

export default ArchiveTherapySessionTable;