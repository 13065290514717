import { getUTCDateTimeToLocalByDateWithMeridium24 } from "./commonFunctions";
import moment from "moment";
function sortSessionByTime(res) {
  const list = res.data.content.filter(Boolean);
  let sortedList = [];
  if (list.length > 0) {
    sortedList = list.map((dataList) => {
      if (dataList.length > 0) {
        dataList.map((data) => {
          data.therapySession.sort(
            (a, b) => new Date(a.startDateTime) - new Date(b.startDateTime)
          );
          const inProgressList = data.therapySession.filter(
            (item) =>
              item.status == "IN_PROGRESS" &&
              getUTCDateTimeToLocalByDateWithMeridium24(item.endDateTime) >=
                getUTCDateTimeToLocalByDateWithMeridium24(new Date())
          );
          const upComingList = data.therapySession.filter(
            (item) => item.status == "UP_COMING"
          );
          const otherList = data.therapySession.filter(
            (item) =>
              item.status !== "UP_COMING" && item.status !== "IN_PROGRESS"
          );
          const inProgressList1 = data.therapySession.filter(
            (item) =>
              item.status == "IN_PROGRESS" &&
              getUTCDateTimeToLocalByDateWithMeridium24(item.endDateTime) <
                getUTCDateTimeToLocalByDateWithMeridium24(new Date())
          );

          const newList = inProgressList.concat(
            upComingList,
            otherList,
            inProgressList1
          );
          data.therapySession = newList;

          if (inProgressList.length > 0) {
            data.order_id = 1;
          } else if (upComingList.length > 0) {
            data.order_id = 2;
          } else {
            data.order_id = 3;
          }
          return data;
        });
      }
      dataList.sort((a, b) => (a.order_id < b.order_id ? -1 : 0));

      const o1 = dataList.filter((f) => f.order_id == 1);
      const o2 = dataList.filter((f) => f.order_id == 2);
      const o3 = dataList.filter((f) => f.order_id == 3);
      o1.sort((a, b) => (a.date < b.date ? -1 : 0));
      o2.sort((a, b) => (a.date < b.date ? -1 : 0));

      dataList = o1.concat(o2, o3);
      // console.log("dataList----1111", dataList);

      return dataList;
    });

    return sortedList;
  }

  return res.data.content;
}

export default sortSessionByTime;
