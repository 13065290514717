const CreateTemplate = (formRef) => {
  const html = `
<html>
<head>
  <style>
  input[type="checkbox"]{
    vertical-align: bottom;
position: relative;
top: 5px;
  }
  .w-100per{
    width: 100%
  }
  .text-dcor-underline{
    text-decoration: underline;
  }
  .form-input{
    height: 18px;
    border: none;
    display: inline-block;
    border-bottom: 1px solid;
    border-radius: 0px;
    font-size: 16px;
    background: white;
    padding: 0px;
  }
  .form-input-w-50per {
    width: 50%;
  }
  .form-input-w-60per{
    width: 60%;
  }
  .form-input-w-37per{
    width: 37%;
  }
  .form-input-w-120px {
    width: 120px;
  }
  button{
      display: none
  }
    .p-case-note-detail__info-label {
        font-weight: bold;
        margin-top: 0px;
    }
    .p-case-note-detail__info-value {
        margin-top:-5px;
    }
    .sign-img {
        margin-top:15px;
        // margin-bottom: 15px;
        // width: 150px !important;
        width: 100% !important;
        // height: 50px !important;
        object-fit: contain;
        // border: 1px solid rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        padding-top: 5px;
    }
    // .p-case-note-detail__user img {
    //     // width: 60px;
    //     // height: 60px;
    //     object-fit: cover;
    //     border-radius: 50%;
    //     margin-right: 10px;
    // }
    p {
        word-wrap: break-word;
        width: 200px;
    }
    tr {
        margin-bottom: 15px;
    }
    td{
        vertical-align: top;
        width: 33%;
        padding-left: 5px;
        padding-right: 5px;                            
      }
    // .c-casenote-billing-info__image {
    //     // width: 250px;
    //     // height: 70px;
    //     object-fit: contain;
    // }
    .p-case-note-detail__info-value{
        width:100%;
    }
  </style>                        
</head>
    <body>
        ${formRef.current.outerHTML}
    <body>
</html>
`;
  return html;
};
export default CreateTemplate;
