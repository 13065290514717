import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UserForm from "./UserForm";
import { toast } from "react-toastify";
//import UploadFile from "../../../components/Utils/UploadFile";
//import { ReqField, ErrorMsg, apiUrl } from "../../../Config";
//import Service from "../../../Service";
import { adminService } from "../../../services/adminService";
import appAction from "../../../redux/actions/appAction";

function AddEditOtherUser() {
  const user = localStorage.getItem("superadmin")
    ? JSON.parse(localStorage.getItem("superadmin"))
    : {};

  const dispatch = useDispatch();
  let params = useParams();
  const { id, tenantId } = params;
  let history = useNavigate();
  const [profileImage, setProfileImage] = useState("");
  const [profilePicKeyType, setProfilePicKeyType] = useState(false);

  const [initialData, setInitialData] = useState({
    loading: false,
    error: "",
    data: "",
    imageUploading: false,
  });

  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    profileImage: "",
    about: "",
  });

  const [formError, setFormError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    profileImage: "",
    username: "",
    password: "",
    gender: "",
    about: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("add marketing",value,name)
    //setMob
    if (value.length > 10 && name == "phone") {
      //alert("ok");
      const fix = value.slice(0, 10);
      console.log(fix);
      setInputs({ ...inputs, [name]: fix });
    } else {
      setInputs({ ...inputs, [name]: value });
    }
  };

  const onSubmit = (data) => {
    //console.log("good", data);

    setInitialData((data) => ({ ...data, loading: true }));

    const formdata = {
      about: inputs?.about,
      userUniqueId: id,
      email: data?.email,
      firstName: data?.firstName,
      gender: data?.gender,
      languageIds: [1],
      lastName: data?.lastName,
      phone: data?.phone,
      profilePicKey: inputs?.profilePicKey || null,
      signature: "string",
      tenantId: tenantId,
      userRole: "ROLE_RCE",
    };
    console.log("fdata", formdata);
    //return false;
    let APIEdit = adminService.PUT(
          "marketing-registrations/" + id,
          JSON.stringify(formdata)
        );
    let API =  adminService.POST(
          "marketing-registrations",
          JSON.stringify(formdata)
        );
      if(id){
      APIEdit.then((res) => {
      setInitialData((data) => ({ ...data, loading: false }));
      //console.log("Login sucess res", res.data);
      if (res?.status === 'Success') {
        history("/marketing-user/");
        toast.success("Details Updated Successfully");
      } else {
        toast.error(res?.message);
      }
    }).catch((err) => {
      console.log(err.response);
      setInitialData((data) => ({ ...data, loading: false }));
      if (err?.response) {
        if (err.response.code === 401) {
          toast.error("Something went wrong.");
          return;
        }
      }
      //alert("ok");
      toast.error(err?.response?.message || "Something went wrong.");
    });
  }else{
    API.then((res) => {
      setInitialData((data) => ({ ...data, loading: false }));
      //console.log("Login sucess res", res.data);
      if (res?.status === 'Success') {
        history("/marketing-user/");
        toast.success("User Added Successfully");
      } else {
        toast.error(res?.message);
      }
    }).catch((err) => {
      console.log(err.response);
      setInitialData((data) => ({ ...data, loading: false }));
      if (err?.response) {
        if (err.response.code === 401) {
          toast.error("Something went wrong.");
          return;
        }
      }
      //alert("ok");
      toast.error(err?.response?.message || "Something went wrong.");
    });
  }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    let reader = new FileReader();
    let file = e.target.files[0];
    let FileSize = e.target.files[0].size / 1024 / 1024; // in MB
    if (file.name.match(/.(jpg|jpeg|jpeg2000|png|svg)$/i)) {
      if (FileSize > 2) {
        toast.warn("Please upload file having size MAX 2MB");
      } else {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          file.url = reader.result;
          setInputs((inputs) => ({ ...inputs, ["profileImage"]: file }));
          let formData = new FormData();
          formData.append("imageFile", file);
          dispatch(appAction.setAppLoading(true));
          adminService
            .POSTUploadImage(`upload/image`, formData)
            .then(
              (res) => {
                if (res.status === "Success") {
                  file.isnew = true;
                  setProfileImage(file);
                  setInputs((inputs) => ({
                    ...inputs,
                    ["profilePicKey"]: res.data.fileKey,
                  }));
                  setProfilePicKeyType(false);
                } else {
                  toast.error(res.message);
                }
              },
              (error) => {
                console.log("Error >>>>>>>", error);
              }
            )
            .finally(() => {
              dispatch(appAction.setAppLoading(false));
            });
        };
      }
    } else {
      toast.warn("Please upload file having extensions - JPG,JPEG,PNG,SVG");
    }
  };
//   const handleImageChange = (e) => {
//     e.preventDefault();
//     const { name, value } = e.target;
//     const up = UploadFile(e, setInputs);

//     if (up) {
//       setInputs((item) => ({
//         ...item,
//         preView: URL.createObjectURL(e.target.files[0]),
//       })); //set preView

//       let fileInput = e.target;
//       let myHeaders = new Headers();
//       myHeaders.append("Authorization", "Bearer " + user.accessToken);
//       myHeaders.append("SessionId", user.sessionId);

//       var formdata = new FormData();
//       formdata.append("imageFile", fileInput.files[0]);

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: formdata,
//         redirect: "follow",
//       };

//       setInitialData((item) => ({ ...item, imageUploading: true }));

//       fetch(apiUrl + "/upload/profile-pic-upload", requestOptions)
//         .then((response) => response.text())
//         .then((result) => {
//           setInitialData((item) => ({ ...item, imageUploading: false }));
//           let res = JSON.parse(result);
//           console.warn("Uploaded", res.code);
//           if (res.code === 200) {
//             setInputs((item) => ({
//               ...item,
//               [name]: res?.data?.fileKey,
//               profilePicKey: res?.data?.fileKey,
//             }));
//           } else {
//             toast.error("Something went wrong.");
//           }
//         })
//         .catch((error) => {
//           console.warn("error", error);
//           toast.error("Something went wrong.");
//           setInitialData((item) => ({ ...item, imageUploading: false }));
//         });
//     }
//   };

  const getAdminData = (id) => {
    setInitialData((data) => ({ ...data, loading: true }));
    adminService.getAll("marketing-registrations/" + id)
      .then((res) => {
        setInitialData((data) => ({ ...data, loading: false }));
        console.log("Get single Other user", res.data);
        if (res?.code === 200) {
          setInputs((item) => ({
            ...item,
            tenantId: tenantId,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            gender: res.data.gender,
            phone: res.data.phone,
            about: res.data.about,
            profileImage: res.data.profilePicKey,
            profilePicKey: '',
            userUniqueId: res.data.userUniqueId,
          }));
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setInitialData((data) => ({ ...data, loading: false }));
        if (err?.response) {
          if (err.response.code === 401) {
            toast.error("Something went wrong.");
            return;
          }
        }

        toast.error("Something went wrong.");
      });
  };

  useEffect(() => {
    if (id) {
      getAdminData(id);
    }
  }, []);

  return (
    <>
    <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
      <div className="card border-none">
        <div className="row p-10">
          <div className="col-md-6">
            <h4>{id ? "Edit" : "Add"} Other User </h4>
          </div>
        </div>
        <hr className="mt-0" />
        <div className="col-md-12">
          <UserForm
            initialData={initialData}
            //setInitialData={setInitialData}
            onSubmit={onSubmit}
            handleChange={handleChange}
            inputs={inputs}
            profileImage={profileImage}
            handleImageChange={handleImageChange}
            isOnboard={false}
            formError={formError}
            setFormError={setFormError}
          />
        </div>
      </div>
      </div>
      </div>
      </div>
    </>
  );
}

export default AddEditOtherUser;
