import React from 'react';
import TherapistSetAvailability from './OnBoardingUser/TherapistSetAvailability';

function EditTherapistAvailability() {
    return (
        <>
            <div className="col-md-10 no-padding">
                <div className="no-padding dash-billing-info mr-0">
                    <div className="custom-table-view">
                        <TherapistSetAvailability /> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditTherapistAvailability;