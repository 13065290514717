import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminService } from "../../../services/adminService";
import { IoIosTrash, IoMdCreate } from "react-icons/io";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "react-responsive-pagination";
import { useSelector } from "react-redux";

function PendingFamilyList(props) {
  // const { families } = props;
  const history = useNavigate();
  const profile = useSelector((state) => state.adminUser.profile);
  const [families, setFamilies] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setloading] = useState(true);
  const [search, setSearch] = useState("");
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  useEffect(() => {
    profile?.userType && getFamilyList();
  }, [currentPage, profile?.userType]);

  const getFamilyList = () => {
    let url =
      profile?.userType == "ROLE_THERAPIST"
        ? `families/pending-onboarding?page=${currentPage == 0 ? currentPage : currentPage - 1
        }&size=10&sort=id,DESC`
        : `families/filter?page=${currentPage == 0 ? currentPage : currentPage - 1
        }&pendingOnboarding=true&size=10&familyMemberName=${search}&sort=id,DESC`;

    adminService.getAll(url).then(
      (res) => {
        if (res?.status === "Success") {
          setFamilies(res.data.content);
          setTotalPages(res.data.totalPages);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const deleteUser = (id) => {
    adminService.DELETE(`families/${id}`).then(
      (res) => {
        if (res.status === "Success") {
          setConfirmDeletion(false);
          toast.success(res.message);
          getFamilyList();
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <div className="row no-gutters">
        {/* <div className="col-md-12">
          <form>
            <div className="row">
              <div className="col-md-6 justify-content-between align-items-center mt-4 mb-4 d-flex">
                <div className="col-auto w-75">
                  <input
                    name="text"
                    className="form-control search"
                    placeholder="Search by Name"
                    onChange={(e) => setSearch(e.target.value)}
                    autoFocus
                  />
                </div>
                
                  <div className="col-auto">
                    <select
                      className="form-control time h-46"
                      onClick={(e) => setStatus(e.target.value)}
                      id="exampleFormControlSelect1"
                    >
                      <option className="form-control time" value="">
                        All
                      </option>
                      <option
                        className="form-control time"
                        value="ROLE_THERAPIST"
                      >
                        Therapist
                      </option>
                      <option
                        className="form-control time"
                        value="ROLE_INTERPRETER"
                      >
                        Interpreter
                      </option>
                      <option className="form-control time" value="ROLE_FAMILY">
                        Family
                      </option>
                    </select>
                  </div>

                <div className="col-auto">
                  <button
                    className="btn btn-theme-green"
                    type="button"
                    onClick={() => getFamilyList()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="32"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-2"></div>
              <div className="col-md-2"></div>
            </div>
          </form>
        </div> */}
        {confirmDeletion && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={() => deleteUser(confirmDeletion?.id)}
            onCancel={() => setConfirmDeletion(false)}
            focusCancelBtn
          >
            You want to delete user "{confirmDeletion?.userProfile?.firstName}".
          </SweetAlert>
        )}
        <div className="col-md-12 therapist p-0">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Caretaker Member</th>
                  <th scope="col">User type</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {families.length > 0 &&
                  families &&
                  families.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            {item.userProfile.firstName +
                              " " +
                              item.userProfile.lastName}
                          </td>
                          <td>
                            {item.userProfile.userType == "ROLE_FAMILY"
                              ? "Caretaker"
                              : item.userProfile.userType}
                          </td>
                          <td>{item.userProfile.email}</td>
                          <td>{item.userProfile.phone}</td>
                          <th>
                            <button
                              className="btn btn-theme-info btn-sm  mb-10"
                              onClick={() =>
                                history(`/edit-family/${item.id}`)
                              }
                            >
                              <IoMdCreate />
                            </button>
                            <button
                              className="btn btn-theme-info btn-sm  mb-10"
                              onClick={() => setConfirmDeletion(item)}
                            >
                              <IoIosTrash />
                            </button>
                          </th>
                          {/* {item.familyMembers &&
                            item.familyMembers.map((subitem, index) => {
                              return (
                                <>
                                  <>
                                    <th>
                                      <div className="user-info">
                                        <span className="admin-name">
                                          <img
                                            src={
                                              subitem.profileUrl
                                                ? subitem.profileUrl
                                                : "/resources/assets/defaultprofile.png"
                                            }
                                            alt="Profile"
                                            className="image mr-2"
                                          />
                                          <div className="content">
                                            <span className="name name-table">
                                              {subitem.firstName}{" "}
                                              {subitem.lastName}
                                            </span>
                                          </div>
                                        </span>
                                      </div>
                                    </th>
                                    <td>
                                      <div className="name-table">
                                        {item.userProfile.firstName}{" "}
                                        {item.userProfile.lastName}
                                      </div>
                                    </td>
                                    <td>Edit</td>
                                  </>
                                </>
                              );
                            })} */}
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {families.length == 0 && (
              <h5 className="text-center p-3">No Data Found</h5>
            )}
            <Pagination
              total={totalPages}
              current={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PendingFamilyList;
