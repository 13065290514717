
import { adminService } from '../services/adminService';

export const adminActions = {
    login,
    logout,
    resetPassword,
    uploadImage,
};

function login(email, password) {
    return adminService.login(email, password)
    .then(
        response => { 
            return response;
        },
        error => {
            
        }
    );
}

function resetPassword(dataobj) {
    return adminService.resetPassword(dataobj)
    .then(
        response => { 
            return response;
        },
        error => {
            
        }
    );
}


function logout() {
    adminService.logout();
    
}


function uploadImage(obj){
    return adminService.POSTUploadImage("therapists",obj)
    .then(
        response => { 
            return response;
        },
        error => {
            console.log(error);
        }
    );
}