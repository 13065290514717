import React, { useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { adminService } from "../../services/adminService";

function EditChildBioModal(props) {
  const [inputs, setInputs] = useState({
    id: props?.childBioModal?.id,
    about: props?.childBioModal?.about,
    allergies: props?.childBioModal?.allergies,
    precautions: props?.childBioModal?.precautions,
    medications: props?.childBioModal?.medications,
  });
  const data = props?.childBioModal;
  console.log("props", props?.childBioModal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onSubmit = (e) => {
    if (inputs.about) {
      console.log("dataaa", inputs);
      adminService
        .PUT(
          `/families/family-member/${props?.childBioModal?.id}`,
          JSON.stringify(inputs)
        )
        .then(
          (res) => {
            if (res?.status == "Success") {
              props?.setLoadingChildList(true);
              props?.setChildBioModal(false);
              toast.success("Bio updated Successfully!");
            } else {
              res?.message && toast.error(res?.message);
            }
          },
          (error) => {
            console.log("Error >>>>>>>", error);
          }
        )
        .finally(() => {
          props.getChildList();
        });
    } else {
      toast.error("Please add about");
    }
  };

  return (
    <div className="col-md-2 custom-modal">
      <div
        className={classNames("modal fade overflow-auto", {
          show: props?.childBioModal ? true : false,
        })}
        tabIndex="-1"
        role="dialog"
        aria-hidden={props?.childBioModal ? true : false}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <div className="w-100 text-center">
                <h5 className="modal-title">
                  {data?.firstName + " " + data?.lastName}
                </h5>
              </div>
              <button
                onClick={() => props?.setChildBioModal(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <div className="close-background">
                  <span aria-hidden="true">&times;</span>
                </div>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb-10">
                    <label>About</label> (
                    <span className="required-span">*</span>)
                    <textarea
                      value={inputs.about}
                      name="about"
                      rows="4"
                      onChange={(e) => handleChange(e)}
                      className={`form-control h-100`}
                      required={true}
                      placeholder="Client's favorite activities and foods/ likes /dislikes etc."
                    ></textarea>
                  </div>
                  {inputs?.about.trim() == "" && (
                    <div className="invalid-feedback-custom">
                      About is required
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-20">
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <label>Allergies</label>
                    <textarea
                      maxlength="500"
                      defaultValue={inputs?.allergies}
                      name="allergies"
                      rows="3"
                      onChange={(e) => handleChange(e)}
                      className={`form-control h-100`}
                      placeholder="Enter Allergies"
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <label>Precautions</label>
                    <textarea
                      maxlength="500"
                      defaultValue={inputs?.precautions}
                      name="precautions"
                      rows="3"
                      onChange={(e) => handleChange(e)}
                      className={`form-control h-100`}
                      placeholder="Enter Precautions"
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <label>Medications</label>
                    <textarea
                      maxlength="500"
                      defaultValue={inputs?.medications}
                      name="medications"
                      rows="3"
                      onChange={(e) => handleChange(e)}
                      className={`form-control h-100`}
                      placeholder="Enter Medications"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => props?.setChildBioModal(false)}
                  className="btn btn-default mt-3"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => onSubmit()}
                  className="btn btn-primary mt-3"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditChildBioModal;
