import { emogiTypes } from "../types";

const initialState = {
    visible: []
}
const emojiReducer =  (state = initialState, action = {}) => {
    switch (action.type) {
        case emogiTypes.showEmoji:
            return {
                ...state,
                visible: [...state.visible, action.payload]
            }
            
            case emogiTypes.hideEmoji: 
            return {
                ...state,
                visible: state.visible.filter(emoji => emoji.timestamp !== action.payload.timestamp )
            }
        default:
            return state;
    }
}
export default emojiReducer;
