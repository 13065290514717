import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { adminService } from "../../../services/adminService";
import allActions from "../../../redux/actions";
import { monthJson } from "../../../config/Config";
import moment from "moment-timezone";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import appAction from "../../../redux/actions/appAction";
import InputMask from "react-input-mask";
import { getNumberFromMask } from "../../../utils/commonFunctions";
import { nameRegex } from "../../../utils/regex";

function FamilyChildrenInsuranceInfo(props) {
  const history = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  let today_date = new Date();
  today_date = moment(today_date).format("YYYY-MM-DD");
  const dispatch = useDispatch();
  let params = useParams();
  const [loading, setloading] = useState(false);
  let familyForm = useSelector((state) => state.adminUser.familyForm);
  let jsonFound = familyForm.hasOwnProperty("familyMemberForms");
  const [familyMembers, setFamilyMembers] = useState(
    jsonFound ? familyForm.familyMemberForms : []
  );
  const [insuranceDetails, setInsuranceDetails] = useState({
    payeeName: "",
    payeePhoneNumber: "",
    payeeAddress: "",
    insurancePlan: "",
    groupNumber: "",
    policyNumber: "",
    insuranceType: "",
  });

  const [inputs, setInputs] = useState({
    payeeName: "",
    payeePhoneNumber: "",
    payeeAddress: "",
  });
  const [deletedInsuranceDetailIds, setDeletedInsuranceDetailIds] = useState(
    []
  );

  useEffect(() => {
    if (params.id) {
      setloading(true);
      dispatch(appAction.setAppLoading(true));
      adminService
        .getById(`families/` + params.id + `/family-member/` + params.childid)
        .then(
          (res) => {
            //setFamily(res.data);
            setFamilyMembers(res.data.familyMembers[0]);
            dispatch(
              allActions.adminAction.setFamilyFormData(
                res.data.familyMembers[0],
                "familyMemberForms"
              )
            );
            setloading(false);
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    }
  }, []);

  const onSubmitNext = () => {
    if (familyMembers.length === 0) {
      toast.warn("Please add child information");
    } else {
      if (params.id) {
      } else {
        dispatch(
          allActions.adminAction.setFamilyFormData(
            familyMembers,
            "familyMemberForms"
          )
        );
        props.handleNext();
        // console.log("object--022", familyMembers);
      }
    }
  };

  const onSubmitSaveAndBack = () => {
    const childList = [];
    const familyMemberUpdateForms = [];
    let formData = new FormData();
    // formData.append("profileImage", familyForm.userProfileForm.profileImage);
    const userBasicInfo = {
      firstName: familyForm?.userProfileForm?.firstName,
      lastName: familyForm?.userProfileForm?.lastName,
      email: familyForm?.userProfileForm?.email,
      phone: familyForm?.userProfileForm?.phone,
      about: "",
      gender: familyForm?.userProfileForm?.gender,
    };
    familyForm?.familyMemberForms.length > 0 &&
      familyForm?.familyMemberForms.map((member) => {
        const data = {
          id: member?.id || null,
          firstName: member.firstName,
          lastName: member.lastName,
          dob: member.dob,
          gender: member.gender,
          about: member.about,
          profilePicKey: member.profilePicKey,
          // insuranceDetailForms: member.insuranceDetailForms,
        };
        if (member?.id) {
          // data.newInsuranceDetailForm = member.insuranceDetailForms;

          const newIns = [];
          const haveIns = [];

          if (member?.insuranceDetailForms) {
            member.insuranceDetailForms.map((ins) => {
              ins?.id ? haveIns.push(ins) : newIns.push(ins);
            });
          }
          data.newInsuranceDetailForm = newIns;
          data.insuranceDetailUpdateForms = haveIns;

          const delIdList = [];
          deletedInsuranceDetailIds.length > 0 &&
            deletedInsuranceDetailIds.map((del) => {
              del.childId == member?.id && delIdList.push(del.delId);
            });
          data.deletedInsuranceDetailIds = delIdList;
          familyMemberUpdateForms.push(data);
        } else {
          data.insuranceDetailForms = member.insuranceDetailForms;
          childList.push(data);
        }
      });
    if (props?.familyData) {
      formData.append(
        "familyUpdateForm",
        new Blob(
          [
            JSON.stringify({
              languageIds: familyForm?.languageIds,
              relationship: familyForm?.relationship,
              userProfileForm: userBasicInfo,
              pendingOnboarding: true,
              newFamilyMemberForms: childList,
              familyMemberUpdateForms,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    } else {
      formData.append(
        "familyForm",
        new Blob(
          [
            JSON.stringify({
              languageIds: familyForm?.languageIds,
              relationship: familyForm?.relationship,
              userProfileForm: userBasicInfo,
              familyMemberForms: childList,
              pendingOnboarding: true,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }
    console.log("deletedInsuranceDetailIds", deletedInsuranceDetailIds);
    dispatch(appAction.setAppLoading(true));
    if (!props?.familyData) {
      adminService
        .onboardUser(`families`, formData)
        .then(
          (res) => {
            console.log("object", res);
            if (res?.data?.id) {
              history("/pending-users-onboarding");
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    } else {
      adminService
        .update(`/families/` + props?.familyData.id, formData)
        .then(
          (res) => {
            if (res?.status === "Success") {
              history("/pending-users-onboarding");
            } else {
              toast.error(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          dispatch(appAction.setAppLoading(false));
        });
    }
  };

  const addInsuranceInformation = (fname, lname) => {
    if (fname && lname) {
      let familyMembersObj = [...familyMembers];
      var payeeNumber = getNumberFromMask(insuranceDetails.payeePhoneNumber);
      let fileindex1 = familyMembersObj.findIndex(
        (obj) => obj.firstName === fname && obj.lastName === lname
      );

      let insuranceDetailFormsArray =
        familyMembersObj[fileindex1].insuranceDetailForms;
      let fileindex = insuranceDetailFormsArray.findIndex(
        (obj) => obj.policyNumber == insuranceDetails.policyNumber
      );
      if (
        !insuranceDetails.policyNumber ||
        !insuranceDetails.payeeAddress ||
        !insuranceDetails.payeeName ||
        !insuranceDetails.payeePhoneNumber ||
        !insuranceDetails.groupNumber ||
        !insuranceDetails.insurancePlan ||
        !insuranceDetails.insuranceType
      ) {
        toast.warn("Please enter all required insurance information");
      } else if (payeeNumber.length !== 10) {
        toast.warn("Phone number should be 10 digit");
        return;
      } else if (fileindex !== -1) {
        toast.warn("This policy is already added, please add another policy");
      } else {
        let insuranceInformation = {
          payeeAddress: insuranceDetails.payeeAddress,
          payeeName: insuranceDetails.payeeName,
          payeePhoneNumber: insuranceDetails.payeePhoneNumber,
          policyNumber: insuranceDetails.policyNumber,
          groupNumber: insuranceDetails.groupNumber,
          insurancePlan: insuranceDetails.insurancePlan,
          insuranceType: insuranceDetails.insuranceType,
        };
        var insu = [...insuranceDetailFormsArray, insuranceInformation];
        //insuranceDetailFormsArray.push(insuranceInformation);
        familyMembersObj[fileindex1].insuranceDetailForms = insu;

        dispatch(
          allActions.adminAction.setFamilyFormData(
            familyMembersObj,
            "familyMemberForms"
          )
        );

        setInsuranceDetails({
          payeeName: "",
          payeePhoneNumber: "",
          payeeAddress: "",
          insurancePlan: "",
          groupNumber: "",
          policyNumber: "",
          insuranceType: "",
        });
        setInputs({});
      }
    }
  };

  console.log("familyForm", familyForm.familyMemberForms, familyMembers);

  const removeInsuranceInformation = (fname, lname, policyNumber, index) => {
    let familyMembersObj = [...familyMembers];
    let familyMembersObj123 = [...familyMembers];
    let fileindex1 = familyMembersObj.findIndex(
      (obj) => obj.firstName === fname && obj.lastName === lname
    );
    let fileindex2 = familyMembersObj123.findIndex(
      (obj) => obj.firstName === fname && obj.lastName === lname
    );
    const delId =
      familyMembersObj123[fileindex2].insuranceDetailDtoList[index].id;
    const childId = familyMembersObj123[fileindex2].id;

    const delIds = [...deletedInsuranceDetailIds];
    delIds.push({ delId: delId, childId: childId });
    setDeletedInsuranceDetailIds(delIds);

    let insuranceDetailFormsArray =
      familyMembersObj[fileindex1].insuranceDetailForms;
    familyMembersObj[fileindex1].insuranceDetailForms =
      insuranceDetailFormsArray.filter(
        (item) => item.policyNumber !== policyNumber
      );

    dispatch(
      allActions.adminAction.setFamilyFormData(
        familyMembersObj,
        "familyMemberForms"
      )
    );
    setInputs({});
  };

  function change(e) {
    const { name, value } = e.target;
    setInsuranceDetails((insuranceDetails) => ({
      ...insuranceDetails,
      [name]: value,
    }));
  }
  useEffect(() => {
    dispatch(
      allActions.adminAction.setFamilyFormData(
        familyMembers,
        "familyMemberForms"
      )
    );
  }, [inputs]);

  useEffect(() => {
    if (props?.familyData) {
      let data =
        familyForm.familyMemberForms &&
        familyForm.familyMemberForms.map((d) => {
          d.insuranceDetailForms = d?.insuranceDetailDtoList || [];
          return d;
        });

      setFamilyMembers(data || []);
    }
  }, [props?.familyData]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="addtheripist">
            <label className="conetent-title">
              {params.id
                ? "Edit Insurance Information"
                : "Insurance Information"}
            </label>
            <form>
              <div className="row gutter-33 custom-nav-tabs">
                <div className="col-md-12 mt-0">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {familyMembers?.length > 0
                      ? familyMembers?.map((info, index) => (
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                index === 0 ? "active" : ""
                              }`}
                              id={`child-tab-${index + 1}`}
                              data-toggle="tab"
                              href={`#child${index + 1}`}
                              role="tab"
                              aria-controls={`child-${index + 1}`}
                              aria-selected="true"
                            >
                              Child {index + 1}
                            </a>
                          </li>
                        ))
                      : ""}
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    {familyMembers?.length > 0 ? (
                      familyMembers?.map((info, index) => (
                        <div
                          className={`tab-pane fade ${
                            index === 0 ? "show active" : ""
                          }`}
                          id={`child${index + 1}`}
                          role="tabpanel"
                          aria-labelledby={`child-tab-${index + 1}`}
                        >
                          <table className="table custom-table">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="col-auto">
                                    <div className="user-info user-info-table">
                                      <span className="admin-name justify-content-start">
                                        <img
                                          src={
                                            info?.profileImage?.url
                                              ? info?.profileImage?.url
                                              : info?.profileUrl
                                              ? info?.profileUrl
                                              : "/resources/assets/defaultprofile.png"
                                          }
                                          alt="Children Profile Picture"
                                          className="user-info-img"
                                        />
                                        <div className="content ml-3">
                                          <sapn className="info-name">
                                            {info.firstName} {info.lastName}
                                          </sapn>
                                          <sapn className="ml-5 info-dob">
                                            Gender -{" "}
                                            {info.gender === "NOT_DISCLOSED"
                                              ? "Other"
                                              : info.gender === "FEMALE"
                                              ? "Female"
                                              : "Male"}
                                          </sapn>
                                          <span className="ml-5 info-dob">
                                            Date of Birth -{" "}
                                            {
                                              monthJson[
                                                parseInt(
                                                  info.dob.split("-")[1]
                                                ) - 1
                                              ].name
                                            }{" "}
                                            {info.dob.split("-")[2]}{" "}
                                            {info.dob.split("-")[0]}
                                            {/* {info.dob} */}
                                          </span>
                                          <small>
                                            <br />
                                            {info.about}
                                          </small>
                                        </div>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="card-body">
                                    {info?.insuranceDetailForms &&
                                      info?.insuranceDetailForms.length !==
                                        3 && (
                                        <>
                                          <div className="row">
                                            <div className="col-md-4">
                                              <div className="form-group">
                                                <label>Payee Name</label>
                                                <input
                                                  value={
                                                    insuranceDetails.payeeName
                                                  }
                                                  name="payeeName"
                                                  //onChange={change}
                                                  type="text"
                                                  className={`form-control ${
                                                    errors.payeeName &&
                                                    "is-invalid"
                                                  }`}
                                                  placeholder="Enter Payee Name"
                                                  {...register('payeeName',{
                                                    onChange:(e)=>change(e),
                                                    maxLength: 30,
                                                    pattern: nameRegex,
                                                  })}
                                                />
                                                {errors.payeeName &&
                                                  errors.payeeName.type ===
                                                    "required" && (
                                                    <div className="invalid-feedback-custom">
                                                      Payee name is required
                                                    </div>
                                                  )}
                                                {errors.payeeName &&
                                                  errors.payeeName.type ===
                                                    "pattern" && (
                                                    <div className="invalid-feedback-custom">
                                                      Alphabetical characters
                                                      only
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="form-group">
                                                <label>
                                                  Payee/Claims Address
                                                </label>
                                                <input
                                                  // value={inputs.firstName}
                                                  value={
                                                    insuranceDetails.payeeAddress
                                                  }
                                                  name="payeeAddress"
                                                  onChange={change}
                                                  type="text"
                                                  className={`form-control ${
                                                    errors.payeeAddress &&
                                                    "is-invalid"
                                                  }`}
                                                  placeholder="Enter Payee/Claims Address"
                                                  //{...register({ maxLength: 40, pattern: nameRegex })}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-4">
                                              <div className="form-group">
                                                <label>
                                                  Payee Phone Number
                                                </label>
                                                <InputMask
                                                  mask="(999) 999-9999"
                                                  value={
                                                    insuranceDetails.payeePhoneNumber
                                                  }
                                                  type="tel"
                                                  name="payeePhoneNumber"
                                                  placeholder="Enter Payee Phone Number"
                                                  className={`form-control ${
                                                    errors.payeePhoneNumber &&
                                                    "is-invalid"
                                                  }`}
                                                  {...register('payeePhoneNumber',{onChange:(e)=>change(e),required:true})}
                                                  // input{...register({ minLength: 10, maxLength: 10, pattern: /\d+/ })}
                                                  //onChange={change}
                                                ></InputMask>
                                                {/* <input
                                                                            // value={inputs.firstName}
                                                                            value={insuranceDetails.payeePhoneNumber}
                                                                            name="payeePhoneNumber" onChange={change}
                                                                            type="number"
                                                                            className={`form-control ${errors.firstName && 'is-invalid'}`}
                                                                            placeholder="Enter Payee Phone Number"
                                                                            {...register({ minLength: 10, maxLength: 10, pattern: /\d+/ })}
                                                                        /> */}
                                                {errors.payeePhoneNumber &&
                                                  errors.payeePhoneNumber
                                                    .type === "required" && (
                                                    <div className="invalid-feedback-custom">
                                                      First name is required
                                                    </div>
                                                  )}
                                                {errors.pattern &&
                                                  errors.pattern.type ===
                                                    "pattern" && (
                                                    <div className="invalid-feedback-custom">
                                                      Alphabetical characters
                                                      only
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-4">
                                              <div className="form-group">
                                                <label>Insurance Plan</label>
                                                <input
                                                  // value={inputs.firstName}
                                                  value={
                                                    insuranceDetails.insurancePlan
                                                  }
                                                  name="insurancePlan"
                                                  onChange={change}
                                                  type="text"
                                                  className={`form-control`}
                                                  placeholder="Enter Insurance Plan"
                                                  // {...register({ maxLength: 20, pattern: nameRegex })}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="form-group">
                                                <label>Group Number</label>
                                                <input
                                                  // value={inputs.firstName}
                                                  onWheel={(event) => {
                                                    event.preventDefault();
                                                    if (
                                                      typeof event.target
                                                        .blur === "function"
                                                    ) {
                                                      event.target.blur();
                                                    }
                                                  }}
                                                  value={
                                                    insuranceDetails.groupNumber
                                                  }
                                                  name="groupNumber"
                                                  //onChange={change}
                                                  type="number"
                                                  className={`form-control ${
                                                    errors.groupNumber &&
                                                    "is-invalid"
                                                  }`}
                                                  placeholder="Enter Group Number"
                                                  {...register('groupNumber',{
                                                    onChange:(e)=>change(e),
                                                    pattern: /\d+/,
                                                  })}
                                                />
                                                {errors.groupNumber &&
                                                  errors.groupNumber.type ===
                                                    "required" && (
                                                    <div className="invalid-feedback-custom">
                                                      First name is required
                                                    </div>
                                                  )}
                                                {errors.pattern &&
                                                  errors.pattern.type ===
                                                    "pattern" && (
                                                    <div className="invalid-feedback-custom">
                                                      Alphabetical characters
                                                      only
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="form-group">
                                                <label>Policy Number</label>
                                                <input
                                                  // value={inputs.firstName}
                                                  value={
                                                    insuranceDetails.policyNumber
                                                  }
                                                  name="policyNumber"
                                                  //onChange={change}
                                                  type="number"
                                                  onWheel={(event) => {
                                                    event.preventDefault();
                                                    if (
                                                      typeof event.target
                                                        .blur === "function"
                                                    ) {
                                                      event.target.blur();
                                                    }
                                                  }}
                                                  className={`form-control ${
                                                    errors.policyNumber &&
                                                    "is-invalid"
                                                  }`}
                                                  placeholder="Enter Policy Number"
                                                  {...register('policyNumber',{
                                                    onChange:(e)=>change(e),
                                                    pattern: /\d+/,
                                                  })}
                                                />
                                                {errors.policyNumber &&
                                                  errors.policyNumber.type ===
                                                    "required" && (
                                                    <div className="invalid-feedback-custom">
                                                      Policy Number is required
                                                    </div>
                                                  )}
                                                {errors.pattern &&
                                                  errors.pattern.type ===
                                                    "pattern" && (
                                                    <div className="invalid-feedback-custom">
                                                      Alphabetical characters
                                                      only
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-4">
                                              <div className="form-group">
                                                <label>Type of Insurance</label>
                                                <div className="input-group">
                                                  <select
                                                    className={`custom-select ${
                                                      errors.insuranceType &&
                                                      "is-invalid"
                                                    }`}
                                                    onChange={change}
                                                    value={
                                                      insuranceDetails.insuranceType
                                                    }
                                                    name="insuranceType"
                                                  >
                                                    <option value="">
                                                      Select Type of Insurance
                                                    </option>
                                                    <option
                                                      value="PRIMARY"
                                                      disabled={`${
                                                        info?.insuranceDetailForms &&
                                                        info?.insuranceDetailForms.filter(
                                                          (item) =>
                                                            item.insuranceType ===
                                                            "PRIMARY"
                                                        ).length > 0
                                                          ? "disabled"
                                                          : ""
                                                      }`}
                                                    >
                                                      Primary
                                                    </option>
                                                    <option
                                                      value="SECONDARY"
                                                      disabled={`${
                                                        info?.insuranceDetailForms &&
                                                        info?.insuranceDetailForms.filter(
                                                          (item) =>
                                                            item.insuranceType ===
                                                            "SECONDARY"
                                                        ).length > 0
                                                          ? "disabled"
                                                          : ""
                                                      }`}
                                                    >
                                                      Secondary
                                                    </option>
                                                    <option
                                                      value="OTHER"
                                                      disabled={`${
                                                        info?.insuranceDetailForms &&
                                                        info?.insuranceDetailForms.filter(
                                                          (item) =>
                                                            item.insuranceType ===
                                                            "OTHER"
                                                        ).length > 0
                                                          ? "disabled"
                                                          : ""
                                                      }`}
                                                    >
                                                      Other
                                                    </option>
                                                  </select>
                                                </div>
                                                {errors.insuranceType &&
                                                  errors.insuranceType.type ===
                                                    "required" && (
                                                    <div className="invalid-feedback-custom">
                                                      Select Language
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-3">
                                              <button
                                                type="button"
                                                onClick={handleSubmit(() => {
                                                  addInsuranceInformation(
                                                    info.firstName,
                                                    info.lastName
                                                  );
                                                })}
                                                style={{ width: 180 }}
                                                className="btn btn-primary d-block custom-btn-green mb-2"
                                              >
                                                <img
                                                  className="pr-2"
                                                  src="../resources/assets/plus-icon-button.svg"
                                                  alt=""
                                                />
                                                Add Insurance
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      )}

                                    <div className="row">
                                      <div className="col-md-12 form-group mb-4">
                                        <label className="conetent-title">
                                          Added Insurance Information
                                        </label>
                                        {console.log("info", info)}
                                        <table className="table custom-table assigned-therapy">
                                          {info?.insuranceDetailForms?.length >
                                          0 ? (
                                            info?.insuranceDetailForms?.map(
                                              (infoInsurance, index) => (
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      className="border-top-0 insurance-table"
                                                      style={{
                                                        minWidth: 50,
                                                        maxWidth: 50,
                                                      }}
                                                    >
                                                      Payee Name <br />{" "}
                                                      {infoInsurance.id}--
                                                      <span>
                                                        {
                                                          infoInsurance.payeeName
                                                        }
                                                      </span>
                                                    </td>
                                                    <td
                                                      className="border-top-0 insurance-table"
                                                      style={{
                                                        minWidth: 50,
                                                        maxWidth: 50,
                                                      }}
                                                    >
                                                      Payee Address <br />{" "}
                                                      <span>
                                                        {
                                                          infoInsurance.payeeAddress
                                                        }
                                                      </span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      className="border-top-0 insurance-table"
                                                      style={{
                                                        minWidth: 50,
                                                        maxWidth: 50,
                                                      }}
                                                    >
                                                      Payee Phone <br />{" "}
                                                      <span>
                                                        {
                                                          infoInsurance.payeePhoneNumber
                                                        }{" "}
                                                      </span>
                                                    </td>
                                                    <td
                                                      className="border-top-0 insurance-table"
                                                      style={{
                                                        minWidth: 50,
                                                        maxWidth: 50,
                                                      }}
                                                    >
                                                      Insurance Plan <br />{" "}
                                                      <span>
                                                        {" "}
                                                        {
                                                          infoInsurance.insurancePlan
                                                        }{" "}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      className="border-top-0 insurance-table"
                                                      style={{
                                                        minWidth: 50,
                                                        maxWidth: 50,
                                                      }}
                                                    >
                                                      Group Number <br />{" "}
                                                      <span>
                                                        {
                                                          infoInsurance.groupNumber
                                                        }{" "}
                                                      </span>
                                                    </td>
                                                    <td
                                                      className="border-top-0 insurance-table"
                                                      style={{
                                                        minWidth: 50,
                                                        maxWidth: 50,
                                                      }}
                                                    >
                                                      Policy Number <br />{" "}
                                                      <span>
                                                        {
                                                          infoInsurance.policyNumber
                                                        }{" "}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      className="border-top-0 insurance-table"
                                                      style={{
                                                        minWidth: 50,
                                                        maxWidth: 50,
                                                      }}
                                                    >
                                                      Type of Insurance <br />{" "}
                                                      <span>
                                                        {
                                                          infoInsurance.insuranceType
                                                        }{" "}
                                                      </span>
                                                    </td>
                                                    <td
                                                      className="border-top-0 insurance-table"
                                                      style={{
                                                        minWidth: 50,
                                                        maxWidth: 50,
                                                      }}
                                                    ></td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      colSpan="4"
                                                      className="text-left"
                                                    >
                                                      <div className="d-flex">
                                                        <img
                                                          className="pl-3"
                                                          src="../resources/assets/action-icon-delete.svg"
                                                          className="cursor-pointer"
                                                          onClick={() =>
                                                            removeInsuranceInformation(
                                                              info.firstName,
                                                              info.lastName,
                                                              infoInsurance.policyNumber,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr className="h-2 bg-white">
                                                    <td colSpan="2"></td>
                                                  </tr>
                                                </tbody>
                                              )
                                            )
                                          ) : (
                                            <tr className="text-center">
                                              <td colSpan="2">
                                                <strong>No data found</strong>
                                              </td>
                                            </tr>
                                          )}
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ))
                    ) : (
                      <table>
                        <tbody>
                          <tr className="text-center">
                            <td colSpan="2">
                              <strong>No data found</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="row addtheripist">
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12 d-flex">
              <button
                type="button"
                className="btn  btn-primary d-block stepper-button-back"
                onClick={(e) => props.handleBack()}
              >
                Back
              </button>
              <button
                className="btn  btn-primary d-block stepper-button-next"
                onClick={onSubmitSaveAndBack}
              >
                Save & Return
              </button>
              <button
                className="btn  btn-primary d-block stepper-button-next"
                type="button"
                onClick={onSubmitNext}
              >
                Save & Continue
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FamilyChildrenInsuranceInfo;
