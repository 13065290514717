import React from "react";

function SelectLocationTreatment(props) {
  const { register, errors, handleChange, inputs, currentRole } = props;
  return (
    <>
      {(currentRole === "ROLE_RCE" ||
        currentRole === "ROLE_REGIONAL_EARLY_STEPS" ||
        currentRole === "ROLE_THERAPIST") && (
        <div className="col-md-12">
          <div className="form-group">
            <label>Location Of Treatment</label> (
            <span className="required-span">*</span>)
            <div className="row ml-2">
              <img
                src="../resources/assets/Icon material-location-on.svg"
                className="img-fluid mb-4"
                alt=""
              />
              <div className="col-md-10">
                <div className="input-group" style={{ marginLeft: 14 }}>
                  <select
                    {...register('locationOfTreatment',{onChange:(e)=>handleChange(e), required: true })}
                    className={`custom-select ${
                      errors.sessionType && "is-invalid"
                    }`}
                    //onChange={handleChange}
                    defaultValue={inputs.locationOfTreatment}
                    value={inputs.locationOfTreatment}
                    name="locationOfTreatment"
                  >
                    <option value="Teletherapy">Teletherapy</option>
                    <option value="Home">Home</option>
                    <option value="Clinic">Clinic</option>
                    <option value="Other">Other</option>
                  </select>
                  <div className="input-group-prepend">
                    <img
                      className="m-auto"
                      src="../resources/assets/Polygon 1.svg"
                      alt=""
                    />
                  </div>
                </div>
                <br />
              </div>
              {inputs.locationOfTreatment === "Other" && (
                <div className="col-md-10">
                  <div className="input-group" style={{ marginLeft: 35 }}>
                    <input
                      // value={inputs.firstName}
                      defaultValue={inputs.otherLocationText}
                      name="otherLocationText"
                      onChange={handleChange}
                      type="text"
                      className={`form-control ${
                        errors.otherLocationText && "is-invalid"
                      }`}
                      placeholder="Enter Location Of Treatment"
                      {...register('otherLocationText',{onChange:(e)=>handleChange(e), required: true })}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectLocationTreatment;
