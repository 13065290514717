import React, { useEffect, useState, useRef } from "react";
import { adminService } from "../../services/adminService";
import { useSelector,useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  CaseNoteHeader,
  ArchiveCaseNoteTableRow,
} from "../../components/case-notes";
import { getSharedCaseNotes } from "../../services/caseNoteServices";
import PaginationTabs from "../pagination/index";
import CaseNoteDownload from "../../components/case-note-detail/CaseNoteDownload";
import appAction from "../../redux/actions/appAction";
import ArchiveCaseNotePrint from "./ArchiveCaseNotePrint";
import DatePicker, { DateObject } from "react-multi-date-picker";
import moment from "moment";

const ArchiveCaseNotes = (props) => {
  const formRef = useRef(null);
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //const {from} = location.state;
  console.log("foo", location.btnName)
  const [currentTab, setCurrentTab] = useState(location.btnName ? location.btnName : 'own');
  const [state, setState] = useState({
    caseNotes: [],
    sharedCaseNotes: [],
    myCaseNotes: [],
    mySharedCaseNotes: [],
    isLoading: true,
    isSharedLoading: true,
    selected: {},
  });
  const [stateShared, setStateShared] = useState({
    caseNotes: [],
    sharedCaseNotes: [],
    myCaseNotes: [],
    mySharedCaseNotes: [],
    isLoading: true,
    isSharedLoading: true,
    selected: {},
  });
  const [isDownloading, setIsDownloading] = useState(false);
  // const [sharedState, setSharedState] = useState({
  //     sharedCaseNotes: [],
  //     mySharedCaseNotes: [],
  //     isSharedLoading: true,
  //     selected: {},
  // })
  const [archiveCaseNote,setArchiveCaseNote]=useState([]);
  const [isDownloadBtn, setDownloadBtn] = useState(false);
  //const [flag, setFlag] = useState(false);
  const [values, setValues] = useState([
    new DateObject().subtract(5, "days"),
    new DateObject()
  ])
  const [search, setSearch] = useState("");
  const [sharedSearch, setSharedSearch] = useState("");
  const [query, setQuery] = useState({
    totalPages: 0,
    currentPage: 0,
    pages: [],
    sortBy: "createdDate,DESC",
    perPage: 10,
  });
  const [queryShared, setQueryShared] = useState({
    totalPages: 0,
    currentPage: 0,
    pages: [],
    sortBy: "createdDate,DESC",
    perPage: 10,
  });
  // const [sharedQuery, setSharedQuery] = useState({
  //     totalPages: 0,
  //     currentPage: 0,
  //     pages: [],
  //     sortBy: 'startDateTime,DESC',
  //     perPage: 10,
  // })
  const user = useSelector((st) => st.adminUser.user)?.userDetails;
  const isTherepist = (user?.roles || []).includes("ROLE_THERAPIST");
  const isInterepreter = (user?.roles || []).includes("ROLE_INTERPRETER");
  const isFamily = (user?.roles || []).includes("ROLE_FAMILY");
  const isAdmin = (user?.roles || []).includes("ROLE_RCE");
  let jsonDates = JSON.stringify(values[0]);
  let jsonDatesTo = JSON.stringify(values[1]);
  let date = moment(parseInt(jsonDates)).format('YYYY-MM-DD');
  let dateTo = moment(parseInt(jsonDatesTo)).format('YYYY-MM-DD');
  const dateto = moment(parseInt(jsonDates)).format('MM/DD/YYYY');
  const datefrom = moment(parseInt(jsonDatesTo)).format('MM/DD/YYYY');
  // const diffTime = Math.abs(date1 - date2);
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  const date1 = new Date(dateto);
  const date2 = new Date(datefrom);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  console.log(diffTime + " milliseconds");
  console.log(diffDays + " days");
  const getArchiveApi = `startDateTime=${date}T00:00:00Z&endDateTime=${dateTo}T23:59:59Z&sort=createdDate,DESC`
  const getListApi = ({ currentPage, sortBy, perPage }, s = search) =>
    `archive/case-notes?name=${s}&page=${currentPage == 0 ? currentPage : currentPage - 1
    }&size=${perPage}&sort=${sortBy}`;
  let timeout;
  // /session-service/api/archive/case-notes

  const getSharedListApi = (
    { currentPage, sortBy, perPage },
    s = sharedSearch
  ) =>
    `archive/case-notes/shared?name=${s}&page=${currentPage == 0 ? currentPage : currentPage - 1
    }&size=${perPage}&sort=${sortBy}`;
    useEffect(() => {
      const url = `archive/case-notes?${getArchiveApi}`;
      //console.log("filter", filter);
      if (values.length === 2) {
        adminService
          .getAll(url)
          .then((res) => {
            //setDownloadBtn(false);
            if (res?.data) {
              setArchiveCaseNote(res?.data?.content);
              console.log("case note archive",res?.data?.content);
              // setPageInfo({
              //   totalPages: res?.data?.totalPages,
              //   page: res?.data?.number,
              // });
              if(res?.data?.content){
              setDownloadBtn(true);
            }
            }
          })
          .catch((e) => console.log("e", e));
      }
    }, [getArchiveApi,archiveCaseNote]);
  useEffect(() => {
    setState({
      ...state,
      isLoading: true,
    });

    getSoapNoteList();

    if (isTherepist) {
      setStateShared({
        ...stateShared,
        isLoading: true,
      });
      //  getSharedCaseNotes();
    }
  }, []);

  const getSoapNoteList = () => {
    adminService
      .getAll(getListApi(query, search))
      .then((res) => {
        setPageContent(res);
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        console.log({ err });
      });
  };

  const getSharedCaseNotes = () => {
    adminService
      .getAll(getSharedListApi(queryShared, sharedSearch))
      .then((res) => {
        setPageContentShared(res);
      })
      .catch((err) => {
        setStateShared({
          ...stateShared,
          isLoading: false,
        });
        console.log({ err });
      });
  };

  useEffect(() => {
    const caseNotes = [...state.myCaseNotes];
    setState((prevState) => ({ ...prevState, caseNotes }));
  }, [state.myCaseNotes]);

  useEffect(() => {
    setCurrentTab(location.btnName ? location.btnName : 'own');
    const caseNotes = [...stateShared.myCaseNotes];
    setStateShared((prevState) => ({ ...prevState, caseNotes }));
  }, [stateShared.myCaseNotes, currentTab]);

  const onSearch = () => {
    console.log("object", search, sharedSearch);
    console.log("currentTab", currentTab);
    if (currentTab === "own") {
      getSoapNoteList();
    }
    if (currentTab === "shared") {
      getSharedCaseNotes();
    }
  };

  useEffect(() => {
    setCurrentTab(location.btnName ? location.btnName : 'own');
    console.log("currentTab", currentTab, location.btnName);
    if (currentTab === "own") {
      getSoapNoteList();
    }
    if (currentTab === "shared") {
      getSharedCaseNotes();
    }
  }, [search, sharedSearch, currentTab]);

  const setPageContentShared = (res) => {
    if (res.data == null) {
      setStateShared({
        ...stateShared,
        myCaseNotes: [],
        isLoading: false,
      });
    }
    if (res.data?.content) {
      setStateShared({
        ...stateShared,
        myCaseNotes: res.data?.content,
        isLoading: false,
      });
      setQueryShared((q) => ({
        ...q,
        totalPages: res.data?.totalPages,
        pages: new Array(res.data?.totalPages).fill().map((_i, inx) => inx),
      }));
    }
  };

  const onPaginateFetch = (number) => {
    setState({
      ...state,
      isLoading: true,
    });
    adminService
      .getAll(
        getListApi(
          {
            ...query,
            currentPage: number,
          },
          search
        )
      )
      .then((res) => {
        setPageContent(res);
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        console.log({ err });
      });
  };

  const setPageContent = (res) => {
    if (res.data?.content) {
      setState({
        ...state,
        myCaseNotes: res.data?.content,
        isLoading: false,
      });
      setQuery((q) => ({
        ...q,
        totalPages: res.data?.totalPages,
        pages: new Array(res.data?.totalPages).fill().map((_i, inx) => inx),
      }));
    }
  };

  const paginate = (number) => {
    console.log("number", number);
    setQuery((q) => ({
      ...q,
      currentPage: number,
    }));
    onPaginateFetch(number);
  };
  const paginatePre = () => {
    paginate(query.currentPage - 1);
  };
  const paginateNext = () => {
    paginate(query.currentPage + 1);
  };

  // const onSelect = (caseNote) => {
  //     setState({
  //         ...state,
  //         selected: caseNote
  //     })
  // }
  const onPdfCreate = (cb) => {
    setIsDownloading(true);
    dispatch(appAction.setAppLoading(true));
    setTimeout(() => {
      if (typeof cb === "function") {
        cb();
      }
    }, 200);
  };

  const onPdfComplete = () => {
    setIsDownloading(false);
    dispatch(appAction.setAppLoading(false));
  };
  const TableHeader = () => {
    return (
      <div className="p-case-notes__table-header">
        <div className="p-case-notes__table-head-col cn-tbl-header">
          Client Name
        </div>
        <div className="p-case-notes__table-head-col cn-tbl-header">
          Session Date
        </div>
        <div className="p-case-notes__table-head-col cn-tbl-header">
          Therapy
        </div>
        <div className="p-case-notes__table-head-col cn-tbl-header">
          Therapist
        </div>
        <div
          className="p-case-notes__table-head-col cn-tbl-header"
          style={{ flex: "1 1 45px" }}
        >
          Status
        </div>
        <div
          className="p-case-notes__table-head-col cn-tbl-header"
          style={{ flex: "0 1 110px" }}
        >
          Action
        </div>
      </div>
    );
  };

  return (
    <div className="p-case-notes col-md-10 d-flex flex-column">
      <div
        className={classNames("card border-0 shadow-sm", "")}
        style={{ marginBottom: 20 }}
      >
        <CaseNoteHeader
          title="All Session Notes"
          search={currentTab == "own" ? search : sharedSearch}
          onSearch={onSearch}
          onChange={currentTab == "own" ? setSearch : setSharedSearch}
          currentTab={currentTab}
        />
      
      <div className="d-none"><ArchiveCaseNotePrint ref={formRef} casenote={archiveCaseNote} isTherepist={isTherepist} /></div>
      <div className="content-header archiveGoalHeader">
            <div className="text-left"><b>Select Date : </b>
              <DatePicker
                value={values}
                onChange={setValues}
                maxDate={new DateObject()}
                format="MM-DD-YYYY"
                range
              />
              {diffDays > 365 ? <p><b className="text-danger">Please select date range with in one year</b></p> : ''}
            </div>
            <div className="text-right pl-2">
              {values.length === 2 && diffDays <= 365 && isDownloadBtn ? <CaseNoteDownload
                downloadName={`ArchiveSessionNote.pdf`}
                targetRef={formRef}
                beforeStart={onPdfCreate}
                onComplete={onPdfComplete}
              />
                : ''}
            </div>
          </div>
          </div>
      {/* <CaseNoteDownload
        downloadName={`ArchiveCaseNote.pdf`}
        targetRef={formRef}
        beforeStart={onPdfCreate}
        onComplete={onPdfComplete}
      /> */}
      {/* {state.isLoading ? <div className="min-h-100 bg-white" ><div className="loader" /></div> : "" } */}
      {
        <div className={classNames("card border-0 shadow-sm", "")}>
          <div className="p-case-notes__main-box">
            <div className="p-case-notes__table p-case-notes__table--full">
              <div className="p-case-notes__table-body soap-note-tabs">
                {user?.roles[0] !== "ROLE_FAMILY" && user?.roles[0] !== "ROLE_RCE" && (
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() => setCurrentTab("own")}
                    >
                      <a
                        className={currentTab == 'own' ? "nav-link active" : "nav-link"}
                        id="own-tab"
                        data-toggle="tab"
                        href="#own"
                        role="tab"
                        aria-controls="own"
                        aria-selected={currentTab == 'own' ? "true" : "false"}
                      >
                        Session Notes
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => setCurrentTab("shared")}
                    >
                      <a
                        className={currentTab == 'shared' ? "nav-link active" : "nav-link"}
                        //className="nav-link"
                        id="shared-tab"
                        data-toggle="tab"
                        href="#shared"
                        role="tab"
                        aria-controls="shared"
                        aria-selected={currentTab == 'shared' ? "true" : "false"}
                      >
                        Shared Session Notes
                      </a>
                    </li>
                  </ul>
                )}
                <div
                  className="d-block tab-content"
                  id="myTabContent"
                  style={{ paddingBottom: 20 }}
                >
                  <div
                    className={currentTab == 'own' ? "tab-pane fade active show" : "tab-pane fade"}
                    //className="tab-pane fade active show"
                    id="own"
                    role="tabpanel"
                    aria-labelledby="own-tab"
                  >
                    <TableHeader />
                    {state.caseNotes?.map((caseNote) => (
                      <ArchiveCaseNoteTableRow
                        {...caseNote}
                        isTherepist={isTherepist}
                        isInterepreter={isInterepreter}
                        isFamily={isFamily}
                        isAdmin={isAdmin}
                        key={caseNote.caseNoteDTO?.id}
                      />
                    ))}
                    {!state.isLoading && state.caseNotes?.length == 0 && (
                      <h5 className="text-center p-3">No Data Found</h5>
                    )}
                    <div className="row">
                      <div
                        style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
                      >
                        <div style={{ marginTop: "2px" }}>
                          {query.totalPages > 0 && (
                            <PaginationTabs
                              shareQuery={query}
                              pagePre={paginatePre}
                              pageNext={paginateNext}
                              pageShare={paginate}
                              paginate={paginate}
                            />
                          )}
                        </div>
                        <button className="btn btn-success ml-3" onClick={() => history(-1)}>Back</button>
                      </div>
                    </div>
                    {state.isLoading ? (
                      <div style={{ paddingTop: 200 }}>
                        <div className=" bg-white">
                          <div className="loader" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={currentTab == 'shared' ? "tab-pane fade active show" : "tab-pane fade"}
                    //className="tab-pane fade"
                    id="shared"
                    role="tabpanel"
                    aria-labelledby="shared-tab"
                  >
                    <TableHeader />
                    {stateShared.isLoading ? (
                      <div style={{ paddingTop: 200 }}>
                        <div className=" bg-white">
                          <div className="loader" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {stateShared.caseNotes?.map((caseNote) => (
                      <ArchiveCaseNoteTableRow
                        {...caseNote}
                        isTherepist={isTherepist}
                        isInterepreter={isInterepreter}
                        isFamily={isFamily}
                        key={caseNote.caseNoteDTO?.id}
                      />
                    ))}
                    {!stateShared.isLoading &&
                      stateShared.caseNotes?.length == 0 && (
                        <h5 className="text-center p-3">No Data Found</h5>
                      )}
                    <div className="row">
                      <div
                        style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
                      >
                        <button className="btn btn-success ml-3" onClick={() => history(-1)}>Back</button>
                      </div>
                    </div>
                    {/* {!stateShared.isLoading && queryShared.totalPages > 0  &&
                                        <PaginationTabs 
                                            shareQuery={queryShared}
                                            pagePre={paginatePreShared}
                                            pageNext={paginateNextShared}
                                            pageShare={paginateShared}
                                        />
                                    } */}
                  </div>
                </div>
              </div>
            </div>
            {/* <CaseNoteColDetail {...state.selected} /> */}
          </div>
        </div>
      }
    </div>
  );
};

export default ArchiveCaseNotes;
