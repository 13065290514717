import React from 'react';
import { Link } from 'react-router-dom';

function TherapistBillingInfo() {
   
    return (
        <>
            <div className="col-md-10 no-padding">
                <div className="no-padding dash-billing-info mr-0">
                    <div className="custom-table-view">
                        <div className="content-header">
                            <div className="text-left header-title">Billing Info </div>
                                    <input name="text" className="form-control w-25 ml-auto" placeholder="Name" autofocus /> 
                                    <button className="btn button-withicon-green ml-3" type="submit" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                        <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                    </svg>
                                    </button>
                            </div>
                        <div>
                    </div>                                   

                        <div className="row mt-4 mb-4 ml-2">
                                <div className="col-md-8 col-md-8">
                                    <h5>Here are your Billing Records and Payment Details</h5>
                                </div>                                
                        </div>

                        <div className="table-responsive">
                            <table className="table table-hover table-billing-info">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Family</th>
                                        <th scope="col">Therapy</th>
                                        <th scope="col">Duration</th>
                                        <th scope="col">Rate/Hrs</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Status</th>      
                                        <th scope="col">Actions</th>                                                         
                                    </tr>
                                </thead>
                                <tbody>
                                        <tr>
                                            <td><span className="table-bold-text">Aug 12, 2020</span></td>
                                            <td><span className="table-nm">Roy Baristow</span></td>
                                            <td><span className="table-nm">Occupational  Therapy</span></td>
                                            <td><span className="table-bold-text"> 1:40 Hrs</span></td>
                                            <td><span className="table-bold-text">$140</span></td>
                                            <td><span className="table-bold-text">$140</span></td>
                                            
                                            <td className="fd-flex">
                                                <span className="badge badge-primary session-status s-approve "><img className="" src="../resources/assets/approve-icon-button.svg" alt="" /> Paid</span>
                                            </td>
                                            <td className="fd-flex">
                                            <div className="btn-group dropleft">
                                                    <button type="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src="../resources/assets/Icon ionic-md-more.svg" alt="" />
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <Link className="dropdown-item" ><img className="pr-2" src="../resources/assets/action-icon-edit.svg" alt=""/>Edit</Link>
                                                        <div className="dropdown-divider"></div>
                                                        <Link className="dropdown-item" ><img className="pr-2" src="../resources/assets/action-icon-delete.svg" alt=""/>Delete</Link>
                                                        <div className="dropdown-divider"></div>
                                                        <button className="btn button-withicon-green" type="submit" block={true}>Submit for Approval</button>
                                                    </div>
                                                </div>
                                            </td>                                                                                     
                                        </tr>

                                        <tr>
                                            <td><span className="table-bold-text">Aug 12, 2020</span></td>
                                            <td><span className="table-nm">Roy Baristow</span></td>
                                            <td><span className="table-nm">Occupational  Therapy</span></td>
                                            <td><span className="table-bold-text"> 1:40 Hrs</span></td>
                                            <td><span className="table-bold-text">$140</span></td>
                                            <td><span className="table-bold-text">$140</span></td>
                                            
                                            <td className="fd-flex">
                                                <span className="badge badge-primary session-status s-pending "><img className="" src="../resources/assets/pending-icon-button.svg" alt="" /> Submitted for Approval</span>                                                
                                            </td>
                                            <td className="fd-flex">
                                            <div className="btn-group dropleft">
                                                    <button type="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src="../resources/assets/Icon ionic-md-more.svg" alt=""/>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <Link className="dropdown-item"><img className="pr-2" src="../resources/assets/action-icon-edit.svg" alt=""/>Edit</Link>
                                                        <div className="dropdown-divider"></div>
                                                        <Link className="dropdown-item"><img className="pr-2" src="../resources/assets/action-icon-delete.svg" alt="" />Delete</Link>
                                                        <div className="dropdown-divider"></div>
                                                        <button className="btn button-withicon-green" type="submit" block={true}>Submit for Approval</button>
                                                    </div>
                                                </div>
                                            </td>
                                         
                                            
                                        </tr>

                                </tbody>
                            </table>
                        </div>

                </div>

                </div>
            </div>
        </>
    )
}

export default TherapistBillingInfo;