import React, { useState, useEffect, useRef } from "react";
import Pagination from "react-responsive-pagination";
import GoalsHeader from "./ArchieGoalsHeader";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { adminService } from "../../services/adminService";
import GoalList from "./ArchieGoalList";
import Table from "react-bootstrap/esm/Table";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import CaseNoteDownload from "../../components/case-note-detail/CaseNoteDownload";
import ArchiveGoalPrint from "../Therapist/ArchiveGoalPrint";
import appAction from "../../redux/actions/appAction";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { toast } from "react-toastify";

function ArchivesGoals(props) {
  let params = useParams();
  const formRef = useRef(null);
  const history = useNavigate();
  const [currentTab, setCurrentTab] = useState(
    params?.tab || "drafted"
  );
  let location = useLocation();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location.search);
  const [filter, setfilter] = useState("");
  const [goals, setGoals] = useState([]);
  const [archiveGoals, setArchiveGoals] = useState([]);
  const [search, setsearch] = useState("");
  const [pageInfo, setPageInfo] = useState({
    totalPages: 0,
    page: 0,
  });
  const [loading, setloading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadBtn, setDownloadBtn] = useState(false);
  const [flag, setFlag] = useState(false);
  const [values, setValues] = useState([
    new DateObject().subtract(5, "days"),
    new DateObject()
  ])
  //const [archive, setArchive] = useState(null);
  ///session-service/api/archive/goals (GET)
  //const archiveFunction = () => {};
  let jsonDates = JSON.stringify(values[0]);
  let jsonDatesTo = JSON.stringify(values[1]);
  let date = moment(parseInt(jsonDates)).format('YYYY-MM-DD');
  let dateTo = moment(parseInt(jsonDatesTo)).format('YYYY-MM-DD');
  const dateto = moment(parseInt(jsonDates)).format('MM/DD/YYYY');
  const datefrom = moment(parseInt(jsonDatesTo)).format('MM/DD/YYYY');
  // const diffTime = Math.abs(date1 - date2);
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  const date1 = new Date(dateto);
  const date2 = new Date(datefrom);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  console.log(diffTime + " milliseconds");
  console.log(diffDays + " days");
  // if(diffDays > 365)
  // {
  //   toast.error("Please select date range with in one year");
  // }
  //const diffTime = Math.abs(date - dateTo);
  //console.log("jsonDates", date, dateTo, values.length,date1,date2, diffDays);
  let qry_param_filter = `&startDateTime=${date}T00:00:00Z&endDateTime=${dateTo}T23:59:59Z`;
  useEffect(() => {
    const url =
      filter === "drafted"
        ? `archive/goals?drafted=true&name=${search}&sort=sessionDate,DESC${qry_param_filter}`
        : filter === "submitted"
          ? `archive/goals?submitted=true&completed=false&name=${search}&sort=sessionDate,DESC${qry_param_filter}`
          : filter === "completed"
            ? `archive/goals?completed=true&name=${search}&sort=sessionDate,DESC${qry_param_filter}`
            : `archive/goals?name=${search}&sort=sessionDate,DESC${qry_param_filter}`;
    console.log("filter", filter);
    if (values.length === 2) {
      adminService
        .getAll(url)
        .then((res) => {
          setDownloadBtn(false);
          if (res?.data) {
            setArchiveGoals(res?.data?.content);
            // setPageInfo({
            //   totalPages: res?.data?.totalPages,
            //   page: res?.data?.number,
            // });
            setDownloadBtn(true);
          }
        })
        .catch((e) => console.log("e", e));
    }
  }, [filter, search, qry_param_filter]);
  useEffect(() => {
    const url =
      filter === "drafted"
        ? `archive/goals?page=${pageInfo?.page}&size=20&drafted=true&name=${search}&sort=sessionDate,DESC`
        : filter === "submitted"
          ? `archive/goals?page=${pageInfo?.page}&size=20&submitted=true&completed=false&name=${search}&sort=sessionDate,DESC`
          : filter === "completed"
            ? `archive/goals?page=${pageInfo?.page}&size=20&completed=true&name=${search}&sort=sessionDate,DESC`
            : `archive/goals?page=${pageInfo?.page}&size=20&name=${search}&sort=sessionDate,DESC`;
    console.log("filter", filter);
    adminService
      .getAll(url)
      .then((res) => {
        if (res?.data) {
          setGoals(res?.data?.content);
          console.log("goalsDto",res?.data?.content)
          setPageInfo({
            totalPages: res?.data?.totalPages,
            page: res?.data?.number,
          });
        }
        setloading(false);
      })
      .catch((e) => console.log("e", e));
  }, [filter, search, pageInfo?.page]);

  const onSearch = (val) => {
    console.log(val);
  };

  const handlePagination = (p) => {
    setPageInfo({
      ...pageInfo,
      page: p,
    });
  };

  //   const TableHeader = () => {
  //     return (
  //       <>
  //         <tr>
  //           <th>Client Name</th>
  //           <th>Session Date</th>
  //           <th>Therapy</th>
  //           <th>Therapist</th>
  //           <th>Action</th>
  //         </tr>
  //       </>
  //     );
  //   };
  const onPdfCreate = (cb) => {
    setIsDownloading(true);
    dispatch(appAction.setAppLoading(true));
    setTimeout(() => {
      if (typeof cb === "function") {
        cb();
      }
    }, 200);
  };

  const onPdfComplete = () => {
    setIsDownloading(false);
    dispatch(appAction.setAppLoading(false));
  };
  function subtractMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() - numOfMonths);

    return date;
  }
  const TableHeader = () => {
    return (
      <div className="p-case-notes__table-header">
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header">
          Client Name
        </div>
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header">
          Session Date
        </div>
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header" style={{flex: "1 1 80px"}}>
          Goal Creation Date
        </div>
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header">
          Therapy
        </div>
        <div className="ptb-20 p-case-notes__table-head-col cn-tbl-header">
          Therapist
        </div>
        <div
          className="ptb-20 p-case-notes__table-head-col cn-tbl-header"
          style={{ flex: "1 1 45px" }}
        >
          Status
        </div>
        <div
          className="ptb-20 p-case-notes__table-head-col cn-tbl-header"
          style={{ flex: "0 1 100px" }}
        >
          Action
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="p-case-notes col-md-10 d-flex flex-column">
        <div
          className={"card border-0 shadow-sm mt-10 mb-10"}
          style={{ marginBottom: 20 }}
        >
          <GoalsHeader
            title="All Goals"
            search={search}
            onSearch={onSearch}
            onChange={setsearch}
            currentTab={currentTab}
            setfilter={setfilter}
          />
          {/* <Table id="table" striped bordered hover>
            <TableHeader />
            <GoalList list={goals} />
          </Table> */}
          <div className="d-none"><ArchiveGoalPrint ref={formRef} goals={archiveGoals} /></div>
          <div className="content-header archiveGoalHeader">
            <div className="text-left"><b>Select Goal Creation Date : </b>
              <DatePicker
                value={values}
                onChange={setValues}
                maxDate={new DateObject()}
                format="MM-DD-YYYY"
                range
              />
              {diffDays > 365 ? <p><b className="text-danger">Please select date range with in one year</b></p> : ''}
            </div>
            <div className="text-right pl-2">
              {values.length === 2 && diffDays <= 365 ? <CaseNoteDownload
                downloadName={`ArchiveGoalsAndMilestone.pdf`}
                targetRef={formRef}
                beforeStart={onPdfCreate}
                onComplete={onPdfComplete}
              />
                : ''}
            </div>
          </div>
          <TableHeader />
          {loading ? <div style={{ paddingTop: 200 }}>
            <div className=" bg-white">
              <div className="loader" />
            </div>
          </div> : <>
            {goals.length ?
              goals.map((goal) => {
                return (
                  <div
                    //   onClick={onClick}
                    className={classNames("p-case-notes__table-row", {
                      "p-case-notes__table-row--active": goal?.active,
                    })}
                  >
                    <div className="p-case-notes__table-col">
                      <div className="p-case-notes__child-info">
                        <img
                          src={
                            goal?.childProfilePic
                              ? goal?.childProfilePic
                              : "/resources/assets/defaultprofile.png"
                          }
                          alt={goal?.childName}
                          className="p-case-notes__child-info-img"
                        />
                        <span className="text-capitalize">{goal?.childName}</span>
                      </div>
                    </div>
                    <div className="p-case-notes__table-col p-case-notes__table-col--date">
                      {moment
                        .utc(goal?.sessionDate)
                        .utc()
                        .local()
                        .format("DD MMMM, yyyy")}{" "}
                      <br />
                      {moment
                        .utc(goal?.sessionDate)
                        .utc()
                        .local()
                        .format("hh:mm A")}
                    </div>
                    <div className="p-case-notes__table-col p-case-notes__table-col--date" style={{flex: "1 1 80px"}}>
                    {moment
                        .utc(goal?.goalsDto?.createdDate)
                        .local()
                        .format("DD MMMM, yyyy")}{" "}
                      <br />
                      {moment
                        .utc(goal?.goalsDto?.createdDate)
                        .local()
                        .format("hh:mm A")}
                    </div>
                    <div className="p-case-notes__table-col p-case-notes__table-col--therapy">
                      {goal?.goalsDto?.therapyType}
                    </div>
                    <div className="p-case-notes__table-col p-case-notes__table-col--therapist">
                      {goal?.therapist}
                    </div>
                    <div
                      className="p-case-notes__table-col p-case-notes__table-col--therapist"
                      style={{ flex: "1 1 45px" }}
                    >
                      {goal?.goalsDto?.completed === true &&
                        goal?.goalsDto?.drafted === false
                        ? "Completed"
                        : goal?.goalsDto?.drafted === true
                          ? "Drafted"
                          : "Submitted"}
                    </div>
                    <div
                      className="p-case-notes__table-col p-case-notes__table-col--action view-all-link"
                      style={{ flex: "0 1 100px" }}
                    >
                      <Link
                        className="btn px-0 view-link"
                        to={`/viewArchiveGoal/${goal?.goalsDto?.id}`}
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                );
              }) : <p className="p-3 text-center"><b>No Data Found</b></p>
            }
          </>
          }
          <div className="row">
            <div
              style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}
            >
              <div style={{ marginTop: "15px" }}>
                <Pagination
                  total={pageInfo?.totalPages}
                  current={pageInfo?.page + 1}
                  onPageChange={(page) => handlePagination(page - 1)}
                />
              </div>
              <button className="btn btn-success ml-3" style={{ borderRadius: "0.25rem" }} onClick={() => history(-1)}>Back</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArchivesGoals;
