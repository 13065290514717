import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "./CommonComponents";
import SignDate from "./SignDate";

function MedicoidForm(props) {
  const profile = useSelector((state) => state.adminUser.profile);
  const formRef = React.useRef(null);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  //   const [nameValue, setnameValue] = useState({
  //     familySign: "",
  // familySignDate: "",
  // patientName: "",
  // printName: "",
  // relationShip: "",
  //   });
  const [familyInfo, setFamilyInfo] = useState({
    id: props?.data?.id ? props.data.id : null,
    familyMemberName: props?.data?.familyMemberName
      ? props?.data?.familyMemberName
      : props?.data?.familyMember
        ? props.data.familyMember.firstName +
        " " +
        props.data.familyMember.lastName
        : " ",
    familyMemberDob: props?.data?.familyMemberDob
      ? props?.data?.familyMemberDob
      : props?.data?.familyMember
        ? props.data.familyMember.dob
        : " ",
    familySign:
      props?.data?.familySignDate && props?.data?.family
        ? props?.data?.family?.userProfile?.signatureUrl
        : false,
    familySignDate: props?.data?.familySignDate
      ? props.data.familySignDate
      : false,
    familyRelationship: props?.data.family
      ? props?.data?.family?.relationship
      : "",
    familyMemberId: props?.inputs?.familyMemberId
      ? props?.inputs?.familyMemberId
      : null,
    familyName: props?.data?.familyName ? props.data.familyName : "",
  });
  const [medicaidInsurancePolicy, setmedicaidInsurancePolicy] = useState({
    printName: props?.data?.familyName ? props.data.familyName : "",
    familySign:
      props?.data?.familySignDate && props?.data?.family
        ? props?.data?.family?.userProfile?.signatureUrl
        : false,
    patientName: props?.data?.familyMemberName
      ? props?.data?.familyMemberName
      : props?.data?.familyMember
        ? props.data.familyMember.firstName +
        " " +
        props.data.familyMember.lastName
        : " ",
    relationShip: props?.data.family ? props?.data?.family?.relationship : "",
    familySignDate: props?.data?.familySignDate
      ? props.data.familySignDate
      : false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const sigCanvas = useRef({});
  const [isSubmit, setIsSubmit] = useState(
    props?.from != "family" || props?.data?.reply
      ? true
      : props?.data?.replyNeeded == false
        ? !props?.data.replyNeeded
        : false
  );

  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }

  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setmedicaidInsurancePolicy({
      ...medicaidInsurancePolicy,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };
  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("res.data.signatureUrl", res.data.signatureUrl);
            const currentDateTime = new Date();
            setmedicaidInsurancePolicy({
              ...medicaidInsurancePolicy,
              familySign: res.data.signatureKey,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const [signatureErr, setSignatureErr] = useState("");
  const onSubmitFamilyForm = (isSubmit = false) => {
    setIsLoading(true);
    if (props?.data?.replyNeeded) {
      if (familyInfo?.familySignDate) {
        let formData = new FormData();
        // formData.append("signature", familyImageURL);
        formData.append(
          "formUpdationForm",
          new Blob(
            [
              JSON.stringify({
                ...familyInfo,
                jsonFormattedForm: JSON.stringify({ medicaidInsurancePolicy }),
              }),
            ],
            {
              type: "application/json",
            }
          )
        );
        adminService
          .onboardPutUser(
            `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
            formData
          )
          .then((response) => {
            setIsLoading(true);
            toast.success("Form Submitted successfully");
            props?.from == "family" && props.handleBack();
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        setSignatureErr("Signature is required!");
      }
    } else if (props?.from === "admin") {
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("signature", familyImageURL);
      console.log("formData 220", formData);
      formData.append(
        "formCreationForm",
        new Blob([JSON.stringify(props?.inputs)], {
          type: "application/json",
        })
      );
      adminService
        .onboardUser(
          `submitted-forms`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Form Submitted successfully");
          props.setIsFocused(!props.isFocused);
          props?.from == "admin" && props.handleBack("reload");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };

  const updateInfo = (e) => {
    const { name, value } = e.target;
    setmedicaidInsurancePolicy({ ...medicaidInsurancePolicy, [name]: value });
  };
  const relValue = props?.data?.reply
    ? JSON.parse(props.data?.jsonFormattedForm)
    : "";

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "MedicaidForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        {" "}
        <div
          style={{
            position: "absolute",
            top: 0,
            right: "100px",
          }}
        >
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.familySignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}
        </div>
        <div className="card-lg">
          <FormHeader
            logoUrl={logoUrl}
            title="MEDICAID INSURANCE PAYMENT POLICY AGREEMENT "
          />
          {/* MEDICAID INSURANCE PAYMENT POLICY AGREEMENT */}
          <hr />
          <br />
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content">
              <div className="w-100per">
                <label style={{ width: "auto" }}>
                  <b>Patient’s Name : </b>
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  relValue?.medicaidInsurancePolicy?.patientName
                ) : (
                  <input
                    {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                    type="text"
                    placeholder="Patient’s name"
                    name="patientName"
                    className={`form-control form-input form-input-w-60per ${errors.patientName && "is-invalid"
                      }`}
                    defaultValue={familyInfo?.familyMemberName}
                    disabled={familyInfo?.familyMemberName === '' ? false : true}
                  //onChange={(e) => updateInfo(e)}
                  />
                )}
              </div>
              <br />
              <div className="w-100per">
                <p className="w-100per">
                  I give consent for {name} Network to bill Medicaid / Private
                  Insurance for covered services for my child’s therapy needs.
                </p>
              </div>
              <div className="w-100per">
                <p className="w-100per">
                  My signature authorizes {name} Network to release health and
                  educational services to Medicaid / Private Insurance as
                  necessary for eligibility verification, billing and auditing.
                </p>
              </div>

              <br />
              <div className="w-100per">
                <div style={{ width: "50%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Print Name : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.medicaidInsurancePolicy?.printName
                    ) : (
                      <input
                        {...register('printName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyName === '' ? true : false })}
                        type="text"
                        placeholder="Print name"
                        name="printName"
                        className={`form-control form-input form-input-w-60per ${errors.printName && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyName}
                        disabled={familyInfo?.familyName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "50%", display: "inline-block" }}>
                  <div className="w-100per">
                    <label style={{ width: "auto" }}>
                      <b>Relationship to Patient : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.medicaidInsurancePolicy?.relationShip
                    ) : (
                      <input
                        {...register('relationShip', { onChange: (e) => updateInfo(e), required: familyInfo?.familyRelationship === '' ? true : false })}
                        type="text"
                        name="relationShip"
                        placeholder="Relationship"
                        className={`form-control form-input form-input-w-37per ${errors.relationShip && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyRelationship}
                        disabled={familyInfo?.familyRelationship === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
              </div>

              <br />

              <SignDate
                date={
                  medicaidInsurancePolicy?.familySignDate ||
                  relValue?.medicaidInsurancePolicy?.familySignDate
                }
                familyImageURL={familyImageURL}
                isSubmit={isSubmit}
                setOpen={setOpen}
                signTitle=""
                familyInfo={familyInfo}
              />

              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />
              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {signatureErr && (
                    <p className="text-red">
                      <small>{signatureErr}</small>
                    </p>
                  )}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {props?.data.replyNeeded && (
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={familyInfo.familySign ? false : true}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {props?.from === 'admin' && props?.isFormOpen &&
                    <>
                      <button
                        className="btn btn-theme-green"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btn-success float-right1 mt-10 mb-10"
                      >
                        {isLoading ? "Loading...." : "Submit"}
                      </button>
                    </>

                  }
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default MedicoidForm;
