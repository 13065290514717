import React, { Component, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../redux/actions";
import { adminActions } from "../actions";
import { adminService } from "../services/adminService";
import { showError, showWarning } from "../utils/commonFunctions";
import { apiUrl } from "../config/Config";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
//import allActions from "../redux/actions";

function AddAccount() {
  let location = useLocation();
  let params = useParams();
  const dispatch = useDispatch();
  let history = useNavigate();
  const [loader, setLoader] = useState(false);
  let user = useSelector((state) => state?.adminUser?.userData);
  let accessToken = useSelector((state) => state?.adminUser?.userData?.accessToken);
  let sessionId = useSelector((state) => state?.adminUser?.userData?.sessionId);
  const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
  const accountId = useSelector((st) => st.adminUser?.billingData?.stripeAccountId);
  const stripeAccountCompleted = useSelector((st) => st.adminUser?.billingData?.stripeAccountCompleted);
  // useEffect(()=>{
  //     getApi();
  // },[])
  console.log("login response", user);

  const getApi = () => {
    setLoader(true);
    if (accountId === null) {
      fetch(`${apiUrl}/account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken,
          'SessionId': sessionId,
          'X-TenantID': tenantId
        },
      })
        .then((response) => response.json())
        .then(
          (res) => {
            if (res.status === "Success") {
              console.log("accountId res", res.data)
              //setAccountId(res?.data?.stripeAccountId);
              setLoader(false);
              window.location.href = res.data;
            } else {
              toast.error(res.message);
              setLoader(false);
            }
          },
          (error) => {
            toast.error(error.info || error.msg);
          }
        )
        .finally(() => {
          // setSignLoading(false);
        });
    } else if (stripeAccountCompleted === false) {
      fetch(`${apiUrl}/account/refresh-url`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken,
          'SessionId': sessionId,
          'X-TenantID': tenantId
        },
      })
        .then((response) => response.json())
        .then(
          (res) => {
            if (res.status === "Success") {
              console.log("refresh res", res.data)
              //setAccountId(res?.data?.stripeAccountId);
              setLoader(false);
              window.location.href = res.data;
            } else {
              toast.error(res.message);
              setLoader(false);
            }
          },
          (error) => {
            toast.error(error.info || error.msg);
          }
        )
        .finally(() => {
          // setSignLoading(false);
        });
    }

  }
  const redirectDesh = () => {
    console.log("login response", user);
    //dispatch(allActions.adminAction.logIn(location?.state?.res));
    //let user = JSON.parse(localStorage.getItem("user"));
    //user.userDetails = location?.state?.res;
    dispatch(allActions.adminAction.logIn(user));
    history("/dashboard");
  }

  return (
    <>
      <div className="col min-h-100">
      {loader && (
        <div className="loader-overlay">
        <div className="loader" />
        </div>
      )}
        <div className="container p-0">
          <div className="row align-items-center login-page">
            <div className="col-md-6 no-padding p-0">
              <div className="log-wrapper-left">
                <img alt=""
                  src={
                    params.tempToken
                      ? "/resources/assets/Mask Group 2.png"
                      : "../resources/assets/Mask Group 2.png"
                  }
                  className="mask-login-left"
                />
                <img alt=""
                  src={
                    params.tempToken
                      ? "/resources/assets/MIS-1.png"
                      : "../resources/assets/MIS-1.png"
                  }
                  className="login-logo"
                />
              </div>
              <img alt=""
                src={
                  params.tempToken
                    ? "/resources/assets/pass-left.png"
                    : "../resources/assets/pass-left.png"
                }
                className="left-img img-fluid"
              />
              <img alt=""
                src={
                  params.tempToken
                    ? "/resources/assets/MIS-1.png"
                    : "../resources/assets/MIS-1.png"
                }
                className="copyright-image "
              />
              <span className="copyright-text">Copyright @RCE 2021</span>
            </div>
            <div className="col-md-6 no-padding">
              <div className="row valign_wrapper padd-b-60-all ">
                <div className="custom-table-view-login">
                  <div className="col-lg-12 min-hei-250-all">
                    <div className="text-center login">
                      <div className="">
                        {user?.userDetails?.superTenantAdmin ?
                          <>
                            <p style={{ textAlign: "left", fontSize: "16px" }}>Please <a onClick={getApi} type="button"><b style={{ textDecoration: "underline", color: "blue" }}>Click Here</b></a> to complete your account details.</p>

                            {accountId === null ? '' : <p style={{ textAlign: "left" }}><a style={{ textDecoration: "underline", color: "blue", fontSize: "16px", cursor: "pointer" }} onClick={redirectDesh}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                            </svg> Skip</a></p>}
                          </>
                          :
                          <p>Please contact Primary Tenant Admin to complete the account details.</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAccount;
