import React, { useEffect, useState } from "react";
import moment from "moment";
import { adminService } from "../../services/adminService";
import events from "../../utils/events.js";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { daysJson, monthJson } from "../../config/Config";
import {
  getUTCTimeToLocalByDateWithMeridium,
  calculateDuration,
} from "../../utils/commonFunctions";
import DeleteSession from "../../pages/Representative/DeleteSession";
import EditSessionRequest from "../../pages/Representative/EditSessionRequest";
import DashboardSessionCalender from "../../pages/DashboardSessionCalender";
import RecentMessages from "../../components/messages/RecentMessages";
//import OAuth2Login from 'react-simple-oauth2-login';

const localizer = momentLocalizer(moment);

let allViews = Object.keys(events).map((k) => events[k]);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

function TherapistDashboard(props) {
  let user = useSelector((state) => state.adminUser.user);
  const [billingInfo, setBillingInfo] = useState({});
  const [loading, setloading] = useState(false);
  const [recentQuery, setRecentQuery] = useState([]);
  const [archive, setArchive] = useState(false);
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
  const onSuccess = response => console.log(response);
  const onFailure = response => console.error(response);
  useEffect(() => {
    let api =
      user.userDetails.roles[0] === "ROLE_THERAPIST"
        ? `session-billings/dashboard/therapist`
        : user.userDetails.roles[0] === "ROLE_INTERPRETER"
          ? `session-billings/dashboard/interpreter`
          : `session-billings/dashboard`;
    adminService.getAll(api).then(
      (res) => {
        if (res.status === "Success") {
          setBillingInfo(res.data);
        } else {
          toast.error(res.message);
        }
        setloading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    adminService.getAll(`queries/recent`).then(
      (res) => {
        if (res.status === "Success") {
          setRecentQuery(res.data.sort((a, b) => (a.id > b.id ? -1 : 1)));
        } else {
          toast.error(res.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  if (loading) {
    return <div className="loader"></div>;
  }
  const archiveDashboard = (e) => {
    setArchive(!archive);
    console.log("archive", archive);
    if (archive === false) {
      let api =
        user.userDetails.roles[0] === "ROLE_THERAPIST"
          ? `archive/session-billings/dashboard/therapist`
          : user.userDetails.roles[0] === "ROLE_INTERPRETER"
            ? `archive/session-billings/dashboard/interpreter`
            : `archive/dashboard/rce`;
      adminService.getAll(api).then(
        (res) => {
          if (res?.status === "Success") {
            setBillingInfo(res.data);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      let api =
        user.userDetails.roles[0] === "ROLE_THERAPIST"
          ? `session-billings/dashboard/therapist`
          : user.userDetails.roles[0] === "ROLE_INTERPRETER"
            ? `session-billings/dashboard/interpreter`
            : `session-billings/dashboard`;
      adminService.getAll(api).then(
        (res) => {
          if (res?.status === "Success") {
            setBillingInfo(res.data);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
          setloading(false);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  function currencyFormat(currency) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency);
  }
  return (
    <>
      <div className="col-md-10 no-padding">
        {/* <OAuth2Login
          // authorizationUrl="https://accounts.spotify.com/authorize"
          authorizationUrl="https://accounts.google.com/o/oauth2/v2/auth"
          responseType="token"
          clientId="130683485700-l30cc60rntu77f71tssuvljmd1ncctcg.apps.googleusercontent.com"
          redirectUri="https://dev-tenant-1.techment.com/oauth-callback"
          onSuccess={onSuccess}
          onFailure={onFailure} />,*/}
        <DashboardSessionCalender /> 
        <div className="no-padding dash-billing-info">
          <div className="custom-table-view">
            <div className="row">
              <div className="col-lg-6">
                <div className="content-header border-0">
                  <div className="text-left header-title">Recent Queries</div>
                  {/* <div className="text-right header-btn"><button className="btn btn-primary custom-btn-yellow custom-btn-yellow-sm">
                                        <Link to={`/therapy-sessions`} >
                                            View All
                                        </Link>
                                    </button></div> */}
                </div>
                <table className="table">
                  <tbody>
                    {recentQuery &&
                      recentQuery.map((item, index) =>
                        index > 2 ? null : (
                          <tr>
                            <td>
                              <div className="col-auto">
                                <div className="user-info">
                                  <span className="admin-name justify-content-start">
                                    <img
                                      src={
                                        item.creatorProfilePic
                                          ? item.creatorProfilePic
                                          : "/resources/assets/defaultprofile.png"
                                      }
                                      alt=""
                                      className="image"
                                    />
                                    <div className="content ml-2">
                                      <span>{item.childName}</span>
                                      <small>
                                        <br />
                                        {item.detail}
                                      </small>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td>
                              <Link to={`/case-notes/` + item.caseNoteId}>
                                <img
                                  className="pl-2"
                                  src="../resources/assets/arrow.svg"
                                  alt=""
                                />
                              </Link>
                            </td>
                          </tr>
                        )
                      )}
                    {recentQuery && recentQuery.length === 0 ? (
                      <tr>
                        <td colSpan="2">
                          <div className="text-center">
                            <h5 className=" mt-2">No Queries Found</h5>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>

              <div className="col-md-6 no-padding">
                <RecentMessages />
              </div>
            </div>
          </div>
        </div>

        {tenantPemission?.billing && (
          <div className="dashboard-count">
            <div className="row">
              <div className="col-lg-3 mt-3 mb-3">
                <div className="font-content">
                  <span>Billing Till Date</span>
                </div>
                <div className="font-content1">
                  <p className="bill-text mb-0">
                    {currencyFormat(
                      billingInfo.totalBilling
                        ? billingInfo.totalBilling
                        : 0
                    )}
                  </p>
                </div>
                {/* <div className="seperator"></div> */}
              </div>

              <div className="col-lg-3 mt-3 mb-4">
                <div className="font-content">
                  <span>Payment Received</span>
                </div>
                <div className="font-content1">
                  <p className="bill-text mb-0">
                    {currencyFormat(
                      billingInfo.paymentDone ? billingInfo.paymentDone : 0
                    )}
                  </p>
                </div>
                {/* <div className="seperator"></div> */}
              </div>

              <div className="col-lg-3 mt-3 mb-4">
                <div className="font-content">
                  <span>Payment Pending</span>
                </div>
                <div className="font-content1">
                  <p className="bill-text mb-0">
                    {currencyFormat(
                      billingInfo.paymentPending
                        ? billingInfo.paymentPending
                        : 0
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="text-top header-btn" style={{ width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                  <div class="form-check form-switch lableTextArchive" style={{ padding: "0", display: "inline-flex", marginRight: '10px', color: "#fff" }}>
                    <input style={{ marginTop: "2px" }} onChange={(e) => archiveDashboard(e)} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                    <label class="form-check-label" for="flexSwitchCheckDefault">Archive</label>
                  </div>
                  <Link className="btn btn-primary custom-btn-yellow custom-btn-yellow-sm" to={`/billing-info`}>View All</Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TherapistDashboard;
