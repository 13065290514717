import React from "react";
import { Link } from "react-router-dom";

function CasesDetails(props) {
  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding dash-billing-info mr-0">
          <div className="custom-table-view">
            <div className="content-header">
              <div className="text-left header-title">
                Session Notes and Queries
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 user-info ml-3 mt-3">
                <span className="admin-name justify-content-start">
                  <img src="../resources/assets/Path 3273.svg" alt="" />
                  <div className="content mt-2">
                    <span>
                      <h4 className="name ml-2">Roy Baristow</h4>
                    </span>
                  </div>
                  <div className="detailsvideo">
                    <button
                      type="button"
                      className="btn button-withicon-green text-left "
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      <img
                        className="pr-1"
                        src="../resources/assets/Icon feather-monitor.svg"
                        alt=""
                      />
                      Watch Video
                    </button>
                  </div>

                  <div
                    className="modal fade modalbackground"
                    id="exampleModalCenter"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content ">
                        <div className="modal-header ">
                          <h5
                            className="modal-title"
                            id="exampleModalLongTitle"
                          >
                            Occupational Therapy Sessions
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <iframe width="465" height="300" src="https://www.youtube.com/embed/WtloFn4JaXU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <br />
                <table className="table">
                  <tr>
                    <td>
                      <h5 className="name">Therapy</h5>
                      <p>Physical Therapy</p>
                    </td>
                    <td>
                      <h5 className="name">Therapist</h5>
                      <p>Dr. Ak Antoni</p>
                    </td>
                    <td>
                      <h5 className="name">Interpreter</h5>
                      <p>Donald Mori</p>
                    </td>
                    <td>
                      <h5 className="name">Date</h5>
                      <p>Aug 12,2021</p>
                    </td>
                    <td>
                      <h5 className="name">Duration</h5>
                      <p>1:00 Hrs</p>
                    </td>
                  </tr>
                </table>
                <hr />
                <div className="col-md-12 casesnotes">
                  <h5 className="name">Session Notes</h5>
                  <p className="mr-1">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut Lorem ipsum dolor sit
                    amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                    tempor invidunt sum dolor sit amet, consetetur sadipscing
                    elitr, sed sum dolor sit amet, consetetur sadipscing elitr,
                    sed ut..Read more
                  </p>
                </div>

                <div className="col-md-12 ml-3">
                  <h5 className="name">Queries</h5>
                  <img src="../resources/assets/Path 3273 (1).svg" alt="" />
                  <span className="ml-1"> Roy Baristow</span>
                  <span>
                    <h5 className="ml-5">
                      Need to Concern about improvement of Speech Lorem ipsum
                      dolor sit amet, consetetur sadipscing elitr, sed diam
                    </h5>
                  </span>
                  <p className="ml-5">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt Lorem ipsum dolor sit
                    amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                    tempor invidunt ut...<Link to="/cases/">Details</Link>
                  </p>
                </div>
              </div>
              <hr />

              <div className="col-md-4 bg-color1">
                <div className="col-auto">
                  <h5 className="name ml-3 mt-3">Session Notes</h5>
                  <p className="ml-3 mt-3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut Lorem ipsum dolor sit
                    amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                    tempor invidunt sum dolor sit amet, consetetur sadipscing
                    elitr, sed sum dolor sit amet, consetetur sadipscing elitr,
                    sed ut..<Link to="#">Read more</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CasesDetails;
