import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { adminService } from "../../../services/adminService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "../FormContext";
import Pagination from "../../Pagination";
import FormTable from "../admin/FormTable";
import CreateForm from "../admin/CreateForm";
import DischargeSummery from "./DischargeSummery";
import FormList from "./FormList";
import ConsultationDocumentationForm from "../admin/ConsultationDocumentationForm";
import ParentProvider from "../other/ParentProvider";
import QuaterlyProgressForm from "../admin/QuaterlyProgressForm";
import PocStForm from "../admin/PocStForm";
import PocPtForm from "../admin/PocPtForm";
import PocDaltonForm from "../admin/PocDaltonForm";
import PocOtForm from "../admin/PocOtForm";
import SampleEvaluation from "./SampleEvaluation";
import AuthToDiscoloseInfo from "../other/AuthToDiscoloseInfo";
import ConsentToTreat from "../admin/ConsentToTreat";
import MedicalInsuranceForm from "../admin/MedicalInsuranceForm";
import HippaNoticeForm from "../admin/HippaNoticeForm";
import CancellationForm from "../admin/CancellationForm";
import ReleaseToCommunicateForm from "../admin/ReleaseToCommunicateForm";
import MediaReleaseForm from "../admin/MediaReleaseForm";
import Acknowledgement from "../other/Acknowledgement";
import ChangeInCoverage from "../other/ChangeInCoverage";
import MedicoidForm from "../admin/MedicoidForm";
import PaginationTabs from "../../../pages/pagination";

function TherapistFormList() {
  const history = useNavigate();
  let user = useSelector((state) => state.adminUser.user);
  let profile = useSelector((state) => state.adminUser.profile);
  const { inputs, setInputs, signatures, setSignatures } = useFormContext();

  const [currentPage, setCurrentPage] = useState(0);
  const { register, formState: { errors }, handleSubmit,setValue } = useForm();
  const [familyList, setFamilyList] = useState([]);

  const [formNameList, setFormNameList] = useState([]);
  const [pageDetail, setPageDetail] = useState({
    totalPages: 0,
    currentPage: 0,
    pages: [],
    sortBy: "createdDate,DESC",
    perPage: 10,
  });
  const [submittedFormList, setSubmittedFormList] = useState({
    isLoading: true,
    list: [],
  });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [family, setFamily] = useState({});
  const [createForm, setCreateForm] = useState(false);
  const [selectedFamily, setselectedFamily] = useState({});
  const [disciplines, setDisciplines] = useState('');
  const [serviceType, setServiceType] = useState();
  const [userType, setUserType] = useState();

  useEffect(() => {
    if (profile.therapistId) {
      getFamilyNameList();
      getFormNameList();
      // getSubmittedFormList(pageDetail.page);
    }
    adminService.getAll(`user-profiles/user-detail`).then(
      (res) => {
        if (res?.status === "Success") {
          console.log("user-detail", res.data);
          setDisciplines(res.data?.disciplines?.name);
          setUserType(res.data?.userType);
          // setProfile(res.data);
          //dispatch(adminAction.setProfileData(res.data));
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, [profile.therapistId]);

  useEffect(() => {
    setInputs({
      ...inputs,
      sentBy: profile?.userUniqeId,
      therapistId: profile?.therapistId,
    });
  }, [profile?.firstName]);
  const getFamilyNameList = () => {
    adminService
      .getAll(
        `families/filter?therapistId=${profile.therapistId}&page=0&size=2147483647`
      )
      .then(
        (res) => {
          if (res?.status === "Success") {
            setFamilyList(res.data.content);
          } else {
            res?.message
              ? toast.error(res?.message)
              : console.log("Somthing went wrong");
          }
        },
        (error) => {
          toast.error(error);
        }
      );
  };

  const getFormNameList = () => {
    adminService.getAll("adminForms").then(
      (res) => {
        if (res?.status === "Success") {
          setFormNameList(res.data.content);
        } else {
          res?.message
            ? toast.error(res?.message)
            : console.log("Somthing went wrong");
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  };
  const getListApi = ({ currentPage, sortBy, perPage }) =>
    `submitted-forms?page=${currentPage == 0 ? currentPage : currentPage - 1
    }&size=${perPage}&sort=${sortBy}`;
  const getSubmittedFormList = () => {
    adminService
      .getAll(getListApi(pageDetail))
      .then((response) => {
        setPageContent(response);
        // setPageDetail({
        //   ...pageDetail,
        //   pages: new Array(response.data?.totalPages).fill().map((_i, inx) => inx),
        //   totalpages: response.data.totalPages,
        // });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const onPaginateFetch = (number) => {
    setSubmittedFormList({
      ...submittedFormList,
      isLoading: true,
    });
    adminService
      .getAll(
        getListApi(
          {
            ...pageDetail,
            currentPage: number,
          }
        )
      )
      .then((res) => {
        setPageContent(res);
      })
      .catch((err) => {
        setSubmittedFormList({
          ...submittedFormList,
          isLoading: false,
        });
        console.log({ err });
      });
  };
  const paginate = (number) => {
    console.log("number", number);
    setPageDetail((q) => ({
      ...q,
      currentPage: number,
    }));
    onPaginateFetch(number);
  };
  const setPageContent = (res) => {
    if (res.data?.content) {
      setSubmittedFormList({
        ...submittedFormList,
        list: res.data?.content,
        isLoading: false,
      });
      setPageDetail((q) => ({
        ...q,
        totalPages: res.data?.totalPages,
        pages: new Array(res.data?.totalPages).fill().map((_i, inx) => inx),
      }));
    }
  };
  useEffect(() => {
    getSubmittedFormList(currentPage);
  }, [currentPage]);

  const handleChange = (e) => {
    console.log("form changes",e);
    const { name, value } = e.target;
    if (name === "formId") {
      // setIsFormOpen(true);
      value == 3 &&
        setInputs((inputs) => ({ ...inputs, reply: true, replyNeeded: false }));
    }
    if (name === "familyMemberId") {
      const family_id = document.getElementById(value).value;
      const fmly = familyList.filter((f) => f.id == family_id)[0];
      const fmlyMember = fmly.familyMembers.filter((f) => f.id == value)[0];
      console.log("selectedFamily value", value, fmlyMember);
      setselectedFamily(fmlyMember);
      fmlyMember?.familyMemberTherapies.map((item) => {
        console.log("profile.therapistId", profile.therapistId, item.therapist.id);
        if (item.therapist.id === profile.therapistId) {
          console.log("serviceType", item.therapy.name);
          setServiceType(item.therapy.name);
          // serviceName=item.therapy.name;
        }
      })
      setInputs({
        ...inputs,
        familyId: family_id,
        sentTo: family_id,
        [name]: value,
      });
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  };
  // const onSubmit = () => {
  //   setIsFormOpen(true);
  // };
  const onSubmit = (html = "") => {
    let formData = new FormData();
    setIsFormOpen(false);
    // formData.append("signature", signatures);
    formData.append(
      "formCreationForm",
      new Blob([JSON.stringify(inputs)], {
        type: "application/json",
      })
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((response) => {
        getSubmittedFormList(pageDetail.pages);
        setIsFormOpen(false);
        toast.success("Form Submitted successfully");
        setInputs({
          ...inputs,
          familyId: null,
          sentTo: null,
          familyMemberId: null,
          formId: null,
          replyNeeded: false,
          reply: null,
        });
        handleBack();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleBack = (isreload = "") => {
    console.log("handleback");
    setCreateForm(false);
    setIsFormOpen(false);
    setValue("familyId",null);
    setValue("sentTo",null);
    setValue("familyMemberId",null);
    setValue("formId",null);
    setValue("replyNeeded",null);
    setValue("reply",null);
    setInputs({
      ...inputs,
      familyId: null,
      sentTo: null,
      familyMemberId: null,
      formId: null,
      replyNeeded: null,
      reply: null,
    });
    isreload && getSubmittedFormList(0);
  };

  const showForms = (isShow = "") => {
    return (
      <>
        {inputs.formId == 7 ? (
          <DischargeSummery
            from="therapist"
            data={family}
            handleBack={handleBack}
            isFormOpen={isShow ? false : isFormOpen}
            setInputs={setInputs}
            inputs={inputs}
            onSubmitTherapistForm={onSubmit}
            selectedFamily={selectedFamily}
          />) : inputs.formId == 8 ? (
            <Acknowledgement
              from="therapist"
              data={family}
              handleBack={handleBack}
              inputs={inputs}
              familyList={familyList}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 10 ? (
            <ConsultationDocumentationForm
              from="therapist"
              data={family}
              inputs={inputs}
              setInputs={setInputs}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
              selectedFamily={selectedFamily}
            />
          ) : inputs.formId == 11 ? (
            <ParentProvider
              from="therapist"
              data={family}
              handleBack={handleBack}
              inputs={inputs}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 9 ? (
            <AuthToDiscoloseInfo
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 12 ? (
            <QuaterlyProgressForm
              from="therapist"
              data={family}
              inputs={inputs}
              disciplines={disciplines}
              selectedFamily={selectedFamily}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 1 ? (
            <ConsentToTreat
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 2 ? (
            <>
              <MedicalInsuranceForm
                from="therapist"
                data={family}
                handleBack={handleBack}
                isFormOpen={isShow ? false : isFormOpen}
              />
            </>
          ) : inputs.formId == 3 ? (
            <HippaNoticeForm
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 4 ? (
            <CancellationForm
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 5 ? (
            <ReleaseToCommunicateForm
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 6 ? (
            <MediaReleaseForm
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 8 ? (
            <Acknowledgement
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 17 ? (
            <ChangeInCoverage
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 13 ? (
            <PocStForm
              from="therapist"
              data={family}
              handleBack={handleBack}
              inputs={inputs}
              isFormOpen={isShow ? false : isFormOpen}
              selectedFamily={selectedFamily}
              serviceType={serviceType}
            />
          ) : inputs.formId == 14 ? (
            <PocPtForm
              from="therapist"
              data={family}
              inputs={inputs}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
              selectedFamily={selectedFamily}
            />
          ) : inputs.formId == 15 ? (
            <PocDaltonForm
              from="therapist"
              data={family}
              handleBack={handleBack}
              inputs={inputs}
              isFormOpen={isShow ? false : isFormOpen}
              selectedFamily={selectedFamily}
            />
          ) : inputs.formId == 16 ? (
            <>
              <PocOtForm
                from="therapist"
                data={family}
                handleBack={handleBack}
                inputs={inputs}
                isFormOpen={isShow ? false : isFormOpen}
                selectedFamily={selectedFamily}
              />
            </>
          ) : inputs.formId == 18 ? (
            <MedicoidForm
              from="therapist"
              data={family}
              handleBack={handleBack}
              isFormOpen={isShow ? false : isFormOpen}
            />
          ) : inputs.formId == 19 ? (
            <>
              <SampleEvaluation
                from="therapist"
                data={family}
                handleBack={handleBack}
                isFormOpen={isShow ? false : isFormOpen}
                setInputs={setInputs}
                inputs={inputs}
                familyList={familyList}
                onSubmitTherapistForm={onSubmit}
                selectedFamily={selectedFamily}
              />
            </>
          ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      <div className="col-md-10 no-padding">
        <div className="no-padding">
          {createForm ? (
            <div className="custom-table-view">
              <div className="row mb-10">
                <div className="col-md-6">
                  {!isFormOpen && (
                    <div className="text-left header-title">Create Forms</div>
                  )}
                </div>
                <div className="col-md-6 text-right">
                  {/* <button
                  className="btn custom-btn-green"
                  onClick={() => handleBack()}
                >
                  Back
                </button> */}
                </div>
              </div>
              {isFormOpen && showForms()}
              <CreateForm
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                handleChange={handleChange}
                familyList={familyList}
                formNameList={formNameList}
                setInputs={setInputs}
                inputs={inputs}
                errors={errors}
                setIsFormOpen={setIsFormOpen}
                isFormOpen={isFormOpen}
                handleBack={handleBack}
              />
            </div>
          ) : (
            <div className="custom-table-view">
              <div className="row mb-10">
                <div className="col-md-6">
                  {!isFormOpen && (
                    <div className="text-left header-title">Forms</div>
                  )}
                </div>
                <div className="col-md-6 text-right">
                  {isFormOpen ? (
                    <>
                      {/* <button
                    className="btn custom-btn-green"
                    onClick={() => setIsFormOpen(false)}
                  >
                    Back
                  </button> */}
                    </>
                  ) : (
                    <button
                      className="btn custom-btn-green w-auto"
                      onClick={() => {
                        setCreateForm(true);
                        setFamily({});
                      }}
                    >
                      + Caretaker Form
                    </button>
                  )}
                </div>
              </div>
              {submittedFormList.isLoading ? (
                <div className="loader" />
              ) : (
                <>
                  {isFormOpen ? (
                    showForms("show")
                  ) : (
                    <>
                      <FormTable
                        userType="admin"
                        list={submittedFormList.list}
                        setIsFormOpen={setIsFormOpen}
                        setFamily={setFamily}
                        setInputs={setInputs}
                        inputs={inputs}
                      />
                      {!submittedFormList.isLoading &&
                        submittedFormList.list?.length == 0 && (
                          <h5 className="text-center p-3">No Data Found</h5>
                        )}
                      <div className="row">
                        <div
                          style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
                        >
                          <div style={{ marginTop: "2px" }}>
                            <PaginationTabs
                              shareQuery={pageDetail}
                              paginate={paginate}
                            />
                            {/* <Pagination
                              totalpages={pageDetail.totalPages}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                            /> */}
                          </div>
                          {userType == "ROLE_THERAPIST" ?
                            <button
                              className=" btn btn-success ml-3"
                              style={{ borderRadius: "0.25rem !important" }}
                              //onClick={() => setfilter("archive")}
                              onClick={() => history("/archive-therapy-form-list")}
                            >
                              Archive
                            </button>
                            : ''}
                        </div>
                      </div>

                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TherapistFormList;
