import React from "react";
const HeaderDownloadButtons = (props) => {
  const { startDownload } = props;
  return (
    <>
      {/* {isSubmit && ( */}
      <button
        onClick={() => startDownload()}
        className="btn btn-theme-green zIndx1"
        style={{ position: "absolute", top: "25px" }}
      >
        Download
      </button>
      {/* )} */}
      {/* {isSubmit && <button onClick={() => setIsSubmit(false)}>Edit</button>}
      <button onClick={() => setIsSubmit(true)}>Check Your Form</button> */}
    </>
  );
};
const FormHeader = (props) => {
  return (
    <>
      <div className="card-lg__header" style={{ textAlign: "center" }}>
        {props?.logoUrl && (
          <img src={props?.logoUrl} alt={props.title} className="" />
        )}
        <h5 className="mt-10 mb-10">{props.title}</h5>
      </div>
    </>
  );
};
const FormFooter = (props) => {
  return (
    <>
      <div className="card-lg__footer mt-10" style={{ display: "block" }}>
        <p style={{ width: "100%", textAlign: "center" }}>
          <small>{props.address}</small>
        </p>
      </div>
    </>
  );
};

export { FormHeader, HeaderDownloadButtons, FormFooter };
