import React from 'react'

const AttachmentBtn = ({onChange, label, accept, name, value, inputProps}) => {
    return (
        <label className="cursor-pointer">
          <img
            src="/resources/assets/Icon material-attach-file.svg"
            alt=""
            className="paperclip-btn"
          />
          <span className="paperclip-btn-p">{label}</span>
          <input
            value={value}
            name={name}
            accept={accept}
            type="file"
            onChange={onChange}
            className="d-none"
            id="therapy-attach-file"
            {...inputProps}
          />
        </label>
    )
}

export default AttachmentBtn

AttachmentBtn.defaultProps = {
    label: "Attach audio/video/image file"
}
