import React from 'react';
import InterpreterSetAvailability from './OnBoardingUser/InterpreterSetAvailability';

function EditInterpreterAvailability() {
    return (
        <>
            <div className="col-md-10 no-padding">
                <div className="no-padding dash-billing-info mr-0">
                    <div className="custom-table-view">
                        <InterpreterSetAvailability />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditInterpreterAvailability;