import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function ViewBilling() {
  const [initialData, setInitialData] = useState({ loading: false, data: [] });

  const getData = () => {};

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="col-md-10">
      <div className="no-padding dash-billing-info mr-0">
        <div className="custom-table-view">
          <div className="content-header">
            <div class="text-left header-title">Billing Details</div>
            <div className="text-right header-btn">
              {/* <Link href="/add-therapist">
                <button className="btn btn-primary custom-btn-green custom-btn-green-auto">
                  <img
                    className="pr-2"
                    src="../resources/assets/plus-icon-button.svg"
                    alt=""
                  />{" "}
                  Add Therapist
                </button>
              </Link> */}
            </div>
          </div>
          <div className="content-body">
            <div className="row">
              <div className="col-4">
                <b>Name:</b>
              </div>
              <div className="col-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewBilling;
