import React, {useState} from "react";
import classNames from "classnames";
import { useForm} from 'react-hook-form'
import { isRtl, sortLanguage, _toastHandleErr, _toasthandleRes } from "../../utils/commonFunctions";
import { adminService } from "../../services/adminService";

const QueryTranslteForm = ({isReply, familyLanguages, languageDto, id, onSuccess}) => {
    const {register, handleSubmit, getValues, formState: { errors }} = useForm()
    const [state, setstate] = useState({
      isVisible: false,
      isLoading: false,
    })
    const [selectedLang, setSelectedLang] = useState()
    const setState = (data) => {
      setstate({
        ...state,
        ...data
      })
    }
    const onSubmit = () => {
      setState({isLoading: true})
    const api = isReply ? `query-replies/${id}/translation` : `queries/${id}/translation`
    const data = {
        languageId: languageDto?.id,
        ...getValues(),
      }
      adminService.PUT(api, JSON.stringify(data)).then(res => {
        console.log("translate", res)
        setState({isLoading: false})
        if(onSuccess && typeof onSuccess === "function"){
          onSuccess()
        }
        _toasthandleRes(res, "success")
      }).catch(err => {
        _toastHandleErr(err)
        setState({isLoading: false})
      })
    }
    const translatedDir = isRtl(
      languageDto?.languageCode?.trim() 
      || selectedLang?.languageCode?.trim()
      || (familyLanguages || [])[0]?.languageCode?.trim()
  )  ? "rtl" : "ltr"
    return <div>
      {
      !state.isVisible ?
      <button onClick={()=> setState({isVisible: true})} className="btn btn-theme-green">Translate</button>
      : <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-center mb-2">
          Translate in {
            languageDto?.id ? languageDto?.languageName : <div className="ml-2 select-wrapper">
            <select 
              onChange={(e)=> {
                setSelectedLang(familyLanguages?.find(lng => lng.id === +e.target.value))
              }}
              {...register('languageId',{required: true})} className="form-control" name="languageId">
              {
                sortLanguage(familyLanguages || [])?.map(lang => <option key={lang.id} value={lang.id}>{lang.languageName}</option>)
              }
            </select>
          </div>
          }
          </div>
          <textarea {...register('translatedNote',{required: true})} dir={translatedDir} name="translatedNote" className="c-textarea"></textarea>
          {errors.translatedNote && <span className="error-text d-block mb-3" >Translation is required</span>}
          <button disabled={state.isLoading} type="submit" className={classNames("btn btn-theme-green", {
            "has-loader" : state.isLoading
          })}>
            Submit
            {state.isLoading && <i className="loader-small" />}
          </button>
        </form>
      </div>
      }
    </div>
  }
  
  export default QueryTranslteForm