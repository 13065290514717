import React, { useEffect, useState } from "react";
import classNames from "classnames";

const Tabs = ({ data }) => {
  const [active, setActive] = useState(null);
  useEffect(() => {
    if (data.length > 0) {
      setActive({
        id: data[0].id,
        index: 0,
      });
    }
  }, [data]);
  return (
    <div className="w-100 c-tabs">
      <div className="c-tabs__nav">
        {data.map((item, index) => {
          return (
            <>
              <TabNavItem
                onClick={() =>
                  setActive({
                    id: item.id,
                    index,
                  })
                }
                text={item.title}
                isActive={active?.id === item.id}
                key={item.id}
              />
            </>
          );
        })}
      </div>
      <div className="c-tabs__container">
        <div className="c-tabs__content">{data[active?.index]?.content}</div>
      </div>
    </div>
  );
};

export default Tabs;

const TabNavItem = ({ text, onClick, isActive }) => {
  return (
    <div
      className={classNames("c-tabs__nav-item", {
        "c-tabs__nav-item--active": isActive,
      })}
      onClick={onClick}
    >
      <p className="c-tabs__nav-item-text">{text}</p>
    </div>
  );
};

/**
 *
 * data: [{
 *  title: '';
 *  id: '';
 *  content: Component;
 * }, ...]
 */
