import React, { useEffect, useState } from "react";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function DeleteMarketingSession(props) {
  let user = useSelector((state) => state.adminUser.user);

  const userType = user?.userDetails?.roles[0];

  let params = useParams();
  const history = useNavigate();
  const [reason, setReason] = useState(null);
  const [cancelAll, setcancelAll] = useState("ONE");
  const [reasonErr, setReasonErr] = useState("");

  const onSubmit = () => {
    if (userType == "ROLE_THERAPIST") {
      if (reason) {
        setReasonErr("");
        deleteSession();
      } else {
        setReasonErr("Please give reason to cancel this session");
      }
    } else {
      deleteSession();
    }
  };

  const deleteSession = () => {
    let api = `marketing/sessions/${props.sessioninfo.info.sessionid}`;
    adminService
      .DELETE(api, JSON.stringify({ reason: reason, sessionList: cancelAll }))
      .then(
        (res) => {
          if (
            res?.status === "Success" ||
            res?.message === "Data not deleted"
          ) {
            toast.success("Session cancel successfully!");
            props.closeModal();
            props.successSub();
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.closeModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  console.log("props", props);
  return (
    <>
      <form>
        <div className="col-md-2 custom-modal">
          <div
            className="modal fade add-new-prop-modal show "
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="addlang m-auto w-100 text-center">
                    <h5 className="modal-title name">Cancel Appointment</h5>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <h4>
                        <img
                          src="../resources/assets/Icon feather-trash-2.svg"
                          className="deleteicon"
                          alt=""
                        />
                        <span className="ml-3">Are You Sure?</span>
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <p>
                        Do you really want to cancel
                        {props.sessioninfo.info.therapyname} appointment?
                      </p>
                    </div>
                  </div>

                  {props?.sessioninfo?.sessionInfo?.recurringSession && (
                    <div className="form-group mb-20">
                      <label>Remove recurring appointment</label>
                      <div className="custom-control custom-radio ">
                        <input
                          onChange={(e) => {
                            setcancelAll(e.target.value);
                          }}
                          checked={cancelAll == "ONE" ? true : false}
                          type="radio"
                          id="radio-btn1"
                          value="ONE"
                          name="clearAll"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          for="radio-btn1"
                        >
                          This Appointment
                        </label>
                      </div>
                      <div className="custom-control custom-radio ">
                        <input
                          onChange={(e) => {
                            setcancelAll(e.target.value);
                          }}
                          checked={cancelAll == "PROCEEDING" ? true : false}
                          type="radio"
                          id="radio-btn2"
                          value="PROCEEDING"
                          name="clearAll"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          for="radio-btn2"
                        >
                          This and Following Appointments
                        </label>
                      </div>
                      <div className="custom-control custom-radio ">
                        <input
                          onChange={(e) => {
                            setcancelAll(e.target.value);
                          }}
                          checked={cancelAll == "ALL" ? true : false}
                          type="radio"
                          id="radio-btn3"
                          value="ALL"
                          name="clearAll"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          for="radio-btn3"
                        >
                          All Upcoming Appointments
                        </label>
                      </div>
                    </div>
                  )}
                  {userType == "ROLE_THERAPIST" && (
                    <>
                      <div className="form-group mb-20">
                        <label>
                          Reason <small className="required-span">*</small>
                        </label>
                        <textarea
                          className="form-control mb-10"
                          defaultValue={reason}
                          onChange={(e) => setReason(e.target.value)}
                          required
                          name="reason"
                        ></textarea>
                        {reasonErr && (
                          <p className="required-span">
                            <small>{reasonErr}</small>
                          </p>
                        )}
                      </div>
                    </>
                  )}

                  <div className="text-center mb-5">
                    <hr />
                    <button
                      type="button"
                      className="btn btn-primary noModalbutton mr-4"
                      onClick={() => props.closeModal(false)}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      onClick={onSubmit}
                      className="btn btn-primary Yes-green-button ml-4"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default DeleteMarketingSession;
