import React from "react";

function ViewImageModal(props) {
  return (
    <div className="col-md-2 custom-modal">
      <div
        className="modal fade add-new-prop-modal show "
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered c-watch-video__dialog"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <div className="text-center w-100">
                <h5 className="modal-title name">
                  {props?.watchImage?.therapyname}
                </h5>
              </div>
              <button
                onClick={() => props?.closeModal(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <div className="close-background">
                  <span aria-hidden="true">&times;</span>
                </div>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12 text-center">
                <img src={props?.watchImage?.img} width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewImageModal;
