import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoIosTrash, IoMdEye } from "react-icons/io";
import { FiEdit, FiDelete } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import React from "react";

function TenantAdminSettingTable(props) {
  let history = useNavigate();

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props?.list.length > 0 &&
            props?.list.map((data) => {
              
              return (
                <React.Fragment>
                {data?.email !== 'admin@meis.com'&& data?.email !== 'admeis@mailinator.com'&& data?.email !== 'adgtg@mailinator.com'&& data?.email !== 'adacay@mailinator.com'?
                <tr key={data.id}>
                  <td>
                    <div class="d-flex" style={{ alignItems: "center" }}>
                      <div class="logo mr-2">
                        <img
                          className="small-tenant-img"
                          src={data?.profilePicUrl}
                          style={{
                            objectFit: "scale-down",
                            borderRadius: 0,
                            width: 50,
                            height: 50,
                          }}
                          alt="img"
                        />
                      </div>
                      <div className="itemInfo">
                        <p className="m-0">
                          <b>{data?.firstName + " " + data?.lastName}</b>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>{data?.email}</td>
                  <td>{data?.phone}</td>
                  <td style={{ width: 100 }}>
                    {data?.active === true ? (
                      <span className="text-success">Active</span>
                    ) : (
                      <span className="text-danger">Inactive</span>
                    )}
                  </td>
                  <td style={{ width: 150 }}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="button-tooltip-1">View</Tooltip>}
                    >
                      <button
                        className="btn btn-theme-info btn-sm mb-10"
                        onClick={() => history(`/view-tenant/${data.id}`)}
                      >
                        <IoMdEye />
                      </button>
                    </OverlayTrigger>
                    <button
                      className="btn btn-theme-info btn-sm mb-10"
                      onClick={() => history(`/edit-tenant/${data.id}`)}
                    >
                      <FiEdit />
                    </button>
                    <button
                      className="btn btn-theme-info btn-sm mb-10"
                      onClick={() => props.onDelete(data)}
                    >
                      <IoIosTrash color="#d05a5a" />
                    </button>
                  </td>
                </tr>
              
              
              :
              ''
            }
            </React.Fragment>
            );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default TenantAdminSettingTable;
