import {messageTypes} from '../types'

const addMsgList = (profileKey, key, messages) => {
    return ({
        type: messageTypes.addMsgList,
        payload: {
            profileKey,
            key,
            messages
        }
    })
}
const clear = () => {
    return ({
        type: messageTypes.clear,
    })
}

const addUserList = (userList) => {
    return {
        type: messageTypes.updateUsers,
        payload: userList
    }
}

export default {
    addMsgList,
    addUserList,
    clear
}