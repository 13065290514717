import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  FormFooter,
  FormHeader,
  HeaderDownloadButtons,
} from "./CommonComponents";
import moment from "moment";
import { toast } from "react-toastify";
import DigitalSignPopup from "../../digitalSignPopup";
import SignDate from "./SignDate";
import CreateTemplate from "./CreateTemplate";
import { adminService } from "../../../services/adminService";
import { useForm } from "react-hook-form";
import produce from "immer";
import DatePicker from "react-multi-date-picker";

function QuaterlyProgressForm(props) {
  const { register, formState: { errors }, handleSubmit } = useForm();
  const profile = useSelector((state) => state.adminUser.profile);
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const formRef = React.useRef(null);
  const sigCanvas = useRef({});
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [familyInfo, setFamilyInfo] = useState({});
  const [formValues, setFormValues] = useState([{ id: "", description: "" }]);
  const [formNewValues, setNewFormValues] = useState([{ name: "" }]);
  const [tableValues, setTableValues] = useState([
    { id: "", current_ifsp_goal: "", current_knowledge: "", goal_met: "" },
  ]);
  const [formLongValues, setFormLongFields] = useState([{ name: "" }]);
  const [formShortValues, setFormShortFields] = useState([{ name: "" }]);
  const [isSubmit, setIsSubmit] = useState(
    (typeof props?.data?.reply === 'undefined') ? false : true
  );
  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const [quartelyProgress, setquartelyProgress] = useState({})
  useEffect(() => {
    setquartelyProgress({
      step1: {
        childName: props?.selectedFamily?.firstName +
          " " +
          props?.selectedFamily?.lastName,
        dob: props?.selectedFamily?.dob
          ? moment(props?.selectedFamily?.dob).format("YYYY-MM-DD") +
          `T00:00:00.000Z`
          : "",
        providerName: profile?.firstName
        ? profile.firstName + " " + profile.lastName
        : "",
        childMmi: "",
        providerDecipline: props?.disciplines,
        communityServiceProvider: "",
        sc: "",
        dateOfReport: "",
        progressMonitorTool: "",
      },

      step2: {
        periodicReview: "",
        previewGoals: [],
      },

      step3: {
        developmentDomains: [
          {
            id: 1,
            domainName: "Adaptive",
            can: "",
            stillEmerging: "",
            conern: "",
          },
          {
            id: 2,
            domainName: "Social/Emotional",
            can: "",
            stillEmerging: "",
            conern: "",
          },
          {
            id: 3,
            domainName: "Communicational",
            can: "",
            stillEmerging: "",
            conern: "",
          },
          { id: 4, domainName: "Motor", can: "", stillEmerging: "", conern: "" },
          {
            id: 5,
            domainName: "Cognitive",
            can: "",
            stillEmerging: "",
            conern: "",
          },
        ],

        ifspOutcomeStatus: [
          { label: "No Change", isChecked: false },
          {
            label: "IFSP Meeting Required for Modification Consideration",
            isChecked: false,
          },
        ],
      },

      step4: {
        outcome: "",
        goals: [],
        strategy: "",
        providerSign: "",
        providerDate: "",
      },
    });
  }, [props?.selectedFamily]);

  const [pageResult, setpageResult] = useState({
    adminSign: "",
    adminSignDate: "",
    reply: false,
    replyNeeded: false,
    sentBy: props.inputs?.sentBy ? props.inputs.sentBy : "",
    sentTo: props.inputs?.sentTo ? props.inputs.sentTo : "",
    therapistId: profile?.therapistId ? profile.therapistId : null,
    witnessSign: "",
    witnessSignDate: "",
    consent1: true,
    consent2: true,
    consent3: true,
    consent4: true,
    consent5: true,
    formId: props.inputs?.formId ? props.inputs.formId : null,
    familyId: props?.inputs?.familyId ? props?.inputs?.familyId : null,
    familyMemberId: props?.inputs?.familyMemberId ? props?.inputs?.familyMemberId : null,
    familyMemberName: "child one",
    familySign: "",
    familySignDate: "",
    providerSign: "",
    interpreterId: null,
    interpreterSign: "",
    interpreterSignDate: "",
  });
  
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");
  console.log("props", props,props?.disciplines);
  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "QuaterlyProgressForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      providerDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setpageResult({
      ...pageResult,
      providerDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setquartelyProgress(
      produce((draftState) => {
        draftState.step4["providerDate"] = moment(currentDateTime)
          .local()
          .format("MM-DD-YYYY hh:mm a");
      })
    );
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };
  const setDate = (e) => {
    setquartelyProgress(
      produce((draftState) => {
        draftState.step1["dob"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const evaDate = (e) => {
    setquartelyProgress(
      produce((draftState) => {
        draftState.step1["dateOfReport"] =
          moment(e.toDate?.()).format("YYYY-MM-DD") + `T00:00:00.000Z`;
      })
    );
  };
  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              providerSign: res.data.signatureUrl,
              providerDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setpageResult({
              ...pageResult,
              providerSign: res.data.signatureUrl,
              providerDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setquartelyProgress(
              produce((draftState) => {
                draftState.step4["providerDate"] = moment(currentDateTime)
                  .local()
                  .format("MM-DD-YYYY hh:mm a");
                draftState.step4["providerSign"] = res.data.signatureKey;
              })
            );
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  // const [pageResult, setpageResult] = useState({
  //   adminSign: "",
  //   adminSignDate: "",
  //   reply: props.inputs?.reply ? props.inputs.reply : true,
  //   replyNeeded: props.inputs?.replyNeeded ? props.inputs.replyNeeded : true,
  //   sentBy: props.inputs?.sentBy ? props.inputs.sentBy : "",
  //   sentTo: props.inputs?.sentTo ? props.inputs.sentTo : "",
  //   therapistId: profile?.therapistId ? profile.therapistId : null,
  //   witnessSign: "",
  //   witnessSignDate: "",
  //   consent1: true,
  //   consent2: true,
  //   consent3: true,
  //   consent4: true,
  //   consent5: true,
  //   formId: props.inputs?.formId ? props.inputs.formId : null,
  //   familyId: 1,
  //   familyMemberId: 1,
  //   familyMemberName: "child one",
  //   familySign: "",
  //   familySignDate: "",
  //   interpreterId: null,
  //   interpreterSign: "",
  //   interpreterSignDate: "",
  // });
  
  let handleChange = (e, i) => {
    const { name, value } = e.target;
    let newArr = [...formValues];
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      [name]: value,
    };
    setFormValues(newArr)
    setquartelyProgress(
      produce((draftState) => {
        draftState.step4.goals = newArr;
      })
    );
  };

  let addFormFields = () => {
    setFormValues([...formValues, { id: "", description: "" }]);
  };
  let addNewFormFields = () => {
    setNewFormValues([...formNewValues, { name: "" }]);
  };
  let addTableFields = () => {
    setTableValues([
      ...tableValues,
      { id: "", current_ifsp_goal: "", current_knowledge: "", goal_met: "" },
    ]);
  };
  let addFormLongFields = () => {
    setFormLongFields([...formLongValues, { name: "" }]);
  };
  let addFormShortFields = () => {
    setFormShortFields([...formShortValues, { name: "" }]);
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    //let inputId = "textDes"+i;
    document.getElementById("goalDec" + i).value = '';
    setFormValues(newFormValues);
  };
  let removeNewFormFields = () => {
    let newFormValues = [...formNewValues];
    newFormValues.pop();
    setNewFormValues(newFormValues);
  };
  let removeLongFormFields = () => {
    let newFormValues = [...formLongValues];
    newFormValues.pop();
    setFormLongFields(newFormValues);
  };
  let removeLongShortFields = () => {
    let newFormValues = [...formShortValues];
    newFormValues.pop();
    setFormShortFields(newFormValues);
  };
  let removeTableFields = (i) => {
    let newFormValues = [...tableValues];
    newFormValues.splice(i, 1);
    //let inputId = "textDes"+i;
    document.getElementById("current_ifsp_goal" + i).value = '';
    document.getElementById("current_knowledge" + i).value = '';
    document.getElementById("goal_met" + i).value = '';
   // document.getElementById("goal_met" + i).checked = false;
    setTableValues(newFormValues);
  };
  const changeInfo = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setquartelyProgress(
      produce((draftState) => {
        draftState.step1[name] = value;
      })
    );
  };
  const outcomeInfo = (e) => {
    const { name, value } = e.target;
    setquartelyProgress(
      produce((draftState) => {
        draftState.step4[name] = value;
      })
    );
  };
  const reviewInfo = (e, i) => {
    const { name, value } = e.target;

    setquartelyProgress(
      produce((draftState) => {
        draftState.step2.periodicReview = value;
      }))
  };
  const ifspInfo = (e, indx) => {
    const { name, value } = e.target;
    const newArray = [...quartelyProgress.step3.ifspOutcomeStatus];
    newArray[indx] = {
      ...newArray[indx],
      isChecked: !newArray[indx].isChecked,
    };
    console.log(newArray)
    setquartelyProgress(
      produce((draftState) => {
        draftState.step3.ifspOutcomeStatus = newArray;
      })
    );
  };
  const realInfo = (e, i) => {
    const { name, value } = e.target;
    const newArr = [...quartelyProgress.step3.developmentDomains]

    newArr[i] = {
      ...newArr[i],
      [name]: value,
    };
    console.log(newArr)
    setquartelyProgress(
      produce((draftState) => {
        draftState.step3.developmentDomains = newArr;
      }))
  };

  const goalInfo = (i, e) => {
    const { name, value } = e.target;
    const newArr = [...tableValues];
    console.log("eeee",name,value);
    newArr[i] = {
      ...newArr[i],
      ["id"]: i + 1,
      [name]: value
    };
    console.log("newArr",newArr);
    setTableValues(newArr)
    setquartelyProgress(
      produce((draftState) => {
        draftState.step2.previewGoals = tableValues;
      })
    );
  };
  const relValue = (typeof props?.data?.reply === 'undefined')
    ? ""
    : JSON.parse(props?.data?.jsonFormattedForm);

  const onSubmitFamilyForm = () => {
    setIsLoading(true);
    console.log("........................discharge", pageResult);
    let formData = new FormData();
    // console.log()
    formData.append(
      "formCreationForm",
      new Blob([JSON.stringify({ ...pageResult, jsonFormattedForm: JSON.stringify({ quartelyProgress }) })], {
        type: "application/json",
      })
    );
    adminService
      .onboardUser(`submitted-forms`, formData)
      .then((response) => {
        setIsLoading(false);
        setOpen(false);
        toast.success("Form Submitted successfully")
        props.handleBack("reload");

      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: "100px",
          }}
        >
          {!props?.data?.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.therapistSignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}
        </div>
        <div className="card-lg">
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
          <FormHeader
                logoUrl={logoUrl}
                title="USF BAY AREA EARLY STEPS QUARTERLY PROGRESS UPDATE
"
              />

              <hr />
              <br />
            <div className="card-lg__content">
              <div style={{ width: "100%" }}>
                <p style={{ width: "100%", textAlign: "center" }}></p>
                <div style={{ width: "100%" }}>
                  <table style={{ width: "100%", border: ".5px solid black" }}>
                    <tr>
                      <th
                        style={{
                          border: ".5px solid black",
                          padding: "1px 8px",
                          fontWeight:"normal"
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <label style={{ width: "auto" }}>
                            <b>Child Name:</b>
                          </label>
                          &nbsp;&nbsp;
                          {isSubmit ? (
                            relValue?.quartelyProgress?.step1?.childName
                          ) : (
                            <input
                              type="text"
                              placeholder="  "
                              name="childName"
                              className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                }`}
                              defaultValue={quartelyProgress?.step1?.childName}
                              onChange={(e) => changeInfo(e)}
                            />)}
                        </div>
                      </th>
                      <th
                        style={{
                          border: ".5px solid black",
                          padding: "1px 8px",
                          fontWeight:"normal"
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <label style={{ width: "auto" }}>
                            <b>Child DOB:</b>
                          </label>
                          &nbsp;&nbsp;
                          {isSubmit ? (

                            moment(relValue?.quartelyProgress?.step1?.dob).format("MM/DD/YYYY")
                          ) : (
                            <div className="form-date-input">
                              <DatePicker
                                multiple={false}
                                value={quartelyProgress?.step1?.dob}
                                onChange={setDate}
                                name="dob"
                                format="MM-DD-YYYY"
                                editable={false}
                              />
                            </div>

                          )}

                        </div>
                      </th>
                      <th
                        style={{
                          border: ".5px solid black",
                          padding: "1px 8px",
                          fontWeight:"normal"
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <label style={{ width: "auto" }}>
                            <b>Child MMI:</b>
                          </label>
                          &nbsp;&nbsp;
                          {isSubmit ? (
                            relValue?.quartelyProgress?.step1?.childMmi
                          ) : (
                            <input
                              type="text"
                              placeholder="  "
                              name="childMmi"
                              className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                }`}
                              defaultValue={""}
                              onChange={(e) => changeInfo(e)}
                            />)}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          padding: "1px 8px",
                          fontWeight:"normal"
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <label style={{ width: "auto" }}>
                            <b>Provider Name:</b>
                          </label>
                          &nbsp;&nbsp;
                          {isSubmit ? (
                            relValue?.quartelyProgress?.step1?.providerName
                          ) : (
                            <input
                              type="text"
                              placeholder="  "
                              name="providerName"
                              className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                }`}
                              defaultValue={quartelyProgress.step1?.providerName}
                              onChange={(e) => changeInfo(e)}
                            />)}
                        </div>
                      </th>
                      <th
                        style={{
                          border: ".5px solid black",
                          padding: "1px 8px",
                          fontWeight:"normal"
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <label style={{ width: "auto" }}>
                            <b>Provider Discipline:</b>
                          </label>
                          &nbsp;&nbsp;
                          {isSubmit ? (
                            relValue?.quartelyProgress?.step1?.providerDecipline
                          ) : (
                            <input
                              style={{ width:"58%"}}
                              type="text"
                              placeholder="  "
                              name="providerDecipline"
                              className={`form-control form-input ${errors.familyMemberName && "is-invalid"
                                }`}
                              defaultValue={quartelyProgress.step1?.providerDecipline}
                              onChange={(e) => changeInfo(e)}
                            />)}
                        </div>
                      </th>
                      <th
                        style={{
                          border: ".5px solid black",
                          padding: "1px 8px",
                          fontWeight:"normal"
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <label style={{ width: "auto" }}>
                            <b>SC:</b>
                          </label>
                          &nbsp;&nbsp;
                          {isSubmit ? (
                            relValue?.quartelyProgress?.step1?.sc
                          ) : (
                            <input
                              type="text"
                              placeholder="  "
                              name="sc"
                              className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                }`}
                              defaultValue={""}
                              onChange={(e) => changeInfo(e)}
                            />)}
                        </div>
                      </th>
                    </tr>
                  </table>
                  <table
                    style={{
                      width: "100%",
                      borderRight: ".5px solid black",
                      borderLeft: ".5px solid black",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          width: "69.6%",
                          padding: "1px 8px",
                          fontWeight:"normal"
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <label style={{ width: "auto" }}>
                            <b>Community Service Provider:</b>
                          </label>
                          &nbsp;&nbsp;
                          {isSubmit ? (
                            relValue?.quartelyProgress?.step1?.communityServiceProvider
                          ) : (
                            <input
                              type="text"
                              placeholder="  "
                              name="communityServiceProvider"
                              className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                                }`}
                              defaultValue={""}
                              onChange={(e) => changeInfo(e)}
                            />)}
                        </div>
                      </th>
                      <th
                        style={{
                          width: "30.4%",
                          borderLeft: ".5px solid black",
                          padding: "1px 8px",
                          fontWeight:"normal"
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <label style={{ width: "auto" }}>
                            <b>Date of Report: </b>
                          </label>
                          &nbsp;&nbsp;
                          {isSubmit ? (

                            <>{relValue?.quartelyProgress?.step1?.dateOfReport ? moment(relValue?.quartelyProgress?.step1?.dateOfReport).format("MM/DD/YYYY"): ''}</>
                          ) : (
                            <div className="form-date-input">
                              <DatePicker
                                multiple={false}
                                value={quartelyProgress?.step1?.dateOfReport}
                                onChange={evaDate}
                                name="dateOfReport"
                                format="MM-DD-YYYY"
                                editable={false}
                              />
                            </div>

                          )}

                        </div>
                      </th>
                    </tr>
                  </table>

                  <div style={{ width: "100%", border: ".5px solid black" }}>
                    <label style={{ width: "auto", padding: "1px 8px" }}>
                      <b>Progress Monitoring Tool Used: </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.quartelyProgress?.step1?.progressMonitorTool
                    ) : (
                      <input
                        type="text"
                        placeholder="  "
                        name="progressMonitorTool"
                        className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                          }`}
                        defaultValue={""}
                        onChange={(e) => changeInfo(e)}
                      />)}
                  </div>
                </div>
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>
                    Is this report being completed for a periodic (6-month)
                    review?
                  </b>{" "}
                  &nbsp;&nbsp; Yes{" "}
                  {isSubmit ? (<><input
                    type="radio"
                    value="Yes"
                    name="periodicReview"
                    checked={relValue?.quartelyProgress?.step2?.periodicReview == "Yes" ? true : false}
                    disabled={relValue?.quartelyProgress?.step2?.periodicReview == "No" ? true : true}
                    defaultValue={""}
                  />{" "}</>) : (<><input
                    type="radio"
                    value="Yes"
                    name="periodicReview"
                    defaultValue={""}
                    onChange={(e) => reviewInfo(e, 0)}
                  />{" "}</>)}

                  No{" "}
                  {isSubmit ? (<>
                    <input
                      type="radio"
                      value="No"
                      name="periodicReview"
                      checked={relValue?.quartelyProgress?.step2?.periodicReview == "Yes" ? false : true}
                      disabled={relValue?.quartelyProgress?.step2?.periodicReview == "Yes" ? true : true}
                      defaultValue={""}
                    /></>) : (<>
                      <input
                        type="radio"
                        value="No"
                        name="periodicReview"
                        defaultValue={""}
                        onChange={(e) => reviewInfo(e, 1)}
                      /></>)}
                  <br />
                  <i>
                    If yes, complete revised outcomes, goals and strategies at
                    the end of document.
                  </i>
                </label>
                <br />
                <br />
                {isSubmit ? (
                  <>
                    <table style={{ width: "100%", border: "1px solid black" }}>
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "1px 5px",
                            width: "40%",
                          }}
                        >
                          Current IFSP Goal(s)
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "1px 5px",
                            width: "40%",
                          }}
                        >
                          Current Knowledge or Skill Level
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "1px 5px",
                            width: "20%",
                          }}
                        >
                          Goal Met
                        </th>
                      </tr>




                      {relValue?.quartelyProgress?.step2?.previewGoals.map((element, indexs) => (
                        <>
                          <tr>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "1px 5px",
                                width: "40%",
                                fontWeight:"normal"
                              }}
                            >
                              {indexs + 1}{" "}
                              {relValue?.quartelyProgress?.step2?.previewGoals[indexs].current_ifsp_goal}

                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "1px 5px",
                                width: "40%",
                                fontWeight:"normal"
                              }}
                            >
                              {relValue?.quartelyProgress?.step2?.previewGoals[indexs].current_knowledge}

                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "1px 5px",
                                width: "20%",
                                fontWeight:"normal"
                              }}
                            >
                              {relValue?.quartelyProgress?.step2?.previewGoals[indexs].goal_met}
                              {/* &nbsp;&nbsp; Yes{" "}
                              <input
                                type="checkbox"
                                value="Yes"
                                name="goal_met"
                                defaultValue={""}
                                checked={relValue?.quartelyProgress?.step2?.previewGoals[indexs].goal_met == "Yes" ? true : false}
                                disabled={relValue?.quartelyProgress?.step2?.previewGoals[indexs].goal_met == "No" ? true : true}

                              />{" "}
                              No{" "}
                              <input
                                type="checkbox"
                                value="No"
                                name="goal_met"
                                defaultValue={""}
                                checked={relValue?.quartelyProgress?.step2?.previewGoals[indexs].goal_met == "Yes" ? false : true}
                                disabled={relValue?.quartelyProgress?.step2?.previewGoals[indexs].goal_met == "Yes" ? true : true}
                                />{" "} */}
                            </th>
                          </tr>
                        </>
                      ))}
                    </table>
                  </>) : (<>
                    <div>
                        <button
                          type="button"
                          className="btn btn-success "
                          style={{
                            width: "45px",
                            height: "28px",
                            padding: "0px",
                            margin: "10px",
                          }}
                          onClick={() => addTableFields()}
                        >
                          + 
                        </button>
                      </div>
                    <table style={{ width: "100%", border: "1px solid black" }}>
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "1px 5px",
                            width: "40%",
                          }}
                        >
                          Current IFSP Goal(s)
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "1px 5px",
                            width: "40%",
                          }}
                        >
                          Current Knowledge or Skill Level
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            padding: "1px 5px",
                            width: "20%",
                          }}
                        >
                          Goal Met (Yes/No)
                        </th>
                        <th style={{
                            border: "1px solid black",
                            padding: "1px 5px",
                            width: "20%",
                          }}>Action</th>
                      </tr>

                      {tableValues.map((element, indexs) => (
                        <>
                          <tr key={indexs}>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "1px 5px",
                                width: "40%",
                              }}
                            >
                              <lable>{indexs + 1}{"."}</lable>
                              <input
                                id={"current_ifsp_goal"+indexs}
                                type="text"
                                placeholder="  "
                                name="current_ifsp_goal"
                                className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                                  }`}
                                value={element.current_ifsp_goal}
                                onChange={(e) => goalInfo(indexs, e)}
                              />{" "}
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "1px 5px",
                                width: "40%",
                              }}
                            >
                              <input
                                id={"current_knowledge"+indexs}
                                type="text"
                                placeholder="  "
                                name="current_knowledge"
                                className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                                  }`}
                                value={element.current_knowledge}
                                onChange={(e) => goalInfo(indexs, e)}
                              />
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "1px 5px",
                                width: "20%",
                              }}
                            >
                              <input
                                id={"goal_met"+indexs}
                                type="text"
                                name="goal_met"
                                className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                                  }`}
                                value={element.goal_met}
                                onChange={(e) => goalInfo(indexs, e)}
                              />{" "}
                              {/* No{" "}
                              <input
                                id={"goal_metN"+indexs}
                                type="radio"
                                value="No"
                                name={"goal_met"+indexs}
                                onChange={(e) => goalInfo(indexs, e)}
                              />{" "} */}
                            </th>
                            <th style={{
                                border: "1px solid black",
                                padding: "1px 5px",
                                width: "20%",
                              }}> {isSubmit ? ('') :
                              (
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  style={{
                                    width: "105px",
                                    height: "20px",
                                    padding: "0px",
                                    backgroundColor: "red",
                                  }}
                                  onClick={() => removeTableFields(indexs)}
                                >
                                  <b>-</b>
                                </button>
                              )}</th>
                          </tr>
                        </>
                      ))}
                    </table>
                  </>)}

                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>Progress in Other Developmental Domains:</b>

                  <i>
                    (Indicate N/A if Domain is addressed in IFSP Goals above, or
                    you can complete the progress monitoring in that area for
                    additional information)
                  </i>
                </label>
                <br />
                <table style={{ width: "100%", border: ".5px solid black" }}>
                  <tr>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "30%",
                      }}
                    >
                      Domain
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      Current Knowledge or Skill Level
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      Concern or Follow-up Needed
                    </th>
                  </tr>

                  <tr>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "30%",
                      }}
                    >
                      Adaptive{" "}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      <label>CAN:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[0].can
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="can"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 0)}
                        />)}
                      <br />
                      <label>STILL EMERGING:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[0].stillEmerging
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="stillEmerging"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 0)}
                        />)}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[0].conern
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="conern"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 0)}
                        />)}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "30%",
                      }}
                    >
                      Social/Emotional{" "}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      <label>CAN:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[1].can
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="can"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 1)}
                        />)}
                      <br />
                      <label>STILL EMERGING:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[1].stillEmerging
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="stillEmerging"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 1)}
                        />)}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[1].conern
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="conern"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 1)}
                        />)}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "30%",
                      }}
                    >
                      Communication{" "}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      <label>CAN:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[2].can
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="can"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 2)}
                        />)}
                      <br />
                      <label>STILL EMERGING:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[2].stillEmerging
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="stillEmerging"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 2)}
                        />)}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[2].conern
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="conern"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 2)}
                        />)}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "30%",
                      }}
                    >
                      Motor{" "}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      <label>CAN:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[3].can
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="can"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 3)}
                        />)}
                      <br />
                      <label>STILL EMERGING:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[3].stillEmerging
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="stillEmerging"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 3)}
                        />)}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[3].conern
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="conern"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 3)}
                        />)}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "30%",
                      }}
                    >
                      Cognitive{" "}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      <label>CAN:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[4].can
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="can"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 4)}
                        />)}
                      <br />
                      <label>STILL EMERGING:</label>
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[4].stillEmerging
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="stillEmerging"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 4)}
                        />)}
                    </th>
                    <th
                      style={{
                        border: ".5px solid black",
                        padding: "1px 5px",
                        width: "40%",
                      }}
                    >
                      {isSubmit ? (
                        relValue?.quartelyProgress?.step3?.developmentDomains[4].conern
                      ) : (
                        <input
                          type="text"
                          placeholder="  "
                          name="conern"
                          className={`form-control form-input form-input-w-60per ${errors.familyMemberName && "is-invalid"
                            }`}
                          defaultValue={""}
                          onChange={(e) => realInfo(e, 4)}
                        />)}
                    </th>
                  </tr>
                </table>
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  <b>IFSP Outcome Status:</b>
                  &nbsp;&nbsp;   <input
                    type="checkbox"
                    value="true"
                    name="wf"
                    style={{ marginRight: "0" }}
                    defaultValue={""}
                    checked={relValue?.quartelyProgress?.step3?.ifspOutcomeStatus[0].isChecked}
                    onChange={(e) => ifspInfo(e, 0)}
                  />{" "} No Change{" "}
                  <input
                    type="checkbox"
                    value="true"
                    name="wfl"
                    style={{ marginRight: "0" }}
                    defaultValue={""}
                    checked={relValue?.quartelyProgress?.step3?.ifspOutcomeStatus[1].isChecked}
                    onChange={(e) => ifspInfo(e, 1)}
                  />{" "}
                  IFSP Meeting Required for Modification Consideration
                  <br />
                  <i>
                    **Must request IFSP meeting if any goals are met or new
                    concerns arise
                  </i>
                </label>
                <br />
                <div style={{ width: "100%", border: ".5px solid black" }}>
                  <label style={{ width: "auto", padding: "1px 5px" }}>
                    <b>Outcome: </b>
                  </label>
                  &nbsp;&nbsp;
                  {isSubmit ? (
                    relValue?.quartelyProgress?.step4?.outcome
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="outcome"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => outcomeInfo(e)}
                    />)}
                </div>
                <div
                  style={{
                    width: "100%",
                    borderRight: ".5px solid black",
                    borderLeft: ".5px solid black",
                  }}
                >
                  <label style={{ width: "auto", padding: "1px 5px" }}>
                    <b>Goals: </b>{" "}{isSubmit ? (<></>):(<button
                      type="button"
                      className="btn btn-success "
                      style={{
                        width: "45px",
                        height: "28px",
                        padding: "0px",
                        margin: "10px",
                      }}
                      onClick={() => addFormFields()}
                    >
                      + 
                    </button>)}
                  </label>
                  {isSubmit ? (<>
                    {relValue?.quartelyProgress?.step4?.goals.map((element, indexs) => (
                      <div style={{ marginLeft: "5px",wordBreak:"break-all" }}>
                        <label>{indexs + 1}.</label>
                        {relValue?.quartelyProgress?.step4?.goals[indexs].description}
                      </div>
                    ))}
                  </>) :
                    (<>
                      <div>
                        {formValues.map((element, indexs) => (
                          <div style={{ padding: "10px" }}>
                            <label>{indexs + 1}.</label>
                            <input
                              id={"goalDec" + indexs}
                              type="text"
                              placeholder=""
                              name="description"
                              className={`form-control form-input form-input-w-85per ${errors.familyMemberName && "is-invalid"
                                }`}
                              value={element.description}
                              onChange={(e) => handleChange(e, indexs)}
                            />
                            <button
                              type="button"
                              className="btn btn-primary "
                              style={{
                                width: "105px",
                                height: "20px",
                                padding: "0px",
                                backgroundColor: "red",
                              }}
                              onClick={() => removeFormFields(indexs)}
                            >
                              <b>-</b>
                            </button>
                          </div>
                        ))}
                      </div>
                    </>)}
                </div>
                <div style={{ width: "100%", border: ".5px solid black" }}>
                  <label style={{ width: "auto", padding: "1px 5px" }}>
                    <b>Strategies:</b>
                  </label>
                  &nbsp;&nbsp;
                  {isSubmit ? (
                    relValue?.quartelyProgress?.step4?.strategy
                  ) : (
                    <input
                      type="text"
                      placeholder="  "
                      name="strategy"
                      className={`form-control form-input form-input-w-37per ${errors.familyMemberName && "is-invalid"
                        }`}
                      defaultValue={""}
                      onChange={(e) => outcomeInfo(e)}
                    />)}
                </div>
              </div>
              <br />
              <label style={{ width: "auto", padding: "10px" }}>
              </label>
              &nbsp;&nbsp;
              <SignDate
                date={relValue?.quartelyProgress?.step4?.providerDate || quartelyProgress?.step4?.providerDate}
                familyImageURL={
                  isSubmit ? props?.data?.therapistSignUrl : familyImageURL
                }
                isSubmit={isSubmit}
                setOpen={setOpen}
                signTitle={"Provider"}
                guardian="Provider"
                familyInfo={familyInfo}
                isLoading={isLoading}
                userType="therapist"
              />
              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />
              <br />
              <br />
              <p style={{width:"100%"}}>Exhibit 11 – Quarterly Progress Update (rev 07-01-18)</p>
              <h6 style={{ width: "100%", textAlign: "center" }}>
                <b>
                  <i>
                    Maintain 1 copy for chart and send 1 copy to the Service
                    Coordinator
                  </i>
                </b>
              </h6>
              
              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {errors.consent1 ||
                    (errors.consent2 && (
                      <p className="error-text">
                        <small>All fields are require</small>
                      </p>
                    ))}
                  {/* {signatureErr && (
                        <p className="text-red">
                          <small>{signatureErr}</small>
                        </p>
                      )} */}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {console.log(
                    "familyInfo.therapistSign",
                    familyInfo.therapistSign
                  )}
                  {/* {props?.data?.replyNeeded && ( */}
                  <button
                    type={isLoading ? "button" : "submit"}
                    className="btn btn-success float-right1 mt-10 mb-10"
                    disabled={familyInfo.providerSign ? false : true}
                  >
                    {isLoading ? "Loading...." : "Submit"}
                  </button>
                  {/* )} */}
                </>
              ) : (
                <>
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default QuaterlyProgressForm;
