import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import Modal from "react-modal";

import axios from "axios";
import {
  getUTCTimeToLocalByDateWithMeridium,
  calculateDuration,
  getUTCDateTimeToLocalByDateWithMeridium24,
  calculateMinsLeft,
} from "../utils/commonFunctions";
import { ReactSVG } from "react-svg";
import StatusBadge from "./StatusBadge";
import AppTooltip from "./app-tooltip/AppTooltip";
import { adminService } from "../services/adminService";
import SweetAlert from "react-bootstrap-sweetalert";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const TherapySessionsTableRow = ({
  therapySessionsdetails,
  subinfo,
  watchVideo,
  user,
  Editsession,
  Deletesession,
  cancelSessionReq,
  ...props
}) => {
  const dummyStart = true;
  const today = new Date();
  const [timer, setTimer] = useState({});
  const [isJoinable, setIsJoinable] = useState(true);

  const joinableRoles = [
    "ROLE_THERAPIST",
    "ROLE_RCE",
    "ROLE_FAMILY",
    "ROLE_INTERPRETER",
  ];

  const recordingList = (subinfo?.sessionRecordings || [])
    .map((item) => item.sessionRecordingFiles?.map((itx) => itx?.fileUrl))
    ?.flat()
    ?.filter(Boolean);
  useEffect(() => {
    const startTime = moment.utc(subinfo.startDateTime).local();
    const endTime = moment.utc(subinfo.endDateTime).local();
    const maxDuration = moment.duration(endTime.diff(startTime));
    const duration = moment.duration(endTime.diff(moment()));
    setTimer({
      ...timer,
      startTime,
      endTime,
      maxDuration,
      et: subinfo.endDateTime,
      st: subinfo.startDateTime,
      duration,
      startDuration: moment.duration(startTime.diff(moment())),
    });
  }, [subinfo]);
  const [childList, setChildList] = useState([]);
  const [commonChildList, setCommonChildList] = useState([]);
  // mark as completed modal
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [updatedStatus, setupdatedStatus] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        timer.startTime &&
        ["IN_PROGRESS", "NOT_COMPLETED", "UP_COMING", "ACTION_NEEDED"].includes(
          subinfo.status
        )
      ) {
        const inTime = moment()
          .add(0, "hours")
          .add(0, "minutes")
          .isBetween(timer.startTime, timer.endTime);
        if (dummyStart) {
          if (!isJoinable) {
            setIsJoinable(true);
          }
        } else if (inTime) {
          if (!isJoinable) {
            setIsJoinable(true);
          }
        } else if (isJoinable) {
          setIsJoinable(false);
        }
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer, subinfo.status, isJoinable]);

  useEffect(() => {
    user.userDetails.roles[0] === "ROLE_FAMILY" && getChildList();
  }, [subinfo]);

  const getChildList = () => {
    adminService.getAll(`families/get-family-member`).then(
      (res) => {
        setChildList(res.data.content);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    let arr = [];
    if (
      user.userDetails.roles[0] === "ROLE_FAMILY" &&
      subinfo?.familyMemberDtoSet
    ) {
      arr = childList.filter((o1) =>
        subinfo?.familyMemberDtoSet.some((o2) => o1.id === o2.id)
      );
    }
    setCommonChildList(arr);
  }, [childList]);

  // Modal open
  const modalHandler = () => {
    setModalOpen(true);
  };
  const hideAlert = () =>{
    setModalOpen(false);
  }
  // Mark as Completed
  const markCompletedHandler = () => {
    let sessionId = subinfo.id;
    var data = {
      description: "",
      sessionId: sessionId,
      attachmentsList: [],
      drafted: true,
    };
    setisLoading(true);
    adminService
      .POST(`case-notes`, JSON.stringify(data))
      .then((res) => {
        if (res?.data) {
          setupdatedStatus("COMPLETED");
          setModalOpen(false);
          setisLoading(false);
        }
      })
      .catch((e) => {
        setModalOpen(false);
        setisLoading(false);
      });
  };
  // Mark as Missed
  const missHandler = () => {
    setModalOpen(false);
    let sessionId = subinfo.id;
    setisLoading(true);
    adminService
      .PUT(
        `therapist/sessions/change-status/${sessionId}/MISSED`,
        JSON.stringify({})
        // status: "MISSED"
      )
      .then((res) => {
        if (res?.data) {
          setupdatedStatus(res.data.status);
          setModalOpen(false);
          setisLoading(false);
        }
      })
      .catch((e) => {
        setModalOpen(false);
        setisLoading(false);
      });
  };
  const time = calculateMinsLeft(subinfo?.startDateTime);
  const endTime = calculateMinsLeft(subinfo?.endDateTime);
  if (
    time < 5 &&
    endTime >= 1 &&
    subinfo?.status != "COMPLETED" &&
    subinfo?.status != "CANCELLED"
  ) {
    subinfo.isSessionReady = true;
  } else {
    subinfo.isSessionReady = false;
  }
  return (
    <>
      {props.isFirst && (
        <tr>
          <td className="bg-white text-left px-3" colSpan={8}>
            <div className="d-flex align-items-center">
              {/* <hr style={{width: 30, marginLeft: 0, marginRight: 20}} /> */}
              {/* <div className="billing-item p-0 date-width"> */}
              <p className={` m-0 ${props.isToday && "text-theme-blue"}`}>
                {props.displayDate}
              </p>
              {/* </div> */}
            </div>
          </td>
        </tr>
      )}

      <tr dataRole={user.userDetails.roles[0]} className="cursor-pointer">
        <td onClick={() => therapySessionsdetails(subinfo.id, subinfo)}>
          <span
            className="ts-therapy-name"
            // style={{ width: '97px' }}
          >
            {subinfo.therapy.name}
          </span>
        </td>
        <td onClick={() => therapySessionsdetails(subinfo.id, subinfo)}>
          <span className="ts-family-name">
            {/* {console.log("child?.firstName", subinfo?.familyMemberDtoSet)} */}

            {user.userDetails.roles[0] == "ROLE_FAMILY" &&
            commonChildList.length > 0 ? (
              <>
                {commonChildList[0]?.firstName +
                  " " +
                  commonChildList[0]?.lastName}
              </>
            ) : subinfo?.familyMemberDtoSet &&
              subinfo?.familyMemberDtoSet.length > 0 ? (
              <>
                {subinfo?.familyMemberDtoSet[0]?.firstName +
                  " " +
                  subinfo?.familyMemberDtoSet[0]?.lastName}
              </>
            ) : (
              <>No Client Present</>
            )}

            {subinfo.familyMemberDtoSet.length > 1 &&
              ` +${subinfo.familyMemberDtoSet.length - 1} more`}
            {/* {subinfo?.familyMember?.firstName +
              " " +
              subinfo?.familyMember?.lastName} */}
          </span>
        </td>
        <td onClick={() => therapySessionsdetails(subinfo.id, subinfo)}>
          <div
            className="ts-interpreter"
            // style={{ width: '97px' }}
          >
            {" "}
            <img
              className="pr-1"
              src="../resources/assets/Icon material-translate.svg"
              alt=""
            />
            {subinfo.interpreter
              ? subinfo.interpreter.userProfile.firstName +
                " " +
                subinfo.interpreter.userProfile.lastName
              : "None Needed"}
          </div>
        </td>
        <td onClick={() => therapySessionsdetails(subinfo.id, subinfo)}>
          <span
            className="ts-time-session ts-time text-nowrap"
            // style={{ width: '140px' }}
          >
            <img
              className="pr-1"
              src="../resources/assets/duration-svgicon.svg"
              alt=""
            />
            {getUTCTimeToLocalByDateWithMeridium(subinfo.startDateTime)} -{" "}
            {getUTCTimeToLocalByDateWithMeridium(subinfo.endDateTime)}
          </span>
        </td>
        <td
          style={{ width: 10 }}
          onClick={() => therapySessionsdetails(subinfo.id, subinfo)}
        >
          <span
            className="ts-therapy-name ts-session-duration text-nowrap"
            // style={{ width: '80px' }}
          >
            {" "}
            <img
              className="pr-1"
              src="../resources/assets/duration-svgicon.svg"
              alt=""
            />{" "}
            {calculateDuration(subinfo.startDateTime, subinfo.endDateTime)}
          </span>
        </td>
        <td>
          <div
            className="w-100 text-center"
            // style={{ width: '150px' }}
          >
            {subinfo.status === "COMPLETED" && recordingList.length > 0 && (
              <>
                <button
                  className="btn button-watchvideo-green text-center"
                  onClick={() =>
                    watchVideo(subinfo.id, subinfo.therapy.name, recordingList)
                  }
                >
                  <ReactSVG
                    wrapper="span"
                    className="mr-1"
                    src="/resources/assets/Icon feather-monitor.svg"
                  />
                  {/* <img className="pr-1" src="../resources/assets/Icon feather-monitor.svg" alt="" /> */}
                  Watch Video
                </button>
              </>
            )}

            {/* -------start----- */}

            {user.userDetails.roles[0] === "ROLE_THERAPIST" &&
              subinfo.status === "ACTION_NEEDED" &&
              subinfo.locationOfTreatment != "Teletherapy" && (
                <>
                  {updatedStatus === "" && (
                    <button
                      onClick={modalHandler}
                      className="btn btn-theme-green"
                    >
                      {isLoading ? "Loading..." : "Action Needed"}
                    </button>
                  )}
                </>
              )}

            {/* ------end------- */}

            {user.userDetails.roles[0] != "ROLE_RCE" &&
              (subinfo.status === "UP_COMING" ||
                subinfo.status === "IN_PROGRESS") &&
              isJoinable &&
              subinfo.locationOfTreatment === "Teletherapy" && (
                <>
                  {subinfo.status === "UP_COMING" ? (
                    <>
                      {subinfo?.isSessionReady && (
                        <a
                          href={`/video-therapy/${subinfo.id}`}
                          className="btn btn-theme-green"
                        >
                          Join Now
                        </a>
                      )}
                    </>
                  ) : subinfo.status === "IN_PROGRESS" &&
                    getUTCDateTimeToLocalByDateWithMeridium24(
                      subinfo.endDateTime
                    ) >=
                      getUTCDateTimeToLocalByDateWithMeridium24(new Date()) ? (
                    <a
                      href={`/video-therapy/${subinfo.id}`}
                      className="btn btn-theme-green"
                    >
                      Join Now
                    </a>
                  ) : (
                    ""
                  )}
                </>
              )}
          </div>
        </td>
        <td
          className="dropleft"
          style={{ width: 10 }}
          onClick={() => therapySessionsdetails(subinfo.id, subinfo)}
        >
          {/* ((subinfo.status == "ACTION_NEEDED"
                  && user.userDetails.roles[0] === "ROLE_THERAPIST") ? subinfo.status : "" ) */}
          <div>
            <StatusBadge
              status={
                updatedStatus
                  ? updatedStatus
                  : subinfo.status == "ACTION_NEEDED" &&
                    user.userDetails.roles[0] != "ROLE_THERAPIST"
                  ? "ACTION_NEEDED_PENDING"
                  : subinfo.status == "IN_PROGRESS"
                  ? getUTCDateTimeToLocalByDateWithMeridium24(
                      subinfo.endDateTime
                    ) >= getUTCDateTimeToLocalByDateWithMeridium24(new Date())
                    ? subinfo.status
                    : "COMPLETED"
                  : subinfo.status
              }
            />
          </div>
        </td>
        <td style={{ width: 2, textAlign: "center" }}>
          {subinfo.status === "MISSED" && (
            <div className="btn-group dropleft">
              <button
                type="button"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src="../resources/assets/Icon ionic-md-more.svg" alt="" />
              </button>
              <div className="dropdown-menu">
                {user.userDetails.roles[0] === "ROLE_RCE" ? (
                  <Link
                    to={
                      "/edit-therapy-session/" +
                      subinfo.id +
                      "?group=" +
                      subinfo.groupSession
                    }
                    className="dropdown-item"
                  >
                    <img
                      className="pr-2"
                      alt=""
                      src="../resources/assets/action-icon-edit.svg"
                    />
                    Edit Session
                  </Link>
                ) : user.userDetails.roles[0] === "ROLE_THERAPIST" ? (
                  <Link
                    to={
                      "/edit-therapy-session/" +
                      subinfo.id +
                      "?group=" +
                      subinfo.groupSession
                    }
                    className="dropdown-item"
                  >
                    <img
                      className="pr-2"
                      alt=""
                      src="../resources/assets/action-icon-edit.svg"
                    />
                    Edit Session
                  </Link>
                ) : user.userDetails.roles[0] === "ROLE_FAMILY" ? (
                  <Link
                    onClick={() =>
                      Editsession(subinfo.id, subinfo.therapy.name, subinfo)
                    }
                    className="dropdown-item"
                  >
                    <img
                      className="pr-2"
                      alt=""
                      src="../resources/assets/action-icon-edit.svg"
                    />
                    Edit Session
                  </Link>
                ) : (
                  <Link
                    onClick={() =>
                      Editsession(subinfo.id, subinfo.therapy.name, subinfo)
                    }
                    className="dropdown-item"
                  >
                    <img
                      className="pr-2"
                      alt=""
                      src="../resources/assets/action-icon-edit.svg"
                    />
                    Edit Session
                  </Link>
                )}
              </div>
            </div>
          )}
          {["COMPLETED"].includes(subinfo.status) &&
            subinfo.caseNoteId &&
            [
              "ROLE_THERAPIST",
              "ROLE_FAMILY",
              "ROLE_INTERPRETER",
              "ROLE_RCE",
            ].includes(user.userDetails.roles[0]) && (
              <>
                <AppTooltip tooltip="View Session Note">
                  <Link to={`/case-notes/${subinfo.caseNoteId}`}>
                    <ReactSVG
                      className="ts-soap-note-icon"
                      wrapper="span"
                      src="/resources/assets/docs.svg"
                    />
                  </Link>
                </AppTooltip>
              </>
            )}
          {![
            "CANCELLED",
            "ACTION_NEEDED",
            "COMPLETED",
            "NOT_COMPLETED",
            "MISSED",
          ].includes(subinfo.status) && (
            <div className="btn-group dropleft">
              {getUTCDateTimeToLocalByDateWithMeridium24(subinfo.endDateTime) <
              getUTCDateTimeToLocalByDateWithMeridium24(new Date()) ? (
                ""
              ) : (
                <button
                  type="button"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="../resources/assets/Icon ionic-md-more.svg"
                    alt=""
                  />
                </button>
              )}
              <div className="dropdown-menu">
                {user.userDetails.roles[0] === "ROLE_RCE" ||
                user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS" ? (
                  <Link
                    to={
                      "/edit-therapy-session/" +
                      subinfo.id +
                      "?group=" +
                      subinfo.groupSession
                    }
                    className="dropdown-item"
                  >
                    <img
                      className="pr-2"
                      alt=""
                      src="../resources/assets/action-icon-edit.svg"
                    />
                    Edit Session
                  </Link>
                ) : user.userDetails.roles[0] === "ROLE_THERAPIST" ? (
                  <Link
                    to={
                      "/edit-therapy-session/" +
                      subinfo.id +
                      "?group=" +
                      subinfo.groupSession
                    }
                    className="dropdown-item"
                  >
                    <img
                      className="pr-2"
                      alt=""
                      src="../resources/assets/action-icon-edit.svg"
                    />
                    Edit Session
                  </Link>
                ) : user.userDetails.roles[0] === "ROLE_FAMILY" ? (
                  <Link
                    onClick={() =>
                      Editsession(subinfo.id, subinfo.therapy.name, subinfo)
                    }
                    className="dropdown-item"
                  >
                    <img
                      className="pr-2"
                      alt=""
                      src="../resources/assets/action-icon-edit.svg"
                    />
                    Edit Session
                  </Link>
                ) : (
                  <Link
                    onClick={() =>
                      Editsession(subinfo.id, subinfo.therapy.name, subinfo)
                    }
                    className="dropdown-item"
                  >
                    <img
                      className="pr-2"
                      alt=""
                      src="../resources/assets/action-icon-edit.svg"
                    />
                    Edit Session
                  </Link>
                )}

                {user.userDetails.roles[0] === "ROLE_RCE" ||
                user.userDetails.roles[0] === "ROLE_REGIONAL_EARLY_STEPS" ? (
                  <>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item"
                      onClick={() =>
                        Deletesession(subinfo.id, subinfo.therapy.name, subinfo)
                      }
                    >
                      <img
                        className="mr-2 cancelled-icon"
                        src="../resources/assets/cancel.png"
                        alt=""
                      />
                      Cancel
                    </Link>
                  </>
                ) : user.userDetails.roles[0] === "ROLE_THERAPIST" ? (
                  <>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item"
                      onClick={() =>
                        Deletesession(subinfo.id, subinfo.therapy.name, subinfo)
                      }
                    >
                      <img
                        className="mr-2 cancelled-icon"
                        src="../resources/assets/cancel.png"
                        alt=""
                      />
                      Cancel
                    </Link>
                  </>
                ) : user.userDetails.roles[0] === "ROLE_FAMILY" ? (
                  <>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item"
                      onClick={() =>
                        cancelSessionReq(
                          subinfo.id,
                          subinfo.therapy.name,
                          subinfo
                        )
                      }
                    >
                      <img
                        className="mr-2 cancelled-icon"
                        src="../resources/assets/cancel.png"
                        alt=""
                      />
                      Cancel
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </td>
      </tr>

      {modalOpen && (
        <>
          <SweetAlert
            confirmBtnBsStyle="info"
            title={
              <h4 className="font-size-1-55-rem">
                Is the session "Completed" or "Missed"?
              </h4>
            }
            focusCancelBtn
            showCancel={true}
            showConfirm={false}
            customClass="action-needed-popup"
            onCancel={hideAlert}
          >
            <div className="d-flex ">
              <button
                onClick={() => markCompletedHandler()}
                className="btn btn-success font-size-16"
              >
                Mark as completed
              </button>

              <button
                onClick={() => missHandler()}
                className="btn btn-danger font-size-16"
              >
                Mark as missed
              </button>
            </div>
          </SweetAlert>
        </>
      )}
    </>
  );
};

export default TherapySessionsTableRow;
