import React from 'react'

const Thumbnail = ({
    src
}) => {
    return (
        <div className="c-thumbnail" >
            <img src={src} alt="" className="c-thumbnail__image editpageimage" />
        </div>
    )
}

export default Thumbnail
