import React, { Children, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import moment from 'moment'
import { submitForm, updateForm } from '../../services/formsServices'
import { downloadFile, _toastHandleErr, _toasthandleRes } from '../../utils/commonFunctions'
import AppModal from '../AppModal'
import AttachmentBtn from '../attachment/AttachmentBtn'
import AttachmentFile from '../attachment/AttachmentFile'

const UploadedFormTh = (props) => {
    console.log("props form",props);
    const user = useSelector(state => state.adminUser.user?.userDetails)

    const {
        fileType, key: formUrl, id: formId, fileName, reply: formReply
    } = props.form || {}

    const [state, setState] = useState({
        isModal: false,
        formState: {},
        isLoading: false
    })

    const { register, getValues, formState: { errors }, handleSubmit, reset } = useForm()

    useEffect(() => {
        if(props.form){
            const {
                fileName, reply, fileType, id, key
            } = props.form
            const type = fileType?.includes('image') ? 'image' : 'document'
            setState(state => ({...state, formState: {
                ...state.formState,
                id,
                file: {
                    url: key,
                    type,
                    fileType,
                    fileName
                }
            }}))
        }


    }, [props.form])

    const toggleModal = () => {
        setState(state => ({ ...state, isModal: !state.isModal }))
    }

    const onChange = e => {
        const {
            files
        } = e.target
        const type = files[0]?.type?.includes('image') ? 'image' : 'document'
        
        const reader = new FileReader()
        reader.onloadend = function (file) {
            setState(state => ({
                ...state, formState: {
                    ...state.formState,
                    file: {
                        file: files[0],
                        url: file.target.result,
                        type,
                        fileType: files[0]?.type,
                        fileName: files[0]?.name,
                    },
                }
            }))
            
        }
        reader.readAsDataURL(files[0])
    }

    const onSubmit = () => {
        const sender = typeof user?.roles === 'string' ? user?.roles : (
            user?.roles?.length > 0 && user?.roles[0]
        )
        const dataObj = {
            ...getValues(),
            // form: state.formState?.file?.file,
            familyMemberId: props.child?.id,
            therapistId: props.therapist?.id,
            sender,
        }
        const data = new FormData()
        if(state.formState?.file?.file){
            data.append('form', state.formState?.file?.file)
        }
        Object.keys(dataObj).forEach(item => {
            data.append([item], dataObj[item])
        })
       
        setState(state => ({ ...state, isLoading: true }))
        updateForm(formId, data).then(res => {
            
            _toasthandleRes(res)
            reset()
            setState(state => ({ 
                ...state, 
                formState: {}
            }))
            if(typeof props.afterSuccess === 'function'){
                props.afterSuccess()
            }
        }).catch(_toastHandleErr)
        .finally(() => {
            setState(state => ({ 
                ...state, 
                isLoading: false,
                isModal: false
            }))
        })
    }

    if(!state.formState?.id)
        return null;

    const attachmentData = {
        attachmentType: fileType?.includes('image') ? 'image' : fileType?.includes("video/mp4") ? 'video' : fileType?.includes("audio/mpeg") ? 'audio' : 'document',
        src: formUrl,
        fileType,
    } 
    const date = moment.utc(props.form?.createdDate).utc().local().format('ddd DD MMMM, YYYY')
    const replyFormType = props.form?.fileFormDto?.fileType?.includes('image') ? "image" : "document"
    return (
        <div title={props.form?.fileName} className="c-forms__uploader" >
            <div className="c-forms__info-row d-flex text-left justify-content-between">
                <p className="c-forms__title flex-1">
                    {props.form?.fileName}
                </p>
                <span className="c-forms__date flex-0 pl-2">
                    {date}
                </span>
            </div>
            <div className="d-flex justify-content-between">
                <div className="flex-1">
                    <AttachmentFile 
                        {...attachmentData}
                        className={`c-attachments__file c-attachments__file--${attachmentData.attachmentType}`}
                    /> 
                   {!props.form?.fileFormDto?.key && props.archive == '' && <button disabled={state.isLoading} onClick={toggleModal} className="btn btn-outline-theme-blue c-forms__upload-btn">
                        <ReactSVG wrapper="span" src="/resources/assets/Icon feather-edit-3.svg" />
                        <span className="pl-2">Update Form</span>
                    </button>}
                    <AppModal
                        title="Upload Form"
                        isOpen={state.isModal}
                        onClose={toggleModal}
                    >
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <input type="text" {...register('title',{ required: true })} name="title" defaultValue={fileName} placeholder="form title" className="form-control" />
                            {
                                errors.title && <span className="error-text">Title is required</span>
                            }
                            <div className="align-items-center d-flex mt-2">
                                {state.formState.file?.url && 
                                <AttachmentFile
                                    {...(state.formState.file)}
                                    src={state.formState.file.url}
                                    attachmentType={state.formState?.file?.type}
                                    // fileName={state.formState?.file?.fileName}
                                    className={`c-attachments__file c-attachments__file--${state.formState.file.type}`}
                                    // onDelete={removeImage}
                                    />
                                }
                                <AttachmentBtn onChange={onChange} accept="image/*, .pdf, .doc, .docx" label={state.formState.file?.url ? "Change Form" : "Attach Form"} />
                            </div>
                            <div className="pt-2 text-left">
                                <label className="cursor-pointer" >
                                    <input className="form-check-inline" type="checkbox" defaultChecked={formReply} name="reply" {...register('reply')} />
                                    <span>Reply Needed</span>
                                </label>
                            </div>
                            <button disabled={state.isLoading} type="submit" className={classNames("btn btn-theme-green mb-4", {
                                "has-loader": state.isLoading,
                            })}>
                                Submit
                                {state.isLoading && <i className="loader-small"></i>}
                            </button>
                        </form>
                    </AppModal>
                
                </div>
                {
                    props.form.reply && 
                    <div className="flex-1">
                        {
                            props.form?.fileFormDto?.key ?
                            <div className="col-auto text-center" >
                                <AttachmentFile
                                    {...props.form.fileFormDto}
                                    src={props.form.fileFormDto.key}
                                    attachmentType={replyFormType}
                                    fileName = {props.form.fileFormDto.fileName !== "undefined" ? props.form.fileFormDto.fileName: ""}
                                    className={`mx-auto c-attachments__file c-attachments__file--${replyFormType}`}
                                />
                                <button onClick={() => {
                                    downloadFile(props.form.fileFormDto.key)
                                }} className="btn btn-theme-green">Download</button>
                            </div>
                            : (

                                <span className={classNames("badge p-2 font-weight-normal badge-theme-light-red")} >
                                    Pending
                                </span>
                            )
                        }
                    </div>
                }
            </div>
        </div>
    )
}
export default UploadedFormTh
