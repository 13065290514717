import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment, { utc } from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "./CommonComponents";
import SignDate from "./SignDate";

function MediaReleaseForm(props) {
  const formRef = React.useRef(null);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const profile = useSelector((state) => state.adminUser.profile);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const { inputs, setInputs, signatures, setSignatures } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [familyInfo, setFamilyInfo] = useState();
  const [signatureErr, setSignatureErr] = useState("");
  const [mediareleaseform, setmediareleaseform] = useState({});
  const sigCanvas = useRef({});
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const [isSubmit, setIsSubmit] = useState(
    props?.from !== "family" || props?.data?.reply
      ? true
      : props?.data?.replyNeeded === false
        ? !props?.data.replyNeeded
        : false
  );
  // const [mediareleaseform, setmediareleaseform] = useState({ 
  //   familySign:  props?.data?.familySignDate && props?.data?.family
  //   ? props?.data?.family?.userProfile?.signatureUrl
  //   : false, 
  //   patientName: props?.data?.familyMemberName
  //   ? props?.data?.familyMemberName
  //   : props?.data?.familyMember
  //   ? props.data.familyMember.firstName +
  //     " " +
  //     props.data.familyMember.lastName
  //   : " ",
  //   dateofbirth: props?.data?.familyMemberDob
  //   ? props?.data?.familyMemberDob
  //   : props?.data?.familyMember
  //   ? props.data.familyMember.dob
  //   : " ",
  //   familySignDate:  props?.data?.familySignDate
  //   ? props.data.familySignDate
  //   : false,
  //   consent1:  false,
  //   consent2:  false,
  // })

  // const [isSubmit, setIsSubmit] = useState(
  //   props?.from !== "family" || props?.data?.reply
  //     ? true
  //     : props?.data?.replyNeeded === false
  //     ? !props?.data.replyNeeded
  //     : false
  // );
  useEffect(() => {
    setFamilyInfo({
      id: props?.data?.id ? props?.data?.id : null,
      familyMemberName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      familyMemberDob: props?.data?.familyMemberDob
        ? props?.data?.familyMemberDob
        : props?.data?.familyMember
          ? props.data.familyMember.dob
          : " ",
      familySign:
        props?.data?.familySignDate && props?.data?.family
          ? props?.data?.family?.userProfile?.signatureUrl
          : false,
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
      consent1: false,
      consent2: false,
    });
    setmediareleaseform({
      familySign: props?.data?.familySignDate && props?.data?.family
        ? props?.data?.family?.userProfile?.signatureUrl
        : false,
      patientName: props?.data?.familyMemberName
        ? props?.data?.familyMemberName
        : props?.data?.familyMember
          ? props.data.familyMember.firstName +
          " " +
          props.data.familyMember.lastName
          : " ",
      dateofbirth: props?.data?.familyMemberDob
        ? props?.data?.familyMemberDob
        : props?.data?.familyMember
          ? props.data.familyMember.dob
          : " ",
      familySignDate: props?.data?.familySignDate
        ? props.data.familySignDate
        : false,
      consent1: false,
      consent2: false,
    })
  }, [props?.data])

  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setmediareleaseform({
      ...mediareleaseform,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };

  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setmediareleaseform({
              ...mediareleaseform,
              familySign: res.data.signatureKey,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };

  const onSubmitFamilyForm = (isSubmit = false) => {
    setIsLoading(true);
    if (props?.data?.replyNeeded) {
      if (familyInfo?.familySignDate) {
        console.log("familyInfo.name111111 ", familyImageURL, familyInfo);
        let formData = new FormData();
        // formData.append("signature", familyImageURL);
        formData.append(
          "formUpdationForm",
          new Blob([JSON.stringify({ ...familyInfo, jsonFormattedForm: JSON.stringify({ mediareleaseform }) })], {
            type: "application/json",
          })
        );
        adminService
          .onboardPutUser(
            `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
            formData
          )
          .then((response) => {
            setIsLoading(false);
            toast.success("Form Submitted successfully")
            props?.from == "family" && props.handleBack();
            setSignatureErr("");
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        console.log("familyInfo.name errr");
        setSignatureErr("Signature is required!");
      }
    } else if (props?.from === "admin") {
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("signature", familyImageURL);
      console.log("formData 220", formData);
      formData.append(
        "formCreationForm",
        new Blob([JSON.stringify(props?.inputs)], {
          type: "application/json",
        })
      );
      adminService
        .onboardUser(
          `submitted-forms`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Form Submitted successfully");
          props.setIsFocused(!props.isFocused);
          props?.from == "admin" && props.handleBack("reload");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };

  const updateInfo = (e) => {
    const { name, value } = e.target;
    setmediareleaseform({ ...mediareleaseform, [name]: value });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "MediaReleaseForm.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };
  const relValue = props?.data?.reply
    ? JSON.parse(props.data?.jsonFormattedForm)
    : "";
  // console.log("props.data", props.data.familyMember);

  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >  <div
        style={{
          position: 'absolute', top: 0, right: "100px"

        }}>
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.familySignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}</div>
        <div className="card-lg">
          <FormHeader logoUrl={logoUrl} title="MEDIA RELEASE FORM" />
          <hr />
          <br />
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content">
              <div className="w-100per">
                <div style={{ width: "60%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>Client's Name : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.mediareleaseform?.patientName
                    ) : (
                      <input
                        {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                        type="text"
                        placeholder="Child's name"
                        name="patientName"
                        className={`form-control form-input form-input-w-60per ${errors?.patientName && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberName}
                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "40%", display: "inline-block" }}>
                  <div style={{ width: "90%" }}>
                    <label style={{ width: "auto" }}>
                      <b>DOB : </b>
                    </label>
                    &nbsp;&nbsp;
                    {isSubmit ? (
                      relValue?.mediareleaseform?.dateofbirth.trim() &&
                      moment(relValue?.mediareleaseform?.dateofbirth).format("MM/DD/YYYY")
                    ) : (
                      <input
                        {...register('dateofbirth', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberDob === '' ? true : false })}
                        type="date"
                        name="dateofbirth"
                        placeholder="DOB"
                        className={`form-control form-input form-input-w-60per ${errors?.dateofbirth && "is-invalid"
                          }`}
                        defaultValue={familyInfo?.familyMemberDob}
                        disabled={familyInfo?.familyMemberDob === '' ? false : true}
                      // defaultValue={
                      //   props?.data?.familyMember
                      //     ? props.data.familyMember.dob
                      //     : " "
                      // }
                      //onChange={(e) => updateInfo(e)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="w-100per">
                <p className="mt-10">Dear Parent/Guardian,</p>
                <p className="w-100per">
                  At {name} Network, we will always ask your permission before
                  using your child’s image or work in media shared publicly.
                  Please indicate if you give consent to share your child’s
                  image for the following types of purposes:{" "}
                </p>
              </div>

              <br />
              <div style={{ textAlign: "center" }}>
                <h5
                  className="mt-10 mb-10"
                  style={{ textDecoration: "underline" }}
                >
                  Media Release
                </h5>
              </div>
              <br />
              <div className="col-md-12">
                <input
                  {...register('consent1', {
                    onChange: (e) => setmediareleaseform({
                      ...mediareleaseform,
                      consent1: !mediareleaseform?.consent1,
                    }), required: relValue?.mediareleaseform?.consent1 === false ? true : false
                  })}
                  className={`form-check-inline ${errors.consent1 && "is-invalid"
                    }`}
                  type="checkbox"
                  name="consent1"
                  defaultValue={relValue?.mediareleaseform?.consent1}
                  checked={relValue?.mediareleaseform?.consent1}
                  // onChange={(e) =>
                  //   setmediareleaseform({
                  //     ...mediareleaseform,
                  //     consent1: !mediareleaseform?.consent1,
                  //   })
                  // }
                  disabled={
                    profile?.userType === "ROLE_RCE" || isSubmit ? true : false || relValue?.mediareleaseform?.consent1 === false
                  }
                />
                I give permission to {name} Network to record, videotape and /or
                take photographs of my child/family as necessary to aid the
                therapeutic team in coaching, consulting and training sessions.
              </div>
              <div className="col-md-12 mt-10">
                <input
                  {...register('consent2', {
                    onChange: (e) =>
                      setmediareleaseform({
                        ...mediareleaseform,
                        consent2: !mediareleaseform?.consent2,
                      }), required: relValue?.mediareleaseform?.consent2 === false ? true : false
                  })}
                  className={`form-check-inline ${errors.consent2 && "is-invalid"
                    }`}
                  type="checkbox"
                  name="consent2"
                  defaultValue={relValue?.mediareleaseform?.consent2}
                  checked={relValue?.mediareleaseform?.consent2}
                  // onChange={(e) =>
                  //   setmediareleaseform({
                  //     ...mediareleaseform,
                  //     consent2: !mediareleaseform?.consent2,
                  //   })
                  // }
                  disabled={
                    profile?.userType === "ROLE_RCE" || isSubmit ? true : false || relValue?.mediareleaseform?.consent2 === false
                  }
                />
                I give permission to {name} Network to share approved media of
                my child/family on MEIS’s social media, on our website, or in
                promotional materials including but not limited to: posters,
                brochures, commercials.
              </div>
              <br />
              <SignDate
                date={relValue?.mediareleaseform?.familySignDate || mediareleaseform?.familySignDate}
                familyImageURL={familyImageURL}
                isSubmit={isSubmit}
                setOpen={setOpen}
                signTitle="Guardian"
                familyInfo={familyInfo}
              />

              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />

              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {signatureErr && (
                    <p className="text-red">
                      <small>{signatureErr}</small>
                    </p>
                  )}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {props?.data.replyNeeded && (
                    <button
                    type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={
                        mediareleaseform?.familySign &&
                          mediareleaseform?.consent1 &&
                          mediareleaseform?.consent2
                          ? false
                          : true
                      }
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {props?.from === 'admin' && props?.isFormOpen &&
                    <>
                      <button
                        className="btn btn-theme-green"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btn-success float-right1 mt-10 mb-10"
                      >
                        {isLoading ? "Loading...." : "Submit"}
                      </button>
                    </>

                  }
                  {!props?.isFormOpen && (
                    <button
                      className="btn btn-theme-green mb-10"
                      onClick={() => props?.handleBack()}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default MediaReleaseForm;
