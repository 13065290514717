import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "./CreateTemplate";
import {
    FormHeader,
    HeaderDownloadButtons,
    FormFooter,
} from "../admin/CommonComponents";
import SignDate from "./SignDate";
import { numRegex } from "../../Utils/Regex";

function PaymentPolicy(props) {
    const history = useNavigate();
    const sigCanvas = useRef({});
    const sigWitnessCanvas = useRef({});
    const tenantName = useSelector((st) => st.adminUser?.tenantInfo?.tenantName);
    const profile = useSelector((state) => state.adminUser.profile);
    const soapNoteBillingData = useSelector(
        (st) => st.appState?.soapNoteBillingData
    );
    const formRef = React.useRef(null);
    const {
        register,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        handleSubmit,
        setValue,
    } = useForm();
    const [open, setOpen] = useState(false);
    const [witnessModal, setWitnessModal] = useState(false);
    const [familyImageURL, setFamilyImageURL] = useState(null);
    const [witnessImageURL, setWitnessImageURL] = useState(null);
    const { inputs, setInputs, signatures, setSignatures } = useFormContext();
    const [isLoading, setIsLoading] = useState(false);
    const [familyInfo, setFamilyInfo] = useState({});
    const [signatureErr, setSignatureErr] = useState("");
    const [jsonStatus, setJsonStatus] = useState(false);
    const [privatePolicy, setprivatePolicy] = useState();
    console.log("payment Form", props?.selectedFamily, props?.data);
    
    

    useEffect(() => {
        setFamilyInfo({
            id: props?.data?.id ? props?.data?.id : null,
            familyMemberName: props?.data?.familyMemberName
                ? props?.data?.familyMemberName
                : props?.data?.familyMember
                    ? props?.data?.familyMember?.firstName +
                    " " +
                    props?.data?.familyMember?.lastName
                    : " ",
            familyMemberDob: props?.data?.familyMemberDob
                ? props?.data?.familyMemberDob
                : props?.data?.familyMember
                    ? props?.data?.familyMember?.dob
                    : " ",
            familySign:
                props?.data?.familySignDate &&
                    props?.data?.familySignDate &&
                    props?.data?.family
                    ? props?.data?.family?.userProfile?.signatureUrl
                    : false,
            familySignDate: props?.data?.familySignDate
                ? props?.data?.familySignDate
                : false,
            familyRelationship: props?.data?.family
                ? props?.data?.family?.relationship
                : "",
            consent1: props?.data?.consent2 ? props?.data?.consent2 : false,
            consent2: props?.data?.consent2 ? props?.data?.consent2 : false,
        });
        setprivatePolicy({
            consent1: false,
            consent2: false,
            consent3: false,
            consent4: false,
            consent5: false,
            consent6: false,
            consent7: false,
            consent8: false,
            consent9: false,
            duedays: props?.from === "family" ? relValue?.privatePolicy?.duedays : "10",
            chequeFee: props?.from === "family" ? relValue?.privatePolicy?.chequeFee : "25",
            chequeDays: props?.from === "family" ? relValue?.privatePolicy?.chequeDays : "10",
            noticeDays: props?.from === "family" ? relValue?.privatePolicy?.noticeDays : "30",
            noticeHours: props?.from === "family" ? relValue?.privatePolicy?.noticeHours : "24",
            dueAmount: props?.from === "family" ? relValue?.privatePolicy?.dueAmount : "25",
        })
        setprivatePolicy({
            familySign:
                props?.data?.familySignDate && props?.data?.family
                    ? props?.data?.family?.userProfile?.signatureUrl
                    : false,
            patientName: props?.data?.familyMemberName
                ? props?.data?.familyMemberName
                : props?.data?.familyMember
                    ? props?.data?.familyMember?.firstName +
                    " " +
                    props?.data?.familyMember?.lastName
                    : " ",
            relationShip: props?.data.family ? props?.data?.family?.relationship : "",
            dateofbirth: props?.data?.familyMemberDob
                ? props?.data?.familyMemberDob
                : props?.data?.familyMember
                    ? props?.data?.familyMember?.dob
                    : " ",
            familySignDate: props?.data?.familySignDate
                ? props?.data?.familySignDate
                : false,
        });
    }, [props?.data]);
    const [pageResult, setpageResult] = useState({
        formId: "20",
        sentBy: profile?.userUniqeId,
        sentTo: props?.selectedFamily?.id,
        replyNeeded: true,
        reply: null,
        familyId: props?.selectedFamily?.familyId,
        familyMemberId: props?.selectedFamily?.id,
    });
    


    // const [isSubmit, setIsSubmit] = useState(
    //     props?.from != "family" || props?.data?.reply
    //         ? true
    //         : props?.data?.replyNeeded == false
    //             ? !props?.data?.replyNeeded
    //             : false
    // );



    if (soapNoteBillingData?.length <= 0) {
        // return null;
    }
    const { name, address1, address2, city, country, state, zipCode, logoUrl } =
        soapNoteBillingData;
    const joinWithComma = (...params) => params.filter(Boolean).join(", ");


    const clearWitness = () => sigWitnessCanvas.current.clear();
    const saveWitness = () => {
        setWitnessImageURL(
            sigWitnessCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        );
        setWitnessModal(false);
        setFamilyInfo({
            ...familyInfo,
            witnessSignDate: moment(new Date()).format("DD-MM-YYYY h:s:a"),
        });
    };

    const clear = () => sigCanvas.current.clear();
    const save = () => {
        setFamilyImageURL(
            sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        );
        setOpen(false);
        const currentDateTime = new Date();
        setFamilyInfo({
            ...familyInfo,
            familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
        });
        setprivatePolicy({
            ...privatePolicy,
            familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
        });
        const signatureData = {
            digitalSignature: sigCanvas.current
                .getTrimmedCanvas()
                .toDataURL("image/png"),
        };
        updateSignature(signatureData);
    };

    const updateSignature = (formData) => {
        const apiUrl = `user-profiles/${profile.id}`;
        adminService
            .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
            .then(
                (res) => {
                    if (res.status === "Success") {
                        const currentDateTime = new Date();
                        setFamilyInfo({
                            ...familyInfo,
                            familySign: res.data.signatureUrl,
                            familySignDate: moment(currentDateTime)
                                .local()
                                .format("MM-DD-YYYY hh:mm a"),
                        });
                        setprivatePolicy({
                            ...privatePolicy,
                            familySign: res.data.signatureKey,
                            familySignDate: moment(currentDateTime)
                                .local()
                                .format("MM-DD-YYYY hh:mm a"),
                        });
                    } else {
                        toast.error(res.message);
                    }
                },
                (error) => {
                    toast.error(error.info || error.msg);
                }
            )
            .finally(() => {
                // setSignLoading(false);
            });
    };
    const onSubmitFamilyForm = (isSubmit = false) => {
        setIsLoading(true);
        if (props?.from === "admin") {
            let postData = {
                "sessionCancellationCharges": privatePolicy?.dueAmount,
                "sessionCancellationNoticePeriod": privatePolicy?.noticeHours
            }
            console.log("formData 218");
            adminService.PUT(`families/cancellation-charges/${props?.selectedFamily?.id}`, JSON.stringify(postData)).then(
                (res) => {
                    console.log("cancellation charges", res);
                    if (res.status === "Success") {
                        //setDashboard(res.data);
                        setIsLoading(false);
                    } else {
                        //toast.error(res.message);
                    }
                    //setloadings(false);
                },
                (error) => {
                    console.log(error);
                }
            );
            console.log("formData 218");

            let formData = new FormData();
            // formData.append("signature", familyImageURL);
            console.log("formData 220", formData);
            formData.append(
                "formCreationForm",
                new Blob(
                    [
                        JSON.stringify({
                            ...pageResult,
                            jsonFormattedForm: JSON.stringify({ privatePolicy }),
                        }),
                    ],
                    {
                        type: "application/json",
                    }
                )
            );
            adminService
                .onboardUser(
                    `submitted-forms`,
                    formData
                )
                .then((response) => {
                    setIsLoading(false);
                    toast.success("Form Submitted successfully");
                    props.setIsFocused(!props.isFocused);
                    props?.from == "admin" && props.handleBack("reload");
                })
                .catch((err) => {
                    console.log("err", err);
                });
        }
        if (props?.from === "family") {
            if (familyInfo?.familySignDate) {
                let formData = new FormData();
                // formData.append("signature", familyImageURL);
                formData.append(
                    "formUpdationForm",
                    new Blob(
                        [
                            JSON.stringify({
                                ...familyInfo,
                                jsonFormattedForm: JSON.stringify({ privatePolicy }),
                            }),
                        ],
                        {
                            type: "application/json",
                        }
                    )
                );
                adminService
                    .onboardPutUser(
                        `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
                        formData
                    )
                    .then((response) => {
                        setIsLoading(false);
                        toast.success("Form Submitted successfully")
                        props?.from == "family" && props.handleBack("reload");
                    })
                    .catch((err) => {
                        console.log("err", err);
                    });
            } else {
                setSignatureErr("Signature is required!");
            }
        }
    };

    const updateInfo = (e) => {
        const { name, value } = e.target;
        setprivatePolicy({ ...privatePolicy, [name]: value });
    };

    const startDownload = () => {
        // setIsSubmit(false);
        const html = CreateTemplate(formRef);
        adminService
            .downloadPdfFromHtml(
                {
                    html,
                },
                { filename: "PaymentPolicy.pdf" }
            )
            .finally(() => {
                if (typeof onComplete === "function") {
                    // onComplete();
                }
            });
    };
    // const handlchildDays = (e) => {
    //     const { name, value } = e.target;
    //     console.log("name value", name, value);
    //     setprivatePolicy({ ...privatePolicy, [name]: value })
    //     console.log("privatePolicy", privatePolicy);
    // }
    const relValue = props.data.reply || props?.from === "family"
        ? JSON.parse(props?.data?.jsonFormattedForm)
        : "";

    // const relValue = jsonStatus || !props?.isFormOpen || props?.from === "family" ? JSON.parse(props?.data?.jsonFormattedForm) : '';
    console.log("props.data", props?.data?.jsonFormattedForm, props.data.reply, relValue);
    const [isSubmit, setIsSubmit] = useState(
        props?.from != "family" || props?.data?.reply
            ? true
            : props?.data?.replyNeeded == false
                ? !props?.data.replyNeeded
                : false
    );
    return (
        <>
            <>
                <div
                    className={`col-md-${props?.from ? 12 : 10}`}
                    id="my-code"
                    ref={formRef}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            right: "100px",
                        }}
                    >
                        {!props?.data?.replyNeeded && props?.data?.id ? (
                            <>
                                <HeaderDownloadButtons
                                    isSubmit={true}
                                    startDownload={startDownload}
                                    setIsSubmit={setIsSubmit}
                                />
                            </>
                        ) : (
                            props?.data?.familySignDate && (
                                <HeaderDownloadButtons
                                    isSubmit={isSubmit}
                                    startDownload={startDownload}
                                    setIsSubmit={setIsSubmit}
                                />
                            )
                        )}</div>
                    <div className="card-lg">
                        <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
                            <FormHeader
                                logoUrl={logoUrl}
                                title="Payment Policy"
                            />

                            <hr />
                            <div className="card-lg__content">
                                <br />
                                <p style={{ width: "100%" }}>
                                    Thank you for choosing our private practice to serve you. We are committed to
                                    providing you with the highest quality care. Please know that the timely payment
                                    of your bill is an integral part of our service and as such, this payment policy is an
                                    agreement between you and {name ? name : ''} for payment of services
                                    provided. By signing this policy, you are agreeing to pay for services provided to
                                    you or your family member. As a client of {name ? name : ''} you are
                                    required to carefully review and sign our payment policy.
                                </p>
                                {/* <h3 style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>Fee Schedule</h3>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "55%", textAlign: "center" }}>
                                        <p style={{ width: "100%" }}><b>Therapy Services</b></p>
                                        <p style={{ width: "100%" }}><b>Comprehensive Evaluation</b></p>
                                        <p style={{ width: "100%" }}><b>Speech/Articulation Evaluation</b></p>
                                    </div>
                                    <div style={{ width: "45%" }}>
                                        <p style={{ width: "100%" }}><b>$50 per 30 minutes</b></p>
                                        <p style={{ width: "100%" }}><b>$250</b></p>
                                        <p style={{ width: "100%" }}><b>$125</b></p>
                                    </div>
                                </div> */}
                                <br />
                                <p style={{ width: "100%", marginBottm: "20px" }}><b>Please read the following information carefully :</b></p>
                                <p style={{ width: "100%" }}>All therapy fees are due :</p>

                                <p style={{ width: "100%" }}><input type="checkbox" name="consent1" checked={props.data.reply ? relValue?.privatePolicy?.consent1 : privatePolicy?.consent1} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} disabled={props.data.reply || props?.isFormOpen || props?.from === "admin" ? true : false} /> At the time of service for home health or clinic based services</p>
                                <p style={{ width: "100%" }}><input type="checkbox" name="consent2" checked={props.data.reply ? relValue?.privatePolicy?.consent2 : privatePolicy?.consent2} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} disabled={props.data.reply || props?.isFormOpen || props?.from === "admin" ? true : false} /> For school or child care based services, invoices are due within {props?.from === "family" || props.data.reply ? relValue?.privatePolicy?.duedays :
                                    <input {...register('duedays', {
                                        onChange: (e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.value }),
                                        required: privatePolicy?.duedays === '' ? true : false,
                                        pattern: numRegex,
                                    })} type="number" value={privatePolicy?.duedays} name="duedays" disabled={privatePolicy?.duedays === '' ? false : true} />} {errors.duedays && (
                                        <span className="fieldError">
                                            {errors.duedays?.message}
                                        </span>
                                    )} days of
                                    receiving the invoice.</p>

                                <p style={{ width: "100%" }}>We accept Cash, Check, Credit Card, and Venmo payment methods at this time.</p>
                                <p style={{ width: "100%" }}>Checks should be made payable to {name ? name : ''}.</p>
                                <p style={{ width: "100%" }}>We will provide you with an invoice outlining the services rendered and the
                                    amount charged.</p>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "50%" }}>
                                        <p style={{ width: "100%" }}><b>Name of Client : </b>
                                            {props?.from === "admin" && !props.data.reply ? <></> : <>
                                                {props.data.reply ? (
                                                    familyInfo?.familyMemberName
                                                ) : (
                                                    <input
                                                        {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                                                        type="text"
                                                        name="patientName"
                                                        className={`form-control form-input form-input-w-60per ${errors.patientName && "is-invalid"
                                                            }`}
                                                        defaultValue={familyInfo?.familyMemberName}
                                                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                                                    //onChange={(e) => updateInfo(e)}
                                                    />
                                                )}
                                            </>}
                                        </p>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <p style={{ width: "100%" }}><b>Date of Birth : </b>
                                            {props?.from === "admin" && !props.data.reply ? <></> : <>
                                                {props.data.reply ? (
                                                    familyInfo?.familyMemberDob?.trim() &&
                                                    moment(familyInfo?.familyMemberDob).format(
                                                        "MM-DD-YYYY"
                                                    )
                                                ) : (
                                                    <input
                                                        {...register('dateofbirth', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberDob === '' ? true : false })}
                                                        type="date"
                                                        name="dateofbirth"
                                                        className={`form-control form-input form-input-w-60per ${errors.dateofbirth && "is-invalid"
                                                            }`}
                                                        defaultValue={familyInfo?.familyMemberDob}
                                                        disabled={familyInfo?.familyMemberDob === '' ? false : true}
                                                    //onChange={(e) => updateInfo(e)}
                                                    />
                                                )}</>}</p>
                                    </div>
                                </div>
                                <p style={{ width: "100%" }}><b>Please read and check all boxes to acknowledge understanding and the
                                    sign below:</b></p>
                                <p style={{ width: "100%" }}>
                                    <input type="checkbox" name="consent3" checked={props.data.reply ? relValue?.privatePolicy?.consent3 : privatePolicy?.consent3} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} disabled={props?.isFormOpen || props?.from === "admin" || props.data.reply ? true : false} /> I understand that I am responsible for all costs / fees for therapy services. In
                                    the event that a third-party payer source determines that rendered therapy
                                    services are “not covered” or otherwise denied, I will be responsible for all
                                    outstanding charges. I understand that I will be billed accordingly and will be
                                    responsible for immediate payment. I also understand that {name ? name : ''} will not become involved in disputes between you and your third-party
                                    source regarding uncovered charges or reasons for denial.
                                </p>
                                <p style={{ width: "100%" }}>
                                    <input type="checkbox" name="consent4" checked={props.data.reply ? relValue?.privatePolicy?.consent4 : privatePolicy?.consent4} disabled={props.data.reply || props?.isFormOpen || props?.from === "admin" ? true : false} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} /> I understand that if fees are not paid in full, treatment sessions may be
                                    postponed or cancelled until payment is received.
                                </p>
                                <p style={{ width: "100%" }}>
                                    <input type="checkbox" name="consent5" checked={props.data.reply ? relValue?.privatePolicy?.consent5 : privatePolicy?.consent5} disabled={props.data.reply || props?.isFormOpen || props?.from === "admin" ? true : false} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} /> I understand that all returned checks will be subject to a ${props?.from === "family" || props.data.reply ? relValue?.privatePolicy?.chequeFee : <input 
                                    {...register('chequeFee',{
                                        onChange:(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.value }),
                                            required: true,
                                            pattern: numRegex,
                                        })
                                    } type="number" name="chequeFee" value={privatePolicy?.chequeFee} />}
                                    {errors.chequeFee && (
                                        <span className="fieldError">
                                            {errors.chequeFee?.message}
                                        </span>
                                    )} returned check
                                    fee. Charges incurred and not paid after {props?.from === "family" || props.data.reply ? relValue?.privatePolicy?.chequeDays : <input type="number" name="chequeDays" 
                                    {...register('chequeDays',{
                                        onChange:(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.value }),
                                            required: true,
                                            pattern: numRegex,
                                        })
                                    } value={privatePolicy?.chequeDays} />}
                                    {errors.chequeDays && (
                                        <span className="fieldError">
                                            {errors.chequeDays?.message}
                                        </span>
                                    )} days may be turned over to a
                                    collection agency at the client’s expense. Overdue accounts may also be
                                    reported to a Credit Bureau.
                                </p>
                                <p style={{ width: "100%" }}>
                                    <input type="checkbox" name="consent6" checked={props.data.reply ? relValue?.privatePolicy?.consent6 : privatePolicy?.consent6} disabled={props.data.reply || props?.isFormOpen || props?.from === "admin" ? true : false} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} /> I understand that I am responsible for all legal and collection fees, which {name ? name : ''} may incur if payment is not made in accordance with the
                                    terms and conditions herein.
                                </p>
                                <p style={{ width: "100%" }}>
                                    <input type="checkbox" name="consent7" checked={props.data.reply ? relValue?.privatePolicy?.consent7 : privatePolicy?.consent7} disabled={props.data.reply || props?.isFormOpen || props?.from === "admin" ? true : false} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} /> I understand that refunds will be issued only in instances of overpayment. All
                                    refunds will be processed within {props?.from === "family" || props.data.reply ? relValue?.privatePolicy?.noticeDays : <input 
                                    {...register('noticeDays',{
                                        onChange:(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.value }),
                                            required: true,
                                            pattern: numRegex,
                                        })} type="number" name="noticeDays" value={privatePolicy?.noticeDays} />} {errors.noticeDays && (
                                            <span className="fieldError">
                                                {errors.noticeDays?.message}
                                            </span>
                                        )} days after the overpayment is discovered on
                                    the client’s bill or at the time the refund is requested. Refunds for payments made
                                    with a credit card will be credited back to the credit card used, all other refunds
                                    will be issued by a check.
                                </p>
                                <p style={{ width: "100%" }}><input type="checkbox" name="consent8" checked={props.data.reply ? relValue?.privatePolicy?.consent8 : privatePolicy?.consent8} disabled={props.data.reply || props?.isFormOpen || props?.from === "admin" ? true : false} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} /> I, understand that all cancellations require {props?.from === "family" || props.data.reply ? relValue?.privatePolicy?.noticeHours : <input 
                                    {...register('noticeHours',{
                                        onChange:(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.value }),
                                        required: true,
                                        pattern: numRegex,
                                    })} type="number" name="noticeHours" value={privatePolicy?.noticeHours} />} {errors.noticeHours && (
                                        <span className="fieldError">
                                            {errors.noticeHours?.message}
                                        </span>
                                    )} hours notice and that there will
                                    be a ${props?.from === "family" || props.data.reply ? relValue?.privatePolicy?.dueAmount : <input 
                                    {...register('dueAmount',{
                                            onChange:(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.value }),
                                            required: true,
                                            pattern: numRegex,
                                        })} type="number" name="dueAmount" value={privatePolicy?.dueAmount} />} {errors.dueAmount && (
                                            <span className="fieldError">
                                                {errors.dueAmount?.message}
                                            </span>
                                        )} charge for any cancellations made less than {props?.from === "family" || props.data.reply ? relValue?.privatePolicy?.noticeHours : privatePolicy?.noticeHours} hours. This charge is
                                    my sole responsibility and will not be covered by a third-party source.</p>
                                <p style={{ width: "100%" }}><input type="checkbox" name="consent9" checked={props.data.reply ? relValue?.privatePolicy?.consent9 : privatePolicy?.consent9} disabled={props.data.reply || props?.isFormOpen || props?.from === "admin" ? true : false} onChange={(e) => setprivatePolicy({ ...privatePolicy, [e.target.name]: e.target.checked })} /> I, {props?.isFormOpen || props.data.reply ? (
                                    familyInfo?.familyMemberName
                                ) : (
                                    <input
                                        {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                                        type="text"
                                        placeholder="Client's name"
                                        name="patientName"
                                        className={`form-control form-input form-input-w-60per ${errors?.patientName && "is-invalid"
                                            }`}
                                        defaultValue={familyInfo?.familyMemberName}
                                        disabled={familyInfo?.familyMemberName === '' ? false : true}
                                    //onChange={(e) => updateInfo(e)}
                                    />
                                )}, understand the payment policy and the risks of not
                                    adhering to it
                                </p>
                                <br />
                                <div style={{ width: "100%", display: "flex" }}>
                                    <div style={{ width: "50%" }}>
                                        {/* <p style={{ width: "100%" }}>
                                            </p> */}
                                        <p style={{ width: "100%" }}><b>Name of Client : </b>{props?.from === "admin" && !props.data.reply ? <></> : <>
                                            {props.data.reply ? (
                                                familyInfo?.familyMemberName
                                            ) : (
                                                <input
                                                    {...register('patientName', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberName === '' ? true : false })}
                                                    type="text"
                                                    placeholder="Client's name"
                                                    name="patientName"
                                                    className={`form-control form-input form-input-w-60per ${errors?.patientName && "is-invalid"
                                                        }`}
                                                    defaultValue={familyInfo?.familyMemberName}
                                                    disabled={familyInfo?.familyMemberName === '' ? false : true}
                                                //onChange={(e) => updateInfo(e)}
                                                />
                                            )}</>}</p>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        {/* <p style={{ width: "100%" }}>
                                           </p> */}
                                        <p style={{ width: "100%" }}><b>Date of Birth : </b> {props?.from === "admin" && !props.data.reply ? <></> : <>
                                            {props.data.reply ? (
                                                familyInfo?.familyMemberDob?.trim() &&
                                                moment(familyInfo?.familyMemberDob).format(
                                                    "MM-DD-YYYY"
                                                )
                                            ) : (
                                                <input
                                                    {...register('dateofbirth', { onChange: (e) => updateInfo(e), required: familyInfo?.familyMemberDob === '' ? true : false })}
                                                    type="date"
                                                    name="dateofbirth"
                                                    className={`form-control form-input form-input-w-60per ${errors.dateofbirth && "is-invalid"
                                                        }`}
                                                    defaultValue={familyInfo?.familyMemberDob}
                                                    disabled={familyInfo?.familyMemberDob === '' ? false : true}
                                                //onChange={(e) => updateInfo(e)}
                                                />
                                            )}</>}</p>
                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", marginBottom: '10px' }}>
                                    <div style={{ width: "50%" }}>
                                        {/* <p style={{ width: "100%" }}>
                                            </p> */}
                                        <p style={{ width: "100%" }}><b>Relationship to Client : </b>{props?.from === "admin" && !props.data.reply ? <></> : <>
                                            {props.data.reply ? (
                                                familyInfo?.familyRelationship
                                            ) : (
                                                <input
                                                    {...register('relationShip', { onChange: (e) => updateInfo(e), required: familyInfo?.familyRelationship === '' ? true : false })}
                                                    type="text"
                                                    name="relationShip"
                                                    placeholder="Relationship"
                                                    className={`form-control form-input form-input-w-37per ${errors.relationShip && "is-invalid"
                                                        }`}
                                                    defaultValue={familyInfo?.familyRelationship}
                                                    disabled={familyInfo?.familyRelationship === '' ? false : true}
                                                //onChange={(e) => updateInfo(e)}
                                                />
                                            )}</>}</p>
                                    </div>
                                </div>
                                {props?.from === "admin" && !props.data.reply ? <></> : <>
                                    <SignDate
                                        date={relValue?.privatePolicy?.familySignDate || privatePolicy?.familySignDate}
                                        familyImageURL={familyImageURL}
                                        isSubmit={isSubmit}
                                        setOpen={setOpen}
                                        signTitle="Guardian"
                                        guardian="Legal Guardian"
                                        familyInfo={familyInfo}
                                    />
                                    <DigitalSignPopup
                                        modalState={open}
                                        setModalState={setOpen}
                                        save={save}
                                        clear={clear}
                                        sigCanvas={sigCanvas}
                                    />
                                </>}
                                <br />
                                <hr />
                                <FormFooter
                                    address={joinWithComma(
                                        address1,
                                        address2,
                                        city,
                                        country,
                                        zipCode
                                    )}
                                />
                                {!isSubmit ? (
                                    <>
                                        {errors.consent1 ||
                                            (errors.consent2 && (
                                                <p className="error-text">
                                                    <small>All fields are require</small>
                                                </p>
                                            ))}
                                        {/* {signatureErr && (
                        <p className="text-red">
                          <small>{signatureErr}</small>
                        </p>
                      )} */}
                                        <button
                                            className="btn btn-theme-green"
                                            onClick={() => props?.handleBack()}
                                            type="button"
                                        >
                                            Back
                                        </button>
                                        {console.log(
                                            "familyInfo.therapistSign",
                                            familyInfo?.familySign
                                        )}
                                        {/* {props?.data?.replyNeeded && ( */}
                                        <button
                                            type={isLoading ? "button" : "submit"}
                                            className="btn btn-success float-right1 mt-10 mb-10"
                                            disabled={familyInfo?.familySign && privatePolicy?.consent1 && privatePolicy?.consent2 && privatePolicy?.consent3 && privatePolicy?.consent4 && privatePolicy?.consent5 && privatePolicy?.consent6 && privatePolicy?.consent7 && privatePolicy?.consent8 && privatePolicy?.consent9 ? false : true}
                                        >
                                            {isLoading ? "Loading...." : "Submit"}
                                        </button>
                                        {/* )} */}
                                    </>
                                ) : (
                                    <>
                                        {props?.from === 'admin' && props?.isFormOpen &&
                                            <>
                                                <button
                                                    className="btn btn-theme-green"
                                                    onClick={() => props?.handleBack()}
                                                    type="button"
                                                >
                                                    Back
                                                </button>
                                                <button
                                                    type={isLoading ? "button" : "submit"}
                                                    className="btn btn-success float-right1 mt-10 mb-10"
                                                >
                                                    {isLoading ? "Loading...." : "Submit"}
                                                </button>
                                            </>

                                        }
                                        {!props?.isFormOpen && (
                                            <button
                                                className="btn btn-theme-green mb-10"
                                                onClick={() => props?.handleBack()}
                                                type="button"
                                            >
                                                Back
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </>
        </>
    );
}

export default PaymentPolicy;
