import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DigitalSignPopup from "../../digitalSignPopup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { adminService } from "../../../services/adminService";
import moment from "moment";
import { useFormContext } from "../FormContext";
import CreateTemplate from "../admin/CreateTemplate";
import {
  FormHeader,
  HeaderDownloadButtons,
  FormFooter,
} from "../admin/CommonComponents";
import SignDate from "../admin/SignDate";
import NameSignDate from "../admin/NameSignDate";
import TherapistSignDate from "../admin/TherapistSignDate";

function ParentProvider(props) {
  const formRef = React.useRef(null);
  const profile = useSelector((state) => state.adminUser.profile);
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [therapistopen, setTherapistopen] = useState(false);
  const [familyImageURL, setFamilyImageURL] = useState(null);
  const [therapistImageURL, settherapistImageURL] = useState(null);
  const [witnessModal, setWitnessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState({});
  const sigCanvas = useRef({});
  const therapistCanvas = useRef({});
  const [isSubmit, setIsSubmit] = useState(
    props?.from != "family" || props?.data?.reply ? true : false
  );
  const [signatureErr, setSignatureErr] = useState("");
  const soapNoteBillingData = useSelector(
    (st) => st.appState?.soapNoteBillingData
  );
  const [familyInfo, setFamilyInfo] = useState({
    id: props?.data?.id ? props.data.id : null,
    familyName: props?.data?.familyName ? props.data.familyName : "",
    familySign:
      props?.data?.familySignDate && props?.data?.family
        ? props?.data?.family?.userProfile?.signatureUrl
        : false,
    familySignDate: props?.data?.familySignDate
      ? props.data.familySignDate
      : false,
  });
  const [parentProvider, setparentProvider] = useState({
    familySign:
      props?.data?.familySignDate && props?.data?.family
        ? props?.data?.family?.userProfile?.signatureUrl
        : false,
    parentName: props.data.familyName ? props.data.familyName : "",
    therapistName: profile?.firstName
      ? profile.firstName + " " + profile?.lastName
      : " ",
    // therapistSign: profile?.signatureUrl ? profile.signatureUrl : " ",
    therapistSign:
      props?.data?.therapistSignDate && props?.data?.therapist
        ? props?.data?.therapist?.userProfile?.signatureUrl
        : false,
    therapistSignDate: props?.data?.therapist?.userProfile.signatureDateTime
      ? moment
        .utc(props?.data?.therapist?.userProfile.signatureDateTime)
        .utc()
        .local()
        .format("MM-DD-YYYY hh:mm a")
      : false,
    // profile?.signatureDateTime
    //   ? moment(profile.signatureDateTime).format("MM-DD-YYYY hh:mm a")
    //   : "",
    familySignDate: props?.data?.familySignDate
      ? props.data.familySignDate
      : false,
  });
  useEffect(() => {
    setInput({
      formId: 11,
      sentBy: props.inputs?.sentBy ? props.inputs.sentBy : "",
      sentTo: props.inputs?.sentTo ? props.inputs.sentTo : "",
      replyNeeded: true,
      reply: false,
      therapistId: profile?.therapistId ? profile?.therapistId : null,
      familyId: props?.inputs?.familyId ? props?.inputs?.familyId : null,
      familyMemberId: props?.inputs?.familyMemberId ? props?.inputs?.familyMemberId : null,
      InterpreterId: null,
      adminSignDate: null,
      therapistName: profile?.firstName
        ? profile.firstName + " " + profile?.lastName
        : " ",
      therapistSign: profile?.signatureUrl ? profile.signatureUrl : " ",
      therapistSignDate: profile?.signatureDateTime
        ? moment(profile.signatureDateTime).format("MM-DD-YYYY h:s:a")
        : "",
      familySignDate: null,
      interpreterSignDate: null,
      witnessSignDate: null,
      consent1: null,
      consent2: null,
      consent3: null,
      consent4: null,
      consent5: null,
    });
  }, [props?.data]);


  if (soapNoteBillingData?.length <= 0) {
    // return null;
  }
  const { name, address1, address2, city, country, state, zipCode, logoUrl } =
    soapNoteBillingData;
  const joinWithComma = (...params) => params.filter(Boolean).join(", ");

  const saveWitness = () => {
    setWitnessModal(false);
    setFamilyInfo({
      ...familyInfo,
      witnessSignDate: moment(new Date()).format("MM-DD-YYYY h:s:a"),
    });
  };
  const relValue =
    props?.data?.reply || props?.from == "therapist"
      ? ""
      : JSON.parse(props?.data?.jsonFormattedForm);
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setparentProvider({
      ...parentProvider,
      therapistName: relValue?.parentProvider?.therapistName,
    });
    setFamilyImageURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setOpen(false);
    const currentDateTime = new Date();
    setFamilyInfo({
      ...familyInfo,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    setparentProvider({
      ...parentProvider,
      familySignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const signatureData = {
      digitalSignature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateSignature(signatureData);
  };
  const therapistclear = () => therapistCanvas.current.clear();
  const therapistsave = () => {
    settherapistImageURL(
      therapistCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setTherapistopen(false);
    const currentDateTime = new Date();

    setparentProvider({
      ...parentProvider,
      therapistSignDate: moment(currentDateTime)
        .local()
        .format("MM-DD-YYYY hh:mm a"),
    });
    const therapistSignatureData = {
      digitalSignature: therapistCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png"),
    };
    updateTherapistSignature(therapistSignatureData);
  };
  const onSubmitFamilyForm = (isSubmit = false) => {
    setIsLoading(true);
    if (props?.data?.replyNeeded || !props?.data?.replyNeeded) {
      if (familyInfo.familySignDate || !familyInfo.familySignDate) {
        let formData = new FormData();
        // formData.append("signature", familyImageURL);
        if (props?.data?.replyNeeded) {
          formData.append(
            "formUpdationForm",
            new Blob(
              [
                JSON.stringify({
                  ...familyInfo,
                  jsonFormattedForm: JSON.stringify({ parentProvider }),
                }),
              ],
              {
                type: "application/json",
              }
            )
          );
          adminService
            .onboardPutUser(
              `submitted-forms/${props?.data?.id ? props.data.id : ""}`,
              formData
            )
            .then((response) => {
              setIsLoading(true);
              toast.success("Form Submitted successfully");
              props?.from == "family" && props.handleBack();
            })
            .catch((err) => {
              console.log("err", err);
            });
        } else {
          formData.append(
            "formCreationForm",
            new Blob(
              [
                JSON.stringify({
                  ...input,
                  jsonFormattedForm: JSON.stringify({ parentProvider }),
                }),
              ],
              {
                type: "application/json",
              }
            )
          );
          adminService
            .onboardUser(`submitted-forms`, formData)
            .then((response) => {
              setIsLoading(false);
              toast.success("Form Submited successfully");
              props.handleBack("reload");
            })
            .catch((err) => {
              setIsLoading(false);
              console.log("err", err);
            });
        }
      } else {
        setSignatureErr("Signature is required!");
      }
    } else {
      setIsLoading(false);
      toast.error("You don't have permission to submit form");
    }
  };
  const updateSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("res.data.signatureUrl", res.data.signatureUrl);
            const currentDateTime = new Date();
            setFamilyInfo({
              ...familyInfo,
              familySign: res.data.signatureUrl,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
            setparentProvider({
              ...parentProvider,
              familySign: res.data.signatureKey,
              familySignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const updateTherapistSignature = (formData) => {
    const apiUrl = `user-profiles/${profile.id}`;
    adminService
      .onboardPutUserJSONContent(apiUrl, JSON.stringify(formData))
      .then(
        (res) => {
          if (res.status === "Success") {
            console.log("res.data.signatureUrl", res.data.signatureUrl);
            const currentDateTime = new Date();

            setparentProvider({
              ...parentProvider,
              therapistSign: res.data.signatureKey,
              therapistSignDate: moment(currentDateTime)
                .local()
                .format("MM-DD-YYYY hh:mm a"),
            });
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          toast.error(error.info || error.msg);
        }
      )
      .finally(() => {
        // setSignLoading(false);
      });
  };
  const updateInfo = (e) => {
    const { name, value } = e.target;
    setparentProvider({ ...parentProvider, [name]: value });
  };

  const startDownload = () => {
    // setIsSubmit(false);
    const html = CreateTemplate(formRef);
    adminService
      .downloadPdfFromHtml(
        {
          html,
        },
        { filename: "ParentProvider.pdf" }
      )
      .finally(() => {
        if (typeof onComplete === "function") {
          // onComplete();
        }
      });
  };

  // console.log("props.data", relValue );
  return (
    <>
      <div
        className={`col-md-${props?.from ? 12 : 10}`}
        id="my-code"
        ref={formRef}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: "100px",
          }}
        >
          {!props?.data.replyNeeded && props?.data?.id ? (
            <>
              <HeaderDownloadButtons
                isSubmit={true}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            </>
          ) : (
            props?.data?.familySignDate && (
              <HeaderDownloadButtons
                isSubmit={isSubmit}
                startDownload={startDownload}
                setIsSubmit={setIsSubmit}
              />
            )
          )}
        </div>
        <div className="card-lg">
          <FormHeader logoUrl={logoUrl} title="PARENT / PROVIDER AGREEMENT" />
          <hr />
          <br />
          <form onSubmit={handleSubmit(onSubmitFamilyForm)}>
            <div className="card-lg__content">
              <div style={{ width: "100%" }}>
                <br />
                <p style={{ width: "100%" }}>
                  When receiving intervention services through {name} Network,
                  parents are expected to play an integral role in the education
                  and treatment of their child. Your assigned provider is not to
                  provide therapy to your child individually but to coach the
                  family as a whole to meet your child’s outcomes. The purpose
                  is to build the capacity of the caregivers using everyday
                  learning opportunities. We will partner with you to meet your
                  child's needs and achieve their goals, by using personalized
                  therapeutic and educational techniques you can build into your
                  daily life.
                </p>
              </div>
              <div style={{ width: "100%" }}>
                <p style={{ width: "100%" }}>
                  We encourage you to watch a short video by the Early Childhood
                  Technical Assistance Center which shows what a virtual Early
                  Intervention Session should look like:
                  <a
                    target="blank"
                    href="https://www.youtube.com/watch?v=pz_81OFGWxc&t=6s"
                  >
                    {" "}
                    https://www.youtube.com/watch?v=pz_81OFGWxc&t=6s
                  </a>
                </p>
                <br />
                <p>
                  <b>Clinician Roles & Responsibilities:</b>
                </p>
                <ul>
                  <li>
                    Intervention is based upon Plan of Care Goals and Objectives
                    & focus on families’ concerns. Intervention is to occur
                    during the naturally scheduled activity in which the
                    appointment is scheduled.
                  </li>
                  <li>
                    The clinician will provide therapy services by incorporating
                    both the child and the caregiver into the session.
                  </li>
                  <li>
                    Clinicians are not to bring their own therapy materials;
                    they are to use accessible materials found in the child’s
                    environment.
                  </li>
                  <li>
                    The clinician will teach the parent/caregiver how to set up
                    the child’s environment for play, incorporate strategies
                    into everyday routines and activities.
                  </li>
                  <li>
                    The clinician will model strategies and provide feedback
                    when parents try suggested interventions.
                  </li>
                  <li>
                    Clinicians are to honor families’ schedules and commitment
                    by arriving at appointments on time, giving families 24-hour
                    notice for cancellations and attempting to reschedule missed
                    appointments.
                  </li>
                </ul>
                <br />
                <p style={{ width: "100%" }}>
                  <b>Caregiver Roles & Responsibilities:</b>
                </p>
                <ul>
                  <li>
                    Parents are expected to be actively engaged and participate
                    in each early intervention session.
                  </li>
                  <li>
                    Parents are encouraged to ask questions and request
                    information as needed. Parents are not to leave the room,
                    take phone calls or be on the computer during session.
                    Siblings are encouraged to participate as well.
                  </li>
                  <li>
                    Research shows that young children learn best through
                    practice & repetition. The most effective intervention
                    occurs between home visits through homework and
                    implementation of skills modeled by the therapist.
                  </li>
                  <li>
                    We suggest that parents commit to spending 5 minutes every
                    day of one-on-one uninterrupted “special time” to work on
                    skills related to the goals.
                  </li>
                  <li>
                    Honor the time and commitment of the clinician by
                    maintaining appointments, giving at least 24-hour notice for
                    cancellations and attempting to reschedule missed
                    appointments.
                  </li>
                </ul>
              </div>

              <br />
              <p>
                <b>Signatures:</b>
              </p>
              <div>
                <NameSignDate
                  date={parentProvider.familySignDate}
                  familyImageURL={familyImageURL}
                  isSubmit={isSubmit}
                  setOpen={setOpen}
                  signTitle="Parent / Legal Guardian"
                  errors={errors}
                  familyInfo={familyInfo}
                  updateInfo={updateInfo}
                  inputName={
                    parentProvider?.parentName
                      ? parentProvider?.parentName
                      : relValue?.parentProvider?.parentName
                  }
                  register={register}
                />
              </div>
              <br />

              <TherapistSignDate
                date={parentProvider.therapistSignDate}
                familyImageURL={therapistImageURL}
                isSubmit={
                  props?.from == "therapist" && !props?.data?.reply
                    ? false
                    : true
                }
                setOpen={setTherapistopen}
                signTitle="Therapist"
                errors={errors}
                parentProvider={parentProvider}
                updateInfo={updateInfo}
                inputName={
                  props?.from == "therapist"
                    ? profile?.firstName + " " + profile?.lastName
                    : props?.data?.therapist?.userProfile?.firstName +
                    " " +
                    props?.data?.therapist?.userProfile?.lastName
                }
                register={register}
              />

              <DigitalSignPopup
                modalState={open}
                setModalState={setOpen}
                save={save}
                clear={clear}
                sigCanvas={sigCanvas}
              />

              <DigitalSignPopup
                modalState={therapistopen}
                setModalState={setTherapistopen}
                save={therapistsave}
                clear={therapistclear}
                sigCanvas={therapistCanvas}
              />
              <br />
              <hr />
              <FormFooter
                address={joinWithComma(
                  address1,
                  address2,
                  city,
                  country,
                  zipCode
                )}
              />
              {!isSubmit ? (
                <>
                  {signatureErr && (
                    <p className="text-red">
                      <small>{signatureErr}</small>
                    </p>
                  )}
                  <button
                    className="btn btn-theme-green"
                    onClick={() => props?.handleBack()}
                    type="button"
                  >
                    Back
                  </button>
                  {props?.data.replyNeeded && (
                    <button
                      type={isLoading ? "button" : "submit"}
                      className="btn btn-success float-right1 mt-10 mb-10"
                      disabled={familyInfo.familySign ? false : true}
                    >
                      {isLoading ? "Loading...." : "Submit"}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {props?.isFormOpen && (
                    <>
                      <button
                        className="btn btn-theme-green mb-10"
                        onClick={() => props?.handleBack()}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        type={isLoading ? "button" : "submit"}
                        className="btn btn-theme-green mb-10"
                        disabled={parentProvider.therapistSign ? false : true}
                      >
                        {isLoading ? "Loading...." : "Submit"}
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
            {props?.data?.reply && (
              <>
                <button
                  className="btn btn-theme-green mb-10"
                  onClick={() => props?.handleBack()}
                  type="button"
                >
                  Back
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
export default ParentProvider;