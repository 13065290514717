import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import allActions from "../redux/actions";
import { useSelector } from "react-redux";
//import { ReactSVG } from "react-svg";
import { adminService } from "../services/adminService";

const therapistMenus = [
  {
    link: "/dashboard",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Dashboard",
  },
  // {
  //   link: "/pending-users-onboarding",
  //   imgSrc: "/resources/assets/hourglass-split.svg",
  //   name: "Pending Onboarding",
  // },
  {
    link: "/pending-users-onboarding",
    imgSrc: "/resources/assets/hourglass-split.svg",
    name: "Pending Onboarding",
    pathIncludes:[
      "/pending-users-onboarding",
      "/edit-family"
    ]
  },
  {
    link: "/therapy-sessions",
    imgSrc: "/resources/assets/Icon feather-calendar.svg",
    name: "Therapy Sessions",
    pathIncludes: [
      "therapy-sessions",
      "add-therapy-session",
      "edit-therapy-session",
      "/archive-therapy-session"
    ],
  },
  {
    link: "/case-notes",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Session Notes",
    pathIncludes:[
      "case-notes",
      "/archive-case-notes",
      "/view-archive-case-notes"
    ]
  },
  {
    link: "/goals",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Goals",
    pathIncludes: ["/goals", "/misgoals","/archiveGoals","/viewArchiveGoal"],
  },
  {
    link: "/family",
    imgSrc: "/resources/assets/family.svg",
    name: "Client",
    pathIncludes: [
      "/family",
      "/add-family",
      "/child-detail",
      "edit-child-detail",
      "edit-parent-detail",
    ],
  },
  {
    link: "/therapist-form-list",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Forms",
    pathIncludes:[
      "therapist-form-list",
      "/archive-therapy-form-list"
    ]
  },
  {
    link: "/billing-info",
    imgSrc: "/resources/assets/bill.svg",
    name: "Billing Info",
    pathIncludes:["/billing-info","/archive-billing-info"]
  },
];
const therapistMenus2 = [
  {
    link: "/faq",
    imgSrc: "/resources/assets/FAQ.svg",
    name: "FAQ",
  },
];
const interpreterMenus = [
  {
    link: "/dashboard",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Dashboard",
  },
  {
    link: "/therapy-sessions",
    imgSrc: "/resources/assets/Icon feather-calendar.svg",
    name: "Therapy Sessions",
  },
  {
    link: "/case-notes",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Session Notes",
  },
  {
    link: "/family",
    imgSrc: "/resources/assets/family.svg",
    name: "Client",
  },
  {
    link: "/billing-info",
    imgSrc: "/resources/assets/bill.svg",
    name: "Billing Info",
  },
];
const familyMenus = [
  {
    link: "/dashboard",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Dashboard",
  },
  {
    link: "/therapy-sessions",
    imgSrc: "/resources/assets/Icon feather-calendar.svg",
    name: "Therapy Sessions",
  },
  {
    link: "/case-notes",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Session Notes",
  },
  {
    link: "/goals",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Goals",
    pathIncludes: ["/goals", "/misgoals"],
  },
  {
    name: "Billing Info",
    imgSrc: "/resources/assets/bill.svg",
    childMenu: [
      {
        link: "/billing-info",
        imgSrc: "/resources/assets/bill.svg",
        name: "Billing Details",
        pathIncludes: ["/billing-info", "/billing-detail"],
      },
      {
        link: "/card-list",
        imgSrc: "/resources/assets/bill.svg",
        name: "Card Details",
        pathIncludes: ["/card-list"],
      }
    ],
  },
  // {
  //   link:"/billing-info",
  //   imgSrc: "/resources/assets/bill.svg",
  //   name:"Billing Info"
  // },
  {
    link: "/my-resources",
    imgSrc: "/resources/assets/Icon feather-image.svg",
    name: "My Resources",
  },
  {
    link: "/my-team",
    imgSrc: "/resources/assets/team.svg",
    name: "My Team",
  },
  {
    link: "/family-form-list",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Forms",
  },
];
const familyMenus2 = [
  {
    link: "/faq",
    imgSrc: "/resources/assets/FAQ.svg",
    name: "FAQ",
  },
];
const adminMenus = [
  {
    link: "/dashboard",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Dashboard",
  },
  {
    link: "/pending-users-onboarding",
    imgSrc: "/resources/assets/hourglass-split.svg",
    name: "Pending Onboarding",
    pathIncludes:[
      "/pending-users-onboarding",
      "/edit-pending-therapist",
      "/edit-pending-interpreter",
      "/edit-family",
    ]
  },
  {
    link: "/therapy-sessions",
    imgSrc: "/resources/assets/Icon feather-calendar.svg",
    name: "Therapy Sessions",
    pathIncludes: [
      "/therapy-sessions",
      "/add-therapy-session",
      "/edit-therapy-session",
      "/archive-therapy-session"
    ],
  },
  {
    name: "Marketing",
    imgSrc: "/resources/assets/user.png",
    childMenu: [
      {
        link: "/marketing-sessions",
        imgSrc: "/resources/assets/video-call.png",
        name: "Marketing Appointment",
        pathIncludes: [
          "/marketing-sessions",
          "/add-marketing-session",
          "/edit-marketing-session",
        ],
      },
      {
        link: "/marketing-user",
        imgSrc: "/resources/assets/people.png",
        name: "Marketing User",
        pathIncludes: ["/cpt-code", "/add-cpt-code", "/view-cpt-code"],
      },
    ],
  },
  {
    name: "Therapist License",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    childMenu: [
      {
        link: "/icd-code",
        imgSrc: "/resources/assets/file-earmark-text.svg",
        name: "ICD 10 Codes",
        pathIncludes: ["/icd-code", "/add-icd-code", "/view-icd-code"],
      },
      {
        link: "/cpt-code",
        imgSrc: "/resources/assets/file-earmark-text.svg",
        name: "CPT Codes",
        pathIncludes: ["/cpt-code", "/add-cpt-code", "/view-cpt-code"],
      },
      {
        link: "/therapy-types",
        imgSrc: "/resources/assets/file-earmark-text.svg",
        name: "Therapy Types",
        pathIncludes: [
          "/therapy-types",
          "/add-therapy-type",
          "/view-therapy-type",
        ],
      },
    ],
  },
  {
    name: "Billing Info",
    imgSrc: "/resources/assets/bill.svg",
    childMenu: [
      {
        link: "/client-billing",
        imgSrc: "/resources/assets/bill.svg",
        name: "Client Billing",
        pathIncludes: ["/client-billing", "/view-client-bill","/client-billing-archive","/view-archive-client-bill"],
      },
      {
        link: "/Therapist-interpreter-billing",
        imgSrc: "/resources/assets/bill.svg",
        name: "Therapist/Interpreter Billing",
        pathIncludes: ["/Therapist-interpreter-billing","/archive-therapist-interpreter-billing"],
      }
    ],
  },
  {
    link: "/therapist",
    imgSrc: "/resources/assets/019---Therapist.svg",
    name: "Therapist",
    pathIncludes: ["/therapist", "/add-therapist", "/edit-therapist"],
  },
  {
    link: "/interpreter",
    imgSrc: "/resources/assets/translations.svg",
    name: "Interpreter",
    pathIncludes: ["/interpreter", "/add-interpreter", "/edit-interpreter"],
  },
  {
    link: "/family",
    imgSrc: "/resources/assets/family.svg",
    name: "Client",
    pathIncludes: [
      "/family",
      "/add-family",
      "/child-detail",
      "edit-child-detail",
      "edit-parent-detail",
    ],
  },
  {
    link: "/case-notes",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Session Notes",
    pathIncludes:[
      "case-notes",
      "/archive-case-notes",
      "/view-archive-case-notes"
    ]
  },
  
  {
    link: "/goals",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Goals",
    pathIncludes: ["/goals", "/misgoals","/archiveGoals","/viewArchiveGoal"],
  },
  
  {
    link: "/form-list",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Forms",
    pathIncludes:[
      "form-list",
      "/archive-admin-form-list"
    ]
  }
  // ,
  // {
  //   link: "/billing-info",
  //   imgSrc: "/resources/assets/bill.svg",
  //   name: "Billing Info",
  // },
];
const superAdminMenus = [
  {
    link: "/dashboard",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Dashboard",
  },
  {
    link: "/pending-users-onboarding",
    imgSrc: "/resources/assets/hourglass-split.svg",
    name: "Pending Onboarding",
    pathIncludes:[
      "/pending-users-onboarding",
      "/edit-pending-therapist",
      "/edit-pending-interpreter",
      "/edit-family",
    ]
  },
  {
    link: "/therapy-sessions",
    imgSrc: "/resources/assets/Icon feather-calendar.svg",
    name: "Therapy Sessions",
    pathIncludes: [
      "/therapy-sessions",
      "/add-therapy-session",
      "/edit-therapy-session",
      "/archive-therapy-session"
    ],
  },
  {
    name: "Marketing",
    imgSrc: "/resources/assets/user.png",
    childMenu: [
      {
        link: "/marketing-sessions",
        imgSrc: "/resources/assets/video-call.png",
        name: "Marketing Appointment",
        pathIncludes: [
          "/marketing-sessions",
          "/add-marketing-session",
          "/edit-marketing-session",
        ],
      },
      {
        link: "/marketing-user",
        imgSrc: "/resources/assets/people.png",
        name: "Marketing User",
        pathIncludes: ["/marketing-user","/edit-marketing-user"],
      },
    ],
  },
  {
    name: "Therapist License",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    childMenu: [
      {
        link: "/icd-code",
        imgSrc: "/resources/assets/file-earmark-text.svg",
        name: "ICD 10 Codes",
        pathIncludes: ["/icd-code", "/add-icd-code", "/view-icd-code"],
      },
      {
        link: "/cpt-code",
        imgSrc: "/resources/assets/file-earmark-text.svg",
        name: "CPT Codes",
        pathIncludes: ["/cpt-code", "/add-cpt-code", "/view-cpt-code","/edit-cpt-code"],
      },
      {
        link: "/therapy-types",
        imgSrc: "/resources/assets/file-earmark-text.svg",
        name: "Therapy Types",
        pathIncludes: [
          "/therapy-types",
          "/add-therapy-type",
          "/view-therapy-type",
        ],
      },
    ],
  },
  {
    name: "Billing Info",
    imgSrc: "/resources/assets/bill.svg",
    childMenu: [
      {
        link: "/client-billing",
        imgSrc: "/resources/assets/bill.svg",
        name: "Client Billing",
        pathIncludes: ["/client-billing", "/view-client-bill","/client-billing-archive","/view-archive-client-bill"],
      },
      {
        link: "/Therapist-interpreter-billing",
        imgSrc: "/resources/assets/bill.svg",
        name: "Therapist/Interpreter Billing",
        pathIncludes: ["/Therapist-interpreter-billing","/archive-therapist-interpreter-billing"],
      },
      {
        link: "/account-detail",
        imgSrc: "/resources/assets/bill.svg",
        name: "Account Details",
        pathIncludes: ["/account-detail"],
      }
    ],
  },
  {
    link: "/therapist",
    imgSrc: "/resources/assets/019---Therapist.svg",
    name: "Therapist",
    pathIncludes: ["/therapist", "/add-therapist", "/edit-therapist"],
  },
  {
    link: "/interpreter",
    imgSrc: "/resources/assets/translations.svg",
    name: "Interpreter",
    pathIncludes: ["/interpreter", "/add-interpreter", "/edit-interpreter"],
  },
  {
    link: "/family",
    imgSrc: "/resources/assets/family.svg",
    name: "Client",
    pathIncludes: [
      "/family",
      "/add-family",
      "/child-detail",
      "edit-child-detail",
      "edit-parent-detail",
    ],
  },
  {
    link: "/case-notes",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Session Notes",
    pathIncludes:[
      "case-notes",
      "/archive-case-notes",
      "/view-archive-case-notes"
    ]
  },
  
  {
    link: "/goals",
    imgSrc: "/resources/assets/file-earmark-text.svg",
    name: "Goals",
    pathIncludes: ["/goals", "/misgoals","/archiveGoals","/viewArchiveGoal"],
  },
  
  {
    link: "/form-list",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Forms",
    pathIncludes:[
      "form-list",
      "/archive-admin-form-list"
    ]
  }
  // ,
  // {
  //   link: "/billing-info",
  //   imgSrc: "/resources/assets/bill.svg",
  //   name: "Billing Info",
  // },
];
const resMenus = [
  {
    link: "/dashboard",
    imgSrc: "/resources/assets/dashboard.svg",
    name: "Dashboard",
  },
  {
    link: "/therapy-sessions",
    imgSrc: "/resources/assets/Icon feather-calendar.svg",
    name: "Therapy Sessions",
    pathIncludes: [
      "/therapy-sessions",
      "/add-therapy-session",
      "/edit-therapy-session",
      
    ],
  },
  {
    link: "/family",
    imgSrc: "/resources/assets/family.svg",
    name: "Client",
    pathIncludes: [
      "/family",
      "/add-family",
      "/child-detail",
      "edit-child-detail",
      "edit-parent-detail",
    ],
  },
];

const SidebarLink = ({
  link,
  isActive,
  imgSrc,
  name,
  onClick,
  therapistInfo,
  profile,
  childMenu,
  pathname,
}) => {
  const [isChildMenu, setChildMenu] = useState(false);
  const [isActiveMenus, setIsActiveMenu] = useState(null);
  const dispatch = useDispatch();
  let tenantPemission = useSelector((state) => state.adminUser.tenantPemission);
  useEffect(() => {
    if (profile) {
      adminService
        .getAll(`controls`)
        .then((res) => {
          dispatch(allActions.adminAction.setTenantPermission(res.data));
        })
        .catch((err) => console.log(err));
    }
    const mnu = localStorage.getItem("isActiveMenus");
    if (mnu) setIsActiveMenu(mnu);

    const isChildMenuItem = localStorage.getItem("isChildMenu");
    if (isChildMenuItem) setChildMenu(isChildMenuItem);
  }, [profile]);

  const handleOnClick = (index) => {
    const nm = index;
    console.log("nm", nm);
    const val = isActiveMenus == nm ? "" : nm;
    setIsActiveMenu(val);
    localStorage.setItem("isActiveMenus", nm);
  };

  function dropDown() {
    const nm = !isChildMenu;
    const val = isChildMenu == nm ? "" : nm;
    setChildMenu(val);
    localStorage.setItem("isChildMenu", nm);
  }
  const childs = childMenu ? childMenu.map((ch) => ch.link) : [];
  if (childs.length > 0) {
    childs.push(
      "/add-icd-code",
      "/add-cpt-code",
      "/add-therapy-type",
      `${pathname.includes("/edit-cpt-code") && pathname}`,
      `${pathname.includes("/view-cpt-code") && pathname}`,
      `${pathname.includes("/view-icd-code") && pathname}`,
      `${pathname.includes("/view-therapy-type") && pathname}`
    );
  }
  const childsList = childMenu ? childMenu.map((ch) => ch.link) : [];
  if (childsList.length > 0) {
    childsList.push(
      `${pathname.includes("/view-client-bill") && pathname}`,
    );
  }
  const childsListOne = childMenu ? childMenu.map((ch) => ch.link) : [];
  if (childsListOne.length > 0) {
    childsListOne.push(
      "/add-marketing-session",
      `${pathname.includes("/edit-marketing-session") && pathname}`,
      `${pathname.includes("/edit-marketing-user") && pathname}`,
    );
  }
  return (
    <>
      {profile?.userType == "ROLE_THERAPIST" ? (
        <>
          {therapistInfo?.onboardPermission == "false" &&
          link == "/pending-users-onboarding" ? (
            <></>
          ) : tenantPemission?.billing == false && link == "/billing-info" ? (
            <></>
          ) : (tenantPemission?.interpreter == false &&
              link == "/interpreter") ||
            link == "/add-interpreter" ||
            link == "/edit-interpreter" ? (
            <></>
          ) : (
            <>
              <li
                className={classNames({
                  "sidebar-active": isActive,
                })}
              >
                <Link to={link} onClick={onClick}>
                  <img
                    src={`${window.location.origin}${imgSrc}`}
                    alt=""
                    className="img-fluid"
                  />{" "}
                  <span>{name}</span>
                </Link>
              </li>
            </>
          )}
        </>
      ) : tenantPemission?.billing == false && link == "/billing-info" && name === "Billing Info" ? (
        <></>
      ): tenantPemission?.billing == false && name === "Billing Info" ? (
        <></>
      ) : (tenantPemission?.interpreter == false && link == "/interpreter") ||
        link == "/add-interpreter" ||
        link == "/edit-interpreter" ? (
        <></>
      ) : childMenu ? (
        <>
          <button
            className={` ${
              childsListOne.includes(pathname) && childsList.includes(pathname) && childs.includes(pathname)
                ? "activeDropDown"
                : childMenu.length > 0
                ? "dropdownMenu"
                : ""
            } ${
              childsListOne.includes(pathname) && childsList.includes(pathname) && childs.includes(pathname) == false && isChildMenu
                ? "selcted-active"
                : childsListOne.includes(pathname) && childsList.includes(pathname) && childs.includes(pathname) + isChildMenu
            }`}
            onClick={childMenu ? dropDown : ""}
          >
            <a className={childMenu ? "childMenuHeading" : ""}>
              <img
                src={`${window.location.origin}${imgSrc}`}
                alt=""
                className="img-fluid"
              />{" "}
              <span>{name}</span>
              <span className="toggleArrow">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  name="Therapy License"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"></path>
                </svg>
              </span>
            </a>
          </button>
          {isChildMenu &&
            childMenu &&
            childMenu.map((data, index) => {
              const viewPath = data.pathIncludes.includes(
                `/${pathname.split("/")[1]}`
              );
              return (
                <div className="dropDownMenuList">
                  <li
                    key={index}
                    onClick={() => handleOnClick(index)} // pass the index
                    className={
                      data.pathIncludes.includes(pathname) || viewPath
                        ? "activeDropDownMenu"
                        : ""
                    }
                  >
                    <Link to={data.link} onClick={onClick}>
                      <img
                        src={`${window.location.origin}${data.imgSrc}`}
                        alt=""
                        className="img-fluid"
                      />{" "}
                      <span>{data.name}</span>
                    </Link>
                  </li>
                </div>
              );
            })}
        </>
      ) : (
        <li
          className={classNames("sidebarItems", {
            "sidebar-active": isActive,
          })}
        >
          <Link to={link} onClick={onClick}>
            <img
              src={`${window.location.origin}${imgSrc}`}
              alt=""
              className="img-fluid"
            />{" "}
            <span>{name}</span>
          </Link>
        </li>
      )}
    </>
  );
};

const Menus = ({ menus, pathname, therapistInfo, profile }) => {
  return (
    <ul className="sidebar-item">
      {menus.map((item, i) => {
        const isActive = [item.link, item.pathIncludes]
          .flat()
          .some((item) => pathname.includes(item));
        return (
          <SidebarLink
            key={item.link + i}
            {...item}
            childMenu={item.childMenu}
            isActive={isActive}
            therapistInfo={therapistInfo}
            profile={profile}
            pathname={pathname}
          />
        );
      })}
    </ul>
  );
};
function Sidebar() {
  let d = new Date();
  const Year = d.getFullYear();
  let location = useLocation();
  let history = useNavigate();
  const dispatch = useDispatch();
  let user = useSelector((state) => state.adminUser.user);

  const profile = useSelector((state) => state.adminUser.profile);
  const [therapistInfo, setTherapistInfo] = useState({});
  console.log("userDetail",user?.userDetails?.superTenantAdmin);
  useEffect(() => {
    const id = profile?.therapistId;
    if (id) {
      adminService
        .getAll(`therapists/${id}`)
        .then((res) => {
          setTherapistInfo(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [profile?.therapistId]);

  const Logout = () => {
    console.log("logout...............");
    dispatch(allActions.adminAction.logOut());
    localStorage.clear();
    setTimeout(()=>{
      //toast.success("logout successfull");
      history("/login");
  },100);
  };
  let bottomCommonMenus = [
    {
      link: "/settings",
      imgSrc: "/resources/assets/Icon feather-settings.svg",
      name: "Settings",
      pathIncludes: [
        "/Settings",
        "/add-tenant",
        "/edit-tenant",
        "/view-tenant",
      ],
    },
    {
      link: "#",
      onClick: Logout,
      imgSrc: "/resources/assets/Icon feather-log-out.svg",
      name: "Logout",
    },
  ];
  let topMenus = [];

  switch (user?.userDetails?.roles?.[0]) {
    case "ROLE_RCE":
      if(user?.userDetails?.superTenantAdmin)
      {
      topMenus = superAdminMenus;
      }else{
        topMenus = adminMenus;
      }
      break;

    // case 'ROLE_SYSTEM_ADMIN':
    //   topMenus = adminMenus
    //   break;

    case "ROLE_THERAPIST":
      topMenus = therapistMenus;
      bottomCommonMenus = [...therapistMenus2, ...bottomCommonMenus];
      break;

    case "ROLE_FAMILY":
      topMenus = familyMenus;
      bottomCommonMenus = [...familyMenus2, ...bottomCommonMenus];
      break;

    case "ROLE_INTERPRETER":
      topMenus = interpreterMenus;
      break;

    case "ROLE_REGIONAL_EARLY_STEPS":
      topMenus = resMenus;
      break;

    default:
      break;
  }
  return (
    <>
      <div className="c-sidebar col-md-2 no-padding">
        {user && !user.userDetails.newUser && (
          <>
            <Menus
              menus={topMenus}
              pathname={location.pathname}
              therapistInfo={therapistInfo}
              profile={profile}
            />
            <hr />
            <Menus
              menus={bottomCommonMenus}
              pathname={location.pathname}
              therapistInfo={therapistInfo}
              profile={profile}
            />
          </>
        )}

        <div className="copyright">
          <img
            src="../resources/assets/MIS-1.png"
            className="copyright-image-sidebar "
            alt="mis"
          />
          <span className="copyright-text-sidebar">Copyright @RCE {Year}</span>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
