import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePageState from "../../hooks/InitialState";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";

function ViewTenentAdmin() {
  const { id } = useParams();
  const history = useNavigate();
  const [tenant, setTenant] = useState(null);
  const { pageState, setPageState } = usePageState();

  useEffect(() => {
    getTenant();
  }, []);

  const getTenant = () => {
    setPageState((item) => ({ ...item, loading: true }));
    adminService
      .getAll(`tenantadmin/${id}`)
      .then((res) => {
        // setPageState((item) => ({ ...item, loading: false }));
        if (res.status == "Success") {
          setTenant(res?.data);
          //setTotalPages(res?.data?.totalPages);
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      })
      .catch((err) => {
        toast.error(err);
        //setPageState((item) => ({ ...item, loading: false }));
      });
  };

  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            <div className="content-header pb-0">
              <div className="text-left header-title py-3">
                View Tenant Details
              </div>
            </div>
            <div className="mt-4 tenant_info">
              <div className="row">
                <div className="col-3">
                  {tenant?.profilePicUrl ? (
                    <img
                      src={tenant?.profilePicUrl}
                      style={{ width: 200, maxHeight: 200 }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-2" style={{ maxWidth: 120 }}>
                  <b>Name :</b>
                </div>
                <div className="col-9">
                  {tenant?.firstName + " " + tenant?.lastName}
                </div>
              </div>
              <div className="row">
                <div className="col-2" style={{ maxWidth: 120 }}>
                  <b>Email :</b>
                </div>
                <div className="col-9">{tenant?.email}</div>
              </div>
              <div className="row">
                <div className="col-2" style={{ maxWidth: 120 }}>
                  <b>Phone :</b>
                </div>
                <div className="col-9">{tenant?.phone}</div>
              </div>
              <div className="row">
                <div className="col-2" style={{ maxWidth: 120 }}>
                  <b>Gender :</b>
                </div>
                <div className="col-9">{tenant?.gender}</div>
              </div>
              <div className="row">
                <div className="col-2" style={{ maxWidth: 120 }}>
                  <b>About :</b>
                </div>
                <div className="col-9">{tenant?.about}</div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    class="btn btn-theme-blue btn-lg"
                    onClick={() => history("/settings")}
                  >
                    Back
                  </button>
                  <button
                    class="btn btn-theme-green btn-lg"
                    onClick={() => history("/edit-tenant/" + id)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewTenentAdmin;
