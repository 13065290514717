// import { Tooltip } from 'bootstrap';
import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
const defaultPopperOptions = {
    placement: 'top'
}
const AppTooltip = ({children, tooltip, popperOptions, wrapper}) => {
    const attPopperOptions = {
        ...defaultPopperOptions,
        ...popperOptions,
    }
    const {
      getArrowProps,
      getTooltipProps,
      setTooltipRef,
      setTriggerRef,
      visible,
    } = usePopperTooltip({
        delayShow: 1000
    }, {
        ...attPopperOptions,
    });
    const childrenWithProps = React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if(wrapper){
        //   const Item = React.cloneElement(wrapper, { ref: setTriggerRef });
          return <span ref={setTriggerRef} >
              {child}
          </span>
        }
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { ref: setTriggerRef });
        }
        return child;
    });

    return <>
        {childrenWithProps}
        {visible && tooltip && (
        <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'tooltip-container', placement: 'top' })}
        >
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            {tooltip}
        </div>
        )} 
    </>
}
export default AppTooltip;