import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { adminService } from "../../../services/adminService";
import { toast } from "react-toastify";

function ViewIcdCode() {
    const params = useParams();
    const [initialData, setInitialData] = useState({
        loader: false,
        error: false,
        data: [],
    });
    const getTherapyDetail = (id) => {
        setInitialData((item) => ({ ...item, loader: true }));
        adminService.getById(`diagnoses/diagnosisId/${id}`)
            .then((res) => {
                if (res.status == "Success") {
                    setInitialData((item) => ({
                        ...item,
                        loader: false,
                        data: res?.data,
                    }));
                } else {
                    toast.error(res?.message || "Something went wrong");
                }
            })
            .catch((err) => {
                toast.error(err);
                setInitialData((item) => ({ ...item, loader: false }));
            });
    };

    useEffect(() => {
        params?.id && getTherapyDetail(params?.id);
    }, []);
    return (
        <div className='col-md-10 no-padding ContentArea'>
            <div className="no-padding dash-billing-info mr-0 flex-grow-1">
                <div className="custom-table-view min-h-100">
                    <div class="content-header">
                        <div class="text-left header-title pt-7px">ICD Code Details</div>
                        <div class="text-right header-btn">
                        </div>
                    </div>
                    <div className="row">
                        {initialData.loader === true ? (
                            <div className="p-5 mt-5">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <div className="col-md-12">
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <p>
                                                <b>Therapy Name:</b>
                                            </p>
                                        </div>
                                        <div className="col-md-9">
                                            {" "}
                                            <p>{initialData.data?.therapyDto?.name}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <p>
                                                <b>ICD 10 Code:</b>
                                            </p>
                                        </div>
                                        <div className="col-md-9">
                                            {" "}
                                            <p>{initialData.data?.code}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <p>
                                                <b>Description:</b>
                                            </p>
                                        </div>
                                        <div className="col-md-9">
                                            {" "}
                                            <p>{initialData.data?.description}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <p>
                                                <b>Status:</b>
                                            </p>
                                        </div>
                                        <div className="col-md-9">
                                            {" "}
                                            <p>{initialData.data?.active ? "Active" : "Inactive"}</p>
                                        </div>
                                    </div>{" "}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Link to="/icd-code" className="btn btn-theme-blue">
                                                Back
                                            </Link>
                                            {/* <Link
                                            to={"/edit-icd-10-code/" + params.id}
                                            className="btn btn-theme-green ml-3"
                                        >
                                            Edit
                                        </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewIcdCode;