import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePageState from "../../hooks/InitialState";
import { adminService } from "../../services/adminService";
import { toast } from "react-toastify";
import TenantForm from "./TenantForm";
//style={{".tdBorder"="padding: 10px, display: table-cell, borderSize: 1px, borderSpacing: 0, borderColor: #dee2e6, borderStyle: solid"}}
function AddEditTenantAdmin() {
  const { id } = useParams();
  // const history = useNavigate();
  const [tenantInfo, setTenant] = useState(null);
  const { pageState, setPageState } = usePageState();

  useEffect(() => {
    if (id) {
      getTenant();
    }
  }, []);

  const getTenant = () => {
    setPageState((item) => ({ ...item, loading: true }));
    adminService
      .getAll(`tenantadmin/${id}`)
      .then((res) => {
        // setPageState((item) => ({ ...item, loading: false }));
        if (res.status == "Success") {
          console.log(res?.data);
          setTenant(res?.data);
          //setTotalPages(res?.data?.totalPages);
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      })
      .catch((err) => {
        toast.error(err);
        //setPageState((item) => ({ ...item, loading: false }));
      });
  };
  return (
    <>
      <div className="col-md-10 no-padding d-flex flex-column">
        <div className="no-padding dash-billing-info mr-0 flex-grow-1">
          <div className="custom-table-view min-h-100">
            <div className="content-header pb-0">
              <div className="text-left header-title py-3">
                {id ? "Edit" : "Add"} Tenant Admin Details
              </div>
            </div>
            <div className="mt-4 tenant_info">
              <div className="row">
                <TenantForm getInfo={tenantInfo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditTenantAdmin;
