import moment from "moment";
import React from "react";
import DatePicker from "react-multi-date-picker";

export default function PlanAndEvolution(props) {
  const {relValue, isSubmit, dischargeSummary, careFrom ,careTo,evalFrom,evalTo,previouFrom,previouTo,changeInfo,dischar} = props;
  return (
    <>
 <label style={{ width: "auto", padding: "10px" }}>
                  CURRENT PLAN OF CARE PERIOD: FROM 
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.dischargeSummary?.step1?.currentPOCFromDate ? moment(relValue?.dischargeSummary?.step1?.currentPOCFromDate).format("MM/DD/YYYY"):''}</>
                ) : (
                  <div className="form-date-input">
                  <DatePicker
                    multiple={false}
                    value={dischargeSummary?.step1?.currentPOCFromDate||''}
                    onChange={careFrom}
                    name="currentPOCFromDate"
                    format="MM-DD-YYYY"
                    editable={false}
                  />
                </div>
                  
                )}
                 {" "}TO {" "}
                {isSubmit ? (
                  <>{relValue?.dischargeSummary?.step1?.currentPOCToDate ? moment(relValue?.dischargeSummary?.step1?.currentPOCToDate).format("MM/DD/YYYY"):''}</>
                ) : (
                  <div className="form-date-input">
                  <DatePicker
                    multiple={false}
                    value={dischargeSummary?.step1?.currentPOCToDate||''}
                    onChange={careTo}
                    name="currentPOCToDate"
                    format="MM-DD-YYYY"
                    editable={false}
                  />
                </div>
                )}&nbsp;&nbsp;<label><b >{" "}DISCHARGED{" "}</b></label> {isSubmit ? (
                  <>{relValue?.dischargeSummary?.step1?.dateOfDischarge ? moment(relValue?.dischargeSummary?.step1?.dateOfDischarge).format("MM/DD/YYYY"):''}<br/></>
                ) : (
                  <div className="form-date-input">
                  <DatePicker
                    multiple={false}
                    value={dischargeSummary?.step1?.dateOfDischarge||''}
                    onChange={dischar}
                    name="dateOfDischarge"
                    format="MM-DD-YYYY"
                    editable={false}
                  />
                </div> )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  CURRENT EVALUATION DATE: 
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.dischargeSummary?.step1?.currentEvaluationDate ? moment(relValue?.dischargeSummary?.step1?.currentEvaluationDate).format("MM/DD/YYYY"):''}<br/></>
                ) : (
                  <div className="form-date-input">
                  <DatePicker
                    multiple={false}
                    value={dischargeSummary?.step1?.currentEvaluationDate||''}
                    onChange={evalFrom}
                    name="currentEvaluationDate"
                    format="MM-DD-YYYY"
                    editable={false}
                  />
                </div>
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  PREVIOUS PLAN OF CARE PERIOD: FROM 
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                  <>{relValue?.dischargeSummary?.step1?.previouPOCFromDate?moment(relValue?.dischargeSummary?.step1?.previouPOCFromDate).format("MM/DD/YYYY"):''}</>
                ) : (
                  <div className="form-date-input">
                  <DatePicker
                    multiple={false}
                    value={dischargeSummary?.step1?.previouPOCFromDate}
                    onChange={previouFrom}
                    name="previouPOCFromDate"
                    format="MM-DD-YYYY"
                    editable={false}
                  />
                </div>
                )}
                {" "} TO {" "}
                {isSubmit ? (
                  <>{relValue?.dischargeSummary?.step1?.previouPOCToDate?moment(relValue?.dischargeSummary?.step1?.previouPOCToDate).format("MM/DD/YYYY"):''}<br/></>
                ) : (
                  <div className="form-date-input">
                  <DatePicker
                    multiple={false}
                    value={dischargeSummary?.step1?.previouPOCToDate}
                    onChange={previouTo}
                    name="previouPOCToDate"
                    format="MM-DD-YYYY"
                    editable={false}
                  />
                </div>
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  PREVIOUS EVALUATION DATE: 
                </label>
                &nbsp;&nbsp;
                {isSubmit ? (
                 
                  <>{relValue?.dischargeSummary?.step1?.previousEvaluationDate ? moment(relValue?.dischargeSummary?.step1?.previousEvaluationDate).format("MM/DD/YYYY"):''}<br/></>
                ) : (
                  <div className="form-date-input">
                  <DatePicker
                    multiple={false}
                    value={dischargeSummary?.step1?.previousEvaluationDate}
                    onChange={evalTo}
                    name="previousEvaluationDate"
                    format="MM-DD-YYYY"
                    editable={false}
                  />
                </div>
                  
                )}
                <br />
                <label style={{ width: "auto", padding: "10px" }}>
                  FREQUENCY: WEEKLY VISITS:{" "}
                  {isSubmit ? (
                    relValue?.dischargeSummary?.step1?.weeklyVisit
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="weeklyVisit"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}{" "}
                   VISIT LENGTH: {" "}
                  {isSubmit ? (
                    relValue?.dischargeSummary?.step1?.visitLength
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="visitLength"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}{" "} minutes<br/>
                </label>
                <br/>
                <label style={{ width: "auto", padding: "10px" }}>
                  DURATION: MONTHS:{" "}
                  {isSubmit ? (
                    relValue?.dischargeSummary?.step1?.months
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="months"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                  {" "} WEEKS #: {" "}
                  {isSubmit ? (
                    <>{relValue?.dischargeSummary?.step1?.weeks}<br/></>
                  ) : (
                    <input
                      // ref={register({ required: true })}
                      type="text"
                      name="weeks"
                      placeholder=""
                      className={`form-control form-input form-input-w-120px`}
                      defaultValue={""}
                      onChange={(e) => changeInfo(e)}
                    />
                  )}
                </label>
                <br />
     
    </>
  );
}
